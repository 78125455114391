import React from "react"
import Modaly from "../Modals/modal" // render the content as Modal
import { Link } from 'react-router-dom'

import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import ActivePlan from "./components/ActivePlan"
import MenuBar from "./components/MenuBar"

import {
  Button,
  Checkbox,
  Icon,
  Modal,
  Form,
  Message,
  Tab,
  Grid,
} from "semantic-ui-react"

import {
  resultHandler,
} from "../../actions/actions"




class Premium extends React.Component {

  static propTypes = {
    profile: PropTypes.object,
    auth: PropTypes.object,
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }),
  }



  handleCloseModal = () => {
    this.props.history.push(this.props.location.pathname);
  }



  render() {

    let params = new URLSearchParams(this.props.location.search);


    return (

      params.get('premium') && (isLoaded(this.props.auth)) && (<Modaly>

        <Modal className="lp-modal-sidebox" dimmer='inverted' open='true' onClose={this.handleCloseModal} >


          <div className="sidebox--rollsection">

            <div className="sidebox__header sidebox__header--premium">
              <div className="intro-header__logo intro-header__logo--left"></div>
              <h1 className="header">Unlock all features</h1>
              <p className="perex">Get the best directions for your next trip.</p>
            </div>

            <div className="sidebox__body">

              <div className="sidebox--section">
                <h3>Your Package</h3>
                <ActivePlan />
              </div>

              <div className="sidebox--section">
                <h3>Unlock all features, from €2.49:</h3>

                <div className="sidebox-banner sidebox-banner--access">
                  <div className="text-wrapper">
                    <h2>Unlimited Planning</h2>
                    <p className="perex">
                      Start planning your next trip right now!                  </p>
                  </div>
                </div>

                <div className="sidebox-banner sidebox-banner--roadbook">
                  <div className="text-wrapper">
                    <h2>Exports</h2>
                    <p className="perex">
                      Detailed roadbook and waypoints to export
                    </p>
                  </div>
                </div>

                <div className="sidebox-banner sidebox-banner--vehicles">
                  <div className="text-wrapper">
                    <h2>Vehicle Profiles</h2>
                    <p className="perex">
                      Save up to 3 vehicle configurations
                    </p>
                  </div>
                </div>

              </div>

              <p className="perex2">The idea of DRIVE2.CITY came in 2019, after getting lost in Berlin's zone.😅
                  Since then, our routing engine has delivered more than 1.000.000 km of low-emission compliant routes.
                  Your symbolic contribution will help us to maintain the project and to explore new approaches in car mobility.</p>

            <div className="button-place-holder">
              <a href="https://drive2.city/purchase" target="_blank" className="button--outline button--outlineBig">Choose Your Package</a>
              <h3>Thank you for your support!</h3>
            <h2>❤</h2>
            </div>

            
            <p className="perex2">Tomas, the founder of DRIVE2.CITY</p>

            </div>

            

          </div>

          <MenuBar />


          {this.props.authError ?
            <Message attached='bottom' warning>
              <Icon name='help' />
              <p>Authorisation was not succesffull</p>
            </Message>
            : null}

        </Modal>

      </Modaly>

      )

    )

  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.firebase.authError,
    profile: state.firebase.profile
  }
}


export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(Premium)