
var helper = require("./pInPoly");

module.exports = {

    pointsInPoly: function (points, poly) {

        var routePoints = points; 
        var routePoint = [];       
        
        for (var m = 0, n = routePoints.length - 1; m < routePoints.length; n = m++) { //for loop for route points
            var routePoint = routePoints[m];  // bod x,y           
          
            if (helper.pointInPoly(routePoint, poly)) { break;}  
        } 
        return [routePoint, m];        
    }

}