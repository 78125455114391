import React from "react"
import PropTypes from "prop-types"
import LinkButton from "../../../helpers/LinkButton"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'




class PremiumButton extends React.Component {

    state = {
        plan: '',
        active: false,

    }

    static propTypes = {
        timestamp: PropTypes.bool.isRequired,
        profile: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        })
    }


    componentDidMount() {
        if (typeof this.props.profile.plan !== "undefined" && this.props.profile.plan) {
            let planString = JSON.stringify(this.props.profile.plan)
            let planObj = JSON.parse(planString);
            console.log("plan settings: " + planObj)
            this.setState({
                plan: planObj
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profile !== this.props.profile && this.props.profile.plan) {
            let planString = JSON.stringify(this.props.profile.plan)
            let planObj = JSON.parse(planString);
            console.log("payment settings: " + planObj)
            this.setState({
                plan: planObj
            })
        };
        if (prevProps.profile !== this.props.profile) {
            var paidDue = this.props.profile.paidDue;
            var timeNow = this.props.timestamp;
            if (paidDue > timeNow) {
                this.setState({
                    active: true,
                });
            }
        }
    }





    render() {
        if (isEmpty(this.props.auth)) {
            return (
                <LinkButton className="buttons button--unfiledstar" to="?premium=true">DEMO</LinkButton>
            )
        }
        else if (isLoaded(this.props.auth) && this.state.plan.stateSub == "DEMO" || !this.state.active) {
            return (
                <LinkButton className="buttons button--unfiledstar" to="?premium=true">DEMO</LinkButton>
            )
        }
        else if (isLoaded(this.props.auth) && this.state.plan.stateSub == "TRIAL" && this.state.active) {
            return (
                <LinkButton className="buttons button--filledstar" to="?premium=true">TRIAL</LinkButton>
            )
        }
        else if (isLoaded(this.props.auth) && this.state.plan.stateSub == "BUYED" && this.state.active) {
            return (
                <LinkButton className="buttons button--filledstar" to="?premium=true">PREMIUM</LinkButton>
            )
        } else {
            return (
                <LinkButton className="buttons button--unfiledstar" to="?premium=true">DEMO</LinkButton>
            )
        }
    }


}

const mapStateToProps = state => {
    const profile = state.firebase.profile
    const auth = state.firebase.auth

    return {
        profile,
        auth
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(PremiumButton)
