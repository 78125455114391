import React from "react"
import PropTypes from "prop-types"
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'


import {
    Icon,
    Message,
} from "semantic-ui-react"
import dateFormat from "dateformat"




class ActivePlan extends React.Component {

    state = {
        plan: '',

    }

    static propTypes = {
        auth: PropTypes.object,
        profile: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        }),
    }



    componentDidMount() {
        if (typeof this.props.profile.plan !== "undefined" && this.props.profile.plan) {
            let planString = JSON.stringify(this.props.profile.plan)
            let planObj = JSON.parse(planString);
            console.log("plan settings: " + planObj)
            this.setState({
                plan: planObj
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profile !== this.props.profile && this.props.profile.plan) {
            let planString = JSON.stringify(this.props.profile.plan)
            let planObj = JSON.parse(planString);
            console.log("payment settings: " + planObj)
            this.setState({
                plan: planObj
            })
        }
    }


    render() {

        const timeNow = this.props.firebase.firestore.Timestamp.now().seconds * 1000;

        if (this.props.auth.uid) {
            return (
                <div>
                    {this.props.profile.paidDue > timeNow ?
                        <div>
                            <div className="card-my-plan">
                                <div className="card-my-plan__text-wrapper">
                                    <h3>{this.state.plan.stateSub == 'TRIAL' ? "5-DAYS TRIAL" : "FULL ACCESS"}</h3>
                                </div>
                                <div className="card-my-plan__details">
                                    <p>Unlimited routing</p>
                                    <p>Waypoints export</p>
                                    <p>Roadbooks with P+R tips</p>
                                    <p>Save up to 3 vehicles</p>                                    
                                </div>                                
                            </div>
                            <p>{this.state.plan.stateSub == 'TRIAL' ? `Trial ends on: ${dateFormat(this.props.profile.paidDue, "mmmm dS, yyyy")}` : `Plan active until ${dateFormat(this.props.profile.paidDue, "mmmm dS, yyyy")}`}</p>
                            <div className="button-place-holder">
                                <a href="https://drive2.city/app/profile" target="_blank" className="button--outline">{this.state.plan.stateSub == 'TRIAL' ? "Manage Your Trial" : "See invoices"}&nbsp;&nbsp;&nbsp;<Icon name="external"></Icon></a>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="card-my-plan card-my-plan--gray">
                                <div className="card-my-plan__text-wrapper">
                                    <h3>DEMO</h3>
                                </div>
                                <div className="card-my-plan__details">
                                    <p>test the emission based routing</p>
                                    <p>max. 3 routes / day</p>
                                </div>
                            </div>
                            <div className="button-place-holder">
                                <a href="https://drive2.city/purchase" target="_blank" className="button--outline">Upgrade Your Package&nbsp;&nbsp;&nbsp;<Icon name="external"></Icon></a>
                            </div>
                        </div>
                    }
                </div>
            )
        } else if (!this.props.auth.uid) {
            return (
                <div>
                    <div className="card-my-plan card-my-plan--gray">
                        <div className="card-my-plan__text-wrapper">
                            <h3>DEMO</h3>
                        </div>
                        <div className="card-my-plan__details">
                            <p>test the emission based routing</p>
                            <p>max. 3 routes / day</p>
                        </div>
                    </div>
                    <div className="button-place-holder">
                        <a href="https://drive2.city/purchase" target="_blank" className="button--outline">Upgrade Your Package&nbsp;&nbsp;&nbsp;<Icon name="external"></Icon></a>
                    </div>
                </div >
            )
        }

    }
}



const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.firebase.authError,
        profile: state.firebase.profile
    }
}


export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(ActivePlan)