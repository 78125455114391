import React from 'react';
import PropTypes from "prop-types"
import CalculateRoute from '../Controlpanel/CalculateRoute';
import SharePanel from '../Controlpanel/SharePanel';
import ClearBackButton from './Components/ClearBackButton'
import MyCarButton from './Components/MyCarButton';
import LoginProfileButton from './Components/LoginProfileButton';
import PremiumButton from './Components/PremiumButton';


class MenuPanel extends React.Component {
    static propTypes = {
        timestamp: PropTypes.bool.isRequired
    }

    render() {

        return (
            <div className="menubar-sidebox menubar-sidebox__main">
                <div className="placeholder">
                    <ClearBackButton />
                </div>
                <div className="placeholder">
                    <MyCarButton />
                </div>

                <div className="placeholder">
                    <CalculateRoute timestamp={this.props.timestamp} />
                </div>

                <div className="placeholder">
                    <LoginProfileButton />
                </div>

                <div className="placeholder">
                    <PremiumButton timestamp={this.props.timestamp} />                    
                </div>

                {/*<button className="buttons button--send" >send</button>                           
                <SharePanel />*/}
            </div>
        );

    }
}


export default (MenuPanel)