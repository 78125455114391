import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { wayPointsGmaps } from "../../helpers/waypoints"
import { pointInPoly } from "../../helpers/pInPoly"
import { gMapDeepLink } from "../../helpers/gmapsDeepLink"
import { gMapDeepLinkCity } from "../../helpers/gmapsDeepLinkCity"

import {
  Button,
  LinkButton,
  Icon,
  Modal,
  Popup,
  Header,
  Grid,
  Divider,
} from "semantic-ui-react"

import QRCode from "qrcode.react";




class SendPhone extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    modalOpen: false,
  }


  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  }



  render() {

    // CASE 1: pouze avoid zone
    if (this.props.isRouteCalculated && this.props.switchPointZone.length == 0) {

      return (

        <div>

          <div className="d-none">

            <button className="button--outline" onClick={this.handleOpenModal}><Icon name='mobile alternate' />Send to your phone</button>
            <Modal dimmer="inverted" open={this.state.modalOpen} onClose={this.handleCloseModal} className="modal__welcome-screen modal__welcome-screen--qr">

              <div className="welcome-screen">
                <button className="welcome-screen__close" onClick={this.handleCloseModal}><Icon name="close"></Icon></button>
                <div className="welcome-screen__float-box">
                  <p>Android version 8.1 or lower?</p>
                  <p>Use a QR Reader app, or send the navigation links to email instead</p>
                </div>
                <div className="welcome-screen__header">
                  <Icon name="mobile alternate" className="welcome-screen__logo"></Icon>
                  <h1 className="welcome-screen__headline">Send to your phone</h1>
                </div>

                <div className="welcome-screen__qr-wrapper">
                  <div className="qr-code">
                    <QRCode
                      value={gMapDeepLink(this.props.selectedStart, this.props.selectedTarget, this.props.waypointsAll)}
                      size={138}
                      bgColor={"#204fa4"}
                      fgColor={"#f4f4f4"}
                      level={"L"}
                      includeMargin={false}
                      renderAs={"svg"}
                      imageSettings={{
                        src: "./assets/ico_car--blue.png",
                        x: null,
                        y: null,
                        height: 30,
                        width: 30,
                        excavate: true,
                      }}
                    />
                  </div>
                </div>

                <div className="welcome-screen__text-description">
                  <h2>How to scan the QR-code?</h2>
                  <ul className="qr-code__description">
                    <li>Open the Camera app on your mobile phone.</li>
                    <li>Line up the QR code in the camera frame. When the scanner reads the code, you’ll see a notification.</li>
                    <li>Tap the notification to open the link. Your default navigation app will be started.</li>
                  </ul>
                </div>

              </div>

            </Modal>
          </div>

          <div className="d-mobile">
            <a className="button--outline"
              href={gMapDeepLink(this.props.selectedStart, this.props.selectedTarget, this.props.waypointsAll)}
            >
              Go to Navigation* <Icon className="button-go-navigate__ico-arrow-navi" name="location arrow"></Icon>
            </a>
          </div>
          <p className="note-underBtn">*Starts Google Maps, Mapy.cz, etc...</p>

        </div>

      )

    } else if (this.props.isRouteCalculated && this.props.switchPointZone.length !== 0) {
      // CASE 2: pouze avoid zone
      return (

        <div>

          <div className="d-none">

            <button className="button--outline d-none" onClick={this.handleOpenModal}><Icon name='mobile alternate' />Send to your phone</button>
            <Modal dimmer="inverted" open={this.state.modalOpen} onClose={this.handleCloseModal} className="modal__welcome-screen modal__welcome-screen--qr">

              <div className="welcome-screen">
                <button className="welcome-screen__close" onClick={this.handleCloseModal}><Icon name="close"></Icon></button>
                <div className="welcome-screen__float-box">
                  <p>Android version 8.1 or lower?</p>
                  <p>Use a QR Reader app, or send the navigation links to email instead</p>
                </div>
                <div className="welcome-screen__header">
                  <Icon name="mobile alternate" className="welcome-screen__logo"></Icon>
                  <h1 className="welcome-screen__headline">Send to your phone</h1>
                </div>

                <div className="welcome-screen__qr-wrapper">

                  <div className="qr-code">
                    <QRCode
                      value={gMapDeepLink(this.props.selectedStart, this.props.switchPointZone, this.props.waypointsAll)}
                      size={138}
                      bgColor={"#204fa4"}
                      fgColor={"#f4f4f4"}
                      level={"L"}
                      includeMargin={false}
                      renderAs={"svg"}
                      imageSettings={{
                        src: "./assets/ico_car--blue.png",
                        x: null,
                        y: null,
                        height: 30,
                        width: 30,
                        excavate: true,
                      }}
                    />
                  </div>

                  <div className="qr-code">
                    <QRCode
                      value={gMapDeepLinkCity(this.props.selectedTarget, this.props.switchPointZone)}
                      size={138}
                      bgColor={"#204fa4"}
                      fgColor={"#f4f4f4"}
                      level={"L"}
                      includeMargin={false}
                      renderAs={"svg"}
                      imageSettings={{
                        src: "./assets/ico_train--blue.png",
                        x: null,
                        y: null,
                        height: 30,
                        width: 30,
                        excavate: true,
                      }}
                    />
                  </div>

                </div>

                <div className="welcome-screen__text-description">
                  <h2>How to scan the QR-code?</h2>
                  <ul className="qr-code__description">
                    <li>Open the Camera app on your mobile phone.</li>
                    <li>Line up the QR code in the camera frame. When the scanner reads the code, you’ll see a notification.</li>
                    <li>Tap the notification to open the link. Your default navigation app will be started.</li>
                  </ul>
                </div>

              </div>



            </Modal>
          </div>


          <div className="d-mobile flex-column">
            <a className="button--outline"
              href={gMapDeepLink(this.props.selectedStart, this.props.switchPointZone, this.props.waypointsAll)}
            >
              Go to Navigation* <Icon className="button-go-navigate__ico-arrow-navi" name="location arrow"></Icon>
            </a>
            <br></br>
            <a className="button--outline"
              href={gMapDeepLinkCity(this.props.selectedTarget, this.props.switchPointZone)}
            >
              Alternatives for City* <Icon className="button-go-navigate__ico-arrow-navi" name="location arrow"></Icon>
            </a>
          </div>
          <p className="note-underBtn">*Starts Google Maps, Mapy.cz, etc...</p>

        </div>

      )
    } else {
      return (<div></div>)
    }

  }
}

const mapStateToProps = state => {
  const selectedStart = state.routeControls.selectedStart
  const selectedTarget = state.routeControls.selectedTarget
  const switchPointZone = state.routeControls.switchPointZone
  const pointInZone = state.routeControls.pointInZone
  const selectedCityTransport = state.routeControls.selectedCityTransport
  const isRouteCalculated = state.routeControls.isRouteCalculated
  const routeZones = state.routeControls.routeZones
  const waypointsAll = state.routeControls.waypointsAll
  const auth = state.firebase.auth

  return {
    selectedStart,
    selectedTarget,
    switchPointZone,
    pointInZone,
    selectedCityTransport,
    isRouteCalculated,
    routeZones,
    waypointsAll,
    auth
  }
}

export default connect(mapStateToProps)(SendPhone)