import React from "react"
import Modaly from "../Modals/modal" // render the content as Modal
import { Link } from 'react-router-dom'

import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import MenuBar from "./components/MenuBar"

import {
  Button,
  Checkbox,
  Icon,
  Modal,
  Form,
  Message,
  Tab,
  Grid,
} from "semantic-ui-react"

import {
  resultHandler,
} from "../../actions/actions"



class About extends React.Component {

  static propTypes = {
    profile: PropTypes.object,
    auth: PropTypes.object,
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }),
  }


  handleCloseModal = () => {
    this.props.history.push(this.props.location.pathname);
  }



  render() {

    let params = new URLSearchParams(this.props.location.search);


    return (

      params.get('about') && (<Modaly>

        <Modal className="lp-modal-sidebox" dimmer='inverted' open='true' onClose={this.handleCloseModal} >


          <div className="sidebox--rollsection">


            <div className="sidebox__header sidebox__header--about">
              <div className="intro-header__logo intro-header__logo--left"></div>
              <h1 className="header">About</h1>
              <p className="perex">DRIVE2.CITY</p>
            </div>

            <div className="sidebox__body">


              <div className="sidebox--section">
                <p className="text">
                  DRIVE2.CITY is the first fully interactive planning service for low-emission zones (LEZs) that takes specific drive-in conditions into account.
                </p>
                <p className="text">
                  Simply select the emission standard of your car, enter your destination and click on plan to get a valid route that will save you unnecessary stressful situations or even expensive fines!
                </p>
                <p className="text">
                  When you're ready to go, hit the export button and open your trip with helpful waypoints in your default navigation app.
                </p>
                <p className="text">
                  The heart of DRIVE2.CITY runs on the company's own customized GraphHopper engine, an open source project trusted by leading mobility companies. If you are looking for driving profiles of commercial vehicles (trucks, vans, etc.), please feel free to contact me and get access to the Business API.
                </p>
                <p className="text text-right">Tomas Novotny, DRIVE2.CITY</p>
              </div>

              <div className="sidebox--section">
                <h3>Terms of use</h3>

                <a className="textlink" href="https://drive2.city/terms-of-service" target="_blank"><Icon name="external"></Icon>&nbsp;&nbsp;Terms of Service</a>
                <a className="textlink" href="https://drive2.city/privacy-policy" target="_blank"><Icon name="external"></Icon>&nbsp;&nbsp;Privacy Policy</a>
                <a className="textlink" href="https://drive2.city/attribution" target="_blank"><Icon name="external"></Icon>&nbsp;&nbsp;Attribution</a>

              </div>

              <div className="sidebox--section">
                <h3>Get in touch</h3>
                <a className="textlink" href="https://www.youtube.com/channel/UCLElINxr2iPtwqiKubE9tQw" target="_blank"><Icon name="youtube"></Icon>&nbsp;&nbsp;Youtube</a>
                <a className="textlink" href="https://www.facebook.com/app.drive2.city" target="_blank"><Icon name="facebook"></Icon>&nbsp;&nbsp;Facebook</a>
                <a className="textlink" href="mailto:hallo@drive2.city" target="_blank"><Icon name="mail"></Icon>&nbsp;&nbsp;E-mail</a>
              </div>

              <div className="sidebox--section">
                <h3>Implemented Zones</h3>
                <p className="note--alone">
                  Last update: 28.04.2024, routing only for private cars (less than 3.5t)
                </p>
                <ul className="zones">
                  <li className="country">Belgium</li>
                  <li>Brussels</li>
                  <li>Antwerp</li>
                  <li>Gent</li>

                  <li className="country">Denmark</li>
                  <li>Copenhagen</li>
                  <li>Aarhus</li>
                  <li>Odense</li>
                  <li>Aalborg</li>

                  <li className="country">Netherlands</li>
                  <li>Amsterdam</li>
                  <li>Arnhem</li>
                  <li>Utrecht</li>
                  <li>Den Haag</li>
                  <li>Breda (only trucks)</li>
                  <li>Delft (only trucks)</li>
                  <li>Eindhoven (only trucks)</li>
                  <li>Leiden (only trucks)</li>
                  <li>Maastricht (only trucks)</li>
                  <li>Rijswijk (only trucks)</li>
                  <li>Rotterdam (only trucks)</li>
                  <li>‘s-Hertogenbosch (only trucks)</li>
                  <li>Tilburg (only trucks)</li>
                  <li>Haarlem (only trucks)</li>
                 
                  <li className="country">France</li>
                  <li>Eurométropole de Strasbourg</li>
                  <li>Grenoble-Alpes Métropole</li>
                  <li>Métropole d'Aix-Marseille-Provence</li>
                  <li>Métropole du Grand Nancy (only when pollution warning)</li>
                  <li>Métropole du Grand-Paris</li>
                  <li>Métropole Nice Côte d'Azur</li>
                  <li>Métropole Rouen Normandie</li>
                  <li>Montpellier Méditerranée Métropole</li>
                  <li>Toulouse Métropole</li>
                  <li>Lyon</li>
                  <li>Lille (only when pollution warning)</li>
                  <li>Reims</li>
                  <li>Saint-Etienne (only trucks)</li>
               
                  <li className="country">Spain</li>                 
                  <li>Madrid</li>
                  <li>Madrid - Zona Cero</li>
                  <li>Madrid - Platze Eliptica</li>
                  <li>Barcelona</li>
                  <li>Barcelona - Sant Joan Despi</li>
                  <li>Barcelona - Sant Cugat del Valles</li>

                  <li className="country">Sweden</li>                  
                  <li>Göteborg (only trucks)</li>
                  <li>Helsingborg (only trucks)</li>
                  <li>Lund (only trucks)</li>
                  <li>Malmö (only trucks)</li>
                  <li>Mölndal (only trucks)</li>
                  <li>Stockholm</li>                  
                  <li>Umea (only trucks)</li>
                  <li>Uppsala (only trucks)</li>
                
                  <li className="country">Portugal</li>
                  <li>Lisbon - zona 1</li>
                  <li>Lisbon - zona 2</li>

                  <li className="country">UK</li>                 
                  <li>Aberdeen</li>
                  <li>Bath</li>
                  <li>Birmingham</li>
                  <li>Bradford</li>
                  <li>Bristol</li>
                  <li>Dundee</li>
                  <li>Edinburgh</li>
                  <li>Glasgow</li>
                  <li>London</li>
                  <li>Oxford</li>
                  <li>Portsmouth</li>
                  <li>Scheffield</li>
                  <li>Newcastle</li>
               
                  <li className="country">Switzerland</li>
                  <li>Geneva (only when pollution warning)</li>

                  <li className="country">Italy</li>
                  <li>Milan (Aera B)</li>
                  <li>Milan (Aera C)</li>

                  <li className="country">Germany</li>
                  <li>Aachen</li>
                  <li>Augsburg</li>
                  <li>Berlin</li>
                  <li>Bonn</li>
                  <li>Bremen</li>
                  <li>Darmstadt (Umweltzone + Diesel Bans)</li>
                  <li>Dinslaken</li>
                  <li>Düsseldorf</li>                 
                  <li>Eschweiler</li>
                  <li>Frankfurt am Main</li>
                  <li>Freiburg</li>
                  <li>Hagen</li>
                  <li>Halle (Saale)</li>
                  <li>Hamburg (Diesel Bans)</li>
                  <li>Hannover</li>                                                                   
                  <li>Köln</li>
                  <li>Krefeld</li>
                  <li>Langenfeld</li>
                  <li>Leipzig</li>
                  <li>Limburg an der Lahn</li>
                  <li>Ludwigsburg und Umgebung(Asperg, etc.)</li>
                  <li>Magdeburg</li>
                  <li>Mainz</li>
                  <li>Mannheim</li>
                  <li>Marburg</li>
                  <li>Mönchengladbach</li>
                  <li>Mühlacker</li>
                  <li>München (EURO-5)</li>
                  <li>Münster</li>
                  <li>Neu-Ulm</li>
                  <li>Neuss</li>
                  <li>Offenbach</li>
                  <li>Osnabrück</li>
                  <li>Overath</li>                  
                  <li>Pforzheim</li>
                  <li>Regensburg</li>
                  <li>Remscheid</li>
                  <li>Reutlingen</li>
                  <li>Ruhrgebiet</li>              
                  <li>Siegen</li>
                  <li>Stuttgart (Umweltzone EU-4, EU-5, EU-6)</li>
                  <li>Tübingen</li>
                  <li>Ulm</li>               
                  <li>Wiesbaden</li>
                  <li>Wuppertal</li>
                </ul>
              </div>

              <div className="sidebox--section">
                <p className="note--alone">
                  &copy; DRIVE2.CITY 2024 (v2.5.1)
                </p>
              </div>

            </div>

          </div>

          <MenuBar />

          {this.props.authError ?
            <Message attached='bottom' warning>
              <Icon name='help' />
              <p>Authorisation was not succesffull</p>
            </Message>
            : null}

        </Modal>

      </Modaly>

      )

    )

  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.firebase.authError,
    profile: state.firebase.profile
  }
}


export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(About)