import React from "react"
import PropTypes from "prop-types"
import LinkButton from "../../../helpers/LinkButton"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'




class LoginProfileButton extends React.Component {
    static propTypes = {  
        profile: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
      })
    }

    
   
    render() {  
        if (isEmpty(this.props.auth)) {            
            return (
                <LinkButton className="buttons button--profile button--profile--green" to="?login=true">login</LinkButton>
            )
        }
        else if (isLoaded(this.props.auth)) {
            return (
                <LinkButton className="buttons button--profile" to="?user-profile=true">profile</LinkButton>
            )
        }
    }


}

const mapStateToProps = state => {
    const profile = state.firebase.profile
    const auth = state.firebase.auth

    return {
        profile,
        auth
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(LoginProfileButton)
