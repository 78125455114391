
import { 
  UPDATE_INPUT_START,
  UPDATE_INPUT_TARGET,
  RECIEVE_GEOCODE_RESULTS_START,
  RECIEVE_GEOCODE_RESULTS_TARGET,
  REQUEST_GEOCODE_RESULTS_START,
  REQUEST_GEOCODE_RESULTS_TARGET,
  INPUT_SELECTED_START, 
  INPUT_SELECTED_TARGET,
  SELECT_CITY, 
  SELECT_VEHICLE,
  UNSET_DEFAULT_VEHICLE,
  REQUEST_DIRECTIONS, 
  RECIEVE_DIRECTIONS,
  RECIEVE_DIRECTIONS_NEW,
  REQUEST_MARKER_START,
  RECIEVE_MARKER_START,
  REQUEST_MARKER_TARGET,
  RECIEVE_MARKER_TARGET,
  REQUEST_MARKER_SWITCH_POINT,
  RECIEVE_MARKER_SWITCH_POINT,
  CHANGE_MARKER_SWITCH_POINT,
  SHOW_WARNING_LEZ,
  SELECT_CITY_TRANSPORT,
  DELETE_CALCULATED_ROUTE,
  SHOW_PAYMENT_MODAL,
  USER_LOGGED,
  UPDATE_VEHICLE_SELECT,
  ACTIVATE_MY_CAR,
  DEACTIVATE_MY_CAR,
  MODE,
  UPDATE_VIEW_ALL,
  ZOOM_SELECTED_ZONE,
} from "../actions/actions"


// these are the initial states
const initialRoutingReducerState = {
  
  // 2x geoinputs START/TARGET (typeinput, graphic input, fetching ico state, geocoding results, selected result)
  userInputStart: "",
  userInputTarget: "", 
  graphicInputStart: false,
  graphicInputTarget: false, 
  isFetchingStart: false,
  isFetchingTarget: false,
  resultsStart: [],
  resultsTarget: [],
  selectedStart: [], 
  selectedTarget: [], 
  
  // 1x input CAR CONFIGURATION (dropdown data, selected value, selected EUnorm)
  vehicleOptions: [
    //{ key: 1, text: 'norm EURO-2 and lower', value: [{ name: 'EURO-1-D', value: 'car_eu2', euNorm: 1 }]},
    { key: 2, text: 'norm EURO-2 and lower', value: [{ name: 'EURO-2-D', value: 'car_eu2', euNorm: 2 }]},
    { key: 3, text: 'min. EURO-3 diesel', value: [{ name: 'EURO-3-D', value: 'car_eu3', euNorm: 3 }]},
    { key: 4, text: 'min. EURO-4 diesel', value: [{ name: 'EURO-4-D', value: 'car_eu4', euNorm: 4 }]},
    { key: 5, text: 'min. EURO-5 diesel', value: [{ name: 'EURO-5-D', value: 'car_eu5', euNorm: 5 }]},
    { key: 6, text: 'min. EURO-6 diesel', value: [{ name: 'EURO-6-D', value: 'car_eu6', euNorm: 6 }]},
  ],  
  selectedVehicle: [{ name: 'EURO-2-D', value: 'car_eu2', euNorm: 2 }],  //null 
  selectedEUnorm: 2, //[],

  //
  mode: 0,
  activateMyCar: false,

  //
  updateViewTrigger: 0,

  //
  zoomZonePoint: [],

  //
  routeZones: [],
  waypointsAll: [],

  //
  isRouteLoading: false,
  computedDirection: [],
  decodedDirection: [],
  computedDirectionInfos: [],
  isRouteCalculated: false,
  pointInZone: false,
  selectedCityTransport: 'transit',
  selectedCityTransportIco: 'train',
  isFetchingSwitchPoint: false,
  switchPointZone: [],      // ano pro aktivaci swithPointu
  switchPointAdress: "-",
  isDraggingSwitchPoint: false,  
  routeAlterDecoded: [],
  freeRequestsOverload: 10,
  allRequestsOverload: false,
}

// our reducer constant returning an unchanged or updated state object depending on the users action, many cases will follow
const routingReducer = (state = initialRoutingReducerState, action) => {
  switch (action.type) {
    // when a user inputs text we update the userInput :) easy!
    case UPDATE_INPUT_START:
    return {
      ...state,
      userInputStart: action.payload.inputValue
    }
    case UPDATE_INPUT_TARGET:
    return {
      ...state,
      userInputTarget: action.payload.inputValue
    }
    // let the app know the request is being made (for our spinner)
    case REQUEST_GEOCODE_RESULTS_START:
    return {
      ...state,
      isFetchingStart: true
    }
    case REQUEST_GEOCODE_RESULTS_TARGET:
    return {
      ...state,
      isFetchingTarget: true
    }
    // let the app know the request IS made (for our spinner)
    case RECIEVE_GEOCODE_RESULTS_START:
    return {
      ...state,
      resultsStart: action.results,
      isFetchingStart: false,
    }
    case RECIEVE_GEOCODE_RESULTS_TARGET:
    return {
      ...state,
      resultsTarget: action.results,
      isFetchingTarget: false,
    }
    // for position change (for map)
    case INPUT_SELECTED_START:
    return {
      ...state,
      selectedStart: action.payload.passValue,
    }
    case INPUT_SELECTED_TARGET:
    return {
      ...state,
      selectedTarget: action.payload.passValue,
    }

    case SELECT_CITY:
    return {
      ...state,
      selectedCity: action.payload.passValue,
      selectedCityId: action.payload.passName,
      selectedCityName: action.payload.passCityName,
      selectedCityZone: action.payload.passZoneName,
      selectedCityCenter: action.payload.passCityCenter,
      userInputStart: "",
      userInputTarget: "",
      isRouteCalculated: false,
    }

    case SELECT_VEHICLE:
    return {
      ...state,      
      selectedVehicle: action.payload.passVehicle,
      selectedEUnorm: action.payload.passVehicle[0].euNorm
    }

    case REQUEST_DIRECTIONS:
    return {
      ...state,
      isRouteLoading: true,
    }

    case RECIEVE_DIRECTIONS:
    return {
      ...state,
      computedDirection: action.results[0].routePolyline,
      computedDirectionInfos: action.results[0].routeInfo,
      decodedDirection: action.results[0].routeDecoded,
      switchPointZone: action.results[0].switchPoint,
      
      routeZones: action.results[0].routeZones,
      waypointsAll: action.results[0].waypointsAll,

      //waypointsDirection: action.results[0].waypointsDirection,
      //selectedCityName: action.results[0].selectedCityName,
      //selectedCityZone: action.results[0].selectedCityZone,
      //selectedCityZoneInfo: action.results[0].selectedCityZoneInfo,
      
      routeAlterDecoded: action.results[0].routeAlterDecoded,
      //switchPointAdress: action.results[0].switchAdress,
      isRouteLoading: false,
      isRouteCalculated: true,
    }

    case RECIEVE_DIRECTIONS_NEW:
    return {
      ...state,
      computedDirection: action.results[0].routePolyline,
      computedDirectionInfos: action.results[0].routeInfo,
      decodedDirection: action.results[0].routeDecoded,
      
      isRouteLoading: false,
      isRouteCalculated: true,
    }

    case REQUEST_MARKER_START:
    return {
      ...state,
      graphicInputStart: action.payload.triggerValue,
      isFetchingStart: true,
      isRouteCalculated: false,
    }

    case RECIEVE_MARKER_START:
    return {
      ...state,      
      userInputStart: action.results[0].title,
      selectedStart: action.results[0].displayposition,
      graphicInputStart: false, 
      isFetchingStart: false,     
    }

    case REQUEST_MARKER_SWITCH_POINT:
    return {
      ...state,
      isFetchingSwitchPoint: true,
    }

    case RECIEVE_MARKER_SWITCH_POINT:
    return {
      ...state,
      isFetchingSwitchPoint: false,
      isDraggingSwitchPoint: false,
      //switchPointAdress: action.results[0].title,
      switchPointZone: action.results[0].displayposition,
    }

    case CHANGE_MARKER_SWITCH_POINT:
    return {
      ...state,
      isDraggingSwitchPoint: true,
    }

    case REQUEST_MARKER_TARGET:
    return {
      ...state,
      graphicInputTarget: true,
      isFetchingTarget: true,
      isRouteCalculated: false,
    }

    case RECIEVE_MARKER_TARGET:
    return {
      ...state,      
      userInputTarget: action.results[0].title,
      selectedTarget: action.results[0].displayposition,
      graphicInputTarget: false, 
      isFetchingTarget: false,    
    }

    case SHOW_WARNING_LEZ:
    return {
      ...state,      
      pointInZone: action.payload.passValue,  
      selectedCityName: action.payload.selectedCityName,
      selectedCityZone: action.payload.selectedCityZone,
      selectedCityZoneInfo: action.payload.selectedCityZoneInfo,

    }

    case SELECT_CITY_TRANSPORT:
    return {
      ...state,
      selectedCityTransport: action.payload.passTransport,
      selectedCityTransportIco: action.payload.passTransportIco,
    }

    case DELETE_CALCULATED_ROUTE:
    return {
      ...state,
      userInputStart: "",
      userInputTarget: "",
      isRouteCalculated: false,
      isRouteLoading: false,
      
      resultsStart: [],
      resultsTarget: [],
      selectedStart: [], 
      selectedTarget: [], 

      computedDirection: [], 
      computedDirectionInfos: [], 
      decodedDirection: [], 

      pointInZone: false,
      selectedCityTransport: 'transit',
      selectedCityTransportIco: 'train',
      isFetchingSwitchPoint: false,
      switchPointZone: [],      
      isDraggingSwitchPoint: false, 
      
      selectedCityZoneInfo: [],
      switchPointAdress: "-",
      waypointsDirection: [],
      routeAlterDecoded: [],

      routeZones: [],
  
    }

    case UNSET_DEFAULT_VEHICLE:
    return {
      ...state,
      activateMyCar: false,
      selectedVehicle: [{ name: 'EURO-2-D', value: 'car_eu2', euNorm: 2 }],  //null 
      selectedEUnorm: 2, //[],
    }

    case SHOW_PAYMENT_MODAL:
    return {
      ...state,      
      freeRequestsOverload: action.payload.passValue,  
    }

    case USER_LOGGED:
    return {
      ...state,
      freeRequestsOverload: 10,      
    }

    case UPDATE_VEHICLE_SELECT:
    return {
      ...state,      
      selectedVehicle: initialRoutingReducerState.vehicleOptions[action.payload.passValue].value,
      selectedEUnorm: initialRoutingReducerState.vehicleOptions[action.payload.passValue].value[0].euNorm
    }

    case ACTIVATE_MY_CAR:
    return {
      ...state,
      activateMyCar: true,
    }

    case DEACTIVATE_MY_CAR:
    return {
      ...state,
      activateMyCar: false,
    }  

    case MODE:
    return {
      ...state,      
      mode: action.payload.addApiMode
    }

    case UPDATE_VIEW_ALL:
    return {
      ...state,
      updateViewTrigger: action.payload.passValue
    }

    case ZOOM_SELECTED_ZONE:
    return {
      ...state,
      zoomZonePoint: action.payload.passFocusPoint
    }

    default:
      return state
  }
}

export default routingReducer;