import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { functions } from '../../index'
import { Link } from 'react-router-dom'
import { gMapDeepLink } from "../../helpers/gmapsDeepLink"
import { gMapDeepLinkCity } from "../../helpers/gmapsDeepLinkCity"
import { onlyCityAdress } from "../../helpers/onlyCityAdress"
import { debounce } from "throttle-debounce"

import {
  Button,
  Popup,
  Modal,
  Icon,
} from "semantic-ui-react"

import {
  resultHandler,
} from "../../actions/actions"

class Email extends React.Component {
  state = {
    loadingSend: false,
    modalText: "Send",
    payment: 0,
  }

  static propTypes = {
    auth: PropTypes.object,
    profile: PropTypes.object,
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }),
  }

  constructor(props) {
    super(props)
    // we are wrapping fetchGeocodeResults in a 1 second debounce
    this.closeAfterSending = debounce(700, this.closeAfterSending)
  }

  componentDidMount() {
    if (typeof this.props.profile.payment !== "undefined" && this.props.profile.payment) {
      let paymentSting = JSON.stringify(this.props.profile.payment)
      let paymentObj = JSON.parse(paymentSting);
      console.log("payment settings: " + paymentObj)
      this.setState({
        payment: paymentObj,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile && this.props.profile.payment) {
      let paymentSting = JSON.stringify(this.props.profile.payment)
      let paymentObj = JSON.parse(paymentSting);
      console.log("payment settings: " + paymentObj)
      this.setState({
        payment: paymentObj,
      })
    }
  }

  handleSend = () => {
    const { dispatch } = this.props;
    this.setState({ loadingSend: true, });
    const callable1 = functions.httpsCallable('oneTripEmail');
    const callable2 = functions.httpsCallable('twoTripsEmail');

    if (this.props.switchPointZone.length == 0) {      
      return callable1({
        name: this.props.auth.displayName,       
        start: this.props.userInputStart,
        target: this.props.userInputTarget,
        destination: onlyCityAdress(this.props.userInputTarget),
        zones: this.props.routeZones,
        route1: gMapDeepLink(this.props.selectedStart, this.props.selectedTarget, this.props.waypointsAll),
        // predelat inputs pro gMapDeepLink - pak ji bude vse ok

      }).then(() => {
        this.setState({ loadingSend: false, modalText: "Sent" });
        dispatch(
          resultHandler({
            handlerCode: 'EMAIL_ROUTE_SUCCESS',
          })
        )
      }).catch((error) => {
        this.setState({ loadingSend: false, modalText: "Not sent" });
        dispatch(
          resultHandler({
            handlerCode: 'EMAIL_ROUTE_ERROR',
            handlerMessage: '...' + error,
          })
        )
      });
    } else if (this.props.switchPointZone.length !== 0) {      
      return callable2({
        name: this.props.auth.displayName,
        start: this.props.userInputStart,
        target: this.props.userInputTarget,
        destination: onlyCityAdress(this.props.userInputTarget),
        zones: this.props.routeZones,
        route1: gMapDeepLink(this.props.selectedStart, this.props.switchPointZone, this.props.waypointsAll),
        route2: gMapDeepLinkCity(this.props.selectedTarget, this.props.switchPointZone),

      }).then(() => {
        this.setState({ loadingSend: false, modalText: "OK" });
        dispatch(
          resultHandler({
            handlerCode: 'EMAIL_ROUTE_SUCCESS',
          })
        )
      }).catch((error) => {
        this.setState({ loadingSend: false, modalText: "OK" });
        dispatch(
          resultHandler({
            handlerCode: 'EMAIL_ROUTE_ERROR',
            handlerMessage: '...' + error,
          })
        )
      });
    }
  }


  render() {

    const timeNow = this.props.firebase.firestore.Timestamp.now().seconds * 1000;

    if (this.props.isRouteCalculated && (this.props.routeZones.length != 0) && this.state.payment.paid_due > timeNow) {
      return (
        <div>
          <Button className="button--outline" onClick={this.handleSend} loading={this.state.loadingSend}>{this.state.modalText + " to your Email*"}</Button>
          <p className="note-underBtn">*your Email: {this.props.auth.email}</p>
        </div>
      );
    } else if (this.props.isRouteCalculated && (this.props.routeZones.length != 0) && this.state.payment.paid_due < timeNow) {
      return (

        <Popup
          on='click'
          pinned
          position='top left'
          trigger={<button className="button--outline" >Send to your Email</button>}
        >Please <Link to={{ pathname: "/", search: "?premium=true" }}>buy premium</Link> to send the iterinary.
        </Popup>

      );
    } else if (this.props.isRouteCalculated && (this.props.routeZones.length == 0)) {
      return (
        <p className="note-underBtn">No zones have been detected...</p>
      );
    } else {
      return (<div></div>)
    }
  }
}



const mapStateToProps = (state) => {
  return {
    isRouteCalculated: state.routeControls.isRouteCalculated,

    auth: state.firebase.auth,
    authError: state.firebase.authError,
    profile: state.firebase.profile,

    selectedStart: state.routeControls.selectedStart,
    selectedTarget: state.routeControls.selectedTarget,

    
    switchPointZone: state.routeControls.switchPointZone,
    switchPointAdress: state.routeControls.switchPointAdress,

    userInputTarget: state.routeControls.userInputTarget,
    userInputStart: state.routeControls.userInputStart,

    routeZones: state.routeControls.routeZones,
    waypointsAll: state.routeControls.waypointsAll

  }
}


export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(Email)