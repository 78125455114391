import * as turf from '@turf/turf';
import { latlngTrans } from './latlng_trans';
//file
import { euCountries } from "../../src/mapData/euCountries";

var polyline = require('@mapbox/polyline');


export const getExportGeoData = (routePolyline, selectedEUnorm) => {
    // test spojeni    
    var routeDecoded = polyline.toGeoJSON(routePolyline[0].routePolyline).coordinates;
    var firstPoint = routeDecoded[0]
    var lastPoint = routeDecoded[routeDecoded.length-1] // pokud by neco nefungovalo, tak sem hodit -1

    // tohle hodit jeste jako vstupni parametry funkce
    var carEU = selectedEUnorm; 
    var carType = "diesel";
    
    // ukladani dat a pushunuti zakladni struktury
    var processedResults = [];

    
    processedResults.push({
        routePolyline: routePolyline[0].routePolyline,
        routeDecoded: routePolyline[0].routeDecoded,
        routeAlterDecoded: [],
        routeInfo: {
          distance: routePolyline[0].routeInfo.distance,
          duration: routePolyline[0].routeInfo.duration,
          bbox: routePolyline[0].routeInfo.bbox
        },
        switchPoint: [],
        routeZones: [],      // zde je array s jedontlivymi zonami, ktere se projizdi   
        waypointsAll: []
      });

    
    // motor iterace: vzdy se vezme jedna zona z filu a udelat se provereni
    const Processing = turf.featureEach( euCountries, function (currentFeature, featureIndex) {
        console.log("DATA SE ODESLALY NA PROCESSING");
        // Zakldni otestovani = vstupni podminka jestli je zona zakazana pro vstupni auto
        if ((carType == "diesel" && carEU < currentFeature.properties.minEUd && carEU >= currentFeature.properties.minOUTd && currentFeature.properties.commercial != 1) 
        || (carType == "benzin" && carEU < currentFeature.properties.minEUb && carEU >= currentFeature.properties.minOUTb && currentFeature.properties.commercial != 1)) {
            
            // zde pokracuji pouze zony s vysimi pozadavky nez parametry vozu
            

            // CASE A: Cil je uvnitr ZAKAZANE zony => redukce/rozdeleni trasy:
            if (turf.booleanPointInPolygon(turf.point(lastPoint), currentFeature) &&
                !turf.booleanPointInPolygon(turf.point(firstPoint), currentFeature) && 
                !currentFeature.properties.localBan && currentFeature.properties.commercial != 1) {
                console.log("cilovy bod je v zakazne zone! "+currentFeature.properties.city)
                const insideZonePoints = turf.pointsWithinPolygon(turf.points(routeDecoded), currentFeature);
                const pointsArray = [];
                pointsArray.push(insideZonePoints);            
                //console.log("Bod 1 unvitr polygonu: "+JSON.stringify(pointsArray[0][0]));
                
                // zde extrahovat prvni bod (tzv. intersection)
                const switchPoint = pointsArray[0].features[0].geometry.coordinates;
                console.log("Bod uvnitr polygonu: "+JSON.stringify(switchPoint));
                // push
                processedResults[0].switchPoint = latlngTrans([switchPoint])[0][0];

                // zde upravit trasu
                // find position of switch point and then slice it to the nex polygon
                const switchPointIndex = routeDecoded.findIndex(element => element == switchPoint);
                var reducedRoute = routeDecoded.slice(0,switchPointIndex);
                var reducedRoute2 = routeDecoded.slice(switchPointIndex, -1)
                console.log(switchPointIndex);
                console.log(reducedRoute);
                // push reduced route
                processedResults[0].routeDecoded = [reducedRoute];   
                processedResults[0].routeAlterDecoded = [reducedRoute2]             
            }
        
        // ---
        // --- zde konec 1 iterace, vysledkem je aktualizovany processedResults
        // --- vysupem jsou: routeDecoded, routeAlterDecoded, switchPoint

    
        
            
            // CASE B: Trasa v tesne blizkosti zony => 3 prujezdni body
            var routeCloseZone = []; // nezapomenout vyprazdnit na konci smycky!!!
            const zoneEnvelopeOrig = turf.envelope(currentFeature); // verze s obalkou

            // zde si z analyzuji LEZ zonu a navrhnu scaling factor pro Envelope
            const areaEnvelopeOrig = turf.area(zoneEnvelopeOrig);
            const scaleFactorEnvelope = areaEnvelopeOrig < 960000 ? 10 : 1.58;
            
            const zoneEnvelope = turf.transformScale(zoneEnvelopeOrig, scaleFactorEnvelope); // trochu presah


            var calculatedRoute = routeDecoded

            // ---- sem vse ok, ted je to o iteraci cele trasy podel zoneEnvelope
            
            // overeni bodu v blizkosti zony pres vytvoreny envelopeBox
            // zde se bere zatim pouze bod cislo jedna
            // ALE JE POTREBA PROITEROVAT CELOU TRASU A VZIT VSECHNY ZONY

            
            if (turf.booleanIntersects(turf.points(calculatedRoute), zoneEnvelope)) {
                
                // jmeno mesta se zonou a info
                const extractedCityName = currentFeature.properties.city;
                const extractedCityZone = currentFeature.properties.zone;
                const extractedCityZoneInfo = [currentFeature.properties.diesel, currentFeature.properties.petrol, currentFeature.properties.warn];
                const extractedZoneUrls = [{
                    urlZone: currentFeature.properties.urlZone ? currentFeature.properties.urlZone : null,
                    urlPark: currentFeature.properties.urlPark ? currentFeature.properties.urlPark : null,
                    urlReg: currentFeature.properties.urlReg ? currentFeature.properties.urlReg : null,
                    urlBadge: currentFeature.properties.urlBadge ? currentFeature.properties.urlBadge : null
                }];

                // objizdne body
                const closePoints = turf.pointsWithinPolygon(turf.points(calculatedRoute), zoneEnvelope);
                
                routeCloseZone.push(closePoints); 
                const arrayRouteCloseZone = routeCloseZone[0].features;
                //console.log("ZONE ENVOELOPE: " + JSON.stringify(arrayRouteCloseZone));

                if (arrayRouteCloseZone.length <= 5){
                    console.log("keep going");
                } else {

                // console.log("Je vice bodu");
                const routeCloseZonePstart = routeCloseZone[0].features[0].geometry.coordinates;
                const routeCloseZonePtarget = routeCloseZone[0].features[arrayRouteCloseZone.length-1].geometry.coordinates;
                const routeCloseZonePmiddle1 = routeCloseZone[0].features[(arrayRouteCloseZone.length/4).toFixed(0)].geometry.coordinates;
                const routeCloseZonePmiddle2 = routeCloseZone[0].features[(arrayRouteCloseZone.length/2).toFixed(0)].geometry.coordinates;
                const routeCloseZonePmiddle3 = routeCloseZone[0].features[(arrayRouteCloseZone.length/1.35).toFixed(0)].geometry.coordinates;
                const indexOnRoute = routeDecoded.findIndex(element => element == routeCloseZonePstart);
                
                //console.log("Prujezdne body v okoli zony: " + JSON.stringify(routeCloseZonePstart) + JSON.stringify(routeCloseZonePmiddle1) + JSON.stringify(routeCloseZonePtarget));  
                // mezi to vlozit jeste testovani jestli se nejedna o duplicitni body (v pripade shody v urcitem miste desetinne carky)
                            
                const extractedWaypoints = [latlngTrans([routeCloseZonePstart])[0][0], latlngTrans([routeCloseZonePmiddle1])[0][0], latlngTrans([routeCloseZonePmiddle2])[0][0], latlngTrans([routeCloseZonePmiddle3])[0][0], latlngTrans([routeCloseZonePtarget])[0][0]];
                
                // zde to vse zabalit do jednoho array

                const extractedZoneDataArray = [
                    {                        
                        selectedCityName: extractedCityName,
                        selectedCityZone: extractedCityZone,
                        selectedCityZoneInfo: extractedCityZoneInfo,
                        selectedCityZoneUrls: extractedZoneUrls,
                        waypointsDirection: extractedWaypoints,
                        sortIndex: indexOnRoute
                    }
                ]

                // finalni nahrani celeho zone datasetu do arraye
                processedResults[0].routeZones.push(extractedZoneDataArray[0]);
                var routeCloseZone = []; 
                }
            
            }
            
            // zde najit opet prvni a posledni bod, pak najit jejich pozici na normalni route linii a tu dal zpracovat - resp waypouints
            
        } 

    });


    

    /*

    // zde si overit vysledek jestli waypoints nejsou duplicitni se start/cilem
    const sliceIndex = [];

    if (processedResults[0].waypointsDirection[0]) {

    if (JSON.stringify(firstPoint).includes(JSON.stringify(processedResults[0].waypointsDirection[0][0]))) {
        console.log("DUPLICITA START") 
        sliceIndex.push(1);    
    } else {
        sliceIndex.push(0);
    }
    if (JSON.stringify(lastPoint).includes(JSON.stringify(processedResults[0].waypointsDirection[2][0]))) {
        console.log("DUPLICITA CIL");
        sliceIndex.push(2)
    } else {
        sliceIndex.push(3)
    }  
       
    processedResults[0].waypointsDirection = processedResults[0].waypointsDirection.slice(sliceIndex[0], sliceIndex[1]);
    
    }
    //console.log("slice indexy: "+sliceIndex+" VYSLEDEK: " +processedResults[0].waypointsDirection);

    // returnovat se bude array slozeny podle situace z 
    // 1. vstupniho bodu
    // 2. middle oblasti (vlastni array, vyhledove s libovolnym poctem bodu)
    //          zde bude potreba otestovat zda nalezene body se neprekryvaji se stratem/cilem
    //          v pripade ze ano, musi být slicerem vyrazeny a tím pádem v array zustane pouze middle point
    //          nasledne je mozno uvazovat i vice bodu, na zaklade nasledujich podminek:
    //                  - dle vzdalenost mezi body
    //                  - vice prujezdnych zon        
    // 3. vystupniho bodu

    */

    // na zaver je nutne vse seradit
    
    
    if (processedResults[0].routeZones[0]) {

        const routeZonesOriginal = processedResults[0].routeZones        
        const routeZonesSorted = routeZonesOriginal.sort((a,b) => a.sortIndex - b.sortIndex).map((routeZonesOriginal, sortIndex, array) => routeZonesOriginal)
        
        console.log(JSON.stringify(routeZonesSorted));

        processedResults[0].routeZones = routeZonesSorted;



        // only waypoints

        var zoneNr = processedResults[0].routeZones.length;
        var waypointsAll = [];
        for (let i =0; i < zoneNr; i++) {
            let waypointload = processedResults[0].routeZones[i].waypointsDirection;
            waypointsAll.push(waypointload[0]);
            waypointsAll.push(waypointload[1]);
            waypointsAll.push(waypointload[2]);
            waypointsAll.push(waypointload[3]);
            waypointsAll.push(waypointload[4]);
        }
        var newWaypointsAll = waypointsAll.slice(0, -1); // removes last element



        processedResults[0].waypointsAll = newWaypointsAll;

    }



    if (processedResults[0].waypointsAll[0] && routeDecoded) {
        

        var indexWaypoints = [];        
        for (let i=0; i < processedResults[0].waypointsAll.length; i++) {  
            let coords = processedResults[0].waypointsAll[i];                     
            let indexWaypoint = routeDecoded.findIndex(el => el.includes(processedResults[0].waypointsAll[i][0]&&processedResults[0].waypointsAll[i][1]));
            let item = {
                "coord": coords,
                "index": indexWaypoint
            }
            indexWaypoints.push(item);
        }
        const indexWaypointsSorted = indexWaypoints.sort((a,b) => a.index - b.index).map((indexWaypoints, index, array) => indexWaypoints)
        
        var onlyWaypoints = []
        indexWaypointsSorted.map((item, i) => {
            onlyWaypoints.push(item.coord)
        });

        
        //console.log("Indexy jednotlivych prujezdnych bodu jsou zde: "+JSON.stringify(indexWaypoints));
        //console.log("Index zesortovane: "+JSON.stringify(indexWaypointsSorted));
        //console.log("only coords: "+JSON.stringify(onlyWaypoints));
        
        processedResults[0].waypointsAll = onlyWaypoints;

    }



    //console.log(JSON.stringify(processedResults));
    return processedResults;

}
