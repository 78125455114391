import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'

import {
    Button,
} from "semantic-ui-react";

// dont forget to import actions here and dobounce
import {
    resultHandler,
    updateViewAll,
} from "../../actions/actions"




class MapControls extends React.Component {



    handleViewAll = () => {
        const { dispatch } = this.props;
        var x = this.props.udateViewTrigger;
        x++;
        dispatch(
            updateViewAll({
                passValue: x
            })
        )
    }


    render() {

        if (this.props.isRouteCalculated) {
        return (
            <div className="mapcontrolBtns">
                <Button onClick={this.handleViewAll} icon="expand"></Button>
            </div>
        )
        } else {
            return (
                <div></div>
            )
        }
    }
}



const mapStateToProps = state => {
    const udateViewTrigger = state.routeControls.udateViewTrigger
    const isRouteCalculated = state.routeControls.isRouteCalculated

    return {
        udateViewTrigger,
        isRouteCalculated
    }
}


export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(MapControls)