import React from "react"
import PropTypes from "prop-types"
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Button, Modal, Icon } from "semantic-ui-react";
import Cookies from "js-cookie";
import { onlyCityAdress } from "../../helpers/onlyCityAdress"
import Lottie from "lottie-react"
import routeOkAnimation from "./gfx/route_ok.json"
import routeAlertAnimation from "./gfx/route_alert.json"



class RouteReadyModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      hideModalChecked: false,
    };
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleHideModalChange = this.handleHideModalChange.bind(this);
  }

  componentDidMount() {
    const hideModal = Cookies.get("hideModal");
    if (this.props.isRouteCalculated && !hideModal) {
      this.setState({ showModal: true });
    }
  }

  componentDidUpdate(prevProps) {
    const hideModal = Cookies.get("hideModal");
    if (
      prevProps.isRouteCalculated === false &&
      this.props.isRouteCalculated === true &&
      !hideModal
    ) {
      this.setState({ showModal: true });
    }
  }

  handleModalClose() {
    if (this.state.hideModalChecked) {
      Cookies.set("hideModal", true, { expires: 7 }); // Set cookie expiration time
    }
    this.setState({ showModal: false });
  }

  handleHideModalChange(event) {
    this.setState({ hideModalChecked: event.target.checked });
  }


  render() {
    const { routeZones, switchPointZone, userInputTarget, selectedEUnorm } = this.props;

    // Logic to determine which option to display
    let modalContentOld;
    let modalContent;
    if (routeZones.length === 0) {
      modalContent = {
        anim: routeOkAnimation,
        anim_param: false,
        header: "Detected LEZs (0)",
        perex: `Great, your EURO-${this.props.selectedEUnorm} Diesel is compliant with all zones ahead!`,
        note: "Remember, some zones might still require an online registration at municipality website or an emission badge. Check official links!",
        zones_list: false,
        zone_links: false
      };
    } else if (routeZones.length !== 0 && switchPointZone.length === 0) {
      modalContent = {
        anim: routeOkAnimation,
        anim_param: false,
        header: `Detected LEZs (${routeZones.length})`,
        perex: `Your EURO-${this.props.selectedEUnorm} Diesel may not be compliant with some zones ahead. We've found alternative route!`,
        note: "Please note, that in allowed zones, online registration or a badge might be still needed. Check official links!",
        zones_list: true,
        zone_links: false
      };
    } else if (routeZones.length !== 0 && switchPointZone.length !== 0) {
      modalContent = {
        anim: routeAlertAnimation,
        anim_param: true,
        header: "Destination Inside LEZ!",
        perex: `Your destination is in ${onlyCityAdress(userInputTarget)}'s LEZ with stricter low-emission rules, than your EURO-${this.props.selectedEUnorm} Diesel. Check if you may apply for special permit or reconsider the trip!`,
        note: "Avoid unnecessary fines by informing yourself about alternatives through official resources:",
        zones_list: false,
        zone_links: true
      };
    }
    return (
      <Modal dimmer="inverted" open={this.state.showModal} onClose={this.handleModalClose} className="modal__welcome-screen">

        <div className="modal__contentHolder--additionalInfo">
          <div className="welcome-screen">

            <button className="welcome-screen__close" onClick={this.handleModalClose}><Icon name="close"></Icon></button>

            <div className="modal-rr">
              <div className="modal-rr__header-img">
                <Lottie animationData={modalContent.anim} loop={modalContent.anim_param} />
              </div>

              <div className="modal-rr__header">
                <h1 className="header">{modalContent.header}</h1>
              </div>

              {modalContent.zones_list &&
                <div className="modal-rr__lezs">
                  {routeZones.map((zone, index) => (
                    <span className="modal-rr__lezbadge">{zone.selectedCityName}</span>
                  ))}
                </div>
              }

              <div className="modal-rr__perex">
                <p className="perex">{modalContent.perex}</p>
              </div>

              <div className="modal-rr__note">
                <p className="note">{modalContent.note}</p>
                {modalContent.zone_links &&
                  <div className="modal--rr__linksblock">
                    <a href={routeZones[0].selectedCityZoneUrls[0].urlZone} target="_blank" className="note">{onlyCityAdress(userInputTarget)} LEZ - official website</a>
                    <a href={routeZones[0].selectedCityZoneUrls[0].urlPark} target="_blank" className="note">Where to park (P+R) in {onlyCityAdress(userInputTarget)}?</a>
                    <a href={routeZones[0].selectedCityZoneUrls[0].urlBadge} target="_blank" className="note">Where to buy the emission badge or register my car?</a>
                  </div>
                }
              </div>

              <div className="modal-rr__buttonarea">
                <Button className="button-send-email button-send-email--outline" onClick={this.handleModalClose}>OK, I understand</Button>                
              </div>

              <div className="modal-rr__checkboxarea">
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.hideModalChecked}
                    onChange={this.handleHideModalChange}
                  />
                  Don't show this message again
                </label>
              </div>

            </div>

            <div>

            </div>


          </div>
          <p className="modal__disclaimerInfo">
            By using this service you agree to the <a href="https://drive2.city/terms-of-service/">Terms of Service</a>. <b>Please note that road sign information always prevails!</b> DRIVE2.CITY is an independent project based on <a href="https://drive2.city/attribution/">open data initiatives</a>. We are not responsible for any fines or charges that may result from the using our service.
          </p>
        </div>


      </Modal >
    )
  }
}



const mapStateToProps = state => {
  const isRouteCalculated = state.routeControls.isRouteCalculated
  const userInputTarget = state.routeControls.userInputTarget
  const routeZones = state.routeControls.routeZones
  const switchPointZone = state.routeControls.switchPointZone
  const selectedEUnorm = state.routeControls.selectedEUnorm



  return {
    isRouteCalculated,
    userInputTarget,
    routeZones,
    switchPointZone,
    selectedEUnorm
  }
}



export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(RouteReadyModal)