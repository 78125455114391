import React from 'react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { render } from 'react-dom'
import { BrowserRouter, Route } from "react-router-dom";

import { createStore, applyMiddleware} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

import { firebase } from '@firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import rootReducer from './reducers'
import App from './components/App/App'
import Spinner from './components/Spinner/Spinner'

import UserProfile from './components/UserPanel/UserProfile';
import LoginModal from './components/Modals/LoginModal';
import MyCar from './components/UserPanel/MyCar';
import Premium from './components/UserPanel/Premium';
import About from './components/UserPanel/About'
import Export from './components/UserPanel/Export';
import LastUpdates from './components/Modals/LastUpdates';
import ZonesStatus from './components/Modals/ZonesStatus';

import 'bootstrap/dist/css/bootstrap.css'; // bootstrap
import './index.css' // postCSS import of CSS module

const middleware = [thunk]
const rrfConfig = { useFirestoreForProfile: true, userProfile: 'users' } // react-redux-firebase config
const fbConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  }

// Initialize firebase instance
firebase.initializeApp(fbConfig);
export const functions = firebase.functions();

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
)

const rrfProps = {
       firebase,
       config: rrfConfig,
       dispatch: store.dispatch,
       createFirestoreInstance,
     }

render(
    <Provider store={store}> 
        <ReactReduxFirebaseProvider {...rrfProps}>  
        {' '} 
            <BrowserRouter>
                <Route exact path="/" component={App} />  
                <Route exact path="/" component={LoginModal} />   
                <Route exact path="/" component={UserProfile} />     
                <Route exact path="/" component={MyCar} />    
                <Route exact path="/" component={Premium} />   
                <Route exact path="/" component={About} />    
                <Route exact path="/" component={Export} />      
                <Route exact path="/" component={LastUpdates} />    
                <Route exact path="/" component={ZonesStatus} />                              
            </BrowserRouter>
            <Spinner />
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
)


serviceWorkerRegistration.register();