import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"


import {
  Button,
} from "semantic-ui-react"

// dont forget to import actions here
import {
  fetchD2cDirections,
  resultHandler,
  dispatchMode
} from "../../actions/actions"


class CalculateRoute extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isRouteLoading: PropTypes.bool.isRequired,
    timestamp: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    // binding this to the handleSearchChange method        
    this.handleNewDirections = this.handleNewDirections.bind(this)
  }


  disReadyToGo() {
    if (this.props.selectedStart.lat > -180 && this.props.selectedTarget.lat > -180) {
      return false
    } else {
      return true
    }
  };


  handleNewDirections() {
    let { dispatch, selectedStart, selectedTarget, selectedVehicle } = this.props;
    let apiMode = 0;
    const timeNow = this.props.timestamp;

    if (!this.props.auth.uid) {
      apiMode = 0;
      console.log("Uzivatel neprihlasen a neplatil");
      dispatch(
        dispatchMode({
          addApiMode: apiMode
        })
      )
    } else if (this.props.auth.uid && !this.props.profile.paidDue || this.props.profile.paidDue == 0) {
      apiMode = 1;
      console.log("Uzivatel ma novy ucet, ale zatim nevybral plan") 
      dispatch(
        dispatchMode({
          addApiMode: apiMode
        })
      )
    } else if (this.props.auth.uid && this.props.profile.paidDue <= timeNow) {
      apiMode = 2;
      console.log("Uzivatel se prihlasil, ale vyprselo mu predplatne")
      dispatch(
        dispatchMode({
          addApiMode: apiMode
        })
      )
    } else if (this.props.auth.uid && this.props.profile.paidDue >= timeNow) {
      apiMode = 4;
      console.log("uzivatel prihlasen a zapltit");
      dispatch(
        dispatchMode({
          addApiMode: apiMode
        })
      )
    }
   


    if (selectedVehicle && selectedStart.lat > -180 && selectedTarget.lat > -180) {
      dispatch(
        fetchD2cDirections({
          coordStart: (selectedStart.lat + "," + selectedStart.lng),
          coordZiel: (selectedTarget.lat + "," + selectedTarget.lng),
          selectedVehicle: selectedVehicle,
          APImode: apiMode
          // zde vlozit apikey code podle toho, jestli je auth/paid/anonymous       
        })
      )
    } else {
      dispatch(
        resultHandler({
          handlerCode: 'SELECT_ROUTE',
        })
      )
    }
  }


  render() {

    if (this.props.isRouteCalculated === false) {
      return (

        //<div className="cp-actions cp-actions--notCalculated">

        <Button className="button-go-calculate"
          icon='location arrow'
          loading={this.props.isRouteLoading}
          onClick={this.handleNewDirections}
        //disabled={this.disReadyToGo()}
        />



      )
    } else {
      return (
        <Button className="button-go-calculate"
          icon='check'      
        />
      )
    }
  }
}

const mapStateToProps = state => {

  const selectedStart = state.routeControls.selectedStart
  const selectedTarget = state.routeControls.selectedTarget
  const selectedVehicle = state.routeControls.selectedVehicle
  const selectedEUnorm = state.routeControls.selectedEUnorm
  const isRouteLoading = state.routeControls.isRouteLoading
  const isRouteCalculated = state.routeControls.isRouteCalculated
  const pointInZone = state.routeControls.pointInZone
  const profile = state.firebase.profile
  const auth = state.firebase.auth

  return {
    selectedStart,
    selectedTarget,
    selectedVehicle,
    selectedEUnorm,
    isRouteLoading,
    isRouteCalculated,
    pointInZone,
    profile,
    auth
  }
}

export default connect(mapStateToProps)(CalculateRoute)