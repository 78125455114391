
module.exports = {

    gMapDeepLinkCity: function (selectedTarget, selectedStart) {
   
        if (selectedStart[0] && selectedStart[1]) {
            var start = selectedStart[0]+','+selectedStart[1];
        } else {
            var start = selectedStart.lat+','+selectedStart.lng; 
        } 
        
        let target = selectedTarget.lat+','+selectedTarget.lng;
      
                
        let url = new URL('https://www.google.com/maps/dir/'),
            params = {
                'api': 1,
                'origin': start,
                'destination': target,                  
                'travelmode': "transit",
            }   
        url.search = new URLSearchParams(params);
        return url.href;        
    } 
}