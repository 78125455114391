export const euCountries = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "id": "be--01",
            "properties": {
                "land": "be",
                "city": "Brussels",
                "zone": "LEZ",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 0,
                "diesel": "min. EURO-5 (diesel)",
                "minEUd": 5,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Online Registration",
                "img_sign": "sign-be--white",
                "color": 1,
                "urlZone": "https://lez.brussels/mytax/",
                "urlPark": "https://www.parkandride.brussels/en/park-ride-brussels/",
                "urlReg": "https://lez.brussels/mytax/en/registration",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                4.290096759796143,
                                50.81333798479452
                            ],
                            [
                                4.289646148681641,
                                50.81295835907817
                            ],
                            [
                                4.28941011428833,
                                50.80883650983604
                            ],
                            [
                                4.292886257171631,
                                50.808782272553195
                            ],
                            [
                                4.294087886810302,
                                50.808321253106755
                            ],
                            [
                                4.2960190773010245,
                                50.80954158878234
                            ],
                            [
                                4.297435283660889,
                                50.810029714126415
                            ],
                            [
                                4.297800064086914,
                                50.8105856284447
                            ],
                            [
                                4.29391622543335,
                                50.81206351482426
                            ],
                            [
                                4.290096759796143,
                                50.81333798479452
                            ]
                        ],
                        [
                            [
                                4.264808893203735,
                                50.817361149851344
                            ],
                            [
                                4.265495538711548,
                                50.81641555080443
                            ],
                            [
                                4.265356063842773,
                                50.81582581267271
                            ],
                            [
                                4.265817403793335,
                                50.815612284961155
                            ],
                            [
                                4.266096353530884,
                                50.8153411372538
                            ],
                            [
                                4.270838499069214,
                                50.81571057561631
                            ],
                            [
                                4.273960590362549,
                                50.81604611801856
                            ],
                            [
                                4.277029037475585,
                                50.81665279967639
                            ],
                            [
                                4.279780983924866,
                                50.81739843114188
                            ],
                            [
                                4.2807841300964355,
                                50.81687310112115
                            ],
                            [
                                4.284759163856506,
                                50.81635793303934
                            ],
                            [
                                4.28642213344574,
                                50.81607662175376
                            ],
                            [
                                4.285263419151306,
                                50.81758144795466
                            ],
                            [
                                4.282350540161133,
                                50.82033000307257
                            ],
                            [
                                4.280827045440674,
                                50.82089935061352
                            ],
                            [
                                4.276750087738037,
                                50.820699401970394
                            ],
                            [
                                4.2726945877075195,
                                50.81953358266302
                            ],
                            [
                                4.268724918365478,
                                50.81852702339471
                            ],
                            [
                                4.264808893203735,
                                50.817361149851344
                            ]
                        ],
                        [
                            [
                                4.2618584632873535,
                                50.814842899237696
                            ],
                            [
                                4.261214733123779,
                                50.81440905500339
                            ],
                            [
                                4.2591118812561035,
                                50.81364981789617
                            ],
                            [
                                4.259948730468749,
                                50.811087301540596
                            ],
                            [
                                4.2688751220703125,
                                50.81204995644615
                            ],
                            [
                                4.2731451988220215,
                                50.81211774829736
                            ],
                            [
                                4.276363849639893,
                                50.80988056525686
                            ],
                            [
                                4.277586936950684,
                                50.80828057470187
                            ],
                            [
                                4.281706809997559,
                                50.807439879737096
                            ],
                            [
                                4.284238815307617,
                                50.80714156498392
                            ],
                            [
                                4.28865909576416,
                                50.80849752578526
                            ],
                            [
                                4.288852214813232,
                                50.813134614258765
                            ],
                            [
                                4.287264347076415,
                                50.8143683818998
                            ],
                            [
                                4.286084175109863,
                                50.8157376902434
                            ],
                            [
                                4.28491473197937,
                                50.81602578218402
                            ],
                            [
                                4.282098412513733,
                                50.81629353662954
                            ],
                            [
                                4.280837774276733,
                                50.81644605429837
                            ],
                            [
                                4.279603958129883,
                                50.8170052814892
                            ],
                            [
                                4.276369214057922,
                                50.816124071968915
                            ],
                            [
                                4.274196624755859,
                                50.81565295698148
                            ],
                            [
                                4.271954298019409,
                                50.81545298587386
                            ],
                            [
                                4.269416928291321,
                                50.81524284582115
                            ],
                            [
                                4.268671274185181,
                                50.81517844787379
                            ],
                            [
                                4.2652541399002075,
                                50.814907297647714
                            ],
                            [
                                4.262679219245911,
                                50.814649703474814
                            ],
                            [
                                4.2618584632873535,
                                50.814842899237696
                            ]
                        ],
                        [
                            [
                                4.261751174926758,
                                50.81592749219223
                            ],
                            [
                                4.262759685516357,
                                50.816781591416444
                            ],
                            [
                                4.2635321617126465,
                                50.81726964110294
                            ],
                            [
                                4.2646050453186035,
                                50.81774412896389
                            ],
                            [
                                4.270527362823486,
                                50.819574251268335
                            ],
                            [
                                4.270913600921631,
                                50.81980470602954
                            ],
                            [
                                4.277222156524658,
                                50.82185163663897
                            ],
                            [
                                4.279003143310547,
                                50.82331561143909
                            ],
                            [
                                4.2794108390808105,
                                50.826243423335875
                            ],
                            [
                                4.280955791473389,
                                50.83254572865727
                            ],
                            [
                                4.28166389465332,
                                50.83783087704972
                            ],
                            [
                                4.277501106262206,
                                50.837912182350316
                            ],
                            [
                                4.273166656494141,
                                50.838684575640606
                            ],
                            [
                                4.2716646194458,
                                50.83610988162223
                            ],
                            [
                                4.269111156463623,
                                50.836191189921195
                            ],
                            [
                                4.264411926269531,
                                50.835594925772526
                            ],
                            [
                                4.261107444763184,
                                50.8353103424705
                            ],
                            [
                                4.258639812469482,
                                50.83597436747584
                            ],
                            [
                                4.254884719848633,
                                50.83339952392984
                            ],
                            [
                                4.2560434341430655,
                                50.829713184871245
                            ],
                            [
                                4.254562854766846,
                                50.82605366331441
                            ],
                            [
                                4.2452073097229,
                                50.81953358266302
                            ],
                            [
                                4.250292778015137,
                                50.819601363652225
                            ],
                            [
                                4.252159595489501,
                                50.81756789117831
                            ],
                            [
                                4.2563652992248535,
                                50.817622118260104
                            ],
                            [
                                4.261751174926758,
                                50.81592749219223
                            ]
                        ],
                        [
                            [
                                4.303078651428223,
                                50.805677083112066
                            ],
                            [
                                4.302713871002197,
                                50.80291071432312
                            ],
                            [
                                4.306447505950928,
                                50.80011705805953
                            ],
                            [
                                4.3064045906066895,
                                50.80171732819916
                            ],
                            [
                                4.303078651428223,
                                50.805677083112066
                            ]
                        ],
                        [
                            [
                                4.304602146148682,
                                50.80527027444502
                            ],
                            [
                                4.307734966278075,
                                50.80228690265132
                            ],
                            [
                                4.307971000671387,
                                50.79892360058499
                            ],
                            [
                                4.315288066864014,
                                50.797540236736154
                            ],
                            [
                                4.3184423446655265,
                                50.79608901692325
                            ],
                            [
                                4.320931434631348,
                                50.79332208045702
                            ],
                            [
                                4.318227767944336,
                                50.78938840843558
                            ],
                            [
                                4.319558143615723,
                                50.78685169264658
                            ],
                            [
                                4.321961402893066,
                                50.783310912668036
                            ],
                            [
                                4.325845241546631,
                                50.78082813690009
                            ],
                            [
                                4.326531887054443,
                                50.77959348348033
                            ],
                            [
                                4.332818984985352,
                                50.77540084562171
                            ],
                            [
                                4.334406852722168,
                                50.77533300049491
                            ],
                            [
                                4.335393905639648,
                                50.77453242056643
                            ],
                            [
                                4.340457916259766,
                                50.77375896592593
                            ],
                            [
                                4.347109794616699,
                                50.77563151831662
                            ],
                            [
                                4.354598522186279,
                                50.77386752173478
                            ],
                            [
                                4.361014366149902,
                                50.7729176498647
                            ],
                            [
                                4.363117218017578,
                                50.77359613174025
                            ],
                            [
                                4.38230037689209,
                                50.769511522136874
                            ],
                            [
                                4.402856826782227,
                                50.77023076498268
                            ],
                            [
                                4.450922012329102,
                                50.78293104295057
                            ],
                            [
                                4.463109970092773,
                                50.80051034986806
                            ],
                            [
                                4.447360038757324,
                                50.8078602291106
                            ],
                            [
                                4.457616806030273,
                                50.81632065091817
                            ],
                            [
                                4.472830295562744,
                                50.81970981303032
                            ],
                            [
                                4.473731517791748,
                                50.8240069169168
                            ],
                            [
                                4.465234279632568,
                                50.83564913191899
                            ],
                            [
                                4.467723369598389,
                                50.83774957160753
                            ],
                            [
                                4.466543197631835,
                                50.83994476882648
                            ],
                            [
                                4.4670796394348145,
                                50.843359314800274
                            ],
                            [
                                4.464290142059326,
                                50.84436195147445
                            ],
                            [
                                4.462316036224365,
                                50.84830454348922
                            ],
                            [
                                4.458646774291992,
                                50.84816906475569
                            ],
                            [
                                4.457874298095703,
                                50.84925288360706
                            ],
                            [
                                4.461114406585693,
                                50.850363771796324
                            ],
                            [
                                4.461350440979004,
                                50.852030054475705
                            ],
                            [
                                4.4538187980651855,
                                50.85350662921632
                            ],
                            [
                                4.450879096984863,
                                50.85352017555719
                            ],
                            [
                                4.448089599609375,
                                50.85457677802214
                            ],
                            [
                                4.444291591644287,
                                50.85723172472359
                            ],
                            [
                                4.439184665679932,
                                50.860496018338765
                            ],
                            [
                                4.427039623260498,
                                50.86286621281939
                            ],
                            [
                                4.421267509460448,
                                50.86787708448919
                            ],
                            [
                                4.428434371948242,
                                50.875825659697796
                            ],
                            [
                                4.434056282043457,
                                50.87666512341922
                            ],
                            [
                                4.433884620666504,
                                50.87834400549779
                            ],
                            [
                                4.43648099899292,
                                50.878736638547196
                            ],
                            [
                                4.429614543914795,
                                50.886507384350004
                            ],
                            [
                                4.427103996276855,
                                50.89048702054067
                            ],
                            [
                                4.432060718536377,
                                50.894737004816704
                            ],
                            [
                                4.423885345458984,
                                50.89894600188329
                            ],
                            [
                                4.423456192016602,
                                50.901584889602915
                            ],
                            [
                                4.419186115264893,
                                50.90357410609511
                            ],
                            [
                                4.418177604675293,
                                50.903655296799734
                            ],
                            [
                                4.410088062286377,
                                50.90733579337823
                            ],
                            [
                                4.41349983215332,
                                50.91165188738295
                            ],
                            [
                                4.411933422088623,
                                50.912017181044526
                            ],
                            [
                                4.404079914093018,
                                50.91140835668247
                            ],
                            [
                                4.398479461669922,
                                50.91124600217426
                            ],
                            [
                                4.388265609741211,
                                50.90973066612144
                            ],
                            [
                                4.3830084800720215,
                                50.901869068590926
                            ],
                            [
                                4.38004732131958,
                                50.90103005896071
                            ],
                            [
                                4.3772149085998535,
                                50.896834784026105
                            ],
                            [
                                4.364168643951416,
                                50.900827070488496
                            ],
                            [
                                4.348719120025635,
                                50.90123304654798
                            ],
                            [
                                4.342367649078369,
                                50.90216677805139
                            ],
                            [
                                4.332389831542969,
                                50.90103005896071
                            ],
                            [
                                4.325652122497559,
                                50.89868887149406
                            ],
                            [
                                4.320094585418701,
                                50.894994157027334
                            ],
                            [
                                4.310567378997803,
                                50.89192177196943
                            ],
                            [
                                4.29591178894043,
                                50.88909283294744
                            ],
                            [
                                4.299602508544922,
                                50.87941358431159
                            ],
                            [
                                4.288573265075684,
                                50.87336134013673
                            ],
                            [
                                4.286470413208008,
                                50.87084272507325
                            ],
                            [
                                4.280762672424316,
                                50.86714580164896
                            ],
                            [
                                4.279303550720215,
                                50.864843540025035
                            ],
                            [
                                4.280548095703125,
                                50.85999487607569
                            ],
                            [
                                4.284152984619141,
                                50.85830178805516
                            ],
                            [
                                4.288294315338135,
                                50.85754326468293
                            ],
                            [
                                4.290053844451904,
                                50.85572817652961
                            ],
                            [
                                4.2852044105529785,
                                50.85360145351973
                            ],
                            [
                                4.288723468780517,
                                50.848196160533874
                            ],
                            [
                                4.2865777015686035,
                                50.847627145887
                            ],
                            [
                                4.284367561340332,
                                50.847884558134595
                            ],
                            [
                                4.2835307121276855,
                                50.846936190199145
                            ],
                            [
                                4.2842817306518555,
                                50.84541876140069
                            ],
                            [
                                4.283766746520996,
                                50.837492103439295
                            ],
                            [
                                4.281620979309082,
                                50.831041384700114
                            ],
                            [
                                4.280977249145508,
                                50.825104851637505
                            ],
                            [
                                4.283552169799805,
                                50.82205496921722
                            ],
                            [
                                4.285118579864502,
                                50.81931668283643
                            ],
                            [
                                4.289109706878662,
                                50.81683581941126
                            ],
                            [
                                4.290740489959717,
                                50.81482934166636
                            ],
                            [
                                4.2995381355285645,
                                50.812009281288205
                            ],
                            [
                                4.306812286376953,
                                50.8121855400502
                            ],
                            [
                                4.303379058837891,
                                50.806789008728835
                            ],
                            [
                                4.304602146148682,
                                50.80527027444502
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "be--02",
            "properties": {
                "land": "be",
                "city": "Antwerp",
                "zone": "LEZ",
                "area_code": 1,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-5 (diesel)",
                "minEUd": 5,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Online Registration, EU-4 diesel after payment",
                "img_sign": "sign-be--white",
                "color": 1,
                "urlZone": "https://www.slimnaarantwerpen.be/en/LEZ",
                "urlPark": "https://www.slimnaarantwerpen.be/en/car-taxi/park-and-ride",
                "urlReg": "https://lez.antwerpen.be/?Taal=EN",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                4.363224506378174,
                                51.228038524509266
                            ],
                            [
                                4.362859725952148,
                                51.22713821834589
                            ],
                            [
                                4.366121292114258,
                                51.22712478080706
                            ],
                            [
                                4.366410970687865,
                                51.22006953118224
                            ],
                            [
                                4.364104270935059,
                                51.22003929207079
                            ],
                            [
                                4.361668825149536,
                                51.21990489577971
                            ],
                            [
                                4.361094832420349,
                                51.21868859150172
                            ],
                            [
                                4.361207485198975,
                                51.21617188199714
                            ],
                            [
                                4.361979961395264,
                                51.214572406327086
                            ],
                            [
                                4.364769458770752,
                                51.212529297764696
                            ],
                            [
                                4.370155334472656,
                                51.20863101020676
                            ],
                            [
                                4.370005130767822,
                                51.20785131309413
                            ],
                            [
                                4.374575614929199,
                                51.20317285320465
                            ],
                            [
                                4.375369548797607,
                                51.20337452075907
                            ],
                            [
                                4.377021789550781,
                                51.20236617415752
                            ],
                            [
                                4.380090236663818,
                                51.20201660884982
                            ],
                            [
                                4.385926723480225,
                                51.203871963618234
                            ],
                            [
                                4.386366605758667,
                                51.20350224308687
                            ],
                            [
                                4.385991096496582,
                                51.203209825655726
                            ],
                            [
                                4.3851542472839355,
                                51.20245692625557
                            ],
                            [
                                4.387128353118896,
                                51.20242667557607
                            ],
                            [
                                4.389504790306091,
                                51.2022485322828
                            ],
                            [
                                4.390094876289368,
                                51.202026692501605
                            ],
                            [
                                4.390266537666321,
                                51.20150233968088
                            ],
                            [
                                4.390266537666321,
                                51.20065193427555
                            ],
                            [
                                4.390443563461304,
                                51.20058134655996
                            ],
                            [
                                4.390132427215576,
                                51.19977798351
                            ],
                            [
                                4.39003050327301,
                                51.19901494370761
                            ],
                            [
                                4.390475749969482,
                                51.19852753281362
                            ],
                            [
                                4.39053475856781,
                                51.19803003223832
                            ],
                            [
                                4.398093223571777,
                                51.19431205379697
                            ],
                            [
                                4.400839805603027,
                                51.193827954962956
                            ],
                            [
                                4.402379393577575,
                                51.194634783527384
                            ],
                            [
                                4.403645396232605,
                                51.19424817993652
                            ],
                            [
                                4.405372738838196,
                                51.19424817993652
                            ],
                            [
                                4.406316876411438,
                                51.19398596000262
                            ],
                            [
                                4.406944513320923,
                                51.19355900896858
                            ],
                            [
                                4.4078510999679565,
                                51.19297740788788
                            ],
                            [
                                4.408848881721497,
                                51.192711818825664
                            ],
                            [
                                4.409814476966858,
                                51.192930341583384
                            ],
                            [
                                4.411177039146423,
                                51.19333712734143
                            ],
                            [
                                4.412587881088257,
                                51.19326316656163
                            ],
                            [
                                4.413086771965026,
                                51.192288217913095
                            ],
                            [
                                4.415929913520813,
                                51.19187806093787
                            ],
                            [
                                4.418526291847229,
                                51.19151496811011
                            ],
                            [
                                4.420945644378662,
                                51.19160910356239
                            ],
                            [
                                4.42324161529541,
                                51.19205288239011
                            ],
                            [
                                4.4281768798828125,
                                51.19433222447136
                            ],
                            [
                                4.43348228931427,
                                51.199354447559195
                            ],
                            [
                                4.434860944747925,
                                51.19796616353213
                            ],
                            [
                                4.436582922935486,
                                51.20110570986435
                            ],
                            [
                                4.437618255615234,
                                51.202998073943284
                            ],
                            [
                                4.438004493713379,
                                51.203414854163995
                            ],
                            [
                                4.4388628005981445,
                                51.20469206038642
                            ],
                            [
                                4.440064430236816,
                                51.2063456537265
                            ],
                            [
                                4.441308975219727,
                                51.20867133900869
                            ],
                            [
                                4.443840980529785,
                                51.2107146187492
                            ],
                            [
                                4.445611238479614,
                                51.21242848413996
                            ],
                            [
                                4.4460296630859375,
                                51.212945991738664
                            ],
                            [
                                4.445407390594482,
                                51.213537421872424
                            ],
                            [
                                4.445949196815491,
                                51.215462877579505
                            ],
                            [
                                4.446351528167725,
                                51.21561072774296
                            ],
                            [
                                4.4462281465530396,
                                51.21716312579432
                            ],
                            [
                                4.4471025466918945,
                                51.21796282557439
                            ],
                            [
                                4.447773098945618,
                                51.21851051110662
                            ],
                            [
                                4.449328780174255,
                                51.21834587012848
                            ],
                            [
                                4.450294375419617,
                                51.22002585245936
                            ],
                            [
                                4.450326561927795,
                                51.22073478660806
                            ],
                            [
                                4.449806213378906,
                                51.22198127467875
                            ],
                            [
                                4.448803067207336,
                                51.22356369473524
                            ],
                            [
                                4.446163773536682,
                                51.2259556999105
                            ],
                            [
                                4.4416844844818115,
                                51.22835765865996
                            ],
                            [
                                4.441008567810059,
                                51.22811914808259
                            ],
                            [
                                4.4406867027282715,
                                51.22843828167417
                            ],
                            [
                                4.441030025482178,
                                51.22863647931704
                            ],
                            [
                                4.440171718597412,
                                51.22914372702362
                            ],
                            [
                                4.439898133277893,
                                51.22899927957061
                            ],
                            [
                                4.431588649749756,
                                51.23489102400784
                            ],
                            [
                                4.427061080932617,
                                51.236771923791906
                            ],
                            [
                                4.425923824310303,
                                51.2385721415634
                            ],
                            [
                                4.418907165527344,
                                51.24061409435951
                            ],
                            [
                                4.418885707855225,
                                51.23682566265585
                            ],
                            [
                                4.406976699829102,
                                51.23690627083405
                            ],
                            [
                                4.4066548347473145,
                                51.23542843179257
                            ],
                            [
                                4.4008612632751465,
                                51.235334385883384
                            ],
                            [
                                4.387664794921875,
                                51.23689283614752
                            ],
                            [
                                4.381957054138184,
                                51.23700031353016
                            ],
                            [
                                4.374597072601318,
                                51.23564339314886
                            ],
                            [
                                4.3732452392578125,
                                51.23435360994592
                            ],
                            [
                                4.365992546081543,
                                51.227931026191825
                            ],
                            [
                                4.363224506378174,
                                51.228038524509266
                            ]
                        ],
                        [
                            [
                                4.420559406280518,
                                51.24386491112567
                            ],
                            [
                                4.41974401473999,
                                51.24370371901383
                            ],
                            [
                                4.419851303100586,
                                51.2412589027275
                            ],
                            [
                                4.420645236968994,
                                51.240788730851676
                            ],
                            [
                                4.420559406280518,
                                51.24386491112567
                            ]
                        ],
                        [
                            [
                                4.372344017028809,
                                51.2029577401734
                            ],
                            [
                                4.373009204864501,
                                51.20239306368668
                            ],
                            [
                                4.37380313873291,
                                51.202823294018735
                            ],
                            [
                                4.3732452392578125,
                                51.203347631802814
                            ],
                            [
                                4.372344017028809,
                                51.2029577401734
                            ]
                        ],
                        [
                            [
                                4.411960244178772,
                                51.19281267561184
                            ],
                            [
                                4.410495758056641,
                                51.1926983712375
                            ],
                            [
                                4.4101256132125854,
                                51.1924126090611
                            ],
                            [
                                4.412083625793457,
                                51.1925504475086
                            ],
                            [
                                4.411960244178772,
                                51.19281267561184
                            ]
                        ],
                        [
                            [
                                4.427533149719238,
                                51.237880275157096
                            ],
                            [
                                4.428547024726868,
                                51.236859249413946
                            ],
                            [
                                4.4318729639053345,
                                51.235509042418876
                            ],
                            [
                                4.431792497634888,
                                51.236261401453675
                            ],
                            [
                                4.427533149719238,
                                51.237880275157096
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "be--03",
            "properties": {
                "land": "be",
                "city": "Gent",
                "zone": "LEZ",
                "area_code": 1,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-5 (diesel)",
                "minEUd": 5,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Online Registration, EU-4 diesel after payment",
                "img_sign": "sign-be--white",
                "color": 1,
                "urlZone": "https://stad.gent/en/mobility-ghent/low-emission-zone-ghent",
                "urlPark": "https://visit.gent.be/en/good-know/practical-information/how-get-ghent/car/pr-car-parks",
                "urlReg": "https://lez.stad.gent/en/flows/controleertoegang",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                3.7380981445312496,
                                51.05679885705327
                            ],
                            [
                                3.7377440929412837,
                                51.05745972576722
                            ],
                            [
                                3.735920190811157,
                                51.061229194510695
                            ],
                            [
                                3.7349331378936763,
                                51.061728170948676
                            ],
                            [
                                3.733828067779541,
                                51.06414206209689
                            ],
                            [
                                3.7342572212219243,
                                51.06474214399236
                            ],
                            [
                                3.7332272529602046,
                                51.06706148851461
                            ],
                            [
                                3.7327659130096436,
                                51.06767501661053
                            ],
                            [
                                3.730920553207397,
                                51.067614338370014
                            ],
                            [
                                3.7291717529296875,
                                51.06764804851345
                            ],
                            [
                                3.7284421920776367,
                                51.06751994983776
                            ],
                            [
                                3.723721504211426,
                                51.06629962362568
                            ],
                            [
                                3.7221443653106685,
                                51.06587486165007
                            ],
                            [
                                3.7200951576232915,
                                51.06520737067035
                            ],
                            [
                                3.720695972442627,
                                51.064040923932765
                            ],
                            [
                                3.7203097343444824,
                                51.064027438827516
                            ],
                            [
                                3.719816207885742,
                                51.065146689194926
                            ],
                            [
                                3.7194514274597164,
                                51.06502532600538
                            ],
                            [
                                3.7182176113128658,
                                51.06431736772461
                            ],
                            [
                                3.713808059692383,
                                51.060959475277976
                            ],
                            [
                                3.712198734283447,
                                51.059685030650044
                            ],
                            [
                                3.712477684020996,
                                51.05946924819267
                            ],
                            [
                                3.710675239562988,
                                51.058430781050525
                            ],
                            [
                                3.7101817131042476,
                                51.056637010216086
                            ],
                            [
                                3.708658218383789,
                                51.05604356697307
                            ],
                            [
                                3.7032079696655273,
                                51.053811343031946
                            ],
                            [
                                3.7000697851181026,
                                51.05252995741452
                            ],
                            [
                                3.7018346786499023,
                                51.05113725336367
                            ],
                            [
                                3.7032616138458248,
                                51.05010871622226
                            ],
                            [
                                3.7040287256240845,
                                51.04995696290706
                            ],
                            [
                                3.7052035331726074,
                                51.04942750855818
                            ],
                            [
                                3.7067162990570073,
                                51.04958263593695
                            ],
                            [
                                3.708937168121338,
                                51.0488744415011
                            ],
                            [
                                3.709908127784729,
                                51.04806169073256
                            ],
                            [
                                3.711410164833069,
                                51.04701959523005
                            ],
                            [
                                3.7136578559875484,
                                51.04624728278961
                            ],
                            [
                                3.7144035100936894,
                                51.04587967103477
                            ],
                            [
                                3.714532256126404,
                                51.04522875499156
                            ],
                            [
                                3.7147468328475948,
                                51.044533984770936
                            ],
                            [
                                3.717439770698547,
                                51.043110685081544
                            ],
                            [
                                3.7197464704513554,
                                51.04195717315757
                            ],
                            [
                                3.7231475114822388,
                                51.040041335504874
                            ],
                            [
                                3.7257492542266846,
                                51.038749449549265
                            ],
                            [
                                3.727245926856994,
                                51.038705599002206
                            ],
                            [
                                3.729670643806457,
                                51.03857404711212
                            ],
                            [
                                3.73359203338623,
                                51.03853694266533
                            ],
                            [
                                3.7350726127624507,
                                51.03861789778365
                            ],
                            [
                                3.735678791999817,
                                51.03872583772139
                            ],
                            [
                                3.734326958656311,
                                51.04107684210247
                            ],
                            [
                                3.73335599899292,
                                51.042621626515725
                            ],
                            [
                                3.7321382761001582,
                                51.04341760885496
                            ],
                            [
                                3.730550408363342,
                                51.04613261521587
                            ],
                            [
                                3.730754256248474,
                                51.04630798903725
                            ],
                            [
                                3.730722069740296,
                                51.046692460091485
                            ],
                            [
                                3.730909824371338,
                                51.04668234247306
                            ],
                            [
                                3.730925917625427,
                                51.04603481029628
                            ],
                            [
                                3.7324494123458862,
                                51.043552519760375
                            ],
                            [
                                3.73360812664032,
                                51.04382908588816
                            ],
                            [
                                3.7319880723953243,
                                51.04664524451987
                            ],
                            [
                                3.731628656387329,
                                51.04732986559775
                            ],
                            [
                                3.7318432331085205,
                                51.04733661058267
                            ],
                            [
                                3.7323367595672607,
                                51.046453009195695
                            ],
                            [
                                3.7325298786163335,
                                51.046277635923374
                            ],
                            [
                                3.732873201370239,
                                51.04570429559173
                            ],
                            [
                                3.7336564064025874,
                                51.04430801356992
                            ],
                            [
                                3.733978271484375,
                                51.04386618609677
                            ],
                            [
                                3.735995292663574,
                                51.04055403221793
                            ],
                            [
                                3.7370038032531734,
                                51.0389181051126
                            ],
                            [
                                3.7372773885726933,
                                51.03900580576293
                            ],
                            [
                                3.7377601861953735,
                                51.03930938365527
                            ],
                            [
                                3.7380927801132198,
                                51.039957009844265
                            ],
                            [
                                3.7384092807769775,
                                51.04059113504938
                            ],
                            [
                                3.7387043237686157,
                                51.04104648556083
                            ],
                            [
                                3.7394499778747554,
                                51.041829003393055
                            ],
                            [
                                3.7404048442840576,
                                51.04279364080679
                            ],
                            [
                                3.741070032119751,
                                51.04358287465994
                            ],
                            [
                                3.7425827980041504,
                                51.04465877436469
                            ],
                            [
                                3.743934631347656,
                                51.04572453125366
                            ],
                            [
                                3.7454152107238765,
                                51.0470735554433
                            ],
                            [
                                3.745243549346924,
                                51.04772107309905
                            ],
                            [
                                3.7411880493164062,
                                51.05122830309205
                            ],
                            [
                                3.7404155731201167,
                                51.05160598894288
                            ],
                            [
                                3.739986419677734,
                                51.05257716698738
                            ],
                            [
                                3.7380981445312496,
                                51.05679885705327
                            ]
                        ],
                        [
                            [
                                3.695354461669922,
                                51.05610426038117
                            ],
                            [
                                3.695998191833496,
                                51.05614472260905
                            ],
                            [
                                3.6981439590454097,
                                51.0547082918678
                            ],
                            [
                                3.6975216865539546,
                                51.05439132697091
                            ],
                            [
                                3.698916435241699,
                                51.053366234152264
                            ],
                            [
                                3.700289726257324,
                                51.05387878339807
                            ],
                            [
                                3.700858354568482,
                                51.05427667955898
                            ],
                            [
                                3.7014484405517574,
                                51.0537101822986
                            ],
                            [
                                3.7016737461090083,
                                51.05377087876512
                            ],
                            [
                                3.7019205093383785,
                                51.053561812823304
                            ],
                            [
                                3.7032401561737056,
                                51.0541890078172
                            ],
                            [
                                3.7069308757781982,
                                51.055645685994236
                            ],
                            [
                                3.7092804908752437,
                                51.05667072835381
                            ],
                            [
                                3.709570169448852,
                                51.05713603614711
                            ],
                            [
                                3.7099778652191158,
                                51.05876120494046
                            ],
                            [
                                3.7112545967102046,
                                51.05948273462571
                            ],
                            [
                                3.711587190628052,
                                51.059914298409076
                            ],
                            [
                                3.713947534561157,
                                51.061633770413444
                            ],
                            [
                                3.7141299247741695,
                                51.061869771390626
                            ],
                            [
                                3.716522455215454,
                                51.0638251617774
                            ],
                            [
                                3.7170374393463135,
                                51.06400721116229
                            ],
                            [
                                3.7180995941162105,
                                51.06477585622761
                            ],
                            [
                                3.718947172164917,
                                51.065362445190644
                            ],
                            [
                                3.7216722965240474,
                                51.0662119746483
                            ],
                            [
                                3.7257707118988037,
                                51.06725700934014
                            ],
                            [
                                3.726886510848999,
                                51.06754017596803
                            ],
                            [
                                3.7268221378326416,
                                51.06780311485705
                            ],
                            [
                                3.725566864013672,
                                51.06772221074259
                            ],
                            [
                                3.721511363983154,
                                51.067108683272245
                            ],
                            [
                                3.7175309658050537,
                                51.066501889863105
                            ],
                            [
                                3.709301948547363,
                                51.065227597811166
                            ],
                            [
                                3.703165054321289,
                                51.06427017012091
                            ],
                            [
                                3.7026500701904297,
                                51.06415554716876
                            ],
                            [
                                3.702113628387451,
                                51.06428365515548
                            ],
                            [
                                3.701813220977783,
                                51.06473540154238
                            ],
                            [
                                3.700740337371826,
                                51.06496464429129
                            ],
                            [
                                3.6996781826019287,
                                51.065996222612284
                            ],
                            [
                                3.698980808258056,
                                51.06562539645078
                            ],
                            [
                                3.69714617729187,
                                51.06476911378252
                            ],
                            [
                                3.6958909034729004,
                                51.063852132101815
                            ],
                            [
                                3.6941421031951904,
                                51.061701199386825
                            ],
                            [
                                3.693294525146485,
                                51.05952319390127
                            ],
                            [
                                3.6934340000152583,
                                51.057722721917344
                            ],
                            [
                                3.695354461669922,
                                51.05610426038117
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--01",
            "properties": {
                "land": "de",
                "city": "Aachen",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "http://www.aachen.de/DE/stadt_buerger/umwelt/luft-stadtklima/luftreinhalteplan_umweltzone/umweltzone/index.html",
                "urlPark": "https://www.aachen.de/DE/stadt_buerger/allgemeines/anreise/ihre_anreise/auto/p_und_r.html",
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            6.110726594924927,
                            50.78080778573436
                        ],
                        [
                            6.114792823791504,
                            50.78071959724704
                        ],
                        [
                            6.120007038116455,
                            50.78204919063711
                        ],
                        [
                            6.124523878097533,
                            50.78483714381574
                        ],
                        [
                            6.122399568557739,
                            50.78727901002694
                        ],
                        [
                            6.121337413787841,
                            50.78870337308619
                        ],
                        [
                            6.118011474609375,
                            50.790839836305054
                        ],
                        [
                            6.118805408477783,
                            50.79155875117699
                        ],
                        [
                            6.110157966613769,
                            50.79604154635516
                        ],
                        [
                            6.105523109436035,
                            50.79854385772381
                        ],
                        [
                            6.103248596191406,
                            50.79632636904049
                        ],
                        [
                            6.100233793258667,
                            50.793349208080215
                        ],
                        [
                            6.0966503620147705,
                            50.79411555693055
                        ],
                        [
                            6.095073223114014,
                            50.7917689977763
                        ],
                        [
                            6.093646287918091,
                            50.79215557776516
                        ],
                        [
                            6.094740629196166,
                            50.79431222850321
                        ],
                        [
                            6.096038818359375,
                            50.79410199334331
                        ],
                        [
                            6.096618175506592,
                            50.79526844745935
                        ],
                        [
                            6.099203824996948,
                            50.794732695986
                        ],
                        [
                            6.099933385848999,
                            50.795987294218286
                        ],
                        [
                            6.101821660995483,
                            50.79739104799252
                        ],
                        [
                            6.103001832962036,
                            50.798950724957
                        ],
                        [
                            6.094472408294678,
                            50.799147376183214
                        ],
                        [
                            6.095448732376099,
                            50.800795145318254
                        ],
                        [
                            6.094987392425536,
                            50.801730889576326
                        ],
                        [
                            6.091575622558593,
                            50.80360232187604
                        ],
                        [
                            6.082606315612793,
                            50.80327686077341
                        ],
                        [
                            6.081490516662598,
                            50.80018486722819
                        ],
                        [
                            6.0802459716796875,
                            50.799723762940815
                        ],
                        [
                            6.077885627746582,
                            50.7994796470643
                        ],
                        [
                            6.074988842010498,
                            50.799981439426986
                        ],
                        [
                            6.074988842010498,
                            50.79570246946651
                        ],
                        [
                            6.073958873748779,
                            50.794054520756994
                        ],
                        [
                            6.0693347454071045,
                            50.79121964176602
                        ],
                        [
                            6.068508625030518,
                            50.7894087558667
                        ],
                        [
                            6.06769323348999,
                            50.78866946018438
                        ],
                        [
                            6.066480875015258,
                            50.789869961929924
                        ],
                        [
                            6.0661375522613525,
                            50.79222339848618
                        ],
                        [
                            6.060633659362793,
                            50.79150449383655
                        ],
                        [
                            6.060322523117065,
                            50.78995135076282
                        ],
                        [
                            6.056857109069824,
                            50.79115860181076
                        ],
                        [
                            6.053488254547119,
                            50.7893951909136
                        ],
                        [
                            6.052350997924805,
                            50.78626836420333
                        ],
                        [
                            6.052136421203612,
                            50.77731403767734
                        ],
                        [
                            6.050859689712524,
                            50.77514303361635
                        ],
                        [
                            6.047297716140747,
                            50.771940618673796
                        ],
                        [
                            6.047576665878296,
                            50.77029183226216
                        ],
                        [
                            6.05096697807312,
                            50.76999328036024
                        ],
                        [
                            6.050945520401,
                            50.76934188777857
                        ],
                        [
                            6.055365800857544,
                            50.769097613222
                        ],
                        [
                            6.05819821357727,
                            50.76943009772166
                        ],
                        [
                            6.058638095855713,
                            50.76894833369839
                        ],
                        [
                            6.060483455657959,
                            50.76888047921198
                        ],
                        [
                            6.062028408050537,
                            50.76805264655319
                        ],
                        [
                            6.060998439788818,
                            50.764381507185746
                        ],
                        [
                            6.061652898788451,
                            50.76239313585011
                        ],
                        [
                            6.066566705703735,
                            50.760995117702294
                        ],
                        [
                            6.063669919967651,
                            50.758592600606306
                        ],
                        [
                            6.0659873485565186,
                            50.75751346380228
                        ],
                        [
                            6.068283319473267,
                            50.75465600655778
                        ],
                        [
                            6.070407629013061,
                            50.75310842719681
                        ],
                        [
                            6.071512699127197,
                            50.75136394525609
                        ],
                        [
                            6.074398756027222,
                            50.750230336538785
                        ],
                        [
                            6.078840494155884,
                            50.75085484294067
                        ],
                        [
                            6.082198619842529,
                            50.75028464177403
                        ],
                        [
                            6.08518123626709,
                            50.75082769066166
                        ],
                        [
                            6.0873377323150635,
                            50.750325370659134
                        ],
                        [
                            6.0890114307403564,
                            50.75071908138812
                        ],
                        [
                            6.0910069942474365,
                            50.75299303545776
                        ],
                        [
                            6.097283363342285,
                            50.75250431435045
                        ],
                        [
                            6.099482774734497,
                            50.75310842719681
                        ],
                        [
                            6.1013710498809814,
                            50.753420662240856
                        ],
                        [
                            6.102958917617798,
                            50.75430984193289
                        ],
                        [
                            6.104997396469115,
                            50.75667864278905
                        ],
                        [
                            6.11063003540039,
                            50.75870797854055
                        ],
                        [
                            6.114685535430908,
                            50.75929165078965
                        ],
                        [
                            6.118891239166259,
                            50.762189543572646
                        ],
                        [
                            6.122485399246216,
                            50.764245784866866
                        ],
                        [
                            6.12537145614624,
                            50.76510761490421
                        ],
                        [
                            6.127839088439941,
                            50.76758443962954
                        ],
                        [
                            6.132817268371582,
                            50.7701289860064
                        ],
                        [
                            6.13686203956604,
                            50.77046824839948
                        ],
                        [
                            6.141185760498047,
                            50.77236807233095
                        ],
                        [
                            6.143674850463867,
                            50.77411855607777
                        ],
                        [
                            6.143513917922973,
                            50.77571293193732
                        ],
                        [
                            6.14084243774414,
                            50.777605758667214
                        ],
                        [
                            6.134984493255614,
                            50.77822311569196
                        ],
                        [
                            6.133171319961548,
                            50.77976308066167
                        ],
                        [
                            6.129212379455566,
                            50.78143866775428
                        ],
                        [
                            6.12661600112915,
                            50.78329056258283
                        ],
                        [
                            6.12513542175293,
                            50.784314839208136
                        ],
                        [
                            6.120672225952148,
                            50.7815200712659
                        ],
                        [
                            6.117582321166992,
                            50.7805974898362
                        ],
                        [
                            6.114674806594849,
                            50.780163327570904
                        ],
                        [
                            6.110608577728271,
                            50.78021081426499
                        ],
                        [
                            6.1090850830078125,
                            50.7796409707531
                        ],
                        [
                            6.107196807861327,
                            50.77961383517457
                        ],
                        [
                            6.106467247009277,
                            50.7804278956811
                        ],
                        [
                            6.107432842254639,
                            50.78115375434768
                        ],
                        [
                            6.110726594924927,
                            50.78080778573436
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--02",
            "properties": {
                "land": "de",
                "city": "Augsburg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.augsburg.de/umwelt-soziales/umwelt/luft-laerm-strahlen/umweltzone",
                "urlPark": "https://www.augsburg.de/buergerservice-rathaus/verkehr/parken",
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            10.902128219604492,
                            48.352399073012755
                        ],
                        [
                            10.901613235473633,
                            48.35295519581988
                        ],
                        [
                            10.901548862457275,
                            48.3543668649171
                        ],
                        [
                            10.9019136428833,
                            48.35871570062473
                        ],
                        [
                            10.902793407440186,
                            48.358972341623584
                        ],
                        [
                            10.904316902160645,
                            48.35875847421431
                        ],
                        [
                            10.905346870422363,
                            48.35942858909587
                        ],
                        [
                            10.905089378356934,
                            48.36076879241444
                        ],
                        [
                            10.904402732849121,
                            48.36118225227881
                        ],
                        [
                            10.90444564819336,
                            48.362051932764004
                        ],
                        [
                            10.903351306915283,
                            48.36587264815649
                        ],
                        [
                            10.908758640289307,
                            48.36812502517932
                        ],
                        [
                            10.91017484664917,
                            48.36823906693597
                        ],
                        [
                            10.910217761993408,
                            48.36888054705881
                        ],
                        [
                            10.909316539764404,
                            48.369322450887296
                        ],
                        [
                            10.909101963043213,
                            48.37010646437185
                        ],
                        [
                            10.909144878387451,
                            48.37074792097207
                        ],
                        [
                            10.909724235534668,
                            48.37183125154972
                        ],
                        [
                            10.911870002746582,
                            48.37411187216734
                        ],
                        [
                            10.911076068878174,
                            48.375081104997854
                        ],
                        [
                            10.911269187927246,
                            48.37580801751255
                        ],
                        [
                            10.911698341369629,
                            48.37603606635883
                        ],
                        [
                            10.908286571502686,
                            48.37757536935313
                        ],
                        [
                            10.904767513275146,
                            48.380126519045795
                        ],
                        [
                            10.903522968292236,
                            48.38055407352444
                        ],
                        [
                            10.90294361114502,
                            48.38079635280162
                        ],
                        [
                            10.902278423309326,
                            48.3814804292421
                        ],
                        [
                            10.901098251342773,
                            48.38110988897801
                        ],
                        [
                            10.899145603179932,
                            48.379485180594585
                        ],
                        [
                            10.898244380950926,
                            48.37929990353772
                        ],
                        [
                            10.897150039672852,
                            48.37985573268528
                        ],
                        [
                            10.894124507904053,
                            48.382207250387204
                        ],
                        [
                            10.88970422744751,
                            48.38340434494374
                        ],
                        [
                            10.886635780334473,
                            48.38528543665075
                        ],
                        [
                            10.885498523712158,
                            48.385527693408136
                        ],
                        [
                            10.881121158599854,
                            48.385499192672995
                        ],
                        [
                            10.880670547485352,
                            48.38272029435882
                        ],
                        [
                            10.881657600402832,
                            48.37944242441055
                        ],
                        [
                            10.8829665184021,
                            48.37872981605639
                        ],
                        [
                            10.883524417877197,
                            48.377048020790575
                        ],
                        [
                            10.883631706237793,
                            48.37533766354106
                        ],
                        [
                            10.884554386138916,
                            48.37426866108203
                        ],
                        [
                            10.883996486663818,
                            48.3737412782679
                        ],
                        [
                            10.882365703582764,
                            48.373755531929305
                        ],
                        [
                            10.880177021026611,
                            48.372429924349575
                        ],
                        [
                            10.878932476043701,
                            48.36984987946931
                        ],
                        [
                            10.88193655014038,
                            48.366229043559805
                        ],
                        [
                            10.88421106338501,
                            48.361196509455645
                        ],
                        [
                            10.889167785644531,
                            48.36103968029088
                        ],
                        [
                            10.890305042266846,
                            48.360269784660616
                        ],
                        [
                            10.88597059249878,
                            48.355293697095306
                        ],
                        [
                            10.890970230102537,
                            48.3536111277396
                        ],
                        [
                            10.893588066101074,
                            48.353382978464374
                        ],
                        [
                            10.902128219604492,
                            48.352399073012755
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Magdeburg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.magdeburg.de/B%C3%BCrger-Stadt/Leben-in-Magdeburg/Verkehr/Umweltzone/",
                "urlPark": "https://www.magdeburg-tourist.de/Tourismus-Freizeit/Reiseplanung/Unterwegs-in-Magdeburg/",
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                11.603236198425293,
                                52.138914257801886
                            ],
                            [
                                11.603665351867676,
                                52.135885140475864
                            ],
                            [
                                11.605693101882935,
                                52.12977359854882
                            ],
                            [
                                11.60678744316101,
                                52.122607274789075
                            ],
                            [
                                11.607173681259155,
                                52.11840442754966
                            ],
                            [
                                11.607398986816406,
                                52.1150247347145
                            ],
                            [
                                11.608182191848755,
                                52.11353574389136
                            ],
                            [
                                11.608976125717163,
                                52.11298888964272
                            ],
                            [
                                11.609920263290405,
                                52.113114073737435
                            ],
                            [
                                11.61082148551941,
                                52.11279781850466
                            ],
                            [
                                11.61356806755066,
                                52.114089179915915
                            ],
                            [
                                11.614576578140259,
                                52.11577579971101
                            ],
                            [
                                11.614437103271484,
                                52.117627055007944
                            ],
                            [
                                11.614630222320555,
                                52.11856912338166
                            ],
                            [
                                11.615885496139526,
                                52.120808926281825
                            ],
                            [
                                11.61679744720459,
                                52.12266655976808
                            ],
                            [
                                11.617323160171509,
                                52.123384560474555
                            ],
                            [
                                11.618256568908691,
                                52.12477441888138
                            ],
                            [
                                11.618567705154419,
                                52.125946872361126
                            ],
                            [
                                11.62004828453064,
                                52.12806116274092
                            ],
                            [
                                11.621099710464478,
                                52.129951424649136
                            ],
                            [
                                11.622333526611328,
                                52.13161768658723
                            ],
                            [
                                11.62523031234741,
                                52.1351278289581
                            ],
                            [
                                11.62779450416565,
                                52.13794627966071
                            ],
                            [
                                11.62853479385376,
                                52.139131556126
                            ],
                            [
                                11.628749370574951,
                                52.140125847055344
                            ],
                            [
                                11.626893281936646,
                                52.140718460230794
                            ],
                            [
                                11.62528395652771,
                                52.14090282716611
                            ],
                            [
                                11.621818542480467,
                                52.1403233856566
                            ],
                            [
                                11.61803126335144,
                                52.13987563023602
                            ],
                            [
                                11.613653898239136,
                                52.13952005654835
                            ],
                            [
                                11.604620218276978,
                                52.139026199495184
                            ],
                            [
                                11.603236198425293,
                                52.138914257801886
                            ]
                        ],
                        [
                            [
                                11.619951725006104,
                                52.12080563253667
                            ],
                            [
                                11.618953943252563,
                                52.1225216407913
                            ],
                            [
                                11.618401408195496,
                                52.12261386201285
                            ],
                            [
                                11.616561412811278,
                                52.12082539500397
                            ],
                            [
                                11.615515351295471,
                                52.119115909179925
                            ],
                            [
                                11.615016460418701,
                                52.11766328877509
                            ],
                            [
                                11.615231037139893,
                                52.11615132746402
                            ],
                            [
                                11.615129113197327,
                                52.11515650141862
                            ],
                            [
                                11.614587306976317,
                                52.11422753784855
                            ],
                            [
                                11.612859964370726,
                                52.11303171581993
                            ],
                            [
                                11.612758040428162,
                                52.11265945613489
                            ],
                            [
                                11.615150570869446,
                                52.11279781850466
                            ],
                            [
                                11.619908809661865,
                                52.1132096087311
                            ],
                            [
                                11.62376582622528,
                                52.113275494814374
                            ],
                            [
                                11.626142263412476,
                                52.11324914039275
                            ],
                            [
                                11.629672050476072,
                                52.11431648200706
                            ],
                            [
                                11.630213856697083,
                                52.11513673643781
                            ],
                            [
                                11.630396246910095,
                                52.11654002829752
                            ],
                            [
                                11.630240678787231,
                                52.1179893913541
                            ],
                            [
                                11.631554961204529,
                                52.1196561006108
                            ],
                            [
                                11.633003354072569,
                                52.12136885942051
                            ],
                            [
                                11.634719967842102,
                                52.1226731469831
                            ],
                            [
                                11.636967658996582,
                                52.12462950675882
                            ],
                            [
                                11.638051271438599,
                                52.12590405859132
                            ],
                            [
                                11.637724041938782,
                                52.126002859536484
                            ],
                            [
                                11.639596223831177,
                                52.127975539222916
                            ],
                            [
                                11.639295816421509,
                                52.12825875484679
                            ],
                            [
                                11.640577912330627,
                                52.129556254573544
                            ],
                            [
                                11.639365553855896,
                                52.13003045824366
                            ],
                            [
                                11.640170216560364,
                                52.13102165933716
                            ],
                            [
                                11.642825603485106,
                                52.13024779990579
                            ],
                            [
                                11.645320057868958,
                                52.13220382715619
                            ],
                            [
                                11.648780107498169,
                                52.134890754880445
                            ],
                            [
                                11.650394797325134,
                                52.13649098042036
                            ],
                            [
                                11.651156544685364,
                                52.13757093553797
                            ],
                            [
                                11.64821147918701,
                                52.13815370531898
                            ],
                            [
                                11.643791198730469,
                                52.138515874787615
                            ],
                            [
                                11.640368700027466,
                                52.138710128107455
                            ],
                            [
                                11.639901995658875,
                                52.138298573459814
                            ],
                            [
                                11.638855934143066,
                                52.138400639367006
                            ],
                            [
                                11.63874864578247,
                                52.13888462613011
                            ],
                            [
                                11.633046269416809,
                                52.13932909913711
                            ],
                            [
                                11.630326509475708,
                                52.139687966699036
                            ],
                            [
                                11.629317998886108,
                                52.13843685624561
                            ],
                            [
                                11.62777304649353,
                                52.13708034941604
                            ],
                            [
                                11.62480115890503,
                                52.13372512231407
                            ],
                            [
                                11.622961163520813,
                                52.13150572627553
                            ],
                            [
                                11.623615622520447,
                                52.13134107824724
                            ],
                            [
                                11.624366641044617,
                                52.13212479741688
                            ],
                            [
                                11.6255521774292,
                                52.13200625254508
                            ],
                            [
                                11.624093055725098,
                                52.13005021662041
                            ],
                            [
                                11.621700525283812,
                                52.126348661119536
                            ],
                            [
                                11.62180781364441,
                                52.12477112542927
                            ],
                            [
                                11.622065305709837,
                                52.12350312828574
                            ],
                            [
                                11.619447469711304,
                                52.12242612575352
                            ],
                            [
                                11.620488166809082,
                                52.12090444478549
                            ],
                            [
                                11.619951725006104,
                                52.12080563253667
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--04-000",
            "properties": {
                "land": "de",
                "city": "Berlin",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.berlin.de/sen/uvk/umwelt/luft/luftreinhaltung/umweltzone/",
                "urlPark": "https://viz.berlin.de/verkehr-in-berlin/parken/",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            13.435335159301758,
                            52.468037365593126
                        ],
                        [
                            13.4477698802948,
                            52.47006349410613
                        ],
                        [
                            13.452351093292236,
                            52.47076281358549
                        ],
                        [
                            13.45451831817627,
                            52.471775826769274
                        ],
                        [
                            13.456073999404907,
                            52.47312865317433
                        ],
                        [
                            13.457254171371458,
                            52.47810826453738
                        ],
                        [
                            13.458831310272217,
                            52.48525648510012
                        ],
                        [
                            13.453627824783325,
                            52.48891508825123
                        ],
                        [
                            13.45399260520935,
                            52.48922866864714
                        ],
                        [
                            13.457254171371458,
                            52.49161311310171
                        ],
                        [
                            13.457651138305664,
                            52.492031194150734
                        ],
                        [
                            13.460140228271484,
                            52.493932106299624
                        ],
                        [
                            13.46123456954956,
                            52.49489886171213
                        ],
                        [
                            13.463315963745117,
                            52.49642733703723
                        ],
                        [
                            13.464528322219847,
                            52.49760958279067
                        ],
                        [
                            13.465343713760376,
                            52.49887017445892
                        ],
                        [
                            13.46574068069458,
                            52.499725788626726
                        ],
                        [
                            13.466330766677856,
                            52.50116266045369
                        ],
                        [
                            13.466341495513916,
                            52.50159371284434
                        ],
                        [
                            13.46624493598938,
                            52.50193332690268
                        ],
                        [
                            13.466577529907227,
                            52.50260601623459
                        ],
                        [
                            13.466888666152954,
                            52.50281500499925
                        ],
                        [
                            13.46775770187378,
                            52.50274969611701
                        ],
                        [
                            13.469281196594238,
                            52.502527645191705
                        ],
                        [
                            13.470032215118406,
                            52.50395789473226
                        ],
                        [
                            13.470890522003174,
                            52.50517912120721
                        ],
                        [
                            13.469946384429932,
                            52.50613909894946
                        ],
                        [
                            13.475267887115479,
                            52.51202258610058
                        ],
                        [
                            13.475442230701447,
                            52.51272613575023
                        ],
                        [
                            13.475117683410645,
                            52.513955280150256
                        ],
                        [
                            13.470954895019531,
                            52.52068639882502
                        ],
                        [
                            13.469098806381226,
                            52.52255343236176
                        ],
                        [
                            13.46599817276001,
                            52.5237741421568
                        ],
                        [
                            13.460408449172974,
                            52.52498176285224
                        ],
                        [
                            13.458305597305298,
                            52.52598700016394
                        ],
                        [
                            13.456149101257324,
                            52.52803004146661
                        ],
                        [
                            13.448735475540161,
                            52.53537898246709
                        ],
                        [
                            13.447651863098145,
                            52.53647534425318
                        ],
                        [
                            13.442351818084717,
                            52.538876802982095
                        ],
                        [
                            13.435399532318115,
                            52.54170878931385
                        ],
                        [
                            13.427642583847046,
                            52.54410343703967
                        ],
                        [
                            13.42803955078125,
                            52.54531702333679
                        ],
                        [
                            13.424338102340698,
                            52.54687636722501
                        ],
                        [
                            13.423393964767456,
                            52.54607386661994
                        ],
                        [
                            13.420411348342896,
                            52.547698425801535
                        ],
                        [
                            13.415647745132446,
                            52.54927073304618
                        ],
                        [
                            13.410948514938354,
                            52.549662170075635
                        ],
                        [
                            13.404779434204102,
                            52.54950559568282
                        ],
                        [
                            13.392540514469147,
                            52.54949580976472
                        ],
                        [
                            13.391625881195068,
                            52.54947949989637
                        ],
                        [
                            13.387516736984253,
                            52.54919244522132
                        ],
                        [
                            13.386894464492798,
                            52.54887276848708
                        ],
                        [
                            13.382796049118042,
                            52.54832474581089
                        ],
                        [
                            13.380800485610962,
                            52.54811597346755
                        ],
                        [
                            13.377946615219116,
                            52.54737873818473
                        ],
                        [
                            13.376133441925049,
                            52.54668063672307
                        ],
                        [
                            13.370243310928345,
                            52.544168684468275
                        ],
                        [
                            13.370018005371094,
                            52.54325521164193
                        ],
                        [
                            13.370178937911987,
                            52.542048093392786
                        ],
                        [
                            13.370082378387451,
                            52.541656588453556
                        ],
                        [
                            13.36987853050232,
                            52.54148041009175
                        ],
                        [
                            13.368140459060669,
                            52.54073653810232
                        ],
                        [
                            13.36463212966919,
                            52.53913782319016
                        ],
                        [
                            13.362808227539062,
                            52.53820466880328
                        ],
                        [
                            13.360737562179564,
                            52.5373237006569
                        ],
                        [
                            13.360211849212646,
                            52.537082247041546
                        ],
                        [
                            13.359997272491453,
                            52.53729107186755
                        ],
                        [
                            13.35683226585388,
                            52.538165515038806
                        ],
                        [
                            13.35559844970703,
                            52.53771524423765
                        ],
                        [
                            13.353753089904785,
                            52.53729107186755
                        ],
                        [
                            13.344172239303587,
                            52.536436188946496
                        ],
                        [
                            13.334355354309082,
                            52.535124465994386
                        ],
                        [
                            13.310623168945312,
                            52.53175687838886
                        ],
                        [
                            13.29345703125,
                            52.52951167650484
                        ],
                        [
                            13.28822135925293,
                            52.52930281468008
                        ],
                        [
                            13.284187316894531,
                            52.52818016535406
                        ],
                        [
                            13.28249216079712,
                            52.52562146198514
                        ],
                        [
                            13.282996416091919,
                            52.52257954415627
                        ],
                        [
                            13.28446626663208,
                            52.517761655324506
                        ],
                        [
                            13.286000490188599,
                            52.51576383914422
                        ],
                        [
                            13.286815881729126,
                            52.514320915408625
                        ],
                        [
                            13.287084102630615,
                            52.513113001639496
                        ],
                        [
                            13.28669786453247,
                            52.511669990855886
                        ],
                        [
                            13.28592538833618,
                            52.510083277245506
                        ],
                        [
                            13.285142183303831,
                            52.50880994711401
                        ],
                        [
                            13.283833265304565,
                            52.50699457165167
                        ],
                        [
                            13.283371925354004,
                            52.506060734205384
                        ],
                        [
                            13.28320026397705,
                            52.5042060611477
                        ],
                        [
                            13.282384872436523,
                            52.5031611404552
                        ],
                        [
                            13.28371524810791,
                            52.500679354262395
                        ],
                        [
                            13.28847885131836,
                            52.49726993533137
                        ],
                        [
                            13.292931318283081,
                            52.49418032931192
                        ],
                        [
                            13.29471230506897,
                            52.492534192645266
                        ],
                        [
                            13.30565571784973,
                            52.48436138764062
                        ],
                        [
                            13.308316469192505,
                            52.48258420585907
                        ],
                        [
                            13.30994188785553,
                            52.48202228785694
                        ],
                        [
                            13.310853838920593,
                            52.481486498891414
                        ],
                        [
                            13.31245243549347,
                            52.48048351054357
                        ],
                        [
                            13.312908411026001,
                            52.480774281506164
                        ],
                        [
                            13.313959836959837,
                            52.480143730804116
                        ],
                        [
                            13.317452073097227,
                            52.47887280063005
                        ],
                        [
                            13.318085074424744,
                            52.478869533410034
                        ],
                        [
                            13.319115042686462,
                            52.4790100236507
                        ],
                        [
                            13.320520520210266,
                            52.47893814497896
                        ],
                        [
                            13.320525884628296,
                            52.478167075477685
                        ],
                        [
                            13.321604132652281,
                            52.478078859037744
                        ],
                        [
                            13.328062891960144,
                            52.47802984982799
                        ],
                        [
                            13.328046798706055,
                            52.478853197306435
                        ],
                        [
                            13.329302072525024,
                            52.478853197306435
                        ],
                        [
                            13.334468007087706,
                            52.47881399063302
                        ],
                        [
                            13.335615992546082,
                            52.478804188959195
                        ],
                        [
                            13.336710333824158,
                            52.47890220559907
                        ],
                        [
                            13.33814799785614,
                            52.479055764562496
                        ],
                        [
                            13.340256214141846,
                            52.47921259018475
                        ],
                        [
                            13.342466354370117,
                            52.479081902205046
                        ],
                        [
                            13.34383964538574,
                            52.47888586950758
                        ],
                        [
                            13.345320224761961,
                            52.47838924943168
                        ],
                        [
                            13.346199989318848,
                            52.47806252264056
                        ],
                        [
                            13.348968029022217,
                            52.47865062911774
                        ],
                        [
                            13.353710174560545,
                            52.47968306369759
                        ],
                        [
                            13.357272148132324,
                            52.47983988708387
                        ],
                        [
                            13.358752727508545,
                            52.47963078911125
                        ],
                        [
                            13.361477851867674,
                            52.478781318378495
                        ],
                        [
                            13.363709449768066,
                            52.477644308807626
                        ],
                        [
                            13.365018367767334,
                            52.47604983210253
                        ],
                        [
                            13.366563320159912,
                            52.4733835288559
                        ],
                        [
                            13.36984634399414,
                            52.47164511670095
                        ],
                        [
                            13.383278846740723,
                            52.47108305898344
                        ],
                        [
                            13.391046524047852,
                            52.47018113741278
                        ],
                        [
                            13.40555191040039,
                            52.46489995032684
                        ],
                        [
                            13.41799736022949,
                            52.4657889073562
                        ],
                        [
                            13.435335159301758,
                            52.468037365593126
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--05",
            "properties": {
                "land": "de",
                "city": "Bonn",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.bonn.de/themen-entdecken/umwelt-natur/umweltzone.php",
                "urlPark": "https://www.bonn.de/themen-entdecken/verkehr-mobilitaet/parken.php",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            7.104206085205078,
                            50.747521783005354
                        ],
                        [
                            7.102360725402832,
                            50.75111957683567
                        ],
                        [
                            7.098498344421387,
                            50.755558737555205
                        ],
                        [
                            7.096245288848877,
                            50.75451346849286
                        ],
                        [
                            7.09339141845703,
                            50.753766833441944
                        ],
                        [
                            7.085859775543213,
                            50.75178479898969
                        ],
                        [
                            7.087147235870361,
                            50.747073736488936
                        ],
                        [
                            7.086760997772217,
                            50.74571599358841
                        ],
                        [
                            7.08592414855957,
                            50.745172885403285
                        ],
                        [
                            7.084722518920898,
                            50.74480628381684
                        ],
                        [
                            7.082619667053223,
                            50.744684082650146
                        ],
                        [
                            7.079808712005614,
                            50.743557101301725
                        ],
                        [
                            7.078671455383301,
                            50.74240293566217
                        ],
                        [
                            7.0778560638427725,
                            50.742199256419646
                        ],
                        [
                            7.078070640563965,
                            50.741316636131074
                        ],
                        [
                            7.077662944793701,
                            50.739524186809724
                        ],
                        [
                            7.076482772827148,
                            50.740325365819395
                        ],
                        [
                            7.07442283630371,
                            50.739157540996395
                        ],
                        [
                            7.073457241058349,
                            50.738424240758064
                        ],
                        [
                            7.073307037353516,
                            50.73766376912356
                        ],
                        [
                            7.074637413024902,
                            50.73384764465462
                        ],
                        [
                            7.075645923614502,
                            50.73129433439736
                        ],
                        [
                            7.077469825744629,
                            50.72996329833324
                        ],
                        [
                            7.081310749053955,
                            50.72806175263577
                        ],
                        [
                            7.082211971282958,
                            50.72766785137705
                        ],
                        [
                            7.083477973937988,
                            50.72564396165348
                        ],
                        [
                            7.086181640625,
                            50.72417692653067
                        ],
                        [
                            7.0905160903930655,
                            50.72302228325225
                        ],
                        [
                            7.0932841300964355,
                            50.72338905537716
                        ],
                        [
                            7.0937347412109375,
                            50.72288644099596
                        ],
                        [
                            7.099142074584961,
                            50.722030625725594
                        ],
                        [
                            7.10439920425415,
                            50.7209302688393
                        ],
                        [
                            7.1075963973999015,
                            50.72046838294099
                        ],
                        [
                            7.110514640808105,
                            50.71993856703988
                        ],
                        [
                            7.111120820045471,
                            50.71996913350468
                        ],
                        [
                            7.113132476806641,
                            50.71953780487921
                        ],
                        [
                            7.115219235420226,
                            50.71740148095959
                        ],
                        [
                            7.116501331329346,
                            50.71610741227584
                        ],
                        [
                            7.116833925247192,
                            50.71620930874374
                        ],
                        [
                            7.1180033683776855,
                            50.71518354085728
                        ],
                        [
                            7.1209537982940665,
                            50.71220800766715
                        ],
                        [
                            7.1241295337677,
                            50.70912357983414
                        ],
                        [
                            7.127155065536499,
                            50.706412648608975
                        ],
                        [
                            7.128678560256958,
                            50.70466642694878
                        ],
                        [
                            7.130352258682251,
                            50.705597300285575
                        ],
                        [
                            7.131317853927612,
                            50.70594382505549
                        ],
                        [
                            7.1308135986328125,
                            50.70654853861769
                        ],
                        [
                            7.133077383041382,
                            50.706969795141774
                        ],
                        [
                            7.135705947875977,
                            50.708838226034324
                        ],
                        [
                            7.137347459793091,
                            50.71107345098074
                        ],
                        [
                            7.137765884399413,
                            50.71160336710217
                        ],
                        [
                            7.1397265791893005,
                            50.7129145440639
                        ],
                        [
                            7.141520977020264,
                            50.715597926613405
                        ],
                        [
                            7.142271995544434,
                            50.716657650570696
                        ],
                        [
                            7.134547233581543,
                            50.718389840060716
                        ],
                        [
                            7.126221656799316,
                            50.72012875805253
                        ],
                        [
                            7.121157646179199,
                            50.722112132615244
                        ],
                        [
                            7.115278244018555,
                            50.725997129915555
                        ],
                        [
                            7.111802101135254,
                            50.72936567800241
                        ],
                        [
                            7.109227180480957,
                            50.73466250160949
                        ],
                        [
                            7.108240127563477,
                            50.737718088935544
                        ],
                        [
                            7.1156108379364005,
                            50.738784102495
                        ],
                        [
                            7.115514278411865,
                            50.73912359216477
                        ],
                        [
                            7.1080684661865225,
                            50.73821375430944
                        ],
                        [
                            7.106351852416992,
                            50.742593035489186
                        ],
                        [
                            7.104206085205078,
                            50.747521783005354
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--06",
            "properties": {
                "land": "de",
                "city": "Frankfurt am Main",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://frankfurt.de/themen/umwelt-und-gruen/umwelt-und-gruen-a-z/luft/umweltzone",
                "urlPark": "https://frankfurt.de/themen/verkehr/auto",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.623108863830566,
                            50.18756065409474
                        ],
                        [
                            8.619117736816406,
                            50.183658663328536
                        ],
                        [
                            8.604011535644531,
                            50.156693243559005
                        ],
                        [
                            8.593583106994629,
                            50.137827650012014
                        ],
                        [
                            8.595170974731445,
                            50.13411415684426
                        ],
                        [
                            8.59130859375,
                            50.132271056921226
                        ],
                        [
                            8.59182357788086,
                            50.127429142816624
                        ],
                        [
                            8.598690032958984,
                            50.11705589409776
                        ],
                        [
                            8.603582382202148,
                            50.11485438509181
                        ],
                        [
                            8.602166175842285,
                            50.112157398606904
                        ],
                        [
                            8.605513572692871,
                            50.10637763033234
                        ],
                        [
                            8.614225387573242,
                            50.09858760109239
                        ],
                        [
                            8.619332313537596,
                            50.091319435210956
                        ],
                        [
                            8.619632720947266,
                            50.0891718115733
                        ],
                        [
                            8.620276451110838,
                            50.08595019565928
                        ],
                        [
                            8.619375228881836,
                            50.08407770399985
                        ],
                        [
                            8.618731498718262,
                            50.08013972552224
                        ],
                        [
                            8.60903263092041,
                            50.05967355308975
                        ],
                        [
                            8.609547615051268,
                            50.05441098581656
                        ],
                        [
                            8.616242408752441,
                            50.053088365063545
                        ],
                        [
                            8.628172874450684,
                            50.053667016128735
                        ],
                        [
                            8.639459609985352,
                            50.056835695848264
                        ],
                        [
                            8.649930953979492,
                            50.06003171731654
                        ],
                        [
                            8.65898609161377,
                            50.06162964821235
                        ],
                        [
                            8.700871467590332,
                            50.062125546978535
                        ],
                        [
                            8.716020584106445,
                            50.065045735732056
                        ],
                        [
                            8.72387409210205,
                            50.06565179036633
                        ],
                        [
                            8.727006912231445,
                            50.07011432050789
                        ],
                        [
                            8.730697631835938,
                            50.072510693106416
                        ],
                        [
                            8.735675811767578,
                            50.080718050319206
                        ],
                        [
                            8.73661994934082,
                            50.08727191043621
                        ],
                        [
                            8.738851547241211,
                            50.092833727354
                        ],
                        [
                            8.738465309143066,
                            50.09542167951057
                        ],
                        [
                            8.738679885864258,
                            50.096385243007326
                        ],
                        [
                            8.739538192749023,
                            50.09696337180414
                        ],
                        [
                            8.736705780029297,
                            50.1051665428782
                        ],
                        [
                            8.73636245727539,
                            50.10662534899367
                        ],
                        [
                            8.736920356750488,
                            50.10761621082688
                        ],
                        [
                            8.731684684753418,
                            50.115982671102664
                        ],
                        [
                            8.726449012756348,
                            50.12299946279942
                        ],
                        [
                            8.723487854003906,
                            50.1287772249296
                        ],
                        [
                            8.722715377807617,
                            50.134389240296336
                        ],
                        [
                            8.718552589416504,
                            50.136754892701575
                        ],
                        [
                            8.710012435913086,
                            50.1378551562934
                        ],
                        [
                            8.706965446472168,
                            50.13950550423828
                        ],
                        [
                            8.703446388244629,
                            50.142641008489306
                        ],
                        [
                            8.702116012573242,
                            50.14396115932111
                        ],
                        [
                            8.70267391204834,
                            50.14640884258041
                        ],
                        [
                            8.70310306549072,
                            50.14893890035415
                        ],
                        [
                            8.7046480178833,
                            50.151551319551075
                        ],
                        [
                            8.705034255981445,
                            50.155400940350646
                        ],
                        [
                            8.701558113098145,
                            50.1583704360625
                        ],
                        [
                            8.698854446411133,
                            50.15947020248462
                        ],
                        [
                            8.691129684448242,
                            50.15916776924058
                        ],
                        [
                            8.68464946746826,
                            50.15853540263865
                        ],
                        [
                            8.681774139404297,
                            50.15757308958628
                        ],
                        [
                            8.678855895996094,
                            50.157793048563136
                        ],
                        [
                            8.67319107055664,
                            50.160240023926974
                        ],
                        [
                            8.668599128723145,
                            50.16235696897325
                        ],
                        [
                            8.664135932922362,
                            50.16604077967817
                        ],
                        [
                            8.658943176269531,
                            50.168872172531124
                        ],
                        [
                            8.651690483093262,
                            50.17068637491781
                        ],
                        [
                            8.647828102111816,
                            50.173022746221484
                        ],
                        [
                            8.64675521850586,
                            50.17632095829559
                        ],
                        [
                            8.647398948669434,
                            50.17846467405517
                        ],
                        [
                            8.648643493652344,
                            50.17997621067504
                        ],
                        [
                            8.650403022766113,
                            50.18137777462423
                        ],
                        [
                            8.654823303222656,
                            50.18338386323114
                        ],
                        [
                            8.657097816467285,
                            50.186269185403425
                        ],
                        [
                            8.656754493713379,
                            50.189071902899045
                        ],
                        [
                            8.652591705322266,
                            50.193577888161805
                        ],
                        [
                            8.647055625915527,
                            50.19899000344829
                        ],
                        [
                            8.643107414245605,
                            50.19904494563526
                        ],
                        [
                            8.623108863830566,
                            50.18756065409474
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Ludwigsburg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.landkreis-ludwigsburg.de/de/umwelt-technik/umwelt-und-naturschutz/immissionsschutz/umweltzonen/",
                "urlPark": "https://www.ludwigsburg.de/start/leben+in+ludwigsburg/parken.html",
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                9.134659767150879,
                                48.87148983809234
                            ],
                            [
                                9.14186954498291,
                                48.880408956183125
                            ],
                            [
                                9.143500328063965,
                                48.88269492976939
                            ],
                            [
                                9.142942428588867,
                                48.88416241330027
                            ],
                            [
                                9.14285659790039,
                                48.88568629292919
                            ],
                            [
                                9.143543243408203,
                                48.88670218688413
                            ],
                            [
                                9.145259857177734,
                                48.88633533866999
                            ],
                            [
                                9.148092269897461,
                                48.892656039268175
                            ],
                            [
                                9.152898788452148,
                                48.90117643333875
                            ],
                            [
                                9.1536283493042,
                                48.9041384817614
                            ],
                            [
                                9.153370857238768,
                                48.91344663393643
                            ],
                            [
                                9.154143333435059,
                                48.916605370257194
                            ],
                            [
                                9.155173301696777,
                                48.91872048381787
                            ],
                            [
                                9.154143333435059,
                                48.9199331085319
                            ],
                            [
                                9.15461540222168,
                                48.9212303023781
                            ],
                            [
                                9.158992767333984,
                                48.9238809850379
                            ],
                            [
                                9.164571762084961,
                                48.92695447282495
                            ],
                            [
                                9.172382354736328,
                                48.92937929299127
                            ],
                            [
                                9.181694984436035,
                                48.93197315629521
                            ],
                            [
                                9.187488555908201,
                                48.93487699554291
                            ],
                            [
                                9.191608428955078,
                                48.93941566805934
                            ],
                            [
                                9.19830322265625,
                                48.94744895231473
                            ],
                            [
                                9.201736450195312,
                                48.94995732700445
                            ],
                            [
                                9.202165603637695,
                                48.952099210069775
                            ],
                            [
                                9.212079048156738,
                                48.95649541881164
                            ],
                            [
                                9.21276569366455,
                                48.964892227887624
                            ],
                            [
                                9.200234413146973,
                                48.96610373128198
                            ],
                            [
                                9.178605079650879,
                                48.96523032481893
                            ],
                            [
                                9.175386428833008,
                                48.96387792334011
                            ],
                            [
                                9.14985179901123,
                                48.97717494505196
                            ],
                            [
                                9.139251708984375,
                                48.97787913756692
                            ],
                            [
                                9.129981994628904,
                                48.97289324043797
                            ],
                            [
                                9.10916805267334,
                                48.970921279106
                            ],
                            [
                                9.090414047241211,
                                48.96880837683932
                            ],
                            [
                                9.090714454650879,
                                48.949027270200034
                            ],
                            [
                                9.085564613342285,
                                48.9471671045817
                            ],
                            [
                                9.082989692687988,
                                48.945335055512416
                            ],
                            [
                                9.077067375183105,
                                48.94505319583951
                            ],
                            [
                                9.07642364501953,
                                48.94415123418794
                            ],
                            [
                                9.073290824890137,
                                48.94364387359495
                            ],
                            [
                                9.057755470275879,
                                48.94260094951064
                            ],
                            [
                                9.05324935913086,
                                48.940430470056214
                            ],
                            [
                                9.042305946350096,
                                48.935384445258144
                            ],
                            [
                                9.04672622680664,
                                48.90106358992757
                            ],
                            [
                                9.093246459960938,
                                48.88760518640721
                            ],
                            [
                                9.09719467163086,
                                48.88805668005377
                            ],
                            [
                                9.096808433532713,
                                48.88938291906472
                            ],
                            [
                                9.105863571166992,
                                48.89048338856039
                            ],
                            [
                                9.109768867492676,
                                48.88125562524943
                            ],
                            [
                                9.122343063354492,
                                48.87563911932324
                            ],
                            [
                                9.134659767150879,
                                48.87148983809234
                            ]
                        ],
                        [
                            [
                                9.136247634887695,
                                48.87081237174292
                            ],
                            [
                                9.140067100524902,
                                48.861919775587594
                            ],
                            [
                                9.147276878356934,
                                48.86011283274077
                            ],
                            [
                                9.1536283493042,
                                48.860705743053124
                            ],
                            [
                                9.154915809631348,
                                48.85695052578769
                            ],
                            [
                                9.161267280578613,
                                48.85604697282049
                            ],
                            [
                                9.159207344055174,
                                48.85359035574604
                            ],
                            [
                                9.165773391723633,
                                48.85051235502711
                            ],
                            [
                                9.169549942016602,
                                48.85220669076219
                            ],
                            [
                                9.172768592834473,
                                48.847660093710566
                            ],
                            [
                                9.182424545288086,
                                48.848846202308756
                            ],
                            [
                                9.196286201477049,
                                48.85076650904263
                            ],
                            [
                                9.207444190979004,
                                48.852686742129535
                            ],
                            [
                                9.205427169799805,
                                48.85443747869951
                            ],
                            [
                                9.207615852355957,
                                48.85610344535875
                            ],
                            [
                                9.20680046081543,
                                48.85754347355922
                            ],
                            [
                                9.206886291503906,
                                48.859774023694534
                            ],
                            [
                                9.206242561340332,
                                48.86127041301128
                            ],
                            [
                                9.214482307434082,
                                48.863726653200374
                            ],
                            [
                                9.217314720153809,
                                48.86626746447529
                            ],
                            [
                                9.230318069458006,
                                48.863641957269515
                            ],
                            [
                                9.228515625,
                                48.85810817919267
                            ],
                            [
                                9.229846000671387,
                                48.854776323867306
                            ],
                            [
                                9.233880043029785,
                                48.853844494137185
                            ],
                            [
                                9.233236312866211,
                                48.84986284445959
                            ],
                            [
                                9.238471984863281,
                                48.84636099015179
                            ],
                            [
                                9.244608879089355,
                                48.84712351154341
                            ],
                            [
                                9.249887466430662,
                                48.854155105974385
                            ],
                            [
                                9.253578186035156,
                                48.859774023694534
                            ],
                            [
                                9.256110191345215,
                                48.85937874357536
                            ],
                            [
                                9.259672164916992,
                                48.862371501106466
                            ],
                            [
                                9.267311096191406,
                                48.85929404028653
                            ],
                            [
                                9.275507926940918,
                                48.86014106672441
                            ],
                            [
                                9.277610778808594,
                                48.86005636472564
                            ],
                            [
                                9.286837577819822,
                                48.863726653200374
                            ],
                            [
                                9.29044246673584,
                                48.86220210451467
                            ],
                            [
                                9.293317794799805,
                                48.86163744506789
                            ],
                            [
                                9.296021461486816,
                                48.86423482577544
                            ],
                            [
                                9.296751022338867,
                                48.86762251107506
                            ],
                            [
                                9.300270080566406,
                                48.870868827622395
                            ],
                            [
                                9.300312995910645,
                                48.87174388560841
                            ],
                            [
                                9.30567741394043,
                                48.87470767798212
                            ],
                            [
                                9.304862022399902,
                                48.87597782096565
                            ],
                            [
                                9.301643371582031,
                                48.87569556975623
                            ],
                            [
                                9.297137260437012,
                                48.885347657024646
                            ],
                            [
                                9.300570487976072,
                                48.88546053591428
                            ],
                            [
                                9.298381805419922,
                                48.88997548257204
                            ],
                            [
                                9.302287101745605,
                                48.889834396659666
                            ],
                            [
                                9.304561614990234,
                                48.89338964026286
                            ],
                            [
                                9.30267333984375,
                                48.89491323870241
                            ],
                            [
                                9.306321144104004,
                                48.89717033622442
                            ],
                            [
                                9.311814308166504,
                                48.89756531781301
                            ],
                            [
                                9.313187599182127,
                                48.90055579142446
                            ],
                            [
                                9.315934181213379,
                                48.90027367891568
                            ],
                            [
                                9.315247535705566,
                                48.903856389475095
                            ],
                            [
                                9.319581985473633,
                                48.906056667080136
                            ],
                            [
                                9.330697059631346,
                                48.90693110948285
                            ],
                            [
                                9.330224990844727,
                                48.909328695635885
                            ],
                            [
                                9.323444366455078,
                                48.90921587063169
                            ],
                            [
                                9.322714805603027,
                                48.90997743446598
                            ],
                            [
                                9.321727752685547,
                                48.909864610926675
                            ],
                            [
                                9.321684837341309,
                                48.91051334279856
                            ],
                            [
                                9.319839477539062,
                                48.910315903555315
                            ],
                            [
                                9.31790828704834,
                                48.91271332729735
                            ],
                            [
                                9.310526847839355,
                                48.91339022611556
                            ],
                            [
                                9.303617477416992,
                                48.910005640311006
                            ],
                            [
                                9.301986694335938,
                                48.90834146821118
                            ],
                            [
                                9.295463562011719,
                                48.9090748390182
                            ],
                            [
                                9.265809059143066,
                                48.91810005978929
                            ],
                            [
                                9.241561889648438,
                                48.92303503777168
                            ],
                            [
                                9.23576831817627,
                                48.92402197485579
                            ],
                            [
                                9.22645568847656,
                                48.922273672980424
                            ],
                            [
                                9.223451614379883,
                                48.92596759369508
                            ],
                            [
                                9.213838577270506,
                                48.93301630245462
                            ],
                            [
                                9.210062026977539,
                                48.93911968025702
                            ],
                            [
                                9.206006526947021,
                                48.93809075662178
                            ],
                            [
                                9.205255508422852,
                                48.93964118139728
                            ],
                            [
                                9.200105667114256,
                                48.94119155801367
                            ],
                            [
                                9.200341701507568,
                                48.94705436504273
                            ],
                            [
                                9.19780969619751,
                                48.94412304762364
                            ],
                            [
                                9.194419384002686,
                                48.94004992172528
                            ],
                            [
                                9.191007614135742,
                                48.93637113827137
                            ],
                            [
                                9.189226627349854,
                                48.93462326875091
                            ],
                            [
                                9.185407161712646,
                                48.932156413279756
                            ],
                            [
                                9.181265830993652,
                                48.93060575602211
                            ],
                            [
                                9.171631336212158,
                                48.92758888482361
                            ],
                            [
                                9.165215492248535,
                                48.9252908653051
                            ],
                            [
                                9.159142971038818,
                                48.921089304678986
                            ],
                            [
                                9.15982961654663,
                                48.918861488204186
                            ],
                            [
                                9.15910005569458,
                                48.917789844885164
                            ],
                            [
                                9.156718254089355,
                                48.91747962805446
                            ],
                            [
                                9.155645370483398,
                                48.9149837225407
                            ],
                            [
                                9.155516624450684,
                                48.90944152038532
                            ],
                            [
                                9.155516624450684,
                                48.90402564503796
                            ],
                            [
                                9.155001640319824,
                                48.90151496204364
                            ],
                            [
                                9.152512550354004,
                                48.896267509444996
                            ],
                            [
                                9.150195121765137,
                                48.8917813470903
                            ],
                            [
                                9.147062301635742,
                                48.88605314590472
                            ],
                            [
                                9.148736000061035,
                                48.88562985377101
                            ],
                            [
                                9.148006439208984,
                                48.88399309047507
                            ],
                            [
                                9.145646095275879,
                                48.88277959345126
                            ],
                            [
                                9.143929481506348,
                                48.87987272502814
                            ],
                            [
                                9.137492179870605,
                                48.87208261362325
                            ],
                            [
                                9.136247634887695,
                                48.87081237174292
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Leonberg / Hemmingen",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled on 1/1/2024",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on January 1, 2024.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rps/wir/abteilungen/pressemitteilungen-und-aktuelle-meldungen/artikel/luftreinhaltung-umweltzonen-in-heidenheim-heilbronn-herrenberg-und-leonberg-hemmingen-werden-aufgehoben/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                9.046661853790281,
                                48.900950746261614
                            ],
                            [
                                9.025912284851074,
                                48.89573144832972
                            ],
                            [
                                9.026985168457031,
                                48.891640266273605
                            ],
                            [
                                9.029216766357422,
                                48.889044308193256
                            ],
                            [
                                9.031662940979004,
                                48.88785915208501
                            ],
                            [
                                9.016985893249512,
                                48.87199793183453
                            ],
                            [
                                8.991408348083496,
                                48.86443244704999
                            ],
                            [
                                8.964028358459473,
                                48.85920933685439
                            ],
                            [
                                8.96329879760742,
                                48.848168429407245
                            ],
                            [
                                8.960251808166504,
                                48.838636276350066
                            ],
                            [
                                8.963470458984375,
                                48.83472403813848
                            ],
                            [
                                8.965766429901121,
                                48.82992159521573
                            ],
                            [
                                8.96735429763794,
                                48.82893279982311
                            ],
                            [
                                8.966002464294434,
                                48.826828013230156
                            ],
                            [
                                8.96310567855835,
                                48.82492091548112
                            ],
                            [
                                8.962311744689941,
                                48.81577996550309
                            ],
                            [
                                8.961045742034912,
                                48.81549737479911
                            ],
                            [
                                8.961646556854248,
                                48.809378895478304
                            ],
                            [
                                8.963642120361328,
                                48.80896908611974
                            ],
                            [
                                8.966495990753174,
                                48.80369779130013
                            ],
                            [
                                8.963770866394043,
                                48.79860968905856
                            ],
                            [
                                8.963406085968018,
                                48.79622092969817
                            ],
                            [
                                8.960015773773193,
                                48.795938228755276
                            ],
                            [
                                8.969156742095947,
                                48.79254569317643
                            ],
                            [
                                8.978126049041748,
                                48.78937911965463
                            ],
                            [
                                8.98082971572876,
                                48.78864399364856
                            ],
                            [
                                8.982846736907959,
                                48.789124654179
                            ],
                            [
                                8.983490467071533,
                                48.78909638015761
                            ],
                            [
                                8.983619213104248,
                                48.78855917072408
                            ],
                            [
                                8.985657691955566,
                                48.78764024651802
                            ],
                            [
                                8.992609977722166,
                                48.78635372435848
                            ],
                            [
                                8.999691009521484,
                                48.78498234024523
                            ],
                            [
                                9.003703594207764,
                                48.784317841680966
                            ],
                            [
                                9.007072448730469,
                                48.78385127360165
                            ],
                            [
                                9.01005506515503,
                                48.783314008016006
                            ],
                            [
                                9.011256694793701,
                                48.78372402700905
                            ],
                            [
                                9.014453887939453,
                                48.78604269207236
                            ],
                            [
                                9.016599655151367,
                                48.7875978342253
                            ],
                            [
                                9.01803731918335,
                                48.789972867412615
                            ],
                            [
                                9.019603729248047,
                                48.79142893336244
                            ],
                            [
                                9.022650718688965,
                                48.79369069958178
                            ],
                            [
                                9.027564525604248,
                                48.79650362904795
                            ],
                            [
                                9.031212329864502,
                                48.79903371729318
                            ],
                            [
                                9.035696983337402,
                                48.802524746844234
                            ],
                            [
                                9.039924144744873,
                                48.8052382537906
                            ],
                            [
                                9.042069911956787,
                                48.80670800875246
                            ],
                            [
                                9.046189785003662,
                                48.80829077361471
                            ],
                            [
                                9.055202007293701,
                                48.811696375030124
                            ],
                            [
                                9.066081047058105,
                                48.81553976350623
                            ],
                            [
                                9.070136547088623,
                                48.817320056841375
                            ],
                            [
                                9.074127674102783,
                                48.81938285734555
                            ],
                            [
                                9.075822830200195,
                                48.820838069646996
                            ],
                            [
                                9.075608253479004,
                                48.82212370749906
                            ],
                            [
                                9.075779914855957,
                                48.82349407637127
                            ],
                            [
                                9.078032970428467,
                                48.82333867559929
                            ],
                            [
                                9.082818031311035,
                                48.82839601700997
                            ],
                            [
                                9.086337089538574,
                                48.83226637485846
                            ],
                            [
                                9.089856147766113,
                                48.834342684497486
                            ],
                            [
                                9.109447002410889,
                                48.8427459159238
                            ],
                            [
                                9.116120338439941,
                                48.84658692362647
                            ],
                            [
                                9.118523597717285,
                                48.849072124570554
                            ],
                            [
                                9.118223190307617,
                                48.85079474829806
                            ],
                            [
                                9.118201732635498,
                                48.85289852803419
                            ],
                            [
                                9.121527671813965,
                                48.85378801905064
                            ],
                            [
                                9.123287200927734,
                                48.85733170730424
                            ],
                            [
                                9.127836227416992,
                                48.86352902913877
                            ],
                            [
                                9.134509563446045,
                                48.87120050462785
                            ],
                            [
                                9.122031927108765,
                                48.875363922550235
                            ],
                            [
                                9.109479188919067,
                                48.88101573713577
                            ],
                            [
                                9.10568118095398,
                                48.89033524984676
                            ],
                            [
                                9.096980094909668,
                                48.88928415779743
                            ],
                            [
                                9.09741997718811,
                                48.88790147957257
                            ],
                            [
                                9.093278646469116,
                                48.88744998452465
                            ],
                            [
                                9.046661853790281,
                                48.900950746261614
                            ]
                        ],
                        [
                            [
                                9.135990142822266,
                                48.87064300372225
                            ],
                            [
                                9.130969047546385,
                                48.865081768600454
                            ],
                            [
                                9.125947952270508,
                                48.85774112125558
                            ],
                            [
                                9.123201370239256,
                                48.85350564266233
                            ],
                            [
                                9.12339448928833,
                                48.85141600788781
                            ],
                            [
                                9.124488830566406,
                                48.84958044593268
                            ],
                            [
                                9.123544692993164,
                                48.84873324079559
                            ],
                            [
                                9.120047092437744,
                                48.84838023442446
                            ],
                            [
                                9.115219116210938,
                                48.84422869277915
                            ],
                            [
                                9.111099243164062,
                                48.84203981627703
                            ],
                            [
                                9.102988243103027,
                                48.838721014591236
                            ],
                            [
                                9.09537076950073,
                                48.83555735636251
                            ],
                            [
                                9.102795124053955,
                                48.82801461520098
                            ],
                            [
                                9.10815954208374,
                                48.82932832032168
                            ],
                            [
                                9.10865306854248,
                                48.82480790000336
                            ],
                            [
                                9.112322330474854,
                                48.825189326219444
                            ],
                            [
                                9.11388874053955,
                                48.82347994904826
                            ],
                            [
                                9.122965335845947,
                                48.82480790000336
                            ],
                            [
                                9.131076335906982,
                                48.827124666357115
                            ],
                            [
                                9.138221740722656,
                                48.828042867286385
                            ],
                            [
                                9.137728214263916,
                                48.83489352771377
                            ],
                            [
                                9.140174388885496,
                                48.83753466616981
                            ],
                            [
                                9.133586883544922,
                                48.84393214092012
                            ],
                            [
                                9.135990142822266,
                                48.844073356310076
                            ],
                            [
                                9.141998291015625,
                                48.84404511326393
                            ],
                            [
                                9.143178462982176,
                                48.84475118463909
                            ],
                            [
                                9.138994216918945,
                                48.85175487350584
                            ],
                            [
                                9.133908748626709,
                                48.852969123136646
                            ],
                            [
                                9.133501052856445,
                                48.85765641519554
                            ],
                            [
                                9.140903949737549,
                                48.85621639024412
                            ],
                            [
                                9.139723777770994,
                                48.8617080278471
                            ],
                            [
                                9.135990142822266,
                                48.87064300372225
                            ]
                        ],
                        [
                            [
                                9.086465835571289,
                                48.83035948408407
                            ],
                            [
                                9.085178375244139,
                                48.829497828151055
                            ],
                            [
                                9.082624912261963,
                                48.82660199062137
                            ],
                            [
                                9.079856872558594,
                                48.823409312373556
                            ],
                            [
                                9.080972671508789,
                                48.82316914693484
                            ],
                            [
                                9.080801010131836,
                                48.82236387794751
                            ],
                            [
                                9.079599380493164,
                                48.821403189248336
                            ],
                            [
                                9.077818393707274,
                                48.8212195260768
                            ],
                            [
                                9.075307846069336,
                                48.81894487255629
                            ],
                            [
                                9.071874618530273,
                                48.816684245051086
                            ],
                            [
                                9.066317081451416,
                                48.814536554490054
                            ],
                            [
                                9.055545330047607,
                                48.81063656535605
                            ],
                            [
                                9.049708843231201,
                                48.80853101034297
                            ],
                            [
                                9.049301147460938,
                                48.807923350509924
                            ],
                            [
                                9.047777652740479,
                                48.80745700192298
                            ],
                            [
                                9.044344425201416,
                                48.806481895408226
                            ],
                            [
                                9.041340351104736,
                                48.804941471119164
                            ],
                            [
                                9.037220478057861,
                                48.80203008131931
                            ],
                            [
                                9.033594131469727,
                                48.799175059241485
                            ],
                            [
                                9.03003215789795,
                                48.796602573444
                            ],
                            [
                                9.026083946228027,
                                48.79402995572052
                            ],
                            [
                                9.02200698852539,
                                48.791598569365675
                            ],
                            [
                                9.019947052001953,
                                48.78984563634166
                            ],
                            [
                                9.0177583694458,
                                48.786735443165774
                            ],
                            [
                                9.015355110168457,
                                48.783427117038315
                            ],
                            [
                                9.015398025512695,
                                48.78204151394977
                            ],
                            [
                                9.019904136657713,
                                48.77963782602115
                            ],
                            [
                                9.023809432983398,
                                48.77768651234782
                            ],
                            [
                                9.027156829833984,
                                48.77760167090221
                            ],
                            [
                                9.027585983276367,
                                48.776527000179286
                            ],
                            [
                                9.026341438293457,
                                48.77545230644962
                            ],
                            [
                                9.029302597045898,
                                48.77205838573418
                            ],
                            [
                                9.031319618225098,
                                48.76790052018067
                            ],
                            [
                                9.033722877502441,
                                48.76218642901084
                            ],
                            [
                                9.034152030944824,
                                48.758650155860764
                            ],
                            [
                                9.03475284576416,
                                48.75245400509876
                            ],
                            [
                                9.035568237304688,
                                48.746257090106084
                            ],
                            [
                                9.036083221435547,
                                48.7434554941429
                            ],
                            [
                                9.03698444366455,
                                48.74065374199998
                            ],
                            [
                                9.038529396057129,
                                48.74127636708441
                            ],
                            [
                                9.041748046875,
                                48.74436107759713
                            ],
                            [
                                9.045395851135254,
                                48.747049432349264
                            ],
                            [
                                9.049644470214844,
                                48.74801154542067
                            ],
                            [
                                9.054450988769531,
                                48.74789835660364
                            ],
                            [
                                9.057197570800781,
                                48.746992836889056
                            ],
                            [
                                9.05972957611084,
                                48.74693624136511
                            ],
                            [
                                9.068012237548828,
                                48.74727581355269
                            ],
                            [
                                9.06848430633545,
                                48.7489736400715
                            ],
                            [
                                9.063291549682617,
                                48.74832281335284
                            ],
                            [
                                9.062819480895996,
                                48.75256718365392
                            ],
                            [
                                9.06672477722168,
                                48.75627364043579
                            ],
                            [
                                9.076552391052246,
                                48.75468923435743
                            ],
                            [
                                9.077668190002441,
                                48.76379888691892
                            ],
                            [
                                9.075565338134766,
                                48.76710850675318
                            ],
                            [
                                9.083290100097656,
                                48.76863595003531
                            ],
                            [
                                9.084234237670898,
                                48.77499979694366
                            ],
                            [
                                9.087796211242676,
                                48.78221118168935
                            ],
                            [
                                9.075050354003906,
                                48.78752714699118
                            ],
                            [
                                9.077796936035156,
                                48.79397341319004
                            ],
                            [
                                9.083547592163086,
                                48.796800461650626
                            ],
                            [
                                9.085092544555664,
                                48.801436476249386
                            ],
                            [
                                9.085993766784668,
                                48.80398044851267
                            ],
                            [
                                9.085350036621094,
                                48.805761152319256
                            ],
                            [
                                9.087324142456055,
                                48.809689783102314
                            ],
                            [
                                9.079792499542235,
                                48.81107462273741
                            ],
                            [
                                9.079406261444092,
                                48.81667011580858
                            ],
                            [
                                9.083418846130371,
                                48.81897312975495
                            ],
                            [
                                9.086465835571289,
                                48.83035948408407
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--09-001",
            "properties": {
                "land": "de",
                "city": "Stuttgart (only EURO-5+ diesel)",
                "zone": "Umweltzone",
                "area_code": 1,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-5 (diesel)",
                "minEUd": 5,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 3,
                "urlZone": "https://www.stuttgart.de/leben/mobilitaet/dieselverkehrsverbot/dieselverkehrsverbot.php",
                "urlPark": "https://www.vvs.de/rundum-mobil/park-ride",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.244222640991211,
                            48.84681285608177
                        ],
                        [
                            9.238407611846924,
                            48.846191539376825
                        ],
                        [
                            9.232957363128662,
                            48.849792244977166
                        ],
                        [
                            9.233676195144653,
                            48.853752722089204
                        ],
                        [
                            9.229695796966553,
                            48.85464219792927
                        ],
                        [
                            9.22827959060669,
                            48.85804465012694
                        ],
                        [
                            9.2300283908844,
                            48.86357137721767
                        ],
                        [
                            9.223204851150513,
                            48.86495472809045
                        ],
                        [
                            9.217389822006226,
                            48.86614042697531
                        ],
                        [
                            9.214696884155273,
                            48.8637054792311
                        ],
                        [
                            9.206521511077879,
                            48.86122100464426
                        ],
                        [
                            9.207154512405396,
                            48.859837550565395
                        ],
                        [
                            9.20701503753662,
                            48.85759994440924
                        ],
                        [
                            9.207862615585327,
                            48.85612462254417
                        ],
                        [
                            9.20573830604553,
                            48.85445159729395
                        ],
                        [
                            9.20780897140503,
                            48.85262320618343
                        ],
                        [
                            9.19730544090271,
                            48.850773568857974
                        ],
                        [
                            9.18707013130188,
                            48.849340405932516
                        ],
                        [
                            9.172543287277222,
                            48.8474977076088
                        ],
                        [
                            9.169442653656004,
                            48.85189606683644
                        ],
                        [
                            9.165934324264526,
                            48.850258199721495
                        ],
                        [
                            9.158906936645508,
                            48.8534774049359
                        ],
                        [
                            9.160816669464111,
                            48.85597638205808
                        ],
                        [
                            9.154776334762573,
                            48.85672463907484
                        ],
                        [
                            9.153510332107544,
                            48.86054339926111
                        ],
                        [
                            9.147276878356934,
                            48.85999989664685
                        ],
                        [
                            9.139916896820068,
                            48.86174331919941
                        ],
                        [
                            9.141000509262085,
                            48.85609638629495
                        ],
                        [
                            9.133608341217041,
                            48.85755759127768
                        ],
                        [
                            9.134037494659424,
                            48.85303971813958
                        ],
                        [
                            9.13914442062378,
                            48.851825470220916
                        ],
                        [
                            9.143328666687012,
                            48.84472294197522
                        ],
                        [
                            9.142094850540161,
                            48.84396038402997
                        ],
                        [
                            9.136172533035278,
                            48.84395332325399
                        ],
                        [
                            9.13387656211853,
                            48.843840350703104
                        ],
                        [
                            9.140335321426392,
                            48.83754172785069
                        ],
                        [
                            9.13789987564087,
                            48.83489352771377
                        ],
                        [
                            9.13838267326355,
                            48.82797930007181
                        ],
                        [
                            9.1311514377594,
                            48.8270328453388
                        ],
                        [
                            9.123094081878662,
                            48.82472313822776
                        ],
                        [
                            9.113770723342896,
                            48.82335280296211
                        ],
                        [
                            9.112268686294556,
                            48.82506924790896
                        ],
                        [
                            9.108449220657349,
                            48.82468782077893
                        ],
                        [
                            9.107998609542847,
                            48.829158811918866
                        ],
                        [
                            9.102730751037596,
                            48.82785922844649
                        ],
                        [
                            9.095242023468018,
                            48.8354867367116
                        ],
                        [
                            9.094265699386597,
                            48.83519013309072
                        ],
                        [
                            9.089223146438599,
                            48.83266893141074
                        ],
                        [
                            9.08668041229248,
                            48.83051486308523
                        ],
                        [
                            9.085017442703247,
                            48.82449710612528
                        ],
                        [
                            9.083558320999146,
                            48.818966065456785
                        ],
                        [
                            9.079535007476807,
                            48.81662772805722
                        ],
                        [
                            9.079921245574951,
                            48.81115940759497
                        ],
                        [
                            9.087485074996948,
                            48.80971804551801
                        ],
                        [
                            9.085478782653809,
                            48.80576821847823
                        ],
                        [
                            9.086143970489502,
                            48.80400871414629
                        ],
                        [
                            9.085639715194702,
                            48.80264487916398
                        ],
                        [
                            9.0836763381958,
                            48.796779259380116
                        ],
                        [
                            9.077925682067871,
                            48.7939451419009
                        ],
                        [
                            9.077496528625488,
                            48.7929273648817
                        ],
                        [
                            9.075189828872679,
                            48.7875978342253
                        ],
                        [
                            9.080908298492432,
                            48.785201481459495
                        ],
                        [
                            9.088000059127808,
                            48.782253598534616
                        ],
                        [
                            9.08668041229248,
                            48.77968024504118
                        ],
                        [
                            9.084341526031494,
                            48.77499272645026
                        ],
                        [
                            9.084223508834839,
                            48.77410183631525
                        ],
                        [
                            9.083418846130371,
                            48.768593521682625
                        ],
                        [
                            9.07572627067566,
                            48.767066077109895
                        ],
                        [
                            9.077775478363037,
                            48.76381303105749
                        ],
                        [
                            9.07721757888794,
                            48.75951302947207
                        ],
                        [
                            9.076606035232544,
                            48.754632647501865
                        ],
                        [
                            9.06997561454773,
                            48.75571486006745
                        ],
                        [
                            9.066767692565918,
                            48.75623827477395
                        ],
                        [
                            9.062883853912354,
                            48.75255303634846
                        ],
                        [
                            9.063366651535034,
                            48.74840063003463
                        ],
                        [
                            9.06845211982727,
                            48.749030233300836
                        ],
                        [
                            9.068602323532104,
                            48.74901608499948
                        ],
                        [
                            9.068130254745483,
                            48.74726873915552
                        ],
                        [
                            9.068044424057007,
                            48.7472121439423
                        ],
                        [
                            9.05972957611084,
                            48.74686549687054
                        ],
                        [
                            9.05720829963684,
                            48.74692209247416
                        ],
                        [
                            9.054440259933472,
                            48.74783468778203
                        ],
                        [
                            9.049655199050903,
                            48.747926653831804
                        ],
                        [
                            9.04547095298767,
                            48.74697868801403
                        ],
                        [
                            9.041919708251953,
                            48.74430447910931
                        ],
                        [
                            9.038829803466797,
                            48.74129051756488
                        ],
                        [
                            9.03923749923706,
                            48.7409792060744
                        ],
                        [
                            9.044215679168701,
                            48.74112071153636
                        ],
                        [
                            9.051640033721924,
                            48.74062544067651
                        ],
                        [
                            9.054043292999268,
                            48.73779522785731
                        ],
                        [
                            9.056532382965088,
                            48.733125028314966
                        ],
                        [
                            9.061810970306396,
                            48.733832662193734
                        ],
                        [
                            9.066617488861084,
                            48.73410156045615
                        ],
                        [
                            9.067175388336182,
                            48.73064823130499
                        ],
                        [
                            9.063398838043213,
                            48.72896393964615
                        ],
                        [
                            9.06299114227295,
                            48.72774668534487
                        ],
                        [
                            9.066810607910156,
                            48.727958999263095
                        ],
                        [
                            9.071295261383057,
                            48.72836947029652
                        ],
                        [
                            9.073634147644043,
                            48.72894978569567
                        ],
                        [
                            9.07440662384033,
                            48.727944845029775
                        ],
                        [
                            9.072067737579346,
                            48.72678418433915
                        ],
                        [
                            9.07219648361206,
                            48.72542532802727
                        ],
                        [
                            9.075136184692383,
                            48.723415285742625
                        ],
                        [
                            9.080393314361572,
                            48.72001784854386
                        ],
                        [
                            9.085693359375,
                            48.71664849669272
                        ],
                        [
                            9.087967872619629,
                            48.71558667938633
                        ],
                        [
                            9.090328216552734,
                            48.7148646308165
                        ],
                        [
                            9.095799922943115,
                            48.71359040217765
                        ],
                        [
                            9.105970859527588,
                            48.71126839145035
                        ],
                        [
                            9.110455513000487,
                            48.710418848555804
                        ],
                        [
                            9.114768505096436,
                            48.70949849423354
                        ],
                        [
                            9.120733737945555,
                            48.70873387937935
                        ],
                        [
                            9.1251540184021,
                            48.708875475599314
                        ],
                        [
                            9.130475521087645,
                            48.7094843348048
                        ],
                        [
                            9.134359359741211,
                            48.7098807973037
                        ],
                        [
                            9.13661241531372,
                            48.71009318664315
                        ],
                        [
                            9.138908386230469,
                            48.71016398289041
                        ],
                        [
                            9.138994216918945,
                            48.710546280904396
                        ],
                        [
                            9.139509201049805,
                            48.711240073584975
                        ],
                        [
                            9.140582084655762,
                            48.710871939884576
                        ],
                        [
                            9.141333103179932,
                            48.710107345900575
                        ],
                        [
                            9.143264293670654,
                            48.709767522622755
                        ],
                        [
                            9.148306846618652,
                            48.7091303477911
                        ],
                        [
                            9.15837049484253,
                            48.70772853476065
                        ],
                        [
                            9.163520336151123,
                            48.70713381440444
                        ],
                        [
                            9.16485071182251,
                            48.70747365546864
                        ],
                        [
                            9.164893627166748,
                            48.708875475599314
                        ],
                        [
                            9.166138172149658,
                            48.708974592716174
                        ],
                        [
                            9.16957139968872,
                            48.70622756034645
                        ],
                        [
                            9.174892902374268,
                            48.70464157647419
                        ],
                        [
                            9.181866645812988,
                            48.70163939873005
                        ],
                        [
                            9.187788963317871,
                            48.69894861556383
                        ],
                        [
                            9.190020561218262,
                            48.69914688870609
                        ],
                        [
                            9.19980525970459,
                            48.69540789224567
                        ],
                        [
                            9.210662841796875,
                            48.6939632055998
                        ],
                        [
                            9.216842651367188,
                            48.69452975432345
                        ],
                        [
                            9.219975471496582,
                            48.69501131572587
                        ],
                        [
                            9.220747947692871,
                            48.69614438320864
                        ],
                        [
                            9.219717979431152,
                            48.69815551518493
                        ],
                        [
                            9.21976089477539,
                            48.699430134697465
                        ],
                        [
                            9.223237037658691,
                            48.699458459208934
                        ],
                        [
                            9.228301048278809,
                            48.698580391941896
                        ],
                        [
                            9.232935905456543,
                            48.696370993645
                        ],
                        [
                            9.239373207092285,
                            48.69707914468399
                        ],
                        [
                            9.238429069519043,
                            48.70316883241063
                        ],
                        [
                            9.238343238830566,
                            48.705774427197326
                        ],
                        [
                            9.238471984863281,
                            48.706992213283684
                        ],
                        [
                            9.24074649810791,
                            48.709371059231344
                        ],
                        [
                            9.239201545715332,
                            48.711438298308025
                        ],
                        [
                            9.230189323425293,
                            48.70914450731946
                        ],
                        [
                            9.226670265197754,
                            48.71344881922564
                        ],
                        [
                            9.224224090576172,
                            48.71647860742975
                        ],
                        [
                            9.221949577331543,
                            48.719026886132525
                        ],
                        [
                            9.219717979431152,
                            48.720838916895346
                        ],
                        [
                            9.219717979431152,
                            48.72123528992326
                        ],
                        [
                            9.218902587890625,
                            48.723018929894415
                        ],
                        [
                            9.218087196350098,
                            48.72416552218907
                        ],
                        [
                            9.217357635498047,
                            48.72471757582291
                        ],
                        [
                            9.215447902679443,
                            48.72560934196893
                        ],
                        [
                            9.21426773071289,
                            48.726345391002425
                        ],
                        [
                            9.213194847106934,
                            48.72648693765841
                        ],
                        [
                            9.212379455566406,
                            48.72645862835907
                        ],
                        [
                            9.212400913238525,
                            48.728624243735496
                        ],
                        [
                            9.213001728057861,
                            48.73011039640248
                        ],
                        [
                            9.213409423828125,
                            48.730987913542506
                        ],
                        [
                            9.213967323303223,
                            48.731681424318666
                        ],
                        [
                            9.215941429138184,
                            48.73250230226286
                        ],
                        [
                            9.218065738677979,
                            48.7329268890441
                        ],
                        [
                            9.220726490020752,
                            48.73311087553582
                        ],
                        [
                            9.22227144241333,
                            48.73302595877716
                        ],
                        [
                            9.222314357757568,
                            48.732403231497976
                        ],
                        [
                            9.222357273101807,
                            48.73172388385135
                        ],
                        [
                            9.222657680511475,
                            48.73170973067777
                        ],
                        [
                            9.225897789001465,
                            48.73166727113315
                        ],
                        [
                            9.22579050064087,
                            48.73287027768046
                        ],
                        [
                            9.22546863555908,
                            48.734667657360276
                        ],
                        [
                            9.226090908050537,
                            48.734993160193746
                        ],
                        [
                            9.229395389556885,
                            48.73478087597618
                        ],
                        [
                            9.231133460998535,
                            48.735134682507514
                        ],
                        [
                            9.231219291687012,
                            48.73554509496443
                        ],
                        [
                            9.232034683227539,
                            48.73548848654888
                        ],
                        [
                            9.232656955718994,
                            48.736677249889574
                        ],
                        [
                            9.233214855194092,
                            48.73731407583026
                        ],
                        [
                            9.233322143554686,
                            48.7379084394297
                        ],
                        [
                            9.234309196472166,
                            48.73754050088728
                        ],
                        [
                            9.23724889755249,
                            48.73621023907247
                        ],
                        [
                            9.239051342010498,
                            48.737441440050304
                        ],
                        [
                            9.239952564239502,
                            48.73755465241947
                        ],
                        [
                            9.242463111877441,
                            48.74146032301972
                        ],
                        [
                            9.242806434631348,
                            48.7432290957297
                        ],
                        [
                            9.245059490203857,
                            48.74429032947739
                        ],
                        [
                            9.246776103973389,
                            48.741913134761354
                        ],
                        [
                            9.249200820922852,
                            48.74148862387308
                        ],
                        [
                            9.249093532562256,
                            48.74093675435812
                        ],
                        [
                            9.25023078918457,
                            48.74044148168563
                        ],
                        [
                            9.252548217773438,
                            48.74038487878376
                        ],
                        [
                            9.252333641052246,
                            48.741998036508754
                        ],
                        [
                            9.255359172821045,
                            48.74274799571551
                        ],
                        [
                            9.25673246383667,
                            48.74274799571551
                        ],
                        [
                            9.261345863342285,
                            48.743568692967024
                        ],
                        [
                            9.259071350097656,
                            48.74536569014957
                        ],
                        [
                            9.260616302490233,
                            48.745691123712234
                        ],
                        [
                            9.261753559112549,
                            48.746568368905805
                        ],
                        [
                            9.264285564422607,
                            48.74829451634778
                        ],
                        [
                            9.261860847473145,
                            48.752086173033774
                        ],
                        [
                            9.263298511505127,
                            48.75249644708683
                        ],
                        [
                            9.26422119140625,
                            48.75119487647747
                        ],
                        [
                            9.266088008880615,
                            48.75190225599143
                        ],
                        [
                            9.265809059143066,
                            48.752468152432115
                        ],
                        [
                            9.266023635864258,
                            48.75393945334863
                        ],
                        [
                            9.267053604125977,
                            48.753868718713605
                        ],
                        [
                            9.2679762840271,
                            48.75509948715406
                        ],
                        [
                            9.268920421600342,
                            48.75644339526616
                        ],
                        [
                            9.268555641174316,
                            48.75703753265508
                        ],
                        [
                            9.269392490386963,
                            48.75730630678448
                        ],
                        [
                            9.272181987762451,
                            48.75740532846966
                        ],
                        [
                            9.274885654449463,
                            48.75808432905261
                        ],
                        [
                            9.275293350219727,
                            48.759909097641696
                        ],
                        [
                            9.27469253540039,
                            48.76116800786959
                        ],
                        [
                            9.273641109466553,
                            48.76156406298943
                        ],
                        [
                            9.272139072418213,
                            48.76268148738444
                        ],
                        [
                            9.273319244384766,
                            48.76364330113176
                        ],
                        [
                            9.274499416351318,
                            48.764930405416905
                        ],
                        [
                            9.272546768188477,
                            48.76601946789372
                        ],
                        [
                            9.27394151687622,
                            48.76623162017313
                        ],
                        [
                            9.272847175598145,
                            48.76756108738421
                        ],
                        [
                            9.274907112121582,
                            48.76775909012765
                        ],
                        [
                            9.275100231170654,
                            48.76826823645436
                        ],
                        [
                            9.276001453399658,
                            48.769838071797246
                        ],
                        [
                            9.277095794677733,
                            48.76980978690994
                        ],
                        [
                            9.277310371398926,
                            48.76915923010415
                        ],
                        [
                            9.27767515182495,
                            48.76932894138781
                        ],
                        [
                            9.280400276184082,
                            48.768282379334195
                        ],
                        [
                            9.281752109527588,
                            48.76879152035513
                        ],
                        [
                            9.283897876739502,
                            48.7684238079134
                        ],
                        [
                            9.2849063873291,
                            48.76880566308756
                        ],
                        [
                            9.285829067230225,
                            48.76866423558387
                        ],
                        [
                            9.28844690322876,
                            48.76881980581603
                        ],
                        [
                            9.289133548736572,
                            48.769795644460295
                        ],
                        [
                            9.290592670440674,
                            48.77017748920222
                        ],
                        [
                            9.292824268341064,
                            48.77057347327488
                        ],
                        [
                            9.294283390045166,
                            48.77152099391484
                        ],
                        [
                            9.296278953552246,
                            48.77218566190158
                        ],
                        [
                            9.297330379486084,
                            48.77289274584511
                        ],
                        [
                            9.296729564666748,
                            48.77369880939393
                        ],
                        [
                            9.302308559417725,
                            48.77566441887645
                        ],
                        [
                            9.304797649383545,
                            48.77439173087215
                        ],
                        [
                            9.307823181152344,
                            48.77384022271721
                        ],
                        [
                            9.314303398132324,
                            48.77443415432545
                        ],
                        [
                            9.31413173675537,
                            48.77533917945551
                        ],
                        [
                            9.31561231613159,
                            48.77717746152309
                        ],
                        [
                            9.315526485443115,
                            48.77891669719581
                        ],
                        [
                            9.30739402770996,
                            48.77832281861946
                        ],
                        [
                            9.307115077972412,
                            48.780867963047896
                        ],
                        [
                            9.3034029006958,
                            48.78034480500824
                        ],
                        [
                            9.299626350402832,
                            48.778845997733754
                        ],
                        [
                            9.297888278961182,
                            48.77996303759197
                        ],
                        [
                            9.294562339782715,
                            48.781433533173036
                        ],
                        [
                            9.293575286865234,
                            48.782281876411545
                        ],
                        [
                            9.295785427093506,
                            48.784360256745806
                        ],
                        [
                            9.297266006469727,
                            48.784628884657444
                        ],
                        [
                            9.299154281616211,
                            48.785562002020214
                        ],
                        [
                            9.295291900634766,
                            48.78872881642969
                        ],
                        [
                            9.292287826538086,
                            48.78697578314741
                        ],
                        [
                            9.291129112243652,
                            48.78663647930936
                        ],
                        [
                            9.288640022277832,
                            48.787583696786456
                        ],
                        [
                            9.291536808013916,
                            48.79067970082096
                        ],
                        [
                            9.290142059326172,
                            48.79185302229517
                        ],
                        [
                            9.287631511688232,
                            48.79031214869127
                        ],
                        [
                            9.286837577819822,
                            48.79039696865249
                        ],
                        [
                            9.286601543426514,
                            48.79051006171105
                        ],
                        [
                            9.286043643951416,
                            48.79058074474321
                        ],
                        [
                            9.28443431854248,
                            48.78985977314325
                        ],
                        [
                            9.281601905822754,
                            48.7900152776979
                        ],
                        [
                            9.27713871002197,
                            48.78993045709148
                        ],
                        [
                            9.275701045989988,
                            48.790538334935874
                        ],
                        [
                            9.273319244384766,
                            48.7893508457766
                        ],
                        [
                            9.274392127990723,
                            48.79092002191152
                        ],
                        [
                            9.2744779586792,
                            48.79251742108274
                        ],
                        [
                            9.273383617401123,
                            48.79429853187003
                        ],
                        [
                            9.271495342254639,
                            48.7937189710143
                        ],
                        [
                            9.267010688781738,
                            48.79260223731597
                        ],
                        [
                            9.265508651733398,
                            48.79241846862943
                        ],
                        [
                            9.265401363372803,
                            48.791443069717964
                        ],
                        [
                            9.263191223144531,
                            48.792361924282766
                        ],
                        [
                            9.264371395111084,
                            48.793733106724574
                        ],
                        [
                            9.262869358062744,
                            48.79428439631906
                        ],
                        [
                            9.26274061203003,
                            48.79513252232774
                        ],
                        [
                            9.260444641113281,
                            48.79559898552089
                        ],
                        [
                            9.259629249572752,
                            48.79578274255762
                        ],
                        [
                            9.258599281311033,
                            48.79568379654455
                        ],
                        [
                            9.25746202468872,
                            48.7952880105407
                        ],
                        [
                            9.254071712493896,
                            48.79451056465666
                        ],
                        [
                            9.252762794494627,
                            48.79381792090263
                        ],
                        [
                            9.251196384429932,
                            48.79280014130255
                        ],
                        [
                            9.249908924102783,
                            48.7935210706521
                        ],
                        [
                            9.248085021972656,
                            48.7950477103721
                        ],
                        [
                            9.246861934661865,
                            48.79492049216977
                        ],
                        [
                            9.246668815612793,
                            48.795853418161755
                        ],
                        [
                            9.24975872039795,
                            48.79616438963705
                        ],
                        [
                            9.2494797706604,
                            48.79750719887327
                        ],
                        [
                            9.250874519348143,
                            48.797874698294045
                        ],
                        [
                            9.250338077545164,
                            48.799047851505925
                        ],
                        [
                            9.255380630493164,
                            48.802100748121546
                        ],
                        [
                            9.255316257476807,
                            48.80393805003233
                        ],
                        [
                            9.254372119903564,
                            48.80393805003233
                        ],
                        [
                            9.253878593444824,
                            48.805916607587164
                        ],
                        [
                            9.253149032592773,
                            48.80646776329037
                        ],
                        [
                            9.253041744232178,
                            48.80813532570663
                        ],
                        [
                            9.252312183380125,
                            48.81019850414747
                        ],
                        [
                            9.25274133682251,
                            48.81031155256769
                        ],
                        [
                            9.25673246383667,
                            48.81101809941938
                        ],
                        [
                            9.256196022033691,
                            48.8119789871598
                        ],
                        [
                            9.256775379180908,
                            48.81206377048808
                        ],
                        [
                            9.256281852722168,
                            48.81309528949853
                        ],
                        [
                            9.255294799804688,
                            48.813024638188544
                        ],
                        [
                            9.254930019378662,
                            48.81416917715238
                        ],
                        [
                            9.254393577575684,
                            48.815214782502125
                        ],
                        [
                            9.255659580230713,
                            48.81548324522208
                        ],
                        [
                            9.255080223083496,
                            48.81683966645563
                        ],
                        [
                            9.254157543182373,
                            48.81664185731164
                        ],
                        [
                            9.253149032592773,
                            48.81945349969508
                        ],
                        [
                            9.250187873840332,
                            48.81867642837445
                        ],
                        [
                            9.24973726272583,
                            48.819566527247275
                        ],
                        [
                            9.24875020980835,
                            48.819396985823424
                        ],
                        [
                            9.247806072235107,
                            48.821502084523495
                        ],
                        [
                            9.24593925476074,
                            48.82131842171434
                        ],
                        [
                            9.245381355285645,
                            48.82307025494908
                        ],
                        [
                            9.243385791778564,
                            48.82290072537667
                        ],
                        [
                            9.243042469024658,
                            48.824511233161545
                        ],
                        [
                            9.243922233581543,
                            48.82470901125121
                        ],
                        [
                            9.24349308013916,
                            48.82589566339821
                        ],
                        [
                            9.241433143615723,
                            48.82589566339821
                        ],
                        [
                            9.240810871124268,
                            48.82877741591594
                        ],
                        [
                            9.241905212402344,
                            48.82879154174558
                        ],
                        [
                            9.241905212402344,
                            48.83072674276482
                        ],
                        [
                            9.242033958435057,
                            48.83266186904296
                        ],
                        [
                            9.242570400238035,
                            48.833933079205224
                        ],
                        [
                            9.243557453155516,
                            48.83444155423872
                        ],
                        [
                            9.244437217712402,
                            48.83459692058069
                        ],
                        [
                            9.244179725646973,
                            48.83509126482687
                        ],
                        [
                            9.239501953125,
                            48.83605169113135
                        ],
                        [
                            9.240145683288572,
                            48.838791629682696
                        ],
                        [
                            9.237098693847656,
                            48.841955083650454
                        ],
                        [
                            9.244222640991211,
                            48.84681285608177
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--09-002",
            "properties": {
                "land": "de",
                "city": "Stuttgart (only EURO-6 diesel)",
                "zone": "Umweltzone",
                "area_code": 2,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 5,
                "minOUTb": 1,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--e5--white.svg",
                "color": 4,
                "urlZone": "https://www.stuttgart.de/leben/mobilitaet/dieselverkehrsverbot/dieselverkehrsverbot.php",
                "urlPark": "https://www.vvs.de/rundum-mobil/park-ride",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.138479232788086,
                            48.840119174874026
                        ],
                        [
                            9.138071537017822,
                            48.83973786230376
                        ],
                        [
                            9.140324592590332,
                            48.83753466616981
                        ],
                        [
                            9.137921333312988,
                            48.834907651819165
                        ],
                        [
                            9.13839340209961,
                            48.82800048915231
                        ],
                        [
                            9.139852523803711,
                            48.82379074923428
                        ],
                        [
                            9.141268730163574,
                            48.817320056841375
                        ],
                        [
                            9.13637638092041,
                            48.81734831495599
                        ],
                        [
                            9.132685661315918,
                            48.816896183210545
                        ],
                        [
                            9.128093719482422,
                            48.817207024223606
                        ],
                        [
                            9.12740707397461,
                            48.816175589812474
                        ],
                        [
                            9.129531383514403,
                            48.81542672687419
                        ],
                        [
                            9.12865161895752,
                            48.81416917715238
                        ],
                        [
                            9.130089282989502,
                            48.81347680485208
                        ],
                        [
                            9.133243560791016,
                            48.81275616230256
                        ],
                        [
                            9.133007526397705,
                            48.81227572818154
                        ],
                        [
                            9.131784439086914,
                            48.81227572818154
                        ],
                        [
                            9.1306471824646,
                            48.81203550939458
                        ],
                        [
                            9.129831790924072,
                            48.811371369111605
                        ],
                        [
                            9.129316806793213,
                            48.81043873173578
                        ],
                        [
                            9.131612777709961,
                            48.80789508708266
                        ],
                        [
                            9.133822917938232,
                            48.80256714651934
                        ],
                        [
                            9.133286476135254,
                            48.80214314815514
                        ],
                        [
                            9.127836227416992,
                            48.8009417999911
                        ],
                        [
                            9.123201370239256,
                            48.79811498492119
                        ],
                        [
                            9.121978282928467,
                            48.79875103220171
                        ],
                        [
                            9.120283126831053,
                            48.79777575640715
                        ],
                        [
                            9.121441841125488,
                            48.796828731330706
                        ],
                        [
                            9.116935729980469,
                            48.79381792090263
                        ],
                        [
                            9.114832878112793,
                            48.79213574625888
                        ],
                        [
                            9.110949039459227,
                            48.78670716779815
                        ],
                        [
                            9.107794761657715,
                            48.784416810109846
                        ],
                        [
                            9.103353023529053,
                            48.78160320296786
                        ],
                        [
                            9.106121063232422,
                            48.78093865966207
                        ],
                        [
                            9.107322692871094,
                            48.78187184563666
                        ],
                        [
                            9.109575748443604,
                            48.782352571034195
                        ],
                        [
                            9.11313772201538,
                            48.782663626190775
                        ],
                        [
                            9.116227626800537,
                            48.783285730720586
                        ],
                        [
                            9.11813735961914,
                            48.78404921210442
                        ],
                        [
                            9.120090007781982,
                            48.7848550965204
                        ],
                        [
                            9.121935367584229,
                            48.78520855051499
                        ],
                        [
                            9.122235774993896,
                            48.7854488978094
                        ],
                        [
                            9.124917984008789,
                            48.78577407172843
                        ],
                        [
                            9.129037857055662,
                            48.78633958656911
                        ],
                        [
                            9.132728576660156,
                            48.78691923266707
                        ],
                        [
                            9.133522510528564,
                            48.78727267212374
                        ],
                        [
                            9.136161804199219,
                            48.78797954356908
                        ],
                        [
                            9.137020111083984,
                            48.78792299421989
                        ],
                        [
                            9.137749671936033,
                            48.7874140472094
                        ],
                        [
                            9.138994216918945,
                            48.787385772224155
                        ],
                        [
                            9.13963794708252,
                            48.78793713156316
                        ],
                        [
                            9.141697883605955,
                            48.78913879118371
                        ],
                        [
                            9.144787788391113,
                            48.79045351521363
                        ],
                        [
                            9.146246910095215,
                            48.79243260470613
                        ],
                        [
                            9.14659023284912,
                            48.79261637334092
                        ],
                        [
                            9.146182537078857,
                            48.79073624706346
                        ],
                        [
                            9.147727489471436,
                            48.78974667861898
                        ],
                        [
                            9.150710105895996,
                            48.78744232217875
                        ],
                        [
                            9.146311283111572,
                            48.78403507366584
                        ],
                        [
                            9.145731925964355,
                            48.78454405494594
                        ],
                        [
                            9.145302772521973,
                            48.78525096482708
                        ],
                        [
                            9.144229888916016,
                            48.78550544994666
                        ],
                        [
                            9.142770767211914,
                            48.785236826727036
                        ],
                        [
                            9.14238452911377,
                            48.7844875017253
                        ],
                        [
                            9.142835140228271,
                            48.783936104484134
                        ],
                        [
                            9.143671989440916,
                            48.782451543338574
                        ],
                        [
                            9.142298698425293,
                            48.781716315845784
                        ],
                        [
                            9.139809608459473,
                            48.781179027405386
                        ],
                        [
                            9.138457775115967,
                            48.780203410007424
                        ],
                        [
                            9.136977195739746,
                            48.77823797824928
                        ],
                        [
                            9.136483669281006,
                            48.777417847278045
                        ],
                        [
                            9.135990142822266,
                            48.775056360854954
                        ],
                        [
                            9.135861396789549,
                            48.77429274267509
                        ],
                        [
                            9.135475158691406,
                            48.77246849667395
                        ],
                        [
                            9.134852886199951,
                            48.77082803281537
                        ],
                        [
                            9.134273529052734,
                            48.77002192317643
                        ],
                        [
                            9.133930206298826,
                            48.769088516900005
                        ],
                        [
                            9.134016036987305,
                            48.767787376170105
                        ],
                        [
                            9.133694171905518,
                            48.7666842087071
                        ],
                        [
                            9.134080410003662,
                            48.7649869806726
                        ],
                        [
                            9.133951663970947,
                            48.76447780107696
                        ],
                        [
                            9.132857322692871,
                            48.76412420109819
                        ],
                        [
                            9.13038969039917,
                            48.764095912992346
                        ],
                        [
                            9.12541151046753,
                            48.76464752818245
                        ],
                        [
                            9.121119976043701,
                            48.7651849935656
                        ],
                        [
                            9.12013292312622,
                            48.76324726240768
                        ],
                        [
                            9.117279052734373,
                            48.7632189738079
                        ],
                        [
                            9.117364883422852,
                            48.759371575797466
                        ],
                        [
                            9.118373394012451,
                            48.75913110363646
                        ],
                        [
                            9.118523597717285,
                            48.75819749492412
                        ],
                        [
                            9.121613502502441,
                            48.75733459871444
                        ],
                        [
                            9.122686386108398,
                            48.7577306840609
                        ],
                        [
                            9.124703407287598,
                            48.75726386885967
                        ],
                        [
                            9.124317169189453,
                            48.75562290827208
                        ],
                        [
                            9.12637710571289,
                            48.75379798397896
                        ],
                        [
                            9.12712812423706,
                            48.75290671778968
                        ],
                        [
                            9.129166603088379,
                            48.75215691017849
                        ],
                        [
                            9.13038969039917,
                            48.749765939482074
                        ],
                        [
                            9.130411148071289,
                            48.74888875010797
                        ],
                        [
                            9.132428169250488,
                            48.749072678181015
                        ],
                        [
                            9.134273529052734,
                            48.74829451634778
                        ],
                        [
                            9.135282039642334,
                            48.747120176584886
                        ],
                        [
                            9.136440753936768,
                            48.74672400758264
                        ],
                        [
                            9.137749671936033,
                            48.74999230844764
                        ],
                        [
                            9.138522148132324,
                            48.751704190731395
                        ],
                        [
                            9.138929843902588,
                            48.75306233683503
                        ],
                        [
                            9.140002727508545,
                            48.75378383702008
                        ],
                        [
                            9.1416335105896,
                            48.753698955183154
                        ],
                        [
                            9.14339303970337,
                            48.753698955183154
                        ],
                        [
                            9.144294261932373,
                            48.75268036195413
                        ],
                        [
                            9.146504402160645,
                            48.753090631155146
                        ],
                        [
                            9.146783351898193,
                            48.754080922319964
                        ],
                        [
                            9.14837121963501,
                            48.75372724914473
                        ],
                        [
                            9.15137529373169,
                            48.75311892545931
                        ],
                        [
                            9.152555465698242,
                            48.7535857791775
                        ],
                        [
                            9.152534008026123,
                            48.7543921526547
                        ],
                        [
                            9.153671264648438,
                            48.75524095285887
                        ],
                        [
                            9.156074523925781,
                            48.75613217763898
                        ],
                        [
                            9.157190322875977,
                            48.755679494012234
                        ],
                        [
                            9.158134460449219,
                            48.753557485136255
                        ],
                        [
                            9.158477783203125,
                            48.75130805786895
                        ],
                        [
                            9.16036605834961,
                            48.75331698514229
                        ],
                        [
                            9.161031246185303,
                            48.75412336293369
                        ],
                        [
                            9.164443016052246,
                            48.75235497365386
                        ],
                        [
                            9.167168140411377,
                            48.75169004318296
                        ],
                        [
                            9.168133735656738,
                            48.750218676393416
                        ],
                        [
                            9.16884183883667,
                            48.74990742020507
                        ],
                        [
                            9.169453382492065,
                            48.74983667989336
                        ],
                        [
                            9.170064926147461,
                            48.75009134454939
                        ],
                        [
                            9.170869588851929,
                            48.75004182652291
                        ],
                        [
                            9.17159914970398,
                            48.75033893394979
                        ],
                        [
                            9.172500371932983,
                            48.750437969368356
                        ],
                        [
                            9.172961711883545,
                            48.75049456094847
                        ],
                        [
                            9.17330503463745,
                            48.75077044398873
                        ],
                        [
                            9.17284369468689,
                            48.75102510391221
                        ],
                        [
                            9.172693490982056,
                            48.75132220552496
                        ],
                        [
                            9.172768592834473,
                            48.75207909931383
                        ],
                        [
                            9.172918796539307,
                            48.75253888903903
                        ],
                        [
                            9.172811508178711,
                            48.752708656489325
                        ],
                        [
                            9.173047542572021,
                            48.753019895325
                        ],
                        [
                            9.174013137817383,
                            48.753868718713605
                        ],
                        [
                            9.174474477767944,
                            48.75403848167041
                        ],
                        [
                            9.175150394439697,
                            48.75381213093385
                        ],
                        [
                            9.175665378570557,
                            48.75284305531398
                        ],
                        [
                            9.177360534667969,
                            48.75282890808623
                        ],
                        [
                            9.178014993667603,
                            48.75256011000169
                        ],
                        [
                            9.178884029388428,
                            48.75299160096506
                        ],
                        [
                            9.180761575698853,
                            48.75349382348519
                        ],
                        [
                            9.183433055877686,
                            48.754257757973946
                        ],
                        [
                            9.1850745677948,
                            48.75473874780375
                        ],
                        [
                            9.187896251678467,
                            48.75623827477395
                        ],
                        [
                            9.189870357513428,
                            48.75732045275146
                        ],
                        [
                            9.190878868103027,
                            48.75749020404446
                        ],
                        [
                            9.19156551361084,
                            48.7573982554986
                        ],
                        [
                            9.19229507446289,
                            48.75723557688985
                        ],
                        [
                            9.193110466003418,
                            48.757256795868706
                        ],
                        [
                            9.19379711151123,
                            48.75737703657954
                        ],
                        [
                            9.194279909133911,
                            48.757667027698005
                        ],
                        [
                            9.194462299346924,
                            48.75807018330073
                        ],
                        [
                            9.194526672363281,
                            48.75908866725325
                        ],
                        [
                            9.19506311416626,
                            48.759661555401664
                        ],
                        [
                            9.19778823852539,
                            48.7608002396048
                        ],
                        [
                            9.199365377426147,
                            48.7615499182175
                        ],
                        [
                            9.199837446212769,
                            48.76218642901084
                        ],
                        [
                            9.20078158378601,
                            48.76227836879205
                        ],
                        [
                            9.201189279556274,
                            48.761797451151224
                        ],
                        [
                            9.202959537506104,
                            48.7622571519344
                        ],
                        [
                            9.203978776931763,
                            48.76282293173775
                        ],
                        [
                            9.205373525619507,
                            48.76349478697619
                        ],
                        [
                            9.205352067947388,
                            48.76378474277635
                        ],
                        [
                            9.205963611602783,
                            48.76391911196979
                        ],
                        [
                            9.206897020339966,
                            48.764081768933444
                        ],
                        [
                            9.206972122192381,
                            48.7644990169965
                        ],
                        [
                            9.205394983291626,
                            48.76505062775907
                        ],
                        [
                            9.204118251800537,
                            48.765460795936946
                        ],
                        [
                            9.20499801635742,
                            48.7654678677727
                        ],
                        [
                            9.206585884094238,
                            48.76596289380114
                        ],
                        [
                            9.208238124847412,
                            48.76635183940031
                        ],
                        [
                            9.207841157913208,
                            48.76653570354402
                        ],
                        [
                            9.208817481994629,
                            48.76716507955512
                        ],
                        [
                            9.210416078567505,
                            48.767978306540044
                        ],
                        [
                            9.211649894714355,
                            48.76856523609425
                        ],
                        [
                            9.21302318572998,
                            48.76918044404597
                        ],
                        [
                            9.213194847106934,
                            48.76986635666862
                        ],
                        [
                            9.213570356369019,
                            48.77067953990692
                        ],
                        [
                            9.21403169631958,
                            48.77121694073242
                        ],
                        [
                            9.214160442352295,
                            48.77152806489716
                        ],
                        [
                            9.213817119598389,
                            48.77172605199823
                        ],
                        [
                            9.21278715133667,
                            48.772001818445105
                        ],
                        [
                            9.212422370910645,
                            48.7721573783367
                        ],
                        [
                            9.212379455566406,
                            48.77238364640955
                        ],
                        [
                            9.212368726730347,
                            48.77354325427823
                        ],
                        [
                            9.213227033615112,
                            48.77466041213749
                        ],
                        [
                            9.214460849761963,
                            48.774335166212005
                        ],
                        [
                            9.215276241302488,
                            48.77419375428283
                        ],
                        [
                            9.215909242630005,
                            48.77418668367591
                        ],
                        [
                            9.216992855072021,
                            48.77469576482853
                        ],
                        [
                            9.217818975448608,
                            48.775424024724984
                        ],
                        [
                            9.217851161956787,
                            48.77591188224171
                        ],
                        [
                            9.218698740005493,
                            48.77600379689527
                        ],
                        [
                            9.218698740005493,
                            48.77695828527268
                        ],
                        [
                            9.220168590545653,
                            48.777127970195366
                        ],
                        [
                            9.219578504562378,
                            48.77784205462573
                        ],
                        [
                            9.220812320709229,
                            48.77814606768642
                        ],
                        [
                            9.22152042388916,
                            48.778075367138925
                        ],
                        [
                            9.222571849822998,
                            48.777969316131006
                        ],
                        [
                            9.223076105117798,
                            48.77843593889001
                        ],
                        [
                            9.223140478134155,
                            48.779248983333765
                        ],
                        [
                            9.223580360412596,
                            48.779991316759414
                        ],
                        [
                            9.223794937133789,
                            48.78019634024694
                        ],
                        [
                            9.224213361740112,
                            48.78023875879497
                        ],
                        [
                            9.224374294281006,
                            48.780062014608326
                        ],
                        [
                            9.224857091903687,
                            48.780182200722976
                        ],
                        [
                            9.22528624534607,
                            48.78062052411417
                        ],
                        [
                            9.225833415985107,
                            48.7802882470557
                        ],
                        [
                            9.226627349853516,
                            48.77997717717766
                        ],
                        [
                            9.227389097213745,
                            48.77981457170144
                        ],
                        [
                            9.22723889350891,
                            48.780097363495436
                        ],
                        [
                            9.227335453033447,
                            48.78033773526766
                        ],
                        [
                            9.22701358795166,
                            48.78054275734019
                        ],
                        [
                            9.227657318115234,
                            48.78112247039287
                        ],
                        [
                            9.227099418640137,
                            48.78121437550584
                        ],
                        [
                            9.227421283721924,
                            48.781829428468754
                        ],
                        [
                            9.227936267852781,
                            48.78204151394977
                        ],
                        [
                            9.229127168655394,
                            48.78296054067828
                        ],
                        [
                            9.228923320770262,
                            48.783632126491504
                        ],
                        [
                            9.230049848556519,
                            48.78438146426479
                        ],
                        [
                            9.228483438491821,
                            48.78532165526751
                        ],
                        [
                            9.228976964950562,
                            48.7860638988804
                        ],
                        [
                            9.230189323425293,
                            48.78571045091009
                        ],
                        [
                            9.231133460998535,
                            48.78595786475067
                        ],
                        [
                            9.232850074768066,
                            48.78720905320526
                        ],
                        [
                            9.235221147537231,
                            48.7883895244449
                        ],
                        [
                            9.236723184585571,
                            48.78894794128389
                        ],
                        [
                            9.238461256027222,
                            48.7895134203576
                        ],
                        [
                            9.238890409469604,
                            48.7898951151298
                        ],
                        [
                            9.239051342010498,
                            48.79073624706346
                        ],
                        [
                            9.239448308944702,
                            48.79134411514574
                        ],
                        [
                            9.239877462387085,
                            48.79164097827688
                        ],
                        [
                            9.240381717681885,
                            48.79141479700294
                        ],
                        [
                            9.240628480911255,
                            48.791937839651546
                        ],
                        [
                            9.240199327468872,
                            48.79279307331649
                        ],
                        [
                            9.241647720336914,
                            48.79351400276759
                        ],
                        [
                            9.240821599960327,
                            48.794213718504466
                        ],
                        [
                            9.23999547958374,
                            48.79506184570799
                        ],
                        [
                            9.240381717681885,
                            48.795181995902325
                        ],
                        [
                            9.241057634353638,
                            48.795075981039915
                        ],
                        [
                            9.24175500869751,
                            48.79510425169179
                        ],
                        [
                            9.24248456954956,
                            48.7951749282518
                        ],
                        [
                            9.243428707122803,
                            48.79523853707067
                        ],
                        [
                            9.244383573532104,
                            48.79577567499172
                        ],
                        [
                            9.24519896507263,
                            48.79627746969557
                        ],
                        [
                            9.245526194572449,
                            48.795849884383905
                        ],
                        [
                            9.246534705162048,
                            48.79576153985695
                        ],
                        [
                            9.24975335597992,
                            48.79617145714816
                        ],
                        [
                            9.24948513507843,
                            48.79751779985581
                        ],
                        [
                            9.250895977020264,
                            48.79790296736875
                        ],
                        [
                            9.250316619873047,
                            48.799076119919484
                        ],
                        [
                            9.255380630493164,
                            48.80212901481458
                        ],
                        [
                            9.255316257476807,
                            48.803952182863085
                        ],
                        [
                            9.254350662231445,
                            48.80396631568988
                        ],
                        [
                            9.253900051116943,
                            48.80594487212955
                        ],
                        [
                            9.253149032592773,
                            48.806453631168516
                        ],
                        [
                            9.253041744232178,
                            48.80817772063843
                        ],
                        [
                            9.252269268035887,
                            48.81021263521396
                        ],
                        [
                            9.256689548492432,
                            48.81104636108637
                        ],
                        [
                            9.25617456436157,
                            48.81199311772448
                        ],
                        [
                            9.256775379180908,
                            48.81209203156565
                        ],
                        [
                            9.256324768066406,
                            48.81309528949853
                        ],
                        [
                            9.25525188446045,
                            48.8130528987245
                        ],
                        [
                            9.254930019378662,
                            48.814183307099746
                        ],
                        [
                            9.254415035247803,
                            48.815214782502125
                        ],
                        [
                            9.255638122558594,
                            48.81552563394117
                        ],
                        [
                            9.255058765411377,
                            48.81688205402781
                        ],
                        [
                            9.254157543182373,
                            48.81664185731164
                        ],
                        [
                            9.253170490264893,
                            48.81948175660701
                        ],
                        [
                            9.250209331512451,
                            48.81870468572444
                        ],
                        [
                            9.249715805053711,
                            48.81960891251362
                        ],
                        [
                            9.24875020980835,
                            48.819467628153056
                        ],
                        [
                            9.247827529907227,
                            48.82151621240401
                        ],
                        [
                            9.245896339416504,
                            48.821332549646634
                        ],
                        [
                            9.245381355285645,
                            48.82305612750662
                        ],
                        [
                            9.243428707122803,
                            48.82292898034524
                        ],
                        [
                            9.243106842041016,
                            48.82449710612528
                        ],
                        [
                            9.243922233581543,
                            48.82472313822776
                        ],
                        [
                            9.24347162246704,
                            48.8259239166783
                        ],
                        [
                            9.241433143615723,
                            48.82593804331235
                        ],
                        [
                            9.240810871124268,
                            48.82880566757125
                        ],
                        [
                            9.241883754730225,
                            48.82880566757125
                        ],
                        [
                            9.241905212402344,
                            48.8306561163045
                        ],
                        [
                            9.240617752075195,
                            48.82979446547264
                        ],
                        [
                            9.238117933273315,
                            48.82961083305205
                        ],
                        [
                            9.23348307609558,
                            48.829547267826605
                        ],
                        [
                            9.230929613113403,
                            48.82959670745337
                        ],
                        [
                            9.226444959640503,
                            48.82956845624404
                        ],
                        [
                            9.22426700592041,
                            48.829660272616124
                        ],
                        [
                            9.222968816757202,
                            48.82982977932254
                        ],
                        [
                            9.222561120986938,
                            48.82913056046265
                        ],
                        [
                            9.222410917282104,
                            48.82867853499683
                        ],
                        [
                            9.222571849822998,
                            48.827357749724854
                        ],
                        [
                            9.222636222839355,
                            48.82675031807337
                        ],
                        [
                            9.222228527069092,
                            48.82542241858904
                        ],
                        [
                            9.22175645828247,
                            48.82473020171454
                        ],
                        [
                            9.224213361740112,
                            48.82347994904826
                        ],
                        [
                            9.225897789001465,
                            48.82234268648366
                        ],
                        [
                            9.226927757263184,
                            48.821473828750506
                        ],
                        [
                            9.22751784324646,
                            48.82030120012392
                        ],
                        [
                            9.227120876312256,
                            48.819396985823424
                        ],
                        [
                            9.225683212280273,
                            48.81799118276005
                        ],
                        [
                            9.22451376914978,
                            48.81738363757687
                        ],
                        [
                            9.222335815429688,
                            48.81616146042661
                        ],
                        [
                            9.221574068069458,
                            48.815991907485675
                        ],
                        [
                            9.221048355102539,
                            48.81645817669333
                        ],
                        [
                            9.22026515007019,
                            48.8161967838838
                        ],
                        [
                            9.219321012496948,
                            48.81699508737833
                        ],
                        [
                            9.218323230743408,
                            48.818280823801864
                        ],
                        [
                            9.216907024383545,
                            48.81953827038312
                        ],
                        [
                            9.21480417251587,
                            48.82065440440466
                        ],
                        [
                            9.212915897369385,
                            48.821417317156744
                        ],
                        [
                            9.212121963500977,
                            48.82131842171434
                        ],
                        [
                            9.211070537567139,
                            48.821191270144524
                        ],
                        [
                            9.209632873535156,
                            48.82051312299102
                        ],
                        [
                            9.205706119537354,
                            48.81884597223549
                        ],
                        [
                            9.204236268997192,
                            48.817885216107115
                        ],
                        [
                            9.203506708145142,
                            48.81739070209806
                        ],
                        [
                            9.202755689620972,
                            48.81806182707091
                        ],
                        [
                            9.202079772949217,
                            48.81861284927871
                        ],
                        [
                            9.200448989868164,
                            48.819396985823424
                        ],
                        [
                            9.199891090393066,
                            48.8197431322873
                        ],
                        [
                            9.200180768966675,
                            48.82022349484811
                        ],
                        [
                            9.199891090393066,
                            48.820612020022374
                        ],
                        [
                            9.199655055999754,
                            48.82130429377807
                        ],
                        [
                            9.199461936950684,
                            48.8219329830878
                        ],
                        [
                            9.198453426361084,
                            48.822731195230816
                        ],
                        [
                            9.197144508361816,
                            48.82405916602563
                        ],
                        [
                            9.19657588005066,
                            48.824511233161545
                        ],
                        [
                            9.194580316543579,
                            48.82583915679028
                        ],
                        [
                            9.194869995117188,
                            48.82621351188064
                        ],
                        [
                            9.196017980575562,
                            48.826926897800945
                        ],
                        [
                            9.197337627410889,
                            48.827717967342494
                        ],
                        [
                            9.198496341705322,
                            48.828283009369194
                        ],
                        [
                            9.199858903884888,
                            48.82929300611839
                        ],
                        [
                            9.203442335128784,
                            48.82961789584992
                        ],
                        [
                            9.203324317932129,
                            48.830444236326244
                        ],
                        [
                            9.203356504440306,
                            48.83155306495408
                        ],
                        [
                            9.202755689620972,
                            48.832633619561896
                        ],
                        [
                            9.201629161834715,
                            48.8337070886449
                        ],
                        [
                            9.199193716049194,
                            48.83587514355962
                        ],
                        [
                            9.198668003082274,
                            48.836411846248424
                        ],
                        [
                            9.199440479278564,
                            48.836800245923726
                        ],
                        [
                            9.198614358901978,
                            48.837485234375855
                        ],
                        [
                            9.198914766311644,
                            48.838085474288256
                        ],
                        [
                            9.198635816574097,
                            48.83839618388828
                        ],
                        [
                            9.200448989868164,
                            48.83892579808222
                        ],
                        [
                            9.202862977981566,
                            48.83988615087048
                        ],
                        [
                            9.20253038406372,
                            48.84088179130599
                        ],
                        [
                            9.201650619506836,
                            48.840902974930025
                        ],
                        [
                            9.20102834701538,
                            48.8414466848806
                        ],
                        [
                            9.20228362083435,
                            48.84215985391929
                        ],
                        [
                            9.202895164489746,
                            48.84189859515306
                        ],
                        [
                            9.205169677734375,
                            48.842647062572524
                        ],
                        [
                            9.20676827430725,
                            48.84392508014017
                        ],
                        [
                            9.207326173782349,
                            48.84420751056173
                        ],
                        [
                            9.208688735961914,
                            48.84441227162139
                        ],
                        [
                            9.20926809310913,
                            48.84475824530259
                        ],
                        [
                            9.209343194961548,
                            48.84559845714905
                        ],
                        [
                            9.208871126174927,
                            48.84648101743714
                        ],
                        [
                            9.209429025650024,
                            48.84668576920108
                        ],
                        [
                            9.208828210830688,
                            48.84763891294455
                        ],
                        [
                            9.206854104995728,
                            48.84833787349268
                        ],
                        [
                            9.205695390701294,
                            48.850455876181805
                        ],
                        [
                            9.204890727996826,
                            48.851776052530816
                        ],
                        [
                            9.201854467391968,
                            48.8512253949698
                        ],
                        [
                            9.199966192245483,
                            48.85111243882412
                        ],
                        [
                            9.199633598327637,
                            48.851197155957266
                        ],
                        [
                            9.188690185546875,
                            48.849594565896844
                        ],
                        [
                            9.185364246368408,
                            48.84914272506833
                        ],
                        [
                            9.184699058532715,
                            48.848281392194565
                        ],
                        [
                            9.182231426239014,
                            48.84672813153049
                        ],
                        [
                            9.181501865386963,
                            48.84497712537673
                        ],
                        [
                            9.181952476501465,
                            48.84397450557891
                        ],
                        [
                            9.1810941696167,
                            48.843254301505475
                        ],
                        [
                            9.179012775421143,
                            48.84340964051497
                        ],
                        [
                            9.176244735717773,
                            48.84098064814147
                        ],
                        [
                            9.174613952636719,
                            48.84050048454143
                        ],
                        [
                            9.17208194732666,
                            48.84072644445604
                        ],
                        [
                            9.17032241821289,
                            48.84119248356046
                        ],
                        [
                            9.167704582214355,
                            48.841121871853694
                        ],
                        [
                            9.163177013397217,
                            48.840571097124254
                        ],
                        [
                            9.155881404876709,
                            48.83994970297896
                        ],
                        [
                            9.151482582092285,
                            48.84007680695404
                        ],
                        [
                            9.145817756652832,
                            48.83965312578284
                        ],
                        [
                            9.14161205291748,
                            48.83942716102615
                        ],
                        [
                            9.138479232788086,
                            48.840119174874026
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--10",
            "properties": {
                "land": "de",
                "city": "München",
                "zone": "Umweltzone",
                "area_code": 1,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-5 (diesel)",
                "minEUd": 5,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "from 1.2.2023 min. EURO-5 diesel on RING!",
                "img_sign": "sign-de--white",
                "color": 3,
                "urlZone": "https://www.muenchen.de/verkehr/autos/umweltzone-und-diesel-fahrverbot-muenchen-alle-infos",
                "urlPark": "https://www.mvv-muenchen.de/plaene-bahnhoefe/park-ride/index.html",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            11.546536263711829,
                            48.17654599539296
                        ],
                        [
                            11.544666672262935,
                            48.1766679563832
                        ],
                        [
                            11.541760676858416,
                            48.17676281473081
                        ],
                        [
                            11.540134945163771,
                            48.176478239162066
                        ],
                        [
                            11.538570178406815,
                            48.175109544121995
                        ],
                        [
                            11.538366961945513,
                            48.17219586370163
                        ],
                        [
                            11.53802151333528,
                            48.17031205695673
                        ],
                        [
                            11.537554115472375,
                            48.167411657581056
                        ],
                        [
                            11.537310255718126,
                            48.16471441003711
                        ],
                        [
                            11.537107052684092,
                            48.160089469145134
                        ],
                        [
                            11.536659976468542,
                            48.15430090725013
                        ],
                        [
                            11.536438107921384,
                            48.15048356142958
                        ],
                        [
                            11.536133283228565,
                            48.147622717448115
                        ],
                        [
                            11.535604920427147,
                            48.146497318053605
                        ],
                        [
                            11.535584598781497,
                            48.146497318053605
                        ],
                        [
                            11.534649803056624,
                            48.14549392911016
                        ],
                        [
                            11.534249030818472,
                            48.14454253643359
                        ],
                        [
                            11.534147422587807,
                            48.143647588832636
                        ],
                        [
                            11.533822276248543,
                            48.141328788370714
                        ],
                        [
                            11.533720668017878,
                            48.140379541591216
                        ],
                        [
                            11.533862919541491,
                            48.13900988316138
                        ],
                        [
                            11.533883231826252,
                            48.138413184825026
                        ],
                        [
                            11.53416773487342,
                            48.13743676099895
                        ],
                        [
                            11.533639372071974,
                            48.13513124216601
                        ],
                        [
                            11.533293904087088,
                            48.13391063145937
                        ],
                        [
                            11.532399751655134,
                            48.13297481026697
                        ],
                        [
                            11.529412469665658,
                            48.13125231218285
                        ],
                        [
                            11.526912925254322,
                            48.129895982619104
                        ],
                        [
                            11.524636900881063,
                            48.12859386408735
                        ],
                        [
                            11.522543771324422,
                            48.12714250556769
                        ],
                        [
                            11.521243185969013,
                            48.12613873830125
                        ],
                        [
                            11.520572573499095,
                            48.125243471328105
                        ],
                        [
                            11.519536169543557,
                            48.12400906046756
                        ],
                        [
                            11.518113654311094,
                            48.12175720160246
                        ],
                        [
                            11.517402415412931,
                            48.119980075543964
                        ],
                        [
                            11.516548906273101,
                            48.11766020613021
                        ],
                        [
                            11.51630504651888,
                            48.116154269915086
                        ],
                        [
                            11.516325368165354,
                            48.11304728906504
                        ],
                        [
                            11.516670829598695,
                            48.11197539811167
                        ],
                        [
                            11.518093344831215,
                            48.11014364561365
                        ],
                        [
                            11.519272000309485,
                            48.10960089159397
                        ],
                        [
                            11.522909574976723,
                            48.10951947799654
                        ],
                        [
                            11.529476915565112,
                            48.10965516901598
                        ],
                        [
                            11.53329738504803,
                            48.11013007859526
                        ],
                        [
                            11.53557340942038,
                            48.110292903726474
                        ],
                        [
                            11.539150019149048,
                            48.11034717865576
                        ],
                        [
                            11.541588593571703,
                            48.110428599549095
                        ],
                        [
                            11.544006869467722,
                            48.11125628380353
                        ],
                        [
                            11.545856139270171,
                            48.11166333674353
                        ],
                        [
                            11.549229532536572,
                            48.11194827188305
                        ],
                        [
                            11.552582602288112,
                            48.11200254393228
                        ],
                        [
                            11.555427632753094,
                            48.11192113413961
                        ],
                        [
                            11.559207459062407,
                            48.1122060678523
                        ],
                        [
                            11.562845033729559,
                            48.11270809006896
                        ],
                        [
                            11.564978828220944,
                            48.1127895088901
                        ],
                        [
                            11.567600320579032,
                            48.112355328488434
                        ],
                        [
                            11.569002514165078,
                            48.1117990294874
                        ],
                        [
                            11.570668889152728,
                            48.111473388253216
                        ],
                        [
                            11.572010117801057,
                            48.111554798755236
                        ],
                        [
                            11.573757779372897,
                            48.11170405100708
                        ],
                        [
                            11.575200616251891,
                            48.11144625139076
                        ],
                        [
                            11.577314067455035,
                            48.11068641341771
                        ],
                        [
                            11.57843096783941,
                            48.1091812335591
                        ],
                        [
                            11.579203190394253,
                            48.1077428959388
                        ],
                        [
                            11.580036377887637,
                            48.10618238978586
                        ],
                        [
                            11.581255676658856,
                            48.10504251186998
                        ],
                        [
                            11.58164178793669,
                            48.104608266014424
                        ],
                        [
                            11.582129507445188,
                            48.10421472753842
                        ],
                        [
                            11.583673952554818,
                            48.10441828229858
                        ],
                        [
                            11.587291205575553,
                            48.10437757141102
                        ],
                        [
                            11.590076516742727,
                            48.10468969952291
                        ],
                        [
                            11.594039237749172,
                            48.10584315517991
                        ],
                        [
                            11.596945233153662,
                            48.10644022794028
                        ],
                        [
                            11.598184853570501,
                            48.1069965849515
                        ],
                        [
                            11.602411755976874,
                            48.10789217139262
                        ],
                        [
                            11.604748777147478,
                            48.108421392448776
                        ],
                        [
                            11.608528603338243,
                            48.108814898715764
                        ],
                        [
                            11.611272025572646,
                            48.10956119542965
                        ],
                        [
                            11.613974804514982,
                            48.110646698575835
                        ],
                        [
                            11.616108577364571,
                            48.11212565970976
                        ],
                        [
                            11.61785621653172,
                            48.11417443674708
                        ],
                        [
                            11.619847737857782,
                            48.11833955628762
                        ],
                        [
                            11.620936831909802,
                            48.12080161966384
                        ],
                        [
                            11.62146519471034,
                            48.1237182192414
                        ],
                        [
                            11.620875866971204,
                            48.12531888722975
                        ],
                        [
                            11.619534638322904,
                            48.12621415435228
                        ],
                        [
                            11.617502466199426,
                            48.127543463326106
                        ],
                        [
                            11.61577991129846,
                            48.12942352730653
                        ],
                        [
                            11.61657245549975,
                            48.13170217625836
                        ],
                        [
                            11.617100808781487,
                            48.13350603719908
                        ],
                        [
                            11.61559700696398,
                            48.13654397179931
                        ],
                        [
                            11.615048322516913,
                            48.138334099090855
                        ],
                        [
                            11.615275107920326,
                            48.13998532179343
                        ],
                        [
                            11.616311511875892,
                            48.14517888291229
                        ],
                        [
                            11.616941476261218,
                            48.149197260010396
                        ],
                        [
                            11.616921154614744,
                            48.15037683284052
                        ],
                        [
                            11.615986358890751,
                            48.15203090079646
                        ],
                        [
                            11.613364866532606,
                            48.15361712899983
                        ],
                        [
                            11.610913842314773,
                            48.15486400802547
                        ],
                        [
                            11.604857991751572,
                            48.157331316696855
                        ],
                        [
                            11.604553167058754,
                            48.158944492830926
                        ],
                        [
                            11.602236499394337,
                            48.15981206238118
                        ],
                        [
                            11.600256062189175,
                            48.16131281210829
                        ],
                        [
                            11.597431353368904,
                            48.162993637186645
                        ],
                        [
                            11.596415271059811,
                            48.16462018966209
                        ],
                        [
                            11.597004598798975,
                            48.16608404279046
                        ],
                        [
                            11.598929320490214,
                            48.168455799688616
                        ],
                        [
                            11.600636338778202,
                            48.17090890197866
                        ],
                        [
                            11.600616017132552,
                            48.17200666112558
                        ],
                        [
                            11.5969784424654,
                            48.17409369395054
                        ],
                        [
                            11.594214685801035,
                            48.17567924375521
                        ],
                        [
                            11.591491585212253,
                            48.17665494004828
                        ],
                        [
                            11.58771175902234,
                            48.17786098341591
                        ],
                        [
                            11.58450093892506,
                            48.17801004298562
                        ],
                        [
                            11.57852635249074,
                            48.17805069574163
                        ],
                        [
                            11.572612753451182,
                            48.17841656710391
                        ],
                        [
                            11.568487459276298,
                            48.17738670030434
                        ],
                        [
                            11.565114066008988,
                            48.17651942800563
                        ],
                        [
                            11.555228686580818,
                            48.17664849256903
                        ],
                        [
                            11.550920497590369,
                            48.17666204377761
                        ],
                        [
                            11.549355730834236,
                            48.17723119129511
                        ],
                        [
                            11.548095790232708,
                            48.17693306607896
                        ],
                        [
                            11.546536263711829,
                            48.17654599539296
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--11",
            "properties": {
                "land": "de",
                "city": "Bremen",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://umwelt.bremen.de/umwelt/luft/umweltzone-bremen-24570",
                "urlPark": "https://vmz.bremen.de/parken/park-and-ride",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.811925649642944,
                            53.06360374065239
                        ],
                        [
                            8.812944889068604,
                            53.06344255887641
                        ],
                        [
                            8.814328908920288,
                            53.066949738099495
                        ],
                        [
                            8.813728094100952,
                            53.067239841239825
                        ],
                        [
                            8.81629228591919,
                            53.070050517151884
                        ],
                        [
                            8.817869424819945,
                            53.06964439871299
                        ],
                        [
                            8.822461366653442,
                            53.0688837221262
                        ],
                        [
                            8.827042579650879,
                            53.06867743463432
                        ],
                        [
                            8.831570148468018,
                            53.06850337879449
                        ],
                        [
                            8.833683729171753,
                            53.07138487934389
                        ],
                        [
                            8.835132122039795,
                            53.073441134330245
                        ],
                        [
                            8.836376667022705,
                            53.07525236224208
                        ],
                        [
                            8.837138414382935,
                            53.076760586422246
                        ],
                        [
                            8.832460641860962,
                            53.07739222016904
                        ],
                        [
                            8.833222389221191,
                            53.07948685760068
                        ],
                        [
                            8.83326530456543,
                            53.080150675273835
                        ],
                        [
                            8.828469514846802,
                            53.08236762322717
                        ],
                        [
                            8.824596405029297,
                            53.084107589614064
                        ],
                        [
                            8.820583820343016,
                            53.08394648456991
                        ],
                        [
                            8.817161321640015,
                            53.08317317196446
                        ],
                        [
                            8.816410303115845,
                            53.08401737086363
                        ],
                        [
                            8.811464309692383,
                            53.08629855835039
                        ],
                        [
                            8.808460235595703,
                            53.087812839207345
                        ],
                        [
                            8.80678653717041,
                            53.08720068953181
                        ],
                        [
                            8.805241584777832,
                            53.085931256667074
                        ],
                        [
                            8.806829452514648,
                            53.08459090112856
                        ],
                        [
                            8.806153535842894,
                            53.084043147668766
                        ],
                        [
                            8.804104328155518,
                            53.085080651260746
                        ],
                        [
                            8.80323529243469,
                            53.08424936155412
                        ],
                        [
                            8.802012205123901,
                            53.084719783282566
                        ],
                        [
                            8.797581195831299,
                            53.083186060621735
                        ],
                        [
                            8.796776533126833,
                            53.08231606759473
                        ],
                        [
                            8.792903423309326,
                            53.082515845326554
                        ],
                        [
                            8.790167570114136,
                            53.07920328188219
                        ],
                        [
                            8.787935972213745,
                            53.07571643684765
                        ],
                        [
                            8.785918951034546,
                            53.072938360048326
                        ],
                        [
                            8.784191608428955,
                            53.07173941307079
                        ],
                        [
                            8.781863451004027,
                            53.07012142632786
                        ],
                        [
                            8.780168294906616,
                            53.06863230911375
                        ],
                        [
                            8.78276467323303,
                            53.06687882370135
                        ],
                        [
                            8.785060644149782,
                            53.06544761725449
                        ],
                        [
                            8.788450956344604,
                            53.0628622994891
                        ],
                        [
                            8.79204511642456,
                            53.06030261757335
                        ],
                        [
                            8.798160552978516,
                            53.05615006449249
                        ],
                        [
                            8.810058832168577,
                            53.06228847974173
                        ],
                        [
                            8.809618949890137,
                            53.0628622994891
                        ],
                        [
                            8.811925649642944,
                            53.06360374065239
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Darmstadt",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.darmstadt.de/leben-in-darmstadt/umwelt/luftreinhaltung",
                "urlPark": "https://www.darmstadt.de/leben-in-darmstadt/mobilitaet-und-verkehr/parken",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                8.667891025543213,
                                49.94634589156723
                            ],
                            [
                                8.655252456665039,
                                49.94412273415381
                            ],
                            [
                                8.649802207946777,
                                49.94528265517067
                            ],
                            [
                                8.640747070312498,
                                49.94332182000844
                            ],
                            [
                                8.633290529251099,
                                49.94413654290162
                            ],
                            [
                                8.633430004119871,
                                49.94343229171672
                            ],
                            [
                                8.633033037185669,
                                49.943031830568906
                            ],
                            [
                                8.632324934005737,
                                49.93747336227211
                            ],
                            [
                                8.632035255432127,
                                49.93548458680074
                            ],
                            [
                                8.630930185317993,
                                49.93452469704815
                            ],
                            [
                                8.629696369171143,
                                49.93364075288293
                            ],
                            [
                                8.629170656204224,
                                49.93024984791979
                            ],
                            [
                                8.627142906188963,
                                49.924938562106334
                            ],
                            [
                                8.625233173370361,
                                49.91804475935977
                            ],
                            [
                                8.625447750091553,
                                49.916676933811345
                            ],
                            [
                                8.618817329406738,
                                49.915626859588265
                            ],
                            [
                                8.619117736816406,
                                49.912393592728215
                            ],
                            [
                                8.625297546386719,
                                49.91329174415673
                            ],
                            [
                                8.627207279205322,
                                49.90764002049756
                            ],
                            [
                                8.63576889038086,
                                49.9006884595659
                            ],
                            [
                                8.63800048828125,
                                49.900301464513824
                            ],
                            [
                                8.641948699951172,
                                49.90349408086041
                            ],
                            [
                                8.641884326934814,
                                49.905166319390915
                            ],
                            [
                                8.643622398376465,
                                49.90812369024422
                            ],
                            [
                                8.644437789916992,
                                49.916925632251704
                            ],
                            [
                                8.644394874572754,
                                49.92017241086631
                            ],
                            [
                                8.64875078201294,
                                49.92142961535176
                            ],
                            [
                                8.653621673583984,
                                49.92231378348566
                            ],
                            [
                                8.658363819122314,
                                49.93112694804592
                            ],
                            [
                                8.662097454071045,
                                49.93916513790181
                            ],
                            [
                                8.667891025543213,
                                49.94634589156723
                            ]
                        ],
                        [
                            [
                                8.6252760887146,
                                49.8929756155455
                            ],
                            [
                                8.616585731506348,
                                49.889740830179875
                            ],
                            [
                                8.61903190612793,
                                49.887998932871014
                            ],
                            [
                                8.616435527801514,
                                49.886574954245475
                            ],
                            [
                                8.603518009185791,
                                49.88284199534933
                            ],
                            [
                                8.60203742980957,
                                49.87241583329345
                            ],
                            [
                                8.610363006591797,
                                49.868474323662454
                            ],
                            [
                                8.618474006652832,
                                49.86891689516031
                            ],
                            [
                                8.61901044845581,
                                49.867768966956106
                            ],
                            [
                                8.613967895507812,
                                49.86602627681585
                            ],
                            [
                                8.605964183807373,
                                49.8662475742869
                            ],
                            [
                                8.600599765777588,
                                49.86534854700419
                            ],
                            [
                                8.599934577941895,
                                49.86064567009334
                            ],
                            [
                                8.60055685043335,
                                49.85710437829439
                            ],
                            [
                                8.609740734100342,
                                49.85036690744938
                            ],
                            [
                                8.622164726257324,
                                49.84437571842794
                            ],
                            [
                                8.629395961761475,
                                49.84083323427772
                            ],
                            [
                                8.633215427398682,
                                49.83688914774683
                            ],
                            [
                                8.634631633758545,
                                49.832626404796834
                            ],
                            [
                                8.634674549102783,
                                49.82974745673615
                            ],
                            [
                                8.634116649627686,
                                49.82787881700774
                            ],
                            [
                                8.632700443267822,
                                49.825331814016245
                            ],
                            [
                                8.631112575531006,
                                49.82285389437034
                            ],
                            [
                                8.630447387695312,
                                49.821746403211144
                            ],
                            [
                                8.629825115203857,
                                49.82036200361088
                            ],
                            [
                                8.629395961761475,
                                49.819005253570346
                            ],
                            [
                                8.629117012023926,
                                49.817080818366044
                            ],
                            [
                                8.629374504089355,
                                49.8157932038756
                            ],
                            [
                                8.63049030303955,
                                49.81597319505783
                            ],
                            [
                                8.632035255432127,
                                49.81399325523203
                            ],
                            [
                                8.633601665496826,
                                49.812304011763786
                            ],
                            [
                                8.634202480316162,
                                49.81042085118441
                            ],
                            [
                                8.633580207824707,
                                49.80637734709829
                            ],
                            [
                                8.634331226348877,
                                49.80454935082837
                            ],
                            [
                                8.636391162872313,
                                49.803192157434765
                            ],
                            [
                                8.644030094146729,
                                49.80126709338689
                            ],
                            [
                                8.647012710571289,
                                49.801641032681275
                            ],
                            [
                                8.649351596832275,
                                49.80272128553066
                            ],
                            [
                                8.652763366699219,
                                49.80301211871808
                            ],
                            [
                                8.657634258270264,
                                49.80373226956769
                            ],
                            [
                                8.659136295318604,
                                49.80467398913046
                            ],
                            [
                                8.663856983184814,
                                49.81157014172188
                            ],
                            [
                                8.665251731872559,
                                49.81273325134745
                            ],
                            [
                                8.666989803314209,
                                49.81371633411398
                            ],
                            [
                                8.670530319213867,
                                49.814353250316564
                            ],
                            [
                                8.672161102294922,
                                49.81418709907186
                            ],
                            [
                                8.67321252822876,
                                49.815280917615716
                            ],
                            [
                                8.677310943603516,
                                49.81893603060948
                            ],
                            [
                                8.679285049438477,
                                49.81979438832474
                            ],
                            [
                                8.67943525314331,
                                49.82102652036266
                            ],
                            [
                                8.675765991210938,
                                49.82083270391951
                            ],
                            [
                                8.672075271606445,
                                49.82231399559724
                            ],
                            [
                                8.672246932983398,
                                49.82516570046719
                            ],
                            [
                                8.672869205474854,
                                49.82880622500742
                            ],
                            [
                                8.673341274261475,
                                49.83338763645338
                            ],
                            [
                                8.674392700195312,
                                49.83637708484799
                            ],
                            [
                                8.690443038940428,
                                49.84545501747498
                            ],
                            [
                                8.705205917358398,
                                49.851708942674435
                            ],
                            [
                                8.716878890991211,
                                49.86133729835112
                            ],
                            [
                                8.7286376953125,
                                49.8638824050825
                            ],
                            [
                                8.71964693069458,
                                49.86508575125364
                            ],
                            [
                                8.713960647583008,
                                49.8643941766576
                            ],
                            [
                                8.707029819488525,
                                49.86460165007597
                            ],
                            [
                                8.700270652770996,
                                49.86385474159968
                            ],
                            [
                                8.694219589233398,
                                49.86406221733479
                            ],
                            [
                                8.686151504516602,
                                49.867478522965484
                            ],
                            [
                                8.687589168548584,
                                49.868488154083146
                            ],
                            [
                                8.690807819366455,
                                49.86854347572632
                            ],
                            [
                                8.69767427444458,
                                49.86663484242169
                            ],
                            [
                                8.704798221588135,
                                49.86604010793751
                            ],
                            [
                                8.714089393615723,
                                49.86729872342943
                            ],
                            [
                                8.727843761444092,
                                49.86870944027562
                            ],
                            [
                                8.725461959838867,
                                49.88257929477767
                            ],
                            [
                                8.749237060546875,
                                49.903756667606714
                            ],
                            [
                                8.742284774780273,
                                49.91514326508463
                            ],
                            [
                                8.741254806518555,
                                49.9216644740939
                            ],
                            [
                                8.739795684814453,
                                49.92812954782584
                            ],
                            [
                                8.730096817016602,
                                49.93304684379022
                            ],
                            [
                                8.732843399047852,
                                49.939013225782986
                            ],
                            [
                                8.730955123901367,
                                49.95276623594377
                            ],
                            [
                                8.69619369506836,
                                49.94895555413248
                            ],
                            [
                                8.67344856262207,
                                49.951551268687936
                            ],
                            [
                                8.666324615478516,
                                49.941885664747346
                            ],
                            [
                                8.661947250366211,
                                49.93492522890987
                            ],
                            [
                                8.657398223876953,
                                49.9257535958617
                            ],
                            [
                                8.655681610107422,
                                49.92183025604657
                            ],
                            [
                                8.653707504272461,
                                49.921001340582436
                            ],
                            [
                                8.646411895751953,
                                49.91973030919019
                            ],
                            [
                                8.645317554473877,
                                49.91381680955162
                            ],
                            [
                                8.644158840179442,
                                49.90495902024137
                            ],
                            [
                                8.642227649688719,
                                49.90180796348792
                            ],
                            [
                                8.636798858642578,
                                49.898214401973675
                            ],
                            [
                                8.634803295135498,
                                49.89666632376184
                            ],
                            [
                                8.629653453826904,
                                49.89055645889729
                            ],
                            [
                                8.628602027893066,
                                49.890252327766774
                            ],
                            [
                                8.6252760887146,
                                49.8929756155455
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--12-001",
            "properties": {
                "land": "de",
                "city": "Darmstadt Huegelstrasse",
                "zone": "only EURO-6 diesel",
                "area_code": 7,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 4,
                "minOUTb": 1,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--e5--white.svg",
                "color": 2,
                "urlZone": "https://www.darmstadt.de/leben-in-darmstadt/umwelt/luftreinhaltung",
                "urlPark": "https://www.darmstadt.de/leben-in-darmstadt/mobilitaet-und-verkehr/parken",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.652291297912598,
                            49.86923499091962
                        ],
                        [
                            8.65446925163269,
                            49.86956691469582
                        ],
                        [
                            8.655112981796265,
                            49.8696464377618
                        ],
                        [
                            8.65648090839386,
                            49.86986771864772
                        ],
                        [
                            8.656400442123413,
                            49.87002676365825
                        ],
                        [
                            8.654614090919495,
                            49.869743248274126
                        ],
                        [
                            8.6522376537323,
                            49.86939403801368
                        ],
                        [
                            8.652291297912598,
                            49.86923499091962
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--12-002",
            "properties": {
                "land": "de",
                "city": "Darmstadt Heinrichstrasse",
                "zone": "only EURO-6 diesel",
                "area_code": 6,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 4,
                "minOUTb": 1,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--e5--white.svg",
                "color": 2,
                "urlZone": "https://www.darmstadt.de/leben-in-darmstadt/umwelt/luftreinhaltung",
                "urlPark": "https://www.darmstadt.de/leben-in-darmstadt/mobilitaet-und-verkehr/parken",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.647688627243042,
                            49.865448823953244
                        ],
                        [
                            8.64775836467743,
                            49.86531396869766
                        ],
                        [
                            8.65571916103363,
                            49.86640663121796
                        ],
                        [
                            8.655703067779541,
                            49.86659334955578
                        ],
                        [
                            8.647688627243042,
                            49.865448823953244
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--13-001",
            "properties": {
                "land": "de",
                "city": "Hamburg: Max Brauer Alle",
                "zone": "only EURO-6 diesel",
                "area_code": 4,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--e5--white.svg",
                "color": 2,
                "urlZone": "https://www.hamburg.de/durchfahrtsbeschraenkungen/11067546/dieseldurchfahrtsbeschraenkungen-faq/",
                "urlPark": "https://www.pr.hamburg/",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.94235873222351,
                            53.55554891534326
                        ],
                        [
                            9.942723512649536,
                            53.555325846047
                        ],
                        [
                            9.94562029838562,
                            53.55679808166897
                        ],
                        [
                            9.947572946548462,
                            53.5578177819401
                        ],
                        [
                            9.94913935661316,
                            53.558353114744186
                        ],
                        [
                            9.949010610580444,
                            53.55870999951695
                        ],
                        [
                            9.94788408279419,
                            53.5583276228595
                        ],
                        [
                            9.946349859237671,
                            53.557683947680665
                        ],
                        [
                            9.944751262664795,
                            53.556810828074035
                        ],
                        [
                            9.94235873222351,
                            53.55554891534326
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--13-002",
            "properties": {
                "land": "de",
                "city": "Hamburg: Stresemannstraße",
                "zone": "only EURO-6 diesel trucks",
                "area_code": 5,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--e5--white.svg",
                "color": 0,
                "urlZone": "https://www.hamburg.de/durchfahrtsbeschraenkungen/11067546/dieseldurchfahrtsbeschraenkungen-faq/",
                "urlPark": "https://www.pr.hamburg/",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.94235873222351,
                            53.55554891534326
                        ],
                        [
                            9.942723512649536,
                            53.555325846047
                        ],
                        [
                            9.94562029838562,
                            53.55679808166897
                        ],
                        [
                            9.947572946548462,
                            53.5578177819401
                        ],
                        [
                            9.94913935661316,
                            53.558353114744186
                        ],
                        [
                            9.949010610580444,
                            53.55870999951695
                        ],
                        [
                            9.94788408279419,
                            53.5583276228595
                        ],
                        [
                            9.946349859237671,
                            53.557683947680665
                        ],
                        [
                            9.944751262664795,
                            53.556810828074035
                        ],
                        [
                            9.94235873222351,
                            53.55554891534326
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Düsseldorf",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.duesseldorf.de/kfz/feinstaub.html",
                "urlPark": "https://www.duesseldorf.de/verkehrsmanagement/mobil-in-duesseldorf/mit-dem-auto/parken-in-duesseldorf",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                6.723289489746094,
                                51.25315936735953
                            ],
                            [
                                6.715521812438965,
                                51.245261885765125
                            ],
                            [
                                6.710028648376465,
                                51.23953939366008
                            ],
                            [
                                6.70213222503662,
                                51.236530098127325
                            ],
                            [
                                6.709556579589844,
                                51.23443422245537
                            ],
                            [
                                6.708440780639648,
                                51.233171276913914
                            ],
                            [
                                6.70191764831543,
                                51.23370870478573
                            ],
                            [
                                6.698741912841796,
                                51.234783541697624
                            ],
                            [
                                6.689815521240234,
                                51.230134691511154
                            ],
                            [
                                6.694879531860351,
                                51.22502847670378
                            ],
                            [
                                6.7009735107421875,
                                51.22467908340353
                            ],
                            [
                                6.7061662673950195,
                                51.228602886556295
                            ],
                            [
                                6.708698272705078,
                                51.22887162795567
                            ],
                            [
                                6.7105865478515625,
                                51.2310483754519
                            ],
                            [
                                6.712946891784668,
                                51.22887162795567
                            ],
                            [
                                6.718268394470214,
                                51.22720540598115
                            ],
                            [
                                6.721218824386597,
                                51.227763059568105
                            ],
                            [
                                6.730542182922363,
                                51.23112899375215
                            ],
                            [
                                6.7424726486206055,
                                51.22822664596061
                            ],
                            [
                                6.752858161926269,
                                51.22508222928381
                            ],
                            [
                                6.7638444900512695,
                                51.2196260222239
                            ],
                            [
                                6.750798225402832,
                                51.21615844125664
                            ],
                            [
                                6.748781204223633,
                                51.213497097331874
                            ],
                            [
                                6.752214431762695,
                                51.21269059910518
                            ],
                            [
                                6.75959587097168,
                                51.21062052233151
                            ],
                            [
                                6.756677627563477,
                                51.20131747027607
                            ],
                            [
                                6.763930320739746,
                                51.191124976330606
                            ],
                            [
                                6.755690574645996,
                                51.18372795561305
                            ],
                            [
                                6.766762733459473,
                                51.18461566078443
                            ],
                            [
                                6.773543357849121,
                                51.18639101983867
                            ],
                            [
                                6.776418685913086,
                                51.19327661398496
                            ],
                            [
                                6.778478622436523,
                                51.19394898014829
                            ],
                            [
                                6.786246299743652,
                                51.193007664772495
                            ],
                            [
                                6.793069839477539,
                                51.18709038493268
                            ],
                            [
                                6.802811622619629,
                                51.18286713427125
                            ],
                            [
                                6.811952590942383,
                                51.17503830237215
                            ],
                            [
                                6.81950569152832,
                                51.171136841746275
                            ],
                            [
                                6.82950496673584,
                                51.168849625147615
                            ],
                            [
                                6.836585998535156,
                                51.169872159530186
                            ],
                            [
                                6.839118003845214,
                                51.17124447267384
                            ],
                            [
                                6.836113929748534,
                                51.1734239449078
                            ],
                            [
                                6.836940050125122,
                                51.175172829609565
                            ],
                            [
                                6.838066577911376,
                                51.17511901876169
                            ],
                            [
                                6.8381524085998535,
                                51.175657124414315
                            ],
                            [
                                6.835716962814331,
                                51.17571093463414
                            ],
                            [
                                6.835566759109497,
                                51.17691492188076
                            ],
                            [
                                6.834933757781982,
                                51.17717051289297
                            ],
                            [
                                6.834858655929565,
                                51.17856278641992
                            ],
                            [
                                6.8358564376831055,
                                51.182638475900106
                            ],
                            [
                                6.837787628173828,
                                51.182033198266076
                            ],
                            [
                                6.839096546173095,
                                51.18249051987906
                            ],
                            [
                                6.843012571334839,
                                51.18698279099359
                            ],
                            [
                                6.842760443687439,
                                51.18708702262589
                            ],
                            [
                                6.843650937080383,
                                51.18807553025864
                            ],
                            [
                                6.838839054107666,
                                51.18826045280174
                            ],
                            [
                                6.838281154632568,
                                51.190452568959735
                            ],
                            [
                                6.8437957763671875,
                                51.191541863973235
                            ],
                            [
                                6.844654083251953,
                                51.19015670660829
                            ],
                            [
                                6.855812072753906,
                                51.190775325722214
                            ],
                            [
                                6.857399940490723,
                                51.19265802844942
                            ],
                            [
                                6.860854625701904,
                                51.19050636191047
                            ],
                            [
                                6.866755485534668,
                                51.19039877594621
                            ],
                            [
                                6.869909763336182,
                                51.191582207738506
                            ],
                            [
                                6.873579025268555,
                                51.1902911897308
                            ],
                            [
                                6.882805824279785,
                                51.19047946544296
                            ],
                            [
                                6.880896091461182,
                                51.191541863973235
                            ],
                            [
                                6.881732940673828,
                                51.19236218025778
                            ],
                            [
                                6.880295276641846,
                                51.19314213957498
                            ],
                            [
                                6.878407001495361,
                                51.193411088002534
                            ],
                            [
                                6.874029636383057,
                                51.19479614754489
                            ],
                            [
                                6.870617866516113,
                                51.19626184482432
                            ],
                            [
                                6.866583824157715,
                                51.197176201161334
                            ],
                            [
                                6.863279342651367,
                                51.19973092415222
                            ],
                            [
                                6.852765083312988,
                                51.203508965304806
                            ],
                            [
                                6.855382919311523,
                                51.20485338917941
                            ],
                            [
                                6.86521053314209,
                                51.2052432647629
                            ],
                            [
                                6.8686652183532715,
                                51.21025757720507
                            ],
                            [
                                6.8675923347473145,
                                51.21278469129283
                            ],
                            [
                                6.866133213043213,
                                51.214585847530635
                            ],
                            [
                                6.876325607299805,
                                51.21662886483988
                            ],
                            [
                                6.873214244842528,
                                51.22010984996657
                            ],
                            [
                                6.873278617858887,
                                51.22209886615063
                            ],
                            [
                                6.871926784515381,
                                51.22260954562905
                            ],
                            [
                                6.873278617858887,
                                51.22418858448747
                            ],
                            [
                                6.872125267982482,
                                51.22422218047039
                            ],
                            [
                                6.871792674064636,
                                51.22505871253775
                            ],
                            [
                                6.865918636322021,
                                51.2243162490921
                            ],
                            [
                                6.865854263305663,
                                51.226211018962694
                            ],
                            [
                                6.865575313568115,
                                51.22926130019719
                            ],
                            [
                                6.866412162780762,
                                51.234474528657145
                            ],
                            [
                                6.864116191864014,
                                51.235267210116255
                            ],
                            [
                                6.863150596618652,
                                51.236153922344826
                            ],
                            [
                                6.862013339996337,
                                51.23643605447005
                            ],
                            [
                                6.861927509307861,
                                51.23697344420803
                            ],
                            [
                                6.859266757965088,
                                51.236960009541086
                            ],
                            [
                                6.853644847869873,
                                51.23729587503783
                            ],
                            [
                                6.8469929695129395,
                                51.23910950635014
                            ],
                            [
                                6.843624114990234,
                                51.24018421709266
                            ],
                            [
                                6.841907501220703,
                                51.240869332085985
                            ],
                            [
                                6.8392252922058105,
                                51.24022451825707
                            ],
                            [
                                6.837015151977539,
                                51.2393916203504
                            ],
                            [
                                6.832573413848877,
                                51.24131263635018
                            ],
                            [
                                6.830577850341797,
                                51.24180966938424
                            ],
                            [
                                6.8312644958496085,
                                51.243408198674906
                            ],
                            [
                                6.826372146606444,
                                51.24770648928639
                            ],
                            [
                                6.824333667755127,
                                51.25195065706575
                            ],
                            [
                                6.8245697021484375,
                                51.256019855138916
                            ],
                            [
                                6.82349681854248,
                                51.26069294523185
                            ],
                            [
                                6.828904151916504,
                                51.26817158644597
                            ],
                            [
                                6.832852363586426,
                                51.272534683883336
                            ],
                            [
                                6.833174228668213,
                                51.27343410169575
                            ],
                            [
                                6.829440593719482,
                                51.27304480360965
                            ],
                            [
                                6.826565265655518,
                                51.272991107063056
                            ],
                            [
                                6.8245697021484375,
                                51.27023907502864
                            ],
                            [
                                6.820192337036133,
                                51.26921876761326
                            ],
                            [
                                6.815793514251709,
                                51.2682252886248
                            ],
                            [
                                6.807875633239745,
                                51.2721453781766
                            ],
                            [
                                6.8071675300598145,
                                51.27409187372548
                            ],
                            [
                                6.805922985076903,
                                51.27432007814775
                            ],
                            [
                                6.79553747177124,
                                51.275085225880346
                            ],
                            [
                                6.796202659606933,
                                51.276373866942414
                            ],
                            [
                                6.797683238983153,
                                51.27657521384333
                            ],
                            [
                                6.799657344818115,
                                51.276991327975495
                            ],
                            [
                                6.802103519439697,
                                51.27915237623786
                            ],
                            [
                                6.8027472496032715,
                                51.28045432518449
                            ],
                            [
                                6.8036699295043945,
                                51.28316548187486
                            ],
                            [
                                6.803433895111084,
                                51.28670853453168
                            ],
                            [
                                6.800644397735596,
                                51.2886678326403
                            ],
                            [
                                6.798713207244873,
                                51.28916435381581
                            ],
                            [
                                6.796524524688721,
                                51.290439180894424
                            ],
                            [
                                6.792726516723633,
                                51.29062704715597
                            ],
                            [
                                6.788520812988281,
                                51.28955351530856
                            ],
                            [
                                6.7850446701049805,
                                51.28708429681189
                            ],
                            [
                                6.780645847320556,
                                51.281903874411206
                            ],
                            [
                                6.776204109191894,
                                51.276763136821344
                            ],
                            [
                                6.776289939880371,
                                51.27552820032234
                            ],
                            [
                                6.77480936050415,
                                51.27474963633835
                            ],
                            [
                                6.771547794342041,
                                51.27343410169575
                            ],
                            [
                                6.7678141593933105,
                                51.27195743631006
                            ],
                            [
                                6.7626214027404785,
                                51.27104456488048
                            ],
                            [
                                6.758544445037842,
                                51.27124593513722
                            ],
                            [
                                6.752471923828125,
                                51.271232510480914
                            ],
                            [
                                6.749253273010254,
                                51.27053442294712
                            ],
                            [
                                6.74335241317749,
                                51.268896560564926
                            ],
                            [
                                6.740584373474121,
                                51.26876230696134
                            ],
                            [
                                6.739039421081543,
                                51.267500303915845
                            ],
                            [
                                6.733052730560303,
                                51.26630539675111
                            ],
                            [
                                6.734576225280762,
                                51.26477479612158
                            ],
                            [
                                6.736657619476318,
                                51.265392413036416
                            ],
                            [
                                6.740391254425049,
                                51.26272048884518
                            ],
                            [
                                6.747064590454102,
                                51.264667383636976
                            ],
                            [
                                6.748867034912109,
                                51.259658997832254
                            ],
                            [
                                6.743202209472656,
                                51.25882645185525
                            ],
                            [
                                6.742236614227295,
                                51.25768503367118
                            ],
                            [
                                6.742622852325439,
                                51.25448891198401
                            ],
                            [
                                6.755862236022949,
                                51.25095680479889
                            ],
                            [
                                6.760861873626709,
                                51.2483646295714
                            ],
                            [
                                6.7517852783203125,
                                51.24307237780318
                            ],
                            [
                                6.74609899520874,
                                51.24644390808839
                            ],
                            [
                                6.739318370819092,
                                51.24860639302491
                            ],
                            [
                                6.739768981933594,
                                51.24919736500501
                            ],
                            [
                                6.729469299316405,
                                51.251682050463245
                            ],
                            [
                                6.723289489746094,
                                51.25315936735953
                            ]
                        ],
                        [
                            [
                                6.835126876831055,
                                51.274440891794804
                            ],
                            [
                                6.836371421813965,
                                51.277125557536706
                            ],
                            [
                                6.831071376800537,
                                51.2787899715122
                            ],
                            [
                                6.827638149261475,
                                51.27374285300487
                            ],
                            [
                                6.835126876831055,
                                51.274440891794804
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--15",
            "properties": {
                "land": "de",
                "city": "Hannover",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.hannover.de/Leben-in-der-Region-Hannover/Umwelt-Nachhaltigkeit/Umweltinformation/Luft,-L%C3%A4rm-und-Strahlung/Umweltzone",
                "urlPark": "https://www.gvh.de/service/rad-auto-carsharing/park-ride-bike-ride/",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.69045639038086,
                            52.38636568080987
                        ],
                        [
                            9.69144344329834,
                            52.38461073636162
                        ],
                        [
                            9.693803787231445,
                            52.382122262704264
                        ],
                        [
                            9.696464538574219,
                            52.38070769936552
                        ],
                        [
                            9.698953628540039,
                            52.37751166718865
                        ],
                        [
                            9.700026512145996,
                            52.376437538867776
                        ],
                        [
                            9.699554443359375,
                            52.37512759092267
                        ],
                        [
                            9.701356887817383,
                            52.3718001484518
                        ],
                        [
                            9.70268726348877,
                            52.3711451105601
                        ],
                        [
                            9.70341682434082,
                            52.36897031509554
                        ],
                        [
                            9.707107543945312,
                            52.36472522512735
                        ],
                        [
                            9.71118450164795,
                            52.363860434566206
                        ],
                        [
                            9.715690612792967,
                            52.3623928724539
                        ],
                        [
                            9.716033935546875,
                            52.36092526159479
                        ],
                        [
                            9.718651771545408,
                            52.35536886460695
                        ],
                        [
                            9.720282554626465,
                            52.353481627451735
                        ],
                        [
                            9.719982147216797,
                            52.347190254901975
                        ],
                        [
                            9.720110893249512,
                            52.34566970551891
                        ],
                        [
                            9.721741676330566,
                            52.34244491911374
                        ],
                        [
                            9.721827507019043,
                            52.33992784922043
                        ],
                        [
                            9.722042083740234,
                            52.33835460777523
                        ],
                        [
                            9.726462364196777,
                            52.33688619859419
                        ],
                        [
                            9.73088264465332,
                            52.3366764218738
                        ],
                        [
                            9.765129089355469,
                            52.343519874049804
                        ],
                        [
                            9.772424697875977,
                            52.34336256520376
                        ],
                        [
                            9.777960777282715,
                            52.344463715372406
                        ],
                        [
                            9.784097671508789,
                            52.34669214965716
                        ],
                        [
                            9.78954792022705,
                            52.3495758390546
                        ],
                        [
                            9.790019989013672,
                            52.35075547595449
                        ],
                        [
                            9.790878295898438,
                            52.352328276184785
                        ],
                        [
                            9.785127639770508,
                            52.355866872083986
                        ],
                        [
                            9.781265258789062,
                            52.3626811474303
                        ],
                        [
                            9.77804660797119,
                            52.36731949524728
                        ],
                        [
                            9.777274131774902,
                            52.37088309268338
                        ],
                        [
                            9.77705955505371,
                            52.374917995645625
                        ],
                        [
                            9.778389930725098,
                            52.37759026091806
                        ],
                        [
                            9.782724380493164,
                            52.38188650529498
                        ],
                        [
                            9.784784317016602,
                            52.38843485404289
                        ],
                        [
                            9.786972999572754,
                            52.39157771643836
                        ],
                        [
                            9.788861274719238,
                            52.393044308974325
                        ],
                        [
                            9.791779518127441,
                            52.3943013495033
                        ],
                        [
                            9.791994094848633,
                            52.39807225627109
                        ],
                        [
                            9.7908353805542,
                            52.40344000517297
                        ],
                        [
                            9.789204597473145,
                            52.40990662061531
                        ],
                        [
                            9.780106544494629,
                            52.41001133533261
                        ],
                        [
                            9.754786491394043,
                            52.4100375139731
                        ],
                        [
                            9.752168655395508,
                            52.41008987120748
                        ],
                        [
                            9.747533798217773,
                            52.41082286596284
                        ],
                        [
                            9.741182327270508,
                            52.41092757850496
                        ],
                        [
                            9.73865032196045,
                            52.41205322263206
                        ],
                        [
                            9.733457565307617,
                            52.41213175487117
                        ],
                        [
                            9.733972549438477,
                            52.40951393821122
                        ],
                        [
                            9.721570014953612,
                            52.41380707599438
                        ],
                        [
                            9.716248512268066,
                            52.41241970521828
                        ],
                        [
                            9.715132713317871,
                            52.410220764021474
                        ],
                        [
                            9.714531898498535,
                            52.405691646863964
                        ],
                        [
                            9.706764221191406,
                            52.40323054428956
                        ],
                        [
                            9.70865249633789,
                            52.400638383557414
                        ],
                        [
                            9.702858924865723,
                            52.3991196731887
                        ],
                        [
                            9.70165729522705,
                            52.395610728648734
                        ],
                        [
                            9.693632125854492,
                            52.396422524208994
                        ],
                        [
                            9.693889617919922,
                            52.39197055850332
                        ],
                        [
                            9.687752723693848,
                            52.39228482963781
                        ],
                        [
                            9.688310623168945,
                            52.38958726290467
                        ],
                        [
                            9.69045639038086,
                            52.38636568080987
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Heidelberg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on March 1, 2023.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://www.heidelberg.de/hd/HD/service/22_02_2023+regierungspraesidium+karlsruhe+hebt+umweltzone+ab+1_+maerz+auf.html",
                "urlPark": "http://parken.heidelberg.de/",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                8.679376244544983,
                                49.43900930658586
                            ],
                            [
                                8.677257299423218,
                                49.438587226393956
                            ],
                            [
                                8.6742103099823,
                                49.43805351648767
                            ],
                            [
                                8.676983714103699,
                                49.435374414305066
                            ],
                            [
                                8.67564797401428,
                                49.43510230980313
                            ],
                            [
                                8.67594838142395,
                                49.434509256299194
                            ],
                            [
                                8.676597476005554,
                                49.43075541014409
                            ],
                            [
                                8.676785230636597,
                                49.429171452076034
                            ],
                            [
                                8.678040504455566,
                                49.427339718737336
                            ],
                            [
                                8.67769181728363,
                                49.426914049006484
                            ],
                            [
                                8.677976131439209,
                                49.424475099594915
                            ],
                            [
                                8.67870032787323,
                                49.42355043042678
                            ],
                            [
                                8.679075837135315,
                                49.42358183457115
                            ],
                            [
                                8.681618571281433,
                                49.42536485913539
                            ],
                            [
                                8.682830929756165,
                                49.42645697329834
                            ],
                            [
                                8.682562708854675,
                                49.42706408056258
                            ],
                            [
                                8.682627081871033,
                                49.42756650847932
                            ],
                            [
                                8.683099150657654,
                                49.42831665169559
                            ],
                            [
                                8.682412505149841,
                                49.42952383483607
                            ],
                            [
                                8.682337403297424,
                                49.42999832643483
                            ],
                            [
                                8.68238568305969,
                                49.43174971892479
                            ],
                            [
                                8.682149648666382,
                                49.43396503618632
                            ],
                            [
                                8.681623935699463,
                                49.434966256986044
                            ],
                            [
                                8.681071400642393,
                                49.43603373818389
                            ],
                            [
                                8.679923415184021,
                                49.43810932956028
                            ],
                            [
                                8.679376244544983,
                                49.43900930658586
                            ]
                        ],
                        [
                            [
                                8.682551980018633,
                                49.43468717310328
                            ],
                            [
                                8.682857751846335,
                                49.432014864529165
                            ],
                            [
                                8.682777285575888,
                                49.43024254826279
                            ],
                            [
                                8.683040142059346,
                                49.42939823335047
                            ],
                            [
                                8.683764338493368,
                                49.42827129452454
                            ],
                            [
                                8.683463931083699,
                                49.42686869053871
                            ],
                            [
                                8.68372142314913,
                                49.42627553748538
                            ],
                            [
                                8.68294894695284,
                                49.42582194501782
                            ],
                            [
                                8.680738806724568,
                                49.42408429814794
                            ],
                            [
                                8.67767035961153,
                                49.42217560707202
                            ],
                            [
                                8.676849603652974,
                                49.42124042602685
                            ],
                            [
                                8.676747679710408,
                                49.41950959618095
                            ],
                            [
                                8.676726222038289,
                                49.41746462579966
                            ],
                            [
                                8.676844239234946,
                                49.41541608027697
                            ],
                            [
                                8.677010536193867,
                                49.41279158356542
                            ],
                            [
                                8.678244352340718,
                                49.41287534626663
                            ],
                            [
                                8.681350350379962,
                                49.4127741329847
                            ],
                            [
                                8.682310581207297,
                                49.412557745268124
                            ],
                            [
                                8.68524491786959,
                                49.41279507368085
                            ],
                            [
                                8.687868118286152,
                                49.41326274690042
                            ],
                            [
                                8.689606189727803,
                                49.41343027055183
                            ],
                            [
                                8.691741228103657,
                                49.41350705203436
                            ],
                            [
                                8.693999648094199,
                                49.41348960170796
                            ],
                            [
                                8.693903088569662,
                                49.414655269876285
                            ],
                            [
                                8.693296909332298,
                                49.417108666616826
                            ],
                            [
                                8.694112300872822,
                                49.41776125647996
                            ],
                            [
                                8.696290254592915,
                                49.418626709865244
                            ],
                            [
                                8.695925474166891,
                                49.41939094835089
                            ],
                            [
                                8.695206642150898,
                                49.4219383240166
                            ],
                            [
                                8.694525361061116,
                                49.42221399098786
                            ],
                            [
                                8.694466352462788,
                                49.42245825156709
                            ],
                            [
                                8.69098484516146,
                                49.42336898386557
                            ],
                            [
                                8.692830204963705,
                                49.424848451641324
                            ],
                            [
                                8.691912889480609,
                                49.42621273262463
                            ],
                            [
                                8.692642450332663,
                                49.42662794104641
                            ],
                            [
                                8.692186474800131,
                                49.42868299653952
                            ],
                            [
                                8.694144487381003,
                                49.428965603549976
                            ],
                            [
                                8.697288036346457,
                                49.428187558098706
                            ],
                            [
                                8.697765469551108,
                                49.429227275256245
                            ],
                            [
                                8.69897246360781,
                                49.42982737043178
                            ],
                            [
                                8.699176311492941,
                                49.43027743699608
                            ],
                            [
                                8.701075315475483,
                                49.430671677958294
                            ],
                            [
                                8.70492160320284,
                                49.43121244580582
                            ],
                            [
                                8.70486259460451,
                                49.43199044328338
                            ],
                            [
                                8.703408837318444,
                                49.43213697057547
                            ],
                            [
                                8.700962662696858,
                                49.43220674532258
                            ],
                            [
                                8.697915673255938,
                                49.431774140290344
                            ],
                            [
                                8.693602681159993,
                                49.431481083100856
                            ],
                            [
                                8.690384030342123,
                                49.43178809535092
                            ],
                            [
                                8.68795931339266,
                                49.432611436898874
                            ],
                            [
                                8.686435818672201,
                                49.43385340054498
                            ],
                            [
                                8.686071038246174,
                                49.4348441579827
                            ],
                            [
                                8.684826493263264,
                                49.4349418372097
                            ],
                            [
                                8.682551980018633,
                                49.43468717310328
                            ]
                        ],
                        [
                            [
                                8.66722583770752,
                                49.40791565002185
                            ],
                            [
                                8.667140007019043,
                                49.406812651575706
                            ],
                            [
                                8.669607639312744,
                                49.405974914679575
                            ],
                            [
                                8.671646118164062,
                                49.407776031335224
                            ],
                            [
                                8.66722583770752,
                                49.40791565002185
                            ]
                        ],
                        [
                            [
                                8.672804832458496,
                                49.40762245032132
                            ],
                            [
                                8.669908046722412,
                                49.405151126126235
                            ],
                            [
                                8.673169612884521,
                                49.40280534741505
                            ],
                            [
                                8.675808906555174,
                                49.40146485212102
                            ],
                            [
                                8.678920269012451,
                                49.400431528689566
                            ],
                            [
                                8.677332401275633,
                                49.39960764713133
                            ],
                            [
                                8.673813343048096,
                                49.39773640716187
                            ],
                            [
                                8.678276538848875,
                                49.395264585375195
                            ],
                            [
                                8.67769718170166,
                                49.392345720382245
                            ],
                            [
                                8.67643117904663,
                                49.38572525939232
                            ],
                            [
                                8.675615787506104,
                                49.38051486006314
                            ],
                            [
                                8.675036430358887,
                                49.376589234546884
                            ],
                            [
                                8.67422103881836,
                                49.37169922687505
                            ],
                            [
                                8.67668867111206,
                                49.37183894813116
                            ],
                            [
                                8.68818998336792,
                                49.3722441375281
                            ],
                            [
                                8.688275814056396,
                                49.37186689233472
                            ],
                            [
                                8.692288398742676,
                                49.37195072485012
                            ],
                            [
                                8.692395687103271,
                                49.37445166247092
                            ],
                            [
                                8.694992065429688,
                                49.3748009453079
                            ],
                            [
                                8.695292472839355,
                                49.375806866010855
                            ],
                            [
                                8.696751594543457,
                                49.37604437206148
                            ],
                            [
                                8.694713115692139,
                                49.38033325441254
                            ],
                            [
                                8.693704605102539,
                                49.3854319271903
                            ],
                            [
                                8.694627285003662,
                                49.392527281636866
                            ],
                            [
                                8.695249557495117,
                                49.39624215688466
                            ],
                            [
                                8.697545528411865,
                                49.396563354705286
                            ],
                            [
                                8.697974681854248,
                                49.39734539278944
                            ],
                            [
                                8.696107864379883,
                                49.398937360412546
                            ],
                            [
                                8.695828914642332,
                                49.40173016138585
                            ],
                            [
                                8.693747520446777,
                                49.40203735979645
                            ],
                            [
                                8.695335388183592,
                                49.406449634009036
                            ],
                            [
                                8.701043128967285,
                                49.407901688171044
                            ],
                            [
                                8.705291748046875,
                                49.40815300087786
                            ],
                            [
                                8.707780838012695,
                                49.406337935756234
                            ],
                            [
                                8.709733486175537,
                                49.40642170946967
                            ],
                            [
                                8.712244033813477,
                                49.405458303135035
                            ],
                            [
                                8.712759017944336,
                                49.40577944068036
                            ],
                            [
                                8.709518909454346,
                                49.40806923011853
                            ],
                            [
                                8.715956211090088,
                                49.409102392827094
                            ],
                            [
                                8.716793060302733,
                                49.41063813504997
                            ],
                            [
                                8.715999126434326,
                                49.41185273351016
                            ],
                            [
                                8.71814489364624,
                                49.41345819110489
                            ],
                            [
                                8.717093467712402,
                                49.414072439252514
                            ],
                            [
                                8.712737560272217,
                                49.413555912915264
                            ],
                            [
                                8.70990514755249,
                                49.41327670722657
                            ],
                            [
                                8.706793785095215,
                                49.4132348262363
                            ],
                            [
                                8.699262142181396,
                                49.41210402599119
                            ],
                            [
                                8.69344711303711,
                                49.41123845758522
                            ],
                            [
                                8.691751956939697,
                                49.4109033947499
                            ],
                            [
                                8.690571784973145,
                                49.41074982351939
                            ],
                            [
                                8.684971332550049,
                                49.40973065681607
                            ],
                            [
                                8.682997226715088,
                                49.40980046342962
                            ],
                            [
                                8.679006099700928,
                                49.4095770819168
                            ],
                            [
                                8.678791522979736,
                                49.40866958406852
                            ],
                            [
                                8.67289066314697,
                                49.408627699147466
                            ],
                            [
                                8.672804832458496,
                                49.40762245032132
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--17",
            "properties": {
                "land": "de",
                "city": "Freiburg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.freiburg.de/pb/232967.html",
                "urlPark": "https://www.freiburg.de/pb/890766.html",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            7.806687355041504,
                            47.985096216443075
                        ],
                        [
                            7.808811664581298,
                            47.98436374248404
                        ],
                        [
                            7.812931537628173,
                            47.98386105826256
                        ],
                        [
                            7.816922664642334,
                            47.98422011892029
                        ],
                        [
                            7.820827960968018,
                            47.985024405730194
                        ],
                        [
                            7.825205326080322,
                            47.98562761261336
                        ],
                        [
                            7.828960418701171,
                            47.986001022865494
                        ],
                        [
                            7.829067707061768,
                            47.985168027056055
                        ],
                        [
                            7.832007408142091,
                            47.985024405730194
                        ],
                        [
                            7.830462455749512,
                            47.98307111602376
                        ],
                        [
                            7.8279733657836905,
                            47.98014104287359
                        ],
                        [
                            7.830934524536132,
                            47.97940849860016
                        ],
                        [
                            7.828574180603027,
                            47.97715334607512
                        ],
                        [
                            7.832479476928711,
                            47.97620529076379
                        ],
                        [
                            7.833359241485595,
                            47.9765356756515
                        ],
                        [
                            7.841620445251464,
                            47.97686605842515
                        ],
                        [
                            7.840890884399414,
                            47.97805829172403
                        ],
                        [
                            7.844345569610596,
                            47.978101383978355
                        ],
                        [
                            7.846512794494628,
                            47.97892012997652
                        ],
                        [
                            7.849259376525878,
                            47.98051449281661
                        ],
                        [
                            7.857391834259033,
                            47.98268332177358
                        ],
                        [
                            7.86646842956543,
                            47.98493823274286
                        ],
                        [
                            7.873291969299316,
                            47.985110578573654
                        ],
                        [
                            7.883312702178955,
                            47.98071558012851
                        ],
                        [
                            7.8922176361083975,
                            47.97858976034685
                        ],
                        [
                            7.888312339782714,
                            47.975099204231945
                        ],
                        [
                            7.893698215484618,
                            47.973145538967444
                        ],
                        [
                            7.900500297546387,
                            47.97405055486054
                        ],
                        [
                            7.911980152130127,
                            47.973763249946266
                        ],
                        [
                            7.913696765899658,
                            47.97709588867299
                        ],
                        [
                            7.90837526321411,
                            47.97860412428775
                        ],
                        [
                            7.907838821411132,
                            47.98002613465532
                        ],
                        [
                            7.91191577911377,
                            47.98279822407734
                        ],
                        [
                            7.912302017211914,
                            47.98733666050054
                        ],
                        [
                            7.918567657470702,
                            47.98997911054169
                        ],
                        [
                            7.917881011962891,
                            47.99164493342563
                        ],
                        [
                            7.898054122924804,
                            47.98946212009248
                        ],
                        [
                            7.888355255126952,
                            47.993080944477654
                        ],
                        [
                            7.881488800048827,
                            47.993540459574525
                        ],
                        [
                            7.870244979858398,
                            47.99313838408855
                        ],
                        [
                            7.8667259216308585,
                            47.99262142528881
                        ],
                        [
                            7.858400344848633,
                            47.99239166415985
                        ],
                        [
                            7.857799530029297,
                            47.993540459574525
                        ],
                        [
                            7.865781784057616,
                            48.00140902055227
                        ],
                        [
                            7.870502471923828,
                            48.001351590148296
                        ],
                        [
                            7.877197265625,
                            48.004625021133904
                        ],
                        [
                            7.874772548675537,
                            48.0109990011349
                        ],
                        [
                            7.869558334350586,
                            48.01266414540322
                        ],
                        [
                            7.87020206451416,
                            48.01467373067356
                        ],
                        [
                            7.868034839630127,
                            48.0162813425011
                        ],
                        [
                            7.871253490447997,
                            48.02130480640519
                        ],
                        [
                            7.87670373916626,
                            48.02064460765878
                        ],
                        [
                            7.878849506378174,
                            48.021979348565665
                        ],
                        [
                            7.878849506378174,
                            48.02479223774133
                        ],
                        [
                            7.873098850250243,
                            48.02947047874422
                        ],
                        [
                            7.87095308303833,
                            48.02988662072008
                        ],
                        [
                            7.870931625366212,
                            48.03071889459093
                        ],
                        [
                            7.870438098907472,
                            48.03202467650734
                        ],
                        [
                            7.869107723236084,
                            48.03273495057888
                        ],
                        [
                            7.868217229843139,
                            48.03310084552225
                        ],
                        [
                            7.866103649139404,
                            48.03104893050752
                        ],
                        [
                            7.860760688781738,
                            48.02707400956044
                        ],
                        [
                            7.855406999588013,
                            48.02312030936231
                        ],
                        [
                            7.850986719131469,
                            48.01997722424577
                        ],
                        [
                            7.844763994216919,
                            48.01530529844063
                        ],
                        [
                            7.841620445251464,
                            48.01294405661759
                        ],
                        [
                            7.837393283843994,
                            48.009864949691405
                        ],
                        [
                            7.819046974182128,
                            48.022912211477326
                        ],
                        [
                            7.816300392150878,
                            48.020214038703834
                        ],
                        [
                            7.812180519104003,
                            48.01763054945498
                        ],
                        [
                            7.80977725982666,
                            48.014874684893115
                        ],
                        [
                            7.807631492614747,
                            48.01188899872598
                        ],
                        [
                            7.804198265075684,
                            48.00861602874121
                        ],
                        [
                            7.8046274185180655,
                            48.00634779611187
                        ],
                        [
                            7.8040266036987305,
                            48.005515128723886
                        ],
                        [
                            7.800850868225098,
                            48.00519928585339
                        ],
                        [
                            7.799477577209472,
                            48.001868461482424
                        ],
                        [
                            7.800164222717285,
                            47.996957974712835
                        ],
                        [
                            7.799649238586425,
                            47.994402039351854
                        ],
                        [
                            7.798104286193848,
                            47.98920362292575
                        ],
                        [
                            7.802567481994628,
                            47.98756644413587
                        ],
                        [
                            7.803812026977539,
                            47.985785594203556
                        ],
                        [
                            7.806687355041504,
                            47.985096216443075
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--18",
            "properties": {
                "land": "de",
                "city": "Karlsruhe",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on March 1, 2023.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://www.karlsruhe.de/stadt-rathaus/aktuelles/meldungen/ende-der-umweltzone-laermaktionsplan-reinhold-frank-strasse",
                "urlPark": "https://web1.karlsruhe.de/service/Parken/parken.php",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.357162475585936,
                            49.02333482524899
                        ],
                        [
                            8.35658311843872,
                            49.021590002700606
                        ],
                        [
                            8.354802131652832,
                            49.017241751322494
                        ],
                        [
                            8.353450298309326,
                            49.01562337306782
                        ],
                        [
                            8.353750705718992,
                            49.01303385841641
                        ],
                        [
                            8.354544639587402,
                            49.01134497194253
                        ],
                        [
                            8.355875015258789,
                            49.00997974683569
                        ],
                        [
                            8.358707427978516,
                            49.01103533654093
                        ],
                        [
                            8.360059261322021,
                            49.0100923441372
                        ],
                        [
                            8.360595703125,
                            49.00891005977109
                        ],
                        [
                            8.362441062927246,
                            49.008023328072944
                        ],
                        [
                            8.366754055023193,
                            49.0051378219803
                        ],
                        [
                            8.368384838104246,
                            49.002406993929625
                        ],
                        [
                            8.371131420135498,
                            48.9993099974164
                        ],
                        [
                            8.373942375183105,
                            48.996269122570595
                        ],
                        [
                            8.376259803771973,
                            48.99847940651206
                        ],
                        [
                            8.377697467803955,
                            48.99770511438146
                        ],
                        [
                            8.381001949310303,
                            48.996578849796
                        ],
                        [
                            8.384542465209961,
                            48.99587492149839
                        ],
                        [
                            8.392481803894043,
                            48.99507243110514
                        ],
                        [
                            8.39853286743164,
                            48.99459374646944
                        ],
                        [
                            8.401451110839844,
                            48.995255456367914
                        ],
                        [
                            8.40203046798706,
                            48.99504427331272
                        ],
                        [
                            8.404734134674072,
                            48.995649662342245
                        ],
                        [
                            8.406128883361816,
                            48.99300279095578
                        ],
                        [
                            8.411879539489746,
                            48.994720457555935
                        ],
                        [
                            8.410398960113525,
                            48.997268689876655
                        ],
                        [
                            8.41503381729126,
                            48.99871873073547
                        ],
                        [
                            8.4190034866333,
                            49.00004203215395
                        ],
                        [
                            8.422801494598389,
                            49.00142160683737
                        ],
                        [
                            8.423638343811035,
                            49.002758913166225
                        ],
                        [
                            8.423359394073486,
                            49.003448667656286
                        ],
                        [
                            8.423895835876465,
                            49.00377242667346
                        ],
                        [
                            8.423659801483154,
                            49.00738993862014
                        ],
                        [
                            8.416192531585693,
                            49.00878338478106
                        ],
                        [
                            8.41726541519165,
                            49.00974047672448
                        ],
                        [
                            8.41778039932251,
                            49.01292126776726
                        ],
                        [
                            8.417887687683105,
                            49.01552486138824
                        ],
                        [
                            8.416879177093506,
                            49.01798759489238
                        ],
                        [
                            8.41529130935669,
                            49.01973254373482
                        ],
                        [
                            8.41202974319458,
                            49.02204028534362
                        ],
                        [
                            8.407695293426514,
                            49.023503675797556
                        ],
                        [
                            8.40205192565918,
                            49.02402429471583
                        ],
                        [
                            8.397438526153564,
                            49.0232081869616
                        ],
                        [
                            8.39331865310669,
                            49.02153371708377
                        ],
                        [
                            8.390121459960938,
                            49.01894450989727
                        ],
                        [
                            8.388619422912598,
                            49.01620036613328
                        ],
                        [
                            8.38831901550293,
                            49.01442714662657
                        ],
                        [
                            8.37409257888794,
                            49.01494786049286
                        ],
                        [
                            8.37231159210205,
                            49.01534191060892
                        ],
                        [
                            8.367397785186766,
                            49.01742469511933
                        ],
                        [
                            8.364651203155518,
                            49.019310384302045
                        ],
                        [
                            8.357162475585936,
                            49.02333482524899
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--19",
            "properties": {
                "land": "de",
                "city": "Neu-Ulm",
                "zone": "Umweltzone",
                "area_code": 3,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-3 (diesel)",
                "minEUd": 3,
                "petrol": "min. - (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 3",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://nu.neu-ulm.de/buerger-service/leben-in-neu-ulm/umwelt-natur/umweltzone/",
                "urlPark": "https://nu.neu-ulm.de/buerger-service/leben-in-neu-ulm/verkehr/parken/parken-in-der-innenstadt/",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.992730617523193,
                            48.38686720995678
                        ],
                        [
                            9.994683265686035,
                            48.38728045794606
                        ],
                        [
                            9.998824596405028,
                            48.38787894908497
                        ],
                        [
                            10.001270771026611,
                            48.38859142935915
                        ],
                        [
                            10.002193450927733,
                            48.38928965035021
                        ],
                        [
                            10.00330924987793,
                            48.38933239826292
                        ],
                        [
                            10.003845691680908,
                            48.388961915159655
                        ],
                        [
                            10.005476474761963,
                            48.38971712786155
                        ],
                        [
                            10.007386207580566,
                            48.39080005481887
                        ],
                        [
                            10.009639263153076,
                            48.39235315983204
                        ],
                        [
                            10.01105546951294,
                            48.39279485993919
                        ],
                        [
                            10.011892318725584,
                            48.39171197543446
                        ],
                        [
                            10.013952255249023,
                            48.38974562623463
                        ],
                        [
                            10.016098022460938,
                            48.3876937025798
                        ],
                        [
                            10.018393993377686,
                            48.38612620515928
                        ],
                        [
                            10.01901626586914,
                            48.38635420778455
                        ],
                        [
                            10.01955270767212,
                            48.38624020659961
                        ],
                        [
                            10.024616718292236,
                            48.387038209531745
                        ],
                        [
                            10.02678394317627,
                            48.38720920853215
                        ],
                        [
                            10.026354789733887,
                            48.388876418675856
                        ],
                        [
                            10.025496482849121,
                            48.39179746715427
                        ],
                        [
                            10.025153160095215,
                            48.39320805979403
                        ],
                        [
                            10.024466514587402,
                            48.39439064773021
                        ],
                        [
                            10.023672580718994,
                            48.39533099924209
                        ],
                        [
                            10.021955966949463,
                            48.39654203240261
                        ],
                        [
                            10.020003318786621,
                            48.39739686198373
                        ],
                        [
                            10.017557144165039,
                            48.397881259037334
                        ],
                        [
                            10.015239715576172,
                            48.39800948101478
                        ],
                        [
                            10.013158321380615,
                            48.39776728367494
                        ],
                        [
                            10.012063980102539,
                            48.39743960308575
                        ],
                        [
                            10.011613368988037,
                            48.39789550593968
                        ],
                        [
                            10.00981092453003,
                            48.40011797384633
                        ],
                        [
                            10.009381771087646,
                            48.400801790591395
                        ],
                        [
                            10.004639625549316,
                            48.39902099861706
                        ],
                        [
                            10.00030517578125,
                            48.39725439138432
                        ],
                        [
                            9.998416900634766,
                            48.39639955940932
                        ],
                        [
                            9.996442794799805,
                            48.395801168480624
                        ],
                        [
                            9.9930739402771,
                            48.39486082565863
                        ],
                        [
                            9.990413188934326,
                            48.3940059534661
                        ],
                        [
                            9.988267421722412,
                            48.392381656728865
                        ],
                        [
                            9.986765384674072,
                            48.38998786176143
                        ],
                        [
                            9.98856782913208,
                            48.38900466334768
                        ],
                        [
                            9.989748001098633,
                            48.38853443130428
                        ],
                        [
                            9.992730617523193,
                            48.38686720995678
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--20",
            "properties": {
                "land": "de",
                "city": "Ulm",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.ulm.de/leben-in-ulm/verkehr-und-mobilitaet/individualverkehr/umweltzone",
                "urlPark": "https://www.ulm.de/leben-in-ulm/verkehr-und-mobilitaet/individualverkehr/parken-in-ulm/park-und-ride",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            10.041546821594238,
                            48.430069074158865
                        ],
                        [
                            10.03826379776001,
                            48.43398434251782
                        ],
                        [
                            10.031354427337646,
                            48.436489955944154
                        ],
                        [
                            10.02028226852417,
                            48.437016688502
                        ],
                        [
                            10.01652717590332,
                            48.43623370569814
                        ],
                        [
                            10.012321472167969,
                            48.43479583312268
                        ],
                        [
                            10.009853839874268,
                            48.43529411040482
                        ],
                        [
                            10.00706434249878,
                            48.434895488970035
                        ],
                        [
                            10.005218982696533,
                            48.43340063073511
                        ],
                        [
                            10.002129077911377,
                            48.42992669507934
                        ],
                        [
                            9.999082088470459,
                            48.42703631351862
                        ],
                        [
                            9.996120929718018,
                            48.42638132736262
                        ],
                        [
                            9.990241527557373,
                            48.42719293939225
                        ],
                        [
                            9.987902641296387,
                            48.426609149591705
                        ],
                        [
                            9.98530626296997,
                            48.42431664184599
                        ],
                        [
                            9.982688426971436,
                            48.42340530569582
                        ],
                        [
                            9.9790620803833,
                            48.42350498388312
                        ],
                        [
                            9.97208833694458,
                            48.42284995221668
                        ],
                        [
                            9.969770908355713,
                            48.42487197929987
                        ],
                        [
                            9.965672492980957,
                            48.42605383111907
                        ],
                        [
                            9.958269596099854,
                            48.42707902971385
                        ],
                        [
                            9.955286979675293,
                            48.427434996611105
                        ],
                        [
                            9.950265884399414,
                            48.42746347385518
                        ],
                        [
                            9.94438648223877,
                            48.42682273200397
                        ],
                        [
                            9.939966201782227,
                            48.42548426740811
                        ],
                        [
                            9.936769008636475,
                            48.42384673618412
                        ],
                        [
                            9.934773445129395,
                            48.421938589768544
                        ],
                        [
                            9.93412971496582,
                            48.41928984990004
                        ],
                        [
                            9.935030937194824,
                            48.41688307948616
                        ],
                        [
                            9.937605857849121,
                            48.41441922607132
                        ],
                        [
                            9.943549633026123,
                            48.411300072756525
                        ],
                        [
                            9.946060180664062,
                            48.40973330255337
                        ],
                        [
                            9.948034286499023,
                            48.40705543808576
                        ],
                        [
                            9.948399066925049,
                            48.40456261837628
                        ],
                        [
                            9.947969913482666,
                            48.40229760767367
                        ],
                        [
                            9.946274757385254,
                            48.40010372756638
                        ],
                        [
                            9.943420886993408,
                            48.39951962665375
                        ],
                        [
                            9.94114637374878,
                            48.399149217721856
                        ],
                        [
                            9.940288066864012,
                            48.39859359926699
                        ],
                        [
                            9.940223693847656,
                            48.39709767326418
                        ],
                        [
                            9.942455291748047,
                            48.39570143597493
                        ],
                        [
                            9.944343566894531,
                            48.394661356765106
                        ],
                        [
                            9.944987297058105,
                            48.393037080950734
                        ],
                        [
                            9.944472312927246,
                            48.390814303704175
                        ],
                        [
                            9.944021701812744,
                            48.388933416347655
                        ],
                        [
                            9.944922924041748,
                            48.38615470554331
                        ],
                        [
                            9.945974349975586,
                            48.38487217246209
                        ],
                        [
                            9.948034286499023,
                            48.384387651553816
                        ],
                        [
                            9.950716495513916,
                            48.38407413556707
                        ],
                        [
                            9.952261447906494,
                            48.383375843019586
                        ],
                        [
                            9.95286226272583,
                            48.38274879665009
                        ],
                        [
                            9.95311975479126,
                            48.38197922918439
                        ],
                        [
                            9.952776432037354,
                            48.38075359771886
                        ],
                        [
                            9.951660633087158,
                            48.37994124432318
                        ],
                        [
                            9.947540760040283,
                            48.378601545493275
                        ],
                        [
                            9.945502281188965,
                            48.377760652686426
                        ],
                        [
                            9.944665431976318,
                            48.376378137712955
                        ],
                        [
                            9.945502281188965,
                            48.374696264759
                        ],
                        [
                            9.9526047706604,
                            48.37364150252643
                        ],
                        [
                            9.963655471801758,
                            48.37272925811
                        ],
                        [
                            9.963419437408447,
                            48.374624997728894
                        ],
                        [
                            9.96387004852295,
                            48.37605388263192
                        ],
                        [
                            9.965007305145264,
                            48.37787467286406
                        ],
                        [
                            9.96760368347168,
                            48.379641952962146
                        ],
                        [
                            9.975371360778809,
                            48.3826490385609
                        ],
                        [
                            9.982645511627197,
                            48.38614045535329
                        ],
                        [
                            9.984512329101562,
                            48.387551204809306
                        ],
                        [
                            9.986121654510496,
                            48.38950338955473
                        ],
                        [
                            9.987258911132812,
                            48.39199694727528
                        ],
                        [
                            9.988610744476318,
                            48.39356426387229
                        ],
                        [
                            9.991464614868162,
                            48.394889321151
                        ],
                        [
                            9.99579906463623,
                            48.39600063290555
                        ],
                        [
                            9.99927520751953,
                            48.397140414617546
                        ],
                        [
                            10.007987022399902,
                            48.40074480621375
                        ],
                        [
                            10.01354455947876,
                            48.40380762601581
                        ],
                        [
                            10.015625953674316,
                            48.40581616584548
                        ],
                        [
                            10.016376972198485,
                            48.407582475748335
                        ],
                        [
                            10.016376972198485,
                            48.41157069181147
                        ],
                        [
                            10.015969276428223,
                            48.41568677250964
                        ],
                        [
                            10.016355514526367,
                            48.41755242944142
                        ],
                        [
                            10.018908977508545,
                            48.419546185596744
                        ],
                        [
                            10.024466514587402,
                            48.42259363317972
                        ],
                        [
                            10.031332969665527,
                            48.42665186614596
                        ],
                        [
                            10.035796165466309,
                            48.42861678882996
                        ],
                        [
                            10.041546821594238,
                            48.430069074158865
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--21",
            "properties": {
                "land": "de",
                "city": "Regensburg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.regensburg.de/leben/umwelt/luft/umweltzone-in-regensburg",
                "urlPark": "https://www.rvv.de/park-ride",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            12.081506252288818,
                            49.023953941126244
                        ],
                        [
                            12.081098556518555,
                            49.02340517971384
                        ],
                        [
                            12.081077098846436,
                            49.022771985949475
                        ],
                        [
                            12.080862522125244,
                            49.02213878412869
                        ],
                        [
                            12.080562114715576,
                            49.02167443100649
                        ],
                        [
                            12.080798149108887,
                            49.02085828471665
                        ],
                        [
                            12.082321643829344,
                            49.020633138557315
                        ],
                        [
                            12.082879543304443,
                            49.02080199827232
                        ],
                        [
                            12.08472490310669,
                            49.019535536445126
                        ],
                        [
                            12.085819244384764,
                            49.01908523114047
                        ],
                        [
                            12.086784839630127,
                            49.01881786043824
                        ],
                        [
                            12.08719253540039,
                            49.017452840259196
                        ],
                        [
                            12.087986469268799,
                            49.01681957076354
                        ],
                        [
                            12.089273929595947,
                            49.01635516801295
                        ],
                        [
                            12.090518474578857,
                            49.01465232085879
                        ],
                        [
                            12.090604305267334,
                            49.01423011833789
                        ],
                        [
                            12.08895206451416,
                            49.01435677947014
                        ],
                        [
                            12.088630199432373,
                            49.01244275466763
                        ],
                        [
                            12.088758945465088,
                            49.01152793741105
                        ],
                        [
                            12.099423408508299,
                            49.01159830856603
                        ],
                        [
                            12.099423408508299,
                            49.012738307419326
                        ],
                        [
                            12.099788188934326,
                            49.0147508342651
                        ],
                        [
                            12.101054191589355,
                            49.014835274172476
                        ],
                        [
                            12.10146188735962,
                            49.01551078827525
                        ],
                        [
                            12.104659080505371,
                            49.01515895915722
                        ],
                        [
                            12.106139659881592,
                            49.016087782645684
                        ],
                        [
                            12.10742712020874,
                            49.016369240887244
                        ],
                        [
                            12.109165191650389,
                            49.01959182432175
                        ],
                        [
                            12.102084159851074,
                            49.02080199827232
                        ],
                        [
                            12.102234363555908,
                            49.021449288539166
                        ],
                        [
                            12.101762294769287,
                            49.02147743140328
                        ],
                        [
                            12.101483345031738,
                            49.02085828471665
                        ],
                        [
                            12.097384929656982,
                            49.021350788389455
                        ],
                        [
                            12.094058990478516,
                            49.02175885916915
                        ],
                        [
                            12.08968162536621,
                            49.02213878412869
                        ],
                        [
                            12.084596157073975,
                            49.02298305143277
                        ],
                        [
                            12.081506252288818,
                            49.023953941126244
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--22",
            "properties": {
                "land": "de",
                "city": "Eschweiler",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.eschweiler.de/leben-wohnen/natur-umwelt-entsorgung/umwelt/umweltzone/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            6.25622034072876,
                            50.82650095355988
                        ],
                        [
                            6.256864070892334,
                            50.825660586530624
                        ],
                        [
                            6.258537769317627,
                            50.82456266487889
                        ],
                        [
                            6.2567138671875,
                            50.82285473543414
                        ],
                        [
                            6.255662441253662,
                            50.82223119006879
                        ],
                        [
                            6.256542205810547,
                            50.82194652528568
                        ],
                        [
                            6.257529258728027,
                            50.82177030335975
                        ],
                        [
                            6.257143020629883,
                            50.82117385498325
                        ],
                        [
                            6.254889965057373,
                            50.81968270070941
                        ],
                        [
                            6.253366470336913,
                            50.81876087243221
                        ],
                        [
                            6.252636909484863,
                            50.81751366403357
                        ],
                        [
                            6.260898113250732,
                            50.81541231367935
                        ],
                        [
                            6.2669920921325675,
                            50.813812512601
                        ],
                        [
                            6.2673139572143555,
                            50.81508693484848
                        ],
                        [
                            6.267528533935546,
                            50.815819034030206
                        ],
                        [
                            6.272850036621093,
                            50.814598862351765
                        ],
                        [
                            6.272356510162353,
                            50.81564278897971
                        ],
                        [
                            6.272678375244141,
                            50.81747299363368
                        ],
                        [
                            6.274802684783935,
                            50.81740520955516
                        ],
                        [
                            6.276261806488036,
                            50.81712051535098
                        ],
                        [
                            6.276841163635254,
                            50.81745943682585
                        ],
                        [
                            6.276905536651611,
                            50.81843551693083
                        ],
                        [
                            6.27842903137207,
                            50.81878798528832
                        ],
                        [
                            6.281991004943848,
                            50.81927601400676
                        ],
                        [
                            6.281754970550537,
                            50.820807848798346
                        ],
                        [
                            6.281905174255371,
                            50.82166185876709
                        ],
                        [
                            6.2816476821899405,
                            50.8236680429616
                        ],
                        [
                            6.281239986419678,
                            50.82464399329408
                        ],
                        [
                            6.281733512878418,
                            50.82572835862601
                        ],
                        [
                            6.282334327697754,
                            50.828357839965776
                        ],
                        [
                            6.265726089477539,
                            50.82719221187368
                        ],
                        [
                            6.25622034072876,
                            50.82650095355988
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--23",
            "properties": {
                "land": "de",
                "city": "Mainz",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.mainz.de/leben-und-arbeit/umwelt/umweltzone.php",
                "urlPark": "https://www.mainz.de/leben-und-arbeit/umwelt/parken-ausserhalb.php",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.200414180755615,
                            49.98577951562862
                        ],
                        [
                            8.20305347442627,
                            49.984896512001
                        ],
                        [
                            8.209233283996582,
                            49.98166789200989
                        ],
                        [
                            8.213417530059813,
                            49.97663136410696
                        ],
                        [
                            8.218245506286621,
                            49.97101466878784
                        ],
                        [
                            8.22376012802124,
                            49.96789553277094
                        ],
                        [
                            8.225369453430176,
                            49.96886165845616
                        ],
                        [
                            8.226935863494873,
                            49.96662573809139
                        ],
                        [
                            8.229467868804932,
                            49.965811395612604
                        ],
                        [
                            8.235175609588623,
                            49.96519027768103
                        ],
                        [
                            8.23974609375,
                            49.96625307459901
                        ],
                        [
                            8.243265151977539,
                            49.96880645179658
                        ],
                        [
                            8.24350118637085,
                            49.97011759285456
                        ],
                        [
                            8.2452392578125,
                            49.97072484608402
                        ],
                        [
                            8.251268863677979,
                            49.97561019553785
                        ],
                        [
                            8.252599239349365,
                            49.975003003952594
                        ],
                        [
                            8.249316215515137,
                            49.97160810983085
                        ],
                        [
                            8.249144554138184,
                            49.970131394149426
                        ],
                        [
                            8.2462477684021,
                            49.96935851554293
                        ],
                        [
                            8.243565559387207,
                            49.967012200887865
                        ],
                        [
                            8.24448823928833,
                            49.965604357192596
                        ],
                        [
                            8.2527494430542,
                            49.96756428521452
                        ],
                        [
                            8.255667686462402,
                            49.969413721569275
                        ],
                        [
                            8.262083530426025,
                            49.97021420183543
                        ],
                        [
                            8.270065784454346,
                            49.972753568358826
                        ],
                        [
                            8.26995849609375,
                            49.97423020358107
                        ],
                        [
                            8.272833824157715,
                            49.97476840515177
                        ],
                        [
                            8.275172710418701,
                            49.974299204118836
                        ],
                        [
                            8.27843427658081,
                            49.974727005244645
                        ],
                        [
                            8.281524181365967,
                            49.975003003952594
                        ],
                        [
                            8.289806842803955,
                            49.97518240226389
                        ],
                        [
                            8.297510147094727,
                            49.9726983661635
                        ],
                        [
                            8.308496475219727,
                            49.97794229198728
                        ],
                        [
                            8.312101364135742,
                            49.97981892657768
                        ],
                        [
                            8.28296184539795,
                            49.9969122487281
                        ],
                        [
                            8.277511596679688,
                            50.0025259209089
                        ],
                        [
                            8.271331787109375,
                            50.00873192077016
                        ],
                        [
                            8.262555599212646,
                            50.015557595179956
                        ],
                        [
                            8.253650665283203,
                            50.02049353941692
                        ],
                        [
                            8.25324296951294,
                            50.01784639215138
                        ],
                        [
                            8.253586292266846,
                            50.01655033978552
                        ],
                        [
                            8.252384662628172,
                            50.016536551806475
                        ],
                        [
                            8.25094699859619,
                            50.01628836750675
                        ],
                        [
                            8.248865604400635,
                            50.01481302326545
                        ],
                        [
                            8.247663974761963,
                            50.013296267010034
                        ],
                        [
                            8.245904445648193,
                            50.0136547773547
                        ],
                        [
                            8.244187831878662,
                            50.014978484687965
                        ],
                        [
                            8.243544101715088,
                            50.01624700333219
                        ],
                        [
                            8.241076469421387,
                            50.01632973164575
                        ],
                        [
                            8.240625858306885,
                            50.01573684225111
                        ],
                        [
                            8.237857818603516,
                            50.01583335962784
                        ],
                        [
                            8.237471580505371,
                            50.01649518784554
                        ],
                        [
                            8.233780860900879,
                            50.01845304294884
                        ],
                        [
                            8.235154151916504,
                            50.02025916247143
                        ],
                        [
                            8.235476016998291,
                            50.02159647439758
                        ],
                        [
                            8.229639530181885,
                            50.022837245983965
                        ],
                        [
                            8.213567733764648,
                            50.025732255058564
                        ],
                        [
                            8.212344646453857,
                            50.02445020113597
                        ],
                        [
                            8.211164474487305,
                            50.02252016296033
                        ],
                        [
                            8.208632469177246,
                            50.01769472825515
                        ],
                        [
                            8.203740119934082,
                            50.01206903827432
                        ],
                        [
                            8.20676565170288,
                            50.010786619705506
                        ],
                        [
                            8.213846683502197,
                            50.01164156922046
                        ],
                        [
                            8.214104175567627,
                            50.01059356448423
                        ],
                        [
                            8.207430839538574,
                            50.00968343656687
                        ],
                        [
                            8.203010559082031,
                            50.010000604312864
                        ],
                        [
                            8.200607299804688,
                            50.00977996610245
                        ],
                        [
                            8.19631576538086,
                            50.007077065819125
                        ],
                        [
                            8.185586929321289,
                            50.00211215909531
                        ],
                        [
                            8.184642791748047,
                            50.00051224657549
                        ],
                        [
                            8.186960220336914,
                            49.998029518286025
                        ],
                        [
                            8.1903076171875,
                            49.992070447299916
                        ],
                        [
                            8.194019794464111,
                            49.98951830448241
                        ],
                        [
                            8.204576969146729,
                            49.99100822052723
                        ],
                        [
                            8.207881450653076,
                            49.99034604131682
                        ],
                        [
                            8.21976900100708,
                            49.99213942225314
                        ],
                        [
                            8.22075605392456,
                            49.99084267657939
                        ],
                        [
                            8.207409381866455,
                            49.98933895962393
                        ],
                        [
                            8.20401906967163,
                            49.98806973074264
                        ],
                        [
                            8.200414180755615,
                            49.98577951562862
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--24",
            "properties": {
                "land": "de",
                "city": "Mannheim",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.mannheim.de/de/service-bieten/umwelt/umweltzone",
                "urlPark": "https://www.vrn.de/mobilitaet/pr/index.html",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.482475280761719,
                            49.49054204358167
                        ],
                        [
                            8.483054637908936,
                            49.492395807234786
                        ],
                        [
                            8.485136032104492,
                            49.49371988114855
                        ],
                        [
                            8.487410545349121,
                            49.49420768882484
                        ],
                        [
                            8.487217426300049,
                            49.49477911449098
                        ],
                        [
                            8.485994338989258,
                            49.49459793146547
                        ],
                        [
                            8.487067222595215,
                            49.49588013537771
                        ],
                        [
                            8.48642349243164,
                            49.49639579487119
                        ],
                        [
                            8.48818302154541,
                            49.49848625067141
                        ],
                        [
                            8.48792552947998,
                            49.50054874621233
                        ],
                        [
                            8.476166725158691,
                            49.501816859098355
                        ],
                        [
                            8.475608825683594,
                            49.500005259200925
                        ],
                        [
                            8.47170352935791,
                            49.50050693973345
                        ],
                        [
                            8.471167087554932,
                            49.50321035184303
                        ],
                        [
                            8.471188545227049,
                            49.50433905187526
                        ],
                        [
                            8.46947193145752,
                            49.504422658249254
                        ],
                        [
                            8.469407558441162,
                            49.50541198949409
                        ],
                        [
                            8.466081619262695,
                            49.50573247279057
                        ],
                        [
                            8.464000225067139,
                            49.505328384810625
                        ],
                        [
                            8.461639881134033,
                            49.50406036293004
                        ],
                        [
                            8.459966182708738,
                            49.502485740568495
                        ],
                        [
                            8.461747169494629,
                            49.49968473839024
                        ],
                        [
                            8.459880352020264,
                            49.49766401537709
                        ],
                        [
                            8.466618061065674,
                            49.49526691161059
                        ],
                        [
                            8.466489315032959,
                            49.49486273719961
                        ],
                        [
                            8.461511135101318,
                            49.495963756205185
                        ],
                        [
                            8.461189270019531,
                            49.495489902960315
                        ],
                        [
                            8.46020221710205,
                            49.4952529746175
                        ],
                        [
                            8.458936214447021,
                            49.49696719499115
                        ],
                        [
                            8.457305431365967,
                            49.497009004494075
                        ],
                        [
                            8.456597328186033,
                            49.492395807234786
                        ],
                        [
                            8.458893299102783,
                            49.49153165547003
                        ],
                        [
                            8.457026481628418,
                            49.489134251352276
                        ],
                        [
                            8.456618785858154,
                            49.48584459881443
                        ],
                        [
                            8.458077907562254,
                            49.484464551639725
                        ],
                        [
                            8.461103439331055,
                            49.483307512415195
                        ],
                        [
                            8.464150428771973,
                            49.48235955628852
                        ],
                        [
                            8.466038703918457,
                            49.48164857714979
                        ],
                        [
                            8.466532230377197,
                            49.48078423566607
                        ],
                        [
                            8.481155633926392,
                            49.47338791580946
                        ],
                        [
                            8.482807874679565,
                            49.47317876705849
                        ],
                        [
                            8.481101989746094,
                            49.47399444213701
                        ],
                        [
                            8.48145604133606,
                            49.474538217976395
                        ],
                        [
                            8.482292890548706,
                            49.47438484591499
                        ],
                        [
                            8.485972881317139,
                            49.47429421674389
                        ],
                        [
                            8.48795771598816,
                            49.47660869379978
                        ],
                        [
                            8.490328788757324,
                            49.47720820917799
                        ],
                        [
                            8.493869304656982,
                            49.480345088583576
                        ],
                        [
                            8.49791407585144,
                            49.478553606509266
                        ],
                        [
                            8.504490852355957,
                            49.47816323596038
                        ],
                        [
                            8.50766658782959,
                            49.48309840602372
                        ],
                        [
                            8.482475280761719,
                            49.49054204358167
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Wiesbaden",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.wiesbaden.de/leben-in-wiesbaden/verkehr/auto/umweltzone.php",
                "urlPark": "https://www.wiesbaden.de/leben-in-wiesbaden/verkehr/auto/parken/parken.php",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                8.31815242767334,
                                50.006084125499044
                            ],
                            [
                                8.318066596984863,
                                50.00856643783905
                            ],
                            [
                                8.318538665771484,
                                50.00961448677992
                            ],
                            [
                                8.320298194885254,
                                50.01074525079479
                            ],
                            [
                                8.321156501770018,
                                50.01234482274278
                            ],
                            [
                                8.32240104675293,
                                50.01347552251671
                            ],
                            [
                                8.319225311279297,
                                50.01289638693172
                            ],
                            [
                                8.316521644592285,
                                50.01463377274809
                            ],
                            [
                                8.31566333770752,
                                50.01405465112216
                            ],
                            [
                                8.313088417053223,
                                50.01273091833977
                            ],
                            [
                                8.311715126037598,
                                50.011158938299296
                            ],
                            [
                                8.310127258300781,
                                50.01107620108326
                            ],
                            [
                                8.311328887939453,
                                50.013999496317574
                            ],
                            [
                                8.308796882629395,
                                50.01777745407607
                            ],
                            [
                                8.303475379943848,
                                50.01590230049252
                            ],
                            [
                                8.303260803222656,
                                50.01637109574909
                            ],
                            [
                                8.300471305847168,
                                50.020204014788675
                            ],
                            [
                                8.29944133758545,
                                50.019762831047636
                            ],
                            [
                                8.296480178833008,
                                50.02149996859767
                            ],
                            [
                                8.294377326965332,
                                50.019873127362715
                            ],
                            [
                                8.29287528991699,
                                50.02070034165459
                            ],
                            [
                                8.28944206237793,
                                50.018687428705455
                            ],
                            [
                                8.28595519065857,
                                50.02032120353888
                            ],
                            [
                                8.280998468399048,
                                50.022458124732644
                            ],
                            [
                                8.276996612548828,
                                50.02388498369977
                            ],
                            [
                                8.273917436599731,
                                50.024360593937246
                            ],
                            [
                                8.27117085456848,
                                50.02433991532921
                            ],
                            [
                                8.268048763275146,
                                50.023953913011475
                            ],
                            [
                                8.26545238494873,
                                50.02411072682713
                            ],
                            [
                                8.264636993408203,
                                50.02417448614428
                            ],
                            [
                                8.26347827911377,
                                50.02359547951048
                            ],
                            [
                                8.272361755371092,
                                50.016067758164176
                            ],
                            [
                                8.278627395629883,
                                50.00696674012984
                            ],
                            [
                                8.28768253326416,
                                49.99676051876117
                            ],
                            [
                                8.292489051818848,
                                49.994636248949604
                            ],
                            [
                                8.299269676208496,
                                49.995105251655765
                            ],
                            [
                                8.305546045303345,
                                49.99748468020169
                            ],
                            [
                                8.30892562866211,
                                49.99956054921783
                            ],
                            [
                                8.311704397201538,
                                50.00113290861561
                            ],
                            [
                                8.310481309890747,
                                50.0022776642481
                            ],
                            [
                                8.310856819152832,
                                50.00313276512694
                            ],
                            [
                                8.310298919677734,
                                50.00463605076339
                            ],
                            [
                                8.309129476547241,
                                50.00491187788589
                            ],
                            [
                                8.309569358825684,
                                50.005449736222396
                            ],
                            [
                                8.315362930297852,
                                50.00616687130919
                            ],
                            [
                                8.316693305969238,
                                50.00622203510347
                            ],
                            [
                                8.31815242767334,
                                50.006084125499044
                            ]
                        ],
                        [
                            [
                                8.25573205947876,
                                50.05183459715117
                            ],
                            [
                                8.246612548828125,
                                50.05182081929983
                            ],
                            [
                                8.243951797485352,
                                50.051297258018124
                            ],
                            [
                                8.241333961486816,
                                50.05115947778411
                            ],
                            [
                                8.241806030273438,
                                50.05226170858033
                            ],
                            [
                                8.237686157226562,
                                50.05209637557484
                            ],
                            [
                                8.232364654541016,
                                50.051462593776805
                            ],
                            [
                                8.22627067565918,
                                50.05132481401746
                            ],
                            [
                                8.216872215270994,
                                50.05209637557484
                            ],
                            [
                                8.213438987731934,
                                50.05102169715449
                            ],
                            [
                                8.213911056518553,
                                50.044545561350596
                            ],
                            [
                                8.217344284057617,
                                50.04509675586474
                            ],
                            [
                                8.216958045959473,
                                50.04316754737399
                            ],
                            [
                                8.21352481842041,
                                50.042423403377924
                            ],
                            [
                                8.213095664978027,
                                50.039143520155534
                            ],
                            [
                                8.232278823852539,
                                50.037021123367985
                            ],
                            [
                                8.242599964141846,
                                50.03367884732611
                            ],
                            [
                                8.243372440338135,
                                50.03461608634768
                            ],
                            [
                                8.244187831878662,
                                50.034595412154815
                            ],
                            [
                                8.24898362159729,
                                50.04235450057255
                            ],
                            [
                                8.251268863677979,
                                50.04333291113992
                            ],
                            [
                                8.250893354415894,
                                50.04356028538791
                            ],
                            [
                                8.252545595169067,
                                50.044821159398865
                            ],
                            [
                                8.25498104095459,
                                50.045041636698144
                            ],
                            [
                                8.255528211593628,
                                50.04807309686942
                            ],
                            [
                                8.25573205947876,
                                50.05183459715117
                            ]
                        ],
                        [
                            [
                                8.211636543273926,
                                50.03917108313303
                            ],
                            [
                                8.212237358093262,
                                50.04360851615055
                            ],
                            [
                                8.20927619934082,
                                50.04330535055181
                            ],
                            [
                                8.210091590881348,
                                50.04506919628934
                            ],
                            [
                                8.212409019470215,
                                50.04545502890476
                            ],
                            [
                                8.212237358093262,
                                50.05071857837695
                            ],
                            [
                                8.208975791931152,
                                50.05231681945558
                            ],
                            [
                                8.205242156982422,
                                50.05231681945558
                            ],
                            [
                                8.199148178100586,
                                50.051738152108776
                            ],
                            [
                                8.190350532531738,
                                50.04934074162167
                            ],
                            [
                                8.186745643615723,
                                50.04887226811364
                            ],
                            [
                                8.177905082702637,
                                50.04936829874445
                            ],
                            [
                                8.174901008605955,
                                50.0485691357599
                            ],
                            [
                                8.177175521850586,
                                50.04393924007457
                            ],
                            [
                                8.175501823425293,
                                50.043911679834615
                            ],
                            [
                                8.17605972290039,
                                50.04162412475545
                            ],
                            [
                                8.178033828735352,
                                50.03831662347228
                            ],
                            [
                                8.179621696472168,
                                50.037875606084604
                            ],
                            [
                                8.181123733520508,
                                50.03688330215079
                            ],
                            [
                                8.195629119873047,
                                50.03826149652037
                            ],
                            [
                                8.211636543273926,
                                50.03917108313303
                            ]
                        ],
                        [
                            [
                                8.211593627929688,
                                50.054328323087546
                            ],
                            [
                                8.215842247009276,
                                50.05402522519607
                            ],
                            [
                                8.217859268188477,
                                50.053005700056055
                            ],
                            [
                                8.231163024902344,
                                50.05242704101622
                            ],
                            [
                                8.23901653289795,
                                50.05317102992865
                            ],
                            [
                                8.242449760437012,
                                50.05408033404601
                            ],
                            [
                                8.243694305419922,
                                50.05325369465134
                            ],
                            [
                                8.255538940429688,
                                50.05284036961384
                            ],
                            [
                                8.25674057006836,
                                50.054328323087546
                            ],
                            [
                                8.262405395507812,
                                50.05419055155604
                            ],
                            [
                                8.263907432556152,
                                50.0532261397596
                            ],
                            [
                                8.272576332092285,
                                50.052537262323675
                            ],
                            [
                                8.282532691955566,
                                50.051379925968675
                            ],
                            [
                                8.288927078247069,
                                50.05113192168982
                            ],
                            [
                                8.289141654968262,
                                50.05217904214879
                            ],
                            [
                                8.290472030639648,
                                50.052537262323675
                            ],
                            [
                                8.29394817352295,
                                50.051379925968675
                            ],
                            [
                                8.296866416931152,
                                50.050139891757844
                            ],
                            [
                                8.301973342895508,
                                50.051104365579725
                            ],
                            [
                                8.306865692138672,
                                50.05328124952725
                            ],
                            [
                                8.31137180328369,
                                50.05529271273233
                            ],
                            [
                                8.314933776855469,
                                50.05620197664454
                            ],
                            [
                                8.31489086151123,
                                50.05807555703867
                            ],
                            [
                                8.310685157775879,
                                50.061574548033036
                            ],
                            [
                                8.303475379943848,
                                50.065596694806906
                            ],
                            [
                                8.299698829650877,
                                50.06994904901809
                            ],
                            [
                                8.298540115356445,
                                50.07788143807803
                            ],
                            [
                                8.300385475158691,
                                50.07801914157735
                            ],
                            [
                                8.299870491027832,
                                50.082397906592334
                            ],
                            [
                                8.293304443359375,
                                50.08333419437582
                            ],
                            [
                                8.294377326965332,
                                50.087960289114015
                            ],
                            [
                                8.293304443359375,
                                50.08999783205443
                            ],
                            [
                                8.285622596740723,
                                50.094458096636124
                            ],
                            [
                                8.282361030578613,
                                50.099826383026375
                            ],
                            [
                                8.281030654907227,
                                50.10624000830021
                            ],
                            [
                                8.281846046447754,
                                50.107451068611596
                            ],
                            [
                                8.280515670776367,
                                50.10981805261961
                            ],
                            [
                                8.281974792480469,
                                50.11157945316845
                            ],
                            [
                                8.286266326904297,
                                50.116092746363165
                            ],
                            [
                                8.288326263427734,
                                50.118982131441875
                            ],
                            [
                                8.289356231689453,
                                50.12192637303954
                            ],
                            [
                                8.290343284606934,
                                50.123604784872086
                            ],
                            [
                                8.292489051818848,
                                50.12696143198666
                            ],
                            [
                                8.293089866638184,
                                50.12833703902711
                            ],
                            [
                                8.291544914245605,
                                50.12811694455761
                            ],
                            [
                                8.290214538574219,
                                50.129162384273044
                            ],
                            [
                                8.28270435333252,
                                50.12682386910801
                            ],
                            [
                                8.279485702514648,
                                50.12588843104751
                            ],
                            [
                                8.278498649597168,
                                50.12407252850347
                            ],
                            [
                                8.275666236877441,
                                50.123769871381555
                            ],
                            [
                                8.27339172363281,
                                50.1195875043154
                            ],
                            [
                                8.271074295043945,
                                50.119862671272806
                            ],
                            [
                                8.269786834716797,
                                50.118954614311185
                            ],
                            [
                                8.264551162719727,
                                50.119202267917956
                            ],
                            [
                                8.264551162719727,
                                50.11667063732894
                            ],
                            [
                                8.269357681274414,
                                50.11617530264245
                            ],
                            [
                                8.270773887634277,
                                50.11347839055919
                            ],
                            [
                                8.268070220947266,
                                50.10935016970876
                            ],
                            [
                                8.266310691833496,
                                50.11080884840011
                            ],
                            [
                                8.264122009277344,
                                50.11091893555482
                            ],
                            [
                                8.258113861083984,
                                50.11449663031724
                            ],
                            [
                                8.256096839904785,
                                50.11243260826883
                            ],
                            [
                                8.253822326660156,
                                50.11314814598755
                            ],
                            [
                                8.249058723449707,
                                50.107285925826865
                            ],
                            [
                                8.246655464172363,
                                50.10849695969161
                            ],
                            [
                                8.24197769165039,
                                50.10692811450649
                            ],
                            [
                                8.242149353027342,
                                50.106295057160516
                            ],
                            [
                                8.236913681030273,
                                50.10365264049715
                            ],
                            [
                                8.228373527526855,
                                50.103515010635334
                            ],
                            [
                                8.225884437561035,
                                50.10329480203387
                            ],
                            [
                                8.223910331726074,
                                50.10158815104786
                            ],
                            [
                                8.22575569152832,
                                50.09974379856066
                            ],
                            [
                                8.22425365447998,
                                50.09792690429676
                            ],
                            [
                                8.222451210021973,
                                50.098257253833495
                            ],
                            [
                                8.221421241760254,
                                50.09743137572077
                            ],
                            [
                                8.219704627990723,
                                50.0970459610628
                            ],
                            [
                                8.218631744384766,
                                50.09869771633916
                            ],
                            [
                                8.213953971862791,
                                50.09726619838984
                            ],
                            [
                                8.213095664978027,
                                50.09448562784431
                            ],
                            [
                                8.2157564163208,
                                50.0923932109388
                            ],
                            [
                                8.213095664978027,
                                50.092007755753514
                            ],
                            [
                                8.211379051208496,
                                50.09305398404301
                            ],
                            [
                                8.20202350616455,
                                50.097376316673724
                            ],
                            [
                                8.199663162231445,
                                50.097541493624995
                            ],
                            [
                                8.197345733642576,
                                50.096715603173976
                            ],
                            [
                                8.19455623626709,
                                50.09844995667801
                            ],
                            [
                                8.193354606628418,
                                50.09481600110869
                            ],
                            [
                                8.186112642288208,
                                50.09688078240311
                            ],
                            [
                                8.185694217681885,
                                50.096646778327106
                            ],
                            [
                                8.186273574829102,
                                50.09583463766768
                            ],
                            [
                                8.181531429290771,
                                50.09173931191796
                            ],
                            [
                                8.182840347290039,
                                50.09132631846534
                            ],
                            [
                                8.186391592025757,
                                50.085592224968124
                            ],
                            [
                                8.186016082763672,
                                50.08537193398317
                            ],
                            [
                                8.190007209777832,
                                50.07804668222974
                            ],
                            [
                                8.187603950500487,
                                50.07782635656758
                            ],
                            [
                                8.182368278503418,
                                50.07570567029715
                            ],
                            [
                                8.180694580078125,
                                50.073419630697785
                            ],
                            [
                                8.175630569458008,
                                50.06953586780176
                            ],
                            [
                                8.174986839294434,
                                50.06799329314162
                            ],
                            [
                                8.177347183227539,
                                50.06391624984374
                            ],
                            [
                                8.180093765258789,
                                50.063833603490615
                            ],
                            [
                                8.179750442504883,
                                50.061712298362735
                            ],
                            [
                                8.181896209716797,
                                50.06226329572605
                            ],
                            [
                                8.184900283813477,
                                50.05744185421106
                            ],
                            [
                                8.184943199157715,
                                50.05622952982745
                            ],
                            [
                                8.186616897583006,
                                50.05498962093254
                            ],
                            [
                                8.188848495483398,
                                50.051600373140595
                            ],
                            [
                                8.188462257385254,
                                50.05107680945381
                            ],
                            [
                                8.189921379089355,
                                50.05016744842174
                            ],
                            [
                                8.19803237915039,
                                50.052344374869485
                            ],
                            [
                                8.203182220458983,
                                50.05311592003439
                            ],
                            [
                                8.20777416229248,
                                50.053336359231615
                            ],
                            [
                                8.208675384521484,
                                50.05410788844726
                            ],
                            [
                                8.211593627929688,
                                50.054328323087546
                            ]
                        ],
                        [
                            [
                                8.298797607421875,
                                50.049258070158416
                            ],
                            [
                                8.301243782043457,
                                50.04807309686942
                            ],
                            [
                                8.308024406433105,
                                50.04815577037385
                            ],
                            [
                                8.309226036071777,
                                50.048734480917474
                            ],
                            [
                                8.307123184204102,
                                50.052344374869485
                            ],
                            [
                                8.30317497253418,
                                50.050277674919116
                            ],
                            [
                                8.298797607421875,
                                50.049258070158416
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--26",
            "properties": {
                "land": "de",
                "city": "Wendlingen",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on May 1, 2023.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rps/presse/artikel/luftreinhaltung-das-regierungspraesidium-stuttgart-hebt-umweltzonen-in-wendlingen-am-neckar-schwaebisch-gmuend-ilsfeld-und-urbach-zum-1-mai-2023-auf/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.36835527420044,
                            48.66574810023468
                        ],
                        [
                            9.369192123413086,
                            48.66548592247822
                        ],
                        [
                            9.373011589050293,
                            48.66364355383065
                        ],
                        [
                            9.376401901245117,
                            48.661205854885196
                        ],
                        [
                            9.38009262084961,
                            48.66205622837285
                        ],
                        [
                            9.382109642028809,
                            48.66253810031407
                        ],
                        [
                            9.388772249221802,
                            48.66159561118126
                        ],
                        [
                            9.392398595809935,
                            48.66215543767852
                        ],
                        [
                            9.39854621887207,
                            48.66100743234037
                        ],
                        [
                            9.406603574752808,
                            48.6633175892887
                        ],
                        [
                            9.409253597259521,
                            48.66535837604949
                        ],
                        [
                            9.408663511276245,
                            48.66606696323546
                        ],
                        [
                            9.408373832702637,
                            48.666988111682684
                        ],
                        [
                            9.405412673950195,
                            48.66826352019778
                        ],
                        [
                            9.402151107788086,
                            48.668405230262444
                        ],
                        [
                            9.398632049560547,
                            48.66965226164764
                        ],
                        [
                            9.398889541625977,
                            48.670275765767485
                        ],
                        [
                            9.395982027053833,
                            48.67121100748138
                        ],
                        [
                            9.392752647399902,
                            48.672790959729895
                        ],
                        [
                            9.39056396484375,
                            48.67430001584138
                        ],
                        [
                            9.392280578613281,
                            48.67591529342427
                        ],
                        [
                            9.390735626220703,
                            48.67651038263678
                        ],
                        [
                            9.391947984695435,
                            48.67835228088542
                        ],
                        [
                            9.38555359840393,
                            48.68055538610339
                        ],
                        [
                            9.38158392906189,
                            48.68357298798766
                        ],
                        [
                            9.381219148635862,
                            48.68332506976686
                        ],
                        [
                            9.37707781791687,
                            48.67811850522374
                        ],
                        [
                            9.37260389328003,
                            48.672330440647734
                        ],
                        [
                            9.36985731124878,
                            48.66863905099789
                        ],
                        [
                            9.370930194854736,
                            48.6677179327282
                        ],
                        [
                            9.36983585357666,
                            48.66647793923832
                        ],
                        [
                            9.36835527420044,
                            48.66574810023468
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--27",
            "properties": {
                "land": "de",
                "city": "Siegen",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.umwelt.nrw.de/umwelt/umwelt-und-gesundheit/luft/umweltzonen",
                "urlPark": "https://kultursiegen.de/anfahrt-parken/",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                8.012101650238037,
                                50.87374047470442
                            ],
                            [
                                8.01403284072876,
                                50.87346966461366
                            ],
                            [
                                8.008689880371094,
                                50.87161457318479
                            ],
                            [
                                8.006157875061035,
                                50.87115417409504
                            ],
                            [
                                8.003947734832764,
                                50.869759407920085
                            ],
                            [
                                8.003926277160645,
                                50.86882502665287
                            ],
                            [
                                8.004827499389648,
                                50.86789062665583
                            ],
                            [
                                8.008067607879639,
                                50.867227055864376
                            ],
                            [
                                8.009140491485596,
                                50.86733539459797
                            ],
                            [
                                8.010706901550293,
                                50.86710517448815
                            ],
                            [
                                8.01203727722168,
                                50.86679369841226
                            ],
                            [
                                8.016972541809082,
                                50.86924482345238
                            ],
                            [
                                8.018453121185303,
                                50.86885211043143
                            ],
                            [
                                8.018903732299805,
                                50.869339615754825
                            ],
                            [
                                8.023817539215088,
                                50.87009794723505
                            ],
                            [
                                8.02426815032959,
                                50.86958336650463
                            ],
                            [
                                8.025040626525879,
                                50.86935315749659
                            ],
                            [
                                8.027229309082031,
                                50.86932607400914
                            ],
                            [
                                8.027615547180176,
                                50.86896044538834
                            ],
                            [
                                8.028280735015867,
                                50.869109405543014
                            ],
                            [
                                8.028581142425537,
                                50.87053127397044
                            ],
                            [
                                8.029782772064209,
                                50.8704500255144
                            ],
                            [
                                8.03102731704712,
                                50.8706666877491
                            ],
                            [
                                8.031413555145264,
                                50.871194797727064
                            ],
                            [
                                8.031091690063477,
                                50.872088508674025
                            ],
                            [
                                8.031327724456787,
                                50.87310406849505
                            ],
                            [
                                8.032078742980957,
                                50.8749049401643
                            ],
                            [
                                8.031971454620361,
                                50.87609645609884
                            ],
                            [
                                8.03152084350586,
                                50.876759900631285
                            ],
                            [
                                8.031885623931885,
                                50.87789721214146
                            ],
                            [
                                8.032121658325195,
                                50.879156346079604
                            ],
                            [
                                8.032400608062744,
                                50.87953543350478
                            ],
                            [
                                8.032336235046387,
                                50.88086221520634
                            ],
                            [
                                8.03152084350586,
                                50.88209419295881
                            ],
                            [
                                8.030812740325928,
                                50.882825241279896
                            ],
                            [
                                8.030480146408081,
                                50.88401655470748
                            ],
                            [
                                8.031193614006042,
                                50.88566132943372
                            ],
                            [
                                8.031966090202332,
                                50.88696424761592
                            ],
                            [
                                8.03202509880066,
                                50.889458303633745
                            ],
                            [
                                8.03226113319397,
                                50.88983053934816
                            ],
                            [
                                8.030641078948975,
                                50.88973578872126
                            ],
                            [
                                8.030083179473877,
                                50.89210449657297
                            ],
                            [
                                8.030179738998413,
                                50.89399260883432
                            ],
                            [
                                8.030394315719604,
                                50.89506859556017
                            ],
                            [
                                8.032196760177612,
                                50.8970513235961
                            ],
                            [
                                8.032636642456053,
                                50.89850617272309
                            ],
                            [
                                8.031671047210693,
                                50.89887156954821
                            ],
                            [
                                8.03169250488281,
                                50.89943319241115
                            ],
                            [
                                8.031220436096191,
                                50.899629420489326
                            ],
                            [
                                8.030104637145996,
                                50.899588821644436
                            ],
                            [
                                8.028838634490967,
                                50.89904750033027
                            ],
                            [
                                8.027122020721436,
                                50.89887156954821
                            ],
                            [
                                8.025212287902832,
                                50.89890540244254
                            ],
                            [
                                8.023860454559326,
                                50.89834377321379
                            ],
                            [
                                8.023399114608765,
                                50.896665611786446
                            ],
                            [
                                8.023366928100586,
                                50.89538664976919
                            ],
                            [
                                8.023560047149658,
                                50.893870797449864
                            ],
                            [
                                8.023581504821777,
                                50.89250378117493
                            ],
                            [
                                8.024343252182007,
                                50.891062279958824
                            ],
                            [
                                8.025051355361938,
                                50.890243379115944
                            ],
                            [
                                8.025383949279785,
                                50.889437999781975
                            ],
                            [
                                8.025448322296143,
                                50.88796256286368
                            ],
                            [
                                8.025426864624023,
                                50.88623664844853
                            ],
                            [
                                8.025212287902832,
                                50.8834479770987
                            ],
                            [
                                8.024826049804686,
                                50.88175574077756
                            ],
                            [
                                8.024096488952637,
                                50.880896061184124
                            ],
                            [
                                8.022186756134033,
                                50.87958958859947
                            ],
                            [
                                8.019429445266724,
                                50.878140918139586
                            ],
                            [
                                8.019225597381592,
                                50.87779566759939
                            ],
                            [
                                8.01852822303772,
                                50.87767381385687
                            ],
                            [
                                8.015846014022827,
                                50.876441719228055
                            ],
                            [
                                8.013206720352173,
                                50.87509450155524
                            ],
                            [
                                8.012477159500122,
                                50.87453935530439
                            ],
                            [
                                8.012101650238037,
                                50.87374047470442
                            ]
                        ],
                        [
                            [
                                8.02574872970581,
                                50.90077970638429
                            ],
                            [
                                8.024976253509521,
                                50.900414324532576
                            ],
                            [
                                8.024901151657104,
                                50.899994808500296
                            ],
                            [
                                8.025662899017334,
                                50.89933846131886
                            ],
                            [
                                8.027122020721436,
                                50.899331694804886
                            ],
                            [
                                8.026349544525145,
                                50.9002722308159
                            ],
                            [
                                8.02574872970581,
                                50.90077970638429
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--28",
            "properties": {
                "land": "de",
                "city": "Muehlacker",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.muehlacker.de/stadt/bauen-wirtschaft-verkehr/verkehr/umweltzone.php",
                "urlPark": "https://www.muehlacker.de/stadt/bauen-wirtschaft-verkehr/verkehr/parken.php",
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.858306407928467,
                            48.9484776830304
                        ],
                        [
                            8.864507675170898,
                            48.949816410421164
                        ],
                        [
                            8.870837688446045,
                            48.9509014578462
                        ],
                        [
                            8.870859146118164,
                            48.95167647727426
                        ],
                        [
                            8.872404098510742,
                            48.95270512083033
                        ],
                        [
                            8.872168064117432,
                            48.954170548992145
                        ],
                        [
                            8.866267204284668,
                            48.95469189286069
                        ],
                        [
                            8.861331939697266,
                            48.95342374963458
                        ],
                        [
                            8.854401111602783,
                            48.95222602920997
                        ],
                        [
                            8.85399341583252,
                            48.95288830170462
                        ],
                        [
                            8.848307132720947,
                            48.95219784720668
                        ],
                        [
                            8.847663402557373,
                            48.9524233027873
                        ],
                        [
                            8.847770690917969,
                            48.95339556830784
                        ],
                        [
                            8.842663764953613,
                            48.95256421200781
                        ],
                        [
                            8.842084407806396,
                            48.95185966192542
                        ],
                        [
                            8.839101791381836,
                            48.951267832167154
                        ],
                        [
                            8.837299346923828,
                            48.95060553816136
                        ],
                        [
                            8.835797309875488,
                            48.94994323536401
                        ],
                        [
                            8.83526086807251,
                            48.94952048430087
                        ],
                        [
                            8.836848735809326,
                            48.94812538037874
                        ],
                        [
                            8.83852243423462,
                            48.94763215248751
                        ],
                        [
                            8.839380741119385,
                            48.946955717737175
                        ],
                        [
                            8.839895725250244,
                            48.946236995767585
                        ],
                        [
                            8.840517997741697,
                            48.945814213302775
                        ],
                        [
                            8.841354846954346,
                            48.94575784203684
                        ],
                        [
                            8.842127323150635,
                            48.94615243956119
                        ],
                        [
                            8.842792510986328,
                            48.946603404339704
                        ],
                        [
                            8.843393325805664,
                            48.94677251508092
                        ],
                        [
                            8.846054077148438,
                            48.94680070014876
                        ],
                        [
                            8.84972333908081,
                            48.94706845749904
                        ],
                        [
                            8.851826190948486,
                            48.94732212103187
                        ],
                        [
                            8.856096267700194,
                            48.94811128822094
                        ],
                        [
                            8.858306407928467,
                            48.9484776830304
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--29",
            "properties": {
                "land": "de",
                "city": "Neuss",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.neuss.de/leben/umwelt-und-gruen/umweltzone",
                "urlPark": "https://www.neuss.de/parken",
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            6.689558029174805,
                            51.20654730738944
                        ],
                        [
                            6.688377857208252,
                            51.206143999180625
                        ],
                        [
                            6.6869401931762695,
                            51.20447695445007
                        ],
                        [
                            6.686275005340576,
                            51.203871963618234
                        ],
                        [
                            6.683678627014159,
                            51.202688847471634
                        ],
                        [
                            6.681683063507079,
                            51.201035122844104
                        ],
                        [
                            6.680588722229004,
                            51.19915276240448
                        ],
                        [
                            6.680116653442383,
                            51.19821155334099
                        ],
                        [
                            6.680138111114502,
                            51.19615427229752
                        ],
                        [
                            6.682455539703369,
                            51.19342453538269
                        ],
                        [
                            6.6881632804870605,
                            51.18986084235737
                        ],
                        [
                            6.690845489501953,
                            51.189134622051775
                        ],
                        [
                            6.696960926055908,
                            51.1881663105089
                        ],
                        [
                            6.697647571563721,
                            51.189686012588965
                        ],
                        [
                            6.69865608215332,
                            51.191380488558984
                        ],
                        [
                            6.703591346740722,
                            51.1903449828699
                        ],
                        [
                            6.704750061035156,
                            51.19065429220121
                        ],
                        [
                            6.706016063690185,
                            51.18991463599882
                        ],
                        [
                            6.706681251525879,
                            51.19073498125053
                        ],
                        [
                            6.703076362609863,
                            51.19296732225525
                        ],
                        [
                            6.700329780578613,
                            51.19428515955074
                        ],
                        [
                            6.697368621826172,
                            51.19681315008202
                        ],
                        [
                            6.694729328155518,
                            51.198534255757586
                        ],
                        [
                            6.695544719696045,
                            51.198803172711344
                        ],
                        [
                            6.692969799041748,
                            51.20225861588382
                        ],
                        [
                            6.689558029174805,
                            51.20654730738944
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--30",
            "properties": {
                "land": "de",
                "city": "Krefeld",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.krefeld.de/de/umwelt/umweltzone-in-krefeld/",
                "urlPark": "https://www.krefeld.de/de/stadtmarketing/parken-in-der-innenstadt",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            6.547486782073975,
                            51.33425827907714
                        ],
                        [
                            6.545898914337158,
                            51.333480710759325
                        ],
                        [
                            6.545662879943848,
                            51.33236796074456
                        ],
                        [
                            6.547830104827881,
                            51.32746081325869
                        ],
                        [
                            6.548731327056885,
                            51.326401551311704
                        ],
                        [
                            6.549417972564697,
                            51.323759994052075
                        ],
                        [
                            6.551628112792968,
                            51.31716214084999
                        ],
                        [
                            6.556541919708252,
                            51.31325930539373
                        ],
                        [
                            6.560339927673339,
                            51.31296423216915
                        ],
                        [
                            6.567378044128418,
                            51.31504311674649
                        ],
                        [
                            6.571948528289795,
                            51.3163842826126
                        ],
                        [
                            6.575789451599121,
                            51.31681344740909
                        ],
                        [
                            6.581153869628906,
                            51.318449601364385
                        ],
                        [
                            6.589457988739014,
                            51.32176196389791
                        ],
                        [
                            6.593148708343506,
                            51.322660417757156
                        ],
                        [
                            6.589586734771728,
                            51.328251891664124
                        ],
                        [
                            6.585896015167236,
                            51.333534336583924
                        ],
                        [
                            6.580381393432617,
                            51.337422041750706
                        ],
                        [
                            6.584844589233398,
                            51.33838721401522
                        ],
                        [
                            6.5813255310058585,
                            51.34305192681701
                        ],
                        [
                            6.577892303466796,
                            51.34589341572041
                        ],
                        [
                            6.577033996582031,
                            51.34728728926183
                        ],
                        [
                            6.577119827270508,
                            51.3486811204066
                        ],
                        [
                            6.5744590759277335,
                            51.3486811204066
                        ],
                        [
                            6.574201583862305,
                            51.349753269360846
                        ],
                        [
                            6.574115753173828,
                            51.35275515297439
                        ],
                        [
                            6.572656631469727,
                            51.35559604024435
                        ],
                        [
                            6.569652557373046,
                            51.35361279788325
                        ],
                        [
                            6.567935943603516,
                            51.351468655508235
                        ],
                        [
                            6.5650177001953125,
                            51.34787699222799
                        ],
                        [
                            6.561841964721679,
                            51.34466033840012
                        ],
                        [
                            6.558237075805664,
                            51.34230131542749
                        ],
                        [
                            6.55085563659668,
                            51.34610786056325
                        ],
                        [
                            6.545963287353516,
                            51.348573904131435
                        ],
                        [
                            6.544589996337891,
                            51.3486811204066
                        ],
                        [
                            6.541929244995117,
                            51.34696562989989
                        ],
                        [
                            6.537981033325194,
                            51.343534456218855
                        ],
                        [
                            6.534719467163086,
                            51.33956684140783
                        ],
                        [
                            6.540985107421875,
                            51.33720755626551
                        ],
                        [
                            6.543989181518555,
                            51.33565250648534
                        ],
                        [
                            6.547486782073975,
                            51.33425827907714
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--31",
            "properties": {
                "land": "de",
                "city": "Langenfeld",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.umwelt.nrw.de/umwelt/umwelt-und-gesundheit/luft/umweltzonen",
                "urlPark": "https://www.langenfeld.de/Startseite/Aktuelles-und-Information/Unsere-Stadt/Verkehrsanbindung/Parken.htm?",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            6.97455883026123,
                            51.128522178293224
                        ],
                        [
                            6.95683479309082,
                            51.127660326365735
                        ],
                        [
                            6.957178115844727,
                            51.12597697543843
                        ],
                        [
                            6.9609761238098145,
                            51.12603084361832
                        ],
                        [
                            6.961405277252196,
                            51.12406461435013
                        ],
                        [
                            6.960718631744385,
                            51.12392993804986
                        ],
                        [
                            6.960439682006835,
                            51.125599896420155
                        ],
                        [
                            6.957135200500488,
                            51.12546522459618
                        ],
                        [
                            6.957499980926513,
                            51.12308146832909
                        ],
                        [
                            6.963164806365967,
                            51.11812501633995
                        ],
                        [
                            6.964538097381592,
                            51.118906231573945
                        ],
                        [
                            6.966490745544434,
                            51.117222561609196
                        ],
                        [
                            6.971576213836669,
                            51.118892762457676
                        ],
                        [
                            6.977949142456054,
                            51.12083227476986
                        ],
                        [
                            6.977090835571289,
                            51.12273130167903
                        ],
                        [
                            6.977777481079102,
                            51.12343163232476
                        ],
                        [
                            6.976339817047119,
                            51.12524974886106
                        ],
                        [
                            6.97455883026123,
                            51.128522178293224
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--32",
            "properties": {
                "land": "de",
                "city": "Leipzig",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.leipzig.de/umwelt-und-verkehr/luft-und-laerm/umweltzone/",
                "urlPark": "https://www.leipzig.de/umwelt-und-verkehr/unterwegs-in-leipzig/auto-motorrad-und-reisemobile/park-ride/#c59912",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            12.267608642578125,
                            51.39733140207045
                        ],
                        [
                            12.263896465301514,
                            51.39285966336813
                        ],
                        [
                            12.25773811340332,
                            51.389351586358394
                        ],
                        [
                            12.256407737731934,
                            51.3866199202953
                        ],
                        [
                            12.266128063201904,
                            51.38603071603923
                        ],
                        [
                            12.265613079071043,
                            51.38403539982823
                        ],
                        [
                            12.264411449432373,
                            51.383553296956315
                        ],
                        [
                            12.257137298583984,
                            51.384905850483925
                        ],
                        [
                            12.258768081665039,
                            51.38115609893754
                        ],
                        [
                            12.255699634552002,
                            51.380673965734715
                        ],
                        [
                            12.250034809112547,
                            51.37800874889903
                        ],
                        [
                            12.243382930755615,
                            51.37403072457131
                        ],
                        [
                            12.24226713180542,
                            51.36662290972303
                        ],
                        [
                            12.241086959838867,
                            51.3608083422056
                        ],
                        [
                            12.241387367248535,
                            51.35594443882009
                        ],
                        [
                            12.242631912231444,
                            51.35291596261771
                        ],
                        [
                            12.242116928100586,
                            51.348989365800364
                        ],
                        [
                            12.245721817016602,
                            51.34723367952496
                        ],
                        [
                            12.252717018127441,
                            51.34705944744704
                        ],
                        [
                            12.252974510192871,
                            51.34825225380073
                        ],
                        [
                            12.26250171661377,
                            51.34803781899259
                        ],
                        [
                            12.268037796020506,
                            51.34874813045121
                        ],
                        [
                            12.269175052642822,
                            51.34877493444162
                        ],
                        [
                            12.269690036773682,
                            51.348480089684884
                        ],
                        [
                            12.269840240478516,
                            51.346992434932964
                        ],
                        [
                            12.267673015594482,
                            51.346791396802814
                        ],
                        [
                            12.267394065856934,
                            51.34561195534128
                        ],
                        [
                            12.263166904449463,
                            51.34594702702519
                        ],
                        [
                            12.263896465301514,
                            51.34203323695207
                        ],
                        [
                            12.264776229858398,
                            51.334633652165806
                        ],
                        [
                            12.266857624053955,
                            51.32853345865091
                        ],
                        [
                            12.26572036743164,
                            51.3264551854161
                        ],
                        [
                            12.271556854248047,
                            51.32696470627908
                        ],
                        [
                            12.273788452148436,
                            51.32687084759811
                        ],
                        [
                            12.27820873260498,
                            51.31960292072515
                        ],
                        [
                            12.279324531555176,
                            51.31651839704277
                        ],
                        [
                            12.282156944274902,
                            51.311381534305454
                        ],
                        [
                            12.275590896606445,
                            51.309865848736806
                        ],
                        [
                            12.267758846282959,
                            51.30730382389792
                        ],
                        [
                            12.26121425628662,
                            51.30613678087381
                        ],
                        [
                            12.260613441467285,
                            51.30364162382156
                        ],
                        [
                            12.261857986450195,
                            51.298919234886654
                        ],
                        [
                            12.282392978668213,
                            51.30290378257687
                        ],
                        [
                            12.285547256469725,
                            51.30266230468425
                        ],
                        [
                            12.288036346435547,
                            51.296866454117094
                        ],
                        [
                            12.29089021682739,
                            51.29037208561474
                        ],
                        [
                            12.285740375518799,
                            51.28775529323645
                        ],
                        [
                            12.281663417816162,
                            51.28205151111749
                        ],
                        [
                            12.277779579162596,
                            51.27853494425065
                        ],
                        [
                            12.271664142608643,
                            51.27711213459822
                        ],
                        [
                            12.263853549957275,
                            51.27640071324687
                        ],
                        [
                            12.265655994415283,
                            51.27422611175821
                        ],
                        [
                            12.267286777496338,
                            51.267540581144196
                        ],
                        [
                            12.268574237823486,
                            51.26498962033765
                        ],
                        [
                            12.271149158477783,
                            51.264586824108825
                        ],
                        [
                            12.272500991821289,
                            51.26374094053775
                        ],
                        [
                            12.282135486602781,
                            51.26254593562866
                        ],
                        [
                            12.289323806762695,
                            51.260343822736495
                        ],
                        [
                            12.297134399414062,
                            51.256624160772205
                        ],
                        [
                            12.31043815612793,
                            51.25879959528236
                        ],
                        [
                            12.313227653503418,
                            51.267298917244716
                        ],
                        [
                            12.319064140319824,
                            51.270883468039685
                        ],
                        [
                            12.321016788482666,
                            51.27677655986179
                        ],
                        [
                            12.3231840133667,
                            51.284024428830115
                        ],
                        [
                            12.327475547790526,
                            51.28825182427875
                        ],
                        [
                            12.33165979385376,
                            51.293699893323726
                        ],
                        [
                            12.332196235656738,
                            51.29862406944621
                        ],
                        [
                            12.338933944702148,
                            51.29885215199866
                        ],
                        [
                            12.350199222564697,
                            51.29454522541372
                        ],
                        [
                            12.36238718032837,
                            51.304701411400174
                        ],
                        [
                            12.370884418487547,
                            51.30450018779272
                        ],
                        [
                            12.375540733337402,
                            51.30307818248702
                        ],
                        [
                            12.37633466720581,
                            51.30126707420632
                        ],
                        [
                            12.377429008483887,
                            51.297631226308994
                        ],
                        [
                            12.378373146057129,
                            51.29348520333045
                        ],
                        [
                            12.378759384155273,
                            51.29123089779079
                        ],
                        [
                            12.380433082580566,
                            51.289083837233164
                        ],
                        [
                            12.383673191070557,
                            51.28956693461154
                        ],
                        [
                            12.386784553527832,
                            51.2899560926925
                        ],
                        [
                            12.389252185821533,
                            51.289647450347175
                        ],
                        [
                            12.391633987426758,
                            51.2897548044417
                        ],
                        [
                            12.392470836639404,
                            51.28913751497062
                        ],
                        [
                            12.39283561706543,
                            51.28755399533851
                        ],
                        [
                            12.39433765411377,
                            51.285608070167235
                        ],
                        [
                            12.395496368408203,
                            51.28335337789393
                        ],
                        [
                            12.400410175323486,
                            51.28351443101278
                        ],
                        [
                            12.412383556365967,
                            51.28135358796176
                        ],
                        [
                            12.416460514068604,
                            51.28497730431364
                        ],
                        [
                            12.430129051208496,
                            51.28638645013014
                        ],
                        [
                            12.431974411010742,
                            51.284937042369286
                        ],
                        [
                            12.442102432250977,
                            51.28295074262611
                        ],
                        [
                            12.447059154510498,
                            51.28284337262525
                        ],
                        [
                            12.447831630706785,
                            51.280883826020606
                        ],
                        [
                            12.453410625457764,
                            51.28160859957508
                        ],
                        [
                            12.454440593719482,
                            51.279232910233056
                        ],
                        [
                            12.452423572540283,
                            51.278776549060055
                        ],
                        [
                            12.45373249053955,
                            51.273165620610044
                        ],
                        [
                            12.456028461456299,
                            51.26866832920547
                        ],
                        [
                            12.46143579483032,
                            51.26556695544289
                        ],
                        [
                            12.46699333190918,
                            51.26249222681329
                        ],
                        [
                            12.476541996002197,
                            51.26402290345785
                        ],
                        [
                            12.48570442199707,
                            51.26893683656952
                        ],
                        [
                            12.488515377044678,
                            51.272655502225085
                        ],
                        [
                            12.4904465675354,
                            51.277729585708734
                        ],
                        [
                            12.494287490844727,
                            51.28154149139306
                        ],
                        [
                            12.497141361236572,
                            51.28351443101278
                        ],
                        [
                            12.48967409133911,
                            51.28488335972189
                        ],
                        [
                            12.485854625701903,
                            51.28452100021084
                        ],
                        [
                            12.480683326721191,
                            51.28413179607006
                        ],
                        [
                            12.475941181182861,
                            51.281729394055645
                        ],
                        [
                            12.472808361053467,
                            51.28148780477684
                        ],
                        [
                            12.471220493316649,
                            51.28174281564499
                        ],
                        [
                            12.467100620269775,
                            51.286144885346566
                        ],
                        [
                            12.464311122894285,
                            51.28956693461154
                        ],
                        [
                            12.46347427368164,
                            51.29192867085649
                        ],
                        [
                            12.463560104370117,
                            51.29954980924195
                        ],
                        [
                            12.463645935058594,
                            51.30523800337354
                        ],
                        [
                            12.463817596435545,
                            51.31006704881808
                        ],
                        [
                            12.465019226074219,
                            51.31328613013825
                        ],
                        [
                            12.469396591186523,
                            51.31409086518039
                        ],
                        [
                            12.474117279052734,
                            51.31870440697447
                        ],
                        [
                            12.477636337280273,
                            51.3210110038862
                        ],
                        [
                            12.479696273803711,
                            51.32455113629829
                        ],
                        [
                            12.479524612426756,
                            51.33307851507538
                        ],
                        [
                            12.486991882324219,
                            51.33538438912286
                        ],
                        [
                            12.497549057006836,
                            51.33672496025461
                        ],
                        [
                            12.50844955444336,
                            51.337475662965204
                        ],
                        [
                            12.503128051757812,
                            51.34910998299869
                        ],
                        [
                            12.499351501464844,
                            51.3474481180961
                        ],
                        [
                            12.489995956420898,
                            51.34600063826727
                        ],
                        [
                            12.473001480102539,
                            51.345250075170945
                        ],
                        [
                            12.467422485351562,
                            51.34760894636592
                        ],
                        [
                            12.467336654663086,
                            51.35141505066322
                        ],
                        [
                            12.478494644165037,
                            51.35264794623193
                        ],
                        [
                            12.485103607177734,
                            51.35430962255015
                        ],
                        [
                            12.491884231567383,
                            51.35586403938396
                        ],
                        [
                            12.47274398803711,
                            51.36658271939382
                        ],
                        [
                            12.46622085571289,
                            51.361920401839974
                        ],
                        [
                            12.457466125488281,
                            51.36395687478539
                        ],
                        [
                            12.451286315917969,
                            51.35843675135407
                        ],
                        [
                            12.447166442871094,
                            51.36052697343981
                        ],
                        [
                            12.444934844970703,
                            51.359347885528635
                        ],
                        [
                            12.441673278808594,
                            51.35988383834181
                        ],
                        [
                            12.432489395141602,
                            51.355488840149505
                        ],
                        [
                            12.428627014160156,
                            51.3584903479837
                        ],
                        [
                            12.42974281311035,
                            51.36518943284107
                        ],
                        [
                            12.421245574951172,
                            51.36535019883751
                        ],
                        [
                            12.422189712524414,
                            51.367279346773806
                        ],
                        [
                            12.426738739013672,
                            51.372316183248635
                        ],
                        [
                            12.431201934814453,
                            51.37167321369868
                        ],
                        [
                            12.440729141235352,
                            51.36786879243345
                        ],
                        [
                            12.455148696899412,
                            51.37092307114478
                        ],
                        [
                            12.454118728637694,
                            51.37724531648993
                        ],
                        [
                            12.43901252746582,
                            51.38988718803266
                        ],
                        [
                            12.425880432128904,
                            51.39486798332534
                        ],
                        [
                            12.412662506103516,
                            51.38688773790379
                        ],
                        [
                            12.395496368408203,
                            51.38806611676009
                        ],
                        [
                            12.39309310913086,
                            51.39363622420581
                        ],
                        [
                            12.376012802124023,
                            51.38951226751899
                        ],
                        [
                            12.372751235961914,
                            51.39347555752776
                        ],
                        [
                            12.38759994506836,
                            51.39663523195962
                        ],
                        [
                            12.382879257202148,
                            51.40573815833822
                        ],
                        [
                            12.352924346923828,
                            51.411199044550045
                        ],
                        [
                            12.338418960571289,
                            51.39711719701097
                        ],
                        [
                            12.316875457763672,
                            51.39331489028546
                        ],
                        [
                            12.319879531860352,
                            51.38222748787189
                        ],
                        [
                            12.267608642578125,
                            51.39733140207045
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--33",
            "properties": {
                "land": "de",
                "city": "Moenchengladbach",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.moenchengladbach.de/de/rathaus/buergerinfo-a-z/planen-bauen-mobilitaet-umwelt-dezernat-vi/fachbereich-umwelt-64/landschaft-luftklima-immissionen/luftqualitaet-in-moenchengladbach/",
                "urlPark": "https://parken-in-mg.de/home",
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            6.402840614318848,
                            51.177997810927344
                        ],
                        [
                            6.403698921203613,
                            51.17858968984212
                        ],
                        [
                            6.405758857727051,
                            51.177217595293314
                        ],
                        [
                            6.406188011169434,
                            51.17385444575812
                        ],
                        [
                            6.406145095825195,
                            51.17197097486416
                        ],
                        [
                            6.402883529663086,
                            51.16696594983017
                        ],
                        [
                            6.401638984680176,
                            51.1622564249409
                        ],
                        [
                            6.4032697677612305,
                            51.15786940636664
                        ],
                        [
                            6.406316757202148,
                            51.15504319187703
                        ],
                        [
                            6.408333778381347,
                            51.15345505252833
                        ],
                        [
                            6.407990455627441,
                            51.15205529117409
                        ],
                        [
                            6.41275405883789,
                            51.14796343760179
                        ],
                        [
                            6.421294212341309,
                            51.14209422481464
                        ],
                        [
                            6.424341201782227,
                            51.14193267629501
                        ],
                        [
                            6.430306434631348,
                            51.148474939139376
                        ],
                        [
                            6.431636810302734,
                            51.1494710048123
                        ],
                        [
                            6.439490318298339,
                            51.14764038107829
                        ],
                        [
                            6.446270942687988,
                            51.14753269506792
                        ],
                        [
                            6.449446678161621,
                            51.14723655724396
                        ],
                        [
                            6.449189186096191,
                            51.15140923315107
                        ],
                        [
                            6.450905799865723,
                            51.15321278917862
                        ],
                        [
                            6.45197868347168,
                            51.15439717568624
                        ],
                        [
                            6.454381942749023,
                            51.15681968976518
                        ],
                        [
                            6.455583572387694,
                            51.16053396432425
                        ],
                        [
                            6.458587646484375,
                            51.16136828921691
                        ],
                        [
                            6.460905075073242,
                            51.16586261855921
                        ],
                        [
                            6.461033821105957,
                            51.16745033083134
                        ],
                        [
                            6.46519660949707,
                            51.168957261413446
                        ],
                        [
                            6.4604759216308585,
                            51.16954925638502
                        ],
                        [
                            6.455798149108887,
                            51.16965689101795
                        ],
                        [
                            6.453566551208496,
                            51.174661624019286
                        ],
                        [
                            6.450991630554199,
                            51.17646427111815
                        ],
                        [
                            6.449875831604004,
                            51.17818613686082
                        ],
                        [
                            6.4444684982299805,
                            51.17770186862044
                        ],
                        [
                            6.444125175476074,
                            51.17893943290186
                        ],
                        [
                            6.44322395324707,
                            51.18230221152909
                        ],
                        [
                            6.443567276000977,
                            51.18639101983867
                        ],
                        [
                            6.441249847412109,
                            51.19047946544296
                        ],
                        [
                            6.441335678100586,
                            51.191851165270975
                        ],
                        [
                            6.442880630493164,
                            51.194944064064515
                        ],
                        [
                            6.4479875564575195,
                            51.195455044751334
                        ],
                        [
                            6.447086334228516,
                            51.1973913411917
                        ],
                        [
                            6.44871711730957,
                            51.198440134452895
                        ],
                        [
                            6.446700096130371,
                            51.19970403306904
                        ],
                        [
                            6.448931694030762,
                            51.201156129098294
                        ],
                        [
                            6.450777053833008,
                            51.20338796523131
                        ],
                        [
                            6.452150344848632,
                            51.2052432647629
                        ],
                        [
                            6.458673477172852,
                            51.2092493812891
                        ],
                        [
                            6.456742286682129,
                            51.20919561022025
                        ],
                        [
                            6.453094482421875,
                            51.20994839947071
                        ],
                        [
                            6.447858810424805,
                            51.211588362016954
                        ],
                        [
                            6.4469146728515625,
                            51.211937854682034
                        ],
                        [
                            6.444211006164551,
                            51.21013659486059
                        ],
                        [
                            6.442880630493164,
                            51.20752867594488
                        ],
                        [
                            6.441936492919921,
                            51.20454417516309
                        ],
                        [
                            6.440906524658203,
                            51.20177460054433
                        ],
                        [
                            6.438760757446289,
                            51.19978470627149
                        ],
                        [
                            6.43549919128418,
                            51.198709051956094
                        ],
                        [
                            6.425027847290039,
                            51.197956078991474
                        ],
                        [
                            6.422324180603027,
                            51.19696106012634
                        ],
                        [
                            6.420650482177734,
                            51.19389519121627
                        ],
                        [
                            6.41876220703125,
                            51.1943255009142
                        ],
                        [
                            6.411294937133789,
                            51.19483648846097
                        ],
                        [
                            6.407046318054199,
                            51.196369417099916
                        ],
                        [
                            6.404557228088379,
                            51.19647698912439
                        ],
                        [
                            6.398248672485352,
                            51.196288737916774
                        ],
                        [
                            6.390523910522461,
                            51.194029663428566
                        ],
                        [
                            6.390695571899414,
                            51.19273871399024
                        ],
                        [
                            6.388678550720215,
                            51.189887739185956
                        ],
                        [
                            6.3885498046875,
                            51.187709045208095
                        ],
                        [
                            6.402840614318848,
                            51.177997810927344
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--34",
            "properties": {
                "land": "de",
                "city": "Overath",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.overath.de/umweltzone-overath.aspx",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            7.284740209579468,
                            50.932644854417504
                        ],
                        [
                            7.284901142120361,
                            50.93209377485365
                        ],
                        [
                            7.284300327301025,
                            50.9316779252159
                        ],
                        [
                            7.283881902694702,
                            50.93101188187081
                        ],
                        [
                            7.283999919891357,
                            50.92992658268805
                        ],
                        [
                            7.283785343170167,
                            50.92962905076345
                        ],
                        [
                            7.283270359039307,
                            50.92884463929388
                        ],
                        [
                            7.2858452796936035,
                            50.92873644357031
                        ],
                        [
                            7.289621829986573,
                            50.92829013355036
                        ],
                        [
                            7.292577624320983,
                            50.929361946619004
                        ],
                        [
                            7.2932857275009155,
                            50.93056221269628
                        ],
                        [
                            7.293044328689575,
                            50.931857112914095
                        ],
                        [
                            7.292379140853882,
                            50.933392013213975
                        ],
                        [
                            7.29092001914978,
                            50.93302350652942
                        ],
                        [
                            7.290930747985839,
                            50.93278684932073
                        ],
                        [
                            7.288114428520203,
                            50.93193487339848
                        ],
                        [
                            7.287835478782654,
                            50.93222900970702
                        ],
                        [
                            7.290378212928772,
                            50.93308098024121
                        ],
                        [
                            7.2915422916412345,
                            50.93402083439204
                        ],
                        [
                            7.291032671928406,
                            50.93448399294047
                        ],
                        [
                            7.291697859764099,
                            50.93525816736849
                        ],
                        [
                            7.291048765182494,
                            50.9356706043783
                        ],
                        [
                            7.290405035018922,
                            50.93561313386677
                        ],
                        [
                            7.287889122962951,
                            50.935792306403584
                        ],
                        [
                            7.285652160644531,
                            50.93565370129404
                        ],
                        [
                            7.284927964210509,
                            50.935437341272944
                        ],
                        [
                            7.284579277038574,
                            50.93514322525499
                        ],
                        [
                            7.284590005874634,
                            50.93425748532155
                        ],
                        [
                            7.284042835235596,
                            50.933814609028644
                        ],
                        [
                            7.284053564071655,
                            50.93350695965577
                        ],
                        [
                            7.283769249916077,
                            50.933087741849675
                        ],
                        [
                            7.283715605735779,
                            50.93283080003625
                        ],
                        [
                            7.284740209579468,
                            50.932644854417504
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--35",
            "properties": {
                "land": "de",
                "city": "Dinslaken",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.dinslaken.de/bauen-planen/umwelt/luft/umweltzone",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            6.732231974601746,
                            51.570184763025665
                        ],
                        [
                            6.727516651153564,
                            51.56566658426592
                        ],
                        [
                            6.725778579711914,
                            51.56351904151964
                        ],
                        [
                            6.725285053253174,
                            51.56201169938732
                        ],
                        [
                            6.724791526794434,
                            51.55724923710352
                        ],
                        [
                            6.7256927490234375,
                            51.555995172489844
                        ],
                        [
                            6.728203296661377,
                            51.553727095480895
                        ],
                        [
                            6.7296624183654785,
                            51.552099346981954
                        ],
                        [
                            6.729319095611572,
                            51.549043825718314
                        ],
                        [
                            6.729726791381836,
                            51.5477628474572
                        ],
                        [
                            6.730842590332031,
                            51.54628167138217
                        ],
                        [
                            6.733996868133545,
                            51.54564114787502
                        ],
                        [
                            6.7379021644592285,
                            51.54501395987049
                        ],
                        [
                            6.741185188293457,
                            51.544066489256686
                        ],
                        [
                            6.745197772979736,
                            51.54150421639033
                        ],
                        [
                            6.747536659240723,
                            51.53946230190699
                        ],
                        [
                            6.748974323272705,
                            51.539675839737235
                        ],
                        [
                            6.752858161926269,
                            51.544667001090126
                        ],
                        [
                            6.754295825958251,
                            51.54614822972842
                        ],
                        [
                            6.756184101104736,
                            51.546895497948725
                        ],
                        [
                            6.755003929138184,
                            51.549724330749775
                        ],
                        [
                            6.753630638122559,
                            51.55141887747668
                        ],
                        [
                            6.7499613761901855,
                            51.553193413866374
                        ],
                        [
                            6.74860954284668,
                            51.55398059205439
                        ],
                        [
                            6.746034622192382,
                            51.55366038562151
                        ],
                        [
                            6.7447686195373535,
                            51.55531476126207
                        ],
                        [
                            6.746785640716553,
                            51.55612858526253
                        ],
                        [
                            6.744382381439209,
                            51.55863667255574
                        ],
                        [
                            6.743288040161133,
                            51.56094452430596
                        ],
                        [
                            6.744124889373779,
                            51.56153147369988
                        ],
                        [
                            6.742064952850342,
                            51.561945001678446
                        ],
                        [
                            6.742558479309081,
                            51.56302549251787
                        ],
                        [
                            6.744554042816161,
                            51.562972135548186
                        ],
                        [
                            6.744554042816161,
                            51.56370578839362
                        ],
                        [
                            6.743266582489014,
                            51.56429270214742
                        ],
                        [
                            6.743781566619873,
                            51.564799576111035
                        ],
                        [
                            6.732231974601746,
                            51.570184763025665
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--36",
            "properties": {
                "land": "de",
                "city": "Herrenberg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "CANCELED 1/1/2024!",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on Jan 1, 2024.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rps/wir/abteilungen/pressemitteilungen-und-aktuelle-meldungen/artikel/luftreinhaltung-umweltzonen-in-heidenheim-heilbronn-herrenberg-und-leonberg-hemmingen-werden-aufgehoben/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.8649582862854,
                            48.58807683161918
                        ],
                        [
                            8.874506950378418,
                            48.5881052187359
                        ],
                        [
                            8.877339363098145,
                            48.59075934370107
                        ],
                        [
                            8.879914283752441,
                            48.59124189689922
                        ],
                        [
                            8.885965347290039,
                            48.593952624794
                        ],
                        [
                            8.889141082763672,
                            48.60001220777119
                        ],
                        [
                            8.888518810272217,
                            48.60083523323139
                        ],
                        [
                            8.873777389526367,
                            48.60448192606988
                        ],
                        [
                            8.87272596359253,
                            48.60441098124976
                        ],
                        [
                            8.871738910675049,
                            48.60382922996566
                        ],
                        [
                            8.869121074676514,
                            48.60086361318052
                        ],
                        [
                            8.867747783660889,
                            48.60006896857828
                        ],
                        [
                            8.86521577835083,
                            48.59954392867905
                        ],
                        [
                            8.856825828552246,
                            48.599515547988474
                        ],
                        [
                            8.854637145996094,
                            48.60059400302024
                        ],
                        [
                            8.849852085113525,
                            48.59965745128183
                        ],
                        [
                            8.84697675704956,
                            48.5973443778922
                        ],
                        [
                            8.846096992492676,
                            48.59365459376432
                        ],
                        [
                            8.85349988937378,
                            48.59060322256209
                        ],
                        [
                            8.85324239730835,
                            48.587679410310756
                        ],
                        [
                            8.854830265045166,
                            48.58651551564243
                        ],
                        [
                            8.8569974899292,
                            48.5860613055735
                        ],
                        [
                            8.861331939697266,
                            48.58711166016273
                        ],
                        [
                            8.8649582862854,
                            48.58807683161918
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--37",
            "properties": {
                "land": "de",
                "city": "Ilsfeld",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on May 1, 2023.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rps/presse/artikel/luftreinhaltung-das-regierungspraesidium-stuttgart-hebt-umweltzonen-in-wendlingen-am-neckar-schwaebisch-gmuend-ilsfeld-und-urbach-zum-1-mai-2023-auf/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.235038757324219,
                            49.05752943501859
                        ],
                        [
                            9.232420921325684,
                            49.05626390802048
                        ],
                        [
                            9.234480857849121,
                            49.05283276162586
                        ],
                        [
                            9.241551160812378,
                            49.05183430979081
                        ],
                        [
                            9.245810508728027,
                            49.04945763249734
                        ],
                        [
                            9.254393577575684,
                            49.04883883396967
                        ],
                        [
                            9.255123138427734,
                            49.04968264823436
                        ],
                        [
                            9.254007339477539,
                            49.056629508018354
                        ],
                        [
                            9.248589277267456,
                            49.06117819081387
                        ],
                        [
                            9.24323558807373,
                            49.06096025723818
                        ],
                        [
                            9.238965511322021,
                            49.06032754144249
                        ],
                        [
                            9.235038757324219,
                            49.05752943501859
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Pforzheim",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.pforzheim.de/stadt/umwelt-natur/luft-und-klima/luftreinhaltung-umweltzonen-und-feinstaubplakette.html",
                "urlPark": "https://www.ws-pforzheim.de/standortmarketing/einkaufen/innenstadt/parken-in-pforzheim.html",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                8.701472282409668,
                                48.89319213336169
                            ],
                            [
                                8.69368314743042,
                                48.89344607066274
                            ],
                            [
                                8.69342565536499,
                                48.89509663167985
                            ],
                            [
                                8.687245845794678,
                                48.895533950680296
                            ],
                            [
                                8.679842948913574,
                                48.89388340410209
                            ],
                            [
                                8.676259517669678,
                                48.89269836269508
                            ],
                            [
                                8.672826290130615,
                                48.89183777930548
                            ],
                            [
                                8.675508499145508,
                                48.89134399526274
                            ],
                            [
                                8.679070472717285,
                                48.89152740133363
                            ],
                            [
                                8.679478168487549,
                                48.88994726542142
                            ],
                            [
                                8.678405284881592,
                                48.88952400625105
                            ],
                            [
                                8.679392337799072,
                                48.888211880053255
                            ],
                            [
                                8.680164813995361,
                                48.88847995164504
                            ],
                            [
                                8.681838512420654,
                                48.88866336822189
                            ],
                            [
                                8.686258792877197,
                                48.888959655117084
                            ],
                            [
                                8.687524795532225,
                                48.8890160905171
                            ],
                            [
                                8.692352771759033,
                                48.88912896112609
                            ],
                            [
                                8.694283962249756,
                                48.88877623962707
                            ],
                            [
                                8.69640827178955,
                                48.88694204772288
                            ],
                            [
                                8.69894027709961,
                                48.88547464575756
                            ],
                            [
                                8.700528144836426,
                                48.886504653564785
                            ],
                            [
                                8.703789710998535,
                                48.8874640938066
                            ],
                            [
                                8.702952861785889,
                                48.88972152764311
                            ],
                            [
                                8.704090118408201,
                                48.890596255857126
                            ],
                            [
                                8.704004287719727,
                                48.891541509465064
                            ],
                            [
                                8.702287673950195,
                                48.891865995389225
                            ],
                            [
                                8.702330589294434,
                                48.89257139230686
                            ],
                            [
                                8.702974319458008,
                                48.89313570267518
                            ],
                            [
                                8.701472282409668,
                                48.89319213336169
                            ]
                        ],
                        [
                            [
                                8.694798946380615,
                                48.89489913152242
                            ],
                            [
                                8.694713115692139,
                                48.89382697419586
                            ],
                            [
                                8.706386089324951,
                                48.89358714638375
                            ],
                            [
                                8.706300258636475,
                                48.894095015683185
                            ],
                            [
                                8.696794509887695,
                                48.89426430430316
                            ],
                            [
                                8.694798946380615,
                                48.89489913152242
                            ]
                        ],
                        [
                            [
                                8.707072734832764,
                                48.89399626372349
                            ],
                            [
                                8.707137107849121,
                                48.89323445633477
                            ],
                            [
                                8.705506324768066,
                                48.89306516422748
                            ],
                            [
                                8.706772327423096,
                                48.89281122499168
                            ],
                            [
                                8.71016263961792,
                                48.89353071614313
                            ],
                            [
                                8.713574409484863,
                                48.894320733715745
                            ],
                            [
                                8.720462322235107,
                                48.89657785798963
                            ],
                            [
                                8.719625473022461,
                                48.897960296280566
                            ],
                            [
                                8.707072734832764,
                                48.89399626372349
                            ]
                        ],
                        [
                            [
                                8.704047203063965,
                                48.89307927192498
                            ],
                            [
                                8.702974319458008,
                                48.89235977427657
                            ],
                            [
                                8.703982830047607,
                                48.89204939954524
                            ],
                            [
                                8.704969882965088,
                                48.89161205006251
                            ],
                            [
                                8.704969882965088,
                                48.890624472641505
                            ],
                            [
                                8.703875541687012,
                                48.88965098437837
                            ],
                            [
                                8.704111576080322,
                                48.88859282346427
                            ],
                            [
                                8.70966911315918,
                                48.88935470157968
                            ],
                            [
                                8.71340274810791,
                                48.89034230408117
                            ],
                            [
                                8.716878890991211,
                                48.89213404739032
                            ],
                            [
                                8.721685409545897,
                                48.894532343445675
                            ],
                            [
                                8.720676898956299,
                                48.89625340264721
                            ],
                            [
                                8.71617078781128,
                                48.89458877255573
                            ],
                            [
                                8.711321353912354,
                                48.893333209799295
                            ],
                            [
                                8.706278800964355,
                                48.89237388217313
                            ],
                            [
                                8.704047203063965,
                                48.89307927192498
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--39",
            "properties": {
                "land": "de",
                "city": "Remscheid",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.remscheid.de/wirtschaft-stadtentwickung/mobilitaet-verkehr/luftreinhalteplan/umweltzone.php",
                "urlPark": "https://www.parkservice-remscheid.de/startseite",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            7.183454632759094,
                            51.18741988980869
                        ],
                        [
                            7.181593179702758,
                            51.18762835086053
                        ],
                        [
                            7.174683809280395,
                            51.18559077176044
                        ],
                        [
                            7.173873782157898,
                            51.18508640658985
                        ],
                        [
                            7.173160314559936,
                            51.18561430866696
                        ],
                        [
                            7.170633673667908,
                            51.18612203043827
                        ],
                        [
                            7.169700264930725,
                            51.18623298875617
                        ],
                        [
                            7.169233560562134,
                            51.18643473046796
                        ],
                        [
                            7.166932225227356,
                            51.1854831743205
                        ],
                        [
                            7.168477177619933,
                            51.18449797358188
                        ],
                        [
                            7.168509364128113,
                            51.18427604832518
                        ],
                        [
                            7.170445919036864,
                            51.18401040911294
                        ],
                        [
                            7.168782949447632,
                            51.18269900322639
                        ],
                        [
                            7.168391346931457,
                            51.182174430424894
                        ],
                        [
                            7.170129418373109,
                            51.182292123560046
                        ],
                        [
                            7.172098159790038,
                            51.18253087156827
                        ],
                        [
                            7.173230051994324,
                            51.18242326698524
                        ],
                        [
                            7.176545262336731,
                            51.18212399041785
                        ],
                        [
                            7.178106307983399,
                            51.181744007257386
                        ],
                        [
                            7.178310155868531,
                            51.181370746413
                        ],
                        [
                            7.176529169082641,
                            51.1815018924595
                        ],
                        [
                            7.177864909172058,
                            51.17875783587508
                        ],
                        [
                            7.177623510360718,
                            51.17866367417218
                        ],
                        [
                            7.177537679672241,
                            51.17821304050282
                        ],
                        [
                            7.1796029806137085,
                            51.17712679347817
                        ],
                        [
                            7.181153297424316,
                            51.176131324814975
                        ],
                        [
                            7.181475162506104,
                            51.176444092622745
                        ],
                        [
                            7.182816267013549,
                            51.17632974764875
                        ],
                        [
                            7.182939648628234,
                            51.17589926990911
                        ],
                        [
                            7.185332179069518,
                            51.17634320001334
                        ],
                        [
                            7.18655526638031,
                            51.176178408276535
                        ],
                        [
                            7.187848091125489,
                            51.176053973309436
                        ],
                        [
                            7.189360857009887,
                            51.17629275362586
                        ],
                        [
                            7.190755605697631,
                            51.17618513448141
                        ],
                        [
                            7.191275954246521,
                            51.17682748252495
                        ],
                        [
                            7.192203998565675,
                            51.176592068050496
                        ],
                        [
                            7.1934109926223755,
                            51.17604724708545
                        ],
                        [
                            7.19602346420288,
                            51.17651471731796
                        ],
                        [
                            7.19853937625885,
                            51.177136882577585
                        ],
                        [
                            7.1983784437179565,
                            51.177792669302995
                        ],
                        [
                            7.199445962905884,
                            51.17793055147895
                        ],
                        [
                            7.200218439102174,
                            51.17784984001135
                        ],
                        [
                            7.200690507888794,
                            51.17843163201194
                        ],
                        [
                            7.199746370315552,
                            51.178905803876795
                        ],
                        [
                            7.199113368988037,
                            51.17961873395568
                        ],
                        [
                            7.197418212890624,
                            51.18011307050929
                        ],
                        [
                            7.1955835819244385,
                            51.18111517868634
                        ],
                        [
                            7.194108366966248,
                            51.181343844613764
                        ],
                        [
                            7.1929872035980225,
                            51.18143800084243
                        ],
                        [
                            7.190621495246887,
                            51.18203656094156
                        ],
                        [
                            7.188915610313416,
                            51.18258803640084
                        ],
                        [
                            7.186871767044067,
                            51.18392970828959
                        ],
                        [
                            7.1853107213974,
                            51.18565129521003
                        ],
                        [
                            7.183454632759094,
                            51.18741988980869
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Ruhrgebiet",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.umwelt.nrw.de/umwelt/umwelt-und-gesundheit/luft/umweltzonen/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                6.8352556228637695,
                                51.53169418224775
                            ],
                            [
                                6.833238601684569,
                                51.53100005892874
                            ],
                            [
                                6.831328868865967,
                                51.530252529672204
                            ],
                            [
                                6.833281517028809,
                                51.52842370099461
                            ],
                            [
                                6.832745075225829,
                                51.52722224071258
                            ],
                            [
                                6.830642223358154,
                                51.52197549265049
                            ],
                            [
                                6.829869747161865,
                                51.52013297968551
                            ],
                            [
                                6.829376220703125,
                                51.51925175148097
                            ],
                            [
                                6.828646659851074,
                                51.518557438461826
                            ],
                            [
                                6.827917098999023,
                                51.51870431325244
                            ],
                            [
                                6.822359561920166,
                                51.518944652797195
                            ],
                            [
                                6.821930408477783,
                                51.51839721087734
                            ],
                            [
                                6.820986270904541,
                                51.51626078922093
                            ],
                            [
                                6.820621490478516,
                                51.51463170037027
                            ],
                            [
                                6.820235252380371,
                                51.513857195072795
                            ],
                            [
                                6.818733215332031,
                                51.512909075833406
                            ],
                            [
                                6.8219733238220215,
                                51.50886265501692
                            ],
                            [
                                6.823368072509766,
                                51.50659222851966
                            ],
                            [
                                6.823518276214599,
                                51.504989506403604
                            ],
                            [
                                6.8230462074279785,
                                51.50266545920017
                            ],
                            [
                                6.821286678314209,
                                51.49900551239905
                            ],
                            [
                                6.81901216506958,
                                51.49451700123546
                            ],
                            [
                                6.817424297332764,
                                51.49081631885529
                            ],
                            [
                                6.8172526359558105,
                                51.48945353702908
                            ],
                            [
                                6.817982196807861,
                                51.4879437409733
                            ],
                            [
                                6.81926965713501,
                                51.48715542051101
                            ],
                            [
                                6.822037696838378,
                                51.48667440471994
                            ],
                            [
                                6.824913024902343,
                                51.48650070332609
                            ],
                            [
                                6.826865673065185,
                                51.48668776633822
                            ],
                            [
                                6.830899715423584,
                                51.48744937210746
                            ],
                            [
                                6.834397315979004,
                                51.488892379731155
                            ],
                            [
                                6.8360066413879395,
                                51.489774195241196
                            ],
                            [
                                6.836049556732178,
                                51.49108352620136
                            ],
                            [
                                6.83650016784668,
                                51.4915110546975
                            ],
                            [
                                6.838388442993164,
                                51.49203209963146
                            ],
                            [
                                6.83849573135376,
                                51.49132401147376
                            ],
                            [
                                6.840426921844482,
                                51.49275353663176
                            ],
                            [
                                6.841864585876465,
                                51.49390246828458
                            ],
                            [
                                6.84345245361328,
                                51.494704031358744
                            ],
                            [
                                6.8459200859069815,
                                51.49578611915106
                            ],
                            [
                                6.848108768463135,
                                51.496427344239926
                            ],
                            [
                                6.851005554199219,
                                51.497015125980425
                            ],
                            [
                                6.8544602394104,
                                51.497215504386794
                            ],
                            [
                                6.859631538391113,
                                51.49698840879303
                            ],
                            [
                                6.862356662750244,
                                51.49696169158996
                            ],
                            [
                                6.863815784454346,
                                51.497175428775996
                            ],
                            [
                                6.86471700668335,
                                51.49713535312996
                            ],
                            [
                                6.864974498748779,
                                51.49685482262109
                            ],
                            [
                                6.868901252746582,
                                51.4965609316647
                            ],
                            [
                                6.876068115234374,
                                51.496641083931664
                            ],
                            [
                                6.88441514968872,
                                51.496801388042684
                            ],
                            [
                                6.88917875289917,
                                51.49713535312996
                            ],
                            [
                                6.890380382537842,
                                51.498310890771144
                            ],
                            [
                                6.891753673553467,
                                51.498417756326035
                            ],
                            [
                                6.89342737197876,
                                51.497509391122684
                            ],
                            [
                                6.896216869354248,
                                51.49769640896799
                            ],
                            [
                                6.900959014892577,
                                51.49800365090511
                            ],
                            [
                                6.9049072265625,
                                51.49812387544715
                            ],
                            [
                                6.909799575805664,
                                51.49832424897921
                            ],
                            [
                                6.917095184326172,
                                51.49884521603993
                            ],
                            [
                                6.9220733642578125,
                                51.49963333437739
                            ],
                            [
                                6.9275665283203125,
                                51.50167703538552
                            ],
                            [
                                6.931471824645996,
                                51.50356036497247
                            ],
                            [
                                6.934711933135986,
                                51.50466895521549
                            ],
                            [
                                6.934669017791748,
                                51.505537109466715
                            ],
                            [
                                6.93559169769287,
                                51.505737450406535
                            ],
                            [
                                6.937694549560547,
                                51.50657887273495
                            ],
                            [
                                6.939303874969482,
                                51.507059678517834
                            ],
                            [
                                6.940333843231201,
                                51.50659222851966
                            ],
                            [
                                6.942501068115234,
                                51.507059678517834
                            ],
                            [
                                6.9463419914245605,
                                51.50780758853995
                            ],
                            [
                                6.949174404144286,
                                51.50819489426212
                            ],
                            [
                                6.953294277191162,
                                51.50851542064582
                            ],
                            [
                                6.95810079574585,
                                51.50843528926129
                            ],
                            [
                                6.962392330169677,
                                51.50812811764834
                            ],
                            [
                                6.968443393707275,
                                51.50746034612789
                            ],
                            [
                                6.972541809082031,
                                51.50672578615134
                            ],
                            [
                                6.975996494293212,
                                51.50567067019112
                            ],
                            [
                                6.979858875274657,
                                51.50432168888368
                            ],
                            [
                                6.983506679534912,
                                51.503547008299094
                            ],
                            [
                                6.985952854156494,
                                51.503466868176616
                            ],
                            [
                                6.988828182220459,
                                51.5038274976179
                            ],
                            [
                                6.99230432510376,
                                51.50470902423732
                            ],
                            [
                                6.9933342933654785,
                                51.50529669917622
                            ],
                            [
                                6.993677616119385,
                                51.506618940077324
                            ],
                            [
                                6.993098258972168,
                                51.50836851299987
                            ],
                            [
                                6.994600296020508,
                                51.50888936524356
                            ],
                            [
                                6.996188163757324,
                                51.50780758853995
                            ],
                            [
                                6.997926235198974,
                                51.50735350177636
                            ],
                            [
                                7.000179290771484,
                                51.50731343507994
                            ],
                            [
                                7.002410888671875,
                                51.50764732314014
                            ],
                            [
                                7.00453519821167,
                                51.50787436562348
                            ],
                            [
                                7.007925510406494,
                                51.50798120875358
                            ],
                            [
                                7.012968063354492,
                                51.508288381356955
                            ],
                            [
                                7.014169692993164,
                                51.50991769706187
                            ],
                            [
                                7.015113830566406,
                                51.50995776146813
                            ],
                            [
                                7.016572952270508,
                                51.50993105186789
                            ],
                            [
                                7.017602920532227,
                                51.50962389033935
                            ],
                            [
                                7.0194053649902335,
                                51.510051244945764
                            ],
                            [
                                7.022409439086914,
                                51.51145347409147
                            ],
                            [
                                7.025027275085449,
                                51.51269541243519
                            ],
                            [
                                7.027387619018555,
                                51.51344322994464
                            ],
                            [
                                7.0307135581970215,
                                51.51413762092547
                            ],
                            [
                                7.034339904785156,
                                51.51461834694225
                            ],
                            [
                                7.037966251373291,
                                51.51485870804814
                            ],
                            [
                                7.039704322814941,
                                51.51489876810915
                            ],
                            [
                                7.040669918060303,
                                51.51549966479653
                            ],
                            [
                                7.043888568878174,
                                51.51543289888939
                            ],
                            [
                                7.0441460609436035,
                                51.51489876810915
                            ],
                            [
                                7.048308849334717,
                                51.514992241447835
                            ],
                            [
                                7.052578926086426,
                                51.51536613288439
                            ],
                            [
                                7.055926322937011,
                                51.51606049455287
                            ],
                            [
                                7.059123516082764,
                                51.51708866450503
                            ],
                            [
                                7.063114643096924,
                                51.51867760878029
                            ],
                            [
                                7.06568956375122,
                                51.51983923884523
                            ],
                            [
                                7.068629264831543,
                                51.521094300098234
                            ],
                            [
                                7.068650722503662,
                                51.52205560021884
                            ],
                            [
                                7.070560455322265,
                                51.52348416152351
                            ],
                            [
                                7.072105407714844,
                                51.523510863177314
                            ],
                            [
                                7.073264122009277,
                                51.5251129337528
                            ],
                            [
                                7.073972225189208,
                                51.52691519577626
                            ],
                            [
                                7.07470178604126,
                                51.52841035161011
                            ],
                            [
                                7.0762038230896,
                                51.53003894763008
                            ],
                            [
                                7.07843542098999,
                                51.53130707631749
                            ],
                            [
                                7.081031799316406,
                                51.53225481258674
                            ],
                            [
                                7.0841646194458,
                                51.5328955245199
                            ],
                            [
                                7.086203098297119,
                                51.533149137168834
                            ],
                            [
                                7.090022563934325,
                                51.533469487968944
                            ],
                            [
                                7.092382907867432,
                                51.53396335770215
                            ],
                            [
                                7.094979286193848,
                                51.534657435837865
                            ],
                            [
                                7.096052169799805,
                                51.53612564317484
                            ],
                            [
                                7.097275257110596,
                                51.53632584959694
                            ],
                            [
                                7.098863124847412,
                                51.5360722546469
                            ],
                            [
                                7.100515365600586,
                                51.536539402143234
                            ],
                            [
                                7.102253437042236,
                                51.53672625979929
                            ],
                            [
                                7.106566429138183,
                                51.53726013458968
                            ],
                            [
                                7.110342979431152,
                                51.53764718989686
                            ],
                            [
                                7.115128040313721,
                                51.538207746919994
                            ],
                            [
                                7.119097709655762,
                                51.53843463708563
                            ],
                            [
                                7.122659683227538,
                                51.53834121186029
                            ],
                            [
                                7.123990058898926,
                                51.538261132943084
                            ],
                            [
                                7.124762535095215,
                                51.53872825797388
                            ],
                            [
                                7.127444744110107,
                                51.538714911610946
                            ],
                            [
                                7.12763786315918,
                                51.53806093503363
                            ],
                            [
                                7.130277156829834,
                                51.53812766776796
                            ],
                            [
                                7.134032249450684,
                                51.538381251266046
                            ],
                            [
                                7.139096260070801,
                                51.53894179924826
                            ],
                            [
                                7.142572402954102,
                                51.5397692622229
                            ],
                            [
                                7.147185802459717,
                                51.541197267776575
                            ],
                            [
                                7.147400379180908,
                                51.541851199286754
                            ],
                            [
                                7.147786617279053,
                                51.54223821555
                            ],
                            [
                                7.149417400360107,
                                51.54235832337627
                            ],
                            [
                                7.150275707244873,
                                51.54238501396128
                            ],
                            [
                                7.15036153793335,
                                51.54193127188712
                            ],
                            [
                                7.151627540588379,
                                51.54215814348974
                            ],
                            [
                                7.152872085571288,
                                51.54233163277561
                            ],
                            [
                                7.154352664947509,
                                51.542411704530636
                            ],
                            [
                                7.156112194061279,
                                51.54237166867075
                            ],
                            [
                                7.159631252288818,
                                51.54219817953749
                            ],
                            [
                                7.161433696746826,
                                51.54218483419215
                            ],
                            [
                                7.163150310516357,
                                51.54227825152732
                            ],
                            [
                                7.165725231170653,
                                51.5425184666515
                            ],
                            [
                                7.1691155433654785,
                                51.54274533532666
                            ],
                            [
                                7.1713900566101065,
                                51.5429054772399
                            ],
                            [
                                7.1733856201171875,
                                51.54322575937568
                            ],
                            [
                                7.175123691558838,
                                51.54310565383893
                            ],
                            [
                                7.179265022277832,
                                51.54387966173663
                            ],
                            [
                                7.181239128112793,
                                51.54411986840725
                            ],
                            [
                                7.184243202209473,
                                51.54438676322075
                            ],
                            [
                                7.188405990600585,
                                51.54438676322075
                            ],
                            [
                                7.192096710205078,
                                51.54436007380985
                            ],
                            [
                                7.194736003875732,
                                51.544313367303104
                            ],
                            [
                                7.195873260498047,
                                51.54448017603564
                            ],
                            [
                                7.19651699066162,
                                51.54514740485079
                            ],
                            [
                                7.197546958923341,
                                51.54630835966596
                            ],
                            [
                                7.197632789611816,
                                51.54716904842491
                            ],
                            [
                                7.196956872940063,
                                51.549243975313615
                            ],
                            [
                                7.196420431137085,
                                51.55215271670875
                            ],
                            [
                                7.195755243301392,
                                51.555087955257605
                            ],
                            [
                                7.194832563400269,
                                51.557642792805964
                            ],
                            [
                                7.193545103073119,
                                51.56077777593722
                            ],
                            [
                                7.192944288253785,
                                51.563118867064205
                            ],
                            [
                                7.192676067352295,
                                51.56591334510308
                            ],
                            [
                                7.19254732131958,
                                51.56760061980594
                            ],
                            [
                                7.192343473434448,
                                51.56858761313386
                            ],
                            [
                                7.1912598609924325,
                                51.56932784406841
                            ],
                            [
                                7.191077470779419,
                                51.570768258908124
                            ],
                            [
                                7.191871404647826,
                                51.571101681764134
                            ],
                            [
                                7.191935777664185,
                                51.57314884449551
                            ],
                            [
                                7.191420793533326,
                                51.57471582859051
                            ],
                            [
                                7.190508842468261,
                                51.57768294834855
                            ],
                            [
                                7.189639806747436,
                                51.57917643646416
                            ],
                            [
                                7.188942432403564,
                                51.5812632372088
                            ],
                            [
                                7.186807394027709,
                                51.581996594906244
                            ],
                            [
                                7.184157371520996,
                                51.581936593357376
                            ],
                            [
                                7.181603908538818,
                                51.58121656859114
                            ],
                            [
                                7.176346778869629,
                                51.57976315051031
                            ],
                            [
                                7.175520658493042,
                                51.579649808614434
                            ],
                            [
                                7.173031568527222,
                                51.57902975676907
                            ],
                            [
                                7.167044878005981,
                                51.577476256395016
                            ],
                            [
                                7.16282844543457,
                                51.57634276768014
                            ],
                            [
                                7.160489559173584,
                                51.57584269014265
                            ],
                            [
                                7.156187295913696,
                                51.57510256528967
                            ],
                            [
                                7.150061130523682,
                                51.57486919008907
                            ],
                            [
                                7.1440744400024405,
                                51.57460247410682
                            ],
                            [
                                7.139890193939209,
                                51.574162389314516
                            ],
                            [
                                7.134375572204589,
                                51.57313550822885
                            ],
                            [
                                7.131993770599365,
                                51.57259538614021
                            ],
                            [
                                7.131822109222412,
                                51.57074158497401
                            ],
                            [
                                7.130974531173706,
                                51.57072157951314
                            ],
                            [
                                7.129483222961425,
                                51.5719552331319
                            ],
                            [
                                7.128056287765503,
                                51.57189521832517
                            ],
                            [
                                7.1221232414245605,
                                51.57078826434845
                            ],
                            [
                                7.117981910705566,
                                51.570048057193176
                            ],
                            [
                                7.113250494003296,
                                51.569767975667126
                            ],
                            [
                                7.107982635498047,
                                51.56980131879641
                            ],
                            [
                                7.102178335189819,
                                51.569881342206955
                            ],
                            [
                                7.097876071929932,
                                51.569874673594796
                            ],
                            [
                                7.094968557357788,
                                51.56966794613248
                            ],
                            [
                                7.090848684310913,
                                51.568914383230506
                            ],
                            [
                                7.086471319198608,
                                51.56762062664021
                            ],
                            [
                                7.081890106201172,
                                51.56618011206922
                            ],
                            [
                                7.0796799659729,
                                51.56545983766855
                            ],
                            [
                                7.075420618057251,
                                51.56409928822453
                            ],
                            [
                                7.070925235748291,
                                51.562845412494205
                            ],
                            [
                                7.06618309020996,
                                51.56165153059717
                            ],
                            [
                                7.065893411636352,
                                51.56073775623775
                            ],
                            [
                                7.06468105316162,
                                51.56039091736707
                            ],
                            [
                                7.062363624572754,
                                51.560771105989765
                            ],
                            [
                                7.060679197311401,
                                51.56091117468112
                            ],
                            [
                                7.058522701263428,
                                51.560577677087664
                            ],
                            [
                                7.053083181381226,
                                51.559830633603376
                            ],
                            [
                                7.047772407531738,
                                51.55927701310451
                            ],
                            [
                                7.042461633682251,
                                51.558623332031765
                            ],
                            [
                                7.03646421432495,
                                51.55799632299099
                            ],
                            [
                                7.031915187835693,
                                51.55742933928828
                            ],
                            [
                                7.026872634887695,
                                51.55732261215377
                            ],
                            [
                                7.022227048873901,
                                51.557689485629425
                            ],
                            [
                                7.013568878173828,
                                51.55835652073055
                            ],
                            [
                                7.008837461471558,
                                51.55849659685885
                            ],
                            [
                                7.004996538162231,
                                51.558283147348305
                            ],
                            [
                                7.001134157180785,
                                51.5578095526696
                            ],
                            [
                                6.9953083992004395,
                                51.55711582761798
                            ],
                            [
                                6.989622116088866,
                                51.55634871548192
                            ],
                            [
                                6.985352039337158,
                                51.555721675082474
                            ],
                            [
                                6.985341310501099,
                                51.55486114812221
                            ],
                            [
                                6.983238458633423,
                                51.553947237322795
                            ],
                            [
                                6.978743076324463,
                                51.5544942518322
                            ],
                            [
                                6.975374221801758,
                                51.553893869701376
                            ],
                            [
                                6.9702136516571045,
                                51.553106690012605
                            ],
                            [
                                6.967692375183105,
                                51.552439577917546
                            ],
                            [
                                6.966898441314697,
                                51.55151227585459
                            ],
                            [
                                6.964731216430664,
                                51.551405534835624
                            ],
                            [
                                6.964462995529175,
                                51.552659725990466
                            ],
                            [
                                6.960557699203491,
                                51.5528598596777
                            ],
                            [
                                6.9559550285339355,
                                51.55336019004351
                            ],
                            [
                                6.9497644901275635,
                                51.55492118541516
                            ],
                            [
                                6.945376396179199,
                                51.5558484179879
                            ],
                            [
                                6.940988302230835,
                                51.55637539786036
                            ],
                            [
                                6.937533617019653,
                                51.556508809517766
                            ],
                            [
                                6.933789253234863,
                                51.5562886800732
                            ],
                            [
                                6.929605007171631,
                                51.55553489542019
                            ],
                            [
                                6.928231716156005,
                                51.554967881032674
                            ],
                            [
                                6.928167343139648,
                                51.55371375351686
                            ],
                            [
                                6.9258713722229,
                                51.55073839779265
                            ],
                            [
                                6.920120716094971,
                                51.54265191895031
                            ],
                            [
                                6.917095184326172,
                                51.53934218643719
                            ],
                            [
                                6.913747787475586,
                                51.53106680201548
                            ],
                            [
                                6.911430358886719,
                                51.52652804920958
                            ],
                            [
                                6.90765380859375,
                                51.51979918312931
                            ],
                            [
                                6.906881332397461,
                                51.52011962787024
                            ],
                            [
                                6.901903152465819,
                                51.52046677379518
                            ],
                            [
                                6.894435882568359,
                                51.52044007035645
                            ],
                            [
                                6.88542366027832,
                                51.518597495269894
                            ],
                            [
                                6.8822479248046875,
                                51.517983287008434
                            ],
                            [
                                6.876497268676758,
                                51.516781551235944
                            ],
                            [
                                6.871004104614258,
                                51.51614061252578
                            ],
                            [
                                6.867656707763672,
                                51.515873552068
                            ],
                            [
                                6.86495304107666,
                                51.51517918754973
                            ],
                            [
                                6.861047744750977,
                                51.51531272000991
                            ],
                            [
                                6.858730316162109,
                                51.51611390655047
                            ],
                            [
                                6.85795783996582,
                                51.5146984674518
                            ],
                            [
                                6.857442855834961,
                                51.51325627671818
                            ],
                            [
                                6.857056617736816,
                                51.51135999349115
                            ],
                            [
                                6.8572282791137695,
                                51.50965060011967
                            ],
                            [
                                6.857700347900391,
                                51.50844864450185
                            ],
                            [
                                6.858258247375488,
                                51.50652544955703
                            ],
                            [
                                6.8592023849487305,
                                51.505537109466715
                            ],
                            [
                                6.861562728881836,
                                51.503667218218546
                            ],
                            [
                                6.863794326782227,
                                51.50211782162702
                            ],
                            [
                                6.864480972290039,
                                51.5015033911501
                            ],
                            [
                                6.866497993469238,
                                51.50134310357631
                            ],
                            [
                                6.867055892944336,
                                51.50070194764357
                            ],
                            [
                                6.866884231567382,
                                51.4997134812415
                            ],
                            [
                                6.865253448486328,
                                51.50000735187072
                            ],
                            [
                                6.865382194519042,
                                51.49869827721546
                            ],
                            [
                                6.8639230728149405,
                                51.49891200625809
                            ],
                            [
                                6.86395525932312,
                                51.499693444538686
                            ],
                            [
                                6.863375902175903,
                                51.50083552254009
                            ],
                            [
                                6.86194896697998,
                                51.50090230984153
                            ],
                            [
                                6.861766576766967,
                                51.501490033873814
                            ],
                            [
                                6.862088441848755,
                                51.50233153463367
                            ],
                            [
                                6.860876083374023,
                                51.50317969824702
                            ],
                            [
                                6.858408451080322,
                                51.50481587478995
                            ],
                            [
                                6.857056617736816,
                                51.505724094371274
                            ],
                            [
                                6.856906414031982,
                                51.5073067572938
                            ],
                            [
                                6.8562304973602295,
                                51.5092633067721
                            ],
                            [
                                6.8559730052948,
                                51.51131325311908
                            ],
                            [
                                6.856112480163573,
                                51.51281559822001
                            ],
                            [
                                6.856595277786255,
                                51.51493882813492
                            ],
                            [
                                6.857700347900391,
                                51.5163742891419
                            ],
                            [
                                6.858408451080322,
                                51.51766282723606
                            ],
                            [
                                6.85847282409668,
                                51.51857746687026
                            ],
                            [
                                6.857743263244629,
                                51.51986594263626
                            ],
                            [
                                6.8573784828186035,
                                51.521134354675056
                            ],
                            [
                                6.855404376983643,
                                51.522629700334875
                            ],
                            [
                                6.852314472198486,
                                51.524124996893335
                            ],
                            [
                                6.850447654724121,
                                51.525046181937505
                            ],
                            [
                                6.848838329315185,
                                51.52588724765887
                            ],
                            [
                                6.84722900390625,
                                51.52687514628408
                            ],
                            [
                                6.845297813415527,
                                51.52851714657659
                            ],
                            [
                                6.843366622924805,
                                51.53061294709611
                            ],
                            [
                                6.84173583984375,
                                51.53206793657423
                            ],
                            [
                                6.840105056762695,
                                51.53330931285069
                            ],
                            [
                                6.839182376861572,
                                51.533029005037584
                            ],
                            [
                                6.836886405944824,
                                51.532241464325594
                            ],
                            [
                                6.8352556228637695,
                                51.53169418224775
                            ]
                        ],
                        [
                            [
                                6.929025650024414,
                                51.55671559681339
                            ],
                            [
                                6.930184364318847,
                                51.556608868004005
                            ],
                            [
                                6.933960914611816,
                                51.55704912272846
                            ],
                            [
                                6.936407089233398,
                                51.557235896172585
                            ],
                            [
                                6.9388532638549805,
                                51.557209214298936
                            ],
                            [
                                6.942200660705566,
                                51.55700909974777
                            ],
                            [
                                6.945183277130127,
                                51.55659552688521
                            ],
                            [
                                6.947286128997802,
                                51.5561952915021
                            ],
                            [
                                6.9507622718811035,
                                51.5554348345713
                            ],
                            [
                                6.954967975616455,
                                51.55444755572847
                            ],
                            [
                                6.958637237548828,
                                51.55374043744105
                            ],
                            [
                                6.963057518005371,
                                51.55334017693454
                            ],
                            [
                                6.964538097381592,
                                51.553313492775544
                            ],
                            [
                                6.964602470397949,
                                51.554487580963176
                            ],
                            [
                                6.9657182693481445,
                                51.554527606162694
                            ],
                            [
                                6.966898441314697,
                                51.55443421397573
                            ],
                            [
                                6.967670917510986,
                                51.553673727601215
                            ],
                            [
                                6.970224380493164,
                                51.553780463298004
                            ],
                            [
                                6.974129676818848,
                                51.554407530458526
                            ],
                            [
                                6.977949142456054,
                                51.55514132147805
                            ],
                            [
                                6.978743076324463,
                                51.556168609018016
                            ],
                            [
                                6.9793009757995605,
                                51.55632870368781
                            ],
                            [
                                6.984150409698486,
                                51.55703578173882
                            ],
                            [
                                6.984901428222656,
                                51.5564087508114
                            ],
                            [
                                6.989128589630126,
                                51.5569824177411
                            ],
                            [
                                6.994879245758057,
                                51.55770282642731
                            ],
                            [
                                7.000308036804199,
                                51.55835652073055
                            ],
                            [
                                7.002453804016113,
                                51.55869003461248
                            ],
                            [
                                7.006123065948486,
                                51.5589968652238
                            ],
                            [
                                7.011122703552245,
                                51.55921031138468
                            ],
                            [
                                7.0166802406311035,
                                51.558903482213424
                            ],
                            [
                                7.024834156036377,
                                51.55811638922115
                            ],
                            [
                                7.030584812164306,
                                51.558076367179645
                            ],
                            [
                                7.033824920654297,
                                51.55831649890036
                            ],
                            [
                                7.0371294021606445,
                                51.55886346086454
                            ],
                            [
                                7.044253349304198,
                                51.55951713848588
                            ],
                            [
                                7.048394680023193,
                                51.55998404532019
                            ],
                            [
                                7.053308486938477,
                                51.56058434706352
                            ],
                            [
                                7.058243751525879,
                                51.561251339706885
                            ],
                            [
                                7.061634063720703,
                                51.56173156835263
                            ],
                            [
                                7.063908576965332,
                                51.56217177348951
                            ],
                            [
                                7.0644450187683105,
                                51.563185563051356
                            ],
                            [
                                7.065603733062744,
                                51.56306551020408
                            ],
                            [
                                7.066526412963867,
                                51.56266533175735
                            ],
                            [
                                7.068994045257568,
                                51.563118867064205
                            ],
                            [
                                7.0732855796813965,
                                51.564226007784185
                            ],
                            [
                                7.07744836807251,
                                51.565506522466244
                            ],
                            [
                                7.0813751220703125,
                                51.56676032481632
                            ],
                            [
                                7.086374759674072,
                                51.56841422396554
                            ],
                            [
                                7.090859413146973,
                                51.569574585034914
                            ],
                            [
                                7.094099521636963,
                                51.57006806295042
                            ],
                            [
                                7.095987796783447,
                                51.57036147971212
                            ],
                            [
                                7.097339630126952,
                                51.571108350196326
                            ],
                            [
                                7.101244926452636,
                                51.57128172908954
                            ],
                            [
                                7.1033477783203125,
                                51.571121687057726
                            ],
                            [
                                7.1042704582214355,
                                51.57069490555162
                            ],
                            [
                                7.107510566711426,
                                51.57046817624684
                            ],
                            [
                                7.112874984741211,
                                51.570454839193694
                            ],
                            [
                                7.11763858795166,
                                51.570708242534344
                            ],
                            [
                                7.1211791038513175,
                                51.57124171863442
                            ],
                            [
                                7.12637186050415,
                                51.57236199806681
                            ],
                            [
                                7.128732204437256,
                                51.572855445724755
                            ],
                            [
                                7.12937593460083,
                                51.57374897244641
                            ],
                            [
                                7.130448818206786,
                                51.574162389314516
                            ],
                            [
                                7.135899066925049,
                                51.57501588321218
                            ],
                            [
                                7.1366071701049805,
                                51.57425574099016
                            ],
                            [
                                7.140941619873047,
                                51.57498921177044
                            ],
                            [
                                7.14385986328125,
                                51.5752959324053
                            ],
                            [
                                7.149653434753418,
                                51.57553597319747
                            ],
                            [
                                7.156026363372803,
                                51.5758960320088
                            ],
                            [
                                7.159566879272461,
                                51.57633610001584
                            ],
                            [
                                7.164802551269531,
                                51.5775629337803
                            ],
                            [
                                7.170081138610839,
                                51.57896308402391
                            ],
                            [
                                7.1733856201171875,
                                51.579883159268434
                            ],
                            [
                                7.17581033706665,
                                51.58057653699904
                            ],
                            [
                                7.1772050857543945,
                                51.5813632412281
                            ],
                            [
                                7.179651260375976,
                                51.582043262722806
                            ],
                            [
                                7.181904315948486,
                                51.58221659990763
                            ],
                            [
                                7.184457778930664,
                                51.583029942482916
                            ],
                            [
                                7.18580961227417,
                                51.5839099360855
                            ],
                            [
                                7.187268733978271,
                                51.585403219564085
                            ],
                            [
                                7.1875691413879395,
                                51.58608318060075
                            ],
                            [
                                7.187032699584961,
                                51.58864294264931
                            ],
                            [
                                7.186625003814697,
                                51.59152250256572
                            ],
                            [
                                7.186238765716552,
                                51.59428190954366
                            ],
                            [
                                7.185938358306885,
                                51.595774852129765
                            ],
                            [
                                7.1855735778808585,
                                51.59693451473866
                            ],
                            [
                                7.185165882110595,
                                51.597827568060225
                            ],
                            [
                                7.184157371520996,
                                51.599600293520716
                            ],
                            [
                                7.183384895324707,
                                51.600919796119875
                            ],
                            [
                                7.182247638702392,
                                51.60223926037916
                            ],
                            [
                                7.180380821228027,
                                51.60415841267934
                            ],
                            [
                                7.178385257720946,
                                51.605917564365036
                            ],
                            [
                                7.176797389984131,
                                51.607223557138
                            ],
                            [
                                7.1760892868042,
                                51.60751673402568
                            ],
                            [
                                7.1747589111328125,
                                51.607370145818486
                            ],
                            [
                                7.173771858215332,
                                51.60771662627274
                            ],
                            [
                                7.172656059265137,
                                51.60847620878489
                            ],
                            [
                                7.171926498413085,
                                51.609209127231374
                            ],
                            [
                                7.171969413757323,
                                51.60991538290305
                            ],
                            [
                                7.172441482543945,
                                51.61064827811489
                            ],
                            [
                                7.1721410751342765,
                                51.61131453622155
                            ],
                            [
                                7.170424461364746,
                                51.61344649644984
                            ],
                            [
                                7.168965339660644,
                                51.61543179436925
                            ],
                            [
                                7.16808557510376,
                                51.617177185906144
                            ],
                            [
                                7.167398929595947,
                                51.61788331760899
                            ],
                            [
                                7.165381908416748,
                                51.61814977954729
                            ],
                            [
                                7.164266109466553,
                                51.6178300250336
                            ],
                            [
                                7.164180278778076,
                                51.61904241564112
                            ],
                            [
                                7.165017127990722,
                                51.61949538843578
                            ],
                            [
                                7.165231704711913,
                                51.619921711641695
                            ],
                            [
                                7.165017127990722,
                                51.620294741161416
                            ],
                            [
                                7.164909839630128,
                                51.62065444529423
                            ],
                            [
                                7.165210247039795,
                                51.621067435411994
                            ],
                            [
                                7.165939807891846,
                                51.62164028644948
                            ],
                            [
                                7.166240215301514,
                                51.62242627959266
                            ],
                            [
                                7.166197299957275,
                                51.62331883156832
                            ],
                            [
                                7.166154384613037,
                                51.624624323732334
                            ],
                            [
                                7.163729667663574,
                                51.6253436605063
                            ],
                            [
                                7.162034511566162,
                                51.62574328711949
                            ],
                            [
                                7.160167694091796,
                                51.62606298587566
                            ],
                            [
                                7.157506942749023,
                                51.62652920919031
                            ],
                            [
                                7.156112194061279,
                                51.62634272043938
                            ],
                            [
                                7.148902416229248,
                                51.62967276133212
                            ],
                            [
                                7.145007848739624,
                                51.63145756263028
                            ],
                            [
                                7.142089605331421,
                                51.63287602999507
                            ],
                            [
                                7.139267921447753,
                                51.63359523593671
                            ],
                            [
                                7.1350836753845215,
                                51.63406804103745
                            ],
                            [
                                7.134622335433959,
                                51.633208997126445
                            ],
                            [
                                7.133753299713135,
                                51.63170396624797
                            ],
                            [
                                7.133495807647705,
                                51.631164540369696
                            ],
                            [
                                7.1330344676971436,
                                51.63115788075087
                            ],
                            [
                                7.132583856582642,
                                51.630831558230476
                            ],
                            [
                                7.13161826133728,
                                51.630212204950155
                            ],
                            [
                                7.131178379058838,
                                51.63001241176551
                            ],
                            [
                                7.1306633949279785,
                                51.629885875626776
                            ],
                            [
                                7.128700017929077,
                                51.62941302693186
                            ],
                            [
                                7.127777338027953,
                                51.629193250508564
                            ],
                            [
                                7.1271443367004395,
                                51.629026752509326
                            ],
                            [
                                7.126597166061401,
                                51.628593854851424
                            ],
                            [
                                7.125524282455444,
                                51.62781462865727
                            ],
                            [
                                7.124419212341309,
                                51.62730845746293
                            ],
                            [
                                7.123925685882568,
                                51.62667573552826
                            ],
                            [
                                7.1235716342926025,
                                51.62609628769987
                            ],
                            [
                                7.12187647819519,
                                51.62564338079618
                            ],
                            [
                                7.120331525802612,
                                51.62553015336383
                            ],
                            [
                                7.118690013885498,
                                51.625503511573974
                            ],
                            [
                                7.1154820919036865,
                                51.62545022794732
                            ],
                            [
                                7.112209796905518,
                                51.62524375330302
                            ],
                            [
                                7.109881639480591,
                                51.62513052487335
                            ],
                            [
                                7.107628583908081,
                                51.62467760832891
                            ],
                            [
                                7.109001874923706,
                                51.62301909592258
                            ],
                            [
                                7.11090087890625,
                                51.620820974019765
                            ],
                            [
                                7.110257148742676,
                                51.620814312882466
                            ],
                            [
                                7.109506130218505,
                                51.6210208076837
                            ],
                            [
                                7.108958959579468,
                                51.62158699828595
                            ],
                            [
                                7.10766077041626,
                                51.62216650370415
                            ],
                            [
                                7.105879783630371,
                                51.62285923610189
                            ],
                            [
                                7.10337996482849,
                                51.62298579183972
                            ],
                            [
                                7.0992279052734375,
                                51.62321891990641
                            ],
                            [
                                7.093691825866699,
                                51.62346536827452
                            ],
                            [
                                7.091331481933594,
                                51.623671851011835
                            ],
                            [
                                7.08892822265625,
                                51.623904975553636
                            ],
                            [
                                7.088134288787842,
                                51.62391829691986
                            ],
                            [
                                7.087200880050659,
                                51.62369849387723
                            ],
                            [
                                7.08593487739563,
                                51.623392099980585
                            ],
                            [
                                7.085784673690796,
                                51.62247956677057
                            ],
                            [
                                7.085334062576294,
                                51.621127385277994
                            ],
                            [
                                7.084636688232421,
                                51.61991505037239
                            ],
                            [
                                7.083177566528319,
                                51.617010643809
                            ],
                            [
                                7.0825982093811035,
                                51.61587814134083
                            ],
                            [
                                7.08168625831604,
                                51.61419265887331
                            ],
                            [
                                7.080870866775513,
                                51.61263369842347
                            ],
                            [
                                7.080559730529785,
                                51.612067396113815
                            ],
                            [
                                7.080602645874023,
                                51.611814223384215
                            ],
                            [
                                7.0800554752349845,
                                51.61154772424862
                            ],
                            [
                                7.078295946121216,
                                51.60970217480152
                            ],
                            [
                                7.075324058532715,
                                51.60655723898863
                            ],
                            [
                                7.07144021987915,
                                51.602485822681025
                            ],
                            [
                                7.068779468536377,
                                51.59959362926887
                            ],
                            [
                                7.065099477767944,
                                51.59544827513224
                            ],
                            [
                                7.064080238342285,
                                51.59447519505449
                            ],
                            [
                                7.063232660293579,
                                51.59398864719791
                            ],
                            [
                                7.062116861343384,
                                51.59342211230438
                            ],
                            [
                                7.061258554458618,
                                51.5929688793019
                            ],
                            [
                                7.060786485671997,
                                51.59258895991858
                            ],
                            [
                                7.060003280639648,
                                51.5927289305874
                            ],
                            [
                                7.058168649673462,
                                51.592448988818425
                            ],
                            [
                                7.055540084838867,
                                51.59200907683869
                            ],
                            [
                                7.053673267364502,
                                51.59167580735109
                            ],
                            [
                                7.052031755447388,
                                51.591435851806025
                            ],
                            [
                                7.051731348037719,
                                51.59116256755809
                            ],
                            [
                                7.051184177398682,
                                51.589729464708114
                            ],
                            [
                                7.050583362579345,
                                51.58845629820794
                            ],
                            [
                                7.050336599349975,
                                51.58784969847781
                            ],
                            [
                                7.05022931098938,
                                51.58732308335315
                            ],
                            [
                                7.050368785858154,
                                51.58654314847302
                            ],
                            [
                                7.05072283744812,
                                51.5855498786876
                            ],
                            [
                                7.048555612564087,
                                51.585063235229796
                            ],
                            [
                                7.047203779220581,
                                51.58446992314115
                            ],
                            [
                                7.045755386352539,
                                51.58356994057748
                            ],
                            [
                                7.044789791107178,
                                51.583109942605546
                            ],
                            [
                                7.043738365173339,
                                51.58297660898954
                            ],
                            [
                                7.039339542388915,
                                51.58288327522558
                            ],
                            [
                                7.038674354553223,
                                51.58284327498233
                            ],
                            [
                                7.03500509262085,
                                51.58221659990763
                            ],
                            [
                                7.031099796295165,
                                51.58161658375865
                            ],
                            [
                                7.029061317443848,
                                51.58118990078811
                            ],
                            [
                                7.028675079345703,
                                51.57941645676221
                            ],
                            [
                                7.028846740722656,
                                51.57892308032986
                            ],
                            [
                                7.027709484100342,
                                51.57840302910278
                            ],
                            [
                                7.026786804199219,
                                51.57772295313422
                            ],
                            [
                                7.0257568359375,
                                51.57690951557113
                            ],
                            [
                                7.024426460266113,
                                51.5760427218179
                            ],
                            [
                                7.021679878234863,
                                51.57504255463824
                            ],
                            [
                                7.020456790924072,
                                51.57468248906584
                            ],
                            [
                                7.018225193023682,
                                51.57426907692816
                            ],
                            [
                                7.016744613647461,
                                51.57409570942884
                            ],
                            [
                                7.013847827911377,
                                51.57374897244641
                            ],
                            [
                                7.012367248535156,
                                51.573508922216845
                            ],
                            [
                                7.010006904602051,
                                51.57272208202111
                            ],
                            [
                                7.008504867553711,
                                51.57225530597703
                            ],
                            [
                                7.008054256439209,
                                51.572788763921835
                            ],
                            [
                                7.010607719421386,
                                51.573882333137284
                            ],
                            [
                                7.012045383453368,
                                51.57425574099016
                            ],
                            [
                                7.01449155807495,
                                51.57448245140423
                            ],
                            [
                                7.017645835876465,
                                51.5748291827915
                            ],
                            [
                                7.020413875579834,
                                51.57534927491311
                            ],
                            [
                                7.02324628829956,
                                51.576482788404654
                            ],
                            [
                                7.024791240692139,
                                51.577362908795614
                            ],
                            [
                                7.025628089904785,
                                51.578496372068926
                            ],
                            [
                                7.025842666625977,
                                51.57925644337101
                            ],
                            [
                                7.025349140167236,
                                51.579776484832436
                            ],
                            [
                                7.0246195793151855,
                                51.580149844262976
                            ],
                            [
                                7.023932933807373,
                                51.58000316770964
                            ],
                            [
                                7.022666931152344,
                                51.579389787902805
                            ],
                            [
                                7.021894454956055,
                                51.57894974946315
                            ],
                            [
                                7.020692825317383,
                                51.578696392065076
                            ],
                            [
                                7.018954753875732,
                                51.57844303325461
                            ],
                            [
                                7.017495632171631,
                                51.57844303325461
                            ],
                            [
                                7.01502799987793,
                                51.57901642222787
                            ],
                            [
                                7.012946605682373,
                                51.5795097976469
                            ],
                            [
                                7.008161544799804,
                                51.58058987108249
                            ],
                            [
                                7.004277706146239,
                                51.58154991480858
                            ],
                            [
                                7.001917362213135,
                                51.58212326458258
                            ],
                            [
                                7.001016139984131,
                                51.58214993183786
                            ],
                            [
                                6.999020576477051,
                                51.58189659228077
                            ],
                            [
                                6.997025012969971,
                                51.581576582400345
                            ],
                            [
                                6.994106769561768,
                                51.5813632412281
                            ],
                            [
                                6.992733478546143,
                                51.58116323296944
                            ],
                            [
                                6.9902873039245605,
                                51.58040319355822
                            ],
                            [
                                6.988527774810791,
                                51.57973648185436
                            ],
                            [
                                6.986875534057617,
                                51.57902975676907
                            ],
                            [
                                6.98530912399292,
                                51.57828301643604
                            ],
                            [
                                6.983678340911865,
                                51.577429583888325
                            ],
                            [
                                6.981897354125977,
                                51.57657613531466
                            ],
                            [
                                6.980502605438232,
                                51.57577601272195
                            ],
                            [
                                6.97981595993042,
                                51.57530926803811
                            ],
                            [
                                6.97906494140625,
                                51.57573600622257
                            ],
                            [
                                6.977713108062744,
                                51.576256087967835
                            ],
                            [
                                6.976189613342285,
                                51.576482788404654
                            ],
                            [
                                6.974987983703612,
                                51.57649612368926
                            ],
                            [
                                6.973507404327393,
                                51.5763894413029
                            ],
                            [
                                6.9718122482299805,
                                51.57624275261282
                            ],
                            [
                                6.97007417678833,
                                51.57637610598699
                            ],
                            [
                                6.968379020690918,
                                51.57669615248873
                            ],
                            [
                                6.967134475708008,
                                51.57688284524063
                            ],
                            [
                                6.9652462005615225,
                                51.576616141074545
                            ],
                            [
                                6.963357925415038,
                                51.57629609400944
                            ],
                            [
                                6.960310935974121,
                                51.57536261053028
                            ],
                            [
                                6.9580793380737305,
                                51.574642481603945
                            ],
                            [
                                6.954946517944336,
                                51.573602275234535
                            ],
                            [
                                6.952071189880371,
                                51.57301548165268
                            ],
                            [
                                6.949882507324219,
                                51.57229531554002
                            ],
                            [
                                6.946148872375488,
                                51.57128172908954
                            ],
                            [
                                6.942243576049805,
                                51.56994802827482
                            ],
                            [
                                6.940269470214844,
                                51.569201138727365
                            ],
                            [
                                6.939196586608887,
                                51.56824083413595
                            ],
                            [
                                6.9385528564453125,
                                51.56653357588851
                            ],
                            [
                                6.936664581298828,
                                51.56551986097108
                            ],
                            [
                                6.93486213684082,
                                51.564319379865324
                            ],
                            [
                                6.931471824645996,
                                51.56047762733274
                            ],
                            [
                                6.929025650024414,
                                51.55671559681339
                            ]
                        ],
                        [
                            [
                                6.771268844604492,
                                51.48084836613703
                            ],
                            [
                                6.770925521850586,
                                51.479832741945344
                            ],
                            [
                                6.769509315490723,
                                51.47785488256033
                            ],
                            [
                                6.767256259918213,
                                51.47500821816881
                            ],
                            [
                                6.765711307525635,
                                51.472228205434426
                            ],
                            [
                                6.765797138214111,
                                51.47065100750698
                            ],
                            [
                                6.767792701721191,
                                51.469541589859126
                            ],
                            [
                                6.767535209655762,
                                51.46820490625919
                            ],
                            [
                                6.768779754638672,
                                51.46563836397241
                            ],
                            [
                                6.7693376541137695,
                                51.46416788407397
                            ],
                            [
                                6.7708396911621085,
                                51.46365988909683
                            ],
                            [
                                6.771440505981445,
                                51.462804305826566
                            ],
                            [
                                6.7708396911621085,
                                51.46226955813691
                            ],
                            [
                                6.775217056274413,
                                51.458205270921866
                            ],
                            [
                                6.777491569519043,
                                51.456012544630916
                            ],
                            [
                                6.778392791748047,
                                51.45400691005982
                            ],
                            [
                                6.779165267944335,
                                51.451626776038545
                            ],
                            [
                                6.779294013977051,
                                51.448658120386
                            ],
                            [
                                6.779508590698242,
                                51.44790923982441
                            ],
                            [
                                6.781225204467773,
                                51.44801622351376
                            ],
                            [
                                6.782855987548828,
                                51.44756154110276
                            ],
                            [
                                6.784830093383789,
                                51.44788249386289
                            ],
                            [
                                6.7885637283325195,
                                51.448310427367055
                            ],
                            [
                                6.791481971740723,
                                51.44839066445258
                            ],
                            [
                                6.794872283935547,
                                51.44801622351376
                            ],
                            [
                                6.797747611999512,
                                51.44726733242416
                            ],
                            [
                                6.797490119934082,
                                51.45026282308676
                            ],
                            [
                                6.798348426818848,
                                51.45446152828587
                            ],
                            [
                                6.800966262817383,
                                51.457296104511144
                            ],
                            [
                                6.803884506225586,
                                51.4590609404128
                            ],
                            [
                                6.810064315795898,
                                51.4613604726841
                            ],
                            [
                                6.81375503540039,
                                51.46406093823295
                            ],
                            [
                                6.815299987792969,
                                51.467429611827924
                            ],
                            [
                                6.815428733825684,
                                51.47176039817776
                            ],
                            [
                                6.814441680908203,
                                51.47504831326697
                            ],
                            [
                                6.8125104904174805,
                                51.47510177334301
                            ],
                            [
                                6.812295913696288,
                                51.475957126038814
                            ],
                            [
                                6.813325881958008,
                                51.4772668537707
                            ],
                            [
                                6.812381744384766,
                                51.48068800592616
                            ],
                            [
                                6.812295913696288,
                                51.483360602493576
                            ],
                            [
                                6.811738014221191,
                                51.48579252925688
                            ],
                            [
                                6.809377670288085,
                                51.486834743880806
                            ],
                            [
                                6.807231903076172,
                                51.48672785116955
                            ],
                            [
                                6.803240776062012,
                                51.485552014806856
                            ],
                            [
                                6.798906326293945,
                                51.484376148122536
                            ],
                            [
                                6.796717643737792,
                                51.48405545194617
                            ],
                            [
                                6.794958114624023,
                                51.484162350922226
                            ],
                            [
                                6.795129776000977,
                                51.48338732766831
                            ],
                            [
                                6.793584823608398,
                                51.48325370163806
                            ],
                            [
                                6.791567802429199,
                                51.483360602493576
                            ],
                            [
                                6.791181564331055,
                                51.4844028727021
                            ],
                            [
                                6.790065765380859,
                                51.48517787869631
                            ],
                            [
                                6.787147521972656,
                                51.48587270045831
                            ],
                            [
                                6.784873008728027,
                                51.48581925300634
                            ],
                            [
                                6.783156394958496,
                                51.48568563410237
                            ],
                            [
                                6.781225204467773,
                                51.485097706273265
                            ],
                            [
                                6.778349876403809,
                                51.483708028543326
                            ],
                            [
                                6.775474548339844,
                                51.482692468035225
                            ],
                            [
                                6.773157119750977,
                                51.48130271700579
                            ],
                            [
                                6.771483421325684,
                                51.48108890539611
                            ],
                            [
                                6.771268844604492,
                                51.48084836613703
                            ]
                        ],
                        [
                            [
                                6.770625114440918,
                                51.485578738697285
                            ],
                            [
                                6.773371696472168,
                                51.48381492833381
                            ],
                            [
                                6.77556037902832,
                                51.48373475351443
                            ],
                            [
                                6.778049468994141,
                                51.48456321985056
                            ],
                            [
                                6.780281066894531,
                                51.48581925300634
                            ],
                            [
                                6.782426834106445,
                                51.486487341653024
                            ],
                            [
                                6.784787178039551,
                                51.48680802072646
                            ],
                            [
                                6.787533760070801,
                                51.48675457437084
                            ],
                            [
                                6.789121627807617,
                                51.48664768147165
                            ],
                            [
                                6.790323257446288,
                                51.48744937210746
                            ],
                            [
                                6.79178237915039,
                                51.48691491324978
                            ],
                            [
                                6.791310310363769,
                                51.486059766046814
                            ],
                            [
                                6.793155670166016,
                                51.485445119088524
                            ],
                            [
                                6.796073913574219,
                                51.48517787869631
                            ],
                            [
                                6.800365447998047,
                                51.48597959517428
                            ],
                            [
                                6.805901527404784,
                                51.48782348958036
                            ],
                            [
                                6.81002140045166,
                                51.48814415925678
                            ],
                            [
                                6.812982559204102,
                                51.48875876983271
                            ],
                            [
                                6.8146562576293945,
                                51.489961244823455
                            ],
                            [
                                6.817059516906738,
                                51.493969265745775
                            ],
                            [
                                6.819934844970703,
                                51.50016764414252
                            ],
                            [
                                6.821651458740234,
                                51.503907637106174
                            ],
                            [
                                6.822037696838378,
                                51.5058576545476
                            ],
                            [
                                6.821136474609375,
                                51.507941142609155
                            ],
                            [
                                6.8174028396606445,
                                51.51256187230236
                            ],
                            [
                                6.810579299926758,
                                51.51021150188967
                            ],
                            [
                                6.809592247009277,
                                51.5104785955435
                            ],
                            [
                                6.796116828918457,
                                51.516007082492614
                            ],
                            [
                                6.788434982299804,
                                51.516487788780005
                            ],
                            [
                                6.787276268005371,
                                51.51921169524832
                            ],
                            [
                                6.783885955810547,
                                51.52030655292787
                            ],
                            [
                                6.784272193908691,
                                51.521962141375425
                            ],
                            [
                                6.780238151550293,
                                51.52401819162466
                            ],
                            [
                                6.781139373779297,
                                51.5242585031269
                            ],
                            [
                                6.779530048370361,
                                51.5254600416149
                            ],
                            [
                                6.776912212371826,
                                51.52623434961829
                            ],
                            [
                                6.773135662078857,
                                51.52722224071258
                            ],
                            [
                                6.7700886726379395,
                                51.527729527809946
                            ],
                            [
                                6.766955852508544,
                                51.52768947903407
                            ],
                            [
                                6.759552955627441,
                                51.527329038465936
                            ],
                            [
                                6.759960651397705,
                                51.52416504880453
                            ],
                            [
                                6.760668754577637,
                                51.52144143859368
                            ],
                            [
                                6.760540008544922,
                                51.51887789193976
                            ],
                            [
                                6.759488582611084,
                                51.51495218146903
                            ],
                            [
                                6.759016513824463,
                                51.51229479086236
                            ],
                            [
                                6.759359836578369,
                                51.51073233306422
                            ],
                            [
                                6.7600250244140625,
                                51.51025156603757
                            ],
                            [
                                6.75959587097168,
                                51.509837568143645
                            ],
                            [
                                6.760690212249756,
                                51.50764732314014
                            ],
                            [
                                6.76305055618286,
                                51.505243274494944
                            ],
                            [
                                6.7645955085754395,
                                51.503667218218546
                            ],
                            [
                                6.765904426574707,
                                51.50269217305979
                            ],
                            [
                                6.764917373657227,
                                51.5018907504593
                            ],
                            [
                                6.7645955085754395,
                                51.499646692197885
                            ],
                            [
                                6.764874458312988,
                                51.498524621630374
                            ],
                            [
                                6.765646934509277,
                                51.498150591969
                            ],
                            [
                                6.764638423919678,
                                51.497108636013046
                            ],
                            [
                                6.764767169952393,
                                51.49530519441468
                            ],
                            [
                                6.766204833984375,
                                51.49299401309479
                            ],
                            [
                                6.767385005950928,
                                51.49054910994308
                            ],
                            [
                                6.768693923950195,
                                51.48822432632349
                            ],
                            [
                                6.769423484802245,
                                51.487636431227635
                            ],
                            [
                                6.771483421325684,
                                51.48720886639676
                            ],
                            [
                                6.771290302276611,
                                51.48639380983175
                            ],
                            [
                                6.770625114440918,
                                51.485578738697285
                            ]
                        ],
                        [
                            [
                                6.740670204162597,
                                51.50259867448262
                            ],
                            [
                                6.737494468688965,
                                51.50225139237363
                            ],
                            [
                                6.733546257019043,
                                51.50083552254009
                            ],
                            [
                                6.732087135314941,
                                51.49883185798456
                            ],
                            [
                                6.731314659118652,
                                51.49795021767351
                            ],
                            [
                                6.729340553283691,
                                51.49698840879303
                            ],
                            [
                                6.727709770202637,
                                51.495679247425926
                            ],
                            [
                                6.7270660400390625,
                                51.49370207531374
                            ],
                            [
                                6.726937294006348,
                                51.491751537714705
                            ],
                            [
                                6.726250648498535,
                                51.490201736015855
                            ],
                            [
                                6.726164817810059,
                                51.487315757980376
                            ],
                            [
                                6.726551055908202,
                                51.48458994432048
                            ],
                            [
                                6.727924346923828,
                                51.483601128502265
                            ],
                            [
                                6.728911399841309,
                                51.48189069376375
                            ],
                            [
                                6.7295122146606445,
                                51.48050091830084
                            ],
                            [
                                6.7323875427246085,
                                51.48169024799336
                            ],
                            [
                                6.733975410461426,
                                51.48238512289726
                            ],
                            [
                                6.736893653869629,
                                51.48337396508289
                            ],
                            [
                                6.74036979675293,
                                51.48500417160153
                            ],
                            [
                                6.744318008422852,
                                51.48651406499528
                            ],
                            [
                                6.746699810028076,
                                51.4872756736659
                            ],
                            [
                                6.749403476715087,
                                51.48762306988736
                            ],
                            [
                                6.752793788909911,
                                51.48771659918707
                            ],
                            [
                                6.756141185760498,
                                51.487382565092844
                            ],
                            [
                                6.760711669921875,
                                51.4868748285829
                            ],
                            [
                                6.765410900115967,
                                51.485886062311515
                            ],
                            [
                                6.767427921295165,
                                51.485097706273265
                            ],
                            [
                                6.767148971557617,
                                51.48568563410237
                            ],
                            [
                                6.767277717590332,
                                51.48680802072646
                            ],
                            [
                                6.767663955688477,
                                51.487676515224976
                            ],
                            [
                                6.766483783721923,
                                51.48933328961809
                            ],
                            [
                                6.765947341918944,
                                51.49053574945637
                            ],
                            [
                                6.7653679847717285,
                                51.492486339073956
                            ],
                            [
                                6.763608455657959,
                                51.4949444975325
                            ],
                            [
                                6.762986183166504,
                                51.49625368000238
                            ],
                            [
                                6.763136386871338,
                                51.498070442356514
                            ],
                            [
                                6.7632222175598145,
                                51.49889864822228
                            ],
                            [
                                6.763544082641601,
                                51.4996867656358
                            ],
                            [
                                6.76332950592041,
                                51.50201096474784
                            ],
                            [
                                6.76307201385498,
                                51.50329323076107
                            ],
                            [
                                6.762428283691406,
                                51.504308332433446
                            ],
                            [
                                6.7609477043151855,
                                51.5059511464381
                            ],
                            [
                                6.759381294250488,
                                51.5073401462148
                            ],
                            [
                                6.757900714874268,
                                51.509530405984826
                            ],
                            [
                                6.757514476776123,
                                51.510278275449934
                            ],
                            [
                                6.757450103759766,
                                51.511613726102865
                            ],
                            [
                                6.757686138153075,
                                51.51293578368774
                            ],
                            [
                                6.758072376251221,
                                51.51489876810915
                            ],
                            [
                                6.759016513824463,
                                51.517102017208906
                            ],
                            [
                                6.759638786315917,
                                51.519465384126605
                            ],
                            [
                                6.759445667266846,
                                51.52153489850574
                            ],
                            [
                                6.758458614349365,
                                51.52436530783196
                            ],
                            [
                                6.75734281539917,
                                51.52614089935105
                            ],
                            [
                                6.757321357727051,
                                51.52723559044543
                            ],
                            [
                                6.754724979400635,
                                51.52726228989943
                            ],
                            [
                                6.749575138092041,
                                51.52707539339267
                            ],
                            [
                                6.74635648727417,
                                51.52706204361282
                            ],
                            [
                                6.743566989898681,
                                51.52707539339267
                            ],
                            [
                                6.741807460784912,
                                51.52714214223315
                            ],
                            [
                                6.738803386688232,
                                51.52722224071258
                            ],
                            [
                                6.737580299377441,
                                51.527208890975785
                            ],
                            [
                                6.735241413116455,
                                51.526781697332005
                            ],
                            [
                                6.731786727905273,
                                51.525994048543595
                            ],
                            [
                                6.72961950302124,
                                51.52524643708982
                            ],
                            [
                                6.728353500366211,
                                51.52503283156269
                            ],
                            [
                                6.726744174957275,
                                51.52503283156269
                            ],
                            [
                                6.72487735748291,
                                51.525126284104104
                            ],
                            [
                                6.726465225219727,
                                51.523777878854176
                            ],
                            [
                                6.728675365447998,
                                51.52174852044161
                            ],
                            [
                                6.7298126220703125,
                                51.52117440921666
                            ],
                            [
                                6.733653545379639,
                                51.51986594263626
                            ],
                            [
                                6.7352843284606925,
                                51.51901141355677
                            ],
                            [
                                6.7368292808532715,
                                51.517996639450025
                            ],
                            [
                                6.739211082458495,
                                51.51576672744647
                            ],
                            [
                                6.741635799407958,
                                51.513777073083524
                            ],
                            [
                                6.742687225341797,
                                51.51349664501128
                            ],
                            [
                                6.743416786193848,
                                51.51245503991427
                            ],
                            [
                                6.744446754455566,
                                51.51185410306298
                            ],
                            [
                                6.743781566619873,
                                51.511319930318024
                            ],
                            [
                                6.743566989898681,
                                51.51030498484663
                            ],
                            [
                                6.743438243865967,
                                51.508608907082994
                            ],
                            [
                                6.7433953285217285,
                                51.506699074656325
                            ],
                            [
                                6.743652820587158,
                                51.5045888170661
                            ],
                            [
                                6.7432451248168945,
                                51.503894280534574
                            ],
                            [
                                6.742687225341797,
                                51.50297266764028
                            ],
                            [
                                6.7418718338012695,
                                51.50266545920017
                            ],
                            [
                                6.740670204162597,
                                51.50259867448262
                            ]
                        ],
                        [
                            [
                                6.675524711608887,
                                51.48042073765504
                            ],
                            [
                                6.674494743347168,
                                51.47828253510031
                            ],
                            [
                                6.669731140136719,
                                51.476090773448675
                            ],
                            [
                                6.666769981384277,
                                51.4741394823883
                            ],
                            [
                                6.66719913482666,
                                51.47248215593633
                            ],
                            [
                                6.670074462890625,
                                51.462429983101735
                            ],
                            [
                                6.670804023742676,
                                51.45823201083626
                            ],
                            [
                                6.6744089126586905,
                                51.45914115860514
                            ],
                            [
                                6.674623489379883,
                                51.4579646109873
                            ],
                            [
                                6.676833629608153,
                                51.45792450087481
                            ],
                            [
                                6.677284240722655,
                                51.45670781071307
                            ],
                            [
                                6.6777563095092765,
                                51.45513007627605
                            ],
                            [
                                6.6779279708862305,
                                51.45320463155758
                            ],
                            [
                                6.679065227508545,
                                51.451519800809095
                            ],
                            [
                                6.678099632263184,
                                51.45147968503341
                            ],
                            [
                                6.678571701049805,
                                51.448938947429966
                            ],
                            [
                                6.675653457641602,
                                51.44734757134268
                            ],
                            [
                                6.675331592559814,
                                51.44661204264502
                            ],
                            [
                                6.67445182800293,
                                51.444178026845904
                            ],
                            [
                                6.674215793609619,
                                51.443656435152484
                            ],
                            [
                                6.673593521118164,
                                51.44362968669993
                            ],
                            [
                                6.673035621643066,
                                51.44253298665538
                            ],
                            [
                                6.672863960266113,
                                51.441677007345525
                            ],
                            [
                                6.6735076904296875,
                                51.44140951052101
                            ],
                            [
                                6.676018238067627,
                                51.44107513728715
                            ],
                            [
                                6.677284240722655,
                                51.441088512263505
                            ],
                            [
                                6.6768550872802725,
                                51.440406383475214
                            ],
                            [
                                6.672391891479491,
                                51.43944336078293
                            ],
                            [
                                6.672992706298828,
                                51.43890833940217
                            ],
                            [
                                6.674838066101073,
                                51.43870770476864
                            ],
                            [
                                6.676232814788818,
                                51.43793190922674
                            ],
                            [
                                6.680953502655029,
                                51.437878405600195
                            ],
                            [
                                6.686897277832031,
                                51.43757075853144
                            ],
                            [
                                6.694836616516112,
                                51.436139503620915
                            ],
                            [
                                6.698591709136963,
                                51.435898727360126
                            ],
                            [
                                6.7002010345458975,
                                51.4360458685592
                            ],
                            [
                                6.7012739181518555,
                                51.43698221053929
                            ],
                            [
                                6.707797050476074,
                                51.43753063050014
                            ],
                            [
                                6.70889139175415,
                                51.436821696134395
                            ],
                            [
                                6.714878082275391,
                                51.43785165376343
                            ],
                            [
                                6.722452640533447,
                                51.43910897315438
                            ],
                            [
                                6.726207733154297,
                                51.43914909979906
                            ],
                            [
                                6.730821132659912,
                                51.438466942045025
                            ],
                            [
                                6.733503341674805,
                                51.43746375036964
                            ],
                            [
                                6.737215518951415,
                                51.435978986254746
                            ],
                            [
                                6.738524436950684,
                                51.43599236272347
                            ],
                            [
                                6.741614341735839,
                                51.435484054158245
                            ],
                            [
                                6.745455265045166,
                                51.43547067754067
                            ],
                            [
                                6.746871471405029,
                                51.43630002042303
                            ],
                            [
                                6.746721267700195,
                                51.43718285275219
                            ],
                            [
                                6.747729778289795,
                                51.43761088652748
                            ],
                            [
                                6.748695373535156,
                                51.43684844857437
                            ],
                            [
                                6.750755310058594,
                                51.43695545817763
                            ],
                            [
                                6.7520856857299805,
                                51.43634014953542
                            ],
                            [
                                6.7543816566467285,
                                51.436968834360435
                            ],
                            [
                                6.755819320678711,
                                51.43735674195716
                            ],
                            [
                                6.7572784423828125,
                                51.43823955386303
                            ],
                            [
                                6.75959587097168,
                                51.440018501777466
                            ],
                            [
                                6.761269569396973,
                                51.44131588626229
                            ],
                            [
                                6.7623209953308105,
                                51.44207824964462
                            ],
                            [
                                6.763951778411865,
                                51.442920846998305
                            ],
                            [
                                6.76532506942749,
                                51.44358956399171
                            ],
                            [
                                6.7671918869018555,
                                51.44429839331376
                            ],
                            [
                                6.76931619644165,
                                51.44495371629514
                            ],
                            [
                                6.772041320800781,
                                51.445635777165556
                            ],
                            [
                                6.775002479553223,
                                51.446184093223415
                            ],
                            [
                                6.775989532470703,
                                51.447080107732575
                            ],
                            [
                                6.778006553649902,
                                51.44752142184913
                            ],
                            [
                                6.778178215026855,
                                51.448350545927426
                            ],
                            [
                                6.77807092666626,
                                51.450396545766004
                            ],
                            [
                                6.7775774002075195,
                                51.45281685856058
                            ],
                            [
                                6.776783466339111,
                                51.45472894865689
                            ],
                            [
                                6.775839328765869,
                                51.45587883840103
                            ],
                            [
                                6.771891117095947,
                                51.45954224745201
                            ],
                            [
                                6.769273281097412,
                                51.46210913260816
                            ],
                            [
                                6.768007278442383,
                                51.461948706515464
                            ],
                            [
                                6.767470836639403,
                                51.46287114884719
                            ],
                            [
                                6.767921447753905,
                                51.4640475699852
                            ],
                            [
                                6.767728328704834,
                                51.46503680974133
                            ],
                            [
                                6.766998767852782,
                                51.46682808121099
                            ],
                            [
                                6.766140460968018,
                                51.468044501595614
                            ],
                            [
                                6.7650461196899405,
                                51.468044501595614
                            ],
                            [
                                6.763865947723389,
                                51.468004400341634
                            ],
                            [
                                6.762642860412598,
                                51.46843214523393
                            ],
                            [
                                6.764788627624512,
                                51.46984902154792
                            ],
                            [
                                6.764509677886962,
                                51.47194752165605
                            ],
                            [
                                6.7645955085754395,
                                51.47290985884941
                            ],
                            [
                                6.76532506942749,
                                51.47464736069959
                            ],
                            [
                                6.7665696144104,
                                51.47654517171324
                            ],
                            [
                                6.768693923950195,
                                51.479137828151224
                            ],
                            [
                                6.769959926605225,
                                51.48084836613703
                            ],
                            [
                                6.769466400146484,
                                51.481810515561335
                            ],
                            [
                                6.767213344573975,
                                51.48389510301222
                            ],
                            [
                                6.765625476837158,
                                51.48485718815831
                            ],
                            [
                                6.761226654052734,
                                51.48603304243829
                            ],
                            [
                                6.757063865661621,
                                51.48656751163282
                            ],
                            [
                                6.754724979400635,
                                51.486834743880806
                            ],
                            [
                                6.751549243927002,
                                51.48700844400211
                            ],
                            [
                                6.747965812683105,
                                51.48660759656985
                            ],
                            [
                                6.744275093078613,
                                51.48561882450357
                            ],
                            [
                                6.740541458129883,
                                51.48409553909154
                            ],
                            [
                                6.737043857574463,
                                51.48253211430862
                            ],
                            [
                                6.73384666442871,
                                51.480888456101646
                            ],
                            [
                                6.731829643249512,
                                51.48024701243891
                            ],
                            [
                                6.730327606201172,
                                51.47984610556836
                            ],
                            [
                                6.72663688659668,
                                51.478656727783616
                            ],
                            [
                                6.7240190505981445,
                                51.4782023505545
                            ],
                            [
                                6.719233989715575,
                                51.478028616888295
                            ],
                            [
                                6.713590621948242,
                                51.4777613330327
                            ],
                            [
                                6.708440780639648,
                                51.47757423340183
                            ],
                            [
                                6.7038702964782715,
                                51.4781488941123
                            ],
                            [
                                6.702110767364502,
                                51.47853645189877
                            ],
                            [
                                6.698033809661865,
                                51.479872832802705
                            ],
                            [
                                6.6950297355651855,
                                51.48024701243891
                            ],
                            [
                                6.6899657249450675,
                                51.48040737420038
                            ],
                            [
                                6.683528423309326,
                                51.48050091830084
                            ],
                            [
                                6.675524711608887,
                                51.48042073765504
                            ]
                        ],
                        [
                            [
                                6.771483421325684,
                                51.41657898575034
                            ],
                            [
                                6.775603294372559,
                                51.41323332561027
                            ],
                            [
                                6.777491569519043,
                                51.41165408896208
                            ],
                            [
                                6.780838966369629,
                                51.41117227709065
                            ],
                            [
                                6.783456802368164,
                                51.411975294055566
                            ],
                            [
                                6.786160469055176,
                                51.41400954057662
                            ],
                            [
                                6.7902374267578125,
                                51.41419690083533
                            ],
                            [
                                6.7919111251831055,
                                51.41516045575089
                            ],
                            [
                                6.7968034744262695,
                                51.41617751946194
                            ],
                            [
                                6.796245574951172,
                                51.418693316684504
                            ],
                            [
                                6.796073913574219,
                                51.42053993077859
                            ],
                            [
                                6.796760559082031,
                                51.4222794265368
                            ],
                            [
                                6.798434257507324,
                                51.42425969496347
                            ],
                            [
                                6.801438331604004,
                                51.42658773864154
                            ],
                            [
                                6.804656982421875,
                                51.429290261272946
                            ],
                            [
                                6.806588172912598,
                                51.431136447211074
                            ],
                            [
                                6.807317733764648,
                                51.43282203009056
                            ],
                            [
                                6.8077898025512695,
                                51.433878832081525
                            ],
                            [
                                6.807553768157959,
                                51.436005739188275
                            ],
                            [
                                6.806459426879882,
                                51.43793190922674
                            ],
                            [
                                6.804592609405518,
                                51.439911499350096
                            ],
                            [
                                6.801717281341553,
                                51.44161013328627
                            ],
                            [
                                6.799721717834473,
                                51.44340232422058
                            ],
                            [
                                6.798369884490967,
                                51.44543517208472
                            ],
                            [
                                6.797876358032227,
                                51.44605035820662
                            ],
                            [
                                6.796159744262694,
                                51.446692282715084
                            ],
                            [
                                6.7937350273132315,
                                51.44720046655099
                            ],
                            [
                                6.791589260101318,
                                51.447481302560234
                            ],
                            [
                                6.789486408233643,
                                51.447548168022145
                            ],
                            [
                                6.786460876464843,
                                51.44722721291201
                            ],
                            [
                                6.783521175384521,
                                51.446745776016755
                            ],
                            [
                                6.782104969024657,
                                51.44633120128947
                            ],
                            [
                                6.781203746795654,
                                51.44559565621989
                            ],
                            [
                                6.779208183288574,
                                51.44516769727275
                            ],
                            [
                                6.778564453125,
                                51.44379017718026
                            ],
                            [
                                6.777191162109374,
                                51.44135601096811
                            ],
                            [
                                6.776161193847656,
                                51.439764370603186
                            ],
                            [
                                6.775860786437988,
                                51.43837331175424
                            ],
                            [
                                6.776032447814941,
                                51.437089219829176
                            ],
                            [
                                6.776697635650634,
                                51.43615288004262
                            ],
                            [
                                6.778564453125,
                                51.435029246963296
                            ],
                            [
                                6.777620315551758,
                                51.43414637301142
                            ],
                            [
                                6.776247024536132,
                                51.4333571227616
                            ],
                            [
                                6.775217056274413,
                                51.43235381886212
                            ],
                            [
                                6.774251461029052,
                                51.4312836037496
                            ],
                            [
                                6.7730712890625,
                                51.429209990626866
                            ],
                            [
                                6.772019863128662,
                                51.427818610354635
                            ],
                            [
                                6.771333217620849,
                                51.426413808443286
                            ],
                            [
                                6.77279233932495,
                                51.42571808102992
                            ],
                            [
                                6.772663593292236,
                                51.42502234302398
                            ],
                            [
                                6.770946979522705,
                                51.42416603555223
                            ],
                            [
                                6.770946979522705,
                                51.42199843555174
                            ],
                            [
                                6.771354675292968,
                                51.41956310797976
                            ],
                            [
                                6.77156925201416,
                                51.4173819077495
                            ],
                            [
                                6.771483421325684,
                                51.41657898575034
                            ]
                        ],
                        [
                            [
                                6.675503253936768,
                                51.43698221053929
                            ],
                            [
                                6.674848794937134,
                                51.43593216858337
                            ],
                            [
                                6.673325300216675,
                                51.43486872569573
                            ],
                            [
                                6.673067808151245,
                                51.43408617643881
                            ],
                            [
                                6.67292833328247,
                                51.432133089047284
                            ],
                            [
                                6.673250198364258,
                                51.4309491563856
                            ],
                            [
                                6.667349338531494,
                                51.43026687617151
                            ],
                            [
                                6.668229103088379,
                                51.42867484938245
                            ],
                            [
                                6.668915748596191,
                                51.42638704989247
                            ],
                            [
                                6.667370796203613,
                                51.42512938032972
                            ],
                            [
                                6.6664910316467285,
                                51.42420617532339
                            ],
                            [
                                6.665740013122559,
                                51.42122235623709
                            ],
                            [
                                6.664731502532959,
                                51.41837215879149
                            ],
                            [
                                6.663615703582764,
                                51.414250432196795
                            ],
                            [
                                6.66318655014038,
                                51.41311287727911
                            ],
                            [
                                6.662478446960449,
                                51.41091798544474
                            ],
                            [
                                6.66196346282959,
                                51.409700042689856
                            ],
                            [
                                6.665589809417724,
                                51.4102889421389
                            ],
                            [
                                6.66670560836792,
                                51.41117227709065
                            ],
                            [
                                6.668615341186523,
                                51.41180130824338
                            ],
                            [
                                6.668475866317749,
                                51.41104513144448
                            ],
                            [
                                6.669001579284667,
                                51.40981380794725
                            ],
                            [
                                6.666136980056763,
                                51.40915128864911
                            ],
                            [
                                6.663336753845215,
                                51.408448606176044
                            ],
                            [
                                6.6610729694366455,
                                51.40816753016321
                            ],
                            [
                                6.656867265701294,
                                51.408368298920124
                            ],
                            [
                                6.653584241867065,
                                51.40965989017849
                            ],
                            [
                                6.6503870487213135,
                                51.411339573454754
                            ],
                            [
                                6.6477155685424805,
                                51.41351437048221
                            ],
                            [
                                6.645301580429077,
                                51.41538795880886
                            ],
                            [
                                6.642855405807495,
                                51.41678640861789
                            ],
                            [
                                6.639711856842041,
                                51.41758263604561
                            ],
                            [
                                6.636224985122681,
                                51.41843237606832
                            ],
                            [
                                6.630721092224121,
                                51.4191482865035
                            ],
                            [
                                6.6294121742248535,
                                51.41936238838072
                            ],
                            [
                                6.628446578979492,
                                51.418385540415436
                            ],
                            [
                                6.628350019454955,
                                51.41728154327087
                            ],
                            [
                                6.627459526062012,
                                51.41523405980531
                            ],
                            [
                                6.627277135848999,
                                51.414471248400204
                            ],
                            [
                                6.626933813095093,
                                51.412423639058545
                            ],
                            [
                                6.626129150390624,
                                51.41206228671336
                            ],
                            [
                                6.6258180141448975,
                                51.41196860230579
                            ],
                            [
                                6.6257429122924805,
                                51.41138641632702
                            ],
                            [
                                6.625807285308838,
                                51.410857758268826
                            ],
                            [
                                6.626912355422974,
                                51.40890367796222
                            ],
                            [
                                6.628092527389526,
                                51.40668181396388
                            ],
                            [
                                6.628607511520386,
                                51.40552399264811
                            ],
                            [
                                6.6294872760772705,
                                51.40439291346077
                            ],
                            [
                                6.632276773452759,
                                51.403322043479086
                            ],
                            [
                                6.635130643844604,
                                51.40207712560644
                            ],
                            [
                                6.637222766876221,
                                51.4011132949476
                            ],
                            [
                                6.637920141220093,
                                51.400390408624
                            ],
                            [
                                6.6397011280059814,
                                51.39965412377189
                            ],
                            [
                                6.6408491134643555,
                                51.39813466210992
                            ],
                            [
                                6.639207601547241,
                                51.39757907542006
                            ],
                            [
                                6.639894247055054,
                                51.3946403781599
                            ],
                            [
                                6.635699272155762,
                                51.39428557961414
                            ],
                            [
                                6.635881662368774,
                                51.392980165051796
                            ],
                            [
                                6.636267900466919,
                                51.39090481394111
                            ],
                            [
                                6.636064052581787,
                                51.39059685059398
                            ],
                            [
                                6.636257171630859,
                                51.385736111066315
                            ],
                            [
                                6.63998007774353,
                                51.386171322289535
                            ],
                            [
                                6.641267538070679,
                                51.386499401860114
                            ],
                            [
                                6.641514301300049,
                                51.386244973010115
                            ],
                            [
                                6.644754409790039,
                                51.38687434706059
                            ],
                            [
                                6.647050380706787,
                                51.382789957022666
                            ],
                            [
                                6.648648977279663,
                                51.38224088007486
                            ],
                            [
                                6.650269031524658,
                                51.382689516609844
                            ],
                            [
                                6.652157306671143,
                                51.38283013312604
                            ],
                            [
                                6.653380393981934,
                                51.38264264434178
                            ],
                            [
                                6.653788089752197,
                                51.38408227066991
                            ],
                            [
                                6.657167673110962,
                                51.384149228931854
                            ],
                            [
                                6.660472154617309,
                                51.38735641494701
                            ],
                            [
                                6.666523218154907,
                                51.387222507710206
                            ],
                            [
                                6.668379306793213,
                                51.386840869935455
                            ],
                            [
                                6.673196554183959,
                                51.38967964312791
                            ],
                            [
                                6.674076318740845,
                                51.38796568792683
                            ],
                            [
                                6.673067808151245,
                                51.386318623612176
                            ],
                            [
                                6.672295331954956,
                                51.38671365663657
                            ],
                            [
                                6.671587228775024,
                                51.38368051904078
                            ],
                            [
                                6.67094349861145,
                                51.379448520985484
                            ],
                            [
                                6.6712868213653564,
                                51.378973066374506
                            ],
                            [
                                6.6703104972839355,
                                51.37798196201247
                            ],
                            [
                                6.668733358383179,
                                51.37620729585983
                            ],
                            [
                                6.667381525039673,
                                51.37483439371322
                            ],
                            [
                                6.668229103088379,
                                51.37378292374023
                            ],
                            [
                                6.671093702316284,
                                51.374633477750415
                            ],
                            [
                                6.673368215560913,
                                51.37566483700506
                            ],
                            [
                                6.675664186477661,
                                51.37639481100592
                            ],
                            [
                                6.679204702377319,
                                51.37672965757065
                            ],
                            [
                                6.677370071411133,
                                51.37398384343589
                            ],
                            [
                                6.6761040687561035,
                                51.37223581254869
                            ],
                            [
                                6.671243906021117,
                                51.37092307114478
                            ],
                            [
                                6.6716730594635,
                                51.36890030408033
                            ],
                            [
                                6.667220592498779,
                                51.36777501749513
                            ],
                            [
                                6.662735939025879,
                                51.365892779908805
                            ],
                            [
                                6.655740737915039,
                                51.36159884520256
                            ],
                            [
                                6.653852462768555,
                                51.357311207645985
                            ],
                            [
                                6.655569076538086,
                                51.35264794623193
                            ],
                            [
                                6.658744812011719,
                                51.34969966250895
                            ],
                            [
                                6.667156219482422,
                                51.34701923995036
                            ],
                            [
                                6.682605743408203,
                                51.34315915601202
                            ],
                            [
                                6.699771881103516,
                                51.33833359386697
                            ],
                            [
                                6.715199947357178,
                                51.33301148211839
                            ],
                            [
                                6.718032360076904,
                                51.33503583420738
                            ],
                            [
                                6.719233989715575,
                                51.33529054767555
                            ],
                            [
                                6.72410488128662,
                                51.33962046011348
                            ],
                            [
                                6.727774143218994,
                                51.3415640958471
                            ],
                            [
                                6.731271743774414,
                                51.34267662265888
                            ],
                            [
                                6.738417148590088,
                                51.34516965696725
                            ],
                            [
                                6.738674640655517,
                                51.34585320720065
                            ],
                            [
                                6.741571426391602,
                                51.347917198972034
                            ],
                            [
                                6.743524074554443,
                                51.3505841675457
                            ],
                            [
                                6.742966175079346,
                                51.35129443953262
                            ],
                            [
                                6.741571426391602,
                                51.3508387946184
                            ],
                            [
                                6.740305423736572,
                                51.35125423575185
                            ],
                            [
                                6.740005016326904,
                                51.35235312639667
                            ],
                            [
                                6.741464138031006,
                                51.35256754101045
                            ],
                            [
                                6.7421507835388175,
                                51.35321077883121
                            ],
                            [
                                6.74335241317749,
                                51.35319737813541
                            ],
                            [
                                6.743373870849609,
                                51.354912635342
                            ],
                            [
                                6.743545532226562,
                                51.35573004001013
                            ],
                            [
                                6.744275093078613,
                                51.35698961864973
                            ],
                            [
                                6.7439961433410645,
                                51.3573380066271
                            ],
                            [
                                6.74436092376709,
                                51.35771319071652
                            ],
                            [
                                6.744811534881592,
                                51.357847184289454
                            ],
                            [
                                6.745283603668213,
                                51.358168767265376
                            ],
                            [
                                6.74685001373291,
                                51.36011161638846
                            ],
                            [
                                6.7464423179626465,
                                51.36064756026537
                            ],
                            [
                                6.74710750579834,
                                51.36084853760247
                            ],
                            [
                                6.7476654052734375,
                                51.361786420182064
                            ],
                            [
                                6.748244762420654,
                                51.36229554839704
                            ],
                            [
                                6.749832630157471,
                                51.36370232061927
                            ],
                            [
                                6.750926971435547,
                                51.364921488259526
                            ],
                            [
                                6.751463413238525,
                                51.36587268442819
                            ],
                            [
                                6.751677989959717,
                                51.36698462109869
                            ],
                            [
                                6.751720905303955,
                                51.36801615266278
                            ],
                            [
                                6.751656532287598,
                                51.36895388846604
                            ],
                            [
                                6.751549243927002,
                                51.36981123010947
                            ],
                            [
                                6.751463413238525,
                                51.37080251137373
                            ],
                            [
                                6.751377582550048,
                                51.37127135314467
                            ],
                            [
                                6.752665042877197,
                                51.37172679483938
                            ],
                            [
                                6.754124164581299,
                                51.37277161455003
                            ],
                            [
                                6.755690574645996,
                                51.374271826795265
                            ],
                            [
                                6.757299900054932,
                                51.375624654611954
                            ],
                            [
                                6.758072376251221,
                                51.37630775049792
                            ],
                            [
                                6.759123802185058,
                                51.37677653589424
                            ],
                            [
                                6.759853363037109,
                                51.37661581001345
                            ],
                            [
                                6.761054992675781,
                                51.377044411108166
                            ],
                            [
                                6.764273643493652,
                                51.37859805643695
                            ],
                            [
                                6.768157482147217,
                                51.38063378773858
                            ],
                            [
                                6.771869659423827,
                                51.382468546925786
                            ],
                            [
                                6.773521900177002,
                                51.38333902738308
                            ],
                            [
                                6.773521900177002,
                                51.38587002265277
                            ],
                            [
                                6.773500442504883,
                                51.38719572621581
                            ],
                            [
                                6.773049831390381,
                                51.39193580658246
                            ],
                            [
                                6.7722344398498535,
                                51.39758576927578
                            ],
                            [
                                6.771655082702637,
                                51.40311455952028
                            ],
                            [
                                6.7711615562438965,
                                51.404600391619894
                            ],
                            [
                                6.770861148834228,
                                51.405898781947585
                            ],
                            [
                                6.771032810211182,
                                51.40628695334097
                            ],
                            [
                                6.770775318145752,
                                51.406902666723695
                            ],
                            [
                                6.770968437194824,
                                51.40800022219237
                            ],
                            [
                                6.770968437194824,
                                51.4085623745472
                            ],
                            [
                                6.770603656768799,
                                51.411306114230904
                            ],
                            [
                                6.770431995391846,
                                51.41455823630869
                            ],
                            [
                                6.770153045654297,
                                51.41595002033338
                            ],
                            [
                                6.769015789031982,
                                51.4159232556556
                            ],
                            [
                                6.768629550933838,
                                51.41657898575034
                            ],
                            [
                                6.76983118057251,
                                51.41695368443837
                            ],
                            [
                                6.770045757293701,
                                51.41821157899879
                            ],
                            [
                                6.770131587982178,
                                51.419830732740664
                            ],
                            [
                                6.76983118057251,
                                51.42177096539372
                            ],
                            [
                                6.769530773162842,
                                51.42361745514586
                            ],
                            [
                                6.769616603851318,
                                51.425156139617
                            ],
                            [
                                6.768758296966553,
                                51.42587863445009
                            ],
                            [
                                6.768479347229004,
                                51.42665463469537
                            ],
                            [
                                6.76983118057251,
                                51.427069388043165
                            ],
                            [
                                6.770775318145752,
                                51.427551032367916
                            ],
                            [
                                6.77107572555542,
                                51.42831362549926
                            ],
                            [
                                6.772212982177734,
                                51.43030701058382
                            ],
                            [
                                6.773371696472168,
                                51.432019379332516
                            ],
                            [
                                6.774616241455078,
                                51.433383877230646
                            ],
                            [
                                6.775496006011963,
                                51.43433365073005
                            ],
                            [
                                6.775646209716797,
                                51.43553756058937
                            ],
                            [
                                6.774122714996338,
                                51.437276485483274
                            ],
                            [
                                6.774508953094482,
                                51.43801216454901
                            ],
                            [
                                6.774594783782959,
                                51.43924272849957
                            ],
                            [
                                6.775195598602295,
                                51.44062038576043
                            ],
                            [
                                6.7768049240112305,
                                51.44321508368179
                            ],
                            [
                                6.777598857879639,
                                51.44481997767495
                            ],
                            [
                                6.776096820831299,
                                51.44475310821799
                            ],
                            [
                                6.77429437637329,
                                51.44504733309622
                            ],
                            [
                                6.770625114440918,
                                51.44448562941091
                            ],
                            [
                                6.7658185958862305,
                                51.44304121677976
                            ],
                            [
                                6.762278079986572,
                                51.44098151234298
                            ],
                            [
                                6.7595744132995605,
                                51.43881471001619
                            ],
                            [
                                6.757557392120361,
                                51.43732998981488
                            ],
                            [
                                6.755197048187256,
                                51.43611275076571
                            ],
                            [
                                6.752064228057861,
                                51.43489547927951
                            ],
                            [
                                6.751205921173096,
                                51.43418650401575
                            ],
                            [
                                6.7494893074035645,
                                51.43398584864152
                            ],
                            [
                                6.7474937438964835,
                                51.434668073318065
                            ],
                            [
                                6.745626926422119,
                                51.434668073318065
                            ],
                            [
                                6.741399765014648,
                                51.434601188996325
                            ],
                            [
                                6.737580299377441,
                                51.43481521848119
                            ],
                            [
                                6.734533309936523,
                                51.43565794983025
                            ],
                            [
                                6.733331680297851,
                                51.43631339679774
                            ],
                            [
                                6.729919910430907,
                                51.43759751053271
                            ],
                            [
                                6.727280616760254,
                                51.43803891629176
                            ],
                            [
                                6.724083423614501,
                                51.438319808644785
                            ],
                            [
                                6.720821857452393,
                                51.43803891629176
                            ],
                            [
                                6.716680526733398,
                                51.437276485483274
                            ],
                            [
                                6.709449291229247,
                                51.436139503620915
                            ],
                            [
                                6.708440780639648,
                                51.43472158070493
                            ],
                            [
                                6.7061662673950195,
                                51.43452092768095
                            ],
                            [
                                6.702239513397217,
                                51.43429351985498
                            ],
                            [
                                6.700329780578613,
                                51.434681450170665
                            ],
                            [
                                6.699106693267822,
                                51.43505600045306
                            ],
                            [
                                6.695866584777832,
                                51.43508275392713
                            ],
                            [
                                6.690695285797119,
                                51.436005739188275
                            ],
                            [
                                6.686811447143555,
                                51.43674143872044
                            ],
                            [
                                6.682755947113037,
                                51.437022339052355
                            ],
                            [
                                6.678013801574707,
                                51.437035715215565
                            ],
                            [
                                6.675503253936768,
                                51.43698221053929
                            ]
                        ],
                        [
                            [
                                6.817638874053954,
                                51.44159675846268
                            ],
                            [
                                6.8178534507751465,
                                51.43315646374443
                            ],
                            [
                                6.824312210083008,
                                51.43290229439077
                            ],
                            [
                                6.825535297393799,
                                51.43220666577073
                            ],
                            [
                                6.826221942901611,
                                51.43163142641103
                            ],
                            [
                                6.826457977294922,
                                51.43041403551086
                            ],
                            [
                                6.827359199523925,
                                51.42979863875662
                            ],
                            [
                                6.8285393714904785,
                                51.42951769400434
                            ],
                            [
                                6.829397678375244,
                                51.42870160659351
                            ],
                            [
                                6.8303632736206055,
                                51.427363726844895
                            ],
                            [
                                6.8315863609313965,
                                51.42680180566903
                            ],
                            [
                                6.833260059356689,
                                51.42621311893035
                            ],
                            [
                                6.83424711227417,
                                51.42558428608219
                            ],
                            [
                                6.836328506469727,
                                51.42411251580244
                            ],
                            [
                                6.838173866271973,
                                51.422908304862254
                            ],
                            [
                                6.840040683746337,
                                51.42231956796505
                            ],
                            [
                                6.8436455726623535,
                                51.42148997128251
                            ],
                            [
                                6.84446096420288,
                                51.42147659056747
                            ],
                            [
                                6.845877170562743,
                                51.421075167294184
                            ],
                            [
                                6.847357749938964,
                                51.420352596517304
                            ],
                            [
                                6.8479156494140625,
                                51.42005821255538
                            ],
                            [
                                6.849825382232665,
                                51.41976382669734
                            ],
                            [
                                6.851220130920409,
                                51.41945605763663
                            ],
                            [
                                6.85297966003418,
                                51.41894756508278
                            ],
                            [
                                6.85420274734497,
                                51.4181312888909
                            ],
                            [
                                6.855897903442383,
                                51.41649869277473
                            ],
                            [
                                6.857185363769531,
                                51.41553516606659
                            ],
                            [
                                6.8592023849487305,
                                51.41363481775554
                            ],
                            [
                                6.8602752685546875,
                                51.412497247519454
                            ],
                            [
                                6.8616485595703125,
                                51.411573787336124
                            ],
                            [
                                6.863107681274414,
                                51.41023540613876
                            ],
                            [
                                6.864910125732421,
                                51.40947251132587
                            ],
                            [
                                6.8680644035339355,
                                51.408575759042854
                            ],
                            [
                                6.869502067565918,
                                51.408548990047635
                            ],
                            [
                                6.869866847991943,
                                51.40742467809767
                            ],
                            [
                                6.870725154876708,
                                51.40615310150856
                            ],
                            [
                                6.872913837432861,
                                51.40435944915342
                            ],
                            [
                                6.8753814697265625,
                                51.40232477325965
                            ],
                            [
                                6.877355575561523,
                                51.40091918770499
                            ],
                            [
                                6.878535747528075,
                                51.40207043240801
                            ],
                            [
                                6.8796515464782715,
                                51.40330196442744
                            ],
                            [
                                6.880209445953369,
                                51.40365000007477
                            ],
                            [
                                6.883041858673096,
                                51.40484133281692
                            ],
                            [
                                6.887247562408447,
                                51.40659481106864
                            ],
                            [
                                6.893577575683594,
                                51.409191441607135
                            ],
                            [
                                6.894285678863525,
                                51.40984726826321
                            ],
                            [
                                6.894607543945312,
                                51.41043616581601
                            ],
                            [
                                6.894264221191406,
                                51.410810914854935
                            ],
                            [
                                6.892848014831543,
                                51.41111874212483
                            ],
                            [
                                6.891496181488037,
                                51.4114667182821
                            ],
                            [
                                6.89091682434082,
                                51.4119351435423
                            ],
                            [
                                6.890509128570557,
                                51.41269799726499
                            ],
                            [
                                6.890723705291747,
                                51.41313964360234
                            ],
                            [
                                6.8918609619140625,
                                51.41388909429054
                            ],
                            [
                                6.892354488372803,
                                51.41427719785401
                            ],
                            [
                                6.892225742340087,
                                51.415401341306435
                            ],
                            [
                                6.892204284667968,
                                51.41656560359754
                            ],
                            [
                                6.893126964569091,
                                51.41750234483294
                            ],
                            [
                                6.894328594207764,
                                51.41787703595211
                            ],
                            [
                                6.89692497253418,
                                51.41807776207394
                            ],
                            [
                                6.898298263549805,
                                51.41849259326581
                            ],
                            [
                                6.899070739746094,
                                51.41898770943745
                            ],
                            [
                                6.900486946105956,
                                51.418452448476295
                            ],
                            [
                                6.903212070465088,
                                51.41735514391008
                            ],
                            [
                                6.9055938720703125,
                                51.416926920348246
                            ],
                            [
                                6.907181739807129,
                                51.41589649096214
                            ],
                            [
                                6.9083404541015625,
                                51.415588695860954
                            ],
                            [
                                6.911602020263672,
                                51.41518722087531
                            ],
                            [
                                6.914005279541016,
                                51.41510692545505
                            ],
                            [
                                6.915700435638428,
                                51.41529428121622
                            ],
                            [
                                6.917245388031006,
                                51.41518722087531
                            ],
                            [
                                6.919069290161133,
                                51.41479912503758
                            ],
                            [
                                6.921472549438477,
                                51.413862328406076
                            ],
                            [
                                6.924498081207275,
                                51.41256416419923
                            ],
                            [
                                6.928274631500243,
                                51.41093136925085
                            ],
                            [
                                6.932201385498047,
                                51.409566200848154
                            ],
                            [
                                6.935055255889892,
                                51.40888360136126
                            ],
                            [
                                6.936686038970947,
                                51.40878991044027
                            ],
                            [
                                6.938188076019287,
                                51.40813406861801
                            ],
                            [
                                6.93960428237915,
                                51.40845529844102
                            ],
                            [
                                6.941878795623778,
                                51.40895052333015
                            ],
                            [
                                6.943852901458739,
                                51.40887021695573
                            ],
                            [
                                6.9467711448669425,
                                51.408602528022385
                            ],
                            [
                                6.949367523193359,
                                51.4075719109975
                            ],
                            [
                                6.9504618644714355,
                                51.4065546558304
                            ],
                            [
                                6.951127052307128,
                                51.405510607259146
                            ],
                            [
                                6.951878070831299,
                                51.40488148955968
                            ],
                            [
                                6.954817771911621,
                                51.40500195957636
                            ],
                            [
                                6.957993507385253,
                                51.40496180293938
                            ],
                            [
                                6.959688663482666,
                                51.4049484173859
                            ],
                            [
                                6.960504055023193,
                                51.40530982595497
                            ],
                            [
                                6.961491107940674,
                                51.40565784632195
                            ],
                            [
                                6.9655680656433105,
                                51.408495452010214
                            ],
                            [
                                6.9699883460998535,
                                51.412283113485906
                            ],
                            [
                                6.9728851318359375,
                                51.415254133617765
                            ],
                            [
                                6.973443031311035,
                                51.41604369658225
                            ],
                            [
                                6.976919174194336,
                                51.41809114378404
                            ],
                            [
                                6.978249549865723,
                                51.41889403922172
                            ],
                            [
                                6.980030536651611,
                                51.419991306845056
                            ],
                            [
                                6.983270645141601,
                                51.4217308234836
                            ],
                            [
                                6.984601020812988,
                                51.42239985071581
                            ],
                            [
                                6.985630989074707,
                                51.422346328897646
                            ],
                            [
                                6.987969875335693,
                                51.422854783639536
                            ],
                            [
                                6.990845203399658,
                                51.42340337320095
                            ],
                            [
                                6.993398666381836,
                                51.42359069495762
                            ],
                            [
                                6.996338367462158,
                                51.42371111568141
                            ],
                            [
                                6.999707221984863,
                                51.42351041429877
                            ],
                            [
                                7.0026469230651855,
                                51.42312238912632
                            ],
                            [
                                7.004964351654053,
                                51.42320267046678
                            ],
                            [
                                7.007260322570801,
                                51.42369773561667
                            ],
                            [
                                7.009320259094238,
                                51.42474136890353
                            ],
                            [
                                7.01228141784668,
                                51.426841943125055
                            ],
                            [
                                7.0143842697143555,
                                51.428193216903615
                            ],
                            [
                                7.017173767089844,
                                51.429852151846916
                            ],
                            [
                                7.020971775054932,
                                51.432046134585
                            ],
                            [
                                7.02200174331665,
                                51.43291567176043
                            ],
                            [
                                7.0224738121032715,
                                51.435243274442634
                            ],
                            [
                                7.022109031677246,
                                51.43659429976191
                            ],
                            [
                                7.02200174331665,
                                51.43695545817763
                            ],
                            [
                                7.02301025390625,
                                51.43718285275219
                            ],
                            [
                                7.023289203643798,
                                51.435671326392985
                            ],
                            [
                                7.023310661315918,
                                51.43304944524155
                            ],
                            [
                                7.024705410003661,
                                51.434066110896985
                            ],
                            [
                                7.026379108428955,
                                51.43555093718735
                            ],
                            [
                                7.027451992034912,
                                51.43638027861256
                            ],
                            [
                                7.028310298919678,
                                51.436875200998706
                            ],
                            [
                                7.030220031738281,
                                51.4376911424138
                            ],
                            [
                                7.032730579376221,
                                51.43838668752181
                            ],
                            [
                                7.034318447113037,
                                51.439416609862725
                            ],
                            [
                                7.035369873046875,
                                51.44047325929706
                            ],
                            [
                                7.034919261932372,
                                51.44118213698832
                            ],
                            [
                                7.035648822784424,
                                51.44152988428593
                            ],
                            [
                                7.0362067222595215,
                                51.44289409811487
                            ],
                            [
                                7.036550045013428,
                                51.44419140091356
                            ],
                            [
                                7.036528587341309,
                                51.4454752931714
                            ],
                            [
                                7.036871910095214,
                                51.446839389143946
                            ],
                            [
                                7.037708759307861,
                                51.44938024358077
                            ],
                            [
                                7.038631439208984,
                                51.4513727094593
                            ],
                            [
                                7.039811611175536,
                                51.453953425264984
                            ],
                            [
                                7.03700065612793,
                                51.45347205929161
                            ],
                            [
                                7.03397512435913,
                                51.45301743121167
                            ],
                            [
                                7.031421661376953,
                                51.45214827669268
                            ],
                            [
                                7.028803825378418,
                                51.451399453376304
                            ],
                            [
                                7.025713920593261,
                                51.451091897384615
                            ],
                            [
                                7.02350378036499,
                                51.450824455707696
                            ],
                            [
                                7.021486759185791,
                                51.45035642900336
                            ],
                            [
                                7.019083499908447,
                                51.4497546733336
                            ],
                            [
                                7.018997669219971,
                                51.44904592870667
                            ],
                            [
                                7.018096446990967,
                                51.449072673986684
                            ],
                            [
                                7.016701698303223,
                                51.44944710626183
                            ],
                            [
                                7.0137834548950195,
                                51.44964769371756
                            ],
                            [
                                7.011315822601318,
                                51.44960757629692
                            ],
                            [
                                7.0085906982421875,
                                51.44930000823424
                            ],
                            [
                                7.007668018341064,
                                51.44851101981648
                            ],
                            [
                                7.006444931030273,
                                51.44856451098751
                            ],
                            [
                                7.0040202140808105,
                                51.44737431761752
                            ],
                            [
                                7.002196311950684,
                                51.446531802433974
                            ],
                            [
                                7.001016139984131,
                                51.44550204054292
                            ],
                            [
                                7.000436782836914,
                                51.44578288699807
                            ],
                            [
                                6.998763084411621,
                                51.446184093223415
                            ],
                            [
                                6.99655294418335,
                                51.44606373172593
                            ],
                            [
                                6.993613243103027,
                                51.445622403520915
                            ],
                            [
                                6.989450454711914,
                                51.44494034245075
                            ],
                            [
                                6.986403465270996,
                                51.444271645237215
                            ],
                            [
                                6.984536647796631,
                                51.44429839331376
                            ],
                            [
                                6.979944705963135,
                                51.443656435152484
                            ],
                            [
                                6.97505235671997,
                                51.44296097029404
                            ],
                            [
                                6.9724345207214355,
                                51.442573110291896
                            ],
                            [
                                6.967241764068603,
                                51.44177063086394
                            ],
                            [
                                6.963529586791992,
                                51.441155387086525
                            ],
                            [
                                6.959431171417235,
                                51.44045988414053
                            ],
                            [
                                6.95584774017334,
                                51.44029938195657
                            ],
                            [
                                6.9554829597473145,
                                51.43979112131968
                            ],
                            [
                                6.954216957092285,
                                51.43973761987098
                            ],
                            [
                                6.952199935913086,
                                51.4393497324937
                            ],
                            [
                                6.949560642242432,
                                51.43823955386303
                            ],
                            [
                                6.942715644836426,
                                51.435724832604734
                            ],
                            [
                                6.938295364379883,
                                51.43433365073005
                            ],
                            [
                                6.935634613037109,
                                51.433330368276906
                            ],
                            [
                                6.931579113006592,
                                51.43092240049073
                            ],
                            [
                                6.92861795425415,
                                51.430641462648445
                            ],
                            [
                                6.9271159172058105,
                                51.43106955771869
                            ],
                            [
                                6.9280171394348145,
                                51.43163142641103
                            ],
                            [
                                6.932566165924072,
                                51.43296918119988
                            ],
                            [
                                6.935291290283203,
                                51.43457443524021
                            ],
                            [
                                6.931729316711426,
                                51.434935609625775
                            ],
                            [
                                6.928660869598389,
                                51.43555093718735
                            ],
                            [
                                6.926214694976807,
                                51.436968834360435
                            ],
                            [
                                6.923940181732178,
                                51.43902871975925
                            ],
                            [
                                6.922545433044434,
                                51.441837504688195
                            ],
                            [
                                6.921300888061523,
                                51.44437863744938
                            ],
                            [
                                6.918168067932129,
                                51.4463044544036
                            ],
                            [
                                6.913769245147705,
                                51.448350545927426
                            ],
                            [
                                6.91239595413208,
                                51.448938947429966
                            ],
                            [
                                6.90915584564209,
                                51.45093143256645
                            ],
                            [
                                6.90415620803833,
                                51.45305754563612
                            ],
                            [
                                6.901516914367676,
                                51.45394005405649
                            ],
                            [
                                6.896367073059082,
                                51.45533063876384
                            ],
                            [
                                6.892569065093994,
                                51.4568949959258
                            ],
                            [
                                6.889479160308838,
                                51.458579628297976
                            ],
                            [
                                6.887505054473877,
                                51.459448660369674
                            ],
                            [
                                6.886153221130371,
                                51.45872669642711
                            ],
                            [
                                6.885123252868652,
                                51.45900746153957
                            ],
                            [
                                6.884222030639648,
                                51.46013050471839
                            ],
                            [
                                6.88218355178833,
                                51.460210721030904
                            ],
                            [
                                6.8798017501831055,
                                51.459809638058495
                            ],
                            [
                                6.877162456512451,
                                51.45867321716224
                            ],
                            [
                                6.873900890350342,
                                51.457255993811245
                            ],
                            [
                                6.871283054351807,
                                51.45623984432289
                            ],
                            [
                                6.867163181304932,
                                51.455450975833514
                            ],
                            [
                                6.86293601989746,
                                51.45491614198447
                            ],
                            [
                                6.858558654785155,
                                51.45468883570109
                            ],
                            [
                                6.854631900787353,
                                51.454341188607806
                            ],
                            [
                                6.852164268493652,
                                51.453779599249
                            ],
                            [
                                6.849954128265381,
                                51.45281685856058
                            ],
                            [
                                6.847937107086182,
                                51.451305849598164
                            ],
                            [
                                6.846671104431152,
                                51.44978141819847
                            ],
                            [
                                6.845834255218506,
                                51.44875172959256
                            ],
                            [
                                6.846177577972412,
                                51.44839066445258
                            ],
                            [
                                6.845426559448241,
                                51.44788249386289
                            ],
                            [
                                6.843323707580566,
                                51.44690625554585
                            ],
                            [
                                6.8408989906311035,
                                51.446531802433974
                            ],
                            [
                                6.836049556732178,
                                51.44617071973935
                            ],
                            [
                                6.8309855461120605,
                                51.44570264533
                            ],
                            [
                                6.8271660804748535,
                                51.44498046397219
                            ],
                            [
                                6.82450532913208,
                                51.4442047749773
                            ],
                            [
                                6.821072101593017,
                                51.442666731973326
                            ],
                            [
                                6.819033622741699,
                                51.44187762893573
                            ],
                            [
                                6.817638874053954,
                                51.44159675846268
                            ]
                        ],
                        [
                            [
                                7.190208435058594,
                                51.58608318060075
                            ],
                            [
                                7.194070816040039,
                                51.5848032454929
                            ],
                            [
                                7.206859588623047,
                                51.58512323265012
                            ],
                            [
                                7.214069366455077,
                                51.58586986058667
                            ],
                            [
                                7.216365337371825,
                                51.586363161599294
                            ],
                            [
                                7.215700149536132,
                                51.58750973245011
                            ],
                            [
                                7.214069366455077,
                                51.58802967946255
                            ],
                            [
                                7.2119879722595215,
                                51.58792302415026
                            ],
                            [
                                7.210357189178466,
                                51.58750973245011
                            ],
                            [
                                7.210121154785155,
                                51.58818966196156
                            ],
                            [
                                7.211709022521973,
                                51.58845629820794
                            ],
                            [
                                7.214326858520508,
                                51.58861627920463
                            ],
                            [
                                7.216644287109375,
                                51.58764305276414
                            ],
                            [
                                7.218596935272217,
                                51.58710976916055
                            ],
                            [
                                7.220206260681152,
                                51.587389743832944
                            ],
                            [
                                7.224798202514649,
                                51.589389512746834
                            ],
                            [
                                7.22947597503662,
                                51.59085595400579
                            ],
                            [
                                7.234046459197997,
                                51.5921757106656
                            ],
                            [
                                7.233896255493164,
                                51.59326881341261
                            ],
                            [
                                7.234003543853759,
                                51.59513502560173
                            ],
                            [
                                7.2348833084106445,
                                51.59829408146926
                            ],
                            [
                                7.234454154968261,
                                51.59893386349487
                            ],
                            [
                                7.232930660247802,
                                51.59994683326921
                            ],
                            [
                                7.2325873374938965,
                                51.60085315549374
                            ],
                            [
                                7.233252525329589,
                                51.601919393776335
                            ],
                            [
                                7.233424186706542,
                                51.603012262033786
                            ],
                            [
                                7.232522964477538,
                                51.603092226971135
                            ],
                            [
                                7.230463027954101,
                                51.603691959512496
                            ],
                            [
                                7.229712009429932,
                                51.60426502987323
                            ],
                            [
                                7.2295403480529785,
                                51.60493138166303
                            ],
                            [
                                7.229454517364502,
                                51.60631736206092
                            ],
                            [
                                7.230463027954101,
                                51.60706364167399
                            ],
                            [
                                7.230956554412841,
                                51.60803645203737
                            ],
                            [
                                7.230441570281982,
                                51.60904921876133
                            ],
                            [
                                7.2299909591674805,
                                51.60943566326641
                            ],
                            [
                                7.229883670806884,
                                51.61087480696834
                            ],
                            [
                                7.229025363922118,
                                51.61236720409901
                            ],
                            [
                                7.228896617889403,
                                51.61324662943213
                            ],
                            [
                                7.229819297790527,
                                51.61456573548521
                            ],
                            [
                                7.229433059692382,
                                51.61579153704777
                            ],
                            [
                                7.229132652282715,
                                51.61661760203669
                            ],
                            [
                                7.228145599365234,
                                51.61840291693952
                            ],
                            [
                                7.227566242218018,
                                51.61985509890477
                            ],
                            [
                                7.227308750152589,
                                51.620561188940975
                            ],
                            [
                                7.22675085067749,
                                51.621693574550434
                            ],
                            [
                                7.227115631103516,
                                51.62202662376358
                            ],
                            [
                                7.226965427398681,
                                51.623625225959735
                            ],
                            [
                                7.226343154907227,
                                51.62515716688223
                            ],
                            [
                                7.2264719009399405,
                                51.62624947577645
                            ],
                            [
                                7.2269225120544425,
                                51.62747496176857
                            ],
                            [
                                7.227180004119872,
                                51.62848729479438
                            ],
                            [
                                7.227287292480469,
                                51.62920657032209
                            ],
                            [
                                7.2275447845458975,
                                51.63012562801152
                            ],
                            [
                                7.226085662841797,
                                51.63056517075918
                            ],
                            [
                                7.223896980285645,
                                51.630778280861364
                            ],
                            [
                                7.223618030548096,
                                51.63196368753114
                            ],
                            [
                                7.221729755401611,
                                51.6318704544211
                            ],
                            [
                                7.219691276550292,
                                51.6315907539412
                            ],
                            [
                                7.218918800354003,
                                51.631843816354476
                            ],
                            [
                                7.216730117797851,
                                51.632043601472944
                            ],
                            [
                                7.214326858520508,
                                51.632429850206904
                            ],
                            [
                                7.212417125701903,
                                51.63302919517908
                            ],
                            [
                                7.209305763244629,
                                51.633721761728346
                            ],
                            [
                                7.207610607147216,
                                51.63412131454264
                            ],
                            [
                                7.2046709060668945,
                                51.63444095425993
                            ],
                            [
                                7.201945781707765,
                                51.634454272532594
                            ],
                            [
                                7.199027538299561,
                                51.63410799617222
                            ],
                            [
                                7.19602346420288,
                                51.63346870979214
                            ],
                            [
                                7.193233966827393,
                                51.63264295154874
                            ],
                            [
                                7.192332744598389,
                                51.63219011000058
                            ],
                            [
                                7.192225456237793,
                                51.63128441334146
                            ],
                            [
                                7.1916890144348145,
                                51.63097807067286
                            ],
                            [
                                7.190079689025879,
                                51.630778280861364
                            ],
                            [
                                7.189071178436278,
                                51.63069836469037
                            ],
                            [
                                7.186431884765625,
                                51.6296461219749
                            ],
                            [
                                7.184007167816162,
                                51.62846065474101
                            ],
                            [
                                7.181861400604248,
                                51.62735507873012
                            ],
                            [
                                7.177290916442871,
                                51.6250372777191
                            ],
                            [
                                7.175338268280028,
                                51.62394493964055
                            ],
                            [
                                7.174565792083739,
                                51.6231190080245
                            ],
                            [
                                7.174093723297119,
                                51.62229306137407
                            ],
                            [
                                7.174222469329834,
                                51.621413811322306
                            ],
                            [
                                7.171754837036133,
                                51.62227973953069
                            ],
                            [
                                7.16933012008667,
                                51.61793661012181
                            ],
                            [
                                7.1706390380859375,
                                51.61537849891513
                            ],
                            [
                                7.174050807952881,
                                51.61128788608502
                            ],
                            [
                                7.175638675689698,
                                51.610794855737375
                            ],
                            [
                                7.176861763000487,
                                51.610408422802664
                            ],
                            [
                                7.177999019622803,
                                51.60936903513815
                            ],
                            [
                                7.177870273590088,
                                51.60867609680741
                            ],
                            [
                                7.178192138671875,
                                51.60751673402568
                            ],
                            [
                                7.17928647994995,
                                51.60658389190236
                            ],
                            [
                                7.181131839752196,
                                51.604984689383734
                            ],
                            [
                                7.182698249816894,
                                51.603212174113104
                            ],
                            [
                                7.184436321258546,
                                51.60111971741148
                            ],
                            [
                                7.18580961227417,
                                51.59894719219123
                            ],
                            [
                                7.187311649322511,
                                51.59546826970978
                            ],
                            [
                                7.187740802764893,
                                51.59214904929437
                            ],
                            [
                                7.188727855682373,
                                51.587203094243044
                            ],
                            [
                                7.190208435058594,
                                51.58608318060075
                            ]
                        ],
                        [
                            [
                                7.234025001525879,
                                51.59112257460339
                            ],
                            [
                                7.231621742248536,
                                51.59068264977816
                            ],
                            [
                                7.228145599365234,
                                51.58936284974034
                            ],
                            [
                                7.223660945892334,
                                51.587816368587546
                            ],
                            [
                                7.219133377075195,
                                51.586163175347814
                            ],
                            [
                                7.21951961517334,
                                51.58522989453506
                            ],
                            [
                                7.218232154846191,
                                51.58472324835147
                            ],
                            [
                                7.21574306488037,
                                51.584949906553014
                            ],
                            [
                                7.21299648284912,
                                51.58453658780709
                            ],
                            [
                                7.2097134590148935,
                                51.584043266962816
                            ],
                            [
                                7.204949855804443,
                                51.58381660423859
                            ],
                            [
                                7.196667194366455,
                                51.58366993952041
                            ],
                            [
                                7.1929121017456055,
                                51.583496607879475
                            ],
                            [
                                7.191452980041503,
                                51.58246993768066
                            ],
                            [
                                7.1907877922058105,
                                51.581256570266355
                            ],
                            [
                                7.191324234008789,
                                51.57874973058205
                            ],
                            [
                                7.192718982696533,
                                51.57401569343687
                            ],
                            [
                                7.193448543548584,
                                51.57128172908954
                            ],
                            [
                                7.195401191711425,
                                51.57108167646174
                            ],
                            [
                                7.195186614990234,
                                51.56929450059186
                            ],
                            [
                                7.193727493286133,
                                51.56814747010768
                            ],
                            [
                                7.193641662597657,
                                51.56575995338882
                            ],
                            [
                                7.194263935089111,
                                51.562131755016765
                            ],
                            [
                                7.1952080726623535,
                                51.55941041625067
                            ],
                            [
                                7.1966028213500985,
                                51.55615526777012
                            ],
                            [
                                7.197139263153076,
                                51.553913882566754
                            ],
                            [
                                7.197632789611816,
                                51.551192052046325
                            ],
                            [
                                7.198340892791748,
                                51.54834996697059
                            ],
                            [
                                7.199370861053467,
                                51.546121541350715
                            ],
                            [
                                7.2008514404296875,
                                51.545467671206744
                            ],
                            [
                                7.203812599182129,
                                51.545961410755694
                            ],
                            [
                                7.207760810852051,
                                51.54725578359836
                            ],
                            [
                                7.210593223571777,
                                51.54821653138285
                            ],
                            [
                                7.210807800292969,
                                51.54925731858867
                            ],
                            [
                                7.211880683898925,
                                51.54992447735129
                            ],
                            [
                                7.213532924652099,
                                51.549751017014195
                            ],
                            [
                                7.215292453765868,
                                51.54997784962962
                            ],
                            [
                                7.221214771270751,
                                51.55240622205599
                            ],
                            [
                                7.227072715759277,
                                51.55476775662006
                            ],
                            [
                                7.229926586151122,
                                51.55600851378473
                            ],
                            [
                                7.2295188903808585,
                                51.55671559681339
                            ],
                            [
                                7.230441570281982,
                                51.55706246371417
                            ],
                            [
                                7.232952117919922,
                                51.557395987085656
                            ],
                            [
                                7.237823009490966,
                                51.558903482213424
                            ],
                            [
                                7.241685390472411,
                                51.55939707595366
                            ],
                            [
                                7.245826721191406,
                                51.55949045795055
                            ],
                            [
                                7.2505903244018555,
                                51.55870337511689
                            ],
                            [
                                7.255311012268066,
                                51.5578095526696
                            ],
                            [
                                7.259087562561035,
                                51.557395987085656
                            ],
                            [
                                7.262091636657715,
                                51.557489373191395
                            ],
                            [
                                7.262263298034668,
                                51.55802300440285
                            ],
                            [
                                7.264130115509032,
                                51.55822311449297
                            ],
                            [
                                7.265996932983398,
                                51.55788959718698
                            ],
                            [
                                7.270116806030273,
                                51.55850326713993
                            ],
                            [
                                7.271876335144043,
                                51.558903482213424
                            ],
                            [
                                7.275910377502441,
                                51.559170290305815
                            ],
                            [
                                7.280073165893554,
                                51.55906356725669
                            ],
                            [
                                7.284965515136718,
                                51.55863667255574
                            ],
                            [
                                7.288269996643066,
                                51.55863667255574
                            ],
                            [
                                7.290329933166504,
                                51.55901020563821
                            ],
                            [
                                7.290930747985839,
                                51.55949045795055
                            ],
                            [
                                7.292089462280274,
                                51.55999738544502
                            ],
                            [
                                7.293591499328613,
                                51.55999738544502
                            ],
                            [
                                7.294921874999999,
                                51.559570499509555
                            ],
                            [
                                7.296295166015625,
                                51.5595971799979
                            ],
                            [
                                7.303762435913087,
                                51.56058434706352
                            ],
                            [
                                7.308011054992676,
                                51.561091262364656
                            ],
                            [
                                7.310628890991212,
                                51.56127801920914
                            ],
                            [
                                7.314190864562987,
                                51.56207839699836
                            ],
                            [
                                7.313847541809082,
                                51.56632016409988
                            ],
                            [
                                7.313976287841797,
                                51.567840701236186
                            ],
                            [
                                7.313976287841797,
                                51.57104166583054
                            ],
                            [
                                7.313675880432129,
                                51.57362894749008
                            ],
                            [
                                7.313032150268555,
                                51.57565599311817
                            ],
                            [
                                7.307324409484862,
                                51.57440243609308
                            ],
                            [
                                7.295780181884765,
                                51.571495117588825
                            ],
                            [
                                7.283720970153809,
                                51.56864096351415
                            ],
                            [
                                7.273807525634766,
                                51.56551986097108
                            ],
                            [
                                7.262520790100097,
                                51.560904504753225
                            ],
                            [
                                7.265868186950684,
                                51.56648022303528
                            ],
                            [
                                7.264065742492676,
                                51.56850758744603
                            ],
                            [
                                7.256340980529785,
                                51.56562656886882
                            ],
                            [
                                7.249002456665038,
                                51.564692866272466
                            ],
                            [
                                7.247028350830077,
                                51.56722715728344
                            ],
                            [
                                7.246856689453125,
                                51.56896106448118
                            ],
                            [
                                7.243294715881347,
                                51.57034814262767
                            ],
                            [
                                7.241964340209961,
                                51.571175034464275
                            ],
                            [
                                7.242822647094727,
                                51.57258871792618
                            ],
                            [
                                7.241792678833008,
                                51.57322886201344
                            ],
                            [
                                7.236943244934082,
                                51.57320218952314
                            ],
                            [
                                7.23599910736084,
                                51.574695824878624
                            ],
                            [
                                7.235054969787598,
                                51.57696285618527
                            ],
                            [
                                7.234325408935546,
                                51.57930978123068
                            ],
                            [
                                7.234067916870117,
                                51.581923259669075
                            ],
                            [
                                7.234454154968261,
                                51.58562987437363
                            ],
                            [
                                7.234282493591308,
                                51.58861627920463
                            ],
                            [
                                7.234025001525879,
                                51.59112257460339
                            ]
                        ],
                        [
                            [
                                7.313504219055177,
                                51.56051764726113
                            ],
                            [
                                7.310285568237304,
                                51.560464287348765
                            ],
                            [
                                7.306981086730957,
                                51.56015746663773
                            ],
                            [
                                7.302002906799316,
                                51.559583839755696
                            ],
                            [
                                7.297754287719726,
                                51.55910358842945
                            ],
                            [
                                7.293226718902587,
                                51.55835652073055
                            ],
                            [
                                7.29341983795166,
                                51.55756941827226
                            ],
                            [
                                7.2910380363464355,
                                51.55754273659428
                            ],
                            [
                                7.289643287658691,
                                51.55799632299099
                            ],
                            [
                                7.286553382873535,
                                51.55783623419105
                            ],
                            [
                                7.282583713531493,
                                51.55802300440285
                            ],
                            [
                                7.277712821960449,
                                51.558289817660686
                            ],
                            [
                                7.273313999176025,
                                51.55823645513432
                            ],
                            [
                                7.26722002029419,
                                51.55742266884972
                            ],
                            [
                                7.263507843017577,
                                51.55686234851733
                            ],
                            [
                                7.26370096206665,
                                51.556275338860495
                            ],
                            [
                                7.260074615478516,
                                51.55612858526253
                            ],
                            [
                                7.259087562561035,
                                51.556662232440004
                            ],
                            [
                                7.256619930267334,
                                51.5568223253723
                            ],
                            [
                                7.253894805908202,
                                51.55719587335627
                            ],
                            [
                                7.2502899169921875,
                                51.558063026491325
                            ],
                            [
                                7.247114181518554,
                                51.558596650972056
                            ],
                            [
                                7.243380546569825,
                                51.55879675853819
                            ],
                            [
                                7.239775657653809,
                                51.55844990486401
                            ],
                            [
                                7.236578464508057,
                                51.55783623419105
                            ],
                            [
                                7.233767509460449,
                                51.55671559681339
                            ],
                            [
                                7.233252525329589,
                                51.55558159040764
                            ],
                            [
                                7.231450080871581,
                                51.55475441496124
                            ],
                            [
                                7.230656147003174,
                                51.55538146869522
                            ],
                            [
                                7.228188514709473,
                                51.554380846925675
                            ],
                            [
                                7.222094535827637,
                                51.55185918243649
                            ],
                            [
                                7.21623659133911,
                                51.54956421283458
                            ],
                            [
                                7.2138118743896475,
                                51.548336623429414
                            ],
                            [
                                7.212932109832764,
                                51.54774950374384
                            ],
                            [
                                7.211043834686279,
                                51.54742925344918
                            ],
                            [
                                7.208554744720458,
                                51.54673537007755
                            ],
                            [
                                7.2050142288208,
                                51.54545432682025
                            ],
                            [
                                7.203340530395507,
                                51.54446683135954
                            ],
                            [
                                7.20278263092041,
                                51.54303892840376
                            ],
                            [
                                7.203083038330077,
                                51.54182450838865
                            ],
                            [
                                7.20552921295166,
                                51.53915533952069
                            ],
                            [
                                7.20679521560669,
                                51.53718005377033
                            ],
                            [
                                7.207739353179931,
                                51.534844301220026
                            ],
                            [
                                7.2081685066223145,
                                51.53261521415877
                            ],
                            [
                                7.210164070129394,
                                51.53240164320126
                            ],
                            [
                                7.209091186523437,
                                51.53074643430678
                            ],
                            [
                                7.208254337310791,
                                51.53015908765206
                            ],
                            [
                                7.207953929901123,
                                51.529024419246404
                            ],
                            [
                                7.208297252655029,
                                51.52680839705218
                            ],
                            [
                                7.2087907791137695,
                                51.52500613080135
                            ],
                            [
                                7.209799289703369,
                                51.5230435819753
                            ],
                            [
                                7.211258411407471,
                                51.5199594057816
                            ],
                            [
                                7.212417125701903,
                                51.51787646733483
                            ],
                            [
                                7.213318347930907,
                                51.51739577570335
                            ],
                            [
                                7.215442657470703,
                                51.516808256819814
                            ],
                            [
                                7.21524953842163,
                                51.515740021251936
                            ],
                            [
                                7.215700149536132,
                                51.514872061405725
                            ],
                            [
                                7.217695713043213,
                                51.513777073083524
                            ],
                            [
                                7.220742702484131,
                                51.51272212041476
                            ],
                            [
                                7.228531837463378,
                                51.511092905004745
                            ],
                            [
                                7.23602056503296,
                                51.509463631328444
                            ],
                            [
                                7.24320888519287,
                                51.507914431826606
                            ],
                            [
                                7.247414588928223,
                                51.50701961156305
                            ],
                            [
                                7.251749038696288,
                                51.505964502406805
                            ],
                            [
                                7.25571870803833,
                                51.50484258738895
                            ],
                            [
                                7.258787155151366,
                                51.50392099367386
                            ],
                            [
                                7.259430885314941,
                                51.50454874793857
                            ],
                            [
                                7.261040210723876,
                                51.50396106335338
                            ],
                            [
                                7.261834144592286,
                                51.50281238523426
                            ],
                            [
                                7.263936996459961,
                                51.501797250237836
                            ],
                            [
                                7.267327308654785,
                                51.49987377454688
                            ],
                            [
                                7.269515991210937,
                                51.49849790532775
                            ],
                            [
                                7.271876335144043,
                                51.498404398145375
                            ],
                            [
                                7.274408340454101,
                                51.4974960326758
                            ],
                            [
                                7.279150485992432,
                                51.497375806477635
                            ],
                            [
                                7.285308837890626,
                                51.4972422214411
                            ],
                            [
                                7.288784980773925,
                                51.49714871168257
                            ],
                            [
                                7.288784980773925,
                                51.49813723371005
                            ],
                            [
                                7.290952205657959,
                                51.49805708407408
                            ],
                            [
                                7.292647361755371,
                                51.4972422214411
                            ],
                            [
                                7.296702861785889,
                                51.497041843152175
                            ],
                            [
                                7.303762435913087,
                                51.49689489851378
                            ],
                            [
                                7.31065034866333,
                                51.496654442629094
                            ],
                            [
                                7.3171305656433105,
                                51.49652085547838
                            ],
                            [
                                7.321314811706544,
                                51.496734594731606
                            ],
                            [
                                7.324662208557129,
                                51.49900551239905
                            ],
                            [
                                7.325928211212158,
                                51.49947304022636
                            ],
                            [
                                7.326207160949708,
                                51.49864484479821
                            ],
                            [
                                7.324361801147461,
                                51.49788342604594
                            ],
                            [
                                7.323589324951172,
                                51.49694833298257
                            ],
                            [
                                7.326486110687255,
                                51.49696169158996
                            ],
                            [
                                7.329039573669433,
                                51.497175428775996
                            ],
                            [
                                7.331142425537109,
                                51.4972956555027
                            ],
                            [
                                7.3333740234375,
                                51.49713535312996
                            ],
                            [
                                7.335691452026366,
                                51.4963605503807
                            ],
                            [
                                7.338974475860595,
                                51.49550558033815
                            ],
                            [
                                7.343158721923828,
                                51.494303251583524
                            ],
                            [
                                7.348587512969971,
                                51.493421523674584
                            ],
                            [
                                7.353050708770752,
                                51.4930474521365
                            ],
                            [
                                7.3569560050964355,
                                51.49314097030879
                            ],
                            [
                                7.360432147979735,
                                51.4938623897609
                            ],
                            [
                                7.362256050109864,
                                51.494663953539806
                            ],
                            [
                                7.363114356994628,
                                51.49618688588868
                            ],
                            [
                                7.363693714141847,
                                51.496814746693175
                            ],
                            [
                                7.365174293518067,
                                51.49628039762045
                            ],
                            [
                                7.366912364959717,
                                51.49628039762045
                            ],
                            [
                                7.369787693023682,
                                51.49712199457346
                            ],
                            [
                                7.374079227447509,
                                51.497910142708726
                            ],
                            [
                                7.378370761871339,
                                51.498190666722394
                            ],
                            [
                                7.381589412689209,
                                51.498404398145375
                            ],
                            [
                                7.382469177246093,
                                51.49909901834819
                            ],
                            [
                                7.383606433868408,
                                51.49975355462072
                            ],
                            [
                                7.384786605834961,
                                51.50074202015363
                            ],
                            [
                                7.3853230476379395,
                                51.5032665172538
                            ],
                            [
                                7.387340068817139,
                                51.506485382132446
                            ],
                            [
                                7.389035224914551,
                                51.50919653172424
                            ],
                            [
                                7.389378547668456,
                                51.51198764527055
                            ],
                            [
                                7.388498783111572,
                                51.51467176063095
                            ],
                            [
                                7.3871684074401855,
                                51.51647443589609
                            ],
                            [
                                7.385151386260987,
                                51.51698184273328
                            ],
                            [
                                7.382748126983643,
                                51.51726224934983
                            ],
                            [
                                7.3790574073791495,
                                51.5167014343904
                            ],
                            [
                                7.376954555511475,
                                51.51664802308175
                            ],
                            [
                                7.375967502593994,
                                51.51578008053786
                            ],
                            [
                                7.37382173538208,
                                51.51578008053786
                            ],
                            [
                                7.37257719039917,
                                51.516487788780005
                            ],
                            [
                                7.372169494628906,
                                51.518370506225146
                            ],
                            [
                                7.3741865158081055,
                                51.51831709687373
                            ],
                            [
                                7.376418113708496,
                                51.517302307296994
                            ],
                            [
                                7.380666732788085,
                                51.51775629490259
                            ],
                            [
                                7.3821043968200675,
                                51.518236982729185
                            ],
                            [
                                7.38231897354126,
                                51.51925175148097
                            ],
                            [
                                7.382061481475829,
                                51.520173035107824
                            ],
                            [
                                7.38006591796875,
                                51.52158830408364
                            ],
                            [
                                7.378563880920409,
                                51.523323951271905
                            ],
                            [
                                7.3775553703308105,
                                51.52464566899077
                            ],
                            [
                                7.3767614364624015,
                                51.52690184594944
                            ],
                            [
                                7.37607479095459,
                                51.52926470432782
                            ],
                            [
                                7.374551296234131,
                                51.53173422750091
                            ],
                            [
                                7.372534275054932,
                                51.533709749589526
                            ],
                            [
                                7.366526126861571,
                                51.53182766628804
                            ],
                            [
                                7.365238666534424,
                                51.530546203343974
                            ],
                            [
                                7.365968227386475,
                                51.52950498814106
                            ],
                            [
                                7.363221645355225,
                                51.527822974816694
                            ],
                            [
                                7.361698150634766,
                                51.52826350812219
                            ],
                            [
                                7.359895706176758,
                                51.527822974816694
                            ],
                            [
                                7.358522415161133,
                                51.52706204361282
                            ],
                            [
                                7.355539798736571,
                                51.52795647020787
                            ],
                            [
                                7.354123592376709,
                                51.52909116522955
                            ],
                            [
                                7.353372573852538,
                                51.52975861967921
                            ],
                            [
                                7.351226806640624,
                                51.530919967095535
                            ],
                            [
                                7.350304126739502,
                                51.53112019641442
                            ],
                            [
                                7.347450256347655,
                                51.53157404627683
                            ],
                            [
                                7.346248626708984,
                                51.53196114993694
                            ],
                            [
                                7.343223094940186,
                                51.53369640175498
                            ],
                            [
                                7.340970039367676,
                                51.5349911234821
                            ],
                            [
                                7.339382171630859,
                                51.536539402143234
                            ],
                            [
                                7.338116168975829,
                                51.538074281588315
                            ],
                            [
                                7.335948944091798,
                                51.540036182553806
                            ],
                            [
                                7.3339104652404785,
                                51.54158428960126
                            ],
                            [
                                7.332279682159423,
                                51.542131452771656
                            ],
                            [
                                7.330498695373535,
                                51.542892132101954
                            ],
                            [
                                7.327558994293212,
                                51.5446536564688
                            ],
                            [
                                7.3247480392456055,
                                51.54608150875478
                            ],
                            [
                                7.3213791847229,
                                51.547175720367186
                            ],
                            [
                                7.32013463973999,
                                51.54754934757382
                            ],
                            [
                                7.3181819915771475,
                                51.54742925344918
                            ],
                            [
                                7.3180317878723145,
                                51.54805640816096
                            ],
                            [
                                7.316722869873047,
                                51.54867021078447
                            ],
                            [
                                7.314920425415039,
                                51.549430780808116
                            ],
                            [
                                7.313117980957031,
                                51.55088516878154
                            ],
                            [
                                7.311336994171143,
                                51.55275312182076
                            ],
                            [
                                7.311036586761474,
                                51.553673727601215
                            ],
                            [
                                7.3113155364990225,
                                51.5546209981579
                            ],
                            [
                                7.312281131744385,
                                51.555621614644494
                            ],
                            [
                                7.313354015350342,
                                51.5577161672213
                            ],
                            [
                                7.313740253448485,
                                51.55949045795055
                            ],
                            [
                                7.313504219055177,
                                51.56051764726113
                            ]
                        ],
                        [
                            [
                                7.381010055541992,
                                51.49730901400831
                            ],
                            [
                                7.3795080184936515,
                                51.49736244799161
                            ],
                            [
                                7.3752379417419425,
                                51.49722886291592
                            ],
                            [
                                7.37079620361328,
                                51.49661436652498
                            ],
                            [
                                7.368822097778319,
                                51.4960666562374
                            ],
                            [
                                7.3683714866638175,
                                51.49435668909045
                            ],
                            [
                                7.367770671844482,
                                51.49404942257001
                            ],
                            [
                                7.365903854370116,
                                51.495038011813065
                            ],
                            [
                                7.364294528961182,
                                51.49426317341218
                            ],
                            [
                                7.360410690307617,
                                51.49291385441472
                            ],
                            [
                                7.358350753784181,
                                51.49237945961231
                            ],
                            [
                                7.354788780212402,
                                51.49224585993291
                            ],
                            [
                                7.350218296051025,
                                51.49239281955874
                            ],
                            [
                                7.346913814544677,
                                51.49288713482338
                            ],
                            [
                                7.342922687530517,
                                51.493541760302776
                            ],
                            [
                                7.33910322189331,
                                51.494396767179566
                            ],
                            [
                                7.33609914779663,
                                51.49547886226591
                            ],
                            [
                                7.333009243011475,
                                51.4964540617562
                            ],
                            [
                                7.330799102783202,
                                51.49669451869797
                            ],
                            [
                                7.327687740325929,
                                51.49644070300001
                            ],
                            [
                                7.324061393737792,
                                51.49603993849402
                            ],
                            [
                                7.323181629180908,
                                51.49570596538067
                            ],
                            [
                                7.323396205902099,
                                51.494704031358744
                            ],
                            [
                                7.322752475738526,
                                51.49450364191158
                            ],
                            [
                                7.321121692657471,
                                51.4958128370432
                            ],
                            [
                                7.316594123840332,
                                51.49577276019911
                            ],
                            [
                                7.312602996826172,
                                51.4958128370432
                            ],
                            [
                                7.305757999420166,
                                51.49609337396509
                            ],
                            [
                                7.299964427947998,
                                51.49629375642361
                            ],
                            [
                                7.294750213623046,
                                51.4963739091604
                            ],
                            [
                                7.2922396659851065,
                                51.49618688588868
                            ],
                            [
                                7.291574478149414,
                                51.495131525901776
                            ],
                            [
                                7.2904157638549805,
                                51.494663953539806
                            ],
                            [
                                7.289557456970215,
                                51.49445020457691
                            ],
                            [
                                7.2888708114624015,
                                51.49546550322391
                            ],
                            [
                                7.28715419769287,
                                51.49565252945548
                            ],
                            [
                                7.28618860244751,
                                51.49628039762045
                            ],
                            [
                                7.284815311431886,
                                51.49653421421106
                            ],
                            [
                                7.279000282287598,
                                51.4965609316647
                            ],
                            [
                                7.2760820388793945,
                                51.496494138001246
                            ],
                            [
                                7.274944782257079,
                                51.49608001510318
                            ],
                            [
                                7.274580001831056,
                                51.49525175801964
                            ],
                            [
                                7.275545597076416,
                                51.49327456736498
                            ],
                            [
                                7.277197837829589,
                                51.49065599369589
                            ],
                            [
                                7.278163433074951,
                                51.48798382470047
                            ],
                            [
                                7.278463840484619,
                                51.486233469120386
                            ],
                            [
                                7.279129028320312,
                                51.48239848577242
                            ],
                            [
                                7.279772758483887,
                                51.480661279169546
                            ],
                            [
                                7.281296253204346,
                                51.4803806472793
                            ],
                            [
                                7.282648086547852,
                                51.479324921368146
                            ],
                            [
                                7.280716896057129,
                                51.47809543760746
                            ],
                            [
                                7.284300327301025,
                                51.473658329298566
                            ],
                            [
                                7.286725044250488,
                                51.47026338272035
                            ],
                            [
                                7.288269996643066,
                                51.46701522491937
                            ],
                            [
                                7.288849353790283,
                                51.46484965791875
                            ],
                            [
                                7.289342880249023,
                                51.46352620526822
                            ],
                            [
                                7.290565967559814,
                                51.46321873097623
                            ],
                            [
                                7.291531562805176,
                                51.462175976647
                            ],
                            [
                                7.292883396148682,
                                51.461828386575846
                            ],
                            [
                                7.298741340637206,
                                51.46202891963228
                            ],
                            [
                                7.3009514808654785,
                                51.46197544423673
                            ],
                            [
                                7.303526401519775,
                                51.46169469738204
                            ],
                            [
                                7.306509017944335,
                                51.461213413041584
                            ],
                            [
                                7.31288194656372,
                                51.45990322440063
                            ],
                            [
                                7.317538261413574,
                                51.45899409181147
                            ],
                            [
                                7.320306301116943,
                                51.45872669642711
                            ],
                            [
                                7.320692539215087,
                                51.459462029964634
                            ],
                            [
                                7.321979999542236,
                                51.45927485527908
                            ],
                            [
                                7.321722507476806,
                                51.45839244999374
                            ],
                            [
                                7.32410430908203,
                                51.45791113082951
                            ],
                            [
                                7.329254150390625,
                                51.457563508276515
                            ],
                            [
                                7.334554195404053,
                                51.45784428054414
                            ],
                            [
                                7.336764335632323,
                                51.45800472106449
                            ],
                            [
                                7.341399192810059,
                                51.45848603924181
                            ],
                            [
                                7.343673706054687,
                                51.45871332661679
                            ],
                            [
                                7.3457980155944815,
                                51.4614005797771
                            ],
                            [
                                7.347879409790039,
                                51.4641946204951
                            ],
                            [
                                7.3491668701171875,
                                51.46553142157758
                            ],
                            [
                                7.349402904510498,
                                51.46780389354294
                            ],
                            [
                                7.348759174346923,
                                51.47184059404813
                            ],
                            [
                                7.350153923034668,
                                51.472615813527405
                            ],
                            [
                                7.348759174346923,
                                51.47631797314678
                            ],
                            [
                                7.3473429679870605,
                                51.48022028542385
                            ],
                            [
                                7.346742153167725,
                                51.481289353809544
                            ],
                            [
                                7.348158359527589,
                                51.481850604680176
                            ],
                            [
                                7.351140975952148,
                                51.48253211430862
                            ],
                            [
                                7.355668544769286,
                                51.482104501614444
                            ],
                            [
                                7.359917163848877,
                                51.481743700284966
                            ],
                            [
                                7.362899780273437,
                                51.482826095710024
                            ],
                            [
                                7.369358539581298,
                                51.4838817405756
                            ],
                            [
                                7.371182441711426,
                                51.48266574245327
                            ],
                            [
                                7.3726415634155265,
                                51.48039401074178
                            ],
                            [
                                7.371633052825928,
                                51.47948528637178
                            ],
                            [
                                7.3709893226623535,
                                51.478015252732696
                            ],
                            [
                                7.371697425842284,
                                51.47657190088194
                            ],
                            [
                                7.377490997314452,
                                51.477988524409795
                            ],
                            [
                                7.382340431213378,
                                51.4774806832986
                            ],
                            [
                                7.3836493492126465,
                                51.476665452849105
                            ],
                            [
                                7.385494709014893,
                                51.4772668537707
                            ],
                            [
                                7.391009330749512,
                                51.47925810245022
                            ],
                            [
                                7.389335632324218,
                                51.47967237816338
                            ],
                            [
                                7.390365600585937,
                                51.48100872578405
                            ],
                            [
                                7.389421463012694,
                                51.48533822311957
                            ],
                            [
                                7.387876510620117,
                                51.487796767005534
                            ],
                            [
                                7.385301589965819,
                                51.49137745247314
                            ],
                            [
                                7.384271621704102,
                                51.495011293466746
                            ],
                            [
                                7.381010055541992,
                                51.49730901400831
                            ]
                        ],
                        [
                            [
                                7.37356424331665,
                                51.533990053210786
                            ],
                            [
                                7.375495433807373,
                                51.5322147677915
                            ],
                            [
                                7.376954555511475,
                                51.529932156233286
                            ],
                            [
                                7.377705574035644,
                                51.5273824372487
                            ],
                            [
                                7.378907203674316,
                                51.52459226699835
                            ],
                            [
                                7.380516529083252,
                                51.5228700191647
                            ],
                            [
                                7.383584976196288,
                                51.52062699409883
                            ],
                            [
                                7.3856449127197275,
                                51.520653697427974
                            ],
                            [
                                7.387661933898926,
                                51.520359959946255
                            ],
                            [
                                7.39030122756958,
                                51.52141473572644
                            ],
                            [
                                7.393348217010498,
                                51.52226921971215
                            ],
                            [
                                7.39701747894287,
                                51.52293677417012
                            ],
                            [
                                7.4019527435302725,
                                51.52331060039215
                            ],
                            [
                                7.407960891723632,
                                51.523510863177314
                            ],
                            [
                                7.41602897644043,
                                51.52295012515947
                            ],
                            [
                                7.4207282066345215,
                                51.5228700191647
                            ],
                            [
                                7.422337532043456,
                                51.52379122959692
                            ],
                            [
                                7.424118518829347,
                                51.52389803539794
                            ],
                            [
                                7.4245262145996085,
                                51.5230569329333
                            ],
                            [
                                7.429203987121581,
                                51.523817931070646
                            ],
                            [
                                7.432336807250976,
                                51.52436530783196
                            ],
                            [
                                7.4298906326293945,
                                51.526341149688875
                            ],
                            [
                                7.426800727844238,
                                51.52939819549174
                            ],
                            [
                                7.423582077026366,
                                51.532708651137604
                            ],
                            [
                                7.425019741058349,
                                51.53306904911657
                            ],
                            [
                                7.4260711669921875,
                                51.53306904911657
                            ],
                            [
                                7.425363063812256,
                                51.53392331440988
                            ],
                            [
                                7.424354553222655,
                                51.534430526842534
                            ],
                            [
                                7.423067092895508,
                                51.53633919666044
                            ],
                            [
                                7.421307563781738,
                                51.53786073624356
                            ],
                            [
                                7.420942783355712,
                                51.539368878791194
                            ],
                            [
                                7.420449256896972,
                                51.54090366281743
                            ],
                            [
                                7.422187328338624,
                                51.54133072394998
                            ],
                            [
                                7.424118518829347,
                                51.54141079746618
                            ],
                            [
                                7.426285743713378,
                                51.541357415137725
                            ],
                            [
                                7.428216934204101,
                                51.54103711985185
                            ],
                            [
                                7.429976463317871,
                                51.54121061341155
                            ],
                            [
                                7.433130741119385,
                                51.541851199286754
                            ],
                            [
                                7.434439659118652,
                                51.54210476203792
                            ],
                            [
                                7.435255050659179,
                                51.54581462388187
                            ],
                            [
                                7.436306476593018,
                                51.55069836925895
                            ],
                            [
                                7.436842918395996,
                                51.552619699147364
                            ],
                            [
                                7.43997573852539,
                                51.55239287970452
                            ],
                            [
                                7.4440526962280265,
                                51.55172575714114
                            ],
                            [
                                7.443666458129883,
                                51.552099346981954
                            ],
                            [
                                7.442293167114258,
                                51.552619699147364
                            ],
                            [
                                7.441821098327636,
                                51.55358033366111
                            ],
                            [
                                7.441821098327636,
                                51.5546877066085
                            ],
                            [
                                7.441992759704589,
                                51.55558159040764
                            ],
                            [
                                7.43748664855957,
                                51.55530141976372
                            ],
                            [
                                7.4341607093811035,
                                51.555034588974564
                            ],
                            [
                                7.426500320434569,
                                51.55482112321619
                            ],
                            [
                                7.424719333648682,
                                51.555168004564784
                            ],
                            [
                                7.424182891845703,
                                51.55434082159703
                            ],
                            [
                                7.425234317779541,
                                51.553180071745786
                            ],
                            [
                                7.4257493019103995,
                                51.55175244223153
                            ],
                            [
                                7.4187541007995605,
                                51.55091185436501
                            ],
                            [
                                7.413969039916992,
                                51.55028473901504
                            ],
                            [
                                7.4086689949035645,
                                51.54921728875178
                            ],
                            [
                                7.402553558349609,
                                51.54688215398097
                            ],
                            [
                                7.405622005462647,
                                51.54369283344949
                            ],
                            [
                                7.400836944580078,
                                51.542571847617985
                            ],
                            [
                                7.401866912841796,
                                51.53996945261782
                            ],
                            [
                                7.385215759277344,
                                51.538314518903526
                            ],
                            [
                                7.378242015838622,
                                51.534844301220026
                            ],
                            [
                                7.37356424331665,
                                51.533990053210786
                            ]
                        ],
                        [
                            [
                                7.445168495178223,
                                51.55169907203511
                            ],
                            [
                                7.445812225341796,
                                51.5499111342719
                            ],
                            [
                                7.446498870849609,
                                51.54845671515902
                            ],
                            [
                                7.44701385498047,
                                51.5477695193124
                            ],
                            [
                                7.4475932121276855,
                                51.547515988126555
                            ],
                            [
                                7.4487411975860605,
                                51.54718906424884
                            ],
                            [
                                7.4494707584381095,
                                51.54696221772871
                            ],
                            [
                                7.449975013732911,
                                51.546641961892455
                            ],
                            [
                                7.450436353683472,
                                51.545847984576596
                            ],
                            [
                                7.451122999191283,
                                51.54455357168423
                            ],
                            [
                                7.45144486427307,
                                51.54381293743644
                            ],
                            [
                                7.452507019042968,
                                51.54229826950278
                            ],
                            [
                                7.45322585105896,
                                51.541297359943336
                            ],
                            [
                                7.453987598419189,
                                51.53994943361797
                            ],
                            [
                                7.452582120895386,
                                51.539695858857435
                            ],
                            [
                                7.447679042816162,
                                51.53948899419056
                            ],
                            [
                                7.445061206817627,
                                51.539335513346224
                            ],
                            [
                                7.444599866867065,
                                51.540536653954696
                            ],
                            [
                                7.443387508392333,
                                51.540509962285576
                            ],
                            [
                                7.440834045410155,
                                51.54098373708504
                            ],
                            [
                                7.438870668411254,
                                51.541390779100354
                            ],
                            [
                                7.436381578445435,
                                51.54105713837327
                            ],
                            [
                                7.435802221298218,
                                51.54059671015294
                            ],
                            [
                                7.435019016265869,
                                51.53979595432643
                            ],
                            [
                                7.4345147609710684,
                                51.53868154568654
                            ],
                            [
                                7.435019016265869,
                                51.537467009387484
                            ],
                            [
                                7.436617612838745,
                                51.536512708129735
                            ],
                            [
                                7.44024395942688,
                                51.53595213023
                            ],
                            [
                                7.444524765014648,
                                51.534844301220026
                            ],
                            [
                                7.446337938308716,
                                51.53413020437412
                            ],
                            [
                                7.446777820587158,
                                51.531520652410194
                            ],
                            [
                                7.447528839111328,
                                51.52712879247287
                            ],
                            [
                                7.447657585144042,
                                51.526381199650764
                            ],
                            [
                                7.44701385498047,
                                51.526247699640834
                            ],
                            [
                                7.4448251724243155,
                                51.52483257547096
                            ],
                            [
                                7.444310188293457,
                                51.52439200896907
                            ],
                            [
                                7.442765235900878,
                                51.52436530783196
                            ],
                            [
                                7.442936897277831,
                                51.52347081069075
                            ],
                            [
                                7.442421913146973,
                                51.52343075816895
                            ],
                            [
                                7.441091537475585,
                                51.52361766963595
                            ],
                            [
                                7.4399328231811515,
                                51.5235776172433
                            ],
                            [
                                7.440361976623535,
                                51.52128122115537
                            ],
                            [
                                7.440683841705323,
                                51.520640345765365
                            ],
                            [
                                7.440919876098632,
                                51.51966566382181
                            ],
                            [
                                7.441155910491943,
                                51.518624199789016
                            ],
                            [
                                7.4433016777038565,
                                51.51879777878167
                            ],
                            [
                                7.443666458129883,
                                51.51871766548264
                            ],
                            [
                                7.444031238555908,
                                51.51732901257553
                            ],
                            [
                                7.446649074554443,
                                51.51640767141776
                            ],
                            [
                                7.446949481964111,
                                51.516033788530564
                            ],
                            [
                                7.446391582489013,
                                51.51599372946776
                            ],
                            [
                                7.4440956115722665,
                                51.51634090684156
                            ],
                            [
                                7.441349029541016,
                                51.51684831516626
                            ],
                            [
                                7.440297603607178,
                                51.51708866450503
                            ],
                            [
                                7.438066005706787,
                                51.51779635241522
                            ],
                            [
                                7.436800003051758,
                                51.51814351604911
                            ],
                            [
                                7.435855865478516,
                                51.51752930166541
                            ],
                            [
                                7.434203624725342,
                                51.51662131740394
                            ],
                            [
                                7.432873249053956,
                                51.5161806714594
                            ],
                            [
                                7.432444095611572,
                                51.51694178450429
                            ],
                            [
                                7.4315643310546875,
                                51.51783640989263
                            ],
                            [
                                7.42750883102417,
                                51.5221090051861
                            ],
                            [
                                7.424794435501099,
                                51.5213413027608
                            ],
                            [
                                7.422723770141602,
                                51.521221139470676
                            ],
                            [
                                7.421103715896606,
                                51.521875357991895
                            ],
                            [
                                7.418861389160156,
                                51.52214572106478
                            ],
                            [
                                7.413679361343385,
                                51.522342651181546
                            ],
                            [
                                7.409999370574951,
                                51.52260299816437
                            ],
                            [
                                7.404892444610596,
                                51.522736508860255
                            ],
                            [
                                7.4005043506622314,
                                51.522609673708466
                            ],
                            [
                                7.397371530532837,
                                51.522352664554575
                            ],
                            [
                                7.395375967025756,
                                51.52200553300516
                            ],
                            [
                                7.393004894256592,
                                51.52140138428695
                            ],
                            [
                                7.390741109848023,
                                51.52056023570747
                            ],
                            [
                                7.389174699783324,
                                51.51979918312931
                            ],
                            [
                                7.388809919357301,
                                51.51889792020728
                            ],
                            [
                                7.38827347755432,
                                51.51779635241522
                            ],
                            [
                                7.388509511947631,
                                51.516808256819814
                            ],
                            [
                                7.389818429946899,
                                51.51509906788574
                            ],
                            [
                                7.390623092651367,
                                51.5130559688384
                            ],
                            [
                                7.390751838684082,
                                51.510966037425156
                            ],
                            [
                                7.390397787094115,
                                51.50870239332834
                            ],
                            [
                                7.389657497406005,
                                51.50699290024027
                            ],
                            [
                                7.387855052947997,
                                51.50404788087151
                            ],
                            [
                                7.386728525161743,
                                51.501997607620325
                            ],
                            [
                                7.3863208293914795,
                                51.50014092880303
                            ],
                            [
                                7.38777995109558,
                                51.49931274551157
                            ],
                            [
                                7.388520240783692,
                                51.49849790532775
                            ],
                            [
                                7.38978624343872,
                                51.49760957934952
                            ],
                            [
                                7.397199869155884,
                                51.49667448066795
                            ],
                            [
                                7.399216890335083,
                                51.49686150193899
                            ],
                            [
                                7.401019334793092,
                                51.497375806477635
                            ],
                            [
                                7.402564287185668,
                                51.496033259055736
                            ],
                            [
                                7.4057936668396,
                                51.495866272780496
                            ],
                            [
                                7.4080896377563485,
                                51.49597982351421
                            ],
                            [
                                7.4153852462768555,
                                51.496934974371236
                            ],
                            [
                                7.420116662979125,
                                51.497542787222756
                            ],
                            [
                                7.423313856124878,
                                51.497976934297135
                            ],
                            [
                                7.4257493019103995,
                                51.498157271097014
                            ],
                            [
                                7.425845861434937,
                                51.499225918972336
                            ],
                            [
                                7.426553964614868,
                                51.49934614029031
                            ],
                            [
                                7.427841424942017,
                                51.499052265397616
                            ],
                            [
                                7.428882122039794,
                                51.49825745789973
                            ],
                            [
                                7.434589862823485,
                                51.49803036749742
                            ],
                            [
                                7.441917657852174,
                                51.49777655923821
                            ],
                            [
                                7.4456727504730225,
                                51.49774316330941
                            ],
                            [
                                7.445951700210571,
                                51.498471189009514
                            ],
                            [
                                7.450286149978638,
                                51.49855133791729
                            ],
                            [
                                7.4502646923065186,
                                51.49603993849402
                            ],
                            [
                                7.446016073226928,
                                51.49595310571996
                            ],
                            [
                                7.446080446243286,
                                51.497081918880454
                            ],
                            [
                                7.440061569213866,
                                51.49716207023123
                            ],
                            [
                                7.434579133987427,
                                51.49742924038262
                            ],
                            [
                                7.429021596908569,
                                51.49765633378002
                            ],
                            [
                                7.426650524139404,
                                51.497636296172814
                            ],
                            [
                                7.426457405090331,
                                51.49638726793613
                            ],
                            [
                                7.425781488418579,
                                51.496427344239926
                            ],
                            [
                                7.424461841583252,
                                51.49669451869797
                            ],
                            [
                                7.423571348190307,
                                51.49728897624843
                            ],
                            [
                                7.422509193420409,
                                51.4972956555027
                            ],
                            [
                                7.41474151611328,
                                51.49625368000238
                            ],
                            [
                                7.409881353378295,
                                51.49558573446086
                            ],
                            [
                                7.407102584838868,
                                51.495298514868715
                            ],
                            [
                                7.404913902282714,
                                51.49518496243772
                            ],
                            [
                                7.404227256774902,
                                51.494837623834115
                            ],
                            [
                                7.40351915359497,
                                51.494456884247164
                            ],
                            [
                                7.401416301727294,
                                51.49438340782045
                            ],
                            [
                                7.401051521301269,
                                51.49550558033815
                            ],
                            [
                                7.397832870483398,
                                51.49592638791004
                            ],
                            [
                                7.3936378955841064,
                                51.49643402362048
                            ],
                            [
                                7.390505075454712,
                                51.49670119803936
                            ],
                            [
                                7.3883163928985605,
                                51.49687486057185
                            ],
                            [
                                7.386900186538696,
                                51.49589299062563
                            ],
                            [
                                7.386492490768432,
                                51.49485098306011
                            ],
                            [
                                7.38727569580078,
                                51.49260657816879
                            ],
                            [
                                7.3886168003082275,
                                51.49051570871896
                            ],
                            [
                                7.389764785766602,
                                51.48873204780603
                            ],
                            [
                                7.391620874404907,
                                51.485278094026924
                            ],
                            [
                                7.391921281814574,
                                51.48299312975285
                            ],
                            [
                                7.392404079437256,
                                51.480941909332984
                            ],
                            [
                                7.393648624420165,
                                51.48066796086017
                            ],
                            [
                                7.394378185272217,
                                51.47909773664776
                            ],
                            [
                                7.393444776535033,
                                51.477995206492004
                            ],
                            [
                                7.393852472305297,
                                51.476986200994055
                            ],
                            [
                                7.396395206451416,
                                51.47433327872688
                            ],
                            [
                                7.4001073837280265,
                                51.471559907884995
                            ],
                            [
                                7.403841018676757,
                                51.469220789366545
                            ],
                            [
                                7.407585382461547,
                                51.46717563320137
                            ],
                            [
                                7.411404848098754,
                                51.46547126637037
                            ],
                            [
                                7.413893938064575,
                                51.46553142157758
                            ],
                            [
                                7.416715621948241,
                                51.46360641561238
                            ],
                            [
                                7.415910959243774,
                                51.46299815031666
                            ],
                            [
                                7.418453693389893,
                                51.46092263462639
                            ],
                            [
                                7.4192798137664795,
                                51.460056972974726
                            ],
                            [
                                7.419826984405518,
                                51.45944531797033
                            ],
                            [
                                7.420384883880615,
                                51.45946537236276
                            ],
                            [
                                7.423571348190307,
                                51.459943332774245
                            ],
                            [
                                7.425068020820618,
                                51.459829692290796
                            ],
                            [
                                7.426671981811523,
                                51.45976618719152
                            ],
                            [
                                7.428705096244812,
                                51.46020737868736
                            ],
                            [
                                7.4299657344818115,
                                51.46047142307287
                            ],
                            [
                                7.430384159088135,
                                51.46078225817666
                            ],
                            [
                                7.4313658475875854,
                                51.46179496431413
                            ],
                            [
                                7.432009577751161,
                                51.46250351102211
                            ],
                            [
                                7.432170510292052,
                                51.463379152604446
                            ],
                            [
                                7.431698441505432,
                                51.464050912047504
                            ],
                            [
                                7.431135177612304,
                                51.46499002185763
                            ],
                            [
                                7.430834770202637,
                                51.46576201580401
                            ],
                            [
                                7.432749867439271,
                                51.465584892806326
                            ],
                            [
                                7.435705661773681,
                                51.46553142157758
                            ],
                            [
                                7.43823766708374,
                                51.465574866955706
                            ],
                            [
                                7.438897490501404,
                                51.46606947295989
                            ],
                            [
                                7.440142035484313,
                                51.466256619779614
                            ],
                            [
                                7.441263198852539,
                                51.466186439812176
                            ],
                            [
                                7.444803714752197,
                                51.465892351155645
                            ],
                            [
                                7.450103759765625,
                                51.46761675306921
                            ],
                            [
                                7.454309463500977,
                                51.468605915451
                            ],
                            [
                                7.459394931793213,
                                51.47129258612404
                            ],
                            [
                                7.460167407989503,
                                51.471078727587525
                            ],
                            [
                                7.463021278381348,
                                51.47025001628963
                            ],
                            [
                                7.465510368347169,
                                51.46975545560105
                            ],
                            [
                                7.467741966247559,
                                51.469728722438134
                            ],
                            [
                                7.469844818115234,
                                51.469929220778205
                            ],
                            [
                                7.471497058868408,
                                51.47004951935931
                            ],
                            [
                                7.4742650985717765,
                                51.46986238809608
                            ],
                            [
                                7.477526664733887,
                                51.46982228843981
                            ],
                            [
                                7.47992992401123,
                                51.47026338272035
                            ],
                            [
                                7.482783794403076,
                                51.47094506549309
                            ],
                            [
                                7.483706474304198,
                                51.471359416711145
                            ],
                            [
                                7.484457492828368,
                                51.472000985366016
                            ],
                            [
                                7.485530376434327,
                                51.47265591072837
                            ],
                            [
                                7.487204074859618,
                                51.47420630880496
                            ],
                            [
                                7.488040924072266,
                                51.47495475798313
                            ],
                            [
                                7.492954730987548,
                                51.476157597006775
                            ],
                            [
                                7.495207786560059,
                                51.476972836533044
                            ],
                            [
                                7.496151924133301,
                                51.47761432624449
                            ],
                            [
                                7.496795654296875,
                                51.478295899177596
                            ],
                            [
                                7.49739646911621,
                                51.47872354758258
                            ],
                            [
                                7.499198913574219,
                                51.479405103940444
                            ],
                            [
                                7.5044775009155265,
                                51.480754822749226
                            ],
                            [
                                7.506902217864989,
                                51.48163679563915
                            ],
                            [
                                7.50889778137207,
                                51.48225149393032
                            ],
                            [
                                7.51063585281372,
                                51.48215795342051
                            ],
                            [
                                7.512438297271728,
                                51.48229158266152
                            ],
                            [
                                7.514047622680663,
                                51.482545477140725
                            ],
                            [
                                7.515249252319337,
                                51.482545477140725
                            ],
                            [
                                7.519047260284424,
                                51.482010960803116
                            ],
                            [
                                7.521257400512695,
                                51.48182387860486
                            ],
                            [
                                7.52389669418335,
                                51.482358397135215
                            ],
                            [
                                7.524411678314208,
                                51.48294636028252
                            ],
                            [
                                7.524540424346925,
                                51.48373475351443
                            ],
                            [
                                7.524669170379639,
                                51.484736928625104
                            ],
                            [
                                7.526857852935791,
                                51.48495072313153
                            ],
                            [
                                7.526557445526123,
                                51.48587270045831
                            ],
                            [
                                7.526128292083739,
                                51.487796767005534
                            ],
                            [
                                7.52535581588745,
                                51.49143089340988
                            ],
                            [
                                7.524604797363281,
                                51.49394254677304
                            ],
                            [
                                7.523231506347656,
                                51.49613345052725
                            ],
                            [
                                7.521815299987792,
                                51.497910142708726
                            ],
                            [
                                7.5209569931030265,
                                51.499326103426
                            ],
                            [
                                7.520613670349121,
                                51.5009290247347
                            ],
                            [
                                7.519969940185546,
                                51.50221132119075
                            ],
                            [
                                7.5191330909729,
                                51.503760714603345
                            ],
                            [
                                7.517480850219727,
                                51.50380078442383
                            ],
                            [
                                7.517352104187012,
                                51.504642242514684
                            ],
                            [
                                7.519733905792236,
                                51.50532341149335
                            ],
                            [
                                7.520806789398193,
                                51.50649873794456
                            ],
                            [
                                7.521493434906005,
                                51.50798120875358
                            ],
                            [
                                7.521708011627197,
                                51.50975743908446
                            ],
                            [
                                7.521343231201172,
                                51.5112130950174
                            ],
                            [
                                7.520592212677003,
                                51.51352335252112
                            ],
                            [
                                7.5194549560546875,
                                51.51640767141776
                            ],
                            [
                                7.518317699432372,
                                51.518958004956936
                            ],
                            [
                                7.517330646514892,
                                51.5212945726301
                            ],
                            [
                                7.5167298316955575,
                                51.52291007217972
                            ],
                            [
                                7.516150474548339,
                                51.52343075816895
                            ],
                            [
                                7.515420913696289,
                                51.52417839943376
                            ],
                            [
                                7.514841556549072,
                                51.525393290308436
                            ],
                            [
                                7.516365051269531,
                                51.525740396033356
                            ],
                            [
                                7.5162577629089355,
                                51.52706204361282
                            ],
                            [
                                7.515742778778077,
                                51.52744918563902
                            ],
                            [
                                7.514970302581787,
                                51.528183411474586
                            ],
                            [
                                7.513875961303711,
                                51.53009234323456
                            ],
                            [
                                7.513446807861328,
                                51.53168083382218
                            ],
                            [
                                7.509605884552001,
                                51.53050615704576
                            ],
                            [
                                7.505593299865723,
                                51.52945159184771
                            ],
                            [
                                7.50216007232666,
                                51.5283970022217
                            ],
                            [
                                7.497997283935547,
                                51.52684844660306
                            ],
                            [
                                7.495186328887939,
                                51.529358148183654
                            ],
                            [
                                7.489027976989746,
                                51.52901107003804
                            ],
                            [
                                7.4892210960388175,
                                51.53087992112608
                            ],
                            [
                                7.484457492828368,
                                51.52953168626424
                            ],
                            [
                                7.481238842010498,
                                51.52954503531994
                            ],
                            [
                                7.478384971618652,
                                51.530893269786475
                            ],
                            [
                                7.480208873748779,
                                51.53591208868727
                            ],
                            [
                                7.482504844665527,
                                51.53892845294797
                            ],
                            [
                                7.485873699188232,
                                51.54377290280936
                            ],
                            [
                                7.493147850036621,
                                51.5458546567126
                            ],
                            [
                                7.499048709869384,
                                51.546895497948725
                            ],
                            [
                                7.506601810455321,
                                51.54867021078447
                            ],
                            [
                                7.508962154388429,
                                51.5503247679127
                            ],
                            [
                                7.5113654136657715,
                                51.5503247679127
                            ],
                            [
                                7.514519691467285,
                                51.548790301633524
                            ],
                            [
                                7.515034675598144,
                                51.54948415366567
                            ],
                            [
                                7.515678405761719,
                                51.55124542283758
                            ],
                            [
                                7.515549659729004,
                                51.55307333464022
                            ],
                            [
                                7.515249252319337,
                                51.55444755572847
                            ],
                            [
                                7.514283657073975,
                                51.555995172489844
                            ],
                            [
                                7.5128889083862305,
                                51.55742266884972
                            ],
                            [
                                7.510871887207031,
                                51.55893016309309
                            ],
                            [
                                7.509369850158692,
                                51.560024065682924
                            ],
                            [
                                7.50741720199585,
                                51.56135805762198
                            ],
                            [
                                7.505743503570556,
                                51.562745367728446
                            ],
                            [
                                7.504949569702148,
                                51.56346568512907
                            ],
                            [
                                7.50216007232666,
                                51.5633856504258
                            ],
                            [
                                7.501945495605469,
                                51.562905439248
                            ],
                            [
                                7.501688003540039,
                                51.561771587177525
                            ],
                            [
                                7.501494884490967,
                                51.559423756543765
                            ],
                            [
                                7.498211860656738,
                                51.561157961325726
                            ],
                            [
                                7.496538162231444,
                                51.56145143559224
                            ],
                            [
                                7.49467134475708,
                                51.56131803843316
                            ],
                            [
                                7.490701675415038,
                                51.560637706834996
                            ],
                            [
                                7.483255863189696,
                                51.55931703408943
                            ],
                            [
                                7.477247714996338,
                                51.55901020563821
                            ],
                            [
                                7.469480037689209,
                                51.55875673709539
                            ],
                            [
                                7.465038299560547,
                                51.55866335359194
                            ],
                            [
                                7.454888820648193,
                                51.55776953035811
                            ],
                            [
                                7.449824810028076,
                                51.556608868004005
                            ],
                            [
                                7.444396018981934,
                                51.55716919145917
                            ],
                            [
                                7.444911003112792,
                                51.556315362486856
                            ],
                            [
                                7.4448251724243155,
                                51.555514883267996
                            ],
                            [
                                7.442851066589355,
                                51.555514883267996
                            ],
                            [
                                7.442722320556641,
                                51.55444755572847
                            ],
                            [
                                7.442808151245116,
                                51.55354030762803
                            ],
                            [
                                7.443280220031738,
                                51.55287320189221
                            ],
                            [
                                7.444310188293457,
                                51.55236619498982
                            ],
                            [
                                7.445168495178223,
                                51.55169907203511
                            ]
                        ],
                        [
                            [
                                7.28093147277832,
                                51.47518196333959
                            ],
                            [
                                7.280888557434082,
                                51.47614423230298
                            ],
                            [
                                7.279601097106934,
                                51.477995206492004
                            ],
                            [
                                7.278517484664916,
                                51.47774796879879
                            ],
                            [
                                7.278109788894652,
                                51.47816225822874
                            ],
                            [
                                7.277626991271973,
                                51.479077690882804
                            ],
                            [
                                7.2777557373046875,
                                51.47979933287059
                            ],
                            [
                                7.2783565521240225,
                                51.4803472386059
                            ],
                            [
                                7.278388738632201,
                                51.48102877070028
                            ],
                            [
                                7.277863025665282,
                                51.48319356979671
                            ],
                            [
                                7.27745532989502,
                                51.486280235219446
                            ],
                            [
                                7.277101278305054,
                                51.488197603983565
                            ],
                            [
                                7.276114225387573,
                                51.490495667972716
                            ],
                            [
                                7.275331020355225,
                                51.49195193925999
                            ],
                            [
                                7.274258136749268,
                                51.49313429044571
                            ],
                            [
                                7.273024320602417,
                                51.49441680621093
                            ],
                            [
                                7.272026538848876,
                                51.495038011813065
                            ],
                            [
                                7.270975112915039,
                                51.495171603309615
                            ],
                            [
                                7.26994514465332,
                                51.49530519441468
                            ],
                            [
                                7.269333600997925,
                                51.4957193243522
                            ],
                            [
                                7.2684645652771,
                                51.496033259055736
                            ],
                            [
                                7.2673702239990225,
                                51.496033259055736
                            ],
                            [
                                7.264677286148071,
                                51.49535863074706
                            ],
                            [
                                7.262595891952515,
                                51.4949578567272
                            ],
                            [
                                7.262166738510132,
                                51.494663953539806
                            ],
                            [
                                7.261061668395996,
                                51.4947173906239
                            ],
                            [
                                7.25718855857849,
                                51.494390087500506
                            ],
                            [
                                7.255868911743163,
                                51.49430993127531
                            ],
                            [
                                7.252435684204102,
                                51.49428321250224
                            ],
                            [
                                7.249431610107422,
                                51.49454371987148
                            ],
                            [
                                7.245097160339355,
                                51.495038011813065
                            ],
                            [
                                7.243305444717407,
                                51.49511148718463
                            ],
                            [
                                7.242093086242675,
                                51.494997934287724
                            ],
                            [
                                7.240215539932252,
                                51.4947173906239
                            ],
                            [
                                7.23849892616272,
                                51.49431661096613
                            ],
                            [
                                7.2375547885894775,
                                51.49401602391037
                            ],
                            [
                                7.2355055809021,
                                51.494036063109085
                            ],
                            [
                                7.234164476394652,
                                51.49418301696384
                            ],
                            [
                                7.2329628467559814,
                                51.49409618065237
                            ],
                            [
                                7.232469320297241,
                                51.493648637039406
                            ],
                            [
                                7.232533693313599,
                                51.49300069297842
                            ],
                            [
                                7.231782674789428,
                                51.49292053431011
                            ],
                            [
                                7.231235504150391,
                                51.494951177130346
                            ],
                            [
                                7.22848892211914,
                                51.49656761102566
                            ],
                            [
                                7.226439714431762,
                                51.497415881912254
                            ],
                            [
                                7.224390506744386,
                                51.49788342604594
                            ],
                            [
                                7.222330570220946,
                                51.49814391284002
                            ],
                            [
                                7.219058275222778,
                                51.498310890771144
                            ],
                            [
                                7.216912508010864,
                                51.49821738320509
                            ],
                            [
                                7.215163707733154,
                                51.49803036749742
                            ],
                            [
                                7.214369773864746,
                                51.497215504386794
                            ],
                            [
                                7.213189601898193,
                                51.496928295064095
                            ],
                            [
                                7.2120201587677,
                                51.496814746693175
                            ],
                            [
                                7.211880683898925,
                                51.4972422214411
                            ],
                            [
                                7.213221788406372,
                                51.497489353450895
                            ],
                            [
                                7.213093042373657,
                                51.49783667184838
                            ],
                            [
                                7.211730480194093,
                                51.49780995514254
                            ],
                            [
                                7.210239171981811,
                                51.49768972977242
                            ],
                            [
                                7.209348678588867,
                                51.49758954172178
                            ],
                            [
                                7.208930253982543,
                                51.49743591961636
                            ],
                            [
                                7.208855152130127,
                                51.497108636013046
                            ],
                            [
                                7.208876609802245,
                                51.49629375642361
                            ],
                            [
                                7.20875859260559,
                                51.49610005339454
                            ],
                            [
                                7.208029031753541,
                                51.49621360354591
                            ],
                            [
                                7.208329439163208,
                                51.49690157782581
                            ],
                            [
                                7.2081685066223145,
                                51.49730901400831
                            ],
                            [
                                7.207632064819336,
                                51.49743591961636
                            ],
                            [
                                7.206516265869141,
                                51.497389164959756
                            ],
                            [
                                7.205647230148315,
                                51.49732237251
                            ],
                            [
                                7.202825546264648,
                                51.49694833298257
                            ],
                            [
                                7.200336456298827,
                                51.496681160012265
                            ],
                            [
                                7.19602346420288,
                                51.49626703881338
                            ],
                            [
                                7.194328308105468,
                                51.49614680937349
                            ],
                            [
                                7.1940386295318595,
                                51.49607333567078
                            ],
                            [
                                7.192879915237427,
                                51.496033259055736
                            ],
                            [
                                7.190819978713989,
                                51.495913028999226
                            ],
                            [
                                7.189993858337402,
                                51.495826195983376
                            ],
                            [
                                7.189972400665282,
                                51.495612452470446
                            ],
                            [
                                7.189146280288695,
                                51.495438785128194
                            ],
                            [
                                7.188427448272705,
                                51.4955322983947
                            ],
                            [
                                7.187215089797973,
                                51.49497789551187
                            ],
                            [
                                7.186206579208374,
                                51.49437672813942
                            ],
                            [
                                7.1855199337005615,
                                51.4936753561844
                            ],
                            [
                                7.1852195262908936,
                                51.492847055407005
                            ],
                            [
                                7.185176610946656,
                                51.49243289937446
                            ],
                            [
                                7.185412645339965,
                                51.49111692700949
                            ],
                            [
                                7.1855199337005615,
                                51.48956042335037
                            ],
                            [
                                7.18505859375,
                                51.48781012829492
                            ],
                            [
                                7.18454360961914,
                                51.4865541499793
                            ],
                            [
                                7.183427810668945,
                                51.48497744737437
                            ],
                            [
                                7.182515859603882,
                                51.48390846544493
                            ],
                            [
                                7.182955741882325,
                                51.482939678925725
                            ],
                            [
                                7.181421518325805,
                                51.48177042640725
                            ],
                            [
                                7.180531024932862,
                                51.481002044143345
                            ],
                            [
                                7.182719707489014,
                                51.48046750971556
                            ],
                            [
                                7.182687520980835,
                                51.481155721631794
                            ],
                            [
                                7.1843934059143075,
                                51.48096863592516
                            ],
                            [
                                7.183846235275268,
                                51.480267057689936
                            ],
                            [
                                7.18509078025818,
                                51.47956546866215
                            ],
                            [
                                7.186421155929565,
                                51.478128847930314
                            ],
                            [
                                7.187054157257079,
                                51.47691937864985
                            ],
                            [
                                7.187247276306152,
                                51.47573660695643
                            ],
                            [
                                7.189092636108398,
                                51.47613754994963
                            ],
                            [
                                7.19154953956604,
                                51.47675232236106
                            ],
                            [
                                7.193309068679809,
                                51.47719334963896
                            ],
                            [
                                7.193105220794677,
                                51.47809543760746
                            ],
                            [
                                7.192869186401367,
                                51.4795253775346
                            ],
                            [
                                7.192429304122925,
                                51.47998642337394
                            ],
                            [
                                7.193470001220702,
                                51.48041405592821
                            ],
                            [
                                7.193770408630371,
                                51.47998642337394
                            ],
                            [
                                7.195068597793578,
                                51.47947192264301
                            ],
                            [
                                7.1966350078582755,
                                51.47981937831838
                            ],
                            [
                                7.197536230087279,
                                51.481082223767146
                            ],
                            [
                                7.1985554695129395,
                                51.4810220890625
                            ],
                            [
                                7.197461128234862,
                                51.47931823948076
                            ],
                            [
                                7.1954870223999015,
                                51.4788304590573
                            ],
                            [
                                7.194253206253051,
                                51.47917791961945
                            ],
                            [
                                7.193480730056763,
                                51.47947192264301
                            ],
                            [
                                7.193748950958252,
                                51.47810211967397
                            ],
                            [
                                7.1939849853515625,
                                51.47730694688353
                            ],
                            [
                                7.194199562072754,
                                51.476839191707604
                            ],
                            [
                                7.193598747253417,
                                51.476678817400156
                            ],
                            [
                                7.19252586364746,
                                51.476478348722935
                            ],
                            [
                                7.192268371582031,
                                51.47589030218703
                            ],
                            [
                                7.192075252532958,
                                51.47500153564903
                            ],
                            [
                                7.191839218139648,
                                51.473491261066485
                            ],
                            [
                                7.191882133483887,
                                51.47268932503559
                            ],
                            [
                                7.192021608352661,
                                51.4725155703708
                            ],
                            [
                                7.192128896713257,
                                51.47203440015294
                            ],
                            [
                                7.192193269729613,
                                51.47133936754529
                            ],
                            [
                                7.1924078464508066,
                                51.47095843172017
                            ],
                            [
                                7.1929872035980225,
                                51.470624274868804
                            ],
                            [
                                7.193759679794311,
                                51.47012971823719
                            ],
                            [
                                7.194070816040039,
                                51.46966188946233
                            ],
                            [
                                7.194789648056029,
                                51.46918737251885
                            ],
                            [
                                7.195422649383545,
                                51.46873290096033
                            ],
                            [
                                7.196409702301025,
                                51.46780389354294
                            ],
                            [
                                7.197203636169433,
                                51.46664093673503
                            ],
                            [
                                7.197675704956055,
                                51.46571188672347
                            ],
                            [
                                7.198340892791748,
                                51.46427482966439
                            ],
                            [
                                7.19880223274231,
                                51.4636398365475
                            ],
                            [
                                7.199113368988037,
                                51.463285573389854
                            ],
                            [
                                7.199585437774658,
                                51.46305162451385
                            ],
                            [
                                7.20079779624939,
                                51.462617144847925
                            ],
                            [
                                7.201752662658691,
                                51.46222276741595
                            ],
                            [
                                7.202471494674682,
                                51.461962075378054
                            ],
                            [
                                7.203340530395507,
                                51.46155432330704
                            ],
                            [
                                7.204359769821166,
                                51.46096608439239
                            ],
                            [
                                7.2045958042144775,
                                51.460692015945256
                            ],
                            [
                                7.204359769821166,
                                51.46007034239147
                            ],
                            [
                                7.204434871673584,
                                51.459782899068315
                            ],
                            [
                                7.204864025115968,
                                51.459582356142874
                            ],
                            [
                                7.205711603164672,
                                51.45925480080294
                            ],
                            [
                                7.2061944007873535,
                                51.458907188483394
                            ],
                            [
                                7.206323146820068,
                                51.45851946392675
                            ],
                            [
                                7.205947637557983,
                                51.45799135104268
                            ],
                            [
                                7.206923961639404,
                                51.45751671273066
                            ],
                            [
                                7.209348678588867,
                                51.45678803304111
                            ],
                            [
                                7.210700511932374,
                                51.45644708717328
                            ],
                            [
                                7.211129665374756,
                                51.45609945347033
                            ],
                            [
                                7.212481498718262,
                                51.455216986795605
                            ],
                            [
                                7.2138118743896475,
                                51.45452169800595
                            ],
                            [
                                7.215120792388916,
                                51.4541740496398
                            ],
                            [
                                7.216848134994507,
                                51.4541740496398
                            ],
                            [
                                7.218736410140991,
                                51.45446152828587
                            ],
                            [
                                7.220495939254761,
                                51.45468883570109
                            ],
                            [
                                7.222115993499755,
                                51.454648722710076
                            ],
                            [
                                7.223167419433594,
                                51.454849287312726
                            ],
                            [
                                7.223521471023559,
                                51.45529052633677
                            ],
                            [
                                7.223660945892334,
                                51.455678278321855
                            ],
                            [
                                7.224154472351074,
                                51.455965747494986
                            ],
                            [
                                7.224680185317993,
                                51.45621310324141
                            ],
                            [
                                7.22476601600647,
                                51.4565807917387
                            ],
                            [
                                7.225334644317628,
                                51.456727866308306
                            ],
                            [
                                7.225656509399413,
                                51.456513939504944
                            ],
                            [
                                7.226332426071167,
                                51.45646714288304
                            ],
                            [
                                7.226965427398681,
                                51.45636686424606
                            ],
                            [
                                7.2279953956603995,
                                51.456199732694806
                            ],
                            [
                                7.228692770004272,
                                51.45641366097073
                            ],
                            [
                                7.231396436691284,
                                51.45757019335056
                            ],
                            [
                                7.2332632541656485,
                                51.458526148860805
                            ],
                            [
                                7.234904766082763,
                                51.459328333838975
                            ],
                            [
                                7.2357094287872314,
                                51.45974279055374
                            ],
                            [
                                7.23572015762329,
                                51.459943332774245
                            ],
                            [
                                7.236578464508057,
                                51.460197351655275
                            ],
                            [
                                7.238509654998779,
                                51.46074549284468
                            ],
                            [
                                7.241191864013671,
                                51.461200043959686
                            ],
                            [
                                7.244668006896973,
                                51.4618417554737
                            ],
                            [
                                7.246105670928955,
                                51.46218934544303
                            ],
                            [
                                7.247586250305176,
                                51.46260377617721
                            ],
                            [
                                7.249174118041993,
                                51.46296472891158
                            ],
                            [
                                7.25022554397583,
                                51.463405889487646
                            ],
                            [
                                7.251448631286621,
                                51.46414114763724
                            ],
                            [
                                7.25123405456543,
                                51.46472934562747
                            ],
                            [
                                7.250525951385497,
                                51.46503680974133
                            ],
                            [
                                7.250804901123046,
                                51.465464582453535
                            ],
                            [
                                7.252070903778076,
                                51.46542447893212
                            ],
                            [
                                7.2528862953186035,
                                51.465584892806326
                            ],
                            [
                                7.253658771514893,
                                51.46574530611661
                            ],
                            [
                                7.254452705383301,
                                51.46653399668985
                            ],
                            [
                                7.255396842956543,
                                51.46767022185433
                            ],
                            [
                                7.256255149841309,
                                51.46864601617634
                            ],
                            [
                                7.256877422332764,
                                51.469648522855394
                            ],
                            [
                                7.257306575775146,
                                51.47034358122226
                            ],
                            [
                                7.256770133972167,
                                51.471145558487876
                            ],
                            [
                                7.256598472595215,
                                51.47170693418584
                            ],
                            [
                                7.256834506988525,
                                51.47256235053797
                            ],
                            [
                                7.2571563720703125,
                                51.47315043997589
                            ],
                            [
                                7.258143424987793,
                                51.47363159842254
                            ],
                            [
                                7.258293628692626,
                                51.47401919459165
                            ],
                            [
                                7.259345054626465,
                                51.47397909858894
                            ],
                            [
                                7.259902954101563,
                                51.47328409560901
                            ],
                            [
                                7.260417938232422,
                                51.47277620211981
                            ],
                            [
                                7.260417938232422,
                                51.47220147372042
                            ],
                            [
                                7.261426448822022,
                                51.47204108310739
                            ],
                            [
                                7.263014316558837,
                                51.471747032185654
                            ],
                            [
                                7.265374660491943,
                                51.47113219231562
                            ],
                            [
                                7.266490459442139,
                                51.47073120532752
                            ],
                            [
                                7.267735004425049,
                                51.470169817623244
                            ],
                            [
                                7.269108295440673,
                                51.46926088955148
                            ],
                            [
                                7.270653247833252,
                                51.46824500733697
                            ],
                            [
                                7.272863388061523,
                                51.46698849015088
                            ],
                            [
                                7.275803089141845,
                                51.465611628397205
                            ],
                            [
                                7.279300689697265,
                                51.46486302593152
                            ],
                            [
                                7.280716896057129,
                                51.46418125228651
                            ],
                            [
                                7.28193998336792,
                                51.46325883643615
                            ],
                            [
                                7.28318452835083,
                                51.46287114884719
                            ],
                            [
                                7.285137176513671,
                                51.46253693276497
                            ],
                            [
                                7.285995483398437,
                                51.463098414384994
                            ],
                            [
                                7.28719711303711,
                                51.46344599478307
                            ],
                            [
                                7.28715419769287,
                                51.46400746521843
                            ],
                            [
                                7.28668212890625,
                                51.46625327787885
                            ],
                            [
                                7.285223007202149,
                                51.469407923261336
                            ],
                            [
                                7.283248901367187,
                                51.47218810785753
                            ],
                            [
                                7.28093147277832,
                                51.47518196333959
                            ]
                        ],
                        [
                            [
                                6.95683479309082,
                                51.399138717323204
                            ],
                            [
                                6.957821846008301,
                                51.39851620568728
                            ],
                            [
                                6.959023475646973,
                                51.39769287083378
                            ],
                            [
                                6.961126327514648,
                                51.3972309935737
                            ],
                            [
                                6.962370872497558,
                                51.39691637885671
                            ],
                            [
                                6.963250637054443,
                                51.39604615666735
                            ],
                            [
                                6.965020895004272,
                                51.39468723813947
                            ],
                            [
                                6.9663405418396,
                                51.39400441654815
                            ],
                            [
                                6.967670917510986,
                                51.393656307500876
                            ],
                            [
                                6.969677209854126,
                                51.39322786196031
                            ],
                            [
                                6.971468925476074,
                                51.39266552109911
                            ],
                            [
                                6.973131895065308,
                                51.392518240207565
                            ],
                            [
                                6.974794864654541,
                                51.39215673055446
                            ],
                            [
                                6.975942850112915,
                                51.39184877563392
                            ],
                            [
                                6.976608037948608,
                                51.391427007922765
                            ],
                            [
                                6.977187395095824,
                                51.39048973242211
                            ],
                            [
                                6.97780966758728,
                                51.38889632000578
                            ],
                            [
                                6.978163719177246,
                                51.38727607065195
                            ],
                            [
                                6.978410482406616,
                                51.38574280665494
                            ],
                            [
                                6.978743076324463,
                                51.38520715646888
                            ],
                            [
                                6.978968381881714,
                                51.38479871898902
                            ],
                            [
                                6.9788289070129395,
                                51.38423627452598
                            ],
                            [
                                6.978582143783569,
                                51.38384122011566
                            ],
                            [
                                6.978217363357543,
                                51.38328545983302
                            ],
                            [
                                6.977949142456054,
                                51.38292387723015
                            ],
                            [
                                6.97806715965271,
                                51.38271630074148
                            ],
                            [
                                6.978646516799926,
                                51.38264264434178
                            ],
                            [
                                6.980395317077636,
                                51.382676124538165
                            ],
                            [
                                6.980953216552734,
                                51.38254220360569
                            ],
                            [
                                6.981232166290283,
                                51.38218061513063
                            ],
                            [
                                6.981382369995116,
                                51.380593609707155
                            ],
                            [
                                6.981768608093262,
                                51.37877216857575
                            ],
                            [
                                6.981950998306274,
                                51.377406040160466
                            ],
                            [
                                6.9773054122924805,
                                51.376763142092386
                            ],
                            [
                                6.972005367279053,
                                51.376488568290945
                            ],
                            [
                                6.968379020690918,
                                51.37569162524753
                            ],
                            [
                                6.965031623840332,
                                51.374881273977685
                            ],
                            [
                                6.962982416152954,
                                51.374070908363485
                            ],
                            [
                                6.962360143661499,
                                51.37306630297803
                            ],
                            [
                                6.962778568267822,
                                51.370501110557235
                            ],
                            [
                                6.963293552398681,
                                51.36920171543431
                            ],
                            [
                                6.962381601333618,
                                51.368210399513295
                            ],
                            [
                                6.961630582809447,
                                51.367158777408726
                            ],
                            [
                                6.961265802383423,
                                51.36639516405775
                            ],
                            [
                                6.961308717727661,
                                51.36562483944246
                            ],
                            [
                                6.960772275924683,
                                51.3648745977966
                            ],
                            [
                                6.958690881729125,
                                51.362536712418255
                            ],
                            [
                                6.956824064254761,
                                51.360781545254746
                            ],
                            [
                                6.954399347305298,
                                51.3589660153234
                            ],
                            [
                                6.953219175338745,
                                51.35826926148237
                            ],
                            [
                                6.9518351554870605,
                                51.358061573190916
                            ],
                            [
                                6.947393417358398,
                                51.35771319071652
                            ],
                            [
                                6.9431984424591064,
                                51.3573380066271
                            ],
                            [
                                6.943166255950928,
                                51.35710351501076
                            ],
                            [
                                6.942737102508545,
                                51.35698291885498
                            ],
                            [
                                6.941395998001099,
                                51.356882421815975
                            ],
                            [
                                6.940795183181763,
                                51.356621128482715
                            ],
                            [
                                6.939518451690674,
                                51.35617223645627
                            ],
                            [
                                6.9381773471832275,
                                51.355756839916225
                            ],
                            [
                                6.936750411987305,
                                51.355348139644356
                            ],
                            [
                                6.9359564781188965,
                                51.35501983678517
                            ],
                            [
                                6.9358813762664795,
                                51.354765232948075
                            ],
                            [
                                6.936396360397339,
                                51.35458432936188
                            ],
                            [
                                6.936761140823363,
                                51.35425602102898
                            ],
                            [
                                6.937093734741211,
                                51.35400141294699
                            ],
                            [
                                6.937683820724487,
                                51.3538540076212
                            ],
                            [
                                6.938488483428954,
                                51.3538339068582
                            ],
                            [
                                6.9400763511657715,
                                51.35402151363649
                            ],
                            [
                                6.941556930541992,
                                51.35426942141517
                            ],
                            [
                                6.942812204360962,
                                51.35451062769601
                            ],
                            [
                                6.9435954093933105,
                                51.35471163195997
                            ],
                            [
                                6.944389343261719,
                                51.35471833208692
                            ],
                            [
                                6.945397853851318,
                                51.35459772965201
                            ],
                            [
                                6.945880651473999,
                                51.354497227380406
                            ],
                            [
                                6.946181058883667,
                                51.35426272122255
                            ],
                            [
                                6.946138143539429,
                                51.353954511303854
                            ],
                            [
                                6.9457197189331055,
                                51.35353239435505
                            ],
                            [
                                6.94535493850708,
                                51.3531370749557
                            ],
                            [
                                6.94535493850708,
                                51.35256754101045
                            ],
                            [
                                6.94585919380188,
                                51.35211860926389
                            ],
                            [
                                6.946781873703003,
                                51.35158256559568
                            ],
                            [
                                6.949206590652466,
                                51.35046355422792
                            ],
                            [
                                6.950440406799316,
                                51.349820277837544
                            ],
                            [
                                6.951320171356201,
                                51.35053056166588
                            ],
                            [
                                6.963958740234375,
                                51.34768936028917
                            ],
                            [
                                6.964967250823975,
                                51.34801771567789
                            ],
                            [
                                6.969108581542969,
                                51.349297609120555
                            ],
                            [
                                6.968808174133301,
                                51.3501687203367
                            ],
                            [
                                6.971157789230347,
                                51.35127433764665
                            ],
                            [
                                6.972874402999877,
                                51.35188409093074
                            ],
                            [
                                6.9746339321136475,
                                51.35225261920103
                            ],
                            [
                                6.975953578948975,
                                51.3528757602598
                            ],
                            [
                                6.977187395095824,
                                51.35586403938396
                            ],
                            [
                                6.979483366012573,
                                51.355475440120024
                            ],
                            [
                                6.980019807815552,
                                51.35396121154152
                            ],
                            [
                                6.9816505908966064,
                                51.35324428055364
                            ],
                            [
                                6.986382007598877,
                                51.3578270852785
                            ],
                            [
                                6.988860368728638,
                                51.357706491027564
                            ],
                            [
                                6.989396810531615,
                                51.358249162656605
                            ],
                            [
                                6.990855932235717,
                                51.35918709846183
                            ],
                            [
                                6.991703510284424,
                                51.35952207088078
                            ],
                            [
                                6.992658376693726,
                                51.359347885528635
                            ],
                            [
                                6.993731260299683,
                                51.358657837047
                            ],
                            [
                                6.997218132019043,
                                51.357632794384614
                            ],
                            [
                                6.998623609542846,
                                51.357277708897556
                            ],
                            [
                                7.000662088394165,
                                51.357498800184544
                            ],
                            [
                                7.002142667770386,
                                51.35788068262146
                            ],
                            [
                                7.003644704818726,
                                51.35784048462012
                            ],
                            [
                                7.004674673080444,
                                51.3574050040113
                            ],
                            [
                                7.006316184997559,
                                51.3574050040113
                            ],
                            [
                                7.008655071258544,
                                51.35773328977744
                            ],
                            [
                                7.008386850357055,
                                51.36016521105835
                            ],
                            [
                                7.010296583175659,
                                51.361089709242925
                            ],
                            [
                                7.010822296142578,
                                51.36192710091259
                            ],
                            [
                                7.012023925781249,
                                51.362797972008224
                            ],
                            [
                                7.013171911239624,
                                51.36351475348624
                            ],
                            [
                                7.01473832130432,
                                51.36382289908486
                            ],
                            [
                                7.015585899353027,
                                51.3638496942563
                            ],
                            [
                                7.016669511795043,
                                51.36429851104779
                            ],
                            [
                                7.017946243286132,
                                51.36457985664442
                            ],
                            [
                                7.018042802810669,
                                51.36473392611968
                            ],
                            [
                                7.018557786941528,
                                51.36471383013057
                            ],
                            [
                                7.019780874252319,
                                51.36506885797338
                            ],
                            [
                                7.020682096481323,
                                51.365142542652464
                            ],
                            [
                                7.022323608398437,
                                51.365222925803536
                            ],
                            [
                                7.023578882217407,
                                51.3647272274576
                            ],
                            [
                                7.025392055511475,
                                51.36443248535697
                            ],
                            [
                                7.028557062149048,
                                51.36263049808354
                            ],
                            [
                                7.02999472618103,
                                51.36246302354649
                            ],
                            [
                                7.033696174621582,
                                51.36336067990943
                            ],
                            [
                                7.035048007965088,
                                51.364472677573296
                            ],
                            [
                                7.037698030471802,
                                51.36563823650301
                            ],
                            [
                                7.0418500900268555,
                                51.36698462109869
                            ],
                            [
                                7.042794227600097,
                                51.368069738083165
                            ],
                            [
                                7.044543027877808,
                                51.36859219264558
                            ],
                            [
                                7.044897079467773,
                                51.36641525930905
                            ],
                            [
                                7.04595923423767,
                                51.365263117326165
                            ],
                            [
                                7.047708034515381,
                                51.36399706741912
                            ],
                            [
                                7.048426866531372,
                                51.36324010022691
                            ],
                            [
                                7.049134969711304,
                                51.36246972253974
                            ],
                            [
                                7.050561904907226,
                                51.362335742488746
                            ],
                            [
                                7.052353620529175,
                                51.36258360527489
                            ],
                            [
                                7.053512334823608,
                                51.362275451337936
                            ],
                            [
                                7.055540084838867,
                                51.362362538530284
                            ],
                            [
                                7.055604457855225,
                                51.36287835927453
                            ],
                            [
                                7.05595850944519,
                                51.363173111377215
                            ],
                            [
                                7.057889699935913,
                                51.36372241705224
                            ],
                            [
                                7.059423923492432,
                                51.36506215936032
                            ],
                            [
                                7.06170916557312,
                                51.36638176721862
                            ],
                            [
                                7.062513828277588,
                                51.36691763772616
                            ],
                            [
                                7.063168287277222,
                                51.36664300487441
                            ],
                            [
                                7.0659685134887695,
                                51.36863238121115
                            ],
                            [
                                7.070775032043457,
                                51.370708742443426
                            ],
                            [
                                7.0709145069122314,
                                51.3715057722298
                            ],
                            [
                                7.071268558502197,
                                51.37221571985165
                            ],
                            [
                                7.071826457977295,
                                51.373079697861506
                            ],
                            [
                                7.072523832321167,
                                51.3737159503124
                            ],
                            [
                                7.073768377304077,
                                51.374780816209366
                            ],
                            [
                                7.0753562450408936,
                                51.37612023499519
                            ],
                            [
                                7.076139450073242,
                                51.37691717057702
                            ],
                            [
                                7.07645058631897,
                                51.37737925292122
                            ],
                            [
                                7.076847553253173,
                                51.3783101002924
                            ],
                            [
                                7.077083587646484,
                                51.37901994240067
                            ],
                            [
                                7.0772552490234375,
                                51.38003111356405
                            ],
                            [
                                7.077223062515259,
                                51.381109225099046
                            ],
                            [
                                7.077287435531616,
                                51.38210026174822
                            ],
                            [
                                7.077351808547974,
                                51.382709604710044
                            ],
                            [
                                7.076632976531982,
                                51.38238819404891
                            ],
                            [
                                7.07592487335205,
                                51.38221409566499
                            ],
                            [
                                7.075645923614502,
                                51.38249533118676
                            ],
                            [
                                7.076750993728638,
                                51.382984141195664
                            ],
                            [
                                7.077523469924927,
                                51.38364034368389
                            ],
                            [
                                7.078059911727904,
                                51.3843567989214
                            ],
                            [
                                7.078725099563599,
                                51.38491924190316
                            ],
                            [
                                7.079669237136841,
                                51.385555329803005
                            ],
                            [
                                7.080763578414917,
                                51.386325319115564
                            ],
                            [
                                7.081235647201538,
                                51.38676052473521
                            ],
                            [
                                7.081289291381835,
                                51.38702164612025
                            ],
                            [
                                7.080516815185547,
                                51.387142163180016
                            ],
                            [
                                7.080323696136475,
                                51.387383196347365
                            ],
                            [
                                7.081407308578491,
                                51.38768448602113
                            ],
                            [
                                7.081943750381469,
                                51.387878649426405
                            ],
                            [
                                7.082029581069946,
                                51.38847452507783
                            ],
                            [
                                7.081729173660278,
                                51.38917751446432
                            ],
                            [
                                7.081096172332764,
                                51.39003447739395
                            ],
                            [
                                7.08044171333313,
                                51.39089142427347
                            ],
                            [
                                7.080162763595581,
                                51.39162785017489
                            ],
                            [
                                7.079969644546509,
                                51.39229062335165
                            ],
                            [
                                7.080012559890746,
                                51.39296008145989
                            ],
                            [
                                7.080527544021606,
                                51.3939441670949
                            ],
                            [
                                7.080956697463989,
                                51.39439268889906
                            ],
                            [
                                7.081879377365112,
                                51.39517591792323
                            ],
                            [
                                7.083016633987427,
                                51.396026074421414
                            ],
                            [
                                7.083982229232788,
                                51.39740503482799
                            ],
                            [
                                7.0845723152160645,
                                51.39864338617248
                            ],
                            [
                                7.085140943527222,
                                51.39974113987202
                            ],
                            [
                                7.085291147232056,
                                51.400691612647314
                            ],
                            [
                                7.085022926330566,
                                51.40166214545162
                            ],
                            [
                                7.084819078445435,
                                51.402123977968
                            ],
                            [
                                7.083456516265869,
                                51.40240509112948
                            ],
                            [
                                7.0831561088562,
                                51.40418543436036
                            ],
                            [
                                7.0816004276275635,
                                51.40503542341357
                            ],
                            [
                                7.079164981842041,
                                51.4059857861671
                            ],
                            [
                                7.076514959335327,
                                51.40692943668273
                            ],
                            [
                                7.075066566467285,
                                51.40752506421715
                            ],
                            [
                                7.074326276779175,
                                51.407725835794984
                            ],
                            [
                                7.073553800582886,
                                51.407585295783065
                            ],
                            [
                                7.072727680206299,
                                51.40919813376336
                            ],
                            [
                                7.071729898452758,
                                51.41025548214616
                            ],
                            [
                                7.0700669288635245,
                                51.41253070587158
                            ],
                            [
                                7.068436145782471,
                                51.4146184586099
                            ],
                            [
                                7.067019939422607,
                                51.41603031427273
                            ],
                            [
                                7.065593004226685,
                                51.41706074064227
                            ],
                            [
                                7.06394076347351,
                                51.41777667256064
                            ],
                            [
                                7.062588930130005,
                                51.41823834233675
                            ],
                            [
                                7.061387300491333,
                                51.41872007974038
                            ],
                            [
                                7.059391736984253,
                                51.41972368302435
                            ],
                            [
                                7.058190107345581,
                                51.42058676422394
                            ],
                            [
                                7.057235240936279,
                                51.42189139091254
                            ],
                            [
                                7.056398391723632,
                                51.42307555827926
                            ],
                            [
                                7.055572271347046,
                                51.424226245195754
                            ],
                            [
                                7.055110931396484,
                                51.42502903286294
                            ],
                            [
                                7.054896354675292,
                                51.42605256668538
                            ],
                            [
                                7.0548319816589355,
                                51.426788426509205
                            ],
                            [
                                7.0549607276916495,
                                51.427524274483055
                            ],
                            [
                                7.055089473724365,
                                51.42808618677443
                            ],
                            [
                                7.055293321609497,
                                51.428728363788935
                            ],
                            [
                                7.055497169494629,
                                51.429209990626866
                            ],
                            [
                                7.0555830001831055,
                                51.42963140994551
                            ],
                            [
                                7.055486440658569,
                                51.42995248872227
                            ],
                            [
                                7.055325508117676,
                                51.43024011987708
                            ],
                            [
                                7.0548319816589355,
                                51.430828754735245
                            ],
                            [
                                7.0542848110198975,
                                51.43154447099392
                            ],
                            [
                                7.055346965789795,
                                51.43256117014098
                            ],
                            [
                                7.05622673034668,
                                51.433223350181414
                            ],
                            [
                                7.056366205215454,
                                51.43385876644864
                            ],
                            [
                                7.056645154953002,
                                51.43437378156989
                            ],
                            [
                                7.057439088821411,
                                51.43506937719206
                            ],
                            [
                                7.0582544803619385,
                                51.43592548034068
                            ],
                            [
                                7.058919668197632,
                                51.436748126843646
                            ],
                            [
                                7.059112787246703,
                                51.43753063050014
                            ],
                            [
                                7.059338092803954,
                                51.438319808644785
                            ],
                            [
                                7.059863805770874,
                                51.43896184182226
                            ],
                            [
                                7.060582637786865,
                                51.439530301165355
                            ],
                            [
                                7.061676979064941,
                                51.440025189420865
                            ],
                            [
                                7.063543796539307,
                                51.440807636937436
                            ],
                            [
                                7.064563035964966,
                                51.44173719391512
                            ],
                            [
                                7.0656466484069815,
                                51.44248617570157
                            ],
                            [
                                7.0665693283081055,
                                51.44282722583782
                            ],
                            [
                                7.067996263504028,
                                51.443128150313505
                            ],
                            [
                                7.0692408084869385,
                                51.44362299958432
                            ],
                            [
                                7.073103189468384,
                                51.444077721213695
                            ],
                            [
                                7.0757317543029785,
                                51.44447225542944
                            ],
                            [
                                7.078135013580322,
                                51.444445507454724
                            ],
                            [
                                7.080581188201904,
                                51.44463274294867
                            ],
                            [
                                7.0825982093811035,
                                51.44492696860245
                            ],
                            [
                                7.08392858505249,
                                51.445201131709936
                            ],
                            [
                                7.0851945877075195,
                                51.445401737818905
                            ],
                            [
                                7.086235284805298,
                                51.44535492980565
                            ],
                            [
                                7.087576389312743,
                                51.44516101038238
                            ],
                            [
                                7.089207172393799,
                                51.44487347317004
                            ],
                            [
                                7.090580463409424,
                                51.44504733309622
                            ],
                            [
                                7.091385126113892,
                                51.445401737818905
                            ],
                            [
                                7.0919108390808105,
                                51.446150659505925
                            ],
                            [
                                7.092179059982299,
                                51.44728739216701
                            ],
                            [
                                7.0925116539001465,
                                51.44839066445258
                            ],
                            [
                                7.093048095703125,
                                51.44898575176938
                            ],
                            [
                                7.095022201538086,
                                51.449848280292045
                            ],
                            [
                                7.098820209503173,
                                51.45135265151124
                            ],
                            [
                                7.1028971672058105,
                                51.452897087726754
                            ],
                            [
                                7.106448411941528,
                                51.454341188607806
                            ],
                            [
                                7.109098434448242,
                                51.45533063876384
                            ],
                            [
                                7.110342979431152,
                                51.45578524380708
                            ],
                            [
                                7.111254930496215,
                                51.455999174025536
                            ],
                            [
                                7.1133363246917725,
                                51.45627995591576
                            ],
                            [
                                7.114913463592528,
                                51.456574106519724
                            ],
                            [
                                7.1154069900512695,
                                51.45619304742002
                            ],
                            [
                                7.121919393539428,
                                51.456968532763646
                            ],
                            [
                                7.12436556816101,
                                51.45686825522812
                            ],
                            [
                                7.127723693847656,
                                51.456627588244075
                            ],
                            [
                                7.128474712371825,
                                51.45650725427617
                            ],
                            [
                                7.130845785140991,
                                51.45645377241083
                            ],
                            [
                                7.1330344676971436,
                                51.45660753260477
                            ],
                            [
                                7.134032249450684,
                                51.45681477378578
                            ],
                            [
                                7.13634967803955,
                                51.45763035897307
                            ],
                            [
                                7.1391284465789795,
                                51.45855957351638
                            ],
                            [
                                7.141875028610229,
                                51.459488769142766
                            ],
                            [
                                7.144460678100586,
                                51.46060511585011
                            ],
                            [
                                7.147378921508789,
                                51.46148079385732
                            ],
                            [
                                7.148623466491699,
                                51.461881862143734
                            ],
                            [
                                7.14959979057312,
                                51.46252356407077
                            ],
                            [
                                7.150812149047852,
                                51.463238783710594
                            ],
                            [
                                7.152625322341918,
                                51.46402751760622
                            ],
                            [
                                7.154567241668701,
                                51.46503012576088
                            ],
                            [
                                7.156187295913696,
                                51.46589903500981
                            ],
                            [
                                7.158536911010742,
                                51.46717563320137
                            ],
                            [
                                7.16231346130371,
                                51.46908712182891
                            ],
                            [
                                7.163654565811157,
                                51.46973540573033
                            ],
                            [
                                7.167055606842041,
                                51.47059085904906
                            ],
                            [
                                7.170628309249878,
                                51.47143961328641
                            ],
                            [
                                7.17430830001831,
                                51.4722616200549
                            ],
                            [
                                7.176271677017212,
                                51.47270937360818
                            ],
                            [
                                7.176400423049927,
                                51.473170488345794
                            ],
                            [
                                7.178417444229125,
                                51.47367169473069
                            ],
                            [
                                7.181872129440307,
                                51.474493661281016
                            ],
                            [
                                7.1851229667663565,
                                51.475235423259
                            ],
                            [
                                7.186410427093506,
                                51.47558959364258
                            ],
                            [
                                7.186163663864136,
                                51.47685923845636
                            ],
                            [
                                7.185841798782349,
                                51.47752745837421
                            ],
                            [
                                7.18553066253662,
                                51.47821571465526
                            ],
                            [
                                7.184865474700928,
                                51.478817095136684
                            ],
                            [
                                7.183610200881957,
                                51.47908437280543
                            ],
                            [
                                7.183020114898682,
                                51.47949865009663
                            ],
                            [
                                7.182751893997192,
                                51.479899560021394
                            ],
                            [
                                7.181260585784911,
                                51.48021360366763
                            ],
                            [
                                7.180005311965942,
                                51.480561053692654
                            ],
                            [
                                7.179147005081177,
                                51.48048755486967
                            ],
                            [
                                7.177666425704956,
                                51.48014678605167
                            ],
                            [
                                7.176496982574462,
                                51.4798060146875
                            ],
                            [
                                7.17559576034546,
                                51.47979933287059
                            ],
                            [
                                7.1747589111328125,
                                51.480066604783296
                            ],
                            [
                                7.1732354164123535,
                                51.47967237816338
                            ],
                            [
                                7.171518802642821,
                                51.47935833079039
                            ],
                            [
                                7.170649766921996,
                                51.479351648907915
                            ],
                            [
                                7.168772220611573,
                                51.47907100895921
                            ],
                            [
                                7.1669697761535645,
                                51.47879704924838
                            ],
                            [
                                7.165038585662842,
                                51.478349355447506
                            ],
                            [
                                7.16305375099182,
                                51.47778806148873
                            ],
                            [
                                7.16133713722229,
                                51.47722007842785
                            ],
                            [
                                7.159427404403686,
                                51.47663872373521
                            ],
                            [
                                7.156991958618164,
                                51.476084091087486
                            ],
                            [
                                7.1546101570129395,
                                51.47561632337124
                            ],
                            [
                                7.1511662006378165,
                                51.475095090836916
                            ],
                            [
                                7.150179147720336,
                                51.47488125012553
                            ],
                            [
                                7.149578332901001,
                                51.47461394782657
                            ],
                            [
                                7.1483659744262695,
                                51.4744602482954
                            ],
                            [
                                7.1476686000823975,
                                51.474573852346616
                            ],
                            [
                                7.145737409591675,
                                51.47439342225076
                            ],
                            [
                                7.143537998199463,
                                51.47423972197657
                            ],
                            [
                                7.141553163528442,
                                51.47418626089023
                            ],
                            [
                                7.140254974365234,
                                51.4741996261677
                            ],
                            [
                                7.138452529907227,
                                51.47412611709325
                            ],
                            [
                                7.138044834136963,
                                51.474072655873755
                            ],
                            [
                                7.13811993598938,
                                51.47361155025526
                            ],
                            [
                                7.1376800537109375,
                                51.473531357498004
                            ],
                            [
                                7.137175798416138,
                                51.47415953032359
                            ],
                            [
                                7.134751081466675,
                                51.47425976986779
                            ],
                            [
                                7.132208347320557,
                                51.47427313512371
                            ],
                            [
                                7.129483222961425,
                                51.47418626089023
                            ],
                            [
                                7.127648591995239,
                                51.47401251192696
                            ],
                            [
                                7.126350402832031,
                                51.47382539691876
                            ],
                            [
                                7.1256422996521,
                                51.47370510829389
                            ],
                            [
                                7.12662935256958,
                                51.47246879015567
                            ],
                            [
                                7.126221656799316,
                                51.47227498589627
                            ],
                            [
                                7.124569416046143,
                                51.47363159842254
                            ],
                            [
                                7.12139368057251,
                                51.47300341832713
                            ],
                            [
                                7.118239402770995,
                                51.4722616200549
                            ],
                            [
                                7.115954160690308,
                                51.471726983190145
                            ],
                            [
                                7.113776206970215,
                                51.47142624720031
                            ],
                            [
                                7.111523151397705,
                                51.471219072365194
                            ],
                            [
                                7.1087443828582755,
                                51.47132600142983
                            ],
                            [
                                7.106888294219971,
                                51.4716066890321
                            ],
                            [
                                7.106062173843384,
                                51.47157995695391
                            ],
                            [
                                7.105010747909546,
                                51.47099184727069
                            ],
                            [
                                7.104098796844482,
                                51.470878234299065
                            ],
                            [
                                7.103508710861206,
                                51.471038629000326
                            ],
                            [
                                7.103283405303955,
                                51.47155322486004
                            ],
                            [
                                7.103090286254883,
                                51.47207449786496
                            ],
                            [
                                7.102028131484985,
                                51.47208786376111
                            ],
                            [
                                7.100965976715088,
                                51.471960887589425
                            ],
                            [
                                7.099463939666748,
                                51.47162005506532
                            ],
                            [
                                7.098820209503173,
                                51.47134605060154
                            ],
                            [
                                7.09791898727417,
                                51.47098516416253
                            ],
                            [
                                7.09716796875,
                                51.4704638787108
                            ],
                            [
                                7.095794677734375,
                                51.46976882217665
                            ],
                            [
                                7.09339141845703,
                                51.46831184238827
                            ],
                            [
                                7.0920610427856445,
                                51.467563284221455
                            ],
                            [
                                7.0899152755737305,
                                51.466293380671864
                            ],
                            [
                                7.088606357574463,
                                51.465678467305864
                            ],
                            [
                                7.087512016296387,
                                51.465371007515415
                            ],
                            [
                                7.085022926330566,
                                51.464969969892806
                            ],
                            [
                                7.082469463348389,
                                51.464742713675484
                            ],
                            [
                                7.080323696136475,
                                51.46448872009329
                            ],
                            [
                                7.079529762268066,
                                51.46414114763724
                            ],
                            [
                                7.079250812530518,
                                51.46434167053112
                            ],
                            [
                                7.077319622039794,
                                51.46423472509736
                            ],
                            [
                                7.073328495025635,
                                51.46406093823295
                            ],
                            [
                                7.069487571716309,
                                51.463967360416426
                            ],
                            [
                                7.066805362701416,
                                51.463860414105476
                            ],
                            [
                                7.065989971160889,
                                51.463633152362455
                            ],
                            [
                                7.065517902374268,
                                51.46268398814269
                            ],
                            [
                                7.064487934112549,
                                51.462349770689805
                            ],
                            [
                                7.063565254211426,
                                51.46228292690551
                            ],
                            [
                                7.063651084899902,
                                51.463058308784085
                            ],
                            [
                                7.061676979064941,
                                51.46245672054102
                            ],
                            [
                                7.059659957885742,
                                51.46152090084457
                            ],
                            [
                                7.056419849395751,
                                51.46046473842374
                            ],
                            [
                                7.054123878479004,
                                51.4596090952505
                            ],
                            [
                                7.052814960479736,
                                51.45920800699107
                            ],
                            [
                                7.0523858070373535,
                                51.458887133845714
                            ],
                            [
                                7.053394317626953,
                                51.45833897033723
                            ],
                            [
                                7.053050994873047,
                                51.45819190095881
                            ],
                            [
                                7.051634788513184,
                                51.458699956802526
                            ],
                            [
                                7.051076889038085,
                                51.458499409118716
                            ],
                            [
                                7.049188613891602,
                                51.45764372910066
                            ],
                            [
                                7.047257423400879,
                                51.45670781071307
                            ],
                            [
                                7.04599142074585,
                                51.45590557967832
                            ],
                            [
                                7.044811248779297,
                                51.45521030137689
                            ],
                            [
                                7.043395042419434,
                                51.4545952386672
                            ],
                            [
                                7.042386531829834,
                                51.45388656918334
                            ],
                            [
                                7.040863037109375,
                                51.452495940485974
                            ],
                            [
                                7.039768695831299,
                                51.451118641466124
                            ],
                            [
                                7.03923225402832,
                                51.44999537655326
                            ],
                            [
                                7.03873872756958,
                                51.44863137486314
                            ],
                            [
                                7.037901878356933,
                                51.44623758712043
                            ],
                            [
                                7.037687301635742,
                                51.44524793988084
                            ],
                            [
                                7.037644386291504,
                                51.4434424470933
                            ],
                            [
                                7.037622928619385,
                                51.44209162432721
                            ],
                            [
                                7.037365436553954,
                                51.44106176230687
                            ],
                            [
                                7.037107944488525,
                                51.440286006749126
                            ],
                            [
                                7.036292552947998,
                                51.4394968625763
                            ],
                            [
                                7.035176753997803,
                                51.43861407497137
                            ],
                            [
                                7.033889293670654,
                                51.43801216454901
                            ],
                            [
                                7.031893730163574,
                                51.4373032376569
                            ],
                            [
                                7.02974796295166,
                                51.436661181165476
                            ],
                            [
                                7.028481960296631,
                                51.436139503620915
                            ],
                            [
                                7.02725887298584,
                                51.435109507385576
                            ],
                            [
                                7.0264434814453125,
                                51.43395909452503
                            ],
                            [
                                7.024683952331542,
                                51.43298255854997
                            ],
                            [
                                7.023267745971679,
                                51.43215315543816
                            ],
                            [
                                7.022345066070557,
                                51.4313504929286
                            ],
                            [
                                7.021422386169434,
                                51.430868888653954
                            ],
                            [
                                7.019298076629639,
                                51.43009295997757
                            ],
                            [
                                7.017173767089844,
                                51.42882201384946
                            ],
                            [
                                7.015392780303955,
                                51.427872125763955
                            ],
                            [
                                7.013590335845947,
                                51.42653422172796
                            ],
                            [
                                7.011981010437012,
                                51.425370213351016
                            ],
                            [
                                7.010071277618407,
                                51.424032236060306
                            ],
                            [
                                7.008504867553711,
                                51.4233364723875
                            ],
                            [
                                7.006552219390869,
                                51.422761121348984
                            ],
                            [
                                7.003934383392334,
                                51.42241323116056
                            ],
                            [
                                7.002432346343994,
                                51.42241323116056
                            ],
                            [
                                7.000629901885986,
                                51.42261393736169
                            ],
                            [
                                6.997475624084472,
                                51.4229350654501
                            ],
                            [
                                6.993055343627929,
                                51.422894924562435
                            ],
                            [
                                6.989772319793701,
                                51.422547035392576
                            ],
                            [
                                6.986253261566161,
                                51.42170406219061
                            ],
                            [
                                6.9835710525512695,
                                51.4208610734401
                            ],
                            [
                                6.981382369995116,
                                51.41969692055611
                            ],
                            [
                                6.977455615997314,
                                51.41695368443837
                            ],
                            [
                                6.97554588317871,
                                51.415495018679685
                            ],
                            [
                                6.973121166229248,
                                51.4137954136264
                            ],
                            [
                                6.969859600067139,
                                51.4105700051111
                            ],
                            [
                                6.96580410003662,
                                51.407478217388814
                            ],
                            [
                                6.961534023284912,
                                51.40457362029742
                            ],
                            [
                                6.962649822235107,
                                51.40394448970762
                            ],
                            [
                                6.959753036499023,
                                51.40186963599969
                            ],
                            [
                                6.956470012664794,
                                51.40033016758128
                            ],
                            [
                                6.955289840698242,
                                51.399647430218884
                            ],
                            [
                                6.95683479309082,
                                51.399138717323204
                            ]
                        ],
                        [
                            [
                                6.817896366119385,
                                51.442372491756856
                            ],
                            [
                                6.819822192192078,
                                51.44289744172616
                            ],
                            [
                                6.823035478591919,
                                51.444321797867886
                            ],
                            [
                                6.826345324516296,
                                51.44538502067682
                            ],
                            [
                                6.828174591064453,
                                51.44582635117607
                            ],
                            [
                                6.830518841743469,
                                51.44617071973935
                            ],
                            [
                                6.834794282913208,
                                51.44660869930571
                            ],
                            [
                                6.83824896812439,
                                51.44696309191048
                            ],
                            [
                                6.8403249979019165,
                                51.44714363048182
                            ],
                            [
                                6.842186450958252,
                                51.44740106387672
                            ],
                            [
                                6.842953562736511,
                                51.447681898652185
                            ],
                            [
                                6.843747496604919,
                                51.448263622335375
                            ],
                            [
                                6.844058632850647,
                                51.44877513186424
                            ],
                            [
                                6.844112277030944,
                                51.44908270346266
                            ],
                            [
                                6.844874024391174,
                                51.44891554524228
                            ],
                            [
                                6.8454909324646,
                                51.44952065509792
                            ],
                            [
                                6.846365332603455,
                                51.450687391241075
                            ],
                            [
                                6.847432851791382,
                                51.451947700223
                            ],
                            [
                                6.848376989364624,
                                51.45268982875912
                            ],
                            [
                                6.849535703659057,
                                51.45335171700516
                            ],
                            [
                                6.850903630256653,
                                51.45395008246324
                            ],
                            [
                                6.852121353149414,
                                51.454411386791904
                            ],
                            [
                                6.855736970901489,
                                51.45520027324701
                            ],
                            [
                                6.857292652130127,
                                51.45530723985232
                            ],
                            [
                                6.858891248703003,
                                51.455360723061
                            ],
                            [
                                6.859084367752075,
                                51.455554599167094
                            ],
                            [
                                6.859620809555054,
                                51.45554457111286
                            ],
                            [
                                6.859803199768066,
                                51.45543091967728
                            ],
                            [
                                6.862345933914185,
                                51.455574655269004
                            ],
                            [
                                6.864523887634277,
                                51.45564485155632
                            ],
                            [
                                6.867967844009399,
                                51.456183019506014
                            ],
                            [
                                6.8703871965408325,
                                51.45682145896949
                            ],
                            [
                                6.873498558998108,
                                51.45780417032595
                            ],
                            [
                                6.876094937324524,
                                51.45893058554953
                            ],
                            [
                                6.878187060356139,
                                51.45978958381733
                            ],
                            [
                                6.879297494888305,
                                51.46026754083365
                            ],
                            [
                                6.880665421485901,
                                51.460631854358546
                            ],
                            [
                                6.88218355178833,
                                51.460949372948804
                            ],
                            [
                                6.882526874542236,
                                51.4613604726841
                            ],
                            [
                                6.8833208084106445,
                                51.462072368345055
                            ],
                            [
                                6.883712410926819,
                                51.4625001688465
                            ],
                            [
                                6.884699463844299,
                                51.46203560405235
                            ],
                            [
                                6.883379817008972,
                                51.461383868492646
                            ],
                            [
                                6.883841156959534,
                                51.46094268836968
                            ],
                            [
                                6.885552406311035,
                                51.46075217745269
                            ],
                            [
                                6.887290477752685,
                                51.46034107223804
                            ],
                            [
                                6.888990998268127,
                                51.45974279055374
                            ],
                            [
                                6.890814900398254,
                                51.458720011522445
                            ],
                            [
                                6.893073320388794,
                                51.45756016573911
                            ],
                            [
                                6.895465850830078,
                                51.456473828117694
                            ],
                            [
                                6.897987127304077,
                                51.45560473940535
                            ],
                            [
                                6.90116822719574,
                                51.454718920421236
                            ],
                            [
                                6.903528571128844,
                                51.45403365243374
                            ],
                            [
                                6.905497312545776,
                                51.4532848600423
                            ],
                            [
                                6.909542083740234,
                                51.451469656083994
                            ],
                            [
                                6.911591291427612,
                                51.45068404820015
                            ],
                            [
                                6.91364586353302,
                                51.44972792845309
                            ],
                            [
                                6.914541721343994,
                                51.44952399822404
                            ],
                            [
                                6.915196180343628,
                                51.44916628234335
                            ],
                            [
                                6.916424632072449,
                                51.448307084152084
                            ],
                            [
                                6.917658448219299,
                                51.44741109371988
                            ],
                            [
                                6.919562816619873,
                                51.44636129151732
                            ],
                            [
                                6.921231150627136,
                                51.445361616667626
                            ],
                            [
                                6.922239661216736,
                                51.44462605597998
                            ],
                            [
                                6.9232213497161865,
                                51.44326858105691
                            ],
                            [
                                6.924090385437012,
                                51.44138610447434
                            ],
                            [
                                6.924857497215271,
                                51.43961055367917
                            ],
                            [
                                6.925898194313049,
                                51.438390031463115
                            ],
                            [
                                6.927148103713989,
                                51.43736342999027
                            ],
                            [
                                6.92884862422943,
                                51.43662439640565
                            ],
                            [
                                6.930484771728516,
                                51.4362665794691
                            ],
                            [
                                6.931005120277405,
                                51.43630670861087
                            ],
                            [
                                6.931691765785217,
                                51.436196353386165
                            ],
                            [
                                6.931434273719788,
                                51.43572817674089
                            ],
                            [
                                6.934186220169067,
                                51.43525330695611
                            ],
                            [
                                6.936294436454773,
                                51.43515967007786
                            ],
                            [
                                6.936846971511841,
                                51.43535363196983
                            ],
                            [
                                6.938193440437317,
                                51.43576496222242
                            ],
                            [
                                6.939378976821899,
                                51.43602580387814
                            ],
                            [
                                6.941508650779723,
                                51.43623648258964
                            ],
                            [
                                6.943080425262451,
                                51.43652741826049
                            ],
                            [
                                6.944813132286072,
                                51.43716947663211
                            ],
                            [
                                6.946384906768799,
                                51.43769783039798
                            ],
                            [
                                6.947924494743347,
                                51.43832315259095
                            ],
                            [
                                6.94783866405487,
                                51.43865420205084
                            ],
                            [
                                6.950241923332214,
                                51.43933635700813
                            ],
                            [
                                6.952312588691711,
                                51.44004859616499
                            ],
                            [
                                6.9537341594696045,
                                51.440516728528756
                            ],
                            [
                                6.955032348632812,
                                51.44075748044744
                            ],
                            [
                                6.956781148910522,
                                51.440881199689805
                            ],
                            [
                                6.959463357925415,
                                51.441095199750215
                            ],
                            [
                                6.9624245166778564,
                                51.44158672734242
                            ],
                            [
                                6.964312791824341,
                                51.44197459572173
                            ],
                            [
                                6.966909170150757,
                                51.44237917905556
                            ],
                            [
                                6.969623565673827,
                                51.44274697897608
                            ],
                            [
                                6.971876621246338,
                                51.44311477593448
                            ],
                            [
                                6.974515914916991,
                                51.44345582137637
                            ],
                            [
                                6.977047920227051,
                                51.44386039158806
                            ],
                            [
                                6.977675557136536,
                                51.44381023845067
                            ],
                            [
                                6.978780627250671,
                                51.444014194199326
                            ],
                            [
                                6.982015371322632,
                                51.44453912529766
                            ],
                            [
                                6.983077526092529,
                                51.444786542958724
                            ],
                            [
                                6.9846493005752555,
                                51.44495371629514
                            ],
                            [
                                6.985630989074707,
                                51.44506739381436
                            ],
                            [
                                6.985877752304077,
                                51.44527134394832
                            ],
                            [
                                6.986483931541443,
                                51.44536496009824
                            ],
                            [
                                6.987181305885315,
                                51.44522787924204
                            ],
                            [
                                6.990603804588318,
                                51.4457126755462
                            ],
                            [
                                6.992148756980896,
                                51.44590659262675
                            ],
                            [
                                6.995941400527954,
                                51.44653514577891
                            ],
                            [
                                6.997287869453429,
                                51.44675580600385
                            ],
                            [
                                6.99954628944397,
                                51.447100167557664
                            ],
                            [
                                7.000769376754761,
                                51.44722386961774
                            ],
                            [
                                7.001659870147705,
                                51.447274019006066
                            ],
                            [
                                7.002561092376709,
                                51.447501362209096
                            ],
                            [
                                7.003338932991028,
                                51.4477688233517
                            ],
                            [
                                7.004658579826355,
                                51.44839400766141
                            ],
                            [
                                7.006133794784546,
                                51.44906933082755
                            ],
                            [
                                7.006906270980834,
                                51.449403645530275
                            ],
                            [
                                7.008075714111328,
                                51.44979813373105
                            ],
                            [
                                7.010071277618407,
                                51.45015250158151
                            ],
                            [
                                7.011396288871765,
                                51.45023273542995
                            ],
                            [
                                7.013880014419556,
                                51.45028288151361
                            ],
                            [
                                7.016669511795043,
                                51.45044334861124
                            ],
                            [
                                7.018375396728515,
                                51.45030962606904
                            ],
                            [
                                7.019780874252319,
                                51.45044334861124
                            ],
                            [
                                7.021411657333374,
                                51.450824455707696
                            ],
                            [
                                7.023117542266846,
                                51.45125904763712
                            ],
                            [
                                7.024769783020019,
                                51.45159334630627
                            ],
                            [
                                7.025316953659058,
                                51.45162009009407
                            ],
                            [
                                7.027773857116699,
                                51.45182735391821
                            ],
                            [
                                7.029200792312622,
                                51.45211484734225
                            ],
                            [
                                7.031325101852416,
                                51.45275000081441
                            ],
                            [
                                7.032709121704102,
                                51.4532313744015
                            ],
                            [
                                7.034178972244263,
                                51.45364588647862
                            ],
                            [
                                7.035326957702637,
                                51.45385314110579
                            ],
                            [
                                7.038642168045043,
                                51.45435455969882
                            ],
                            [
                                7.040369510650635,
                                51.45466209371099
                            ],
                            [
                                7.041678428649902,
                                51.455223672213336
                            ],
                            [
                                7.043631076812744,
                                51.45574513177949
                            ],
                            [
                                7.045304775238037,
                                51.45664095866534
                            ],
                            [
                                7.0479655265808105,
                                51.458018091082415
                            ],
                            [
                                7.050089836120605,
                                51.459020831263764
                            ],
                            [
                                7.052600383758545,
                                51.46005028826489
                            ],
                            [
                                7.054617404937744,
                                51.460692015945256
                            ],
                            [
                                7.055389881134032,
                                51.461066352925236
                            ],
                            [
                                7.054424285888671,
                                51.46197544423673
                            ],
                            [
                                7.05345869064331,
                                51.46296472891158
                            ],
                            [
                                7.054038047790527,
                                51.463405889487646
                            ],
                            [
                                7.055368423461914,
                                51.46204228847139
                            ],
                            [
                                7.05646276473999,
                                51.46138721075001
                            ],
                            [
                                7.060174942016602,
                                51.462670619491576
                            ],
                            [
                                7.063329219818115,
                                51.4638871507067
                            ],
                            [
                                7.0647239685058585,
                                51.4643684068504
                            ],
                            [
                                7.066440582275391,
                                51.46451545632642
                            ],
                            [
                                7.071676254272461,
                                51.464649137257055
                            ],
                            [
                                7.075152397155762,
                                51.464809553856895
                            ],
                            [
                                7.078542709350586,
                                51.46515712122197
                            ],
                            [
                                7.0801520347595215,
                                51.465371007515415
                            ],
                            [
                                7.082684040069579,
                                51.465518053760604
                            ],
                            [
                                7.086246013641357,
                                51.465825512560286
                            ],
                            [
                                7.088048458099364,
                                51.46622654266394
                            ],
                            [
                                7.090387344360352,
                                51.467496448073646
                            ],
                            [
                                7.092747688293457,
                                51.46891335344456
                            ],
                            [
                                7.09540843963623,
                                51.470450512338864
                            ],
                            [
                                7.097210884094238,
                                51.47147971152115
                            ],
                            [
                                7.098734378814697,
                                51.4721212784844
                            ],
                            [
                                7.100558280944824,
                                51.472736105024595
                            ],
                            [
                                7.102489471435547,
                                51.47331082668864
                            ],
                            [
                                7.1037983894348145,
                                51.4738187142257
                            ],
                            [
                                7.106459140777588,
                                51.472802933497064
                            ],
                            [
                                7.11014986038208,
                                51.47192078977759
                            ],
                            [
                                7.112445831298827,
                                51.47193415571878
                            ],
                            [
                                7.114999294281006,
                                51.4721212784844
                            ],
                            [
                                7.117016315460205,
                                51.47253561901976
                            ],
                            [
                                7.119784355163573,
                                51.473230633402736
                            ],
                            [
                                7.122445106506348,
                                51.47383207961083
                            ],
                            [
                                7.124161720275878,
                                51.47416621296672
                            ],
                            [
                                7.124633789062499,
                                51.474901297734796
                            ],
                            [
                                7.126586437225341,
                                51.47518196333959
                            ],
                            [
                                7.1276164054870605,
                                51.475556181459716
                            ],
                            [
                                7.128860950469971,
                                51.4747542817288
                            ],
                            [
                                7.132401466369628,
                                51.47483447233627
                            ],
                            [
                                7.135426998138428,
                                51.474807742149466
                            ],
                            [
                                7.137422561645508,
                                51.47487456758712
                            ],
                            [
                                7.138645648956299,
                                51.47527551815742
                            ],
                            [
                                7.1401262283325195,
                                51.475582911207965
                            ],
                            [
                                7.1408772468566895,
                                51.47464736069959
                            ],
                            [
                                7.145211696624756,
                                51.47496812303541
                            ],
                            [
                                7.150082588195801,
                                51.47548935702058
                            ],
                            [
                                7.152807712554932,
                                51.475957126038814
                            ],
                            [
                                7.156691551208496,
                                51.47659863003499
                            ],
                            [
                                7.158815860748291,
                                51.47707975211147
                            ],
                            [
                                7.161755561828613,
                                51.4780018885732
                            ],
                            [
                                7.165167331695557,
                                51.479044281254964
                            ],
                            [
                                7.167356014251709,
                                51.47949865009663
                            ],
                            [
                                7.170660495758056,
                                51.480033195879834
                            ],
                            [
                                7.173578739166259,
                                51.48070136929861
                            ],
                            [
                                7.1748876571655265,
                                51.481716974147
                            ],
                            [
                                7.176518440246582,
                                51.482104501614444
                            ],
                            [
                                7.178063392639161,
                                51.48263901685567
                            ],
                            [
                                7.180037498474122,
                                51.483587765979486
                            ],
                            [
                                7.18181848526001,
                                51.48477701517141
                            ],
                            [
                                7.1828484535217285,
                                51.48579252925688
                            ],
                            [
                                7.183771133422851,
                                51.48718214346172
                            ],
                            [
                                7.184414863586426,
                                51.48843810447892
                            ],
                            [
                                7.184693813323975,
                                51.48994788416445
                            ],
                            [
                                7.184479236602783,
                                51.49128393068314
                            ],
                            [
                                7.184329032897949,
                                51.49229929985166
                            ],
                            [
                                7.184479236602783,
                                51.49358183910838
                            ],
                            [
                                7.185273170471191,
                                51.49457043849186
                            ],
                            [
                                7.186152935028075,
                                51.49531855350365
                            ],
                            [
                                7.187633514404296,
                                51.49589299062563
                            ],
                            [
                                7.18855619430542,
                                51.49625368000238
                            ],
                            [
                                7.189757823944092,
                                51.49644070300001
                            ],
                            [
                                7.19203233718872,
                                51.49657429038565
                            ],
                            [
                                7.193298339843749,
                                51.49654757293984
                            ],
                            [
                                7.196087837219238,
                                51.496761312067775
                            ],
                            [
                                7.200336456298827,
                                51.4972422214411
                            ],
                            [
                                7.204692363739013,
                                51.497829992673395
                            ],
                            [
                                7.20503568649292,
                                51.498070442356514
                            ],
                            [
                                7.207996845245361,
                                51.4982440996721
                            ],
                            [
                                7.2092413902282715,
                                51.498377681772325
                            ],
                            [
                                7.209348678588867,
                                51.4987383514873
                            ],
                            [
                                7.209734916687012,
                                51.49955318737237
                            ],
                            [
                                7.211666107177734,
                                51.49990049004296
                            ],
                            [
                                7.214412689208984,
                                51.499646692197885
                            ],
                            [
                                7.215099334716798,
                                51.49887193213894
                            ],
                            [
                                7.217459678649902,
                                51.498791783794935
                            ],
                            [
                                7.220356464385985,
                                51.49884521603993
                            ],
                            [
                                7.222352027893066,
                                51.49869827721546
                            ],
                            [
                                7.224390506744386,
                                51.49843111450277
                            ],
                            [
                                7.22550630569458,
                                51.49817730847518
                            ],
                            [
                                7.227029800415039,
                                51.497896784379265
                            ],
                            [
                                7.228338718414307,
                                51.49732237251
                            ],
                            [
                                7.230699062347412,
                                51.4963605503807
                            ],
                            [
                                7.232329845428467,
                                51.49629375642361
                            ],
                            [
                                7.233166694641113,
                                51.496173527054204
                            ],
                            [
                                7.233595848083497,
                                51.49574604228349
                            ],
                            [
                                7.233531475067139,
                                51.495011293466746
                            ],
                            [
                                7.234926223754883,
                                51.49475746839587
                            ],
                            [
                                7.236750125885009,
                                51.49475746839587
                            ],
                            [
                                7.238402366638183,
                                51.494997934287724
                            ],
                            [
                                7.2402262687683105,
                                51.49550558033815
                            ],
                            [
                                7.242050170898437,
                                51.495665888442645
                            ],
                            [
                                7.244646549224853,
                                51.495679247425926
                            ],
                            [
                                7.247178554534912,
                                51.49538534888975
                            ],
                            [
                                7.251105308532714,
                                51.495038011813065
                            ],
                            [
                                7.2534441947937,
                                51.494997934287724
                            ],
                            [
                                7.254431247711181,
                                51.4957193243522
                            ],
                            [
                                7.255825996398925,
                                51.49669451869797
                            ],
                            [
                                7.25694179534912,
                                51.496654442629094
                            ],
                            [
                                7.25698471069336,
                                51.49509144845867
                            ],
                            [
                                7.261791229248046,
                                51.49575940124326
                            ],
                            [
                                7.265911102294922,
                                51.49660100781579
                            ],
                            [
                                7.266876697540282,
                                51.497081918880454
                            ],
                            [
                                7.267091274261474,
                                51.498017009203224
                            ],
                            [
                                7.2666192054748535,
                                51.49896543836212
                            ],
                            [
                                7.2650957107543945,
                                51.49996727871468
                            ],
                            [
                                7.262263298034668,
                                51.50134310357631
                            ],
                            [
                                7.259838581085205,
                                51.502318177600124
                            ],
                            [
                                7.25970983505249,
                                51.501984250488896
                            ],
                            [
                                7.258036136627197,
                                51.502077750326684
                            ],
                            [
                                7.256855964660644,
                                51.502358248689035
                            ],
                            [
                                7.25644826889038,
                                51.503386727913174
                            ],
                            [
                                7.255439758300781,
                                51.503947706797454
                            ],
                            [
                                7.2507405281066895,
                                51.50531005533675
                            ],
                            [
                                7.241406440734864,
                                51.50735350177636
                            ],
                            [
                                7.233617305755615,
                                51.50903627121008
                            ],
                            [
                                7.22578525543213,
                                51.51073233306422
                            ],
                            [
                                7.219862937927245,
                                51.512121187086656
                            ],
                            [
                                7.216601371765137,
                                51.513363107227256
                            ],
                            [
                                7.214090824127197,
                                51.51457828663463
                            ],
                            [
                                7.212910652160644,
                                51.51578008053786
                            ],
                            [
                                7.212460041046142,
                                51.51563319631723
                            ],
                            [
                                7.209970951080322,
                                51.51659461171048
                            ],
                            [
                                7.209863662719726,
                                51.51758271194066
                            ],
                            [
                                7.21078634262085,
                                51.51839721087734
                            ],
                            [
                                7.209048271179198,
                                51.521708466404974
                            ],
                            [
                                7.20801830291748,
                                51.524485462825666
                            ],
                            [
                                7.207117080688476,
                                51.526487999376876
                            ],
                            [
                                7.206966876983643,
                                51.52942489367756
                            ],
                            [
                                7.2066450119018555,
                                51.53196114993694
                            ],
                            [
                                7.205078601837158,
                                51.53206793657423
                            ],
                            [
                                7.204284667968749,
                                51.532414991415465
                            ],
                            [
                                7.204284667968749,
                                51.53305570109414
                            ],
                            [
                                7.204864025115968,
                                51.534270355105946
                            ],
                            [
                                7.206087112426757,
                                51.53492438614894
                            ],
                            [
                                7.205915451049805,
                                51.53617903164019
                            ],
                            [
                                7.203876972198487,
                                51.539168685754454
                            ],
                            [
                                7.2020745277404785,
                                51.54125065029291
                            ],
                            [
                                7.200250625610351,
                                51.54302558330498
                            ],
                            [
                                7.198898792266845,
                                51.54341258958052
                            ],
                            [
                                7.194972038269043,
                                51.543599419018264
                            ],
                            [
                                7.191195487976074,
                                51.54345262452462
                            ],
                            [
                                7.186067104339599,
                                51.543492659433475
                            ],
                            [
                                7.182011604309082,
                                51.543332519586656
                            ],
                            [
                                7.178342342376708,
                                51.542852096664774
                            ],
                            [
                                7.175917625427245,
                                51.542411704530636
                            ],
                            [
                                7.174394130706786,
                                51.54157094407588
                            ],
                            [
                                7.174201011657716,
                                51.54063674757441
                            ],
                            [
                                7.173771858215332,
                                51.540209679929376
                            ],
                            [
                                7.172291278839111,
                                51.540650093373756
                            ],
                            [
                                7.172484397888184,
                                51.54158428960126
                            ],
                            [
                                7.170875072479248,
                                51.54193127188712
                            ],
                            [
                                7.1683430671691895,
                                51.54194461730684
                            ],
                            [
                                7.164266109466553,
                                51.541717744639676
                            ],
                            [
                                7.161540985107422,
                                51.541557598546596
                            ],
                            [
                                7.15883731842041,
                                51.54158428960126
                            ],
                            [
                                7.154953479766846,
                                51.54177112654547
                            ],
                            [
                                7.152850627899169,
                                51.541717744639676
                            ],
                            [
                                7.150318622589111,
                                51.54115723084824
                            ],
                            [
                                7.1500396728515625,
                                51.53959576316829
                            ],
                            [
                                7.149202823638916,
                                51.53959576316829
                            ],
                            [
                                7.1472930908203125,
                                51.54034313899898
                            ],
                            [
                                7.142572402954102,
                                51.538888414023596
                            ],
                            [
                                7.139890193939209,
                                51.538314518903526
                            ],
                            [
                                7.136971950531006,
                                51.53799420220145
                            ],
                            [
                                7.133538722991943,
                                51.53759380315364
                            ],
                            [
                                7.129397392272949,
                                51.537366908796294
                            ],
                            [
                                7.128453254699707,
                                51.5373402152681
                            ],
                            [
                                7.1283674240112305,
                                51.53660613710846
                            ],
                            [
                                7.1277666091918945,
                                51.53628580838298
                            ],
                            [
                                7.126543521881103,
                                51.536032213209815
                            ],
                            [
                                7.126307487487793,
                                51.537366908796294
                            ],
                            [
                                7.119333744049072,
                                51.53771392323784
                            ],
                            [
                                7.117273807525634,
                                51.53770057657746
                            ],
                            [
                                7.114977836608887,
                                51.5374870294793
                            ],
                            [
                                7.108626365661621,
                                51.53680634141707
                            ],
                            [
                                7.104613780975341,
                                51.53639258487529
                            ],
                            [
                                7.101759910583496,
                                51.53599217173754
                            ],
                            [
                                7.099184989929199,
                                51.53532480868011
                            ],
                            [
                                7.09791898727417,
                                51.53476421615017
                            ],
                            [
                                7.097747325897217,
                                51.53362966252356
                            ],
                            [
                                7.09641695022583,
                                51.53318918114215
                            ],
                            [
                                7.095129489898682,
                                51.53298896092339
                            ],
                            [
                                7.09489345550537,
                                51.53364301037767
                            ],
                            [
                                7.090044021606444,
                                51.53276204361083
                            ],
                            [
                                7.0860958099365225,
                                51.53245503603458
                            ],
                            [
                                7.084314823150635,
                                51.5322147677915
                            ],
                            [
                                7.081804275512695,
                                51.53169418224775
                            ],
                            [
                                7.079980373382568,
                                51.53110684782056
                            ],
                            [
                                7.077534198760986,
                                51.52974527068611
                            ],
                            [
                                7.075989246368408,
                                51.528210110372775
                            ],
                            [
                                7.075023651123047,
                                51.52652804920958
                            ],
                            [
                                7.074337005615234,
                                51.52460561750232
                            ],
                            [
                                7.073457241058349,
                                51.523003529077705
                            ],
                            [
                                7.073564529418945,
                                51.52217576130714
                            ],
                            [
                                7.072577476501465,
                                51.52201554645227
                            ],
                            [
                                7.070302963256836,
                                51.52077386221533
                            ],
                            [
                                7.06796407699585,
                                51.519852590742715
                            ],
                            [
                                7.064166069030761,
                                51.51822363035807
                            ],
                            [
                                7.061033248901367,
                                51.516794904029844
                            ],
                            [
                                7.05747127532959,
                                51.515406192499135
                            ],
                            [
                                7.053802013397217,
                                51.514765234435444
                            ],
                            [
                                7.050325870513916,
                                51.51436463106562
                            ],
                            [
                                7.047622203826903,
                                51.51429786349477
                            ],
                            [
                                7.046656608581542,
                                51.5136168286822
                            ],
                            [
                                7.043695449829101,
                                51.51356341375649
                            ],
                            [
                                7.042644023895264,
                                51.51424444936764
                            ],
                            [
                                7.037665843963623,
                                51.51412426735259
                            ],
                            [
                                7.03397512435913,
                                51.51381713409578
                            ],
                            [
                                7.031099796295165,
                                51.513282984368956
                            ],
                            [
                                7.028331756591797,
                                51.51276218235477
                            ],
                            [
                                7.025864124298096,
                                51.51196093686036
                            ],
                            [
                                7.024040222167969,
                                51.51110625946627
                            ],
                            [
                                7.020821571350097,
                                51.50982421331023
                            ],
                            [
                                7.016873359680176,
                                51.50827502606943
                            ],
                            [
                                7.014105319976807,
                                51.50743363506349
                            ],
                            [
                                7.011744976043701,
                                51.50742027952542
                            ],
                            [
                                7.007496356964111,
                                51.50726001276327
                            ],
                            [
                                7.00352668762207,
                                51.50692612186484
                            ],
                            [
                                7.001123428344727,
                                51.50639189133809
                            ],
                            [
                                6.999492645263672,
                                51.50543026060529
                            ],
                            [
                                6.997926235198974,
                                51.503346657728606
                            ],
                            [
                                6.996960639953612,
                                51.50185067895931
                            ],
                            [
                                6.995673179626465,
                                51.50222467825562
                            ],
                            [
                                6.9953083992004395,
                                51.50337337118891
                            ],
                            [
                                6.993870735168457,
                                51.50385421079633
                            ],
                            [
                                6.992690563201904,
                                51.503707288121234
                            ],
                            [
                                6.989986896514893,
                                51.503186376638006
                            ],
                            [
                                6.987905502319336,
                                51.50282574212297
                            ],
                            [
                                6.984536647796631,
                                51.50270552998373
                            ],
                            [
                                6.9812750816345215,
                                51.503106235881305
                            ],
                            [
                                6.978957653045653,
                                51.50380078442383
                            ],
                            [
                                6.975867748260498,
                                51.50484258738895
                            ],
                            [
                                6.971554756164551,
                                51.50617819737355
                            ],
                            [
                                6.96732759475708,
                                51.506845987685146
                            ],
                            [
                                6.962413787841797,
                                51.5073401462148
                            ],
                            [
                                6.958765983581543,
                                51.50764732314014
                            ],
                            [
                                6.9539594650268555,
                                51.50778087767913
                            ],
                            [
                                6.950740814208984,
                                51.507593901214925
                            ],
                            [
                                6.947286128997802,
                                51.50728672392945
                            ],
                            [
                                6.944947242736816,
                                51.50687269909404
                            ],
                            [
                                6.941642761230469,
                                51.50611141780412
                            ],
                            [
                                6.938896179199219,
                                51.505350123794834
                            ],
                            [
                                6.936535835266112,
                                51.504401827502825
                            ],
                            [
                                6.936407089233398,
                                51.503480224873485
                            ],
                            [
                                6.93462610244751,
                                51.503199733417084
                            ],
                            [
                                6.933639049530028,
                                51.50327987400937
                            ],
                            [
                                6.9312357902526855,
                                51.50230482056266
                            ],
                            [
                                6.928102970123291,
                                51.500982454474055
                            ],
                            [
                                6.924455165863037,
                                51.49957990305636
                            ],
                            [
                                6.9222235679626465,
                                51.49884521603993
                            ],
                            [
                                6.919713020324707,
                                51.498417756326035
                            ],
                            [
                                6.916987895965576,
                                51.49808380063506
                            ],
                            [
                                6.914219856262207,
                                51.497896784379265
                            ],
                            [
                                6.910743713378905,
                                51.49765633378002
                            ],
                            [
                                6.906065940856934,
                                51.49752274956565
                            ],
                            [
                                6.899757385253906,
                                51.49732237251
                            ],
                            [
                                6.89465045928955,
                                51.496814746693175
                            ],
                            [
                                6.8912386894226065,
                                51.49638726793613
                            ],
                            [
                                6.889629364013672,
                                51.49598650296033
                            ],
                            [
                                6.88490867614746,
                                51.49621360354591
                            ],
                            [
                                6.880788803100586,
                                51.49612009167713
                            ],
                            [
                                6.87443733215332,
                                51.49598650296033
                            ],
                            [
                                6.868236064910889,
                                51.495933067364
                            ],
                            [
                                6.866390705108642,
                                51.49585291385202
                            ],
                            [
                                6.864137649536133,
                                51.49575940124326
                            ],
                            [
                                6.86370849609375,
                                51.49555901643561
                            ],
                            [
                                6.862635612487793,
                                51.49558573446086
                            ],
                            [
                                6.860618591308594,
                                51.495438785128194
                            ],
                            [
                                6.8599748611450195,
                                51.49550558033815
                            ],
                            [
                                6.858558654785155,
                                51.49629375642361
                            ],
                            [
                                6.855297088623047,
                                51.496427344239926
                            ],
                            [
                                6.852421760559082,
                                51.49638726793613
                            ],
                            [
                                6.850147247314453,
                                51.49612009167713
                            ],
                            [
                                6.847851276397704,
                                51.495599093467625
                            ],
                            [
                                6.845855712890625,
                                51.4949444975325
                            ],
                            [
                                6.844182014465332,
                                51.49406278202701
                            ],
                            [
                                6.842551231384277,
                                51.49316768975135
                            ],
                            [
                                6.84072732925415,
                                51.491778257971646
                            ],
                            [
                                6.839032173156738,
                                51.49040214437382
                            ],
                            [
                                6.839439868927002,
                                51.48867860370568
                            ],
                            [
                                6.837680339813232,
                                51.4885182710288
                            ],
                            [
                                6.8361568450927725,
                                51.488585076379344
                            ],
                            [
                                6.83521270751953,
                                51.48811743686986
                            ],
                            [
                                6.832830905914307,
                                51.48710197456258
                            ],
                            [
                                6.83049201965332,
                                51.486447256610255
                            ],
                            [
                                6.828217506408691,
                                51.486059766046814
                            ],
                            [
                                6.825428009033202,
                                51.48576580549173
                            ],
                            [
                                6.823389530181885,
                                51.48576580549173
                            ],
                            [
                                6.820600032806396,
                                51.486019680628154
                            ],
                            [
                                6.817874908447266,
                                51.48639380983175
                            ],
                            [
                                6.81626558303833,
                                51.48652742666053
                            ],
                            [
                                6.815364360809326,
                                51.486233469120386
                            ],
                            [
                                6.814634799957275,
                                51.48559210063662
                            ],
                            [
                                6.814162731170654,
                                51.48448304634681
                            ],
                            [
                                6.813862323760986,
                                51.48316016318391
                            ],
                            [
                                6.813776493072509,
                                51.48100872578405
                            ],
                            [
                                6.814098358154297,
                                51.4796189234441
                            ],
                            [
                                6.814892292022705,
                                51.47764105478667
                            ],
                            [
                                6.815385818481445,
                                51.477066387677866
                            ],
                            [
                                6.8163299560546875,
                                51.47701292990431
                            ],
                            [
                                6.816866397857666,
                                51.47659863003499
                            ],
                            [
                                6.817123889923095,
                                51.4755695463358
                            ],
                            [
                                6.815900802612305,
                                51.4752621531952
                            ],
                            [
                                6.816501617431641,
                                51.47352467476185
                            ],
                            [
                                6.816973686218262,
                                51.471145558487876
                            ],
                            [
                                6.816973686218262,
                                51.467750424914485
                            ],
                            [
                                6.816222667694092,
                                51.4652774323854
                            ],
                            [
                                6.814999580383301,
                                51.463285573389854
                            ],
                            [
                                6.813411712646484,
                                51.46189523102591
                            ],
                            [
                                6.8115234375,
                                51.46067864671062
                            ],
                            [
                                6.809098720550537,
                                51.45974279055374
                            ],
                            [
                                6.806437969207763,
                                51.458646477506335
                            ],
                            [
                                6.803369522094727,
                                51.45717577230565
                            ],
                            [
                                6.801609992980957,
                                51.45571839040817
                            ],
                            [
                                6.800022125244141,
                                51.45371274291277
                            ],
                            [
                                6.799485683441162,
                                51.45093143256645
                            ],
                            [
                                6.799249649047852,
                                51.44840403728644
                            ],
                            [
                                6.799464225769043,
                                51.446250960584905
                            ],
                            [
                                6.802532672882079,
                                51.44480660379139
                            ],
                            [
                                6.805214881896973,
                                51.44419140091356
                            ],
                            [
                                6.806137561798096,
                                51.443562815499966
                            ],
                            [
                                6.807618141174316,
                                51.44312146312449
                            ],
                            [
                                6.809248924255371,
                                51.44285397476038
                            ],
                            [
                                6.811738014221191,
                                51.4447129864968
                            ],
                            [
                                6.817896366119385,
                                51.442372491756856
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--41",
            "properties": {
                "land": "de",
                "city": "Marburg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.marburg.de/portal/seiten/umweltzone-marburg-900001155-23001.html?rubrik=900000045",
                "urlPark": "https://www.stadtwerke-marburg.de/verkehr/parken/parkride",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                8.766660690307617,
                                50.82686691511868
                            ],
                            [
                                8.766295909881592,
                                50.82975384461045
                            ],
                            [
                                8.766875267028809,
                                50.83375187933079
                            ],
                            [
                                8.76835584640503,
                                50.836922958237324
                            ],
                            [
                                8.76009464263916,
                                50.837532756402396
                            ],
                            [
                                8.759171962738035,
                                50.83844066335112
                            ],
                            [
                                8.758699893951416,
                                50.840066721199676
                            ],
                            [
                                8.755781650543213,
                                50.84390128324569
                            ],
                            [
                                8.75530958175659,
                                50.84688199716338
                            ],
                            [
                                8.752906322479248,
                                50.84791165407802
                            ],
                            [
                                8.751232624053955,
                                50.847735530164364
                            ],
                            [
                                8.746490478515625,
                                50.84578457464391
                            ],
                            [
                                8.742198944091797,
                                50.84296638374065
                            ],
                            [
                                8.742027282714844,
                                50.838630373019775
                            ],
                            [
                                8.747692108154297,
                                50.83586595587541
                            ],
                            [
                                8.750867843627928,
                                50.8328303288168
                            ],
                            [
                                8.760395050048828,
                                50.827517506362035
                            ],
                            [
                                8.760416507720947,
                                50.82299028767253
                            ],
                            [
                                8.75481605529785,
                                50.82171608109489
                            ],
                            [
                                8.748142719268799,
                                50.821295856407346
                            ],
                            [
                                8.74507427215576,
                                50.81954713886873
                            ],
                            [
                                8.737564086914062,
                                50.81865242085029
                            ],
                            [
                                8.730998039245605,
                                50.811846580302166
                            ],
                            [
                                8.729667663574219,
                                50.804036266486364
                            ],
                            [
                                8.731727600097656,
                                50.79828617331057
                            ],
                            [
                                8.74460220336914,
                                50.79606189089025
                            ],
                            [
                                8.751382827758789,
                                50.79513959640007
                            ],
                            [
                                8.753228187561035,
                                50.79595338660077
                            ],
                            [
                                8.759300708770752,
                                50.797146919930135
                            ],
                            [
                                8.762476444244385,
                                50.795899134361555
                            ],
                            [
                                8.765566349029541,
                                50.79846248385208
                            ],
                            [
                                8.771231174468994,
                                50.80076802201682
                            ],
                            [
                                8.774728775024414,
                                50.80200211629311
                            ],
                            [
                                8.776359558105469,
                                50.80360232187604
                            ],
                            [
                                8.777647018432617,
                                50.80563640240476
                            ],
                            [
                                8.777647018432617,
                                50.80657204971098
                            ],
                            [
                                8.774857521057129,
                                50.8076297154064
                            ],
                            [
                                8.775200843811035,
                                50.808104301204715
                            ],
                            [
                                8.778290748596191,
                                50.80852464459985
                            ],
                            [
                                8.778655529022215,
                                50.81020598035826
                            ],
                            [
                                8.77833366394043,
                                50.81335154279877
                            ],
                            [
                                8.77659559249878,
                                50.815195394710656
                            ],
                            [
                                8.772819042205809,
                                50.817933922758414
                            ],
                            [
                                8.770909309387207,
                                50.82004871571348
                            ],
                            [
                                8.769664764404297,
                                50.82167541435493
                            ],
                            [
                                8.768720626831055,
                                50.82313939468026
                            ],
                            [
                                8.767647743225098,
                                50.82465754801618
                            ],
                            [
                                8.765501976013182,
                                50.82483375904525
                            ],
                            [
                                8.765437602996826,
                                50.825836793774
                            ],
                            [
                                8.766660690307617,
                                50.82686691511868
                            ]
                        ],
                        [
                            [
                                8.768548965454102,
                                50.82492864163161
                            ],
                            [
                                8.7705659866333,
                                50.82182452556165
                            ],
                            [
                                8.772754669189453,
                                50.82263785103416
                            ],
                            [
                                8.77309799194336,
                                50.82152630267206
                            ],
                            [
                                8.771402835845947,
                                50.82083496046597
                            ],
                            [
                                8.7735915184021,
                                50.8182186120039
                            ],
                            [
                                8.777539730072021,
                                50.815398756273325
                            ],
                            [
                                8.778998851776123,
                                50.81390741758378
                            ],
                            [
                                8.779492378234863,
                                50.81211774829736
                            ],
                            [
                                8.779299259185791,
                                50.80836193147623
                            ],
                            [
                                8.781144618988037,
                                50.807914467463924
                            ],
                            [
                                8.780415058135986,
                                50.80613812865256
                            ],
                            [
                                8.778269290924072,
                                50.8052295933835
                            ],
                            [
                                8.776724338531492,
                                50.802517442717615
                            ],
                            [
                                8.774278163909912,
                                50.801079939032675
                            ],
                            [
                                8.769879341125488,
                                50.79953389514707
                            ],
                            [
                                8.765995502471922,
                                50.79781148777718
                            ],
                            [
                                8.762927055358887,
                                50.79516672296839
                            ],
                            [
                                8.760974407196045,
                                50.79273883275006
                            ],
                            [
                                8.75985860824585,
                                50.79020229877231
                            ],
                            [
                                8.766295909881592,
                                50.79139597896673
                            ],
                            [
                                8.767969608306883,
                                50.78961901213635
                            ],
                            [
                                8.767690658569336,
                                50.7879098047356
                            ],
                            [
                                8.77432107925415,
                                50.786607509532764
                            ],
                            [
                                8.778526782989502,
                                50.78541370706158
                            ],
                            [
                                8.779449462890625,
                                50.785332310328485
                            ],
                            [
                                8.773355484008789,
                                50.79318644210486
                            ],
                            [
                                8.774900436401367,
                                50.795248102578874
                            ],
                            [
                                8.784513473510742,
                                50.79698416718006
                            ],
                            [
                                8.791100978851318,
                                50.79744529849984
                            ],
                            [
                                8.7935471534729,
                                50.800062810653735
                            ],
                            [
                                8.79155158996582,
                                50.801107062153044
                            ],
                            [
                                8.790950775146484,
                                50.802029238878184
                            ],
                            [
                                8.796014785766602,
                                50.80772463295176
                            ],
                            [
                                8.789663314819336,
                                50.80712800517717
                            ],
                            [
                                8.786916732788084,
                                50.81472088095414
                            ],
                            [
                                8.788118362426758,
                                50.82084851629391
                            ],
                            [
                                8.783719539642334,
                                50.82239385487996
                            ],
                            [
                                8.77957820892334,
                                50.82353249269084
                            ],
                            [
                                8.777668476104735,
                                50.82492864163161
                            ],
                            [
                                8.777754306793213,
                                50.82609432624109
                            ],
                            [
                                8.781573772430418,
                                50.828032551332946
                            ],
                            [
                                8.780457973480225,
                                50.82975384461045
                            ],
                            [
                                8.777539730072021,
                                50.83013333380169
                            ],
                            [
                                8.774878978729248,
                                50.8277614757408
                            ],
                            [
                                8.772304058074951,
                                50.82757172188971
                            ],
                            [
                                8.772325515747069,
                                50.82597233735481
                            ],
                            [
                                8.769900798797606,
                                50.825701249799664
                            ],
                            [
                                8.768548965454102,
                                50.82492864163161
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--42",
            "properties": {
                "land": "de",
                "city": "Muenster",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.stadt-muenster.de/umwelt/immissionsschutz/luft/umweltzone.html",
                "urlPark": "https://www.stadtwerke-muenster.de/hilfe/mobilitaet/park-and-ride",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            7.6265716552734375,
                            51.95529492738267
                        ],
                        [
                            7.630863189697265,
                            51.953906401505336
                        ],
                        [
                            7.631056308746338,
                            51.954237006804924
                        ],
                        [
                            7.6317644119262695,
                            51.95407831656533
                        ],
                        [
                            7.633094787597655,
                            51.955559403626914
                        ],
                        [
                            7.634553909301758,
                            51.95673630399606
                        ],
                        [
                            7.635262012481689,
                            51.95897100731088
                        ],
                        [
                            7.635648250579835,
                            51.96214435906539
                        ],
                        [
                            7.634575366973876,
                            51.965806656628295
                        ],
                        [
                            7.633116245269775,
                            51.96547613664187
                        ],
                        [
                            7.632107734680175,
                            51.96679820196322
                        ],
                        [
                            7.6306915283203125,
                            51.96700972879559
                        ],
                        [
                            7.630176544189452,
                            51.96735345776896
                        ],
                        [
                            7.625176906585693,
                            51.96652057148062
                        ],
                        [
                            7.623460292816162,
                            51.96625615989829
                        ],
                        [
                            7.6230525970458975,
                            51.966454468731264
                        ],
                        [
                            7.620370388031006,
                            51.966956847181095
                        ],
                        [
                            7.617344856262206,
                            51.96673209962335
                        ],
                        [
                            7.615563869476318,
                            51.96735345776896
                        ],
                        [
                            7.614641189575196,
                            51.96703616957945
                        ],
                        [
                            7.616636753082275,
                            51.966123953522164
                        ],
                        [
                            7.6174092292785645,
                            51.96456388883217
                        ],
                        [
                            7.617194652557373,
                            51.962395573150054
                        ],
                        [
                            7.617173194885254,
                            51.960425488083416
                        ],
                        [
                            7.618417739868163,
                            51.95920901648469
                        ],
                        [
                            7.619211673736572,
                            51.957648711112476
                        ],
                        [
                            7.6143407821655265,
                            51.95813796525144
                        ],
                        [
                            7.613954544067383,
                            51.95770160370922
                        ],
                        [
                            7.618954181671142,
                            51.95714622833104
                        ],
                        [
                            7.619190216064452,
                            51.955612298688564
                        ],
                        [
                            7.616915702819824,
                            51.95435602411603
                        ],
                        [
                            7.617237567901611,
                            51.95407831656533
                        ],
                        [
                            7.619383335113525,
                            51.95381383158463
                        ],
                        [
                            7.618782520294189,
                            51.95284844816569
                        ],
                        [
                            7.619791030883789,
                            51.9527162022431
                        ],
                        [
                            7.620327472686768,
                            51.954805642218076
                        ],
                        [
                            7.624318599700928,
                            51.95540071806757
                        ],
                        [
                            7.6265716552734375,
                            51.95529492738267
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Wuppertal",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.wuppertal.de/rathaus-buergerservice/umweltschutz/immission/102370100000187581.php",
                "urlPark": "https://www.wuppertal.de/microsite/park_and_ride/index.php",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                7.088799476623535,
                                51.24018421709266
                            ],
                            [
                                7.0867180824279785,
                                51.23996256005723
                            ],
                            [
                                7.0841968059539795,
                                51.23914309144089
                            ],
                            [
                                7.080913782119751,
                                51.237551131175444
                            ],
                            [
                                7.081718444824218,
                                51.23657040249303
                            ],
                            [
                                7.072985172271728,
                                51.23265399665872
                            ],
                            [
                                7.0722877979278564,
                                51.23304363687189
                            ],
                            [
                                7.0709145069122314,
                                51.23266743258305
                            ],
                            [
                                7.070560455322265,
                                51.231921732848306
                            ],
                            [
                                7.069884538650513,
                                51.231814243601754
                            ],
                            [
                                7.069809436798096,
                                51.23122976642884
                            ],
                            [
                                7.068253755569458,
                                51.23053107133107
                            ],
                            [
                                7.065035104751587,
                                51.22986595748776
                            ],
                            [
                                7.065421342849731,
                                51.229429261352614
                            ],
                            [
                                7.0638978481292725,
                                51.22887834647055
                            ],
                            [
                                7.0653462409973145,
                                51.227501030411
                            ],
                            [
                                7.06621527671814,
                                51.22593554308287
                            ],
                            [
                                7.068157196044922,
                                51.22552568567358
                            ],
                            [
                                7.069755792617797,
                                51.22623789457001
                            ],
                            [
                                7.071923017501831,
                                51.22613711096165
                            ],
                            [
                                7.073564529418945,
                                51.22545177657199
                            ],
                            [
                                7.074894905090331,
                                51.224867218587086
                            ],
                            [
                                7.07544207572937,
                                51.22510238648512
                            ],
                            [
                                7.07417607307434,
                                51.22845507811767
                            ],
                            [
                                7.074755430221558,
                                51.22916052320967
                            ],
                            [
                                7.078456878662109,
                                51.22946285351015
                            ],
                            [
                                7.080709934234619,
                                51.2295837850742
                            ],
                            [
                                7.081203460693359,
                                51.22940238760896
                            ],
                            [
                                7.083477973937988,
                                51.23011453651384
                            ],
                            [
                                7.087297439575195,
                                51.232801791610726
                            ],
                            [
                                7.090033292770386,
                                51.23267415054375
                            ],
                            [
                                7.090870141983032,
                                51.233251891494845
                            ],
                            [
                                7.0894646644592285,
                                51.235206751842
                            ],
                            [
                                7.087866067886352,
                                51.23643605447005
                            ],
                            [
                                7.08867073059082,
                                51.23679879323171
                            ],
                            [
                                7.091535329818726,
                                51.237792951473104
                            ],
                            [
                                7.091653347015381,
                                51.2391498084561
                            ],
                            [
                                7.091470956802368,
                                51.23959984624086
                            ],
                            [
                                7.092146873474121,
                                51.240345421538514
                            ],
                            [
                                7.088799476623535,
                                51.24018421709266
                            ]
                        ],
                        [
                            [
                                7.119312286376953,
                                51.262210254503
                            ],
                            [
                                7.11888313293457,
                                51.261001782152064
                            ],
                            [
                                7.121329307556152,
                                51.26038410623664
                            ],
                            [
                                7.122659683227538,
                                51.259712709958144
                            ],
                            [
                                7.122616767883301,
                                51.25742988926045
                            ],
                            [
                                7.123904228210449,
                                51.25520067259869
                            ],
                            [
                                7.123432159423828,
                                51.25248786556346
                            ],
                            [
                                7.124032974243164,
                                51.2497211747292
                            ],
                            [
                                7.121543884277344,
                                51.249157071702165
                            ],
                            [
                                7.12064266204834,
                                51.247572173785265
                            ],
                            [
                                7.127594947814941,
                                51.24660509059691
                            ],
                            [
                                7.128410339355469,
                                51.24593349308464
                            ],
                            [
                                7.129697799682617,
                                51.24665881797422
                            ],
                            [
                                7.133045196533203,
                                51.24703490785793
                            ],
                            [
                                7.137250900268554,
                                51.24792139327224
                            ],
                            [
                                7.138881683349609,
                                51.247464721101935
                            ],
                            [
                                7.138495445251465,
                                51.246309588899756
                            ],
                            [
                                7.14137077331543,
                                51.245826036572566
                            ],
                            [
                                7.142271995544434,
                                51.24381118048449
                            ],
                            [
                                7.143731117248535,
                                51.24416042853021
                            ],
                            [
                                7.143945693969727,
                                51.24364998818434
                            ],
                            [
                                7.144289016723633,
                                51.241903702056796
                            ],
                            [
                                7.147550582885741,
                                51.2433813330954
                            ],
                            [
                                7.1448469161987305,
                                51.24639018046001
                            ],
                            [
                                7.1466064453125,
                                51.247652763133026
                            ],
                            [
                                7.147636413574219,
                                51.2480557077534
                            ],
                            [
                                7.149524688720703,
                                51.24824374736803
                            ],
                            [
                                7.1529150009155265,
                                51.245637987072314
                            ],
                            [
                                7.154846191406249,
                                51.24351566083601
                            ],
                            [
                                7.155704498291016,
                                51.24391864170407
                            ],
                            [
                                7.1541595458984375,
                                51.24612154137664
                            ],
                            [
                                7.154846191406249,
                                51.24673940892249
                            ],
                            [
                                7.1567344665527335,
                                51.24759903691687
                            ],
                            [
                                7.156219482421875,
                                51.248807861598635
                            ],
                            [
                                7.159695625305175,
                                51.251144832550985
                            ],
                            [
                                7.163343429565429,
                                51.252595306509825
                            ],
                            [
                                7.164630889892578,
                                51.25380399985758
                            ],
                            [
                                7.171926498413085,
                                51.25434118670208
                            ],
                            [
                                7.1736860275268555,
                                51.25847731518399
                            ],
                            [
                                7.175745964050292,
                                51.25939043626131
                            ],
                            [
                                7.178363800048828,
                                51.25654358714669
                            ],
                            [
                                7.189135551452637,
                                51.259820134021695
                            ],
                            [
                                7.195615768432618,
                                51.26022297202415
                            ],
                            [
                                7.19651699066162,
                                51.26170001449684
                            ],
                            [
                                7.199392318725585,
                                51.26320386352366
                            ],
                            [
                                7.203040122985839,
                                51.266426231471925
                            ],
                            [
                                7.20600128173828,
                                51.267124381415876
                            ],
                            [
                                7.206559181213379,
                                51.2676077097796
                            ],
                            [
                                7.212224006652832,
                                51.269353019883276
                            ],
                            [
                                7.215828895568847,
                                51.26913821606296
                            ],
                            [
                                7.220335006713867,
                                51.267043826194396
                            ],
                            [
                                7.224884033203126,
                                51.26870860541008
                            ],
                            [
                                7.225613594055175,
                                51.26978262451168
                            ],
                            [
                                7.227158546447754,
                                51.27010482534727
                            ],
                            [
                                7.231020927429199,
                                51.271125113089084
                            ],
                            [
                                7.235183715820312,
                                51.268252139690674
                            ],
                            [
                                7.2363853454589835,
                                51.26937987029022
                            ],
                            [
                                7.236943244934082,
                                51.27206483175651
                            ],
                            [
                                7.235870361328125,
                                51.27469594178418
                            ],
                            [
                                7.241792678833008,
                                51.27611882626784
                            ],
                            [
                                7.242822647094727,
                                51.2750718023457
                            ],
                            [
                                7.243595123291016,
                                51.27544765983238
                            ],
                            [
                                7.24273681640625,
                                51.278561789292226
                            ],
                            [
                                7.244153022766112,
                                51.27907184210145
                            ],
                            [
                                7.242994308471679,
                                51.28135358796176
                            ],
                            [
                                7.243638038635254,
                                51.28315206070123
                            ],
                            [
                                7.240419387817384,
                                51.28473573211808
                            ],
                            [
                                7.237501144409181,
                                51.28747347593227
                            ],
                            [
                                7.232351303100585,
                                51.28720507689187
                            ],
                            [
                                7.231020927429199,
                                51.28607778379228
                            ],
                            [
                                7.22900390625,
                                51.285433603882936
                            ],
                            [
                                7.227416038513183,
                                51.28583621738515
                            ],
                            [
                                7.2280168533325195,
                                51.28642671080388
                            ],
                            [
                                7.2269439697265625,
                                51.286856155794155
                            ],
                            [
                                7.219820022583007,
                                51.28766135432723
                            ],
                            [
                                7.218875885009765,
                                51.28680247538999
                            ],
                            [
                                7.2138118743896475,
                                51.28768819403519
                            ],
                            [
                                7.2110652923583975,
                                51.28613146504354
                            ],
                            [
                                7.20801830291748,
                                51.28599726179775
                            ],
                            [
                                7.203297615051269,
                                51.28779555271015
                            ],
                            [
                                7.201023101806641,
                                51.28779555271015
                            ],
                            [
                                7.200422286987304,
                                51.287097716836456
                            ],
                            [
                                7.201409339904786,
                                51.28567517240781
                            ],
                            [
                                7.204971313476562,
                                51.28634618942108
                            ],
                            [
                                7.205700874328613,
                                51.284494158651846
                            ],
                            [
                                7.201323509216308,
                                51.28352785208052
                            ],
                            [
                                7.19552993774414,
                                51.28264205319712
                            ],
                            [
                                7.1956586837768555,
                                51.28352785208052
                            ],
                            [
                                7.188792228698731,
                                51.28272258107425
                            ],
                            [
                                7.183213233947754,
                                51.280467747146425
                            ],
                            [
                                7.185273170471191,
                                51.278373873671775
                            ],
                            [
                                7.182397842407227,
                                51.27678998289831
                            ],
                            [
                                7.181367874145508,
                                51.278266492972115
                            ],
                            [
                                7.1764326095581055,
                                51.27778327671744
                            ],
                            [
                                7.1744585037231445,
                                51.27716582632859
                            ],
                            [
                                7.171754837036133,
                                51.27566243415853
                            ],
                            [
                                7.168321609497071,
                                51.27311192420464
                            ],
                            [
                                7.165231704711913,
                                51.27225277318377
                            ],
                            [
                                7.15531826019287,
                                51.27018537520318
                            ],
                            [
                                7.154760360717773,
                                51.26903081377626
                            ],
                            [
                                7.1529150009155265,
                                51.26852064948648
                            ],
                            [
                                7.151026725769043,
                                51.268574351257385
                            ],
                            [
                                7.1497392654418945,
                                51.26932616946066
                            ],
                            [
                                7.1378087997436515,
                                51.266372527190775
                            ],
                            [
                                7.130995988845825,
                                51.265318567972564
                            ],
                            [
                                7.131392955780029,
                                51.2646069640041
                            ],
                            [
                                7.128978967666626,
                                51.26400276330663
                            ],
                            [
                                7.127305269241332,
                                51.2640766104846
                            ],
                            [
                                7.124193906784058,
                                51.2630494527206
                            ],
                            [
                                7.119859457015991,
                                51.26284133299136
                            ],
                            [
                                7.119312286376953,
                                51.262210254503
                            ]
                        ],
                        [
                            [
                                7.16557502746582,
                                51.27301795534419
                            ],
                            [
                                7.1648991107940665,
                                51.27393749950299
                            ],
                            [
                                7.1608757972717285,
                                51.275521488620235
                            ],
                            [
                                7.171926498413085,
                                51.27955504480202
                            ],
                            [
                                7.178964614868163,
                                51.28006508658289
                            ],
                            [
                                7.180037498474122,
                                51.28087040418028
                            ],
                            [
                                7.182129621505736,
                                51.28139385304746
                            ],
                            [
                                7.180831432342529,
                                51.28222599024942
                            ],
                            [
                                7.180402278900146,
                                51.28432639855271
                            ],
                            [
                                7.177419662475586,
                                51.28328627226109
                            ],
                            [
                                7.176529169082641,
                                51.2833869306736
                            ],
                            [
                                7.172774076461792,
                                51.284319688136
                            ],
                            [
                                7.1701884269714355,
                                51.28262192120578
                            ],
                            [
                                7.168107032775878,
                                51.28191729594958
                            ],
                            [
                                7.16582179069519,
                                51.28538663207778
                            ],
                            [
                                7.16206669807434,
                                51.28538663207778
                            ],
                            [
                                7.156959772109985,
                                51.28455455213751
                            ],
                            [
                                7.154406309127808,
                                51.28262192120578
                            ],
                            [
                                7.151005268096924,
                                51.28295074262611
                            ],
                            [
                                7.144857645034789,
                                51.28286350451952
                            ],
                            [
                                7.141671180725097,
                                51.28213204002995
                            ],
                            [
                                7.141885757446289,
                                51.280763029316375
                            ],
                            [
                                7.13986873626709,
                                51.278320183353316
                            ],
                            [
                                7.136178016662598,
                                51.277595357912396
                            ],
                            [
                                7.134418487548828,
                                51.279769799926314
                            ],
                            [
                                7.131714820861816,
                                51.27955504480202
                            ],
                            [
                                7.127165794372559,
                                51.28025299528512
                            ],
                            [
                                7.12411880493164,
                                51.280038242419806
                            ],
                            [
                                7.119569778442383,
                                51.27646782893933
                            ],
                            [
                                7.119655609130859,
                                51.27252125960352
                            ],
                            [
                                7.1182823181152335,
                                51.272091680578896
                            ],
                            [
                                7.118797302246093,
                                51.27061497204998
                            ],
                            [
                                7.122831344604492,
                                51.26870860541008
                            ],
                            [
                                7.121973037719727,
                                51.26801047953303
                            ],
                            [
                                7.118926048278809,
                                51.266909567178125
                            ],
                            [
                                7.116823196411132,
                                51.267580858337226
                            ],
                            [
                                7.116265296936035,
                                51.26650678777596
                            ],
                            [
                                7.117552757263184,
                                51.2658891858365
                            ],
                            [
                                7.118840217590332,
                                51.264922487877996
                            ],
                            [
                                7.119956016540527,
                                51.26454654429176
                            ],
                            [
                                7.119698524475098,
                                51.26344554895662
                            ],
                            [
                                7.123979330062866,
                                51.26357981809245
                            ],
                            [
                                7.1272194385528564,
                                51.26473451646927
                            ],
                            [
                                7.128002643585204,
                                51.265929464476834
                            ],
                            [
                                7.130148410797119,
                                51.26665447396663
                            ],
                            [
                                7.131800651550293,
                                51.26633224893875
                            ],
                            [
                                7.136811017990112,
                                51.26676859478893
                            ],
                            [
                                7.149009704589843,
                                51.27002427535017
                            ],
                            [
                                7.15360164642334,
                                51.27096401653068
                            ],
                            [
                                7.1610260009765625,
                                51.27225277318377
                            ],
                            [
                                7.16557502746582,
                                51.27301795534419
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--44",
            "properties": {
                "land": "de",
                "city": "Urbach",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on May 1, 2023.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rps/presse/artikel/luftreinhaltung-das-regierungspraesidium-stuttgart-hebt-umweltzonen-in-wendlingen-am-neckar-schwaebisch-gmuend-ilsfeld-und-urbach-zum-1-mai-2023-auf/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.587674140930176,
                            48.80559156420466
                        ],
                        [
                            9.593188762664795,
                            48.80916692553859
                        ],
                        [
                            9.590699672698975,
                            48.81182355068562
                        ],
                        [
                            9.589025974273682,
                            48.81141376130742
                        ],
                        [
                            9.586880207061768,
                            48.81260072823778
                        ],
                        [
                            9.587287902832031,
                            48.81364636629953
                        ],
                        [
                            9.586751461029053,
                            48.814762631513574
                        ],
                        [
                            9.585378170013428,
                            48.814536554490054
                        ],
                        [
                            9.582717418670654,
                            48.81703747481909
                        ],
                        [
                            9.582459926605225,
                            48.819693682938826
                        ],
                        [
                            9.58033561706543,
                            48.82104999024419
                        ],
                        [
                            9.57956314086914,
                            48.82445472499255
                        ],
                        [
                            9.57460641860962,
                            48.825090438219945
                        ],
                        [
                            9.571537971496582,
                            48.822801832861295
                        ],
                        [
                            9.574263095855713,
                            48.821106502252114
                        ],
                        [
                            9.571709632873535,
                            48.81962304092776
                        ],
                        [
                            9.571774005889893,
                            48.81757437929946
                        ],
                        [
                            9.569950103759766,
                            48.81754612131229
                        ],
                        [
                            9.569134712219238,
                            48.815271301088956
                        ],
                        [
                            9.572374820709229,
                            48.814395255832615
                        ],
                        [
                            9.571430683135986,
                            48.8128692049561
                        ],
                        [
                            9.568748474121094,
                            48.81124419230916
                        ],
                        [
                            9.571216106414795,
                            48.80738634266783
                        ],
                        [
                            9.572889804840086,
                            48.804884940887405
                        ],
                        [
                            9.577438831329346,
                            48.80457402347358
                        ],
                        [
                            9.58280324935913,
                            48.80413590930051
                        ],
                        [
                            9.58531379699707,
                            48.806340574050346
                        ],
                        [
                            9.587674140930176,
                            48.80559156420466
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--45",
            "properties": {
                "land": "de",
                "city": "Offenbach",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.offenbach.de/buerger_innen/umwelt-klima/luft_laerm/luftreinhaltung/testartikel-umweltzone.php",
                "urlPark": "https://www.offenbach.de/leben-in-of/verkehr-mobilitaet/mit-dem-auto/mit-dem-auto.php",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.843650817871094,
                            50.08776754408084
                        ],
                        [
                            8.820905685424805,
                            50.0977892579842
                        ],
                        [
                            8.814811706542969,
                            50.114083833025084
                        ],
                        [
                            8.81155014038086,
                            50.116780711007785
                        ],
                        [
                            8.808460235595703,
                            50.119807638007856
                        ],
                        [
                            8.809747695922852,
                            50.12250419359175
                        ],
                        [
                            8.818244934082031,
                            50.122889403418334
                        ],
                        [
                            8.817987442016602,
                            50.13312384332221
                        ],
                        [
                            8.796529769897461,
                            50.1351044498705
                        ],
                        [
                            8.780393600463867,
                            50.13725001444916
                        ],
                        [
                            8.773527145385742,
                            50.13576463383481
                        ],
                        [
                            8.76932144165039,
                            50.13174837387309
                        ],
                        [
                            8.770351409912108,
                            50.1265212293832
                        ],
                        [
                            8.77833366394043,
                            50.119312335776414
                        ],
                        [
                            8.781166076660156,
                            50.11567996283143
                        ],
                        [
                            8.779706954956055,
                            50.11067123910089
                        ],
                        [
                            8.774213790893555,
                            50.108194204096264
                        ],
                        [
                            8.767175674438477,
                            50.108194204096264
                        ],
                        [
                            8.758506774902344,
                            50.111166630727574
                        ],
                        [
                            8.750696182250977,
                            50.11468926783571
                        ],
                        [
                            8.743829727172852,
                            50.113973753146134
                        ],
                        [
                            8.737735748291016,
                            50.1105061074198
                        ],
                        [
                            8.740739822387695,
                            50.10637763033234
                        ],
                        [
                            8.740139007568358,
                            50.104671089184414
                        ],
                        [
                            8.741683959960938,
                            50.09878030260815
                        ],
                        [
                            8.743400573730469,
                            50.09635771289063
                        ],
                        [
                            8.739967346191406,
                            50.08903414010894
                        ],
                        [
                            8.737306594848633,
                            50.07686241989082
                        ],
                        [
                            8.733959197998047,
                            50.07184963676399
                        ],
                        [
                            8.736104965209961,
                            50.0698664130596
                        ],
                        [
                            8.742456436157227,
                            50.065293668098896
                        ],
                        [
                            8.757820129394531,
                            50.06667104680024
                        ],
                        [
                            8.767518997192383,
                            50.06755254841142
                        ],
                        [
                            8.784942626953125,
                            50.068103478691334
                        ],
                        [
                            8.81052017211914,
                            50.068544218358724
                        ],
                        [
                            8.822793960571289,
                            50.06832384903131
                        ],
                        [
                            8.836612701416014,
                            50.07157419393176
                        ],
                        [
                            8.843650817871094,
                            50.08776754408084
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--46",
            "properties": {
                "land": "de",
                "city": "Hagen",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.hagen.de/web/de/fachbereiche/fb_69/fb_69_07/fb_69_0702/umweltzone.html",
                "urlPark": "https://www.hagen.de/irj/portal/010904",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            7.4596309661865225,
                            51.37744622098992
                        ],
                        [
                            7.461175918579102,
                            51.373722647659605
                        ],
                        [
                            7.462527751922607,
                            51.37076232471282
                        ],
                        [
                            7.463321685791015,
                            51.369556708484495
                        ],
                        [
                            7.463128566741944,
                            51.366823860840015
                        ],
                        [
                            7.462999820709228,
                            51.36536359597839
                        ],
                        [
                            7.462270259857178,
                            51.366234401729706
                        ],
                        [
                            7.459888458251953,
                            51.3676410529644
                        ],
                        [
                            7.458772659301759,
                            51.36729274335035
                        ],
                        [
                            7.45793581008911,
                            51.36494828278824
                        ],
                        [
                            7.457163333892821,
                            51.362255354270026
                        ],
                        [
                            7.455489635467529,
                            51.36086193606025
                        ],
                        [
                            7.454502582550049,
                            51.35985704085011
                        ],
                        [
                            7.453515529632568,
                            51.35808837173304
                        ],
                        [
                            7.453880310058595,
                            51.35665462771128
                        ],
                        [
                            7.453451156616211,
                            51.35635983365905
                        ],
                        [
                            7.451133728027344,
                            51.35447042673739
                        ],
                        [
                            7.44746446609497,
                            51.35361279788325
                        ],
                        [
                            7.448666095733643,
                            51.35098620964531
                        ],
                        [
                            7.448322772979735,
                            51.35046355422792
                        ],
                        [
                            7.449545860290527,
                            51.34861411026403
                        ],
                        [
                            7.454416751861572,
                            51.349525439807095
                        ],
                        [
                            7.45793581008911,
                            51.35082539322879
                        ],
                        [
                            7.46093988418579,
                            51.35157586501015
                        ],
                        [
                            7.463407516479491,
                            51.35300976798236
                        ],
                        [
                            7.465209960937499,
                            51.35138824821721
                        ],
                        [
                            7.4669694900512695,
                            51.351616068508676
                        ],
                        [
                            7.468535900115967,
                            51.350677977684484
                        ],
                        [
                            7.471089363098144,
                            51.350771787631196
                        ],
                        [
                            7.47368574142456,
                            51.35154906265818
                        ],
                        [
                            7.475316524505615,
                            51.35235312639667
                        ],
                        [
                            7.478299140930176,
                            51.350946005594096
                        ],
                        [
                            7.478020191192627,
                            51.34965945732884
                        ],
                        [
                            7.478492259979247,
                            51.349217198019524
                        ],
                        [
                            7.479822635650635,
                            51.34713986233455
                        ],
                        [
                            7.482311725616454,
                            51.34390975335249
                        ],
                        [
                            7.4826765060424805,
                            51.34357466677303
                        ],
                        [
                            7.483170032501221,
                            51.34270343020064
                        ],
                        [
                            7.482526302337646,
                            51.340531968513226
                        ],
                        [
                            7.482461929321289,
                            51.34018345273601
                        ],
                        [
                            7.485551834106445,
                            51.34192600512288
                        ],
                        [
                            7.489113807678223,
                            51.343882946516274
                        ],
                        [
                            7.491023540496825,
                            51.345075835551235
                        ],
                        [
                            7.4945855140686035,
                            51.34601404106799
                        ],
                        [
                            7.495422363281249,
                            51.34691201978676
                        ],
                        [
                            7.499670982360841,
                            51.34585320720065
                        ],
                        [
                            7.500572204589843,
                            51.34590681855247
                        ],
                        [
                            7.4988341331481925,
                            51.346992434932964
                        ],
                        [
                            7.494800090789795,
                            51.348466687605416
                        ],
                        [
                            7.486903667449951,
                            51.35062437191441
                        ],
                        [
                            7.485079765319825,
                            51.35136144575547
                        ],
                        [
                            7.482569217681886,
                            51.3535725961368
                        ],
                        [
                            7.483384609222411,
                            51.35382720660191
                        ],
                        [
                            7.487225532531738,
                            51.35465803090915
                        ],
                        [
                            7.487139701843262,
                            51.35536823974298
                        ],
                        [
                            7.491002082824707,
                            51.35694941986648
                        ],
                        [
                            7.494199275970458,
                            51.359133502647474
                        ],
                        [
                            7.494456768035889,
                            51.359870439597906
                        ],
                        [
                            7.496623992919921,
                            51.360245602945604
                        ],
                        [
                            7.4960875511169425,
                            51.36110310763014
                        ],
                        [
                            7.496259212493897,
                            51.361813216545016
                        ],
                        [
                            7.505421638488769,
                            51.36112990439286
                        ],
                        [
                            7.505550384521485,
                            51.36153185395225
                        ],
                        [
                            7.500743865966797,
                            51.363541548837986
                        ],
                        [
                            7.497546672821045,
                            51.36652913223332
                        ],
                        [
                            7.495915889739989,
                            51.36853860783658
                        ],
                        [
                            7.494328022003175,
                            51.37262426962476
                        ],
                        [
                            7.491881847381592,
                            51.376401507961255
                        ],
                        [
                            7.48844861984253,
                            51.37798196201247
                        ],
                        [
                            7.484886646270752,
                            51.380486468117624
                        ],
                        [
                            7.4808526039123535,
                            51.38288370120904
                        ],
                        [
                            7.480208873748779,
                            51.381999819821814
                        ],
                        [
                            7.474758625030517,
                            51.377432827384006
                        ],
                        [
                            7.471325397491455,
                            51.37535677108993
                        ],
                        [
                            7.468729019165039,
                            51.37753997612149
                        ],
                        [
                            7.462441921234131,
                            51.37728549746044
                        ],
                        [
                            7.4596309661865225,
                            51.37744622098992
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--47",
            "properties": {
                "land": "de",
                "city": "Halle",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://halle.de/serviceportal/dienstleistungen/leistung/feinstaubplakette-beantragen/27551403",
                "urlPark": "https://havag.com/kundenservice/fahrgastinformationen/park-ride-plaetze",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            11.96462631225586,
                            51.506472026316416
                        ],
                        [
                            11.959991455078123,
                            51.51101277815347
                        ],
                        [
                            11.957931518554688,
                            51.5104785955435
                        ],
                        [
                            11.956686973571777,
                            51.507219945984644
                        ],
                        [
                            11.955914497375488,
                            51.5054035483508
                        ],
                        [
                            11.952652931213379,
                            51.504254906593374
                        ],
                        [
                            11.948661804199219,
                            51.50270552998373
                        ],
                        [
                            11.94887638092041,
                            51.49920588205518
                        ],
                        [
                            11.951408386230469,
                            51.49640062670799
                        ],
                        [
                            11.950206756591797,
                            51.493969265745775
                        ],
                        [
                            11.951923370361328,
                            51.49127057041176
                        ],
                        [
                            11.95338249206543,
                            51.48875876983271
                        ],
                        [
                            11.95286750793457,
                            51.486353724706774
                        ],
                        [
                            11.95462703704834,
                            51.48485718815831
                        ],
                        [
                            11.95638656616211,
                            51.48223813101211
                        ],
                        [
                            11.956772804260254,
                            51.4810621788744
                        ],
                        [
                            11.957974433898926,
                            51.48108890539611
                        ],
                        [
                            11.961793899536133,
                            51.48060782560929
                        ],
                        [
                            11.96535587310791,
                            51.47975256012483
                        ],
                        [
                            11.971492767333984,
                            51.47892400639213
                        ],
                        [
                            11.975483894348145,
                            51.47969910549954
                        ],
                        [
                            11.977887153625488,
                            51.478442903769064
                        ],
                        [
                            11.981234550476074,
                            51.47862999983663
                        ],
                        [
                            11.982264518737793,
                            51.47924473865486
                        ],
                        [
                            11.984667778015137,
                            51.479137828151224
                        ],
                        [
                            11.994280815124512,
                            51.479405103940444
                        ],
                        [
                            11.993293762207031,
                            51.48841138226431
                        ],
                        [
                            11.993980407714844,
                            51.48913287656172
                        ],
                        [
                            11.990504264831543,
                            51.490281899464755
                        ],
                        [
                            11.984195709228516,
                            51.49025517833077
                        ],
                        [
                            11.982779502868652,
                            51.49188513884279
                        ],
                        [
                            11.981878280639648,
                            51.496480779256814
                        ],
                        [
                            11.980376243591309,
                            51.496507496741785
                        ],
                        [
                            11.97934627532959,
                            51.49864484479821
                        ],
                        [
                            11.976513862609863,
                            51.50032793585056
                        ],
                        [
                            11.972136497497559,
                            51.501823964606395
                        ],
                        [
                            11.969046592712402,
                            51.50329323076107
                        ],
                        [
                            11.967630386352539,
                            51.504869299972306
                        ],
                        [
                            11.96660041809082,
                            51.506445314672646
                        ],
                        [
                            11.96462631225586,
                            51.506472026316416
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--48",
            "properties": {
                "land": "de",
                "city": "Heidenheim",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "CANCELED 1/1/2024!",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on Jan 1, 2024.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rps/wir/abteilungen/pressemitteilungen-und-aktuelle-meldungen/artikel/luftreinhaltung-umweltzonen-in-heidenheim-heilbronn-herrenberg-und-leonberg-hemmingen-werden-aufgehoben/",
                "urlPark": "https://www.heidenheim.de/leben/verkehr-parken/parken",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            10.106391906738281,
                            48.67894734131055
                        ],
                        [
                            10.127334594726562,
                            48.66602444828527
                        ],
                        [
                            10.129909515380858,
                            48.65451574975446
                        ],
                        [
                            10.148448944091797,
                            48.65423225111565
                        ],
                        [
                            10.153942108154297,
                            48.647654635180295
                        ],
                        [
                            10.161731243133545,
                            48.64834929462663
                        ],
                        [
                            10.163812637329102,
                            48.65031980710416
                        ],
                        [
                            10.16810417175293,
                            48.65100025395789
                        ],
                        [
                            10.166730880737305,
                            48.65644349822171
                        ],
                        [
                            10.16758918762207,
                            48.66154600600186
                        ],
                        [
                            10.191364288330078,
                            48.665797701278436
                        ],
                        [
                            10.1898193359375,
                            48.6723729502778
                        ],
                        [
                            10.193681716918945,
                            48.675603577221786
                        ],
                        [
                            10.177030563354492,
                            48.68274422801354
                        ],
                        [
                            10.176172256469727,
                            48.68943058629219
                        ],
                        [
                            10.172567367553711,
                            48.69832546631803
                        ],
                        [
                            10.17608642578125,
                            48.7064824459498
                        ],
                        [
                            10.167245864868164,
                            48.713901883269656
                        ],
                        [
                            10.165185928344727,
                            48.72171659582923
                        ],
                        [
                            10.159263610839844,
                            48.727548524879076
                        ],
                        [
                            10.147418975830076,
                            48.72669925689536
                        ],
                        [
                            10.121498107910156,
                            48.7224527018357
                        ],
                        [
                            10.117034912109375,
                            48.69934516106739
                        ],
                        [
                            10.106391906738281,
                            48.67894734131055
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "de",
                "city": "Heilbronn",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "CANCELED 1/1/2024!",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on Jan 1, 2024.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rps/wir/abteilungen/pressemitteilungen-und-aktuelle-meldungen/artikel/luftreinhaltung-umweltzonen-in-heidenheim-heilbronn-herrenberg-und-leonberg-hemmingen-werden-aufgehoben/",
                "urlPark": "https://www.heilbronn.de/umwelt-mobilitaet/mobilitaet/parken.html?tx_contrast=0",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                9.175729751586914,
                                49.15465373315846
                            ],
                            [
                                9.177961349487305,
                                49.15611322023743
                            ],
                            [
                                9.177703857421873,
                                49.15835850100522
                            ],
                            [
                                9.181480407714844,
                                49.15908819534026
                            ],
                            [
                                9.179677963256834,
                                49.16167010435831
                            ],
                            [
                                9.183025360107422,
                                49.16352226051911
                            ],
                            [
                                9.181222915649414,
                                49.16655291208843
                            ],
                            [
                                9.169206619262695,
                                49.1648692396718
                            ],
                            [
                                9.161825180053711,
                                49.16475699280851
                            ],
                            [
                                9.161567687988281,
                                49.16284875720288
                            ],
                            [
                                9.167919158935547,
                                49.15970562061156
                            ],
                            [
                                9.165000915527344,
                                49.158470762371884
                            ],
                            [
                                9.165773391723633,
                                49.15347488527833
                            ],
                            [
                                9.175729751586914,
                                49.15465373315846
                            ]
                        ],
                        [
                            [
                                9.185600280761719,
                                49.1653182245806
                            ],
                            [
                                9.191265106201172,
                                49.16498148628067
                            ],
                            [
                                9.195556640625,
                                49.160603679993315
                            ],
                            [
                                9.189376831054688,
                                49.15656228453343
                            ],
                            [
                                9.186372756958008,
                                49.15785332170642
                            ],
                            [
                                9.181051254272461,
                                49.156842947651
                            ],
                            [
                                9.181737899780273,
                                49.15442919286795
                            ],
                            [
                                9.178133010864258,
                                49.15409238052379
                            ],
                            [
                                9.174613952636719,
                                49.1512855219297
                            ],
                            [
                                9.175794124603271,
                                49.14169890090241
                            ],
                            [
                                9.170129299163818,
                                49.14039340493346
                            ],
                            [
                                9.169936180114744,
                                49.136532866197655
                            ],
                            [
                                9.175236225128174,
                                49.133710973126185
                            ],
                            [
                                9.178862571716309,
                                49.131857702226306
                            ],
                            [
                                9.178433418273926,
                                49.129316362069524
                            ],
                            [
                                9.183368682861328,
                                49.12989203616907
                            ],
                            [
                                9.182209968566895,
                                49.12603067951286
                            ],
                            [
                                9.169807434082031,
                                49.122941377671964
                            ],
                            [
                                9.162168502807615,
                                49.120343406700435
                            ],
                            [
                                9.158477783203125,
                                49.12063831836098
                            ],
                            [
                                9.155130386352539,
                                49.12482306625102
                            ],
                            [
                                9.14837121963501,
                                49.1248932771296
                            ],
                            [
                                9.147405624389648,
                                49.121143877127736
                            ],
                            [
                                9.146289825439453,
                                49.118770514893555
                            ],
                            [
                                9.149036407470703,
                                49.116804329988206
                            ],
                            [
                                9.152534008026123,
                                49.11684646333908
                            ],
                            [
                                9.157404899597168,
                                49.117646990205756
                            ],
                            [
                                9.159915447235107,
                                49.118601987812816
                            ],
                            [
                                9.168970584869385,
                                49.12157921526946
                            ],
                            [
                                9.184119701385496,
                                49.12572175798949
                            ],
                            [
                                9.189205169677734,
                                49.12709784814912
                            ],
                            [
                                9.191350936889647,
                                49.128221158742974
                            ],
                            [
                                9.193882942199707,
                                49.1300464841805
                            ],
                            [
                                9.195599555969238,
                                49.13236314661548
                            ],
                            [
                                9.19755220413208,
                                49.1331353433705
                            ],
                            [
                                9.197015762329102,
                                49.13722076602489
                            ],
                            [
                                9.197745323181152,
                                49.14130585197088
                            ],
                            [
                                9.197766780853271,
                                49.14214809586425
                            ],
                            [
                                9.199483394622803,
                                49.1455169282987
                            ],
                            [
                                9.199606776237488,
                                49.14896272619121
                            ],
                            [
                                9.199402928352356,
                                49.151131140093014
                            ],
                            [
                                9.198764562606812,
                                49.15191357035127
                            ],
                            [
                                9.196479320526123,
                                49.15196619974569
                            ],
                            [
                                9.196393489837646,
                                49.153601191750184
                            ],
                            [
                                9.199419021606445,
                                49.15353102152779
                            ],
                            [
                                9.200084209442139,
                                49.154387091450175
                            ],
                            [
                                9.201350212097168,
                                49.15656228453343
                            ],
                            [
                                9.202766418457031,
                                49.15830237022649
                            ],
                            [
                                9.203925132751465,
                                49.16049142346117
                            ],
                            [
                                9.204633235931396,
                                49.16284875720288
                            ],
                            [
                                9.20480489730835,
                                49.163774821901136
                            ],
                            [
                                9.204912185668945,
                                49.16557077680273
                            ],
                            [
                                9.204483032226562,
                                49.167212334849346
                            ],
                            [
                                9.203646183013916,
                                49.16889592759506
                            ],
                            [
                                9.203431606292725,
                                49.17060752153268
                            ],
                            [
                                9.202980995178223,
                                49.17396039061395
                            ],
                            [
                                9.202165603637695,
                                49.176064585768735
                            ],
                            [
                                9.19780969619751,
                                49.17578403158282
                            ],
                            [
                                9.197359085083008,
                                49.17513875092052
                            ],
                            [
                                9.187660217285156,
                                49.17149135425113
                            ],
                            [
                                9.183282852172852,
                                49.16890995729407
                            ],
                            [
                                9.185600280761719,
                                49.1653182245806
                            ]
                        ],
                        [
                            [
                                9.162511825561523,
                                49.11584929776882
                            ],
                            [
                                9.172124862670898,
                                49.10854550225535
                            ],
                            [
                                9.175643920898438,
                                49.10972541896763
                            ],
                            [
                                9.180707931518553,
                                49.117197573204585
                            ],
                            [
                                9.180278778076172,
                                49.119725490868085
                            ],
                            [
                                9.171266555786133,
                                49.118770514893555
                            ],
                            [
                                9.165172576904297,
                                49.117534636337204
                            ],
                            [
                                9.162511825561523,
                                49.11584929776882
                            ]
                        ],
                        [
                            [
                                9.182767868041992,
                                49.115175146307706
                            ],
                            [
                                9.193410873413086,
                                49.11444480521893
                            ],
                            [
                                9.199848175048828,
                                49.11562458163312
                            ],
                            [
                                9.202251434326172,
                                49.116748152131365
                            ],
                            [
                                9.21529769897461,
                                49.11568076076249
                            ],
                            [
                                9.221649169921875,
                                49.11809640313488
                            ],
                            [
                                9.227142333984375,
                                49.11927609269998
                            ],
                            [
                                9.229717254638672,
                                49.12253413716317
                            ],
                            [
                                9.236841201782227,
                                49.126971474114754
                            ],
                            [
                                9.250144958496094,
                                49.1317453805511
                            ],
                            [
                                9.262075424194336,
                                49.13225082608545
                            ],
                            [
                                9.263105392456055,
                                49.136532866197655
                            ],
                            [
                                9.2569899559021,
                                49.13859653703879
                            ],
                            [
                                9.25568103790283,
                                49.14162871382183
                            ],
                            [
                                9.251325130462645,
                                49.14226039396851
                            ],
                            [
                                9.25175428390503,
                                49.145053727419196
                            ],
                            [
                                9.249565601348877,
                                49.147832867748086
                            ],
                            [
                                9.248127937316895,
                                49.14944694335094
                            ],
                            [
                                9.240789413452148,
                                49.14901184988686
                            ],
                            [
                                9.241347312927246,
                                49.15082237497634
                            ],
                            [
                                9.240832328796387,
                                49.15365732785654
                            ],
                            [
                                9.230060577392578,
                                49.156772782020695
                            ],
                            [
                                9.230146408081055,
                                49.16074400030069
                            ],
                            [
                                9.234051704406738,
                                49.16370466609091
                            ],
                            [
                                9.233279228210447,
                                49.164813116271965
                            ],
                            [
                                9.22699213027954,
                                49.163367916818196
                            ],
                            [
                                9.22703504562378,
                                49.16007045919942
                            ],
                            [
                                9.225854873657227,
                                49.157460400911745
                            ],
                            [
                                9.2195463180542,
                                49.15882157750269
                            ],
                            [
                                9.217958450317383,
                                49.15375556588957
                            ],
                            [
                                9.215168952941895,
                                49.1495872952662
                            ],
                            [
                                9.208753108978271,
                                49.150892549035476
                            ],
                            [
                                9.206328392028809,
                                49.151425868636515
                            ],
                            [
                                9.204697608947754,
                                49.145952052453424
                            ],
                            [
                                9.203474521636963,
                                49.14265343532952
                            ],
                            [
                                9.203603267669676,
                                49.1414181519837
                            ],
                            [
                                9.204719066619873,
                                49.13802096401587
                            ],
                            [
                                9.205920696258545,
                                49.13361269534839
                            ],
                            [
                                9.200448989868164,
                                49.13147861555256
                            ],
                            [
                                9.196972846984863,
                                49.12517411964987
                            ],
                            [
                                9.195384979248047,
                                49.123348614836544
                            ],
                            [
                                9.19008493423462,
                                49.12080683852253
                            ],
                            [
                                9.183733463287354,
                                49.12024510242391
                            ],
                            [
                                9.182424545288086,
                                49.11635490535197
                            ],
                            [
                                9.182767868041992,
                                49.115175146307706
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--50",
            "properties": {
                "land": "de",
                "city": "Limburg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.limburg.de/Verkehr/Umweltzone",
                "urlPark": "https://www.limburg.de/Mobilit%C3%A4t-Verkehr/Parken",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.049631118774414,
                            50.398834516221775
                        ],
                        [
                            8.048579692840576,
                            50.39908071906552
                        ],
                        [
                            8.046691417694092,
                            50.39857463405495
                        ],
                        [
                            8.048214912414549,
                            50.39660495446968
                        ],
                        [
                            8.050060272216797,
                            50.39352716626973
                        ],
                        [
                            8.049523830413818,
                            50.39202239709279
                        ],
                        [
                            8.04847240447998,
                            50.390955350009285
                        ],
                        [
                            8.042206764221191,
                            50.38862965136594
                        ],
                        [
                            8.038666248321533,
                            50.38634488339247
                        ],
                        [
                            8.039631843566895,
                            50.386167022584694
                        ],
                        [
                            8.042764663696289,
                            50.38607125110421
                        ],
                        [
                            8.045661449432373,
                            50.37951728605967
                        ],
                        [
                            8.04872989654541,
                            50.37828575105469
                        ],
                        [
                            8.044931888580322,
                            50.375658369512415
                        ],
                        [
                            8.045639991760254,
                            50.375056240747014
                        ],
                        [
                            8.048579692840576,
                            50.37568573882017
                        ],
                        [
                            8.050811290740967,
                            50.379380450415844
                        ],
                        [
                            8.053858280181885,
                            50.37875100137005
                        ],
                        [
                            8.050532341003418,
                            50.376082592008146
                        ],
                        [
                            8.058257102966309,
                            50.37327717929372
                        ],
                        [
                            8.059673309326172,
                            50.375165719272786
                        ],
                        [
                            8.062162399291992,
                            50.371087473614025
                        ],
                        [
                            8.063514232635498,
                            50.372811875292484
                        ],
                        [
                            8.0659818649292,
                            50.37177176749479
                        ],
                        [
                            8.075230121612549,
                            50.372921358997445
                        ],
                        [
                            8.079307079315186,
                            50.37272976234799
                        ],
                        [
                            8.08093786239624,
                            50.373249808596434
                        ],
                        [
                            8.079049587249756,
                            50.37491939223451
                        ],
                        [
                            8.076388835906982,
                            50.375535207432115
                        ],
                        [
                            8.072376251220703,
                            50.377971020312636
                        ],
                        [
                            8.070251941680908,
                            50.38117296606486
                        ],
                        [
                            8.070080280303955,
                            50.3826233489921
                        ],
                        [
                            8.071990013122559,
                            50.38360848945959
                        ],
                        [
                            8.07795524597168,
                            50.38373163057923
                        ],
                        [
                            8.082246780395508,
                            50.38400527636708
                        ],
                        [
                            8.079500198364258,
                            50.388711736789446
                        ],
                        [
                            8.076753616333008,
                            50.39341773012179
                        ],
                        [
                            8.074178695678711,
                            50.39648184677813
                        ],
                        [
                            8.073234558105469,
                            50.396317702692095
                        ],
                        [
                            8.070573806762695,
                            50.397411985863314
                        ],
                        [
                            8.0694580078125,
                            50.396098843026586
                        ],
                        [
                            8.067569732666016,
                            50.39648184677813
                        ],
                        [
                            8.06344985961914,
                            50.39659127585307
                        ],
                        [
                            8.060617446899414,
                            50.39675541899181
                        ],
                        [
                            8.058557510375977,
                            50.39752141279113
                        ],
                        [
                            8.05323600769043,
                            50.39877980430508
                        ],
                        [
                            8.049631118774414,
                            50.398834516221775
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--51",
            "properties": {
                "land": "de",
                "city": "Tuebingen",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.tuebingen.de/105.html#2118",
                "urlPark": "https://www.tuebingen.de/14103.html",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.984670639038086,
                            48.48509716232647
                        ],
                        [
                            9.00578498840332,
                            48.451976459625996
                        ],
                        [
                            9.040288925170897,
                            48.46375898031356
                        ],
                        [
                            9.058399200439453,
                            48.47565256743914
                        ],
                        [
                            9.066038131713867,
                            48.48521094190555
                        ],
                        [
                            9.072046279907227,
                            48.48304908625469
                        ],
                        [
                            9.076337814331055,
                            48.488510438664235
                        ],
                        [
                            9.081058502197266,
                            48.50034135141255
                        ],
                        [
                            9.088783264160156,
                            48.51222636297196
                        ],
                        [
                            9.104490280151367,
                            48.51842366030161
                        ],
                        [
                            9.114189147949219,
                            48.52973603679617
                        ],
                        [
                            9.131269454956055,
                            48.53428301381282
                        ],
                        [
                            9.131183624267578,
                            48.53718149853727
                        ],
                        [
                            9.118824005126953,
                            48.551444172019075
                        ],
                        [
                            9.106035232543945,
                            48.56365800726797
                        ],
                        [
                            9.085693359375,
                            48.569508241755074
                        ],
                        [
                            9.078483581542969,
                            48.58784973411136
                        ],
                        [
                            9.065265655517578,
                            48.59301594992377
                        ],
                        [
                            9.043378829956055,
                            48.589155530844344
                        ],
                        [
                            9.034194946289062,
                            48.57660713188407
                        ],
                        [
                            9.008359909057617,
                            48.54968286415505
                        ],
                        [
                            8.991451263427734,
                            48.54496680257221
                        ],
                        [
                            8.99282455444336,
                            48.536670013291
                        ],
                        [
                            8.974864482879639,
                            48.52793134210814
                        ],
                        [
                            8.973126411437987,
                            48.525018116559266
                        ],
                        [
                            8.96484375,
                            48.5141595462428
                        ],
                        [
                            8.993425369262695,
                            48.512624377322055
                        ],
                        [
                            8.983898162841797,
                            48.500284478628686
                        ],
                        [
                            8.987932205200195,
                            48.4931748781445
                        ],
                        [
                            8.984670639038086,
                            48.48509716232647
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--52",
            "properties": {
                "land": "de",
                "city": "Reutlingen",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.reutlingen.de/19791",
                "urlPark": "https://www.reutlingen.de/parkplaetze",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.222335815429688,
                            48.4829353018258
                        ],
                        [
                            9.223966598510742,
                            48.48504027244122
                        ],
                        [
                            9.231476783752441,
                            48.48307753232202
                        ],
                        [
                            9.238085746765137,
                            48.48014750358189
                        ],
                        [
                            9.237055778503418,
                            48.47696126083093
                        ],
                        [
                            9.25924301147461,
                            48.463246753669175
                        ],
                        [
                            9.316062927246094,
                            48.44252569905383
                        ],
                        [
                            9.334774017333984,
                            48.4563027269503
                        ],
                        [
                            9.342155456542969,
                            48.46632003599557
                        ],
                        [
                            9.318809509277344,
                            48.49567757122466
                        ],
                        [
                            9.299583435058592,
                            48.498521390679656
                        ],
                        [
                            9.259757995605469,
                            48.49738388204065
                        ],
                        [
                            9.247913360595701,
                            48.50420855101961
                        ],
                        [
                            9.249286651611328,
                            48.52240317628364
                        ],
                        [
                            9.24173355102539,
                            48.53365782868522
                        ],
                        [
                            9.247913360595701,
                            48.54218241531944
                        ],
                        [
                            9.24774169921875,
                            48.56252196094323
                        ],
                        [
                            9.253921508789062,
                            48.5686563079607
                        ],
                        [
                            9.24722671508789,
                            48.57459114842401
                        ],
                        [
                            9.230060577392578,
                            48.572376313362405
                        ],
                        [
                            9.224309921264648,
                            48.565930023367635
                        ],
                        [
                            9.215898513793945,
                            48.562039133528415
                        ],
                        [
                            9.219160079956055,
                            48.5546257340465
                        ],
                        [
                            9.211606979370117,
                            48.55374514315169
                        ],
                        [
                            9.203410148620605,
                            48.5546541399492
                        ],
                        [
                            9.196414947509766,
                            48.556131224913614
                        ],
                        [
                            9.19255256652832,
                            48.55581876822905
                        ],
                        [
                            9.188175201416016,
                            48.55263728122386
                        ],
                        [
                            9.18233871459961,
                            48.55025103468065
                        ],
                        [
                            9.177403450012207,
                            48.55079079077093
                        ],
                        [
                            9.171223640441895,
                            48.549171505227484
                        ],
                        [
                            9.175472259521484,
                            48.539341045937974
                        ],
                        [
                            9.16585922241211,
                            48.52285795820353
                        ],
                        [
                            9.168777465820312,
                            48.508871546362016
                        ],
                        [
                            9.15761947631836,
                            48.50409481406604
                        ],
                        [
                            9.152297973632812,
                            48.500113859894256
                        ],
                        [
                            9.154701232910156,
                            48.49283359224373
                        ],
                        [
                            9.146804809570312,
                            48.48919306636477
                        ],
                        [
                            9.128093719482422,
                            48.48430069812584
                        ],
                        [
                            9.120540618896484,
                            48.47485595493325
                        ],
                        [
                            9.137706756591797,
                            48.45687194520768
                        ],
                        [
                            9.123458862304688,
                            48.446852771568224
                        ],
                        [
                            9.117965698242188,
                            48.43011178780495
                        ],
                        [
                            9.139595031738281,
                            48.41701125356405
                        ],
                        [
                            9.177188873291016,
                            48.424188486187234
                        ],
                        [
                            9.203624725341797,
                            48.421568344106404
                        ],
                        [
                            9.19830322265625,
                            48.42999788466895
                        ],
                        [
                            9.168262481689453,
                            48.43535105606878
                        ],
                        [
                            9.16585922241211,
                            48.44298119773134
                        ],
                        [
                            9.167232513427734,
                            48.452204167096575
                        ],
                        [
                            9.184913635253906,
                            48.46074246010542
                        ],
                        [
                            9.198646545410156,
                            48.46620621404278
                        ],
                        [
                            9.213237762451172,
                            48.46950694703277
                        ],
                        [
                            9.218559265136719,
                            48.475993968969874
                        ],
                        [
                            9.222335815429688,
                            48.4829353018258
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--53",
            "properties": {
                "land": "de",
                "city": "Schramberg",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on March 1, 2023.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rpf/service/presse/pressemitteilungen/artikel/umweltzone-in-schramberg-wird-aufgehoben/",
                "urlPark": "https://www.schramberg.de/de/stadt/mobilitaet/parkplaetze.php",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.367805480957031,
                            48.244453584180164
                        ],
                        [
                            8.369436264038086,
                            48.236507827129
                        ],
                        [
                            8.378877639770508,
                            48.22730292557268
                        ],
                        [
                            8.374242782592773,
                            48.22490137458404
                        ],
                        [
                            8.373727798461912,
                            48.21946887925649
                        ],
                        [
                            8.383169174194336,
                            48.21924012996237
                        ],
                        [
                            8.381710052490234,
                            48.21443615867381
                        ],
                        [
                            8.386001586914062,
                            48.21151924189948
                        ],
                        [
                            8.391237258911133,
                            48.21300631827239
                        ],
                        [
                            8.400249481201172,
                            48.206256932580494
                        ],
                        [
                            8.40428352355957,
                            48.20825896180814
                        ],
                        [
                            8.405914306640623,
                            48.21146204579193
                        ],
                        [
                            8.403511047363281,
                            48.214893699169544
                        ],
                        [
                            8.395357131958008,
                            48.216895390765785
                        ],
                        [
                            8.394927978515625,
                            48.21889700410474
                        ],
                        [
                            8.392009735107422,
                            48.22278562915987
                        ],
                        [
                            8.391838073730469,
                            48.22501573671019
                        ],
                        [
                            8.395185470581053,
                            48.22518727942031
                        ],
                        [
                            8.39578628540039,
                            48.22844648167533
                        ],
                        [
                            8.399047851562498,
                            48.23033329338121
                        ],
                        [
                            8.406858444213865,
                            48.228903896961775
                        ],
                        [
                            8.411836624145508,
                            48.229246955743626
                        ],
                        [
                            8.41226577758789,
                            48.231305260141205
                        ],
                        [
                            8.406600952148438,
                            48.23262024460825
                        ],
                        [
                            8.404884338378906,
                            48.237879844555344
                        ],
                        [
                            8.401451110839844,
                            48.23856583947082
                        ],
                        [
                            8.396987915039062,
                            48.234049537222916
                        ],
                        [
                            8.388662338256836,
                            48.233935195282996
                        ],
                        [
                            8.38033676147461,
                            48.245196649426866
                        ],
                        [
                            8.367805480957031,
                            48.244453584180164
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--54",
            "properties": {
                "land": "de",
                "city": "Pfinztal",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on March 1, 2023.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://web1.karlsruhe.de/service/Buergerdienste/leistung.php?id=6015857#:~:text=Die%20Umweltzonen%20in%20den%20St%C3%A4dten,wurden%20zum%2001.03.2023%20aufgehoben.&text=Ohne%20passende%20Umweltplakette%20d%C3%BCrfen%20Sie,wenn%20Sie%20eine%20Ausnahmegenehmigung%20haben.",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.536033630371094,
                            48.95429736286702
                        ],
                        [
                            8.548564910888672,
                            48.95373374317786
                        ],
                        [
                            8.560409545898438,
                            48.96230007526008
                        ],
                        [
                            8.557491302490234,
                            48.970639563977144
                        ],
                        [
                            8.570537567138672,
                            48.98145628190888
                        ],
                        [
                            8.576717376708984,
                            48.98810289196596
                        ],
                        [
                            8.596458435058594,
                            48.98573725101642
                        ],
                        [
                            8.597145080566406,
                            49.00049250972169
                        ],
                        [
                            8.557319641113281,
                            49.00837520762085
                        ],
                        [
                            8.5418701171875,
                            49.027963936994226
                        ],
                        [
                            8.520240783691406,
                            49.03167817117755
                        ],
                        [
                            8.519039154052734,
                            49.012879046208184
                        ],
                        [
                            8.513202667236328,
                            49.01006419483569
                        ],
                        [
                            8.512344360351562,
                            49.000830365223656
                        ],
                        [
                            8.499298095703125,
                            48.99294647301535
                        ],
                        [
                            8.510284423828125,
                            48.96827309405025
                        ],
                        [
                            8.527450561523438,
                            48.965681117161665
                        ],
                        [
                            8.525390625,
                            48.96139842537241
                        ],
                        [
                            8.539466857910156,
                            48.9602713400899
                        ],
                        [
                            8.536033630371094,
                            48.95429736286702
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--55",
            "properties": {
                "land": "de",
                "city": "Osnabrueck",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.osnabrueck.de/umweltzone/",
                "urlPark": "https://www.osnabrueck.de/anreise/anreise-mit-dem-auto/?L=0",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            8.016564846038817,
                            52.29277187542594
                        ],
                        [
                            8.011908531188965,
                            52.290199479399206
                        ],
                        [
                            8.012917041778564,
                            52.289779482188806
                        ],
                        [
                            8.01128625869751,
                            52.28816508084278
                        ],
                        [
                            8.008882999420166,
                            52.28853259201219
                        ],
                        [
                            8.006458282470703,
                            52.28515925006131
                        ],
                        [
                            8.00654411315918,
                            52.284004116743006
                        ],
                        [
                            8.007681369781494,
                            52.274683189408776
                        ],
                        [
                            8.008925914764404,
                            52.27409236034316
                        ],
                        [
                            8.008153438568115,
                            52.27204409194057
                        ],
                        [
                            8.007445335388184,
                            52.27132192346203
                        ],
                        [
                            8.002982139587402,
                            52.26872201952366
                        ],
                        [
                            8.003389835357666,
                            52.267198772645756
                        ],
                        [
                            8.008496761322021,
                            52.26698866552089
                        ],
                        [
                            8.008668422698975,
                            52.26658158013297
                        ],
                        [
                            8.007895946502686,
                            52.26568860554502
                        ],
                        [
                            8.017723560333252,
                            52.25987069849973
                        ],
                        [
                            8.018302917480469,
                            52.25515540123304
                        ],
                        [
                            8.024182319641113,
                            52.258491624428245
                        ],
                        [
                            8.031799793243408,
                            52.26051425172391
                        ],
                        [
                            8.038108348846436,
                            52.261578064125615
                        ],
                        [
                            8.038129806518555,
                            52.25402575698262
                        ],
                        [
                            8.036305904388428,
                            52.25396007910636
                        ],
                        [
                            8.03576946258545,
                            52.25193715289342
                        ],
                        [
                            8.038344383239746,
                            52.25151679301294
                        ],
                        [
                            8.039138317108154,
                            52.2498484504618
                        ],
                        [
                            8.050060272216797,
                            52.24632764768099
                        ],
                        [
                            8.05018901824951,
                            52.24460655659491
                        ],
                        [
                            8.058321475982666,
                            52.2448036314778
                        ],
                        [
                            8.059329986572266,
                            52.245775854756225
                        ],
                        [
                            8.061175346374512,
                            52.245907234646474
                        ],
                        [
                            8.061668872833252,
                            52.25156933821584
                        ],
                        [
                            8.059372901916504,
                            52.25151679301294
                        ],
                        [
                            8.058857917785645,
                            52.25255454924615
                        ],
                        [
                            8.062162399291992,
                            52.253763044894114
                        ],
                        [
                            8.064908981323242,
                            52.25271218098045
                        ],
                        [
                            8.069157600402832,
                            52.25302744276857
                        ],
                        [
                            8.08117389678955,
                            52.251608747077135
                        ],
                        [
                            8.081731796264648,
                            52.2522918284442
                        ],
                        [
                            8.082504272460938,
                            52.25217360357569
                        ],
                        [
                            8.085336685180664,
                            52.25644263519164
                        ],
                        [
                            8.086259365081787,
                            52.25793998279206
                        ],
                        [
                            8.08568000793457,
                            52.25805819229172
                        ],
                        [
                            8.08495044708252,
                            52.25734893056761
                        ],
                        [
                            8.0794358253479,
                            52.25850475866928
                        ],
                        [
                            8.078384399414062,
                            52.25846535593449
                        ],
                        [
                            8.070058822631836,
                            52.25983129697854
                        ],
                        [
                            8.06572437286377,
                            52.26152553077886
                        ],
                        [
                            8.063685894012451,
                            52.26262871799161
                        ],
                        [
                            8.06272029876709,
                            52.26253678677214
                        ],
                        [
                            8.060617446899414,
                            52.26346922316116
                        ],
                        [
                            8.05971622467041,
                            52.2642571822884
                        ],
                        [
                            8.060531616210938,
                            52.26568860554502
                        ],
                        [
                            8.06119680404663,
                            52.266423997689046
                        ],
                        [
                            8.060595989227295,
                            52.267855350993415
                        ],
                        [
                            8.059587478637695,
                            52.26884020028329
                        ],
                        [
                            8.059523105621338,
                            52.26996946720955
                        ],
                        [
                            8.062376976013184,
                            52.27337022523431
                        ],
                        [
                            8.067612648010254,
                            52.274381211092546
                        ],
                        [
                            8.072912693023682,
                            52.274459988242945
                        ],
                        [
                            8.073320388793945,
                            52.27505081240824
                        ],
                        [
                            8.074994087219238,
                            52.27499829502354
                        ],
                        [
                            8.076281547546387,
                            52.274683189408776
                        ],
                        [
                            8.081817626953125,
                            52.273659080689626
                        ],
                        [
                            8.085851669311523,
                            52.272490517479206
                        ],
                        [
                            8.088705539703367,
                            52.27130879301715
                        ],
                        [
                            8.090465068817139,
                            52.27243799706097
                        ],
                        [
                            8.08964967727661,
                            52.274880130680536
                        ],
                        [
                            8.08793306350708,
                            52.27638998469256
                        ],
                        [
                            8.0904221534729,
                            52.277217100301506
                        ],
                        [
                            8.089349269866943,
                            52.27968522655066
                        ],
                        [
                            8.08868408203125,
                            52.28206132461091
                        ],
                        [
                            8.087031841278076,
                            52.283282143126186
                        ],
                        [
                            8.088319301605225,
                            52.284148510054735
                        ],
                        [
                            8.085336685180664,
                            52.28652436879356
                        ],
                        [
                            8.091902732849121,
                            52.28960885718466
                        ],
                        [
                            8.091773986816406,
                            52.29016010482989
                        ],
                        [
                            8.088705539703367,
                            52.2946092097008
                        ],
                        [
                            8.077354431152344,
                            52.287850068842445
                        ],
                        [
                            8.068170547485352,
                            52.28191692449755
                        ],
                        [
                            8.061046600341797,
                            52.280026552340345
                        ],
                        [
                            8.05117607116699,
                            52.280551663806364
                        ],
                        [
                            8.045339584350584,
                            52.286274975242264
                        ],
                        [
                            8.043193817138672,
                            52.28517237640325
                        ],
                        [
                            8.043794631958008,
                            52.28275706401606
                        ],
                        [
                            8.038387298583984,
                            52.28039413101991
                        ],
                        [
                            8.034095764160156,
                            52.2864324871222
                        ],
                        [
                            8.030834197998047,
                            52.2868000126643
                        ],
                        [
                            8.030576705932617,
                            52.286274975242264
                        ],
                        [
                            8.026199340820312,
                            52.28753505460224
                        ],
                        [
                            8.019590377807617,
                            52.29126257954172
                        ],
                        [
                            8.016564846038817,
                            52.29277187542594
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--56",
            "properties": {
                "land": "de",
                "city": "SchwaebischGmuend",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "canceled",
                "commercial": 1,
                "diesel": "no restrictions",
                "minEUd": 0,
                "petrol": "no restrictions",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "The Low Emission Zone (LEZ) was lifted on May 1, 2023.",
                "img_sign": "sign-de--white",
                "color": 0,
                "urlZone": "https://rp.baden-wuerttemberg.de/rps/presse/artikel/luftreinhaltung-das-regierungspraesidium-stuttgart-hebt-umweltzonen-in-wendlingen-am-neckar-schwaebisch-gmuend-ilsfeld-und-urbach-zum-1-mai-2023-auf/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.763648509979248,
                            48.79021319188866
                        ],
                        [
                            9.766244888305664,
                            48.7883753872291
                        ],
                        [
                            9.76358413696289,
                            48.78752714699118
                        ],
                        [
                            9.76978540420532,
                            48.78229601534405
                        ],
                        [
                            9.784698486328125,
                            48.783285730720586
                        ],
                        [
                            9.781351089477539,
                            48.79069383738754
                        ],
                        [
                            9.791479110717773,
                            48.792333652085546
                        ],
                        [
                            9.810705184936523,
                            48.78814919123431
                        ],
                        [
                            9.815855026245117,
                            48.79108965963536
                        ],
                        [
                            9.803752899169922,
                            48.79725277462017
                        ],
                        [
                            9.804439544677734,
                            48.79860968905856
                        ],
                        [
                            9.813365936279297,
                            48.79923159590929
                        ],
                        [
                            9.833450317382812,
                            48.799118522509964
                        ],
                        [
                            9.832334518432617,
                            48.800927666312056
                        ],
                        [
                            9.834222793579102,
                            48.80273674486138
                        ],
                        [
                            9.833707809448242,
                            48.80454575815854
                        ],
                        [
                            9.823579788208008,
                            48.803768455752625
                        ],
                        [
                            9.819889068603516,
                            48.80320313734489
                        ],
                        [
                            9.812850952148438,
                            48.804164174846534
                        ],
                        [
                            9.80506181716919,
                            48.80805053573547
                        ],
                        [
                            9.79755163192749,
                            48.80800814069614
                        ],
                        [
                            9.796199798583984,
                            48.805563299463124
                        ],
                        [
                            9.789011478424072,
                            48.803471664383096
                        ],
                        [
                            9.78609323501587,
                            48.80306180674727
                        ],
                        [
                            9.775943756103516,
                            48.79832700157747
                        ],
                        [
                            9.763648509979248,
                            48.79021319188866
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "de--57",
            "properties": {
                "land": "de",
                "city": "Koeln",
                "zone": "Umweltzone",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-1 (petrol)",
                "minEUb": 1,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Emission-Sticker 4",
                "img_sign": "sign-de--white",
                "color": 1,
                "urlZone": "https://www.stadt-koeln.de/leben-in-koeln/umwelt-tiere/luft-umweltzone/index.html",
                "urlPark": "hhttps://www.stadt-koeln.de/leben-in-koeln/verkehr/parken/index.html",
                "urlReg": "",
                "urlBadge": "https://www.berlin.de/labo/mobilitaet/kfz-zulassung/feinstaubplakette/shop.86595.en.php"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                6.816544532775879,
                                50.938878698524086
                            ],
                            [
                                6.817574501037598,
                                50.93311816907569
                            ],
                            [
                                6.82002067565918,
                                50.93008887202655
                            ],
                            [
                                6.824698448181152,
                                50.9298995344099
                            ],
                            [
                                6.832551956176758,
                                50.928979883594444
                            ],
                            [
                                6.840920448303223,
                                50.92743807525738
                            ],
                            [
                                6.845426559448241,
                                50.928357756555926
                            ],
                            [
                                6.847314834594727,
                                50.93054868874399
                            ],
                            [
                                6.848173141479492,
                                50.93328044727823
                            ],
                            [
                                6.848344802856444,
                                50.93868939668977
                            ],
                            [
                                6.8488168716430655,
                                50.94453035542745
                            ],
                            [
                                6.840147972106934,
                                50.94371915503829
                            ],
                            [
                                6.837530136108398,
                                50.95201977031373
                            ],
                            [
                                6.839332580566406,
                                50.952749720940695
                            ],
                            [
                                6.836199760437012,
                                50.95569644209373
                            ],
                            [
                                6.8314361572265625,
                                50.957237313617654
                            ],
                            [
                                6.826844215393066,
                                50.957210281224235
                            ],
                            [
                                6.822166442871094,
                                50.9558586415023
                            ],
                            [
                                6.815943717956543,
                                50.95261454576363
                            ],
                            [
                                6.811223030090332,
                                50.948315770089756
                            ],
                            [
                                6.813454627990723,
                                50.94128546894236
                            ],
                            [
                                6.814441680908203,
                                50.939040956625846
                            ],
                            [
                                6.816544532775879,
                                50.938878698524086
                            ]
                        ],
                        [
                            [
                                6.852807998657227,
                                50.95123573650007
                            ],
                            [
                                6.851606369018555,
                                50.94780205332098
                            ],
                            [
                                6.850447654724121,
                                50.94753167379386
                            ],
                            [
                                6.8503618240356445,
                                50.944070676944776
                            ],
                            [
                                6.85023307800293,
                                50.940609422418376
                            ],
                            [
                                6.850705146789551,
                                50.937445394034576
                            ],
                            [
                                6.849846839904785,
                                50.9316035452139
                            ],
                            [
                                6.848559379577637,
                                50.92862824759507
                            ],
                            [
                                6.851305961608887,
                                50.925057639225955
                            ],
                            [
                                6.859631538391113,
                                50.92159496937043
                            ],
                            [
                                6.872291564941406,
                                50.9240026343033
                            ],
                            [
                                6.877012252807617,
                                50.924814178675
                            ],
                            [
                                6.878900527954102,
                                50.925138792459805
                            ],
                            [
                                6.889758110046386,
                                50.92497648585051
                            ],
                            [
                                6.885852813720702,
                                50.9334427249145
                            ],
                            [
                                6.883106231689453,
                                50.93609317948795
                            ],
                            [
                                6.881432533264159,
                                50.937229042349465
                            ],
                            [
                                6.881561279296875,
                                50.93820261700557
                            ],
                            [
                                6.882762908935547,
                                50.938418964160995
                            ],
                            [
                                6.881947517395019,
                                50.94058238035294
                            ],
                            [
                                6.880273818969727,
                                50.94236712293478
                            ],
                            [
                                6.876883506774902,
                                50.94458443495011
                            ],
                            [
                                6.874823570251465,
                                50.94555785560071
                            ],
                            [
                                6.873493194580078,
                                50.947829091187195
                            ],
                            [
                                6.870875358581543,
                                50.95104648497729
                            ],
                            [
                                6.868257522583008,
                                50.951100556919585
                            ],
                            [
                                6.864137649536133,
                                50.95226308846324
                            ],
                            [
                                6.852807998657227,
                                50.95123573650007
                            ]
                        ],
                        [
                            [
                                6.872034072875977,
                                50.97588591628918
                            ],
                            [
                                6.869845390319824,
                                50.96829224403101
                            ],
                            [
                                6.867270469665527,
                                50.96337325997817
                            ],
                            [
                                6.868429183959961,
                                50.96018375022545
                            ],
                            [
                                6.871733665466308,
                                50.95293896552811
                            ],
                            [
                                6.8740081787109375,
                                50.94950540817795
                            ],
                            [
                                6.876068115234374,
                                50.947693901698855
                            ],
                            [
                                6.878385543823242,
                                50.94736944532268
                            ],
                            [
                                6.878213882446289,
                                50.94561193392811
                            ],
                            [
                                6.883363723754883,
                                50.94128546894236
                            ],
                            [
                                6.884565353393555,
                                50.939554770212645
                            ],
                            [
                                6.8846940994262695,
                                50.93649884798059
                            ],
                            [
                                6.887526512145996,
                                50.933632048107846
                            ],
                            [
                                6.890702247619628,
                                50.92521994555208
                            ],
                            [
                                6.8929338455200195,
                                50.91742860313932
                            ],
                            [
                                6.89640998840332,
                                50.914831199061766
                            ],
                            [
                                6.905980110168457,
                                50.91001479538914
                            ],
                            [
                                6.914820671081543,
                                50.90557676881467
                            ],
                            [
                                6.927008628845215,
                                50.8964017018654
                            ],
                            [
                                6.947565078735352,
                                50.89466933294632
                            ],
                            [
                                6.9663190841674805,
                                50.89331587488911
                            ],
                            [
                                6.967906951904297,
                                50.89331587488911
                            ],
                            [
                                6.973228454589844,
                                50.89361363903644
                            ],
                            [
                                6.984472274780273,
                                50.901544292463036
                            ],
                            [
                                6.9882917404174805,
                                50.89905426688555
                            ],
                            [
                                6.998162269592285,
                                50.90730873196528
                            ],
                            [
                                6.998591423034668,
                                50.90890532841256
                            ],
                            [
                                7.001595497131348,
                                50.91147600422708
                            ],
                            [
                                7.003440856933594,
                                50.91101599898483
                            ],
                            [
                                7.007732391357422,
                                50.91328892179695
                            ],
                            [
                                7.012624740600586,
                                50.91469591362739
                            ],
                            [
                                7.0069169998168945,
                                50.915886411952826
                            ],
                            [
                                6.999964714050293,
                                50.91683337750956
                            ],
                            [
                                6.9966816902160645,
                                50.917942655510736
                            ],
                            [
                                6.992722749710083,
                                50.91955917793381
                            ],
                            [
                                6.990566253662109,
                                50.92007320676875
                            ],
                            [
                                6.98979377746582,
                                50.92014760520865
                            ],
                            [
                                6.989257335662842,
                                50.92116888085887
                            ],
                            [
                                6.990448236465454,
                                50.92277176498852
                            ],
                            [
                                6.992229223251343,
                                50.926369599151016
                            ],
                            [
                                6.987390518188477,
                                50.92868910786209
                            ],
                            [
                                6.983002424240112,
                                50.92981838948111
                            ],
                            [
                                6.983002424240112,
                                50.93052164082761
                            ],
                            [
                                6.9842469692230225,
                                50.93149535590776
                            ],
                            [
                                6.983335018157958,
                                50.933787563011535
                            ],
                            [
                                6.980524063110352,
                                50.93668815873275
                            ],
                            [
                                6.980137825012207,
                                50.93776991967491
                            ],
                            [
                                6.981360912322997,
                                50.93813500831307
                            ],
                            [
                                6.983742713928223,
                                50.93776991967491
                            ],
                            [
                                6.988667249679565,
                                50.93868263589572
                            ],
                            [
                                6.992840766906738,
                                50.93940603528457
                            ],
                            [
                                6.992154121398926,
                                50.94193446435752
                            ],
                            [
                                6.994214057922363,
                                50.94542265950697
                            ],
                            [
                                6.989965438842773,
                                50.94451683553694
                            ],
                            [
                                6.988034248352051,
                                50.943570433431546
                            ],
                            [
                                6.984343528747559,
                                50.94201558814745
                            ],
                            [
                                6.982390880584717,
                                50.94150180175399
                            ],
                            [
                                6.980180740356445,
                                50.94221839700297
                            ],
                            [
                                6.982583999633789,
                                50.945071147823235
                            ],
                            [
                                6.9847726821899405,
                                50.94711258241876
                            ],
                            [
                                6.982583999633789,
                                50.94950540817795
                            ],
                            [
                                6.976768970489502,
                                50.95322283096434
                            ],
                            [
                                6.970245838165283,
                                50.95704808653342
                            ],
                            [
                                6.967306137084961,
                                50.95700753777226
                            ],
                            [
                                6.965911388397217,
                                50.95875110255122
                            ],
                            [
                                6.963379383087158,
                                50.95902141681417
                            ],
                            [
                                6.960804462432861,
                                50.95883219699523
                            ],
                            [
                                6.9580793380737305,
                                50.957358959193485
                            ],
                            [
                                6.949281692504883,
                                50.955047638797964
                            ],
                            [
                                6.946663856506348,
                                50.95383110820364
                            ],
                            [
                                6.93960428237915,
                                50.95338503900661
                            ],
                            [
                                6.938767433166504,
                                50.95133036197247
                            ],
                            [
                                6.936471462249756,
                                50.94949188973433
                            ],
                            [
                                6.933681964874268,
                                50.950559834665974
                            ],
                            [
                                6.929948329925537,
                                50.9548854365587
                            ],
                            [
                                6.929111480712891,
                                50.956088423028696
                            ],
                            [
                                6.927158832550049,
                                50.959048448153986
                            ],
                            [
                                6.9268798828125,
                                50.96012968885106
                            ],
                            [
                                6.923382282257079,
                                50.96381923329953
                            ],
                            [
                                6.919198036193848,
                                50.96810306196809
                            ],
                            [
                                6.917288303375244,
                                50.9687381701307
                            ],
                            [
                                6.9161295890808105,
                                50.970670467093605
                            ],
                            [
                                6.917374134063721,
                                50.971900068761215
                            ],
                            [
                                6.914563179016112,
                                50.976385812004686
                            ],
                            [
                                6.901988983154297,
                                50.98790894277794
                            ],
                            [
                                6.901516914367676,
                                50.98553160249181
                            ],
                            [
                                6.901538372039795,
                                50.982924492781805
                            ],
                            [
                                6.9031476974487305,
                                50.97777738524929
                            ],
                            [
                                6.9026970863342285,
                                50.9769397442147
                            ],
                            [
                                6.894328594207764,
                                50.96900842628947
                            ],
                            [
                                6.887204647064209,
                                50.97177846125563
                            ],
                            [
                                6.872034072875977,
                                50.97588591628918
                            ]
                        ],
                        [
                            [
                                6.898384094238281,
                                50.99495920188907
                            ],
                            [
                                6.897568702697753,
                                50.99485116002201
                            ],
                            [
                                6.898384094238281,
                                50.9924606693624
                            ],
                            [
                                6.902740001678467,
                                50.98889496296894
                            ],
                            [
                                6.9145846366882315,
                                50.97819620010022
                            ],
                            [
                                6.917932033538818,
                                50.97388627961264
                            ],
                            [
                                6.920442581176757,
                                50.97261619539096
                            ],
                            [
                                6.921665668487549,
                                50.96984621038867
                            ],
                            [
                                6.921815872192383,
                                50.96695444004575
                            ],
                            [
                                6.929326057434082,
                                50.9598323501676
                            ],
                            [
                                6.930398941040039,
                                50.956480400657284
                            ],
                            [
                                6.932458877563477,
                                50.95384462538519
                            ],
                            [
                                6.936063766479491,
                                50.9512492544365
                            ],
                            [
                                6.938810348510742,
                                50.953858142562815
                            ],
                            [
                                6.9458699226379395,
                                50.95466916602564
                            ],
                            [
                                6.950418949127197,
                                50.95619655511855
                            ],
                            [
                                6.957070827484131,
                                50.958102341904095
                            ],
                            [
                                6.960375308990478,
                                50.95961610265721
                            ],
                            [
                                6.965739727020263,
                                50.95960258715438
                            ],
                            [
                                6.968379020690918,
                                50.95884571272212
                            ],
                            [
                                6.971769332885742,
                                50.95725082980847
                            ],
                            [
                                6.973915100097656,
                                50.95757521720864
                            ],
                            [
                                6.975502967834472,
                                50.9570210540299
                            ],
                            [
                                6.9740867614746085,
                                50.95603435688941
                            ],
                            [
                                6.983013153076172,
                                50.950316507599545
                            ],
                            [
                                6.985909938812256,
                                50.94768038272841
                            ],
                            [
                                6.991403102874756,
                                50.946815160440984
                            ],
                            [
                                6.994256973266602,
                                50.94666644873849
                            ],
                            [
                                6.998569965362549,
                                50.94715313981372
                            ],
                            [
                                7.002754211425781,
                                50.94735592625786
                            ],
                            [
                                7.005865573883057,
                                50.94812650667758
                            ],
                            [
                                7.007474899291992,
                                50.94853207018864
                            ],
                            [
                                7.00927734375,
                                50.94809946898437
                            ],
                            [
                                7.014620304107666,
                                50.94832928887545
                            ],
                            [
                                7.018675804138184,
                                50.948924111552415
                            ],
                            [
                                7.017989158630371,
                                50.949478371286794
                            ],
                            [
                                7.020456790924072,
                                50.95057335279897
                            ],
                            [
                                7.022430896759033,
                                50.95019484358861
                            ],
                            [
                                7.026937007904053,
                                50.95139795147768
                            ],
                            [
                                7.030820846557617,
                                50.951925146244925
                            ],
                            [
                                7.033996582031249,
                                50.9540068312572
                            ],
                            [
                                7.034146785736084,
                                50.95695347270235
                            ],
                            [
                                7.031035423278808,
                                50.96595444018619
                            ],
                            [
                                7.029318809509277,
                                50.96594092652717
                            ],
                            [
                                7.0288896560668945,
                                50.96648146982226
                            ],
                            [
                                7.0282673835754395,
                                50.96910301557352
                            ],
                            [
                                7.02899694442749,
                                50.97023807195767
                            ],
                            [
                                7.02800989151001,
                                50.97329177642871
                            ],
                            [
                                7.0253705978393555,
                                50.97677761839819
                            ],
                            [
                                7.016251087188721,
                                50.98258677355902
                            ],
                            [
                                7.013525962829589,
                                50.981938345764604
                            ],
                            [
                                7.010393142700195,
                                50.979412092780116
                            ],
                            [
                                7.006573677062988,
                                50.97619666290737
                            ],
                            [
                                7.0062947273254395,
                                50.97462939789044
                            ],
                            [
                                7.0046210289001465,
                                50.97437268564797
                            ],
                            [
                                7.002797126770019,
                                50.978344810913484
                            ],
                            [
                                7.000823020935058,
                                50.98137096401539
                            ],
                            [
                                6.999664306640625,
                                50.983262209547924
                            ],
                            [
                                6.999020576477051,
                                50.98553160249181
                            ],
                            [
                                6.9991278648376465,
                                50.9874361859425
                            ],
                            [
                                6.99904203414917,
                                50.9890570464752
                            ],
                            [
                                6.9988274574279785,
                                50.98994849564248
                            ],
                            [
                                6.997668743133545,
                                50.994027332514015
                            ],
                            [
                                6.99402093887329,
                                50.99360866047078
                            ],
                            [
                                6.991209983825684,
                                50.99294688082788
                            ],
                            [
                                6.986875534057617,
                                50.99132615613349
                            ],
                            [
                                6.984987258911133,
                                50.9904077203498
                            ],
                            [
                                6.985523700714111,
                                50.98932718439453
                            ],
                            [
                                6.9796013832092285,
                                50.98708499203354
                            ],
                            [
                                6.977691650390625,
                                50.9853695066749
                            ],
                            [
                                6.991531848907471,
                                50.980722519240096
                            ],
                            [
                                6.997883319854735,
                                50.9753049496424
                            ],
                            [
                                6.998612880706787,
                                50.97015699742153
                            ],
                            [
                                6.995265483856201,
                                50.96467062502492
                            ],
                            [
                                6.9904375076293945,
                                50.96614363099994
                            ],
                            [
                                6.989150047302245,
                                50.967089573510805
                            ],
                            [
                                6.988270282745361,
                                50.96879222148825
                            ],
                            [
                                6.987369060516357,
                                50.96992728546728
                            ],
                            [
                                6.9849443435668945,
                                50.97021104712801
                            ],
                            [
                                6.977841854095459,
                                50.97126500383277
                            ],
                            [
                                6.974666118621826,
                                50.97534548243501
                            ],
                            [
                                6.97331428527832,
                                50.975183351052316
                            ],
                            [
                                6.967735290527343,
                                50.98101972421965
                            ],
                            [
                                6.9591522216796875,
                                50.985342490650396
                            ],
                            [
                                6.953830718994141,
                                50.988746385924294
                            ],
                            [
                                6.947650909423828,
                                50.9929063634003
                            ],
                            [
                                6.946020126342773,
                                50.9940408380009
                            ],
                            [
                                6.937694549560547,
                                50.99652578069471
                            ],
                            [
                                6.923189163208008,
                                50.99847042563645
                            ],
                            [
                                6.9138336181640625,
                                50.99922665443757
                            ],
                            [
                                6.907052993774414,
                                50.999280670308885
                            ],
                            [
                                6.903362274169921,
                                50.99847042563645
                            ],
                            [
                                6.898384094238281,
                                50.99495920188907
                            ]
                        ],
                        [
                            [
                                6.999664306640625,
                                50.94631494645956
                            ],
                            [
                                6.996831893920898,
                                50.94566601219259
                            ],
                            [
                                6.993570327758788,
                                50.94155588479963
                            ],
                            [
                                6.994600296020508,
                                50.938743483006796
                            ],
                            [
                                6.983528137207031,
                                50.93685042447835
                            ],
                            [
                                6.985845565795898,
                                50.93187401737826
                            ],
                            [
                                6.98704719543457,
                                50.9316035452139
                            ],
                            [
                                6.988592147827148,
                                50.92943971127504
                            ],
                            [
                                6.997175216674805,
                                50.92592326642186
                            ],
                            [
                                7.001466751098633,
                                50.92549045483727
                            ],
                            [
                                7.008934020996094,
                                50.92570686113292
                            ],
                            [
                                7.01228141784668,
                                50.92489533233354
                            ],
                            [
                                7.01725959777832,
                                50.92273118632478
                            ],
                            [
                                7.021894454956055,
                                50.92386737553169
                            ],
                            [
                                7.022323608398437,
                                50.92559865811094
                            ],
                            [
                                7.025327682495117,
                                50.92613967070414
                            ],
                            [
                                7.026100158691406,
                                50.924354305269155
                            ],
                            [
                                7.044897079467773,
                                50.920621047038956
                            ],
                            [
                                7.057428359985352,
                                50.923055814643824
                            ],
                            [
                                7.056999206542969,
                                50.925165843506285
                            ],
                            [
                                7.046270370483398,
                                50.935498192630625
                            ],
                            [
                                7.039403915405273,
                                50.94355691326188
                            ],
                            [
                                7.03648567199707,
                                50.94864021986066
                            ],
                            [
                                7.0333099365234375,
                                50.95058687092804
                            ],
                            [
                                7.029104232788085,
                                50.95047872578537
                            ],
                            [
                                7.024812698364258,
                                50.949180964446164
                            ],
                            [
                                7.019233703613281,
                                50.947883166872444
                            ],
                            [
                                7.007732391357422,
                                50.946801641214975
                            ],
                            [
                                7.006101608276367,
                                50.94577416853283
                            ],
                            [
                                7.00404167175293,
                                50.946585333063865
                            ],
                            [
                                6.999664306640625,
                                50.94631494645956
                            ]
                        ],
                        [
                            [
                                7.078285217285155,
                                50.948856518449745
                            ],
                            [
                                7.070302963256836,
                                50.94907281603233
                            ],
                            [
                                7.060861587524414,
                                50.94999206952937
                            ],
                            [
                                7.058372497558593,
                                50.948856518449745
                            ],
                            [
                                7.056398391723632,
                                50.94869429460228
                            ],
                            [
                                7.053995132446288,
                                50.951019448982564
                            ],
                            [
                                7.047128677368163,
                                50.952046805726596
                            ],
                            [
                                7.042150497436523,
                                50.952209017873436
                            ],
                            [
                                7.039918899536133,
                                50.950640943405034
                            ],
                            [
                                7.03974723815918,
                                50.94772093962799
                            ],
                            [
                                7.0429229736328125,
                                50.942853859021554
                            ],
                            [
                                7.046785354614258,
                                50.93809444305038
                            ],
                            [
                                7.056913375854491,
                                50.92879054146357
                            ],
                            [
                                7.063436508178711,
                                50.92402968601044
                            ],
                            [
                                7.068157196044922,
                                50.92370506448664
                            ],
                            [
                                7.076311111450195,
                                50.919917646010035
                            ],
                            [
                                7.082662582397461,
                                50.9198094295233
                            ],
                            [
                                7.090644836425781,
                                50.91937656105958
                            ],
                            [
                                7.09742546081543,
                                50.91786148972436
                            ],
                            [
                                7.09467887878418,
                                50.92462481958783
                            ],
                            [
                                7.093477249145508,
                                50.928465953160334
                            ],
                            [
                                7.09141731262207,
                                50.92927741967155
                            ],
                            [
                                7.090387344360352,
                                50.93241495698836
                            ],
                            [
                                7.090816497802734,
                                50.93371318638334
                            ],
                            [
                                7.08592414855957,
                                50.93928434271681
                            ],
                            [
                                7.080087661743164,
                                50.94561193392811
                            ],
                            [
                                7.078285217285155,
                                50.948856518449745
                            ]
                        ],
                        [
                            [
                                7.078113555908203,
                                50.95026243474511
                            ],
                            [
                                7.072019577026366,
                                50.96167041337127
                            ],
                            [
                                7.071590423583984,
                                50.96421114571444
                            ],
                            [
                                7.073564529418945,
                                50.97004889781987
                            ],
                            [
                                7.074766159057617,
                                50.97231893662098
                            ],
                            [
                                7.077598571777344,
                                50.97258917194503
                            ],
                            [
                                7.078371047973633,
                                50.975129307132285
                            ],
                            [
                                7.080173492431641,
                                50.9768586813772
                            ],
                            [
                                7.081804275512695,
                                50.97718293187813
                            ],
                            [
                                7.081031799316406,
                                50.979344544022084
                            ],
                            [
                                7.0786285400390625,
                                50.981127798285954
                            ],
                            [
                                7.068929672241211,
                                50.985450554654086
                            ],
                            [
                                7.06395149230957,
                                50.987503722928075
                            ],
                            [
                                7.055110931396484,
                                50.98912458110244
                            ],
                            [
                                7.053565979003906,
                                50.9906373309996
                            ],
                            [
                                7.046699523925781,
                                50.99236612765258
                            ],
                            [
                                7.044639587402344,
                                50.99171783645421
                            ],
                            [
                                7.042064666748047,
                                50.99269026985585
                            ],
                            [
                                7.0360565185546875,
                                50.99625568467374
                            ],
                            [
                                7.0333099365234375,
                                50.99636372327078
                            ],
                            [
                                7.03348159790039,
                                50.99917263850339
                            ],
                            [
                                7.030563354492187,
                                50.999334686117315
                            ],
                            [
                                7.027387619018555,
                                50.99771418451001
                            ],
                            [
                                7.021894454956055,
                                50.99625568467374
                            ],
                            [
                                7.016487121582031,
                                50.992041983185366
                            ],
                            [
                                7.013053894042968,
                                50.99074538267704
                            ],
                            [
                                7.015628814697266,
                                50.98669327261579
                            ],
                            [
                                7.019062042236328,
                                50.98496426465637
                            ],
                            [
                                7.018289566040039,
                                50.98328922678308
                            ],
                            [
                                7.02824592590332,
                                50.9770208069107
                            ],
                            [
                                7.031164169311523,
                                50.97069749165596
                            ],
                            [
                                7.0325374603271475,
                                50.97004889781987
                            ],
                            [
                                7.033910751342773,
                                50.9675084847542
                            ],
                            [
                                7.032709121704102,
                                50.96610309017613
                            ],
                            [
                                7.035455703735351,
                                50.95934579185391
                            ],
                            [
                                7.037515640258789,
                                50.955615342177126
                            ],
                            [
                                7.040605545043945,
                                50.954317724262815
                            ],
                            [
                                7.05021858215332,
                                50.95312820934491
                            ],
                            [
                                7.05545425415039,
                                50.9524252998552
                            ],
                            [
                                7.056570053100586,
                                50.95323634832283
                            ],
                            [
                                7.059316635131835,
                                50.95339855631791
                            ],
                            [
                                7.06120491027832,
                                50.95172237973444
                            ],
                            [
                                7.071075439453125,
                                50.95026243474511
                            ],
                            [
                                7.078113555908203,
                                50.95026243474511
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "dk",
                "city": "Copenhagen",
                "zone": "Miljozon",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "DPF compulsory for diesels (EURO-5?)",
                "minEUd": 5,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 1.10.2023, non-Danish vehicle must be registered",
                "img_sign": "sign-be--white",
                "color": 1,
                "urlZone": "https://miljoezoner.dk/en/about-the-low-emission-zones/where-are-the-zones/",
                "urlPark": "https://international.kk.dk/live/transport-and-parking",
                "urlReg": "https://miljoezoner.dk/en/filter-registration/filter-registration/",
                "urlBadge": "https://miljoezoner.dk/"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            12.555388379499504,
                            55.72289379805147
                        ],
                        [
                            12.558123620242071,
                            55.71733918517069
                        ],
                        [
                            12.5590771962911,
                            55.716806766652326
                        ],
                        [
                            12.564288391786818,
                            55.71764072632246
                        ],
                        [
                            12.567550609377037,
                            55.71766428645287
                        ],
                        [
                            12.5720842764226,
                            55.71748524698208
                        ],
                        [
                            12.574434757735787,
                            55.71728264685794
                        ],
                        [
                            12.577061274220682,
                            55.71695283045506
                        ],
                        [
                            12.577998138041039,
                            55.71682561027009
                        ],
                        [
                            12.580382077602167,
                            55.716853881082386
                        ],
                        [
                            12.58271582951096,
                            55.716868016164
                        ],
                        [
                            12.583669405560045,
                            55.7166512776833
                        ],
                        [
                            12.584254934712135,
                            55.71603874938867
                        ],
                        [
                            12.584572793394699,
                            55.715397940276375
                        ],
                        [
                            12.587433520528151,
                            55.71232568135318
                        ],
                        [
                            12.58762590867812,
                            55.71195341254506
                        ],
                        [
                            12.58893080262655,
                            55.71063395560711
                        ],
                        [
                            12.589842555163386,
                            55.709682030120575
                        ],
                        [
                            12.590167673735635,
                            55.7089370004872
                        ],
                        [
                            12.590575048651687,
                            55.708016661707774
                        ],
                        [
                            12.591264762079817,
                            55.707314461724025
                        ],
                        [
                            12.591526934054627,
                            55.706428173149334
                        ],
                        [
                            12.59237395350604,
                            55.70362820202101
                        ],
                        [
                            12.592494956876152,
                            55.70291456123795
                        ],
                        [
                            12.596947400791208,
                            55.70334833669662
                        ],
                        [
                            12.598774537787705,
                            55.703032424602384
                        ],
                        [
                            12.598632253311507,
                            55.70033288407484
                        ],
                        [
                            12.595570894203092,
                            55.700462440146
                        ],
                        [
                            12.59384055916783,
                            55.70092383765072
                        ],
                        [
                            12.592170723067454,
                            55.70085110477035
                        ],
                        [
                            12.591874650481628,
                            55.70330076419404
                        ],
                        [
                            12.591610749349883,
                            55.70445738972444
                        ],
                        [
                            12.590933470089738,
                            55.706610427027044
                        ],
                        [
                            12.590722632660658,
                            55.707318629598916
                        ],
                        [
                            12.590024851866076,
                            55.70805946044172
                        ],
                        [
                            12.589647988188403,
                            55.70875907970557
                        ],
                        [
                            12.5892970810836,
                            55.70958623365519
                        ],
                        [
                            12.588430052938833,
                            55.710613157641035
                        ],
                        [
                            12.5867037564513,
                            55.7125218532953
                        ],
                        [
                            12.58536062859946,
                            55.71405101857073
                        ],
                        [
                            12.583920698110859,
                            55.71570508607263
                        ],
                        [
                            12.583025279360896,
                            55.71627763145679
                        ],
                        [
                            12.581984658290224,
                            55.716393502658235
                        ],
                        [
                            12.580851268674593,
                            55.71637532680623
                        ],
                        [
                            12.579048323743308,
                            55.71644121086828
                        ],
                        [
                            12.577027582826958,
                            55.71650709818039
                        ],
                        [
                            12.573554801904976,
                            55.71696830573484
                        ],
                        [
                            12.57109037691572,
                            55.71716823364039
                        ],
                        [
                            12.566572952257275,
                            55.71726138237827
                        ],
                        [
                            12.562801704086155,
                            55.71700238013608
                        ],
                        [
                            12.558986095741687,
                            55.71599362406235
                        ],
                        [
                            12.558498052526915,
                            55.71595045595808
                        ],
                        [
                            12.557332390551522,
                            55.7162003762499
                        ],
                        [
                            12.556961316371712,
                            55.71694331063614
                        ],
                        [
                            12.55642104511017,
                            55.7184286726837
                        ],
                        [
                            12.555847369206106,
                            55.71944855579429
                        ],
                        [
                            12.55552534440065,
                            55.72007200491112
                        ],
                        [
                            12.555798616495935,
                            55.720113604037465
                        ],
                        [
                            12.555579367564007,
                            55.7205717508088
                        ],
                        [
                            12.55526378239631,
                            55.72094763987275
                        ],
                        [
                            12.555025800138736,
                            55.72158868264299
                        ],
                        [
                            12.55449292734798,
                            55.7228299459091
                        ],
                        [
                            12.554249771563093,
                            55.72319998586528
                        ],
                        [
                            12.553680683554916,
                            55.723331101543664
                        ],
                        [
                            12.549598766601804,
                            55.72371861803421
                        ],
                        [
                            12.548688222324955,
                            55.72382933618971
                        ],
                        [
                            12.546091111598287,
                            55.72405368580027
                        ],
                        [
                            12.544445922153528,
                            55.72413817972307
                        ],
                        [
                            12.543742322435435,
                            55.72387886698135
                        ],
                        [
                            12.543607810723785,
                            55.72359041595351
                        ],
                        [
                            12.541786729098817,
                            55.72370696208014
                        ],
                        [
                            12.540855493306339,
                            55.72416149087934
                        ],
                        [
                            12.540213975915435,
                            55.72519581046845
                        ],
                        [
                            12.539634542357504,
                            55.72602616264112
                        ],
                        [
                            12.538894727947223,
                            55.72621553810632
                        ],
                        [
                            12.536918440502035,
                            55.7267370442558
                        ],
                        [
                            12.535056485950378,
                            55.72707820613013
                        ],
                        [
                            12.533183669051226,
                            55.7269471030333
                        ],
                        [
                            12.52937079054982,
                            55.72711607810709
                        ],
                        [
                            12.52750832070609,
                            55.726798516821816
                        ],
                        [
                            12.526282192451362,
                            55.72708694947971
                        ],
                        [
                            12.525728625026147,
                            55.7268160026546
                        ],
                        [
                            12.525340610474814,
                            55.727328760649414
                        ],
                        [
                            12.52126386947424,
                            55.72773954633857
                        ],
                        [
                            12.519466087003508,
                            55.72739167766264
                        ],
                        [
                            12.518152011058561,
                            55.72638363729013
                        ],
                        [
                            12.51679471353637,
                            55.72468863421079
                        ],
                        [
                            12.51545814253808,
                            55.72284722399658
                        ],
                        [
                            12.514635551690333,
                            55.72286470627037
                        ],
                        [
                            12.514733848709767,
                            55.72204594471316
                        ],
                        [
                            12.514144069128065,
                            55.72096783214124
                        ],
                        [
                            12.513569807592603,
                            55.72036174597645
                        ],
                        [
                            12.513581374184582,
                            55.71984641152642
                        ],
                        [
                            12.512334554095105,
                            55.71982601396314
                        ],
                        [
                            12.511004956029183,
                            55.720021249570664
                        ],
                        [
                            12.508276102851909,
                            55.72022813902461
                        ],
                        [
                            12.507236223855955,
                            55.72004456236593
                        ],
                        [
                            12.50609804784051,
                            55.71977648061531
                        ],
                        [
                            12.505192676924452,
                            55.72000085660642
                        ],
                        [
                            12.503485412900943,
                            55.720117413445564
                        ],
                        [
                            12.502580045615787,
                            55.72049622077088
                        ],
                        [
                            12.5009621696328,
                            55.72044984933777
                        ],
                        [
                            12.498634082327783,
                            55.72117831550102
                        ],
                        [
                            12.494156274316595,
                            55.72296661603005
                        ],
                        [
                            12.492324024658075,
                            55.722654845152505
                        ],
                        [
                            12.48894126509515,
                            55.72444719660882
                        ],
                        [
                            12.484326473792493,
                            55.724747293900066
                        ],
                        [
                            12.477514612538783,
                            55.722090231890974
                        ],
                        [
                            12.477111078672692,
                            55.72102047855623
                        ],
                        [
                            12.474967908347452,
                            55.7201308062555
                        ],
                        [
                            12.473616816020723,
                            55.71943055324397
                        ],
                        [
                            12.472887348665068,
                            55.7188477553199
                        ],
                        [
                            12.469772858080802,
                            55.71884192721541
                        ],
                        [
                            12.466177254764006,
                            55.71883027067838
                        ],
                        [
                            12.465468481517718,
                            55.71841065060019
                        ],
                        [
                            12.464529046842188,
                            55.7179692762424
                        ],
                        [
                            12.46323566500655,
                            55.71754964691394
                        ],
                        [
                            12.460116026967285,
                            55.717036760200585
                        ],
                        [
                            12.459076147971302,
                            55.71609839376373
                        ],
                        [
                            12.45876573633015,
                            55.71622370487151
                        ],
                        [
                            12.458036270041163,
                            55.714617939945356
                        ],
                        [
                            12.45737405854095,
                            55.714087528841674
                        ],
                        [
                            12.45523795446826,
                            55.71348133293276
                        ],
                        [
                            12.453763499175011,
                            55.71266237465096
                        ],
                        [
                            12.453075420038914,
                            55.71223103029038
                        ],
                        [
                            12.453489304133768,
                            55.70947380997467
                        ],
                        [
                            12.453742499509246,
                            55.706958164975475
                        ],
                        [
                            12.454451272726686,
                            55.70635186146569
                        ],
                        [
                            12.455548060523398,
                            55.70508967050043
                        ],
                        [
                            12.456888004181252,
                            55.704372564486164
                        ],
                        [
                            12.459748964803055,
                            55.70359714251978
                        ],
                        [
                            12.461269993399014,
                            55.70315986370696
                        ],
                        [
                            12.463215239681006,
                            55.703040341073944
                        ],
                        [
                            12.463789501215643,
                            55.702824616371515
                        ],
                        [
                            12.464255118676931,
                            55.70233485857304
                        ],
                        [
                            12.464094734499554,
                            55.70191797427958
                        ],
                        [
                            12.463986090425465,
                            55.70123870945167
                        ],
                        [
                            12.46399126395275,
                            55.70075767786247
                        ],
                        [
                            12.463794669913028,
                            55.70078974681971
                        ],
                        [
                            12.464079213398406,
                            55.699422418970954
                        ],
                        [
                            12.464141295596704,
                            55.69905086141233
                        ],
                        [
                            12.463996436831195,
                            55.698444435157995
                        ],
                        [
                            12.463494605183655,
                            55.69689042697888
                        ],
                        [
                            12.463494605183655,
                            55.69623440036588
                        ],
                        [
                            12.46438445015994,
                            55.6952663780437
                        ],
                        [
                            12.4664404649169,
                            55.69338120990659
                        ],
                        [
                            12.466864693402727,
                            55.69270471265102
                        ],
                        [
                            12.466859519875442,
                            55.692421863355406
                        ],
                        [
                            12.466978511005038,
                            55.691704525037295
                        ],
                        [
                            12.467278576021187,
                            55.691643289574245
                        ],
                        [
                            12.46729927013061,
                            55.691386677146625
                        ],
                        [
                            12.466735355649774,
                            55.69140708956044
                        ],
                        [
                            12.466119706175135,
                            55.690570173146455
                        ],
                        [
                            12.466078317956402,
                            55.69021440479267
                        ],
                        [
                            12.466600843504636,
                            55.68840635397868
                        ],
                        [
                            12.467164757497471,
                            55.68625991136784
                        ],
                        [
                            12.467594158288762,
                            55.68447008593438
                        ],
                        [
                            12.467583811234107,
                            55.68388968305666
                        ],
                        [
                            12.46792387838039,
                            55.68273288980714
                        ],
                        [
                            12.467872143107002,
                            55.68235079954766
                        ],
                        [
                            12.469450067768406,
                            55.681259924487904
                        ],
                        [
                            12.469475934346207,
                            55.680189438529084
                        ],
                        [
                            12.470469251452812,
                            55.67876304941541
                        ],
                        [
                            12.472160994786435,
                            55.67748246486397
                        ],
                        [
                            12.472528315334586,
                            55.6764702189339
                        ],
                        [
                            12.472911156357611,
                            55.67555421609944
                        ],
                        [
                            12.47310775004027,
                            55.67447482162902
                        ],
                        [
                            12.473780308594314,
                            55.67380383201669
                        ],
                        [
                            12.474240751714035,
                            55.67298112328828
                        ],
                        [
                            12.474499428080946,
                            55.67296361868935
                        ],
                        [
                            12.474587378045754,
                            55.672181738614626
                        ],
                        [
                            12.47850374010693,
                            55.67203294524191
                        ],
                        [
                            12.478477648056526,
                            55.6701160672352
                        ],
                        [
                            12.478389698091775,
                            55.668855623798976
                        ],
                        [
                            12.47834831013995,
                            55.66745216627558
                        ],
                        [
                            12.478141369502367,
                            55.665961119810845
                        ],
                        [
                            12.478389698815477,
                            55.66559929159976
                        ],
                        [
                            12.478405219391647,
                            55.66496024870489
                        ],
                        [
                            12.478379351755024,
                            55.66383387269059
                        ],
                        [
                            12.478162063885208,
                            55.66215592220496
                        ],
                        [
                            12.478120673152034,
                            55.66131546679202
                        ],
                        [
                            12.477592973362647,
                            55.660235680033026
                        ],
                        [
                            12.477458461544813,
                            55.6586101107053
                        ],
                        [
                            12.47797063916056,
                            55.65701949858149
                        ],
                        [
                            12.479496829727225,
                            55.655688584038586
                        ],
                        [
                            12.48174226742924,
                            55.654081225150975
                        ],
                        [
                            12.482471734784866,
                            55.654063712096956
                        ],
                        [
                            12.483361581487998,
                            55.65378642103042
                        ],
                        [
                            12.484520451243924,
                            55.652972046016146
                        ],
                        [
                            12.48607250900713,
                            55.65200878868998
                        ],
                        [
                            12.487345196733344,
                            55.65131989952923
                        ],
                        [
                            12.488074662216434,
                            55.65034492076532
                        ],
                        [
                            12.488638573863653,
                            55.64930277969739
                        ],
                        [
                            12.48900589430474,
                            55.64881818984435
                        ],
                        [
                            12.490169937588774,
                            55.64753078563456
                        ],
                        [
                            12.490361357969192,
                            55.646970269847486
                        ],
                        [
                            12.49054243142686,
                            55.64643018234034
                        ],
                        [
                            12.491354675219924,
                            55.646030220846
                        ],
                        [
                            12.492358341164191,
                            55.645784985327026
                        ],
                        [
                            12.494143208097768,
                            55.64545216545477
                        ],
                        [
                            12.497133508154462,
                            55.64330628299865
                        ],
                        [
                            12.497323241715037,
                            55.64298315245483
                        ],
                        [
                            12.497995800269933,
                            55.64272038136187
                        ],
                        [
                            12.49871492057008,
                            55.642539360250396
                        ],
                        [
                            12.500003129560497,
                            55.641882419983006
                        ],
                        [
                            12.500670514588052,
                            55.641459053790555
                        ],
                        [
                            12.501161999685223,
                            55.641234229883594
                        ],
                        [
                            12.501632790609193,
                            55.64075537972815
                        ],
                        [
                            12.501844905230143,
                            55.64029112320003
                        ],
                        [
                            12.501881119921507,
                            55.640095491443134
                        ],
                        [
                            12.50245751671281,
                            55.63916340446457
                        ],
                        [
                            12.504583833530717,
                            55.63746103786346
                        ],
                        [
                            12.506704975744043,
                            55.635808240279914
                        ],
                        [
                            12.507299931388843,
                            55.635706033012895
                        ],
                        [
                            12.508923277940056,
                            55.63446892028733
                        ],
                        [
                            12.509506181732462,
                            55.633318270866766
                        ],
                        [
                            12.523336487669525,
                            55.62977539811146
                        ],
                        [
                            12.53586085385686,
                            55.628523464281415
                        ],
                        [
                            12.54485721812381,
                            55.63018795972809
                        ],
                        [
                            12.567063280930398,
                            55.62907830704086
                        ],
                        [
                            12.57037353197552,
                            55.62907452465299
                        ],
                        [
                            12.578352858336928,
                            55.62857823939635
                        ],
                        [
                            12.581287627523409,
                            55.628478981590945
                        ],
                        [
                            12.587184211220318,
                            55.628585874694835
                        ],
                        [
                            12.587603463962012,
                            55.631632202941574
                        ],
                        [
                            12.588121593766857,
                            55.63491071476312
                        ],
                        [
                            12.588594942790536,
                            55.63909390984489
                        ],
                        [
                            12.589000670580617,
                            55.64134562646066
                        ],
                        [
                            12.588946573452802,
                            55.64220811415444
                        ],
                        [
                            12.59054243872572,
                            55.64263553464656
                        ],
                        [
                            12.591719051256916,
                            55.64270422679053
                        ],
                        [
                            12.59822424617866,
                            55.642666058941785
                        ],
                        [
                            12.598845463970406,
                            55.642588922327576
                        ],
                        [
                            12.600339212812685,
                            55.64265594998608
                        ],
                        [
                            12.60253187941126,
                            55.64274875795746
                        ],
                        [
                            12.603920563350385,
                            55.64282094129038
                        ],
                        [
                            12.605916789010507,
                            55.64290085777739
                        ],
                        [
                            12.607136455496686,
                            55.64299108657235
                        ],
                        [
                            12.61012395508618,
                            55.64326950639719
                        ],
                        [
                            12.613216519060927,
                            55.643612371734264
                        ],
                        [
                            12.615368067887118,
                            55.643810871888206
                        ],
                        [
                            12.614883855723122,
                            55.6447595307813
                        ],
                        [
                            12.614254286910182,
                            55.64610223371045
                        ],
                        [
                            12.614222310635284,
                            55.6462981428154
                        ],
                        [
                            12.614761339270444,
                            55.64630072056025
                        ],
                        [
                            12.616478925546659,
                            55.64626720922848
                        ],
                        [
                            12.617666615757969,
                            55.64643734015894
                        ],
                        [
                            12.619786187936569,
                            55.646452806222754
                        ],
                        [
                            12.62110635128522,
                            55.646411562444996
                        ],
                        [
                            12.622664056634989,
                            55.64665129196243
                        ],
                        [
                            12.624240030184097,
                            55.64690390787365
                        ],
                        [
                            12.625062277253932,
                            55.64645538462406
                        ],
                        [
                            12.626291087722194,
                            55.64587538696918
                        ],
                        [
                            12.627108766751235,
                            55.64529280599211
                        ],
                        [
                            12.62814004054124,
                            55.644519873971404
                        ],
                        [
                            12.628669933097882,
                            55.64445800523504
                        ],
                        [
                            12.630416716001463,
                            55.6447315106972
                        ],
                        [
                            12.63255455838069,
                            55.64504085124142
                        ],
                        [
                            12.634208188985298,
                            55.64527285523732
                        ],
                        [
                            12.636834811567724,
                            55.64560539162753
                        ],
                        [
                            12.638803638689467,
                            55.645855437690926
                        ],
                        [
                            12.64127494793675,
                            55.646164769357995
                        ],
                        [
                            12.643390027434805,
                            55.64650402718303
                        ],
                        [
                            12.643860535479462,
                            55.64570492230118
                        ],
                        [
                            12.64721347912294,
                            55.64616892158844
                        ],
                        [
                            12.651977943516158,
                            55.646877797324436
                        ],
                        [
                            12.652729200870027,
                            55.648087443087576
                        ],
                        [
                            12.653176230886118,
                            55.65159335919532
                        ],
                        [
                            12.656242329002396,
                            55.655414078546414
                        ],
                        [
                            12.64741401005844,
                            55.6601153531258
                        ],
                        [
                            12.644227416676358,
                            55.66499428648561
                        ],
                        [
                            12.648624570656068,
                            55.66912498597057
                        ],
                        [
                            12.650935178994189,
                            55.675332059330344
                        ],
                        [
                            12.637791649921354,
                            55.68740671903129
                        ],
                        [
                            12.633617124371,
                            55.69139950317242
                        ],
                        [
                            12.631292519817464,
                            55.69581153167542
                        ],
                        [
                            12.628799874807072,
                            55.702716659702986
                        ],
                        [
                            12.624768498662746,
                            55.71412946236447
                        ],
                        [
                            12.627247149177464,
                            55.71616414231576
                        ],
                        [
                            12.64094274521338,
                            55.72237952934222
                        ],
                        [
                            12.636251513178735,
                            55.725005784719286
                        ],
                        [
                            12.626528231549202,
                            55.72604846894734
                        ],
                        [
                            12.622803253277084,
                            55.72574090683332
                        ],
                        [
                            12.61732676561141,
                            55.72268869436138
                        ],
                        [
                            12.615702338996869,
                            55.721545083561864
                        ],
                        [
                            12.6019809069943,
                            55.7265070042817
                        ],
                        [
                            12.60086061277812,
                            55.72501650588612
                        ],
                        [
                            12.600272472645372,
                            55.723060623942075
                        ],
                        [
                            12.598998137973354,
                            55.71947197396332
                        ],
                        [
                            12.595455207511378,
                            55.7201187440337
                        ],
                        [
                            12.594376924786076,
                            55.71887250566047
                        ],
                        [
                            12.590259843537183,
                            55.719755909810175
                        ],
                        [
                            12.585106480183896,
                            55.719953092925095
                        ],
                        [
                            12.584336277910296,
                            55.71991365586507
                        ],
                        [
                            12.58308995059366,
                            55.72186968604635
                        ],
                        [
                            12.580254375358948,
                            55.72212973079152
                        ],
                        [
                            12.577999727284265,
                            55.7220450751374
                        ],
                        [
                            12.577736685009143,
                            55.72228211050691
                        ],
                        [
                            12.577977180803487,
                            55.72298474261268
                        ],
                        [
                            12.577406004294602,
                            55.72473703761872
                        ],
                        [
                            12.576616878108581,
                            55.72662468445671
                        ],
                        [
                            12.576120854674116,
                            55.72764465316169
                        ],
                        [
                            12.576549237809076,
                            55.72893967887194
                        ],
                        [
                            12.573370181940618,
                            55.729367112303606
                        ],
                        [
                            12.568041715153441,
                            55.73010770172826
                        ],
                        [
                            12.567696002449026,
                            55.7306070643117
                        ],
                        [
                            12.56755320802597,
                            55.73149997686798
                        ],
                        [
                            12.567665940429606,
                            55.73183005294297
                        ],
                        [
                            12.567199979828018,
                            55.73199508993423
                        ],
                        [
                            12.567124824892261,
                            55.732354783522965
                        ],
                        [
                            12.56586973746414,
                            55.73263830436895
                        ],
                        [
                            12.566057624803506,
                            55.73191045566708
                        ],
                        [
                            12.566215450168528,
                            55.731237606714615
                        ],
                        [
                            12.56366018235093,
                            55.731538062711394
                        ],
                        [
                            12.56282596965275,
                            55.72880424788735
                        ],
                        [
                            12.562104491693901,
                            55.72647231443747
                        ],
                        [
                            12.561593438607133,
                            55.72480051776091
                        ],
                        [
                            12.561366483780347,
                            55.722742651252304
                        ],
                        [
                            12.556511467968107,
                            55.72291195815032
                        ],
                        [
                            12.555388379499504,
                            55.72289379805147
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "dk",
                "city": "Aarhus",
                "zone": "Miljozon",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "DPF compulsory for diesels (EURO-5?)",
                "minEUd": 5,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 1.10.2023, non-Danish vehicle must be registered",
                "img_sign": "sign-be--white",
                "color": 1,
                "urlZone": "https://aarhus.dk/virksomhed/transport/miljoezone-i-aarhus",
                "urlPark": "https://www.visitaarhus.com/aarhus-region/plan-your-trip/parking-aarhus-gdk1102033",
                "urlReg": "https://miljoezoner.dk/en/filter-registration/filter-registration/",
                "urlBadge": "https://miljoezoner.dk/"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                10.203359973059491,
                                56.17105123269863
                            ],
                            [
                                10.195495467517844,
                                56.169337439662655
                            ],
                            [
                                10.189992422085425,
                                56.1664613822164
                            ],
                            [
                                10.18592312767683,
                                56.1629041409669
                            ],
                            [
                                10.183603836498008,
                                56.16055596181073
                            ],
                            [
                                10.183329738449942,
                                56.15516634786931
                            ],
                            [
                                10.183645989253762,
                                56.151373249702885
                            ],
                            [
                                10.184826719307978,
                                56.14873074506656
                            ],
                            [
                                10.187757459978684,
                                56.145477278687
                            ],
                            [
                                10.1920797618738,
                                56.14276389853171
                            ],
                            [
                                10.19515809380124,
                                56.14101360152597
                            ],
                            [
                                10.196186277863063,
                                56.14079507171789
                            ],
                            [
                                10.199389611121745,
                                56.14105908303691
                            ],
                            [
                                10.203066835958651,
                                56.141808874534064
                            ],
                            [
                                10.206137439604333,
                                56.14281207921917
                            ],
                            [
                                10.208563880647176,
                                56.14354075671102
                            ],
                            [
                                10.209037752822269,
                                56.14500862124618
                            ],
                            [
                                10.210383697107062,
                                56.14606461873055
                            ],
                            [
                                10.212506818257623,
                                56.1481765498628
                            ],
                            [
                                10.213625288738854,
                                56.149665417374166
                            ],
                            [
                                10.215426115122227,
                                56.151196456911805
                            ],
                            [
                                10.215065939891133,
                                56.15152377229572
                            ],
                            [
                                10.214250819510653,
                                56.15188275979341
                            ],
                            [
                                10.215729414848226,
                                56.153096971600064
                            ],
                            [
                                10.214990135823086,
                                56.15440617413984
                            ],
                            [
                                10.214364562342126,
                                56.15606369906658
                            ],
                            [
                                10.215122804520973,
                                56.157942856858085
                            ],
                            [
                                10.218478138872939,
                                56.15754172160402
                            ],
                            [
                                10.22033585325454,
                                56.15837571453474
                            ],
                            [
                                10.216771986773097,
                                56.16010696296587
                            ],
                            [
                                10.21531234870966,
                                56.15969526216219
                            ],
                            [
                                10.213682105846914,
                                56.15991692949672
                            ],
                            [
                                10.211672763279068,
                                56.1607191559097
                            ],
                            [
                                10.210592294661012,
                                56.16183800247586
                            ],
                            [
                                10.208393467109715,
                                56.164940983561394
                            ],
                            [
                                10.205455751808643,
                                56.169615357647274
                            ],
                            [
                                10.204299792893863,
                                56.17112390824013
                            ],
                            [
                                10.203359973059491,
                                56.17105123269863
                            ]
                        ],
                        [
                            [
                                10.20557132379787,
                                56.17137850978227
                            ],
                            [
                                10.207003101568375,
                                56.16912003319979
                            ],
                            [
                                10.209900747056281,
                                56.164507773477396
                            ],
                            [
                                10.212150683551982,
                                56.162040081988124
                            ],
                            [
                                10.21271498821531,
                                56.16115968507631
                            ],
                            [
                                10.215312190871515,
                                56.16053723249274
                            ],
                            [
                                10.21664366818257,
                                56.16233133316521
                            ],
                            [
                                10.217317625833147,
                                56.16268831258864
                            ],
                            [
                                10.219438126736492,
                                56.1617272065821
                            ],
                            [
                                10.221870949477875,
                                56.16106814856397
                            ],
                            [
                                10.22420514426912,
                                56.15992392375864
                            ],
                            [
                                10.229486831743515,
                                56.16243390913422
                            ],
                            [
                                10.233185379830047,
                                56.16565574850347
                            ],
                            [
                                10.227109410734698,
                                56.168658228700394
                            ],
                            [
                                10.225580677525897,
                                56.17006758824937
                            ],
                            [
                                10.222391707176513,
                                56.170113345809625
                            ],
                            [
                                10.221536931618289,
                                56.169243942846265
                            ],
                            [
                                10.219728752554374,
                                56.16953679656925
                            ],
                            [
                                10.221902400423033,
                                56.17445456377587
                            ],
                            [
                                10.222050342346364,
                                56.175049339841905
                            ],
                            [
                                10.221277756746844,
                                56.1758911609854
                            ],
                            [
                                10.219075074210963,
                                56.17807798416072
                            ],
                            [
                                10.218401119088185,
                                56.177071521001665
                            ],
                            [
                                10.216543626049827,
                                56.17537876177002
                            ],
                            [
                                10.215129958781347,
                                56.174335618913545
                            ],
                            [
                                10.212023178388051,
                                56.17301792426622
                            ],
                            [
                                10.20929445008781,
                                56.17213943527079
                            ],
                            [
                                10.20557132379787,
                                56.17137850978227
                            ]
                        ]
                        
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "dk",
                "city": "Aarhus",
                "zone": "Miljozon-2024",
                "area_code": 0,
                "time": "Miljozon extension on 1/3/2024",
                "commercial": 2,
                "diesel": "DPF compulsory for diesels (EURO-5?)",
                "minEUd": 5,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 1.3.2024, non-Danish vehicle must be registered",
                "img_sign": "sign-be--white",
                "color": 3,
                "urlZone": "https://aarhus.dk/virksomhed/transport/miljoezone-i-aarhus",
                "urlPark": "https://www.visitaarhus.com/aarhus-region/plan-your-trip/parking-aarhus-gdk1102033",
                "urlReg": "https://miljoezoner.dk/en/filter-registration/filter-registration/",
                "urlBadge": "https://miljoezoner.dk/"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                  [
                    [
                      10.20433644287965,
                      56.171127751310564
                    ],
                    [
                      10.205074301142986,
                      56.17008524033952
                    ],
                    [
                      10.208060408207842,
                      56.16540930488796
                    ],
                    [
                      10.210575288509887,
                      56.16193887188251
                    ],
                    [
                      10.211535108638344,
                      56.16073628780069
                    ],
                    [
                      10.213348102213814,
                      56.1600087803879
                    ],
                    [
                      10.21524108079953,
                      56.159741529306785
                    ],
                    [
                      10.216520840970531,
                      56.16006816926463
                    ],
                    [
                      10.219640256386981,
                      56.16156770797295
                    ],
                    [
                      10.218493804566634,
                      56.162117029285014
                    ],
                    [
                      10.21532106580986,
                      56.160498735874825
                    ],
                    [
                      10.212734868999519,
                      56.16110746448453
                    ],
                    [
                      10.20980208527493,
                      56.16456661451832
                    ],
                    [
                      10.205509556369037,
                      56.1713800803399
                    ],
                    [
                      10.20433644287965,
                      56.171127751310564
                    ]
                  ]
                ]
              }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "dk",
                "city": "Odense",
                "zone": "Miljozon",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "DPF compulsory for diesels (EURO-5?)",
                "minEUd": 5,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 1.10.2023, non-Danish vehicle must be registered",
                "img_sign": "sign-be--white",
                "color": 1,
                "urlZone": "https://www.odense.dk/erhverv/byggeri-og-miljoe/transport/miljozone",
                "urlPark": "https://odenserundt.dk/tag/51",
                "urlReg": "https://miljoezoner.dk/en/filter-registration/filter-registration/",
                "urlBadge": "https://miljoezoner.dk/"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            10.340518000253326,
                            55.416250147783046
                        ],
                        [
                            10.340268432967918,
                            55.41465877264167
                        ],
                        [
                            10.340855467047106,
                            55.41300653707111
                        ],
                        [
                            10.34107560248367,
                            55.410715511762106
                        ],
                        [
                            10.340977764511877,
                            55.408452126007006
                        ],
                        [
                            10.341124522558118,
                            55.40711902657506
                        ],
                        [
                            10.340708711176973,
                            55.40584143243066
                        ],
                        [
                            10.340659792191104,
                            55.40349443990351
                        ],
                        [
                            10.340261705678557,
                            55.39982160661026
                        ],
                        [
                            10.340090489227947,
                            55.397654826909246
                        ],
                        [
                            10.339625758860961,
                            55.39547403767236
                        ],
                        [
                            10.339664776791324,
                            55.39427407606246
                        ],
                        [
                            10.339982750199653,
                            55.39367675072023
                        ],
                        [
                            10.34617100192142,
                            55.39391290367848
                        ],
                        [
                            10.347369517076856,
                            55.391579094886396
                        ],
                        [
                            10.348714789189955,
                            55.390689988633056
                        ],
                        [
                            10.350818321550463,
                            55.389439650978204
                        ],
                        [
                            10.35497643535561,
                            55.38660540174081
                        ],
                        [
                            10.356248328989835,
                            55.38557724151315
                        ],
                        [
                            10.358905442647199,
                            55.38382276815716
                        ],
                        [
                            10.363357070367726,
                            55.38243325833383
                        ],
                        [
                            10.366341128510328,
                            55.382155350511084
                        ],
                        [
                            10.370645999273137,
                            55.382210932232084
                        ],
                        [
                            10.376369560344727,
                            55.38165512342309
                        ],
                        [
                            10.381359296911057,
                            55.38187745264938
                        ],
                        [
                            10.386544709420946,
                            55.381919139240125
                        ],
                        [
                            10.39361351895414,
                            55.38187746178971
                        ],
                        [
                            10.398505417547653,
                            55.38190525285506
                        ],
                        [
                            10.407017322393045,
                            55.38211369397595
                        ],
                        [
                            10.41362138549593,
                            55.382474974145396
                        ],
                        [
                            10.417485985384644,
                            55.38350321502617
                        ],
                        [
                            10.422328966991984,
                            55.385392890341905
                        ],
                        [
                            10.426438161811177,
                            55.38708795892063
                        ],
                        [
                            10.424897213753468,
                            55.38865791664375
                        ],
                        [
                            10.422328966991984,
                            55.391408665726146
                        ],
                        [
                            10.421619638190293,
                            55.39243667486343
                        ],
                        [
                            10.421619638190293,
                            55.39347854821207
                        ],
                        [
                            10.421913152105617,
                            55.394631522713325
                        ],
                        [
                            10.423380721683515,
                            55.39631230481808
                        ],
                        [
                            10.424212344444726,
                            55.39811802414269
                        ],
                        [
                            10.424698959262287,
                            55.40107571562203
                        ],
                        [
                            10.425016932670616,
                            55.404756074160105
                        ],
                        [
                            10.424723417789778,
                            55.40738072705204
                        ],
                        [
                            10.423916254521515,
                            55.41014405913165
                        ],
                        [
                            10.422742198859027,
                            55.41274058199892
                        ],
                        [
                            10.420614221174986,
                            55.41573956311322
                        ],
                        [
                            10.419635841456113,
                            55.41676693902036
                        ],
                        [
                            10.417092054187492,
                            55.419265849952524
                        ],
                        [
                            10.41508637576382,
                            55.42068182930569
                        ],
                        [
                            10.41125180653853,
                            55.42216404332771
                        ],
                        [
                            10.408316667381797,
                            55.42360768053419
                        ],
                        [
                            10.407240449691102,
                            55.424579329709445
                        ],
                        [
                            10.402593146027016,
                            55.424690373807096
                        ],
                        [
                            10.396184758868714,
                            55.42474589573885
                        ],
                        [
                            10.3933229733058,
                            55.42449604840738
                        ],
                        [
                            10.390607969585687,
                            55.42364932510816
                        ],
                        [
                            10.387550532964212,
                            55.42228897753279
                        ],
                        [
                            10.38446863684976,
                            55.421817009261815
                        ],
                        [
                            10.381191064791778,
                            55.42190029818997
                        ],
                        [
                            10.374345526841353,
                            55.42246943531927
                        ],
                        [
                            10.37055430543046,
                            55.422663772668216
                        ],
                        [
                            10.367668085260647,
                            55.42312184977828
                        ],
                        [
                            10.363020735014459,
                            55.423704860967945
                        ],
                        [
                            10.357884241491405,
                            55.42384366911523
                        ],
                        [
                            10.35423977703823,
                            55.42384366911523
                        ],
                        [
                            10.351353556867423,
                            55.42312186142186
                        ],
                        [
                            10.349274499964821,
                            55.421858666212756
                        ],
                        [
                            10.348051525316464,
                            55.42127563941179
                        ],
                        [
                            10.345507736819684,
                            55.4200262682111
                        ],
                        [
                            10.342083407803045,
                            55.41812437032371
                        ],
                        [
                            10.340518000253326,
                            55.416250147783046
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "dk",
                "city": "Aalborg",
                "zone": "Miljozon",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "DPF compulsory for diesels (EURO-5?)",
                "minEUd": 5,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 1.10.2023, non-Danish vehicle must be registered",
                "img_sign": "sign-be--white",
                "color": 1,
                "urlZone": "https://www.aalborg.dk/mit-liv/trafik-og-parkering/miljoe-og-trafikgener/miljoezone",
                "urlPark": "https://www.aalborg.dk/mit-liv/trafik-og-parkering/parkering",
                "urlReg": "https://miljoezoner.dk/en/filter-registration/filter-registration/",
                "urlBadge": "https://miljoezoner.dk/"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                9.90892111668245,
                                57.05700236039482
                            ],
                            [
                                9.907447510829314,
                                57.05682501128322
                            ],
                            [
                                9.904790188800604,
                                57.056411193394524
                            ],
                            [
                                9.904150015765907,
                                57.05550471904536
                            ],
                            [
                                9.905865196348742,
                                57.05514343686886
                            ],
                            [
                                9.907737400505937,
                                57.054427430895515
                            ],
                            [
                                9.908534597113942,
                                57.0540989831691
                            ],
                            [
                                9.909827021919796,
                                57.0538033777313
                            ],
                            [
                                9.911493887555707,
                                57.05345521719761
                            ],
                            [
                                9.913317776766945,
                                57.05310705339946
                            ],
                            [
                                9.914622280308322,
                                57.053034792579524
                            ],
                            [
                                9.91588072195617,
                                57.05299278126
                            ],
                            [
                                9.91677766456263,
                                57.0529769431397
                            ],
                            [
                                9.91759889123486,
                                57.052821729203
                            ],
                            [
                                9.917913403577103,
                                57.0530909774551
                            ],
                            [
                                9.916177761391424,
                                57.05375300309095
                            ],
                            [
                                9.913030530698137,
                                57.05506756702897
                            ],
                            [
                                9.90892111668245,
                                57.05700236039482
                            ]
                        ],
                        [
                            [
                                9.915803617891754,
                                57.05248041731804
                            ],
                            [
                                9.916736847323705,
                                57.05220445284408
                            ],
                            [
                                9.917034216219236,
                                57.0522217812055
                            ],
                            [
                                9.917228922043734,
                                57.05240661655401
                            ],
                            [
                                9.917402334719611,
                                57.05259824590817
                            ],
                            [
                                9.916640329503196,
                                57.05273638929637
                            ],
                            [
                                9.915930618761166,
                                57.05270794805256
                            ],
                            [
                                9.915684087661731,
                                57.05259011981053
                            ],
                            [
                                9.915803617891754,
                                57.05248041731804
                            ]
                        ],
                        [
                            [
                                9.917356539183402,
                                57.05214245644683
                            ],
                            [
                                9.917822483394048,
                                57.051961897340135
                            ],
                            [
                                9.918428210868882,
                                57.05182568550316
                            ],
                            [
                                9.918748547513673,
                                57.05186369815925
                            ],
                            [
                                9.918131171434595,
                                57.05228500248967
                            ],
                            [
                                9.91763610570976,
                                57.05252574567544
                            ],
                            [
                                9.917356539183402,
                                57.05214245644683
                            ]
                        ],
                        [
                            [
                                9.915020995473242,
                                57.05239903917399
                            ],
                            [
                                9.910706405235317,
                                57.05336626722067
                            ],
                            [
                                9.908199238297868,
                                57.05387973304093
                            ],
                            [
                                9.90596482068537,
                                57.0547754244125
                            ],
                            [
                                9.905167563697745,
                                57.05358306616719
                            ],
                            [
                                9.904957759226505,
                                57.05322363966465
                            ],
                            [
                                9.904548639408489,
                                57.05214534013524
                            ],
                            [
                                9.904223442478497,
                                57.05090725293263
                            ],
                            [
                                9.903898244197364,
                                57.049994350867365
                            ],
                            [
                                9.90361500816229,
                                57.04883607441633
                            ],
                            [
                                9.903363241538528,
                                57.04785464884739
                            ],
                            [
                                9.903384221985647,
                                57.04730686543965
                            ],
                            [
                                9.903635983833283,
                                57.046274043767994
                            ],
                            [
                                9.903835298080224,
                                57.04554363301301
                            ],
                            [
                                9.904170985233748,
                                57.04488739235089
                            ],
                            [
                                9.904800398644966,
                                57.04421402031494
                            ],
                            [
                                9.905734023254809,
                                57.04344362089216
                            ],
                            [
                                9.907160693655385,
                                57.04274739541185
                            ],
                            [
                                9.909101385008427,
                                57.04218812286959
                            ],
                            [
                                9.909006972996735,
                                57.04201120837391
                            ],
                            [
                                9.908398540031698,
                                57.042091105347254
                            ],
                            [
                                9.910381185465724,
                                57.04133207594302
                            ],
                            [
                                9.910601480159642,
                                57.0425020001349
                            ],
                            [
                                9.91100010865432,
                                57.04330665831469
                            ],
                            [
                                9.911713443854524,
                                57.044419454736925
                            ],
                            [
                                9.912521190936957,
                                57.04561780941316
                            ],
                            [
                                9.913265996806928,
                                57.04655934997109
                            ],
                            [
                                9.91449335296062,
                                57.04724409174935
                            ],
                            [
                                9.915224845465247,
                                57.047935031017744
                            ],
                            [
                                9.915620898045233,
                                57.04830252355927
                            ],
                            [
                                9.916040247940742,
                                57.04905699527913
                            ],
                            [
                                9.916110139572368,
                                57.049570201110896
                            ],
                            [
                                9.916296517257592,
                                57.050181603739446
                            ],
                            [
                                9.916541138461525,
                                57.05117946464358
                            ],
                            [
                                9.91689642092311,
                                57.05195556216091
                            ],
                            [
                                9.915020995473242,
                                57.05239903917399
                            ]
                        ],
                        [
                            [
                                9.918152001126003,
                                57.0530505813372
                            ],
                            [
                                9.917859246687613,
                                57.05273214378548
                            ],
                            [
                                9.91845038545651,
                                57.052438199006275
                            ],
                            [
                                9.919159751978952,
                                57.051963595753364
                            ],
                            [
                                9.919480655881557,
                                57.05177987673659
                            ],
                            [
                                9.919114712834755,
                                57.05160534282899
                            ],
                            [
                                9.91865306160554,
                                57.05155022668748
                            ],
                            [
                                9.91723995845453,
                                57.051880922308385
                            ],
                            [
                                9.91692468444404,
                                57.05121646608441
                            ],
                            [
                                9.916682599063762,
                                57.05052750115553
                            ],
                            [
                                9.916598150668335,
                                57.04957823974354
                            ],
                            [
                                9.916389844626309,
                                57.04907297780733
                            ],
                            [
                                9.916005897181918,
                                57.048256233219234
                            ],
                            [
                                9.915454167664251,
                                57.04754577706373
                            ],
                            [
                                9.914506372253754,
                                57.046690027733206
                            ],
                            [
                                9.913729447015811,
                                57.04634091125405
                            ],
                            [
                                9.913431062684424,
                                57.04606529066305
                            ],
                            [
                                9.912672877933147,
                                57.04516406591307
                            ],
                            [
                                9.912092998950328,
                                57.04420547956303
                            ],
                            [
                                9.911376228493339,
                                57.04304334155748
                            ],
                            [
                                9.911117519568364,
                                57.04195206880317
                            ],
                            [
                                9.91095988256356,
                                57.04110366309854
                            ],
                            [
                                9.912097121082525,
                                57.04078205983865
                            ],
                            [
                                9.914050693965237,
                                57.04037469217559
                            ],
                            [
                                9.916488437649207,
                                57.04004389409485
                            ],
                            [
                                9.917973656263086,
                                57.03981585884364
                            ],
                            [
                                9.918120868329027,
                                57.04091932702596
                            ],
                            [
                                9.918104511432773,
                                57.04150219416729
                            ],
                            [
                                9.918006370055508,
                                57.041662369795006
                            ],
                            [
                                9.918006372576002,
                                57.04233865835343
                            ],
                            [
                                9.919666597544563,
                                57.04233865835343
                            ],
                            [
                                9.919666597544563,
                                57.041626774347606
                            ],
                            [
                                9.918668826874068,
                                57.04157783182106
                            ],
                            [
                                9.918513436360001,
                                57.03977581208753
                            ],
                            [
                                9.91957663703539,
                                57.03962897664795
                            ],
                            [
                                9.921408609412964,
                                57.03936645186093
                            ],
                            [
                                9.923150618862593,
                                57.03896598674842
                            ],
                            [
                                9.926209357582337,
                                57.038262938001424
                            ],
                            [
                                9.928057686856107,
                                57.03796480552916
                            ],
                            [
                                9.930126618464584,
                                57.03785801175172
                            ],
                            [
                                9.933103573578563,
                                57.03791140894174
                            ],
                            [
                                9.936203208356915,
                                57.03822734070053
                            ],
                            [
                                9.938272355730874,
                                57.03863226345135
                            ],
                            [
                                9.940333326727739,
                                57.0391172744894
                            ],
                            [
                                9.942549686166359,
                                57.039909298090805
                            ],
                            [
                                9.944242631099598,
                                57.040776947901094
                            ],
                            [
                                9.945444862972778,
                                57.0417825030878
                            ],
                            [
                                9.946769772272376,
                                57.043335276720256
                            ],
                            [
                                9.947963825697514,
                                57.0445543136901
                            ],
                            [
                                9.947350442089373,
                                57.04480790394547
                            ],
                            [
                                9.946614377395179,
                                57.045008104863484
                            ],
                            [
                                9.946107313612458,
                                57.04520385650545
                            ],
                            [
                                9.945461216210589,
                                57.0451771631605
                            ],
                            [
                                9.943964560205472,
                                57.04535066956035
                            ],
                            [
                                9.944062701582794,
                                57.04603578947564
                            ],
                            [
                                9.943907314005997,
                                57.046342754586334
                            ],
                            [
                                9.943383893325745,
                                57.04646731945644
                            ],
                            [
                                9.94352292694353,
                                57.04694778004276
                            ],
                            [
                                9.94574746483164,
                                57.04692108795106
                            ],
                            [
                                9.94574746483164,
                                57.04749051508068
                            ],
                            [
                                9.945444862251605,
                                57.047726290946
                            ],
                            [
                                9.945240396844753,
                                57.04866493124109
                            ],
                            [
                                9.946712517505489,
                                57.04929660927388
                            ],
                            [
                                9.947350436457981,
                                57.04963023770182
                            ],
                            [
                                9.946050057012343,
                                57.05028858838597
                            ],
                            [
                                9.943122172586953,
                                57.04959909855444
                            ],
                            [
                                9.937354759707887,
                                57.04896614197864
                            ],
                            [
                                9.933363675525385,
                                57.04877485905192
                            ],
                            [
                                9.930803821266267,
                                57.04901507519696
                            ],
                            [
                                9.92658942989138,
                                57.049954069587415
                            ],
                            [
                                9.924463036340853,
                                57.05079479235454
                            ],
                            [
                                9.922025858801987,
                                57.051599911368214
                            ],
                            [
                                9.918152001126003,
                                57.0530505813372
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Strasbourg",
                "zone": "ZFE-m",
                "area_code": 0,
                "time": "24/7, Stage 2024",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel M1)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol M1)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://www.strasbourg.eu/zone-faibles-emissions",
                "urlPark": "https://www.cts-strasbourg.eu/en/getting-around/park-and-ride/",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/bon-a-savoir-2",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                7.71093473472996,
                                48.70178372731553
                            ],
                            [
                                7.694292114360394,
                                48.694826860221355
                            ],
                            [
                                7.690884339713307,
                                48.69650078074031
                            ],
                            [
                                7.677649493990032,
                                48.69200199313599
                            ],
                            [
                                7.668297926353745,
                                48.68765975876741
                            ],
                            [
                                7.668535678073653,
                                48.68535769952251
                            ],
                            [
                                7.674162468769879,
                                48.68059628856372
                            ],
                            [
                                7.675192726221809,
                                48.67630540082459
                            ],
                            [
                                7.682721530674172,
                                48.67593908859746
                            ],
                            [
                                7.684544293858124,
                                48.67107211623781
                            ],
                            [
                                7.6902503351683436,
                                48.67300849499151
                            ],
                            [
                                7.697937640762262,
                                48.675206456271525
                            ],
                            [
                                7.705070192349439,
                                48.67709034674746
                            ],
                            [
                                7.71402550712051,
                                48.67850321837349
                            ],
                            [
                                7.720761805840937,
                                48.678136922127095
                            ],
                            [
                                7.724724334500934,
                                48.67881718446435
                            ],
                            [
                                7.727656605709029,
                                48.67960209112985
                            ],
                            [
                                7.72900386545308,
                                48.68122419281602
                            ],
                            [
                                7.727260352843018,
                                48.68750280364472
                            ],
                            [
                                7.7161652725966405,
                                48.68629946386994
                            ],
                            [
                                7.7131537508146835,
                                48.70005767716114
                            ],
                            [
                                7.71093473472996,
                                48.70178372731553
                            ]
                        ],
                        [
                            [
                                7.730985120672045,
                                48.68713656395391
                            ],
                            [
                                7.732807883855202,
                                48.683474109459354
                            ],
                            [
                                7.735423152770267,
                                48.67892183050148
                            ],
                            [
                                7.737721419393182,
                                48.67813691323556
                            ],
                            [
                                7.738196922832259,
                                48.67651471212881
                            ],
                            [
                                7.741604697479374,
                                48.675468103046825
                            ],
                            [
                                7.746359731870513,
                                48.67510178473148
                            ],
                            [
                                7.747627741041612,
                                48.67839855368311
                            ],
                            [
                                7.747072987029497,
                                48.68640409436239
                            ],
                            [
                                7.730985120672045,
                                48.68713656395391
                            ]
                        ],
                        [
                            [
                                7.6692492776838606,
                                48.65960936528461
                            ],
                            [
                                7.669327709949727,
                                48.658665888205775
                            ],
                            [
                                7.668499605378344,
                                48.653682991561254
                            ],
                            [
                                7.679534294866244,
                                48.64827692164988
                            ],
                            [
                                7.701119208428054,
                                48.64010715669181
                            ],
                            [
                                7.700747252602042,
                                48.63711670293549
                            ],
                            [
                                7.692440239167382,
                                48.63232341413348
                            ],
                            [
                                7.6929981729045664,
                                48.62888179773495
                            ],
                            [
                                7.68909263673828,
                                48.625398970761324
                            ],
                            [
                                7.689588577838009,
                                48.621915903418625
                            ],
                            [
                                7.685807026946662,
                                48.619907908315014
                            ],
                            [
                                7.679315027918818,
                                48.62154880817758
                            ],
                            [
                                7.676375137187591,
                                48.6199642014559
                            ],
                            [
                                7.668908407295248,
                                48.61254059323002
                            ],
                            [
                                7.655573892796951,
                                48.61048596195667
                            ],
                            [
                                7.6505082349234215,
                                48.612240318431816
                            ],
                            [
                                7.641455703019545,
                                48.61215060542361
                            ],
                            [
                                7.632500435925493,
                                48.608029084138025
                            ],
                            [
                                7.633286797062425,
                                48.60601167586853
                            ],
                            [
                                7.634266760639122,
                                48.603479488695314
                            ],
                            [
                                7.630388219521194,
                                48.59974345122234
                            ],
                            [
                                7.621990685486452,
                                48.59811827788519
                            ],
                            [
                                7.621508241880349,
                                48.59783910174269
                            ],
                            [
                                7.619767024670665,
                                48.59744635307072
                            ],
                            [
                                7.619329810150816,
                                48.59580116382216
                            ],
                            [
                                7.617505570262267,
                                48.594983534690584
                            ],
                            [
                                7.615636101241364,
                                48.59515304425875
                            ],
                            [
                                7.612775512054412,
                                48.59410343223561
                            ],
                            [
                                7.609096879550748,
                                48.59279716963235
                            ],
                            [
                                7.609760239510365,
                                48.591869802400566
                            ],
                            [
                                7.600451460227561,
                                48.59022976687976
                            ],
                            [
                                7.598159853095012,
                                48.58949182565951
                            ],
                            [
                                7.594662136943668,
                                48.588005938344764
                            ],
                            [
                                7.59126994961747,
                                48.58627068506655
                            ],
                            [
                                7.5877270043786496,
                                48.584026735645836
                            ],
                            [
                                7.584949940405551,
                                48.58184902154986
                            ],
                            [
                                7.583758907749967,
                                48.580751896080386
                            ],
                            [
                                7.586276660324671,
                                48.58039283166363
                            ],
                            [
                                7.590256820082175,
                                48.57838800846105
                            ],
                            [
                                7.579826424192333,
                                48.575743824627864
                            ],
                            [
                                7.578982147879316,
                                48.574277483610956
                            ],
                            [
                                7.577760411768281,
                                48.571806664995705
                            ],
                            [
                                7.576765371828316,
                                48.568903641003516
                            ],
                            [
                                7.576311775005053,
                                48.56582862077789
                            ],
                            [
                                7.5757841023102515,
                                48.5626458830053
                            ],
                            [
                                7.5754457450481425,
                                48.55988530379844
                            ],
                            [
                                7.575475897774737,
                                48.55715123246506
                            ],
                            [
                                7.576832770419259,
                                48.556732128177345
                            ],
                            [
                                7.579591744795806,
                                48.55912696305552
                            ],
                            [
                                7.5881423783947355,
                                48.55599503335927
                            ],
                            [
                                7.587230529345703,
                                48.55185928724816
                            ],
                            [
                                7.594391801636249,
                                48.55007289371699
                            ],
                            [
                                7.598031462833006,
                                48.55029841323028
                            ],
                            [
                                7.601303033543417,
                                48.554200436443466
                            ],
                            [
                                7.6056601023687165,
                                48.55038823300475
                            ],
                            [
                                7.606594836857909,
                                48.54752390166894
                            ],
                            [
                                7.604588173587899,
                                48.54726392590567
                            ],
                            [
                                7.6051610753713135,
                                48.54326155415137
                            ],
                            [
                                7.605774507578332,
                                48.542244165304055
                            ],
                            [
                                7.608648211213108,
                                48.53643337729781
                            ],
                            [
                                7.612628747444859,
                                48.529846100882025
                            ],
                            [
                                7.613598500328379,
                                48.52519666435313
                            ],
                            [
                                7.616839918312223,
                                48.52534643813593
                            ],
                            [
                                7.617895263702962,
                                48.52500695025918
                            ],
                            [
                                7.618287249132919,
                                48.52451768431649
                            ],
                            [
                                7.620759758063741,
                                48.52389862167803
                            ],
                            [
                                7.625252514153857,
                                48.52369891749396
                            ],
                            [
                                7.630559393830509,
                                48.52278026810504
                            ],
                            [
                                7.636330305825879,
                                48.52246339521014
                            ],
                            [
                                7.640385847396175,
                                48.52311244676176
                            ],
                            [
                                7.647710653272213,
                                48.52438518520131
                            ],
                            [
                                7.650590238551473,
                                48.52474464729471
                            ],
                            [
                                7.659143042078,
                                48.52527679408567
                            ],
                            [
                                7.662685987316877,
                                48.52659478608689
                            ],
                            [
                                7.666002787115957,
                                48.52684440192081
                            ],
                            [
                                7.667962714269294,
                                48.52594577916065
                            ],
                            [
                                7.668776837855745,
                                48.52486741080082
                            ],
                            [
                                7.666575688899371,
                                48.52443805404445
                            ],
                            [
                                7.66719381977029,
                                48.523000181658006
                            ],
                            [
                                7.66708828704941,
                                48.5217220381613
                            ],
                            [
                                7.668073147539218,
                                48.51800378580316
                            ],
                            [
                                7.668917423852292,
                                48.51630607758278
                            ],
                            [
                                7.669234027469372,
                                48.51557704423894
                            ],
                            [
                                7.669701394713343,
                                48.514408571656475
                            ],
                            [
                                7.671344718249628,
                                48.512491019789906
                            ],
                            [
                                7.674247807562438,
                                48.51093341245064
                            ],
                            [
                                7.6784993418483225,
                                48.509535108813964
                            ],
                            [
                                7.68124323986396,
                                48.5090956339875
                            ],
                            [
                                7.684379123308361,
                                48.50931537187711
                            ],
                            [
                                7.687062715873253,
                                48.509535108813964
                            ],
                            [
                                7.691274920736674,
                                48.50929155998648
                            ],
                            [
                                7.69373236785944,
                                48.50919167919889
                            ],
                            [
                                7.695903364091663,
                                48.50998073205628
                            ],
                            [
                                7.697712527616886,
                                48.50978097300103
                            ],
                            [
                                7.700686254041386,
                                48.50861610299074
                            ],
                            [
                                7.7050846274949265,
                                48.50739958289577
                            ],
                            [
                                7.709440343649533,
                                48.505758660778724
                            ],
                            [
                                7.712899258694819,
                                48.50462695131827
                            ],
                            [
                                7.719304656927733,
                                48.5036083911977
                            ],
                            [
                                7.729467888791277,
                                48.502137101552364
                            ],
                            [
                                7.732542479942367,
                                48.50188245093196
                            ],
                            [
                                7.735104639235914,
                                48.501146786398124
                            ],
                            [
                                7.741654234583507,
                                48.49897822266004
                            ],
                            [
                                7.757582324856116,
                                48.49674277313136
                            ],
                            [
                                7.759717457600715,
                                48.49566745849913
                            ],
                            [
                                7.762322319548929,
                                48.49586554448737
                            ],
                            [
                                7.763945020434193,
                                48.494677016949765
                            ],
                            [
                                7.764073128399389,
                                48.49337526434462
                            ],
                            [
                                7.771289911787136,
                                48.4924413834502
                            ],
                            [
                                7.784356924181765,
                                48.49730872473316
                            ],
                            [
                                7.796228262240106,
                                48.50248683641743
                            ],
                            [
                                7.802362416456333,
                                48.50906048837385
                            ],
                            [
                                7.806248358051391,
                                48.516754941165914
                            ],
                            [
                                7.8054370076083615,
                                48.521139125074
                            ],
                            [
                                7.806563461199829,
                                48.523797852236925
                            ],
                            [
                                7.805111570933917,
                                48.52934088926901
                            ],
                            [
                                7.803318059428591,
                                48.536919162070234
                            ],
                            [
                                7.804414402981934,
                                48.54229502539553
                            ],
                            [
                                7.8060798065227175,
                                48.55323511403154
                            ],
                            [
                                7.805375639426529,
                                48.56129907033147
                            ],
                            [
                                7.80315510137234,
                                48.57017237214333
                            ],
                            [
                                7.8007378825708145,
                                48.577585793327074
                            ],
                            [
                                7.800353558676704,
                                48.58204945981518
                            ],
                            [
                                7.801720043633054,
                                48.588235788789916
                            ],
                            [
                                7.808492368736609,
                                48.59646539026218
                            ],
                            [
                                7.815794522722143,
                                48.60386418646391
                            ],
                            [
                                7.827657785396497,
                                48.61652238072293
                            ],
                            [
                                7.8316840083361114,
                                48.62199851914826
                            ],
                            [
                                7.8360396791348705,
                                48.634078431218
                            ],
                            [
                                7.839695130131361,
                                48.64165928282884
                            ],
                            [
                                7.844648638098306,
                                48.64558122035021
                            ],
                            [
                                7.855775997117377,
                                48.64872995946274
                            ],
                            [
                                7.867220308626798,
                                48.652848819039576
                            ],
                            [
                                7.889639778528732,
                                48.66222590911889
                            ],
                            [
                                7.895532744903107,
                                48.66690771630232
                            ],
                            [
                                7.891390587379163,
                                48.670602088430684
                            ],
                            [
                                7.885753836934583,
                                48.67088409044334
                            ],
                            [
                                7.887675456404253,
                                48.67381681781487
                            ],
                            [
                                7.886992213925566,
                                48.67550869826414
                            ],
                            [
                                7.881440868790548,
                                48.67505753570009
                            ],
                            [
                                7.877896548434819,
                                48.673224646234644
                            ],
                            [
                                7.875291686487316,
                                48.67336564009963
                            ],
                            [
                                7.871234934273076,
                                48.672378674761205
                            ],
                            [
                                7.873109652119041,
                                48.6749271888217
                            ],
                            [
                                7.8645691211416136,
                                48.67523736432116
                            ],
                            [
                                7.859786423794702,
                                48.67261491128292
                            ],
                            [
                                7.859145883970882,
                                48.66711577625176
                            ],
                            [
                                7.855473455650753,
                                48.66615689126519
                            ],
                            [
                                7.844029156737463,
                                48.67013332119254
                            ],
                            [
                                7.839929701868357,
                                48.67413763911128
                            ],
                            [
                                7.842321050541813,
                                48.67540654669426
                            ],
                            [
                                7.834292951423691,
                                48.68087658239358
                            ],
                            [
                                7.831004846996734,
                                48.67969239823742
                            ],
                            [
                                7.8195877122629724,
                                48.68656560089471
                            ],
                            [
                                7.814847717570103,
                                48.68137812628996
                            ],
                            [
                                7.809168264470628,
                                48.68025034371638
                            ],
                            [
                                7.796605960176265,
                                48.66940397490512
                            ],
                            [
                                7.786570836277974,
                                48.67002439200141
                            ],
                            [
                                7.7817027336206195,
                                48.67010899374068
                            ],
                            [
                                7.770683282020997,
                                48.670159998552236
                            ],
                            [
                                7.7680784200726976,
                                48.66663480929847
                            ],
                            [
                                7.764918423611022,
                                48.667480877210096
                            ],
                            [
                                7.764235181132477,
                                48.66891916006011
                            ],
                            [
                                7.7553447811589535,
                                48.67280319945513
                            ],
                            [
                                7.752013974078068,
                                48.67288779652873
                            ],
                            [
                                7.7486831669972105,
                                48.67209821831645
                            ],
                            [
                                7.743686956375484,
                                48.67260580573091
                            ],
                            [
                                7.739936370383305,
                                48.67262811435228
                            ],
                            [
                                7.738014750913635,
                                48.671189937367416
                            ],
                            [
                                7.735068267726035,
                                48.670456733301904
                            ],
                            [
                                7.732292595158469,
                                48.67214872658499
                            ],
                            [
                                7.731225028786611,
                                48.674432827390405
                            ],
                            [
                                7.72968773321017,
                                48.67691420226464
                            ],
                            [
                                7.7263996287840655,
                                48.676970595727624
                            ],
                            [
                                7.722855308428365,
                                48.67646305228692
                            ],
                            [
                                7.716407207849869,
                                48.67697062103201
                            ],
                            [
                                7.712905590149802,
                                48.67677324363544
                            ],
                            [
                                7.708549919350958,
                                48.67640668356046
                            ],
                            [
                                7.7040661396743815,
                                48.675278789450914
                            ],
                            [
                                7.698429389229716,
                                48.67389708503188
                            ],
                            [
                                7.691634423080302,
                                48.671759684169984
                            ],
                            [
                                7.686809023077814,
                                48.669701068958574
                            ],
                            [
                                7.68248412340634,
                                48.66777490895234
                            ],
                            [
                                7.676381829271037,
                                48.66473915618613
                            ],
                            [
                                7.6692492776838606,
                                48.65960936528461
                            ]
                        ],
                        [
                            [
                                7.739906401941283,
                                48.438721321661404
                            ],
                            [
                                7.752983204193725,
                                48.43397723691217
                            ],
                            [
                                7.764320497986688,
                                48.45425249794306
                            ],
                            [
                                7.766867028322963,
                                48.47234918428384
                            ],
                            [
                                7.7674558913473675,
                                48.48847230168539
                            ],
                            [
                                7.764958075186598,
                                48.490776936350926
                            ],
                            [
                                7.7600114196533525,
                                48.493081466276436
                            ],
                            [
                                7.756778951681014,
                                48.49301655137367
                            ],
                            [
                                7.753840344432859,
                                48.49532097951061
                            ],
                            [
                                7.740838844340885,
                                48.49741111684068
                            ],
                            [
                                7.735108560207749,
                                48.4994557191051
                            ],
                            [
                                7.731974045810176,
                                48.49880664795518
                            ],
                            [
                                7.730112927886154,
                                48.49900137017303
                            ],
                            [
                                7.729476229649379,
                                48.500559120986594
                            ],
                            [
                                7.717495447306419,
                                48.50244887801989
                            ],
                            [
                                7.7122059542605825,
                                48.503260157363
                            ],
                            [
                                7.707406229089457,
                                48.504395926628746
                            ],
                            [
                                7.702900364642545,
                                48.50644024716965
                            ],
                            [
                                7.700108687757506,
                                48.5069918750921
                            ],
                            [
                                7.697414964447233,
                                48.50608330825534
                            ],
                            [
                                7.6951130554359395,
                                48.50647269603715
                            ],
                            [
                                7.692174448188808,
                                48.507867977687624
                            ],
                            [
                                7.686346210479996,
                                48.508127560732646
                            ],
                            [
                                7.681318960767612,
                                48.50803019550412
                            ],
                            [
                                7.677155933833262,
                                48.508484463507415
                            ],
                            [
                                7.672209278300045,
                                48.51004192288798
                            ],
                            [
                                7.668389088877319,
                                48.51322158715999
                            ],
                            [
                                7.665771972290628,
                                48.51456832935588
                            ],
                            [
                                7.665967879441126,
                                48.5178774759695
                            ],
                            [
                                7.664841413329611,
                                48.5215107997511
                            ],
                            [
                                7.663616993642421,
                                48.52215958014645
                            ],
                            [
                                7.66288234183088,
                                48.523911245705534
                            ],
                            [
                                7.651029959264662,
                                48.52361930565246
                            ],
                            [
                                7.6372112815375885,
                                48.52135256450103
                            ],
                            [
                                7.626534341871178,
                                48.521482321650666
                            ],
                            [
                                7.622518245298977,
                                48.52193646905499
                            ],
                            [
                                7.615563541479645,
                                48.52310425796975
                            ],
                            [
                                7.613174372139753,
                                48.5221216458566
                            ],
                            [
                                7.611247887192945,
                                48.523467514534815
                            ],
                            [
                                7.610614522279235,
                                48.52156231328931
                            ],
                            [
                                7.5927994615116745,
                                48.51197066236048
                            ],
                            [
                                7.590978537383677,
                                48.51373636903199
                            ],
                            [
                                7.586096349507017,
                                48.51275737095321
                            ],
                            [
                                7.594614330419034,
                                48.49556224582082
                            ],
                            [
                                7.593585112434937,
                                48.49524745707137
                            ],
                            [
                                7.591682774159807,
                                48.48564402045554
                            ],
                            [
                                7.608297894154504,
                                48.47503966177155
                            ],
                            [
                                7.613180082032244,
                                48.47705157994673
                            ],
                            [
                                7.624798574268652,
                                48.47924330494621
                            ],
                            [
                                7.631897539482253,
                                48.47896340287704
                            ],
                            [
                                7.634853242413186,
                                48.48330171310491
                            ],
                            [
                                7.637967286595682,
                                48.482829415082335
                            ],
                            [
                                7.640368795227005,
                                48.47710901194213
                            ],
                            [
                                7.644037033685663,
                                48.47787876735626
                            ],
                            [
                                7.653462891963898,
                                48.4774341717241
                            ],
                            [
                                7.65610191243843,
                                48.47876373339983
                            ],
                            [
                                7.656497765509926,
                                48.48096792993044
                            ],
                            [
                                7.660086833354541,
                                48.48429153724527
                            ],
                            [
                                7.664309266113833,
                                48.485166134552486
                            ],
                            [
                                7.667393147804461,
                                48.48750746735652
                            ],
                            [
                                7.670243289916584,
                                48.487804813277904
                            ],
                            [
                                7.673753187147071,
                                48.487454994366004
                            ],
                            [
                                7.67591718393615,
                                48.48750746735652
                            ],
                            [
                                7.677975619906448,
                                48.48740252132123
                            ],
                            [
                                7.679321520347884,
                                48.485933254020495
                            ],
                            [
                                7.681142444474887,
                                48.48568837199872
                            ],
                            [
                                7.682805027373718,
                                48.48782230416015
                            ],
                            [
                                7.6840189767917195,
                                48.48651047125077
                            ],
                            [
                                7.68341200208269,
                                48.48404413351571
                            ],
                            [
                                7.690352671715601,
                                48.48285465296155
                            ],
                            [
                                7.696132126553948,
                                48.4814727193272
                            ],
                            [
                                7.698164172319537,
                                48.48196252289745
                            ],
                            [
                                7.697914182186253,
                                48.479286442670315
                            ],
                            [
                                7.695802965806564,
                                48.47421298029727
                            ],
                            [
                                7.695171688072975,
                                48.46865944607757
                            ],
                            [
                                7.695024677387039,
                                48.46215465984267
                            ],
                            [
                                7.69668726028587,
                                48.457236997435956
                            ],
                            [
                                7.693546827610987,
                                48.45244996261121
                            ],
                            [
                                7.700936084939855,
                                48.44964939324407
                            ],
                            [
                                7.70397095896837,
                                48.45414773271875
                            ],
                            [
                                7.7075600268139794,
                                48.451102208017346
                            ],
                            [
                                7.717092094736557,
                                48.449823280451085
                            ],
                            [
                                7.73240929985343,
                                48.444500703776015
                            ],
                            [
                                7.741830602946806,
                                48.44889447350303
                            ],
                            [
                                7.745419670791421,
                                48.44766915761909
                            ],
                            [
                                7.73942909431446,
                                48.44117448984531
                            ],
                            [
                                7.739906401941283,
                                48.438721321661404
                            ]
                        ],
                        [
                            [
                                7.581937437224724,
                                48.58105198317077
                            ],
                            [
                                7.585106047537693,
                                48.58365278864355
                            ],
                            [
                                7.574133267378983,
                                48.586059384937954
                            ],
                            [
                                7.5674439789411,
                                48.59180369991802
                            ],
                            [
                                7.5525397748757825,
                                48.59110510189387
                            ],
                            [
                                7.544970316905818,
                                48.588737558983354
                            ],
                            [
                                7.544266181280847,
                                48.58450675528448
                            ],
                            [
                                7.557644758157721,
                                48.5831093478142
                            ],
                            [
                                7.571375402847735,
                                48.582100085056936
                            ],
                            [
                                7.581937437224724,
                                48.58105198317077
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Grenoble-Alpes",
                "zone": "ZFE-m",
                "area_code": 3,
                "time": "!Crit 4/5 BAN from 1.1.2024!",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel cars)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol cars)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco (HGV only 2 & 1)",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://zfe.grenoblealpesmetropole.fr/",
                "urlPark": "https://www.grenoblealpesmetropole.fr/129-se-garer.htm",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/bon-a-savoir-2",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                5.780266166011643,
                                45.18341457507623
                            ],
                            [
                                5.779779986393606,
                                45.18251114240809
                            ],
                            [
                                5.7804208595262025,
                                45.18198153728255
                            ],
                            [
                                5.781514763666991,
                                45.18171673287202
                            ],
                            [
                                5.782851757617237,
                                45.181771251528005
                            ],
                            [
                                5.784166652493042,
                                45.182004902318084
                            ],
                            [
                                5.785371052001324,
                                45.18181798176275
                            ],
                            [
                                5.786310262626728,
                                45.1815298113699
                            ],
                            [
                                5.787138977885434,
                                45.18101577407586
                            ],
                            [
                                5.788299179246735,
                                45.18046278938061
                            ],
                            [
                                5.789426250915511,
                                45.1793957365976
                            ],
                            [
                                5.789879281922879,
                                45.17877263713808
                            ],
                            [
                                5.790243916637053,
                                45.177775663819915
                            ],
                            [
                                5.79018866586037,
                                45.176950030999905
                            ],
                            [
                                5.789879278831307,
                                45.176233435255256
                            ],
                            [
                                5.7902439135446,
                                45.17536883490669
                            ],
                            [
                                5.7904428052061405,
                                45.17487032063184
                            ],
                            [
                                5.790116759628319,
                                45.173266891477795
                            ],
                            [
                                5.790415097122008,
                                45.171599905743875
                            ],
                            [
                                5.790846029055814,
                                45.170485958174424
                            ],
                            [
                                5.791497951725631,
                                45.169878341226195
                            ],
                            [
                                5.7926902936502245,
                                45.16939435767006
                            ],
                            [
                                5.793927841768351,
                                45.16888021082096
                            ],
                            [
                                5.794734457953524,
                                45.16873998814711
                            ],
                            [
                                5.7956847184010485,
                                45.16893474179966
                            ],
                            [
                                5.796601829953715,
                                45.16902043318163
                            ],
                            [
                                5.797441594747909,
                                45.16877114880364
                            ],
                            [
                                5.798181913711716,
                                45.16823362565145
                            ],
                            [
                                5.798292409080091,
                                45.1671429833786
                            ],
                            [
                                5.798590717773436,
                                45.16669112740439
                            ],
                            [
                                5.799618324693711,
                                45.16590428769888
                            ],
                            [
                                5.800469139025068,
                                45.16565498968325
                            ],
                            [
                                5.801496746373715,
                                45.1656783614238
                            ],
                            [
                                5.80232546163154,
                                45.165740686004284
                            ],
                            [
                                5.803342017830431,
                                45.165561490154346
                            ],
                            [
                                5.804446971507502,
                                45.165086262010135
                            ],
                            [
                                5.805143092391916,
                                45.16485254180452
                            ],
                            [
                                5.806634779856751,
                                45.1649070766064
                            ],
                            [
                                5.8078612784385655,
                                45.1647590534501
                            ],
                            [
                                5.809496609881535,
                                45.16432277243638
                            ],
                            [
                                5.810259037838762,
                                45.164065669857536
                            ],
                            [
                                5.810480028573863,
                                45.16418253169854
                            ],
                            [
                                5.811474486884322,
                                45.16383194545702
                            ],
                            [
                                5.812137459090366,
                                45.163574847508016
                            ],
                            [
                                5.8123474002891555,
                                45.16390985369708
                            ],
                            [
                                5.8133971063461445,
                                45.164283811783406
                            ],
                            [
                                5.8151650322301975,
                                45.16443183617463
                            ],
                            [
                                5.819070759384914,
                                45.16575473406192
                            ],
                            [
                                5.821689499600268,
                                45.16644809025064
                            ],
                            [
                                5.823402177800972,
                                45.16745305221801
                            ],
                            [
                                5.825107054245393,
                                45.16887975217389
                            ],
                            [
                                5.827836289829122,
                                45.17004826103758
                            ],
                            [
                                5.828206702761406,
                                45.171291607185594
                            ],
                            [
                                5.829786786520145,
                                45.171899209056846
                            ],
                            [
                                5.83077019529253,
                                45.17246785621785
                            ],
                            [
                                5.8292343096815955,
                                45.17334807966773
                            ],
                            [
                                5.827963612951919,
                                45.173659659929626
                            ],
                            [
                                5.826593470392709,
                                45.174586601128965
                            ],
                            [
                                5.82564321023014,
                                45.175046171423276
                            ],
                            [
                                5.825998014280003,
                                45.17613202705897
                            ],
                            [
                                5.825710726324388,
                                45.17828957992066
                            ],
                            [
                                5.824550524963115,
                                45.17843756791521
                            ],
                            [
                                5.823611314336858,
                                45.17867123238673
                            ],
                            [
                                5.823807704363901,
                                45.179281936304676
                            ],
                            [
                                5.823420970577047,
                                45.18018542021494
                            ],
                            [
                                5.821023221097619,
                                45.17941434432609
                            ],
                            [
                                5.818968007257098,
                                45.17862768038566
                            ],
                            [
                                5.817376873962019,
                                45.17936761211851
                            ],
                            [
                                5.816503945556349,
                                45.180076383642245
                            ],
                            [
                                5.816084063158797,
                                45.180761776978414
                            ],
                            [
                                5.8146807719880655,
                                45.18175090963334
                            ],
                            [
                                5.814000435308998,
                                45.183543322423645
                            ],
                            [
                                5.812762887189962,
                                45.18515544317913
                            ],
                            [
                                5.812335753619124,
                                45.18728783221604
                            ],
                            [
                                5.812158961030207,
                                45.18757597346314
                            ],
                            [
                                5.811219750404888,
                                45.18787190079232
                            ],
                            [
                                5.810612025882136,
                                45.18900887043284
                            ],
                            [
                                5.808952674254925,
                                45.19164741233607
                            ],
                            [
                                5.805148590213065,
                                45.197537065275895
                            ],
                            [
                                5.8049696968393505,
                                45.1992903366029
                            ],
                            [
                                5.805533223214212,
                                45.20130686935809
                            ],
                            [
                                5.807975168762823,
                                45.204091819927754
                            ],
                            [
                                5.805673080673529,
                                45.20571350955163
                            ],
                            [
                                5.80562888252652,
                                45.20638302418868
                            ],
                            [
                                5.812059714226081,
                                45.20646866001141
                            ],
                            [
                                5.8145348104640675,
                                45.205355394634665
                            ],
                            [
                                5.818158600689856,
                                45.20299927842257
                            ],
                            [
                                5.8205232015594675,
                                45.20309270399983
                            ],
                            [
                                5.819581592061809,
                                45.20497253281752
                            ],
                            [
                                5.818112003670564,
                                45.205844468662235
                            ],
                            [
                                5.815413276783232,
                                45.20752788044837
                            ],
                            [
                                5.813700598583381,
                                45.20868002679012
                            ],
                            [
                                5.809784404069319,
                                45.211889855588964
                            ],
                            [
                                5.807883883744182,
                                45.21080783393185
                            ],
                            [
                                5.805099400477076,
                                45.20957010046334
                            ],
                            [
                                5.801231131876193,
                                45.208055898684506
                            ],
                            [
                                5.798170410189812,
                                45.20712171751427
                            ],
                            [
                                5.79434726253902,
                                45.20610188311895
                            ],
                            [
                                5.791651175565903,
                                45.20555692724773
                            ],
                            [
                                5.788173968124767,
                                45.204880416363636
                            ],
                            [
                                5.785102196901221,
                                45.20431209292161
                            ],
                            [
                                5.783223775649645,
                                45.203510202243336
                            ],
                            [
                                5.78184257865712,
                                45.2012757422724
                            ],
                            [
                                5.7810801606197515,
                                45.19933706819043
                            ],
                            [
                                5.781041776146168,
                                45.198737980877354
                            ],
                            [
                                5.782050737541709,
                                45.19620619736284
                            ],
                            [
                                5.7825429138322875,
                                45.19431595677477
                            ],
                            [
                                5.782555216712382,
                                45.192633761898264
                            ],
                            [
                                5.782407568225011,
                                45.19064802259277
                            ],
                            [
                                5.7823829594105405,
                                45.18864486362486
                            ],
                            [
                                5.782149175672515,
                                45.18750884171479
                            ],
                            [
                                5.781718531429533,
                                45.185757080103286
                            ],
                            [
                                5.781054098279071,
                                45.184690384136076
                            ],
                            [
                                5.780266166011643,
                                45.18341457507623
                            ]
                        ],
                        [
                            [
                                5.755175374963329,
                                45.085516481714706
                            ],
                            [
                                5.755088767119986,
                                45.08587476366483
                            ],
                            [
                                5.754721638226528,
                                45.08622335490378
                            ],
                            [
                                5.758051114107985,
                                45.08652725325348
                            ],
                            [
                                5.757861220046152,
                                45.087510442721964
                            ],
                            [
                                5.762136270316802,
                                45.089098635729016
                            ],
                            [
                                5.7645289354937574,
                                45.09017115693587
                            ],
                            [
                                5.765250532928235,
                                45.091216845721675
                            ],
                            [
                                5.765972130362741,
                                45.090805722499994
                            ],
                            [
                                5.766984898691675,
                                45.09044822164074
                            ],
                            [
                                5.769364814635054,
                                45.09121455480155
                            ],
                            [
                                5.771061201585724,
                                45.092850080987176
                            ],
                            [
                                5.771960033477399,
                                45.093502490933616
                            ],
                            [
                                5.774352698654383,
                                45.093565050399405
                            ],
                            [
                                5.776796002247693,
                                45.09464642462834
                            ],
                            [
                                5.777293880501418,
                                45.0972140865386
                            ],
                            [
                                5.772192060044802,
                                45.09691918158521
                            ],
                            [
                                5.767650884839156,
                                45.100193780214056
                            ],
                            [
                                5.77404181345895,
                                45.1066868578732
                            ],
                            [
                                5.776447138240542,
                                45.10926901678687
                            ],
                            [
                                5.772858423528334,
                                45.1105998739217
                            ],
                            [
                                5.773871191857296,
                                45.11221697659988
                            ],
                            [
                                5.772263422134756,
                                45.11263687974605
                            ],
                            [
                                5.772668529466557,
                                45.11451300500113
                            ],
                            [
                                5.773807893836391,
                                45.11447726985827
                            ],
                            [
                                5.773947149481842,
                                45.11463807782562
                            ],
                            [
                                5.771848871254235,
                                45.116665755515385
                            ],
                            [
                                5.772215999773266,
                                45.118121888621516
                            ],
                            [
                                5.769380350452195,
                                45.120337422367896
                            ],
                            [
                                5.766721833588861,
                                45.12332092330081
                            ],
                            [
                                5.765443213573548,
                                45.123687150688255
                            ],
                            [
                                5.761759264159281,
                                45.12447319683321
                            ],
                            [
                                5.759695748689552,
                                45.12431241658305
                            ],
                            [
                                5.7601261752285495,
                                45.12520563446225
                            ],
                            [
                                5.7611895819738095,
                                45.126795527684834
                            ],
                            [
                                5.762227611151189,
                                45.12778699860215
                            ],
                            [
                                5.763215060272017,
                                45.12849259890686
                            ],
                            [
                                5.762911229773891,
                                45.12900169750361
                            ],
                            [
                                5.764721553160854,
                                45.13015385123359
                            ],
                            [
                                5.7655191082198485,
                                45.13127025706362
                            ],
                            [
                                5.7597047779476895,
                                45.13350998563982
                            ],
                            [
                                5.757007871396411,
                                45.13843120301365
                            ],
                            [
                                5.75966821910032,
                                45.13903996257389
                            ],
                            [
                                5.759817997389831,
                                45.13966883959182
                            ],
                            [
                                5.760588476477153,
                                45.140024949776034
                            ],
                            [
                                5.761023546745037,
                                45.14091542220754
                            ],
                            [
                                5.764929662401329,
                                45.14619681411713
                            ],
                            [
                                5.765714215343678,
                                45.14729846377887
                            ],
                            [
                                5.76622774090589,
                                45.146725004967664
                            ],
                            [
                                5.766998029248697,
                                45.14649360767348
                            ],
                            [
                                5.7676613331004205,
                                45.14629742227541
                            ],
                            [
                                5.768253318826652,
                                45.14594026573394
                            ],
                            [
                                5.769045004068147,
                                45.145713895709264
                            ],
                            [
                                5.7700649228922885,
                                45.144959322468736
                            ],
                            [
                                5.770735359043243,
                                45.14436068725942
                            ],
                            [
                                5.772416019791535,
                                45.144566214017175
                            ],
                            [
                                5.773379927447195,
                                45.14520528233649
                            ],
                            [
                                5.774331861340613,
                                45.146049108973756
                            ],
                            [
                                5.7758186290769515,
                                45.14647808840118
                            ],
                            [
                                5.776088950484365,
                                45.14864676831243
                            ],
                            [
                                5.77673096382523,
                                45.14931403783959
                            ],
                            [
                                5.777575718220703,
                                45.14979065414778
                            ],
                            [
                                5.786226009750521,
                                45.159083887725444
                            ],
                            [
                                5.788118259597297,
                                45.15925067529085
                            ],
                            [
                                5.7915986477077865,
                                45.16068026296611
                            ],
                            [
                                5.792612352982047,
                                45.16051347958546
                            ],
                            [
                                5.794031540366831,
                                45.16163330148885
                            ],
                            [
                                5.79666717408179,
                                45.16118061017826
                            ],
                            [
                                5.797680879356051,
                                45.161847732959075
                            ],
                            [
                                5.80122884781801,
                                45.16099000223451
                            ],
                            [
                                5.8024859968382145,
                                45.16381228072024
                            ],
                            [
                                5.80392659051185,
                                45.16481292162919
                            ],
                            [
                                5.802521659323702,
                                45.165330833921956
                            ],
                            [
                                5.800781541507291,
                                45.16520512748755
                            ],
                            [
                                5.7995763005035315,
                                45.165446483405134
                            ],
                            [
                                5.798363923337035,
                                45.16604986920419
                            ],
                            [
                                5.797814787796881,
                                45.1668191768286
                            ],
                            [
                                5.797672156322022,
                                45.16788513079371
                            ],
                            [
                                5.79725139012811,
                                45.16839798717419
                            ],
                            [
                                5.7963528047006605,
                                45.168649385724734
                            ],
                            [
                                5.795161809156895,
                                45.16841306076995
                            ],
                            [
                                5.79405640644643,
                                45.16846334054526
                            ],
                            [
                                5.791496151134822,
                                45.169408592062496
                            ],
                            [
                                5.790412143314597,
                                45.17010746474671
                            ],
                            [
                                5.790126875936011,
                                45.170791246617995
                            ],
                            [
                                5.7897845576765405,
                                45.17159065641059
                            ],
                            [
                                5.789563477134436,
                                45.17318944233466
                            ],
                            [
                                5.789679527199098,
                                45.17380279944143
                            ],
                            [
                                5.789907739371301,
                                45.17482840101724
                            ],
                            [
                                5.789429920135433,
                                45.17562775414291
                            ],
                            [
                                5.7893657381350465,
                                45.176155624635186
                            ],
                            [
                                5.789615345198712,
                                45.176789060441195
                            ],
                            [
                                5.789700924764389,
                                45.17766379354157
                            ],
                            [
                                5.789443333589162,
                                45.17847162337972
                            ],
                            [
                                5.78923877576193,
                                45.17886815267019
                            ],
                            [
                                5.788712769919329,
                                45.1794088699792
                            ],
                            [
                                5.788186764076755,
                                45.1798774874932
                            ],
                            [
                                5.787485422953694,
                                45.18034095157077
                            ],
                            [
                                5.786521089465111,
                                45.18086105639608
                            ],
                            [
                                5.7859074159823365,
                                45.18113398098379
                            ],
                            [
                                5.785103795945076,
                                45.18115972851862
                            ],
                            [
                                5.783927588295342,
                                45.181164878041244
                            ],
                            [
                                5.782576046638553,
                                45.18124211617658
                            ],
                            [
                                5.781640925140351,
                                45.18131420910879
                            ],
                            [
                                5.780472023268203,
                                45.181484142087044
                            ],
                            [
                                5.780121352707255,
                                45.18143779677948
                            ],
                            [
                                5.77946384540374,
                                45.180974341630275
                            ],
                            [
                                5.778353388626272,
                                45.18076836035297
                            ],
                            [
                                5.777359821136116,
                                45.18039243990634
                            ],
                            [
                                5.77651236711921,
                                45.17978478674925
                            ],
                            [
                                5.774733457722334,
                                45.17873361351829
                            ],
                            [
                                5.772644045625981,
                                45.177683059870276
                            ],
                            [
                                5.771464924688132,
                                45.177019469319504
                            ],
                            [
                                5.7698722958875805,
                                45.17620063246315
                            ],
                            [
                                5.767899272281824,
                                45.1750926769777
                            ],
                            [
                                5.76648197876176,
                                45.174186260161434
                            ],
                            [
                                5.765125352039917,
                                45.173218877930395
                            ],
                            [
                                5.763992978350927,
                                45.1721991244454
                            ],
                            [
                                5.763079780226462,
                                45.17138049298751
                            ],
                            [
                                5.762130047455514,
                                45.1704688666147
                            ],
                            [
                                5.761012258492485,
                                45.16923273753068
                            ],
                            [
                                5.760289000459039,
                                45.16835198077416
                            ],
                            [
                                5.759195036670377,
                                45.16683515676965
                            ],
                            [
                                5.758574057551158,
                                45.16599041909484
                            ],
                            [
                                5.757799668036938,
                                45.16538776439552
                            ],
                            [
                                5.756733045077823,
                                45.1644554380442
                            ],
                            [
                                5.755031249253449,
                                45.1630711502261
                            ],
                            [
                                5.753847736108469,
                                45.16184516532681
                            ],
                            [
                                5.7519022882022455,
                                45.16005340756843
                            ],
                            [
                                5.7503099495577885,
                                45.15867300991985
                            ],
                            [
                                5.749206798415571,
                                45.15749329521094
                            ],
                            [
                                5.747920665702225,
                                45.15632461706721
                            ],
                            [
                                5.746985544205245,
                                45.155423053405286
                            ],
                            [
                                5.745911616319972,
                                45.15499544841552
                            ],
                            [
                                5.745166441377421,
                                45.15451632232731
                            ],
                            [
                                5.743858737954525,
                                45.15377443758797
                            ],
                            [
                                5.741776631495725,
                                45.15285222532165
                            ],
                            [
                                5.740777318986289,
                                45.15238880821127
                            ],
                            [
                                5.7404777878824405,
                                45.15207968092824
                            ],
                            [
                                5.739791058032154,
                                45.15174994331153
                            ],
                            [
                                5.739250440916834,
                                45.15183753005178
                            ],
                            [
                                5.738439515243186,
                                45.151641747740314
                            ],
                            [
                                5.736335489048059,
                                45.15110076331126
                            ],
                            [
                                5.734881667344894,
                                45.15075041101224
                            ],
                            [
                                5.734457940378775,
                                45.15044642711584
                            ],
                            [
                                5.733866183806299,
                                45.15038459968599
                            ],
                            [
                                5.733347483601278,
                                45.1505494726826
                            ],
                            [
                                5.731389565166353,
                                45.150415513310634
                            ],
                            [
                                5.728489227396352,
                                45.150487645266054
                            ],
                            [
                                5.725479293324327,
                                45.15071434678754
                            ],
                            [
                                5.724142361808106,
                                45.15087406658637
                            ],
                            [
                                5.720949799930878,
                                45.15139959728543
                            ],
                            [
                                5.719649396598953,
                                45.15163659784719
                            ],
                            [
                                5.718626607460578,
                                45.151662358718596
                            ],
                            [
                                5.718115212891945,
                                45.15162114131908
                            ],
                            [
                                5.717355426675368,
                                45.15194057538608
                            ],
                            [
                                5.7162376647385145,
                                45.15284735110748
                            ],
                            [
                                5.715244098146854,
                                45.15382108325562
                            ],
                            [
                                5.713885252057679,
                                45.15516058435412
                            ],
                            [
                                5.712643293819298,
                                45.15655156872333
                            ],
                            [
                                5.7117373976314525,
                                45.15737584421555
                            ],
                            [
                                5.71067808030989,
                                45.15794252361934
                            ],
                            [
                                5.7096187629883275,
                                45.158287680131
                            ],
                            [
                                5.708077271102184,
                                45.158498896878996
                            ],
                            [
                                5.706338529567915,
                                45.15854526085508
                            ],
                            [
                                5.704723983857065,
                                45.15852465464806
                            ],
                            [
                                5.70337244408222,
                                45.15835979960028
                            ],
                            [
                                5.702181625299772,
                                45.15770039513154
                            ],
                            [
                                5.700837388147164,
                                45.157231595125864
                            ],
                            [
                                5.700165274533504,
                                45.15654641419286
                            ],
                            [
                                5.699646574328483,
                                45.15568606852909
                            ],
                            [
                                5.698703322513694,
                                45.15370481079066
                            ],
                            [
                                5.697921498134264,
                                45.15196856231614
                            ],
                            [
                                5.697395492291719,
                                45.15084538355066
                            ],
                            [
                                5.696621096947268,
                                45.14911908509691
                            ],
                            [
                                5.695522629683239,
                                45.1471467549774
                            ],
                            [
                                5.694621971237808,
                                45.14531978466562
                            ],
                            [
                                5.6934570050947855,
                                45.142707668819156
                            ],
                            [
                                5.692982138709311,
                                45.14155338904902
                            ],
                            [
                                5.6921227459097565,
                                45.13957047812451
                            ],
                            [
                                5.691589444235689,
                                45.13843674488405
                            ],
                            [
                                5.691479859685103,
                                45.137720422157486
                            ],
                            [
                                5.691450637138388,
                                45.136529966643565
                            ],
                            [
                                5.691801312529407,
                                45.13595276608072
                            ],
                            [
                                5.692414986012267,
                                45.13564870328145
                            ],
                            [
                                5.6922980958241,
                                45.13511787789781
                            ],
                            [
                                5.69183053507615,
                                45.13470558292843
                            ],
                            [
                                5.69101230376495,
                                45.13385521513976
                            ],
                            [
                                5.69054863106993,
                                45.13274056199131
                            ],
                            [
                                5.690876372111461,
                                45.130744003675886
                            ],
                            [
                                5.691336627222995,
                                45.1292441514029
                            ],
                            [
                                5.691472511319631,
                                45.12802491771731
                            ],
                            [
                                5.691604012780573,
                                45.126756943496076
                            ],
                            [
                                5.69195531600451,
                                45.125822647708475
                            ],
                            [
                                5.6930073276896564,
                                45.124786582098835
                            ],
                            [
                                5.694315084440603,
                                45.12370922721266
                            ],
                            [
                                5.695257511575136,
                                45.122848385913755
                            ],
                            [
                                5.696346051443442,
                                45.12199268647993
                            ],
                            [
                                5.6968425273746846,
                                45.121446342007204
                            ],
                            [
                                5.697149364116143,
                                45.12109065253023
                            ],
                            [
                                5.697558479770578,
                                45.12037926692395
                            ],
                            [
                                5.697865316511951,
                                45.11931732697974
                            ],
                            [
                                5.698144570399336,
                                45.118592142709275
                            ],
                            [
                                5.698568297328876,
                                45.117298182434894
                            ],
                            [
                                5.69891742337316,
                                45.11612052012708
                            ],
                            [
                                5.699173120657463,
                                45.11520800967449
                            ],
                            [
                                5.699208302006696,
                                45.113995907688434
                            ],
                            [
                                5.699281558321729,
                                45.111994392088775
                            ],
                            [
                                5.699398448508703,
                                45.110581696961475
                            ],
                            [
                                5.699512374948313,
                                45.10980636769318
                            ],
                            [
                                5.699870351146785,
                                45.10909484140214
                            ],
                            [
                                5.7004182738997144,
                                45.108388462324825
                            ],
                            [
                                5.701126200726833,
                                45.107806839361444
                            ],
                            [
                                5.702857636624714,
                                45.106569353283476
                            ],
                            [
                                5.704433435275433,
                                45.10549500182239
                            ],
                            [
                                5.706230621903188,
                                45.10431418662151
                            ],
                            [
                                5.707341116076975,
                                45.10373148032065
                            ],
                            [
                                5.708042457200008,
                                45.10349428063381
                            ],
                            [
                                5.709547418360302,
                                45.10329317577924
                            ],
                            [
                                5.710855127329751,
                                45.10324676686639
                            ],
                            [
                                5.711753720643628,
                                45.103071443965064
                            ],
                            [
                                5.712645013458882,
                                45.10294253106764
                            ],
                            [
                                5.713463244768917,
                                45.103086914998244
                            ],
                            [
                                5.71404039006859,
                                45.10301987964752
                            ],
                            [
                                5.714354532446379,
                                45.10263313570019
                            ],
                            [
                                5.71492437210847,
                                45.102421714568464
                            ],
                            [
                                5.715326182127711,
                                45.10222576257638
                            ],
                            [
                                5.715633018869113,
                                45.101787446474816
                            ],
                            [
                                5.715888716153415,
                                45.101282089617
                            ],
                            [
                                5.7168402556728495,
                                45.10045724732359
                            ],
                            [
                                5.717300510784327,
                                45.099936408152956
                            ],
                            [
                                5.717979934998226,
                                45.09908552205829
                            ],
                            [
                                5.7187624127794265,
                                45.09812251549258
                            ],
                            [
                                5.719361474989483,
                                45.097426314787725
                            ],
                            [
                                5.7202527626669735,
                                45.09627111859402
                            ],
                            [
                                5.720918593193005,
                                45.095514926809585
                            ],
                            [
                                5.721612628679708,
                                45.09450925492163
                            ],
                            [
                                5.723008625227607,
                                45.09292386951313
                            ],
                            [
                                5.724082552726031,
                                45.09170153391011
                            ],
                            [
                                5.724754671302293,
                                45.09119608778468
                            ],
                            [
                                5.725672240753028,
                                45.09072439207287
                            ],
                            [
                                5.726702335527847,
                                45.0903014608335
                            ],
                            [
                                5.728360715059466,
                                45.08955874473702
                            ],
                            [
                                5.730536300316004,
                                45.08868974543242
                            ],
                            [
                                5.733885774468433,
                                45.08739309725388
                            ],
                            [
                                5.736718806627465,
                                45.08623080800305
                            ],
                            [
                                5.737752491764184,
                                45.08566316650271
                            ],
                            [
                                5.738709597223135,
                                45.08495135821261
                            ],
                            [
                                5.739819839556219,
                                45.08339255718019
                            ],
                            [
                                5.741134264386972,
                                45.08258160340776
                            ],
                            [
                                5.742372120781084,
                                45.082284250807135
                            ],
                            [
                                5.743431317488785,
                                45.0823473257301
                            ],
                            [
                                5.74461812825848,
                                45.08277082698436
                            ],
                            [
                                5.745871958033916,
                                45.08362197833836
                            ],
                            [
                                5.750335592032258,
                                45.08452497376567
                            ],
                            [
                                5.755175374963329,
                                45.085516481714706
                            ]
                        ],
                        [
                            [
                                5.701247546575928,
                                45.17810016897758
                            ],
                            [
                                5.701303387600632,
                                45.17955656797437
                            ],
                            [
                                5.694762322395462,
                                45.17992211781737
                            ],
                            [
                                5.6946827892165,
                                45.179207328294666
                            ],
                            [
                                5.69402664048846,
                                45.17905315682938
                            ],
                            [
                                5.692754109622712,
                                45.17831032482559
                            ],
                            [
                                5.691541228641086,
                                45.17787583369042
                            ],
                            [
                                5.691262862514748,
                                45.177385275197764
                            ],
                            [
                                5.690745896850672,
                                45.17727314694915
                            ],
                            [
                                5.6887774506674305,
                                45.17653029172362
                            ],
                            [
                                5.687663986159549,
                                45.175927590740855
                            ],
                            [
                                5.686987954137578,
                                45.17564726252883
                            ],
                            [
                                5.685476823734092,
                                45.174455852232995
                            ],
                            [
                                5.685218342192599,
                                45.1738531216767
                            ],
                            [
                                5.685178575603146,
                                45.173026119356734
                            ],
                            [
                                5.6846019600548345,
                                45.172269191453154
                            ],
                            [
                                5.684104877685485,
                                45.17187670635772
                            ],
                            [
                                5.683051063061868,
                                45.17149823602543
                            ],
                            [
                                5.682076781618008,
                                45.170993605003105
                            ],
                            [
                                5.681261566532044,
                                45.1710496753374
                            ],
                            [
                                5.680824134046958,
                                45.16994227601285
                            ],
                            [
                                5.680844017341684,
                                45.169045126595876
                            ],
                            [
                                5.680287285088127,
                                45.16875074636428
                            ],
                            [
                                5.679313003643472,
                                45.168400291724026
                            ],
                            [
                                5.678398372083819,
                                45.16769937597482
                            ],
                            [
                                5.678219422431141,
                                45.16692835868844
                            ],
                            [
                                5.677622923587364,
                                45.16670406079146
                            ],
                            [
                                5.6770462933268675,
                                45.16701246228044
                            ],
                            [
                                5.677225242979517,
                                45.167671331273425
                            ],
                            [
                                5.677622908875151,
                                45.1684143020596
                            ],
                            [
                                5.677344542748756,
                                45.16900306447894
                            ],
                            [
                                5.677523492401463,
                                45.169942268121616
                            ],
                            [
                                5.67792267216285,
                                45.171389292033865
                            ],
                            [
                                5.678499287711077,
                                45.172903161169046
                            ],
                            [
                                5.6790957865541145,
                                45.17395443550444
                            ],
                            [
                                5.679592868924232,
                                45.174613224196605
                            ],
                            [
                                5.680626800252355,
                                45.175075773405524
                            ],
                            [
                                5.681044349442715,
                                45.17531405486454
                            ],
                            [
                                5.681263065685641,
                                45.17605692595632
                            ],
                            [
                                5.681143753687849,
                                45.17684182357414
                            ],
                            [
                                5.68046772166511,
                                45.17619707697497
                            ],
                            [
                                5.679533206810703,
                                45.17553830660552
                            ],
                            [
                                5.678479392187825,
                                45.17572052044912
                            ],
                            [
                                5.678220909355389,
                                45.174977644968806
                            ],
                            [
                                5.677405694269453,
                                45.17405254120035
                            ],
                            [
                                5.6763518796466315,
                                45.17326759288477
                            ],
                            [
                                5.675641038409566,
                                45.17272128586569
                            ],
                            [
                                5.675061700884839,
                                45.172292446706194
                            ],
                            [
                                5.674096138344737,
                                45.17188402545716
                            ],
                            [
                                5.673565078948059,
                                45.17096506693747
                            ],
                            [
                                5.672879529543735,
                                45.169984828173625
                            ],
                            [
                                5.671942933880132,
                                45.1693585556861
                            ],
                            [
                                5.671576015086686,
                                45.16879353626365
                            ],
                            [
                                5.671363591327463,
                                45.16779964506193
                            ],
                            [
                                5.671296001949344,
                                45.16699635045916
                            ],
                            [
                                5.6708132206792925,
                                45.166567468193676
                            ],
                            [
                                5.670958055060453,
                                45.166016043393455
                            ],
                            [
                                5.67178843884443,
                                45.16566884726191
                            ],
                            [
                                5.67227122011451,
                                45.16516506872014
                            ],
                            [
                                5.6725579593051805,
                                45.16432707434916
                            ],
                            [
                                5.672316568669373,
                                45.16379605190065
                            ],
                            [
                                5.67178550927278,
                                45.16363265938256
                            ],
                            [
                                5.672084833660136,
                                45.16342161001896
                            ],
                            [
                                5.673166263706435,
                                45.16327183257749
                            ],
                            [
                                5.673600766848466,
                                45.16296546840175
                            ],
                            [
                                5.673967680613885,
                                45.16233911873806
                            ],
                            [
                                5.673909746862307,
                                45.161644679539734
                            ],
                            [
                                5.673600766848466,
                                45.161297456766164
                            ],
                            [
                                5.672944184322148,
                                45.16131107338532
                            ],
                            [
                                5.672229668041297,
                                45.16126341520365
                            ],
                            [
                                5.6718917196348,
                                45.1607119369603
                            ],
                            [
                                5.6714668721162695,
                                45.15990173393345
                            ],
                            [
                                5.671051680224309,
                                45.158907687590386
                            ],
                            [
                                5.670655799582306,
                                45.15834257132053
                            ],
                            [
                                5.670694422083841,
                                45.15745743818863
                            ],
                            [
                                5.671032369469373,
                                45.15684464587699
                            ],
                            [
                                5.671582740117628,
                                45.15601396103662
                            ],
                            [
                                5.67180481950183,
                                45.15557818709789
                            ],
                            [
                                5.672007587636074,
                                45.15556456910866
                            ],
                            [
                                5.672152422005638,
                                45.15596629843739
                            ],
                            [
                                5.672799348908455,
                                45.15632717151007
                            ],
                            [
                                5.6737262889154465,
                                45.156340789325014
                            ],
                            [
                                5.673784222665432,
                                45.15683102821106
                            ],
                            [
                                5.673948368298113,
                                45.15751871731882
                            ],
                            [
                                5.674170447682457,
                                45.15837661486762
                            ],
                            [
                                5.674506636373707,
                                45.15938393349492
                            ],
                            [
                                5.674844583289598,
                                45.159690316950645
                            ],
                            [
                                5.67575221207818,
                                45.15986052925865
                            ],
                            [
                                5.676766052744938,
                                45.15986052925865
                            ],
                            [
                                5.677277800891602,
                                45.159520104134714
                            ],
                            [
                                5.67780886028973,
                                45.15890733378694
                            ],
                            [
                                5.6782819859333244,
                                45.15836945216182
                            ],
                            [
                                5.67860061458876,
                                45.157647723018584
                            ],
                            [
                                5.678986839605784,
                                45.15713025593135
                            ],
                            [
                                5.679537210253926,
                                45.15637447318946
                            ],
                            [
                                5.679933090895929,
                                45.1559046572435
                            ],
                            [
                                5.680155170280159,
                                45.15564591637278
                            ],
                            [
                                5.68049311716905,
                                45.15574124209374
                            ],
                            [
                                5.680734507803351,
                                45.156006791474425
                            ],
                            [
                                5.680995209689172,
                                45.15621786830454
                            ],
                            [
                                5.681342812204548,
                                45.15623148613767
                            ],
                            [
                                5.681487646585765,
                                45.155945510957736
                            ],
                            [
                                5.68130418970307,
                                45.15531227509524
                            ],
                            [
                                5.6807151965533365,
                                45.15457689558494
                            ],
                            [
                                5.680213091020505,
                                45.15375979123232
                            ],
                            [
                                5.679904111006579,
                                45.153092484637654
                            ],
                            [
                                5.679826866003509,
                                45.152513691977504
                            ],
                            [
                                5.680174468517436,
                                45.15221407935053
                            ],
                            [
                                5.680599316035966,
                                45.15194851229643
                            ],
                            [
                                5.680618627287487,
                                45.151417374475244
                            ],
                            [
                                5.681420044194965,
                                45.15135608902338
                            ],
                            [
                                5.681902825465045,
                                45.151505897789235
                            ],
                            [
                                5.682279394857034,
                                45.15205746303232
                            ],
                            [
                                5.682839421130126,
                                45.15295629865798
                            ],
                            [
                                5.683293235523735,
                                45.15348742213732
                            ],
                            [
                                5.68383395054687,
                                45.153739363595605
                            ],
                            [
                                5.6844036285124275,
                                45.153698498606445
                            ],
                            [
                                5.685069866665202,
                                45.153630406381666
                            ],
                            [
                                5.685436780430706,
                                45.15322185132325
                            ],
                            [
                                5.685523681058839,
                                45.153283134768856
                            ],
                            [
                                5.685349879801038,
                                45.15358274177564
                            ],
                            [
                                5.685446436119463,
                                45.153943629973185
                            ],
                            [
                                5.685948528639585,
                                45.15432494328948
                            ],
                            [
                                5.686122329897245,
                                45.15453602634929
                            ],
                            [
                                5.686479588037685,
                                45.155359923714116
                            ],
                            [
                                5.686711323046978,
                                45.15565271081843
                            ],
                            [
                                5.687425839667924,
                                45.155536958165385
                            ],
                            [
                                5.687647919052182,
                                45.15511479867837
                            ],
                            [
                                5.68789896531149,
                                45.15478115429124
                            ],
                            [
                                5.688507269712716,
                                45.154454317078404
                            ],
                            [
                                5.68893211722974,
                                45.15452240831928
                            ],
                            [
                                5.689270062381723,
                                45.15493095718492
                            ],
                            [
                                5.689859055531457,
                                45.15553015229736
                            ],
                            [
                                5.690361148053057,
                                45.155768466784366
                            ],
                            [
                                5.6910563530808815,
                                45.15595230870733
                            ],
                            [
                                5.692388829635121,
                                45.15585698337168
                            ],
                            [
                                5.6932674915457255,
                                45.15578889372591
                            ],
                            [
                                5.6939337297000066,
                                45.155972735582964
                            ],
                            [
                                5.694670265224659,
                                45.15629759675372
                            ],
                            [
                                5.6951240796182105,
                                45.15663123226287
                            ],
                            [
                                5.6954040927555525,
                                45.15704657169758
                            ],
                            [
                                5.695539271510228,
                                45.15795894605566
                            ],
                            [
                                5.695703417142909,
                                45.158844071397255
                            ],
                            [
                                5.695307538982178,
                                45.15930024799633
                            ],
                            [
                                5.695413750861803,
                                45.159953864959476
                            ],
                            [
                                5.696582081534729,
                                45.16002194962843
                            ],
                            [
                                5.6969586509266605,
                                45.159654291449016
                            ],
                            [
                                5.698493895365033,
                                45.15940237614106
                            ],
                            [
                                5.699527047283311,
                                45.15921854534889
                            ],
                            [
                                5.700096729183002,
                                45.16083895931172
                            ],
                            [
                                5.700620033759179,
                                45.163003948195666
                            ],
                            [
                                5.700890391270036,
                                45.165379927815394
                            ],
                            [
                                5.700818274390031,
                                45.16838871133103
                            ],
                            [
                                5.701117598732878,
                                45.17281402902361
                            ],
                            [
                                5.701262433015643,
                                45.176637851949465
                            ],
                            [
                                5.701247546575928,
                                45.17810016897758
                            ]
                        ],
                        [
                            [
                                5.664614826340767,
                                45.0809341223472
                            ],
                            [
                                5.664696843796207,
                                45.081247517378245
                            ],
                            [
                                5.664502593576373,
                                45.08139967123779
                            ],
                            [
                                5.664633582383345,
                                45.08060835052268
                            ],
                            [
                                5.665332189351773,
                                45.08035142585581
                            ],
                            [
                                5.665215754857201,
                                45.080043114730614
                            ],
                            [
                                5.666234556685652,
                                45.079755356178964
                            ],
                            [
                                5.665710601459011,
                                45.079179834728734
                            ],
                            [
                                5.666438317052268,
                                45.0786351394448
                            ],
                            [
                                5.6644141163764345,
                                45.07688886820378
                            ],
                            [
                                5.665869547561783,
                                45.075799428765066
                            ],
                            [
                                5.669202484974591,
                                45.07623109593277
                            ],
                            [
                                5.667907151219822,
                                45.07423717747474
                            ],
                            [
                                5.665135866786727,
                                45.06987893279009
                            ],
                            [
                                5.668294152457662,
                                45.06944721764046
                            ],
                            [
                                5.669938789696062,
                                45.068121215006585
                            ],
                            [
                                5.670317201804465,
                                45.06731943104913
                            ],
                            [
                                5.670462744877824,
                                45.066589592220055
                            ],
                            [
                                5.670928482857136,
                                45.066281206842945
                            ],
                            [
                                5.671408775147711,
                                45.06650735628227
                            ],
                            [
                                5.6716998613840985,
                                45.06698021130978
                            ],
                            [
                                5.672456685600963,
                                45.06733998968889
                            ],
                            [
                                5.673679247796031,
                                45.06903605725964
                            ],
                            [
                                5.675155774556771,
                                45.07129531909632
                            ],
                            [
                                5.6756215125360825,
                                45.07266236167942
                            ],
                            [
                                5.676465662622718,
                                45.073618244210024
                            ],
                            [
                                5.677426255595435,
                                45.0752319051563
                            ],
                            [
                                5.678430503113788,
                                45.07588968892881
                            ],
                            [
                                5.678969012651663,
                                45.07691746091547
                            ],
                            [
                                5.679041784211279,
                                45.07827409163275
                            ],
                            [
                                5.678823469533427,
                                45.07936348389131
                            ],
                            [
                                5.678561491919538,
                                45.0801753759344
                            ],
                            [
                                5.677905170769122,
                                45.08176720477792
                            ],
                            [
                                5.6768135973798906,
                                45.082733211592455
                            ],
                            [
                                5.675707469680532,
                                45.08384307159861
                            ],
                            [
                                5.674863319592731,
                                45.084387717233625
                            ],
                            [
                                5.67317501941946,
                                45.08530229916573
                            ],
                            [
                                5.671763251170205,
                                45.08597024301034
                            ],
                            [
                                5.671355730438137,
                                45.08579555075892
                            ],
                            [
                                5.670991872642077,
                                45.08570306640979
                            ],
                            [
                                5.670383589106734,
                                45.08571732339439
                            ],
                            [
                                5.669774958172752,
                                45.08629651196199
                            ],
                            [
                                5.669325100525668,
                                45.08704384337241
                            ],
                            [
                                5.668822318449571,
                                45.08726804088923
                            ],
                            [
                                5.668504771875888,
                                45.08700647703415
                            ],
                            [
                                5.667684443225056,
                                45.08553048711937
                            ],
                            [
                                5.666652416858483,
                                45.0838115647212
                            ],
                            [
                                5.665435154990547,
                                45.0818123181775
                            ],
                            [
                                5.664614826340767,
                                45.0809341223472
                            ]
                        ],
                        [
                            [
                                5.673138813741986,
                                45.066964909080696
                            ],
                            [
                                5.673345458840657,
                                45.06690235935861
                            ],
                            [
                                5.676135167678069,
                                45.069675331298015
                            ],
                            [
                                5.679840019096474,
                                45.06998806405241
                            ],
                            [
                                5.679351305608719,
                                45.07182912488324
                            ],
                            [
                                5.678997056868184,
                                45.07300703162022
                            ],
                            [
                                5.67743245826037,
                                45.07263177193187
                            ],
                            [
                                5.676797762599591,
                                45.072454565111855
                            ],
                            [
                                5.6753660072700995,
                                45.07014040207767
                            ],
                            [
                                5.673138813741986,
                                45.066964909080696
                            ]
                        ],
                        [
                            [
                                5.680966192712759,
                                45.07007218806214
                            ],
                            [
                                5.6811922787685205,
                                45.070868186402265
                            ],
                            [
                                5.6811619807279214,
                                45.07170042579577
                            ],
                            [
                                5.681364921486448,
                                45.072932973930506
                            ],
                            [
                                5.681581389478083,
                                45.07443302053929
                            ],
                            [
                                5.682230799905767,
                                45.075178249745534
                            ],
                            [
                                5.682907269100099,
                                45.07624830545578
                            ],
                            [
                                5.6833266800004765,
                                45.0774043252687
                            ],
                            [
                                5.686221961506305,
                                45.07673556117183
                            ],
                            [
                                5.687926663876567,
                                45.07574194714911
                            ],
                            [
                                5.690213129914412,
                                45.07495850850461
                            ],
                            [
                                5.6913225393934965,
                                45.07597124420411
                            ],
                            [
                                5.693725715683598,
                                45.079333707941004
                            ],
                            [
                                5.695606300037383,
                                45.08133034692594
                            ],
                            [
                                5.69691864906477,
                                45.08096733444938
                            ],
                            [
                                5.698488057596336,
                                45.07970629940405
                            ],
                            [
                                5.701748639113845,
                                45.07844523652679
                            ],
                            [
                                5.701762161699577,
                                45.07791978594227
                            ],
                            [
                                5.7044274503258805,
                                45.07712682334619
                            ],
                            [
                                5.704508626629092,
                                45.07598990596762
                            ],
                            [
                                5.710031781215093,
                                45.07342702563366
                            ],
                            [
                                5.713332950884677,
                                45.07239513560168
                            ],
                            [
                                5.706472727419566,
                                45.06620228911268
                            ],
                            [
                                5.702750203721166,
                                45.06176151380919
                            ],
                            [
                                5.704914905142857,
                                45.06077719601521
                            ],
                            [
                                5.712065700077346,
                                45.05743664596318
                            ],
                            [
                                5.715109811451896,
                                45.0563566807474
                            ],
                            [
                                5.71569157495972,
                                45.05712125999963
                            ],
                            [
                                5.717250088216986,
                                45.05896155130196
                            ],
                            [
                                5.718765379213181,
                                45.060872893190265
                            ],
                            [
                                5.719468907175212,
                                45.06193366036689
                            ],
                            [
                                5.7208993467324945,
                                45.063419240484365
                            ],
                            [
                                5.7213322870173045,
                                45.06385882050188
                            ],
                            [
                                5.720547582752005,
                                45.06560755101481
                            ],
                            [
                                5.7204393476800135,
                                45.06620956061673
                            ],
                            [
                                5.720980523035735,
                                45.06777666698969
                            ],
                            [
                                5.722367122235482,
                                45.07063400531496
                            ],
                            [
                                5.7231788852695615,
                                45.072363419309966
                            ],
                            [
                                5.724023262672489,
                                45.07426807399722
                            ],
                            [
                                5.724889143240915,
                                45.07612158016832
                            ],
                            [
                                5.725335612909049,
                                45.07719161821473
                            ],
                            [
                                5.7265716041589485,
                                45.08018164507624
                            ],
                            [
                                5.727640425486982,
                                45.08274187793893
                            ],
                            [
                                5.72848119762898,
                                45.08448392011988
                            ],
                            [
                                5.729184725591068,
                                45.08605052542515
                            ],
                            [
                                5.729712371562329,
                                45.08709172103852
                            ],
                            [
                                5.730172370614838,
                                45.087712608472145
                            ],
                            [
                                5.7262077082593805,
                                45.086146200223794
                            ],
                            [
                                5.730573065175122,
                                45.08825926707084
                            ],
                            [
                                5.726283420066011,
                                45.0900393859352
                            ],
                            [
                                5.724275029154114,
                                45.090933283490955
                            ],
                            [
                                5.72359829124926,
                                45.091426459062575
                            ],
                            [
                                5.722015597761242,
                                45.09330665186343
                            ],
                            [
                                5.7200942423082495,
                                45.09566687602674
                            ],
                            [
                                5.71810769059644,
                                45.098209556724015
                            ],
                            [
                                5.716688724021083,
                                45.09980445284717
                            ],
                            [
                                5.715116945660867,
                                45.10140700890986
                            ],
                            [
                                5.714331061523069,
                                45.102092716974
                            ],
                            [
                                5.71318497313527,
                                45.10224680468227
                            ],
                            [
                                5.7115695342642425,
                                45.10267824805186
                            ],
                            [
                                5.710576257489123,
                                45.10285544701935
                            ],
                            [
                                5.70848055300894,
                                45.102986419847895
                            ],
                            [
                                5.707039756150863,
                                45.10337163228931
                            ],
                            [
                                5.705390545735867,
                                45.10424169582143
                            ],
                            [
                                5.702629018477921,
                                45.10615998593212
                            ],
                            [
                                5.700283070502451,
                                45.10783991920397
                            ],
                            [
                                5.699344369502171,
                                45.10867960763039
                            ],
                            [
                                5.698940509784819,
                                45.10965794461754
                            ],
                            [
                                5.698876683746562,
                                45.110494082981916
                            ],
                            [
                                5.698843938363666,
                                45.112828123633165
                            ],
                            [
                                5.698622008277653,
                                45.1152535962228
                            ],
                            [
                                5.698174488049517,
                                45.11674018243576
                            ],
                            [
                                5.697410428924684,
                                45.11925881739111
                            ],
                            [
                                5.696799181783888,
                                45.12079151051225
                            ],
                            [
                                5.694986071134906,
                                45.1222959091304
                            ],
                            [
                                5.692115392601238,
                                45.12472958461038
                            ],
                            [
                                5.690903813448472,
                                45.12578465848952
                            ],
                            [
                                5.690292566308528,
                                45.124706480584194
                            ],
                            [
                                5.689353721371646,
                                45.123036522540446
                            ],
                            [
                                5.6884586809163125,
                                45.12151929327834
                            ],
                            [
                                5.687356851266912,
                                45.12017473887414
                            ],
                            [
                                5.686712858744073,
                                45.119073348636874
                            ],
                            [
                                5.685632258807715,
                                45.117371155286605
                            ],
                            [
                                5.684289698125127,
                                45.11539162118041
                            ],
                            [
                                5.683064348479604,
                                45.11331789396104
                            ],
                            [
                                5.6827805551650385,
                                45.11239354077492
                            ],
                            [
                                5.682038326494478,
                                45.11101468611554
                            ],
                            [
                                5.681241521346607,
                                45.11000555659169
                            ],
                            [
                                5.679822554771164,
                                45.10846487021732
                            ],
                            [
                                5.679087449029737,
                                45.1075354669328
                            ],
                            [
                                5.678050511917604,
                                45.105332177286726
                            ],
                            [
                                5.676719257389351,
                                45.10271721672714
                            ],
                            [
                                5.67559499925639,
                                45.10057537794904
                            ],
                            [
                                5.67470123611821,
                                45.09903070294473
                            ],
                            [
                                5.672660107274936,
                                45.09705053269872
                            ],
                            [
                                5.671847405893345,
                                45.09617325797572
                            ],
                            [
                                5.671127007477793,
                                45.0952101005326
                            ],
                            [
                                5.670450269572996,
                                45.09377689218553
                            ],
                            [
                                5.669788954278033,
                                45.09190860209014
                            ],
                            [
                                5.669428755070669,
                                45.090521546649256
                            ],
                            [
                                5.669417839942781,
                                45.08955058779745
                            ],
                            [
                                5.669846680948638,
                                45.08876193732044
                            ],
                            [
                                5.670381522196607,
                                45.087968193768205
                            ],
                            [
                                5.67157127109445,
                                45.087112787883
                            ],
                            [
                                5.671538525711526,
                                45.08795278116287
                            ],
                            [
                                5.671876894664791,
                                45.08844598247089
                            ],
                            [
                                5.67276101999326,
                                45.08836121379494
                            ],
                            [
                                5.673132134328512,
                                45.08762140913865
                            ],
                            [
                                5.673252200731241,
                                45.086897007794335
                            ],
                            [
                                5.672837425876821,
                                45.08624966262872
                            ],
                            [
                                5.674802148827268,
                                45.08544817752508
                            ],
                            [
                                5.675902187624018,
                                45.08474528689834
                            ],
                            [
                                5.677212002924563,
                                45.083905246450996
                            ],
                            [
                                5.67848907284224,
                                45.082826277296874
                            ],
                            [
                                5.679296792277739,
                                45.081392758185046
                            ],
                            [
                                5.679834985797015,
                                45.079848417413956
                            ],
                            [
                                5.6800096278367675,
                                45.078807909342174
                            ],
                            [
                                5.680162439622393,
                                45.07756698234999
                            ],
                            [
                                5.6805585601078405,
                                45.077131634903566
                            ],
                            [
                                5.6809515046981005,
                                45.07596775076374
                            ],
                            [
                                5.680984250080968,
                                45.075304865757516
                            ],
                            [
                                5.680089209625606,
                                45.07448781086791
                            ],
                            [
                                5.680027491747438,
                                45.0732943403826
                            ],
                            [
                                5.680213048915164,
                                45.071899124476516
                            ],
                            [
                                5.680540502739632,
                                45.07001051830363
                            ],
                            [
                                5.680966192712759,
                                45.07007218806214
                            ]
                        ],
                        [
                            [
                                5.7311365876757066,
                                45.08795801161111
                            ],
                            [
                                5.730594618611491,
                                45.087255366202214
                            ],
                            [
                                5.728604034026603,
                                45.08290647633186
                            ],
                            [
                                5.725827161453793,
                                45.076647435477184
                            ],
                            [
                                5.723623591539422,
                                45.07164207071253
                            ],
                            [
                                5.721814764763138,
                                45.06783476141746
                            ],
                            [
                                5.7210778320908275,
                                45.06616299012265
                            ],
                            [
                                5.721955830700949,
                                45.0643025955431
                            ],
                            [
                                5.721999730630756,
                                45.06384782321487
                            ],
                            [
                                5.719234408225816,
                                45.0604991933734
                            ],
                            [
                                5.715843843334397,
                                45.056257699152525
                            ],
                            [
                                5.717438874141465,
                                45.055596117852474
                            ],
                            [
                                5.722881077721212,
                                45.05276051737087
                            ],
                            [
                                5.73394386465182,
                                45.0492265685273
                            ],
                            [
                                5.7372070928171865,
                                45.049609088750145
                            ],
                            [
                                5.736563227170308,
                                45.05201787353619
                            ],
                            [
                                5.736241294346257,
                                45.05433351948696
                            ],
                            [
                                5.73711206948488,
                                45.05552154426505
                            ],
                            [
                                5.739789965243858,
                                45.05918082468426
                            ],
                            [
                                5.742468504766123,
                                45.06086204854665
                            ],
                            [
                                5.743639169578842,
                                45.06186466237335
                            ],
                            [
                                5.743946469092151,
                                45.0635597632824
                            ],
                            [
                                5.743112370246962,
                                45.06542018203689
                            ],
                            [
                                5.743079554632629,
                                45.067010414384754
                            ],
                            [
                                5.743372220835198,
                                45.06822995545224
                            ],
                            [
                                5.744294119375155,
                                45.07006955291973
                            ],
                            [
                                5.7439136533117505,
                                45.07056561401109
                            ],
                            [
                                5.74452825233837,
                                45.071330366425116
                            ],
                            [
                                5.744164963559001,
                                45.07257404490477
                            ],
                            [
                                5.7428333323343,
                                45.07352478321795
                            ],
                            [
                                5.742160200067133,
                                45.074361833205415
                            ],
                            [
                                5.742965032126023,
                                45.0763769032597
                            ],
                            [
                                5.743736757189083,
                                45.07809444037963
                            ],
                            [
                                5.743575790777072,
                                45.0794067416839
                            ],
                            [
                                5.744292822975154,
                                45.08040903025267
                            ],
                            [
                                5.744526955937232,
                                45.08139063603605
                            ],
                            [
                                5.74550738771768,
                                45.081566290028434
                            ],
                            [
                                5.745463487787845,
                                45.08219657343929
                            ],
                            [
                                5.744409889456165,
                                45.08192792890139
                            ],
                            [
                                5.7430197249909725,
                                45.08181427121684
                            ],
                            [
                                5.741938801127475,
                                45.081886595043386
                            ],
                            [
                                5.740577903282514,
                                45.08221723453474
                            ],
                            [
                                5.739114572267226,
                                45.0830748217968
                            ],
                            [
                                5.7383800762033275,
                                45.08423858079499
                            ],
                            [
                                5.737150878150118,
                                45.085189125040245
                            ],
                            [
                                5.734092516326598,
                                45.08673889152419
                            ],
                            [
                                5.7311365876757066,
                                45.08795801161111
                            ]
                        ],
                        [
                            [
                                5.690011386823471,
                                45.14293782494218
                            ],
                            [
                                5.6908228016543205,
                                45.144756877861425
                            ],
                            [
                                5.692375946639061,
                                45.144942773998054
                            ],
                            [
                                5.693722003429372,
                                45.144750237294346
                            ],
                            [
                                5.694192653424665,
                                45.14571954631788
                            ],
                            [
                                5.695265726805985,
                                45.1480033245214
                            ],
                            [
                                5.695971702905041,
                                45.14977585269713
                            ],
                            [
                                5.696574134899606,
                                45.15097078349592
                            ],
                            [
                                5.69715774089363,
                                45.15198645497779
                            ],
                            [
                                5.6975715598913155,
                                45.15296666302436
                            ],
                            [
                                5.697948079888505,
                                45.15391591805849
                            ],
                            [
                                5.6977227581069485,
                                45.1548327889044
                            ],
                            [
                                5.697953143577848,
                                45.15613555518007
                            ],
                            [
                                5.69940216232925,
                                45.15875134163787
                            ],
                            [
                                5.697916918109399,
                                45.159027218365566
                            ],
                            [
                                5.696641781607923,
                                45.15921624424149
                            ],
                            [
                                5.6963085072950435,
                                45.1591549386205
                            ],
                            [
                                5.696163605419002,
                                45.15889438899691
                            ],
                            [
                                5.696098399575561,
                                45.158209801954655
                            ],
                            [
                                5.6960331937321484,
                                45.15744857237675
                            ],
                            [
                                5.695902782044129,
                                45.15683549418256
                            ],
                            [
                                5.695565617356209,
                                45.156370186756135
                            ],
                            [
                                5.694732431458732,
                                45.15581840616244
                            ],
                            [
                                5.693725363426694,
                                45.15547609519146
                            ],
                            [
                                5.693116775550948,
                                45.15542500382509
                            ],
                            [
                                5.692066230488024,
                                45.155542515953215
                            ],
                            [
                                5.690993956611408,
                                45.155573170715485
                            ],
                            [
                                5.690443329382191,
                                45.15533304123937
                            ],
                            [
                                5.689921682575516,
                                45.15482723338772
                            ],
                            [
                                5.689392790730068,
                                45.154219236237765
                            ],
                            [
                                5.6890305360422815,
                                45.15404041231233
                            ],
                            [
                                5.688559599171867,
                                45.15399442404376
                            ],
                            [
                                5.687226501920378,
                                45.154617751227875
                            ],
                            [
                                5.687154050983509,
                                45.15496006735489
                            ],
                            [
                                5.687030884447978,
                                45.155215525836866
                            ],
                            [
                                5.686791796353447,
                                45.1551695433991
                            ],
                            [
                                5.68669761013507,
                                45.15474037219181
                            ],
                            [
                                5.686581688635101,
                                45.15433163470374
                            ],
                            [
                                5.686284639790699,
                                45.15405062598012
                            ],
                            [
                                5.6859441201571315,
                                45.153708304348015
                            ],
                            [
                                5.686045551470158,
                                45.15348860433403
                            ],
                            [
                                5.686480282047484,
                                45.15292146446029
                            ],
                            [
                                5.686233948859552,
                                45.15259957366018
                            ],
                            [
                                5.685603625702299,
                                45.152461619903676
                            ],
                            [
                                5.68520514513915,
                                45.15292657373615
                            ],
                            [
                                5.684719723873712,
                                45.15334553995902
                            ],
                            [
                                5.684038685059932,
                                45.15337108658011
                            ],
                            [
                                5.683618469622132,
                                45.153156494608425
                            ],
                            [
                                5.683183763996226,
                                45.15269154256424
                            ],
                            [
                                5.682770793651883,
                                45.152119288687686
                            ],
                            [
                                5.682466499695636,
                                45.15141929174888
                            ],
                            [
                                5.682169450851234,
                                45.15105651623483
                            ],
                            [
                                5.681039217849531,
                                45.15083679761952
                            ],
                            [
                                5.679908983222958,
                                45.15095431715392
                            ],
                            [
                                5.67998867925445,
                                45.1516236629252
                            ],
                            [
                                5.6800031694425,
                                45.1518433701286
                            ],
                            [
                                5.679532238348145,
                                45.152063076485405
                            ],
                            [
                                5.679329375722176,
                                45.152374751491465
                            ],
                            [
                                5.679387336472132,
                                45.15316670490566
                            ],
                            [
                                5.6796336696600065,
                                45.15374405755509
                            ],
                            [
                                5.67999138072895,
                                45.15431654551884
                            ],
                            [
                                5.680455066728712,
                                45.15489899570551
                            ],
                            [
                                5.680389860885242,
                                45.15520554604461
                            ],
                            [
                                5.679976890540928,
                                45.155261746761255
                            ],
                            [
                                5.6796581064160705,
                                45.155481439939535
                            ],
                            [
                                5.679259626258698,
                                45.15598724194467
                            ],
                            [
                                5.678861146102378,
                                45.156523693710795
                            ],
                            [
                                5.67837572482,
                                45.15702948646481
                            ],
                            [
                                5.678109747920587,
                                45.157603345859314
                            ],
                            [
                                5.677776473607707,
                                45.15834413786456
                            ],
                            [
                                5.6770736995135564,
                                45.15907981142814
                            ],
                            [
                                5.6766607291691855,
                                45.159452753425796
                            ],
                            [
                                5.676189797971688,
                                45.15953960255709
                            ],
                            [
                                5.675429063127552,
                                45.159432318286406
                            ],
                            [
                                5.674965377103973,
                                45.1592943810588
                            ],
                            [
                                5.67476975957257,
                                45.15900828799036
                            ],
                            [
                                5.6746668361298305,
                                45.158408302696444
                            ],
                            [
                                5.674456728411485,
                                45.15749380737415
                            ],
                            [
                                5.6744234450234785,
                                45.15696858405647
                            ],
                            [
                                5.67432481083398,
                                45.15648170699487
                            ],
                            [
                                5.674155723649989,
                                45.156133935117964
                            ],
                            [
                                5.6737611868898625,
                                45.15593520737826
                            ],
                            [
                                5.673042566360834,
                                45.15590539815764
                            ],
                            [
                                5.672633939001571,
                                45.15564705092493
                            ],
                            [
                                5.672507123613798,
                                45.15526946440082
                            ],
                            [
                                5.672056224458288,
                                45.155021050849626
                            ],
                            [
                                5.671408061098987,
                                45.15524959497242
                            ],
                            [
                                5.670717621767096,
                                45.15601470217331
                            ],
                            [
                                5.670252632013529,
                                45.15688909782392
                            ],
                            [
                                5.670041273034286,
                                45.15778335222481
                            ],
                            [
                                5.67009763542859,
                                45.15855836135367
                            ],
                            [
                                5.670731712366205,
                                45.159353231561425
                            ],
                            [
                                5.671103567509164,
                                45.16029966349265
                            ],
                            [
                                5.671343107685516,
                                45.16109450940846
                            ],
                            [
                                5.671836278636249,
                                45.16164095954309
                            ],
                            [
                                5.6724985367709735,
                                45.16184960275737
                            ],
                            [
                                5.673203066700836,
                                45.161680701166915
                            ],
                            [
                                5.673386244482913,
                                45.161899279600846
                            ],
                            [
                                5.673358063284667,
                                45.162425851475206
                            ],
                            [
                                5.672991707721678,
                                45.16282326098812
                            ],
                            [
                                5.672329449586925,
                                45.16303189987269
                            ],
                            [
                                5.671385379480682,
                                45.163230602861915
                            ],
                            [
                                5.671244473494909,
                                45.1638068376115
                            ],
                            [
                                5.671441741874986,
                                45.1643631966665
                            ],
                            [
                                5.671174020501496,
                                45.164611569490404
                            ],
                            [
                                5.670779483741342,
                                45.164532090304675
                            ],
                            [
                                5.670652668353654,
                                45.16415456265753
                            ],
                            [
                                5.670723121347066,
                                45.163409434959675
                            ],
                            [
                                5.670849936733703,
                                45.162843131390986
                            ],
                            [
                                5.670751302544204,
                                45.16243578674681
                            ],
                            [
                                5.669708598247354,
                                45.162028439190124
                            ],
                            [
                                5.669412695676726,
                                45.161551540788906
                            ],
                            [
                                5.668806799936249,
                                45.161044831863705
                            ],
                            [
                                5.668299538387544,
                                45.16055798963143
                            ],
                            [
                                5.667665461449928,
                                45.16045863356294
                            ],
                            [
                                5.667411830675604,
                                45.16084612125101
                            ],
                            [
                                5.667721823844261,
                                45.161283283683986
                            ],
                            [
                                5.668285447788406,
                                45.162157598487
                            ],
                            [
                                5.66834181018271,
                                45.16282326098812
                            ],
                            [
                                5.668496814194384,
                                45.16458176676605
                            ],
                            [
                                5.668186821024648,
                                45.16570439900846
                            ],
                            [
                                5.667256841517485,
                                45.16639982381395
                            ],
                            [
                                5.6671582073269064,
                                45.16788999123699
                            ],
                            [
                                5.6674748006184075,
                                45.16878746409634
                            ],
                            [
                                5.666671636497938,
                                45.16960205963875
                            ],
                            [
                                5.666516639913084,
                                45.170655056124986
                            ],
                            [
                                5.666756180089379,
                                45.171310685612326
                            ],
                            [
                                5.666347552730144,
                                45.17135042049054
                            ],
                            [
                                5.665572569806784,
                                45.1713305530547
                            ],
                            [
                                5.665008962608624,
                                45.17155903305803
                            ],
                            [
                                5.664290342079568,
                                45.171996113284166
                            ],
                            [
                                5.663853533523195,
                                45.1726815277932
                            ],
                            [
                                5.663332181375324,
                                45.17297953152885
                            ],
                            [
                                5.662458564261414,
                                45.17316826642147
                            ],
                            [
                                5.66178221552866,
                                45.173496067642844
                            ],
                            [
                                5.661401769366535,
                                45.17351593432281
                            ],
                            [
                                5.660429518063097,
                                45.17356560099262
                            ],
                            [
                                5.659584082146353,
                                45.174012599072256
                            ],
                            [
                                5.658175022286514,
                                45.17480725366107
                            ],
                            [
                                5.657710032532975,
                                45.17502578172909
                            ],
                            [
                                5.656244618370408,
                                45.175542305280885
                            ],
                            [
                                5.655230095270724,
                                45.175880025763945
                            ],
                            [
                                5.654708743121688,
                                45.17580056230116
                            ],
                            [
                                5.654441021749307,
                                45.175562171247094
                            ],
                            [
                                5.654116937980405,
                                45.17543304234255
                            ],
                            [
                                5.6536519482268375,
                                45.17561183613225
                            ],
                            [
                                5.6536237670297,
                                45.1761680798875
                            ],
                            [
                                5.6536237670297,
                                45.17708189711911
                            ],
                            [
                                5.653201049071299,
                                45.17849232536449
                            ],
                            [
                                5.652961508896084,
                                45.17856185261414
                            ],
                            [
                                5.6524401567471045,
                                45.17770765480725
                            ],
                            [
                                5.6517497174163225,
                                45.17709182983455
                            ],
                            [
                                5.6509465532958245,
                                45.17574096463093
                            ],
                            [
                                5.6507915608816575,
                                45.175164849434225
                            ],
                            [
                                5.6507915608816575,
                                45.17460859587939
                            ],
                            [
                                5.6499883967611595,
                                45.1738636049279
                            ],
                            [
                                5.650024694361235,
                                45.17272437368496
                            ],
                            [
                                5.650898311475174,
                                45.17232703324433
                            ],
                            [
                                5.650996945665753,
                                45.17184028742676
                            ],
                            [
                                5.65041923112247,
                                45.171085326499934
                            ],
                            [
                                5.650376959327275,
                                45.170568768521065
                            ],
                            [
                                5.6509687644730775,
                                45.17048929764559
                            ],
                            [
                                5.651250576444568,
                                45.170221082626824
                            ],
                            [
                                5.651208311753749,
                                45.16989326158682
                            ],
                            [
                                5.651757845098757,
                                45.16936675873504
                            ],
                            [
                                5.651673301507316,
                                45.16797597307786
                            ],
                            [
                                5.652222834852353,
                                45.16768787751758
                            ],
                            [
                                5.652703021587456,
                                45.1669809896338
                            ],
                            [
                                5.653802088278553,
                                45.16670282356861
                            ],
                            [
                                5.653097558348662,
                                45.16633524489873
                            ],
                            [
                                5.652153488242362,
                                45.165669623433416
                            ],
                            [
                                5.651167146339816,
                                45.164636404558365
                            ],
                            [
                                5.648868317810354,
                                45.16498625760116
                            ],
                            [
                                5.646458825448889,
                                45.16551280094325
                            ],
                            [
                                5.64364070572924,
                                45.166128751129236
                            ],
                            [
                                5.642118920907421,
                                45.165979731479496
                            ],
                            [
                                5.641611659357579,
                                45.165482996678634
                            ],
                            [
                                5.643401165380595,
                                45.16413185610719
                            ],
                            [
                                5.643360560671994,
                                45.16309669909725
                            ],
                            [
                                5.643247835883386,
                                45.160930794031486
                            ],
                            [
                                5.641397074256133,
                                45.15913413020019
                            ],
                            [
                                5.6392412126701,
                                45.15527889134225
                            ],
                            [
                                5.638438038760597,
                                45.15226137687259
                            ],
                            [
                                5.638522582352039,
                                45.15126766372023
                            ],
                            [
                                5.6392257845458005,
                                45.14969584345107
                            ],
                            [
                                5.6402543982434565,
                                45.14893065141524
                            ],
                            [
                                5.640240307644376,
                                45.14810582252119
                            ],
                            [
                                5.639845778508885,
                                45.14658532547597
                            ],
                            [
                                5.638803074638474,
                                45.14561138995444
                            ],
                            [
                                5.638408537877211,
                                45.14385230065193
                            ],
                            [
                                5.638283739085068,
                                45.142203787746666
                            ],
                            [
                                5.6376073903522865,
                                45.1413788615144
                            ],
                            [
                                5.636226511689642,
                                45.13943079556904
                            ],
                            [
                                5.635040718541404,
                                45.138197507264294
                            ],
                            [
                                5.63420937322374,
                                45.13651770757156
                            ],
                            [
                                5.633138487730861,
                                45.13399294513326
                            ],
                            [
                                5.631920296477659,
                                45.133079979516964
                            ],
                            [
                                5.630525327215878,
                                45.13071411791762
                            ],
                            [
                                5.629102176733625,
                                45.129769733993186
                            ],
                            [
                                5.6283280540378655,
                                45.128207432624606
                            ],
                            [
                                5.6280744232624045,
                                45.12656710476543
                            ],
                            [
                                5.628229419847258,
                                45.125185216204926
                            ],
                            [
                                5.6275953452458225,
                                45.12373589460401
                            ],
                            [
                                5.6275953452458225,
                                45.12253289782521
                            ],
                            [
                                5.626425825561256,
                                45.1204847623078
                            ],
                            [
                                5.625721296102654,
                                45.11870821993571
                            ],
                            [
                                5.625326759342471,
                                45.11644119519195
                            ],
                            [
                                5.62462395115989,
                                45.11447948688013
                            ],
                            [
                                5.624187142603489,
                                45.112719437172444
                            ],
                            [
                                5.623778515244226,
                                45.111048831307045
                            ],
                            [
                                5.623973787477269,
                                45.10970502776968
                            ],
                            [
                                5.622719724201261,
                                45.108282951959154
                            ],
                            [
                                5.622776086595536,
                                45.10642326090033
                            ],
                            [
                                5.621885907565314,
                                45.10452193338298
                            ],
                            [
                                5.62032185112065,
                                45.10291076148306
                            ],
                            [
                                5.61954686819729,
                                45.1014586787154
                            ],
                            [
                                5.619105020533027,
                                45.098794893058255
                            ],
                            [
                                5.618442762399383,
                                45.09691499952564
                            ],
                            [
                                5.61892184264326,
                                45.094540208960126
                            ],
                            [
                                5.6193163794044665,
                                45.092033483733644
                            ],
                            [
                                5.619893538912152,
                                45.09123924648429
                            ],
                            [
                                5.620006263700759,
                                45.089657543625776
                            ],
                            [
                                5.619259461975673,
                                45.088404087609945
                            ],
                            [
                                5.61492237513184,
                                45.08663725867993
                            ],
                            [
                                5.618867742740946,
                                45.08449829532978
                            ],
                            [
                                5.625064405687141,
                                45.080934752864096
                            ],
                            [
                                5.62912249808474,
                                45.08116359029822
                            ],
                            [
                                5.6320755333080115,
                                45.08196392115846
                            ],
                            [
                                5.6350627402116515,
                                45.082083312566226
                            ],
                            [
                                5.63831766848773,
                                45.082660367518685
                            ],
                            [
                                5.640469351070266,
                                45.08397048184352
                            ],
                            [
                                5.642639303255407,
                                45.08564188013963
                            ],
                            [
                                5.645231973397813,
                                45.0870147778719
                            ],
                            [
                                5.647067929303034,
                                45.088601366953355
                            ],
                            [
                                5.647561100253796,
                                45.089964245656404
                            ],
                            [
                                5.646518395956946,
                                45.09133703950229
                            ],
                            [
                                5.654634672311602,
                                45.091592493164654
                            ],
                            [
                                5.6562128193543515,
                                45.09238829701587
                            ],
                            [
                                5.659707287807834,
                                45.09267677317388
                            ],
                            [
                                5.66007364889262,
                                45.090319176688126
                            ],
                            [
                                5.6616236147381755,
                                45.089125423506005
                            ],
                            [
                                5.661849064316527,
                                45.08713577942561
                            ],
                            [
                                5.661510889949568,
                                45.086648306056105
                            ],
                            [
                                5.663342667767807,
                                45.08652892418837
                            ],
                            [
                                5.666766683227905,
                                45.085175912252936
                            ],
                            [
                                5.6679221123133345,
                                45.087374540374526
                            ],
                            [
                                5.668401192666039,
                                45.08839921144835
                            ],
                            [
                                5.668344830271707,
                                45.08944376012761
                            ],
                            [
                                5.668598461046088,
                                45.09075687993686
                            ],
                            [
                                5.669260718868259,
                                45.09304953226737
                            ],
                            [
                                5.670080982583443,
                                45.09497247402544
                            ],
                            [
                                5.670715059521058,
                                45.09614621135219
                            ],
                            [
                                5.672589109135544,
                                45.09802613019474
                            ],
                            [
                                5.673838960447625,
                                45.09938398842934
                            ],
                            [
                                5.674754849356788,
                                45.10103504376315
                            ],
                            [
                                5.676824141709261,
                                45.105011025827025
                            ],
                            [
                                5.678078204985326,
                                45.10756690557005
                            ],
                            [
                                5.679121711693398,
                                45.108911190805514
                            ],
                            [
                                5.680333503173131,
                                45.110353139665875
                            ],
                            [
                                5.681503331165345,
                                45.11188583843216
                            ],
                            [
                                5.682165589300041,
                                45.11314872290373
                            ],
                            [
                                5.682729213244215,
                                45.11446129761899
                            ],
                            [
                                5.683766702553584,
                                45.11618889945689
                            ],
                            [
                                5.685277329281604,
                                45.118599090649184
                            ],
                            [
                                5.686367782825442,
                                45.12052273166651
                            ],
                            [
                                5.687953897069576,
                                45.12258620174785
                            ],
                            [
                                5.6891439561815105,
                                45.124422944082255
                            ],
                            [
                                5.68996179633811,
                                45.12599667635814
                            ],
                            [
                                5.690036145444196,
                                45.128164858601934
                            ],
                            [
                                5.690283975794387,
                                45.12945873454538
                            ],
                            [
                                5.689664409299468,
                                45.13132071989932
                            ],
                            [
                                5.689441361983313,
                                45.133156514652256
                            ],
                            [
                                5.6897139753695285,
                                45.13486986977421
                            ],
                            [
                                5.6887226539667495,
                                45.13548176984287
                            ],
                            [
                                5.688598738791683,
                                45.1364433138273
                            ],
                            [
                                5.688945716315629,
                                45.13744869021639
                            ],
                            [
                                5.690655745734887,
                                45.13931925089753
                            ],
                            [
                                5.691077057331228,
                                45.14070027968546
                            ],
                            [
                                5.689912254682383,
                                45.14187150573352
                            ],
                            [
                                5.690011386823471,
                                45.14293782494218
                            ]
                        ],
                        [
                            [
                                5.645849157971753,
                                45.17481464398767
                            ],
                            [
                                5.645826961613238,
                                45.17450952208458
                            ],
                            [
                                5.64593794340567,
                                45.173476789665756
                            ],
                            [
                                5.647136546766234,
                                45.172076311998154
                            ],
                            [
                                5.64729192127561,
                                45.17112960088244
                            ],
                            [
                                5.648557113711661,
                                45.17070709662832
                            ],
                            [
                                5.649100924494888,
                                45.169971618782654
                            ],
                            [
                                5.6494005753356475,
                                45.17006551010067
                            ],
                            [
                                5.649100924494888,
                                45.1709731181904
                            ],
                            [
                                5.648601506428605,
                                45.17114524912796
                            ],
                            [
                                5.648246364692028,
                                45.171771175423515
                            ],
                            [
                                5.647891222955451,
                                45.17328901812266
                            ],
                            [
                                5.64755827757827,
                                45.17498676331479
                            ],
                            [
                                5.645849157971753,
                                45.17481464398767
                            ]
                        ],
                        [
                            [
                                5.646993410321016,
                                45.18656717313726
                            ],
                            [
                                5.646990246295587,
                                45.185872130011205
                            ],
                            [
                                5.647428489933645,
                                45.18519259407478
                            ],
                            [
                                5.647305781714493,
                                45.184265941089365
                            ],
                            [
                                5.6466045918932934,
                                45.18309215898691
                            ],
                            [
                                5.646131288764252,
                                45.18196777659497
                            ],
                            [
                                5.647183073496706,
                                45.18159709621423
                            ],
                            [
                                5.6475862575795475,
                                45.18211604810611
                            ],
                            [
                                5.647726495543537,
                                45.1833269173778
                            ],
                            [
                                5.648550393583861,
                                45.1832651389569
                            ],
                            [
                                5.648550393583861,
                                45.18236316637572
                            ],
                            [
                                5.648429485097154,
                                45.18015148487285
                            ],
                            [
                                5.648429485097154,
                                45.17838449702381
                            ],
                            [
                                5.648482074332975,
                                45.176839882309366
                            ],
                            [
                                5.6482892485906575,
                                45.17512232859565
                            ],
                            [
                                5.648517135282788,
                                45.17399778881199
                            ],
                            [
                                5.648850200447924,
                                45.172663141350796
                            ],
                            [
                                5.648902789685081,
                                45.17189694071405
                            ],
                            [
                                5.649376092814123,
                                45.171513836529726
                            ],
                            [
                                5.650024693398137,
                                45.171476761794565
                            ],
                            [
                                5.649884455434119,
                                45.17209467089722
                            ],
                            [
                                5.649586449760022,
                                45.17232947459854
                            ],
                            [
                                5.649358563067921,
                                45.173985431108605
                            ],
                            [
                                5.650094812380075,
                                45.174739246107976
                            ],
                            [
                                5.650199990853082,
                                45.17569076882785
                            ],
                            [
                                5.650988829402394,
                                45.17712420160851
                            ],
                            [
                                5.651549766574107,
                                45.177791468888245
                            ],
                            [
                                5.652180837413312,
                                45.17826102834658
                            ],
                            [
                                5.652478843087408,
                                45.17897771689715
                            ],
                            [
                                5.65240872410547,
                                45.179644970542114
                            ],
                            [
                                5.653127443671394,
                                45.17978089162196
                            ],
                            [
                                5.653758514510571,
                                45.17957083163407
                            ],
                            [
                                5.653898752474561,
                                45.178396952730594
                            ],
                            [
                                5.654284406876826,
                                45.176852338354024
                            ],
                            [
                                5.654179228402484,
                                45.176086194078124
                            ],
                            [
                                5.654968066951795,
                                45.1762962669215
                            ],
                            [
                                5.655949732700947,
                                45.17618505198311
                            ],
                            [
                                5.657824889603603,
                                45.17544287930994
                            ],
                            [
                                5.658843392807455,
                                45.17490777708852
                            ],
                            [
                                5.660265453884421,
                                45.17414914253783
                            ],
                            [
                                5.6615349637559405,
                                45.173956829909656
                            ],
                            [
                                5.6624381647090445,
                                45.17365201750965
                            ],
                            [
                                5.663302931580631,
                                45.17339461910103
                            ],
                            [
                                5.664196524013391,
                                45.173083029997315
                            ],
                            [
                                5.664667341532237,
                                45.17248694174441
                            ],
                            [
                                5.665226009142117,
                                45.171926540354804
                            ],
                            [
                                5.666148427137443,
                                45.1717436465905
                            ],
                            [
                                5.666801806551376,
                                45.17187912350917
                            ],
                            [
                                5.667435968922945,
                                45.17143204845405
                            ],
                            [
                                5.667195755902554,
                                45.17082916895842
                            ],
                            [
                                5.667138104778729,
                                45.1701517686536
                            ],
                            [
                                5.6674263604025725,
                                45.16966403544521
                            ],
                            [
                                5.667974046086101,
                                45.16912887892542
                            ],
                            [
                                5.66818863042775,
                                45.168616078648625
                            ],
                            [
                                5.667909983324336,
                                45.1679251034077
                            ],
                            [
                                5.667977242970068,
                                45.166732812694704
                            ],
                            [
                                5.66835197528053,
                                45.16621795217168
                            ],
                            [
                                5.668759123477884,
                                45.16556893428549
                            ],
                            [
                                5.669018553539047,
                                45.16477630193262
                            ],
                            [
                                5.669028162059419,
                                45.1640514062795
                            ],
                            [
                                5.668927886264356,
                                45.162903141407384
                            ],
                            [
                                5.6688702351389395,
                                45.16225274677811
                            ],
                            [
                                5.6701289513632105,
                                45.16265246935686
                            ],
                            [
                                5.67001364918417,
                                45.16358740280697
                            ],
                            [
                                5.670088126876976,
                                45.164674355655904
                            ],
                            [
                                5.670424425133177,
                                45.16510115985983
                            ],
                            [
                                5.671212323837096,
                                45.16514180770923
                            ],
                            [
                                5.671231540959582,
                                45.16548053871
                            ],
                            [
                                5.670568553068847,
                                45.16569732552895
                            ],
                            [
                                5.670309123007684,
                                45.1662731613319
                            ],
                            [
                                5.670407297694396,
                                45.16686047076888
                            ],
                            [
                                5.670733987399842,
                                45.16717886831378
                            ],
                            [
                                5.6708588981709624,
                                45.167890175547285
                            ],
                            [
                                5.670906940774415,
                                45.168621796577554
                            ],
                            [
                                5.671214413440595,
                                45.16929244093376
                            ],
                            [
                                5.671814444962479,
                                45.16997262796414
                            ],
                            [
                                5.672381347689765,
                                45.170284234085045
                            ],
                            [
                                5.672880990769812,
                                45.17094131097005
                            ],
                            [
                                5.673553587226024,
                                45.17201835874519
                            ],
                            [
                                5.674046181663726,
                                45.17237977204405
                            ],
                            [
                                5.675016642262705,
                                45.17292844544667
                            ],
                            [
                                5.676092796591945,
                                45.173741285216124
                            ],
                            [
                                5.677203339736678,
                                45.17476622468118
                            ],
                            [
                                5.677779850982944,
                                45.17539615618546
                            ],
                            [
                                5.677731808379434,
                                45.17613445413258
                            ],
                            [
                                5.678221842939138,
                                45.176351200335745
                            ],
                            [
                                5.679221129102189,
                                45.17596512058648
                            ],
                            [
                                5.679577171383357,
                                45.17616522741028
                            ],
                            [
                                5.680278593432206,
                                45.176795143448544
                            ],
                            [
                                5.680682151305319,
                                45.1773776402415
                            ],
                            [
                                5.680797453554561,
                                45.17809559320628
                            ],
                            [
                                5.68138357332262,
                                45.17818364341855
                            ],
                            [
                                5.6817390885922805,
                                45.17805495460098
                            ],
                            [
                                5.68167182894652,
                                45.17726926919596
                            ],
                            [
                                5.68176791415496,
                                45.17601621400655
                            ],
                            [
                                5.681710263029601,
                                45.175291461392106
                            ],
                            [
                                5.681191401656463,
                                45.17478095272904
                            ],
                            [
                                5.680115247327109,
                                45.17436776817266
                            ],
                            [
                                5.679673255370972,
                                45.17382588224379
                            ],
                            [
                                5.6791928293397405,
                                45.17295208031635
                            ],
                            [
                                5.6788613751012065,
                                45.1720647739848
                            ],
                            [
                                5.678573119478813,
                                45.17114352758418
                            ],
                            [
                                5.678227212729581,
                                45.17036452083781
                            ],
                            [
                                5.6780254333300775,
                                45.16932090478221
                            ],
                            [
                                5.6780926929758095,
                                45.16883316445899
                            ],
                            [
                                5.678448208245499,
                                45.16859606696016
                            ],
                            [
                                5.679043936534015,
                                45.16879929344793
                            ],
                            [
                                5.679697316016956,
                                45.16909058349373
                            ],
                            [
                                5.680206567669416,
                                45.16918542179917
                            ],
                            [
                                5.680168133586307,
                                45.16963928859448
                            ],
                            [
                                5.680312261398257,
                                45.170208310511924
                            ],
                            [
                                5.68052364885591,
                                45.17060797726964
                            ],
                            [
                                5.680764460442759,
                                45.171552982277746
                            ],
                            [
                                5.6813794057735265,
                                45.17160039939492
                            ],
                            [
                                5.6820520022281755,
                                45.171512339003215
                            ],
                            [
                                5.6827630327659335,
                                45.17194586576986
                            ],
                            [
                                5.6835124973882785,
                                45.17202037785066
                            ],
                            [
                                5.684002531947868,
                                45.17240648434404
                            ],
                            [
                                5.68440608982101,
                                45.17298225232298
                            ],
                            [
                                5.684463195815368,
                                45.17372797869197
                            ],
                            [
                                5.684597715106918,
                                45.174296959760625
                            ],
                            [
                                5.685279920083474,
                                45.17496076382281
                            ],
                            [
                                5.685942718490878,
                                45.17564494583681
                            ],
                            [
                                5.68651922973865,
                                45.17606489446672
                            ],
                            [
                                5.6867786597997565,
                                45.17623422771558
                            ],
                            [
                                5.687211043235578,
                                45.1764712934185
                            ],
                            [
                                5.687729903357933,
                                45.176572892702694
                            ],
                            [
                                5.689316631021313,
                                45.17730290990235
                            ],
                            [
                                5.690248657537154,
                                45.17770930001808
                            ],
                            [
                                5.690594564284879,
                                45.17785153587343
                            ],
                            [
                                5.690719475056028,
                                45.17806827554142
                            ],
                            [
                                5.691488156719117,
                                45.1783730643032
                            ],
                            [
                                5.692736424676269,
                                45.17890179954904
                            ],
                            [
                                5.693437846693627,
                                45.179348815964886
                            ],
                            [
                                5.6936108000682,
                                45.17966037078517
                            ],
                            [
                                5.693255284798624,
                                45.18018188264733
                            ],
                            [
                                5.693572365983641,
                                45.18062888901335
                            ],
                            [
                                5.694994427349826,
                                45.18055438834995
                            ],
                            [
                                5.6985618443918895,
                                45.18040061180773
                            ],
                            [
                                5.7006276763608525,
                                45.180339656356324
                            ],
                            [
                                5.701252230212049,
                                45.18042093027668
                            ],
                            [
                                5.701290664295101,
                                45.181836432452855
                            ],
                            [
                                5.701538950218378,
                                45.18594851397546
                            ],
                            [
                                5.701721338142249,
                                45.18950379165673
                            ],
                            [
                                5.700020629963319,
                                45.18946316119428
                            ],
                            [
                                5.69994376179713,
                                45.18991686637955
                            ],
                            [
                                5.701000699084119,
                                45.18999135494505
                            ],
                            [
                                5.701980768203441,
                                45.189943953141864
                            ],
                            [
                                5.7020384094221015,
                                45.19211085547903
                            ],
                            [
                                5.69909820206118,
                                45.192165026902956
                            ],
                            [
                                5.699184678749248,
                                45.19267288149061
                            ],
                            [
                                5.701855847527071,
                                45.19269996694126
                            ],
                            [
                                5.7019999705028965,
                                45.19451531878116
                            ],
                            [
                                5.701327374046684,
                                45.19593724148203
                            ],
                            [
                                5.699716928251519,
                                45.19790899197878
                            ],
                            [
                                5.694048202473539,
                                45.201718338342886
                            ],
                            [
                                5.687811726552184,
                                45.20593904732968
                            ],
                            [
                                5.685237223548768,
                                45.20809123892903
                            ],
                            [
                                5.683671034659909,
                                45.207278889580095
                            ],
                            [
                                5.683248259745994,
                                45.20666961995505
                            ],
                            [
                                5.683008046725632,
                                45.20576924289534
                            ],
                            [
                                5.682663618309363,
                                45.20508080202106
                            ],
                            [
                                5.682163975227809,
                                45.204336109484615
                            ],
                            [
                                5.681251165752798,
                                45.203855439125476
                            ],
                            [
                                5.680194228467343,
                                45.20336122452176
                            ],
                            [
                                5.677216434261254,
                                45.20183160997911
                            ],
                            [
                                5.675179427855028,
                                45.201594649878416
                            ],
                            [
                                5.674823912585396,
                                45.20132383712601
                            ],
                            [
                                5.675611811289286,
                                45.201019171238556
                            ],
                            [
                                5.676044194725051,
                                45.20017963901873
                            ],
                            [
                                5.676139617243621,
                                45.19905339958302
                            ],
                            [
                                5.675284458893941,
                                45.19857268459077
                            ],
                            [
                                5.674948160666617,
                                45.19882996917636
                            ],
                            [
                                5.675534280434704,
                                45.19933776428641
                            ],
                            [
                                5.6755246719143315,
                                45.19992003043515
                            ],
                            [
                                5.675255633331204,
                                45.20048197932334
                            ],
                            [
                                5.6745734283562115,
                                45.20080018888348
                            ],
                            [
                                5.673996917108468,
                                45.20112516702255
                            ],
                            [
                                5.674486958464541,
                                45.20182251227206
                            ],
                            [
                                5.6751115123157945,
                                45.202039160678254
                            ],
                            [
                                5.676668092682803,
                                45.20226257848279
                            ],
                            [
                                5.677494425537731,
                                45.20254692716358
                            ],
                            [
                                5.679416359941428,
                                45.203624515735555
                            ],
                            [
                                5.681261195932279,
                                45.204518156787174
                            ],
                            [
                                5.6817223271187345,
                                45.20502579949667
                            ],
                            [
                                5.682020191263007,
                                45.20546584092952
                            ],
                            [
                                5.682270012802292,
                                45.20608866298525
                            ],
                            [
                                5.682670075512021,
                                45.20715551224228
                            ],
                            [
                                5.683035199301969,
                                45.20758199802205
                            ],
                            [
                                5.6839672258193445,
                                45.20819802739163
                            ],
                            [
                                5.684582171148634,
                                45.20865158321283
                            ],
                            [
                                5.684245866621239,
                                45.209788332395505
                            ],
                            [
                                5.683736615019313,
                                45.21044495338171
                            ],
                            [
                                5.683880740449723,
                                45.21197478454465
                            ],
                            [
                                5.682266508957355,
                                45.21290890816934
                            ],
                            [
                                5.678001374199994,
                                45.215083979643396
                            ],
                            [
                                5.673193160623981,
                                45.21807591446432
                            ],
                            [
                                5.6708295531881845,
                                45.22152344676957
                            ],
                            [
                                5.668524402175137,
                                45.225503616041976
                            ],
                            [
                                5.665206866628836,
                                45.22992138739261
                            ],
                            [
                                5.661300474902703,
                                45.23560007533945
                            ],
                            [
                                5.657955060906517,
                                45.23507001387273
                            ],
                            [
                                5.655055702110047,
                                45.23338163698645
                            ],
                            [
                                5.653243602862432,
                                45.23216440392841
                            ],
                            [
                                5.648225481869247,
                                45.23100604503489
                            ],
                            [
                                5.649842431966391,
                                45.22976912727128
                            ],
                            [
                                5.652184221763491,
                                45.228237667983024
                            ],
                            [
                                5.656059337919828,
                                45.225783313994555
                            ],
                            [
                                5.659321116566247,
                                45.22358412841564
                            ],
                            [
                                5.659919823934075,
                                45.22293774154369
                            ],
                            [
                                5.66023793120354,
                                45.22155976272205
                            ],
                            [
                                5.660571943835691,
                                45.22000249442482
                            ],
                            [
                                5.661112725761683,
                                45.21728678255474
                            ],
                            [
                                5.661637602756002,
                                45.21466497264058
                            ],
                            [
                                5.662035230357105,
                                45.213053036097875
                            ],
                            [
                                5.661224056822732,
                                45.212627248617196
                            ],
                            [
                                5.6605242208314905,
                                45.21259363368043
                            ],
                            [
                                5.658058889497653,
                                45.21214543262363
                            ],
                            [
                                5.656484258518418,
                                45.21171963834837
                            ],
                            [
                                5.6554981259846215,
                                45.2118316897824
                            ],
                            [
                                5.654782384630664,
                                45.212156637693056
                            ],
                            [
                                5.654639236359344,
                                45.2131314702859
                            ],
                            [
                                5.654830100720261,
                                45.214397607213755
                            ],
                            [
                                5.6548619112972744,
                                45.215450832473834
                            ],
                            [
                                5.655243640020274,
                                45.216537650762916
                            ],
                            [
                                5.655052775659357,
                                45.21727712349707
                            ],
                            [
                                5.654065396761638,
                                45.21836698501994
                            ],
                            [
                                5.65299973741142,
                                45.21938652575108
                            ],
                            [
                                5.652204469067584,
                                45.22033882760212
                            ],
                            [
                                5.6519022671622,
                                45.221268706862844
                            ],
                            [
                                5.651329674078255,
                                45.221223893753375
                            ],
                            [
                                5.650677554176696,
                                45.2200027229224
                            ],
                            [
                                5.649866380642237,
                                45.219341711257016
                            ],
                            [
                                5.649659610917325,
                                45.218557450160546
                            ],
                            [
                                5.648689383747552,
                                45.2178740138138
                            ],
                            [
                                5.6472739278570145,
                                45.21694399339896
                            ],
                            [
                                5.646208268506825,
                                45.216607868652005
                            ],
                            [
                                5.645906066601441,
                                45.21615969923178
                            ],
                            [
                                5.646383227504913,
                                45.21517371407424
                            ],
                            [
                                5.646780861590713,
                                45.214445418605465
                            ],
                            [
                                5.646749050863917,
                                45.21401964154947
                            ],
                            [
                                5.646319606051236,
                                45.21381795656819
                            ],
                            [
                                5.645715202240552,
                                45.21358265651941
                            ],
                            [
                                5.645238041337052,
                                45.21328012645489
                            ],
                            [
                                5.644848849205857,
                                45.21268334875606
                            ],
                            [
                                5.64450516511485,
                                45.21188846662562
                            ],
                            [
                                5.644381957610534,
                                45.21135397066996
                            ],
                            [
                                5.644362503793815,
                                45.21093824812067
                            ],
                            [
                                5.643545443502376,
                                45.210988500458484
                            ],
                            [
                                5.643519505080718,
                                45.211609798422785
                            ],
                            [
                                5.643234182439812,
                                45.212591983561765
                            ],
                            [
                                5.642801122957337,
                                45.21337196928678
                            ],
                            [
                                5.642989176515897,
                                45.21459623394202
                            ],
                            [
                                5.642889795160016,
                                45.215253223285515
                            ],
                            [
                                5.642170003950866,
                                45.216089170246164
                            ],
                            [
                                5.641306439009156,
                                45.21696718838683
                            ],
                            [
                                5.641118385450596,
                                45.21750163158708
                            ],
                            [
                                5.640878455357125,
                                45.218483715006215
                            ],
                            [
                                5.640619071143362,
                                45.218456308257174
                            ],
                            [
                                5.6404634406119385,
                                45.21771632106538
                            ],
                            [
                                5.64043234060361,
                                45.216540191398764
                            ],
                            [
                                5.640451794420301,
                                45.215731659143245
                            ],
                            [
                                5.640056666339433,
                                45.214688881762385
                            ],
                            [
                                5.639447113423131,
                                45.21349202825519
                            ],
                            [
                                5.639171455072301,
                                45.21271419097772
                            ],
                            [
                                5.639262239549481,
                                45.211928445912406
                            ],
                            [
                                5.639171455072301,
                                45.21127974122436
                            ],
                            [
                                5.639698366628892,
                                45.2105273969575
                            ],
                            [
                                5.640781295745711,
                                45.209718779213375
                            ],
                            [
                                5.6414686639876095,
                                45.20901065813692
                            ],
                            [
                                5.641920295849474,
                                45.20846536866486
                            ],
                            [
                                5.641583096363746,
                                45.20760646632377
                            ],
                            [
                                5.641388558198912,
                                45.20680694451036
                            ],
                            [
                                5.641155112401776,
                                45.206318088494214
                            ],
                            [
                                5.640467741418007,
                                45.205861209057076
                            ],
                            [
                                5.639067066632151,
                                45.20544087752319
                            ],
                            [
                                5.637912806854814,
                                45.20498856077273
                            ],
                            [
                                5.637440989899346,
                                45.20448204696294
                            ],
                            [
                                5.637097305808339,
                                45.20371446407995
                            ],
                            [
                                5.636306183938757,
                                45.20342661782837
                            ],
                            [
                                5.635469669135119,
                                45.20293773211753
                            ],
                            [
                                5.633796640919769,
                                45.20246711916056
                            ],
                            [
                                5.633245449452488,
                                45.20184115220451
                            ],
                            [
                                5.632648866525955,
                                45.200698858713736
                            ],
                            [
                                5.632376513095437,
                                45.19999976395147
                            ],
                            [
                                5.6321690057191915,
                                45.19919099654919
                            ],
                            [
                                5.6322468206919325,
                                45.1987477681086
                            ],
                            [
                                5.632862858212405,
                                45.198779753727706
                            ],
                            [
                                5.634522917217339,
                                45.19748203703702
                            ],
                            [
                                5.635832921587337,
                                45.19610203448411
                            ],
                            [
                                5.637731914530093,
                                45.19436994477175
                            ],
                            [
                                5.639513591679474,
                                45.19480344401234
                            ],
                            [
                                5.642922355935212,
                                45.19602674187723
                            ],
                            [
                                5.647185141031002,
                                45.19829901480682
                            ],
                            [
                                5.6482450712154275,
                                45.199649137773804
                            ],
                            [
                                5.650833746859206,
                                45.200510901640854
                            ],
                            [
                                5.65097642976923,
                                45.19953423493894
                            ],
                            [
                                5.651486011588702,
                                45.19730794670761
                            ],
                            [
                                5.65171022758895,
                                45.19556994503196
                            ],
                            [
                                5.651404452917291,
                                45.193027478126766
                            ],
                            [
                                5.651852884918526,
                                45.19179211674671
                            ],
                            [
                                5.651261770007238,
                                45.1904992679828
                            ],
                            [
                                5.65170622638783,
                                45.18928437268673
                            ],
                            [
                                5.652256574752812,
                                45.18669853186293
                            ],
                            [
                                5.649892115110049,
                                45.18724444137456
                            ],
                            [
                                5.649219467107798,
                                45.18605205330209
                            ],
                            [
                                5.646993410321016,
                                45.18656717313726
                            ]
                        ],
                        [
                            [
                                5.63230031386064,
                                45.204738478684504
                            ],
                            [
                                5.633037731309059,
                                45.205011933967
                            ],
                            [
                                5.633872177368886,
                                45.20524436992315
                            ],
                            [
                                5.634318508982261,
                                45.20553149538185
                            ],
                            [
                                5.634779064780105,
                                45.206105125297285
                            ],
                            [
                                5.6351733914135025,
                                45.2062888487699
                            ],
                            [
                                5.635720360524829,
                                45.206176822337994
                            ],
                            [
                                5.635554997770214,
                                45.20581833627429
                            ],
                            [
                                5.635097070142336,
                                45.20561220576474
                            ],
                            [
                                5.6349189871763485,
                                45.20504310243675
                            ],
                            [
                                5.634505580290238,
                                45.20476078922459
                            ],
                            [
                                5.633838252516341,
                                45.20446020768915
                            ],
                            [
                                5.633634729126641,
                                45.20411067325753
                            ],
                            [
                                5.634283459932817,
                                45.20376113667757
                            ],
                            [
                                5.635173874763808,
                                45.20365806778898
                            ],
                            [
                                5.635612722073574,
                                45.203554998713685
                            ],
                            [
                                5.636312333727204,
                                45.203900055318826
                            ],
                            [
                                5.636802061884424,
                                45.204227184973604
                            ],
                            [
                                5.6371773013994755,
                                45.20490384593697
                            ],
                            [
                                5.63751438701442,
                                45.2052309698189
                            ],
                            [
                                5.638563804494339,
                                45.20562530843628
                            ],
                            [
                                5.640028593404651,
                                45.20608006115526
                            ],
                            [
                                5.640709124761742,
                                45.20646095077018
                            ],
                            [
                                5.640957168893152,
                                45.20690905293449
                            ],
                            [
                                5.641033490164318,
                                45.207581199562384
                            ],
                            [
                                5.641440535233841,
                                45.208186124793855
                            ],
                            [
                                5.6410462086713835,
                                45.20884929465069
                            ],
                            [
                                5.640034950675727,
                                45.20974097699752
                            ],
                            [
                                5.638769289593739,
                                45.210879083960066
                            ],
                            [
                                5.638705565395725,
                                45.211172402666136
                            ],
                            [
                                5.6388772882560545,
                                45.21207749042736
                            ],
                            [
                                5.63879460687923,
                                45.21278093977952
                            ],
                            [
                                5.638807328187397,
                                45.21336681784513
                            ],
                            [
                                5.639328856875068,
                                45.21410161431996
                            ],
                            [
                                5.6396473608236874,
                                45.21538309065994
                            ],
                            [
                                5.639984446438632,
                                45.21617162439955
                            ],
                            [
                                5.639882598705043,
                                45.216789899025514
                            ],
                            [
                                5.6398825987257055,
                                45.217314083019204
                            ],
                            [
                                5.6400225210555845,
                                45.21774865923177
                            ],
                            [
                                5.640283407117664,
                                45.218667169561
                            ],
                            [
                                5.640404249130597,
                                45.21935261731031
                            ],
                            [
                                5.640410609236227,
                                45.2198498977603
                            ],
                            [
                                5.640754054957881,
                                45.219984297134744
                            ],
                            [
                                5.641218342691388,
                                45.21993501740138
                            ],
                            [
                                5.6412819437364305,
                                45.21922269647405
                            ],
                            [
                                5.641364625113255,
                                45.218111637216055
                            ],
                            [
                                5.641690486394083,
                                45.21729360298119
                            ],
                            [
                                5.642523660271934,
                                45.21640651925816
                            ],
                            [
                                5.643303889275472,
                                45.215555379985034
                            ],
                            [
                                5.643424731794568,
                                45.21518752349829
                            ],
                            [
                                5.64335477062906,
                                45.21453338837125
                            ],
                            [
                                5.6433102498873495,
                                45.21384788252763
                            ],
                            [
                                5.643334101075766,
                                45.21325976628023
                            ],
                            [
                                5.643518544148463,
                                45.212668336717684
                            ],
                            [
                                5.643728427644845,
                                45.21217547405146
                            ],
                            [
                                5.643868348804261,
                                45.21180358387713
                            ],
                            [
                                5.644046431771187,
                                45.21186631250052
                            ],
                            [
                                5.64416727378412,
                                45.21229644976691
                            ],
                            [
                                5.644472558868671,
                                45.21285203892165
                            ],
                            [
                                5.644815863109102,
                                45.213454047930696
                            ],
                            [
                                5.64503210671117,
                                45.21378112264202
                            ],
                            [
                                5.645369192326115,
                                45.21395137996652
                            ],
                            [
                                5.645928881648814,
                                45.21410819547222
                            ],
                            [
                                5.64628504758079,
                                45.21425156927029
                            ],
                            [
                                5.646335928428215,
                                45.214453188062805
                            ],
                            [
                                5.646137567444441,
                                45.21487850371301
                            ],
                            [
                                5.645507916956319,
                                45.216047869866685
                            ],
                            [
                                5.64539982150572,
                                45.21635702977758
                            ],
                            [
                                5.645488862988174,
                                45.21661688424447
                            ],
                            [
                                5.6457369071196695,
                                45.21691705947856
                            ],
                            [
                                5.646143953900065,
                                45.21706042619499
                            ],
                            [
                                5.646659122481026,
                                45.217163470799335
                            ],
                            [
                                5.647221179071494,
                                45.217454969049214
                            ],
                            [
                                5.647908070512756,
                                45.21807770970918
                            ],
                            [
                                5.648972525452962,
                                45.21885155639458
                            ],
                            [
                                5.649290530749909,
                                45.21920996031503
                            ],
                            [
                                5.64950041424629,
                                45.219649002039006
                            ],
                            [
                                5.649988604028266,
                                45.220135156393184
                            ],
                            [
                                5.650370210385006,
                                45.2206861890472
                            ],
                            [
                                5.6511021179524334,
                                45.2219839126077
                            ],
                            [
                                5.651197519540602,
                                45.22264692155659
                            ],
                            [
                                5.651057597210695,
                                45.223359199581665
                            ],
                            [
                                5.650675878987812,
                                45.22401809657654
                            ],
                            [
                                5.650459635385744,
                                45.22445262155031
                            ],
                            [
                                5.650535956707984,
                                45.22482890860235
                            ],
                            [
                                5.65100660463213,
                                45.22498569418846
                            ],
                            [
                                5.651483612578005,
                                45.22491402086621
                            ],
                            [
                                5.65141365129719,
                                45.22445262164581
                            ],
                            [
                                5.651509052927167,
                                45.22402101947236
                            ],
                            [
                                5.651909739730712,
                                45.223452100326654
                            ],
                            [
                                5.652418548360998,
                                45.22292797289563
                            ],
                            [
                                5.652585867235729,
                                45.2222292392664
                            ],
                            [
                                5.652522266176959,
                                45.221772298137495
                            ],
                            [
                                5.652458665229943,
                                45.22121231623183
                            ],
                            [
                                5.652643108421415,
                                45.22060752954607
                            ],
                            [
                                5.653221873763471,
                                45.2198772927311
                            ],
                            [
                                5.654074127959348,
                                45.21922769158678
                            ],
                            [
                                5.6546401773877335,
                                45.218721445548596
                            ],
                            [
                                5.65561563972409,
                                45.217549963491166
                            ],
                            [
                                5.655882764173555,
                                45.21700786086055
                            ],
                            [
                                5.65581916311379,
                                45.216479193812916
                            ],
                            [
                                5.655482076573463,
                                45.21591961760433
                            ],
                            [
                                5.65531671381882,
                                45.21552087002891
                            ],
                            [
                                5.655349853004594,
                                45.214846931938524
                            ],
                            [
                                5.655311692368514,
                                45.21415246900807
                            ],
                            [
                                5.655181274556952,
                                45.21334850467383
                            ],
                            [
                                5.655181274556952,
                                45.21275707603411
                            ],
                            [
                                5.655391158053334,
                                45.21243895657105
                            ],
                            [
                                5.655696443137913,
                                45.212394150869585
                            ],
                            [
                                5.6558554457868695,
                                45.212479281671705
                            ],
                            [
                                5.656192531401814,
                                45.21244791770667
                            ],
                            [
                                5.656408775003882,
                                45.212286617042906
                            ],
                            [
                                5.656936663796216,
                                45.212340383981626
                            ],
                            [
                                5.6575281536492525,
                                45.2125868151341
                            ],
                            [
                                5.658554143256623,
                                45.212807196399154
                            ],
                            [
                                5.659533599572143,
                                45.21291472924156
                            ],
                            [
                                5.660258651648462,
                                45.21300433978823
                            ],
                            [
                                5.660824701077814,
                                45.21311635277354
                            ],
                            [
                                5.660945543331195,
                                45.21346135140246
                            ],
                            [
                                5.661193587462662,
                                45.213779465146615
                            ],
                            [
                                5.66109818587347,
                                45.21439776564415
                            ],
                            [
                                5.660852473695314,
                                45.215590889734244
                            ],
                            [
                                5.660410149348621,
                                45.21757621244981
                            ],
                            [
                                5.660020147218319,
                                45.21972108500839
                            ],
                            [
                                5.6598993052054425,
                                45.2203214011173
                            ],
                            [
                                5.659611843321841,
                                45.22163822017751
                            ],
                            [
                                5.65940831993214,
                                45.222377389558744
                            ],
                            [
                                5.659090931211551,
                                45.222964873351145
                            ],
                            [
                                5.658531241887857,
                                45.22347556130569
                            ],
                            [
                                5.657933391930129,
                                45.223869773431346
                            ],
                            [
                                5.656278185569192,
                                45.22497502286569
                            ],
                            [
                                5.654955283533155,
                                45.22577238262468
                            ],
                            [
                                5.653078906243309,
                                45.226856919037346
                            ],
                            [
                                5.6510180032977075,
                                45.228214063850174
                            ],
                            [
                                5.648979046382664,
                                45.22946105094752
                            ],
                            [
                                5.646209822628492,
                                45.23124273794909
                            ],
                            [
                                5.647746970625178,
                                45.23165830051241
                            ],
                            [
                                5.650248232004429,
                                45.232366775537486
                            ],
                            [
                                5.652798140105546,
                                45.232999998275574
                            ],
                            [
                                5.653618112844015,
                                45.23338819569793
                            ],
                            [
                                5.654479428746299,
                                45.234096649158346
                            ],
                            [
                                5.655600934700885,
                                45.235336405305986
                            ],
                            [
                                5.656434688494329,
                                45.23559357582121
                            ],
                            [
                                5.657926180990671,
                                45.23626786701473
                            ],
                            [
                                5.658422298950342,
                                45.23641343298806
                            ],
                            [
                                5.65433803287695,
                                45.24212856516121
                            ],
                            [
                                5.6499640337223695,
                                45.24763176240816
                            ],
                            [
                                5.6425935671140905,
                                45.25330332265088
                            ],
                            [
                                5.637279974914122,
                                45.255776910919195
                            ],
                            [
                                5.6305455106584645,
                                45.26096506400239
                            ],
                            [
                                5.624717699859502,
                                45.26684640737261
                            ],
                            [
                                5.621714337541363,
                                45.270774002575024
                            ],
                            [
                                5.619700314691869,
                                45.275206921105905
                            ],
                            [
                                5.618736246972503,
                                45.27929039912624
                            ],
                            [
                                5.618521989222046,
                                45.283602051471064
                            ],
                            [
                                5.617242236769698,
                                45.288390832898756
                            ],
                            [
                                5.615999541819576,
                                45.29420904523434
                            ],
                            [
                                5.61257141782022,
                                45.295053088638525
                            ],
                            [
                                5.608371965920725,
                                45.29674113776085
                            ],
                            [
                                5.602929819071022,
                                45.29942382672351
                            ],
                            [
                                5.595645055571481,
                                45.28745624574995
                            ],
                            [
                                5.596930602350625,
                                45.28588851662187
                            ],
                            [
                                5.599587398449785,
                                45.28543627900689
                            ],
                            [
                                5.599116031399802,
                                45.28314488635027
                            ],
                            [
                                5.594521995205469,
                                45.27903727917061
                            ],
                            [
                                5.589636918506329,
                                45.274664969919655
                            ],
                            [
                                5.586423052256663,
                                45.27125735051291
                            ],
                            [
                                5.5806380930067405,
                                45.267547939300215
                            ],
                            [
                                5.579224134654851,
                                45.26597965218221
                            ],
                            [
                                5.577295814904346,
                                45.26576853435603
                            ],
                            [
                                5.573610581604669,
                                45.2650748559692
                            ],
                            [
                                5.571468004105384,
                                45.26332554239701
                            ],
                            [
                                5.573353472305172,
                                45.26127455439172
                            ],
                            [
                                5.5743390579549725,
                                45.25901234934099
                            ],
                            [
                                5.573353472305172,
                                45.25717235609997
                            ],
                            [
                                5.573260677188927,
                                45.25381241065659
                            ],
                            [
                                5.570603881089028,
                                45.250614714338184
                            ],
                            [
                                5.570860990375195,
                                45.24934765277908
                            ],
                            [
                                5.573817747261444,
                                45.246934123854714
                            ],
                            [
                                5.5745890751616685,
                                45.24427912376146
                            ],
                            [
                                5.575874621661683,
                                45.24421878140822
                            ],
                            [
                                5.577588683660991,
                                45.242559341574236
                            ],
                            [
                                5.5807168469699775,
                                45.243555010990065
                            ],
                            [
                                5.582773721369449,
                                45.24319295129382
                            ],
                            [
                                5.5847448926689935,
                                45.24379638283918
                            ],
                            [
                                5.586544658048524,
                                45.24268001534759
                            ],
                            [
                                5.5872731343980035,
                                45.24080931993515
                            ],
                            [
                                5.5876159467981665,
                                45.239662734237584
                            ],
                            [
                                5.589804640700038,
                                45.23775556378939
                            ],
                            [
                                5.592188735750966,
                                45.23645710221973
                            ],
                            [
                                5.594516953573418,
                                45.23548651560833
                            ],
                            [
                                5.595969761495354,
                                45.235434050994456
                            ],
                            [
                                5.598428359516447,
                                45.23511926229676
                            ],
                            [
                                5.599658177908196,
                                45.23344034263178
                            ],
                            [
                                5.601353120484845,
                                45.232063080887144
                            ],
                            [
                                5.601651132860383,
                                45.230764489161885
                            ],
                            [
                                5.602805372080525,
                                45.22856149418752
                            ],
                            [
                                5.603606279010961,
                                45.22740712085567
                            ],
                            [
                                5.605431601785341,
                                45.226292078901565
                            ],
                            [
                                5.605841368121389,
                                45.225426266534384
                            ],
                            [
                                5.609614022866339,
                                45.22302185556134
                            ],
                            [
                                5.6096326488437285,
                                45.22138195389033
                            ],
                            [
                                5.610321801355411,
                                45.221158923607504
                            ],
                            [
                                5.610787444919964,
                                45.22268075988501
                            ],
                            [
                                5.611998118187955,
                                45.2230480935728
                            ],
                            [
                                5.614326336010436,
                                45.22324487850088
                            ],
                            [
                                5.61592814987273,
                                45.2219592046593
                            ],
                            [
                                5.618461243467863,
                                45.21959765577256
                            ],
                            [
                                5.621236479113065,
                                45.218849821450846
                            ],
                            [
                                5.622801041490192,
                                45.218666141340776
                            ],
                            [
                                5.6228569187171615,
                                45.22020116401811
                            ],
                            [
                                5.62309905337105,
                                45.221093294908
                            ],
                            [
                                5.6242352236688475,
                                45.22102769752536
                            ],
                            [
                                5.62429110089721,
                                45.21835125977202
                            ],
                            [
                                5.622745164261829,
                                45.215346677105344
                            ],
                            [
                                5.621326779309271,
                                45.211956663050074
                            ],
                            [
                                5.620246486238415,
                                45.21042141780768
                            ],
                            [
                                5.621401282279464,
                                45.20846621643727
                            ],
                            [
                                5.622593329804175,
                                45.20815127838824
                            ],
                            [
                                5.624176517924639,
                                45.20908296506562
                            ],
                            [
                                5.626802748335621,
                                45.20664217569271
                            ],
                            [
                                5.6293172230215305,
                                45.20606476966114
                            ],
                            [
                                5.629801492329335,
                                45.207862583536894
                            ],
                            [
                                5.630881795845454,
                                45.205146136235385
                            ],
                            [
                                5.63230031386064,
                                45.204738478684504
                            ]
                        ],
                        [
                            [
                                5.665356864704023,
                                45.24472104185173
                            ],
                            [
                                5.668282498859753,
                                45.24154108199022
                            ],
                            [
                                5.667330444848602,
                                45.240691021045365
                            ],
                            [
                                5.6669867228019655,
                                45.23998745553055
                            ],
                            [
                                5.666435168821636,
                                45.23935705342868
                            ],
                            [
                                5.665731737658376,
                                45.23886173258492
                            ],
                            [
                                5.664642654697758,
                                45.23782317982173
                            ],
                            [
                                5.664067120109337,
                                45.23718149603519
                            ],
                            [
                                5.663955210605707,
                                45.23674244505841
                            ],
                            [
                                5.663507572593488,
                                45.235779898354
                            ],
                            [
                                5.663948280697099,
                                45.2344419772443
                            ],
                            [
                                5.664771614899706,
                                45.23273070255752
                            ],
                            [
                                5.666435977524941,
                                45.23013756536767
                            ],
                            [
                                5.667867347467734,
                                45.228140576926535
                            ],
                            [
                                5.669346151617418,
                                45.22638407326676
                            ],
                            [
                                5.6704939244869195,
                                45.224963670834455
                            ],
                            [
                                5.672348423436972,
                                45.22217672790072
                            ],
                            [
                                5.674256521220258,
                                45.2200234583855
                            ],
                            [
                                5.675719338298734,
                                45.21874531227337
                            ],
                            [
                                5.677785359526638,
                                45.217446905537145
                            ],
                            [
                                5.679440021468764,
                                45.21660228054287
                            ],
                            [
                                5.681226284405511,
                                45.215958997218394
                            ],
                            [
                                5.682706961222777,
                                45.215347075450694
                            ],
                            [
                                5.683636077107849,
                                45.214736291354825
                            ],
                            [
                                5.684447007443538,
                                45.21419180537535
                            ],
                            [
                                5.686525016430124,
                                45.213415234384655
                            ],
                            [
                                5.68931792300495,
                                45.21245551922087
                            ],
                            [
                                5.692624998282497,
                                45.21175926239562
                            ],
                            [
                                5.694855056706842,
                                45.2112326009304
                            ],
                            [
                                5.696956239482432,
                                45.21069288621214
                            ],
                            [
                                5.6990849316149195,
                                45.2099698276993
                            ],
                            [
                                5.701889408029331,
                                45.209066753483995
                            ],
                            [
                                5.7038026967912,
                                45.2081026458612
                            ],
                            [
                                5.707337577414847,
                                45.206678862743615
                            ],
                            [
                                5.709504281906305,
                                45.205955753203625
                            ],
                            [
                                5.711151484151628,
                                45.20527727169616
                            ],
                            [
                                5.712279168314524,
                                45.20467019534257
                            ],
                            [
                                5.714319164942225,
                                45.203134638295495
                            ],
                            [
                                5.715877671681653,
                                45.201902591626634
                            ],
                            [
                                5.716974540178711,
                                45.20031855308861
                            ],
                            [
                                5.716987210964703,
                                45.19917572949632
                            ],
                            [
                                5.716325183583166,
                                45.197562903070065
                            ],
                            [
                                5.716236488077328,
                                45.196714674802394
                            ],
                            [
                                5.7165152453809185,
                                45.19607179863971
                            ],
                            [
                                5.716984064481096,
                                45.1952681932232
                            ],
                            [
                                5.7174402127953385,
                                45.19447350559631
                            ],
                            [
                                5.7182891554909645,
                                45.19408955141793
                            ],
                            [
                                5.7188466700970935,
                                45.19416991413485
                            ],
                            [
                                5.719454866999399,
                                45.1938573917933
                            ],
                            [
                                5.718821327673453,
                                45.19329484730372
                            ],
                            [
                                5.717313504079897,
                                45.193714523878555
                            ],
                            [
                                5.7165152445302,
                                45.19428599338633
                            ],
                            [
                                5.715666301834574,
                                45.19577714461073
                            ],
                            [
                                5.715246315011058,
                                45.19678461477858
                            ],
                            [
                                5.715597596170539,
                                45.199255064707046
                            ],
                            [
                                5.715673620889419,
                                45.20037110207835
                            ],
                            [
                                5.714483589775256,
                                45.20184616887147
                            ],
                            [
                                5.712266202137869,
                                45.203658519931054
                            ],
                            [
                                5.710391194173411,
                                45.20481891521828
                            ],
                            [
                                5.708097781801172,
                                45.20565809531021
                            ],
                            [
                                5.7057410155120465,
                                45.206229444871866
                            ],
                            [
                                5.702441444477955,
                                45.207354278346656
                            ],
                            [
                                5.701805763161417,
                                45.20608357768671
                            ],
                            [
                                5.700830066255463,
                                45.20448995395037
                            ],
                            [
                                5.70023873479758,
                                45.2048024327116
                            ],
                            [
                                5.699706536485365,
                                45.20496908734904
                            ],
                            [
                                5.6994700039026895,
                                45.20535447432778
                            ],
                            [
                                5.699248254605834,
                                45.205729442936644
                            ],
                            [
                                5.69847619437229,
                                45.206376046239
                            ],
                            [
                                5.697014282026942,
                                45.20753146504592
                            ],
                            [
                                5.695641545262902,
                                45.2084269767642
                            ],
                            [
                                5.694563974908988,
                                45.209178325667466
                            ],
                            [
                                5.693841891682268,
                                45.20943659956066
                            ],
                            [
                                5.692452417470008,
                                45.20965634199922
                            ],
                            [
                                5.690563892107747,
                                45.20989896092354
                            ],
                            [
                                5.688275443727491,
                                45.21016505790891
                            ],
                            [
                                5.687164546455904,
                                45.21014157881294
                            ],
                            [
                                5.687075674674304,
                                45.20928067194541
                            ],
                            [
                                5.687819975846423,
                                45.20791884720046
                            ],
                            [
                                5.689297467891407,
                                45.206517146521634
                            ],
                            [
                                5.69110823046509,
                                45.20513177445059
                            ],
                            [
                                5.693030082745025,
                                45.20385594998842
                            ],
                            [
                                5.697417958207808,
                                45.20075753703219
                            ],
                            [
                                5.7011577130400894,
                                45.198137689566124
                            ],
                            [
                                5.70246857186504,
                                45.196853880037395
                            ],
                            [
                                5.703362147272628,
                                45.19475613834294
                            ],
                            [
                                5.703539891642492,
                                45.19274418431971
                            ],
                            [
                                5.7049729491227765,
                                45.19230577204948
                            ],
                            [
                                5.7061838271490615,
                                45.19171077856632
                            ],
                            [
                                5.706572641194867,
                                45.19137413476167
                            ],
                            [
                                5.705739468240296,
                                45.19089656687811
                            ],
                            [
                                5.704795205559606,
                                45.19037984957245
                            ],
                            [
                                5.704317519732257,
                                45.18999622308465
                            ],
                            [
                                5.703706523635361,
                                45.18941686239296
                            ],
                            [
                                5.703162183972694,
                                45.1891428391007
                            ],
                            [
                                5.703039985272596,
                                45.187514330603506
                            ],
                            [
                                5.703107796144991,
                                45.18400514840965
                            ],
                            [
                                5.703318199522812,
                                45.18157429077627
                            ],
                            [
                                5.703429289249783,
                                45.180493699567506
                            ],
                            [
                                5.70302936623176,
                                45.179123355134095
                            ],
                            [
                                5.702851623035912,
                                45.17647619545602
                            ],
                            [
                                5.702496135894677,
                                45.17149314580206
                            ],
                            [
                                5.702481901866605,
                                45.16822905804395
                            ],
                            [
                                5.702234028857077,
                                45.163237701023974
                            ],
                            [
                                5.702131044221886,
                                45.15978380387017
                            ],
                            [
                                5.703308595330526,
                                45.15900830257323
                            ],
                            [
                                5.705708133437781,
                                45.158945635340956
                            ],
                            [
                                5.707729966472414,
                                45.1589926357716
                            ],
                            [
                                5.709925690028911,
                                45.15884379500761
                            ],
                            [
                                5.71125876675444,
                                45.15830328630139
                            ],
                            [
                                5.712536298617806,
                                45.15736325894747
                            ],
                            [
                                5.713636110018399,
                                45.15636364840131
                            ],
                            [
                                5.714846988044712,
                                45.15492221772058
                            ],
                            [
                                5.716702182801384,
                                45.15352775226617
                            ],
                            [
                                5.718979522208514,
                                45.15272866304096
                            ],
                            [
                                5.721565773353404,
                                45.151953562891634
                            ],
                            [
                                5.724087510160757,
                                45.15135031324283
                            ],
                            [
                                5.725964926550745,
                                45.15109177572387
                            ],
                            [
                                5.728401340412773,
                                45.1509119924346
                            ],
                            [
                                5.731145256674978,
                                45.150990337508006
                            ],
                            [
                                5.733178198681827,
                                45.15116269629104
                            ],
                            [
                                5.733700318754103,
                                45.15138989243377
                            ],
                            [
                                5.7343557481437415,
                                45.15143689909658
                            ],
                            [
                                5.73557773514321,
                                45.15147607128628
                            ],
                            [
                                5.737477369477716,
                                45.151867791700084
                            ],
                            [
                                5.739377409684579,
                                45.15247122611456
                            ],
                            [
                                5.741343697856138,
                                45.15323898218725
                            ],
                            [
                                5.744276466111103,
                                45.1547666282963
                            ],
                            [
                                5.745731741536787,
                                45.1558790430056
                            ],
                            [
                                5.747044589711891,
                                45.15703340908556
                            ],
                            [
                                5.748666499728444,
                                45.15816144657941
                            ],
                            [
                                5.750771138030132,
                                45.16001143402593
                            ],
                            [
                                5.75300404154649,
                                45.16201671376541
                            ],
                            [
                                5.755268216695782,
                                45.164231780787134
                            ],
                            [
                                5.7571392052431065,
                                45.16622378360745
                            ],
                            [
                                5.757672435933557,
                                45.166858205098436
                            ],
                            [
                                5.758861096014442,
                                45.16804870536822
                            ],
                            [
                                5.760337738654528,
                                45.16962234663981
                            ],
                            [
                                5.762170719152834,
                                45.17157246641193
                            ],
                            [
                                5.763635759377735,
                                45.17276097215111
                            ],
                            [
                                5.765057707885774,
                                45.17402966244603
                            ],
                            [
                                5.766121882056183,
                                45.17475662889785
                            ],
                            [
                                5.768543638108838,
                                45.17628369912518
                            ],
                            [
                                5.771248205117928,
                                45.17749638894344
                            ],
                            [
                                5.773603307334383,
                                45.1786944975517
                            ],
                            [
                                5.774869730223742,
                                45.17939142494794
                            ],
                            [
                                5.776322866404314,
                                45.180402169598636
                            ],
                            [
                                5.777400436758256,
                                45.1813574774572
                            ],
                            [
                                5.77644506510444,
                                45.18195257916801
                            ],
                            [
                                5.776989404767988,
                                45.182704277700736
                            ],
                            [
                                5.777689270049308,
                                45.18324455489076
                            ],
                            [
                                5.7795693103551,
                                45.1836535699517
                            ],
                            [
                                5.7801025410455225,
                                45.184624483010936
                            ],
                            [
                                5.781046849571283,
                                45.186268381778774
                            ],
                            [
                                5.781313464916934,
                                45.18721576168471
                            ],
                            [
                                5.781362218293111,
                                45.188301454630675
                            ],
                            [
                                5.781439981102409,
                                45.189217483725855
                            ],
                            [
                                5.781562179802563,
                                45.191378314468096
                            ],
                            [
                                5.781752958510083,
                                45.193641350252875
                            ],
                            [
                                5.781486343165284,
                                45.19527751185947
                            ],
                            [
                                5.780800047407126,
                                45.19710993780228
                            ],
                            [
                                5.780277925689006,
                                45.19803365316275
                            ],
                            [
                                5.7801335090434804,
                                45.199043460424775
                            ],
                            [
                                5.78024374878845,
                                45.20019474932383
                            ],
                            [
                                5.780910287152125,
                                45.20143151807858
                            ],
                            [
                                5.781287351498406,
                                45.20283248275919
                            ],
                            [
                                5.780454178543778,
                                45.20333343239463
                            ],
                            [
                                5.778032422491179,
                                45.20315340412708
                            ],
                            [
                                5.770586726165675,
                                45.20231183690581
                            ],
                            [
                                5.76772216097288,
                                45.20190319833068
                            ],
                            [
                                5.76350075133962,
                                45.201206546532035
                            ],
                            [
                                5.760585442766683,
                                45.200563454906586
                            ],
                            [
                                5.757752654723731,
                                45.199678919660414
                            ],
                            [
                                5.756752847179143,
                                45.20038341787375
                            ],
                            [
                                5.75858582767745,
                                45.20099397593617
                            ],
                            [
                                5.760463244066557,
                                45.201299252510694
                            ],
                            [
                                5.764373602464275,
                                45.20216028017617
                            ],
                            [
                                5.767040520935751,
                                45.2025600122449
                            ],
                            [
                                5.77065093706932,
                                45.203045309595865
                            ],
                            [
                                5.776661985180425,
                                45.20383072016676
                            ],
                            [
                                5.782319705026765,
                                45.204773301118365
                            ],
                            [
                                5.78336394846221,
                                45.20593952788204
                            ],
                            [
                                5.7847303522312075,
                                45.20614302813928
                            ],
                            [
                                5.786152300739246,
                                45.206017797329224
                            ],
                            [
                                5.787196544174634,
                                45.205634276257456
                            ],
                            [
                                5.78961830868289,
                                45.20598649196947
                            ],
                            [
                                5.7930509812532875,
                                45.2065891630464
                            ],
                            [
                                5.79471732716155,
                                45.207074426026196
                            ],
                            [
                                5.798707248877719,
                                45.207998191047835
                            ],
                            [
                                5.80186219713022,
                                45.20897651535526
                            ],
                            [
                                5.804306807628592,
                                45.20989220445202
                            ],
                            [
                                5.807017396971389,
                                45.21107397874903
                            ],
                            [
                                5.809025961584155,
                                45.212197325064466
                            ],
                            [
                                5.809125942338881,
                                45.212447759200046
                            ],
                            [
                                5.806848602931751,
                                45.21229123799429
                            ],
                            [
                                5.806426461968329,
                                45.21247123734332
                            ],
                            [
                                5.804582372496924,
                                45.214505972973626
                            ],
                            [
                                5.803240757288677,
                                45.21553679204919
                            ],
                            [
                                5.8025742189258835,
                                45.216405429725654
                            ],
                            [
                                5.802663090707512,
                                45.21743056162393
                            ],
                            [
                                5.802878549027298,
                                45.21859035010942
                            ],
                            [
                                5.802645260600116,
                                45.21999886941779
                            ],
                            [
                                5.801623235110128,
                                45.220476193045414
                            ],
                            [
                                5.79906411561808,
                                45.221514695267956
                            ],
                            [
                                5.798664192600086,
                                45.22147557124558
                            ],
                            [
                                5.798264269582063,
                                45.22178073790437
                            ],
                            [
                                5.79644239805603,
                                45.2225240857301
                            ],
                            [
                                5.795342609756716,
                                45.22383861388417
                            ],
                            [
                                5.795175573883,
                                45.22486342492056
                            ],
                            [
                                5.794642343192578,
                                45.226647346639965
                            ],
                            [
                                5.7921981751844385,
                                45.22932854944497
                            ],
                            [
                                5.790631810030902,
                                45.23102627268452
                            ],
                            [
                                5.7876432564173115,
                                45.2338506548777
                            ],
                            [
                                5.782515116043697,
                                45.23867906687863
                            ],
                            [
                                5.778698947071689,
                                45.24219862472248
                            ],
                            [
                                5.775843941082485,
                                45.24030568019492
                            ],
                            [
                                5.772098109496085,
                                45.23786922027301
                            ],
                            [
                                5.768836314595433,
                                45.235932992615915
                            ],
                            [
                                5.765425859970435,
                                45.234947309111504
                            ],
                            [
                                5.762102147016861,
                                45.23454339127517
                            ],
                            [
                                5.761557807353313,
                                45.23534915542777
                            ],
                            [
                                5.760577730885728,
                                45.23689452625797
                            ],
                            [
                                5.7610109801495355,
                                45.23906179290117
                            ],
                            [
                                5.762176363551617,
                                45.241615763005655
                            ],
                            [
                                5.762576286569697,
                                45.24389192952492
                            ],
                            [
                                5.7622652353332455,
                                45.24476795556572
                            ],
                            [
                                5.763687183841284,
                                45.24511992650966
                            ],
                            [
                                5.762232053033131,
                                45.24621493103999
                            ],
                            [
                                5.760361176995929,
                                45.249621217956815
                            ],
                            [
                                5.759939036032534,
                                45.25044240993549
                            ],
                            [
                                5.760116779596586,
                                45.250864732616066
                            ],
                            [
                                5.7591206621629,
                                45.25164160429989
                            ],
                            [
                                5.757798694409587,
                                45.25283815180697
                            ],
                            [
                                5.7579989121375945,
                                45.2547510039586
                            ],
                            [
                                5.757832277546697,
                                45.255673783146335
                            ],
                            [
                                5.759264101929887,
                                45.25915710267711
                            ],
                            [
                                5.758880672342684,
                                45.260523672913735
                            ],
                            [
                                5.759920585405752,
                                45.261621629969596
                            ],
                            [
                                5.760913229693273,
                                45.26255321324527
                            ],
                            [
                                5.761338648673785,
                                45.263384971109616
                            ],
                            [
                                5.761007767245218,
                                45.26511498843411
                            ],
                            [
                                5.760960498468762,
                                45.26631266185143
                            ],
                            [
                                5.758975209893748,
                                45.26627939348663
                            ],
                            [
                                5.756848114992124,
                                45.2659799773256
                            ],
                            [
                                5.754626482539578,
                                45.266479003383495
                            ],
                            [
                                5.755240976622105,
                                45.267676648022615
                            ],
                            [
                                5.7554773205005745,
                                45.2695728670235
                            ],
                            [
                                5.75231031253503,
                                45.267610112872376
                            ],
                            [
                                5.751648549676986,
                                45.26950633409595
                            ],
                            [
                                5.750844980272262,
                                45.26840852976824
                            ],
                            [
                                5.749852335984713,
                                45.26924020184455
                            ],
                            [
                                5.749710529658216,
                                45.26980573189519
                            ],
                            [
                                5.747819778634181,
                                45.27226738529305
                            ],
                            [
                                5.7467798655711135,
                                45.27226738529305
                            ],
                            [
                                5.747030601082997,
                                45.278496010439426
                            ],
                            [
                                5.746274300673917,
                                45.279959484986335
                            ],
                            [
                                5.7444308184254,
                                45.282354180097116
                            ],
                            [
                                5.738522221475762,
                                45.288273964749806
                            ],
                            [
                                5.737104158208638,
                                45.28684396100334
                            ],
                            [
                                5.736631470452608,
                                45.28561346380698
                            ],
                            [
                                5.737245964535163,
                                45.284682258997606
                            ],
                            [
                                5.7356860949406325,
                                45.28258699228647
                            ],
                            [
                                5.733417193711574,
                                45.28198833044101
                            ],
                            [
                                5.7302029169714785,
                                45.27942731679158
                            ],
                            [
                                5.728722349590186,
                                45.27825598910107
                            ],
                            [
                                5.728202393058666,
                                45.27639332266071
                            ],
                            [
                                5.726500717137611,
                                45.274031639805855
                            ],
                            [
                                5.723286440397487,
                                45.273765528534085
                            ],
                            [
                                5.722341064243949,
                                45.27143700180662
                            ],
                            [
                                5.71794506811429,
                                45.2707051593496
                            ],
                            [
                                5.715156210353712,
                                45.2722353646341
                            ],
                            [
                                5.71156378340865,
                                45.2721355698944
                            ],
                            [
                                5.708380252670764,
                                45.27124659615055
                            ],
                            [
                                5.7066785767497095,
                                45.269550032162726
                            ],
                            [
                                5.705922276339749,
                                45.26851876260466
                            ],
                            [
                                5.703086149803681,
                                45.267653812399004
                            ],
                            [
                                5.7029443434771565,
                                45.266522703780936
                            ],
                            [
                                5.703180687355626,
                                45.26475946010123
                            ],
                            [
                                5.702566193272133,
                                45.26223093962568
                            ],
                            [
                                5.703464300008676,
                                45.26179841826453
                            ],
                            [
                                5.702755268375114,
                                45.26090009414111
                            ],
                            [
                                5.701668086536557,
                                45.25966903429355
                            ],
                            [
                                5.700956962065561,
                                45.25894569383061
                            ],
                            [
                                5.6994227540034785,
                                45.256026419665346
                            ],
                            [
                                5.698224153955351,
                                45.25422078191221
                            ],
                            [
                                5.697696769934026,
                                45.252212575296795
                            ],
                            [
                                5.698607700917023,
                                45.25004247008738
                            ],
                            [
                                5.6988713929282255,
                                45.24813537899033
                            ],
                            [
                                5.697337194378406,
                                45.246683929183035
                            ],
                            [
                                5.695299574296001,
                                45.24626198694233
                            ],
                            [
                                5.692542794183879,
                                45.24540121505234
                            ],
                            [
                                5.691344194135667,
                                45.2431901529682
                            ],
                            [
                                5.690241482091238,
                                45.24224493986608
                            ],
                            [
                                5.687077177963346,
                                45.243240788940454
                            ],
                            [
                                5.686621709944802,
                                45.24435476891429
                            ],
                            [
                                5.687053205962457,
                                45.24805100051549
                            ],
                            [
                                5.685015585879967,
                                45.24931677796869
                            ],
                            [
                                5.681899225753938,
                                45.246363253365786
                            ],
                            [
                                5.680484877696557,
                                45.24737590767316
                            ],
                            [
                                5.677272629651867,
                                45.249198618049434
                            ],
                            [
                                5.675354869575017,
                                45.24789908344064
                            ],
                            [
                                5.674779541550919,
                                45.247105846404736
                            ],
                            [
                                5.6742761295305115,
                                45.24658264144625
                            ],
                            [
                                5.672845081790484,
                                45.24566965346554
                            ],
                            [
                                5.67202829833721,
                                45.245441010153144
                            ],
                            [
                                5.670306164551647,
                                45.24557958196786
                            ],
                            [
                                5.668623393823111,
                                45.245835938933226
                            ],
                            [
                                5.66781645113474,
                                45.24561422486832
                            ],
                            [
                                5.666806658188392,
                                45.24524073295311
                            ],
                            [
                                5.665356864704023,
                                45.24472104185173
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Marseille",
                "zone": "ZFE-m",
                "area_code": 0,
                "time": "!Crit 4/5 BAN from 1.9.2023!",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://ampmetropole.fr/missions/strategie-environnementale/qualite-de-lair/zone-a-faibles-emissions-mobilite-zfe-m/",
                "urlPark": "https://ampmetropole.fr/mes-demarches/parkings/",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                5.359698241383967,
                                43.327018005762255
                            ],
                            [
                                5.360302867626757,
                                43.32656438981283
                            ],
                            [
                                5.36192791403235,
                                43.32535473830208
                            ],
                            [
                                5.364214316533918,
                                43.3230865767936
                            ],
                            [
                                5.364875672629779,
                                43.321821868149726
                            ],
                            [
                                5.364403275417601,
                                43.320199703305065
                            ],
                            [
                                5.363855294653433,
                                43.318673729045656
                            ],
                            [
                                5.364082045315342,
                                43.317986340733
                            ],
                            [
                                5.365896050604704,
                                43.318852448733026
                            ],
                            [
                                5.369561852961567,
                                43.319938503222886
                            ],
                            [
                                5.37390790730268,
                                43.32102453829938
                            ],
                            [
                                5.37664781112656,
                                43.321684398232435
                            ],
                            [
                                5.377346958999141,
                                43.32206931321659
                            ],
                            [
                                5.377611543944624,
                                43.32286663221035
                            ],
                            [
                                5.377989461713469,
                                43.32422754420435
                            ],
                            [
                                5.378065045266908,
                                43.325684648502914
                            ],
                            [
                                5.3742858675783225,
                                43.32631696590923
                            ],
                            [
                                5.3746259935705325,
                                43.32741663267683
                            ],
                            [
                                5.3745504100170365,
                                43.32853002499692
                            ],
                            [
                                5.3742858675783225,
                                43.33099041218472
                            ],
                            [
                                5.371886089746681,
                                43.33045435866353
                            ],
                            [
                                5.368125807947905,
                                43.329478454208925
                            ],
                            [
                                5.367558931295349,
                                43.32910733149856
                            ],
                            [
                                5.366047260219915,
                                43.32846129766665
                            ],
                            [
                                5.362003540094179,
                                43.32741663267683
                            ],
                            [
                                5.359698241383967,
                                43.327018005762255
                            ]
                        ],
                        [
                            [
                                5.359085882266271,
                                43.32635947076159
                            ],
                            [
                                5.361183325884156,
                                43.323926388992135
                            ],
                            [
                                5.36303512295018,
                                43.32190561988807
                            ],
                            [
                                5.363337457165613,
                                43.32091583090531
                            ],
                            [
                                5.363110706503676,
                                43.31967857200141
                            ],
                            [
                                5.363564207826016,
                                43.32022846795783
                            ],
                            [
                                5.363998813261503,
                                43.321575692018996
                            ],
                            [
                                5.363280769500449,
                                43.32290913947742
                            ],
                            [
                                5.360975471110578,
                                43.325094830252965
                            ],
                            [
                                5.3601251561310335,
                                43.32578213814307
                            ],
                            [
                                5.359085882266271,
                                43.32635947076159
                            ]
                        ],
                        [
                            [
                                5.36455437966589,
                                43.317222305388924
                            ],
                            [
                                5.364827795145601,
                                43.31681763475527
                            ],
                            [
                                5.36547902165259,
                                43.31602204677043
                            ],
                            [
                                5.366767736814467,
                                43.31513974680445
                            ],
                            [
                                5.367253726646908,
                                43.31489929691577
                            ],
                            [
                                5.368111248384508,
                                43.313393290577835
                            ],
                            [
                                5.368709013990241,
                                43.311989435114896
                            ],
                            [
                                5.369024907381828,
                                43.31148021959416
                            ],
                            [
                                5.3689860294456935,
                                43.3109747644032
                            ],
                            [
                                5.36874789442777,
                                43.31012251901615
                            ],
                            [
                                5.368811073105832,
                                43.30987497662247
                            ],
                            [
                                5.368621537607737,
                                43.308934278456036
                            ],
                            [
                                5.367999994312811,
                                43.30684929834885
                            ],
                            [
                                5.367434910178815,
                                43.30497669064073
                            ],
                            [
                                5.367250234042274,
                                43.30467607786977
                            ],
                            [
                                5.366770816047904,
                                43.30285202868734
                            ],
                            [
                                5.366133834498555,
                                43.30093276973449
                            ],
                            [
                                5.366080375617145,
                                43.30050834657146
                            ],
                            [
                                5.3658712347456685,
                                43.29975120584416
                            ],
                            [
                                5.365584500744632,
                                43.298909413989605
                            ],
                            [
                                5.3650207525384985,
                                43.298449606794776
                            ],
                            [
                                5.364432706726831,
                                43.297993331161166
                            ],
                            [
                                5.363752320961197,
                                43.297197496826925
                            ],
                            [
                                5.363285615502491,
                                43.29662806305754
                            ],
                            [
                                5.363139818552469,
                                43.29604090199567
                            ],
                            [
                                5.363353654078793,
                                43.295379454731204
                            ],
                            [
                                5.364019459888738,
                                43.29459773530016
                            ],
                            [
                                5.364884521790856,
                                43.29357547132727
                            ],
                            [
                                5.3658409940786385,
                                43.2925519580036
                            ],
                            [
                                5.366390162589823,
                                43.29186924854639
                            ],
                            [
                                5.366443621471262,
                                43.29135278966899
                            ],
                            [
                                5.367657624000572,
                                43.291063805167056
                            ],
                            [
                                5.369057465772585,
                                43.29057191248873
                            ],
                            [
                                5.369998738688594,
                                43.289974608888144
                            ],
                            [
                                5.371181367954762,
                                43.28886782252076
                            ],
                            [
                                5.372878877230136,
                                43.28743307262292
                            ],
                            [
                                5.374737287858068,
                                43.28617397850513
                            ],
                            [
                                5.376140722985383,
                                43.28557582383846
                            ],
                            [
                                5.377548609825027,
                                43.28515416374418
                            ],
                            [
                                5.381007988916849,
                                43.28439282561541
                            ],
                            [
                                5.383231325063491,
                                43.283929108390595
                            ],
                            [
                                5.385765521374566,
                                43.283384448808306
                            ],
                            [
                                5.387559571462731,
                                43.28316189832816
                            ],
                            [
                                5.390338491564563,
                                43.28310284091765
                            ],
                            [
                                5.392381938749111,
                                43.28312041073946
                            ],
                            [
                                5.393677194641953,
                                43.283096984309395
                            ],
                            [
                                5.394554089708123,
                                43.282956425382
                            ],
                            [
                                5.395374686608477,
                                43.28298570848523
                            ],
                            [
                                5.397989333597025,
                                43.28323168599658
                            ],
                            [
                                5.398110009611457,
                                43.28354208476989
                            ],
                            [
                                5.398230685625833,
                                43.28422729909718
                            ],
                            [
                                5.398442535199848,
                                43.28569670062052
                            ],
                            [
                                5.398514940808468,
                                43.2862647647201
                            ],
                            [
                                5.399834331904202,
                                43.287166628785315
                            ],
                            [
                                5.400071550116564,
                                43.28749441757083
                            ],
                            [
                                5.3999347839668985,
                                43.28857194610242
                            ],
                            [
                                5.39973365727505,
                                43.289561615816496
                            ],
                            [
                                5.39957275592252,
                                43.29048685459358
                            ],
                            [
                                5.3997203229026525,
                                43.29112459756419
                            ],
                            [
                                5.400082350947059,
                                43.29176288030442
                            ],
                            [
                                5.400854677442993,
                                43.292500703381734
                            ],
                            [
                                5.40116186870344,
                                43.293161543487315
                            ],
                            [
                                5.401314724989589,
                                43.29388763845819
                            ],
                            [
                                5.4005745787654575,
                                43.294953342795765
                            ],
                            [
                                5.400062163545954,
                                43.29561237221509
                            ],
                            [
                                5.399949532599237,
                                43.29602224908385
                            ],
                            [
                                5.400005848072539,
                                43.29678929694401
                            ],
                            [
                                5.400142614222233,
                                43.297017652834995
                            ],
                            [
                                5.4005931380118,
                                43.29774370176452
                            ],
                            [
                                5.4005931380118,
                                43.298352639343335
                            ],
                            [
                                5.400430235681455,
                                43.30023058219086
                            ],
                            [
                                5.400333694869943,
                                43.30139570784212
                            ],
                            [
                                5.400373920208068,
                                43.302549101694865
                            ],
                            [
                                5.400454371184395,
                                43.30357779426623
                            ],
                            [
                                5.400639407739789,
                                43.304309619427414
                            ],
                            [
                                5.400607227469322,
                                43.30623574114307
                            ],
                            [
                                5.400572746113653,
                                43.30662043923235
                            ],
                            [
                                5.40142552328453,
                                43.307639086466594
                            ],
                            [
                                5.402222997522671,
                                43.30859552691382
                            ],
                            [
                                5.40260916076997,
                                43.30919264761516
                            ],
                            [
                                5.402947053611513,
                                43.31031076962202
                            ],
                            [
                                5.40341366753637,
                                43.31170985666836
                            ],
                            [
                                5.403640266557403,
                                43.31208016911853
                            ],
                            [
                                5.404911387247381,
                                43.31333872688157
                            ],
                            [
                                5.40481484643459,
                                43.31402360550436
                            ],
                            [
                                5.403543745094282,
                                43.31459141524314
                            ],
                            [
                                5.40290818474935,
                                43.31385386022123
                            ],
                            [
                                5.401677289398748,
                                43.31437483255385
                            ],
                            [
                                5.400003915326238,
                                43.3152704262379
                            ],
                            [
                                5.400462484182157,
                                43.31584406754561
                            ],
                            [
                                5.399198290133796,
                                43.316347744687505
                            ],
                            [
                                5.397233117736022,
                                43.316361492900654
                            ],
                            [
                                5.395135708101719,
                                43.3162102797024
                            ],
                            [
                                5.392887097376956,
                                43.315344134041084
                            ],
                            [
                                5.390959716755873,
                                43.31469795384061
                            ],
                            [
                                5.388975648471217,
                                43.314587965036935
                            ],
                            [
                                5.387293914398981,
                                43.314904182310556
                            ],
                            [
                                5.3844139448112,
                                43.31576497090066
                            ],
                            [
                                5.382278709418102,
                                43.316452384342625
                            ],
                            [
                                5.38071035067756,
                                43.31752473378401
                            ],
                            [
                                5.37838615639933,
                                43.31900949407634
                            ],
                            [
                                5.377157937253855,
                                43.31977938272226
                            ],
                            [
                                5.3764398934928295,
                                43.32078293743183
                            ],
                            [
                                5.37564626617845,
                                43.320851673449454
                            ],
                            [
                                5.364951193323151,
                                43.31792345020406
                            ],
                            [
                                5.36455437966589,
                                43.317222305388924
                            ]
                        ],
                        [
                            [
                                5.396391397333474,
                                43.280523038617275
                            ],
                            [
                                5.396168101277368,
                                43.27935641438032
                            ],
                            [
                                5.395458807923831,
                                43.27728129795949
                            ],
                            [
                                5.394946540501309,
                                43.275110478317
                            ],
                            [
                                5.398716303327035,
                                43.27748211877062
                            ],
                            [
                                5.398939599383141,
                                43.278180205004844
                            ],
                            [
                                5.399031544818627,
                                43.27918428759921
                            ],
                            [
                                5.396391397333474,
                                43.280523038617275
                            ]
                        ],
                        [
                            [
                                5.391954578229814,
                                43.273197454164716
                            ],
                            [
                                5.394200673850975,
                                43.27465109690962
                            ],
                            [
                                5.394568455582288,
                                43.27551179006326
                            ],
                            [
                                5.39489683213597,
                                43.27690799973226
                            ],
                            [
                                5.395356559309221,
                                43.27860062191863
                            ],
                            [
                                5.395661116969336,
                                43.2797374480389
                            ],
                            [
                                5.395647981907274,
                                43.28045463322678
                            ],
                            [
                                5.3953327404167055,
                                43.28082756618383
                            ],
                            [
                                5.394925553491703,
                                43.28086581558847
                            ],
                            [
                                5.394518366565677,
                                43.28144911603229
                            ],
                            [
                                5.393309940851623,
                                43.28187941605378
                            ],
                            [
                                5.39220659563415,
                                43.28237664784433
                            ],
                            [
                                5.391392221783121,
                                43.28262526221533
                            ],
                            [
                                5.390091851803874,
                                43.282711320475755
                            ],
                            [
                                5.388436833977636,
                                43.282787816893205
                            ],
                            [
                                5.386808086275522,
                                43.28281650302466
                            ],
                            [
                                5.3843386945982274,
                                43.2833137271557
                            ],
                            [
                                5.381776224611343,
                                43.28390697759721
                            ],
                            [
                                5.379004726505542,
                                43.28449024888292
                            ],
                            [
                                5.376403984207656,
                                43.285111761313146
                            ],
                            [
                                5.3749853938931835,
                                43.2855994066835
                            ],
                            [
                                5.3731727553222015,
                                43.286660735489875
                            ],
                            [
                                5.371964329607152,
                                43.28766467812878
                            ],
                            [
                                5.370663955920719,
                                43.288831142800035
                            ],
                            [
                                5.36938985489536,
                                43.2899115370756
                            ],
                            [
                                5.368260239553734,
                                43.290466068277595
                            ],
                            [
                                5.3676166214397085,
                                43.29060948080033
                            ],
                            [
                                5.366487006098083,
                                43.29082937918599
                            ],
                            [
                                5.3648451165065865,
                                43.29063816320672
                            ],
                            [
                                5.364871386630739,
                                43.29159423690754
                            ],
                            [
                                5.36508154762376,
                                43.29246425091367
                            ],
                            [
                                5.3646349555125425,
                                43.292951835739274
                            ],
                            [
                                5.363623555729504,
                                43.29403215681762
                            ],
                            [
                                5.362533339690685,
                                43.29525586041302
                            ],
                            [
                                5.362270638448365,
                                43.29579122288584
                            ],
                            [
                                5.362270638448365,
                                43.29663249724851
                            ],
                            [
                                5.362520204628652,
                                43.29753111837044
                            ],
                            [
                                5.362848581181225,
                                43.29859223897935
                            ],
                            [
                                5.3594334650323106,
                                43.29711048886011
                            ],
                            [
                                5.358671631429473,
                                43.29490213619337
                            ],
                            [
                                5.356916708216659,
                                43.29423668825888
                            ],
                            [
                                5.355734552626785,
                                43.295269186111085
                            ],
                            [
                                5.354026994552299,
                                43.2945999765393
                            ],
                            [
                                5.353186350577147,
                                43.29267834812026
                            ],
                            [
                                5.353659212813483,
                                43.29101479985911
                            ],
                            [
                                5.354841368403385,
                                43.29082358449958
                            ],
                            [
                                5.354335992361996,
                                43.28932185740379
                            ],
                            [
                                5.3517352500630295,
                                43.28800242246186
                            ],
                            [
                                5.351091632019859,
                                43.28660646737873
                            ],
                            [
                                5.349331533697097,
                                43.28577461589791
                            ],
                            [
                                5.3490704895950785,
                                43.28376077790463
                            ],
                            [
                                5.3475468223905125,
                                43.282919325485125
                            ],
                            [
                                5.345707913694355,
                                43.282622901973895
                            ],
                            [
                                5.345931209750461,
                                43.28086345527956
                            ],
                            [
                                5.349254380464998,
                                43.27960121217518
                            ],
                            [
                                5.351855122762913,
                                43.27961077472136
                            ],
                            [
                                5.3529716030424765,
                                43.27993590039807
                            ],
                            [
                                5.352879657608042,
                                43.2787883902555
                            ],
                            [
                                5.3593516185723615,
                                43.27525369366245
                            ],
                            [
                                5.361479498634935,
                                43.273780938391496
                            ],
                            [
                                5.360879741677735,
                                43.27339185653213
                            ],
                            [
                                5.362863136056632,
                                43.27015929998518
                            ],
                            [
                                5.366265117143513,
                                43.269126375899475
                            ],
                            [
                                5.370126840298241,
                                43.267385661464175
                            ],
                            [
                                5.370547162286329,
                                43.26619965605411
                            ],
                            [
                                5.368471822472003,
                                43.26585532757443
                            ],
                            [
                                5.367578638248574,
                                43.26443973447374
                            ],
                            [
                                5.368379878496228,
                                43.26279824199972
                            ],
                            [
                                5.372359802316055,
                                43.261172138431476
                            ],
                            [
                                5.373712714099327,
                                43.26181301328893
                            ],
                            [
                                5.374133036090285,
                                43.26224345217855
                            ],
                            [
                                5.375144435872301,
                                43.26246345309525
                            ],
                            [
                                5.377968476013791,
                                43.26422343290139
                            ],
                            [
                                5.385587704228442,
                                43.26908561242203
                            ],
                            [
                                5.39055396704137,
                                43.2723065791192
                            ],
                            [
                                5.390737857910267,
                                43.27270825291339
                            ],
                            [
                                5.391420881140675,
                                43.27306210620253
                            ],
                            [
                                5.391933148563254,
                                43.27316730543254
                            ],
                            [
                                5.394349994361448,
                                43.27469745513619
                            ],
                            [
                                5.394717776100293,
                                43.27602674176555
                            ],
                            [
                                5.39428062672701,
                                43.27481910069133
                            ],
                            [
                                5.391954578229814,
                                43.273197454164716
                            ]
                        ],
                        [
                            [
                                5.363502182103076,
                                43.29779032412711
                            ],
                            [
                                5.364383176080111,
                                43.298680854712984
                            ],
                            [
                                5.365141809782159,
                                43.299179546143534
                            ],
                            [
                                5.365570059147956,
                                43.300444065573686
                            ],
                            [
                                5.366010556135535,
                                43.301913369915894
                            ],
                            [
                                5.366549481274461,
                                43.304041043029514
                            ],
                            [
                                5.367536093199391,
                                43.30732610161493
                            ],
                            [
                                5.368001062241774,
                                43.30945411423781
                            ],
                            [
                                5.368421511370798,
                                43.31114074352138
                            ],
                            [
                                5.368127846711133,
                                43.31220024073869
                            ],
                            [
                                5.367321086573469,
                                43.3137838021957
                            ],
                            [
                                5.366697049172927,
                                43.31463848690879
                            ],
                            [
                                5.365877235333869,
                                43.31517265875064
                            ],
                            [
                                5.3658038191694,
                                43.31483435046255
                            ],
                            [
                                5.366146427937707,
                                43.31394405649519
                            ],
                            [
                                5.367345558628301,
                                43.311931944080015
                            ],
                            [
                                5.367272141689938,
                                43.31023649856468
                            ],
                            [
                                5.366794936619186,
                                43.308687261461984
                            ],
                            [
                                5.366264447317349,
                                43.30699804851449
                            ],
                            [
                                5.365848422383607,
                                43.30562681359493
                            ],
                            [
                                5.365297800516714,
                                43.3040596503007
                            ],
                            [
                                5.364600354029221,
                                43.301699926799785
                            ],
                            [
                                5.364098676903666,
                                43.299936751137864
                            ],
                            [
                                5.3636459462422295,
                                43.29853864159193
                            ],
                            [
                                5.363502182103076,
                                43.29779032412711
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Nancy (only when pollution warning)",
                "zone": "ZFE",
                "area_code": 1,
                "time": "Temporary during Emission Peak",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 0,
                "urlZone": "https://www.grandnancy.eu/se-deplacer/critair-et-pics-de-pollution",
                "urlPark": "https://www.grandnancy.eu/se-deplacer/circulation-et-stationnement",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                6.133332252502441,
                                45.89786360014489
                            ],
                            [
                                6.133203506469727,
                                45.90070084385809
                            ],
                            [
                                6.134834289550781,
                                45.90147732739488
                            ],
                            [
                                6.136293411254883,
                                45.903060125589604
                            ],
                            [
                                6.139426231384277,
                                45.90409041337522
                            ],
                            [
                                6.1420440673828125,
                                45.904836986779465
                            ],
                            [
                                6.141872406005859,
                                45.905434238274005
                            ],
                            [
                                6.139469146728516,
                                45.908062068513644
                            ],
                            [
                                6.137752532958984,
                                45.90976411944332
                            ],
                            [
                                6.136980056762695,
                                45.91055540605009
                            ],
                            [
                                6.136379241943359,
                                45.910839072086716
                            ],
                            [
                                6.133546829223632,
                                45.91128696287702
                            ],
                            [
                                6.132237911224365,
                                45.911540766053776
                            ],
                            [
                                6.131529808044433,
                                45.911331751757245
                            ],
                            [
                                6.131143569946289,
                                45.91158555472919
                            ],
                            [
                                6.130456924438477,
                                45.911704991020294
                            ],
                            [
                                6.127495765686035,
                                45.911660202441254
                            ],
                            [
                                6.124813556671143,
                                45.9114661181811
                            ],
                            [
                                6.124083995819092,
                                45.911331751757245
                            ],
                            [
                                6.12337589263916,
                                45.91139147020801
                            ],
                            [
                                6.122088432312012,
                                45.91137654060134
                            ],
                            [
                                6.120650768280029,
                                45.91107794762467
                            ],
                            [
                                6.119835376739501,
                                45.91063005514768
                            ],
                            [
                                6.118590831756592,
                                45.909853699625444
                            ],
                            [
                                6.1176252365112305,
                                45.90904747278164
                            ],
                            [
                                6.116788387298584,
                                45.908151651442694
                            ],
                            [
                                6.115415096282959,
                                45.90662872198624
                            ],
                            [
                                6.115458011627196,
                                45.90635996539191
                            ],
                            [
                                6.114857196807861,
                                45.905434238274005
                            ],
                            [
                                6.114213466644287,
                                45.90538944463481
                            ],
                            [
                                6.113376617431641,
                                45.90462794723831
                            ],
                            [
                                6.112239360809326,
                                45.903493147423
                            ],
                            [
                                6.111745834350586,
                                45.903060125589604
                            ],
                            [
                                6.112432479858398,
                                45.90249271255755
                            ],
                            [
                                6.114470958709717,
                                45.901313072167724
                            ],
                            [
                                6.116037368774414,
                                45.90083523601656
                            ],
                            [
                                6.116487979888915,
                                45.900402193452386
                            ],
                            [
                                6.117753982543945,
                                45.899491299793404
                            ],
                            [
                                6.1183762550353995,
                                45.89926730726023
                            ],
                            [
                                6.119384765624999,
                                45.89841612739329
                            ],
                            [
                                6.120843887329101,
                                45.8972513337993
                            ],
                            [
                                6.121723651885986,
                                45.896280653806365
                            ],
                            [
                                6.122410297393799,
                                45.89559370078914
                            ],
                            [
                                6.123440265655518,
                                45.89560863464074
                            ],
                            [
                                6.124212741851807,
                                45.89547422983173
                            ],
                            [
                                6.124920845031738,
                                45.89559370078914
                            ],
                            [
                                6.125779151916504,
                                45.895668370006995
                            ],
                            [
                                6.127302646636963,
                                45.89547422983173
                            ],
                            [
                                6.1278605461120605,
                                45.8953248907735
                            ],
                            [
                                6.128182411193847,
                                45.89454832119685
                            ],
                            [
                                6.129190921783447,
                                45.894473650472946
                            ],
                            [
                                6.129019260406494,
                                45.89525022109378
                            ],
                            [
                                6.130542755126953,
                                45.89637025574733
                            ],
                            [
                                6.131486892700195,
                                45.897042265696086
                            ],
                            [
                                6.133332252502441,
                                45.89786360014489
                            ]
                        ],
                        [
                            [
                                6.1736297607421875,
                                48.61041256062223
                            ],
                            [
                                6.1745524406433105,
                                48.610199750856424
                            ],
                            [
                                6.175367832183838,
                                48.61044093518991
                            ],
                            [
                                6.176505088806152,
                                48.61222850080921
                            ],
                            [
                                6.177492141723633,
                                48.6139166991197
                            ],
                            [
                                6.178586483001709,
                                48.616186456617996
                            ],
                            [
                                6.179080009460449,
                                48.617761040930105
                            ],
                            [
                                6.179509162902832,
                                48.61861214713695
                            ],
                            [
                                6.181225776672363,
                                48.62088169353486
                            ],
                            [
                                6.1821699142456055,
                                48.6229383818181
                            ],
                            [
                                6.182577610015869,
                                48.624172354561914
                            ],
                            [
                                6.181440353393555,
                                48.62544884634219
                            ],
                            [
                                6.181139945983887,
                                48.6267820366205
                            ],
                            [
                                6.180860996246338,
                                48.62784572700763
                            ],
                            [
                                6.179251670837402,
                                48.62906539772416
                            ],
                            [
                                6.1782002449035645,
                                48.63035594742903
                            ],
                            [
                                6.175539493560791,
                                48.632525698358016
                            ],
                            [
                                6.173543930053711,
                                48.634071411714885
                            ],
                            [
                                6.171419620513916,
                                48.63531929253713
                            ],
                            [
                                6.170454025268555,
                                48.636652222048916
                            ],
                            [
                                6.170239448547363,
                                48.6381269115359
                            ],
                            [
                                6.1699604988098145,
                                48.63998439175189
                            ],
                            [
                                6.168866157531738,
                                48.64073587194724
                            ],
                            [
                                6.165046691894531,
                                48.641685840323
                            ],
                            [
                                6.157665252685547,
                                48.64280592930345
                            ],
                            [
                                6.1481380462646475,
                                48.64437968318474
                            ],
                            [
                                6.142773628234862,
                                48.64525869550965
                            ],
                            [
                                6.14133596420288,
                                48.64494678966411
                            ],
                            [
                                6.139662265777588,
                                48.64523034051247
                            ],
                            [
                                6.13811731338501,
                                48.645641486410895
                            ],
                            [
                                6.1374735832214355,
                                48.64627946478972
                            ],
                            [
                                6.136744022369385,
                                48.64737109796242
                            ],
                            [
                                6.134705543518066,
                                48.648306764731444
                            ],
                            [
                                6.130950450897217,
                                48.65091519860333
                            ],
                            [
                                6.12788200378418,
                                48.65362272364397
                            ],
                            [
                                6.1267876625061035,
                                48.65540876004701
                            ],
                            [
                                6.1260151863098145,
                                48.656939598033446
                            ],
                            [
                                6.125199794769286,
                                48.658838906472525
                            ],
                            [
                                6.124556064605713,
                                48.66036964029877
                            ],
                            [
                                6.12135887145996,
                                48.66704479719101
                            ],
                            [
                                6.117410659790038,
                                48.679627401760364
                            ],
                            [
                                6.11140251159668,
                                48.69254680589792
                            ],
                            [
                                6.073808670043945,
                                48.69113036634908
                            ],
                            [
                                6.080074310302734,
                                48.66806512543773
                            ],
                            [
                                6.090631484985351,
                                48.667356566347124
                            ],
                            [
                                6.091704368591309,
                                48.66333176170394
                            ],
                            [
                                6.099343299865723,
                                48.65923576785397
                            ],
                            [
                                6.101682186126709,
                                48.65600409145431
                            ],
                            [
                                6.107218265533446,
                                48.65027727887091
                            ],
                            [
                                6.117711067199707,
                                48.647130090073794
                            ],
                            [
                                6.12438440322876,
                                48.65012134170882
                            ],
                            [
                                6.1260151863098145,
                                48.64601009712531
                            ],
                            [
                                6.13009214401245,
                                48.6446348818896
                            ],
                            [
                                6.136443614959716,
                                48.6471442670403
                            ],
                            [
                                6.137645244598389,
                                48.64569819592692
                            ],
                            [
                                6.138696670532227,
                                48.64524451801305
                            ],
                            [
                                6.142215728759766,
                                48.644592348861806
                            ],
                            [
                                6.15809440612793,
                                48.64101944646997
                            ],
                            [
                                6.16307258605957,
                                48.63778660240362
                            ],
                            [
                                6.165647506713866,
                                48.633929605122965
                            ],
                            [
                                6.172857284545898,
                                48.63375943668655
                            ],
                            [
                                6.174960136413573,
                                48.632284619529024
                            ],
                            [
                                6.173758506774902,
                                48.629987222309936
                            ],
                            [
                                6.1559057235717765,
                                48.627264245707465
                            ],
                            [
                                6.153545379638672,
                                48.624966619908676
                            ],
                            [
                                6.15109920501709,
                                48.620058994756164
                            ],
                            [
                                6.15109920501709,
                                48.6164559835426
                            ],
                            [
                                6.158652305603027,
                                48.61407274821429
                            ],
                            [
                                6.160712242126465,
                                48.61342017606443
                            ],
                            [
                                6.168050765991211,
                                48.61049768427742
                            ],
                            [
                                6.1736297607421875,
                                48.61041256062223
                            ]
                        ],
                        [
                            [
                                6.106252670288085,
                                48.69597442471197
                            ],
                            [
                                6.114020347595214,
                                48.69724909945107
                            ],
                            [
                                6.12037181854248,
                                48.69993997346546
                            ],
                            [
                                6.125950813293457,
                                48.70226250695086
                            ],
                            [
                                6.13161563873291,
                                48.7039901757614
                            ],
                            [
                                6.135435104370116,
                                48.70585938997357
                            ],
                            [
                                6.138653755187988,
                                48.706907252419974
                            ],
                            [
                                6.1406707763671875,
                                48.708209969901155
                            ],
                            [
                                6.145648956298828,
                                48.71155156922771
                            ],
                            [
                                6.148180961608887,
                                48.713222285673645
                            ],
                            [
                                6.150412559509277,
                                48.714298310961325
                            ],
                            [
                                6.1533308029174805,
                                48.71455315568503
                            ],
                            [
                                6.1574506759643555,
                                48.714723051450356
                            ],
                            [
                                6.160712242126465,
                                48.715600837097824
                            ],
                            [
                                6.162042617797852,
                                48.71695995885137
                            ],
                            [
                                6.1629438400268555,
                                48.71829072998903
                            ],
                            [
                                6.165733337402344,
                                48.720329289841125
                            ],
                            [
                                6.167407035827637,
                                48.721688283844614
                            ],
                            [
                                6.168179512023926,
                                48.72265088238135
                            ],
                            [
                                6.165947914123535,
                                48.72236776707706
                            ],
                            [
                                6.159982681274414,
                                48.719678092202116
                            ],
                            [
                                6.150927543640137,
                                48.71715816103922
                            ],
                            [
                                6.142988204956055,
                                48.71415673000144
                            ],
                            [
                                6.135349273681641,
                                48.71325060242344
                            ],
                            [
                                6.130113601684569,
                                48.709201145393074
                            ],
                            [
                                6.118183135986328,
                                48.701696045250706
                            ],
                            [
                                6.1074113845825195,
                                48.698778666534544
                            ],
                            [
                                6.106467247009277,
                                48.69696584118716
                            ],
                            [
                                6.106252670288085,
                                48.69597442471197
                            ]
                        ],
                        [
                            [
                                6.134257964469526,
                                48.70357935985459
                            ],
                            [
                                6.1326503060308255,
                                48.703247722017835
                            ],
                            [
                                6.130060359051896,
                                48.70236046523348
                            ],
                            [
                                6.125216565083036,
                                48.70052066235567
                            ],
                            [
                                6.121960658938775,
                                48.69881630364307
                            ],
                            [
                                6.120517406041614,
                                48.697889821253426
                            ],
                            [
                                6.119904517825546,
                                48.69692417357214
                            ],
                            [
                                6.119785894299952,
                                48.69571056305634
                            ],
                            [
                                6.124254047103136,
                                48.69525382033851
                            ],
                            [
                                6.125756611763052,
                                48.69516247129792
                            ],
                            [
                                6.128524494030302,
                                48.69479707347742
                            ],
                            [
                                6.129513023412187,
                                48.69479707347742
                            ],
                            [
                                6.130125911628227,
                                48.69574971223929
                            ],
                            [
                                6.130956276308581,
                                48.69633694633069
                            ],
                            [
                                6.131845952751689,
                                48.69661098656246
                            ],
                            [
                                6.133546223287539,
                                48.697237358633316
                            ],
                            [
                                6.135681446778335,
                                48.698190374204074
                            ],
                            [
                                6.1360768585309415,
                                48.698973313510436
                            ],
                            [
                                6.135701217365948,
                                48.69992587324566
                            ],
                            [
                                6.135246493850644,
                                48.70086536655961
                            ],
                            [
                                6.134514982108243,
                                48.70243114978231
                            ],
                            [
                                6.134257964469526,
                                48.70357935985459
                            ]
                        ],
                        [
                            [
                                6.13709868828326,
                                48.69823200357135
                            ],
                            [
                                6.136446258891226,
                                48.69751429910343
                            ],
                            [
                                6.1356158942109005,
                                48.697018424584485
                            ],
                            [
                                6.132650306067006,
                                48.695778716917545
                            ],
                            [
                                6.131207053170556,
                                48.695230625901104
                            ],
                            [
                                6.130495312015739,
                                48.69449982859689
                            ],
                            [
                                6.133836541324712,
                                48.694212726753676
                            ],
                            [
                                6.135477500097778,
                                48.69442152825667
                            ],
                            [
                                6.137573182386177,
                                48.695060977472224
                            ],
                            [
                                6.13709868828326,
                                48.69823200357135
                            ]
                        ],
                        [
                            [
                                6.130662082424351,
                                48.69403860883719
                            ],
                            [
                                6.1308202471250866,
                                48.69312508892082
                            ],
                            [
                                6.131551758867431,
                                48.692355109260944
                            ],
                            [
                                6.133252029403366,
                                48.691232744936656
                            ],
                            [
                                6.133845147032616,
                                48.68991458744773
                            ],
                            [
                                6.133924229382956,
                                48.689275072868156
                            ],
                            [
                                6.135545417568409,
                                48.68893573530326
                            ],
                            [
                                6.13522908816617,
                                48.68845282790258
                            ],
                            [
                                6.133864917620173,
                                48.68827010497557
                            ],
                            [
                                6.133074094115017,
                                48.68824400164627
                            ],
                            [
                                6.132125105908386,
                                48.688831323284404
                            ],
                            [
                                6.130345753022169,
                                48.689379483966405
                            ],
                            [
                                6.129100206001681,
                                48.68964051076526
                            ],
                            [
                                6.128151217795789,
                                48.6897057672534
                            ],
                            [
                                6.1257392061051235,
                                48.689536100208244
                            ],
                            [
                                6.1245925120233835,
                                48.690423583043156
                            ],
                            [
                                6.12358421205397,
                                48.69140240626541
                            ],
                            [
                                6.123959853218935,
                                48.69315118971983
                            ],
                            [
                                6.12390054145618,
                                48.69465196290881
                            ],
                            [
                                6.120045276869149,
                                48.693738454121586
                            ],
                            [
                                6.118621794559516,
                                48.69163732100688
                            ],
                            [
                                6.118107759281401,
                                48.68877911719406
                            ],
                            [
                                6.119036976899707,
                                48.68639063067462
                            ],
                            [
                                6.121213483054248,
                                48.67724859012901
                            ],
                            [
                                6.12654640586527,
                                48.66205079532463
                            ],
                            [
                                6.128247166188373,
                                48.65647385305229
                            ],
                            [
                                6.13040152922477,
                                48.65288548213579
                            ],
                            [
                                6.1338311719197804,
                                48.65001661429304
                            ],
                            [
                                6.1380722987187255,
                                48.647842153555786
                            ],
                            [
                                6.1394418292477155,
                                48.64800268742013
                            ],
                            [
                                6.140833448978697,
                                48.64800268742013
                            ],
                            [
                                6.141584481849662,
                                48.64775458941463
                            ],
                            [
                                6.142848067981959,
                                48.64816188990204
                            ],
                            [
                                6.145604506340675,
                                48.64950826049255
                            ],
                            [
                                6.146111031338734,
                                48.64971060209865
                            ],
                            [
                                6.1467824714510755,
                                48.64963277850032
                            ],
                            [
                                6.1475245894708905,
                                48.65002967759631
                            ],
                            [
                                6.147889758655936,
                                48.65018532344834
                            ],
                            [
                                6.1483020464444,
                                48.64989737824516
                            ],
                            [
                                6.147124081333999,
                                48.64920474656165
                            ],
                            [
                                6.145981455176326,
                                48.64848875774277
                            ],
                            [
                                6.145769421455867,
                                48.648169672493964
                            ],
                            [
                                6.145156879598488,
                                48.64799845518715
                            ],
                            [
                                6.144544337741081,
                                48.648076281307766
                            ],
                            [
                                6.144296965067809,
                                48.64819302026379
                            ],
                            [
                                6.14295408484125,
                                48.64752371324934
                            ],
                            [
                                6.142447559844101,
                                48.64711901167533
                            ],
                            [
                                6.1429894237949725,
                                48.64642634182911
                            ],
                            [
                                6.143884677279345,
                                48.645873755691554
                            ],
                            [
                                6.147807301097885,
                                48.645227767037085
                            ],
                            [
                                6.1569836575745285,
                                48.64372561011501
                            ],
                            [
                                6.164160857071437,
                                48.642551899968225
                            ],
                            [
                                6.168365034814627,
                                48.64189194548371
                            ],
                            [
                                6.170525183741574,
                                48.64118591574092
                            ],
                            [
                                6.17164010380597,
                                48.63978921985424
                            ],
                            [
                                6.1721743363368375,
                                48.63725665065485
                            ],
                            [
                                6.1727085688677334,
                                48.63616683932662
                            ],
                            [
                                6.17511122839278,
                                48.63415917775552
                            ],
                            [
                                6.17778239104814,
                                48.63197939772917
                            ],
                            [
                                6.179779956163856,
                                48.63026006817856
                            ],
                            [
                                6.18108069623861,
                                48.629385029751234
                            ],
                            [
                                6.181591700776579,
                                48.629968390616796
                            ],
                            [
                                6.181475563270084,
                                48.63300789745074
                            ],
                            [
                                6.180871648235268,
                                48.63687609588362
                            ],
                            [
                                6.180709045933014,
                                48.63936264054436
                            ],
                            [
                                6.180546453423858,
                                48.6414653615947
                            ],
                            [
                                6.180592902259576,
                                48.64470368984212
                            ],
                            [
                                6.180755494768704,
                                48.64780369331342
                            ],
                            [
                                6.180938975547747,
                                48.65025135835609
                            ],
                            [
                                6.181031885553011,
                                48.65210810973903
                            ],
                            [
                                6.182216488121242,
                                48.653519808835426
                            ],
                            [
                                6.183912085450828,
                                48.656220334929344
                            ],
                            [
                                6.184887640507412,
                                48.65793877973172
                            ],
                            [
                                6.185305735531756,
                                48.658966749959035
                            ],
                            [
                                6.186118698079213,
                                48.65994867209284
                            ],
                            [
                                6.187094251940977,
                                48.66154425141235
                            ],
                            [
                                6.188185944504056,
                                48.66336990423957
                            ],
                            [
                                6.189161499560612,
                                48.66504208283703
                            ],
                            [
                                6.189928008362045,
                                48.66637671520246
                            ],
                            [
                                6.190137055873805,
                                48.666974988315616
                            ],
                            [
                                6.1895795958416215,
                                48.66761927449426
                            ],
                            [
                                6.19032287588513,
                                48.66823287272055
                            ],
                            [
                                6.190717743408214,
                                48.668432290535605
                            ],
                            [
                                6.1919023459765015,
                                48.66771131470435
                            ],
                            [
                                6.192784991027821,
                                48.667496553952674
                            ],
                            [
                                6.193551498571708,
                                48.66726645213106
                            ],
                            [
                                6.193298102488228,
                                48.666713260940725
                            ],
                            [
                                6.19194737824489,
                                48.66420900062124
                            ],
                            [
                                6.1901787143934826,
                                48.664601049735694
                            ],
                            [
                                6.189457926803641,
                                48.663246211233115
                            ],
                            [
                                6.188072078375484,
                                48.661556545560586
                            ],
                            [
                                6.187448200372927,
                                48.66063636988852
                            ],
                            [
                                6.186657101727718,
                                48.65927759454672
                            ],
                            [
                                6.185936310987984,
                                48.658021292922115
                            ],
                            [
                                6.185067572588508,
                                48.65655940003805
                            ],
                            [
                                6.184275952079247,
                                48.655136782194546
                            ],
                            [
                                6.182989574665385,
                                48.65315157362386
                            ],
                            [
                                6.182468666844898,
                                48.6521752239307
                            ],
                            [
                                6.182040875244354,
                                48.65100557770856
                            ],
                            [
                                6.181725907804264,
                                48.6493447600632
                            ],
                            [
                                6.18167139420575,
                                48.64752395545469
                            ],
                            [
                                6.181703854567473,
                                48.64582343373698
                            ],
                            [
                                6.181661455091387,
                                48.644942991350206
                            ],
                            [
                                6.181631169720731,
                                48.6433198841365
                            ],
                            [
                                6.181753911326979,
                                48.64160699782346
                            ],
                            [
                                6.182008307438707,
                                48.63924558679915
                            ],
                            [
                                6.182105220576432,
                                48.637454108799886
                            ],
                            [
                                6.1821960766130815,
                                48.636037156890296
                            ],
                            [
                                6.182280875835318,
                                48.634520096877566
                            ],
                            [
                                6.182450473738612,
                                48.633623445401554
                            ],
                            [
                                6.182774332697051,
                                48.63200512966969
                            ],
                            [
                                6.183331580812933,
                                48.63053198323149
                            ],
                            [
                                6.1858668819958496,
                                48.627571150630075
                            ],
                            [
                                6.186945651986264,
                                48.62456055761115
                            ],
                            [
                                6.189901808397849,
                                48.621786419802845
                            ],
                            [
                                6.192418938376704,
                                48.62025456145767
                            ],
                            [
                                6.19549543079188,
                                48.619079224762714
                            ],
                            [
                                6.19921119459346,
                                48.61855097455009
                            ],
                            [
                                6.203186658398636,
                                48.61857738817824
                            ],
                            [
                                6.20913987057051,
                                48.6186038008004
                            ],
                            [
                                6.212635884429687,
                                48.618643419707695
                            ],
                            [
                                6.214713531717649,
                                48.618920748943424
                            ],
                            [
                                6.221109091901269,
                                48.628305700818316
                            ],
                            [
                                6.226407531027945,
                                48.63138312718334
                            ],
                            [
                                6.250482099633501,
                                48.63072564752423
                            ],
                            [
                                6.2713732542077025,
                                48.62756962590589
                            ],
                            [
                                6.276745265383909,
                                48.630462653262356
                            ],
                            [
                                6.2777400822685365,
                                48.63887778977792
                            ],
                            [
                                6.2914685552747756,
                                48.64992052640335
                            ],
                            [
                                6.274357704861586,
                                48.67712266445949
                            ],
                            [
                                6.282515133771426,
                                48.69415730883469
                            ],
                            [
                                6.287290254817037,
                                48.6999355589185
                            ],
                            [
                                6.290274705470921,
                                48.70755132977288
                            ],
                            [
                                6.287489218193571,
                                48.71214649603263
                            ],
                            [
                                6.290871595601487,
                                48.71792268110465
                            ],
                            [
                                6.288682998455698,
                                48.72172934958277
                            ],
                            [
                                6.278734829610443,
                                48.717135058555414
                            ],
                            [
                                6.273163855056737,
                                48.72435447034036
                            ],
                            [
                                6.255655077889514,
                                48.720679262914615
                            ],
                            [
                                6.233769106430742,
                                48.718972825321
                            ],
                            [
                                6.222627157324268,
                                48.719235357949714
                            ],
                            [
                                6.220239596801974,
                                48.72409196443016
                            ],
                            [
                                6.222627157324268,
                                48.72802940921417
                            ],
                            [
                                6.20631216041815,
                                48.73459113211743
                            ],
                            [
                                6.200741185865439,
                                48.728554378559124
                            ],
                            [
                                6.19158887052825,
                                48.73511603295697
                            ],
                            [
                                6.1870127128592,
                                48.735509704989795
                            ],
                            [
                                6.187696614234142,
                                48.73761074978893
                            ],
                            [
                                6.181349592957218,
                                48.73974469969869
                            ],
                            [
                                6.178362759414171,
                                48.73974469969869
                            ],
                            [
                                6.177553825330023,
                                48.741386137985245
                            ],
                            [
                                6.171455706847809,
                                48.7367899757015
                            ],
                            [
                                6.171393482075388,
                                48.73416340832799
                            ],
                            [
                                6.172762447448889,
                                48.73194713556623
                            ],
                            [
                                6.174504767014554,
                                48.730921050276834
                            ],
                            [
                                6.17568705529203,
                                48.72849940597172
                            ],
                            [
                                6.175127024002592,
                                48.72640602623761
                            ],
                            [
                                6.174504767014554,
                                48.72410731281644
                            ],
                            [
                                6.175127024002592,
                                48.72197269913008
                            ],
                            [
                                6.170024516701801,
                                48.722136903399075
                            ],
                            [
                                6.16802117555892,
                                48.72012185434596
                            ],
                            [
                                6.165538869535226,
                                48.71812898619669
                            ],
                            [
                                6.163654709541305,
                                48.71572165403737
                            ],
                            [
                                6.158600375589629,
                                48.71345234201763
                            ],
                            [
                                6.152319842277848,
                                48.71343260842079
                            ],
                            [
                                6.148730966098668,
                                48.71207097154141
                            ],
                            [
                                6.1448379065362815,
                                48.70917958939239
                            ],
                            [
                                6.139933109091686,
                                48.70602184729691
                            ],
                            [
                                6.136912468094124,
                                48.704423163629514
                            ],
                            [
                                6.135805897938724,
                                48.703298134400825
                            ],
                            [
                                6.136583487778353,
                                48.70102826203674
                            ],
                            [
                                6.137749872536631,
                                48.699172812069435
                            ],
                            [
                                6.138108760153841,
                                48.69656717112525
                            ],
                            [
                                6.138288191833084,
                                48.69537406246263
                            ],
                            [
                                6.139092805046943,
                                48.69497278918783
                            ],
                            [
                                6.138896121816714,
                                48.694488896572636
                            ],
                            [
                                6.134926696631851,
                                48.69367453020834
                            ],
                            [
                                6.1331207869753825,
                                48.69374534519761
                            ],
                            [
                                6.130662082424351,
                                48.69403860883719
                            ]
                        ],
                        [
                            [
                                6.184415574177763,
                                48.62374162674047
                            ],
                            [
                                6.183304665255974,
                                48.622218586504204
                            ],
                            [
                                6.182029177234909,
                                48.61911796972646
                            ],
                            [
                                6.181082847413052,
                                48.61675158141361
                            ],
                            [
                                6.178490726596266,
                                48.61237211045423
                            ],
                            [
                                6.177462107224869,
                                48.608318721210765
                            ],
                            [
                                6.177626686324004,
                                48.60494518276266
                            ],
                            [
                                6.184374429402993,
                                48.60529887075512
                            ],
                            [
                                6.191698199329011,
                                48.6039929335478
                            ],
                            [
                                6.198692811056759,
                                48.60369365151516
                            ],
                            [
                                6.2042062108895095,
                                48.60505401010093
                            ],
                            [
                                6.20659260471362,
                                48.606550388553444
                            ],
                            [
                                6.213792940315301,
                                48.6137322615985
                            ],
                            [
                                6.214821559687579,
                                48.61783961613975
                            ],
                            [
                                6.208238395708605,
                                48.6177036163721
                            ],
                            [
                                6.203794760022873,
                                48.6177036163721
                            ],
                            [
                                6.200338598933769,
                                48.6177036163721
                            ],
                            [
                                6.196347555771581,
                                48.61794841569065
                            ],
                            [
                                6.194084593153121,
                                48.618438010765885
                            ],
                            [
                                6.190751866389263,
                                48.619689176608716
                            ],
                            [
                                6.187707153048962,
                                48.62191943880683
                            ],
                            [
                                6.185444190431269,
                                48.62382324320507
                            ],
                            [
                                6.184415574177763,
                                48.62374162674047
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Paris",
                "zone": "ZFE-m",
                "area_code": 0,
                "time": "Mo-Fr: 08.00-20.00 (only M1 private cars)*",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://www.zonefaiblesemissionsmetropolitaine.fr/",
                "urlPark": "https://www.paris.fr/pages/deplacements-les-mesures-de-la-ville-pour-le-deconfinement-7788",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                2.4159785990856903,
                                48.92761079378079
                            ],
                            [
                                2.4138779319657147,
                                48.92802122355303
                            ],
                            [
                                2.4106547838204335,
                                48.92765176338867
                            ],
                            [
                                2.404149739094322,
                                48.92632658871946
                            ],
                            [
                                2.4030662126962454,
                                48.92589403503814
                            ],
                            [
                                2.4008991772890056,
                                48.92544344695153
                            ],
                            [
                                2.3995550559343712,
                                48.92556960996728
                            ],
                            [
                                2.3972797485998854,
                                48.925358412140724
                            ],
                            [
                                2.3959466361384614,
                                48.92525330277846
                            ],
                            [
                                2.392650842965793,
                                48.92471373286949
                            ],
                            [
                                2.3874143772175955,
                                48.92387284092234
                            ],
                            [
                                2.3835964926972224,
                                48.923242211839266
                            ],
                            [
                                2.3811222359688315,
                                48.92259751028368
                            ],
                            [
                                2.3775830648068563,
                                48.9215021180471
                            ],
                            [
                                2.376452585439665,
                                48.92106062315003
                            ],
                            [
                                2.374895510084599,
                                48.9209274731293
                            ],
                            [
                                2.372707392041349,
                                48.9207397517533
                            ],
                            [
                                2.370435768407191,
                                48.92083085477944
                            ],
                            [
                                2.367684224287018,
                                48.9212863674191
                            ],
                            [
                                2.3655619000412287,
                                48.92152464115904
                            ],
                            [
                                2.3636848776958175,
                                48.92148960203946
                            ],
                            [
                                2.362202456638471,
                                48.921475586384986
                            ],
                            [
                                2.36028277469444,
                                48.92160172713443
                            ],
                            [
                                2.358213781681826,
                                48.92193811310406
                            ],
                            [
                                2.3537238589117635,
                                48.92226046917821
                            ],
                            [
                                2.350353744617877,
                                48.922470706601075
                            ],
                            [
                                2.3478048335919084,
                                48.92236559116142
                            ],
                            [
                                2.3468236628198156,
                                48.92226748321755
                            ],
                            [
                                2.345533210476958,
                                48.92235858905738
                            ],
                            [
                                2.343357570939702,
                                48.92303132414534
                            ],
                            [
                                2.342077782976503,
                                48.92352886197443
                            ],
                            [
                                2.3398808115836403,
                                48.924748170255526
                            ],
                            [
                                2.3374065548553062,
                                48.92604451670613
                            ],
                            [
                                2.3348256491304085,
                                48.92668917376338
                            ],
                            [
                                2.3328526359359785,
                                48.92705355100432
                            ],
                            [
                                2.3314981936752304,
                                48.9275370348077
                            ],
                            [
                                2.3305810123018205,
                                48.92832881701261
                            ],
                            [
                                2.328384042965297,
                                48.9290365055258
                            ],
                            [
                                2.3265176951579463,
                                48.929386848212175
                            ],
                            [
                                2.324416709919035,
                                48.93003146212067
                            ],
                            [
                                2.32273165576828,
                                48.9308302112874
                            ],
                            [
                                2.3217504849961585,
                                48.93151684509445
                            ],
                            [
                                2.3205710620892717,
                                48.933067936176826
                            ],
                            [
                                2.3195578966189885,
                                48.93411885535019
                            ],
                            [
                                2.3192592794270865,
                                48.93484747965738
                            ],
                            [
                                2.318726034442875,
                                48.93567417512784
                            ],
                            [
                                2.3173396010938063,
                                48.93680911177026
                            ],
                            [
                                2.314726700669979,
                                48.9377408580518
                            ],
                            [
                                2.311513314056782,
                                48.93819835200043
                            ],
                            [
                                2.3067247740956134,
                                48.93861167370065
                            ],
                            [
                                2.304666433879703,
                                48.93868873771328
                            ],
                            [
                                2.3046557689800125,
                                48.93749079776248
                            ],
                            [
                                2.3038878962018146,
                                48.93756785909267
                            ],
                            [
                                2.3036852631075533,
                                48.93806525208697
                            ],
                            [
                                2.3026081082394683,
                                48.93812830154948
                            ],
                            [
                                2.301381644774466,
                                48.937413736313914
                            ],
                            [
                                2.300485793200437,
                                48.936895319838186
                            ],
                            [
                                2.299461973943778,
                                48.93653101599389
                            ],
                            [
                                2.2974676377022263,
                                48.937140509526586
                            ],
                            [
                                2.2952386736667165,
                                48.936629095915464
                            ],
                            [
                                2.293223007625045,
                                48.936692147192076
                            ],
                            [
                                2.291889887858929,
                                48.936495984487976
                            ],
                            [
                                2.2868880499039506,
                                48.93548014468368
                            ],
                            [
                                2.2840725145294414,
                                48.93488463795674
                            ],
                            [
                                2.282462114676207,
                                48.93447829139939
                            ],
                            [
                                2.2817049067975574,
                                48.934198050467245
                            ],
                            [
                                2.282035518688474,
                                48.93369361282535
                            ],
                            [
                                2.281139667114388,
                                48.93346941668136
                            ],
                            [
                                2.2808943744215924,
                                48.93360953938921
                            ],
                            [
                                2.2800411824460696,
                                48.93386876536195
                            ],
                            [
                                2.279152732251191,
                                48.933878376806945
                            ],
                            [
                                2.2762804454916363,
                                48.933415829627904
                            ],
                            [
                                2.2745257800396246,
                                48.933298356645906
                            ],
                            [
                                2.272558759842383,
                                48.93357735630414
                            ],
                            [
                                2.267942983842943,
                                48.93441434059051
                            ],
                            [
                                2.2673506445505325,
                                48.9341867409897
                            ],
                            [
                                2.26693712466664,
                                48.93409129568664
                            ],
                            [
                                2.2659312654903374,
                                48.93428952803441
                            ],
                            [
                                2.264903053887082,
                                48.93447307580337
                            ],
                            [
                                2.265283045131895,
                                48.93506042412699
                            ],
                            [
                                2.263696025803341,
                                48.93533941105218
                            ],
                            [
                                2.2621760608258796,
                                48.935420170455245
                            ],
                            [
                                2.2611366730104976,
                                48.93524396795294
                            ],
                            [
                                2.2587002585600544,
                                48.93446573279698
                            ],
                            [
                                2.2564203016334545,
                                48.93392977046483
                            ],
                            [
                                2.2532909619733346,
                                48.933320382642535
                            ],
                            [
                                2.250753961605625,
                                48.932769724598046
                            ],
                            [
                                2.248272832568489,
                                48.93209423989103
                            ],
                            [
                                2.2456352462834275,
                                48.93118379295083
                            ],
                            [
                                2.2443782270417216,
                                48.930613662102076
                            ],
                            [
                                2.243160019816912,
                                48.930077659293204
                            ],
                            [
                                2.241148301463454,
                                48.92907906337098
                            ],
                            [
                                2.2378312792733936,
                                48.927380542916865
                            ],
                            [
                                2.2349254591364627,
                                48.92591193219181
                            ],
                            [
                                2.233338436880814,
                                48.92514823810379
                            ],
                            [
                                2.2303432117770114,
                                48.923723623688346
                            ],
                            [
                                2.2281975374369267,
                                48.92265886184887
                            ],
                            [
                                2.2268005108027467,
                                48.92171886777621
                            ],
                            [
                                2.2260069996749223,
                                48.92066869722527
                            ],
                            [
                                2.225578057805677,
                                48.91935612887991
                            ],
                            [
                                2.2254774718883823,
                                48.91866577680946
                            ],
                            [
                                2.2245163175632854,
                                48.918357318544906
                            ],
                            [
                                2.223309286551597,
                                48.91794603789549
                            ],
                            [
                                2.222135571726227,
                                48.91678547908006
                            ],
                            [
                                2.221867342612569,
                                48.916271361561996
                            ],
                            [
                                2.219920266293883,
                                48.91430714267139
                            ],
                            [
                                2.2188026449863685,
                                48.91304381033771
                            ],
                            [
                                2.216686093434106,
                                48.9107612103696
                            ],
                            [
                                2.2151884789527685,
                                48.909093777550225
                            ],
                            [
                                2.21449555374312,
                                48.90823433156427
                            ],
                            [
                                2.2132773465174296,
                                48.9074850588994
                            ],
                            [
                                2.211947370516299,
                                48.90660354445188
                            ],
                            [
                                2.209745656541287,
                                48.90531797877236
                            ],
                            [
                                2.207319941281611,
                                48.9038924828626
                            ],
                            [
                                2.2056323331069336,
                                48.90279051166792
                            ],
                            [
                                2.204749411357369,
                                48.90171055587035
                            ],
                            [
                                2.206805834562971,
                                48.90111546846316
                            ],
                            [
                                2.2066940724317874,
                                48.90031466064622
                            ],
                            [
                                2.205799975385787,
                                48.90043955812229
                            ],
                            [
                                2.204056486146783,
                                48.90077751443556
                            ],
                            [
                                2.2025812260214366,
                                48.90039547669579
                            ],
                            [
                                2.1997536423319275,
                                48.898874644277214
                            ],
                            [
                                2.1978762487105143,
                                48.897544656224426
                            ],
                            [
                                2.1963786361581867,
                                48.89645724004171
                            ],
                            [
                                2.1948363096723256,
                                48.89548735671238
                            ],
                            [
                                2.1923440141576123,
                                48.894561546871444
                            ],
                            [
                                2.1905334758976664,
                                48.893892895177316
                            ],
                            [
                                2.187951770677813,
                                48.89291561978479
                            ],
                            [
                                2.186141230383271,
                                48.89226164334727
                            ],
                            [
                                2.1847330275361116,
                                48.89213672543636
                            ],
                            [
                                2.1820842650380996,
                                48.89208528855903
                            ],
                            [
                                2.1799272525636297,
                                48.89194567660459
                            ],
                            [
                                2.1782284681767408,
                                48.89146069637496
                            ],
                            [
                                2.176641445920211,
                                48.89110798052536
                            ],
                            [
                                2.174350320370536,
                                48.8905642061776
                            ],
                            [
                                2.173120936932719,
                                48.89042458728136
                            ],
                            [
                                2.170919222957707,
                                48.89003512198377
                            ],
                            [
                                2.1698239540762643,
                                48.8896970930403
                            ],
                            [
                                2.168952206736151,
                                48.88899163444634
                            ],
                            [
                                2.168169871821334,
                                48.888315560164415
                            ],
                            [
                                2.1678234092156288,
                                48.88789668346942
                            ],
                            [
                                2.1681251669683377,
                                48.887654174304856
                            ],
                            [
                                2.1693321979801112,
                                48.887294082770126
                            ],
                            [
                                2.169119849932514,
                                48.88694133752736
                            ],
                            [
                                2.1684828046672067,
                                48.88697073577799
                            ],
                            [
                                2.1679910512920912,
                                48.88642691564033
                            ],
                            [
                                2.167074601820019,
                                48.88630198315161
                            ],
                            [
                                2.1663816766094897,
                                48.88636077495019
                            ],
                            [
                                2.1659234518734536,
                                48.88619909733882
                            ],
                            [
                                2.1656552227597956,
                                48.88553033439297
                            ],
                            [
                                2.1657669848901264,
                                48.88477337213672
                            ],
                            [
                                2.166079916223964,
                                48.88382531577031
                            ],
                            [
                                2.1662252069933743,
                                48.88326676257884
                            ],
                            [
                                2.1661469735021797,
                                48.88245096973452
                            ],
                            [
                                2.165284788603202,
                                48.880964851618955
                            ],
                            [
                                2.165094792980824,
                                48.88038421693952
                            ],
                            [
                                2.1644018677702377,
                                48.87928172747871
                            ],
                            [
                                2.163641885281521,
                                48.87806161081443
                            ],
                            [
                                2.162689445700437,
                                48.877075056936036
                            ],
                            [
                                2.1613371239184005,
                                48.875928391534046
                            ],
                            [
                                2.160130091345735,
                                48.87498751701855
                            ],
                            [
                                2.158978941399141,
                                48.874340656363955
                            ],
                            [
                                2.1580289632879612,
                                48.873649682334474
                            ],
                            [
                                2.1574366231384943,
                                48.87280432682414
                            ],
                            [
                                2.157760733317332,
                                48.87215743794064
                            ],
                            [
                                2.15890070705089,
                                48.8712311961138
                            ],
                            [
                                2.1596048084744552,
                                48.87052547654616
                            ],
                            [
                                2.158721887641491,
                                48.87006234267005
                            ],
                            [
                                2.1593365821030943,
                                48.86902578749584
                            ],
                            [
                                2.160107740804875,
                                48.86743783447966
                            ],
                            [
                                2.160655375245142,
                                48.86583512709868
                            ],
                            [
                                2.1607336082888082,
                                48.865194227504674
                            ],
                            [
                                2.160767136927916,
                                48.86401052852824
                            ],
                            [
                                2.160612597105114,
                                48.863179462213424
                            ],
                            [
                                2.1604896587617475,
                                48.86254715383154
                            ],
                            [
                                2.160109667516963,
                                48.86151779751734
                            ],
                            [
                                2.1594906496381157,
                                48.86037432842858
                            ],
                            [
                                2.159133010820284,
                                48.85953610349938
                            ],
                            [
                                2.158820076854539,
                                48.85943316262774
                            ],
                            [
                                2.158339499692431,
                                48.85884492501526
                            ],
                            [
                                2.1567460830142693,
                                48.85667256530928
                            ],
                            [
                                2.155539052002524,
                                48.85513569523093
                            ],
                            [
                                2.154175558889335,
                                48.853422281804825
                            ],
                            [
                                2.1533485191224315,
                                48.85241479970995
                            ],
                            [
                                2.151985667340682,
                                48.85073975382795
                            ],
                            [
                                2.150879222246175,
                                48.84923211932443
                            ],
                            [
                                2.149984972908811,
                                48.84800432447656
                            ],
                            [
                                2.149202637994023,
                                48.84672460673187
                            ],
                            [
                                2.148040314730679,
                                48.844996198198515
                            ],
                            [
                                2.147034455554376,
                                48.84351781182704
                            ],
                            [
                                2.146098028427332,
                                48.84190431081757
                            ],
                            [
                                2.1456621561173677,
                                48.84125702249398
                            ],
                            [
                                2.1449915833328532,
                                48.83984472802359
                            ],
                            [
                                2.144723356311772,
                                48.83887375077731
                            ],
                            [
                                2.1449580567863222,
                                48.837557019017936
                            ],
                            [
                                2.145219872439128,
                                48.83665656365997
                            ],
                            [
                                2.145789859305438,
                                48.83542069467512
                            ],
                            [
                                2.1467398374165896,
                                48.8342362916207
                            ],
                            [
                                2.1473959331208903,
                                48.83341827710396
                            ],
                            [
                                2.1477871005787392,
                                48.83256488709176
                            ],
                            [
                                2.1489158980992897,
                                48.832550173343435
                            ],
                            [
                                2.150044702359992,
                                48.832299232005425
                            ],
                            [
                                2.1504358698178407,
                                48.8312471825283
                            ],
                            [
                                2.14843532767739,
                                48.831122112239996
                            ],
                            [
                                2.1487147330040273,
                                48.83051882764127
                            ],
                            [
                                2.149061195609761,
                                48.82913565992396
                            ],
                            [
                                2.1494212561690347,
                                48.82812131599084
                            ],
                            [
                                2.150080652740513,
                                48.826465845390885
                            ],
                            [
                                2.150725972743146,
                                48.82520060130457
                            ],
                            [
                                2.1514865109116954,
                                48.824048053738835
                            ],
                            [
                                2.152727880000299,
                                48.82259508221267
                            ],
                            [
                                2.153596171642903,
                                48.821470100126135
                            ],
                            [
                                2.1547093011067773,
                                48.82028152285565
                            ],
                            [
                                2.1562096318681654,
                                48.81835034562272
                            ],
                            [
                                2.157802225123959,
                                48.816342821983795
                            ],
                            [
                                2.158659556988084,
                                48.81497604301768
                            ],
                            [
                                2.159467240991205,
                                48.813671835980045
                            ],
                            [
                                2.162400218419748,
                                48.812453556843394
                            ],
                            [
                                2.1663391193773123,
                                48.81220759828702
                            ],
                            [
                                2.16730925806516,
                                48.81268303014596
                            ],
                            [
                                2.1693925117795345,
                                48.81591840630577
                            ],
                            [
                                2.174795958657313,
                                48.815064166586694
                            ],
                            [
                                2.1766572712565164,
                                48.81397963258064
                            ],
                            [
                                2.176070675770262,
                                48.81291736077608
                            ],
                            [
                                2.1759465904139006,
                                48.81192192410472
                            ],
                            [
                                2.176465501805893,
                                48.810919039528216
                            ],
                            [
                                2.1772664302572764,
                                48.809997853797654
                            ],
                            [
                                2.177920712155071,
                                48.80947039300733
                            ],
                            [
                                2.1784734655932425,
                                48.80938867324173
                            ],
                            [
                                2.1799286736251133,
                                48.80828172874698
                            ],
                            [
                                2.1806577332612562,
                                48.80736463476853
                            ],
                            [
                                2.1816842753610217,
                                48.80629479327581
                            ],
                            [
                                2.1827785015560153,
                                48.805336374223515
                            ],
                            [
                                2.1801952287702306,
                                48.804883162641346
                            ],
                            [
                                2.1820640991643643,
                                48.800335433699246
                            ],
                            [
                                2.184834870487663,
                                48.8008148978104
                            ],
                            [
                                2.1855158227623406,
                                48.80093089650313
                            ],
                            [
                                2.1866076945123325,
                                48.800652499189255
                            ],
                            [
                                2.1825806836483252,
                                48.79709506415335
                            ],
                            [
                                2.1964095921222224,
                                48.79737709478434
                            ],
                            [
                                2.1985228922846716,
                                48.79842114491893
                            ],
                            [
                                2.202529389425763,
                                48.79842177477235
                            ],
                            [
                                2.2042881783879693,
                                48.79740804885154
                            ],
                            [
                                2.204361461261044,
                                48.79589546732666
                            ],
                            [
                                2.205411849113318,
                                48.795074792307815
                            ],
                            [
                                2.2097599662694734,
                                48.79402881449133
                            ],
                            [
                                2.211494337654784,
                                48.79122870371788
                            ],
                            [
                                2.2060958326464117,
                                48.784871553275934
                            ],
                            [
                                2.2072927862449205,
                                48.78459793631913
                            ],
                            [
                                2.2094912724478775,
                                48.78751107553808
                            ],
                            [
                                2.211616489658468,
                                48.78800999644662
                            ],
                            [
                                2.2193356189917495,
                                48.78538659533535
                            ],
                            [
                                2.2217050985653373,
                                48.78622352347031
                            ],
                            [
                                2.2230730455354717,
                                48.786609793287
                            ],
                            [
                                2.224636413501628,
                                48.78483936554673
                            ],
                            [
                                2.224636413501628,
                                48.78390584213554
                            ],
                            [
                                2.2264440638761585,
                                48.78162023938697
                            ],
                            [
                                2.2236348870617917,
                                48.780654463260305
                            ],
                            [
                                2.2238791633065205,
                                48.7796403783268
                            ],
                            [
                                2.22424557767323,
                                48.7783204275662
                            ],
                            [
                                2.224856268285606,
                                48.77778921807507
                            ],
                            [
                                2.22659062962245,
                                48.7775638547746
                            ],
                            [
                                2.2338301732664547,
                                48.77688264322546
                            ],
                            [
                                2.237327680370896,
                                48.776505806054445
                            ],
                            [
                                2.238123364402611,
                                48.776325388200604
                            ],
                            [
                                2.240086623085517,
                                48.775309999957045
                            ],
                            [
                                2.242174138647613,
                                48.77396703557565
                            ],
                            [
                                2.245330263366327,
                                48.77219819840141
                            ],
                            [
                                2.244137397331457,
                                48.77164132935667
                            ],
                            [
                                2.2445598707183763,
                                48.769037065668385
                            ],
                            [
                                2.245142857330137,
                                48.766657695903604
                            ],
                            [
                                2.246087209607623,
                                48.76511793182536
                            ],
                            [
                                2.247876508660994,
                                48.76364364538233
                            ],
                            [
                                2.2498894700961216,
                                48.762464185067984
                            ],
                            [
                                2.2527970810574516,
                                48.76125193310327
                            ],
                            [
                                2.2560526112788466,
                                48.76069494266039
                            ],
                            [
                                2.2594815778852535,
                                48.76038368155892
                            ],
                            [
                                2.26534650256022,
                                48.76023624096541
                            ],
                            [
                                2.268626884157243,
                                48.76097343960768
                            ],
                            [
                                2.270913210725439,
                                48.76051473950159
                            ],
                            [
                                2.271534502149109,
                                48.760285387032724
                            ],
                            [
                                2.2773000213204853,
                                48.760285387032724
                            ],
                            [
                                2.280282186408755,
                                48.76044920972802
                            ],
                            [
                                2.2839104872667804,
                                48.76097343876239
                            ],
                            [
                                2.2858737459506244,
                                48.76097343876239
                            ],
                            [
                                2.2928071191952313,
                                48.76087357157812
                            ],
                            [
                                2.2977778524312953,
                                48.76183553604838
                            ],
                            [
                                2.2995647407961997,
                                48.762087149521506
                            ],
                            [
                                2.303288990469383,
                                48.762878748332525
                            ],
                            [
                                2.305645148425185,
                                48.76337975379107
                            ],
                            [
                                2.308031685612349,
                                48.76379057123276
                            ],
                            [
                                2.3099470140155063,
                                48.76370039135665
                            ],
                            [
                                2.3110870904452554,
                                48.763499991052385
                            ],
                            [
                                2.3129568167760226,
                                48.762968926231366
                            ],
                            [
                                2.3163010409714104,
                                48.76198694279856
                            ],
                            [
                                2.3208765544632968,
                                48.76049388779853
                            ],
                            [
                                2.3237495470668534,
                                48.75957198092385
                            ],
                            [
                                2.3284314609416015,
                                48.75801872998659
                            ],
                            [
                                2.329343522086077,
                                48.75806883560503
                            ],
                            [
                                2.3304840079583187,
                                48.75764408768299
                            ],
                            [
                                2.3334506317078763,
                                48.757194263865614
                            ],
                            [
                                2.3343702759431153,
                                48.758289461549225
                            ],
                            [
                                2.336862215161034,
                                48.76002999445231
                            ],
                            [
                                2.3395618159796356,
                                48.75930640944651
                            ],
                            [
                                2.341223108792377,
                                48.75750717992594
                            ],
                            [
                                2.3430327313186865,
                                48.75602081127926
                            ],
                            [
                                2.344634692244341,
                                48.755492748672594
                            ],
                            [
                                2.3458806618533004,
                                48.75578611747278
                            ],
                            [
                                2.3476012865519067,
                                48.756196830914774
                            ],
                            [
                                2.349499906908278,
                                48.75519937815926
                            ],
                            [
                                2.354769849541981,
                                48.754001341656306
                            ],
                            [
                                2.3583701162056627,
                                48.753973084128006
                            ],
                            [
                                2.360656010018573,
                                48.75412378878002
                            ],
                            [
                                2.3614703560499777,
                                48.75464183938766
                            ],
                            [
                                2.3626561581654926,
                                48.75462300127742
                            ],
                            [
                                2.363841960280922,
                                48.75415204622351
                            ],
                            [
                                2.3659791983384935,
                                48.75426749647727
                            ],
                            [
                                2.367962635971139,
                                48.75565560892849
                            ],
                            [
                                2.369915559178736,
                                48.75559525701098
                            ],
                            [
                                2.3715328237100266,
                                48.75452902784272
                            ],
                            [
                                2.3731195738163535,
                                48.75436808562506
                            ],
                            [
                                2.3758353576515105,
                                48.75489114594674
                            ],
                            [
                                2.3765677038540503,
                                48.754126671332244
                            ],
                            [
                                2.3786121703379024,
                                48.754126671332244
                            ],
                            [
                                2.3809007522217485,
                                48.7549313811308
                            ],
                            [
                                2.3827011033031624,
                                48.75613842166044
                            ],
                            [
                                2.3834029350806816,
                                48.75750636586827
                            ],
                            [
                                2.3828231610043815,
                                48.75911566430443
                            ],
                            [
                                2.3812317162154955,
                                48.76189832335663
                            ],
                            [
                                2.378101968592375,
                                48.76631905158516
                            ],
                            [
                                2.377654861789381,
                                48.76720315053569
                            ],
                            [
                                2.3778464789901363,
                                48.7684450727713
                            ],
                            [
                                2.3784226351565394,
                                48.76928032237001
                            ],
                            [
                                2.3803068709699176,
                                48.770332757290475
                            ],
                            [
                                2.383532427194666,
                                48.77088001473098
                            ],
                            [
                                2.388546410631193,
                                48.771385170151746
                            ],
                            [
                                2.3935603940689703,
                                48.77222708455881
                            ],
                            [
                                2.3971372484963354,
                                48.77285851109909
                            ],
                            [
                                2.398542440496044,
                                48.77355307078071
                            ],
                            [
                                2.4029815723291392,
                                48.77456332275193
                            ],
                            [
                                2.40528097874531,
                                48.77481588256819
                            ],
                            [
                                2.4081233005673823,
                                48.77485797574727
                            ],
                            [
                                2.4087939607724422,
                                48.775384137509576
                            ],
                            [
                                2.4101352811825336,
                                48.775152627013625
                            ],
                            [
                                2.412115325597483,
                                48.77572087814332
                            ],
                            [
                                2.4143508779483227,
                                48.77664691038535
                            ],
                            [
                                2.416682220565974,
                                48.77734141799729
                            ],
                            [
                                2.420067457791305,
                                48.77710991652759
                            ],
                            [
                                2.4263908254390287,
                                48.77660481870629
                            ],
                            [
                                2.4297121902641265,
                                48.776689002029144
                            ],
                            [
                                2.4341193858971337,
                                48.776941551151964
                            ],
                            [
                                2.4365784733152225,
                                48.777257235768474
                            ],
                            [
                                2.437440750722203,
                                48.77795173493661
                            ],
                            [
                                2.438941752132905,
                                48.778562044455185
                            ],
                            [
                                2.4398679019401754,
                                48.77887771888092
                            ],
                            [
                                2.439420795136016,
                                48.781045296319036
                            ],
                            [
                                2.4395166037376157,
                                48.782539397139885
                            ],
                            [
                                2.4392930643908244,
                                48.7837599015522
                            ],
                            [
                                2.4390375747886424,
                                48.7874422649532
                            ],
                            [
                                2.439324947706382,
                                48.790091323106424
                            ],
                            [
                                2.439931735511209,
                                48.79213218039692
                            ],
                            [
                                2.4406982043165897,
                                48.79438333905571
                            ],
                            [
                                2.441560481723627,
                                48.79598223151743
                            ],
                            [
                                2.4428059935320903,
                                48.79770729517978
                            ],
                            [
                                2.4440514553641037,
                                48.79899051704709
                            ],
                            [
                                2.444722115569192,
                                48.80027372480666
                            ],
                            [
                                2.4452330947735845,
                                48.801451722700534
                            ],
                            [
                                2.444658243168959,
                                48.80281900699066
                            ],
                            [
                                2.4457440739768117,
                                48.80374453212369
                            ],
                            [
                                2.447021521986642,
                                48.803197632972996
                            ],
                            [
                                2.4478837993936793,
                                48.80462797199985
                            ],
                            [
                                2.447915733506335,
                                48.80648813911847
                            ],
                            [
                                2.4481712231085737,
                                48.80884381532431
                            ],
                            [
                                2.448235095508778,
                                48.81138866258118
                            ],
                            [
                                2.448139286908429,
                                48.8126925838566
                            ],
                            [
                                2.447564435303775,
                                48.81389132027101
                            ],
                            [
                                2.446797966498366,
                                48.81500590911662
                            ],
                            [
                                2.449033500515611,
                                48.81599429736207
                            ],
                            [
                                2.4502789853856655,
                                48.81723499681192
                            ],
                            [
                                2.4519716039983734,
                                48.81742425573859
                            ],
                            [
                                2.4550055430211444,
                                48.81860185078449
                            ],
                            [
                                2.4581991630444975,
                                48.81990558445895
                            ],
                            [
                                2.460658250463837,
                                48.82112517558457
                            ],
                            [
                                2.462051717976891,
                                48.8226692855676
                            ],
                            [
                                2.462445028227961,
                                48.824111998539934
                            ],
                            [
                                2.4629505943337904,
                                48.82509225971478
                            ],
                            [
                                2.4641586186740767,
                                48.826368446223796
                            ],
                            [
                                2.465282362246711,
                                48.82677533943709
                            ],
                            [
                                2.466349918639878,
                                48.827034269761924
                            ],
                            [
                                2.4683726570704323,
                                48.827607610719696
                            ],
                            [
                                2.470620144215701,
                                48.82805148308472
                            ],
                            [
                                2.472895724949126,
                                48.828532340376825
                            ],
                            [
                                2.475564615933621,
                                48.82884674457276
                            ],
                            [
                                2.4782895862836654,
                                48.829457035443255
                            ],
                            [
                                2.482896934931091,
                                48.830289262185914
                            ],
                            [
                                2.486043416933228,
                                48.8308810594597
                            ],
                            [
                                2.4887684950972186,
                                48.83102900768583
                            ],
                            [
                                2.490538391222742,
                                48.831491343077346
                            ],
                            [
                                2.491549760438545,
                                48.83239750806902
                            ],
                            [
                                2.4922240065816936,
                                48.833470090347646
                            ],
                            [
                                2.492589223242504,
                                48.83474606349543
                            ],
                            [
                                2.4932915629759407,
                                48.835559710562734
                            ],
                            [
                                2.49343203092198,
                                48.83639183593965
                            ],
                            [
                                2.493094895685573,
                                48.83785264063266
                            ],
                            [
                                2.4924206495424244,
                                48.83984962690556
                            ],
                            [
                                2.4920554328816706,
                                48.842475172885344
                            ],
                            [
                                2.4916902281307785,
                                48.84452744285474
                            ],
                            [
                                2.491015981987573,
                                48.84687542961575
                            ],
                            [
                                2.4902574550768293,
                                48.84900146438312
                            ],
                            [
                                2.4890494479032554,
                                48.851571077658775
                            ],
                            [
                                2.4878976107425217,
                                48.852070194947544
                            ],
                            [
                                2.487841423562969,
                                48.85268022043215
                            ],
                            [
                                2.4880099850992963,
                                48.85426994889178
                            ],
                            [
                                2.487504300491395,
                                48.856580510714
                            ],
                            [
                                2.4863805569187605,
                                48.85853978357855
                            ],
                            [
                                2.4849758774540476,
                                48.859685737822076
                            ],
                            [
                                2.4834869533598294,
                                48.86062838535585
                            ],
                            [
                                2.482363209787195,
                                48.86068383304519
                            ],
                            [
                                2.481464214929275,
                                48.86096107056932
                            ],
                            [
                                2.48126755980482,
                                48.861829738201806
                            ],
                            [
                                2.4818294315911658,
                                48.86301258052265
                            ],
                            [
                                2.4819137123587893,
                                48.86377032419381
                            ],
                            [
                                2.481098998268493,
                                48.865248815397166
                            ],
                            [
                                2.480818603725595,
                                48.86650585831987
                            ],
                            [
                                2.4809871652607853,
                                48.86768859012301
                            ],
                            [
                                2.4816895049942786,
                                48.869702865877684
                            ],
                            [
                                2.4813523819227044,
                                48.87110726704938
                            ],
                            [
                                2.47932964349215,
                                48.87262249778763
                            ],
                            [
                                2.4788239588842202,
                                48.873564874246824
                            ],
                            [
                                2.47803733838316,
                                48.87524632540439
                            ],
                            [
                                2.4766045653292394,
                                48.87663209431463
                            ],
                            [
                                2.4760708309826214,
                                48.87762985000512
                            ],
                            [
                                2.475565146375857,
                                48.87905250443862
                            ],
                            [
                                2.47477852587474,
                                48.880290365635204
                            ],
                            [
                                2.473149097694204,
                                48.88149124647424
                            ],
                            [
                                2.4706206746567716,
                                48.88252582838405
                            ],
                            [
                                2.468317000333087,
                                48.88311700843798
                            ],
                            [
                                2.4664628351515603,
                                48.88453951292681
                            ],
                            [
                                2.4684293864026188,
                                48.884853565555034
                            ],
                            [
                                2.470311656887162,
                                48.885370824408454
                            ],
                            [
                                2.471238745334233,
                                48.886054336829716
                            ],
                            [
                                2.4718287107097865,
                                48.886977987421005
                            ],
                            [
                                2.472643424800083,
                                48.88705187873143
                            ],
                            [
                                2.4718287107097865,
                                48.887901620951766
                            ],
                            [
                                2.471744429942163,
                                48.88871440434684
                            ],
                            [
                                2.4723063017274,
                                48.88995202641314
                            ],
                            [
                                2.4724748632637272,
                                48.89155904218808
                            ],
                            [
                                2.471884897888202,
                                48.89318447686301
                            ],
                            [
                                2.4708735286734225,
                                48.89458821882832
                            ],
                            [
                                2.469328381260482,
                                48.89682304246779
                            ],
                            [
                                2.4688226853812694,
                                48.89816187053714
                            ],
                            [
                                2.4686822174352585,
                                48.89967628176166
                            ],
                            [
                                2.468738404613674,
                                48.90131992006741
                            ],
                            [
                                2.4671651636126057,
                                48.90242796033303
                            ],
                            [
                                2.4659852328615273,
                                48.90373910958036
                            ],
                            [
                                2.4642996175025758,
                                48.904939427322745
                            ],
                            [
                                2.4617431008760207,
                                48.90521641963059
                            ],
                            [
                                2.461237416268091,
                                48.90244642746259
                            ],
                            [
                                2.4498594709992005,
                                48.900230317254085
                            ],
                            [
                                2.4240695560131655,
                                48.895465360313835
                            ],
                            [
                                2.4244066713721395,
                                48.896758220525186
                            ],
                            [
                                2.421372563725754,
                                48.9020955424385
                            ],
                            [
                                2.4115678954414363,
                                48.906176658485975
                            ],
                            [
                                2.4145458507705655,
                                48.90890952252437
                            ],
                            [
                                2.413084984126357,
                                48.9096850355005
                            ],
                            [
                                2.413955885395012,
                                48.910534393044685
                            ],
                            [
                                2.413506387966663,
                                48.910866746414115
                            ],
                            [
                                2.414911067432456,
                                48.91112524195103
                            ],
                            [
                                2.4154167520392775,
                                48.91191918416612
                            ],
                            [
                                2.413955885395012,
                                48.91278696703961
                            ],
                            [
                                2.4133659200195154,
                                48.913562419833255
                            ],
                            [
                                2.4133940136087233,
                                48.914337860590166
                            ],
                            [
                                2.4129726097695254,
                                48.915371762876816
                            ],
                            [
                                2.4100789700702308,
                                48.91668257251294
                            ],
                            [
                                2.411371275179249,
                                48.91784565648791
                            ],
                            [
                                2.4133658952795827,
                                48.91937792474985
                            ],
                            [
                                2.415810037549363,
                                48.921242437732076
                            ],
                            [
                                2.41718662342592,
                                48.92308842161779
                            ],
                            [
                                2.418226086229879,
                                48.924583618548866
                            ],
                            [
                                2.419658859284908,
                                48.926614061224655
                            ],
                            [
                                2.4159785990856903,
                                48.92761079378079
                            ]
                        ],
                        [
                            [
                                2.2906344087930677,
                                48.95130484941643
                            ],
                            [
                                2.2921303234284096,
                                48.95066016660627
                            ],
                            [
                                2.3001241172607934,
                                48.950445270484664
                            ],
                            [
                                2.3056403024792758,
                                48.949340075812756
                            ],
                            [
                                2.306341512463831,
                                48.95142764519727
                            ],
                            [
                                2.3011058112402623,
                                48.95204161957034
                            ],
                            [
                                2.294654679375384,
                                48.95188812668542
                            ],
                            [
                                2.2906344087930677,
                                48.95130484941643
                            ]
                        ],
                        [
                            [
                                2.4167867634685365,
                                48.93831627606684
                            ],
                            [
                                2.413183851210988,
                                48.93749490284213
                            ],
                            [
                                2.40992003657891,
                                48.936589984489046
                            ],
                            [
                                2.4053846058558577,
                                48.9355597497794
                            ],
                            [
                                2.4041129897649967,
                                48.93504462445094
                            ],
                            [
                                2.4036255369303774,
                                48.934042203170634
                            ],
                            [
                                2.403540762524642,
                                48.93285876325109
                            ],
                            [
                                2.40364673053179,
                                48.9317449118179
                            ],
                            [
                                2.4034771817195235,
                                48.9304778756013
                            ],
                            [
                                2.4033102815502048,
                                48.929323363500515
                            ],
                            [
                                2.403606991971884,
                                48.927930946732175
                            ],
                            [
                                2.4041156384078874,
                                48.9272904219801
                            ],
                            [
                                2.409011360356942,
                                48.92811196310768
                            ],
                            [
                                2.412783822630189,
                                48.928738553226054
                            ],
                            [
                                2.4155528980440693,
                                48.929062463757674
                            ],
                            [
                                2.4172271925638142,
                                48.92909031184237
                            ],
                            [
                                2.419473714323857,
                                48.92960549861016
                            ],
                            [
                                2.4169092885406656,
                                48.93052446721356
                            ],
                            [
                                2.415913189270043,
                                48.93059408535768
                            ],
                            [
                                2.4128613106522607,
                                48.93252943091153
                            ],
                            [
                                2.413603086705706,
                                48.93303065925588
                            ],
                            [
                                2.415722446930033,
                                48.932919275687965
                            ],
                            [
                                2.417735839073572,
                                48.9335875738102
                            ],
                            [
                                2.4133911507642267,
                                48.93546711431492
                            ],
                            [
                                2.4139421779397594,
                                48.93591442684976
                            ],
                            [
                                2.4164430229179175,
                                48.93573343876133
                            ],
                            [
                                2.4157648276695625,
                                48.9372091686526
                            ],
                            [
                                2.4172059925719225,
                                48.93853171969184
                            ],
                            [
                                2.4168457013459204,
                                48.93840642688542
                            ],
                            [
                                2.4167867634685365,
                                48.93831627606684
                            ]
                        ],
                        [
                            [
                                2.399624941119157,
                                48.92667797265278
                            ],
                            [
                                2.4023379259018895,
                                48.927047923475754
                            ],
                            [
                                2.402773027234332,
                                48.92745150306564
                            ],
                            [
                                2.4026706504504887,
                                48.928258652460045
                            ],
                            [
                                2.402440302685733,
                                48.92903215838743
                            ],
                            [
                                2.4024658968186827,
                                48.92980565236425
                            ],
                            [
                                2.4027218387793425,
                                48.930949056246476
                            ],
                            [
                                2.402875403956159,
                                48.931840220526084
                            ],
                            [
                                2.4027474329753034,
                                48.93252960084919
                            ],
                            [
                                2.4027331510314696,
                                48.93349679786584
                            ],
                            [
                                2.4026694599201335,
                                48.934493956594594
                            ],
                            [
                                2.4028286812217914,
                                48.935072717163365
                            ],
                            [
                                2.4036566319939254,
                                48.9360628702878
                            ],
                            [
                                2.4047287220962232,
                                48.936355728715995
                            ],
                            [
                                2.406637805931524,
                                48.93687994451463
                            ],
                            [
                                2.409769311389425,
                                48.93754665175547
                            ],
                            [
                                2.41217886043097,
                                48.9380626239238
                            ],
                            [
                                2.4146627127467184,
                                48.93864134311826
                            ],
                            [
                                2.4168705760517355,
                                48.939101534496416
                            ],
                            [
                                2.416700739995747,
                                48.93940831984483
                            ],
                            [
                                2.4179957399212526,
                                48.94007766315062
                            ],
                            [
                                2.417242092424061,
                                48.94141632283444
                            ],
                            [
                                2.41435164127779,
                                48.94265839128366
                            ],
                            [
                                2.4122499200870777,
                                48.944715064962196
                            ],
                            [
                                2.4090108439503695,
                                48.946052639428416
                            ],
                            [
                                2.4057715716715506,
                                48.94959971801387
                            ],
                            [
                                2.4060475552619778,
                                48.95015041995342
                            ],
                            [
                                2.404083825866877,
                                48.951398190467984
                            ],
                            [
                                2.4021307112260217,
                                48.9501434490808
                            ],
                            [
                                2.3981194977798452,
                                48.94816947551601
                            ],
                            [
                                2.3974826125706272,
                                48.94858774269838
                            ],
                            [
                                2.3981725715471214,
                                48.948936296005655
                            ],
                            [
                                2.3972066289800864,
                                48.94957762772847
                            ],
                            [
                                2.395455194654744,
                                48.94914542682088
                            ],
                            [
                                2.3938205226181424,
                                48.947911542339625
                            ],
                            [
                                2.3897497386650457,
                                48.94671654484088
                            ],
                            [
                                2.3900469517624003,
                                48.945454714192095
                            ],
                            [
                                2.3876267879673776,
                                48.94461115215091
                            ],
                            [
                                2.3859071895821273,
                                48.94401855487544
                            ],
                            [
                                2.3836780913500206,
                                48.94357933402651
                            ],
                            [
                                2.3821071078340594,
                                48.94546168187625
                            ],
                            [
                                2.3796407328952967,
                                48.94495275204176
                            ],
                            [
                                2.3781865116672236,
                                48.944903950829286
                            ],
                            [
                                2.3769976592770945,
                                48.94479937664306
                            ],
                            [
                                2.376078127175134,
                                48.9460240925591
                            ],
                            [
                                2.375218332142623,
                                48.9478296650928
                            ],
                            [
                                2.371832225780679,
                                48.947153454460135
                            ],
                            [
                                2.371651774972065,
                                48.947515960268305
                            ],
                            [
                                2.365230722985075,
                                48.94764504567968
                            ],
                            [
                                2.3652413377384676,
                                48.94677363529698
                            ],
                            [
                                2.362493549666823,
                                48.94704967758537
                            ],
                            [
                                2.361819890892292,
                                48.94767163994396
                            ],
                            [
                                2.359584124813864,
                                48.947825526342
                            ],
                            [
                                2.3598965462741717,
                                48.948710363917996
                            ],
                            [
                                2.3556373910285515,
                                48.94975490629412
                            ],
                            [
                                2.356810685221916,
                                48.951875936977416
                            ],
                            [
                                2.355502420354668,
                                48.95209392419781
                            ],
                            [
                                2.3530323381808103,
                                48.9503179417585
                            ],
                            [
                                2.35200720526268,
                                48.95051028947992
                            ],
                            [
                                2.351452361508535,
                                48.950179745819554
                            ],
                            [
                                2.3504662812729293,
                                48.948621697633854
                            ],
                            [
                                2.348035126313647,
                                48.94718789523503
                            ],
                            [
                                2.344757558483792,
                                48.94593635681005
                            ],
                            [
                                2.339090109267943,
                                48.94740387541739
                            ],
                            [
                                2.336977511710586,
                                48.9451358739241
                            ],
                            [
                                2.338440079250404,
                                48.944668919644386
                            ],
                            [
                                2.3393948108383995,
                                48.94506916643758
                            ],
                            [
                                2.340633930559676,
                                48.944055201659694
                            ],
                            [
                                2.338440079250404,
                                48.9426676375262
                            ],
                            [
                                2.3367946907679595,
                                48.94394846732715
                            ],
                            [
                                2.3343773807365267,
                                48.94517590977412
                            ],
                            [
                                2.328182521615844,
                                48.946921067248496
                            ],
                            [
                                2.3213572064305765,
                                48.94862868745753
                            ],
                            [
                                2.3145774489557027,
                                48.950123187872265
                            ],
                            [
                                2.3077724472092314,
                                48.95113702935373
                            ],
                            [
                                2.30683804085524,
                                48.949042637182885
                            ],
                            [
                                2.3125577858896804,
                                48.94777906372724
                            ],
                            [
                                2.3197081160832624,
                                48.94672513083779
                            ],
                            [
                                2.326645584535811,
                                48.945516712661714
                            ],
                            [
                                2.331337988724812,
                                48.94403579677339
                            ],
                            [
                                2.3350314615279046,
                                48.94148396541635
                            ],
                            [
                                2.336229954373266,
                                48.938281671150776
                            ],
                            [
                                2.33681980051324,
                                48.93365895848612
                            ],
                            [
                                2.3351337851549943,
                                48.93016256225178
                            ],
                            [
                                2.333657225505391,
                                48.927677844342156
                            ],
                            [
                                2.3369073755934267,
                                48.92697049310627
                            ],
                            [
                                2.3395684359776965,
                                48.926036240268616
                            ],
                            [
                                2.3420466754202494,
                                48.92455474636276
                            ],
                            [
                                2.3444937127959236,
                                48.92346647459701
                            ],
                            [
                                2.348443692910365,
                                48.923049780225114
                            ],
                            [
                                2.352158123752872,
                                48.9230974026153
                            ],
                            [
                                2.356089634495902,
                                48.922894991562885
                            ],
                            [
                                2.360764381604156,
                                48.922561632529465
                            ],
                            [
                                2.3613804335492716,
                                48.92283546332743
                            ],
                            [
                                2.364152667299294,
                                48.9225497268086
                            ],
                            [
                                2.368918004196985,
                                48.921692495012934
                            ],
                            [
                                2.373773943054289,
                                48.92135912795305
                            ],
                            [
                                2.3753684304398917,
                                48.92187108359445
                            ],
                            [
                                2.3786490057717913,
                                48.92270465835523
                            ],
                            [
                                2.3829976077341257,
                                48.923752347682154
                            ],
                            [
                                2.3890041315611654,
                                48.92479817854027
                            ],
                            [
                                2.395345842755461,
                                48.92578629923014
                            ],
                            [
                                2.3978597646761557,
                                48.926232542904586
                            ],
                            [
                                2.399624941119157,
                                48.92667797265278
                            ]
                        ],
                        [
                            [
                                2.2197352210403096,
                                48.920220653486666
                            ],
                            [
                                2.2162284648860293,
                                48.917988588632284
                            ],
                            [
                                2.2113438504884186,
                                48.91412279062038
                            ],
                            [
                                2.204988599498364,
                                48.91046381337557
                            ],
                            [
                                2.1947749335405717,
                                48.906371959739886
                            ],
                            [
                                2.1980247284975007,
                                48.90411777079737
                            ],
                            [
                                2.2006213008108944,
                                48.90314092390622
                            ],
                            [
                                2.203364848915129,
                                48.90276520847928
                            ],
                            [
                                2.204556985888331,
                                48.90328047463103
                            ],
                            [
                                2.2067616227572557,
                                48.904396866402436
                            ],
                            [
                                2.209436562811618,
                                48.90606471938071
                            ],
                            [
                                2.2116560629453375,
                                48.90754566888816
                            ],
                            [
                                2.213175364642865,
                                48.90857900594622
                            ],
                            [
                                2.2141169707608412,
                                48.90952942329983
                            ],
                            [
                                2.215663895096185,
                                48.91112077932175
                            ],
                            [
                                2.217210819432921,
                                48.91284469114939
                            ],
                            [
                                2.2197688498301034,
                                48.91571250104778
                            ],
                            [
                                2.2209794862663443,
                                48.91734785827245
                            ],
                            [
                                2.2218538348047048,
                                48.918695882755884
                            ],
                            [
                                2.2197352210403096,
                                48.920220653486666
                            ]
                        ],
                        [
                            [
                                2.1938109847287137,
                                48.90543296463733
                            ],
                            [
                                2.193146091575045,
                                48.90517640118253
                            ],
                            [
                                2.192584656610933,
                                48.90482845247547
                            ],
                            [
                                2.192616738608706,
                                48.90482845247547
                            ],
                            [
                                2.190242670762217,
                                48.90422744445672
                            ],
                            [
                                2.190242670762217,
                                48.90421690039193
                            ],
                            [
                                2.183633788628214,
                                48.902772343854195
                            ],
                            [
                                2.1762945230780417,
                                48.90018218000969
                            ],
                            [
                                2.170992240659899,
                                48.89695077377374
                            ],
                            [
                                2.167735917871198,
                                48.894535773285014
                            ],
                            [
                                2.1662648980387758,
                                48.8914345383686
                            ],
                            [
                                2.1636822972051846,
                                48.88872394690563
                            ],
                            [
                                2.165527012086642,
                                48.88836533625073
                            ],
                            [
                                2.166553636020268,
                                48.888418073272845
                            ],
                            [
                                2.167532136957192,
                                48.88896653500791
                            ],
                            [
                                2.1688154168732865,
                                48.88985249894867
                            ],
                            [
                                2.16995432780044,
                                48.89037985098412
                            ],
                            [
                                2.1713017717133596,
                                48.89075954100542
                            ],
                            [
                                2.1744509651797443,
                                48.891417673010864
                            ],
                            [
                                2.1766004590403156,
                                48.89202938170649
                            ],
                            [
                                2.1792793058673112,
                                48.892546164254526
                            ],
                            [
                                2.1809064994223775,
                                48.89272022347268
                            ],
                            [
                                2.183232444272221,
                                48.89269913048551
                            ],
                            [
                                2.1858631681025145,
                                48.89292060640764
                            ],
                            [
                                2.1875314319947563,
                                48.89347956508644
                            ],
                            [
                                2.189648843857583,
                                48.89441817975262
                            ],
                            [
                                2.191452924446196,
                                48.89495131260395
                            ],
                            [
                                2.193778869294789,
                                48.895794987143375
                            ],
                            [
                                2.195382969191428,
                                48.89668083008593
                            ],
                            [
                                2.197003110086996,
                                48.8978513841829
                            ],
                            [
                                2.1983826359977456,
                                48.89906409149853
                            ],
                            [
                                2.1991526373824968,
                                48.89987606816888
                            ],
                            [
                                2.199457416362918,
                                48.900550942446756
                            ],
                            [
                                2.1987355714097987,
                                48.90132070979496
                            ],
                            [
                                2.1987676534075717,
                                48.90231189960414
                            ],
                            [
                                2.197949562460934,
                                48.902986740992105
                            ],
                            [
                                2.196088806580576,
                                48.904262588091854
                            ],
                            [
                                2.1938109847287137,
                                48.90543296463733
                            ]
                        ],
                        [
                            [
                                2.163048722970956,
                                48.887713273932974
                            ],
                            [
                                2.163067776435952,
                                48.88680220588412
                            ],
                            [
                                2.1635408812679486,
                                48.88486054050392
                            ],
                            [
                                2.162655230146868,
                                48.882321641810336
                            ],
                            [
                                2.1593598102786586,
                                48.881227354875534
                            ],
                            [
                                2.157630530546129,
                                48.879982842597144
                            ],
                            [
                                2.1546591865248956,
                                48.87584982705755
                            ],
                            [
                                2.151705062017669,
                                48.87202518397825
                            ],
                            [
                                2.1483117583925946,
                                48.86953567054255
                            ],
                            [
                                2.148686979466561,
                                48.868795231009614
                            ],
                            [
                                2.1505141429571495,
                                48.868634264444125
                            ],
                            [
                                2.152161853005481,
                                48.868526953126974
                            ],
                            [
                                2.15418478401358,
                                48.86909570056133
                            ],
                            [
                                2.156468738376475,
                                48.8698683282291
                            ],
                            [
                                2.155767238107643,
                                48.87091994116162
                            ],
                            [
                                2.1556707158532618,
                                48.87199538002727
                            ],
                            [
                                2.1558338554513625,
                                48.87312205942419
                            ],
                            [
                                2.1570737163909257,
                                48.87431309291199
                            ],
                            [
                                2.1593087288761126,
                                48.87545044977506
                            ],
                            [
                                2.1617183353584153,
                                48.877349843444364
                            ],
                            [
                                2.1636107546890457,
                                48.87948491909728
                            ],
                            [
                                2.1648382152368413,
                                48.881816376412075
                            ],
                            [
                                2.1652511080036163,
                                48.88330427033233
                            ],
                            [
                                2.1649737702209393,
                                48.884827619782556
                            ],
                            [
                                2.1648269445825292,
                                48.88616855784963
                            ],
                            [
                                2.165120595858042,
                                48.8871876467328
                            ],
                            [
                                2.163048722970956,
                                48.887713273932974
                            ]
                        ],
                        [
                            [
                                2.158080479890856,
                                48.869610931936364
                            ],
                            [
                                2.1577425734778046,
                                48.86924895402413
                            ],
                            [
                                2.156941252528128,
                                48.868886973480244
                            ],
                            [
                                2.156004769041658,
                                48.86797248468551
                            ],
                            [
                                2.1551069034300667,
                                48.86698176963117
                            ],
                            [
                                2.15440213013747,
                                48.86636117446264
                            ],
                            [
                                2.154025605848801,
                                48.86530056640376
                            ],
                            [
                                2.1536394270912353,
                                48.864532087838654
                            ],
                            [
                                2.153446355504826,
                                48.86432250627388
                            ],
                            [
                                2.154701436466553,
                                48.86374454938914
                            ],
                            [
                                2.1539194244824955,
                                48.86326820628
                            ],
                            [
                                2.1530891401539236,
                                48.86260766966586
                            ],
                            [
                                2.152925014181875,
                                48.862042395832844
                            ],
                            [
                                2.1527995060854153,
                                48.86158509098212
                            ],
                            [
                                2.1521816200734065,
                                48.86114683657928
                            ],
                            [
                                2.1506176095297747,
                                48.86041640852176
                            ],
                            [
                                2.1499224877658207,
                                48.85975583426972
                            ],
                            [
                                2.1504438290887435,
                                48.859381081539794
                            ],
                            [
                                2.151428584919728,
                                48.85903173324715
                            ],
                            [
                                2.1509555159420586,
                                48.8581742316467
                            ],
                            [
                                2.150395556743547,
                                48.85809800857106
                            ],
                            [
                                2.1502024673647213,
                                48.857386587603486
                            ],
                            [
                                2.150038333653896,
                                48.85690842067862
                            ],
                            [
                                2.1510906707686672,
                                48.85648918172623
                            ],
                            [
                                2.150472784756772,
                                48.85612710889663
                            ],
                            [
                                2.153563969445628,
                                48.853466656980686
                            ],
                            [
                                2.1543845993052457,
                                48.85454657296276
                            ],
                            [
                                2.155838790982557,
                                48.8563069510146
                            ],
                            [
                                2.1571904166332843,
                                48.85798389186269
                            ],
                            [
                                2.15849275551102,
                                48.85973567856203
                            ],
                            [
                                2.1589754789575863,
                                48.86049787915542
                            ],
                            [
                                2.159681711203035,
                                48.86199582385743
                            ],
                            [
                                2.160116162305144,
                                48.86308825843753
                            ],
                            [
                                2.160190273987922,
                                48.8647000829786
                            ],
                            [
                                2.159968221202462,
                                48.866059197775826
                            ],
                            [
                                2.159334605224643,
                                48.86761075845649
                            ],
                            [
                                2.1585429387716886,
                                48.86919206289167
                            ],
                            [
                                2.158080479890856,
                                48.869610931936364
                            ]
                        ],
                        [
                            [
                                2.153125379928383,
                                48.820819569223545
                            ],
                            [
                                2.1513739669101426,
                                48.81931775907432
                            ],
                            [
                                2.151088853163202,
                                48.81788295199175
                            ],
                            [
                                2.15263661350491,
                                48.816662663183365
                            ],
                            [
                                2.1554266551737555,
                                48.81585806090612
                            ],
                            [
                                2.1575242777424535,
                                48.814972983484495
                            ],
                            [
                                2.157320625065836,
                                48.81557644705799
                            ],
                            [
                                2.153675242155458,
                                48.82028321362148
                            ],
                            [
                                2.153654876887913,
                                48.82028321362148
                            ],
                            [
                                2.153491954746414,
                                48.82059161879383
                            ],
                            [
                                2.153125379928383,
                                48.820819569223545
                            ]
                        ],
                        [
                            [
                                2.237008368322904,
                                48.77542292345058
                            ],
                            [
                                2.2364918287368027,
                                48.774730760091586
                            ],
                            [
                                2.2370428042952426,
                                48.77360739268778
                            ],
                            [
                                2.237507689922836,
                                48.773096762831244
                            ],
                            [
                                2.2383341532625423,
                                48.77282442478429
                            ],
                            [
                                2.2402797857052406,
                                48.772007401779376
                            ],
                            [
                                2.2406069274435367,
                                48.77260882278259
                            ],
                            [
                                2.2409685051542283,
                                48.773391794048365
                            ],
                            [
                                2.2400731698699587,
                                48.774208794525066
                            ],
                            [
                                2.238489115137952,
                                48.7751619449441
                            ],
                            [
                                2.237008368322904,
                                48.77542292345058
                            ]
                        ],
                        [
                            [
                                2.2779809966023947,
                                48.759682720810986
                            ],
                            [
                                2.2763448591708766,
                                48.7573282208495
                            ],
                            [
                                2.274868344903581,
                                48.75532878227992
                            ],
                            [
                                2.2773624568417006,
                                48.75402647357427
                            ],
                            [
                                2.2773425039463007,
                                48.7537370670546
                            ],
                            [
                                2.279537322451432,
                                48.75313193894576
                            ],
                            [
                                2.281353035942601,
                                48.7511718000313
                            ],
                            [
                                2.282098150099756,
                                48.750299215104064
                            ],
                            [
                                2.285091084425261,
                                48.7473259209014
                            ],
                            [
                                2.284053533858952,
                                48.746154973572686
                            ],
                            [
                                2.2846321678288177,
                                48.74560238221133
                            ],
                            [
                                2.2824373506942095,
                                48.7446945411898
                            ],
                            [
                                2.278626347652988,
                                48.74252354851399
                            ],
                            [
                                2.2750348264622176,
                                48.74062878736078
                            ],
                            [
                                2.2750148724316546,
                                48.738852382014755
                            ],
                            [
                                2.2758728469382845,
                                48.73739173658984
                            ],
                            [
                                2.2774291727881177,
                                48.73520726873966
                            ],
                            [
                                2.279564132607021,
                                48.732851621740394
                            ],
                            [
                                2.2801028607851492,
                                48.7326147349288
                            ],
                            [
                                2.2809408823963793,
                                48.733206949864524
                            ],
                            [
                                2.2823774908728467,
                                48.733746517399624
                            ],
                            [
                                2.2845124514529402,
                                48.73423343892358
                            ],
                            [
                                2.2881672775300217,
                                48.7350625278805
                            ],
                            [
                                2.2913198350197206,
                                48.735891586471524
                            ],
                            [
                                2.2939735701221196,
                                48.7367206313904
                            ],
                            [
                                2.2968866928658542,
                                48.73774704901385
                            ],
                            [
                                2.29890193531142,
                                48.73858922218048
                            ],
                            [
                                2.301196535885225,
                                48.73970772917713
                            ],
                            [
                                2.3018350285412907,
                                48.74002353344963
                            ],
                            [
                                2.301655452482464,
                                48.73862871627492
                            ],
                            [
                                2.301232996225167,
                                48.736542766522604
                            ],
                            [
                                2.3010534201654593,
                                48.73585847404303
                            ],
                            [
                                2.302090970731797,
                                48.73439774162449
                            ],
                            [
                                2.3030886155071926,
                                48.732199983157926
                            ],
                            [
                                2.303988061681423,
                                48.729426366363384
                            ],
                            [
                                2.3059234925451904,
                                48.72934739883789
                            ],
                            [
                                2.308457510274252,
                                48.73105833413098
                            ],
                            [
                                2.312044529324538,
                                48.73342336010671
                            ],
                            [
                                2.3142792536205548,
                                48.73497623945903
                            ],
                            [
                                2.3153168041868355,
                                48.736042171251654
                            ],
                            [
                                2.3157956736795597,
                                48.73748969675802
                            ],
                            [
                                2.3146583486018812,
                                48.73708176578123
                            ],
                            [
                                2.313580892244744,
                                48.73814765293014
                            ],
                            [
                                2.317332036599737,
                                48.74091095880408
                            ],
                            [
                                2.3147581130797903,
                                48.7433188586258
                            ],
                            [
                                2.317172413435486,
                                48.74443724280604
                            ],
                            [
                                2.3219361264163183,
                                48.748206617516445
                            ],
                            [
                                2.322754201064754,
                                48.747864557754326
                            ],
                            [
                                2.3247893964056914,
                                48.75020634545652
                            ],
                            [
                                2.324929066674457,
                                48.750785197503575
                            ],
                            [
                                2.3245300087643272,
                                48.751719240154046
                            ],
                            [
                                2.3279220009999904,
                                48.75023265705778
                            ],
                            [
                                2.329019410252556,
                                48.75129826533427
                            ],
                            [
                                2.330626997149068,
                                48.75314556415134
                            ],
                            [
                                2.331804217984171,
                                48.754763607345296
                            ],
                            [
                                2.331864076666335,
                                48.75555287778636
                            ],
                            [
                                2.3302079863393885,
                                48.75623690214866
                            ],
                            [
                                2.3283324141618493,
                                48.75678937656514
                            ],
                            [
                                2.3261375901191457,
                                48.757631233089285
                            ],
                            [
                                2.3253195214033724,
                                48.75819684588481
                            ],
                            [
                                2.322765550778911,
                                48.75889398777724
                            ],
                            [
                                2.320111815677336,
                                48.75984103296176
                            ],
                            [
                                2.3179369500668088,
                                48.76048553961226
                            ],
                            [
                                2.3149119762965995,
                                48.76152208807184
                            ],
                            [
                                2.312777016477753,
                                48.762179725823074
                            ],
                            [
                                2.311081020359893,
                                48.76239016808458
                            ],
                            [
                                2.3094049771375182,
                                48.76286365994943
                            ],
                            [
                                2.3079085099748227,
                                48.76307409934577
                            ],
                            [
                                2.3063322258735184,
                                48.76291626899334
                            ],
                            [
                                2.303299385757157,
                                48.76225864088619
                            ],
                            [
                                2.299887440626094,
                                48.761548392859
                            ],
                            [
                                2.2966949773455667,
                                48.76103542970398
                            ],
                            [
                                2.2938417055035814,
                                48.76031201273699
                            ],
                            [
                                2.291068253028584,
                                48.75997002946477
                            ],
                            [
                                2.2887537171500867,
                                48.760062102112954
                            ],
                            [
                                2.286618757331212,
                                48.760338319046014
                            ],
                            [
                                2.2834262940506562,
                                48.76019363417532
                            ],
                            [
                                2.280972087903365,
                                48.759864803373915
                            ],
                            [
                                2.2779809966023947,
                                48.759682720810986
                            ]
                        ],
                        [
                            [
                                2.3337487583710015,
                                48.75421740219207
                            ],
                            [
                                2.3327241069474667,
                                48.753528608609884
                            ],
                            [
                                2.330976172166487,
                                48.75163437756626
                            ],
                            [
                                2.3292081461804344,
                                48.74947513200735
                            ],
                            [
                                2.33059443930253,
                                48.74815039560909
                            ],
                            [
                                2.3321012796309617,
                                48.749316165531184
                            ],
                            [
                                2.3359788820763185,
                                48.74759399588444
                            ],
                            [
                                2.337646452040019,
                                48.7482961183284
                            ],
                            [
                                2.339133201164202,
                                48.750534896078165
                            ],
                            [
                                2.3399971229525534,
                                48.751939048971735
                            ],
                            [
                                2.3416044193033088,
                                48.75350211641461
                            ],
                            [
                                2.3428299827707804,
                                48.754800220208864
                            ],
                            [
                                2.3416847584572054,
                                48.75510488639452
                            ],
                            [
                                2.3379477944428118,
                                48.755343308529774
                            ],
                            [
                                2.334632745719688,
                                48.754588301218746
                            ],
                            [
                                2.3337487583710015,
                                48.75421740219207
                            ]
                        ],
                        [
                            [
                                2.4903228834197932,
                                48.860719089909765
                            ],
                            [
                                2.491776707721641,
                                48.857818775455854
                            ],
                            [
                                2.493511917372075,
                                48.85494915002664
                            ],
                            [
                                2.494848497778065,
                                48.852202795306624
                            ],
                            [
                                2.49618507818505,
                                48.852727391556044
                            ],
                            [
                                2.496443014754675,
                                48.85346798866854
                            ],
                            [
                                2.497169926905599,
                                48.85453257782362
                            ],
                            [
                                2.498506508174529,
                                48.855118863541094
                            ],
                            [
                                2.500171371487113,
                                48.855628000911
                            ],
                            [
                                2.4995382523879357,
                                48.85649197971921
                            ],
                            [
                                2.4969588866917434,
                                48.85598285113642
                            ],
                            [
                                2.49517677948333,
                                48.85871357112126
                            ],
                            [
                                2.4972871695982235,
                                48.85909925402808
                            ],
                            [
                                2.496091281866029,
                                48.86071908978613
                            ],
                            [
                                2.494379520994613,
                                48.86019457727991
                            ],
                            [
                                2.4941919307627813,
                                48.860564821972446
                            ],
                            [
                                2.4916829114025916,
                                48.859546642477426
                            ],
                            [
                                2.4910966919264865,
                                48.860919637233536
                            ],
                            [
                                2.4903228834197932,
                                48.860719089909765
                            ]
                        ],
                        [
                            [
                                2.4945190751199675,
                                48.83518497280676
                            ],
                            [
                                2.4934872285075755,
                                48.83352162374291
                            ],
                            [
                                2.493634635166046,
                                48.83184435751866
                            ],
                            [
                                2.4940136808608315,
                                48.8308047015048
                            ],
                            [
                                2.4988149263245134,
                                48.82984819891843
                            ],
                            [
                                2.496245838839741,
                                48.83343845484032
                            ],
                            [
                                2.495235050320616,
                                48.83507408458718
                            ],
                            [
                                2.4945190751199675,
                                48.83518497280676
                            ]
                        ],
                        [
                            [
                                2.4802449645175386,
                                48.82848473646612
                            ],
                            [
                                2.478481238246985,
                                48.82851154154693
                            ],
                            [
                                2.4757020001602825,
                                48.828044403876675
                            ],
                            [
                                2.4722131693709173,
                                48.82761619051982
                            ],
                            [
                                2.4691382676579394,
                                48.8269738636229
                            ],
                            [
                                2.4671277550000354,
                                48.82633152849289
                            ],
                            [
                                2.4653833396047844,
                                48.825767045369616
                            ],
                            [
                                2.4642598178258197,
                                48.82512469477052
                            ],
                            [
                                2.4636980569357263,
                                48.82362584468612
                            ],
                            [
                                2.46304759695812,
                                48.821912818270306
                            ],
                            [
                                2.46165796476825,
                                48.82037301030934
                            ],
                            [
                                2.4588787266815473,
                                48.819029759651016
                            ],
                            [
                                2.4562768867710645,
                                48.817569663758945
                            ],
                            [
                                2.455508161342209,
                                48.81698561349347
                            ],
                            [
                                2.4587013285052706,
                                48.81679092855947
                            ],
                            [
                                2.4595883193841814,
                                48.817238702777075
                            ],
                            [
                                2.4613623011419463,
                                48.81811477120033
                            ],
                            [
                                2.463520645613528,
                                48.816693585808906
                            ],
                            [
                                2.464880698293797,
                                48.81614846291296
                            ],
                            [
                                2.4593813548454193,
                                48.812741310509836
                            ],
                            [
                                2.4581691339788847,
                                48.81355904820467
                            ],
                            [
                                2.45734127582503,
                                48.813052831204374
                            ],
                            [
                                2.4564247185836336,
                                48.81336434996325
                            ],
                            [
                                2.455360329529668,
                                48.81390950313357
                            ],
                            [
                                2.4544733386507573,
                                48.81340328967187
                            ],
                            [
                                2.4568090812974503,
                                48.81126156077687
                            ],
                            [
                                2.457991735802665,
                                48.81026854633811
                            ],
                            [
                                2.46071184116434,
                                48.80929498367823
                            ],
                            [
                                2.4646737337550633,
                                48.81149520837528
                            ],
                            [
                                2.465353760096349,
                                48.811027912090054
                            ],
                            [
                                2.46496939738131,
                                48.81021013311144
                            ],
                            [
                                2.465117229195073,
                                48.80978176737105
                            ],
                            [
                                2.4658268218977355,
                                48.80978176737105
                            ],
                            [
                                2.4661520518865245,
                                48.81052166953313
                            ],
                            [
                                2.468576493621896,
                                48.81151467895924
                            ],
                            [
                                2.4702026435659548,
                                48.81248819852004
                            ],
                            [
                                2.4713557317085986,
                                48.81357851798714
                            ],
                            [
                                2.4717696607849575,
                                48.81344222935061
                            ],
                            [
                                2.4740462707065944,
                                48.81562280307776
                            ],
                            [
                                2.480343905945176,
                                48.81330594034324
                            ],
                            [
                                2.482206586790511,
                                48.81587589924183
                            ],
                            [
                                2.482758105340423,
                                48.81972517576983
                            ],
                            [
                                2.48240330898912,
                                48.821204675696464
                            ],
                            [
                                2.4802449645175386,
                                48.82848473646612
                            ]
                        ],
                        [
                            [
                                2.470455616424232,
                                48.89910669882332
                            ],
                            [
                                2.47063894306865,
                                48.897367786904056
                            ],
                            [
                                2.472026987659234,
                                48.89509505834914
                            ],
                            [
                                2.473257895125897,
                                48.893459318551976
                            ],
                            [
                                2.4745149921140808,
                                48.89512949440112
                            ],
                            [
                                2.473074568482531,
                                48.89562881449058
                            ],
                            [
                                2.474960213964323,
                                48.898245858899344
                            ],
                            [
                                2.4715555762884946,
                                48.899158348747136
                            ],
                            [
                                2.470455616424232,
                                48.89910669882332
                            ]
                        ],
                        [
                            [
                                2.465176182427598,
                                48.90523283756565
                            ],
                            [
                                2.4672761543282604,
                                48.903683331054935
                            ],
                            [
                                2.469808473384859,
                                48.90415021798631
                            ],
                            [
                                2.4691290707112614,
                                48.90523960386426
                            ],
                            [
                                2.4668952770716146,
                                48.905320799375914
                            ],
                            [
                                2.466874689111876,
                                48.905320799375914
                            ],
                            [
                                2.466267344297421,
                                48.905347864517125
                            ],
                            [
                                2.465176182427598,
                                48.90523283756565
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "fr--06",
            "properties": {
                "land": "fr",
                "city": "Nice Côte d'Azur",
                "zone": "ZFE-m",
                "area_code": 4,
                "time": "24/7: Plan 2024 - Crit 4/5 BAN (M1)",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://www.nicecotedazur.org/environnement/qualit%C3%A9-de-vie/qualit%C3%A9-de-l-air/zone-%C3%A0-faibles-%C3%A9missions-zfe",
                "urlPark": "https://www.nicecotedazur.org/services/transports-et-mobilites/stationnement/",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            7.251486108507152,
                            43.697491636038535
                        ],
                        [
                            7.251619087018867,
                            43.696970331546964
                        ],
                        [
                            7.251852127782115,
                            43.69609899469009
                        ],
                        [
                            7.252237506808939,
                            43.69487256361273
                        ],
                        [
                            7.252610372028158,
                            43.694256349346944
                        ],
                        [
                            7.252883362636254,
                            43.694039710014664
                        ],
                        [
                            7.2525770804915055,
                            43.69391935449178
                        ],
                        [
                            7.252943287403582,
                            43.693216473414594
                        ],
                        [
                            7.252197556962926,
                            43.69315388771386
                        ],
                        [
                            7.249101635495634,
                            43.69212325719204
                        ],
                        [
                            7.245537114094105,
                            43.69081062734642
                        ],
                        [
                            7.245217515333962,
                            43.691190969688904
                        ],
                        [
                            7.244851308290322,
                            43.691070608420574
                        ],
                        [
                            7.245091007360713,
                            43.690666192884976
                        ],
                        [
                            7.243532963404931,
                            43.69006919353916
                        ],
                        [
                            7.241281589466865,
                            43.688902688775755
                        ],
                        [
                            7.239896661268062,
                            43.688166046072666
                        ],
                        [
                            7.238047642468132,
                            43.686960333301755
                        ],
                        [
                            7.237821260013078,
                            43.68714329482853
                        ],
                        [
                            7.237554927713177,
                            43.68694588894695
                        ],
                        [
                            7.237761335245807,
                            43.686738852812425
                        ],
                        [
                            7.235939950842436,
                            43.685199399233966
                        ],
                        [
                            7.235607035467012,
                            43.68534384683113
                        ],
                        [
                            7.235294095013984,
                            43.68502606165785
                        ],
                        [
                            7.235553769006799,
                            43.684862353487404
                        ],
                        [
                            7.233363188019609,
                            43.68297485576821
                        ],
                        [
                            7.232404391739237,
                            43.6820503486274
                        ],
                        [
                            7.232244592359706,
                            43.68213702177715
                        ],
                        [
                            7.232058159749556,
                            43.682002196823504
                        ],
                        [
                            7.232184667592406,
                            43.6818818171445
                        ],
                        [
                            7.231592078226981,
                            43.68138585032045
                        ],
                        [
                            7.230726500391171,
                            43.68038427180693
                        ],
                        [
                            7.229168457468887,
                            43.678308834092235
                        ],
                        [
                            7.227474283973606,
                            43.67549651638399
                        ],
                        [
                            7.227054818217994,
                            43.67465376270593
                        ],
                        [
                            7.226462228850863,
                            43.67371949565117
                        ],
                        [
                            7.224301555778084,
                            43.67213979421868
                        ],
                        [
                            7.224008590248644,
                            43.671951970946225
                        ],
                        [
                            7.224374797160721,
                            43.6715522425373
                        ],
                        [
                            7.223143010273333,
                            43.670762409902096
                        ],
                        [
                            7.2208553646545965,
                            43.669097307900756
                        ],
                        [
                            7.221081747109622,
                            43.66860605165894
                        ],
                        [
                            7.22064229881488,
                            43.668158138051666
                        ],
                        [
                            7.221780870069068,
                            43.668928739891726
                        ],
                        [
                            7.225291045860786,
                            43.6716189617336
                        ],
                        [
                            7.226522832749282,
                            43.67268329174601
                        ],
                        [
                            7.227473011105076,
                            43.67399858913916
                        ],
                        [
                            7.228045625550891,
                            43.674605379977834
                        ],
                        [
                            7.228718114607801,
                            43.67574189234139
                        ],
                        [
                            7.2295699779143945,
                            43.676863261307574
                        ],
                        [
                            7.2296299026816655,
                            43.6777974794181
                        ],
                        [
                            7.230415583154922,
                            43.67916988839687
                        ],
                        [
                            7.232485726610321,
                            43.68118754900814
                        ],
                        [
                            7.23621055896416,
                            43.68458868671766
                        ],
                        [
                            7.239755404475005,
                            43.687318376733174
                        ],
                        [
                            7.245021049209356,
                            43.68992913922588
                        ],
                        [
                            7.250582500956966,
                            43.69188476996803
                        ],
                        [
                            7.255411389631206,
                            43.69315524560099
                        ],
                        [
                            7.259740746545248,
                            43.69400475300401
                        ],
                        [
                            7.265362448758026,
                            43.694463156006975
                        ],
                        [
                            7.271035323687812,
                            43.69476644885421
                        ],
                        [
                            7.275906983994162,
                            43.69472793557412
                        ],
                        [
                            7.278623580550118,
                            43.69408283303963
                        ],
                        [
                            7.27898978746336,
                            43.69351475325993
                        ],
                        [
                            7.2800750915865535,
                            43.69302369691093
                        ],
                        [
                            7.281080496018916,
                            43.692999625417684
                        ],
                        [
                            7.281766301692244,
                            43.6932307113521
                        ],
                        [
                            7.282412157519616,
                            43.69365918083258
                        ],
                        [
                            7.282239041524747,
                            43.693832493460945
                        ],
                        [
                            7.282252358138976,
                            43.69402987667738
                        ],
                        [
                            7.282625223359304,
                            43.6945594381948
                        ],
                        [
                            7.28266517320418,
                            43.69488680115745
                        ],
                        [
                            7.282691806434855,
                            43.69562817537826
                        ],
                        [
                            7.2826585123727625,
                            43.69715903232864
                        ],
                        [
                            7.282844950063861,
                            43.69754415065654
                        ],
                        [
                            7.281506630256075,
                            43.69932046626374
                        ],
                        [
                            7.282092561316119,
                            43.699821098555276
                        ],
                        [
                            7.280807516457287,
                            43.700596117705686
                        ],
                        [
                            7.278823340820992,
                            43.701197825899925
                        ],
                        [
                            7.279802108110744,
                            43.70258413702513
                        ],
                        [
                            7.279009769518069,
                            43.70286813462812
                        ],
                        [
                            7.2773385366808725,
                            43.702949958151635
                        ],
                        [
                            7.27409594092768,
                            43.7040185457881
                        ],
                        [
                            7.273696441565448,
                            43.70399447803254
                        ],
                        [
                            7.2734034760359805,
                            43.70410999992575
                        ],
                        [
                            7.273243676655369,
                            43.70436992337187
                        ],
                        [
                            7.271752215775166,
                            43.70509192703025
                        ],
                        [
                            7.270613645191759,
                            43.70510636701468
                        ],
                        [
                            7.269495052484075,
                            43.70647334309825
                        ],
                        [
                            7.268696055584343,
                            43.707455231490826
                        ],
                        [
                            7.266825076856406,
                            43.70669474592523
                        ],
                        [
                            7.264434744463131,
                            43.70566471226775
                        ],
                        [
                            7.262867085239094,
                            43.70486663320068
                        ],
                        [
                            7.258687783302861,
                            43.70293316020357
                        ],
                        [
                            7.256443932657476,
                            43.70210041534165
                        ],
                        [
                            7.252948321219691,
                            43.70067558200654
                        ],
                        [
                            7.251319650819397,
                            43.69997076334897
                        ],
                        [
                            7.251486108507152,
                            43.697491636038535
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "fr--07",
            "properties": {
                "land": "fr",
                "city": "Rouen",
                "zone": "ZFE-m",
                "area_code": 0,
                "time": "24/7, on 31.8.2023 Cirt 4/5 BAN",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://www.metropole-rouen-normandie.fr/zone-faibles-emissions-mobilite",
                "urlPark": "https://rouen.fr/parkings-relais",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            1.0896741456548682,
                            49.39754705662389
                        ],
                        [
                            1.0937213609580567,
                            49.39817839104967
                        ],
                        [
                            1.0979732035889924,
                            49.398569044318606
                        ],
                        [
                            1.0986234827684882,
                            49.39882133434176
                        ],
                        [
                            1.0999865679730476,
                            49.399024793093844
                        ],
                        [
                            1.100099116291915,
                            49.398723673841516
                        ],
                        [
                            1.1026877276425466,
                            49.39904920808772
                        ],
                        [
                            1.1028377920683567,
                            49.399293357355845
                        ],
                        [
                            1.1106036367145293,
                            49.39966772160818
                        ],
                        [
                            1.1123168722449748,
                            49.39971655094391
                        ],
                        [
                            1.1136174306049327,
                            49.399936282354844
                        ],
                        [
                            1.1149805158084973,
                            49.40008276941529
                        ],
                        [
                            1.1161560204792806,
                            49.40022111790478
                        ],
                        [
                            1.1178442452730906,
                            49.40027808481679
                        ],
                        [
                            1.1183457017420153,
                            49.38737134963853
                        ],
                        [
                            1.122480580782252,
                            49.387427423868616
                        ],
                        [
                            1.1225012732292043,
                            49.390804127450366
                        ],
                        [
                            1.1265222145036091,
                            49.3909134626758
                        ],
                        [
                            1.1267951765748023,
                            49.39139180142689
                        ],
                        [
                            1.128821395024005,
                            49.391555802212366
                        ],
                        [
                            1.1288004042018827,
                            49.39208197560151
                        ],
                        [
                            1.1321074446760235,
                            49.392737966240276
                        ],
                        [
                            1.1298712554026622,
                            49.39407725326038
                        ],
                        [
                            1.129010359199583,
                            49.395525833729806
                        ],
                        [
                            1.1316139974142345,
                            49.396796718527696
                        ],
                        [
                            1.1341756414642532,
                            49.39697436647137
                        ],
                        [
                            1.137734658485499,
                            49.39427541174723
                        ],
                        [
                            1.1419130778778595,
                            49.395382341437454
                        ],
                        [
                            1.144443228178062,
                            49.39706319536202
                        ],
                        [
                            1.146532437875095,
                            49.396899212964655
                        ],
                        [
                            1.1487581286068291,
                            49.39726817258932
                        ],
                        [
                            1.1469733766049899,
                            49.39892845660026
                        ],
                        [
                            1.146332965592478,
                            49.3993862205098
                        ],
                        [
                            1.1472988242858264,
                            49.40002162299746
                        ],
                        [
                            1.1458815212260447,
                            49.40123773543016
                        ],
                        [
                            1.1462279730850469,
                            49.40179795686788
                        ],
                        [
                            1.1497239873002059,
                            49.40289105346582
                        ],
                        [
                            1.1508998239135337,
                            49.401818452652634
                        ],
                        [
                            1.15478189514954,
                            49.40365782898945
                        ],
                        [
                            1.1487662310490236,
                            49.406629515952176
                        ],
                        [
                            1.1491861726965453,
                            49.4069642464313
                        ],
                        [
                            1.1536375541601558,
                            49.4047987047947
                        ],
                        [
                            1.1573540374867832,
                            49.40751074009813
                        ],
                        [
                            1.1560312212970985,
                            49.40797525507611
                        ],
                        [
                            1.156146705250336,
                            49.40846025867248
                        ],
                        [
                            1.157511518942016,
                            49.41011333591206
                        ],
                        [
                            1.155380315081203,
                            49.411540946670016
                        ],
                        [
                            1.1542044784678467,
                            49.41197810146929
                        ],
                        [
                            1.1536795514086293,
                            49.412415252376036
                        ],
                        [
                            1.1541011967750592,
                            49.41294678581443
                        ],
                        [
                            1.1581815752618638,
                            49.41561076346284
                        ],
                        [
                            1.1608343468350313,
                            49.414445648199404
                        ],
                        [
                            1.1633975733763293,
                            49.414736929608154
                        ],
                        [
                            1.1648974526622453,
                            49.41703799196878
                        ],
                        [
                            1.1694418662137593,
                            49.42125390353294
                        ],
                        [
                            1.169531418371065,
                            49.42380949303805
                        ],
                        [
                            1.169531418371065,
                            49.42500350797985
                        ],
                        [
                            1.172195383072733,
                            49.42664159095773
                        ],
                        [
                            1.1743220775833265,
                            49.427537053198904
                        ],
                        [
                            1.177523323141088,
                            49.42935705357053
                        ],
                        [
                            1.1842615867994937,
                            49.42728953422096
                        ],
                        [
                            1.1883134994982356,
                            49.4293279341847
                        ],
                        [
                            1.1858174317308396,
                            49.430893076671595
                        ],
                        [
                            1.1825266512305461,
                            49.43125705602506
                        ],
                        [
                            1.1796031784358831,
                            49.43277927232336
                        ],
                        [
                            1.1757415489302332,
                            49.433507200568215
                        ],
                        [
                            1.174504688479061,
                            49.43560906293828
                        ],
                        [
                            1.1769335976059665,
                            49.439459458466956
                        ],
                        [
                            1.1734413460331439,
                            49.440172736331306
                        ],
                        [
                            1.1738219124193279,
                            49.441824868797426
                        ],
                        [
                            1.1710012439110642,
                            49.44207959792513
                        ],
                        [
                            1.1713370414586564,
                            49.44353516917104
                        ],
                        [
                            1.172154139876426,
                            49.4436152242468
                        ],
                        [
                            1.1713706278306688,
                            49.448003503069884
                        ],
                        [
                            1.1702737011888757,
                            49.44840374066575
                        ],
                        [
                            1.1640950828042662,
                            49.45150365956698
                        ],
                        [
                            1.1609274272971675,
                            49.4530899234004
                        ],
                        [
                            1.1619348074799234,
                            49.45474161892753
                        ],
                        [
                            1.1625840089616588,
                            49.45570932757167
                        ],
                        [
                            1.1628974165739976,
                            49.45705535669629
                        ],
                        [
                            1.161565434223121,
                            49.45728817960563
                        ],
                        [
                            1.1611177127183794,
                            49.456786155356866
                        ],
                        [
                            1.1595618677870618,
                            49.45540373896074
                        ],
                        [
                            1.1562151084868901,
                            49.456895301228826
                        ],
                        [
                            1.155431589457379,
                            49.45526550457279
                        ],
                        [
                            1.1527228522388953,
                            49.45506177617992
                        ],
                        [
                            1.1514132561460144,
                            49.455505611948354
                        ],
                        [
                            1.1522527408208703,
                            49.45662609748234
                        ],
                        [
                            1.150607360177247,
                            49.45784115244649
                        ],
                        [
                            1.1490850946333069,
                            49.45946358807802
                        ],
                        [
                            1.147842657313447,
                            49.45930352955156
                        ],
                        [
                            1.145760736678568,
                            49.46222815756923
                        ],
                        [
                            1.1441825054901358,
                            49.46226453238151
                        ],
                        [
                            1.142940068171157,
                            49.46407596389716
                        ],
                        [
                            1.141037236240976,
                            49.464294204137474
                        ],
                        [
                            1.1413170644659658,
                            49.46536356726577
                        ],
                        [
                            1.1418991071737992,
                            49.465312645741676
                        ],
                        [
                            1.144339209295822,
                            49.464985291820284
                        ],
                        [
                            1.1437459722246786,
                            49.46756042082566
                        ],
                        [
                            1.1452458515115325,
                            49.46774227482655
                        ],
                        [
                            1.1475292498270164,
                            49.467844112771985
                        ],
                        [
                            1.1471151040543361,
                            49.469226178245634
                        ],
                        [
                            1.1502827595614349,
                            49.46989537488622
                        ],
                        [
                            1.1514468449346396,
                            49.47085985731752
                        ],
                        [
                            1.1484135069753165,
                            49.47410381279795
                        ],
                        [
                            1.1464435169935996,
                            49.475427519801855
                        ],
                        [
                            1.1455480666734843,
                            49.477165737240085
                        ],
                        [
                            1.1410708123020754,
                            49.47896207812312
                        ],
                        [
                            1.1399291131434381,
                            49.479762044443305
                        ],
                        [
                            1.1408581428509592,
                            49.48261272724895
                        ],
                        [
                            1.1390224732438128,
                            49.483616256928485
                        ],
                        [
                            1.1369293581205966,
                            49.48322357873178
                        ],
                        [
                            1.1367054955400704,
                            49.483827138364575
                        ],
                        [
                            1.1367950405728209,
                            49.4847433712977
                        ],
                        [
                            1.1367055112663422,
                            49.48519420785237
                        ],
                        [
                            1.1349369968847896,
                            49.483696244617875
                        ],
                        [
                            1.1341422847250442,
                            49.48267091326639
                        ],
                        [
                            1.1332020704249146,
                            49.48228549267671
                        ],
                        [
                            1.1327319590068612,
                            49.482081876619475
                        ],
                        [
                            1.13203798500831,
                            49.48101287842587
                        ],
                        [
                            1.1308627064632333,
                            49.47977659291149
                        ],
                        [
                            1.1300344149169632,
                            49.480096575685366
                        ],
                        [
                            1.131847703288713,
                            49.48182008506009
                        ],
                        [
                            1.1321387246421466,
                            49.4828018027865
                        ],
                        [
                            1.13278792612482,
                            49.48333264935772
                        ],
                        [
                            1.1334930932518716,
                            49.48333264935772
                        ],
                        [
                            1.1347803030867851,
                            49.48435796685288
                        ],
                        [
                            1.1370301187374423,
                            49.48619766574768
                        ],
                        [
                            1.1373882988656874,
                            49.486866630521746
                        ],
                        [
                            1.1366719386091688,
                            49.48735380563747
                        ],
                        [
                            1.136157074179323,
                            49.4883935865781
                        ],
                        [
                            1.132843907995067,
                            49.490073177192954
                        ],
                        [
                            1.133582654509496,
                            49.49114197758061
                        ],
                        [
                            1.1344669141415977,
                            49.492388107877304
                        ],
                        [
                            1.1319596532447633,
                            49.49257714016076
                        ],
                        [
                            1.1317134044072077,
                            49.49345685772073
                        ],
                        [
                            1.1298889243794576,
                            49.49336234336894
                        ],
                        [
                            1.1243710964399156,
                            49.49201786492753
                        ],
                        [
                            1.1202170765616302,
                            49.48901110251214
                        ],
                        [
                            1.1171969234119103,
                            49.48570338724687
                        ],
                        [
                            1.1148362585828977,
                            49.48236571993476
                        ],
                        [
                            1.1109186634328125,
                            49.48224936819582
                        ],
                        [
                            1.1088591276969169,
                            49.48208938410414
                        ],
                        [
                            1.1085904926004844,
                            49.48237299190899
                        ],
                        [
                            1.1070682247993773,
                            49.484292755387514
                        ],
                        [
                            1.1078069713129537,
                            49.484932659823045
                        ],
                        [
                            1.1083666277628481,
                            49.486139729513496
                        ],
                        [
                            1.106083229593139,
                            49.488648309668946
                        ],
                        [
                            1.1050310754672523,
                            49.490138850574795
                        ],
                        [
                            1.1023895003998518,
                            49.489586260496566
                        ],
                        [
                            1.1020537065295741,
                            49.48991345002122
                        ],
                        [
                            1.0993561624405004,
                            49.48990617916698
                        ],
                        [
                            1.099691956310778,
                            49.48725224511318
                        ],
                        [
                            1.100850994822423,
                            49.48671884276584
                        ],
                        [
                            1.0998771925989104,
                            49.48568630322919
                        ],
                        [
                            1.0992279911172318,
                            49.48331574275406
                        ],
                        [
                            1.100081106241504,
                            49.4811678304894
                        ],
                        [
                            1.1009653634322376,
                            49.479407937858184
                        ],
                        [
                            1.1010325250029496,
                            49.47838251497956
                        ],
                        [
                            1.1013907051311946,
                            49.47745889031745
                        ],
                        [
                            1.1013011600993536,
                            49.47684070659972
                        ],
                        [
                            1.1017153067907373,
                            49.473589657219804
                        ],
                        [
                            1.1018384312090745,
                            49.47203315093657
                        ],
                        [
                            1.102409280788379,
                            49.47031657263861
                        ],
                        [
                            1.1025100202064664,
                            49.469865595495065
                        ],
                        [
                            1.1019839431439493,
                            49.46896363211508
                        ],
                        [
                            1.1021070675622866,
                            49.468592658486244
                        ],
                        [
                            1.1028793934640646,
                            49.468279874615774
                        ],
                        [
                            1.1020958744337293,
                            49.467683397514094
                        ],
                        [
                            1.1012116179749967,
                            49.466752294391966
                        ],
                        [
                            1.1003609401524557,
                            49.46543562953491
                        ],
                        [
                            1.099588614251502,
                            49.46427897212834
                        ],
                        [
                            1.0997341249278065,
                            49.46390068830914
                        ],
                        [
                            1.0989729890415276,
                            49.4635733194726
                        ],
                        [
                            1.0976857792066141,
                            49.463355076020775
                        ],
                        [
                            1.0970365777248787,
                            49.463165930910094
                        ],
                        [
                            1.0965105006614806,
                            49.46269306493812
                        ],
                        [
                            1.0945181236995154,
                            49.460612400448184
                        ],
                        [
                            1.0936786330706525,
                            49.45976846696581
                        ],
                        [
                            1.0936226674253362,
                            49.459171886240796
                        ],
                        [
                            1.0942942551650106,
                            49.45826245043037
                        ],
                        [
                            1.0955478856134562,
                            49.45674183605519
                        ],
                        [
                            1.0942606757785427,
                            49.456676353994226
                        ],
                        [
                            1.0936674398028003,
                            49.45572321374544
                        ],
                        [
                            1.0930630108369428,
                            49.4543334912307
                        ],
                        [
                            1.0939808474150823,
                            49.45395513063286
                        ],
                        [
                            1.0917646078726193,
                            49.4525871871796
                        ],
                        [
                            1.0906788651102772,
                            49.45168488646965
                        ],
                        [
                            1.088540977471979,
                            49.452587167939754
                        ],
                        [
                            1.0881827973437623,
                            49.45156118529604
                        ],
                        [
                            1.0876791065383031,
                            49.451241016103296
                        ],
                        [
                            1.0870410981850966,
                            49.4517867577903
                        ],
                        [
                            1.0859441715433036,
                            49.4528563938712
                        ],
                        [
                            1.0856083860838623,
                            49.45265992994541
                        ],
                        [
                            1.085227819698531,
                            49.45183769146544
                        ],
                        [
                            1.0841756655717631,
                            49.451015439192986
                        ],
                        [
                            1.0840861205399221,
                            49.450484242338746
                        ],
                        [
                            1.0835264640900277,
                            49.45046968891927
                        ],
                        [
                            1.083280229499394,
                            49.450913571452816
                        ],
                        [
                            1.0823400066632871,
                            49.4508626349122
                        ],
                        [
                            1.0803924022171998,
                            49.451219189587334
                        ],
                        [
                            1.0783888321266772,
                            49.451844973155715
                        ],
                        [
                            1.0775269611930298,
                            49.452259732281505
                        ],
                        [
                            1.0780194588690222,
                            49.45279818626199
                        ],
                        [
                            1.077180003404493,
                            49.45332208680125
                        ],
                        [
                            1.0765308019227575,
                            49.45441351776037
                        ],
                        [
                            1.0744153005415455,
                            49.453482164863004
                        ],
                        [
                            1.073228828867883,
                            49.45301648177764
                        ],
                        [
                            1.0713036106789104,
                            49.45244164811851
                        ],
                        [
                            1.0702514565529953,
                            49.452186972823
                        ],
                        [
                            1.0696022550712598,
                            49.45267449294482
                        ],
                        [
                            1.0693671993622331,
                            49.45303103444101
                        ],
                        [
                            1.069658220716633,
                            49.453373020786785
                        ],
                        [
                            1.070329808456279,
                            49.453460336066996
                        ],
                        [
                            1.0697253794904782,
                            49.45599241153664
                        ],
                        [
                            1.0687403803380278,
                            49.456348933721216
                        ],
                        [
                            1.0663114705021428,
                            49.45617431328242
                        ],
                        [
                            1.065897324729434,
                            49.45633438202847
                        ],
                        [
                            1.0646772736683943,
                            49.45613793394875
                        ],
                        [
                            1.0647444324422963,
                            49.45520661381849
                        ],
                        [
                            1.063613926413069,
                            49.45469729063669
                        ],
                        [
                            1.0628416005121153,
                            49.4547045667197
                        ],
                        [
                            1.0614760387739182,
                            49.45586145013203
                        ],
                        [
                            1.0606589408041884,
                            49.46004491907121
                        ],
                        [
                            1.0612409829756189,
                            49.4609933320516
                        ],
                        [
                            1.0598418412793364,
                            49.46696586519482
                        ],
                        [
                            1.0588232661471864,
                            49.468064264414096
                        ],
                        [
                            1.0603119523046587,
                            49.46787513748188
                        ],
                        [
                            1.0606925186908143,
                            49.468137005347245
                        ],
                        [
                            1.0591254806309962,
                            49.47058831164867
                        ],
                        [
                            1.0626625372321996,
                            49.47139569832743
                        ],
                        [
                            1.060983567882488,
                            49.47274857000073
                        ],
                        [
                            1.0614648724290987,
                            49.47296677161026
                        ],
                        [
                            1.062718502877516,
                            49.4725230939834
                        ],
                        [
                            1.0646996867109522,
                            49.472108506339225
                        ],
                        [
                            1.0658861583846146,
                            49.471926668546274
                        ],
                        [
                            1.06754274147724,
                            49.47144661352908
                        ],
                        [
                            1.0676994452829547,
                            49.472239429132
                        ],
                        [
                            1.0682479086042918,
                            49.47254491428822
                        ],
                        [
                            1.0682926811201696,
                            49.47420322903599
                        ],
                        [
                            1.066931270926034,
                            49.47570611258794
                        ],
                        [
                            1.0661324484265435,
                            49.477771460359065
                        ],
                        [
                            1.0662323012388413,
                            49.47980428305638
                        ],
                        [
                            1.0687619058215887,
                            49.47825804794172
                        ],
                        [
                            1.0704926879035668,
                            49.478344551894565
                        ],
                        [
                            1.0730222924862858,
                            49.479490714846804
                        ],
                        [
                            1.0770330804500077,
                            49.479879987850694
                        ],
                        [
                            1.0813101092507793,
                            49.479533981970434
                        ],
                        [
                            1.0836566503437268,
                            49.48019355356766
                        ],
                        [
                            1.0848548840922945,
                            49.481436984057495
                        ],
                        [
                            1.0860031914366743,
                            49.4816856663665
                        ],
                        [
                            1.0895646084142072,
                            49.48262632280736
                        ],
                        [
                            1.0920110023180882,
                            49.48264794688083
                        ],
                        [
                            1.0932258907332368,
                            49.48329667035068
                        ],
                        [
                            1.09059643333822,
                            49.48709149707159
                        ],
                        [
                            1.0886326610210517,
                            49.48814015790893
                        ],
                        [
                            1.0828245557641196,
                            49.488172590362865
                        ],
                        [
                            1.080577867483413,
                            49.48791313012924
                        ],
                        [
                            1.0779341902602368,
                            49.489221230895396
                        ],
                        [
                            1.0741305283792997,
                            49.48974268425246
                        ],
                        [
                            1.0716365928197717,
                            49.48881487910336
                        ],
                        [
                            1.0683355817572249,
                            49.48742104999377
                        ],
                        [
                            1.067060192171084,
                            49.48769397089069
                        ],
                        [
                            1.0673077677969616,
                            49.48790840767066
                        ],
                        [
                            1.0635416185068607,
                            49.49000399468963
                        ],
                        [
                            1.066460009970541,
                            49.492425986684935
                        ],
                        [
                            1.0656347578854763,
                            49.49300100943441
                        ],
                        [
                            1.0635416185068607,
                            49.49135876554382
                        ],
                        [
                            1.0626338412131702,
                            49.49175836705231
                        ],
                        [
                            1.0605181928094112,
                            49.4904279722266
                        ],
                        [
                            1.060480681351379,
                            49.48931684683262
                        ],
                        [
                            1.0607006731722208,
                            49.488037148427594
                        ],
                        [
                            1.060880728172208,
                            49.48737434254272
                        ],
                        [
                            1.0600554714039845,
                            49.487330484992384
                        ],
                        [
                            1.059222717027552,
                            49.4853078950469
                        ],
                        [
                            1.0575271991080513,
                            49.48456707011229
                        ],
                        [
                            1.05574165368742,
                            49.48435261869699
                        ],
                        [
                            1.0558241788960459,
                            49.48504470852495
                        ],
                        [
                            1.0562968232724756,
                            49.48657994329304
                        ],
                        [
                            1.0562668141058111,
                            49.48721351821155
                        ],
                        [
                            1.0550739514337693,
                            49.48809076304349
                        ],
                        [
                            1.053971114556191,
                            49.48947482723503
                        ],
                        [
                            1.0532584013685664,
                            49.49095144796553
                        ],
                        [
                            1.052103048450192,
                            49.49308101170257
                        ],
                        [
                            1.0518404698678694,
                            49.49411895752152
                        ],
                        [
                            1.0514878621592914,
                            49.49416281390961
                        ],
                        [
                            1.051607898825921,
                            49.49515688150575
                        ],
                        [
                            1.0523656302850952,
                            49.49636532864716
                        ],
                        [
                            1.05196801099018,
                            49.497641966521456
                        ],
                        [
                            1.0491846607770299,
                            49.49779788894415
                        ],
                        [
                            1.0479242757747897,
                            49.499001398427964
                        ],
                        [
                            1.0471515397317148,
                            49.498757775415385
                        ],
                        [
                            1.0478492528575316,
                            49.498187692827344
                        ],
                        [
                            1.0485544682759667,
                            49.49742757237925
                        ],
                        [
                            1.0487753738662207,
                            49.496702631962364
                        ],
                        [
                            1.0488053830328852,
                            49.49540160991344
                        ],
                        [
                            1.048828200326625,
                            49.49368434481755
                        ],
                        [
                            1.0491658058554947,
                            49.49143701768455
                        ],
                        [
                            1.0494658975232767,
                            49.48987756845159
                        ],
                        [
                            1.049390874606047,
                            49.48929276218152
                        ],
                        [
                            1.0498935281812294,
                            49.489170926636234
                        ],
                        [
                            1.049901030472597,
                            49.48890288742703
                        ],
                        [
                            1.0502911496392073,
                            49.48882978556978
                        ],
                        [
                            1.0504486977651197,
                            49.48843016015425
                        ],
                        [
                            1.050426190889823,
                            49.48824496678233
                        ],
                        [
                            1.0501336015145455,
                            49.48825471381937
                        ],
                        [
                            1.0500210671392267,
                            49.48867383457613
                        ],
                        [
                            1.0496084410972912,
                            49.4887615570714
                        ],
                        [
                            1.0496159433886874,
                            49.48901985017167
                        ],
                        [
                            1.049023262345571,
                            49.489195293764055
                        ],
                        [
                            1.049083280678957,
                            49.490057882283054
                        ],
                        [
                            1.0489032256789699,
                            49.49087659630899
                        ],
                        [
                            1.0486706541651643,
                            49.49246037577626
                        ],
                        [
                            1.048460589997319,
                            49.493819951612096
                        ],
                        [
                            1.048460590187375,
                            49.49533054747246
                        ],
                        [
                            1.048348055812113,
                            49.49690931361451
                        ],
                        [
                            1.0480629704413218,
                            49.497464796155185
                        ],
                        [
                            1.0473577550229436,
                            49.49812259288419
                        ],
                        [
                            1.0469901427304364,
                            49.49843443416981
                        ],
                        [
                            1.0468400968971139,
                            49.49803975970855
                        ],
                        [
                            1.0465550098131757,
                            49.497995906794046
                        ],
                        [
                            1.0462774250218558,
                            49.49853188416432
                        ],
                        [
                            1.045729757728168,
                            49.49862446147921
                        ],
                        [
                            1.045422163770155,
                            49.49886808515507
                        ],
                        [
                            1.0454746798121164,
                            49.49927249777926
                        ],
                        [
                            1.0447844689776673,
                            49.49977922695285
                        ],
                        [
                            1.0438541745741645,
                            49.49964280562418
                        ],
                        [
                            1.04253377123851,
                            49.499954637222714
                        ],
                        [
                            1.0417160214449268,
                            49.49999361603315
                        ],
                        [
                            1.0409732945696248,
                            49.49998874368342
                        ],
                        [
                            1.0405981799846131,
                            49.50054906070358
                        ],
                        [
                            1.039592873488516,
                            49.50164045394047
                        ],
                        [
                            1.0388201371190746,
                            49.50212767003967
                        ],
                        [
                            1.0385500546179003,
                            49.50259052085042
                        ],
                        [
                            1.0385350500351933,
                            49.503102087705315
                        ],
                        [
                            1.0366669794059078,
                            49.502517439434484
                        ],
                        [
                            1.0370195871156795,
                            49.502088692929505
                        ],
                        [
                            1.0367795137811981,
                            49.50199125001785
                        ],
                        [
                            1.0364043991972949,
                            49.50224460118426
                        ],
                        [
                            1.0355791471122586,
                            49.50328235284627
                        ],
                        [
                            1.0350853744673714,
                            49.50324447769722
                        ],
                        [
                            1.0339019133375018,
                            49.50471429900492
                        ],
                        [
                            1.0330143174906539,
                            49.506107226160054
                        ],
                        [
                            1.0323190340764938,
                            49.50658753667349
                        ],
                        [
                            1.0300704579307194,
                            49.505405964342486
                        ],
                        [
                            1.0306574330216165,
                            49.50206439832317
                        ],
                        [
                            1.0321522904428093,
                            49.49943269765984
                        ],
                        [
                            1.0334066906116277,
                            49.49795447840643
                        ],
                        [
                            1.0317126250816386,
                            49.49601614289574
                        ],
                        [
                            1.0304631252728882,
                            49.49459786014225
                        ],
                        [
                            1.0267187654797283,
                            49.49406311789221
                        ],
                        [
                            1.0224996895401546,
                            49.492595462544955
                        ],
                        [
                            1.0244233246985175,
                            49.491124847138764
                        ],
                        [
                            1.0260223923679348,
                            49.491001436071144
                        ],
                        [
                            1.0292609607808458,
                            49.48839444018071
                        ],
                        [
                            1.031168763550454,
                            49.488615561848064
                        ],
                        [
                            1.0320395448979127,
                            49.488224741479826
                        ],
                        [
                            1.0330686501263528,
                            49.48727853187796
                        ],
                        [
                            1.0350985267741635,
                            49.4845027755332
                        ],
                        [
                            1.0374438621557545,
                            49.48603177157028
                        ],
                        [
                            1.0385442131316154,
                            49.485342655085276
                        ],
                        [
                            1.0399928766443907,
                            49.486268331709255
                        ],
                        [
                            1.0416552773981778,
                            49.486026628945666
                        ],
                        [
                            1.0450370169900793,
                            49.48544537524623
                        ],
                        [
                            1.045084514155036,
                            49.48573850745569
                        ],
                        [
                            1.0465965072215226,
                            49.48558422756409
                        ],
                        [
                            1.0466440006846653,
                            49.48509053171534
                        ],
                        [
                            1.047427703897256,
                            49.48508024626827
                        ],
                        [
                            1.0486151330067344,
                            49.484817966637195
                        ],
                        [
                            1.0488495788417822,
                            49.481918163667245
                        ],
                        [
                            1.047883026591677,
                            49.4801535520906
                        ],
                        [
                            1.0474080549474252,
                            49.47906317274183
                        ],
                        [
                            1.0487815173656827,
                            49.47483128668961
                        ],
                        [
                            1.049391064309816,
                            49.472969195503254
                        ],
                        [
                            1.0488685955006076,
                            49.472902323508464
                        ],
                        [
                            1.048646942067137,
                            49.473133803101405
                        ],
                        [
                            1.0478236578846918,
                            49.473133803101405
                        ],
                        [
                            1.0458604413755097,
                            49.47298462686129
                        ],
                        [
                            1.0445146883837992,
                            49.47261425786007
                        ],
                        [
                            1.0434935079353238,
                            49.47346816638856
                        ],
                        [
                            1.0424960674830857,
                            49.473781945948105
                        ],
                        [
                            1.040105376875431,
                            49.473164672771475
                        ],
                        [
                            1.0383479819259094,
                            49.47197639771903
                        ],
                        [
                            1.0372634633388316,
                            49.47170890377089
                        ],
                        [
                            1.0371209718452974,
                            49.47012448658947
                        ],
                        [
                            1.0364480941600789,
                            49.470026744117604
                        ],
                        [
                            1.036408513190139,
                            49.468766373157706
                        ],
                        [
                            1.03857113572397,
                            49.46551302922765
                        ],
                        [
                            1.0390936045331784,
                            49.465657082830006
                        ],
                        [
                            1.0380170021397817,
                            49.46780240242467
                        ],
                        [
                            1.03785076790777,
                            49.46929428546349
                        ],
                        [
                            1.0405976872486349,
                            49.4695257821069
                        ],
                        [
                            1.0407006024565533,
                            49.46744394691808
                        ],
                        [
                            1.0403285413357821,
                            49.46619894363235
                        ],
                        [
                            1.042515535397115,
                            49.46389055263771
                        ],
                        [
                            1.0438666147330196,
                            49.46256540636898
                        ],
                        [
                            1.0431858220440802,
                            49.46177305632105
                        ],
                        [
                            1.0445483726950044,
                            49.45974620881648
                        ],
                        [
                            1.0467810720842579,
                            49.45850529726914
                        ],
                        [
                            1.0498367229934047,
                            49.45687928759014
                        ],
                        [
                            1.0499067204950734,
                            49.45598768395027
                        ],
                        [
                            1.0505637646027992,
                            49.45527241541896
                        ],
                        [
                            1.0502075358696175,
                            49.45499453849811
                        ],
                        [
                            1.0503421111693854,
                            49.454546844589316
                        ],
                        [
                            1.0519140443140031,
                            49.45391991389707
                        ],
                        [
                            1.0517319718505291,
                            49.453168592676235
                        ],
                        [
                            1.0500379063205685,
                            49.45365232135819
                        ],
                        [
                            1.0503783026650808,
                            49.45282380442541
                        ],
                        [
                            1.0486684047476729,
                            49.45276205104986
                        ],
                        [
                            1.0491548974754323,
                            49.45171410092061
                        ],
                        [
                            1.0472391785120578,
                            49.4512663770486
                        ],
                        [
                            1.0475022172595345,
                            49.45052779086865
                        ],
                        [
                            1.047114323750094,
                            49.45042486371153
                        ],
                        [
                            1.048270088084621,
                            49.449081644501945
                        ],
                        [
                            1.0461722975991563,
                            49.447136226377836
                        ],
                        [
                            1.0455933945048628,
                            49.44540872015216
                        ],
                        [
                            1.0440814014384046,
                            49.443864628887894
                        ],
                        [
                            1.0418590410453703,
                            49.44240041544745
                        ],
                        [
                            1.0390126988983752,
                            49.44079498811274
                        ],
                        [
                            1.0410786462099395,
                            49.4381517471264
                        ],
                        [
                            1.0312160678090834,
                            49.433147155642274
                        ],
                        [
                            1.0226983864619115,
                            49.42459443886514
                        ],
                        [
                            1.0158244373903642,
                            49.41292922026267
                        ],
                        [
                            1.0129305276508092,
                            49.40591817600338
                        ],
                        [
                            1.0120423840171213,
                            49.40132537450992
                        ],
                        [
                            1.0148296963479595,
                            49.40094148686234
                        ],
                        [
                            1.015124650033158,
                            49.402030760238745
                        ],
                        [
                            1.0168648875264523,
                            49.4017956350076
                        ],
                        [
                            1.0175506548455076,
                            49.40255859549089
                        ],
                        [
                            1.021724249500494,
                            49.40132057815211
                        ],
                        [
                            1.0214882929607256,
                            49.4002744774038
                        ],
                        [
                            1.0235234733923448,
                            49.39965544384276
                        ],
                        [
                            1.0262370695564016,
                            49.39852772762089
                        ],
                        [
                            1.027114556771437,
                            49.39881085896744
                        ],
                        [
                            1.0277265856697966,
                            49.39870048592479
                        ],
                        [
                            1.0279035578809328,
                            49.39810062809457
                        ],
                        [
                            1.0289727649919769,
                            49.39774550880685
                        ],
                        [
                            1.0295700462054924,
                            49.39843175052303
                        ],
                        [
                            1.0304548979205777,
                            49.3986669044086
                        ],
                        [
                            1.0311554129244485,
                            49.39821581169528
                        ],
                        [
                            1.031900170980606,
                            49.396785724428014
                        ],
                        [
                            1.0330357426717285,
                            49.39673293517481
                        ],
                        [
                            1.035214708074335,
                            49.3949026766214
                        ],
                        [
                            1.0396145507454548,
                            49.39251620014616
                        ],
                        [
                            1.0399684951677841,
                            49.39224743090537
                        ],
                        [
                            1.041627610870222,
                            49.39126832813233
                        ],
                        [
                            1.0430321688905622,
                            49.39367856857211
                        ],
                        [
                            1.049888039039928,
                            49.39319639090357
                        ],
                        [
                            1.05507923196339,
                            49.39275485060253
                        ],
                        [
                            1.0577633105037307,
                            49.39253887648485
                        ],
                        [
                            1.0578960396627224,
                            49.39267326049196
                        ],
                        [
                            1.058382713243958,
                            49.39276444943016
                        ],
                        [
                            1.0589597331332357,
                            49.393521889738736
                        ],
                        [
                            1.061009661453511,
                            49.39640621123371
                        ],
                        [
                            1.0630450661461737,
                            49.39931743362075
                        ],
                        [
                            1.0656974177636869,
                            49.40298720033667
                        ],
                        [
                            1.0680634147938974,
                            49.406074993024504
                        ],
                        [
                            1.0697147309708157,
                            49.40832490105845
                        ],
                        [
                            1.0704225283321023,
                            49.40920280100889
                        ],
                        [
                            1.0710330068159521,
                            49.40995403875226
                        ],
                        [
                            1.0753495449233696,
                            49.40730313036531
                        ],
                        [
                            1.074774456496442,
                            49.40664972037962
                        ],
                        [
                            1.0759202123924183,
                            49.406180526058506
                        ],
                        [
                            1.0793300441532097,
                            49.40479496610652
                        ],
                        [
                            1.0806540776650309,
                            49.402090869198474
                        ],
                        [
                            1.0811838760650687,
                            49.4008379970127
                        ],
                        [
                            1.0809977084707327,
                            49.39766865301854
                        ],
                        [
                            1.0812390501494349,
                            49.39749235368308
                        ],
                        [
                            1.0821748648248217,
                            49.3972327116918
                        ],
                        [
                            1.0860983485277131,
                            49.39768809598584
                        ],
                        [
                            1.0898071298454965,
                            49.39810800623252
                        ],
                        [
                            1.0896741456548682,
                            49.39754705662389
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Montpellier",
                "zone": "ZFE-m",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-3 (diesel)",
                "minEUd": 3,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 4, 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://www.montpellier3m.fr/vivre-transport/zone-faibles-emissions",
                "urlPark": "https://www.montpellier-france.com/Prepare-Book/Getting-here-Access-and-transportation/In-and-around-Montpellier/Parking",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                3.945978952620578,
                                43.66139012384875
                            ],
                            [
                                3.9459432041885805,
                                43.66219319421822
                            ],
                            [
                                3.9454572117421662,
                                43.663799715430486
                            ],
                            [
                                3.942740245077829,
                                43.664977211128786
                            ],
                            [
                                3.94031700453894,
                                43.66415385190382
                            ],
                            [
                                3.9407208779627467,
                                43.662507099574015
                            ],
                            [
                                3.9386647950814506,
                                43.66183422003206
                            ],
                            [
                                3.937411215078157,
                                43.66725624368587
                            ],
                            [
                                3.9430899246169986,
                                43.66849563998207
                            ],
                            [
                                3.9490256400777923,
                                43.66882319066755
                            ],
                            [
                                3.9484504264152065,
                                43.669646485847466
                            ],
                            [
                                3.9448767585500093,
                                43.67047862218894
                            ],
                            [
                                3.941228658198014,
                                43.67014198500533
                            ],
                            [
                                3.9387687018938777,
                                43.668822947631526
                            ],
                            [
                                3.936259791234562,
                                43.669150496530136
                            ],
                            [
                                3.9311423967951953,
                                43.669072120653425
                            ],
                            [
                                3.930200025474903,
                                43.668204553669625
                            ],
                            [
                                3.9281072268274784,
                                43.66737238580049
                            ],
                            [
                                3.92785020249039,
                                43.66839046569859
                            ],
                            [
                                3.925316814654934,
                                43.67022295922783
                            ],
                            [
                                3.92476607816846,
                                43.671179020598885
                            ],
                            [
                                3.9225876094015746,
                                43.67136491965243
                            ],
                            [
                                3.921804339732091,
                                43.67018754925505
                            ],
                            [
                                3.91916080459913,
                                43.66843472948628
                            ],
                            [
                                3.923113663656096,
                                43.6662360888275
                            ],
                            [
                                3.9248515432339843,
                                43.66478416637355
                            ],
                            [
                                3.9265099524205596,
                                43.662240094108
                            ],
                            [
                                3.928541558124749,
                                43.66087661376042
                            ],
                            [
                                3.92992451863401,
                                43.66080578276657
                            ],
                            [
                                3.9310015144288855,
                                43.6603099634691
                            ],
                            [
                                3.932041794457632,
                                43.65970789167403
                            ],
                            [
                                3.934511395122712,
                                43.659141218232406
                            ],
                            [
                                3.9353436191464937,
                                43.65937142503978
                            ],
                            [
                                3.936530762237851,
                                43.65930059227017
                            ],
                            [
                                3.9385011749997147,
                                43.65987610610543
                            ],
                            [
                                3.9428825897105924,
                                43.66077920900304
                            ],
                            [
                                3.9455016476664184,
                                43.660894309376886
                            ],
                            [
                                3.945978952620578,
                                43.66139012384875
                            ]
                        ],
                        [
                            [
                                3.9468921505551293,
                                43.6601024659123
                            ],
                            [
                                3.9451574979810857,
                                43.66027419307224
                            ],
                            [
                                3.941377781320938,
                                43.65977221999242
                            ],
                            [
                                3.938109330681641,
                                43.65907209263597
                            ],
                            [
                                3.9368129061267894,
                                43.658649370279534
                            ],
                            [
                                3.9360825260956744,
                                43.65816059384454
                            ],
                            [
                                3.934786101540851,
                                43.65845121815022
                            ],
                            [
                                3.931371574895536,
                                43.65919098276234
                            ],
                            [
                                3.9301847073451484,
                                43.65896641232604
                            ],
                            [
                                3.9284135357695504,
                                43.65933629259766
                            ],
                            [
                                3.9267154021969475,
                                43.659006042464085
                            ],
                            [
                                3.925017268625794,
                                43.65759255136726
                            ],
                            [
                                3.924031255582804,
                                43.65569023045157
                            ],
                            [
                                3.922625305633318,
                                43.65335185902336
                            ],
                            [
                                3.9224061916229687,
                                43.65242706191128
                            ],
                            [
                                3.926368503291968,
                                43.651674002314536
                            ],
                            [
                                3.92832226987494,
                                43.65039245813611
                            ],
                            [
                                3.9291256879090497,
                                43.64936191823912
                            ],
                            [
                                3.929801285870383,
                                43.64802747933976
                            ],
                            [
                                3.9310429319237414,
                                43.64574168081475
                            ],
                            [
                                3.9318098309562686,
                                43.643720063974484
                            ],
                            [
                                3.9314994194429858,
                                43.64143410154
                            ],
                            [
                                3.931042934762985,
                                43.63976912424417
                            ],
                            [
                                3.9317002767911333,
                                43.63884411799492
                            ],
                            [
                                3.9346765754171145,
                                43.63959733844638
                            ],
                            [
                                3.939570121626133,
                                43.64131517432526
                            ],
                            [
                                3.9442810859183908,
                                43.64268940296944
                            ],
                            [
                                3.9494667841392754,
                                43.6441428847948
                            ],
                            [
                                3.948334695091802,
                                43.64669299974642
                            ],
                            [
                                3.946739133718836,
                                43.64847880243792
                            ],
                            [
                                3.946465241206994,
                                43.64994535622324
                            ],
                            [
                                3.947104323733697,
                                43.651834647507144
                            ],
                            [
                                3.9462278676966775,
                                43.65293120190333
                            ],
                            [
                                3.9449679621432665,
                                43.65336717579959
                            ],
                            [
                                3.9466888797837782,
                                43.65922594920579
                            ],
                            [
                                3.9468921505551293,
                                43.6601024659123
                            ]
                        ],
                        [
                            [
                                3.9184841309761964,
                                43.66811248443602
                            ],
                            [
                                3.916101257408826,
                                43.669531907494985
                            ],
                            [
                                3.914369640608669,
                                43.67043214468515
                            ],
                            [
                                3.9120399936748527,
                                43.67107874495582
                            ],
                            [
                                3.912771394457195,
                                43.67247968831066
                            ],
                            [
                                3.910304863328548,
                                43.6736726347298
                            ],
                            [
                                3.9061823736464873,
                                43.67448150365928
                            ],
                            [
                                3.902695798725688,
                                43.67524278589775
                            ],
                            [
                                3.9014458949388597,
                                43.67732832216518
                            ],
                            [
                                3.900437200655176,
                                43.677344181478844
                            ],
                            [
                                3.899428506371436,
                                43.67795476186919
                            ],
                            [
                                3.8951634853485473,
                                43.67739176300577
                            ],
                            [
                                3.8915268292753638,
                                43.676760710246725
                            ],
                            [
                                3.888577494901682,
                                43.675444360146315
                            ],
                            [
                                3.8875408639908358,
                                43.67733111254185
                            ],
                            [
                                3.884705145935385,
                                43.68270645115217
                            ],
                            [
                                3.8832030685776147,
                                43.68713064050627
                            ],
                            [
                                3.8824026915917216,
                                43.68427636214247
                            ],
                            [
                                3.8828458858912995,
                                43.681750178496316
                            ],
                            [
                                3.8817494809042614,
                                43.67901568988336
                            ],
                            [
                                3.8823634687289257,
                                43.67721568116201
                            ],
                            [
                                3.8826046800977565,
                                43.67412695641289
                            ],
                            [
                                3.8804118664378393,
                                43.67220784613883
                            ],
                            [
                                3.8771181602793945,
                                43.671063494268225
                            ],
                            [
                                3.877743112172766,
                                43.66950116824037
                            ],
                            [
                                3.8763177832934446,
                                43.66801017958912
                            ],
                            [
                                3.8742455743841333,
                                43.666780879175406
                            ],
                            [
                                3.8726817846540484,
                                43.66499579933853
                            ],
                            [
                                3.872418647014456,
                                43.66259257112975
                            ],
                            [
                                3.870152842646803,
                                43.661931934418305
                            ],
                            [
                                3.866918442497848,
                                43.661019782877645
                            ],
                            [
                                3.8675324303225125,
                                43.65992518273657
                            ],
                            [
                                3.8701233134135293,
                                43.65761314287036
                            ],
                            [
                                3.8715705704294123,
                                43.65592354711811
                            ],
                            [
                                3.873149396265603,
                                43.65528894488307
                            ],
                            [
                                3.875472960702325,
                                43.655175371963935
                            ],
                            [
                                3.8775743432516947,
                                43.65508781156177
                            ],
                            [
                                3.879092619648418,
                                43.6549684108069
                            ],
                            [
                                3.8803358557214835,
                                43.65444304892321
                            ],
                            [
                                3.881656096066763,
                                43.65352762724012
                            ],
                            [
                                3.882294212233262,
                                43.65341618364792
                            ],
                            [
                                3.8826902843369453,
                                43.65311369285459
                            ],
                            [
                                3.882448240274101,
                                43.65295448656218
                            ],
                            [
                                3.882866316382632,
                                43.65269179525609
                            ],
                            [
                                3.8835264365557123,
                                43.6517126629941
                            ],
                            [
                                3.883838485629866,
                                43.65063872453544
                            ],
                            [
                                3.88450960780537,
                                43.649165989535334
                            ],
                            [
                                3.885158718156731,
                                43.64836194375695
                            ],
                            [
                                3.886214910432642,
                                43.647860402827234
                            ],
                            [
                                3.8880192389044907,
                                43.647263324831755
                            ],
                            [
                                3.889262465229365,
                                43.646594590430425
                            ],
                            [
                                3.8913511798934906,
                                43.644777770233475
                            ],
                            [
                                3.893001480325296,
                                43.643726855062795
                            ],
                            [
                                3.8947618007857443,
                                43.64342431546916
                            ],
                            [
                                3.8960270311163185,
                                43.64370297041319
                            ],
                            [
                                3.89735827346405,
                                43.64415677712731
                            ],
                            [
                                3.897743343565338,
                                43.644538927490686
                            ],
                            [
                                3.898711529500787,
                                43.6444354370156
                            ],
                            [
                                3.901263994167664,
                                43.6448255470097
                            ],
                            [
                                3.9037064388060116,
                                43.64562168199345
                            ],
                            [
                                3.9076617085619887,
                                43.6471815270406
                            ],
                            [
                                3.911204353488529,
                                43.64774676179596
                            ],
                            [
                                3.9148924116738613,
                                43.648866697149
                            ],
                            [
                                3.918391048589001,
                                43.6498060721095
                            ],
                            [
                                3.919864462570274,
                                43.65098304765556
                            ],
                            [
                                3.9202715366772907,
                                43.65229653006449
                            ],
                            [
                                3.921459752987431,
                                43.653801029162196
                            ],
                            [
                                3.9233337239623154,
                                43.65697255952239
                            ],
                            [
                                3.9243239042210973,
                                43.65828591093299
                            ],
                            [
                                3.9256441445664336,
                                43.659352490334754
                            ],
                            [
                                3.9275584930667833,
                                43.65994944814861
                            ],
                            [
                                3.9280866128800653,
                                43.660347423846275
                            ],
                            [
                                3.926568336483342,
                                43.661326415322065
                            ],
                            [
                                3.925413126181269,
                                43.662281513573106
                            ],
                            [
                                3.9249180360523326,
                                43.66330822724626
                            ],
                            [
                                3.9238838477812976,
                                43.66472490410496
                            ],
                            [
                                3.922727782151327,
                                43.66571499370096
                            ],
                            [
                                3.9207144156247864,
                                43.66698040310325
                            ],
                            [
                                3.9184841309761964,
                                43.66811248443602
                            ]
                        ],
                        [
                            [
                                3.8662151910569946,
                                43.65987413192482
                            ],
                            [
                                3.8668259262443883,
                                43.65878530977017
                            ],
                            [
                                3.8667386783605764,
                                43.65659182547688
                            ],
                            [
                                3.8677856529685926,
                                43.655360914300104
                            ],
                            [
                                3.8672185417222806,
                                43.65346715552832
                            ],
                            [
                                3.8678510888814515,
                                43.65258338099781
                            ],
                            [
                                3.870032285979619,
                                43.65228352596989
                            ],
                            [
                                3.8712973802970794,
                                43.652772762348945
                            ],
                            [
                                3.8716027478912167,
                                43.65424044757458
                            ],
                            [
                                3.8714718760646463,
                                43.6552031033504
                            ],
                            [
                                3.8698577902120235,
                                43.65640245616865
                            ],
                            [
                                3.8678292769104985,
                                43.658548606688754
                            ],
                            [
                                3.8662151910569946,
                                43.65987413192482
                            ]
                        ],
                        [
                            [
                                3.841647373023278,
                                43.64763388799571
                            ],
                            [
                                3.842892144680235,
                                43.64605989129609
                            ],
                            [
                                3.843885447316069,
                                43.644686022315796
                            ],
                            [
                                3.8451930852733085,
                                43.64348499731051
                            ],
                            [
                                3.845972639239733,
                                43.64265700480681
                            ],
                            [
                                3.846827633913449,
                                43.64158332720564
                            ],
                            [
                                3.847003662228218,
                                43.64168341660607
                            ],
                            [
                                3.8474814533692836,
                                43.642875377564366
                            ],
                            [
                                3.848763945378863,
                                43.643612379759475
                            ],
                            [
                                3.850851138258008,
                                43.64461323246218
                            ],
                            [
                                3.85256111152691,
                                43.64574144640753
                            ],
                            [
                                3.853566987613334,
                                43.64658759297416
                            ],
                            [
                                3.854245953971258,
                                43.64777945662783
                            ],
                            [
                                3.855302511323032,
                                43.6491219623872
                            ],
                            [
                                3.856761031648375,
                                43.649813398976136
                            ],
                            [
                                3.8588470681186493,
                                43.65033527734914
                            ],
                            [
                                3.8624933689305863,
                                43.65077196624631
                            ],
                            [
                                3.861638374257808,
                                43.651426993639205
                            ],
                            [
                                3.859840370753517,
                                43.65207291643321
                            ],
                            [
                                3.857702884070193,
                                43.65300084969738
                            ],
                            [
                                3.8568101690437686,
                                43.65322828213306
                            ],
                            [
                                3.855955174370081,
                                43.65177269966074
                            ],
                            [
                                3.855012165539449,
                                43.6514815789327
                            ],
                            [
                                3.8545846682025626,
                                43.65095391901684
                            ],
                            [
                                3.8531261478772194,
                                43.650963016640475
                            ],
                            [
                                3.85224600630238,
                                43.65163623699516
                            ],
                            [
                                3.8514790257865172,
                                43.651581651842235
                            ],
                            [
                                3.8508377797812443,
                                43.65107218802194
                            ],
                            [
                                3.850561163858032,
                                43.64991678084317
                            ],
                            [
                                3.8488672122858816,
                                43.649724586879245
                            ],
                            [
                                3.848313980438519,
                                43.648550957802314
                            ],
                            [
                                3.8459627450867515,
                                43.648960365898546
                            ],
                            [
                                3.841647373023278,
                                43.64763388799571
                            ]
                        ],
                        [
                            [
                                3.844672124670211,
                                43.64260762816278
                            ],
                            [
                                3.843427310270812,
                                43.64371245314982
                            ],
                            [
                                3.8421565242387317,
                                43.645156106756474
                            ],
                            [
                                3.841077041339503,
                                43.64727208540492
                            ],
                            [
                                3.8391503657427393,
                                43.64947697333383
                            ],
                            [
                                3.837374001010545,
                                43.65111347915726
                            ],
                            [
                                3.8343013569037794,
                                43.6542477888965
                            ],
                            [
                                3.8313042696181867,
                                43.656580192306706
                            ],
                            [
                                3.830573933916554,
                                43.65843878026891
                            ],
                            [
                                3.8301205929824675,
                                43.66155448765005
                            ],
                            [
                                3.8285087141064196,
                                43.66434208878135
                            ],
                            [
                                3.826664637033616,
                                43.66658494604212
                            ],
                            [
                                3.8170624987716337,
                                43.664933244753996
                            ],
                            [
                                3.812523267789004,
                                43.66707050235908
                            ],
                            [
                                3.8087721139885957,
                                43.6662446640764
                            ],
                            [
                                3.800651848963355,
                                43.658956052077684
                            ],
                            [
                                3.798688723835511,
                                43.65980081072311
                            ],
                            [
                                3.793436970866196,
                                43.659989329694184
                            ],
                            [
                                3.790829887018276,
                                43.66054833754387
                            ],
                            [
                                3.7899365282808617,
                                43.6634414739091
                            ],
                            [
                                3.7877433046514284,
                                43.66441046043687
                            ],
                            [
                                3.7871986786498724,
                                43.66652939845474
                            ],
                            [
                                3.780957559162431,
                                43.66601830471848
                            ],
                            [
                                3.7830919041710445,
                                43.66297294748523
                            ],
                            [
                                3.775817526296237,
                                43.6612072411005
                            ],
                            [
                                3.7709894903867394,
                                43.661963293033494
                            ],
                            [
                                3.7695764067063067,
                                43.65828943028583
                            ],
                            [
                                3.763668397537117,
                                43.65655205052133
                            ],
                            [
                                3.7596928636614564,
                                43.652639783158236
                            ],
                            [
                                3.7570138925170227,
                                43.64991326871771
                            ],
                            [
                                3.7526863237446264,
                                43.64962569943182
                            ],
                            [
                                3.7479656460539275,
                                43.64711048988244
                            ],
                            [
                                3.7460520952363368,
                                43.64613057279735
                            ],
                            [
                                3.7404733586222108,
                                43.64471392527375
                            ],
                            [
                                3.7432935465915023,
                                43.64164448177479
                            ],
                            [
                                3.74853373190831,
                                43.63998272261816
                            ],
                            [
                                3.754275017812091,
                                43.63706712748743
                            ],
                            [
                                3.758205156798283,
                                43.63703516857947
                            ],
                            [
                                3.7605897355097113,
                                43.63668361946898
                            ],
                            [
                                3.764684440680412,
                                43.63151691913788
                            ],
                            [
                                3.770484754953145,
                                43.63019838748431
                            ],
                            [
                                3.774623861138082,
                                43.62926244368714
                            ],
                            [
                                3.7781778109245465,
                                43.62952939217044
                            ],
                            [
                                3.780596874961873,
                                43.62934392499244
                            ],
                            [
                                3.782463257397154,
                                43.62843791040635
                            ],
                            [
                                3.785395426400356,
                                43.62629223764159
                            ],
                            [
                                3.7885135144200603,
                                43.624164591612185
                            ],
                            [
                                3.7901345553850945,
                                43.62272519032496
                            ],
                            [
                                3.791408612855804,
                                43.62101812424089
                            ],
                            [
                                3.7928902981891497,
                                43.61927079386618
                            ],
                            [
                                3.794656097138784,
                                43.618380809116076
                            ],
                            [
                                3.7952391107479286,
                                43.61784391629308
                            ],
                            [
                                3.797798401631667,
                                43.61664645078861
                            ],
                            [
                                3.798692477049599,
                                43.61584543014658
                            ],
                            [
                                3.7999888894516403,
                                43.61401679811695
                            ],
                            [
                                3.8009388445835555,
                                43.61273834055763
                            ],
                            [
                                3.8023358478286013,
                                43.61175116047565
                            ],
                            [
                                3.803419914272922,
                                43.61166215146426
                            ],
                            [
                                3.8042022302638827,
                                43.61094198279582
                            ],
                            [
                                3.804258109976928,
                                43.61032700047764
                            ],
                            [
                                3.806526826350364,
                                43.60872478017029
                            ],
                            [
                                3.8093019634035272,
                                43.60758933413783
                            ],
                            [
                                3.8122524122822767,
                                43.60614079816639
                            ],
                            [
                                3.8150687498485922,
                                43.60571189534724
                            ],
                            [
                                3.817622840008994,
                                43.60663780562675
                            ],
                            [
                                3.8193662870741036,
                                43.607932577339994
                            ],
                            [
                                3.8199921398663435,
                                43.6089764667409
                            ],
                            [
                                3.8211097341392133,
                                43.60935679403477
                            ],
                            [
                                3.8204615294607436,
                                43.61003652193247
                            ],
                            [
                                3.8222665507147724,
                                43.61106998978761
                            ],
                            [
                                3.822121263459138,
                                43.6129229866506
                            ],
                            [
                                3.820780158051292,
                                43.614630291435816
                            ],
                            [
                                3.8179749964272105,
                                43.6151804996974
                            ],
                            [
                                3.8173156158064785,
                                43.61563360860421
                            ],
                            [
                                3.816700938957382,
                                43.61714664396584
                            ],
                            [
                                3.8185114416778276,
                                43.617672556431955
                            ],
                            [
                                3.8195172765236975,
                                43.617316554650216
                            ],
                            [
                                3.820221360400268,
                                43.61789910198348
                            ],
                            [
                                3.821808344266657,
                                43.620002697924264
                            ],
                            [
                                3.822165946481789,
                                43.621240568211704
                            ],
                            [
                                3.8221547705395267,
                                43.62214669128085
                            ],
                            [
                                3.822657687962419,
                                43.62279391368307
                            ],
                            [
                                3.82507169159058,
                                43.62457373936144
                            ],
                            [
                                3.8266139716861858,
                                43.62510767678992
                            ],
                            [
                                3.8278097975575918,
                                43.62499441773133
                            ],
                            [
                                3.831654321854245,
                                43.62354630096766
                            ],
                            [
                                3.8334248287668515,
                                43.623740234801915
                            ],
                            [
                                3.8341769518441993,
                                43.6246022766874
                            ],
                            [
                                3.8351825715744496,
                                43.6251321795348
                            ],
                            [
                                3.835874095723625,
                                43.62579657288666
                            ],
                            [
                                3.8367164978686787,
                                43.625823876566244
                            ],
                            [
                                3.838187558331839,
                                43.62677949752867
                            ],
                            [
                                3.8408530743326423,
                                43.62888838078797
                            ],
                            [
                                3.841305708321215,
                                43.63061750038648
                            ],
                            [
                                3.8382504288985047,
                                43.631454740422356
                            ],
                            [
                                3.837282295083753,
                                43.63219186601441
                            ],
                            [
                                3.8359118199511784,
                                43.633302087500454
                            ],
                            [
                                3.8334611375777854,
                                43.63438355489714
                            ],
                            [
                                3.8321343543924513,
                                43.635409664076946
                            ],
                            [
                                3.831361029113566,
                                43.636268439693566
                            ],
                            [
                                3.83075829060121,
                                43.636449513146914
                            ],
                            [
                                3.8305156788737804,
                                43.63692688599997
                            ],
                            [
                                3.830743127368862,
                                43.63742071599469
                            ],
                            [
                                3.8297992173739033,
                                43.63833154907286
                            ],
                            [
                                3.8290941270400936,
                                43.6388445714924
                            ],
                            [
                                3.8280743996210163,
                                43.638328805625804
                            ],
                            [
                                3.8282639400339633,
                                43.63776365283741
                            ],
                            [
                                3.827588269873104,
                                43.63744751867421
                            ],
                            [
                                3.827462622002969,
                                43.63785497156229
                            ],
                            [
                                3.827363553490727,
                                43.63807181231607
                            ],
                            [
                                3.8272234071197886,
                                43.63845334517305
                            ],
                            [
                                3.8271557505741214,
                                43.638745377613816
                            ],
                            [
                                3.828321088102996,
                                43.63917724682713
                            ],
                            [
                                3.8292578476026904,
                                43.63934382528768
                            ],
                            [
                                3.82981850192985,
                                43.63895116804119
                            ],
                            [
                                3.8312653518073603,
                                43.63766846981915
                            ],
                            [
                                3.8316813211470446,
                                43.637629203115154
                            ],
                            [
                                3.8322600610983386,
                                43.63721035667507
                            ],
                            [
                                3.8321696328717394,
                                43.636451190001736
                            ],
                            [
                                3.8336345726620777,
                                43.63494592031316
                            ],
                            [
                                3.835045251292655,
                                43.63433071112877
                            ],
                            [
                                3.8365825292866305,
                                43.63350605915116
                            ],
                            [
                                3.8381017216578925,
                                43.632275605002775
                            ],
                            [
                                3.8393315440541755,
                                43.63159491741732
                            ],
                            [
                                3.840995421412913,
                                43.63142474431609
                            ],
                            [
                                3.841320962636104,
                                43.63172581947458
                            ],
                            [
                                3.8421348156913098,
                                43.63209234371922
                            ],
                            [
                                3.842695470018498,
                                43.63351914895344
                            ],
                            [
                                3.8434550770887768,
                                43.63540631792975
                            ],
                            [
                                3.8444317007555355,
                                43.63679377723392
                            ],
                            [
                                3.8458785506330457,
                                43.63841680217752
                            ],
                            [
                                3.846240263103141,
                                43.63985654567463
                            ],
                            [
                                3.8461860062321875,
                                43.6409036101856
                            ],
                            [
                                3.8458785506330457,
                                43.641597280375265
                            ],
                            [
                                3.844672124670211,
                                43.64260762816278
                            ]
                        ],
                        [
                            [
                                3.814303970241383,
                                43.59157330729528
                            ],
                            [
                                3.8192938139810337,
                                43.592934734201975
                            ],
                            [
                                3.8191229295937887,
                                43.59570699933025
                            ],
                            [
                                3.8185760974028256,
                                43.600533406027495
                            ],
                            [
                                3.819054547791012,
                                43.60392404395924
                            ],
                            [
                                3.8193621405591784,
                                43.60595337983537
                            ],
                            [
                                3.8161495014384457,
                                43.60476548420749
                            ],
                            [
                                3.8128001542716277,
                                43.60471598804739
                            ],
                            [
                                3.810202701365597,
                                43.60568115582595
                            ],
                            [
                                3.8051103265903805,
                                43.60813112756131
                            ],
                            [
                                3.801316678268165,
                                43.61085320134444
                            ],
                            [
                                3.798826158753741,
                                43.61343141558021
                            ],
                            [
                                3.797629963336277,
                                43.61538619966677
                            ],
                            [
                                3.7938363150140333,
                                43.61736566310131
                            ],
                            [
                                3.7909312690013337,
                                43.61845434021541
                            ],
                            [
                                3.7904186138235616,
                                43.620136802439305
                            ],
                            [
                                3.788402628274838,
                                43.622499847675584
                            ],
                            [
                                3.784301386845925,
                                43.62554288572571
                            ],
                            [
                                3.781601402904357,
                                43.627522014790856
                            ],
                            [
                                3.7794824274873235,
                                43.62848681688973
                            ],
                            [
                                3.774971061914897,
                                43.62831364847733
                            ],
                            [
                                3.775127493069732,
                                43.62745969828592
                            ],
                            [
                                3.7789553184038027,
                                43.626692792710884
                            ],
                            [
                                3.779775566689608,
                                43.62367455316547
                            ],
                            [
                                3.7829540287984287,
                                43.62186848490444
                            ],
                            [
                                3.7837000353355847,
                                43.61981734648515
                            ],
                            [
                                3.78742532987107,
                                43.61630389732869
                            ],
                            [
                                3.7888949413833473,
                                43.615066718472804
                            ],
                            [
                                3.790979739110327,
                                43.615635823907695
                            ],
                            [
                                3.79586705181444,
                                43.613903747140085
                            ],
                            [
                                3.797883495517681,
                                43.61086011997125
                            ],
                            [
                                3.8007665021311254,
                                43.608876278038366
                            ],
                            [
                                3.805072805632477,
                                43.605164206693644
                            ],
                            [
                                3.806269001049941,
                                43.60395154708212
                            ],
                            [
                                3.8054487527641356,
                                43.60298635155348
                            ],
                            [
                                3.8062348240380572,
                                43.60187264516233
                            ],
                            [
                                3.8039107872281193,
                                43.60041242110216
                            ],
                            [
                                3.8052094971250767,
                                43.59900164661741
                            ],
                            [
                                3.8135828650434007,
                                43.600016406141805
                            ],
                            [
                                3.815223361614926,
                                43.59865513944561
                            ],
                            [
                                3.8151208305793602,
                                43.593630561208585
                            ],
                            [
                                3.814303970241383,
                                43.59157330729528
                            ]
                        ],
                        [
                            [
                                3.8164598849694187,
                                43.59111354071979
                            ],
                            [
                                3.816614135746903,
                                43.59094901755094
                            ],
                            [
                                3.816720232427002,
                                43.59069484140829
                            ],
                            [
                                3.817063006318193,
                                43.58942985580478
                            ],
                            [
                                3.816858064936838,
                                43.58673890751925
                            ],
                            [
                                3.8160666589900245,
                                43.58529295620201
                            ],
                            [
                                3.816531852128122,
                                43.58359629847973
                            ],
                            [
                                3.8158499786347306,
                                43.58239298916379
                            ],
                            [
                                3.81539294677944,
                                43.58051297533936
                            ],
                            [
                                3.8147910994655376,
                                43.5799693650649
                            ],
                            [
                                3.814807422031123,
                                43.57898202978228
                            ],
                            [
                                3.8151257120726427,
                                43.5774093545229
                            ],
                            [
                                3.8154908043316595,
                                43.57644611134077
                            ],
                            [
                                3.815123546590854,
                                43.575618357780144
                            ],
                            [
                                3.8152610770038677,
                                43.57479342233455
                            ],
                            [
                                3.814926464396734,
                                43.57351036425223
                            ],
                            [
                                3.814186167449151,
                                43.572537362254025
                            ],
                            [
                                3.8126355236567235,
                                43.57161494769238
                            ],
                            [
                                3.8102708354136894,
                                43.570426114747704
                            ],
                            [
                                3.8080264825547943,
                                43.568622599610194
                            ],
                            [
                                3.8055536137689217,
                                43.56755820468098
                            ],
                            [
                                3.805104743196381,
                                43.56674215583078
                            ],
                            [
                                3.805610742751014,
                                43.566345954213375
                            ],
                            [
                                3.808736514186819,
                                43.56845702830083
                            ],
                            [
                                3.8120477929229253,
                                43.570637841241904
                            ],
                            [
                                3.814280340430116,
                                43.572186458187645
                            ],
                            [
                                3.815398436218146,
                                43.57293147936073
                            ],
                            [
                                3.817210241071365,
                                43.57342815502247
                            ],
                            [
                                3.820244792310234,
                                43.57408405497506
                            ],
                            [
                                3.8240736573316667,
                                43.5749881151701
                            ],
                            [
                                3.824970211093415,
                                43.575248498419
                            ],
                            [
                                3.826790965649934,
                                43.575580413895324
                            ],
                            [
                                3.8282444163963305,
                                43.5756261983916
                            ],
                            [
                                3.8297057595320894,
                                43.57577784870389
                            ],
                            [
                                3.8308748340413956,
                                43.575411598240805
                            ],
                            [
                                3.8317737425231257,
                                43.57498139951184
                            ],
                            [
                                3.8310799905482895,
                                43.57436410354984
                            ],
                            [
                                3.8293991552263833,
                                43.57499151906521
                            ],
                            [
                                3.8280169024731947,
                                43.57512771401457
                            ],
                            [
                                3.8260132971866767,
                                43.57495394121912
                            ],
                            [
                                3.8230361069794583,
                                43.57424860186643
                            ],
                            [
                                3.8189724584551072,
                                43.5732876824776
                            ],
                            [
                                3.8164044555284136,
                                43.57273566319614
                            ],
                            [
                                3.814725376690774,
                                43.57181561981585
                            ],
                            [
                                3.8125242313254546,
                                43.57042530537382
                            ],
                            [
                                3.8095276857164606,
                                43.568307897854254
                            ],
                            [
                                3.805830890294203,
                                43.565884908607615
                            ],
                            [
                                3.8069772845471164,
                                43.562533386151046
                            ],
                            [
                                3.8065367287063054,
                                43.56145405224876
                            ],
                            [
                                3.807040221095747,
                                43.56049631724326
                            ],
                            [
                                3.806998263396707,
                                43.55842877389634
                            ],
                            [
                                3.8076695865078705,
                                43.55768383279906
                            ],
                            [
                                3.809054190579076,
                                43.55775984761797
                            ],
                            [
                                3.81092130819016,
                                43.559067287496475
                            ],
                            [
                                3.812326891111695,
                                43.55993383085294
                            ],
                            [
                                3.8141520510237115,
                                43.55984261634927
                            ],
                            [
                                3.8157085270769358,
                                43.559135765691025
                            ],
                            [
                                3.8175546658393387,
                                43.556110370933624
                            ],
                            [
                                3.819589614246638,
                                43.55395145366427
                            ],
                            [
                                3.8222119704423108,
                                43.556140777666684
                            ],
                            [
                                3.824960199735102,
                                43.55880130740729
                            ],
                            [
                                3.8277923444260296,
                                43.56114247640619
                            ],
                            [
                                3.8315958090924482,
                                43.56345366474889
                            ],
                            [
                                3.833515391379734,
                                43.56460726934995
                            ],
                            [
                                3.8335995180393923,
                                43.56590983116496
                            ],
                            [
                                3.833750060908386,
                                43.5671449920936
                            ],
                            [
                                3.8344022606833903,
                                43.56789130580876
                            ],
                            [
                                3.8351549729013357,
                                43.56868371120393
                            ],
                            [
                                3.836838943891223,
                                43.56940943488581
                            ],
                            [
                                3.8368832210801145,
                                43.569938762336676
                            ],
                            [
                                3.8369983316718788,
                                43.57048413126114
                            ],
                            [
                                3.8367503794122513,
                                43.570743978863476
                            ],
                            [
                                3.837303844278807,
                                43.57133103783784
                            ],
                            [
                                3.83779974879954,
                                43.57139519694161
                            ],
                            [
                                3.838140683156439,
                                43.57181222945081
                            ],
                            [
                                3.8395841195284675,
                                43.57170957556281
                            ],
                            [
                                3.839570837331422,
                                43.570747186399444
                            ],
                            [
                                3.838857974582936,
                                43.57064773868939
                            ],
                            [
                                3.838826980551005,
                                43.57017616182816
                            ],
                            [
                                3.8387561370482217,
                                43.56984894306123
                            ],
                            [
                                3.8384791024408003,
                                43.569599871184494
                            ],
                            [
                                3.837320710148674,
                                43.56906031951377
                            ],
                            [
                                3.835723783633057,
                                43.56829894393408
                            ],
                            [
                                3.834813618260739,
                                43.5671538649012
                            ],
                            [
                                3.8347143274930886,
                                43.56623059160938
                            ],
                            [
                                3.8357817032465107,
                                43.56575096353504
                            ],
                            [
                                3.839408619866674,
                                43.566803979361595
                            ],
                            [
                                3.8436783363637232,
                                43.567990242237926
                            ],
                            [
                                3.848619013445159,
                                43.569073090843204
                            ],
                            [
                                3.848117913270414,
                                43.57097758323118
                            ],
                            [
                                3.8485311015681987,
                                43.57168458681187
                            ],
                            [
                                3.848522310326871,
                                43.572920230799184
                            ],
                            [
                                3.848351976871527,
                                43.574955107514114
                            ],
                            [
                                3.848305780061253,
                                43.57631258599574
                            ],
                            [
                                3.8480172736742304,
                                43.578987869723306
                            ],
                            [
                                3.847746021302868,
                                43.58114692135399
                            ],
                            [
                                3.847617947271061,
                                43.58216230187034
                            ],
                            [
                                3.8476962147348956,
                                43.583105508022896
                            ],
                            [
                                3.8470202684572996,
                                43.58346113929318
                            ],
                            [
                                3.8469082153882823,
                                43.58438453352963
                            ],
                            [
                                3.845398571492126,
                                43.58517523126545
                            ],
                            [
                                3.8436102241070387,
                                43.58536028668291
                            ],
                            [
                                3.839685155976639,
                                43.58534347200586
                            ],
                            [
                                3.8374555280679488,
                                43.58561264258151
                            ],
                            [
                                3.835806532427739,
                                43.586336032539265
                            ],
                            [
                                3.8336698056817227,
                                43.587177172721596
                            ],
                            [
                                3.8321833870762134,
                                43.587059413803814
                            ],
                            [
                                3.8303950593957836,
                                43.5863360207056
                            ],
                            [
                                3.828978316662301,
                                43.5863696665385
                            ],
                            [
                                3.8270970681154495,
                                43.5869921110567
                            ],
                            [
                                3.824263582648456,
                                43.588035111766004
                            ],
                            [
                                3.8211978442748205,
                                43.58912856084578
                            ],
                            [
                                3.820129480901329,
                                43.59044067352119
                            ],
                            [
                                3.8205939867156076,
                                43.59165182906327
                            ],
                            [
                                3.8195720739244337,
                                43.5921060061078
                            ],
                            [
                                3.8164598849694187,
                                43.59111354071979
                            ]
                        ],
                        [
                            [
                                3.8181814968534695,
                                43.54667414861055
                            ],
                            [
                                3.8212174975768107,
                                43.54584060725605
                            ],
                            [
                                3.8228274979611285,
                                43.54570723956999
                            ],
                            [
                                3.8236554981578195,
                                43.54710758555939
                            ],
                            [
                                3.825679498640085,
                                43.54864126050694
                            ],
                            [
                                3.8298194996271775,
                                43.55034159363743
                            ],
                            [
                                3.837271501507871,
                                43.55310869990254
                            ],
                            [
                                3.8439875031087354,
                                43.55527562212609
                            ],
                            [
                                3.847621503974352,
                                43.55610903300297
                            ],
                            [
                                3.8476675039852353,
                                43.55827584734004
                            ],
                            [
                                3.847713503997028,
                                43.55947589559926
                            ],
                            [
                                3.8488717171548217,
                                43.56167528108145
                            ],
                            [
                                3.849607717330656,
                                43.56424187703914
                            ],
                            [
                                3.8491477172209443,
                                43.56774160439923
                            ],
                            [
                                3.842293715587516,
                                43.56604176221987
                            ],
                            [
                                3.8388897147762577,
                                43.56500850137593
                            ],
                            [
                                3.838252115515388,
                                43.564039093855996
                            ],
                            [
                                3.835836133104209,
                                43.563079480609446
                            ],
                            [
                                3.8348876363056377,
                                43.56379270812943
                            ],
                            [
                                3.832346380733327,
                                43.5623921806461
                            ],
                            [
                                3.8299125021569296,
                                43.56073225406027
                            ],
                            [
                                3.827234673993246,
                                43.55873441832796
                            ],
                            [
                                3.8248544839140663,
                                43.556023881273404
                            ],
                            [
                                3.8209173273931185,
                                43.553196492609516
                            ],
                            [
                                3.8224291773374546,
                                43.55142413211064
                            ],
                            [
                                3.823198712476426,
                                43.55058105291397
                            ],
                            [
                                3.8197805447694577,
                                43.54915427663778
                            ],
                            [
                                3.8189406210906895,
                                43.54777000125435
                            ],
                            [
                                3.8181814968534695,
                                43.54667414861055
                            ]
                        ],
                        [
                            [
                                3.8174330565338153,
                                43.545760872367566
                            ],
                            [
                                3.816915891644584,
                                43.54477943012367
                            ],
                            [
                                3.816104895321075,
                                43.54367722104746
                            ],
                            [
                                3.816138686834506,
                                43.54367722104746
                            ],
                            [
                                3.8183013436975557,
                                43.538655791352625
                            ],
                            [
                                3.816959965626694,
                                43.534671462535954
                            ],
                            [
                                3.8105395780644358,
                                43.53148671414749
                            ],
                            [
                                3.8191902055165485,
                                43.52705228437614
                            ],
                            [
                                3.820474283029256,
                                43.527909796222474
                            ],
                            [
                                3.821623194486989,
                                43.52977177996971
                            ],
                            [
                                3.8218597448000935,
                                43.53241075718182
                            ],
                            [
                                3.8222314514477773,
                                43.53802061519508
                            ],
                            [
                                3.822299034474611,
                                43.544095331319824
                            ],
                            [
                                3.8207784163679435,
                                43.54485462779911
                            ],
                            [
                                3.8174330565338153,
                                43.545760872367566
                            ]
                        ],
                        [
                            [
                                3.820785256660173,
                                43.525924787744486
                            ],
                            [
                                3.8236337585213676,
                                43.52431302798067
                            ],
                            [
                                3.8263719381463375,
                                43.522880915336316
                            ],
                            [
                                3.830277210725768,
                                43.52104190265288
                            ],
                            [
                                3.8342784015270865,
                                43.51743276255999
                            ],
                            [
                                3.830520208056896,
                                43.510278399755606
                            ],
                            [
                                3.8326341918867968,
                                43.507155197690736
                            ],
                            [
                                3.844881438179357,
                                43.50618300673952
                            ],
                            [
                                3.858127295197761,
                                43.49483847492755
                            ],
                            [
                                3.8287945855414307,
                                43.47314085478246
                            ],
                            [
                                3.8237314762576204,
                                43.46868057775697
                            ],
                            [
                                3.8281751666286254,
                                43.46635587341626
                            ],
                            [
                                3.8515376451692305,
                                43.48417560196276
                            ],
                            [
                                3.8721559392824645,
                                43.49782314155519
                            ],
                            [
                                3.908388878498471,
                                43.51646078616463
                            ],
                            [
                                3.898224382224612,
                                43.523990229932366
                            ],
                            [
                                3.89912448627058,
                                43.53759401186878
                            ],
                            [
                                3.8963133949972644,
                                43.538731047581706
                            ],
                            [
                                3.8963133949972644,
                                43.54025198390741
                            ],
                            [
                                3.8953559943462324,
                                43.54302796293774
                            ],
                            [
                                3.8932739106429324,
                                43.54802035872302
                            ],
                            [
                                3.891990586365779,
                                43.552198506038366
                            ],
                            [
                                3.891847994778857,
                                43.55510679802478
                            ],
                            [
                                3.892071193065931,
                                43.557874414122836
                            ],
                            [
                                3.892682299865072,
                                43.561417158585385
                            ],
                            [
                                3.8943730208135605,
                                43.568204043590725
                            ],
                            [
                                3.8943119101338652,
                                43.5718790067246
                            ],
                            [
                                3.8955912659225476,
                                43.57377493054378
                            ],
                            [
                                3.8968134795199774,
                                43.577567610223014
                            ],
                            [
                                3.8969968115592337,
                                43.58012052466964
                            ],
                            [
                                3.897200513825851,
                                43.58169944069925
                            ],
                            [
                                3.8968134795199774,
                                43.58277662133224
                            ],
                            [
                                3.892250548756465,
                                43.580031986096316
                            ],
                            [
                                3.8829498563754044,
                                43.57473163369252
                            ],
                            [
                                3.877938780627005,
                                43.57223752419492
                            ],
                            [
                                3.8721536362660913,
                                43.57052553126607
                            ],
                            [
                                3.8670802854325075,
                                43.56982118660554
                            ],
                            [
                                3.862588078444503,
                                43.56987879297097
                            ],
                            [
                                3.8584536578531754,
                                43.56895708451975
                            ],
                            [
                                3.855193826232977,
                                43.568899477272765
                            ],
                            [
                                3.850848164806308,
                                43.56782464991858
                            ],
                            [
                                3.851166197158875,
                                43.565405052636805
                            ],
                            [
                                3.851126443114822,
                                43.563100583914974
                            ],
                            [
                                3.849854313702167,
                                43.5590387431852
                            ],
                            [
                                3.8496158493426833,
                                43.556532364314165
                            ],
                            [
                                3.849059292724121,
                                43.5554087813706
                            ],
                            [
                                3.8454814287513273,
                                43.55434279869888
                            ],
                            [
                                3.8397568463940104,
                                43.55247008077794
                            ],
                            [
                                3.8339925099933225,
                                43.5504244300875
                            ],
                            [
                                3.8282679276359772,
                                43.54834989650749
                            ],
                            [
                                3.826399487561474,
                                43.54742785866355
                            ],
                            [
                                3.8250786934278267,
                                43.54589663712778
                            ],
                            [
                                3.8255557419574586,
                                43.545205081921694
                            ],
                            [
                                3.825197955559986,
                                43.543908394527534
                            ],
                            [
                                3.8237668099710618,
                                43.54341852758631
                            ],
                            [
                                3.8236873018828987,
                                43.53745335786064
                            ],
                            [
                                3.8230512371762018,
                                43.52831714723803
                            ],
                            [
                                3.820785256660173,
                                43.525924787744486
                            ]
                        ],
                        [
                            [
                                3.9280492079194005,
                                43.59683861048043
                            ],
                            [
                                3.9289275255553093,
                                43.59578767605021
                            ],
                            [
                                3.927018139390526,
                                43.59477820859229
                            ],
                            [
                                3.927915550888798,
                                43.59401763973267
                            ],
                            [
                                3.9305505037965247,
                                43.594197411421675
                            ],
                            [
                                3.9311806012306363,
                                43.593201746088255
                            ],
                            [
                                3.9325362654076628,
                                43.59191565398447
                            ],
                            [
                                3.9349039042527636,
                                43.590836974899645
                            ],
                            [
                                3.936813290418968,
                                43.592067773838664
                            ],
                            [
                                3.9374624817139647,
                                43.593561293803305
                            ],
                            [
                                3.939868308282229,
                                43.59613338019159
                            ],
                            [
                                3.9393527739597403,
                                43.598622391362966
                            ],
                            [
                                3.93847445632386,
                                43.60109747260768
                            ],
                            [
                                3.9280492079194005,
                                43.59683861048043
                            ]
                        ],
                        [
                            [
                                3.9136026902625076,
                                43.59088113287362
                            ],
                            [
                                3.9098917698044318,
                                43.58909450854003
                            ],
                            [
                                3.9047307450511823,
                                43.58682033514364
                            ],
                            [
                                3.8982657535464114,
                                43.58340790017007
                            ],
                            [
                                3.899180327699696,
                                43.58207343824705
                            ],
                            [
                                3.898850566241947,
                                43.57935375931032
                            ],
                            [
                                3.897688321270408,
                                43.57417509051646
                            ],
                            [
                                3.897856420053756,
                                43.57216233124197
                            ],
                            [
                                3.8983257622176097,
                                43.570847489347784
                            ],
                            [
                                3.9007379556749697,
                                43.57041957866724
                            ],
                            [
                                3.902677903288179,
                                43.570614541180134
                            ],
                            [
                                3.904586561423997,
                                43.571099677717854
                            ],
                            [
                                3.905425119424507,
                                43.57174349774283
                            ],
                            [
                                3.9062887090070717,
                                43.57181150649856
                            ],
                            [
                                3.9068519196039517,
                                43.57156667461879
                            ],
                            [
                                3.906920756454838,
                                43.57096819249989
                            ],
                            [
                                3.9061072487674267,
                                43.570687084702286
                            ],
                            [
                                3.9053187539310557,
                                43.569902697744766
                            ],
                            [
                                3.9044301327664073,
                                43.570492122424184
                            ],
                            [
                                3.9029640343328538,
                                43.57002222528888
                            ],
                            [
                                3.900326217846896,
                                43.56982314329517
                            ],
                            [
                                3.8978670035180016,
                                43.57025116876815
                            ],
                            [
                                3.8989636115132953,
                                43.57735358031903
                            ],
                            [
                                3.8976413527618377,
                                43.57250913354278
                            ],
                            [
                                3.8981324774413793,
                                43.57103108914416
                            ],
                            [
                                3.8968479975114008,
                                43.56952563626555
                            ],
                            [
                                3.8959413042701954,
                                43.5638621646188
                            ],
                            [
                                3.894052363196579,
                                43.55715496864573
                            ],
                            [
                                3.894845718447158,
                                43.55291125496507
                            ],
                            [
                                3.8956427588412623,
                                43.54884028540616
                            ],
                            [
                                3.8980606034141942,
                                43.54177541566483
                            ],
                            [
                                3.899873986981106,
                                43.540077532856515
                            ],
                            [
                                3.9070994364516594,
                                43.545471850674005
                            ],
                            [
                                3.9335910917771173,
                                43.53771529444697
                            ],
                            [
                                3.9563200990080816,
                                43.54380038764009
                            ],
                            [
                                3.9689617227328995,
                                43.54687687244811
                            ],
                            [
                                3.9680943570756995,
                                43.5501506441085
                            ],
                            [
                                3.968159818634547,
                                43.55217886994822
                            ],
                            [
                                3.9664250924948874,
                                43.55468143130318
                            ],
                            [
                                3.9646004612077945,
                                43.55650177740401
                            ],
                            [
                                3.9625875182686627,
                                43.55908718437732
                            ],
                            [
                                3.9600650267140622,
                                43.561937383757254
                            ],
                            [
                                3.9583477539879937,
                                43.56361964822048
                            ],
                            [
                                3.9556173317729986,
                                43.566718741859034
                            ],
                            [
                                3.955311839769223,
                                43.56990425389844
                            ],
                            [
                                3.954280771238814,
                                43.57209001023895
                            ],
                            [
                                3.951550349023819,
                                43.57406818968036
                            ],
                            [
                                3.948533529086234,
                                43.57594947268893
                            ],
                            [
                                3.945535792807135,
                                43.57863296897659
                            ],
                            [
                                3.943683688226457,
                                43.5789787716551
                            ],
                            [
                                3.940284977011146,
                                43.58292080467078
                            ],
                            [
                                3.936867175774921,
                                43.58480181328821
                            ],
                            [
                                3.9351296343639603,
                                43.58507842724546
                            ],
                            [
                                3.9330593352858045,
                                43.58646941402171
                            ],
                            [
                                3.932333768542861,
                                43.588032222888586
                            ],
                            [
                                3.9339567467840766,
                                43.589456694736754
                            ],
                            [
                                3.9338803713375796,
                                43.59012051476239
                            ],
                            [
                                3.933784902028748,
                                43.59056305737823
                            ],
                            [
                                3.9323910501284445,
                                43.59101942604326
                            ],
                            [
                                3.9311117613978297,
                                43.592098101858056
                            ],
                            [
                                3.929431501572509,
                                43.59342567632157
                            ],
                            [
                                3.927541209268668,
                                43.59319058714411
                            ],
                            [
                                3.9262810143989384,
                                43.59395116645638
                            ],
                            [
                                3.922958682471517,
                                43.59259594571384
                            ],
                            [
                                3.9214311735391334,
                                43.594130938343454
                            ],
                            [
                                3.9136026902625076,
                                43.59088113287362
                            ]
                        ],
                        [
                            [
                                3.9080835596199677,
                                43.59426644112128
                            ],
                            [
                                3.9009273301387566,
                                43.590442917643145
                            ],
                            [
                                3.89549172413939,
                                43.58724230011805
                            ],
                            [
                                3.897681608570622,
                                43.58483464380177
                            ],
                            [
                                3.9120331726119844,
                                43.59149087046478
                            ],
                            [
                                3.9269155127322506,
                                43.59801159769867
                            ],
                            [
                                3.9370828330252436,
                                43.6020611833315
                            ],
                            [
                                3.9345018978019084,
                                43.60449646723029
                            ],
                            [
                                3.9305913898889457,
                                43.60412834924472
                            ],
                            [
                                3.920580489630936,
                                43.600192177667424
                            ],
                            [
                                3.9146365176029576,
                                43.5974168803277
                            ],
                            [
                                3.9080835596199677,
                                43.59426644112128
                            ]
                        ],
                        [
                            [
                                3.8946227452665596,
                                43.58493442407877
                            ],
                            [
                                3.892315544057709,
                                43.58487777213813
                            ],
                            [
                                3.8884441412238004,
                                43.582073441275924
                            ],
                            [
                                3.8780421901744546,
                                43.57470790990223
                            ],
                            [
                                3.8777684546202806,
                                43.57391464509095
                            ],
                            [
                                3.882343748883528,
                                43.57637939777493
                            ],
                            [
                                3.89020386978936,
                                43.58074204635062
                            ],
                            [
                                3.895835001184821,
                                43.58382970397395
                            ],
                            [
                                3.8946227452665596,
                                43.58493442407877
                            ]
                        ],
                        [
                            [
                                3.860417423532681,
                                43.585085369758474
                            ],
                            [
                                3.861632221677013,
                                43.583808946944345
                            ],
                            [
                                3.8641871089199356,
                                43.582088342972156
                            ],
                            [
                                3.867328585479328,
                                43.580743209210425
                            ],
                            [
                                3.8705962608082984,
                                43.57936801241647
                            ],
                            [
                                3.8732954334933822,
                                43.57791959865094
                            ],
                            [
                                3.874670012176068,
                                43.57683326546157
                            ],
                            [
                                3.875169858969656,
                                43.5765797848978
                            ],
                            [
                                3.875919629159597,
                                43.575294259894235
                            ],
                            [
                                3.876819353388271,
                                43.5755296397644
                            ],
                            [
                                3.8836679221591908,
                                43.5806551442723
                            ],
                            [
                                3.8874464151423354,
                                43.583278636866936
                            ],
                            [
                                3.891495900661482,
                                43.58639781779985
                            ],
                            [
                                3.89208050999639,
                                43.587471933733326
                            ],
                            [
                                3.8910823964984047,
                                43.58814324645854
                            ],
                            [
                                3.889884660301391,
                                43.58815357428813
                            ],
                            [
                                3.889385603552938,
                                43.588659635767755
                            ],
                            [
                                3.88917172208906,
                                43.58921733124595
                            ],
                            [
                                3.8883304549981403,
                                43.58922765889125
                            ],
                            [
                                3.8870899425089647,
                                43.58948584944787
                            ],
                            [
                                3.885521478440978,
                                43.58990927956327
                            ],
                            [
                                3.884851316521889,
                                43.58921733124595
                            ],
                            [
                                3.883582286503099,
                                43.5897337113403
                            ],
                            [
                                3.8845804000010844,
                                43.59088005931545
                            ],
                            [
                                3.885934982603999,
                                43.5903843439516
                            ],
                            [
                                3.8871184600374136,
                                43.59007451977595
                            ],
                            [
                                3.888273419941214,
                                43.58972338378183
                            ],
                            [
                                3.8891859808538243,
                                43.58972338378183
                            ],
                            [
                                3.8898524355128643,
                                43.590046809802885
                            ],
                            [
                                3.891144787544704,
                                43.589681524876255
                            ],
                            [
                                3.892783868169772,
                                43.58874547213364
                            ],
                            [
                                3.8949272812952813,
                                43.58917925448267
                            ],
                            [
                                3.901231437548347,
                                43.592512425068776
                            ],
                            [
                                3.912894651991536,
                                43.59823902175253
                            ],
                            [
                                3.918569640162815,
                                43.600779188255785
                            ],
                            [
                                3.9256690354199577,
                                43.60394338990335
                            ],
                            [
                                3.9297327832310884,
                                43.60558506143323
                            ],
                            [
                                3.9303316513295954,
                                43.60677240600313
                            ],
                            [
                                3.9290198450187575,
                                43.60755707303093
                            ],
                            [
                                3.927094911845728,
                                43.60772226478477
                            ],
                            [
                                3.925583777913147,
                                43.60683457183757
                            ],
                            [
                                3.92437178295242,
                                43.60679327333219
                            ],
                            [
                                3.9229031302345447,
                                43.60638028671792
                            ],
                            [
                                3.921990569169367,
                                43.605781450951184
                            ],
                            [
                                3.920265258695423,
                                43.60535813251482
                            ],
                            [
                                3.920165447345312,
                                43.604965785938134
                            ],
                            [
                                3.919709166890158,
                                43.60435661118245
                            ],
                            [
                                3.916843155274961,
                                43.60372677994903
                            ],
                            [
                                3.9164153923482274,
                                43.60413978478084
                            ],
                            [
                                3.9164153923482274,
                                43.60526520855663
                            ],
                            [
                                3.9188393822707894,
                                43.60564722835471
                            ],
                            [
                                3.9215342823504216,
                                43.60662809962702
                            ],
                            [
                                3.923145522710513,
                                43.60715465489403
                            ],
                            [
                                3.924229188793845,
                                43.607959965797846
                            ],
                            [
                                3.925450108584812,
                                43.608316456184525
                            ],
                            [
                                3.928588509522598,
                                43.60869992709877
                            ],
                            [
                                3.930314630037998,
                                43.60874253482717
                            ],
                            [
                                3.9318839734269204,
                                43.608414179555496
                            ],
                            [
                                3.9350689140442796,
                                43.60868991087756
                            ],
                            [
                                3.93777885230071,
                                43.61021248739502
                            ],
                            [
                                3.9393188657890335,
                                43.61344375583798
                            ],
                            [
                                3.939294121650505,
                                43.61761594229887
                            ],
                            [
                                3.9395455524249314,
                                43.6194816775199
                            ],
                            [
                                3.937345533036023,
                                43.62132460309357
                            ],
                            [
                                3.936779813796335,
                                43.62320159878942
                            ],
                            [
                                3.9363910469553787,
                                43.6257427806234
                            ],
                            [
                                3.9348353190430885,
                                43.6275058910897
                            ],
                            [
                                3.9336724517145285,
                                43.6286206052155
                            ],
                            [
                                3.9359667575249944,
                                43.63008790147762
                            ],
                            [
                                3.937074897517533,
                                43.631700801350576
                            ],
                            [
                                3.9411281961232305,
                                43.634262247138594
                            ],
                            [
                                3.9461040305520783,
                                43.63852121584563
                            ],
                            [
                                3.94904958376614,
                                43.64190188992316
                            ],
                            [
                                3.949544883835273,
                                43.64348035068451
                            ],
                            [
                                3.945230058226258,
                                43.64227410711439
                            ],
                            [
                                3.9438108330159025,
                                43.64161927903592
                            ],
                            [
                                3.9430869329133884,
                                43.64152277745188
                            ],
                            [
                                3.9400459726105908,
                                43.640682114981104
                            ],
                            [
                                3.936629188171196,
                                43.63944360221711
                            ],
                            [
                                3.933819312772897,
                                43.63858883755867
                            ],
                            [
                                3.931714287358062,
                                43.6380235832556
                            ],
                            [
                                3.931423442400728,
                                43.63626368010139
                            ],
                            [
                                3.930525126835704,
                                43.637701625424825
                            ],
                            [
                                3.9277244959577615,
                                43.63632486996494
                            ],
                            [
                                3.9248283886334434,
                                43.635008999796725
                            ],
                            [
                                3.923528473169796,
                                43.634343542112106
                            ],
                            [
                                3.923665862608374,
                                43.633961091831736
                            ],
                            [
                                3.924014620416443,
                                43.63305085037595
                            ],
                            [
                                3.922799252299825,
                                43.632653093478524
                            ],
                            [
                                3.9219749156639807,
                                43.63276783131536
                            ],
                            [
                                3.921700136785063,
                                43.63344095554544
                            ],
                            [
                                3.9210237580075216,
                                43.633471551922156
                            ],
                            [
                                3.9208335264756613,
                                43.6342364562787
                            ],
                            [
                                3.9219326423402947,
                                43.634550065512684
                            ],
                            [
                                3.925423932675102,
                                43.636132513577024
                            ],
                            [
                                3.9260433855721146,
                                43.636701533526576
                            ],
                            [
                                3.9273775918126432,
                                43.63702914862927
                            ],
                            [
                                3.929569502064055,
                                43.63796025548464
                            ],
                            [
                                3.9294449022002027,
                                43.63950283077446
                            ],
                            [
                                3.929983827058834,
                                43.64117988428771
                            ],
                            [
                                3.9304149669471258,
                                43.64325988562405
                            ],
                            [
                                3.928951531635761,
                                43.64611282770508
                            ],
                            [
                                3.927370685380936,
                                43.64908956294664
                            ],
                            [
                                3.9242089928713426,
                                43.64925854349144
                            ],
                            [
                                3.9220532934326116,
                                43.64713975303664
                            ],
                            [
                                3.916699973161826,
                                43.64856961982599
                            ],
                            [
                                3.911887957229254,
                                43.647184801091015
                            ],
                            [
                                3.906372959498782,
                                43.64566390656361
                            ],
                            [
                                3.9013647522956774,
                                43.64427901843558
                            ],
                            [
                                3.898023418166531,
                                43.64336904214949
                            ],
                            [
                                3.895221008896044,
                                43.642225052404996
                            ],
                            [
                                3.8941072308534785,
                                43.642745050444006
                            ],
                            [
                                3.8926037134922353,
                                43.6432153978715
                            ],
                            [
                                3.890751234546485,
                                43.64424658070209
                            ],
                            [
                                3.8891633954496285,
                                43.645668110870275
                            ],
                            [
                                3.886799168626311,
                                43.64686885717899
                            ],
                            [
                                3.884346160791466,
                                43.647863151882945
                            ],
                            [
                                3.8837965241805534,
                                43.64908574354959
                            ],
                            [
                                3.8831904210212826,
                                43.65056750259723
                            ],
                            [
                                3.88223364618122,
                                43.65200361233812
                            ],
                            [
                                3.8809308038455868,
                                43.65293890637031
                            ],
                            [
                                3.8792004663686157,
                                43.65416139471833
                            ],
                            [
                                3.8776743020399067,
                                43.65444320773554
                            ],
                            [
                                3.8749770112667363,
                                43.65439165776695
                            ],
                            [
                                3.873338279891641,
                                43.6532133606961
                            ],
                            [
                                3.872449912048495,
                                43.65246011385486
                            ],
                            [
                                3.8707195745714102,
                                43.651576364795545
                            ],
                            [
                                3.871541293699323,
                                43.6502066262982
                            ],
                            [
                                3.8704114851114753,
                                43.64880729844609
                            ],
                            [
                                3.8713173676724466,
                                43.64796768608582
                            ],
                            [
                                3.869373282807601,
                                43.647695177789444
                            ],
                            [
                                3.868192586675434,
                                43.647982410530204
                            ],
                            [
                                3.8664418922869856,
                                43.649668987966265
                            ],
                            [
                                3.865393511344962,
                                43.65014770219031
                            ],
                            [
                                3.863663173867991,
                                43.650096148534516
                            ],
                            [
                                3.862279008819371,
                                43.65018689095669
                            ],
                            [
                                3.860233139214415,
                                43.64997331137525
                            ],
                            [
                                3.8583195895342897,
                                43.64964189327998
                            ],
                            [
                                3.856314913250486,
                                43.64894734118175
                            ],
                            [
                                3.8554599229676967,
                                43.64812982594026
                            ],
                            [
                                3.8548492156224086,
                                43.64668625834403
                            ],
                            [
                                3.853850273920102,
                                43.645408767807055
                            ],
                            [
                                3.8530868897392736,
                                43.644812168583854
                            ],
                            [
                                3.8517295070356,
                                43.64426876495119
                            ],
                            [
                                3.849714172797377,
                                43.643303873333736
                            ],
                            [
                                3.848297586151631,
                                43.64239965842748
                            ],
                            [
                                3.8480024109343276,
                                43.64122112608817
                            ],
                            [
                                3.8479718823698477,
                                43.63979867503588
                            ],
                            [
                                3.8478904547232275,
                                43.63841383720927
                            ],
                            [
                                3.8482263437634288,
                                43.63786136640411
                            ],
                            [
                                3.8486538448179317,
                                43.63663854589066
                            ],
                            [
                                3.849193302972708,
                                43.63552619995741
                            ],
                            [
                                3.8500788286222303,
                                43.63428123207652
                            ],
                            [
                                3.8493765151758055,
                                43.63406759594233
                            ],
                            [
                                3.8501704334426847,
                                43.632962570213664
                            ],
                            [
                                3.849372098950937,
                                43.63396351307537
                            ],
                            [
                                3.8486596070483188,
                                43.63385301113766
                            ],
                            [
                                3.8480896135267812,
                                43.63445708591911
                            ],
                            [
                                3.8471023033191045,
                                43.63584201493694
                            ],
                            [
                                3.8475094415490787,
                                43.63623244128647
                            ],
                            [
                                3.847366943168822,
                                43.63663759802307
                            ],
                            [
                                3.8469801618503823,
                                43.63796354642679
                            ],
                            [
                                3.846369454505094,
                                43.63788251708587
                            ],
                            [
                                3.8459623162751484,
                                43.63742580421251
                            ],
                            [
                                3.845097147537075,
                                43.636446069724514
                            ],
                            [
                                3.844419572783039,
                                43.63530900491094
                            ],
                            [
                                3.84383940701926,
                                43.63437343802997
                            ],
                            [
                                3.843554410258008,
                                43.63336418633244
                            ],
                            [
                                3.8432490610585717,
                                43.63220757753305
                            ],
                            [
                                3.843269417970049,
                                43.63139720159086
                            ],
                            [
                                3.8432287041471227,
                                43.630631017922894
                            ],
                            [
                                3.842271761934853,
                                43.630188220044516
                            ],
                            [
                                3.841923170546636,
                                43.629328479418774
                            ],
                            [
                                3.8414067388600586,
                                43.628440690866256
                            ],
                            [
                                3.840490082867234,
                                43.62749681215712
                            ],
                            [
                                3.838966609391889,
                                43.62645946842068
                            ],
                            [
                                3.837371918172664,
                                43.62542733112812
                            ],
                            [
                                3.8374622937170955,
                                43.62468901786127
                            ],
                            [
                                3.836377787175479,
                                43.62438995167875
                            ],
                            [
                                3.8356031395452987,
                                43.624614251381416
                            ],
                            [
                                3.8345573653795384,
                                43.62393200363033
                            ],
                            [
                                3.834725211417094,
                                43.6229829429015
                            ],
                            [
                                3.8341313149777534,
                                43.622515638076266
                            ],
                            [
                                3.832421494828793,
                                43.62269642964512
                            ],
                            [
                                3.8304658904446853,
                                43.62310622437002
                            ],
                            [
                                3.828800219911102,
                                43.62365187665398
                            ],
                            [
                                3.827384979896209,
                                43.62431312443209
                            ],
                            [
                                3.8266902257075515,
                                43.624285184532
                            ],
                            [
                                3.8256609602423453,
                                43.62405235152903
                            ],
                            [
                                3.8243486467737284,
                                43.62321414525144
                            ],
                            [
                                3.823460905310185,
                                43.622422495257666
                            ],
                            [
                                3.8231272483386647,
                                43.62168631222775
                            ],
                            [
                                3.822844200336249,
                                43.620307868129316
                            ],
                            [
                                3.8222523726928728,
                                43.619162245271326
                            ],
                            [
                                3.8211579383620347,
                                43.617797646921986
                            ],
                            [
                                3.8206175739928767,
                                43.61688483789729
                            ],
                            [
                                3.822019948188995,
                                43.615562171664465
                            ],
                            [
                                3.82198135073358,
                                43.61476110613836
                            ],
                            [
                                3.8226006890338056,
                                43.61390285508176
                            ],
                            [
                                3.8233083090412947,
                                43.61307382234148
                            ],
                            [
                                3.8245820250544966,
                                43.61315765763257
                            ],
                            [
                                3.825135255242486,
                                43.61262669881651
                            ],
                            [
                                3.8261259232522775,
                                43.61204915934351
                            ],
                            [
                                3.8262159839798358,
                                43.61149024489467
                            ],
                            [
                                3.824273245414531,
                                43.611667235032
                            ],
                            [
                                3.8232505469731564,
                                43.611262391774034
                            ],
                            [
                                3.822707179052088,
                                43.609621133703826
                            ],
                            [
                                3.821970158032997,
                                43.60850195212802
                            ],
                            [
                                3.821837084793202,
                                43.607479105720216
                            ],
                            [
                                3.8210914683027966,
                                43.60642585208532
                            ],
                            [
                                3.820344210880279,
                                43.605358496860816
                            ],
                            [
                                3.8202749727548166,
                                43.60271556106761
                            ],
                            [
                                3.820549747635539,
                                43.59926849351817
                            ],
                            [
                                3.8205906932476807,
                                43.596940764867924
                            ],
                            [
                                3.821074591954499,
                                43.59491344566882
                            ],
                            [
                                3.821095064760584,
                                43.59398674581885
                            ],
                            [
                                3.8219037406012433,
                                43.593156410631394
                            ],
                            [
                                3.8218525585857037,
                                43.59244468563824
                            ],
                            [
                                3.8215761724930815,
                                43.59146604729156
                            ],
                            [
                                3.8216785365234216,
                                43.59007961960427
                            ],
                            [
                                3.8236746351181523,
                                43.58911577405118
                            ],
                            [
                                3.8278409239190694,
                                43.58736596695482
                            ],
                            [
                                3.8302157694271557,
                                43.58712128889863
                            ],
                            [
                                3.833338247165443,
                                43.58793151523648
                            ],
                            [
                                3.8349351260416142,
                                43.58765718174476
                            ],
                            [
                                3.8369926430545718,
                                43.58665622461649
                            ],
                            [
                                3.840076852503131,
                                43.586059549131505
                            ],
                            [
                                3.843700539183544,
                                43.58615593927553
                            ],
                            [
                                3.846510894401007,
                                43.58548830705854
                            ],
                            [
                                3.848496756592283,
                                43.584546632443534
                            ],
                            [
                                3.849438511785081,
                                43.58370133344059
                            ],
                            [
                                3.8486707815556827,
                                43.58288568640069
                            ],
                            [
                                3.8484353442855195,
                                43.58163253441785
                            ],
                            [
                                3.8486419030815284,
                                43.57915995757227
                            ],
                            [
                                3.8488978158183613,
                                43.57643070430842
                            ],
                            [
                                3.849204907909325,
                                43.57556304731426
                            ],
                            [
                                3.849082071072928,
                                43.57489561034936
                            ],
                            [
                                3.849369272404772,
                                43.57394593461402
                            ],
                            [
                                3.8500448750064606,
                                43.57215862213087
                            ],
                            [
                                3.8509968634513996,
                                43.57149856337091
                            ],
                            [
                                3.850259842431541,
                                43.57090524639224
                            ],
                            [
                                3.8503110244576817,
                                43.56968151164375
                            ],
                            [
                                3.8511094638957672,
                                43.56973342816775
                            ],
                            [
                                3.8572890019533475,
                                43.570848289691554
                            ],
                            [
                                3.865073518166497,
                                43.57190878651366
                            ],
                            [
                                3.869915507886617,
                                43.57255609310832
                            ],
                            [
                                3.871850188063206,
                                43.57309006401482
                            ],
                            [
                                3.8735710808027477,
                                43.57384068778504
                            ],
                            [
                                3.8737143904460254,
                                43.5745674648534
                            ],
                            [
                                3.8731513882780177,
                                43.57612481474928
                            ],
                            [
                                3.8723717357385112,
                                43.57731652701685
                            ],
                            [
                                3.8710323750488556,
                                43.57849470129719
                            ],
                            [
                                3.869096759270235,
                                43.57941296186621
                            ],
                            [
                                3.866334604271117,
                                43.580649992728695
                            ],
                            [
                                3.863577510253066,
                                43.5818654610201
                            ],
                            [
                                3.8627649799001915,
                                43.58245200026829
                            ],
                            [
                                3.861788733965682,
                                43.58307580675614
                            ],
                            [
                                3.8614492281791684,
                                43.583409470124906
                            ],
                            [
                                3.8607944290767193,
                                43.583127753428016
                            ],
                            [
                                3.8605682257503133,
                                43.583265737280925
                            ],
                            [
                                3.8598538994559988,
                                43.584062012921606
                            ],
                            [
                                3.8595800743774475,
                                43.584703050279046
                            ],
                            [
                                3.859838025539034,
                                43.58493876633557
                            ],
                            [
                                3.860417423532681,
                                43.585085369758474
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Toulouse",
                "zone": "ZFE-m",
                "area_code": 0,
                "time": "24/7: Plan 2024 - Crit 4/5 BAN (M1)",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://metropole.toulouse.fr/faq-qualite-de-lair-et-zfe",
                "urlPark": "https://www.toulouse-visit.com/public-transport-toulouse",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/bon-a-savoir-2",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                1.4151132894076,
                                43.62431430886957
                            ],
                            [
                                1.413694920078342,
                                43.62465144248441
                            ],
                            [
                                1.4117049690793237,
                                43.624827670666974
                            ],
                            [
                                1.4117261387707174,
                                43.624291322417605
                            ],
                            [
                                1.4115250267016393,
                                43.62398483555526
                            ],
                            [
                                1.4109216904941775,
                                43.624000159935434
                            ],
                            [
                                1.4107735026540809,
                                43.62424534948798
                            ],
                            [
                                1.4100854876809024,
                                43.62442157886099
                            ],
                            [
                                1.4100113937604704,
                                43.62481234649772
                            ],
                            [
                                1.4086141941226629,
                                43.62448287591303
                            ],
                            [
                                1.4075980489316464,
                                43.62398483555526
                            ],
                            [
                                1.406327867442542,
                                43.62328757212228
                            ],
                            [
                                1.4067512612722055,
                                43.623103677122316
                            ],
                            [
                                1.4067830158089691,
                                43.62261328770447
                            ],
                            [
                                1.406634827968901,
                                43.622306792287134
                            ],
                            [
                                1.406084415990506,
                                43.622153543992795
                            ],
                            [
                                1.4058621342299489,
                                43.621992632863055
                            ],
                            [
                                1.405396401017299,
                                43.621954320625576
                            ],
                            [
                                1.404793064809894,
                                43.6225749758631
                            ],
                            [
                                1.403692240852365,
                                43.62219185610331
                            ],
                            [
                                1.4010761266644352,
                                43.62149060218155
                            ],
                            [
                                1.3998271148667243,
                                43.621184101040654
                            ],
                            [
                                1.3981229547025578,
                                43.621069162709915
                            ],
                            [
                                1.3971491488935897,
                                43.62115345084007
                            ],
                            [
                                1.3965246429956437,
                                43.62109215039328
                            ],
                            [
                                1.3956990250275965,
                                43.62088526092356
                            ],
                            [
                                1.3947781434477236,
                                43.620471479848305
                            ],
                            [
                                1.3944606141085956,
                                43.61991975661374
                            ],
                            [
                                1.3943335959591252,
                                43.61916880642357
                            ],
                            [
                                1.3945135383367813,
                                43.61817263353478
                            ],
                            [
                                1.394280671730968,
                                43.617306715999774
                            ],
                            [
                                1.393740844597886,
                                43.616563396311705
                            ],
                            [
                                1.3929999052381277,
                                43.61615724853644
                            ],
                            [
                                1.3919660880083882,
                                43.615885169585255
                            ],
                            [
                                1.3904842096048355,
                                43.6157165784345
                            ],
                            [
                                1.3893092917276135,
                                43.61538705800322
                            ],
                            [
                                1.3881767132332925,
                                43.614743338462716
                            ],
                            [
                                1.3875733770258591,
                                43.613609149172646
                            ],
                            [
                                1.387438461564301,
                                43.61286581966934
                            ],
                            [
                                1.3872479343413886,
                                43.612467310726004
                            ],
                            [
                                1.3870256525808031,
                                43.612428992418984
                            ],
                            [
                                1.3868245405116397,
                                43.611608974801726
                            ],
                            [
                                1.3867081072083067,
                                43.61066632391422
                            ],
                            [
                                1.3866869375168278,
                                43.609861610247265
                            ],
                            [
                                1.3868668798945123,
                                43.60967001016826
                            ],
                            [
                                1.3862847133785863,
                                43.609271480048875
                            ],
                            [
                                1.385342662108087,
                                43.609746650272996
                            ],
                            [
                                1.3848451743579062,
                                43.60957037788583
                            ],
                            [
                                1.3838713685497908,
                                43.60947074543833
                            ],
                            [
                                1.3832151081142285,
                                43.60979263428899
                            ],
                            [
                                1.3829610718160836,
                                43.61035210366808
                            ],
                            [
                                1.3828022991297928,
                                43.61079660987622
                            ],
                            [
                                1.3825482628324721,
                                43.610995870212946
                            ],
                            [
                                1.381712060018316,
                                43.61118746606823
                            ],
                            [
                                1.3807805935931583,
                                43.6112334489832
                            ],
                            [
                                1.379446899403007,
                                43.611241112588914
                            ],
                            [
                                1.3771817424143933,
                                43.610742962539774
                            ],
                            [
                                1.374884830888135,
                                43.60989226599361
                            ],
                            [
                                1.3726833603838315,
                                43.60911827850978
                            ],
                            [
                                1.3727468694581262,
                                43.60888835549997
                            ],
                            [
                                1.372810378532506,
                                43.608566461809886
                            ],
                            [
                                1.372461078623246,
                                43.60837485760513
                            ],
                            [
                                1.3720800241763982,
                                43.60837485760513
                            ],
                            [
                                1.371910666644851,
                                43.60840551431886
                            ],
                            [
                                1.3712332365169573,
                                43.6080759538265
                            ],
                            [
                                1.3704922973147404,
                                43.607869019571496
                            ],
                            [
                                1.3698042823415904,
                                43.60768507741409
                            ],
                            [
                                1.3692538703632238,
                                43.607248212534955
                            ],
                            [
                                1.3689680795283152,
                                43.60671170746522
                            ],
                            [
                                1.3686505394478843,
                                43.60610620918456
                            ],
                            [
                                1.3677084881765325,
                                43.604412337584876
                            ],
                            [
                                1.3667346816541226,
                                43.60250380205429
                            ],
                            [
                                1.3657608758460356,
                                43.600717847217055
                            ],
                            [
                                1.3648429503203658,
                                43.59889232381511
                            ],
                            [
                                1.3643878019538818,
                                43.59751253805405
                            ],
                            [
                                1.3644089716453323,
                                43.59706026805824
                            ],
                            [
                                1.3641866898847468,
                                43.59679197069116
                            ],
                            [
                                1.3639644081241329,
                                43.596539003507104
                            ],
                            [
                                1.3634034112996574,
                                43.59563444548141
                            ],
                            [
                                1.3625460375415912,
                                43.594070597483864
                            ],
                            [
                                1.3616886650368656,
                                43.592476047774824
                            ],
                            [
                                1.3613605348182318,
                                43.591602093952645
                            ],
                            [
                                1.3612546868335187,
                                43.59038817772557
                            ],
                            [
                                1.3612546868335187,
                                43.58922286271738
                            ],
                            [
                                1.3614028746735585,
                                43.58904653019172
                            ],
                            [
                                1.3614557989025968,
                                43.58880886379637
                            ],
                            [
                                1.3613922898283022,
                                43.58844852908396
                            ],
                            [
                                1.362334341098773,
                                43.586401480316056
                            ],
                            [
                                1.3639749953797207,
                                43.58330393745524
                            ],
                            [
                                1.3655750612741144,
                                43.58080474033761
                            ],
                            [
                                1.366697054923094,
                                43.57996895605402
                            ],
                            [
                                1.3672262972100668,
                                43.57994595265254
                            ],
                            [
                                1.367554427427848,
                                43.57983860333084
                            ],
                            [
                                1.367713200114082,
                                43.579570229188874
                            ],
                            [
                                1.3675226728911412,
                                43.579255846529236
                            ],
                            [
                                1.3674168244330644,
                                43.579064148980166
                            ],
                            [
                                1.3680625000233704,
                                43.57795229115948
                            ],
                            [
                                1.3695050083418039,
                                43.57606947351559
                            ],
                            [
                                1.3715055441870163,
                                43.573446859332876
                            ],
                            [
                                1.3730627381490308,
                                43.57150140558332
                            ],
                            [
                                1.3741635621065882,
                                43.57044309122699
                            ],
                            [
                                1.375031519457508,
                                43.57009031564229
                            ],
                            [
                                1.3755501768982867,
                                43.569967610606966
                            ],
                            [
                                1.375888891962262,
                                43.56970686157621
                            ],
                            [
                                1.3759947404194577,
                                43.56913167589977
                            ],
                            [
                                1.3762805312543662,
                                43.568594830978924
                            ],
                            [
                                1.376820358387448,
                                43.568157682294725
                            ],
                            [
                                1.3778259209551322,
                                43.56749044689232
                            ],
                            [
                                1.3794771568910846,
                                43.56671583372906
                            ],
                            [
                                1.3821445380172577,
                                43.56573413144827
                            ],
                            [
                                1.3842479673760408,
                                43.56505835145154
                            ],
                            [
                                1.3860685608435688,
                                43.56430671639731
                            ],
                            [
                                1.3879103240025472,
                                43.56338633417022
                            ],
                            [
                                1.3896991635673999,
                                43.56266535539086
                            ],
                            [
                                1.3905565360720686,
                                43.56247360502894
                            ],
                            [
                                1.390958760210367,
                                43.562619335359756
                            ],
                            [
                                1.39148800249734,
                                43.56335565164048
                            ],
                            [
                                1.392197187161912,
                                43.564030600325566
                            ],
                            [
                                1.3928534484147974,
                                43.56495864430636
                            ],
                            [
                                1.393287427089831,
                                43.565587557267975
                            ],
                            [
                                1.3932768422445463,
                                43.56602472460463
                            ],
                            [
                                1.3929381271805426,
                                43.566883710831064
                            ],
                            [
                                1.3922818667440993,
                                43.567903741065976
                            ],
                            [
                                1.3918748515988852,
                                43.568750106700776
                            ],
                            [
                                1.3912609305453714,
                                43.569049205833295
                            ],
                            [
                                1.3907634427960431,
                                43.56934063432834
                            ],
                            [
                                1.3904882368064193,
                                43.56967040697705
                            ],
                            [
                                1.3901601065885814,
                                43.569816119898036
                            ],
                            [
                                1.3901389368971309,
                                43.57035295393348
                            ],
                            [
                                1.3906258398011744,
                                43.57053701021516
                            ],
                            [
                                1.3908481215617599,
                                43.57085143839606
                            ],
                            [
                                1.3911974214710483,
                                43.57118120277278
                            ],
                            [
                                1.3915361365349384,
                                43.571342249835624
                            ],
                            [
                                1.3920759636680486,
                                43.57102015527934
                            ],
                            [
                                1.3921500575876848,
                                43.570805424618584
                            ],
                            [
                                1.3920124545937256,
                                43.570521672213175
                            ],
                            [
                                1.392044209130404,
                                43.56943266408629
                            ],
                            [
                                1.392044209130404,
                                43.56924860442922
                            ],
                            [
                                1.3924464332687592,
                                43.568934167881224
                            ],
                            [
                                1.3930497694761073,
                                43.56778377627694
                            ],
                            [
                                1.3939600648565147,
                                43.56650297925677
                            ],
                            [
                                1.3946163252920485,
                                43.56611950235194
                            ],
                            [
                                1.3946480798296648,
                                43.56553661278235
                            ],
                            [
                                1.3940447436222598,
                                43.565245165882175
                            ],
                            [
                                1.3933884831866692,
                                43.564623919099006
                            ],
                            [
                                1.3926898821743805,
                                43.56380324983809
                            ],
                            [
                                1.391790170286754,
                                43.56263742023236
                            ],
                            [
                                1.3913667764571187,
                                43.56198546620965
                            ],
                            [
                                1.3924570155677145,
                                43.56135651564253
                            ],
                            [
                                1.3937567561836772,
                                43.560771149311364
                            ],
                            [
                                1.3950163528275255,
                                43.560134515743755
                            ],
                            [
                                1.396011328327063,
                                43.55982003161927
                            ],
                            [
                                1.397863676332122,
                                43.55932912629103
                            ],
                            [
                                1.400869772522185,
                                43.55869247748572
                            ],
                            [
                                1.4020764430111115,
                                43.558339632927186
                            ],
                            [
                                1.4026268549895065,
                                43.558186221672685
                            ],
                            [
                                1.4044368636117213,
                                43.55772598556453
                            ],
                            [
                                1.4057387996376463,
                                43.55728875800651
                            ],
                            [
                                1.4061621934673667,
                                43.55704329553038
                            ],
                            [
                                1.4068078690576726,
                                43.55698960048028
                            ],
                            [
                                1.4071465841215343,
                                43.55672112451319
                            ],
                            [
                                1.4072841871164314,
                                43.55645264734926
                            ],
                            [
                                1.4083426716902068,
                                43.55586199337921
                            ],
                            [
                                1.4087978200575435,
                                43.5565753799157
                            ],
                            [
                                1.4092106290410982,
                                43.55731177006203
                            ],
                            [
                                1.4091047805838741,
                                43.557534219482136
                            ],
                            [
                                1.4093799865726453,
                                43.557718314933254
                            ],
                            [
                                1.4097716258656021,
                                43.557718314933254
                            ],
                            [
                                1.409856304631404,
                                43.55751120751157
                            ],
                            [
                                1.4092953078068717,
                                43.55669811223149
                            ],
                            [
                                1.4086496322166226,
                                43.55569323403893
                            ],
                            [
                                1.40899893212665,
                                43.55552447422542
                            ],
                            [
                                1.4099727379347655,
                                43.55589267684488
                            ],
                            [
                                1.4110841467376645,
                                43.55645264734926
                            ],
                            [
                                1.412502530345705,
                                43.55787940858352
                            ],
                            [
                                1.4148206115634139,
                                43.56018821842815
                            ],
                            [
                                1.4171281084844338,
                                43.56238955941188
                            ],
                            [
                                1.4178478779943475,
                                43.56330228694699
                            ],
                            [
                                1.418112499137834,
                                43.5636704420387
                            ],
                            [
                                1.418133668829256,
                                43.56418432142567
                            ],
                            [
                                1.4178881732839272,
                                43.56567150877734
                            ],
                            [
                                1.417030800778349,
                                43.566714568003704
                            ],
                            [
                                1.4162898615769564,
                                43.567427826047265
                            ],
                            [
                                1.416194597965017,
                                43.56804137459605
                            ],
                            [
                                1.416173428273595,
                                43.56825611511127
                            ],
                            [
                                1.4165333130289923,
                                43.56887732443519
                            ],
                            [
                                1.417200162637954,
                                43.56919943437805
                            ],
                            [
                                1.4180787048349544,
                                43.56932214097836
                            ],
                            [
                                1.4191160197173645,
                                43.569306802667
                            ],
                            [
                                1.4205343890466509,
                                43.56918409603543
                            ],
                            [
                                1.4218998341476663,
                                43.56929913351016
                            ],
                            [
                                1.4233182034768959,
                                43.56959822991567
                            ],
                            [
                                1.4240803123705632,
                                43.569820633972455
                            ],
                            [
                                1.423932124529614,
                                43.5701427349436
                            ],
                            [
                                1.4241226517534926,
                                43.57028844672189
                            ],
                            [
                                1.4247471628581536,
                                43.57033445791026
                            ],
                            [
                                1.4250223688469248,
                                43.57016573910616
                            ],
                            [
                                1.4262184564155973,
                                43.570326788884216
                            ],
                            [
                                1.4283777649470437,
                                43.57058753523117
                            ],
                            [
                                1.430060755419703,
                                43.5707255769575
                            ],
                            [
                                1.4324000079154473,
                                43.57097865379134
                            ],
                            [
                                1.4341359226171733,
                                43.57128541076207
                            ],
                            [
                                1.4352155768833086,
                                43.571737874441965
                            ],
                            [
                                1.4363904947605022,
                                43.57249708552186
                            ],
                            [
                                1.4370467551960928,
                                43.57298021486221
                            ],
                            [
                                1.4361047039255936,
                                43.574130507215926
                            ],
                            [
                                1.4357765703472296,
                                43.575012384059306
                            ],
                            [
                                1.43579774003868,
                                43.575625855346686
                            ],
                            [
                                1.4364116610914266,
                                43.57558751357408
                            ],
                            [
                                1.4373537123626932,
                                43.57529611530629
                            ],
                            [
                                1.4378088607291488,
                                43.57496637346094
                            ],
                            [
                                1.437893539495775,
                                43.57446792305734
                            ],
                            [
                                1.4386768180800402,
                                43.57454460800321
                            ],
                            [
                                1.4397988117290765,
                                43.57519642610137
                            ],
                            [
                                1.4410018054068985,
                                43.57592693761606
                            ],
                            [
                                1.442441344427607,
                                43.57632568860754
                            ],
                            [
                                1.4433410563160862,
                                43.576241337656285
                            ],
                            [
                                1.4452947017436486,
                                43.57584323063449
                            ],
                            [
                                1.4470941255196976,
                                43.57501504590948
                            ],
                            [
                                1.450142561093486,
                                43.573918450461804
                            ],
                            [
                                1.4515609323755427,
                                43.573956808975424
                            ],
                            [
                                1.4523653806512016,
                                43.57389546042771
                            ],
                            [
                                1.4526405866408538,
                                43.573327983397604
                            ],
                            [
                                1.453254507693572,
                                43.57305958023713
                            ],
                            [
                                1.455745750129438,
                                43.572611849886925
                            ],
                            [
                                1.4586036584802002,
                                43.57244313746256
                            ],
                            [
                                1.4607312124740588,
                                43.572358781072836
                            ],
                            [
                                1.461218094526572,
                                43.572519830282204
                            ],
                            [
                                1.4626682183934463,
                                43.57264253011843
                            ],
                            [
                                1.463758457504838,
                                43.573616451197864
                            ],
                            [
                                1.4644993967062305,
                                43.573601113980146
                            ],
                            [
                                1.4651556571426738,
                                43.57407656591516
                            ],
                            [
                                1.4655049570519338,
                                43.57405356026254
                            ],
                            [
                                1.4656213903552668,
                                43.573815834673724
                            ],
                            [
                                1.4665210985250496,
                                43.57424528400776
                            ],
                            [
                                1.4674949043331367,
                                43.57449834489444
                            ],
                            [
                                1.4685533889070541,
                                43.57454435585103
                            ],
                            [
                                1.4692943281091857,
                                43.57454435585103
                            ],
                            [
                                1.4688074252051706,
                                43.574881768454816
                            ],
                            [
                                1.4676960164023,
                                43.57537254704883
                            ],
                            [
                                1.4670820953494967,
                                43.57557959306854
                            ],
                            [
                                1.4664470046045892,
                                43.576047361085074
                            ],
                            [
                                1.4661294592320928,
                                43.57663014893993
                            ],
                            [
                                1.466002441083475,
                                43.57732028515741
                            ],
                            [
                                1.4660236107748688,
                                43.57768068648298
                            ],
                            [
                                1.4665105136788554,
                                43.57776503541734
                            ],
                            [
                                1.4668492287428307,
                                43.577504320146545
                            ],
                            [
                                1.4665105136788554,
                                43.57716692223741
                            ],
                            [
                                1.4667327954394977,
                                43.57648445250496
                            ],
                            [
                                1.4676430921732617,
                                43.575863321643624
                            ],
                            [
                                1.4686168979813203,
                                43.575449230842736
                            ],
                            [
                                1.4698870794704249,
                                43.57503513719493
                            ],
                            [
                                1.4713583730286928,
                                43.57419927282322
                            ],
                            [
                                1.473189557741648,
                                43.573071976190505
                            ],
                            [
                                1.4741739483950198,
                                43.57250449140125
                            ],
                            [
                                1.4748407936767478,
                                43.572213078214986
                            ],
                            [
                                1.4753488662722702,
                                43.57228976603213
                            ],
                            [
                                1.4757087510276108,
                                43.57245847888612
                            ],
                            [
                                1.476121560012018,
                                43.572389460048726
                            ],
                            [
                                1.4763120872350441,
                                43.572059702287106
                            ],
                            [
                                1.4761321448573312,
                                43.57179896231372
                            ],
                            [
                                1.4762909175435368,
                                43.57143085690032
                            ],
                            [
                                1.4768836689048612,
                                43.57093237723336
                            ],
                            [
                                1.4782596974319233,
                                43.57008878212673
                            ],
                            [
                                1.4806836271068562,
                                43.56855495117523
                            ],
                            [
                                1.4833298399977082,
                                43.566744978873544
                            ],
                            [
                                1.4850022456251395,
                                43.56533377749699
                            ],
                            [
                                1.4867487455400692,
                                43.563930212646994
                            ],
                            [
                                1.4874155908217972,
                                43.564306033695516
                            ],
                            [
                                1.4879236634172628,
                                43.56449010845526
                            ],
                            [
                                1.488516414778502,
                                43.56435972222491
                            ],
                            [
                                1.4896066538899788,
                                43.564152637632134
                            ],
                            [
                                1.4906122152935382,
                                43.56418331513035
                            ],
                            [
                                1.491077948506188,
                                43.56435972047933
                            ],
                            [
                                1.4916389453306635,
                                43.564244673571125
                            ],
                            [
                                1.492390469378222,
                                43.56435972047933
                            ],
                            [
                                1.4931314085803535,
                                43.56435972047933
                            ],
                            [
                                1.4933536903409959,
                                43.56440573918101
                            ],
                            [
                                1.4932584267290565,
                                43.56613141511974
                            ],
                            [
                                1.4934595348674407,
                                43.569113256474026
                            ],
                            [
                                1.4936712317818603,
                                43.5707314319946
                            ],
                            [
                                1.4938424339202925,
                                43.572150918955685
                            ],
                            [
                                1.4940117914526923,
                                43.57279509425089
                            ],
                            [
                                1.4947527306540564,
                                43.57359263506973
                            ],
                            [
                                1.4961287598804915,
                                43.575249036785095
                            ],
                            [
                                1.4970073020774635,
                                43.57644529530552
                            ],
                            [
                                1.4976635625130825,
                                43.577978925311186
                            ],
                            [
                                1.497869298606787,
                                43.58015993423339
                            ],
                            [
                                1.4970225109473745,
                                43.582674911779776
                            ],
                            [
                                1.4953161840754774,
                                43.5867584548501
                            ],
                            [
                                1.4934003375658165,
                                43.59163077773175
                            ],
                            [
                                1.4927123225926096,
                                43.59321768372595
                            ],
                            [
                                1.492574716866045,
                                43.59395689561342
                            ],
                            [
                                1.4927123198608285,
                                43.594800151608126
                            ],
                            [
                                1.4923524351062838,
                                43.59723019597257
                            ],
                            [
                                1.4909091564873904,
                                43.59916911221006
                            ],
                            [
                                1.4884958116577138,
                                43.6008861268495
                            ],
                            [
                                1.4867704818021537,
                                43.60208954093807
                            ],
                            [
                                1.4856662206557871,
                                43.60323017987312
                            ],
                            [
                                1.484523057316153,
                                43.60489342627136
                            ],
                            [
                                1.483983230183071,
                                43.606342022763585
                            ],
                            [
                                1.4837185781866822,
                                43.60703948481736
                            ],
                            [
                                1.4840255387130696,
                                43.60835007448338
                            ],
                            [
                                1.4842942027613617,
                                43.61031222962973
                            ],
                            [
                                1.4835956029428985,
                                43.61296389217702
                            ],
                            [
                                1.482456350044231,
                                43.616410374234306
                            ],
                            [
                                1.4807214980631613,
                                43.62192320137447
                            ],
                            [
                                1.479600948379158,
                                43.62474714874861
                            ],
                            [
                                1.4792516484699547,
                                43.62532946506681
                            ],
                            [
                                1.478510709268562,
                                43.62670861279278
                            ],
                            [
                                1.4778332791406399,
                                43.626563037582685
                            ],
                            [
                                1.4780449760559407,
                                43.62576619860363
                            ],
                            [
                                1.4784472001934432,
                                43.62555932521869
                            ],
                            [
                                1.4785318789599557,
                                43.625153238355296
                            ],
                            [
                                1.4789341030974867,
                                43.624670527760685
                            ],
                            [
                                1.4778650336782846,
                                43.624180151124534
                            ],
                            [
                                1.4774945640767783,
                                43.62444066421082
                            ],
                            [
                                1.477050000555579,
                                43.62469351406759
                            ],
                            [
                                1.4766477764173374,
                                43.625168562437636
                            ],
                            [
                                1.4766160218805737,
                                43.62542140923338
                            ],
                            [
                                1.4769123975616196,
                                43.625965409338846
                            ],
                            [
                                1.477050000555579,
                                43.6261646194132
                            ],
                            [
                                1.4773993004656631,
                                43.62631019558887
                            ],
                            [
                                1.4773040368537238,
                                43.626509404521215
                            ],
                            [
                                1.4769123975616196,
                                43.626900158586125
                            ],
                            [
                                1.4772193580879787,
                                43.62722195414386
                            ],
                            [
                                1.4781379660933567,
                                43.62763675541913
                            ],
                            [
                                1.4780427024814742,
                                43.6285561554613
                            ],
                            [
                                1.4779156843327996,
                                43.63041789747356
                            ],
                            [
                                1.477668040309311,
                                43.63341221002747
                            ],
                            [
                                1.477414004011166,
                                43.63476818985362
                            ],
                            [
                                1.4770752889471908,
                                43.63534274834822
                            ],
                            [
                                1.4761400753499174,
                                43.63586143603777
                            ],
                            [
                                1.4754943997669159,
                                43.63632107491091
                            ],
                            [
                                1.4755684937131264,
                                43.637125434506345
                            ],
                            [
                                1.4759389633138085,
                                43.63764634733889
                            ],
                            [
                                1.476362357143529,
                                43.63795276454249
                            ],
                            [
                                1.4761189056915214,
                                43.63845834951192
                            ],
                            [
                                1.4748139263320752,
                                43.640302438570046
                            ],
                            [
                                1.473056841938842,
                                43.64199529925003
                            ],
                            [
                                1.4718289998326384,
                                43.64286852095225
                            ],
                            [
                                1.4708551940245513,
                                43.643113633534114
                            ],
                            [
                                1.469606182226812,
                                43.64310597378139
                            ],
                            [
                                1.46852508644443,
                                43.64348543238685
                            ],
                            [
                                1.467921750237025,
                                43.643791819812236
                            ],
                            [
                                1.4684827470615573,
                                43.64469565361304
                            ],
                            [
                                1.46628109914721,
                                43.64547692271998
                            ],
                            [
                                1.4646298632120534,
                                43.64590585045863
                            ],
                            [
                                1.4619464613702462,
                                43.64630800160447
                            ],
                            [
                                1.4592896650894716,
                                43.64646884763988
                            ],
                            [
                                1.4577019382277854,
                                43.64649948493104
                            ],
                            [
                                1.4568022263401872,
                                43.64646118831433
                            ],
                            [
                                1.4558707599150011,
                                43.64613949577242
                            ],
                            [
                                1.4554156115477213,
                                43.64569525085727
                            ],
                            [
                                1.4554367812391433,
                                43.64485270837454
                            ],
                            [
                                1.4557437438076875,
                                43.64417866556332
                            ],
                            [
                                1.456315325477476,
                                43.643374400418026
                            ],
                            [
                                1.4560507043339896,
                                43.642891836162505
                            ],
                            [
                                1.4544418077809667,
                                43.64186541676045
                            ],
                            [
                                1.4541771866375086,
                                43.64152837969942
                            ],
                            [
                                1.453849056419699,
                                43.64149007991358
                            ],
                            [
                                1.453457417127595,
                                43.641666258725394
                            ],
                            [
                                1.4534044928985281,
                                43.641842437020614
                            ],
                            [
                                1.4527482324629375,
                                43.641827117189365
                            ],
                            [
                                1.4520178781060338,
                                43.64135220048328
                            ],
                            [
                                1.452060217489759,
                                43.64113772073995
                            ],
                            [
                                1.4516579933514322,
                                43.64090026013139
                            ],
                            [
                                1.4508429567790984,
                                43.64119899874541
                            ],
                            [
                                1.4508747113158336,
                                43.64135985846045
                            ],
                            [
                                1.4510758233849685,
                                43.641505397831565
                            ],
                            [
                                1.450684184092836,
                                43.6417734957505
                            ],
                            [
                                1.4502713751092244,
                                43.641467098031654
                            ],
                            [
                                1.4502184508802145,
                                43.641367518436425
                            ],
                            [
                                1.4498797358163245,
                                43.641367518436425
                            ],
                            [
                                1.4498162267419445,
                                43.64155901751121
                            ],
                            [
                                1.449424587449812,
                                43.64171221633123
                            ],
                            [
                                1.4495833601361028,
                                43.642003293013744
                            ],
                            [
                                1.4503983932426934,
                                43.64205691228568
                            ],
                            [
                                1.4503772235512997,
                                43.64232500774355
                            ],
                            [
                                1.4499749994130298,
                                43.64245522510552
                            ],
                            [
                                1.4503137144769482,
                                43.64301439056908
                            ],
                            [
                                1.4513616142055241,
                                43.64308332841671
                            ],
                            [
                                1.4517109141147557,
                                43.64267735995384
                            ],
                            [
                                1.4525788714656471,
                                43.64287651463647
                            ],
                            [
                                1.4523777593964837,
                                43.64322886361276
                            ],
                            [
                                1.4527164744604022,
                                43.64334375957054
                            ],
                            [
                                1.4530551895235249,
                                43.642907153760405
                            ],
                            [
                                1.4532668864387972,
                                43.64293779286814
                            ],
                            [
                                1.4535950166565499,
                                43.64234032744778
                            ],
                            [
                                1.4539019773683322,
                                43.64211053156296
                            ],
                            [
                                1.4554050254633353,
                                43.64309864801746
                            ],
                            [
                                1.4548640765219432,
                                43.64401282321475
                            ],
                            [
                                1.454631209916073,
                                43.64446474015244
                            ],
                            [
                                1.4546100402246225,
                                43.64565962279545
                            ],
                            [
                                1.4541760615487647,
                                43.645843448785115
                            ],
                            [
                                1.453646819261877,
                                43.64628769260369
                            ],
                            [
                                1.4528952952142902,
                                43.64640258271186
                            ],
                            [
                                1.4488413001724325,
                                43.64638726306464
                            ],
                            [
                                1.444850813328145,
                                43.646494493661066
                            ],
                            [
                                1.4428833356926418,
                                43.64670991422932
                            ],
                            [
                                1.4409992331508192,
                                43.64686309991421
                            ],
                            [
                                1.4393585820609474,
                                43.64678650712062
                            ],
                            [
                                1.4387975852364718,
                                43.6468401220867
                            ],
                            [
                                1.4379084581941584,
                                43.647261380867945
                            ],
                            [
                                1.4347920675279795,
                                43.648150722484985
                            ],
                            [
                                1.433289019432948,
                                43.648579631131184
                            ],
                            [
                                1.4326115893050542,
                                43.648625585447235
                            ],
                            [
                                1.4321776106300774,
                                43.64893194665618
                            ],
                            [
                                1.4294528665355983,
                                43.649779307204824
                            ],
                            [
                                1.4281085911268576,
                                43.650208204221
                            ],
                            [
                                1.4271242004726616,
                                43.65071368605862
                            ],
                            [
                                1.4261927340474188,
                                43.65123448107744
                            ],
                            [
                                1.425684661451868,
                                43.65124979850961
                            ],
                            [
                                1.4255999826861512,
                                43.650751979963474
                            ],
                            [
                                1.4256529069143937,
                                43.649059366042195
                            ],
                            [
                                1.4266161278771108,
                                43.648546211222566
                            ],
                            [
                                1.4269971823239302,
                                43.64761945782763
                            ],
                            [
                                1.4263515043740256,
                                43.64702969564809
                            ],
                            [
                                1.425430622794977,
                                43.64704501415264
                            ],
                            [
                                1.4249648895824123,
                                43.647420316293335
                            ],
                            [
                                1.424954304736218,
                                43.6482015499644
                            ],
                            [
                                1.4243297988373627,
                                43.648270481858816
                            ],
                            [
                                1.4226150538270588,
                                43.64780327524457
                            ],
                            [
                                1.4221387357691526,
                                43.646577798006405
                            ],
                            [
                                1.4221387331547248,
                                43.64330718671465
                            ],
                            [
                                1.4220247130883763,
                                43.6394386502204
                            ],
                            [
                                1.4214107887320324,
                                43.635537800986896
                            ],
                            [
                                1.4205957538825658,
                                43.62932464021267
                            ],
                            [
                                1.4203999328208852,
                                43.6268398507535
                            ],
                            [
                                1.4195531451614443,
                                43.62567524173872
                            ],
                            [
                                1.4200929722944977,
                                43.62473281134859
                            ],
                            [
                                1.4198389430125928,
                                43.62355283251577
                            ],
                            [
                                1.4193626249546867,
                                43.62309309598845
                            ],
                            [
                                1.4197754339382413,
                                43.621890102107244
                            ],
                            [
                                1.4188757220506432,
                                43.621422690347856
                            ],
                            [
                                1.4176796344811464,
                                43.62165256543793
                            ],
                            [
                                1.4164623772210234,
                                43.62149931547532
                            ],
                            [
                                1.4155414956420032,
                                43.623483872260636
                            ],
                            [
                                1.4155520804873163,
                                43.62395893018967
                            ],
                            [
                                1.4151132894076,
                                43.62431430886957
                            ]
                        ],
                        [
                            [
                                1.424237280643041,
                                43.65205294688485
                            ],
                            [
                                1.4232873357416906,
                                43.65110263379657
                            ],
                            [
                                1.422362172012015,
                                43.64971000946622
                            ],
                            [
                                1.4222217453737471,
                                43.64878356767008
                            ],
                            [
                                1.422370432402147,
                                43.64843689545313
                            ],
                            [
                                1.4231303883231874,
                                43.64852655225289
                            ],
                            [
                                1.42476594563189,
                                43.64917207725986
                            ],
                            [
                                1.4251624443726598,
                                43.64943506694047
                            ],
                            [
                                1.425145923592396,
                                43.65035552175459
                            ],
                            [
                                1.4247989871936113,
                                43.651240101399964
                            ],
                            [
                                1.4244355300133407,
                                43.65177801508443
                            ],
                            [
                                1.424237280643041,
                                43.65205294688485
                            ]
                        ],
                        [
                            [
                                1.4870711173942937,
                                43.563626743297704
                            ],
                            [
                                1.4878968597567166,
                                43.56315438275365
                            ],
                            [
                                1.4890760527065652,
                                43.56208081946863
                            ],
                            [
                                1.48985645498297,
                                43.56062760998222
                            ],
                            [
                                1.4902555664426984,
                                43.559860748854646
                            ],
                            [
                                1.4909933179293091,
                                43.55932174919275
                            ],
                            [
                                1.492039473726578,
                                43.55929983853491
                            ],
                            [
                                1.4928800266494875,
                                43.55954961955652
                            ],
                            [
                                1.4932730909661132,
                                43.56008861718061
                            ],
                            [
                                1.4933819395463672,
                                43.560925587672955
                            ],
                            [
                                1.4933782859211817,
                                43.562395431777134
                            ],
                            [
                                1.4933117673444087,
                                43.56401233380555
                            ],
                            [
                                1.4924833106769029,
                                43.56403425095226
                            ],
                            [
                                1.4917455591902922,
                                43.56390279717118
                            ],
                            [
                                1.491539956316842,
                                43.563705615962334
                            ],
                            [
                                1.491298070583042,
                                43.56362236148016
                            ],
                            [
                                1.490856629119861,
                                43.563745052255626
                            ],
                            [
                                1.4904756590903219,
                                43.56375381587253
                            ],
                            [
                                1.489350890430103,
                                43.56377134310321
                            ],
                            [
                                1.4885708089399543,
                                43.5640211055869
                            ],
                            [
                                1.4878209631671666,
                                43.56408683238425
                            ],
                            [
                                1.4870711173942937,
                                43.563626743297704
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Lyon",
                "zone": "ZFE-m",
                "area_code": 0,
                "time": "24/7: Plan 2024 - Crit 4/5 BAN (M1)",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://zfe.grandlyon.com/",
                "urlPark": "https://www.lyon.fr/mobilites/le-stationnement",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/bon-a-savoir-2",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                4.826216697692871,
                                45.79463885085458
                            ],
                            [
                                4.830508232116699,
                                45.79407033073256
                            ],
                            [
                                4.837331771850586,
                                45.79284350439244
                            ],
                            [
                                4.841945171356201,
                                45.791706421718274
                            ],
                            [
                                4.848103523254394,
                                45.79040473280974
                            ],
                            [
                                4.852631092071533,
                                45.78911797596399
                            ],
                            [
                                4.856901168823242,
                                45.788758875728504
                            ],
                            [
                                4.860076904296875,
                                45.78848954903366
                            ],
                            [
                                4.860527515411377,
                                45.78949203624919
                            ],
                            [
                                4.862480163574219,
                                45.79077878445946
                            ],
                            [
                                4.865677356719971,
                                45.79125756690808
                            ],
                            [
                                4.868788719177246,
                                45.79191588605932
                            ],
                            [
                                4.871513843536377,
                                45.79279861998982
                            ],
                            [
                                4.871256351470947,
                                45.793965602709086
                            ],
                            [
                                4.871513843536377,
                                45.7944443577813
                            ],
                            [
                                4.873251914978027,
                                45.79499791320652
                            ],
                            [
                                4.8743462562561035,
                                45.795491620162956
                            ],
                            [
                                4.875118732452393,
                                45.7961199681452
                            ],
                            [
                                4.875354766845703,
                                45.79667350692615
                            ],
                            [
                                4.874732494354248,
                                45.797316801844325
                            ],
                            [
                                4.873809814453125,
                                45.7981994502279
                            ],
                            [
                                4.873487949371338,
                                45.79894744638776
                            ],
                            [
                                4.874625205993652,
                                45.80104178221643
                            ],
                            [
                                4.874260425567627,
                                45.80198420765608
                            ],
                            [
                                4.874088764190674,
                                45.805200301426666
                            ],
                            [
                                4.872586727142334,
                                45.80518534328075
                            ],
                            [
                                4.872007369995117,
                                45.805888371797096
                            ],
                            [
                                4.871020317077636,
                                45.80819184987988
                            ],
                            [
                                4.868252277374268,
                                45.811347759343825
                            ],
                            [
                                4.867973327636719,
                                45.81221522919141
                            ],
                            [
                                4.866213798522949,
                                45.812648959048516
                            ],
                            [
                                4.866921901702881,
                                45.81383048187007
                            ],
                            [
                                4.86569881439209,
                                45.814338977647374
                            ],
                            [
                                4.864261150360107,
                                45.813860393514915
                            ],
                            [
                                4.863853454589844,
                                45.81414455333957
                            ],
                            [
                                4.861900806427002,
                                45.81439880037474
                            ],
                            [
                                4.861471652984619,
                                45.815026935132366
                            ],
                            [
                                4.861342906951904,
                                45.81595416873047
                            ],
                            [
                                4.860827922821045,
                                45.81632804887801
                            ],
                            [
                                4.858682155609131,
                                45.81650751045717
                            ],
                            [
                                4.857501983642578,
                                45.81685147686732
                            ],
                            [
                                4.856901168823242,
                                45.817464629111576
                            ],
                            [
                                4.855935573577881,
                                45.8165523757616
                            ],
                            [
                                4.8502278327941895,
                                45.81387534933129
                            ],
                            [
                                4.848790168762207,
                                45.81330702548479
                            ],
                            [
                                4.847545623779297,
                                45.812334879144544
                            ],
                            [
                                4.845893383026123,
                                45.81312755359149
                            ],
                            [
                                4.843318462371826,
                                45.81034566528735
                            ],
                            [
                                4.842932224273682,
                                45.808416209537384
                            ],
                            [
                                4.84097957611084,
                                45.802507770456586
                            ],
                            [
                                4.839434623718262,
                                45.80066779944923
                            ],
                            [
                                4.837803840637207,
                                45.799231682295705
                            ],
                            [
                                4.837009906768799,
                                45.798259290290204
                            ],
                            [
                                4.834563732147217,
                                45.79727192104451
                            ],
                            [
                                4.832460880279541,
                                45.79665854649086
                            ],
                            [
                                4.829092025756835,
                                45.79616484987281
                            ],
                            [
                                4.826216697692871,
                                45.79463885085458
                            ]
                        ],
                        [
                            [
                                4.82175350189209,
                                45.750890749197175
                            ],
                            [
                                4.819682836532593,
                                45.74976778390482
                            ],
                            [
                                4.816839694976807,
                                45.74807580686611
                            ],
                            [
                                4.815187454223633,
                                45.746114247166894
                            ],
                            [
                                4.813567399978638,
                                45.743441323953206
                            ],
                            [
                                4.81300950050354,
                                45.740386397855666
                            ],
                            [
                                4.813202619552612,
                                45.73794533186225
                            ],
                            [
                                4.814640283584595,
                                45.73189462235795
                            ],
                            [
                                4.8155200481414795,
                                45.73140784124592
                            ],
                            [
                                4.816195964813232,
                                45.73204440030809
                            ],
                            [
                                4.817408323287964,
                                45.733751840549644
                            ],
                            [
                                4.818770885467529,
                                45.73533941358856
                            ],
                            [
                                4.821345806121826,
                                45.73805765319192
                            ],
                            [
                                4.824382066726685,
                                45.74170422961455
                            ],
                            [
                                4.826399087905883,
                                45.74402533490942
                            ],
                            [
                                4.8274075984954825,
                                45.745275696798586
                            ],
                            [
                                4.828459024429321,
                                45.746503569841856
                            ],
                            [
                                4.8293280601501465,
                                45.74739450959265
                            ],
                            [
                                4.829456806182861,
                                45.747918585156455
                            ],
                            [
                                4.829113483428954,
                                45.74827046170263
                            ],
                            [
                                4.82175350189209,
                                45.750890749197175
                            ]
                        ],
                        [
                            [
                                4.820702075958252,
                                45.751399819353544
                            ],
                            [
                                4.818942546844482,
                                45.752882085424154
                            ],
                            [
                                4.81675386428833,
                                45.75482843562817
                            ],
                            [
                                4.813406467437744,
                                45.75716396627005
                            ],
                            [
                                4.810359477996826,
                                45.75945448791422
                            ],
                            [
                                4.807891845703125,
                                45.760592228418844
                            ],
                            [
                                4.804222583770751,
                                45.76183473430851
                            ],
                            [
                                4.802055358886719,
                                45.76339157010074
                            ],
                            [
                                4.799158573150635,
                                45.76521780286881
                            ],
                            [
                                4.79302167892456,
                                45.76641530009872
                            ],
                            [
                                4.7889018058776855,
                                45.76584649211976
                            ],
                            [
                                4.786884784698486,
                                45.76487351765755
                            ],
                            [
                                4.786863327026367,
                                45.76250837051217
                            ],
                            [
                                4.787893295288086,
                                45.76250837051217
                            ],
                            [
                                4.788708686828613,
                                45.76198443194557
                            ],
                            [
                                4.788558483123779,
                                45.76140060888965
                            ],
                            [
                                4.792013168334961,
                                45.759259872083206
                            ],
                            [
                                4.7919487953186035,
                                45.75893052066901
                            ],
                            [
                                4.790382385253906,
                                45.75903531451167
                            ],
                            [
                                4.786219596862792,
                                45.760412586723646
                            ],
                            [
                                4.785768985748291,
                                45.7586909911467
                            ],
                            [
                                4.782807826995849,
                                45.75672980450886
                            ],
                            [
                                4.782679080963135,
                                45.754064875562705
                            ],
                            [
                                4.779760837554932,
                                45.75418465038148
                            ],
                            [
                                4.777443408966064,
                                45.75381035321928
                            ],
                            [
                                4.774911403656006,
                                45.75298689062582
                            ],
                            [
                                4.773581027984619,
                                45.75280722444529
                            ],
                            [
                                4.772186279296875,
                                45.75110036688274
                            ],
                            [
                                4.773130416870117,
                                45.75101053082819
                            ],
                            [
                                4.773280620574951,
                                45.75036670153907
                            ],
                            [
                                4.774932861328125,
                                45.749603080428734
                            ],
                            [
                                4.7759199142456055,
                                45.74840522234564
                            ],
                            [
                                4.777572154998779,
                                45.74752178553909
                            ],
                            [
                                4.77879524230957,
                                45.74795601893701
                            ],
                            [
                                4.7837090492248535,
                                45.74475159642185
                            ],
                            [
                                4.78978157043457,
                                45.74755173277844
                            ],
                            [
                                4.796690940856934,
                                45.75036670153907
                            ],
                            [
                                4.798879623413085,
                                45.75072604903484
                            ],
                            [
                                4.801111221313477,
                                45.751564517527676
                            ],
                            [
                                4.802548885345459,
                                45.75208855393921
                            ],
                            [
                                4.803686141967773,
                                45.751489654781516
                            ],
                            [
                                4.807870388031006,
                                45.7502019998303
                            ],
                            [
                                4.810745716094971,
                                45.749348537736985
                            ],
                            [
                                4.810895919799805,
                                45.74874960917746
                            ],
                            [
                                4.812290668487549,
                                45.74858490269689
                            ],
                            [
                                4.813299179077148,
                                45.748180621093205
                            ],
                            [
                                4.814178943634033,
                                45.7489742081404
                            ],
                            [
                                4.814436435699463,
                                45.74975281088162
                            ],
                            [
                                4.8169684410095215,
                                45.74976778390482
                            ],
                            [
                                4.820702075958252,
                                45.751399819353544
                            ]
                        ],
                        [
                            [
                                4.821796417236327,
                                45.75242543189118
                            ],
                            [
                                4.82225775718689,
                                45.75185648127827
                            ],
                            [
                                4.822182655334473,
                                45.751429764512274
                            ],
                            [
                                4.825315475463867,
                                45.75050894353279
                            ],
                            [
                                4.8282551765441895,
                                45.749640513079605
                            ],
                            [
                                4.829124212265015,
                                45.74944586302089
                            ],
                            [
                                4.83098030090332,
                                45.74782874398066
                            ],
                            [
                                4.830111265182495,
                                45.74677309933391
                            ],
                            [
                                4.8272788524627686,
                                45.74318675316291
                            ],
                            [
                                4.824779033660889,
                                45.74004944724224
                            ],
                            [
                                4.822322130203247,
                                45.73670229406166
                            ],
                            [
                                4.820884466171265,
                                45.734665449415154
                            ],
                            [
                                4.820669889450073,
                                45.73360206717855
                            ],
                            [
                                4.823276996612549,
                                45.732905615726494
                            ],
                            [
                                4.823545217514038,
                                45.73294305957389
                            ],
                            [
                                4.824124574661255,
                                45.73293557080642
                            ],
                            [
                                4.824382066726685,
                                45.73265848570421
                            ],
                            [
                                4.824542999267578,
                                45.732463776431615
                            ],
                            [
                                4.824285507202148,
                                45.728367235518256
                            ],
                            [
                                4.825572967529297,
                                45.72688431849176
                            ],
                            [
                                4.830722808837891,
                                45.72694423509439
                            ],
                            [
                                4.833565950393677,
                                45.72679444346731
                            ],
                            [
                                4.834713935852051,
                                45.72671954750314
                            ],
                            [
                                4.837900400161743,
                                45.72647987974298
                            ],
                            [
                                4.840046167373656,
                                45.72627016960933
                            ],
                            [
                                4.841805696487427,
                                45.72534144383977
                            ],
                            [
                                4.842588901519775,
                                45.72436027323015
                            ],
                            [
                                4.842545986175537,
                                45.72086237213719
                            ],
                            [
                                4.842857122421265,
                                45.718690117764865
                            ],
                            [
                                4.8496270179748535,
                                45.7187725151613
                            ],
                            [
                                4.850442409515381,
                                45.718030934219186
                            ],
                            [
                                4.851922988891602,
                                45.71695974437169
                            ],
                            [
                                4.853725433349609,
                                45.71728185256877
                            ],
                            [
                                4.8543477058410645,
                                45.71780621077845
                            ],
                            [
                                4.859046936035156,
                                45.71825565675612
                            ],
                            [
                                4.859293699264526,
                                45.718368017685684
                            ],
                            [
                                4.859604835510254,
                                45.71832307334096
                            ],
                            [
                                4.859776496887206,
                                45.71780621077845
                            ],
                            [
                                4.860076904296875,
                                45.71777624758472
                            ],
                            [
                                4.865773916244507,
                                45.71829311042443
                            ],
                            [
                                4.8687779903411865,
                                45.718570266789506
                            ],
                            [
                                4.868048429489136,
                                45.71967138795671
                            ],
                            [
                                4.868520498275757,
                                45.719761274440934
                            ],
                            [
                                4.869400262832642,
                                45.7187050991187
                            ],
                            [
                                4.87094521522522,
                                45.7188324404642
                            ],
                            [
                                4.870891571044922,
                                45.71898225344029
                            ],
                            [
                                4.871277809143066,
                                45.71914704725003
                            ],
                            [
                                4.871621131896973,
                                45.718899856353154
                            ],
                            [
                                4.872930049896239,
                                45.71906465040592
                            ],
                            [
                                4.873595237731934,
                                45.719356784123164
                            ],
                            [
                                4.873906373977661,
                                45.719746293370264
                            ],
                            [
                                4.874056577682495,
                                45.72042792801893
                            ],
                            [
                                4.873584508895874,
                                45.721154496455235
                            ],
                            [
                                4.874228239059448,
                                45.721394187062614
                            ],
                            [
                                4.875032901763916,
                                45.720480361108976
                            ],
                            [
                                4.876073598861694,
                                45.72024066658125
                            ],
                            [
                                4.877661466598511,
                                45.719746293370264
                            ],
                            [
                                4.878519773483276,
                                45.71978374603939
                            ],
                            [
                                4.880611896514893,
                                45.72047287067055
                            ],
                            [
                                4.882843494415283,
                                45.72120692886348
                            ],
                            [
                                4.8835837841033936,
                                45.72178368210647
                            ],
                            [
                                4.884420633316039,
                                45.722405370492815
                            ],
                            [
                                4.885289669036865,
                                45.72281732886562
                            ],
                            [
                                4.8868560791015625,
                                45.72337159533791
                            ],
                            [
                                4.886974096298218,
                                45.723708646585045
                            ],
                            [
                                4.887510538101196,
                                45.723783546586034
                            ],
                            [
                                4.887617826461792,
                                45.72316936361357
                            ],
                            [
                                4.8881542682647705,
                                45.72299709156725
                            ],
                            [
                                4.890396595001221,
                                45.72371613658965
                            ],
                            [
                                4.8914265632629395,
                                45.724188004856146
                            ],
                            [
                                4.891995191574097,
                                45.7245175612816
                            ],
                            [
                                4.8919737339019775,
                                45.72464488937985
                            ],
                            [
                                4.893293380737305,
                                45.726360045477314
                            ],
                            [
                                4.892284870147705,
                                45.72678695387542
                            ],
                            [
                                4.891469478607177,
                                45.72749097112457
                            ],
                            [
                                4.8913514614105225,
                                45.728030212378236
                            ],
                            [
                                4.892091751098633,
                                45.72891395762042
                            ],
                            [
                                4.893701076507568,
                                45.72930340023782
                            ],
                            [
                                4.893969297409058,
                                45.729857602358024
                            ],
                            [
                                4.896308183670044,
                                45.72955803432723
                            ],
                            [
                                4.897499084472656,
                                45.72961045884861
                            ],
                            [
                                4.897981882095337,
                                45.73010474477386
                            ],
                            [
                                4.899559020996094,
                                45.7327857952462
                            ],
                            [
                                4.898990392684936,
                                45.73324260944911
                            ],
                            [
                                4.899108409881592,
                                45.733564623773006
                            ],
                            [
                                4.899805784225464,
                                45.73342982730522
                            ],
                            [
                                4.900116920471191,
                                45.73386417031437
                            ],
                            [
                                4.899816513061523,
                                45.7350548519297
                            ],
                            [
                                4.9002885818481445,
                                45.73606578809032
                            ],
                            [
                                4.897735118865967,
                                45.737001823773056
                            ],
                            [
                                4.898003339767456,
                                45.73735376913089
                            ],
                            [
                                4.900846481323242,
                                45.73640276274363
                            ],
                            [
                                4.901854991912842,
                                45.736567505167365
                            ],
                            [
                                4.9031853675842285,
                                45.73805765319192
                            ],
                            [
                                4.903829097747803,
                                45.73924075747363
                            ],
                            [
                                4.905288219451904,
                                45.74377076678219
                            ],
                            [
                                4.905974864959717,
                                45.74606183813791
                            ],
                            [
                                4.906951189041138,
                                45.75072604903484
                            ],
                            [
                                4.906543493270874,
                                45.751047962535154
                            ],
                            [
                                4.906661510467529,
                                45.751489654781516
                            ],
                            [
                                4.907112121582031,
                                45.751654352690515
                            ],
                            [
                                4.907562732696532,
                                45.75358577371696
                            ],
                            [
                                4.90739107131958,
                                45.75384029708464
                            ],
                            [
                                4.907702207565308,
                                45.75392264263152
                            ],
                            [
                                4.908410310745239,
                                45.75711156761604
                            ],
                            [
                                4.907423257827759,
                                45.75811461901973
                            ],
                            [
                                4.908592700958252,
                                45.7583017534799
                            ],
                            [
                                4.908946752548218,
                                45.76085420485423
                            ],
                            [
                                4.908539056777954,
                                45.76101887512688
                            ],
                            [
                                4.908345937728882,
                                45.76132575933809
                            ],
                            [
                                4.908850193023682,
                                45.761565277549614
                            ],
                            [
                                4.908796548843384,
                                45.76205928061347
                            ],
                            [
                                4.907927513122559,
                                45.76328678443991
                            ],
                            [
                                4.907358884811401,
                                45.7641999100119
                            ],
                            [
                                4.906693696975708,
                                45.764327147634724
                            ],
                            [
                                4.906725883483887,
                                45.764798672765274
                            ],
                            [
                                4.9057066440582275,
                                45.76583900776558
                            ],
                            [
                                4.904816150665283,
                                45.76699907067782
                            ],
                            [
                                4.903464317321777,
                                45.7666622807047
                            ],
                            [
                                4.903442859649658,
                                45.76726101702891
                            ],
                            [
                                4.903228282928467,
                                45.76778490604079
                            ],
                            [
                                4.9037110805511475,
                                45.76829382208379
                            ],
                            [
                                4.900857210159302,
                                45.77186358792295
                            ],
                            [
                                4.898432493209839,
                                45.774707263032546
                            ],
                            [
                                4.894537925720215,
                                45.77954117794948
                            ],
                            [
                                4.8933470249176025,
                                45.78089549691404
                            ],
                            [
                                4.891952276229858,
                                45.78176344094223
                            ],
                            [
                                4.890750646591186,
                                45.781845745278
                            ],
                            [
                                4.89017128944397,
                                45.78242935435538
                            ],
                            [
                                4.889988899230956,
                                45.78269122823775
                            ],
                            [
                                4.89017128944397,
                                45.78308777777515
                            ],
                            [
                                4.891018867492676,
                                45.78328979249276
                            ],
                            [
                                4.890868663787842,
                                45.783865904518755
                            ],
                            [
                                4.888390302658081,
                                45.786289998982234
                            ],
                            [
                                4.885847568511963,
                                45.786910969183964
                            ],
                            [
                                4.885826110839844,
                                45.787322451651534
                            ],
                            [
                                4.885987043380737,
                                45.78781622660314
                            ],
                            [
                                4.885654449462891,
                                45.78847458640136
                            ],
                            [
                                4.883991479873657,
                                45.789245156742616
                            ],
                            [
                                4.882854223251343,
                                45.79065160718024
                            ],
                            [
                                4.881105422973633,
                                45.791930847767844
                            ],
                            [
                                4.878627061843872,
                                45.793008080226016
                            ],
                            [
                                4.87642765045166,
                                45.79303800305262
                            ],
                            [
                                4.8743462562561035,
                                45.79247694738007
                            ],
                            [
                                4.871202707290649,
                                45.79048702438808
                            ],
                            [
                                4.867351055145264,
                                45.78800326142747
                            ],
                            [
                                4.864797592163086,
                                45.78735985899797
                            ],
                            [
                                4.861353635787964,
                                45.78700074743595
                            ],
                            [
                                4.8608386516571045,
                                45.78741971070003
                            ],
                            [
                                4.859422445297241,
                                45.78777881956298
                            ],
                            [
                                4.857051372528076,
                                45.78793592896303
                            ],
                            [
                                4.8539721965789795,
                                45.78807807518148
                            ],
                            [
                                4.851869344711304,
                                45.78830251584104
                            ],
                            [
                                4.849938154220581,
                                45.78887109480062
                            ],
                            [
                                4.847137928009032,
                                45.78973143351427
                            ],
                            [
                                4.8432862758636475,
                                45.79050946752472
                            ],
                            [
                                4.839048385620117,
                                45.79138474280463
                            ],
                            [
                                4.8370420932769775,
                                45.79178123050187
                            ],
                            [
                                4.834359884262085,
                                45.79234977397572
                            ],
                            [
                                4.831527471542358,
                                45.79280610072611
                            ],
                            [
                                4.82948899269104,
                                45.79314273281927
                            ],
                            [
                                4.827042818069458,
                                45.79341951768373
                            ],
                            [
                                4.824650287628174,
                                45.79365141745779
                            ],
                            [
                                4.822225570678711,
                                45.79225252352953
                            ],
                            [
                                4.820852279663086,
                                45.79279113925254
                            ],
                            [
                                4.8197150230407715,
                                45.792686408825205
                            ],
                            [
                                4.818148612976074,
                                45.79236473556776
                            ],
                            [
                                4.81624960899353,
                                45.79151191841101
                            ],
                            [
                                4.814275503158569,
                                45.790299997898
                            ],
                            [
                                4.8131489753723145,
                                45.7893573748357
                            ],
                            [
                                4.812194108963013,
                                45.788721469320954
                            ],
                            [
                                4.81050968170166,
                                45.78696333984853
                            ],
                            [
                                4.809211492538452,
                                45.785541832924935
                            ],
                            [
                                4.808696508407593,
                                45.78439711942286
                            ],
                            [
                                4.807859659194946,
                                45.78453179281959
                            ],
                            [
                                4.807634353637694,
                                45.78487595668873
                            ],
                            [
                                4.80725884437561,
                                45.78498818357796
                            ],
                            [
                                4.8036110401153564,
                                45.78403798877436
                            ],
                            [
                                4.801594018936157,
                                45.78279597744629
                            ],
                            [
                                4.800832271575927,
                                45.78224230082914
                            ],
                            [
                                4.800564050674438,
                                45.781524009456426
                            ],
                            [
                                4.800081253051758,
                                45.7813369528927
                            ],
                            [
                                4.798053503036499,
                                45.78026697728653
                            ],
                            [
                                4.796111583709717,
                                45.77930173692038
                            ],
                            [
                                4.793676137924194,
                                45.777977310155265
                            ],
                            [
                                4.794931411743164,
                                45.777640586470525
                            ],
                            [
                                4.795478582382202,
                                45.77581476042044
                            ],
                            [
                                4.792882204055786,
                                45.773637160204345
                            ],
                            [
                                4.792860746383667,
                                45.77242485107416
                            ],
                            [
                                4.791412353515625,
                                45.77112271191466
                            ],
                            [
                                4.795199632644653,
                                45.76867550606895
                            ],
                            [
                                4.798182249069214,
                                45.76682693383449
                            ],
                            [
                                4.800209999084473,
                                45.76562944544106
                            ],
                            [
                                4.802119731903076,
                                45.764551683908216
                            ],
                            [
                                4.804072380065918,
                                45.763196968002525
                            ],
                            [
                                4.806421995162963,
                                45.76202934115837
                            ],
                            [
                                4.809061288833618,
                                45.760944025063196
                            ],
                            [
                                4.810724258422852,
                                45.76031528056393
                            ],
                            [
                                4.8122477531433105,
                                45.759140108157524
                            ],
                            [
                                4.813524484634399,
                                45.75833918029661
                            ],
                            [
                                4.815552234649658,
                                45.756842088046795
                            ],
                            [
                                4.8171937465667725,
                                45.75568181392639
                            ],
                            [
                                4.818685054779053,
                                45.75472363388481
                            ],
                            [
                                4.8197901248931885,
                                45.75338365139218
                            ],
                            [
                                4.820691347122192,
                                45.752934488049576
                            ],
                            [
                                4.821796417236327,
                                45.75242543189118
                            ]
                        ],
                        [
                            [
                                4.7933220863342285,
                                45.7785534770772
                            ],
                            [
                                4.796111583709717,
                                45.78013977602365
                            ],
                            [
                                4.799137115478516,
                                45.78172602984036
                            ],
                            [
                                4.799394607543944,
                                45.78278849536649
                            ],
                            [
                                4.802055358886719,
                                45.78497322000578
                            ],
                            [
                                4.80424404144287,
                                45.78673889379681
                            ],
                            [
                                4.804630279541016,
                                45.7876217097201
                            ],
                            [
                                4.804286956787109,
                                45.78851947428625
                            ],
                            [
                                4.802978038787842,
                                45.78991098078836
                            ],
                            [
                                4.801068305969238,
                                45.79107802397183
                            ],
                            [
                                4.7992658615112305,
                                45.791377261877585
                            ],
                            [
                                4.797248840332031,
                                45.791137871681514
                            ],
                            [
                                4.797763824462891,
                                45.79047954334053
                            ],
                            [
                                4.797763824462891,
                                45.789641659660354
                            ],
                            [
                                4.793150424957275,
                                45.78896835114702
                            ],
                            [
                                4.7922492027282715,
                                45.78986609402405
                            ],
                            [
                                4.79079008102417,
                                45.790464581242404
                            ],
                            [
                                4.786927700042725,
                                45.78643963098876
                            ],
                            [
                                4.792013168334961,
                                45.786679041363726
                            ],
                            [
                                4.792528152465819,
                                45.78631992541569
                            ],
                            [
                                4.79154109954834,
                                45.78278849536649
                            ],
                            [
                                4.791626930236816,
                                45.7815464562019
                            ],
                            [
                                4.7923994064331055,
                                45.77930173692038
                            ],
                            [
                                4.7933220863342285,
                                45.7785534770772
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Lille (only when pollution warning)",
                "zone": "ZFE",
                "area_code": 1,
                "time": "Temporary during Emission Peak",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 0,
                "urlZone": "https://www.lille.fr/Actualites/Pollution-la-vignette-Crit-Air-a-Lille",
                "urlPark": "https://www.lille.fr/Vivre-a-Lille/Me-deplacer/En-voiture/Le-stationnement/Les-parcs-relais-pratiques-et-gratuits",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                3.122091293334961,
                                50.70607971642304
                            ],
                            [
                                3.119344711303711,
                                50.70219306786194
                            ],
                            [
                                3.1160831451416016,
                                50.69833327986365
                            ],
                            [
                                3.1142377853393555,
                                50.69580521837641
                            ],
                            [
                                3.113422393798828,
                                50.69428287906098
                            ],
                            [
                                3.112156391143799,
                                50.69130601924233
                            ],
                            [
                                3.1116628646850586,
                                50.68891352009441
                            ],
                            [
                                3.111813068389892,
                                50.68594991448269
                            ],
                            [
                                3.1133365631103516,
                                50.68453601832582
                            ],
                            [
                                3.115568161010742,
                                50.685446898617165
                            ],
                            [
                                3.1119203567504883,
                                50.68779879132414
                            ],
                            [
                                3.1128430366516113,
                                50.68842413023731
                            ],
                            [
                                3.1157827377319336,
                                50.68923977716304
                            ],
                            [
                                3.1196236610412593,
                                50.690680718742705
                            ],
                            [
                                3.1217265129089355,
                                50.68919899515351
                            ],
                            [
                                3.124537467956543,
                                50.68997384727175
                            ],
                            [
                                3.12636137008667,
                                50.6886960141215
                            ],
                            [
                                3.1316614151000977,
                                50.69189053173734
                            ],
                            [
                                3.13234806060791,
                                50.694418804222984
                            ],
                            [
                                3.1282711029052734,
                                50.69607705948341
                            ],
                            [
                                3.123936653137207,
                                50.69906720767511
                            ],
                            [
                                3.1211471557617188,
                                50.70311719531449
                            ],
                            [
                                3.1230354309082027,
                                50.70488385887146
                            ],
                            [
                                3.122091293334961,
                                50.70607971642304
                            ]
                        ],
                        [
                            [
                                3.1140661239624023,
                                50.681177844194885
                            ],
                            [
                                3.11586856842041,
                                50.67745230102966
                            ],
                            [
                                3.1146240234375,
                                50.67587497466894
                            ],
                            [
                                3.1151819229125977,
                                50.67378085604806
                            ],
                            [
                                3.1176710128784175,
                                50.67418880856135
                            ],
                            [
                                3.1193017959594727,
                                50.67546703681043
                            ],
                            [
                                3.1201171874999996,
                                50.678050583368815
                            ],
                            [
                                3.115396499633789,
                                50.68115065152067
                            ],
                            [
                                3.1140661239624023,
                                50.681177844194885
                            ]
                        ],
                        [
                            [
                                3.1156110763549805,
                                50.66254717076451
                            ],
                            [
                                3.115997314453125,
                                50.66028922924558
                            ],
                            [
                                3.1190872192382812,
                                50.65903779321195
                            ],
                            [
                                3.1156110763549805,
                                50.66254717076451
                            ]
                        ],
                        [
                            [
                                3.1152462959289546,
                                50.71082207841815
                            ],
                            [
                                3.1133151054382324,
                                50.70958557754182
                            ],
                            [
                                3.1119418144226074,
                                50.70724836592542
                            ],
                            [
                                3.1121349334716797,
                                50.70535948768707
                            ],
                            [
                                3.1102895736694336,
                                50.70565845190169
                            ],
                            [
                                3.107156753540039,
                                50.70443540442616
                            ],
                            [
                                3.105783462524414,
                                50.70315796522381
                            ],
                            [
                                3.1078648567199707,
                                50.70235615050049
                            ],
                            [
                                3.1053972244262695,
                                50.701282512706264
                            ],
                            [
                                3.103938102722168,
                                50.699801124001105
                            ],
                            [
                                3.106255531311035,
                                50.698360462580006
                            ],
                            [
                                3.105783462524414,
                                50.69721877492789
                            ],
                            [
                                3.1066203117370605,
                                50.69643045056708
                            ],
                            [
                                3.105032444000244,
                                50.69425569398131
                            ],
                            [
                                3.101534843444824,
                                50.69127883243743
                            ],
                            [
                                3.0993247032165527,
                                50.692746897356834
                            ],
                            [
                                3.096792697906494,
                                50.692529409156734
                            ],
                            [
                                3.091363906860351,
                                50.691292425841844
                            ],
                            [
                                3.0879521369934078,
                                50.686860767326024
                            ],
                            [
                                3.085141181945801,
                                50.68484871056832
                            ],
                            [
                                3.086493015289306,
                                50.68434568289711
                            ],
                            [
                                3.090527057647705,
                                50.6846991623645
                            ],
                            [
                                3.0965352058410645,
                                50.68541970841675
                            ],
                            [
                                3.1027793884277344,
                                50.685215781411436
                            ],
                            [
                                3.108530044555664,
                                50.68498466306725
                            ],
                            [
                                3.1103754043579097,
                                50.68566441965323
                            ],
                            [
                                3.110525608062744,
                                50.689769940060394
                            ],
                            [
                                3.111147880554199,
                                50.69289641990945
                            ],
                            [
                                3.1134653091430664,
                                50.69723236661101
                            ],
                            [
                                3.116726875305176,
                                50.70067093588509
                            ],
                            [
                                3.119516372680664,
                                50.70419079110273
                            ],
                            [
                                3.1212759017944336,
                                50.70708530030171
                            ],
                            [
                                3.1205034255981445,
                                50.70836263250825
                            ],
                            [
                                3.1152462959289546,
                                50.71082207841815
                            ]
                        ],
                        [
                            [
                                3.065528869628906,
                                50.68381545870311
                            ],
                            [
                                3.0686187744140625,
                                50.68381545870311
                            ],
                            [
                                3.0745840072631836,
                                50.682945334221635
                            ],
                            [
                                3.0800342559814453,
                                50.683108483790946
                            ],
                            [
                                3.081192970275879,
                                50.68340758986141
                            ],
                            [
                                3.0736827850341797,
                                50.686996713995015
                            ],
                            [
                                3.066129684448242,
                                50.685093424782785
                            ],
                            [
                                3.065528869628906,
                                50.68381545870311
                            ]
                        ],
                        [
                            [
                                3.0099105834960938,
                                50.655065623174735
                            ],
                            [
                                3.012356758117676,
                                50.65620833664591
                            ],
                            [
                                3.0127429962158203,
                                50.657432641658005
                            ],
                            [
                                3.015017509460449,
                                50.657867942416004
                            ],
                            [
                                3.0176353454589844,
                                50.65895617666014
                            ],
                            [
                                3.0202531814575195,
                                50.662139117081445
                            ],
                            [
                                3.0238580703735347,
                                50.66518583231177
                            ],
                            [
                                3.026604652404785,
                                50.66708992894651
                            ],
                            [
                                3.028707504272461,
                                50.6676883433696
                            ],
                            [
                                3.0319690704345703,
                                50.66907554747044
                            ],
                            [
                                3.0373764038085938,
                                50.67182263463033
                            ],
                            [
                                3.035445213317871,
                                50.672937742951866
                            ],
                            [
                                3.027205467224121,
                                50.67274736047949
                            ],
                            [
                                3.0200815200805664,
                                50.67198582286857
                            ],
                            [
                                3.0176782608032227,
                                50.66855875073327
                            ],
                            [
                                3.0154037475585938,
                                50.662356746153684
                            ],
                            [
                                3.0081939697265625,
                                50.65871132615369
                            ],
                            [
                                3.0099105834960938,
                                50.655065623174735
                            ]
                        ],
                        [
                            [
                                2.9733896255493164,
                                50.64814072906255
                            ],
                            [
                                2.9749560356140137,
                                50.64897068180172
                            ],
                            [
                                2.9772090911865234,
                                50.64903871006465
                            ],
                            [
                                2.9819297790527344,
                                50.649487694129434
                            ],
                            [
                                2.984483242034912,
                                50.65082101545431
                            ],
                            [
                                2.9874229431152344,
                                50.65201825133689
                            ],
                            [
                                2.9901695251464844,
                                50.65264406704129
                            ],
                            [
                                3.0001473426818848,
                                50.65382765220871
                            ],
                            [
                                2.9997825622558594,
                                50.65460308838992
                            ],
                            [
                                2.9957056045532227,
                                50.65643959670406
                            ],
                            [
                                2.9885387420654292,
                                50.6589017655468
                            ],
                            [
                                2.9837751388549805,
                                50.66128219280534
                            ],
                            [
                                2.9751491546630855,
                                50.65943227121294
                            ],
                            [
                                2.9688620567321777,
                                50.65733741907938
                            ],
                            [
                                2.9695701599121094,
                                50.65446704788051
                            ],
                            [
                                2.971737384796142,
                                50.65082101545431
                            ],
                            [
                                2.9733896255493164,
                                50.64814072906255
                            ]
                        ],
                        [
                            [
                                2.9665660858154297,
                                50.62211904034937
                            ],
                            [
                                2.9633474349975586,
                                50.620430943851154
                            ],
                            [
                                2.968068122863769,
                                50.61671419525607
                            ],
                            [
                                2.9706859588623047,
                                50.615801981298716
                            ],
                            [
                                2.974162101745605,
                                50.615366290777914
                            ],
                            [
                                2.977316379547119,
                                50.61817097787392
                            ],
                            [
                                2.9714584350585938,
                                50.62051262733487
                            ],
                            [
                                2.969634532928467,
                                50.620553469023505
                            ],
                            [
                                2.9665660858154297,
                                50.62211904034937
                            ]
                        ],
                        [
                            [
                                3.0212831497192383,
                                50.62145197720038
                            ],
                            [
                                3.021519184112549,
                                50.62096188395929
                            ],
                            [
                                3.0229568481445312,
                                50.619695786113425
                            ],
                            [
                                3.0239439010620117,
                                50.61700010942205
                            ],
                            [
                                3.0279994010925293,
                                50.613827726339
                            ],
                            [
                                3.024458885192871,
                                50.61340563349282
                            ],
                            [
                                3.023536205291748,
                                50.61257505263987
                            ],
                            [
                                3.0237936973571777,
                                50.61152659357464
                            ],
                            [
                                3.0258965492248535,
                                50.61113171332364
                            ],
                            [
                                3.0295872688293457,
                                50.60878959681055
                            ],
                            [
                                3.0355095863342285,
                                50.605126401745046
                            ],
                            [
                                3.039414882659912,
                                50.60436376764963
                            ],
                            [
                                3.041539192199707,
                                50.60456804585144
                            ],
                            [
                                3.043084144592285,
                                50.6068695189783
                            ],
                            [
                                3.0491137504577637,
                                50.60086366336494
                            ],
                            [
                                3.0544352531433105,
                                50.601054337228824
                            ],
                            [
                                3.058490753173828,
                                50.60614776733232
                            ],
                            [
                                3.062009811401367,
                                50.612561436438135
                            ],
                            [
                                3.0678892135620117,
                                50.609361519741924
                            ],
                            [
                                3.076729774475097,
                                50.60356026478511
                            ],
                            [
                                3.076450824737549,
                                50.60014181959645
                            ],
                            [
                                3.0800342559814453,
                                50.60035973586308
                            ],
                            [
                                3.086385726928711,
                                50.58860443665508
                            ],
                            [
                                3.0891537666320796,
                                50.59289552917843
                            ],
                            [
                                3.093380928039551,
                                50.59113827191043
                            ],
                            [
                                3.0972647666931152,
                                50.59157418680764
                            ],
                            [
                                3.1013846397399902,
                                50.597758294557906
                            ],
                            [
                                3.1023716926574707,
                                50.600931761262146
                            ],
                            [
                                3.1001186370849605,
                                50.60248436658359
                            ],
                            [
                                3.0996465682983394,
                                50.603778165222856
                            ],
                            [
                                3.0923080444335938,
                                50.60906194193067
                            ],
                            [
                                3.0837249755859375,
                                50.61468551625998
                            ],
                            [
                                3.079433441162109,
                                50.61587005757803
                            ],
                            [
                                3.070979118347168,
                                50.615774750759385
                            ],
                            [
                                3.063018321990967,
                                50.61546159842418
                            ],
                            [
                                3.05619478225708,
                                50.61644189443452
                            ],
                            [
                                3.0495858192443848,
                                50.61650996978771
                            ],
                            [
                                3.0413460731506348,
                                50.61663250517513
                            ],
                            [
                                3.0379128456115723,
                                50.61610151619075
                            ],
                            [
                                3.0347585678100586,
                                50.61582921182227
                            ],
                            [
                                3.0319261550903316,
                                50.617054569066156
                            ],
                            [
                                3.029651641845703,
                                50.617953144089846
                            ],
                            [
                                3.027935028076172,
                                50.61999529620861
                            ],
                            [
                                3.023686408996582,
                                50.62124777230397
                            ],
                            [
                                3.0212831497192383,
                                50.62145197720038
                            ]
                        ],
                        [
                            [
                                3.137390613555908,
                                50.60101347860874
                            ],
                            [
                                3.1378626823425293,
                                50.60125862979715
                            ],
                            [
                                3.136188983917236,
                                50.602593319419576
                            ],
                            [
                                3.132627010345459,
                                50.60474508624236
                            ],
                            [
                                3.131747245788574,
                                50.60395520858556
                            ],
                            [
                                3.1327128410339355,
                                50.60253884303311
                            ],
                            [
                                3.137390613555908,
                                50.60101347860874
                            ]
                        ],
                        [
                            [
                                2.9739904403686523,
                                50.64665766225224
                            ],
                            [
                                2.974376678466797,
                                50.64453502647366
                            ],
                            [
                                2.9722309112548824,
                                50.64374581692316
                            ],
                            [
                                2.972574234008789,
                                50.641840773740235
                            ],
                            [
                                2.9687118530273438,
                                50.63835706647613
                            ],
                            [
                                2.97029972076416,
                                50.63626127442539
                            ],
                            [
                                2.968111038208008,
                                50.63514529507524
                            ],
                            [
                                2.969698905944824,
                                50.6335937675647
                            ],
                            [
                                2.9706430435180664,
                                50.634328708032356
                            ],
                            [
                                2.9718446731567383,
                                50.63362098778692
                            ],
                            [
                                2.9660511016845703,
                                50.63068111271064
                            ],
                            [
                                2.96755313873291,
                                50.62918388345113
                            ],
                            [
                                2.964763641357422,
                                50.627169354302275
                            ],
                            [
                                2.9650211334228516,
                                50.62545421416371
                            ],
                            [
                                2.9717588424682617,
                                50.623140037048174
                            ],
                            [
                                2.9770803451538086,
                                50.61962771537123
                            ],
                            [
                                2.9844188690185542,
                                50.617122643532596
                            ],
                            [
                                2.9889678955078125,
                                50.61704095416104
                            ],
                            [
                                2.995576858520508,
                                50.618484112171004
                            ],
                            [
                                2.9940319061279297,
                                50.620852973656554
                            ],
                            [
                                2.9978513717651367,
                                50.62145197720038
                            ],
                            [
                                2.9967784881591797,
                                50.624283527033704
                            ],
                            [
                                3.001112937927246,
                                50.625318089250754
                            ],
                            [
                                3.002314567565918,
                                50.624446880475986
                            ],
                            [
                                3.013730049133301,
                                50.625563113810344
                            ],
                            [
                                3.018794059753418,
                                50.62520918903667
                            ],
                            [
                                3.021111488342285,
                                50.62248660171465
                            ],
                            [
                                3.024415969848633,
                                50.62300390543577
                            ],
                            [
                                3.0277633666992183,
                                50.623276168266464
                            ],
                            [
                                3.030424118041992,
                                50.62123415861269
                            ],
                            [
                                3.0319690704345703,
                                50.61859302795956
                            ],
                            [
                                3.036561012268066,
                                50.61832073801525
                            ],
                            [
                                3.0443716049194336,
                                50.61764000625769
                            ],
                            [
                                3.052611351013183,
                                50.6179939880012
                            ],
                            [
                                3.062009811401367,
                                50.61731325151447
                            ],
                            [
                                3.071107864379883,
                                50.61701372433899
                            ],
                            [
                                3.0764293670654297,
                                50.61772169458882
                            ],
                            [
                                3.0835962295532227,
                                50.617558317784706
                            ],
                            [
                                3.093252182006836,
                                50.61064151253878
                            ],
                            [
                                3.1014490127563477,
                                50.605548568875776
                            ],
                            [
                                3.1046676635742188,
                                50.60484041540773
                            ],
                            [
                                3.1074142456054688,
                                50.60184426357796
                            ],
                            [
                                3.1135511398315425,
                                50.60546685940414
                            ],
                            [
                                3.114452362060547,
                                50.6094432224503
                            ],
                            [
                                3.1242799758911133,
                                50.60633841978648
                            ],
                            [
                                3.129558563232422,
                                50.60857171957938
                            ],
                            [
                                3.1262969970703125,
                                50.61061427901209
                            ],
                            [
                                3.1267261505126953,
                                50.61410004229431
                            ],
                            [
                                3.120417594909668,
                                50.61864748575925
                            ],
                            [
                                3.125782012939453,
                                50.62060792455322
                            ],
                            [
                                3.1217479705810547,
                                50.623548429520824
                            ],
                            [
                                3.1235074996948238,
                                50.62665209640503
                            ],
                            [
                                3.121833801269531,
                                50.62771383015179
                            ],
                            [
                                3.1208038330078125,
                                50.63092610932254
                            ],
                            [
                                3.121490478515625,
                                50.63519973370705
                            ],
                            [
                                3.1168127059936523,
                                50.6362884931029
                            ],
                            [
                                3.1173276901245117,
                                50.637295573091066
                            ],
                            [
                                3.117928504943847,
                                50.64028946724282
                            ],
                            [
                                3.122220039367676,
                                50.643773031266264
                            ],
                            [
                                3.124752044677734,
                                50.647800580261624
                            ],
                            [
                                3.1229496002197266,
                                50.65005912218796
                            ],
                            [
                                3.1192588806152344,
                                50.65014075420038
                            ],
                            [
                                3.1159114837646484,
                                50.656235543770684
                            ],
                            [
                                3.114194869995117,
                                50.65852088598832
                            ],
                            [
                                3.1110620498657227,
                                50.6625743742173
                            ],
                            [
                                3.111276626586914,
                                50.667389137111456
                            ],
                            [
                                3.1127357482910156,
                                50.67152345472018
                            ],
                            [
                                3.110504150390625,
                                50.68139538502156
                            ],
                            [
                                3.1096887588500977,
                                50.68365231159186
                            ],
                            [
                                3.102436065673828,
                                50.684250514893215
                            ],
                            [
                                3.0923080444335938,
                                50.68386984094747
                            ],
                            [
                                3.085784912109375,
                                50.68297252585591
                            ],
                            [
                                3.0847549438476562,
                                50.682319922284336
                            ],
                            [
                                3.081965446472168,
                                50.68240149822713
                            ],
                            [
                                3.077073097229004,
                                50.68183046364952
                            ],
                            [
                                3.0673742294311523,
                                50.682564649687286
                            ],
                            [
                                3.0649280548095703,
                                50.682347114281015
                            ],
                            [
                                3.063039779663086,
                                50.68164011724613
                            ],
                            [
                                3.0585765838623047,
                                50.67921993137434
                            ],
                            [
                                3.0553579330444336,
                                50.67965503017147
                            ],
                            [
                                3.05269718170166,
                                50.67818655556408
                            ],
                            [
                                3.0489635467529292,
                                50.675439840827146
                            ],
                            [
                                3.0468177795410156,
                                50.67470554332169
                            ],
                            [
                                3.041067123413086,
                                50.67184983270943
                            ],
                            [
                                3.0348443984985347,
                                50.66885794953764
                            ],
                            [
                                3.027334213256836,
                                50.66499541840129
                            ],
                            [
                                3.023214340209961,
                                50.66241115326413
                            ],
                            [
                                3.0188798904418945,
                                50.658167209346786
                            ],
                            [
                                3.0139875411987305,
                                50.65522886965842
                            ],
                            [
                                3.009181022644043,
                                50.653079412179316
                            ],
                            [
                                3.0006837844848633,
                                50.6523447649185
                            ],
                            [
                                2.9964780807495117,
                                50.6523447649185
                            ],
                            [
                                2.9897403717041016,
                                50.65152847670924
                            ],
                            [
                                2.9868221282958984,
                                50.650249596662945
                            ],
                            [
                                2.983903884887695,
                                50.64834481716105
                            ],
                            [
                                2.9794836044311523,
                                50.64788221619841
                            ],
                            [
                                2.9756212234497066,
                                50.64728354936779
                            ],
                            [
                                2.9739904403686523,
                                50.64665766225224
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "fr",
                "city": "Reims",
                "zone": "ZFE-m",
                "area_code": 2,
                "time": "24/7: Status 2024 - Crit 4/5 BAN",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 1,
                "urlZone": "https://www.reims.fr/qualite-de-vie-environnement/ecologie-urbaine/zone-a-faibles-emissions-mobilite-zfem-de-reims",
                "urlPark": "https://www.reims.fr/qualite-de-vie-environnement/stationnement-et-deplacements/les-parkings-et-zones-de-stationnement",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/bon-a-savoir-2",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                4.042823227177536,
                                49.23547296034545
                            ],
                            [
                                4.042541669831223,
                                49.23529854452468
                            ],
                            [
                                4.043119223362652,
                                49.23485071729894
                            ],
                            [
                                4.044028870174714,
                                49.23426146476342
                            ],
                            [
                                4.04518397723777,
                                49.233790057674156
                            ],
                            [
                                4.046635080484805,
                                49.23339878637361
                            ],
                            [
                                4.048367741079375,
                                49.2329839411278
                            ],
                            [
                                4.048483251785399,
                                49.23322907737585
                            ],
                            [
                                4.0479778924457435,
                                49.233290361247896
                            ],
                            [
                                4.04638240081502,
                                49.23370991802153
                            ],
                            [
                                4.045256171428463,
                                49.234030475851995
                            ],
                            [
                                4.044288769263403,
                                49.23446416842884
                            ],
                            [
                                4.043444097224381,
                                49.23497328094294
                            ],
                            [
                                4.042823227177536,
                                49.23547296034545
                            ]
                        ],
                        [
                            [
                                4.068787791521174,
                                49.23123085505682
                            ],
                            [
                                4.068948935902142,
                                49.23117278914384
                            ],
                            [
                                4.069073825015948,
                                49.23099470223406
                            ],
                            [
                                4.068956582174508,
                                49.23074005348079
                            ],
                            [
                                4.068446910630172,
                                49.23034672745209
                            ],
                            [
                                4.0680187193831046,
                                49.22991564895642
                            ],
                            [
                                4.067594938968824,
                                49.22929749499241
                            ],
                            [
                                4.067091857951596,
                                49.22822413218884
                            ],
                            [
                                4.066457579066309,
                                49.22698540585512
                            ],
                            [
                                4.065724628886301,
                                49.22559935187098
                            ],
                            [
                                4.06537721845595,
                                49.225618760863796
                            ],
                            [
                                4.064750224130108,
                                49.22562708360181
                            ],
                            [
                                4.064110486017256,
                                49.2254806032073
                            ],
                            [
                                4.062741445790749,
                                49.22520732560969
                            ],
                            [
                                4.062657336795667,
                                49.225049192003326
                            ],
                            [
                                4.063643706681887,
                                49.22488107084908
                            ],
                            [
                                4.064606568328287,
                                49.22490458571485
                            ],
                            [
                                4.0651239660850536,
                                49.22502609930771
                            ],
                            [
                                4.065697436505189,
                                49.22535734717317
                            ],
                            [
                                4.065954861005167,
                                49.22563033369522
                            ],
                            [
                                4.066110335595027,
                                49.225925812966494
                            ],
                            [
                                4.0663818191492,
                                49.22647294369503
                            ],
                            [
                                4.0665194520224475,
                                49.226957315649315
                            ],
                            [
                                4.066733613601173,
                                49.227361714852606
                            ],
                            [
                                4.067006200143908,
                                49.22786481521777
                            ],
                            [
                                4.067276525020276,
                                49.22844036575668
                            ],
                            [
                                4.067596964033896,
                                49.229095367168355
                            ],
                            [
                                4.068043948044902,
                                49.22979203313557
                            ],
                            [
                                4.068413517871619,
                                49.230173180905865
                            ],
                            [
                                4.068930521936579,
                                49.230596730326624
                            ],
                            [
                                4.069190495194221,
                                49.230806441767925
                            ],
                            [
                                4.069305189277969,
                                49.2309828649897
                            ],
                            [
                                4.069131873773131,
                                49.2312108827488
                            ],
                            [
                                4.068787791521174,
                                49.23123085505682
                            ]
                        ],
                        [
                            [
                                4.062492327197276,
                                49.2252468180383
                            ],
                            [
                                4.061980886097359,
                                49.22534342266684
                            ],
                            [
                                4.0600922705397124,
                                49.22583078887388
                            ],
                            [
                                4.05587600033968,
                                49.22695559033346
                            ],
                            [
                                4.051986218672084,
                                49.228099835504054
                            ],
                            [
                                4.048539167438321,
                                49.229143950700575
                            ],
                            [
                                4.046426838685875,
                                49.230004159039424
                            ],
                            [
                                4.044805489634825,
                                49.23084527519066
                            ],
                            [
                                4.044059282404135,
                                49.231384187895
                            ],
                            [
                                4.043213076551723,
                                49.23208893687462
                            ],
                            [
                                4.0427889046545715,
                                49.2326862806799
                            ],
                            [
                                4.0424295540127275,
                                49.233996403015794
                            ],
                            [
                                4.041935446881297,
                                49.23477854904016
                            ],
                            [
                                4.041096962051967,
                                49.234338593425406
                            ],
                            [
                                4.040033883070464,
                                49.23381064151502
                            ],
                            [
                                4.039360100617529,
                                49.233263129839486
                            ],
                            [
                                4.040632800804872,
                                49.23277427502879
                            ],
                            [
                                4.0418006903892945,
                                49.23219742012961
                            ],
                            [
                                4.043773979994313,
                                49.23085999660435
                            ],
                            [
                                4.045450949654139,
                                49.229892012298535
                            ],
                            [
                                4.046648785122386,
                                49.22936401282462
                            ],
                            [
                                4.047547161725134,
                                49.22898267639084
                            ],
                            [
                                4.049358887875798,
                                49.22840577721709
                            ],
                            [
                                4.052894670485415,
                                49.227329623256765
                            ],
                            [
                                4.057910606519783,
                                49.22598021017217
                            ],
                            [
                                4.062028165951375,
                                49.22470899022224
                            ],
                            [
                                4.062492327197276,
                                49.2252468180383
                            ]
                        ],
                        [
                            [
                                4.0389841007747975,
                                49.233812330650096
                            ],
                            [
                                4.040944494659499,
                                49.234926637344785
                            ],
                            [
                                4.042425367737707,
                                49.23577386197698
                            ],
                            [
                                4.040567202149049,
                                49.23748946750197
                            ],
                            [
                                4.039819713833083,
                                49.23818470923274
                            ],
                            [
                                4.03893824176356,
                                49.239031877980835
                            ],
                            [
                                4.039241472933725,
                                49.23930352205227
                            ],
                            [
                                4.041413420115475,
                                49.24000794634321
                            ],
                            [
                                4.0434795906487295,
                                49.24064330081106
                            ],
                            [
                                4.044114244220708,
                                49.24079523539402
                            ],
                            [
                                4.043994364019625,
                                49.241071473067535
                            ],
                            [
                                4.044149503104279,
                                49.24145820321479
                            ],
                            [
                                4.044579661474643,
                                49.24167919051095
                            ],
                            [
                                4.045305994460705,
                                49.24167919051095
                            ],
                            [
                                4.045538703086493,
                                49.24276569699845
                            ],
                            [
                                4.045796269257124,
                                49.24357835174547
                            ],
                            [
                                4.0458456316932825,
                                49.24385917754006
                            ],
                            [
                                4.0460994956490595,
                                49.24399728800074
                            ],
                            [
                                4.045817424587085,
                                49.24426430046253
                            ],
                            [
                                4.045648181949872,
                                49.24423667855072
                            ],
                            [
                                4.044679017763968,
                                49.24621879637124
                            ],
                            [
                                4.044024057191109,
                                49.247612513136346
                            ],
                            [
                                4.043756089681693,
                                49.24789331598788
                            ],
                            [
                                4.043382345524833,
                                49.248510155988754
                            ],
                            [
                                4.043741986129163,
                                49.24863904695593
                            ],
                            [
                                4.043283116514971,
                                49.249090082517796
                            ],
                            [
                                4.042345230231945,
                                49.251198303452924
                            ],
                            [
                                4.042288808379311,
                                49.25135480671236
                            ],
                            [
                                4.043233746438659,
                                49.25168622085101
                            ],
                            [
                                4.042930520046667,
                                49.25186113297181
                            ],
                            [
                                4.04214072107095,
                                49.25161257345698
                            ],
                            [
                                4.040465584528931,
                                49.255254806213486
                            ],
                            [
                                4.039944191838146,
                                49.256405077236366
                            ],
                            [
                                4.039591603008972,
                                49.256975786255
                            ],
                            [
                                4.0391896517459145,
                                49.25696197885654
                            ],
                            [
                                4.038858218246702,
                                49.25704942564758
                            ],
                            [
                                4.038583198960964,
                                49.25730716265704
                            ],
                            [
                                4.038604354290925,
                                49.257560295909315
                            ],
                            [
                                4.0389005289065665,
                                49.25780882547636
                            ],
                            [
                                4.03777670935537,
                                49.258838523173836
                            ],
                            [
                                4.035837470800601,
                                49.26051372894938
                            ],
                            [
                                4.0334449113831,
                                49.2613851240892
                            ],
                            [
                                4.031949934752333,
                                49.26139893025032
                            ],
                            [
                                4.030976789586788,
                                49.26144034870978
                            ],
                            [
                                4.030574838322593,
                                49.26142194050979
                            ],
                            [
                                4.029996592643869,
                                49.26180390925364
                            ],
                            [
                                4.029587589603437,
                                49.261941969517636
                            ],
                            [
                                4.028833049511235,
                                49.2627427114335
                            ],
                            [
                                4.0285157195656325,
                                49.262420575443514
                            ],
                            [
                                4.02908691346812,
                                49.261794705222314
                            ],
                            [
                                4.028705013035534,
                                49.26142843133616
                            ],
                            [
                                4.027781230304981,
                                49.26072891473865
                            ],
                            [
                                4.026674101384742,
                                49.25987291383325
                            ],
                            [
                                4.025378969203558,
                                49.25888076152839
                            ],
                            [
                                4.0240391316563375,
                                49.25783603113169
                            ],
                            [
                                4.023116106754458,
                                49.257144097411015
                            ],
                            [
                                4.022283997119899,
                                49.25752609926485
                            ],
                            [
                                4.022058340471631,
                                49.257360412061274
                            ],
                            [
                                4.022819932340127,
                                49.256927781781854
                            ],
                            [
                                4.02101467754008,
                                49.255510200679794
                            ],
                            [
                                4.02013322342782,
                                49.25632946255459
                            ],
                            [
                                4.019921670130714,
                                49.25621900181332
                            ],
                            [
                                4.020753779765272,
                                49.25537673053063
                            ],
                            [
                                4.0188295650442285,
                                49.2539125778178
                            ],
                            [
                                4.018293630025312,
                                49.25339706700191
                            ],
                            [
                                4.017059569127724,
                                49.253125501103426
                            ],
                            [
                                4.01509968245017,
                                49.253263752910385
                            ],
                            [
                                4.011593665471651,
                                49.25374150089962
                            ],
                            [
                                4.007469096914235,
                                49.25426621185545
                            ],
                            [
                                4.002910943693479,
                                49.25481027217097
                            ],
                            [
                                3.994700539221526,
                                49.25566738257575
                            ],
                            [
                                3.98695173684834,
                                49.2563234552041
                            ],
                            [
                                3.9824285191347997,
                                49.256282341689854
                            ],
                            [
                                3.980263623730366,
                                49.25608443249732
                            ],
                            [
                                3.9766673032446818,
                                49.25553747978586
                            ],
                            [
                                3.975778779397757,
                                49.25539940160513
                            ],
                            [
                                3.974594080674791,
                                49.25532575980509
                            ],
                            [
                                3.9736702979454037,
                                49.25562492915574
                            ],
                            [
                                3.97249265802094,
                                49.2560345492698
                            ],
                            [
                                3.971752221481296,
                                49.25484707624881
                            ],
                            [
                                3.9737972366845895,
                                49.25436379416584
                            ],
                            [
                                3.975193488444262,
                                49.25463075056794
                            ],
                            [
                                3.9774712122734,
                                49.255302737878935
                            ],
                            [
                                3.980204601294048,
                                49.255781399151545
                            ],
                            [
                                3.983349693640207,
                                49.256025335121564
                            ],
                            [
                                3.985747297671537,
                                49.256043745334495
                            ],
                            [
                                3.9904366291755764,
                                49.255763009385646
                            ],
                            [
                                3.9985965855727557,
                                49.254983082406966
                            ],
                            [
                                4.007245929927933,
                                49.25398198814153
                            ],
                            [
                                4.01257993526599,
                                49.25323657150682
                            ],
                            [
                                4.013933876366906,
                                49.2528453307541
                            ],
                            [
                                4.015739131166953,
                                49.25179586965683
                            ],
                            [
                                4.016373791056964,
                                49.251482868167955
                            ],
                            [
                                4.017086020900905,
                                49.25153350084213
                            ],
                            [
                                4.017674248341649,
                                49.25187935310757
                            ],
                            [
                                4.017977474734693,
                                49.252238381176056
                            ],
                            [
                                4.0189788270064355,
                                49.25191617661929
                            ],
                            [
                                4.020022489936878,
                                49.25150191052779
                            ],
                            [
                                4.021602089446674,
                                49.25059051051804
                            ],
                            [
                                4.023943279265666,
                                49.24895639686673
                            ],
                            [
                                4.023583638661336,
                                49.24881369705051
                            ],
                            [
                                4.0261676943483735,
                                49.24714703250103
                            ],
                            [
                                4.027345341034703,
                                49.24639207209353
                            ],
                            [
                                4.027964845088178,
                                49.24568872118468
                            ],
                            [
                                4.028042414629368,
                                49.24552299426256
                            ],
                            [
                                4.027922534428342,
                                49.24520074587713
                            ],
                            [
                                4.027238512102144,
                                49.24487389179518
                            ],
                            [
                                4.026603852211025,
                                49.24466672963729
                            ],
                            [
                                4.02726671920837,
                                49.244155725931336
                            ],
                            [
                                4.02834564102244,
                                49.24476340541921
                            ],
                            [
                                4.028726436956674,
                                49.244915324122616
                            ],
                            [
                                4.029177750656942,
                                49.24459767539139
                            ],
                            [
                                4.03057424560896,
                                49.24309199015539
                            ],
                            [
                                4.032393911769958,
                                49.24109061970702
                            ],
                            [
                                4.0341742688726185,
                                49.23920466935954
                            ],
                            [
                                4.035887850576728,
                                49.237597804508255
                            ],
                            [
                                4.0374449006191355,
                                49.23624228255747
                            ],
                            [
                                4.038298165582461,
                                49.23541808834213
                            ],
                            [
                                4.039038948068338,
                                49.23453724272733
                            ],
                            [
                                4.0389841007747975,
                                49.233812330650096
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "fr--13",
            "properties": {
                "land": "fr",
                "city": "Saint-Etienne",
                "zone": "ZFE-m_onlyHGV",
                "area_code": 5,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-III (diesel)",
                "minEUd": 3,
                "petrol": "min. EURO-III (petrol)",
                "minEUb": 3,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 5, 4, 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 0,
                "urlZone": "https://www.saint-etienne-metropole.fr/preserver-recycler/qualite-de-lair/zone-faibles-emissions-mobilite",
                "urlPark": "https://www.saint-etienne-metropole.fr/habiter-se-deplacer/deplacement-et-mobilite/en-voiture#les-parcs-relais",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.405242125475667,
                            45.45926377377569
                        ],
                        [
                            4.404418848972512,
                            45.459806657718104
                        ],
                        [
                            4.401941253073375,
                            45.461635269449886
                        ],
                        [
                            4.398547343625751,
                            45.46404093509932
                        ],
                        [
                            4.398362836232735,
                            45.46377270160036
                        ],
                        [
                            4.3979163892919075,
                            45.46384107150163
                        ],
                        [
                            4.397217094714222,
                            45.4644020932698
                        ],
                        [
                            4.396866404323134,
                            45.46519924061812
                        ],
                        [
                            4.395799252126807,
                            45.465738997692085
                        ],
                        [
                            4.394512298837753,
                            45.46607701289872
                        ],
                        [
                            4.393068564565908,
                            45.46607276700294
                        ],
                        [
                            4.389854214234816,
                            45.46530795116814
                        ],
                        [
                            4.385397175261346,
                            45.46421157551134
                        ],
                        [
                            4.383074743234971,
                            45.463457047568085
                        ],
                        [
                            4.38172294093593,
                            45.463488755156305
                        ],
                        [
                            4.381229804978972,
                            45.46365295199891
                        ],
                        [
                            4.380525103243514,
                            45.46415043492274
                        ],
                        [
                            4.379288299240898,
                            45.4652049046897
                        ],
                        [
                            4.3774462600955815,
                            45.466921428858626
                        ],
                        [
                            4.376721171298215,
                            45.46746633443482
                        ],
                        [
                            4.375223849272288,
                            45.46814951690203
                        ],
                        [
                            4.373549430632352,
                            45.468313180794325
                        ],
                        [
                            4.372709420635175,
                            45.46832658251881
                        ],
                        [
                            4.372793459296247,
                            45.467889351586535
                        ],
                        [
                            4.3725282707446524,
                            45.467602817234166
                        ],
                        [
                            4.372000092157748,
                            45.46761943913248
                        ],
                        [
                            4.3716893412891125,
                            45.467893748388036
                        ],
                        [
                            4.370497864424408,
                            45.46811450970429
                        ],
                        [
                            4.3682514044969025,
                            45.46859204504162
                        ],
                        [
                            4.366971981129012,
                            45.46912375555107
                        ],
                        [
                            4.365612546371523,
                            45.46983652261633
                        ],
                        [
                            4.363456048997477,
                            45.47180863614355
                        ],
                        [
                            4.360652372321823,
                            45.47420309279704
                        ],
                        [
                            4.358690309193207,
                            45.47547454091395
                        ],
                        [
                            4.35685377052485,
                            45.47631471687956
                        ],
                        [
                            4.355141751320787,
                            45.47651281978543
                        ],
                        [
                            4.353025087969684,
                            45.47716550971512
                        ],
                        [
                            4.35106729893738,
                            45.4780292645427
                        ],
                        [
                            4.349704758456738,
                            45.478624609343086
                        ],
                        [
                            4.348561895982215,
                            45.47873483428606
                        ],
                        [
                            4.347812393928024,
                            45.478323068071376
                        ],
                        [
                            4.347151801969858,
                            45.47743288510304
                        ],
                        [
                            4.346679776963526,
                            45.47665280272264
                        ],
                        [
                            4.346638357240039,
                            45.47622249168555
                        ],
                        [
                            4.347153811646592,
                            45.47598241273744
                        ],
                        [
                            4.347272024617581,
                            45.47551818477345
                        ],
                        [
                            4.346128894418484,
                            45.47486354094721
                        ],
                        [
                            4.3458716895201235,
                            45.47440883882987
                        ],
                        [
                            4.345839528234336,
                            45.47293759360505
                        ],
                        [
                            4.34576613118557,
                            45.471455589692
                        ],
                        [
                            4.345034810118278,
                            45.469685500968694
                        ],
                        [
                            4.344482148950874,
                            45.46831252045223
                        ],
                        [
                            4.343507294188981,
                            45.46703450299714
                        ],
                        [
                            4.342534041171916,
                            45.46630128426631
                        ],
                        [
                            4.343047458661829,
                            45.46586889741255
                        ],
                        [
                            4.342872009517237,
                            45.46552809174466
                        ],
                        [
                            4.342159311847382,
                            45.46516928970664
                        ],
                        [
                            4.341521559622123,
                            45.465156717053674
                        ],
                        [
                            4.340942639503936,
                            45.465567326420796
                        ],
                        [
                            4.340039698440961,
                            45.46573916600539
                        ],
                        [
                            4.339016073150816,
                            45.465308676806416
                        ],
                        [
                            4.337772397307759,
                            45.464429029634886
                        ],
                        [
                            4.337106455342621,
                            45.46344696041976
                        ],
                        [
                            4.336948840721021,
                            45.46220685853166
                        ],
                        [
                            4.337357683844061,
                            45.46110870931267
                        ],
                        [
                            4.338194583459654,
                            45.45985570566171
                        ],
                        [
                            4.338753474479802,
                            45.458738068049286
                        ],
                        [
                            4.339038383910349,
                            45.45698983280042
                        ],
                        [
                            4.338831157903542,
                            45.45585390811135
                        ],
                        [
                            4.338446024886224,
                            45.45407713636047
                        ],
                        [
                            4.3376786270206935,
                            45.45108962712118
                        ],
                        [
                            4.337530608980103,
                            45.44992243545349
                        ],
                        [
                            4.337629739662248,
                            45.449071611542735
                        ],
                        [
                            4.338402113127472,
                            45.44744854587239
                        ],
                        [
                            4.3397704672869395,
                            45.445864541463095
                        ],
                        [
                            4.340818880649238,
                            45.44504283920003
                        ],
                        [
                            4.342323534714211,
                            45.443520161362926
                        ],
                        [
                            4.343046090157571,
                            45.4421551989507
                        ],
                        [
                            4.343508011089625,
                            45.4410325030137
                        ],
                        [
                            4.343020568617476,
                            45.44031110387607
                        ],
                        [
                            4.342515459124371,
                            45.43978832406387
                        ],
                        [
                            4.341925450511297,
                            45.44008574369258
                        ],
                        [
                            4.34169828904885,
                            45.43953018109846
                        ],
                        [
                            4.341728772900808,
                            45.438297916770836
                        ],
                        [
                            4.342126275138696,
                            45.43730900013608
                        ],
                        [
                            4.342970804577789,
                            45.43655651460301
                        ],
                        [
                            4.344014927246718,
                            45.43587761761799
                        ],
                        [
                            4.345452092741624,
                            45.4347685515302
                        ],
                        [
                            4.3463711769493045,
                            45.434159273182075
                        ],
                        [
                            4.346912519574687,
                            45.433858422723915
                        ],
                        [
                            4.346896351247693,
                            45.43286199750111
                        ],
                        [
                            4.347275857475552,
                            45.43193373853805
                        ],
                        [
                            4.348093002834133,
                            45.43073630641376
                        ],
                        [
                            4.349172939013016,
                            45.429868999001314
                        ],
                        [
                            4.350345471584177,
                            45.429290578990845
                        ],
                        [
                            4.351657524721446,
                            45.42909261866558
                        ],
                        [
                            4.353395749274199,
                            45.42874985905553
                        ],
                        [
                            4.3553636343406765,
                            45.427911558594076
                        ],
                        [
                            4.358670357148156,
                            45.426399683048345
                        ],
                        [
                            4.361013399529952,
                            45.4252062320912
                        ],
                        [
                            4.36319383638255,
                            45.424316417707246
                        ],
                        [
                            4.365268549459159,
                            45.42365418192753
                        ],
                        [
                            4.365808298455335,
                            45.42434876980565
                        ],
                        [
                            4.366667015605572,
                            45.42437114346944
                        ],
                        [
                            4.367423248763714,
                            45.42333535300753
                        ],
                        [
                            4.367219205317127,
                            45.42239164944081
                        ],
                        [
                            4.369065326486748,
                            45.420415055174885
                        ],
                        [
                            4.371500558531409,
                            45.41785192861752
                        ],
                        [
                            4.3732983273115735,
                            45.4160489206416
                        ],
                        [
                            4.374512326464327,
                            45.41536275295246
                        ],
                        [
                            4.375298585490384,
                            45.41463464972796
                        ],
                        [
                            4.3748888356415705,
                            45.41428656722033
                        ],
                        [
                            4.3752940355742,
                            45.413790297291484
                        ],
                        [
                            4.376395714774674,
                            45.41189491364801
                        ],
                        [
                            4.376885601155465,
                            45.41022053315905
                        ],
                        [
                            4.377096295176017,
                            45.408329207630544
                        ],
                        [
                            4.37685088308811,
                            45.40596899466311
                        ],
                        [
                            4.376927597848692,
                            45.40529335348029
                        ],
                        [
                            4.377217655900125,
                            45.40515772181675
                        ],
                        [
                            4.378579168831465,
                            45.405526589158455
                        ],
                        [
                            4.379941163019197,
                            45.40616029768961
                        ],
                        [
                            4.3812507925247814,
                            45.406901262886294
                        ],
                        [
                            4.382520704409956,
                            45.40740773332183
                        ],
                        [
                            4.383618403859771,
                            45.40768354689479
                        ],
                        [
                            4.3852184422235325,
                            45.407731741316326
                        ],
                        [
                            4.386742061070862,
                            45.40764966225501
                        ],
                        [
                            4.388183243666134,
                            45.40781606079295
                        ],
                        [
                            4.390078100598458,
                            45.40877848173187
                        ],
                        [
                            4.391258899240825,
                            45.40934685089428
                        ],
                        [
                            4.39478069387107,
                            45.41059011201037
                        ],
                        [
                            4.396051605535433,
                            45.410968629044845
                        ],
                        [
                            4.397616645928736,
                            45.41106498706583
                        ],
                        [
                            4.398965111012473,
                            45.41132959743268
                        ],
                        [
                            4.401970287829272,
                            45.413608565458105
                        ],
                        [
                            4.403940727274033,
                            45.415365235861884
                        ],
                        [
                            4.40565797028998,
                            45.41673426087431
                        ],
                        [
                            4.407957428060882,
                            45.41855551003013
                        ],
                        [
                            4.409872468075321,
                            45.42008682036101
                        ],
                        [
                            4.410441742218524,
                            45.42068919369771
                        ],
                        [
                            4.410666958982915,
                            45.42114382144379
                        ],
                        [
                            4.411474600264455,
                            45.42157969434675
                        ],
                        [
                            4.412444882429298,
                            45.4226646140184
                        ],
                        [
                            4.41323512929273,
                            45.423863411643794
                        ],
                        [
                            4.414923487729254,
                            45.426420648163
                        ],
                        [
                            4.4156728694263165,
                            45.42808418720858
                        ],
                        [
                            4.415957253571918,
                            45.43015257568911
                        ],
                        [
                            4.416231811451496,
                            45.43182851159489
                        ],
                        [
                            4.416579996904744,
                            45.43276152371064
                        ],
                        [
                            4.417470159648616,
                            45.43370582736114
                        ],
                        [
                            4.419168841534599,
                            45.434220450330315
                        ],
                        [
                            4.420183044536628,
                            45.434400959245636
                        ],
                        [
                            4.420999103767485,
                            45.43438342990362
                        ],
                        [
                            4.422182922411423,
                            45.43409472384087
                        ],
                        [
                            4.424892571916388,
                            45.433230181461056
                        ],
                        [
                            4.426657748274238,
                            45.43320810594648
                        ],
                        [
                            4.428287131114786,
                            45.43375457329489
                        ],
                        [
                            4.429640270233364,
                            45.43466362704157
                        ],
                        [
                            4.429723801088585,
                            45.43490922805907
                        ],
                        [
                            4.431109131538221,
                            45.435388835368116
                        ],
                        [
                            4.431300212844718,
                            45.43528714560827
                        ],
                        [
                            4.432557242775971,
                            45.435988742471864
                        ],
                        [
                            4.433096190402949,
                            45.43664096831773
                        ],
                        [
                            4.433281886493603,
                            45.43753010104686
                        ],
                        [
                            4.433105752389139,
                            45.43869884339813
                        ],
                        [
                            4.432624760799342,
                            45.4397597688982
                        ],
                        [
                            4.431574468956825,
                            45.44089604994193
                        ],
                        [
                            4.431260341557163,
                            45.44081950188641
                        ],
                        [
                            4.430344023252246,
                            45.44093449974332
                        ],
                        [
                            4.4297615923455,
                            45.44129818811015
                        ],
                        [
                            4.4296852882380335,
                            45.441625611427696
                        ],
                        [
                            4.429704414738325,
                            45.44192723516761
                        ],
                        [
                            4.429736800473819,
                            45.4425541183538
                        ],
                        [
                            4.429498579588028,
                            45.44291252411455
                        ],
                        [
                            4.4283169724429285,
                            45.443999894850606
                        ],
                        [
                            4.42705469235338,
                            45.444175052965505
                        ],
                        [
                            4.4272133528489235,
                            45.444548208132375
                        ],
                        [
                            4.427092910485015,
                            45.44482921630825
                        ],
                        [
                            4.42609152982962,
                            45.4458842367585
                        ],
                        [
                            4.422972224393675,
                            45.448176204081534
                        ],
                        [
                            4.418070415226936,
                            45.451389536476455
                        ],
                        [
                            4.412629829932939,
                            45.45467243712062
                        ],
                        [
                            4.411981108043165,
                            45.45433538084785
                        ],
                        [
                            4.411623380856014,
                            45.45444490022882
                        ],
                        [
                            4.411404696266857,
                            45.45493839031718
                        ],
                        [
                            4.411511255347364,
                            45.45538566871576
                        ],
                        [
                            4.407431345420321,
                            45.45788134605169
                        ],
                        [
                            4.406161692530219,
                            45.45764879167464
                        ],
                        [
                            4.405398611101049,
                            45.4575314718808
                        ],
                        [
                            4.405087718128129,
                            45.45783231200946
                        ],
                        [
                            4.40524459381146,
                            45.4583484433615
                        ],
                        [
                            4.405242125475667,
                            45.45926377377569
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "fr--13",
            "properties": {
                "land": "fr",
                "city": "Clermont",
                "zone": "ZFE-m_onlyHGV",
                "area_code": 5,
                "time": "Start from July 1, 2023",
                "commercial": 1,
                "diesel": "min. EURO-II (diesel)",
                "minEUd": 2,
                "petrol": "-(petrol)",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 5, 4, 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 0,
                "urlZone": "https://www.saint-etienne-metropole.fr/preserver-recycler/qualite-de-lair/zone-faibles-emissions-mobilite",
                "urlPark": "https://www.saint-etienne-metropole.fr/habiter-se-deplacer/deplacement-et-mobilite/en-voiture#les-parcs-relais",
                "urlReg": "https://mieuxrespirerenville.gouv.fr/",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.405242125475667,
                            45.45926377377569
                        ],
                        [
                            4.404418848972512,
                            45.459806657718104
                        ],
                        [
                            4.401941253073375,
                            45.461635269449886
                        ],
                        [
                            4.398547343625751,
                            45.46404093509932
                        ],
                        [
                            4.398362836232735,
                            45.46377270160036
                        ],
                        [
                            4.3979163892919075,
                            45.46384107150163
                        ],
                        [
                            4.397217094714222,
                            45.4644020932698
                        ],
                        [
                            4.396866404323134,
                            45.46519924061812
                        ],
                        [
                            4.395799252126807,
                            45.465738997692085
                        ],
                        [
                            4.394512298837753,
                            45.46607701289872
                        ],
                        [
                            4.393068564565908,
                            45.46607276700294
                        ],
                        [
                            4.389854214234816,
                            45.46530795116814
                        ],
                        [
                            4.385397175261346,
                            45.46421157551134
                        ],
                        [
                            4.383074743234971,
                            45.463457047568085
                        ],
                        [
                            4.38172294093593,
                            45.463488755156305
                        ],
                        [
                            4.381229804978972,
                            45.46365295199891
                        ],
                        [
                            4.380525103243514,
                            45.46415043492274
                        ],
                        [
                            4.379288299240898,
                            45.4652049046897
                        ],
                        [
                            4.3774462600955815,
                            45.466921428858626
                        ],
                        [
                            4.376721171298215,
                            45.46746633443482
                        ],
                        [
                            4.375223849272288,
                            45.46814951690203
                        ],
                        [
                            4.373549430632352,
                            45.468313180794325
                        ],
                        [
                            4.372709420635175,
                            45.46832658251881
                        ],
                        [
                            4.372793459296247,
                            45.467889351586535
                        ],
                        [
                            4.3725282707446524,
                            45.467602817234166
                        ],
                        [
                            4.372000092157748,
                            45.46761943913248
                        ],
                        [
                            4.3716893412891125,
                            45.467893748388036
                        ],
                        [
                            4.370497864424408,
                            45.46811450970429
                        ],
                        [
                            4.3682514044969025,
                            45.46859204504162
                        ],
                        [
                            4.366971981129012,
                            45.46912375555107
                        ],
                        [
                            4.365612546371523,
                            45.46983652261633
                        ],
                        [
                            4.363456048997477,
                            45.47180863614355
                        ],
                        [
                            4.360652372321823,
                            45.47420309279704
                        ],
                        [
                            4.358690309193207,
                            45.47547454091395
                        ],
                        [
                            4.35685377052485,
                            45.47631471687956
                        ],
                        [
                            4.355141751320787,
                            45.47651281978543
                        ],
                        [
                            4.353025087969684,
                            45.47716550971512
                        ],
                        [
                            4.35106729893738,
                            45.4780292645427
                        ],
                        [
                            4.349704758456738,
                            45.478624609343086
                        ],
                        [
                            4.348561895982215,
                            45.47873483428606
                        ],
                        [
                            4.347812393928024,
                            45.478323068071376
                        ],
                        [
                            4.347151801969858,
                            45.47743288510304
                        ],
                        [
                            4.346679776963526,
                            45.47665280272264
                        ],
                        [
                            4.346638357240039,
                            45.47622249168555
                        ],
                        [
                            4.347153811646592,
                            45.47598241273744
                        ],
                        [
                            4.347272024617581,
                            45.47551818477345
                        ],
                        [
                            4.346128894418484,
                            45.47486354094721
                        ],
                        [
                            4.3458716895201235,
                            45.47440883882987
                        ],
                        [
                            4.345839528234336,
                            45.47293759360505
                        ],
                        [
                            4.34576613118557,
                            45.471455589692
                        ],
                        [
                            4.345034810118278,
                            45.469685500968694
                        ],
                        [
                            4.344482148950874,
                            45.46831252045223
                        ],
                        [
                            4.343507294188981,
                            45.46703450299714
                        ],
                        [
                            4.342534041171916,
                            45.46630128426631
                        ],
                        [
                            4.343047458661829,
                            45.46586889741255
                        ],
                        [
                            4.342872009517237,
                            45.46552809174466
                        ],
                        [
                            4.342159311847382,
                            45.46516928970664
                        ],
                        [
                            4.341521559622123,
                            45.465156717053674
                        ],
                        [
                            4.340942639503936,
                            45.465567326420796
                        ],
                        [
                            4.340039698440961,
                            45.46573916600539
                        ],
                        [
                            4.339016073150816,
                            45.465308676806416
                        ],
                        [
                            4.337772397307759,
                            45.464429029634886
                        ],
                        [
                            4.337106455342621,
                            45.46344696041976
                        ],
                        [
                            4.336948840721021,
                            45.46220685853166
                        ],
                        [
                            4.337357683844061,
                            45.46110870931267
                        ],
                        [
                            4.338194583459654,
                            45.45985570566171
                        ],
                        [
                            4.338753474479802,
                            45.458738068049286
                        ],
                        [
                            4.339038383910349,
                            45.45698983280042
                        ],
                        [
                            4.338831157903542,
                            45.45585390811135
                        ],
                        [
                            4.338446024886224,
                            45.45407713636047
                        ],
                        [
                            4.3376786270206935,
                            45.45108962712118
                        ],
                        [
                            4.337530608980103,
                            45.44992243545349
                        ],
                        [
                            4.337629739662248,
                            45.449071611542735
                        ],
                        [
                            4.338402113127472,
                            45.44744854587239
                        ],
                        [
                            4.3397704672869395,
                            45.445864541463095
                        ],
                        [
                            4.340818880649238,
                            45.44504283920003
                        ],
                        [
                            4.342323534714211,
                            45.443520161362926
                        ],
                        [
                            4.343046090157571,
                            45.4421551989507
                        ],
                        [
                            4.343508011089625,
                            45.4410325030137
                        ],
                        [
                            4.343020568617476,
                            45.44031110387607
                        ],
                        [
                            4.342515459124371,
                            45.43978832406387
                        ],
                        [
                            4.341925450511297,
                            45.44008574369258
                        ],
                        [
                            4.34169828904885,
                            45.43953018109846
                        ],
                        [
                            4.341728772900808,
                            45.438297916770836
                        ],
                        [
                            4.342126275138696,
                            45.43730900013608
                        ],
                        [
                            4.342970804577789,
                            45.43655651460301
                        ],
                        [
                            4.344014927246718,
                            45.43587761761799
                        ],
                        [
                            4.345452092741624,
                            45.4347685515302
                        ],
                        [
                            4.3463711769493045,
                            45.434159273182075
                        ],
                        [
                            4.346912519574687,
                            45.433858422723915
                        ],
                        [
                            4.346896351247693,
                            45.43286199750111
                        ],
                        [
                            4.347275857475552,
                            45.43193373853805
                        ],
                        [
                            4.348093002834133,
                            45.43073630641376
                        ],
                        [
                            4.349172939013016,
                            45.429868999001314
                        ],
                        [
                            4.350345471584177,
                            45.429290578990845
                        ],
                        [
                            4.351657524721446,
                            45.42909261866558
                        ],
                        [
                            4.353395749274199,
                            45.42874985905553
                        ],
                        [
                            4.3553636343406765,
                            45.427911558594076
                        ],
                        [
                            4.358670357148156,
                            45.426399683048345
                        ],
                        [
                            4.361013399529952,
                            45.4252062320912
                        ],
                        [
                            4.36319383638255,
                            45.424316417707246
                        ],
                        [
                            4.365268549459159,
                            45.42365418192753
                        ],
                        [
                            4.365808298455335,
                            45.42434876980565
                        ],
                        [
                            4.366667015605572,
                            45.42437114346944
                        ],
                        [
                            4.367423248763714,
                            45.42333535300753
                        ],
                        [
                            4.367219205317127,
                            45.42239164944081
                        ],
                        [
                            4.369065326486748,
                            45.420415055174885
                        ],
                        [
                            4.371500558531409,
                            45.41785192861752
                        ],
                        [
                            4.3732983273115735,
                            45.4160489206416
                        ],
                        [
                            4.374512326464327,
                            45.41536275295246
                        ],
                        [
                            4.375298585490384,
                            45.41463464972796
                        ],
                        [
                            4.3748888356415705,
                            45.41428656722033
                        ],
                        [
                            4.3752940355742,
                            45.413790297291484
                        ],
                        [
                            4.376395714774674,
                            45.41189491364801
                        ],
                        [
                            4.376885601155465,
                            45.41022053315905
                        ],
                        [
                            4.377096295176017,
                            45.408329207630544
                        ],
                        [
                            4.37685088308811,
                            45.40596899466311
                        ],
                        [
                            4.376927597848692,
                            45.40529335348029
                        ],
                        [
                            4.377217655900125,
                            45.40515772181675
                        ],
                        [
                            4.378579168831465,
                            45.405526589158455
                        ],
                        [
                            4.379941163019197,
                            45.40616029768961
                        ],
                        [
                            4.3812507925247814,
                            45.406901262886294
                        ],
                        [
                            4.382520704409956,
                            45.40740773332183
                        ],
                        [
                            4.383618403859771,
                            45.40768354689479
                        ],
                        [
                            4.3852184422235325,
                            45.407731741316326
                        ],
                        [
                            4.386742061070862,
                            45.40764966225501
                        ],
                        [
                            4.388183243666134,
                            45.40781606079295
                        ],
                        [
                            4.390078100598458,
                            45.40877848173187
                        ],
                        [
                            4.391258899240825,
                            45.40934685089428
                        ],
                        [
                            4.39478069387107,
                            45.41059011201037
                        ],
                        [
                            4.396051605535433,
                            45.410968629044845
                        ],
                        [
                            4.397616645928736,
                            45.41106498706583
                        ],
                        [
                            4.398965111012473,
                            45.41132959743268
                        ],
                        [
                            4.401970287829272,
                            45.413608565458105
                        ],
                        [
                            4.403940727274033,
                            45.415365235861884
                        ],
                        [
                            4.40565797028998,
                            45.41673426087431
                        ],
                        [
                            4.407957428060882,
                            45.41855551003013
                        ],
                        [
                            4.409872468075321,
                            45.42008682036101
                        ],
                        [
                            4.410441742218524,
                            45.42068919369771
                        ],
                        [
                            4.410666958982915,
                            45.42114382144379
                        ],
                        [
                            4.411474600264455,
                            45.42157969434675
                        ],
                        [
                            4.412444882429298,
                            45.4226646140184
                        ],
                        [
                            4.41323512929273,
                            45.423863411643794
                        ],
                        [
                            4.414923487729254,
                            45.426420648163
                        ],
                        [
                            4.4156728694263165,
                            45.42808418720858
                        ],
                        [
                            4.415957253571918,
                            45.43015257568911
                        ],
                        [
                            4.416231811451496,
                            45.43182851159489
                        ],
                        [
                            4.416579996904744,
                            45.43276152371064
                        ],
                        [
                            4.417470159648616,
                            45.43370582736114
                        ],
                        [
                            4.419168841534599,
                            45.434220450330315
                        ],
                        [
                            4.420183044536628,
                            45.434400959245636
                        ],
                        [
                            4.420999103767485,
                            45.43438342990362
                        ],
                        [
                            4.422182922411423,
                            45.43409472384087
                        ],
                        [
                            4.424892571916388,
                            45.433230181461056
                        ],
                        [
                            4.426657748274238,
                            45.43320810594648
                        ],
                        [
                            4.428287131114786,
                            45.43375457329489
                        ],
                        [
                            4.429640270233364,
                            45.43466362704157
                        ],
                        [
                            4.429723801088585,
                            45.43490922805907
                        ],
                        [
                            4.431109131538221,
                            45.435388835368116
                        ],
                        [
                            4.431300212844718,
                            45.43528714560827
                        ],
                        [
                            4.432557242775971,
                            45.435988742471864
                        ],
                        [
                            4.433096190402949,
                            45.43664096831773
                        ],
                        [
                            4.433281886493603,
                            45.43753010104686
                        ],
                        [
                            4.433105752389139,
                            45.43869884339813
                        ],
                        [
                            4.432624760799342,
                            45.4397597688982
                        ],
                        [
                            4.431574468956825,
                            45.44089604994193
                        ],
                        [
                            4.431260341557163,
                            45.44081950188641
                        ],
                        [
                            4.430344023252246,
                            45.44093449974332
                        ],
                        [
                            4.4297615923455,
                            45.44129818811015
                        ],
                        [
                            4.4296852882380335,
                            45.441625611427696
                        ],
                        [
                            4.429704414738325,
                            45.44192723516761
                        ],
                        [
                            4.429736800473819,
                            45.4425541183538
                        ],
                        [
                            4.429498579588028,
                            45.44291252411455
                        ],
                        [
                            4.4283169724429285,
                            45.443999894850606
                        ],
                        [
                            4.42705469235338,
                            45.444175052965505
                        ],
                        [
                            4.4272133528489235,
                            45.444548208132375
                        ],
                        [
                            4.427092910485015,
                            45.44482921630825
                        ],
                        [
                            4.42609152982962,
                            45.4458842367585
                        ],
                        [
                            4.422972224393675,
                            45.448176204081534
                        ],
                        [
                            4.418070415226936,
                            45.451389536476455
                        ],
                        [
                            4.412629829932939,
                            45.45467243712062
                        ],
                        [
                            4.411981108043165,
                            45.45433538084785
                        ],
                        [
                            4.411623380856014,
                            45.45444490022882
                        ],
                        [
                            4.411404696266857,
                            45.45493839031718
                        ],
                        [
                            4.411511255347364,
                            45.45538566871576
                        ],
                        [
                            4.407431345420321,
                            45.45788134605169
                        ],
                        [
                            4.406161692530219,
                            45.45764879167464
                        ],
                        [
                            4.405398611101049,
                            45.4575314718808
                        ],
                        [
                            4.405087718128129,
                            45.45783231200946
                        ],
                        [
                            4.40524459381146,
                            45.4583484433615
                        ],
                        [
                            4.405242125475667,
                            45.45926377377569
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "it--01",
            "properties": {
                "land": "it",
                "city": "Milan",
                "zone": "Aera B",
                "area_code": 0,
                "time": "Mo-Fr: 7.30-19.30 (schema until 30/9/25)",
                "diesel": "min. EURO-6a/b/c (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Online Registration!!!",
                "img_sign": "sign-it--white",
                "color": 1,
                "urlZone": "https://www.comune.milano.it/aree-tematiche/mobilita/area-b/",
                "urlPark": "https://www.atm.it/it/AltriServizi/Auto/Pagine/ParcheggiStruttura.aspx",
                "urlReg": "https://areab.atm.it/en-GB",
                "urlBadge": "https://www.atm.it/it/AltriServizi/Auto/Pagine/AreaC.aspx"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.092409610748291,
                            45.50508367930963
                        ],
                        [
                            9.09191608428955,
                            45.504677637719396
                        ],
                        [
                            9.090628623962402,
                            45.501459278555686
                        ],
                        [
                            9.089727401733398,
                            45.49875210502611
                        ],
                        [
                            9.089963436126709,
                            45.49842121933467
                        ],
                        [
                            9.090070724487303,
                            45.49347274225822
                        ],
                        [
                            9.091014862060545,
                            45.4873354250324
                        ],
                        [
                            9.090135097503662,
                            45.483198375594675
                        ],
                        [
                            9.089319705963135,
                            45.48300279846421
                        ],
                        [
                            9.089276790618896,
                            45.4781883781261
                        ],
                        [
                            9.087646007537842,
                            45.477947646310284
                        ],
                        [
                            9.085843563079834,
                            45.4773458122711
                        ],
                        [
                            9.084234237670898,
                            45.47650323381734
                        ],
                        [
                            9.083805084228516,
                            45.47611203239544
                        ],
                        [
                            9.08318281173706,
                            45.474742806031884
                        ],
                        [
                            9.081487655639648,
                            45.47380990747654
                        ],
                        [
                            9.080522060394287,
                            45.473057558683
                        ],
                        [
                            9.080092906951904,
                            45.472846899220976
                        ],
                        [
                            9.080028533935547,
                            45.471342165878326
                        ],
                        [
                            9.078762531280518,
                            45.468588399830615
                        ],
                        [
                            9.0767240524292,
                            45.46417908441834
                        ],
                        [
                            9.081487655639648,
                            45.46443492443043
                        ],
                        [
                            9.082646369934082,
                            45.46315571275936
                        ],
                        [
                            9.085693359375,
                            45.46381789654229
                        ],
                        [
                            9.08616542816162,
                            45.46320086190073
                        ],
                        [
                            9.084105491638184,
                            45.46261392024268
                        ],
                        [
                            9.082174301147461,
                            45.46234302203183
                        ],
                        [
                            9.080500602722168,
                            45.46390819372825
                        ],
                        [
                            9.076638221740723,
                            45.46348680562306
                        ],
                        [
                            9.077346324920654,
                            45.46076275652555
                        ],
                        [
                            9.078118801116943,
                            45.45976942410796
                        ],
                        [
                            9.07869815826416,
                            45.458068677974076
                        ],
                        [
                            9.080049991607666,
                            45.4578579624921
                        ],
                        [
                            9.086487293243408,
                            45.45573571266526
                        ],
                        [
                            9.09268856048584,
                            45.45450145972176
                        ],
                        [
                            9.098482131958008,
                            45.452108136186936
                        ],
                        [
                            9.101357460021973,
                            45.45219845212662
                        ],
                        [
                            9.105391502380371,
                            45.45420041832061
                        ],
                        [
                            9.11339521408081,
                            45.45573571266526
                        ],
                        [
                            9.116485118865967,
                            45.45632272592734
                        ],
                        [
                            9.115798473358154,
                            45.45414020984753
                        ],
                        [
                            9.118995666503906,
                            45.45120496883528
                        ],
                        [
                            9.119424819946289,
                            45.44945880427777
                        ],
                        [
                            9.121849536895752,
                            45.4459060957197
                        ],
                        [
                            9.124059677124023,
                            45.443000536383984
                        ],
                        [
                            9.125497341156006,
                            45.43935708471715
                        ],
                        [
                            9.126720428466797,
                            45.437143798795546
                        ],
                        [
                            9.128437042236328,
                            45.43773100556224
                        ],
                        [
                            9.131247997283936,
                            45.43419261585387
                        ],
                        [
                            9.133028984069824,
                            45.43443353453236
                        ],
                        [
                            9.135839939117432,
                            45.430789529655954
                        ],
                        [
                            9.13813591003418,
                            45.42807896021842
                        ],
                        [
                            9.139466285705566,
                            45.42670856724186
                        ],
                        [
                            9.138844013214111,
                            45.425488738913636
                        ],
                        [
                            9.14113998413086,
                            45.422055006540425
                        ],
                        [
                            9.143993854522705,
                            45.422582128149564
                        ],
                        [
                            9.144272804260254,
                            45.42856084874316
                        ],
                        [
                            9.150581359863281,
                            45.42755188993389
                        ],
                        [
                            9.155044555664062,
                            45.42264237030581
                        ],
                        [
                            9.156053066253662,
                            45.42200982445922
                        ],
                        [
                            9.1571044921875,
                            45.421693548878274
                        ],
                        [
                            9.157383441925049,
                            45.423124305241636
                        ],
                        [
                            9.157748222351074,
                            45.423515874347714
                        ],
                        [
                            9.159078598022461,
                            45.42332009013417
                        ],
                        [
                            9.160451889038086,
                            45.42586523195071
                        ],
                        [
                            9.159679412841797,
                            45.426422437037395
                        ],
                        [
                            9.160387516021729,
                            45.42764224518933
                        ],
                        [
                            9.161524772644043,
                            45.427461534533826
                        ],
                        [
                            9.162940979003906,
                            45.4294944960526
                        ],
                        [
                            9.165644645690918,
                            45.431534913430966
                        ],
                        [
                            9.16735053062439,
                            45.43329292607204
                        ],
                        [
                            9.167747497558594,
                            45.434309310967194
                        ],
                        [
                            9.16683554649353,
                            45.435841382493344
                        ],
                        [
                            9.166996479034424,
                            45.43619898533882
                        ],
                        [
                            9.169968366622925,
                            45.43585267524943
                        ],
                        [
                            9.1703599691391,
                            45.437068515434795
                        ],
                        [
                            9.169995188713074,
                            45.437907919223576
                        ],
                        [
                            9.17135775089264,
                            45.43878495109859
                        ],
                        [
                            9.172226786613464,
                            45.4387887151545
                        ],
                        [
                            9.171899557113647,
                            45.43795308858015
                        ],
                        [
                            9.174377918243408,
                            45.43775735444059
                        ],
                        [
                            9.174265265464783,
                            45.43726801612117
                        ],
                        [
                            9.17203366756439,
                            45.437252959490195
                        ],
                        [
                            9.171148538589478,
                            45.4367523242229
                        ],
                        [
                            9.170156121253967,
                            45.434900311545114
                        ],
                        [
                            9.169378280639648,
                            45.43397051803605
                        ],
                        [
                            9.169662594795227,
                            45.43316869999615
                        ],
                        [
                            9.169399738311768,
                            45.43260779703677
                        ],
                        [
                            9.167296886444092,
                            45.43180972415719
                        ],
                        [
                            9.16432499885559,
                            45.42967143553584
                        ],
                        [
                            9.162769317626953,
                            45.42804507727672
                        ],
                        [
                            9.161556959152222,
                            45.42593676534388
                        ],
                        [
                            9.163911938667297,
                            45.42526284188612
                        ],
                        [
                            9.164705872535706,
                            45.425236487174026
                        ],
                        [
                            9.165279865264893,
                            45.4248261050697
                        ],
                        [
                            9.16608989238739,
                            45.423305029781936
                        ],
                        [
                            9.167554378509521,
                            45.42274402879871
                        ],
                        [
                            9.165773391723633,
                            45.419652775746115
                        ],
                        [
                            9.162640571594238,
                            45.41425303129724
                        ],
                        [
                            9.16532278060913,
                            45.41357520263221
                        ],
                        [
                            9.165086746215819,
                            45.41304799693478
                        ],
                        [
                            9.167811870574951,
                            45.41238521707537
                        ],
                        [
                            9.166417121887207,
                            45.40459697109925
                        ],
                        [
                            9.16658878326416,
                            45.40251791535519
                        ],
                        [
                            9.170022010803223,
                            45.40278910087822
                        ],
                        [
                            9.172811508178711,
                            45.4029246931516
                        ],
                        [
                            9.17283296585083,
                            45.4035875840258
                        ],
                        [
                            9.173262119293213,
                            45.403964223102754
                        ],
                        [
                            9.174935817718506,
                            45.40312054808307
                        ],
                        [
                            9.174871444702148,
                            45.40257817891726
                        ],
                        [
                            9.177725315093994,
                            45.40215633263292
                        ],
                        [
                            9.180729389190674,
                            45.39924852077677
                        ],
                        [
                            9.182488918304443,
                            45.398691047522796
                        ],
                        [
                            9.185342788696289,
                            45.39899238509984
                        ],
                        [
                            9.183368682861328,
                            45.40274390338145
                        ],
                        [
                            9.178690910339354,
                            45.40764008864347
                        ],
                        [
                            9.17959213256836,
                            45.41473503784269
                        ],
                        [
                            9.176995754241943,
                            45.41478022574541
                        ],
                        [
                            9.177017211914062,
                            45.415322477757584
                        ],
                        [
                            9.180536270141602,
                            45.41595509852431
                        ],
                        [
                            9.185471534729004,
                            45.4187114348669
                        ],
                        [
                            9.194462299346924,
                            45.42130196713284
                        ],
                        [
                            9.2035174369812,
                            45.41984104203872
                        ],
                        [
                            9.205191135406494,
                            45.41639190396519
                        ],
                        [
                            9.21130657196045,
                            45.41792822729503
                        ],
                        [
                            9.215683937072754,
                            45.421949581628006
                        ],
                        [
                            9.214825630187988,
                            45.4233351504824
                        ],
                        [
                            9.21675682067871,
                            45.42484116501617
                        ],
                        [
                            9.21622037887573,
                            45.42523272221415
                        ],
                        [
                            9.214653968811035,
                            45.427235645400835
                        ],
                        [
                            9.215276241302488,
                            45.42773260030017
                        ],
                        [
                            9.214890003204346,
                            45.429027674328324
                        ],
                        [
                            9.213409423828125,
                            45.42925355628993
                        ],
                        [
                            9.210448265075684,
                            45.4310154045667
                        ],
                        [
                            9.208967685699463,
                            45.431226220334516
                        ],
                        [
                            9.207487106323242,
                            45.433153642271385
                        ],
                        [
                            9.209182262420654,
                            45.43375594812766
                        ],
                        [
                            9.208946228027342,
                            45.43444859191563
                        ],
                        [
                            9.214439392089844,
                            45.43608982260523
                        ],
                        [
                            9.218602180480957,
                            45.432220055490674
                        ],
                        [
                            9.221692085266113,
                            45.430879879728714
                        ],
                        [
                            9.223816394805908,
                            45.4324158088372
                        ],
                        [
                            9.228579998016357,
                            45.43155750066439
                        ],
                        [
                            9.229137897491455,
                            45.430623887480216
                        ],
                        [
                            9.231605529785156,
                            45.43050342013797
                        ],
                        [
                            9.232399463653564,
                            45.433921581111214
                        ],
                        [
                            9.234802722930908,
                            45.433801120807765
                        ],
                        [
                            9.235231876373291,
                            45.43402698366581
                        ],
                        [
                            9.234652519226074,
                            45.4348852542824
                        ],
                        [
                            9.235167503356934,
                            45.43545742744176
                        ],
                        [
                            9.234437942504883,
                            45.43646624493309
                        ],
                        [
                            9.234459400177002,
                            45.43700828867391
                        ],
                        [
                            9.231905937194824,
                            45.44336185760955
                        ],
                        [
                            9.228944778442383,
                            45.444325369564424
                        ],
                        [
                            9.228923320770262,
                            45.44783301626615
                        ],
                        [
                            9.228601455688477,
                            45.44857064811582
                        ],
                        [
                            9.228966236114502,
                            45.44893193366566
                        ],
                        [
                            9.228923320770262,
                            45.45638293207027
                        ],
                        [
                            9.23201322555542,
                            45.45641303511761
                        ],
                        [
                            9.232099056243896,
                            45.45602169424856
                        ],
                        [
                            9.237463474273682,
                            45.45602169424856
                        ],
                        [
                            9.236733913421629,
                            45.450136202118934
                        ],
                        [
                            9.235038757324219,
                            45.44723086066625
                        ],
                        [
                            9.234673976898193,
                            45.44543940974865
                        ],
                        [
                            9.236669540405273,
                            45.44051639031444
                        ],
                        [
                            9.239695072174072,
                            45.432235113464515
                        ],
                        [
                            9.245467185974121,
                            45.42988602097337
                        ],
                        [
                            9.246196746826172,
                            45.43009684095962
                        ],
                        [
                            9.247827529907227,
                            45.42952461345063
                        ],
                        [
                            9.252676963806152,
                            45.43342468070219
                        ],
                        [
                            9.248299598693848,
                            45.436436131239255
                        ],
                        [
                            9.247527122497559,
                            45.43744493123411
                        ],
                        [
                            9.248342514038086,
                            45.43831820621825
                        ],
                        [
                            9.252676963806152,
                            45.43735459167128
                        ],
                        [
                            9.255638122558594,
                            45.43898068167359
                        ],
                        [
                            9.256818294525146,
                            45.44325647249114
                        ],
                        [
                            9.254887104034424,
                            45.44723086066625
                        ],
                        [
                            9.255402088165283,
                            45.448164199106145
                        ],
                        [
                            9.251024723052979,
                            45.45147592055937
                        ],
                        [
                            9.253427982330322,
                            45.45296613177457
                        ],
                        [
                            9.249823093414307,
                            45.45581097111728
                        ],
                        [
                            9.251561164855957,
                            45.45707529809363
                        ],
                        [
                            9.249715805053711,
                            45.460401546762164
                        ],
                        [
                            9.246068000793457,
                            45.461485169110084
                        ],
                        [
                            9.243428707122803,
                            45.46168082092546
                        ],
                        [
                            9.243342876434326,
                            45.462403221746726
                        ],
                        [
                            9.246218204498291,
                            45.46244837149073
                        ],
                        [
                            9.250402450561523,
                            45.46451017127185
                        ],
                        [
                            9.251625537872314,
                            45.46755005967148
                        ],
                        [
                            9.252011775970459,
                            45.472545955766435
                        ],
                        [
                            9.251818656921385,
                            45.47412589097481
                        ],
                        [
                            9.250874519348143,
                            45.477496271383515
                        ],
                        [
                            9.252655506134033,
                            45.477842325817434
                        ],
                        [
                            9.251368045806885,
                            45.48100185497031
                        ],
                        [
                            9.250402450561523,
                            45.48389041229689
                        ],
                        [
                            9.249780178070068,
                            45.48528950401066
                        ],
                        [
                            9.250316619873047,
                            45.485981515025316
                        ],
                        [
                            9.24997329711914,
                            45.486748734691346
                        ],
                        [
                            9.246110916137695,
                            45.48640273495911
                        ],
                        [
                            9.246025085449219,
                            45.48707968896834
                        ],
                        [
                            9.24724817276001,
                            45.487230122087816
                        ],
                        [
                            9.248042106628418,
                            45.488945031254566
                        ],
                        [
                            9.247441291809082,
                            45.49105098862575
                        ],
                        [
                            9.247570037841797,
                            45.49165267626906
                        ],
                        [
                            9.248878955841064,
                            45.49169780258319
                        ],
                        [
                            9.248921871185303,
                            45.4931869506652
                        ],
                        [
                            9.248578548431396,
                            45.498631783181466
                        ],
                        [
                            9.252462387084961,
                            45.50139912055804
                        ],
                        [
                            9.254736900329588,
                            45.50462124282247
                        ],
                        [
                            9.253025650978088,
                            45.50612884696815
                        ],
                        [
                            9.255117774009705,
                            45.50809882245714
                        ],
                        [
                            9.254822731018066,
                            45.50845220771363
                        ],
                        [
                            9.25574541091919,
                            45.50897852206792
                        ],
                        [
                            9.257183074951172,
                            45.50969279796287
                        ],
                        [
                            9.258282780647278,
                            45.510301804988025
                        ],
                        [
                            9.25796091556549,
                            45.511861885515145
                        ],
                        [
                            9.256904125213623,
                            45.51161002001986
                        ],
                        [
                            9.2549729347229,
                            45.51041834261825
                        ],
                        [
                            9.25047218799591,
                            45.50694466579189
                        ],
                        [
                            9.246674180030823,
                            45.504042251946515
                        ],
                        [
                            9.243514537811278,
                            45.501666071182335
                        ],
                        [
                            9.239716529846191,
                            45.49870698436449
                        ],
                        [
                            9.236610531806944,
                            45.49629297625651
                        ],
                        [
                            9.233885407447815,
                            45.49413456985284
                        ],
                        [
                            9.231675267219542,
                            45.492773302369834
                        ],
                        [
                            9.230312705039978,
                            45.49217538719141
                        ],
                        [
                            9.229363203048706,
                            45.491938475647856
                        ],
                        [
                            9.22926127910614,
                            45.49243486059563
                        ],
                        [
                            9.230913519859314,
                            45.49283723003216
                        ],
                        [
                            9.23450231552124,
                            45.49521002310559
                        ],
                        [
                            9.237297177314758,
                            45.49732702666802
                        ],
                        [
                            9.245027303695679,
                            45.50327526785122
                        ],
                        [
                            9.25371766090393,
                            45.50991459758064
                        ],
                        [
                            9.255428910255432,
                            45.51125289537055
                        ],
                        [
                            9.256796836853027,
                            45.512406963697956
                        ],
                        [
                            9.256453514099121,
                            45.51275280366972
                        ],
                        [
                            9.259135723114012,
                            45.51878210686962
                        ],
                        [
                            9.247612953186035,
                            45.51939853289016
                        ],
                        [
                            9.244029521942139,
                            45.51825588419192
                        ],
                        [
                            9.24299955368042,
                            45.517864972731275
                        ],
                        [
                            9.242677688598633,
                            45.51899259456274
                        ],
                        [
                            9.240853786468506,
                            45.519233150962165
                        ],
                        [
                            9.237656593322754,
                            45.51858665330667
                        ],
                        [
                            9.23722743988037,
                            45.517519164175404
                        ],
                        [
                            9.228343963623047,
                            45.5164817257566
                        ],
                        [
                            9.225640296936035,
                            45.516962858995775
                        ],
                        [
                            9.22677755355835,
                            45.520826811131926
                        ],
                        [
                            9.227120876312256,
                            45.52291654238294
                        ],
                        [
                            9.22596216201782,
                            45.52205961161822
                        ],
                        [
                            9.225618839263916,
                            45.521383078110446
                        ],
                        [
                            9.223902225494385,
                            45.5216837606738
                        ],
                        [
                            9.222400188446045,
                            45.52270606936925
                        ],
                        [
                            9.219632148742674,
                            45.51950377568219
                        ],
                        [
                            9.209160804748535,
                            45.5224053922695
                        ],
                        [
                            9.211864471435545,
                            45.527185967805266
                        ],
                        [
                            9.211177825927734,
                            45.52754674947172
                        ],
                        [
                            9.211585521697998,
                            45.52826830586263
                        ],
                        [
                            9.204869270324707,
                            45.52880946708162
                        ],
                        [
                            9.202873706817627,
                            45.525757851000876
                        ],
                        [
                            9.200642108917235,
                            45.52506632878312
                        ],
                        [
                            9.19682264328003,
                            45.52587811486665
                        ],
                        [
                            9.192960262298584,
                            45.526028444337314
                        ],
                        [
                            9.19281005859375,
                            45.52368325884547
                        ],
                        [
                            9.19057846069336,
                            45.524434931508075
                        ],
                        [
                            9.19032096862793,
                            45.525757851000876
                        ],
                        [
                            9.18881893157959,
                            45.52596831259726
                        ],
                        [
                            9.185128211975098,
                            45.52730622861787
                        ],
                        [
                            9.181115627288817,
                            45.52770459072315
                        ],
                        [
                            9.178948402404785,
                            45.528858321657644
                        ],
                        [
                            9.17635202407837,
                            45.52978655054006
                        ],
                        [
                            9.173240661621094,
                            45.53331894143264
                        ],
                        [
                            9.165215492248535,
                            45.53298825893188
                        ],
                        [
                            9.164979457855225,
                            45.52924539872167
                        ],
                        [
                            9.165666103363037,
                            45.52856895162705
                        ],
                        [
                            9.173948764801025,
                            45.522540697163244
                        ],
                        [
                            9.176437854766846,
                            45.52066143340208
                        ],
                        [
                            9.178047180175781,
                            45.51930832462607
                        ],
                        [
                            9.178733825683592,
                            45.51781986738799
                        ],
                        [
                            9.179736971855164,
                            45.5142902621671
                        ],
                        [
                            9.182982444763184,
                            45.5140985520305
                        ],
                        [
                            9.1828054189682,
                            45.51353469490245
                        ],
                        [
                            9.179130792617798,
                            45.513545972100346
                        ],
                        [
                            9.178229570388794,
                            45.51653434990499
                        ],
                        [
                            9.177612662315369,
                            45.5185152374124
                        ],
                        [
                            9.17607843875885,
                            45.520116435169975
                        ],
                        [
                            9.173251390457153,
                            45.521773965129455
                        ],
                        [
                            9.172961711883545,
                            45.52208592109031
                        ],
                        [
                            9.169254899024963,
                            45.52514525294423
                        ],
                        [
                            9.164818525314331,
                            45.528279580107814
                        ],
                        [
                            9.164158701896667,
                            45.52784364098244
                        ],
                        [
                            9.16381001472473,
                            45.527911286930255
                        ],
                        [
                            9.162640571594238,
                            45.53027132795617
                        ],
                        [
                            9.16211485862732,
                            45.53140621688414
                        ],
                        [
                            9.15736198425293,
                            45.53094399667422
                        ],
                        [
                            9.155709743499756,
                            45.52874933831439
                        ],
                        [
                            9.15635347366333,
                            45.526404266256364
                        ],
                        [
                            9.15259838104248,
                            45.52476566429786
                        ],
                        [
                            9.151611328125,
                            45.52073660515773
                        ],
                        [
                            9.155430793762207,
                            45.51836112912116
                        ],
                        [
                            9.15658950805664,
                            45.517173353494314
                        ],
                        [
                            9.157984256744385,
                            45.51380534702895
                        ],
                        [
                            9.15686845779419,
                            45.513654984897144
                        ],
                        [
                            9.15585994720459,
                            45.516962858995775
                        ],
                        [
                            9.153864383697508,
                            45.518526513612244
                        ],
                        [
                            9.148242473602295,
                            45.51899259456274
                        ],
                        [
                            9.146225452423096,
                            45.51863175803531
                        ],
                        [
                            9.143564701080322,
                            45.52114253090277
                        ],
                        [
                            9.137170314788818,
                            45.52002998668944
                        ],
                        [
                            9.136826992034912,
                            45.52102225691326
                        ],
                        [
                            9.134745597839355,
                            45.52091701696152
                        ],
                        [
                            9.134466648101807,
                            45.52219491734356
                        ],
                        [
                            9.12961721420288,
                            45.51987964118952
                        ],
                        [
                            9.124681949615479,
                            45.516827540688055
                        ],
                        [
                            9.12508964538574,
                            45.515444268211084
                        ],
                        [
                            9.124360084533691,
                            45.5142865031511
                        ],
                        [
                            9.129037857055662,
                            45.51073788004391
                        ],
                        [
                            9.130218029022217,
                            45.5095048315009
                        ],
                        [
                            9.131076335906982,
                            45.50583560044691
                        ],
                        [
                            9.13111925125122,
                            45.50279777737714
                        ],
                        [
                            9.130840301513672,
                            45.49968459060421
                        ],
                        [
                            9.131226539611816,
                            45.49924842991741
                        ],
                        [
                            9.130840301513672,
                            45.49845129993241
                        ],
                        [
                            9.130947589874268,
                            45.495774063803815
                        ],
                        [
                            9.13264274597168,
                            45.49448052209095
                        ],
                        [
                            9.137728214263916,
                            45.492510070054564
                        ],
                        [
                            9.138736724853514,
                            45.491532339254626
                        ],
                        [
                            9.140560626983643,
                            45.491096115421975
                        ],
                        [
                            9.142405986785889,
                            45.49099081950789
                        ],
                        [
                            9.143221378326416,
                            45.49078022708919
                        ],
                        [
                            9.145281314849854,
                            45.489381271747526
                        ],
                        [
                            9.15062427520752,
                            45.48574081650614
                        ],
                        [
                            9.14886474609375,
                            45.48485323182811
                        ],
                        [
                            9.14837121963501,
                            45.48539481058301
                        ],
                        [
                            9.148972034454344,
                            45.485966471397994
                        ],
                        [
                            9.14335012435913,
                            45.490058189961346
                        ],
                        [
                            9.141998291015625,
                            45.49035903988949
                        ],
                        [
                            9.142920970916748,
                            45.48939631446289
                        ],
                        [
                            9.142234325408936,
                            45.48828314267531
                        ],
                        [
                            9.149208068847656,
                            45.48274704273072
                        ],
                        [
                            9.147791862487793,
                            45.48176914245492
                        ],
                        [
                            9.14684772491455,
                            45.482476241276814
                        ],
                        [
                            9.147748947143555,
                            45.48295766518383
                        ],
                        [
                            9.140775203704834,
                            45.48808758319751
                        ],
                        [
                            9.134917259216309,
                            45.49240477678395
                        ],
                        [
                            9.129960536956787,
                            45.493502825499434
                        ],
                        [
                            9.129080772399902,
                            45.49515737901887
                        ],
                        [
                            9.129724502563477,
                            45.49930859021302
                        ],
                        [
                            9.129853248596191,
                            45.50176006757972
                        ],
                        [
                            9.130218029022217,
                            45.50532429516629
                        ],
                        [
                            9.12961721420288,
                            45.50867777161094
                        ],
                        [
                            9.12914514541626,
                            45.50914393413429
                        ],
                        [
                            9.128758907318115,
                            45.50789581290221
                        ],
                        [
                            9.12142038345337,
                            45.50965520472071
                        ],
                        [
                            9.119210243225098,
                            45.50852739577985
                        ],
                        [
                            9.112386703491211,
                            45.511264173025715
                        ],
                        [
                            9.106271266937256,
                            45.51079802806233
                        ],
                        [
                            9.103374481201172,
                            45.5132339687908
                        ],
                        [
                            9.09893274307251,
                            45.51254229263566
                        ],
                        [
                            9.101743698120115,
                            45.5055799483871
                        ],
                        [
                            9.098525047302246,
                            45.506316824703944
                        ],
                        [
                            9.095606803894043,
                            45.504421980400835
                        ],
                        [
                            9.092409610748291,
                            45.50508367930963
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "it--02",
            "properties": {
                "land": "it",
                "city": "Milan",
                "zone": "Aera C",
                "area_code": 1,
                "time": "Mo-Fr: 7.30-19.30",
                "diesel": "Congestion Charge",
                "minEUd": 10,
                "petrol": "Congestion Charge",
                "minEUb": 10,
                "minOUTd": 4,
                "minOUTb": 2,
                "warn": "req. Online Registration",
                "img_sign": "sign-it--white",
                "color": 4,
                "urlZone": "https://www.comune.milano.it/aree-tematiche/mobilita/area-c/",
                "urlPark": "https://www.atm.it/it/AltriServizi/Auto/Pagine/ParcheggiStruttura.aspx",
                "urlReg": "https://areac.atm-mi.it/Areac/iweb/Purchase.aspx",
                "urlBadge": "https://www.atm.it/it/AltriServizi/Auto/Pagine/AreaC.aspx"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            9.17135238647461,
                            45.4748481323179
                        ],
                        [
                            9.16684627532959,
                            45.471763495263886
                        ],
                        [
                            9.165730476379395,
                            45.468151998325745
                        ],
                        [
                            9.166696071624756,
                            45.468151998325745
                        ],
                        [
                            9.167168140411377,
                            45.467760738931645
                        ],
                        [
                            9.166417121887207,
                            45.46640635851926
                        ],
                        [
                            9.165430068969727,
                            45.463276110389316
                        ],
                        [
                            9.164807796478271,
                            45.46150021927382
                        ],
                        [
                            9.1690993309021,
                            45.45894163372505
                        ],
                        [
                            9.17332649230957,
                            45.4563377774691
                        ],
                        [
                            9.174485206604004,
                            45.45550993670661
                        ],
                        [
                            9.177446365356445,
                            45.453101603568896
                        ],
                        [
                            9.178240299224852,
                            45.45320697029505
                        ],
                        [
                            9.180278778076172,
                            45.453252127403125
                        ],
                        [
                            9.181501865386963,
                            45.45299623664587
                        ],
                        [
                            9.185771942138672,
                            45.45268013469568
                        ],
                        [
                            9.189162254333496,
                            45.45225866267273
                        ],
                        [
                            9.191951751708984,
                            45.45225866267273
                        ],
                        [
                            9.198582172393799,
                            45.452364030973776
                        ],
                        [
                            9.200878143310547,
                            45.45222855740771
                        ],
                        [
                            9.201672077178955,
                            45.452770449719395
                        ],
                        [
                            9.202401638031006,
                            45.45469713577075
                        ],
                        [
                            9.203839302062988,
                            45.45696993859499
                        ],
                        [
                            9.20478343963623,
                            45.458565361351695
                        ],
                        [
                            9.206135272979736,
                            45.460296193478605
                        ],
                        [
                            9.206500053405762,
                            45.46127446639574
                        ],
                        [
                            9.206070899963379,
                            45.46305036462222
                        ],
                        [
                            9.205749034881592,
                            45.46506699477677
                        ],
                        [
                            9.205448627471924,
                            45.46783598133375
                        ],
                        [
                            9.205148220062256,
                            45.47042425881147
                        ],
                        [
                            9.204976558685303,
                            45.47235034165923
                        ],
                        [
                            9.205126762390137,
                            45.47376476683216
                        ],
                        [
                            9.20102834701538,
                            45.47567568914403
                        ],
                        [
                            9.197251796722412,
                            45.477631684241196
                        ],
                        [
                            9.196393489837646,
                            45.47678911006199
                        ],
                        [
                            9.195213317871094,
                            45.47751131727267
                        ],
                        [
                            9.195921421051025,
                            45.47824856091938
                        ],
                        [
                            9.193732738494873,
                            45.47924156772784
                        ],
                        [
                            9.19008493423462,
                            45.47976815514664
                        ],
                        [
                            9.18330430984497,
                            45.4807912252054
                        ],
                        [
                            9.18257474899292,
                            45.48059563971866
                        ],
                        [
                            9.18156623840332,
                            45.4773458122711
                        ],
                        [
                            9.177875518798828,
                            45.47672892570996
                        ],
                        [
                            9.176416397094727,
                            45.47591643066609
                        ],
                        [
                            9.174892902374268,
                            45.47705993885071
                        ],
                        [
                            9.17135238647461,
                            45.4748481323179
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "pt--01",
            "properties": {
                "land": "pt",
                "city": "Lisbon",
                "zone": "ZONA 2",
                "area_code": 0,
                "time": "Mo-Fr: 07.00-21.00",
                "diesel": "min. EURO-2 (diesel)",
                "minEUd": 2,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-pt--white",
                "color": 1,
                "urlZone": "https://lisboaparapessoas.pt/2022/04/07/zer-lisboa-google-maps/",
                "urlPark": "",
                "urlReg": "https://informacoeseservicos.lisboa.pt/servicos/detalhe/zona-de-emissoes-reduzidas-zer",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -9.172897338867186,
                            38.697735932227125
                        ],
                        [
                            -9.160966873168945,
                            38.69977900653675
                        ],
                        [
                            -9.14036750793457,
                            38.703396109713545
                        ],
                        [
                            -9.128007888793944,
                            38.706544180227716
                        ],
                        [
                            -9.10989761352539,
                            38.71886720793689
                        ],
                        [
                            -9.101486206054688,
                            38.72891158257717
                        ],
                        [
                            -9.09586429595947,
                            38.74240230346903
                        ],
                        [
                            -9.101121425628662,
                            38.743423209640184
                        ],
                        [
                            -9.103696346282959,
                            38.744176327788175
                        ],
                        [
                            -9.105520248413086,
                            38.746017249820724
                        ],
                        [
                            -9.105820655822754,
                            38.748008739298605
                        ],
                        [
                            -9.105756282806396,
                            38.7491801777486
                        ],
                        [
                            -9.10712957382202,
                            38.749213647136166
                        ],
                        [
                            -9.109747409820557,
                            38.74924711650805
                        ],
                        [
                            -9.112257957458496,
                            38.74936425918603
                        ],
                        [
                            -9.114253520965576,
                            38.748895687320804
                        ],
                        [
                            -9.114875793457031,
                            38.7478413893795
                        ],
                        [
                            -9.115755558013916,
                            38.74705483950528
                        ],
                        [
                            -9.117300510406494,
                            38.746519311228404
                        ],
                        [
                            -9.118974208831787,
                            38.746653193674206
                        ],
                        [
                            -9.120862483978271,
                            38.74723892642276
                        ],
                        [
                            -9.122471809387207,
                            38.74725566157356
                        ],
                        [
                            -9.124274253845215,
                            38.746519311228404
                        ],
                        [
                            -9.12639856338501,
                            38.74630175171854
                        ],
                        [
                            -9.128093719482422,
                            38.747004633899984
                        ],
                        [
                            -9.12940263748169,
                            38.748092414111035
                        ],
                        [
                            -9.130432605743406,
                            38.74820955868372
                        ],
                        [
                            -9.13088321685791,
                            38.748092414111035
                        ],
                        [
                            -9.131827354431152,
                            38.74834343795998
                        ],
                        [
                            -9.13686990737915,
                            38.748577726089316
                        ],
                        [
                            -9.140839576721191,
                            38.74869486986568
                        ],
                        [
                            -9.14161205291748,
                            38.74841037750396
                        ],
                        [
                            -9.142642021179197,
                            38.74862793058844
                        ],
                        [
                            -9.149401187896729,
                            38.74762383389823
                        ],
                        [
                            -9.155259132385254,
                            38.74680381112565
                        ],
                        [
                            -9.157769680023192,
                            38.74615113320797
                        ],
                        [
                            -9.160730838775633,
                            38.74497964505743
                        ],
                        [
                            -9.16109561920166,
                            38.74394202521559
                        ],
                        [
                            -9.162490367889404,
                            38.744126120158775
                        ],
                        [
                            -9.165000915527344,
                            38.742670083549
                        ],
                        [
                            -9.169785976409912,
                            38.74009265859846
                        ],
                        [
                            -9.170515537261963,
                            38.73885412260998
                        ],
                        [
                            -9.169807434082031,
                            38.73639372389187
                        ],
                        [
                            -9.168198108673096,
                            38.73410062293043
                        ],
                        [
                            -9.167726039886475,
                            38.7323933028728
                        ],
                        [
                            -9.170258045196533,
                            38.72927984947937
                        ],
                        [
                            -9.171159267425537,
                            38.72772307189841
                        ],
                        [
                            -9.171695709228516,
                            38.7247433384907
                        ],
                        [
                            -9.173884391784666,
                            38.72352127706034
                        ],
                        [
                            -9.174528121948242,
                            38.72223223071042
                        ],
                        [
                            -9.174635410308838,
                            38.72074226504892
                        ],
                        [
                            -9.174120426177979,
                            38.719704293083645
                        ],
                        [
                            -9.174420833587646,
                            38.7187667570604
                        ],
                        [
                            -9.175407886505127,
                            38.714162607027056
                        ],
                        [
                            -9.174528121948242,
                            38.71257201354583
                        ],
                        [
                            -9.17309045791626,
                            38.70908932739828
                        ],
                        [
                            -9.173197746276855,
                            38.706527435538455
                        ],
                        [
                            -9.174249172210693,
                            38.70307794593952
                        ],
                        [
                            -9.174141883850098,
                            38.70212344612382
                        ],
                        [
                            -9.172897338867186,
                            38.697735932227125
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "pt--02",
            "properties": {
                "land": "pt",
                "city": "Lisbon",
                "zone": "ZONA 1",
                "area_code": 1,
                "time": "Mo-Fr: 07.00-21.00",
                "diesel": "min. EURO-3 (diesel)",
                "minEUd": 3,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-pt--white",
                "color": 1,
                "urlZone": "https://lisboaparapessoas.pt/2022/04/07/zer-lisboa-google-maps/",
                "urlPark": "",
                "urlReg": "https://informacoeseservicos.lisboa.pt/servicos/detalhe/zona-de-emissoes-reduzidas-zer",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -9.144675135612488,
                                38.71832309733377
                            ],
                            [
                                -9.142974615097046,
                                38.71922715575154
                            ],
                            [
                                -9.14708375930786,
                                38.723747276297296
                            ],
                            [
                                -9.15012001991272,
                                38.722211304441494
                            ],
                            [
                                -9.148907661437988,
                                38.72091386380065
                            ],
                            [
                                -9.149776697158813,
                                38.720478588115185
                            ],
                            [
                                -9.149009585380554,
                                38.71966243905916
                            ],
                            [
                                -9.147416353225708,
                                38.71975033248225
                            ],
                            [
                                -9.146552681922913,
                                38.71962895582191
                            ],
                            [
                                -9.145511984825134,
                                38.719206228602644
                            ],
                            [
                                -9.144675135612488,
                                38.71832309733377
                            ]
                        ],
                        [
                            [
                                -9.134917259216309,
                                38.71000187454716
                            ],
                            [
                                -9.139047861099243,
                                38.70897211866348
                            ],
                            [
                                -9.139326810836792,
                                38.7088214214364
                            ],
                            [
                                -9.13960576057434,
                                38.70843630485739
                            ],
                            [
                                -9.139745235443115,
                                38.70819351290446
                            ],
                            [
                                -9.139680862426758,
                                38.70780002074705
                            ],
                            [
                                -9.14211630821228,
                                38.70715535870246
                            ],
                            [
                                -9.141815900802612,
                                38.70643533967735
                            ],
                            [
                                -9.141558408737183,
                                38.705782292895805
                            ],
                            [
                                -9.141011238098145,
                                38.70557298176772
                            ],
                            [
                                -9.136719703674316,
                                38.70653580788358
                            ],
                            [
                                -9.13463294506073,
                                38.707134428019174
                            ],
                            [
                                -9.132910966873169,
                                38.707561412745754
                            ],
                            [
                                -9.13337230682373,
                                38.708628863406204
                            ],
                            [
                                -9.13339912891388,
                                38.70882560747477
                            ],
                            [
                                -9.134466648101807,
                                38.70884653766297
                            ],
                            [
                                -9.134917259216309,
                                38.71000187454716
                            ]
                        ],
                        [
                            [
                                -9.139198064804077,
                                38.709265140140154
                            ],
                            [
                                -9.1351318359375,
                                38.710265590131705
                            ],
                            [
                                -9.135716557502747,
                                38.711739030733845
                            ],
                            [
                                -9.135805070400238,
                                38.7118886753462
                            ],
                            [
                                -9.13594052195549,
                                38.712259122899134
                            ],
                            [
                                -9.136059880256653,
                                38.712647358246144
                            ],
                            [
                                -9.136467576026917,
                                38.71364357514979
                            ],
                            [
                                -9.1366446018219,
                                38.71370217571177
                            ],
                            [
                                -9.136987924575806,
                                38.71363101788028
                            ],
                            [
                                -9.13736343383789,
                                38.71469000652579
                            ],
                            [
                                -9.13761556148529,
                                38.71489091959699
                            ],
                            [
                                -9.138232469558716,
                                38.71507508941615
                            ],
                            [
                                -9.138650894165039,
                                38.71510857478682
                            ],
                            [
                                -9.139246344566345,
                                38.71509601777465
                            ],
                            [
                                -9.139836430549622,
                                38.715765722011746
                            ],
                            [
                                -9.140469431877136,
                                38.716414492007694
                            ],
                            [
                                -9.141054153442383,
                                38.71697954494807
                            ],
                            [
                                -9.141848087310791,
                                38.718113822921865
                            ],
                            [
                                -9.142733216285706,
                                38.71899695677671
                            ],
                            [
                                -9.144503474235535,
                                38.718051040478805
                            ],
                            [
                                -9.143087267875671,
                                38.71649401824719
                            ],
                            [
                                -9.14160668849945,
                                38.71487417686262
                            ],
                            [
                                -9.141032695770264,
                                38.714087263923865
                            ],
                            [
                                -9.141467213630676,
                                38.713894720077136
                            ],
                            [
                                -9.14139211177826,
                                38.71371473296879
                            ],
                            [
                                -9.141043424606323,
                                38.71335475739252
                            ],
                            [
                                -9.141032695770264,
                                38.71267665894147
                            ],
                            [
                                -9.141027331352234,
                                38.71234179313625
                            ],
                            [
                                -9.140501618385315,
                                38.71154229468292
                            ],
                            [
                                -9.140034914016724,
                                38.71083906352122
                            ],
                            [
                                -9.139729142189026,
                                38.71084743536145
                            ],
                            [
                                -9.139364361763,
                                38.70992652705859
                            ],
                            [
                                -9.139198064804077,
                                38.709265140140154
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "ch--01",
            "properties": {
                "land": "ch",
                "city": "Genf (only when pollution warning)",
                "zone": "CRIT AIR",
                "area_code": 0,
                "time": "Temporary during Emission Peak",
                "commercial": 2,
                "diesel": "min. EURO-3 (diesel)",
                "minEUd": 3,
                "petrol": "min. EURO-2 (petrol)",
                "minEUb": 2,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Crit'Air 4, 3, 2, 1 or Eco ",
                "img_sign": "sign-fr--white",
                "color": 0,
                "urlZone": "https://www.ge.ch/en/stick-air-and-differentiated-traffic-scheme",
                "urlPark": "https://www.ge.ch/stationner-geneve",
                "urlReg": "",
                "urlBadge": "https://www.certificat-air.gouv.fr/en/demande"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            6.148974895477295,
                            46.2053269326161
                        ],
                        [
                            6.147440671920776,
                            46.20691589615733
                        ],
                        [
                            6.150047779083251,
                            46.20928440602187
                        ],
                        [
                            6.152515411376952,
                            46.21152660024792
                        ],
                        [
                            6.151989698410034,
                            46.213568253777034
                        ],
                        [
                            6.1513352394104,
                            46.21590678183222
                        ],
                        [
                            6.15056276321411,
                            46.21702033161977
                        ],
                        [
                            6.149446964263916,
                            46.21813385882635
                        ],
                        [
                            6.1484599113464355,
                            46.21876484755327
                        ],
                        [
                            6.14884614944458,
                            46.21938098248957
                        ],
                        [
                            6.149725914001465,
                            46.22060580851608
                        ],
                        [
                            6.149672269821167,
                            46.22090273192293
                        ],
                        [
                            6.148899793624878,
                            46.22226855891235
                        ],
                        [
                            6.148116588592528,
                            46.22377538296277
                        ],
                        [
                            6.147505044937134,
                            46.2237976509884
                        ],
                        [
                            6.144071817398071,
                            46.22316672009857
                        ],
                        [
                            6.141389608383179,
                            46.22269166146698
                        ],
                        [
                            6.139715909957886,
                            46.22258031900559
                        ],
                        [
                            6.138707399368286,
                            46.222758466835444
                        ],
                        [
                            6.138052940368652,
                            46.22177122367043
                        ],
                        [
                            6.134995222091675,
                            46.21940325229756
                        ],
                        [
                            6.131486892700195,
                            46.21658233806416
                        ],
                        [
                            6.1313796043396,
                            46.21601813782713
                        ],
                        [
                            6.131401062011719,
                            46.215750883060004
                        ],
                        [
                            6.130832433700561,
                            46.21490456771662
                        ],
                        [
                            6.129856109619141,
                            46.21333810753847
                        ],
                        [
                            6.128718852996826,
                            46.213813247100184
                        ],
                        [
                            6.126744747161865,
                            46.21445913334974
                        ],
                        [
                            6.123762130737304,
                            46.21502334960427
                        ],
                        [
                            6.120361089706421,
                            46.21563210274599
                        ],
                        [
                            6.117786169052124,
                            46.21610722246044
                        ],
                        [
                            6.112239360809326,
                            46.21760679209648
                        ],
                        [
                            6.110866069793701,
                            46.2179260021286
                        ],
                        [
                            6.110801696777344,
                            46.21702033161977
                        ],
                        [
                            6.110833883285522,
                            46.216530372495214
                        ],
                        [
                            6.1106568574905396,
                            46.21638189917012
                        ],
                        [
                            6.110925078392029,
                            46.21503819732217
                        ],
                        [
                            6.111059188842773,
                            46.21403225535782
                        ],
                        [
                            6.111010909080505,
                            46.21328613889952
                        ],
                        [
                            6.110581755638123,
                            46.21227274061063
                        ],
                        [
                            6.109723448753356,
                            46.211530312414126
                        ],
                        [
                            6.1093103885650635,
                            46.211181367694806
                        ],
                        [
                            6.109272837638854,
                            46.21072105424833
                        ],
                        [
                            6.109589338302612,
                            46.20978556664779
                        ],
                        [
                            6.109921932220459,
                            46.20838230537199
                        ],
                        [
                            6.11015260219574,
                            46.20655949711844
                        ],
                        [
                            6.11065149307251,
                            46.20400152366385
                        ],
                        [
                            6.111037731170654,
                            46.20161050899547
                        ],
                        [
                            6.111740469932555,
                            46.198391385458905
                        ],
                        [
                            6.112008690834045,
                            46.19709551432436
                        ],
                        [
                            6.112614870071411,
                            46.19605211163161
                        ],
                        [
                            6.112695336341858,
                            46.19493442342472
                        ],
                        [
                            6.1124056577682495,
                            46.191852308276076
                        ],
                        [
                            6.112475395202637,
                            46.19074938893569
                        ],
                        [
                            6.112791895866394,
                            46.19001409707962
                        ],
                        [
                            6.114047169685364,
                            46.18876259286729
                        ],
                        [
                            6.114524602890015,
                            46.18868460501474
                        ],
                        [
                            6.114717721939086,
                            46.18839864860874
                        ],
                        [
                            6.1154794692993155,
                            46.18754448425185
                        ],
                        [
                            6.116842031478882,
                            46.18640805370468
                        ],
                        [
                            6.117743253707886,
                            46.18568755942389
                        ],
                        [
                            6.11939013004303,
                            46.18488534849575
                        ],
                        [
                            6.120237708091736,
                            46.18445452668426
                        ],
                        [
                            6.120452284812926,
                            46.1841574062264
                        ],
                        [
                            6.120672225952148,
                            46.18285748534227
                        ],
                        [
                            6.121492981910706,
                            46.180810976181746
                        ],
                        [
                            6.122013330459595,
                            46.17893525206215
                        ],
                        [
                            6.122308373451233,
                            46.17789893347297
                        ],
                        [
                            6.12263560295105,
                            46.17710403787822
                        ],
                        [
                            6.122758984565735,
                            46.1766731550898
                        ],
                        [
                            6.123054027557373,
                            46.175603362535114
                        ],
                        [
                            6.1234670877456665,
                            46.17422151634962
                        ],
                        [
                            6.123906970024108,
                            46.173396849662815
                        ],
                        [
                            6.124845743179321,
                            46.17286563889734
                        ],
                        [
                            6.125800609588623,
                            46.172709617977425
                        ],
                        [
                            6.127206087112427,
                            46.17337827594638
                        ],
                        [
                            6.13009214401245,
                            46.1750127389789
                        ],
                        [
                            6.132946014404297,
                            46.176858880843646
                        ],
                        [
                            6.134549975395203,
                            46.17800293765395
                        ],
                        [
                            6.136443614959716,
                            46.17894639516665
                        ],
                        [
                            6.13711416721344,
                            46.17869010319184
                        ],
                        [
                            6.138471364974976,
                            46.17854152758696
                        ],
                        [
                            6.140064597129822,
                            46.17860838665883
                        ],
                        [
                            6.141915321350098,
                            46.17933640240146
                        ],
                        [
                            6.143749952316284,
                            46.180417265137955
                        ],
                        [
                            6.1451393365859985,
                            46.18116382802349
                        ],
                        [
                            6.146534085273742,
                            46.18176552804618
                        ],
                        [
                            6.150466203689575,
                            46.18308404519376
                        ],
                        [
                            6.152408123016357,
                            46.18279063143719
                        ],
                        [
                            6.153073310852051,
                            46.18380828763651
                        ],
                        [
                            6.154918670654296,
                            46.18319918180965
                        ],
                        [
                            6.1563509702682495,
                            46.18261978219936
                        ],
                        [
                            6.157504320144653,
                            46.182029234005405
                        ],
                        [
                            6.157922744750977,
                            46.18217408603634
                        ],
                        [
                            6.1582982540130615,
                            46.182965194240545
                        ],
                        [
                            6.158051490783691,
                            46.18325860706543
                        ],
                        [
                            6.156737208366394,
                            46.183574302659814
                        ],
                        [
                            6.156538724899292,
                            46.18379714551735
                        ],
                        [
                            6.156544089317321,
                            46.185349592358065
                        ],
                        [
                            6.157370209693909,
                            46.18608123272694
                        ],
                        [
                            6.157810091972351,
                            46.18664945431558
                        ],
                        [
                            6.15958034992218,
                            46.188591762188864
                        ],
                        [
                            6.160685420036316,
                            46.189802420327865
                        ],
                        [
                            6.161715388298035,
                            46.19056742368526
                        ],
                        [
                            6.163727045059204,
                            46.19245017785964
                        ],
                        [
                            6.1647409200668335,
                            46.19333397315673
                        ],
                        [
                            6.165143251419067,
                            46.19478217842149
                        ],
                        [
                            6.1652183532714835,
                            46.19533545702421
                        ],
                        [
                            6.165711879730225,
                            46.19640857883655
                        ],
                        [
                            6.166956424713135,
                            46.197678474461696
                        ],
                        [
                            6.167230010032653,
                            46.19828370678673
                        ],
                        [
                            6.167026162147521,
                            46.2002218906012
                        ],
                        [
                            6.16931676864624,
                            46.20023302939103
                        ],
                        [
                            6.171371340751648,
                            46.20028872330642
                        ],
                        [
                            6.172868013381958,
                            46.20007708612764
                        ],
                        [
                            6.174107193946838,
                            46.199917429470865
                        ],
                        [
                            6.175233721733093,
                            46.20093105571692
                        ],
                        [
                            6.176703572273254,
                            46.202460741972295
                        ],
                        [
                            6.177459955215454,
                            46.202984241321474
                        ],
                        [
                            6.177535057067871,
                            46.203221856401385
                        ],
                        [
                            6.178232431411743,
                            46.20364881841567
                        ],
                        [
                            6.179176568984985,
                            46.20427626091982
                        ],
                        [
                            6.179069280624389,
                            46.20458441053163
                        ],
                        [
                            6.178447008132935,
                            46.20540489685273
                        ],
                        [
                            6.178377270698547,
                            46.205857829753185
                        ],
                        [
                            6.174273490905762,
                            46.20918046102317
                        ],
                        [
                            6.172245740890502,
                            46.21021247764832
                        ],
                        [
                            6.17130160331726,
                            46.21095492365889
                        ],
                        [
                            6.168737411499023,
                            46.209781854363655
                        ],
                        [
                            6.165668964385985,
                            46.20834146918958
                        ],
                        [
                            6.164381504058838,
                            46.207814308473054
                        ],
                        [
                            6.162385940551758,
                            46.2074727650264
                        ],
                        [
                            6.159714460372925,
                            46.20629219632182
                        ],
                        [
                            6.15534782409668,
                            46.20416116845278
                        ],
                        [
                            6.15309476852417,
                            46.20323299458305
                        ],
                        [
                            6.15031599998474,
                            46.203782475407756
                        ],
                        [
                            6.149822473526,
                            46.204257697635015
                        ],
                        [
                            6.148974895477295,
                            46.2053269326161
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "nl",
                "city": "Amsterdam",
                "zone": "Milieuzone",
                "area_code": 0,
                "commercial": 2,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "petrol cars ok",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 1,
                "urlZone": "https://www.amsterdam.nl/en/traffic-transport/low-emission-zone/",
                "urlPark": "https://www.amsterdam.nl/en/parking/park-and-ride/",
                "urlReg": null,
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                4.868895,
                                52.418333
                            ],
                            [
                                4.869027,
                                52.41848
                            ],
                            [
                                4.869129,
                                52.418581
                            ],
                            [
                                4.869241,
                                52.418694
                            ],
                            [
                                4.869382,
                                52.418827
                            ],
                            [
                                4.869507,
                                52.418944
                            ],
                            [
                                4.86956,
                                52.418989
                            ],
                            [
                                4.869594,
                                52.41902
                            ],
                            [
                                4.869644,
                                52.419063
                            ],
                            [
                                4.869945,
                                52.419313
                            ],
                            [
                                4.870142,
                                52.419456
                            ],
                            [
                                4.870355,
                                52.419614
                            ],
                            [
                                4.870527,
                                52.419736
                            ],
                            [
                                4.87069,
                                52.419858
                            ],
                            [
                                4.870907,
                                52.419998
                            ],
                            [
                                4.871062,
                                52.420099
                            ],
                            [
                                4.871283,
                                52.420236
                            ],
                            [
                                4.871338,
                                52.42027
                            ],
                            [
                                4.87149,
                                52.420345
                            ],
                            [
                                4.871508,
                                52.420354
                            ],
                            [
                                4.871496,
                                52.420363
                            ],
                            [
                                4.871782,
                                52.420511
                            ],
                            [
                                4.872016,
                                52.420624
                            ],
                            [
                                4.872272,
                                52.420742
                            ],
                            [
                                4.872512,
                                52.420841
                            ],
                            [
                                4.872576,
                                52.420867
                            ],
                            [
                                4.872651,
                                52.420896
                            ],
                            [
                                4.872683,
                                52.420909
                            ],
                            [
                                4.872692,
                                52.420912
                            ],
                            [
                                4.872695,
                                52.420913
                            ],
                            [
                                4.872819,
                                52.420962
                            ],
                            [
                                4.87311,
                                52.421093
                            ],
                            [
                                4.873274,
                                52.421167
                            ],
                            [
                                4.873372,
                                52.42119
                            ],
                            [
                                4.873671,
                                52.421246
                            ],
                            [
                                4.874037,
                                52.421373
                            ],
                            [
                                4.874127,
                                52.421399
                            ],
                            [
                                4.874144,
                                52.421404
                            ],
                            [
                                4.874165,
                                52.42141
                            ],
                            [
                                4.874187,
                                52.421417
                            ],
                            [
                                4.874204,
                                52.421422
                            ],
                            [
                                4.874229,
                                52.42143
                            ],
                            [
                                4.874333,
                                52.421456
                            ],
                            [
                                4.874402,
                                52.421482
                            ],
                            [
                                4.87448,
                                52.42151
                            ],
                            [
                                4.874651,
                                52.421587
                            ],
                            [
                                4.874775,
                                52.421623
                            ],
                            [
                                4.874939,
                                52.421675
                            ],
                            [
                                4.875193,
                                52.421775
                            ],
                            [
                                4.875628,
                                52.421984
                            ],
                            [
                                4.875868,
                                52.422112
                            ],
                            [
                                4.875975,
                                52.422168
                            ],
                            [
                                4.876512,
                                52.422468
                            ],
                            [
                                4.876705,
                                52.422572
                            ],
                            [
                                4.876775,
                                52.42261
                            ],
                            [
                                4.876962,
                                52.422694
                            ],
                            [
                                4.877204,
                                52.422792
                            ],
                            [
                                4.877435,
                                52.422867
                            ],
                            [
                                4.877684,
                                52.422935
                            ],
                            [
                                4.877985,
                                52.423004
                            ],
                            [
                                4.878283,
                                52.42305
                            ],
                            [
                                4.878601,
                                52.42309
                            ],
                            [
                                4.878966,
                                52.423123
                            ],
                            [
                                4.87923,
                                52.423135
                            ],
                            [
                                4.87969,
                                52.423171
                            ],
                            [
                                4.880113,
                                52.423207
                            ],
                            [
                                4.880477,
                                52.423249
                            ],
                            [
                                4.880969,
                                52.423314
                            ],
                            [
                                4.881347,
                                52.423367
                            ],
                            [
                                4.881746,
                                52.423436
                            ],
                            [
                                4.882561,
                                52.423569
                            ],
                            [
                                4.883335,
                                52.42107
                            ],
                            [
                                4.877365,
                                52.419397
                            ],
                            [
                                4.875274,
                                52.417794
                            ],
                            [
                                4.874746,
                                52.417389
                            ],
                            [
                                4.87452,
                                52.417144
                            ],
                            [
                                4.874377,
                                52.416936
                            ],
                            [
                                4.874293,
                                52.416775
                            ],
                            [
                                4.874239,
                                52.416641
                            ],
                            [
                                4.874208,
                                52.416521
                            ],
                            [
                                4.874198,
                                52.416415
                            ],
                            [
                                4.874199,
                                52.416326
                            ],
                            [
                                4.874204,
                                52.416213
                            ],
                            [
                                4.873219,
                                52.416241
                            ],
                            [
                                4.869,
                                52.418187
                            ],
                            [
                                4.868895,
                                52.418333
                            ]
                        ],
                        [
                            [
                                4.830564,
                                52.33778
                            ],
                            [
                                4.831664,
                                52.337823
                            ],
                            [
                                4.832556,
                                52.337845
                            ],
                            [
                                4.834314,
                                52.337685
                            ],
                            [
                                4.836312,
                                52.337241
                            ],
                            [
                                4.838015,
                                52.336943
                            ],
                            [
                                4.840222,
                                52.337025
                            ],
                            [
                                4.84239,
                                52.336706
                            ],
                            [
                                4.846862,
                                52.336865
                            ],
                            [
                                4.845954,
                                52.335173
                            ],
                            [
                                4.844773,
                                52.334312
                            ],
                            [
                                4.843547,
                                52.333564
                            ],
                            [
                                4.839961,
                                52.332842
                            ],
                            [
                                4.836193,
                                52.331705
                            ],
                            [
                                4.833196,
                                52.331566
                            ],
                            [
                                4.832016,
                                52.331927
                            ],
                            [
                                4.828884,
                                52.333203
                            ],
                            [
                                4.828611,
                                52.335589
                            ],
                            [
                                4.829519,
                                52.337059
                            ],
                            [
                                4.830564,
                                52.33778
                            ]
                        ],
                        [
                            [
                                4.969483,
                                52.361318
                            ],
                            [
                                4.974091,
                                52.361124
                            ],
                            [
                                4.974454,
                                52.360735
                            ],
                            [
                                4.975089,
                                52.360167
                            ],
                            [
                                4.975476,
                                52.359308
                            ],
                            [
                                4.975544,
                                52.357547
                            ],
                            [
                                4.973411,
                                52.356479
                            ],
                            [
                                4.972933,
                                52.356105
                            ],
                            [
                                4.971663,
                                52.356285
                            ],
                            [
                                4.967554,
                                52.357366
                            ],
                            [
                                4.969483,
                                52.361318
                            ]
                        ],
                        [
                            [
                                4.974326,
                                52.371983
                            ],
                            [
                                4.980742,
                                52.369273
                            ],
                            [
                                4.980038,
                                52.368706
                            ],
                            [
                                4.974531,
                                52.366688
                            ],
                            [
                                4.972805,
                                52.367581
                            ],
                            [
                                4.973577,
                                52.369488
                            ],
                            [
                                4.974326,
                                52.371983
                            ]
                        ],
                        [
                            [
                                4.890512,
                                52.344402
                            ],
                            [
                                4.890377,
                                52.344415
                            ],
                            [
                                4.889129,
                                52.34449
                            ],
                            [
                                4.887777,
                                52.344565
                            ],
                            [
                                4.886586,
                                52.344583
                            ],
                            [
                                4.885774,
                                52.344661
                            ],
                            [
                                4.885378,
                                52.341344
                            ],
                            [
                                4.884143,
                                52.339927
                            ],
                            [
                                4.877496,
                                52.339763
                            ],
                            [
                                4.877405,
                                52.340142
                            ],
                            [
                                4.873241,
                                52.339983
                            ],
                            [
                                4.873309,
                                52.339592
                            ],
                            [
                                4.870368,
                                52.339463
                            ],
                            [
                                4.868926,
                                52.339404
                            ],
                            [
                                4.868281,
                                52.339376
                            ],
                            [
                                4.864281,
                                52.339242
                            ],
                            [
                                4.863774,
                                52.339304
                            ],
                            [
                                4.863018,
                                52.339502
                            ],
                            [
                                4.862352,
                                52.339574
                            ],
                            [
                                4.861237,
                                52.339566
                            ],
                            [
                                4.858005,
                                52.339386
                            ],
                            [
                                4.857997,
                                52.341193
                            ],
                            [
                                4.857335,
                                52.341224
                            ],
                            [
                                4.857097,
                                52.338848
                            ],
                            [
                                4.846941,
                                52.33829
                            ],
                            [
                                4.845548,
                                52.33829
                            ],
                            [
                                4.844707,
                                52.338287
                            ],
                            [
                                4.844246,
                                52.338409
                            ],
                            [
                                4.843631,
                                52.338688
                            ],
                            [
                                4.843297,
                                52.338889
                            ],
                            [
                                4.84219,
                                52.339749
                            ],
                            [
                                4.842089,
                                52.34005
                            ],
                            [
                                4.841652,
                                52.344044
                            ],
                            [
                                4.841714,
                                52.344818
                            ],
                            [
                                4.841827,
                                52.34539
                            ],
                            [
                                4.841866,
                                52.345728
                            ],
                            [
                                4.841781,
                                52.346196
                            ],
                            [
                                4.841739,
                                52.347199
                            ],
                            [
                                4.841753,
                                52.348088
                            ],
                            [
                                4.841988,
                                52.348942
                            ],
                            [
                                4.842737,
                                52.350458
                            ],
                            [
                                4.843073,
                                52.351409
                            ],
                            [
                                4.843388,
                                52.352062
                            ],
                            [
                                4.844686,
                                52.352253
                            ],
                            [
                                4.844551,
                                52.355468
                            ],
                            [
                                4.843455,
                                52.355994
                            ],
                            [
                                4.843206,
                                52.357875
                            ],
                            [
                                4.843304,
                                52.358325
                            ],
                            [
                                4.843124,
                                52.359108
                            ],
                            [
                                4.842992,
                                52.359913
                            ],
                            [
                                4.842864,
                                52.360417
                            ],
                            [
                                4.842819,
                                52.361116
                            ],
                            [
                                4.842739,
                                52.362708
                            ],
                            [
                                4.842869,
                                52.363971
                            ],
                            [
                                4.842741,
                                52.36435
                            ],
                            [
                                4.842676,
                                52.365701
                            ],
                            [
                                4.842637,
                                52.367057
                            ],
                            [
                                4.842754,
                                52.367736
                            ],
                            [
                                4.842845,
                                52.368571
                            ],
                            [
                                4.842663,
                                52.369409
                            ],
                            [
                                4.84278,
                                52.37107
                            ],
                            [
                                4.842888,
                                52.372225
                            ],
                            [
                                4.842947,
                                52.37264
                            ],
                            [
                                4.843105,
                                52.37395
                            ],
                            [
                                4.843355,
                                52.374818
                            ],
                            [
                                4.843784,
                                52.375342
                            ],
                            [
                                4.844496,
                                52.37749
                            ],
                            [
                                4.845145,
                                52.378854
                            ],
                            [
                                4.845341,
                                52.379632
                            ],
                            [
                                4.845387,
                                52.380662
                            ],
                            [
                                4.845323,
                                52.381901
                            ],
                            [
                                4.845303,
                                52.382039
                            ],
                            [
                                4.845526,
                                52.382479
                            ],
                            [
                                4.845534,
                                52.3828
                            ],
                            [
                                4.845547,
                                52.383294
                            ],
                            [
                                4.845552,
                                52.383519
                            ],
                            [
                                4.845534,
                                52.383988
                            ],
                            [
                                4.845553,
                                52.384479
                            ],
                            [
                                4.845556,
                                52.38537
                            ],
                            [
                                4.845224,
                                52.385728
                            ],
                            [
                                4.845053,
                                52.386739
                            ],
                            [
                                4.84533,
                                52.387846
                            ],
                            [
                                4.845491,
                                52.388726
                            ],
                            [
                                4.845249,
                                52.39042
                            ],
                            [
                                4.84532,
                                52.391071
                            ],
                            [
                                4.845272,
                                52.391991
                            ],
                            [
                                4.84526,
                                52.39224
                            ],
                            [
                                4.844863,
                                52.394057
                            ],
                            [
                                4.844916,
                                52.394774
                            ],
                            [
                                4.845166,
                                52.395705
                            ],
                            [
                                4.845768,
                                52.396833
                            ],
                            [
                                4.847318,
                                52.398538
                            ],
                            [
                                4.847893,
                                52.399014
                            ],
                            [
                                4.849632,
                                52.400456
                            ],
                            [
                                4.861245,
                                52.39537
                            ],
                            [
                                4.862575,
                                52.39597
                            ],
                            [
                                4.863732,
                                52.396479
                            ],
                            [
                                4.864751,
                                52.395659
                            ],
                            [
                                4.8656,
                                52.394978
                            ],
                            [
                                4.867721,
                                52.396129
                            ],
                            [
                                4.870111,
                                52.397148
                            ],
                            [
                                4.869465,
                                52.398128
                            ],
                            [
                                4.868394,
                                52.399752
                            ],
                            [
                                4.877146,
                                52.404561
                            ],
                            [
                                4.881395,
                                52.402174
                            ],
                            [
                                4.887246,
                                52.406424
                            ],
                            [
                                4.889119,
                                52.405619
                            ],
                            [
                                4.890158,
                                52.405125
                            ],
                            [
                                4.890896,
                                52.405721
                            ],
                            [
                                4.891762,
                                52.406542
                            ],
                            [
                                4.891937,
                                52.406761
                            ],
                            [
                                4.89184,
                                52.406916
                            ],
                            [
                                4.892111,
                                52.407527
                            ],
                            [
                                4.875971,
                                52.414972
                            ],
                            [
                                4.87522,
                                52.415453
                            ],
                            [
                                4.875115,
                                52.415537
                            ],
                            [
                                4.875024,
                                52.415635
                            ],
                            [
                                4.874935,
                                52.415734
                            ],
                            [
                                4.874854,
                                52.415844
                            ],
                            [
                                4.874807,
                                52.415935
                            ],
                            [
                                4.87477,
                                52.416032
                            ],
                            [
                                4.874745,
                                52.41611
                            ],
                            [
                                4.874731,
                                52.416161
                            ],
                            [
                                4.874719,
                                52.416234
                            ],
                            [
                                4.874713,
                                52.416332
                            ],
                            [
                                4.874712,
                                52.416407
                            ],
                            [
                                4.87472,
                                52.416488
                            ],
                            [
                                4.874744,
                                52.416579
                            ],
                            [
                                4.874788,
                                52.41669
                            ],
                            [
                                4.87486,
                                52.416827
                            ],
                            [
                                4.874983,
                                52.417005
                            ],
                            [
                                4.875175,
                                52.417213
                            ],
                            [
                                4.875676,
                                52.417598
                            ],
                            [
                                4.877691,
                                52.419142
                            ],
                            [
                                4.883904,
                                52.420884
                            ],
                            [
                                4.883168,
                                52.423681
                            ],
                            [
                                4.883566,
                                52.423768
                            ],
                            [
                                4.884165,
                                52.423906
                            ],
                            [
                                4.884526,
                                52.423995
                            ],
                            [
                                4.884662,
                                52.424029
                            ],
                            [
                                4.885174,
                                52.424176
                            ],
                            [
                                4.88576,
                                52.424348
                            ],
                            [
                                4.886446,
                                52.424532
                            ],
                            [
                                4.886729,
                                52.424594
                            ],
                            [
                                4.887062,
                                52.424654
                            ],
                            [
                                4.887367,
                                52.424703
                            ],
                            [
                                4.887728,
                                52.42474
                            ],
                            [
                                4.888182,
                                52.424771
                            ],
                            [
                                4.888253,
                                52.424776
                            ],
                            [
                                4.888408,
                                52.424784
                            ],
                            [
                                4.88847,
                                52.424788
                            ],
                            [
                                4.888544,
                                52.424791
                            ],
                            [
                                4.888826,
                                52.424804
                            ],
                            [
                                4.889411,
                                52.424816
                            ],
                            [
                                4.89023,
                                52.42482
                            ],
                            [
                                4.890872,
                                52.424813
                            ],
                            [
                                4.890999,
                                52.424812
                            ],
                            [
                                4.891449,
                                52.424804
                            ],
                            [
                                4.891482,
                                52.424803
                            ],
                            [
                                4.891746,
                                52.424798
                            ],
                            [
                                4.892293,
                                52.424772
                            ],
                            [
                                4.892939,
                                52.42473
                            ],
                            [
                                4.893442,
                                52.424691
                            ],
                            [
                                4.893558,
                                52.42468
                            ],
                            [
                                4.893923,
                                52.424644
                            ],
                            [
                                4.894466,
                                52.424575
                            ],
                            [
                                4.895133,
                                52.424484
                            ],
                            [
                                4.896821,
                                52.424235
                            ],
                            [
                                4.897261,
                                52.424154
                            ],
                            [
                                4.897659,
                                52.424077
                            ],
                            [
                                4.898056,
                                52.423995
                            ],
                            [
                                4.898166,
                                52.423969
                            ],
                            [
                                4.898222,
                                52.423956
                            ],
                            [
                                4.898539,
                                52.423882
                            ],
                            [
                                4.898956,
                                52.423784
                            ],
                            [
                                4.898998,
                                52.423775
                            ],
                            [
                                4.899051,
                                52.423762
                            ],
                            [
                                4.899096,
                                52.423751
                            ],
                            [
                                4.899139,
                                52.423741
                            ],
                            [
                                4.899263,
                                52.423713
                            ],
                            [
                                4.899322,
                                52.423699
                            ],
                            [
                                4.899953,
                                52.42354
                            ],
                            [
                                4.900582,
                                52.423362
                            ],
                            [
                                4.901013,
                                52.423223
                            ],
                            [
                                4.901569,
                                52.423046
                            ],
                            [
                                4.902096,
                                52.422864
                            ],
                            [
                                4.902704,
                                52.422639
                            ],
                            [
                                4.90326,
                                52.42242
                            ],
                            [
                                4.903697,
                                52.422242
                            ],
                            [
                                4.904333,
                                52.421965
                            ],
                            [
                                4.905322,
                                52.421509
                            ],
                            [
                                4.906179,
                                52.421098
                            ],
                            [
                                4.907458,
                                52.420498
                            ],
                            [
                                4.907609,
                                52.420428
                            ],
                            [
                                4.907649,
                                52.420408
                            ],
                            [
                                4.907698,
                                52.420386
                            ],
                            [
                                4.907837,
                                52.42032
                            ],
                            [
                                4.908267,
                                52.420118
                            ],
                            [
                                4.909234,
                                52.419662
                            ],
                            [
                                4.909662,
                                52.419155
                            ],
                            [
                                4.91019,
                                52.418028
                            ],
                            [
                                4.910846,
                                52.41766
                            ],
                            [
                                4.912983,
                                52.417146
                            ],
                            [
                                4.913476,
                                52.417106
                            ],
                            [
                                4.913814,
                                52.417801
                            ],
                            [
                                4.91409,
                                52.417697
                            ],
                            [
                                4.914267,
                                52.417637
                            ],
                            [
                                4.914344,
                                52.41761
                            ],
                            [
                                4.914639,
                                52.417512
                            ],
                            [
                                4.914983,
                                52.417404
                            ],
                            [
                                4.915675,
                                52.417154
                            ],
                            [
                                4.916607,
                                52.416838
                            ],
                            [
                                4.917383,
                                52.416548
                            ],
                            [
                                4.917925,
                                52.416338
                            ],
                            [
                                4.919367,
                                52.41573
                            ],
                            [
                                4.91972,
                                52.415582
                            ],
                            [
                                4.921826,
                                52.41462
                            ],
                            [
                                4.922636,
                                52.414251
                            ],
                            [
                                4.923467,
                                52.413865
                            ],
                            [
                                4.924122,
                                52.413545
                            ],
                            [
                                4.924156,
                                52.413528
                            ],
                            [
                                4.92419,
                                52.413511
                            ],
                            [
                                4.925002,
                                52.413114
                            ],
                            [
                                4.925094,
                                52.413069
                            ],
                            [
                                4.92679,
                                52.412204
                            ],
                            [
                                4.926866,
                                52.412166
                            ],
                            [
                                4.9285,
                                52.411329
                            ],
                            [
                                4.928823,
                                52.41116
                            ],
                            [
                                4.929107,
                                52.411012
                            ],
                            [
                                4.929172,
                                52.410977
                            ],
                            [
                                4.929212,
                                52.410957
                            ],
                            [
                                4.92966,
                                52.410722
                            ],
                            [
                                4.92976,
                                52.41067
                            ],
                            [
                                4.92979,
                                52.410654
                            ],
                            [
                                4.929819,
                                52.410639
                            ],
                            [
                                4.929833,
                                52.410632
                            ],
                            [
                                4.930078,
                                52.410503
                            ],
                            [
                                4.931596,
                                52.409702
                            ],
                            [
                                4.931957,
                                52.409525
                            ],
                            [
                                4.932603,
                                52.409193
                            ],
                            [
                                4.933015,
                                52.408996
                            ],
                            [
                                4.933319,
                                52.408836
                            ],
                            [
                                4.933463,
                                52.408765
                            ],
                            [
                                4.933479,
                                52.408757
                            ],
                            [
                                4.933523,
                                52.408736
                            ],
                            [
                                4.933569,
                                52.408712
                            ],
                            [
                                4.933698,
                                52.408653
                            ],
                            [
                                4.934051,
                                52.408488
                            ],
                            [
                                4.93442,
                                52.408318
                            ],
                            [
                                4.934792,
                                52.408153
                            ],
                            [
                                4.935236,
                                52.407957
                            ],
                            [
                                4.935419,
                                52.407877
                            ],
                            [
                                4.935883,
                                52.407667
                            ],
                            [
                                4.936352,
                                52.407462
                            ],
                            [
                                4.936861,
                                52.407234
                            ],
                            [
                                4.937391,
                                52.406999
                            ],
                            [
                                4.937933,
                                52.406751
                            ],
                            [
                                4.938022,
                                52.406711
                            ],
                            [
                                4.938723,
                                52.406509
                            ],
                            [
                                4.938893,
                                52.406462
                            ],
                            [
                                4.939577,
                                52.406241
                            ],
                            [
                                4.939695,
                                52.406203
                            ],
                            [
                                4.939963,
                                52.40612
                            ],
                            [
                                4.940146,
                                52.406067
                            ],
                            [
                                4.940295,
                                52.406033
                            ],
                            [
                                4.940435,
                                52.406003
                            ],
                            [
                                4.940595,
                                52.405982
                            ],
                            [
                                4.940777,
                                52.405969
                            ],
                            [
                                4.940985,
                                52.405962
                            ],
                            [
                                4.94132,
                                52.405963
                            ],
                            [
                                4.941719,
                                52.40597
                            ],
                            [
                                4.942115,
                                52.405962
                            ],
                            [
                                4.942353,
                                52.40595
                            ],
                            [
                                4.942775,
                                52.405922
                            ],
                            [
                                4.943081,
                                52.40588
                            ],
                            [
                                4.943471,
                                52.405823
                            ],
                            [
                                4.943777,
                                52.405769
                            ],
                            [
                                4.943964,
                                52.405726
                            ],
                            [
                                4.943998,
                                52.405718
                            ],
                            [
                                4.944176,
                                52.405672
                            ],
                            [
                                4.944302,
                                52.405638
                            ],
                            [
                                4.944787,
                                52.40549
                            ],
                            [
                                4.945114,
                                52.405385
                            ],
                            [
                                4.9453,
                                52.405322
                            ],
                            [
                                4.945655,
                                52.405201
                            ],
                            [
                                4.945751,
                                52.405166
                            ],
                            [
                                4.94621,
                                52.404999
                            ],
                            [
                                4.946356,
                                52.404945
                            ],
                            [
                                4.946783,
                                52.404784
                            ],
                            [
                                4.947838,
                                52.404382
                            ],
                            [
                                4.948546,
                                52.404103
                            ],
                            [
                                4.948697,
                                52.404041
                            ],
                            [
                                4.948731,
                                52.404026
                            ],
                            [
                                4.948768,
                                52.404011
                            ],
                            [
                                4.948998,
                                52.403914
                            ],
                            [
                                4.949613,
                                52.403647
                            ],
                            [
                                4.95009,
                                52.403428
                            ],
                            [
                                4.950449,
                                52.403261
                            ],
                            [
                                4.95111,
                                52.402942
                            ],
                            [
                                4.95181,
                                52.4026
                            ],
                            [
                                4.952269,
                                52.402355
                            ],
                            [
                                4.952767,
                                52.4021
                            ],
                            [
                                4.954339,
                                52.401234
                            ],
                            [
                                4.954661,
                                52.40105
                            ],
                            [
                                4.954738,
                                52.401003
                            ],
                            [
                                4.954784,
                                52.400976
                            ],
                            [
                                4.954847,
                                52.400938
                            ],
                            [
                                4.954911,
                                52.400899
                            ],
                            [
                                4.954989,
                                52.400853
                            ],
                            [
                                4.955186,
                                52.400734
                            ],
                            [
                                4.956051,
                                52.400187
                            ],
                            [
                                4.956641,
                                52.399796
                            ],
                            [
                                4.956916,
                                52.399614
                            ],
                            [
                                4.95729,
                                52.399362
                            ],
                            [
                                4.957688,
                                52.399077
                            ],
                            [
                                4.958107,
                                52.398765
                            ],
                            [
                                4.958523,
                                52.398461
                            ],
                            [
                                4.958965,
                                52.398109
                            ],
                            [
                                4.959336,
                                52.397822
                            ],
                            [
                                4.959632,
                                52.397577
                            ],
                            [
                                4.959815,
                                52.397424
                            ],
                            [
                                4.959835,
                                52.397407
                            ],
                            [
                                4.959858,
                                52.397384
                            ],
                            [
                                4.959965,
                                52.397291
                            ],
                            [
                                4.960285,
                                52.397008
                            ],
                            [
                                4.960653,
                                52.396675
                            ],
                            [
                                4.960911,
                                52.396435
                            ],
                            [
                                4.961282,
                                52.39607
                            ],
                            [
                                4.961695,
                                52.395656
                            ],
                            [
                                4.962003,
                                52.39533
                            ],
                            [
                                4.962417,
                                52.394879
                            ],
                            [
                                4.962615,
                                52.394635
                            ],
                            [
                                4.962878,
                                52.394291
                            ],
                            [
                                4.96307,
                                52.394066
                            ],
                            [
                                4.963244,
                                52.393861
                            ],
                            [
                                4.96327,
                                52.393826
                            ],
                            [
                                4.963304,
                                52.393786
                            ],
                            [
                                4.96354,
                                52.393464
                            ],
                            [
                                4.964059,
                                52.391094
                            ],
                            [
                                4.965466,
                                52.389387
                            ],
                            [
                                4.966454,
                                52.388399
                            ],
                            [
                                4.967244,
                                52.387845
                            ],
                            [
                                4.967869,
                                52.387854
                            ],
                            [
                                4.968452,
                                52.387158
                            ],
                            [
                                4.969628,
                                52.385945
                            ],
                            [
                                4.970263,
                                52.385121
                            ],
                            [
                                4.970758,
                                52.384323
                            ],
                            [
                                4.97105,
                                52.382934
                            ],
                            [
                                4.972064,
                                52.380235
                            ],
                            [
                                4.972256,
                                52.379799
                            ],
                            [
                                4.972357,
                                52.379572
                            ],
                            [
                                4.972563,
                                52.378056
                            ],
                            [
                                4.973208,
                                52.372567
                            ],
                            [
                                4.973295,
                                52.371826
                            ],
                            [
                                4.972489,
                                52.369705
                            ],
                            [
                                4.972427,
                                52.36935
                            ],
                            [
                                4.972134,
                                52.368562
                            ],
                            [
                                4.971676,
                                52.368334
                            ],
                            [
                                4.970756,
                                52.368642
                            ],
                            [
                                4.970499,
                                52.36829
                            ],
                            [
                                4.966871,
                                52.367186
                            ],
                            [
                                4.967152,
                                52.363076
                            ],
                            [
                                4.966317,
                                52.360112
                            ],
                            [
                                4.964031,
                                52.355182
                            ],
                            [
                                4.963351,
                                52.354003
                            ],
                            [
                                4.960983,
                                52.352894
                            ],
                            [
                                4.959399,
                                52.351391
                            ],
                            [
                                4.959479,
                                52.349763
                            ],
                            [
                                4.95946,
                                52.349724
                            ],
                            [
                                4.958709,
                                52.348141
                            ],
                            [
                                4.958423,
                                52.347871
                            ],
                            [
                                4.958649,
                                52.347747
                            ],
                            [
                                4.957859,
                                52.34695
                            ],
                            [
                                4.957539,
                                52.346713
                            ],
                            [
                                4.956318,
                                52.345434
                            ],
                            [
                                4.956208,
                                52.34532
                            ],
                            [
                                4.956057,
                                52.345186
                            ],
                            [
                                4.953262,
                                52.343936
                            ],
                            [
                                4.952706,
                                52.343829
                            ],
                            [
                                4.952029,
                                52.343438
                            ],
                            [
                                4.951592,
                                52.343171
                            ],
                            [
                                4.951298,
                                52.342613
                            ],
                            [
                                4.950744,
                                52.341435
                            ],
                            [
                                4.950558,
                                52.341103
                            ],
                            [
                                4.950946,
                                52.340919
                            ],
                            [
                                4.951168,
                                52.340477
                            ],
                            [
                                4.951142,
                                52.340442
                            ],
                            [
                                4.949958,
                                52.339819
                            ],
                            [
                                4.948615,
                                52.339252
                            ],
                            [
                                4.946201,
                                52.338546
                            ],
                            [
                                4.942556,
                                52.337646
                            ],
                            [
                                4.941744,
                                52.338168
                            ],
                            [
                                4.940325,
                                52.338738
                            ],
                            [
                                4.938932,
                                52.339091
                            ],
                            [
                                4.938386,
                                52.338652
                            ],
                            [
                                4.938604,
                                52.337611
                            ],
                            [
                                4.93824,
                                52.336144
                            ],
                            [
                                4.93589,
                                52.335244
                            ],
                            [
                                4.935629,
                                52.335516
                            ],
                            [
                                4.934226,
                                52.335498
                            ],
                            [
                                4.934225,
                                52.335498
                            ],
                            [
                                4.928673,
                                52.336521
                            ],
                            [
                                4.928306,
                                52.336802
                            ],
                            [
                                4.927875,
                                52.336617
                            ],
                            [
                                4.927971,
                                52.336376
                            ],
                            [
                                4.927691,
                                52.336279
                            ],
                            [
                                4.926774,
                                52.335966
                            ],
                            [
                                4.926947,
                                52.335778
                            ],
                            [
                                4.925778,
                                52.335374
                            ],
                            [
                                4.925498,
                                52.335385
                            ],
                            [
                                4.924805,
                                52.335386
                            ],
                            [
                                4.92457,
                                52.335404
                            ],
                            [
                                4.92377,
                                52.335444
                            ],
                            [
                                4.923773,
                                52.335269
                            ],
                            [
                                4.923756,
                                52.334956
                            ],
                            [
                                4.923731,
                                52.334812
                            ],
                            [
                                4.923682,
                                52.334632
                            ],
                            [
                                4.923635,
                                52.334509
                            ],
                            [
                                4.923517,
                                52.334258
                            ],
                            [
                                4.923411,
                                52.33409
                            ],
                            [
                                4.923296,
                                52.333941
                            ],
                            [
                                4.922935,
                                52.333663
                            ],
                            [
                                4.922437,
                                52.333288
                            ],
                            [
                                4.922444,
                                52.333218
                            ],
                            [
                                4.922555,
                                52.333086
                            ],
                            [
                                4.921679,
                                52.332762
                            ],
                            [
                                4.920372,
                                52.33236
                            ],
                            [
                                4.919594,
                                52.332168
                            ],
                            [
                                4.919591,
                                52.332167
                            ],
                            [
                                4.917911,
                                52.331767
                            ],
                            [
                                4.916919,
                                52.331369
                            ],
                            [
                                4.916906,
                                52.331366
                            ],
                            [
                                4.914573,
                                52.330765
                            ],
                            [
                                4.91418,
                                52.330679
                            ],
                            [
                                4.91348,
                                52.330935
                            ],
                            [
                                4.91297,
                                52.333781
                            ],
                            [
                                4.912564,
                                52.335794
                            ],
                            [
                                4.912617,
                                52.335793
                            ],
                            [
                                4.912536,
                                52.336218
                            ],
                            [
                                4.913853,
                                52.336661
                            ],
                            [
                                4.912253,
                                52.33898
                            ],
                            [
                                4.910562,
                                52.339115
                            ],
                            [
                                4.908703,
                                52.338364
                            ],
                            [
                                4.909928,
                                52.336612
                            ],
                            [
                                4.910322,
                                52.336541
                            ],
                            [
                                4.911163,
                                52.336236
                            ],
                            [
                                4.911831,
                                52.334817
                            ],
                            [
                                4.912001,
                                52.333731
                            ],
                            [
                                4.911929,
                                52.33344
                            ],
                            [
                                4.91199,
                                52.332702
                            ],
                            [
                                4.912208,
                                52.3308
                            ],
                            [
                                4.907916,
                                52.330265
                            ],
                            [
                                4.906806,
                                52.330177
                            ],
                            [
                                4.906293,
                                52.330255
                            ],
                            [
                                4.904415,
                                52.330979
                            ],
                            [
                                4.904379,
                                52.330988
                            ],
                            [
                                4.899343,
                                52.333629
                            ],
                            [
                                4.899268,
                                52.333651
                            ],
                            [
                                4.89916,
                                52.333721
                            ],
                            [
                                4.895429,
                                52.335586
                            ],
                            [
                                4.892401,
                                52.337782
                            ],
                            [
                                4.892816,
                                52.34116
                            ],
                            [
                                4.892879,
                                52.341672
                            ],
                            [
                                4.892951,
                                52.341828
                            ],
                            [
                                4.892881,
                                52.342044
                            ],
                            [
                                4.892767,
                                52.342124
                            ],
                            [
                                4.89257,
                                52.342366
                            ],
                            [
                                4.892145,
                                52.342781
                            ],
                            [
                                4.891607,
                                52.343338
                            ],
                            [
                                4.891145,
                                52.34382
                            ],
                            [
                                4.891118,
                                52.343879
                            ],
                            [
                                4.89095,
                                52.343968
                            ],
                            [
                                4.890842,
                                52.344079
                            ],
                            [
                                4.890839,
                                52.344203
                            ],
                            [
                                4.890512,
                                52.344402
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--02",
            "properties": {
                "land": "nl",
                "city": "Arnhem",
                "zone": "Milieuzone",
                "area_code": 1,
                "commercial": 2,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "petrol cars ok",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 1,
                "urlZone": "https://www.arnhem.nl/stad_en_wijken/Wegen_en_vervoer/milieuzone",
                "urlPark": "https://www.arnhem.nl/stad_en_wijken/Parkeren/parkeergarages_en_p_r",
                "urlReg": "https://www.arnhem.nl/Formulieren/Vergunningen_en_ontheffingen/Ontheffing_milieuzone",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            5.901803970336914,
                            51.98133834673074
                        ],
                        [
                            5.9021687507629395,
                            51.98063788431213
                        ],
                        [
                            5.90864896774292,
                            51.976738883903245
                        ],
                        [
                            5.909700393676758,
                            51.976368791654984
                        ],
                        [
                            5.911540389060974,
                            51.976415053353215
                        ],
                        [
                            5.911594033241272,
                            51.976758710187404
                        ],
                        [
                            5.912441611289977,
                            51.97684792835758
                        ],
                        [
                            5.91339647769928,
                            51.978116789768016
                        ],
                        [
                            5.91364324092865,
                            51.97893293944344
                        ],
                        [
                            5.915477871894836,
                            51.979071716451664
                        ],
                        [
                            5.916309356689453,
                            51.978837116972564
                        ],
                        [
                            5.916014313697815,
                            51.98051893677578
                        ],
                        [
                            5.915204286575317,
                            51.98216104567841
                        ],
                        [
                            5.915290117263794,
                            51.98274584658581
                        ],
                        [
                            5.914254784584045,
                            51.984335012745824
                        ],
                        [
                            5.913310647010803,
                            51.98393524860382
                        ],
                        [
                            5.911974906921387,
                            51.984374658581956
                        ],
                        [
                            5.910875201225281,
                            51.98563339563447
                        ],
                        [
                            5.904722213745117,
                            51.98484049452794
                        ],
                        [
                            5.905473232269286,
                            51.98420285970525
                        ],
                        [
                            5.9054142236709595,
                            51.983786575078824
                        ],
                        [
                            5.90465247631073,
                            51.98349913819779
                        ],
                        [
                            5.902876853942871,
                            51.983525569252365
                        ],
                        [
                            5.9022921323776245,
                            51.98150354853506
                        ],
                        [
                            5.901803970336914,
                            51.98133834673074
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--03",
            "properties": {
                "land": "nl",
                "city": "Utrecht",
                "zone": "Milieuzone",
                "area_code": 0,
                "commercial": 2,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "petrol cars ok",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 1,
                "urlZone": "https://www.utrecht.nl/city-of-utrecht/mobility/",
                "urlPark": "https://goedopweg.nl/en/pr",
                "urlReg": "https://pki.utrecht.nl/Loket/product/129ca97535c459e338a1cc4c3d52ac4f",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            5.105049689560911,
                            52.09075123197346
                        ],
                        [
                            5.105098503746111,
                            52.09108361076584
                        ],
                        [
                            5.104462472782855,
                            52.092529907927364
                        ],
                        [
                            5.104471691847675,
                            52.09298669049557
                        ],
                        [
                            5.104910639995647,
                            52.093142126509306
                        ],
                        [
                            5.107089877772292,
                            52.09425066597813
                        ],
                        [
                            5.108680188118258,
                            52.09522947785948
                        ],
                        [
                            5.108868656744279,
                            52.09538157678579
                        ],
                        [
                            5.10938409049346,
                            52.09566269851962
                        ],
                        [
                            5.109650574085776,
                            52.09568196729845
                        ],
                        [
                            5.112897255089789,
                            52.09668630436986
                        ],
                        [
                            5.113002050577712,
                            52.096774496254376
                        ],
                        [
                            5.113001414588458,
                            52.096880625776656
                        ],
                        [
                            5.112376872961876,
                            52.097582739144855
                        ],
                        [
                            5.112005854452548,
                            52.0978151499431
                        ],
                        [
                            5.112492724389703,
                            52.0979643903557
                        ],
                        [
                            5.114001183082738,
                            52.09847587273851
                        ],
                        [
                            5.11590887142282,
                            52.096519836114176
                        ],
                        [
                            5.11615214931891,
                            52.09655193637732
                        ],
                        [
                            5.117494462896299,
                            52.09657534510079
                        ],
                        [
                            5.117795403962078,
                            52.09657148506074
                        ],
                        [
                            5.119859549694115,
                            52.09651823937417
                        ],
                        [
                            5.120552409787429,
                            52.0971659938647
                        ],
                        [
                            5.120662243700295,
                            52.09724075245981
                        ],
                        [
                            5.1209634278579,
                            52.097329785079694
                        ],
                        [
                            5.121270732643809,
                            52.097338689957624
                        ],
                        [
                            5.122602776317607,
                            52.09696248213411
                        ],
                        [
                            5.124308437716378,
                            52.097559662998535
                        ],
                        [
                            5.124283931969676,
                            52.097661488060325
                        ],
                        [
                            5.124263107643206,
                            52.09772336932373
                        ],
                        [
                            5.124701812801741,
                            52.098870723147805
                        ],
                        [
                            5.126380240221647,
                            52.09932046719612
                        ],
                        [
                            5.127291919976844,
                            52.09872093119232
                        ],
                        [
                            5.12752491837036,
                            52.09842060979821
                        ],
                        [
                            5.127605921940223,
                            52.09814290453511
                        ],
                        [
                            5.12764476218757,
                            52.09795864843366
                        ],
                        [
                            5.127379934308859,
                            52.09790997580578
                        ],
                        [
                            5.127357797089326,
                            52.09763939561948
                        ],
                        [
                            5.127021270068424,
                            52.096948419603585
                        ],
                        [
                            5.126709936527199,
                            52.09637427785941
                        ],
                        [
                            5.126523551315422,
                            52.09609059340496
                        ],
                        [
                            5.126474342076585,
                            52.09567756432781
                        ],
                        [
                            5.126635177553893,
                            52.09519337368648
                        ],
                        [
                            5.126712618085757,
                            52.09495610726965
                        ],
                        [
                            5.126781418423248,
                            52.09463367784183
                        ],
                        [
                            5.126890826205734,
                            52.094529972520775
                        ],
                        [
                            5.127069355258593,
                            52.09444707415577
                        ],
                        [
                            5.128890001592835,
                            52.09359693227355
                        ],
                        [
                            5.129026644874319,
                            52.09342714365142
                        ],
                        [
                            5.128676257026798,
                            52.09327106533711
                        ],
                        [
                            5.128228570168072,
                            52.0929787388487
                        ],
                        [
                            5.128162642004656,
                            52.092875429087094
                        ],
                        [
                            5.128248215941188,
                            52.09282922067655
                        ],
                        [
                            5.129120368533557,
                            52.09269984414409
                        ],
                        [
                            5.12968507227625,
                            52.092535177119075
                        ],
                        [
                            5.130080470856332,
                            52.092371082086146
                        ],
                        [
                            5.131243371229691,
                            52.09185139678374
                        ],
                        [
                            5.132436896189553,
                            52.091394283232454
                        ],
                        [
                            5.1328004076374,
                            52.09122215064527
                        ],
                        [
                            5.131272069632105,
                            52.09018130643856
                        ],
                        [
                            5.130236309453735,
                            52.08949357012917
                        ],
                        [
                            5.129825368497699,
                            52.08917312119496
                        ],
                        [
                            5.129360800577897,
                            52.08873201569498
                        ],
                        [
                            5.129711732355597,
                            52.088462445685714
                        ],
                        [
                            5.130390398230617,
                            52.08825707617685
                        ],
                        [
                            5.131810881807233,
                            52.0887556460785
                        ],
                        [
                            5.132096165689728,
                            52.08867730632741
                        ],
                        [
                            5.130959383749741,
                            52.086986031810525
                        ],
                        [
                            5.130551523898511,
                            52.08609029478522
                        ],
                        [
                            5.130440398412465,
                            52.0854707851418
                        ],
                        [
                            5.130355435251095,
                            52.08532455984321
                        ],
                        [
                            5.130137294321912,
                            52.08517105563533
                        ],
                        [
                            5.129891029298566,
                            52.084989139186575
                        ],
                        [
                            5.129068481402438,
                            52.08457347647733
                        ],
                        [
                            5.128370329586643,
                            52.08415679583396
                        ],
                        [
                            5.128024076074073,
                            52.08390028857095
                        ],
                        [
                            5.127207676152119,
                            52.083047050858546
                        ],
                        [
                            5.126849293243663,
                            52.08261216295366
                        ],
                        [
                            5.126402564965165,
                            52.081812882285
                        ],
                        [
                            5.126234103784111,
                            52.08151250716258
                        ],
                        [
                            5.126017919691477,
                            52.08126777872041
                        ],
                        [
                            5.125856124163716,
                            52.08113052819188
                        ],
                        [
                            5.126447174015164,
                            52.080895369587445
                        ],
                        [
                            5.126442655858288,
                            52.080781974993045
                        ],
                        [
                            5.125656951851122,
                            52.081087447067894
                        ],
                        [
                            5.125315789794152,
                            52.08101213362
                        ],
                        [
                            5.125112434455248,
                            52.08103832238603
                        ],
                        [
                            5.124614500040209,
                            52.08116057576328
                        ],
                        [
                            5.12446743354946,
                            52.081155646216224
                        ],
                        [
                            5.124397990946046,
                            52.08113355154209
                        ],
                        [
                            5.124255945595614,
                            52.08098913795058
                        ],
                        [
                            5.124179362801854,
                            52.080906150966484
                        ],
                        [
                            5.124041428343045,
                            52.08090390173045
                        ],
                        [
                            5.123940951366588,
                            52.08085546535377
                        ],
                        [
                            5.123749015849742,
                            52.080899620152294
                        ],
                        [
                            5.122977786595553,
                            52.07948174286917
                        ],
                        [
                            5.122099187545779,
                            52.07823022484264
                        ],
                        [
                            5.120414331685519,
                            52.07888556531637
                        ],
                        [
                            5.120130012747532,
                            52.07901385598062
                        ],
                        [
                            5.119699864057401,
                            52.07923507597372
                        ],
                        [
                            5.118818987921457,
                            52.07981087798268
                        ],
                        [
                            5.117979071866039,
                            52.080283967446974
                        ],
                        [
                            5.117526447344788,
                            52.080459632170154
                        ],
                        [
                            5.116414848972156,
                            52.080641955568645
                        ],
                        [
                            5.115537519978322,
                            52.080880558762246
                        ],
                        [
                            5.114262155133042,
                            52.08220234363584
                        ],
                        [
                            5.113220624635954,
                            52.0825333036633
                        ],
                        [
                            5.11242569020259,
                            52.082762570008754
                        ],
                        [
                            5.111604881271249,
                            52.08276073499886
                        ],
                        [
                            5.111098428368805,
                            52.08261115680079
                        ],
                        [
                            5.110703856808539,
                            52.08256561161208
                        ],
                        [
                            5.108706295896858,
                            52.08473705329245
                        ],
                        [
                            5.108482827852913,
                            52.08464548496768
                        ],
                        [
                            5.106921110368162,
                            52.08313889478399
                        ],
                        [
                            5.10633637871707,
                            52.082678670638714
                        ],
                        [
                            5.105079300690246,
                            52.08174929439315
                        ],
                        [
                            5.103387812997237,
                            52.082638945501
                        ],
                        [
                            5.101727531954891,
                            52.08144626834643
                        ],
                        [
                            5.101458280671475,
                            52.081285093205786
                        ],
                        [
                            5.100886319563955,
                            52.081073985931084
                        ],
                        [
                            5.098833987103732,
                            52.08315655675949
                        ],
                        [
                            5.098664354388421,
                            52.083279503157264
                        ],
                        [
                            5.098381938325256,
                            52.08343531212484
                        ],
                        [
                            5.099791450590598,
                            52.08447353419502
                        ],
                        [
                            5.094285676401295,
                            52.087343662985774
                        ],
                        [
                            5.098401108718834,
                            52.08866108882354
                        ],
                        [
                            5.098515900228348,
                            52.088699023582784
                        ],
                        [
                            5.104010976964754,
                            52.09040237746878
                        ],
                        [
                            5.105049689560911,
                            52.09075123197346
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--04",
            "properties": {
                "land": "nl",
                "city": "Den Haag",
                "zone": "Milieuzone",
                "area_code": 0,
                "commercial": 2,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "petrol cars ok",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 1,
                "urlZone": "https://www.denhaag.nl/en/in-the-city/getting-there-and-around/low-emission-zones-in-the-hague.htm",
                "urlPark": "https://www.denhaag.nl/nl/parkeren/waar-parkeren-en-kosten/slim-parkeren-park-ride-pr.htm",
                "urlReg": "https://secure.denhaag.nl/aselectserver/server?request=login1&a-select-server=secure.denhaag.nl&rid=REFAFF93691F59921B96B612EC65D815820C713FB",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.32935100033936,
                            52.0754270001922
                        ],
                        [
                            4.32950100005326,
                            52.0748940004241
                        ],
                        [
                            4.32975299995917,
                            52.0743430001178
                        ],
                        [
                            4.32990799975379,
                            52.0741840002156
                        ],
                        [
                            4.33045000047847,
                            52.0739860002351
                        ],
                        [
                            4.33139400047625,
                            52.0736790002655
                        ],
                        [
                            4.33208100024903,
                            52.0733919996686
                        ],
                        [
                            4.33251599978715,
                            52.0730959997547
                        ],
                        [
                            4.33282300028858,
                            52.0729040000364
                        ],
                        [
                            4.3331609995934,
                            52.0726050002203
                        ],
                        [
                            4.33344600009809,
                            52.0723049995921
                        ],
                        [
                            4.33388400042945,
                            52.0719180000747
                        ],
                        [
                            4.33413899941357,
                            52.0717340003096
                        ],
                        [
                            4.33462999939557,
                            52.0712670003604
                        ],
                        [
                            4.33531299961005,
                            52.0705840000062
                        ],
                        [
                            4.33624099991997,
                            52.0696010000692
                        ],
                        [
                            4.33451400024839,
                            52.0690960002293
                        ],
                        [
                            4.33380599980442,
                            52.0689050004309
                        ],
                        [
                            4.33246700047223,
                            52.0686249999538
                        ],
                        [
                            4.33180500056671,
                            52.0685329997727
                        ],
                        [
                            4.33122499945436,
                            52.0684339996856
                        ],
                        [
                            4.33042099939071,
                            52.0682129998762
                        ],
                        [
                            4.32962700012116,
                            52.0679460000336
                        ],
                        [
                            4.32699299990007,
                            52.0668949998224
                        ],
                        [
                            4.32565999928677,
                            52.0663649996269
                        ],
                        [
                            4.32434599935565,
                            52.0658469999843
                        ],
                        [
                            4.32318199944365,
                            52.065379999534
                        ],
                        [
                            4.32264799950879,
                            52.0651400001886
                        ],
                        [
                            4.32215699966215,
                            52.0648870002302
                        ],
                        [
                            4.3201380007036,
                            52.063761999822
                        ],
                        [
                            4.31907000069792,
                            52.0631659995713
                        ],
                        [
                            4.31810100024862,
                            52.0626130002007
                        ],
                        [
                            4.31733200021118,
                            52.0621810000552
                        ],
                        [
                            4.31667000025529,
                            52.0617459996091
                        ],
                        [
                            4.31624599959963,
                            52.0615180003344
                        ],
                        [
                            4.31609700027024,
                            52.0615900000367
                        ],
                        [
                            4.31590799941317,
                            52.0617190001406
                        ],
                        [
                            4.3159589996032,
                            52.0618060004062
                        ],
                        [
                            4.31588199998759,
                            52.0619029997121
                        ],
                        [
                            4.31575700058197,
                            52.0620049996734
                        ],
                        [
                            4.31531600047054,
                            52.0622780000708
                        ],
                        [
                            4.3151790001227,
                            52.0623790003386
                        ],
                        [
                            4.31506199987826,
                            52.0624549996145
                        ],
                        [
                            4.31492399960653,
                            52.0623719998475
                        ],
                        [
                            4.31452300027809,
                            52.0626430000837
                        ],
                        [
                            4.31406900027804,
                            52.062933000156
                        ],
                        [
                            4.31325999962554,
                            52.0635360001511
                        ],
                        [
                            4.31308499929431,
                            52.0636379998858
                        ],
                        [
                            4.31284500040724,
                            52.063717000327
                        ],
                        [
                            4.31241399952477,
                            52.0638870000466
                        ],
                        [
                            4.31214899945816,
                            52.0639200002691
                        ],
                        [
                            4.31204099971879,
                            52.0639330001878
                        ],
                        [
                            4.31194600053782,
                            52.0639200000719
                        ],
                        [
                            4.31182899993105,
                            52.0639219999041
                        ],
                        [
                            4.31155500027724,
                            52.0639850001799
                        ],
                        [
                            4.31138399983742,
                            52.0640280003353
                        ],
                        [
                            4.31118399962926,
                            52.0640700000743
                        ],
                        [
                            4.31083199977109,
                            52.0641600002828
                        ],
                        [
                            4.31074600010126,
                            52.0642199999605
                        ],
                        [
                            4.30760399957697,
                            52.0649480003792
                        ],
                        [
                            4.30718000018048,
                            52.065062000411
                        ],
                        [
                            4.30695999985695,
                            52.0651520001583
                        ],
                        [
                            4.30635400037503,
                            52.0653959998945
                        ],
                        [
                            4.30533400016266,
                            52.0659339997746
                        ],
                        [
                            4.30465100049545,
                            52.0664619997497
                        ],
                        [
                            4.304175999459,
                            52.0669539999596
                        ],
                        [
                            4.3031620007126,
                            52.0680430003281
                        ],
                        [
                            4.30269500000009,
                            52.0685769999094
                        ],
                        [
                            4.30211100069553,
                            52.0692010004287
                        ],
                        [
                            4.30111800014415,
                            52.0702899997941
                        ],
                        [
                            4.30119799932875,
                            52.0704449995484
                        ],
                        [
                            4.3011799998262,
                            52.0705949997213
                        ],
                        [
                            4.30104799955597,
                            52.0707649996782
                        ],
                        [
                            4.301081999706,
                            52.0708879997144
                        ],
                        [
                            4.30114800011292,
                            52.0710069999124
                        ],
                        [
                            4.30118100009738,
                            52.071077000204
                        ],
                        [
                            4.30114499932603,
                            52.0712129998298
                        ],
                        [
                            4.30071400036636,
                            52.0716790000383
                        ],
                        [
                            4.30025599962795,
                            52.0721910003
                        ],
                        [
                            4.30033600012238,
                            52.0722610002774
                        ],
                        [
                            4.3003189995671,
                            52.0723829996685
                        ],
                        [
                            4.30025199988738,
                            52.0724960002608
                        ],
                        [
                            4.30013399994656,
                            52.072623000341
                        ],
                        [
                            4.29993400013805,
                            52.0727419995536
                        ],
                        [
                            4.29932300013636,
                            52.0734539996047
                        ],
                        [
                            4.29978800001287,
                            52.0735949995597
                        ],
                        [
                            4.30023800065103,
                            52.0737520003736
                        ],
                        [
                            4.30103900040381,
                            52.0740740000526
                        ],
                        [
                            4.30095199957078,
                            52.0741759995662
                        ],
                        [
                            4.30140500054151,
                            52.0743470001086
                        ],
                        [
                            4.30245300069704,
                            52.0747920002044
                        ],
                        [
                            4.30349799955667,
                            52.0752379996082
                        ],
                        [
                            4.30318700041483,
                            52.0754920000503
                        ],
                        [
                            4.30302000053133,
                            52.0754139997903
                        ],
                        [
                            4.30262299986958,
                            52.0756760001335
                        ],
                        [
                            4.30137400006407,
                            52.0751790003968
                        ],
                        [
                            4.3009140000401,
                            52.0749449996723
                        ],
                        [
                            4.30078599983339,
                            52.0750630003363
                        ],
                        [
                            4.30068099949782,
                            52.075172999951
                        ],
                        [
                            4.30049599986391,
                            52.0751269999054
                        ],
                        [
                            4.30021300060293,
                            52.0750560002355
                        ],
                        [
                            4.30006299981381,
                            52.0750530000687
                        ],
                        [
                            4.29997700002068,
                            52.0751139996461
                        ],
                        [
                            4.29985799934208,
                            52.0752330001151
                        ],
                        [
                            4.29935499993749,
                            52.0750389999364
                        ],
                        [
                            4.29861299935666,
                            52.0747510000431
                        ],
                        [
                            4.29792600034557,
                            52.074496999609
                        ],
                        [
                            4.29778300008739,
                            52.0742250000531
                        ],
                        [
                            4.2975830006757,
                            52.0742270004377
                        ],
                        [
                            4.29723999934092,
                            52.0742089998909
                        ],
                        [
                            4.29681000070002,
                            52.0741449995693
                        ],
                        [
                            4.29528699951501,
                            52.0758519998844
                        ],
                        [
                            4.29490600056207,
                            52.0762600003821
                        ],
                        [
                            4.29481499978134,
                            52.0764719995687
                        ],
                        [
                            4.29482299989776,
                            52.0765490003824
                        ],
                        [
                            4.2947210006359,
                            52.0768860002738
                        ],
                        [
                            4.29314200009588,
                            52.078059999893
                        ],
                        [
                            4.29229499995044,
                            52.0786970004323
                        ],
                        [
                            4.29142299997974,
                            52.079321999799
                        ],
                        [
                            4.2897679995633,
                            52.0805529999006
                        ],
                        [
                            4.28806899931113,
                            52.0817639999635
                        ],
                        [
                            4.28745399935588,
                            52.0822159995375
                        ],
                        [
                            4.28722600056678,
                            52.0824630000689
                        ],
                        [
                            4.28653399982317,
                            52.0830249995505
                        ],
                        [
                            4.28351899933399,
                            52.085207000332
                        ],
                        [
                            4.28165299933084,
                            52.0865259997289
                        ],
                        [
                            4.28125200042909,
                            52.0869340001606
                        ],
                        [
                            4.2809099994855,
                            52.0873180003512
                        ],
                        [
                            4.28057699980386,
                            52.0878689998795
                        ],
                        [
                            4.28171599962873,
                            52.0883399999678
                        ],
                        [
                            4.2827420004711,
                            52.0887440000665
                        ],
                        [
                            4.28310899967941,
                            52.0890010000504
                        ],
                        [
                            4.28333300024331,
                            52.0891559998416
                        ],
                        [
                            4.28346599929806,
                            52.089334000351
                        ],
                        [
                            4.28340999968014,
                            52.0894970000645
                        ],
                        [
                            4.28345599986658,
                            52.089858999726
                        ],
                        [
                            4.2835059995655,
                            52.0903769996612
                        ],
                        [
                            4.28368000057466,
                            52.0908710003324
                        ],
                        [
                            4.28386799933168,
                            52.0913600000599
                        ],
                        [
                            4.28407600041957,
                            52.0918380001627
                        ],
                        [
                            4.28430999976097,
                            52.0923189999855
                        ],
                        [
                            4.28480500065737,
                            52.0932869998109
                        ],
                        [
                            4.28555299950716,
                            52.0944780000422
                        ],
                        [
                            4.2860299996492,
                            52.0951729996495
                        ],
                        [
                            4.28629500068038,
                            52.0954360004069
                        ],
                        [
                            4.28649900018255,
                            52.0955739997676
                        ],
                        [
                            4.28693200014809,
                            52.0957700000445
                        ],
                        [
                            4.28761499979393,
                            52.0960329999611
                        ],
                        [
                            4.28849999951424,
                            52.096307999537
                        ],
                        [
                            4.28946400044751,
                            52.0965610002233
                        ],
                        [
                            4.29092600042287,
                            52.0969080002005
                        ],
                        [
                            4.29234200060772,
                            52.0972220000976
                        ],
                        [
                            4.29432500012225,
                            52.0976650003072
                        ],
                        [
                            4.29603600067969,
                            52.0980569998033
                        ],
                        [
                            4.29689999950898,
                            52.0982350000981
                        ],
                        [
                            4.29775500055856,
                            52.0983980003548
                        ],
                        [
                            4.29831199983319,
                            52.0984520003743
                        ],
                        [
                            4.29887400004573,
                            52.0984669996452
                        ],
                        [
                            4.29940399996118,
                            52.0984409999925
                        ],
                        [
                            4.29988099972012,
                            52.0983880004091
                        ],
                        [
                            4.30056799950135,
                            52.0982029997555
                        ],
                        [
                            4.30151200000631,
                            52.0978010004291
                        ],
                        [
                            4.30376899941775,
                            52.0968089995827
                        ],
                        [
                            4.30503999934127,
                            52.096414000258
                        ],
                        [
                            4.30626799963566,
                            52.0962689995553
                        ],
                        [
                            4.30657399954976,
                            52.0961929998082
                        ],
                        [
                            4.30677499940212,
                            52.096137999778
                        ],
                        [
                            4.30690199959259,
                            52.0960450000238
                        ],
                        [
                            4.3080550006926,
                            52.094822000204
                        ],
                        [
                            4.30945500009099,
                            52.0931969999384
                        ],
                        [
                            4.3112789993824,
                            52.0909559998138
                        ],
                        [
                            4.31228400028984,
                            52.0897610000981
                        ],
                        [
                            4.31325200024405,
                            52.0885400000503
                        ],
                        [
                            4.31498000051188,
                            52.0863939995983
                        ],
                        [
                            4.31612299969428,
                            52.0850640000895
                        ],
                        [
                            4.31760799966906,
                            52.0835750001184
                        ],
                        [
                            4.31773600003481,
                            52.0834879997142
                        ],
                        [
                            4.31797199985382,
                            52.0834110002755
                        ],
                        [
                            4.31846700049477,
                            52.0832559996267
                        ],
                        [
                            4.32104699996496,
                            52.0821480001925
                        ],
                        [
                            4.32144899939779,
                            52.0819030000881
                        ],
                        [
                            4.32252499935431,
                            52.0810439998692
                        ],
                        [
                            4.32285299939325,
                            52.0806959998083
                        ],
                        [
                            4.32391099936447,
                            52.079844999757
                        ],
                        [
                            4.3247400006718,
                            52.0791869995491
                        ],
                        [
                            4.32678399965403,
                            52.0779560002318
                        ],
                        [
                            4.3279630002578,
                            52.0769720001347
                        ],
                        [
                            4.32921199998549,
                            52.0759759995436
                        ],
                        [
                            4.32935100033936,
                            52.0754270001922
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--05",
            "properties": {
                "land": "nl",
                "city": "Breda",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.breda.nl/ontheffing-milieuzone",
                "urlPark": "-",
                "urlReg": "https://www.breda.nl/ontheffing-milieuzone",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.774775571144403,
                            51.58348468539563
                        ],
                        [
                            4.774604114596002,
                            51.58317072045958
                        ],
                        [
                            4.7744537980065616,
                            51.58289542535326
                        ],
                        [
                            4.773757597692821,
                            51.5822956672057
                        ],
                        [
                            4.773243356727676,
                            51.582025282891664
                        ],
                        [
                            4.771803482022307,
                            51.58171556797291
                        ],
                        [
                            4.77120221566318,
                            51.581617245335025
                        ],
                        [
                            4.77087784828467,
                            51.581509090187694
                        ],
                        [
                            4.770513923907714,
                            51.58112562895454
                        ],
                        [
                            4.770395252915989,
                            51.58060942603103
                        ],
                        [
                            4.770481137391727,
                            51.57960933151017
                        ],
                        [
                            4.7705918969839445,
                            51.578965284515334
                        ],
                        [
                            4.770686833778569,
                            51.57887187236125
                        ],
                        [
                            4.771264365939345,
                            51.57897020093918
                        ],
                        [
                            4.772870380033055,
                            51.579324182058286
                        ],
                        [
                            4.773653608580645,
                            51.579599498799894
                        ],
                        [
                            4.776303917826937,
                            51.580469682037204
                        ],
                        [
                            4.7786773376693645,
                            51.581128456284205
                        ],
                        [
                            4.780633899032324,
                            51.581847734749516
                        ],
                        [
                            4.781361747783791,
                            51.58227543450471
                        ],
                        [
                            4.782263647324356,
                            51.583042334329804
                        ],
                        [
                            4.784945760975262,
                            51.585529071558824
                        ],
                        [
                            4.786923599046418,
                            51.58764768850975
                        ],
                        [
                            4.787936258179258,
                            51.58883230489789
                        ],
                        [
                            4.789050631301194,
                            51.590588688195794
                        ],
                        [
                            4.7894620240736,
                            51.59161103133971
                        ],
                        [
                            4.789343355859302,
                            51.59232681594284
                        ],
                        [
                            4.788908228888573,
                            51.59297558847328
                        ],
                        [
                            4.788267405496271,
                            51.593196758821165
                        ],
                        [
                            4.785253156744574,
                            51.59337369384974
                        ],
                        [
                            4.783433534864656,
                            51.593570288033845
                        ],
                        [
                            4.782666129104825,
                            51.593383523593275
                        ],
                        [
                            4.781795869047897,
                            51.592906768391174
                        ],
                        [
                            4.780435108338224,
                            51.592798640115774
                        ],
                        [
                            4.779359157771751,
                            51.59279372519748
                        ],
                        [
                            4.778607574715352,
                            51.593034556037566
                        ],
                        [
                            4.77729428240238,
                            51.59308861992645
                        ],
                        [
                            4.775149716552193,
                            51.5931082800131
                        ],
                        [
                            4.77306110672302,
                            51.59315251398533
                        ],
                        [
                            4.77151838377344,
                            51.592847789817
                        ],
                        [
                            4.7708854725497645,
                            51.59241527456706
                        ],
                        [
                            4.7693743952506225,
                            51.59180089929467
                        ],
                        [
                            4.767341165585634,
                            51.59105872288396
                        ],
                        [
                            4.7651971774231185,
                            51.590228061043746
                        ],
                        [
                            4.764152872672128,
                            51.589618571478724
                        ],
                        [
                            4.763535047279163,
                            51.588615277500736
                        ],
                        [
                            4.763155300104529,
                            51.58723895022678
                        ],
                        [
                            4.763329348870883,
                            51.58601496575133
                        ],
                        [
                            4.764025552024606,
                            51.58481061273952
                        ],
                        [
                            4.765228084744081,
                            51.58362097556741
                        ],
                        [
                            4.7658926423000025,
                            51.5838372755517
                        ],
                        [
                            4.7668736558349565,
                            51.58474179160305
                        ],
                        [
                            4.767379985401988,
                            51.58540541964521
                        ],
                        [
                            4.768558783923112,
                            51.58507114895403
                        ],
                        [
                            4.772631581885918,
                            51.58391236974347
                        ],
                        [
                            4.774775571144403,
                            51.58348468539563
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--06",
            "properties": {
                "land": "nl",
                "city": "Delft",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.delft.nl/wonen/parkeren-en-verkeer/vrachtverkeer/milieuzone",
                "urlPark": "https://parkerendelft.com/parkeerloket/ontheffing-milieuzone/",
                "urlReg": "https://vergunningen.parkerendelft.com/Account/LogOn?ReturnUrl=%2f",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.3600471438114425,
                            52.00517427509948
                        ],
                        [
                            4.361112474753611,
                            52.005567895445694
                        ],
                        [
                            4.363066765574757,
                            52.00638057451695
                        ],
                        [
                            4.365529215844276,
                            52.007567885214314
                        ],
                        [
                            4.365872045519211,
                            52.00719200111581
                        ],
                        [
                            4.366617322318689,
                            52.00637983508116
                        ],
                        [
                            4.36702338185205,
                            52.00604588457884
                        ],
                        [
                            4.367451124911469,
                            52.00600261776276
                        ],
                        [
                            4.36791900409159,
                            52.00620782562706
                        ],
                        [
                            4.3682844934664615,
                            52.00640314317016
                        ],
                        [
                            4.36938296911643,
                            52.006691173011774
                        ],
                        [
                            4.369790630342095,
                            52.00676287118148
                        ],
                        [
                            4.370083825115586,
                            52.006888960787194
                        ],
                        [
                            4.370598825305791,
                            52.00695549919803
                        ],
                        [
                            4.37126152582087,
                            52.007208913130825
                        ],
                        [
                            4.371351961431969,
                            52.00695969565393
                        ],
                        [
                            4.3726588082034255,
                            52.006258383517945
                        ],
                        [
                            4.3729339293252565,
                            52.00641537915425
                        ],
                        [
                            4.3736523038832615,
                            52.00598522287805
                        ],
                        [
                            4.374118202426644,
                            52.00562054362345
                        ],
                        [
                            4.375312131739179,
                            52.004848695846306
                        ],
                        [
                            4.375418693183121,
                            52.00478664300792
                        ],
                        [
                            4.375323651895286,
                            52.00475295714534
                        ],
                        [
                            4.376712514260447,
                            52.002732426223844
                        ],
                        [
                            4.376785630600239,
                            52.00275486412161
                        ],
                        [
                            4.37769003296367,
                            52.00145284722282
                        ],
                        [
                            4.3838947223327125,
                            52.00306094544629
                        ],
                        [
                            4.384587769863515,
                            52.003422177275354
                        ],
                        [
                            4.383843898847374,
                            52.00411903359074
                        ],
                        [
                            4.382271107129043,
                            52.00561964310816
                        ],
                        [
                            4.381968454265461,
                            52.00597664926113
                        ],
                        [
                            4.381663513351839,
                            52.00592260997399
                        ],
                        [
                            4.381372433390169,
                            52.00634354585472
                        ],
                        [
                            4.381229203566278,
                            52.00636629903315
                        ],
                        [
                            4.381049011209029,
                            52.00645731162942
                        ],
                        [
                            4.380771792196441,
                            52.00645731162942
                        ],
                        [
                            4.3810351502584695,
                            52.006935124725175
                        ],
                        [
                            4.380195299493096,
                            52.007723651092874
                        ],
                        [
                            4.3790171186915074,
                            52.00826686330831
                        ],
                        [
                            4.37727451010619,
                            52.008924223496166
                        ],
                        [
                            4.376355067078151,
                            52.009197247201996
                        ],
                        [
                            4.376137912186124,
                            52.00918018339905
                        ],
                        [
                            4.3758745541240955,
                            52.00933944530723
                        ],
                        [
                            4.37265695303708,
                            52.010056085717906
                        ],
                        [
                            4.372412076242426,
                            52.010374602733236
                        ],
                        [
                            4.372855539006451,
                            52.01166565658613
                        ],
                        [
                            4.374029099491253,
                            52.01214910163151
                        ],
                        [
                            4.373816564914591,
                            52.01231119674199
                        ],
                        [
                            4.373632101377751,
                            52.012515750227095
                        ],
                        [
                            4.37379843278589,
                            52.01258115656057
                        ],
                        [
                            4.372851267826803,
                            52.01342289910528
                        ],
                        [
                            4.370816702098438,
                            52.01438222057874
                        ],
                        [
                            4.368112988936076,
                            52.01325026271974
                        ],
                        [
                            4.3671674498959305,
                            52.01453570768854
                        ],
                        [
                            4.366756241694645,
                            52.014470304211784
                        ],
                        [
                            4.366197183352625,
                            52.01425418769617
                        ],
                        [
                            4.365305462197284,
                            52.013839013566496
                        ],
                        [
                            4.365176093325431,
                            52.0136513308459
                        ],
                        [
                            4.36688784405905,
                            52.012642271292435
                        ],
                        [
                            4.368602223714618,
                            52.01158929436164
                        ],
                        [
                            4.369156661739737,
                            52.010889710501004
                        ],
                        [
                            4.365368636965854,
                            52.00937737071206
                        ],
                        [
                            4.365710540414369,
                            52.00906737852429
                        ],
                        [
                            4.362607524538504,
                            52.0077548098628
                        ],
                        [
                            4.362746134044073,
                            52.00751875240172
                        ],
                        [
                            4.362917085767606,
                            52.00745333866789
                        ],
                        [
                            4.362732273093513,
                            52.007003972174005
                        ],
                        [
                            4.362524358833724,
                            52.006745157778624
                        ],
                        [
                            4.362205556970963,
                            52.00648065360832
                        ],
                        [
                            4.361867548756351,
                            52.006320482044686
                        ],
                        [
                            4.360689367856793,
                            52.005899545906715
                        ],
                        [
                            4.3597098606797715,
                            52.00551558047292
                        ],
                        [
                            4.3600471438114425,
                            52.00517427509948
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--07",
            "properties": {
                "land": "nl",
                "city": "Eindhoven",
                "zone": "Milieuzone",
                "area_code": 3,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel truck/bus)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.eindhoven.nl/stad-en-wonen/verkeer/milieuzone",
                "urlPark": "https://www.thisiseindhoven.com/nl/zoeken?searchterm=parkeren",
                "urlReg": "https://www.eindhoven.nl/stad-en-wonen/verkeer/milieuzone",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            5.502154144459183,
                            51.44462906912375
                        ],
                        [
                            5.502118175861597,
                            51.44466077580998
                        ],
                        [
                            5.5020872342324765,
                            51.444700011882475
                        ],
                        [
                            5.500831642825716,
                            51.44610349469218
                        ],
                        [
                            5.5001366523211175,
                            51.446419482413006
                        ],
                        [
                            5.499606864477727,
                            51.446824227535615
                        ],
                        [
                            5.4994701450338255,
                            51.44726092219622
                        ],
                        [
                            5.49924797593809,
                            51.447963885317165
                        ],
                        [
                            5.4984499332013,
                            51.448931749096516
                        ],
                        [
                            5.497749246053274,
                            51.449748291522184
                        ],
                        [
                            5.496810021624242,
                            51.45065137775336
                        ],
                        [
                            5.495699176146331,
                            51.45127618799731
                        ],
                        [
                            5.494673036639767,
                            51.45166885655004
                        ],
                        [
                            5.4934596514118255,
                            51.45203805541084
                        ],
                        [
                            5.492074204068132,
                            51.4522556471816
                        ],
                        [
                            5.490553200259427,
                            51.45231954637609
                        ],
                        [
                            5.487321163062404,
                            51.4523332100533
                        ],
                        [
                            5.485338961398355,
                            51.45237585968749
                        ],
                        [
                            5.483276776459348,
                            51.452546256893186
                        ],
                        [
                            5.481148012101556,
                            51.45262693698385
                        ],
                        [
                            5.479006074153233,
                            51.4526908356587
                        ],
                        [
                            5.477878136359664,
                            51.45258433765542
                        ],
                        [
                            5.476676144585497,
                            51.45261983696338
                        ],
                        [
                            5.475308950150151,
                            51.452673085873556
                        ],
                        [
                            5.473977343375026,
                            51.452767381815136
                        ],
                        [
                            5.4727753516008875,
                            51.45271413301438
                        ],
                        [
                            5.471282831009489,
                            51.452536636564645
                        ],
                        [
                            5.4686311651145445,
                            51.45220280071106
                        ],
                        [
                            5.466677216401223,
                            51.452085651819374
                        ],
                        [
                            5.465144587675411,
                            51.451790720923356
                        ],
                        [
                            5.463464077849011,
                            51.45139311987501
                        ],
                        [
                            5.460421251272834,
                            51.45049051679365
                        ],
                        [
                            5.458792011237534,
                            51.450025451979855
                        ],
                        [
                            5.4563263956056005,
                            51.449260810153675
                        ],
                        [
                            5.453267183241081,
                            51.44829166237159
                        ],
                        [
                            5.4511252452927295,
                            51.44756385204582
                        ],
                        [
                            5.4496884523536835,
                            51.44704185357827
                        ],
                        [
                            5.4491187880051655,
                            51.446669063925015
                        ],
                        [
                            5.448860079654622,
                            51.44617928296569
                        ],
                        [
                            5.4488714729415335,
                            51.44541948457706
                        ],
                        [
                            5.449070948183447,
                            51.445039554234626
                        ],
                        [
                            5.448979801887219,
                            51.44472000642119
                        ],
                        [
                            5.44944889043424,
                            51.44300510525622
                        ],
                        [
                            5.449730847020675,
                            51.44211043959868
                        ],
                        [
                            5.4501182187776465,
                            51.441737609686555
                        ],
                        [
                            5.450517692095417,
                            51.441332452997244
                        ],
                        [
                            5.450278433069258,
                            51.44071815924406
                        ],
                        [
                            5.450346792790782,
                            51.44036662326383
                        ],
                        [
                            5.450610625472109,
                            51.43932687482601
                        ],
                        [
                            5.451972750912148,
                            51.436551561994605
                        ],
                        [
                            5.452691474769779,
                            51.43518255984026
                        ],
                        [
                            5.453756882277617,
                            51.433130688453815
                        ],
                        [
                            5.454449074137159,
                            51.43217968945882
                        ],
                        [
                            5.455212424363225,
                            51.43146938021275
                        ],
                        [
                            5.455958633349496,
                            51.43101480697629
                        ],
                        [
                            5.457321539483388,
                            51.430335942681126
                        ],
                        [
                            5.459895396771998,
                            51.429188681507185
                        ],
                        [
                            5.46057329734532,
                            51.429188681507185
                        ],
                        [
                            5.461456277084892,
                            51.42894361002212
                        ],
                        [
                            5.461439187154525,
                            51.428691433643024
                        ],
                        [
                            5.462034371578284,
                            51.428374544000775
                        ],
                        [
                            5.465164302540813,
                            51.427400777511394
                        ],
                        [
                            5.4679759365738505,
                            51.42652778850001
                        ],
                        [
                            5.4691380518437995,
                            51.426161935364576
                        ],
                        [
                            5.472625617602148,
                            51.42541357300152
                        ],
                        [
                            5.474716285759342,
                            51.42507257544966
                        ],
                        [
                            5.47816607818649,
                            51.42471520976923
                        ],
                        [
                            5.481683579014799,
                            51.42441649628191
                        ],
                        [
                            5.484705430640332,
                            51.424201853985466
                        ],
                        [
                            5.486209344518642,
                            51.424070424662034
                        ],
                        [
                            5.4890436768330915,
                            51.42383164414778
                        ],
                        [
                            5.490462141059567,
                            51.4238387484823
                        ],
                        [
                            5.4914704469552476,
                            51.42398438709691
                        ],
                        [
                            5.4922295558084215,
                            51.42427736979164
                        ],
                        [
                            5.493408761008737,
                            51.42470717585056
                        ],
                        [
                            5.494576572922142,
                            51.42527195617933
                        ],
                        [
                            5.495935286553475,
                            51.42598171193916
                        ],
                        [
                            5.49676129985852,
                            51.42643636573098
                        ],
                        [
                            5.49857397885674,
                            51.4273927874996
                        ],
                        [
                            5.499286059291705,
                            51.42780125310787
                        ],
                        [
                            5.500448245314374,
                            51.42849121953063
                        ],
                        [
                            5.501479337783849,
                            51.42941823132276
                        ],
                        [
                            5.502289559233702,
                            51.430883944278634
                        ],
                        [
                            5.502591481337873,
                            51.431757634284565
                        ],
                        [
                            5.50257934196344,
                            51.43257816653224
                        ],
                        [
                            5.502607825181627,
                            51.43305761484859
                        ],
                        [
                            5.502653398329272,
                            51.433419861350444
                        ],
                        [
                            5.498959778928906,
                            51.433957516741174
                        ],
                        [
                            5.494937935653553,
                            51.43452218024757
                        ],
                        [
                            5.492830177566873,
                            51.43483824810443
                        ],
                        [
                            5.492562435322526,
                            51.43505842891443
                        ],
                        [
                            5.491969984401123,
                            51.43572961870717
                        ],
                        [
                            5.491440196557733,
                            51.43625164839409
                        ],
                        [
                            5.491371836000724,
                            51.43645406833008
                        ],
                        [
                            5.489759685895734,
                            51.43661742260926
                        ],
                        [
                            5.490494552904551,
                            51.437299242858614
                        ],
                        [
                            5.494348397869089,
                            51.43935192686965
                        ],
                        [
                            5.496661042203925,
                            51.43765820789747
                        ],
                        [
                            5.498461181543007,
                            51.437398977393116
                        ],
                        [
                            5.502123912935019,
                            51.4368940216072
                        ],
                        [
                            5.503895569056823,
                            51.43661703090112
                        ],
                        [
                            5.504055075074518,
                            51.43679814039885
                        ],
                        [
                            5.504280159545999,
                            51.4377626297821
                        ],
                        [
                            5.5045706883641685,
                            51.43807867521821
                        ],
                        [
                            5.504587778294564,
                            51.43867880042109
                        ],
                        [
                            5.504517934144872,
                            51.43917896804254
                        ],
                        [
                            5.504438181136464,
                            51.43935651642181
                        ],
                        [
                            5.504347034841118,
                            51.43972226390838
                        ],
                        [
                            5.504187528823394,
                            51.44024424796618
                        ],
                        [
                            5.503988146301424,
                            51.44080173455589
                        ],
                        [
                            5.50374350466339,
                            51.44147506540739
                        ],
                        [
                            5.503544122141477,
                            51.442107102718154
                        ],
                        [
                            5.50332764969005,
                            51.44274623267634
                        ],
                        [
                            5.503173843641775,
                            51.44319817475275
                        ],
                        [
                            5.502746595380643,
                            51.44390830163243
                        ],
                        [
                            5.502154144459183,
                            51.44462906912375
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--08",
            "properties": {
                "land": "nl",
                "city": "Leiden",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://gemeente.leiden.nl/inwoners-en-ondernemers/vergunningen-en-ontheffingen/ontheffing-milieuzone/",
                "urlPark": "https://gemeente.leiden.nl/english/i-am-looking-for-a-parking-place/",
                "urlReg": "https://gemeente.leiden.nl/inwoners-en-ondernemers/vergunningen-en-ontheffingen/ontheffing-milieuzone/",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.480855464935303,
                            52.16079680113125
                        ],
                        [
                            4.4808125495910645,
                            52.15982271689272
                        ],
                        [
                            4.481627941131592,
                            52.15921719432359
                        ],
                        [
                            4.482164382934569,
                            52.15771651592873
                        ],
                        [
                            4.483001232147217,
                            52.156255280453465
                        ],
                        [
                            4.483366012573242,
                            52.15571553269593
                        ],
                        [
                            4.4828081130981445,
                            52.155070459718
                        ],
                        [
                            4.482700824737549,
                            52.15478083207497
                        ],
                        [
                            4.483022689819336,
                            52.154451707465974
                        ],
                        [
                            4.483709335327148,
                            52.15425423153256
                        ],
                        [
                            4.484889507293701,
                            52.1545965225937
                        ],
                        [
                            4.48765754699707,
                            52.15401725925614
                        ],
                        [
                            4.489803314208984,
                            52.15349723233921
                        ],
                        [
                            4.494867324829102,
                            52.15352356296202
                        ],
                        [
                            4.496015310287476,
                            52.152950868401625
                        ],
                        [
                            4.4972169399261475,
                            52.153424823046194
                        ],
                        [
                            4.498322010040283,
                            52.153398492364964
                        ],
                        [
                            4.499073028564453,
                            52.15296403387655
                        ],
                        [
                            4.50015664100647,
                            52.15349723233921
                        ],
                        [
                            4.502806663513184,
                            52.15329975217172
                        ],
                        [
                            4.50290322303772,
                            52.15476108466704
                        ],
                        [
                            4.503943920135498,
                            52.15520210802372
                        ],
                        [
                            4.504190683364868,
                            52.156024901113625
                        ],
                        [
                            4.503300189971924,
                            52.15607755935356
                        ],
                        [
                            4.503493309020996,
                            52.156400089713955
                        ],
                        [
                            4.504244327545166,
                            52.15720311433603
                        ],
                        [
                            4.503911733627319,
                            52.157742844055896
                        ],
                        [
                            4.50414776802063,
                            52.15835496862275
                        ],
                        [
                            4.5050597190856925,
                            52.15884861133512
                        ],
                        [
                            4.504491090774536,
                            52.15961210128131
                        ],
                        [
                            4.504716396331787,
                            52.16026369010119
                        ],
                        [
                            4.505531787872314,
                            52.160763893227895
                        ],
                        [
                            4.504802227020264,
                            52.16143520964136
                        ],
                        [
                            4.505478143692017,
                            52.16304106272283
                        ],
                        [
                            4.501991271972656,
                            52.1631924311314
                        ],
                        [
                            4.499963521957397,
                            52.16362020863299
                        ],
                        [
                            4.499491453170776,
                            52.163778156209546
                        ],
                        [
                            4.498708248138427,
                            52.16435729252723
                        ],
                        [
                            4.497495889663695,
                            52.1639821709995
                        ],
                        [
                            4.494599103927611,
                            52.16432438725656
                        ],
                        [
                            4.493762254714966,
                            52.16473899188856
                        ],
                        [
                            4.49264645576477,
                            52.16502197378441
                        ],
                        [
                            4.491369724273682,
                            52.16454156159319
                        ],
                        [
                            4.488022327423095,
                            52.164627114828725
                        ],
                        [
                            4.48642373085022,
                            52.16525230655719
                        ],
                        [
                            4.485275745391846,
                            52.1642849008997
                        ],
                        [
                            4.483451843261719,
                            52.16374525051065
                        ],
                        [
                            4.481252431869507,
                            52.16348200404367
                        ],
                        [
                            4.479085206985474,
                            52.16414011729132
                        ],
                        [
                            4.477776288986206,
                            52.163159524999415
                        ],
                        [
                            4.476993083953857,
                            52.16207360899387
                        ],
                        [
                            4.47718620300293,
                            52.160935014059596
                        ],
                        [
                            4.477604627609253,
                            52.160217618477816
                        ],
                        [
                            4.480855464935303,
                            52.16079680113125
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--09",
            "properties": {
                "land": "nl",
                "city": "Maastricht",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.gemeentemaastricht.nl/milieuzone-ontheffing",
                "urlPark": "https://www.gemeentemaastricht.nl/en/parking-and-transport",
                "urlReg": "https://www.gemeentemaastricht.nl/milieuzone-ontheffing",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            5.6777215003967285,
                            50.85009959956356
                        ],
                        [
                            5.678129196166992,
                            50.84988961543951
                        ],
                        [
                            5.683794021606444,
                            50.85380464780634
                        ],
                        [
                            5.68341851234436,
                            50.854143302983545
                        ],
                        [
                            5.6777215003967285,
                            50.85009959956356
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--10",
            "properties": {
                "land": "nl",
                "city": "Rijswijk",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.rijswijk.nl/ondernemers/product/milieuzone",
                "urlPark": "https://www.rijswijk.nl/inwoners/wonen/verkeer/parkeren",
                "urlReg": "https://www.rijswijk.nl/ondernemers/product/milieuzone",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.336187839508057,
                            52.044863019224735
                        ],
                        [
                            4.339578151702881,
                            52.04709332725623
                        ],
                        [
                            4.343419075012207,
                            52.04496859844604
                        ],
                        [
                            4.343011379241943,
                            52.049270739548156
                        ],
                        [
                            4.342861175537109,
                            52.05151402306388
                        ],
                        [
                            4.343397617340088,
                            52.052833548994016
                        ],
                        [
                            4.344191551208496,
                            52.05365163550058
                        ],
                        [
                            4.34708833694458,
                            52.055841922403566
                        ],
                        [
                            4.347109794616699,
                            52.05693702558766
                        ],
                        [
                            4.344770908355713,
                            52.059127151422345
                        ],
                        [
                            4.340393543243408,
                            52.06193721584345
                        ],
                        [
                            4.33785080909729,
                            52.06088181331663
                        ],
                        [
                            4.334728717803955,
                            52.05942399756426
                        ],
                        [
                            4.330801963806152,
                            52.05756372918786
                        ],
                        [
                            4.3286776542663565,
                            52.056666550791554
                        ],
                        [
                            4.3280768394470215,
                            52.05685786240222
                        ],
                        [
                            4.327540397644043,
                            52.05671932649012
                        ],
                        [
                            4.327454566955566,
                            52.05644885037594
                        ],
                        [
                            4.32561993598938,
                            52.05560442660463
                        ],
                        [
                            4.326446056365967,
                            52.054839153775056
                        ],
                        [
                            4.327304363250732,
                            52.05386275216741
                        ],
                        [
                            4.3276047706604,
                            52.05334815354428
                        ],
                        [
                            4.327991008758545,
                            52.05219358378464
                        ],
                        [
                            4.328259229660034,
                            52.05175154060722
                        ],
                        [
                            4.328699111938477,
                            52.05156680484929
                        ],
                        [
                            4.330995082855225,
                            52.04895403162673
                        ],
                        [
                            4.331209659576416,
                            52.048472367360105
                        ],
                        [
                            4.331821203231811,
                            52.04780594659482
                        ],
                        [
                            4.333655834197998,
                            52.046532461451996
                        ],
                        [
                            4.336187839508057,
                            52.044863019224735
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "nl",
                "city": "Rotterdam",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.rotterdam.nl/milieuzone",
                "urlPark": "https://www.rotterdam.nl/parkeren",
                "urlReg": "https://www.rotterdam.nl/milieuzone",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                4.468581676483154,
                                51.89776074951209
                            ],
                            [
                                4.468613862991333,
                                51.902043948473306
                            ],
                            [
                                4.47493314743042,
                                51.90424166019629
                            ],
                            [
                                4.480082988739014,
                                51.90662459959564
                            ],
                            [
                                4.483762979507446,
                                51.90972223181882
                            ],
                            [
                                4.489535093307495,
                                51.90755788307334
                            ],
                            [
                                4.490361213684082,
                                51.90802120809264
                            ],
                            [
                                4.488333463668823,
                                51.908835324762805
                            ],
                            [
                                4.4923460483551025,
                                51.910384091343616
                            ],
                            [
                                4.4968414306640625,
                                51.91285274132509
                            ],
                            [
                                4.498000144958496,
                                51.91217768162097
                            ],
                            [
                                4.498772621154785,
                                51.912588013238874
                            ],
                            [
                                4.497774839401245,
                                51.91337557471052
                            ],
                            [
                                4.505982398986816,
                                51.918431522718166
                            ],
                            [
                                4.5143723487854,
                                51.91512272059604
                            ],
                            [
                                4.51811671257019,
                                51.910622358384714
                            ],
                            [
                                4.519071578979492,
                                51.90536695288861
                            ],
                            [
                                4.523062705993652,
                                51.90612154513853
                            ],
                            [
                                4.52412486076355,
                                51.90844481535614
                            ],
                            [
                                4.525401592254639,
                                51.91023186451667
                            ],
                            [
                                4.527987241744995,
                                51.91468594049786
                            ],
                            [
                                4.522075653076172,
                                51.91593670853256
                            ],
                            [
                                4.523030519485474,
                                51.91767713729435
                            ],
                            [
                                4.524425268173218,
                                51.91990716983637
                            ],
                            [
                                4.529263973236084,
                                51.91937779528325
                            ],
                            [
                                4.530551433563232,
                                51.9213563007423
                            ],
                            [
                                4.533244371414185,
                                51.92572326165641
                            ],
                            [
                                4.533909559249878,
                                51.92729791000568
                            ],
                            [
                                4.533931016921997,
                                51.92843585677718
                            ],
                            [
                                4.531795978546143,
                                51.9337348683824
                            ],
                            [
                                4.52985405921936,
                                51.938471033267675
                            ],
                            [
                                4.52762246131897,
                                51.942968604094204
                            ],
                            [
                                4.527075290679932,
                                51.94403340410934
                            ],
                            [
                                4.527000188827515,
                                51.944747665731
                            ],
                            [
                                4.52635645866394,
                                51.94493945626636
                            ],
                            [
                                4.523642063140869,
                                51.94557434322231
                            ],
                            [
                                4.521217346191406,
                                51.946262127283255
                            ],
                            [
                                4.518942832946777,
                                51.94631503331263
                            ],
                            [
                                4.515981674194336,
                                51.94583887680161
                            ],
                            [
                                4.511679410934448,
                                51.94468153087347
                            ],
                            [
                                4.507323503494263,
                                51.94327283524922
                            ],
                            [
                                4.505499601364136,
                                51.94243288769685
                            ],
                            [
                                4.504276514053345,
                                51.941420961490046
                            ],
                            [
                                4.502774477005005,
                                51.93942349766481
                            ],
                            [
                                4.501733779907227,
                                51.93792864867212
                            ],
                            [
                                4.500424861907959,
                                51.936936464760684
                            ],
                            [
                                4.499105215072632,
                                51.93619561979868
                            ],
                            [
                                4.4957685470581055,
                                51.939006796979236
                            ],
                            [
                                4.493236541748047,
                                51.9409844372553
                            ],
                            [
                                4.491294622421265,
                                51.94140773354534
                            ],
                            [
                                4.4883012771606445,
                                51.94103073548157
                            ],
                            [
                                4.486091136932373,
                                51.94068019057967
                            ],
                            [
                                4.485039710998535,
                                51.94054790877274
                            ],
                            [
                                4.4850075244903564,
                                51.94127545388378
                            ],
                            [
                                4.484578371047974,
                                51.94148710115505
                            ],
                            [
                                4.482218027114868,
                                51.941513556993755
                            ],
                            [
                                4.479053020477295,
                                51.94130190984729
                            ],
                            [
                                4.472808837890625,
                                51.940554522872375
                            ],
                            [
                                4.467455148696899,
                                51.939912950669715
                            ],
                            [
                                4.464504718780518,
                                51.9392581407131
                            ],
                            [
                                4.4625842571258545,
                                51.93841150357145
                            ],
                            [
                                4.461790323257446,
                                51.93786250376067
                            ],
                            [
                                4.4609856605529785,
                                51.93778974424543
                            ],
                            [
                                4.457681179046631,
                                51.9367909426086
                            ],
                            [
                                4.453336000442505,
                                51.93539522890522
                            ],
                            [
                                4.449248313903809,
                                51.93464112855418
                            ],
                            [
                                4.448025226593018,
                                51.93423761351199
                            ],
                            [
                                4.445750713348389,
                                51.93367533240193
                            ],
                            [
                                4.442939758300781,
                                51.932087677116
                            ],
                            [
                                4.446018934249878,
                                51.93001040995896
                            ],
                            [
                                4.448904991149902,
                                51.929355455471814
                            ],
                            [
                                4.452477693557739,
                                51.928958508706366
                            ],
                            [
                                4.4544947147369385,
                                51.92924960334431
                            ],
                            [
                                4.454805850982666,
                                51.92874018648883
                            ],
                            [
                                4.454097747802734,
                                51.92572987794152
                            ],
                            [
                                4.454634189605713,
                                51.923612616928025
                            ],
                            [
                                4.45220947265625,
                                51.92361923352428
                            ],
                            [
                                4.449591636657715,
                                51.92430073771203
                            ],
                            [
                                4.448486566543579,
                                51.92527335198111
                            ],
                            [
                                4.448046684265136,
                                51.92637826915081
                            ],
                            [
                                4.445096254348755,
                                51.92691417726365
                            ],
                            [
                                4.442499876022339,
                                51.927383918618446
                            ],
                            [
                                4.440150260925293,
                                51.92410224241464
                            ],
                            [
                                4.440128803253174,
                                51.92336780218193
                            ],
                            [
                                4.437929391860962,
                                51.92274583438953
                            ],
                            [
                                4.437446594238281,
                                51.92291786889643
                            ],
                            [
                                4.436856508255005,
                                51.92274583438953
                            ],
                            [
                                4.436105489730834,
                                51.92276568455859
                            ],
                            [
                                4.43548321723938,
                                51.92207092341868
                            ],
                            [
                                4.4332945346832275,
                                51.921859184552424
                            ],
                            [
                                4.429389238357544,
                                51.92060858150426
                            ],
                            [
                                4.429357051849365,
                                51.92009244945521
                            ],
                            [
                                4.431685209274292,
                                51.91996672408293
                            ],
                            [
                                4.430440664291382,
                                51.91896090842923
                            ],
                            [
                                4.4297540187835684,
                                51.917783016715
                            ],
                            [
                                4.429593086242676,
                                51.91648597660651
                            ],
                            [
                                4.4300758838653564,
                                51.91549331855956
                            ],
                            [
                                4.430794715881348,
                                51.91419621230496
                            ],
                            [
                                4.4312989711761475,
                                51.912938777617114
                            ],
                            [
                                4.43272590637207,
                                51.91273361387941
                            ],
                            [
                                4.43323016166687,
                                51.9124556486105
                            ],
                            [
                                4.434249401092529,
                                51.911145217738486
                            ],
                            [
                                4.43472146987915,
                                51.91094666427045
                            ],
                            [
                                4.440107345581055,
                                51.90708131520988
                            ],
                            [
                                4.441545009613037,
                                51.90639293047973
                            ],
                            [
                                4.442274570465088,
                                51.906035495785495
                            ],
                            [
                                4.437446594238281,
                                51.903970261856976
                            ],
                            [
                                4.437103271484375,
                                51.90340098196447
                            ],
                            [
                                4.448068141937255,
                                51.901640140349606
                            ],
                            [
                                4.459376335144043,
                                51.90081927330258
                            ],
                            [
                                4.467401504516602,
                                51.901891693377564
                            ],
                            [
                                4.467744827270508,
                                51.89785343511043
                            ],
                            [
                                4.468581676483154,
                                51.89776074951209
                            ]
                        ],
                        [
                            [
                                4.469397068023682,
                                51.89513237117156
                            ],
                            [
                                4.469751119613647,
                                51.89505292157333
                            ],
                            [
                                4.470952749252319,
                                51.89443056152697
                            ],
                            [
                                4.474396705627441,
                                51.89258329318077
                            ],
                            [
                                4.474868774414062,
                                51.892868002571234
                            ],
                            [
                                4.471607208251953,
                                51.89429814338371
                            ],
                            [
                                4.469633102416992,
                                51.895324373787325
                            ],
                            [
                                4.469397068023682,
                                51.89513237117156
                            ]
                        ],
                        [
                            [
                                4.475635886192322,
                                51.892431006022136
                            ],
                            [
                                4.47543740272522,
                                51.892315135012254
                            ],
                            [
                                4.476612210273743,
                                51.892073460230186
                            ],
                            [
                                4.476724863052368,
                                51.89216946817592
                            ],
                            [
                                4.475635886192322,
                                51.892431006022136
                            ]
                        ],
                        [
                            [
                                4.528266191482544,
                                51.91409032442586
                            ],
                            [
                                4.529178142547607,
                                51.91387192988655
                            ],
                            [
                                4.530776739120483,
                                51.9137329509903
                            ],
                            [
                                4.532032012939453,
                                51.91380574951342
                            ],
                            [
                                4.533630609512329,
                                51.91423592019526
                            ],
                            [
                                4.5320212841033936,
                                51.913918256089715
                            ],
                            [
                                4.530841112136841,
                                51.913845457749005
                            ],
                            [
                                4.5293498039245605,
                                51.91399105431238
                            ],
                            [
                                4.5283520221710205,
                                51.91416312236958
                            ],
                            [
                                4.528266191482544,
                                51.91409032442586
                            ]
                        ],
                        [
                            [
                                4.460095167160034,
                                51.939172154857346
                            ],
                            [
                                4.45569634437561,
                                51.93845119004438
                            ],
                            [
                                4.452874660491943,
                                51.93719443468847
                            ],
                            [
                                4.451404809951782,
                                51.93562674839995
                            ],
                            [
                                4.452627897262573,
                                51.9357392002949
                            ],
                            [
                                4.456136226654053,
                                51.93689016230894
                            ],
                            [
                                4.459590911865234,
                                51.938437961223954
                            ],
                            [
                                4.460095167160034,
                                51.939172154857346
                            ]
                        ],
                        [
                            [
                                4.102063179016113,
                                51.93254413376412
                            ],
                            [
                                4.100368022918701,
                                51.932279521780124
                            ],
                            [
                                4.095818996429443,
                                51.93279551370361
                            ],
                            [
                                4.084124565124512,
                                51.93422438344916
                            ],
                            [
                                4.080111980438232,
                                51.93439637396158
                            ],
                            [
                                4.074232578277588,
                                51.934290533724266
                            ],
                            [
                                4.068353176116943,
                                51.93377455899217
                            ],
                            [
                                4.062666893005371,
                                51.93254413376412
                            ],
                            [
                                4.051401615142821,
                                51.930003794304845
                            ],
                            [
                                4.043591022491455,
                                51.928191068337675
                            ],
                            [
                                4.040243625640869,
                                51.928296922961835
                            ],
                            [
                                4.036681652069092,
                                51.92927606639959
                            ],
                            [
                                4.035286903381348,
                                51.931287212943
                            ],
                            [
                                4.028656482696533,
                                51.94249241205695
                            ],
                            [
                                4.0338921546936035,
                                51.94300828653578
                            ],
                            [
                                4.039750099182129,
                                51.93426407362591
                            ],
                            [
                                4.071807861328125,
                                51.94140773354534
                            ],
                            [
                                4.078760147094727,
                                51.949026383760376
                            ],
                            [
                                4.042882919311523,
                                51.94479396007323
                            ],
                            [
                                4.042367935180664,
                                51.94627535379646
                            ],
                            [
                                4.078845977783203,
                                51.95124824631019
                            ],
                            [
                                4.073266983032227,
                                51.96071637857509
                            ],
                            [
                                4.035072326660156,
                                51.957754496243574
                            ],
                            [
                                4.033956527709961,
                                51.961509706685675
                            ],
                            [
                                4.04545783996582,
                                51.96045193275158
                            ],
                            [
                                4.053955078125,
                                51.96113948864793
                            ],
                            [
                                4.054040908813477,
                                51.9650001835794
                            ],
                            [
                                4.009666442871094,
                                51.975575726542665
                            ],
                            [
                                4.004087448120117,
                                51.97472977494965
                            ],
                            [
                                4.001340866088867,
                                51.97219182433531
                            ],
                            [
                                4.003314971923828,
                                51.96156259472718
                            ],
                            [
                                4.009151458740234,
                                51.960557711268194
                            ],
                            [
                                4.0085506439208975,
                                51.95897100731088
                            ],
                            [
                                4.004001617431641,
                                51.95759581845331
                            ],
                            [
                                3.983917236328125,
                                51.94024365913649
                            ],
                            [
                                3.9824581146240234,
                                51.94156646862434
                            ],
                            [
                                3.9972209930419917,
                                51.95637927006984
                            ],
                            [
                                3.9949893951416016,
                                51.96685108376489
                            ],
                            [
                                3.9873504638671875,
                                51.96928357919238
                            ],
                            [
                                3.9849472045898438,
                                51.968278868824726
                            ],
                            [
                                3.986234664916992,
                                51.96679820196322
                            ],
                            [
                                3.9903545379638667,
                                51.964365571634
                            ],
                            [
                                3.9742183685302734,
                                51.948920578035974
                            ],
                            [
                                3.9689826965332027,
                                51.954951105932835
                            ],
                            [
                                3.9680385589599605,
                                51.96108660010723
                            ],
                            [
                                3.973102569580078,
                                51.96838462887153
                            ],
                            [
                                3.983230590820313,
                                51.96933645806144
                            ],
                            [
                                3.9840888977050777,
                                51.970975672054905
                            ],
                            [
                                3.978767395019531,
                                51.972086073273395
                            ],
                            [
                                3.977994918823242,
                                51.97393668082494
                            ],
                            [
                                3.986835479736328,
                                51.98123261725621
                            ],
                            [
                                3.9924144744873042,
                                51.98213130983506
                            ],
                            [
                                4.004001617431641,
                                51.98012244270682
                            ],
                            [
                                4.063739776611328,
                                51.96706261034769
                            ],
                            [
                                4.072237014770508,
                                51.96864902789657
                            ],
                            [
                                4.078330993652344,
                                51.96330786509095
                            ],
                            [
                                4.082021713256836,
                                51.96468287872987
                            ],
                            [
                                4.073781967163086,
                                51.97732045125947
                            ],
                            [
                                4.039192199707031,
                                51.98741737180161
                            ],
                            [
                                4.029750823974609,
                                51.988474509331326
                            ],
                            [
                                4.020395278930664,
                                51.98773451568071
                            ],
                            [
                                4.006319046020508,
                                51.9900601688372
                            ],
                            [
                                3.9949035644531246,
                                51.98905592433601
                            ],
                            [
                                3.978767395019531,
                                51.98509158139705
                            ],
                            [
                                3.961944580078125,
                                51.96880766656288
                            ],
                            [
                                3.9594554901123047,
                                51.96182703399873
                            ],
                            [
                                3.9601421356201167,
                                51.95378738300075
                            ],
                            [
                                3.981342315673828,
                                51.91854401770406
                            ],
                            [
                                3.9911270141601562,
                                51.91621465225316
                            ],
                            [
                                4.002285003662109,
                                51.91722052943251
                            ],
                            [
                                4.02524471282959,
                                51.922514248435256
                            ],
                            [
                                4.046874046325684,
                                51.928071981587046
                            ],
                            [
                                4.059877395629883,
                                51.9310887485424
                            ],
                            [
                                4.067773818969727,
                                51.93278228321558
                            ],
                            [
                                4.07468318939209,
                                51.93354964507263
                            ],
                            [
                                4.082751274108887,
                                51.9335231845374
                            ],
                            [
                                4.09116268157959,
                                51.93257059487671
                            ],
                            [
                                4.099574089050293,
                                51.93145921471351
                            ],
                            [
                                4.102449417114258,
                                51.931988446795515
                            ],
                            [
                                4.102063179016113,
                                51.93254413376412
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "nl",
                "city": "Rotterdam - s-Gravendijkwal",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "ELECTRO (van/trucks)",
                "minEUd": 10,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 6,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.rotterdam.nl/en/exemptions-request-s-gravendijkwal",
                "urlPark": "https://www.rotterdam.nl/parkeren",
                "urlReg": "https://www.rotterdam.nl/en/exemptions-request-s-gravendijkwal",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                4.461350440979004,
                                51.92609708023579
                            ],
                            [
                                4.461365193128585,
                                51.926050766598465
                            ],
                            [
                                4.461591839790343,
                                51.92548755586322
                            ],
                            [
                                4.461798369884491,
                                51.92489539128666
                            ],
                            [
                                4.461931139230728,
                                51.92437600028279
                            ],
                            [
                                4.462106823921204,
                                51.92382352045289
                            ],
                            [
                                4.4622503221035,
                                51.92333554608542
                            ],
                            [
                                4.46245014667511,
                                51.9226804941877
                            ],
                            [
                                4.4627076387405396,
                                51.92276651164878
                            ],
                            [
                                4.462605714797974,
                                51.92305433810842
                            ],
                            [
                                4.46243941783905,
                                51.92359111298356
                            ],
                            [
                                4.462189972400665,
                                51.92447276625977
                            ],
                            [
                                4.4619981944561005,
                                51.92508395850799
                            ],
                            [
                                4.461876153945923,
                                51.9253395154515
                            ],
                            [
                                4.461543560028076,
                                51.926130161376044
                            ],
                            [
                                4.461350440979004,
                                51.92609708023579
                            ]
                        ],
                        [
                            [
                                4.462546706199646,
                                51.922345520773376
                            ],
                            [
                                4.462636560201645,
                                51.92226446510243
                            ],
                            [
                                4.462816268205643,
                                51.92171278639294
                            ],
                            [
                                4.462942332029343,
                                51.92124133144848
                            ],
                            [
                                4.463029503822326,
                                51.920553990895094
                            ],
                            [
                                4.463093876838684,
                                51.91992867554564
                            ],
                            [
                                4.463168978691101,
                                51.91957548432321
                            ],
                            [
                                4.463113993406296,
                                51.919113932823365
                            ],
                            [
                                4.463244080543518,
                                51.919142883325726
                            ],
                            [
                                4.463266879320145,
                                51.91935794361608
                            ],
                            [
                                4.463300406932831,
                                51.919724370891586
                            ],
                            [
                                4.463305771350861,
                                51.92013297926993
                            ],
                            [
                                4.463276267051696,
                                51.92045556220474
                            ],
                            [
                                4.4632333517074585,
                                51.9207897225955
                            ],
                            [
                                4.463167637586594,
                                51.92115531106485
                            ],
                            [
                                4.463142156600952,
                                51.92131659914894
                            ],
                            [
                                4.463060349225998,
                                51.921608570463064
                            ],
                            [
                                4.462955743074417,
                                51.92195843727033
                            ],
                            [
                                4.462814927101135,
                                51.92242492210503
                            ],
                            [
                                4.462546706199646,
                                51.922345520773376
                            ]
                        ],
                        [
                            [
                                4.462931603193283,
                                51.92243815564665
                            ],
                            [
                                4.4630831480026245,
                                51.92198159620627
                            ],
                            [
                                4.463238716125488,
                                51.92146299860049
                            ],
                            [
                                4.4633446633815765,
                                51.92094853062213
                            ],
                            [
                                4.463423788547516,
                                51.920399317141914
                            ],
                            [
                                4.463451951742172,
                                51.919864158386886
                            ],
                            [
                                4.463425129652023,
                                51.91947209058245
                            ],
                            [
                                4.4634076952934265,
                                51.919375313825356
                            ],
                            [
                                4.463493525981903,
                                51.91925868514857
                            ],
                            [
                                4.463532418012619,
                                51.91957879291898
                            ],
                            [
                                4.463549852371216,
                                51.920081696641084
                            ],
                            [
                                4.4634948670864105,
                                51.92059948307402
                            ],
                            [
                                4.463405013084412,
                                51.92117681617621
                            ],
                            [
                                4.46332722902298,
                                51.921531649026306
                            ],
                            [
                                4.463230669498444,
                                51.92188151643308
                            ],
                            [
                                4.463045597076416,
                                51.92245552466411
                            ],
                            [
                                4.462931603193283,
                                51.92243815564665
                            ]
                        ],
                        [
                            [
                                4.462985247373581,
                                51.91884758732555
                            ],
                            [
                                4.462871253490448,
                                51.9182900764167
                            ],
                            [
                                4.4627344608306885,
                                51.91773752168202
                            ],
                            [
                                4.462643265724182,
                                51.91717255224988
                            ],
                            [
                                4.46258157491684,
                                51.916830092940145
                            ],
                            [
                                4.462593644857407,
                                51.916342042550085
                            ],
                            [
                                4.462688863277435,
                                51.915830824757855
                            ],
                            [
                                4.462805539369583,
                                51.91550489969664
                            ],
                            [
                                4.4630831480026245,
                                51.91488365019743
                            ],
                            [
                                4.463489502668381,
                                51.91494486557644
                            ],
                            [
                                4.463350027799606,
                                51.91524266893242
                            ],
                            [
                                4.463189095258713,
                                51.915630637564014
                            ],
                            [
                                4.463041573762894,
                                51.91611207798516
                            ],
                            [
                                4.46301206946373,
                                51.91652402806157
                            ],
                            [
                                4.4630201160907745,
                                51.916838364934925
                            ],
                            [
                                4.463092535734177,
                                51.917287531963815
                            ],
                            [
                                4.4631341099739075,
                                51.91749432926938
                            ],
                            [
                                4.463230669498444,
                                51.91799146609471
                            ],
                            [
                                4.463286995887756,
                                51.918426559697565
                            ],
                            [
                                4.463405013084412,
                                51.918894735556734
                            ],
                            [
                                4.462985247373581,
                                51.91884758732555
                            ]
                        ],
                        [
                            [
                                4.463101923465729,
                                51.91473888376636
                            ],
                            [
                                4.463230669498444,
                                51.914401369360206
                            ],
                            [
                                4.463415741920471,
                                51.9137875498937
                            ],
                            [
                                4.463914632797241,
                                51.91386862087023
                            ],
                            [
                                4.463799297809601,
                                51.91432029934555
                            ],
                            [
                                4.46357935667038,
                                51.91480671723788
                            ],
                            [
                                4.463101923465729,
                                51.91473888376636
                            ]
                        ],
                        [
                            [
                                4.463563263416289,
                                51.9137346055035
                            ],
                            [
                                4.463616907596588,
                                51.9134731916619
                            ],
                            [
                                4.4636209309101105,
                                51.913128222332816
                            ],
                            [
                                4.463567286729813,
                                51.91286349587061
                            ],
                            [
                                4.463486820459366,
                                51.912602076957704
                            ],
                            [
                                4.463423788547516,
                                51.91239939352532
                            ],
                            [
                                4.463386237621307,
                                51.91220332745694
                            ],
                            [
                                4.463477432727814,
                                51.91216444505475
                            ],
                            [
                                4.463465362787247,
                                51.912057725097
                            ],
                            [
                                4.463535100221634,
                                51.911795474200936
                            ],
                            [
                                4.4637832045555115,
                                51.91186413941004
                            ],
                            [
                                4.463745653629303,
                                51.91198988747262
                            ],
                            [
                                4.463753700256348,
                                51.9122653737737
                            ],
                            [
                                4.463799297809601,
                                51.91244985764911
                            ],
                            [
                                4.463902562856674,
                                51.912790695819915
                            ],
                            [
                                4.463824778795242,
                                51.9127956594635
                            ],
                            [
                                4.463828802108765,
                                51.91286763223358
                            ],
                            [
                                4.4638703763484955,
                                51.91307610443392
                            ],
                            [
                                4.463871717453002,
                                51.91337971102634
                            ],
                            [
                                4.4638583064079285,
                                51.91348560058118
                            ],
                            [
                                4.463801980018616,
                                51.913775968313686
                            ],
                            [
                                4.463563263416289,
                                51.9137346055035
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--12",
            "properties": {
                "land": "nl",
                "city": "‘s-Hertogenbosch",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.s-hertogenbosch.nl/ondernemer/milieu-en-afval/ontheffing-milieuzone/",
                "urlPark": "-",
                "urlReg": "https://www.s-hertogenbosch.nl/ondernemer/milieu-en-afval/ontheffing-milieuzone/",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            5.295401023827395,
                            51.68323873630774
                        ],
                        [
                            5.297006537720904,
                            51.68382518824387
                        ],
                        [
                            5.300262713894199,
                            51.68506424271766
                        ],
                        [
                            5.300960466608558,
                            51.684807889263226
                        ],
                        [
                            5.301852221375185,
                            51.68440241930841
                        ],
                        [
                            5.302653344861653,
                            51.68403390537489
                        ],
                        [
                            5.3037645806657,
                            51.68414072131654
                        ],
                        [
                            5.305435741488026,
                            51.68439707854819
                        ],
                        [
                            5.306682271154244,
                            51.68485460890071
                        ],
                        [
                            5.308723843911281,
                            51.68493471942847
                        ],
                        [
                            5.309636952400865,
                            51.68515368748086
                        ],
                        [
                            5.311049686291597,
                            51.68518573149714
                        ],
                        [
                            5.31369139020137,
                            51.68526985862448
                        ],
                        [
                            5.314716483695378,
                            51.68555825321528
                        ],
                        [
                            5.315698506034352,
                            51.68640206387499
                        ],
                        [
                            5.317075890409086,
                            51.687773082762334
                        ],
                        [
                            5.316421208849761,
                            51.6879706757359
                        ],
                        [
                            5.316576269923985,
                            51.6885547906042
                        ],
                        [
                            5.3163953710728435,
                            51.68893394819156
                        ],
                        [
                            5.316515970306938,
                            51.68923834001228
                        ],
                        [
                            5.3171275807112295,
                            51.69000198065987
                        ],
                        [
                            5.317351550717433,
                            51.69029034510612
                        ],
                        [
                            5.316455670689862,
                            51.690899108459405
                        ],
                        [
                            5.316833725167243,
                            51.69129659067352
                        ],
                        [
                            5.315594687952057,
                            51.69217319178435
                        ],
                        [
                            5.312874208217835,
                            51.69093759386476
                        ],
                        [
                            5.304667026244061,
                            51.69446275535998
                        ],
                        [
                            5.305017188935182,
                            51.695097204906034
                        ],
                        [
                            5.303872426290326,
                            51.69614903587362
                        ],
                        [
                            5.302444839933372,
                            51.69588190651518
                        ],
                        [
                            5.30139435185896,
                            51.696115644790126
                        ],
                        [
                            5.300828704434451,
                            51.69571494986545
                        ],
                        [
                            5.3005324129264295,
                            51.69619912245287
                        ],
                        [
                            5.300276524805724,
                            51.696900328740696
                        ],
                        [
                            5.298862406244524,
                            51.697785168788585
                        ],
                        [
                            5.296949979238633,
                            51.6983611402778
                        ],
                        [
                            5.295668888272047,
                            51.69581426317913
                        ],
                        [
                            5.294532387587395,
                            51.69333033084976
                        ],
                        [
                            5.2927142358501555,
                            51.68885721077871
                        ],
                        [
                            5.29075463426841,
                            51.684182293313484
                        ],
                        [
                            5.291185603735158,
                            51.68398189347002
                        ],
                        [
                            5.2924515765418505,
                            51.68437434233087
                        ],
                        [
                            5.293421257841629,
                            51.684516291080826
                        ],
                        [
                            5.294161986611158,
                            51.684132193435744
                        ],
                        [
                            5.294700698444132,
                            51.68364789176039
                        ],
                        [
                            5.295401023827395,
                            51.68323873630774
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--13",
            "properties": {
                "land": "nl",
                "city": "Tilburg",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://www.tilburg.nl/ondernemers/milieu/ontheffing-milieuzone/",
                "urlPark": "-",
                "urlReg": "https://winkel.tilburg.nl/formulieren/server/winkel-ui/start.html?sessionId=e34dc82a-91a3-4245-8280-5b3cd04ac333",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            5.0638604164123535,
                            51.54421328177006
                        ],
                        [
                            5.064171552658081,
                            51.54418659225738
                        ],
                        [
                            5.065888166427612,
                            51.544153230344556
                        ],
                        [
                            5.068774223327637,
                            51.54417324749518
                        ],
                        [
                            5.073580741882324,
                            51.54423997126705
                        ],
                        [
                            5.076917409896851,
                            51.544159902729085
                        ],
                        [
                            5.081852674484253,
                            51.54385964445683
                        ],
                        [
                            5.084513425827026,
                            51.54367281608752
                        ],
                        [
                            5.088815689086913,
                            51.54346596949814
                        ],
                        [
                            5.0904035568237305,
                            51.54353936678263
                        ],
                        [
                            5.092613697052002,
                            51.54397307559256
                        ],
                        [
                            5.09487748146057,
                            51.54490053132951
                        ],
                        [
                            5.098546743392944,
                            51.54652186537268
                        ],
                        [
                            5.100349187850952,
                            51.54738255009284
                        ],
                        [
                            5.103739500045776,
                            51.548957093953554
                        ],
                        [
                            5.108170509338379,
                            51.5509785682552
                        ],
                        [
                            5.109297037124634,
                            51.55154563237165
                        ],
                        [
                            5.108674764633179,
                            51.55207933331826
                        ],
                        [
                            5.107172727584839,
                            51.553533636619115
                        ],
                        [
                            5.105981826782227,
                            51.55487448974971
                        ],
                        [
                            5.104844570159912,
                            51.5561485971447
                        ],
                        [
                            5.104340314865112,
                            51.55740265752814
                        ],
                        [
                            5.103964805603027,
                            51.5586500130758
                        ],
                        [
                            5.103707313537598,
                            51.55971724200184
                        ],
                        [
                            5.103492736816406,
                            51.560070756061634
                        ],
                        [
                            5.10364294052124,
                            51.56033088729398
                        ],
                        [
                            5.103256702423096,
                            51.5613447178963
                        ],
                        [
                            5.103020668029785,
                            51.56175157776946
                        ],
                        [
                            5.101604461669922,
                            51.56363909316953
                        ],
                        [
                            5.10039210319519,
                            51.56510636550195
                        ],
                        [
                            5.099952220916748,
                            51.56606673629985
                        ],
                        [
                            5.099136829376221,
                            51.56716713623478
                        ],
                        [
                            5.0982677936553955,
                            51.56832086029335
                        ],
                        [
                            5.0959718227386475,
                            51.57048151329607
                        ],
                        [
                            5.094265937805176,
                            51.57200191126013
                        ],
                        [
                            5.092431306838989,
                            51.573648951671494
                        ],
                        [
                            5.091497898101807,
                            51.57437576429138
                        ],
                        [
                            5.090306997299194,
                            51.57515590802442
                        ],
                        [
                            5.088472366333008,
                            51.57583602240499
                        ],
                        [
                            5.086348056793212,
                            51.5764627854704
                        ],
                        [
                            5.085318088531494,
                            51.57669615248873
                        ],
                        [
                            5.083858966827393,
                            51.576642811561584
                        ],
                        [
                            5.0833117961883545,
                            51.57656280005356
                        ],
                        [
                            5.082410573959351,
                            51.5764627854704
                        ],
                        [
                            5.08042573928833,
                            51.576396108959386
                        ],
                        [
                            5.076466798782349,
                            51.57627609099303
                        ],
                        [
                            5.074267387390137,
                            51.5761960788394
                        ],
                        [
                            5.072422027587891,
                            51.57605605723161
                        ],
                        [
                            5.069632530212402,
                            51.575749341726286
                        ],
                        [
                            5.067315101623535,
                            51.57547596311826
                        ],
                        [
                            5.066800117492676,
                            51.57510256528967
                        ],
                        [
                            5.066628456115723,
                            51.57400902543118
                        ],
                        [
                            5.066370964050292,
                            51.571981906353884
                        ],
                        [
                            5.066070556640625,
                            51.569114445395826
                        ],
                        [
                            5.06594181060791,
                            51.56763396452484
                        ],
                        [
                            5.066081285476685,
                            51.56606006712817
                        ],
                        [
                            5.065791606903076,
                            51.564559478622094
                        ],
                        [
                            5.065287351608276,
                            51.562111745767226
                        ],
                        [
                            5.065072774887085,
                            51.560797785773765
                        ],
                        [
                            5.064740180969238,
                            51.55985064385667
                        ],
                        [
                            5.064396858215332,
                            51.55781622305145
                        ],
                        [
                            5.0639355182647705,
                            51.55538146869522
                        ],
                        [
                            5.063613653182983,
                            51.55466102323999
                        ],
                        [
                            5.062594413757324,
                            51.55304665032469
                        ],
                        [
                            5.0611138343811035,
                            51.55071171210745
                        ],
                        [
                            5.0599658489227295,
                            51.54955754124255
                        ],
                        [
                            5.058324337005615,
                            51.548089767212
                        ],
                        [
                            5.059407949447632,
                            51.54749597244644
                        ],
                        [
                            5.0583672523498535,
                            51.546888825965326
                        ],
                        [
                            5.061049461364745,
                            51.54506066565809
                        ],
                        [
                            5.0611138343811035,
                            51.544787102505786
                        ],
                        [
                            5.0638604164123535,
                            51.54421328177006
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "nl--14",
            "properties": {
                "land": "nl",
                "city": "Haarlem",
                "zone": "Milieuzone",
                "area_code": 2,
                "commercial": 1,
                "time": "Mo-Su: 00.00-24.00",
                "diesel": "min. EURO-VI (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-nl--white",
                "color": 0,
                "urlZone": "https://haarlem.nl/ontheffing-milieuzone-voor-dieselvrachtwagens",
                "urlPark": "https://haarlem.nl/parkeren-en-verkeer",
                "urlReg": "https://haarlem.nl/ontheffing-milieuzone-voor-dieselvrachtwagens",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.627069784479602,
                            52.397510332559904
                        ],
                        [
                            4.626798995850976,
                            52.397330243352286
                        ],
                        [
                            4.626240375038634,
                            52.39692524654782
                        ],
                        [
                            4.625665148358763,
                            52.3965101240492
                        ],
                        [
                            4.624658501667625,
                            52.39602412201583
                        ],
                        [
                            4.623613139978488,
                            52.395598510890466
                        ],
                        [
                            4.623496988436841,
                            52.39503487139311
                        ],
                        [
                            4.623297871508925,
                            52.39412020754787
                        ],
                        [
                            4.623325528331748,
                            52.39329665520208
                        ],
                        [
                            4.623513583208194,
                            52.39271273459491
                        ],
                        [
                            4.623956069878005,
                            52.39205117349107
                        ],
                        [
                            4.623303408836733,
                            52.39101830842267
                        ],
                        [
                            4.622811458731547,
                            52.390038206870145
                        ],
                        [
                            4.621505604520593,
                            52.38773556792225
                        ],
                        [
                            4.620875070396863,
                            52.38666209094086
                        ],
                        [
                            4.620781042958242,
                            52.386277253915665
                        ],
                        [
                            4.620781042958242,
                            52.38570674373179
                        ],
                        [
                            4.621948089396199,
                            52.38565948220264
                        ],
                        [
                            4.621079718350586,
                            52.38505182946108
                        ],
                        [
                            4.6208308221908965,
                            52.38521724686919
                        ],
                        [
                            4.620194754226844,
                            52.385338777631375
                        ],
                        [
                            4.619652713701413,
                            52.385406294576626
                        ],
                        [
                            4.619868423706578,
                            52.38563922724606
                        ],
                        [
                            4.619531031133988,
                            52.38586540706737
                        ],
                        [
                            4.619232355742525,
                            52.38590591676419
                        ],
                        [
                            4.6187456254746735,
                            52.38563247559182
                        ],
                        [
                            4.617705790279018,
                            52.38583839914483
                        ],
                        [
                            4.617473487196548,
                            52.385716869758056
                        ],
                        [
                            4.617003350005945,
                            52.38581476845704
                        ],
                        [
                            4.615980212318618,
                            52.38441715641932
                        ],
                        [
                            4.614951441524454,
                            52.38455556893592
                        ],
                        [
                            4.614973565628333,
                            52.38432938240331
                        ],
                        [
                            4.615028876974037,
                            52.382922840258345
                        ],
                        [
                            4.614951442612636,
                            52.381575781906065
                        ],
                        [
                            4.614600980373666,
                            52.38060468326182
                        ],
                        [
                            4.614205878395666,
                            52.37963440550121
                        ],
                        [
                            4.613020611179564,
                            52.37827105321844
                        ],
                        [
                            4.61215859891351,
                            52.3772365569605
                        ],
                        [
                            4.611316177835221,
                            52.376016657403596
                        ],
                        [
                            4.611169243925957,
                            52.37554423626827
                        ],
                        [
                            4.610777421336962,
                            52.37410256866522
                        ],
                        [
                            4.610463962331494,
                            52.37248787392542
                        ],
                        [
                            4.610806808118696,
                            52.372344342647295
                        ],
                        [
                            4.6106696698041105,
                            52.37190178493637
                        ],
                        [
                            4.614088358482434,
                            52.37141735433681
                        ],
                        [
                            4.614284270361139,
                            52.371830014165425
                        ],
                        [
                            4.6148132324331925,
                            52.37192570285001
                        ],
                        [
                            4.615126691439428,
                            52.371740305834976
                        ],
                        [
                            4.61508750906313,
                            52.37138745130105
                        ],
                        [
                            4.619603277866872,
                            52.370950864671414
                        ],
                        [
                            4.619534708709551,
                            52.37072957938909
                        ],
                        [
                            4.6213762803692475,
                            52.3705381966291
                        ],
                        [
                            4.622110975372635,
                            52.370167392379045
                        ],
                        [
                            4.622590959475929,
                            52.370239161613796
                        ],
                        [
                            4.623070943578483,
                            52.37004777672897
                        ],
                        [
                            4.622865236105866,
                            52.36977864033207
                        ],
                        [
                            4.622992578827279,
                            52.36956931088949
                        ],
                        [
                            4.623413789366452,
                            52.36931213335927
                        ],
                        [
                            4.624295392820272,
                            52.36886954526702
                        ],
                        [
                            4.62513781389859,
                            52.369192515393166
                        ],
                        [
                            4.626411241109935,
                            52.36926428621189
                        ],
                        [
                            4.627224275406547,
                            52.369108782624494
                        ],
                        [
                            4.627753237479254,
                            52.36863628759599
                        ],
                        [
                            4.627645485945635,
                            52.36810995806965
                        ],
                        [
                            4.628262608363514,
                            52.36808603385137
                        ],
                        [
                            4.628399746678809,
                            52.369192515393166
                        ],
                        [
                            4.628468316108325,
                            52.37013749798686
                        ],
                        [
                            4.6288993222412955,
                            52.37142334663773
                        ],
                        [
                            4.629251963622352,
                            52.37180610365749
                        ],
                        [
                            4.630016019949494,
                            52.37149511383177
                        ],
                        [
                            4.631152308846225,
                            52.37138148238759
                        ],
                        [
                            4.632886128972302,
                            52.37150109442598
                        ],
                        [
                            4.635658282055346,
                            52.370998721688125
                        ],
                        [
                            4.63637339162986,
                            52.370956855142936
                        ],
                        [
                            4.637881913095526,
                            52.37195561532525
                        ],
                        [
                            4.638998610804492,
                            52.3719795374478
                        ],
                        [
                            4.63965491559793,
                            52.37187786833704
                        ],
                        [
                            4.640115308512634,
                            52.3716984516887
                        ],
                        [
                            4.641114459094069,
                            52.37108244898212
                        ],
                        [
                            4.642348703929798,
                            52.370550167880026
                        ],
                        [
                            4.6428776660017945,
                            52.37043653400485
                        ],
                        [
                            4.643974772522938,
                            52.37035878434273
                        ],
                        [
                            4.647648135074519,
                            52.37031093376038
                        ],
                        [
                            4.6540250667250405,
                            52.37035877977402
                        ],
                        [
                            4.65413281825866,
                            52.37067575830696
                        ],
                        [
                            4.6543483213251875,
                            52.37107048311091
                        ],
                        [
                            4.655435632251681,
                            52.37230846075181
                        ],
                        [
                            4.656424976212861,
                            52.37351051605984
                        ],
                        [
                            4.6574143311996465,
                            52.37470058405185
                        ],
                        [
                            4.658364503811555,
                            52.375501918301154
                        ],
                        [
                            4.658942443853817,
                            52.376315197895934
                        ],
                        [
                            4.65923631005964,
                            52.37696700546624
                        ],
                        [
                            4.660147300295989,
                            52.37905990466328
                        ],
                        [
                            4.660529328459489,
                            52.38069229717814
                        ],
                        [
                            4.66063710008811,
                            52.381529408486045
                        ],
                        [
                            4.658413500264857,
                            52.381415802858214
                        ],
                        [
                            4.657629852749977,
                            52.38148157457334
                        ],
                        [
                            4.6515859437034806,
                            52.381086926240584
                        ],
                        [
                            4.647442407469356,
                            52.380752084406794
                        ],
                        [
                            4.6467567158942416,
                            52.38077600176479
                        ],
                        [
                            4.646727329112508,
                            52.38132011816083
                        ],
                        [
                            4.646835080645417,
                            52.38175660229132
                        ],
                        [
                            4.6469722189607126,
                            52.38221101954068
                        ],
                        [
                            4.647305269154117,
                            52.38295840616101
                        ],
                        [
                            4.647804844445147,
                            52.38366392752201
                        ],
                        [
                            4.648157487371492,
                            52.38396287458443
                        ],
                        [
                            4.648921543697838,
                            52.384584676463646
                        ],
                        [
                            4.649352549831605,
                            52.38487763777522
                        ],
                        [
                            4.650831684515538,
                            52.384231924549255
                        ],
                        [
                            4.651772061532796,
                            52.383998748007684
                        ],
                        [
                            4.652732029738615,
                            52.38398679020321
                        ],
                        [
                            4.651507580496855,
                            52.385630957935945
                        ],
                        [
                            4.651370442181474,
                            52.386306543654285
                        ],
                        [
                            4.650635773866242,
                            52.387334855118894
                        ],
                        [
                            4.6491566391823085,
                            52.38775932300422
                        ],
                        [
                            4.649430915812189,
                            52.38856639857076
                        ],
                        [
                            4.649597440909275,
                            52.38973812646233
                        ],
                        [
                            4.649930491103277,
                            52.391615217542096
                        ],
                        [
                            4.650697612647946,
                            52.39513929570043
                        ],
                        [
                            4.64868306475131,
                            52.395404294151035
                        ],
                        [
                            4.648586553342142,
                            52.39520309305388
                        ],
                        [
                            4.64455082875466,
                            52.39577494693049
                        ],
                        [
                            4.644180891815694,
                            52.3957455029842
                        ],
                        [
                            4.643589796923578,
                            52.39591480540744
                        ],
                        [
                            4.642636805509227,
                            52.39608410990377
                        ],
                        [
                            4.641390278867618,
                            52.39626077193162
                        ],
                        [
                            4.641563184175396,
                            52.396800568191196
                        ],
                        [
                            4.642686481191532,
                            52.397747770527445
                        ],
                        [
                            4.640893091248131,
                            52.39772568855685
                        ],
                        [
                            4.640077202877961,
                            52.39803432200159
                        ],
                        [
                            4.639687160670661,
                            52.3976564758776
                        ],
                        [
                            4.639436439744486,
                            52.39688183008397
                        ],
                        [
                            4.6378590356197265,
                            52.397019235869635
                        ],
                        [
                            4.637633856614173,
                            52.39690882408266
                        ],
                        [
                            4.637376510356859,
                            52.39524307161335
                        ],
                        [
                            4.636966362881196,
                            52.39526760851598
                        ],
                        [
                            4.637071102137185,
                            52.396050681554385
                        ],
                        [
                            4.635543101943995,
                            52.3962960465355
                        ],
                        [
                            4.633464217188703,
                            52.39661256398094
                        ],
                        [
                            4.633125495496557,
                            52.396045525806386
                        ],
                        [
                            4.631500989807336,
                            52.396428292974946
                        ],
                        [
                            4.6315412003440315,
                            52.39671781978129
                        ],
                        [
                            4.6313200423908825,
                            52.39677179914551
                        ],
                        [
                            4.631163221297669,
                            52.39651907700866
                        ],
                        [
                            4.629643258837746,
                            52.39690675146858
                        ],
                        [
                            4.629864416790866,
                            52.397466168369505
                        ],
                        [
                            4.629229090308343,
                            52.39767717465688
                        ],
                        [
                            4.628762648081278,
                            52.396835597056025
                        ],
                        [
                            4.628119279491472,
                            52.39699262733092
                        ],
                        [
                            4.627069784479602,
                            52.397510332559904
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "es--01--001",
            "properties": {
                "land": "es",
                "city": "Madrid",
                "zone": "ZBE (2023)",
                "area_code": 0,
                "time": "official until 31/12/24 than extended the perimeter outside M30",
                "commercial": 1,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 0,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. DGT-B Sticker",
                "img_sign": "sign-es--white",
                "color": 1,
                "urlZone": "https://www.madrid360.es/medio-ambiente/zonas-de-bajas-emisiones/",
                "urlPark": "https://www.esmadrid.com/aparcar-madrid?utm_referrer=https%3A%2F%2Fwww.esmadrid.com%2Fde%2Fautofahren-madrid%3Futm_referrer%3Dhttps%253A%252F%252Fwww.google.com%252F",
                "urlReg": null,
                "urlBadge": "https://www.dgt.es/nuestros-servicios/tu-vehiculo/tus-vehiculos/distintivo-ambiental/"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -3.7512095659102442,
                            40.47194745741544
                        ],
                        [
                            -3.751119557179237,
                            40.47171532394293
                        ],
                        [
                            -3.7508099613987724,
                            40.470994706192045
                        ],
                        [
                            -3.750625130812722,
                            40.47008581524287
                        ],
                        [
                            -3.7506205099807914,
                            40.4687183593544
                        ],
                        [
                            -3.7501861577864304,
                            40.46796958251133
                        ],
                        [
                            -3.7499089078338557,
                            40.46740360302118
                        ],
                        [
                            -3.749853457843386,
                            40.46722080117283
                        ],
                        [
                            -3.7495711463648718,
                            40.46617134448039
                        ],
                        [
                            -3.7492615505844356,
                            40.46502177272404
                        ],
                        [
                            -3.7490810433881165,
                            40.464630974090824
                        ],
                        [
                            -3.748706755951787,
                            40.46401926377891
                        ],
                        [
                            -3.748346331012783,
                            40.46342864164049
                        ],
                        [
                            -3.7478652704459705,
                            40.46269368225626
                        ],
                        [
                            -3.7474262913538325,
                            40.4621663309029
                        ],
                        [
                            -3.746982695440579,
                            40.461670612521374
                        ],
                        [
                            -3.746820966301044,
                            40.46137177604507
                        ],
                        [
                            -3.7467193079853303,
                            40.460844414313584
                        ],
                        [
                            -3.7468671746262316,
                            40.460295953721726
                        ],
                        [
                            -3.7466592371614524,
                            40.460197511602814
                        ],
                        [
                            -3.7464420580328124,
                            40.46061588961146
                        ],
                        [
                            -3.7465298538512286,
                            40.461262788293396
                        ],
                        [
                            -3.7460862539257107,
                            40.460745972998865
                        ],
                        [
                            -3.7457997623078825,
                            40.460317048442676
                        ],
                        [
                            -3.745557451183771,
                            40.45961397231602
                        ],
                        [
                            -3.7454650345320033,
                            40.45900924802646
                        ],
                        [
                            -3.745169301067733,
                            40.457928260030855
                        ],
                        [
                            -3.744730321975595,
                            40.45714069128624
                        ],
                        [
                            -3.7443986239509286,
                            40.456508726116994
                        ],
                        [
                            -3.7448653280389976,
                            40.456357538567346
                        ],
                        [
                            -3.7451148529955844,
                            40.45621689868062
                        ],
                        [
                            -3.7450871280010176,
                            40.45583013747486
                        ],
                        [
                            -3.7444448322760877,
                            40.45489486935671
                        ],
                        [
                            -3.744047442194102,
                            40.45452567813234
                        ],
                        [
                            -3.743705500585719,
                            40.45413539108122
                        ],
                        [
                            -3.7437655714095968,
                            40.453411068565316
                        ],
                        [
                            -3.7437655714095968,
                            40.453108679184936
                        ],
                        [
                            -3.7436500555821226,
                            40.45262344410645
                        ],
                        [
                            -3.743562259763678,
                            40.45227182429818
                        ],
                        [
                            -3.7433820472934656,
                            40.45204327043595
                        ],
                        [
                            -3.743419013954707,
                            40.45178658594071
                        ],
                        [
                            -3.7431925931593355,
                            40.45139628298071
                        ],
                        [
                            -3.7429461029235256,
                            40.4508980898168
                        ],
                        [
                            -3.7426827154683338,
                            40.45023350976814
                        ],
                        [
                            -3.7426180238124687,
                            40.45006824281015
                        ],
                        [
                            -3.742363878021081,
                            40.449677929869495
                        ],
                        [
                            -3.741961855609077,
                            40.44911882687907
                        ],
                        [
                            -3.7416152931673707,
                            40.44863708215283
                        ],
                        [
                            -3.741241005731098,
                            40.44803929192423
                        ],
                        [
                            -3.7405690042526487,
                            40.446787171806506
                        ],
                        [
                            -3.7403564459544896,
                            40.446495302149884
                        ],
                        [
                            -3.7402455491093463,
                            40.44572869730774
                        ],
                        [
                            -3.7402871366025465,
                            40.44512384804872
                        ],
                        [
                            -3.7402363074454,
                            40.4446596577163
                        ],
                        [
                            -3.7400653422600953,
                            40.4442060102819
                        ],
                        [
                            -3.739815817302116,
                            40.44379104674323
                        ],
                        [
                            -3.739538567349598,
                            40.44322486354636
                        ],
                        [
                            -3.739390700707304,
                            40.44261647504382
                        ],
                        [
                            -3.7392390559231785,
                            40.44162139093271
                        ],
                        [
                            -3.7391743642673134,
                            40.44090748394481
                        ],
                        [
                            -3.7391420958081483,
                            40.4401264508862
                        ],
                        [
                            -3.738924916678087,
                            40.44001391182482
                        ],
                        [
                            -3.738707737547969,
                            40.43942659554111
                        ],
                        [
                            -3.7382225524578416,
                            40.43854033331556
                        ],
                        [
                            -3.7380977899795482,
                            40.437326983000986
                        ],
                        [
                            -3.7382595165829855,
                            40.43672806335027
                        ],
                        [
                            -3.738504420707642,
                            40.435922660832375
                        ],
                        [
                            -3.738598294107021,
                            40.435296993566055
                        ],
                        [
                            -3.7386121566042902,
                            40.43481866689615
                        ],
                        [
                            -3.738524706026709,
                            40.43426885001779
                        ],
                        [
                            -3.73834449355644,
                            40.43368851790626
                        ],
                        [
                            -3.7384461515036946,
                            40.433495072937944
                        ],
                        [
                            -3.73813655572323,
                            40.432960457991754
                        ],
                        [
                            -3.7379055140944217,
                            40.43223942452431
                        ],
                        [
                            -3.7374249462485807,
                            40.43095210058016
                        ],
                        [
                            -3.7370917792806893,
                            40.43009200119761
                        ],
                        [
                            -3.7370270876262737,
                            40.429933719469176
                        ],
                        [
                            -3.7358256715012885,
                            40.4284739935313
                        ],
                        [
                            -3.734975438313029,
                            40.427439854625845
                        ],
                        [
                            -3.7346226911952556,
                            40.42697137677385
                        ],
                        [
                            -3.734276128753521,
                            40.42661610522342
                        ],
                        [
                            -3.7334628622251387,
                            40.42609902347482
                        ],
                        [
                            -3.732644972429256,
                            40.42557841688685
                        ],
                        [
                            -3.731998055872367,
                            40.425131679738115
                        ],
                        [
                            -3.73106464769765,
                            40.424649763109386
                        ],
                        [
                            -3.729259308992539,
                            40.42356738709438
                        ],
                        [
                            -3.7285661841104627,
                            40.42314174297894
                        ],
                        [
                            -3.727808367571157,
                            40.42279348669962
                        ],
                        [
                            -3.7266455601478583,
                            40.42237247272945
                        ],
                        [
                            -3.724751140217194,
                            40.42167419771292
                        ],
                        [
                            -3.723893888858697,
                            40.42129883821946
                        ],
                        [
                            -3.723440826643156,
                            40.42090658074062
                        ],
                        [
                            -3.7230588332709544,
                            40.42053629915745
                        ],
                        [
                            -3.722761233579348,
                            40.420324951422
                        ],
                        [
                            -3.7226524098115874,
                            40.420152491177674
                        ],
                        [
                            -3.7225791203375707,
                            40.41997664890397
                        ],
                        [
                            -3.7224858435095314,
                            40.419569165159174
                        ],
                        [
                            -3.7224347629661168,
                            40.419148153761114
                        ],
                        [
                            -3.7224258789229907,
                            40.418457336102705
                        ],
                        [
                            -3.7225169355445473,
                            40.41786554248239
                        ],
                        [
                            -3.722605552287348,
                            40.417527705807544
                        ],
                        [
                            -3.7226899462294227,
                            40.41723687814627
                        ],
                        [
                            -3.723149325256088,
                            40.41641892891073
                        ],
                        [
                            -3.7235206440708453,
                            40.41532317249758
                        ],
                        [
                            -3.7239107928336352,
                            40.41487336109549
                        ],
                        [
                            -3.7240062912410394,
                            40.41432719121889
                        ],
                        [
                            -3.724017395568893,
                            40.4139474456353
                        ],
                        [
                            -3.7240329418213776,
                            40.413333629867395
                        ],
                        [
                            -3.7239640942030974,
                            40.4130467294228
                        ],
                        [
                            -3.7233281722292304,
                            40.412000132364
                        ],
                        [
                            -3.7230905366554907,
                            40.411535107475885
                        ],
                        [
                            -3.7229550619379665,
                            40.41117323108605
                        ],
                        [
                            -3.7225636363661465,
                            40.41009817555204
                        ],
                        [
                            -3.7225747408328402,
                            40.40988172188642
                        ],
                        [
                            -3.722392627242698,
                            40.409563803619506
                        ],
                        [
                            -3.7222882452622343,
                            40.40904464774144
                        ],
                        [
                            -3.7222776604940577,
                            40.408703091641286
                        ],
                        [
                            -3.72233540371829,
                            40.40821944199638
                        ],
                        [
                            -3.722607535236534,
                            40.40746348292771
                        ],
                        [
                            -3.7229700215875994,
                            40.40652135620098
                        ],
                        [
                            -3.7231558733373333,
                            40.40582207966665
                        ],
                        [
                            -3.7231777382487223,
                            40.405534874699896
                        ],
                        [
                            -3.7232214680723246,
                            40.40501457272612
                        ],
                        [
                            -3.7231741708917525,
                            40.404222853102084
                        ],
                        [
                            -3.7231572159918187,
                            40.40392912731258
                        ],
                        [
                            -3.7231063512797675,
                            40.40353533849205
                        ],
                        [
                            -3.723083038292714,
                            40.403314235071946
                        ],
                        [
                            -3.7232229162137855,
                            40.40337233531096
                        ],
                        [
                            -3.7233204068863586,
                            40.4032819571394
                        ],
                        [
                            -3.7232229162137855,
                            40.40318835104847
                        ],
                        [
                            -3.722972831412278,
                            40.40293980887401
                        ],
                        [
                            -3.7228626245666305,
                            40.402452407738025
                        ],
                        [
                            -3.722714269220461,
                            40.401843958247724
                        ],
                        [
                            -3.722578630024998,
                            40.40142110687242
                        ],
                        [
                            -3.7224387520199116,
                            40.40115641967043
                        ],
                        [
                            -3.7222713223874564,
                            40.40088850377586
                        ],
                        [
                            -3.721675641465424,
                            40.40033154446803
                        ],
                        [
                            -3.7212602464278177,
                            40.400066853239
                        ],
                        [
                            -3.7209169097128836,
                            40.39989092983416
                        ],
                        [
                            -3.720429457577467,
                            40.39969886654305
                        ],
                        [
                            -3.7195838319638597,
                            40.39948904843848
                        ],
                        [
                            -3.719113331024971,
                            40.39942448833
                        ],
                        [
                            -3.718388509070934,
                            40.399361542711034
                        ],
                        [
                            -3.7176191750931764,
                            40.399224353409494
                        ],
                        [
                            -3.7171656315313726,
                            40.39902260382203
                        ],
                        [
                            -3.716898591863469,
                            40.39900000783058
                        ],
                        [
                            -3.7166357909212593,
                            40.3988789577472
                        ],
                        [
                            -3.7160296532641155,
                            40.398975797831525
                        ],
                        [
                            -3.7157308184773115,
                            40.399053270994216
                        ],
                        [
                            -3.715673595691726,
                            40.39928407236698
                        ],
                        [
                            -3.71534085578827,
                            40.39922112661671
                        ],
                        [
                            -3.7147135245068625,
                            40.399040359006136
                        ],
                        [
                            -3.7145439755115603,
                            40.398933835008194
                        ],
                        [
                            -3.7140854663272194,
                            40.39877113099564
                        ],
                        [
                            -3.7136754294042476,
                            40.39850428623694
                        ],
                        [
                            -3.713313851389813,
                            40.39819769733609
                        ],
                        [
                            -3.712642881878537,
                            40.39770658442802
                        ],
                        [
                            -3.7120949176786837,
                            40.39735456767502
                        ],
                        [
                            -3.712296208532308,
                            40.397104750335984
                        ],
                        [
                            -3.7112487505738443,
                            40.39681518816937
                        ],
                        [
                            -3.7106262399711625,
                            40.39651710816793
                        ],
                        [
                            -3.709608602879399,
                            40.396099793948565
                        ],
                        [
                            -3.7086729857052205,
                            40.39581875100154
                        ],
                        [
                            -3.7077000799141615,
                            40.39554905563719
                        ],
                        [
                            -3.706876278459049,
                            40.3953077483431
                        ],
                        [
                            -3.706503517619751,
                            40.3950380509319
                        ],
                        [
                            -3.7062313999951186,
                            40.394720089021604
                        ],
                        [
                            -3.705970467406928,
                            40.39426869617441
                        ],
                        [
                            -3.705303225504707,
                            40.39405577396937
                        ],
                        [
                            -3.7051653039936525,
                            40.39385420699563
                        ],
                        [
                            -3.704419782315,
                            40.39377755460754
                        ],
                        [
                            -3.7039351932234297,
                            40.39373780889048
                        ],
                        [
                            -3.7032977699358582,
                            40.393564630152326
                        ],
                        [
                            -3.702716263025991,
                            40.393189882703666
                        ],
                        [
                            -3.702302498493907,
                            40.39289462566896
                        ],
                        [
                            -3.7020042898231225,
                            40.39259936734004
                        ],
                        [
                            -3.7013333221805738,
                            40.3918555395083
                        ],
                        [
                            -3.699760291232309,
                            40.39008397125656
                        ],
                        [
                            -3.6991005045471184,
                            40.38936566635519
                        ],
                        [
                            -3.6983698933012477,
                            40.38870697686659
                        ],
                        [
                            -3.6977399305261827,
                            40.3881533341285
                        ],
                        [
                            -3.6985190006806192,
                            40.38824986740485
                        ],
                        [
                            -3.698485452205972,
                            40.388062479153405
                        ],
                        [
                            -3.6978554863863735,
                            40.38794891026214
                        ],
                        [
                            -3.6974976359809943,
                            40.3878012704173
                        ],
                        [
                            -3.6969347671129356,
                            40.38741229466146
                        ],
                        [
                            -3.696133332400791,
                            40.38674506909413
                        ],
                        [
                            -3.6951827922606526,
                            40.38589044177053
                        ],
                        [
                            -3.6941614275598056,
                            40.3850812331475
                        ],
                        [
                            -3.693097939221218,
                            40.38421212846575
                        ],
                        [
                            -3.692605894913811,
                            40.38378054098993
                        ],
                        [
                            -3.6918790152601275,
                            40.382968470253786
                        ],
                        [
                            -3.6907756431749306,
                            40.381988858188606
                        ],
                        [
                            -3.68998912295055,
                            40.38144935796717
                        ],
                        [
                            -3.6891504110614903,
                            40.381048987468176
                        ],
                        [
                            -3.688166322445568,
                            40.38064861458969
                        ],
                        [
                            -3.687387252376169,
                            40.38034194438259
                        ],
                        [
                            -3.6868094730753853,
                            40.380296511642285
                        ],
                        [
                            -3.6859670335784074,
                            40.38030219073664
                        ],
                        [
                            -3.685530903395403,
                            40.38021700427251
                        ],
                        [
                            -3.6852923364581045,
                            40.380100582597976
                        ],
                        [
                            -3.685068679732808,
                            40.38082466571106
                        ],
                        [
                            -3.6848785717040755,
                            40.38117108686728
                        ],
                        [
                            -3.6840100379976093,
                            40.3823579939899
                        ],
                        [
                            -3.6830408598144686,
                            40.38307921305727
                        ],
                        [
                            -3.6818293870004766,
                            40.38412127574642
                        ],
                        [
                            -3.68130379421612,
                            40.384768652648575
                        ],
                        [
                            -3.6808415710077327,
                            40.38653754939557
                        ],
                        [
                            -3.6799133989648,
                            40.38800828063489
                        ],
                        [
                            -3.67861246654212,
                            40.39002125739577
                        ],
                        [
                            -3.678213612443983,
                            40.390415896325436
                        ],
                        [
                            -3.677736478569358,
                            40.39067709492096
                        ],
                        [
                            -3.677572463702006,
                            40.3912136845459
                        ],
                        [
                            -3.6761336068611854,
                            40.39227265568542
                        ],
                        [
                            -3.6750451550830974,
                            40.39286317060356
                        ],
                        [
                            -3.674187807750627,
                            40.393601309664064
                        ],
                        [
                            -3.6729726074132145,
                            40.39434795894209
                        ],
                        [
                            -3.6719810664413615,
                            40.394969686465146
                        ],
                        [
                            -3.671377193880602,
                            40.39540971897378
                        ],
                        [
                            -3.6706540373999985,
                            40.39615350963112
                        ],
                        [
                            -3.669949519414075,
                            40.39699665134788
                        ],
                        [
                            -3.6689505252238916,
                            40.398228693695756
                        ],
                        [
                            -3.6685516711257264,
                            40.398699931398426
                        ],
                        [
                            -3.668234824411968,
                            40.39910587448588
                        ],
                        [
                            -3.6679627095133185,
                            40.399378393922404
                        ],
                        [
                            -3.6677688738766676,
                            40.3994039426029
                        ],
                        [
                            -3.667668228449287,
                            40.399605492963076
                        ],
                        [
                            -3.667906795386614,
                            40.39957142815584
                        ],
                        [
                            -3.6676123143237476,
                            40.39999439829157
                        ],
                        [
                            -3.6672992748701176,
                            40.40047377578375
                        ],
                        [
                            -3.6671756850177246,
                            40.40064318751109
                        ],
                        [
                            -3.666972997659343,
                            40.400729775563065
                        ],
                        [
                            -3.66699771563043,
                            40.40093306881133
                        ],
                        [
                            -3.6670619823537436,
                            40.401068597303066
                        ],
                        [
                            -3.666701101005117,
                            40.40192317175132
                        ],
                        [
                            -3.6662611211303044,
                            40.40300737609604
                        ],
                        [
                            -3.6658606904642,
                            40.403519359299395
                        ],
                        [
                            -3.664763212573604,
                            40.40439272679072
                        ],
                        [
                            -3.663655847496102,
                            40.40512303401064
                        ],
                        [
                            -3.6632356390698817,
                            40.405367727685416
                        ],
                        [
                            -3.662731392471784,
                            40.40554842037528
                        ],
                        [
                            -3.662162879150401,
                            40.405672646318465
                        ],
                        [
                            -3.662227145873686,
                            40.40627118628524
                        ],
                        [
                            -3.6619107558513235,
                            40.40645564092577
                        ],
                        [
                            -3.662375453696427,
                            40.40666644560966
                        ],
                        [
                            -3.6626028590251565,
                            40.40684713481306
                        ],
                        [
                            -3.662721506516391,
                            40.40708805336061
                        ],
                        [
                            -3.6628648707456364,
                            40.40751342242362
                        ],
                        [
                            -3.6628895887160127,
                            40.408187231631246
                        ],
                        [
                            -3.6628846452058497,
                            40.40909441724307
                        ],
                        [
                            -3.6628302656702374,
                            40.40966657741603
                        ],
                        [
                            -3.6627066758178444,
                            40.410103222696705
                        ],
                        [
                            -3.662469383301442,
                            40.41066784601409
                        ],
                        [
                            -3.6619107566174023,
                            40.411646516858696
                        ],
                        [
                            -3.6605809315554154,
                            40.41372049087795
                        ],
                        [
                            -3.6593351460128076,
                            40.41570030902599
                        ],
                        [
                            -3.658890222543846,
                            40.41645684026406
                        ],
                        [
                            -3.6585441747305367,
                            40.41673160233705
                        ],
                        [
                            -3.658400810502002,
                            40.41679182311796
                        ],
                        [
                            -3.6577087073282257,
                            40.41686709901802
                        ],
                        [
                            -3.6574714148110843,
                            40.41693484725593
                        ],
                        [
                            -3.6572489530773282,
                            40.41701012299595
                        ],
                        [
                            -3.657011660560187,
                            40.417141855337974
                        ],
                        [
                            -3.656838634767098,
                            40.41727735119275
                        ],
                        [
                            -3.6566211187077613,
                            40.41748435875749
                        ],
                        [
                            -3.656354164626066,
                            40.41767631016023
                        ],
                        [
                            -3.6558153128699473,
                            40.417698892642136
                        ],
                        [
                            -3.655409938153184,
                            40.41761609017098
                        ],
                        [
                            -3.655202307201364,
                            40.418011282864995
                        ],
                        [
                            -3.6557164409878737,
                            40.41828979822591
                        ],
                        [
                            -3.6561366464857485,
                            40.41854949394332
                        ],
                        [
                            -3.656339335862299,
                            40.418963501609966
                        ],
                        [
                            -3.656616177131639,
                            40.418907046452404
                        ],
                        [
                            -3.6567941465189335,
                            40.41922319472681
                        ],
                        [
                            -3.6575159112573203,
                            40.41973505068742
                        ],
                        [
                            -3.658168465678898,
                            40.42001732252922
                        ],
                        [
                            -3.658381040224924,
                            40.42025442996169
                        ],
                        [
                            -3.6585145181636562,
                            40.421142635714546
                        ],
                        [
                            -3.658559010510089,
                            40.42168834878862
                        ],
                        [
                            -3.658084425476602,
                            40.421733511051514
                        ],
                        [
                            -3.6575851224727387,
                            40.421820071970586
                        ],
                        [
                            -3.657263788856085,
                            40.42197061243462
                        ],
                        [
                            -3.657347829956308,
                            40.422117389062606
                        ],
                        [
                            -3.6578916253073714,
                            40.42198942996882
                        ],
                        [
                            -3.658385984717029,
                            40.42210233506444
                        ],
                        [
                            -3.658499689060818,
                            40.42245610367098
                        ],
                        [
                            -3.6587369815771638,
                            40.422791052577054
                        ],
                        [
                            -3.659216509712735,
                            40.4252372576785
                        ],
                        [
                            -3.6594043666054574,
                            40.42643398529091
                        ],
                        [
                            -3.6593697614466976,
                            40.426768914389186
                        ],
                        [
                            -3.659557618022376,
                            40.426881811462636
                        ],
                        [
                            -3.6597355874104665,
                            40.42685546882916
                        ],
                        [
                            -3.65989872563361,
                            40.428101088884176
                        ],
                        [
                            -3.6600519770505286,
                            40.42922626562424
                        ],
                        [
                            -3.6602250026842285,
                            40.43094974156489
                        ],
                        [
                            -3.6602447770611093,
                            40.43182275305631
                        ],
                        [
                            -3.6600915261162754,
                            40.43253771291481
                        ],
                        [
                            -3.659869064381809,
                            40.4334295181246
                        ],
                        [
                            -3.6595625614762923,
                            40.43472393102414
                        ],
                        [
                            -3.659226397077873,
                            40.43630428584456
                        ],
                        [
                            -3.6590484275727704,
                            40.43685739989516
                        ],
                        [
                            -3.6587320378772006,
                            40.438260864708866
                        ],
                        [
                            -3.6586282224008926,
                            40.43903219243461
                        ],
                        [
                            -3.6585392377072026,
                            40.439961536524805
                        ],
                        [
                            -3.658544179521101,
                            40.44068392964783
                        ],
                        [
                            -3.6585738410856834,
                            40.44120690958752
                        ],
                        [
                            -3.6586628233085605,
                            40.44241839868269
                        ],
                        [
                            -3.658682597684617,
                            40.44361858181932
                        ],
                        [
                            -3.6586578785889685,
                            40.44411520135657
                        ],
                        [
                            -3.6585392323307815,
                            40.44433341255788
                        ],
                        [
                            -3.6582871090317894,
                            40.444841315196925
                        ],
                        [
                            -3.6579904933855403,
                            40.44517991482354
                        ],
                        [
                            -3.6576988213342645,
                            40.44531535419725
                        ],
                        [
                            -3.6570561552749723,
                            40.44552980433207
                        ],
                        [
                            -3.657332996544369,
                            40.44595869251185
                        ],
                        [
                            -3.6582475614526686,
                            40.445849589638186
                        ],
                        [
                            -3.658643048980224,
                            40.44588721133857
                        ],
                        [
                            -3.6592165058958415,
                            40.44600007631405
                        ],
                        [
                            -3.66010635283385,
                            40.4463574808199
                        ],
                        [
                            -3.6607243020959004,
                            40.446771315242046
                        ],
                        [
                            -3.6611642818858456,
                            40.4473506790396
                        ],
                        [
                            -3.6614608975313843,
                            40.447948848226076
                        ],
                        [
                            -3.661851441128931,
                            40.44926931486964
                        ],
                        [
                            -3.662256815844927,
                            40.45091327988982
                        ],
                        [
                            -3.6622914213478737,
                            40.45127442075761
                        ],
                        [
                            -3.66227164697176,
                            40.45209450285836
                        ],
                        [
                            -3.6624496163590265,
                            40.45251206375295
                        ],
                        [
                            -3.662578149805711,
                            40.45295971624404
                        ],
                        [
                            -3.6626819651475557,
                            40.453418650674905
                        ],
                        [
                            -3.6627165703063156,
                            40.45397538698663
                        ],
                        [
                            -3.662810498594183,
                            40.454708917651544
                        ],
                        [
                            -3.662968692976733,
                            40.45576970065079
                        ],
                        [
                            -3.6631170008002414,
                            40.4566311051467
                        ],
                        [
                            -3.663349348444001,
                            40.45751506646215
                        ],
                        [
                            -3.6635866409610855,
                            40.458308742089
                        ],
                        [
                            -3.663823932222897,
                            40.458997086954355
                        ],
                        [
                            -3.6640809991162087,
                            40.45961395947967
                        ],
                        [
                            -3.6637942706584,
                            40.459538731426676
                        ],
                        [
                            -3.6634778806360373,
                            40.459565061255034
                        ],
                        [
                            -3.6630527315438712,
                            40.45990358669923
                        ],
                        [
                            -3.6628945345504462,
                            40.460212022342745
                        ],
                        [
                            -3.6630774475327144,
                            40.4605054083348
                        ],
                        [
                            -3.6633987811485724,
                            40.46057687395924
                        ],
                        [
                            -3.663329570831735,
                            40.46077622503634
                        ],
                        [
                            -3.662963744867966,
                            40.46097557552136
                        ],
                        [
                            -3.6632455297315687,
                            40.46116740278842
                        ],
                        [
                            -3.6636113556953376,
                            40.460858974365976
                        ],
                        [
                            -3.6638486482117116,
                            40.460840167709165
                        ],
                        [
                            -3.6643874999685693,
                            40.46053926048239
                        ],
                        [
                            -3.6647632131200396,
                            40.46108841515698
                        ],
                        [
                            -3.6654743826441063,
                            40.46217255659124
                        ],
                        [
                            -3.666381899380781,
                            40.46377164668468
                        ],
                        [
                            -3.666552059012133,
                            40.46417441069488
                        ],
                        [
                            -3.6669995133778457,
                            40.465135757215876
                        ],
                        [
                            -3.667406005830429,
                            40.46600119979931
                        ],
                        [
                            -3.6675635588949262,
                            40.46643031884091
                        ],
                        [
                            -3.667472176870092,
                            40.46661011738419
                        ],
                        [
                            -3.6672787598349714,
                            40.46670405759659
                        ],
                        [
                            -3.666727561622878,
                            40.46679128075195
                        ],
                        [
                            -3.666851030022542,
                            40.46708985146455
                        ],
                        [
                            -3.66719938729193,
                            40.467294489051795
                        ],
                        [
                            -3.667605070233151,
                            40.46787149898128
                        ],
                        [
                            -3.667944608330913,
                            40.4680425874744
                        ],
                        [
                            -3.66865896121422,
                            40.468113035550914
                        ],
                        [
                            -3.6689544034553876,
                            40.468307606044675
                        ],
                        [
                            -3.670144990518196,
                            40.46977022037714
                        ],
                        [
                            -3.670436023174176,
                            40.470243215304436
                        ],
                        [
                            -3.6707094174869894,
                            40.470340497536284
                        ],
                        [
                            -3.671079822684675,
                            40.47079336124159
                        ],
                        [
                            -3.671596348734255,
                            40.471471504784404
                        ],
                        [
                            -3.671823645146503,
                            40.471994352463014
                        ],
                        [
                            -3.6719264697811127,
                            40.472175495811655
                        ],
                        [
                            -3.6722536947843025,
                            40.47260995601056
                        ],
                        [
                            -3.6728800311881287,
                            40.4735107719745
                        ],
                        [
                            -3.6731834137589487,
                            40.47422173485461
                        ],
                        [
                            -3.6737021000884624,
                            40.47495874601924
                        ],
                        [
                            -3.67395165649981,
                            40.475680860400985
                        ],
                        [
                            -3.6741131343196116,
                            40.47687568179549
                        ],
                        [
                            -3.6741424932172606,
                            40.4775345001037
                        ],
                        [
                            -3.6741131336137585,
                            40.47824914245422
                        ],
                        [
                            -3.6738342186204704,
                            40.48010270912005
                        ],
                        [
                            -3.673605493172033,
                            40.48093667672916
                        ],
                        [
                            -3.673302110852802,
                            40.48189692667822
                        ],
                        [
                            -3.6728519302650966,
                            40.48257802607728
                        ],
                        [
                            -3.671829237206339,
                            40.48334472003921
                        ],
                        [
                            -3.6711441797897066,
                            40.48374295073543
                        ],
                        [
                            -3.6700823404587197,
                            40.483929039020865
                        ],
                        [
                            -3.6691868725492895,
                            40.483958813101225
                        ],
                        [
                            -3.66920155235141,
                            40.4843123792939
                        ],
                        [
                            -3.6709386622291333,
                            40.484297492333525
                        ],
                        [
                            -3.6723723864354554,
                            40.48424538780506
                        ],
                        [
                            -3.6730378707833324,
                            40.48424910954779
                        ],
                        [
                            -3.6736886753291174,
                            40.48424166606151
                        ],
                        [
                            -3.6739382319594256,
                            40.48413745716195
                        ],
                        [
                            -3.675274103058541,
                            40.48413001428304
                        ],
                        [
                            -3.6755481260258307,
                            40.48426771888086
                        ],
                        [
                            -3.6761646777006263,
                            40.48413001428304
                        ],
                        [
                            -3.6769916398675946,
                            40.484081631519416
                        ],
                        [
                            -3.677231409963923,
                            40.48420444923602
                        ],
                        [
                            -3.678738536280065,
                            40.48405185749357
                        ],
                        [
                            -3.6804854246333036,
                            40.48368340267476
                        ],
                        [
                            -3.6820953095629534,
                            40.48329261521761
                        ],
                        [
                            -3.6835045705344953,
                            40.48312141237679
                        ],
                        [
                            -3.6841015169846685,
                            40.48316979572502
                        ],
                        [
                            -3.6845223379695824,
                            40.48311396865739
                        ],
                        [
                            -3.6848795464792943,
                            40.48316235211857
                        ],
                        [
                            -3.6861077565623077,
                            40.483188404737064
                        ],
                        [
                            -3.6874177393337106,
                            40.48340736758868
                        ],
                        [
                            -3.6881799711378562,
                            40.48358091063443
                        ],
                        [
                            -3.6883691365516995,
                            40.48367375897956
                        ],
                        [
                            -3.6900449388410834,
                            40.48445193120784
                        ],
                        [
                            -3.692183788547908,
                            40.48528711357761
                        ],
                        [
                            -3.6925762883837194,
                            40.48552861413552
                        ],
                        [
                            -3.6931187183093925,
                            40.485709735999706
                        ],
                        [
                            -3.6938728282056275,
                            40.48585060822339
                        ],
                        [
                            -3.69477246808205,
                            40.485770109845845
                        ],
                        [
                            -3.695367818000335,
                            40.4856560703133
                        ],
                        [
                            -3.696223367708569,
                            40.48537431978119
                        ],
                        [
                            -3.6965761676604814,
                            40.485344132686635
                        ],
                        [
                            -3.697422887544235,
                            40.48488126220704
                        ],
                        [
                            -3.698767937358639,
                            40.48434459678526
                        ],
                        [
                            -3.7007127463334655,
                            40.48361338374659
                        ],
                        [
                            -3.703120605694778,
                            40.48276476355343
                        ],
                        [
                            -3.704902245450853,
                            40.4821240985093
                        ],
                        [
                            -3.705682817698545,
                            40.481959735724445
                        ],
                        [
                            -3.709047648559448,
                            40.481245266930785
                        ],
                        [
                            -3.7102030684014267,
                            40.481057424346204
                        ],
                        [
                            -3.712024403001635,
                            40.48070521608463
                        ],
                        [
                            -3.712518322933505,
                            40.48054420689198
                        ],
                        [
                            -3.713400322813243,
                            40.48028592050579
                        ],
                        [
                            -3.71443667312829,
                            40.4798632673519
                        ],
                        [
                            -3.715106993036045,
                            40.479658648870696
                        ],
                        [
                            -3.7156494233857984,
                            40.47943390206669
                        ],
                        [
                            -3.716879813215911,
                            40.47885693974706
                        ],
                        [
                            -3.71760305710319,
                            40.478394019452395
                        ],
                        [
                            -3.7207958962482053,
                            40.47634105300071
                        ],
                        [
                            -3.7216426161319305,
                            40.475794255503615
                        ],
                        [
                            -3.723036174773796,
                            40.4749421828883
                        ],
                        [
                            -3.72415631327155,
                            40.4743618280981
                        ],
                        [
                            -3.725307323113327,
                            40.47387539902732
                        ],
                        [
                            -3.726550941453638,
                            40.47346612606535
                        ],
                        [
                            -3.728451651155126,
                            40.47301994733556
                        ],
                        [
                            -3.7298672609601624,
                            40.472845500725725
                        ],
                        [
                            -3.731216718979681,
                            40.47279182503618
                        ],
                        [
                            -3.7332982386936635,
                            40.4728656294856
                        ],
                        [
                            -3.7346873885030902,
                            40.47305014025352
                        ],
                        [
                            -3.7358384043116644,
                            40.473281616533825
                        ],
                        [
                            -3.7393884497624867,
                            40.474140419652
                        ],
                        [
                            -3.741165679518417,
                            40.474482596360275
                        ],
                        [
                            -3.7427047673389495,
                            40.47455304411909
                        ],
                        [
                            -3.747802721603506,
                            40.47461678247353
                        ],
                        [
                            -3.749005453305898,
                            40.474335615909325
                        ],
                        [
                            -3.7498247381118404,
                            40.473938514137416
                        ],
                        [
                            -3.750412013061066,
                            40.47342007217907
                        ],
                        [
                            -3.750854300817622,
                            40.47263688915572
                        ],
                        [
                            -3.7509340542055725,
                            40.47203018959826
                        ],
                        [
                            -3.7512095659102442,
                            40.47194745741544
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "es--01--002",
            "properties": {
                "land": "es",
                "city": "Madrid",
                "zone": "ZBE (2024)",
                "area_code": 0,
                "time": "Starts on 1/1/2024 (grace period until 1/5/2024)",
                "commercial": 0,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. DGT-B Sticker",
                "img_sign": "sign-es--white",
                "color": 0,
                "urlZone": "https://www.madrid360.es/medio-ambiente/zonas-de-bajas-emisiones/",
                "urlPark": "https://www.esmadrid.com/aparcar-madrid?utm_referrer=https%3A%2F%2Fwww.esmadrid.com%2Fde%2Fautofahren-madrid%3Futm_referrer%3Dhttps%253A%252F%252Fwww.google.com%252F",
                "urlReg": null,
                "urlBadge": "https://www.dgt.es/nuestros-servicios/tu-vehiculo/tus-vehiculos/distintivo-ambiental/"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -3.8854952,
                                40.5746025
                            ],
                            [
                                -3.8844087,
                                40.5773387
                            ],
                            [
                                -3.8837329,
                                40.5790986
                            ],
                            [
                                -3.884133,
                                40.5810028
                            ],
                            [
                                -3.8841732,
                                40.5812852
                            ],
                            [
                                -3.8837233,
                                40.5820441
                            ],
                            [
                                -3.8834272,
                                40.5825336
                            ],
                            [
                                -3.8837424,
                                40.5843299
                            ],
                            [
                                -3.8836855,
                                40.5852714
                            ],
                            [
                                -3.8772769,
                                40.5884618
                            ],
                            [
                                -3.8768501,
                                40.5886153
                            ],
                            [
                                -3.876103,
                                40.5892655
                            ],
                            [
                                -3.8761896,
                                40.5896393
                            ],
                            [
                                -3.8761715,
                                40.5899802
                            ],
                            [
                                -3.8743765,
                                40.5908729
                            ],
                            [
                                -3.8739154,
                                40.5911445
                            ],
                            [
                                -3.8687519,
                                40.5920347
                            ],
                            [
                                -3.8673773,
                                40.592161
                            ],
                            [
                                -3.8660265,
                                40.5917918
                            ],
                            [
                                -3.8659706,
                                40.5917814
                            ],
                            [
                                -3.8649058,
                                40.5904147
                            ],
                            [
                                -3.863538,
                                40.5895024
                            ],
                            [
                                -3.8612909,
                                40.5888869
                            ],
                            [
                                -3.859929,
                                40.5881795
                            ],
                            [
                                -3.8549787,
                                40.5879984
                            ],
                            [
                                -3.8549372,
                                40.5879979
                            ],
                            [
                                -3.8529054,
                                40.589109
                            ],
                            [
                                -3.850281,
                                40.5904657
                            ],
                            [
                                -3.8483729,
                                40.5909456
                            ],
                            [
                                -3.8456946,
                                40.59134
                            ],
                            [
                                -3.8446227,
                                40.5919649
                            ],
                            [
                                -3.8434222,
                                40.5923886
                            ],
                            [
                                -3.8424471,
                                40.5925363
                            ],
                            [
                                -3.8416398,
                                40.5926593
                            ],
                            [
                                -3.8405726,
                                40.5924114
                            ],
                            [
                                -3.8376911,
                                40.5913638
                            ],
                            [
                                -3.8354147,
                                40.5920891
                            ],
                            [
                                -3.8333338,
                                40.5935208
                            ],
                            [
                                -3.8292613,
                                40.5943076
                            ],
                            [
                                -3.8282019,
                                40.5945741
                            ],
                            [
                                -3.8251987,
                                40.5946359
                            ],
                            [
                                -3.8227392,
                                40.5943928
                            ],
                            [
                                -3.8202782,
                                40.5950039
                            ],
                            [
                                -3.8161257,
                                40.5950036
                            ],
                            [
                                -3.814585,
                                40.5953817
                            ],
                            [
                                -3.8144363,
                                40.5954184
                            ],
                            [
                                -3.8139117,
                                40.5961466
                            ],
                            [
                                -3.8110387,
                                40.5974912
                            ],
                            [
                                -3.8060054,
                                40.5998719
                            ],
                            [
                                -3.8097492,
                                40.6058564
                            ],
                            [
                                -3.8101037,
                                40.6065709
                            ],
                            [
                                -3.8125088,
                                40.6078677
                            ],
                            [
                                -3.8127852,
                                40.6081379
                            ],
                            [
                                -3.8097257,
                                40.609815
                            ],
                            [
                                -3.8096036,
                                40.6098699
                            ],
                            [
                                -3.8093489,
                                40.6094979
                            ],
                            [
                                -3.8091848,
                                40.6093216
                            ],
                            [
                                -3.8069287,
                                40.6093796
                            ],
                            [
                                -3.8061982,
                                40.6095122
                            ],
                            [
                                -3.806256,
                                40.6080238
                            ],
                            [
                                -3.8061265,
                                40.6079069
                            ],
                            [
                                -3.8051955,
                                40.6073833
                            ],
                            [
                                -3.8049141,
                                40.6072051
                            ],
                            [
                                -3.8016909,
                                40.6063494
                            ],
                            [
                                -3.80053,
                                40.6059105
                            ],
                            [
                                -3.8003093,
                                40.6048302
                            ],
                            [
                                -3.8035533,
                                40.5989977
                            ],
                            [
                                -3.803328,
                                40.5988659
                            ],
                            [
                                -3.7997974,
                                40.5985953
                            ],
                            [
                                -3.7991036,
                                40.598489
                            ],
                            [
                                -3.7972076,
                                40.5997114
                            ],
                            [
                                -3.7967724,
                                40.5998747
                            ],
                            [
                                -3.7921886,
                                40.5993107
                            ],
                            [
                                -3.7899012,
                                40.5989863
                            ],
                            [
                                -3.7856303,
                                40.6004002
                            ],
                            [
                                -3.785034,
                                40.6004707
                            ],
                            [
                                -3.7844603,
                                40.6010736
                            ],
                            [
                                -3.7834875,
                                40.6017633
                            ],
                            [
                                -3.781815,
                                40.6009946
                            ],
                            [
                                -3.7816282,
                                40.6008026
                            ],
                            [
                                -3.7811989,
                                40.6008846
                            ],
                            [
                                -3.7798177,
                                40.6011573
                            ],
                            [
                                -3.7789381,
                                40.6017409
                            ],
                            [
                                -3.7782573,
                                40.6017277
                            ],
                            [
                                -3.7709939,
                                40.6000059
                            ],
                            [
                                -3.7681695,
                                40.5996665
                            ],
                            [
                                -3.7660243,
                                40.5986709
                            ],
                            [
                                -3.7652863,
                                40.5984091
                            ],
                            [
                                -3.760917,
                                40.5972649
                            ],
                            [
                                -3.7598498,
                                40.5970096
                            ],
                            [
                                -3.7587501,
                                40.5962936
                            ],
                            [
                                -3.7584567,
                                40.5960812
                            ],
                            [
                                -3.7578213,
                                40.5960509
                            ],
                            [
                                -3.7573702,
                                40.5960619
                            ],
                            [
                                -3.7536427,
                                40.5947568
                            ],
                            [
                                -3.7529724,
                                40.5947769
                            ],
                            [
                                -3.7508279,
                                40.5930671
                            ],
                            [
                                -3.7501453,
                                40.5924316
                            ],
                            [
                                -3.7500943,
                                40.5924433
                            ],
                            [
                                -3.7492465,
                                40.592706
                            ],
                            [
                                -3.7452456,
                                40.5918462
                            ],
                            [
                                -3.7408152,
                                40.5906486
                            ],
                            [
                                -3.7381919,
                                40.5864677
                            ],
                            [
                                -3.7372529,
                                40.586071
                            ],
                            [
                                -3.7322935,
                                40.5853805
                            ],
                            [
                                -3.7298291,
                                40.5850877
                            ],
                            [
                                -3.7214402,
                                40.5826002
                            ],
                            [
                                -3.7206857,
                                40.5822087
                            ],
                            [
                                -3.7194289,
                                40.5821178
                            ],
                            [
                                -3.7178569,
                                40.5826752
                            ],
                            [
                                -3.717249,
                                40.5828907
                            ],
                            [
                                -3.7145085,
                                40.5826566
                            ],
                            [
                                -3.7130058,
                                40.583287
                            ],
                            [
                                -3.710664,
                                40.5831592
                            ],
                            [
                                -3.708096,
                                40.5837108
                            ],
                            [
                                -3.7046138,
                                40.5851825
                            ],
                            [
                                -3.7018111,
                                40.5872355
                            ],
                            [
                                -3.6972612,
                                40.5894702
                            ],
                            [
                                -3.6965813,
                                40.5899968
                            ],
                            [
                                -3.6958695,
                                40.5908749
                            ],
                            [
                                -3.6919839,
                                40.596835
                            ],
                            [
                                -3.6881659,
                                40.6048486
                            ],
                            [
                                -3.6869184,
                                40.6065407
                            ],
                            [
                                -3.6859709,
                                40.6075102
                            ],
                            [
                                -3.6850335,
                                40.6083176
                            ],
                            [
                                -3.6794697,
                                40.611017
                            ],
                            [
                                -3.6749436,
                                40.6158542
                            ],
                            [
                                -3.6695308,
                                40.6183901
                            ],
                            [
                                -3.6667206,
                                40.6199378
                            ],
                            [
                                -3.6665293,
                                40.6209118
                            ],
                            [
                                -3.6686055,
                                40.6275663
                            ],
                            [
                                -3.6685301,
                                40.6283055
                            ],
                            [
                                -3.6680532,
                                40.6295788
                            ],
                            [
                                -3.6674819,
                                40.6311043
                            ],
                            [
                                -3.6629957,
                                40.6378146
                            ],
                            [
                                -3.6611528,
                                40.6404107
                            ],
                            [
                                -3.6586288,
                                40.6423079
                            ],
                            [
                                -3.6551286,
                                40.643562
                            ],
                            [
                                -3.6542196,
                                40.6437293
                            ],
                            [
                                -3.6503427,
                                40.6427512
                            ],
                            [
                                -3.6487985,
                                40.6420482
                            ],
                            [
                                -3.6446519,
                                40.6388282
                            ],
                            [
                                -3.6438921,
                                40.6385171
                            ],
                            [
                                -3.6391303,
                                40.6389399
                            ],
                            [
                                -3.6382169,
                                40.6386477
                            ],
                            [
                                -3.6374662,
                                40.6380482
                            ],
                            [
                                -3.6367734,
                                40.6373223
                            ],
                            [
                                -3.632728,
                                40.6309483
                            ],
                            [
                                -3.6301761,
                                40.628665
                            ],
                            [
                                -3.629815,
                                40.6267211
                            ],
                            [
                                -3.6301874,
                                40.6260884
                            ],
                            [
                                -3.6289785,
                                40.6232482
                            ],
                            [
                                -3.6289375,
                                40.6226629
                            ],
                            [
                                -3.6292272,
                                40.6207695
                            ],
                            [
                                -3.6285122,
                                40.6189085
                            ],
                            [
                                -3.6284697,
                                40.618152
                            ],
                            [
                                -3.6276469,
                                40.6173998
                            ],
                            [
                                -3.6263478,
                                40.6175509
                            ],
                            [
                                -3.6230731,
                                40.6162804
                            ],
                            [
                                -3.621514,
                                40.6138744
                            ],
                            [
                                -3.6209991,
                                40.6131655
                            ],
                            [
                                -3.6191658,
                                40.6117789
                            ],
                            [
                                -3.6177287,
                                40.6110389
                            ],
                            [
                                -3.6178119,
                                40.6098042
                            ],
                            [
                                -3.617653,
                                40.6092375
                            ],
                            [
                                -3.6167636,
                                40.6076387
                            ],
                            [
                                -3.6165692,
                                40.6070632
                            ],
                            [
                                -3.616747,
                                40.605828
                            ],
                            [
                                -3.6160824,
                                40.6029667
                            ],
                            [
                                -3.6157394,
                                40.6016533
                            ],
                            [
                                -3.6061371,
                                40.5971725
                            ],
                            [
                                -3.6053167,
                                40.5966273
                            ],
                            [
                                -3.605101,
                                40.5962951
                            ],
                            [
                                -3.6047102,
                                40.5948917
                            ],
                            [
                                -3.6032553,
                                40.5934399
                            ],
                            [
                                -3.602515,
                                40.5925879
                            ],
                            [
                                -3.6019947,
                                40.5912393
                            ],
                            [
                                -3.602084,
                                40.5906533
                            ],
                            [
                                -3.6047956,
                                40.5886211
                            ],
                            [
                                -3.6051586,
                                40.5882589
                            ],
                            [
                                -3.6067771,
                                40.5855838
                            ],
                            [
                                -3.6072337,
                                40.585122
                            ],
                            [
                                -3.6102759,
                                40.583106
                            ],
                            [
                                -3.6139477,
                                40.581483
                            ],
                            [
                                -3.6162815,
                                40.580858
                            ],
                            [
                                -3.6234834,
                                40.5751439
                            ],
                            [
                                -3.6254996,
                                40.5734303
                            ],
                            [
                                -3.6306884,
                                40.5749246
                            ],
                            [
                                -3.6313392,
                                40.5750382
                            ],
                            [
                                -3.6349891,
                                40.5749462
                            ],
                            [
                                -3.6369757,
                                40.5751334
                            ],
                            [
                                -3.6395198,
                                40.5755428
                            ],
                            [
                                -3.6410238,
                                40.5759129
                            ],
                            [
                                -3.6431229,
                                40.5767391
                            ],
                            [
                                -3.6448172,
                                40.5772341
                            ],
                            [
                                -3.6488831,
                                40.5773916
                            ],
                            [
                                -3.6497583,
                                40.5774768
                            ],
                            [
                                -3.6506256,
                                40.5779764
                            ],
                            [
                                -3.6513436,
                                40.5789093
                            ],
                            [
                                -3.6552157,
                                40.5882656
                            ],
                            [
                                -3.6556588,
                                40.5888757
                            ],
                            [
                                -3.6575397,
                                40.5902794
                            ],
                            [
                                -3.6591834,
                                40.5915943
                            ],
                            [
                                -3.6623153,
                                40.5921793
                            ],
                            [
                                -3.6630328,
                                40.5922784
                            ],
                            [
                                -3.6651499,
                                40.5923292
                            ],
                            [
                                -3.6672709,
                                40.5920774
                            ],
                            [
                                -3.6740915,
                                40.5888135
                            ],
                            [
                                -3.6822443,
                                40.5848871
                            ],
                            [
                                -3.6840297,
                                40.5837156
                            ],
                            [
                                -3.6858924,
                                40.5828449
                            ],
                            [
                                -3.6869973,
                                40.582511
                            ],
                            [
                                -3.6887067,
                                40.5825144
                            ],
                            [
                                -3.6904163,
                                40.5810692
                            ],
                            [
                                -3.6924629,
                                40.580238
                            ],
                            [
                                -3.6948919,
                                40.5796237
                            ],
                            [
                                -3.6970006,
                                40.5793511
                            ],
                            [
                                -3.6987182,
                                40.5786349
                            ],
                            [
                                -3.7009167,
                                40.5784659
                            ],
                            [
                                -3.7000364,
                                40.5782644
                            ],
                            [
                                -3.6973517,
                                40.5760692
                            ],
                            [
                                -3.6971587,
                                40.5750473
                            ],
                            [
                                -3.6964571,
                                40.5744357
                            ],
                            [
                                -3.6948463,
                                40.573717
                            ],
                            [
                                -3.6934298,
                                40.5719764
                            ],
                            [
                                -3.6926225,
                                40.5715491
                            ],
                            [
                                -3.6924878,
                                40.5707817
                            ],
                            [
                                -3.6917935,
                                40.5697841
                            ],
                            [
                                -3.6912773,
                                40.5696578
                            ],
                            [
                                -3.690705,
                                40.569081
                            ],
                            [
                                -3.6903993,
                                40.5696688
                            ],
                            [
                                -3.689256,
                                40.5705457
                            ],
                            [
                                -3.6895849,
                                40.5697689
                            ],
                            [
                                -3.6895237,
                                40.5686762
                            ],
                            [
                                -3.6868511,
                                40.5628837
                            ],
                            [
                                -3.6857903,
                                40.5598877
                            ],
                            [
                                -3.6827672,
                                40.5544835
                            ],
                            [
                                -3.6820663,
                                40.5531171
                            ],
                            [
                                -3.6826111,
                                40.5525637
                            ],
                            [
                                -3.6830616,
                                40.5522126
                            ],
                            [
                                -3.6818348,
                                40.5504645
                            ],
                            [
                                -3.6817939,
                                40.5499748
                            ],
                            [
                                -3.6802489,
                                40.5487819
                            ],
                            [
                                -3.678561,
                                40.5467804
                            ],
                            [
                                -3.6787263,
                                40.5454196
                            ],
                            [
                                -3.6781392,
                                40.5436027
                            ],
                            [
                                -3.676928,
                                40.5418355
                            ],
                            [
                                -3.6767933,
                                40.5414772
                            ],
                            [
                                -3.6762291,
                                40.5411957
                            ],
                            [
                                -3.6757758,
                                40.5408745
                            ],
                            [
                                -3.6744035,
                                40.5384805
                            ],
                            [
                                -3.6740245,
                                40.5379541
                            ],
                            [
                                -3.6721698,
                                40.537277
                            ],
                            [
                                -3.6718847,
                                40.5370622
                            ],
                            [
                                -3.6703355,
                                40.5349506
                            ],
                            [
                                -3.6693614,
                                40.5342974
                            ],
                            [
                                -3.6709644,
                                40.5320963
                            ],
                            [
                                -3.6723495,
                                40.530866
                            ],
                            [
                                -3.6757345,
                                40.5283834
                            ],
                            [
                                -3.6765199,
                                40.5272356
                            ],
                            [
                                -3.6771913,
                                40.5269828
                            ],
                            [
                                -3.6730213,
                                40.5246795
                            ],
                            [
                                -3.6721287,
                                40.5243468
                            ],
                            [
                                -3.6702344,
                                40.5246667
                            ],
                            [
                                -3.6689342,
                                40.5250307
                            ],
                            [
                                -3.6670312,
                                40.5248586
                            ],
                            [
                                -3.6658697,
                                40.5247055
                            ],
                            [
                                -3.6655955,
                                40.5231029
                            ],
                            [
                                -3.6660546,
                                40.5214751
                            ],
                            [
                                -3.6655062,
                                40.5207541
                            ],
                            [
                                -3.6642797,
                                40.5203468
                            ],
                            [
                                -3.6639799,
                                40.5198693
                            ],
                            [
                                -3.6628641,
                                40.5200149
                            ],
                            [
                                -3.6625986,
                                40.519938
                            ],
                            [
                                -3.6603859,
                                40.5154518
                            ],
                            [
                                -3.6601716,
                                40.5148318
                            ],
                            [
                                -3.6591952,
                                40.5136459
                            ],
                            [
                                -3.6591053,
                                40.5135126
                            ],
                            [
                                -3.6585242,
                                40.5117415
                            ],
                            [
                                -3.6577949,
                                40.5113768
                            ],
                            [
                                -3.6575102,
                                40.5118695
                            ],
                            [
                                -3.6558629,
                                40.5131585
                            ],
                            [
                                -3.6536957,
                                40.5122662
                            ],
                            [
                                -3.6520488,
                                40.5111701
                            ],
                            [
                                -3.6486542,
                                40.510754
                            ],
                            [
                                -3.6477768,
                                40.5103395
                            ],
                            [
                                -3.6456566,
                                40.5082994
                            ],
                            [
                                -3.6448476,
                                40.5078909
                            ],
                            [
                                -3.6433893,
                                40.5079299
                            ],
                            [
                                -3.6423479,
                                40.5081939
                            ],
                            [
                                -3.6334464,
                                40.5076829
                            ],
                            [
                                -3.6331954,
                                40.5076277
                            ],
                            [
                                -3.6303906,
                                40.5084294
                            ],
                            [
                                -3.6285741,
                                40.5082554
                            ],
                            [
                                -3.6259208,
                                40.5093221
                            ],
                            [
                                -3.6253516,
                                40.5094319
                            ],
                            [
                                -3.6236017,
                                40.5090306
                            ],
                            [
                                -3.6227523,
                                40.5090233
                            ],
                            [
                                -3.6213055,
                                40.5099878
                            ],
                            [
                                -3.6209096,
                                40.5101261
                            ],
                            [
                                -3.6179285,
                                40.5101124
                            ],
                            [
                                -3.6177532,
                                40.5100857
                            ],
                            [
                                -3.6157603,
                                40.5109451
                            ],
                            [
                                -3.615099,
                                40.5109811
                            ],
                            [
                                -3.6142492,
                                40.5107387
                            ],
                            [
                                -3.6136156,
                                40.5098389
                            ],
                            [
                                -3.6129637,
                                40.5094438
                            ],
                            [
                                -3.6114235,
                                40.5091134
                            ],
                            [
                                -3.6112629,
                                40.5090487
                            ],
                            [
                                -3.6098885,
                                40.5078277
                            ],
                            [
                                -3.6093354,
                                40.5073821
                            ],
                            [
                                -3.6068694,
                                40.5060884
                            ],
                            [
                                -3.6063498,
                                40.5057011
                            ],
                            [
                                -3.6059654,
                                40.5044435
                            ],
                            [
                                -3.605764,
                                40.5040344
                            ],
                            [
                                -3.6024273,
                                40.5013041
                            ],
                            [
                                -3.6022895,
                                40.5012569
                            ],
                            [
                                -3.60067,
                                40.5016552
                            ],
                            [
                                -3.6002224,
                                40.5018062
                            ],
                            [
                                -3.5982096,
                                40.5012726
                            ],
                            [
                                -3.5975152,
                                40.5012368
                            ],
                            [
                                -3.5937369,
                                40.501477
                            ],
                            [
                                -3.5933344,
                                40.5014052
                            ],
                            [
                                -3.5932621,
                                40.5015777
                            ],
                            [
                                -3.589168,
                                40.5044851
                            ],
                            [
                                -3.5847069,
                                40.5067217
                            ],
                            [
                                -3.582984,
                                40.5084823
                            ],
                            [
                                -3.5779324,
                                40.510357
                            ],
                            [
                                -3.5759357,
                                40.5103674
                            ],
                            [
                                -3.5721062,
                                40.5123682
                            ],
                            [
                                -3.5649426,
                                40.5120506
                            ],
                            [
                                -3.5616936,
                                40.5111491
                            ],
                            [
                                -3.5616263,
                                40.5106865
                            ],
                            [
                                -3.5585337,
                                40.5112384
                            ],
                            [
                                -3.5548807,
                                40.5113314
                            ],
                            [
                                -3.5542579,
                                40.5113309
                            ],
                            [
                                -3.5551284,
                                40.5085721
                            ],
                            [
                                -3.5549382,
                                40.505456
                            ],
                            [
                                -3.5552156,
                                40.5031569
                            ],
                            [
                                -3.5534056,
                                40.5024597
                            ],
                            [
                                -3.5532919,
                                40.499951
                            ],
                            [
                                -3.5531706,
                                40.4997283
                            ],
                            [
                                -3.5511108,
                                40.4981808
                            ],
                            [
                                -3.5506239,
                                40.4979345
                            ],
                            [
                                -3.5444292,
                                40.4957732
                            ],
                            [
                                -3.5442481,
                                40.4957115
                            ],
                            [
                                -3.5420128,
                                40.4943044
                            ],
                            [
                                -3.5418534,
                                40.4941758
                            ],
                            [
                                -3.5410157,
                                40.4886339
                            ],
                            [
                                -3.5409536,
                                40.48853
                            ],
                            [
                                -3.5370202,
                                40.4865334
                            ],
                            [
                                -3.5369427,
                                40.4864279
                            ],
                            [
                                -3.5372729,
                                40.4850629
                            ],
                            [
                                -3.5372827,
                                40.4848106
                            ],
                            [
                                -3.5352143,
                                40.4813787
                            ],
                            [
                                -3.5347922,
                                40.4802275
                            ],
                            [
                                -3.5342606,
                                40.4726894
                            ],
                            [
                                -3.5340094,
                                40.4722491
                            ],
                            [
                                -3.5334153,
                                40.4717113
                            ],
                            [
                                -3.5288742,
                                40.4707644
                            ],
                            [
                                -3.5278125,
                                40.470625
                            ],
                            [
                                -3.5256849,
                                40.4696659
                            ],
                            [
                                -3.5253123,
                                40.4690334
                            ],
                            [
                                -3.5250873,
                                40.4677375
                            ],
                            [
                                -3.5284622,
                                40.4640961
                            ],
                            [
                                -3.528924,
                                40.4634776
                            ],
                            [
                                -3.5295431,
                                40.4615641
                            ],
                            [
                                -3.5296562,
                                40.4610418
                            ],
                            [
                                -3.5294176,
                                40.459233
                            ],
                            [
                                -3.5293124,
                                40.458706
                            ],
                            [
                                -3.52694,
                                40.456359
                            ],
                            [
                                -3.5268126,
                                40.4559246
                            ],
                            [
                                -3.5282113,
                                40.4549237
                            ],
                            [
                                -3.528789,
                                40.4546745
                            ],
                            [
                                -3.5294447,
                                40.454536
                            ],
                            [
                                -3.5302172,
                                40.4543699
                            ],
                            [
                                -3.5319102,
                                40.4547303
                            ],
                            [
                                -3.5321918,
                                40.4546475
                            ],
                            [
                                -3.5336484,
                                40.4535323
                            ],
                            [
                                -3.5345645,
                                40.4525471
                            ],
                            [
                                -3.5330509,
                                40.4481556
                            ],
                            [
                                -3.5320116,
                                40.4469332
                            ],
                            [
                                -3.5338561,
                                40.4465484
                            ],
                            [
                                -3.5354916,
                                40.4464811
                            ],
                            [
                                -3.5351213,
                                40.4448993
                            ],
                            [
                                -3.535578,
                                40.4447055
                            ],
                            [
                                -3.5369665,
                                40.4448551
                            ],
                            [
                                -3.538398,
                                40.4450116
                            ],
                            [
                                -3.5379349,
                                40.4442495
                            ],
                            [
                                -3.5409726,
                                40.4445887
                            ],
                            [
                                -3.541326,
                                40.4437878
                            ],
                            [
                                -3.5416121,
                                40.4436539
                            ],
                            [
                                -3.5452276,
                                40.4427028
                            ],
                            [
                                -3.5515566,
                                40.4417414
                            ],
                            [
                                -3.5519568,
                                40.4416335
                            ],
                            [
                                -3.5597852,
                                40.4382299
                            ],
                            [
                                -3.5599614,
                                40.4381715
                            ],
                            [
                                -3.5647984,
                                40.4378302
                            ],
                            [
                                -3.5652918,
                                40.4377534
                            ],
                            [
                                -3.5679161,
                                40.436511
                            ],
                            [
                                -3.568547,
                                40.4362064
                            ],
                            [
                                -3.5737955,
                                40.4376686
                            ],
                            [
                                -3.5759428,
                                40.4367377
                            ],
                            [
                                -3.5751024,
                                40.4340948
                            ],
                            [
                                -3.5791192,
                                40.4336261
                            ],
                            [
                                -3.5769724,
                                40.4298296
                            ],
                            [
                                -3.5763173,
                                40.4296773
                            ],
                            [
                                -3.5774817,
                                40.4260142
                            ],
                            [
                                -3.5752487,
                                40.4255791
                            ],
                            [
                                -3.5787862,
                                40.4182983
                            ],
                            [
                                -3.5795289,
                                40.4173377
                            ],
                            [
                                -3.5781848,
                                40.4148425
                            ],
                            [
                                -3.5775371,
                                40.4136194
                            ],
                            [
                                -3.5762686,
                                40.413113
                            ],
                            [
                                -3.5728835,
                                40.411762
                            ],
                            [
                                -3.564754,
                                40.4135565
                            ],
                            [
                                -3.5640324,
                                40.4135356
                            ],
                            [
                                -3.5594,
                                40.4132371
                            ],
                            [
                                -3.5588291,
                                40.4131545
                            ],
                            [
                                -3.5541995,
                                40.4118975
                            ],
                            [
                                -3.5533963,
                                40.4116717
                            ],
                            [
                                -3.5495724,
                                40.4121651
                            ],
                            [
                                -3.5492626,
                                40.4123127
                            ],
                            [
                                -3.5399128,
                                40.4105481
                            ],
                            [
                                -3.5397601,
                                40.4104261
                            ],
                            [
                                -3.5397367,
                                40.4114686
                            ],
                            [
                                -3.5396554,
                                40.4115945
                            ],
                            [
                                -3.5383758,
                                40.4114115
                            ],
                            [
                                -3.53828,
                                40.4114284
                            ],
                            [
                                -3.5378487,
                                40.4122837
                            ],
                            [
                                -3.5377866,
                                40.4123539
                            ],
                            [
                                -3.5364741,
                                40.4125722
                            ],
                            [
                                -3.5362058,
                                40.4125154
                            ],
                            [
                                -3.5356266,
                                40.4131503
                            ],
                            [
                                -3.5351717,
                                40.4144134
                            ],
                            [
                                -3.5333218,
                                40.41411
                            ],
                            [
                                -3.5327888,
                                40.4141076
                            ],
                            [
                                -3.5304148,
                                40.4147078
                            ],
                            [
                                -3.5302304,
                                40.4147088
                            ],
                            [
                                -3.5308782,
                                40.4183948
                            ],
                            [
                                -3.5311901,
                                40.4200833
                            ],
                            [
                                -3.5260472,
                                40.4168469
                            ],
                            [
                                -3.5250587,
                                40.4162839
                            ],
                            [
                                -3.5245106,
                                40.415359
                            ],
                            [
                                -3.5225796,
                                40.4141214
                            ],
                            [
                                -3.5222392,
                                40.4138077
                            ],
                            [
                                -3.5189842,
                                40.4088043
                            ],
                            [
                                -3.5188408,
                                40.4087466
                            ],
                            [
                                -3.5190935,
                                40.4059671
                            ],
                            [
                                -3.5189383,
                                40.4049014
                            ],
                            [
                                -3.5179819,
                                40.4029951
                            ],
                            [
                                -3.5179164,
                                40.40265
                            ],
                            [
                                -3.5182961,
                                40.4005096
                            ],
                            [
                                -3.5184412,
                                40.399978
                            ],
                            [
                                -3.5193812,
                                40.39813
                            ],
                            [
                                -3.5194579,
                                40.397867
                            ],
                            [
                                -3.5192602,
                                40.3955513
                            ],
                            [
                                -3.5192621,
                                40.3953879
                            ],
                            [
                                -3.5196478,
                                40.3951744
                            ],
                            [
                                -3.5200356,
                                40.3952601
                            ],
                            [
                                -3.5203218,
                                40.393698
                            ],
                            [
                                -3.5203949,
                                40.3920974
                            ],
                            [
                                -3.5214636,
                                40.3919753
                            ],
                            [
                                -3.5225669,
                                40.3918683
                            ],
                            [
                                -3.5268869,
                                40.389794
                            ],
                            [
                                -3.5291493,
                                40.3893862
                            ],
                            [
                                -3.5320835,
                                40.3914167
                            ],
                            [
                                -3.5328645,
                                40.3916886
                            ],
                            [
                                -3.5373523,
                                40.3913137
                            ],
                            [
                                -3.54247,
                                40.3931209
                            ],
                            [
                                -3.5426773,
                                40.3931559
                            ],
                            [
                                -3.5426796,
                                40.3922803
                            ],
                            [
                                -3.5428715,
                                40.3914889
                            ],
                            [
                                -3.5439389,
                                40.3885395
                            ],
                            [
                                -3.5440161,
                                40.3883688
                            ],
                            [
                                -3.545711,
                                40.3852624
                            ],
                            [
                                -3.5462504,
                                40.3843594
                            ],
                            [
                                -3.5490862,
                                40.3809737
                            ],
                            [
                                -3.5491366,
                                40.380851
                            ],
                            [
                                -3.5491876,
                                40.3791004
                            ],
                            [
                                -3.5491752,
                                40.378982
                            ],
                            [
                                -3.5486662,
                                40.3770678
                            ],
                            [
                                -3.548686,
                                40.3769663
                            ],
                            [
                                -3.5500537,
                                40.3759989
                            ],
                            [
                                -3.5501376,
                                40.3759272
                            ],
                            [
                                -3.5507144,
                                40.3750069
                            ],
                            [
                                -3.5509196,
                                40.3744809
                            ],
                            [
                                -3.5511929,
                                40.3736938
                            ],
                            [
                                -3.551234,
                                40.37351
                            ],
                            [
                                -3.5511235,
                                40.3709582
                            ],
                            [
                                -3.5511883,
                                40.3705102
                            ],
                            [
                                -3.5522962,
                                40.3668863
                            ],
                            [
                                -3.5523549,
                                40.3667256
                            ],
                            [
                                -3.5552436,
                                40.3643403
                            ],
                            [
                                -3.555563,
                                40.3640721
                            ],
                            [
                                -3.5560666,
                                40.3619707
                            ],
                            [
                                -3.5560796,
                                40.3617973
                            ],
                            [
                                -3.555644,
                                40.359626
                            ],
                            [
                                -3.5555367,
                                40.3594451
                            ],
                            [
                                -3.5529055,
                                40.3614918
                            ],
                            [
                                -3.5528018,
                                40.3612909
                            ],
                            [
                                -3.5530794,
                                40.3569398
                            ],
                            [
                                -3.5532912,
                                40.3560167
                            ],
                            [
                                -3.5535035,
                                40.3557642
                            ],
                            [
                                -3.5547392,
                                40.3542815
                            ],
                            [
                                -3.5567465,
                                40.3530016
                            ],
                            [
                                -3.5576096,
                                40.3526636
                            ],
                            [
                                -3.558761,
                                40.3503317
                            ],
                            [
                                -3.5589293,
                                40.3501325
                            ],
                            [
                                -3.560534,
                                40.3493006
                            ],
                            [
                                -3.560736,
                                40.3491871
                            ],
                            [
                                -3.5620641,
                                40.3472279
                            ],
                            [
                                -3.5620756,
                                40.3470066
                            ],
                            [
                                -3.5653316,
                                40.3444096
                            ],
                            [
                                -3.5688608,
                                40.3405045
                            ],
                            [
                                -3.5690894,
                                40.3402521
                            ],
                            [
                                -3.5698078,
                                40.3393383
                            ],
                            [
                                -3.5699571,
                                40.3382147
                            ],
                            [
                                -3.5699944,
                                40.3376813
                            ],
                            [
                                -3.5710067,
                                40.3361914
                            ],
                            [
                                -3.571557,
                                40.3352914
                            ],
                            [
                                -3.5715478,
                                40.3345984
                            ],
                            [
                                -3.5716784,
                                40.3344597
                            ],
                            [
                                -3.5730479,
                                40.3334832
                            ],
                            [
                                -3.5751024,
                                40.3320629
                            ],
                            [
                                -3.5781836,
                                40.3290082
                            ],
                            [
                                -3.578762,
                                40.3284214
                            ],
                            [
                                -3.5821651,
                                40.3264406
                            ],
                            [
                                -3.582501,
                                40.3262947
                            ],
                            [
                                -3.5832879,
                                40.3247445
                            ],
                            [
                                -3.583893,
                                40.3237227
                            ],
                            [
                                -3.5844979,
                                40.3221759
                            ],
                            [
                                -3.585063,
                                40.3204097
                            ],
                            [
                                -3.5850735,
                                40.3183478
                            ],
                            [
                                -3.5838092,
                                40.3158014
                            ],
                            [
                                -3.5823858,
                                40.3161497
                            ],
                            [
                                -3.5804456,
                                40.315956
                            ],
                            [
                                -3.5792623,
                                40.3158624
                            ],
                            [
                                -3.5777969,
                                40.3146492
                            ],
                            [
                                -3.5761354,
                                40.3148605
                            ],
                            [
                                -3.5762156,
                                40.3145914
                            ],
                            [
                                -3.5766286,
                                40.3142286
                            ],
                            [
                                -3.5780236,
                                40.3136473
                            ],
                            [
                                -3.5789642,
                                40.3134841
                            ],
                            [
                                -3.597161,
                                40.3119847
                            ],
                            [
                                -3.5986952,
                                40.3119774
                            ],
                            [
                                -3.6110695,
                                40.3141301
                            ],
                            [
                                -3.6117377,
                                40.3143009
                            ],
                            [
                                -3.6133616,
                                40.3150761
                            ],
                            [
                                -3.6139999,
                                40.3154989
                            ],
                            [
                                -3.6152706,
                                40.3169566
                            ],
                            [
                                -3.6154788,
                                40.3171617
                            ],
                            [
                                -3.6180183,
                                40.3181704
                            ],
                            [
                                -3.6188643,
                                40.3185661
                            ],
                            [
                                -3.6255774,
                                40.3201226
                            ],
                            [
                                -3.6259982,
                                40.3192304
                            ],
                            [
                                -3.6303086,
                                40.3207688
                            ],
                            [
                                -3.6310204,
                                40.3210784
                            ],
                            [
                                -3.6366943,
                                40.3255141
                            ],
                            [
                                -3.6379701,
                                40.326395
                            ],
                            [
                                -3.6426764,
                                40.3290915
                            ],
                            [
                                -3.6430173,
                                40.3292718
                            ],
                            [
                                -3.6441882,
                                40.3306103
                            ],
                            [
                                -3.6446014,
                                40.3309204
                            ],
                            [
                                -3.6483969,
                                40.3330275
                            ],
                            [
                                -3.6491347,
                                40.3334125
                            ],
                            [
                                -3.6497998,
                                40.3324977
                            ],
                            [
                                -3.6499375,
                                40.3323024
                            ],
                            [
                                -3.6521412,
                                40.3313233
                            ],
                            [
                                -3.6523218,
                                40.3309909
                            ],
                            [
                                -3.6527113,
                                40.3312878
                            ],
                            [
                                -3.6529522,
                                40.3314058
                            ],
                            [
                                -3.6584477,
                                40.3281702
                            ],
                            [
                                -3.6585003,
                                40.3280482
                            ],
                            [
                                -3.6600409,
                                40.3281208
                            ],
                            [
                                -3.660653,
                                40.3281173
                            ],
                            [
                                -3.6628078,
                                40.3293843
                            ],
                            [
                                -3.6635389,
                                40.3295062
                            ],
                            [
                                -3.6669147,
                                40.3268382
                            ],
                            [
                                -3.6676036,
                                40.3262756
                            ],
                            [
                                -3.6693251,
                                40.3253738
                            ],
                            [
                                -3.6704522,
                                40.325079
                            ],
                            [
                                -3.6712799,
                                40.3254526
                            ],
                            [
                                -3.6741729,
                                40.3263727
                            ],
                            [
                                -3.6776977,
                                40.3268927
                            ],
                            [
                                -3.6786042,
                                40.3269054
                            ],
                            [
                                -3.6794496,
                                40.3266932
                            ],
                            [
                                -3.6799413,
                                40.3264201
                            ],
                            [
                                -3.6808916,
                                40.324937
                            ],
                            [
                                -3.6812059,
                                40.3245838
                            ],
                            [
                                -3.6834103,
                                40.3237329
                            ],
                            [
                                -3.6847999,
                                40.3226346
                            ],
                            [
                                -3.6876933,
                                40.3224283
                            ],
                            [
                                -3.6889851,
                                40.3221323
                            ],
                            [
                                -3.6924875,
                                40.3204501
                            ],
                            [
                                -3.6930191,
                                40.3201949
                            ],
                            [
                                -3.6961688,
                                40.3222778
                            ],
                            [
                                -3.6996785,
                                40.3223949
                            ],
                            [
                                -3.7033035,
                                40.3222046
                            ],
                            [
                                -3.7099054,
                                40.3236727
                            ],
                            [
                                -3.7126029,
                                40.3238633
                            ],
                            [
                                -3.713598,
                                40.3267041
                            ],
                            [
                                -3.7148181,
                                40.3285525
                            ],
                            [
                                -3.7161781,
                                40.3291477
                            ],
                            [
                                -3.7201192,
                                40.3311323
                            ],
                            [
                                -3.7251721,
                                40.3346953
                            ],
                            [
                                -3.7208913,
                                40.3655479
                            ],
                            [
                                -3.72346,
                                40.365372
                            ],
                            [
                                -3.7271148,
                                40.3645421
                            ],
                            [
                                -3.730557,
                                40.3633447
                            ],
                            [
                                -3.7544157,
                                40.3579871
                            ],
                            [
                                -3.7577072,
                                40.3572607
                            ],
                            [
                                -3.7716237,
                                40.3598245
                            ],
                            [
                                -3.7718553,
                                40.3594954
                            ],
                            [
                                -3.7736057,
                                40.3606075
                            ],
                            [
                                -3.7766987,
                                40.3612534
                            ],
                            [
                                -3.7804983,
                                40.3618946
                            ],
                            [
                                -3.7841669,
                                40.3603652
                            ],
                            [
                                -3.7879224,
                                40.3587412
                            ],
                            [
                                -3.7953966,
                                40.3619014
                            ],
                            [
                                -3.8028103,
                                40.3633817
                            ],
                            [
                                -3.8069304,
                                40.3664529
                            ],
                            [
                                -3.8105153,
                                40.3637165
                            ],
                            [
                                -3.8132116,
                                40.3696034
                            ],
                            [
                                -3.8132895,
                                40.3698328
                            ],
                            [
                                -3.8223916,
                                40.3801692
                            ],
                            [
                                -3.8227776,
                                40.3805346
                            ],
                            [
                                -3.8309138,
                                40.3910345
                            ],
                            [
                                -3.8319266,
                                40.3924087
                            ],
                            [
                                -3.8334564,
                                40.3954732
                            ],
                            [
                                -3.834162,
                                40.3960582
                            ],
                            [
                                -3.8214855,
                                40.3966207
                            ],
                            [
                                -3.8206626,
                                40.3966177
                            ],
                            [
                                -3.8182651,
                                40.3959554
                            ],
                            [
                                -3.8148521,
                                40.3940331
                            ],
                            [
                                -3.8084141,
                                40.3924144
                            ],
                            [
                                -3.8042572,
                                40.3920568
                            ],
                            [
                                -3.7964659,
                                40.3922649
                            ],
                            [
                                -3.7937887,
                                40.3936341
                            ],
                            [
                                -3.7885871,
                                40.3939729
                            ],
                            [
                                -3.7893944,
                                40.3924188
                            ],
                            [
                                -3.7867592,
                                40.3928269
                            ],
                            [
                                -3.7814847,
                                40.3941828
                            ],
                            [
                                -3.7801091,
                                40.3958373
                            ],
                            [
                                -3.7796751,
                                40.3968453
                            ],
                            [
                                -3.7781135,
                                40.3976555
                            ],
                            [
                                -3.7781087,
                                40.3980446
                            ],
                            [
                                -3.7776503,
                                40.3991361
                            ],
                            [
                                -3.775853,
                                40.4000885
                            ],
                            [
                                -3.7745596,
                                40.4003063
                            ],
                            [
                                -3.7744545,
                                40.4011967
                            ],
                            [
                                -3.7743427,
                                40.4021083
                            ],
                            [
                                -3.7758184,
                                40.4034942
                            ],
                            [
                                -3.7763504,
                                40.4040385
                            ],
                            [
                                -3.7776649,
                                40.4063691
                            ],
                            [
                                -3.778371,
                                40.407628
                            ],
                            [
                                -3.7793943,
                                40.4119524
                            ],
                            [
                                -3.7794676,
                                40.4122324
                            ],
                            [
                                -3.7805658,
                                40.414253
                            ],
                            [
                                -3.7813952,
                                40.4175676
                            ],
                            [
                                -3.780387,
                                40.4217859
                            ],
                            [
                                -3.7803556,
                                40.4219008
                            ],
                            [
                                -3.7795547,
                                40.4234444
                            ],
                            [
                                -3.7791186,
                                40.424274
                            ],
                            [
                                -3.7715641,
                                40.4289459
                            ],
                            [
                                -3.7707828,
                                40.4294273
                            ],
                            [
                                -3.7708271,
                                40.4437256
                            ],
                            [
                                -3.7708286,
                                40.4440573
                            ],
                            [
                                -3.7761955,
                                40.4434524
                            ],
                            [
                                -3.7776382,
                                40.4435711
                            ],
                            [
                                -3.782734,
                                40.4452715
                            ],
                            [
                                -3.7828176,
                                40.4453034
                            ],
                            [
                                -3.7830608,
                                40.444517
                            ],
                            [
                                -3.7846829,
                                40.4445033
                            ],
                            [
                                -3.7846582,
                                40.4440128
                            ],
                            [
                                -3.7873958,
                                40.444295
                            ],
                            [
                                -3.7890881,
                                40.4435576
                            ],
                            [
                                -3.7894321,
                                40.4420749
                            ],
                            [
                                -3.7902014,
                                40.4423019
                            ],
                            [
                                -3.7900835,
                                40.4425423
                            ],
                            [
                                -3.7899114,
                                40.4428921
                            ],
                            [
                                -3.7902164,
                                40.4429844
                            ],
                            [
                                -3.7898554,
                                40.4437092
                            ],
                            [
                                -3.7893357,
                                40.4435458
                            ],
                            [
                                -3.7886666,
                                40.44383
                            ],
                            [
                                -3.789379,
                                40.4448419
                            ],
                            [
                                -3.7882239,
                                40.4452799
                            ],
                            [
                                -3.7899582,
                                40.4466098
                            ],
                            [
                                -3.7901666,
                                40.44662
                            ],
                            [
                                -3.7903328,
                                40.4478876
                            ],
                            [
                                -3.7910069,
                                40.4477226
                            ],
                            [
                                -3.7923736,
                                40.4516145
                            ],
                            [
                                -3.7923777,
                                40.4518931
                            ],
                            [
                                -3.7932117,
                                40.4530973
                            ],
                            [
                                -3.7934395,
                                40.4532335
                            ],
                            [
                                -3.793402,
                                40.4532634
                            ],
                            [
                                -3.7935046,
                                40.4535678
                            ],
                            [
                                -3.7929693,
                                40.4536852
                            ],
                            [
                                -3.7930489,
                                40.4539115
                            ],
                            [
                                -3.7952912,
                                40.4562601
                            ],
                            [
                                -3.7955966,
                                40.456399
                            ],
                            [
                                -3.7953556,
                                40.4571772
                            ],
                            [
                                -3.7958167,
                                40.4579451
                            ],
                            [
                                -3.7988737,
                                40.4590809
                            ],
                            [
                                -3.8022259,
                                40.46179
                            ],
                            [
                                -3.8025433,
                                40.4620765
                            ],
                            [
                                -3.8051943,
                                40.4631527
                            ],
                            [
                                -3.8049834,
                                40.463739
                            ],
                            [
                                -3.8105345,
                                40.4646618
                            ],
                            [
                                -3.810802,
                                40.4637376
                            ],
                            [
                                -3.8118642,
                                40.4638953
                            ],
                            [
                                -3.8116311,
                                40.4646566
                            ],
                            [
                                -3.8117885,
                                40.4646791
                            ],
                            [
                                -3.8119142,
                                40.4643704
                            ],
                            [
                                -3.8120618,
                                40.4640653
                            ],
                            [
                                -3.8129213,
                                40.4636383
                            ],
                            [
                                -3.8152421,
                                40.4635655
                            ],
                            [
                                -3.8175152,
                                40.4642612
                            ],
                            [
                                -3.8249519,
                                40.464731
                            ],
                            [
                                -3.8271617,
                                40.4660305
                            ],
                            [
                                -3.8290407,
                                40.4663143
                            ],
                            [
                                -3.8309247,
                                40.4659405
                            ],
                            [
                                -3.8343462,
                                40.4644591
                            ],
                            [
                                -3.8369621,
                                40.4668429
                            ],
                            [
                                -3.8383521,
                                40.4676436
                            ],
                            [
                                -3.8381172,
                                40.4709209
                            ],
                            [
                                -3.837554,
                                40.4727124
                            ],
                            [
                                -3.8361762,
                                40.4748125
                            ],
                            [
                                -3.8367451,
                                40.4750246
                            ],
                            [
                                -3.837483,
                                40.4755327
                            ],
                            [
                                -3.8365301,
                                40.4805036
                            ],
                            [
                                -3.8368044,
                                40.4808197
                            ],
                            [
                                -3.8360592,
                                40.4820841
                            ],
                            [
                                -3.8351981,
                                40.4825042
                            ],
                            [
                                -3.833398,
                                40.4857784
                            ],
                            [
                                -3.8332469,
                                40.4878695
                            ],
                            [
                                -3.8342502,
                                40.4917091
                            ],
                            [
                                -3.8344678,
                                40.4940318
                            ],
                            [
                                -3.8369541,
                                40.4965994
                            ],
                            [
                                -3.8395369,
                                40.4993104
                            ],
                            [
                                -3.8393114,
                                40.5001589
                            ],
                            [
                                -3.8365908,
                                40.5063047
                            ],
                            [
                                -3.8529505,
                                40.5104498
                            ],
                            [
                                -3.8536027,
                                40.5113331
                            ],
                            [
                                -3.8540093,
                                40.5119932
                            ],
                            [
                                -3.8534777,
                                40.5138216
                            ],
                            [
                                -3.8533651,
                                40.5145764
                            ],
                            [
                                -3.8522154,
                                40.5154787
                            ],
                            [
                                -3.8514088,
                                40.5173232
                            ],
                            [
                                -3.8512915,
                                40.5176518
                            ],
                            [
                                -3.853756,
                                40.5202841
                            ],
                            [
                                -3.8543787,
                                40.5213591
                            ],
                            [
                                -3.853745,
                                40.5242734
                            ],
                            [
                                -3.8536926,
                                40.524414
                            ],
                            [
                                -3.8550485,
                                40.5256329
                            ],
                            [
                                -3.8561266,
                                40.5269228
                            ],
                            [
                                -3.857282,
                                40.5276146
                            ],
                            [
                                -3.8576963,
                                40.5278565
                            ],
                            [
                                -3.8582149,
                                40.5299421
                            ],
                            [
                                -3.8581106,
                                40.5301132
                            ],
                            [
                                -3.8590661,
                                40.5308353
                            ],
                            [
                                -3.8595424,
                                40.5312892
                            ],
                            [
                                -3.8604169,
                                40.5326347
                            ],
                            [
                                -3.8606108,
                                40.5329161
                            ],
                            [
                                -3.8625974,
                                40.534393
                            ],
                            [
                                -3.8629447,
                                40.5346298
                            ],
                            [
                                -3.8638685,
                                40.5375238
                            ],
                            [
                                -3.8638785,
                                40.5392382
                            ],
                            [
                                -3.865492,
                                40.5423881
                            ],
                            [
                                -3.8656578,
                                40.5433947
                            ],
                            [
                                -3.8689465,
                                40.5469094
                            ],
                            [
                                -3.8694408,
                                40.5474254
                            ],
                            [
                                -3.8692729,
                                40.5477806
                            ],
                            [
                                -3.8690623,
                                40.5482039
                            ],
                            [
                                -3.8703819,
                                40.5504933
                            ],
                            [
                                -3.870776,
                                40.5511414
                            ],
                            [
                                -3.8724752,
                                40.5528339
                            ],
                            [
                                -3.8727934,
                                40.5534058
                            ],
                            [
                                -3.873071,
                                40.5546858
                            ],
                            [
                                -3.8729515,
                                40.5552333
                            ],
                            [
                                -3.8736023,
                                40.5568761
                            ],
                            [
                                -3.8738241,
                                40.5575738
                            ],
                            [
                                -3.8751398,
                                40.5581958
                            ],
                            [
                                -3.8754271,
                                40.5588059
                            ],
                            [
                                -3.8801807,
                                40.5595983
                            ],
                            [
                                -3.8820896,
                                40.5596411
                            ],
                            [
                                -3.8849156,
                                40.5609526
                            ],
                            [
                                -3.8849855,
                                40.5610271
                            ],
                            [
                                -3.8849261,
                                40.5617524
                            ],
                            [
                                -3.8838567,
                                40.5634427
                            ],
                            [
                                -3.8838139,
                                40.5638375
                            ],
                            [
                                -3.8868022,
                                40.5661378
                            ],
                            [
                                -3.8870605,
                                40.5664574
                            ],
                            [
                                -3.8883762,
                                40.569622
                            ],
                            [
                                -3.8889539,
                                40.5708516
                            ],
                            [
                                -3.8886507,
                                40.5717252
                            ],
                            [
                                -3.8883959,
                                40.5722256
                            ],
                            [
                                -3.8854952,
                                40.5746025
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "es--01--001",
            "properties": {
                "land": "es",
                "city": "Madrid",
                "zone": "ZBEDEP Zona Cero",
                "area_code": 1,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. PHEV",
                "minEUd": 9,
                "petrol": "min. PHEV",
                "minEUb": 9,
                "minOUTd": 4,
                "minOUTb": 3,
                "warn": "req. DGT-ECO/O Sticker",
                "img_sign": "sign-es--white",
                "color": 4,
                "urlZone": "https://www.race.es/distrito-centro-zona-cero-emisiones-calles-area",
                "urlPark": "https://www.esmadrid.com/aparcar-madrid?utm_referrer=https%3A%2F%2Fwww.esmadrid.com%2Fde%2Fautofahren-madrid%3Futm_referrer%3Dhttps%253A%252F%252Fwww.google.com%252F",
                "urlReg": null,
                "urlBadge": "https://www.dgt.es/nuestros-servicios/tu-vehiculo/tus-vehiculos/distintivo-ambiental/"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -3.7138830749967724,
                            40.43020211383467
                        ],
                        [
                            -3.7147860048206383,
                            40.42888436735723
                        ],
                        [
                            -3.713376827555493,
                            40.42702679592915
                        ],
                        [
                            -3.7127977107533923,
                            40.42605907378493
                        ],
                        [
                            -3.712062912689788,
                            40.425082556490054
                        ],
                        [
                            -3.711346798396079,
                            40.424186611638646
                        ],
                        [
                            -3.710792586297572,
                            40.423584568902754
                        ],
                        [
                            -3.710587091923543,
                            40.42339020749938
                        ],
                        [
                            -3.7108112676045266,
                            40.42319110499102
                        ],
                        [
                            -3.7112284834535956,
                            40.42301096411853
                        ],
                        [
                            -3.7121376403794386,
                            40.422461057418076
                        ],
                        [
                            -3.7127229911108373,
                            40.422029661112106
                        ],
                        [
                            -3.7126171303723368,
                            40.4216930759105
                        ],
                        [
                            -3.712554858272398,
                            40.42122108556748
                        ],
                        [
                            -3.7127105358286485,
                            40.42050523881284
                        ],
                        [
                            -3.712785261055302,
                            40.41978938444166
                        ],
                        [
                            -3.712922258156823,
                            40.418419284662264
                        ],
                        [
                            -3.7131090697311606,
                            40.41692731955348
                        ],
                        [
                            -3.713199291799782,
                            40.415750630936
                        ],
                        [
                            -3.713280244128441,
                            40.41516747713814
                        ],
                        [
                            -3.7133300609461912,
                            40.41491145680112
                        ],
                        [
                            -3.7138095478179594,
                            40.41496360917111
                        ],
                        [
                            -3.714768521562547,
                            40.414987314780205
                        ],
                        [
                            -3.714980243038582,
                            40.41492093905282
                        ],
                        [
                            -3.715061195367184,
                            40.4142808840387
                        ],
                        [
                            -3.7166304251299778,
                            40.414271401696055
                        ],
                        [
                            -3.7167611958304576,
                            40.413697715045714
                        ],
                        [
                            -3.717745077982954,
                            40.41173008334306
                        ],
                        [
                            -3.7179941627400126,
                            40.411227497842276
                        ],
                        [
                            -3.7179132104113535,
                            40.41093827267838
                        ],
                        [
                            -3.717334005453722,
                            40.410018562197024
                        ],
                        [
                            -3.7165369363674188,
                            40.40883318397826
                        ],
                        [
                            -3.7161259493928185,
                            40.40861981252172
                        ],
                        [
                            -3.7146189906527525,
                            40.40799866402892
                        ],
                        [
                            -3.7135105702695625,
                            40.407567172140375
                        ],
                        [
                            -3.7126948198763614,
                            40.40721628958312
                        ],
                        [
                            -3.71209701806211,
                            40.40696972236694
                        ],
                        [
                            -3.7117109377238933,
                            40.40711197279413
                        ],
                        [
                            -3.7113746742034266,
                            40.40706455601824
                        ],
                        [
                            -3.7111691798293975,
                            40.406931788869
                        ],
                        [
                            -3.7110944546027724,
                            40.40670418743173
                        ],
                        [
                            -3.709973587733913,
                            40.40650029269011
                        ],
                        [
                            -3.7083171785405398,
                            40.40614940457013
                        ],
                        [
                            -3.706741727761994,
                            40.40587439143982
                        ],
                        [
                            -3.703908396866666,
                            40.40534805366235
                        ],
                        [
                            -3.7032109614158912,
                            40.405234250362554
                        ],
                        [
                            -3.7028996063043564,
                            40.405485565726764
                        ],
                        [
                            -3.702700339033271,
                            40.40556143433142
                        ],
                        [
                            -3.7022893502861507,
                            40.4055566925463
                        ],
                        [
                            -3.7020963101160476,
                            40.40548082393559
                        ],
                        [
                            -3.7018596802321326,
                            40.40536227906148
                        ],
                        [
                            -3.7001845954753776,
                            40.40582223841636
                        ],
                        [
                            -3.6958683431727195,
                            40.40701106416711
                        ],
                        [
                            -3.695556988061128,
                            40.40713908948635
                        ],
                        [
                            -3.6937093889421817,
                            40.407679363294136
                        ],
                        [
                            -3.6933295357059137,
                            40.40791644467245
                        ],
                        [
                            -3.6929310011627763,
                            40.40838112175109
                        ],
                        [
                            -3.6925760563354117,
                            40.408741481358845
                        ],
                        [
                            -3.6924141516781503,
                            40.40904968212405
                        ],
                        [
                            -3.692495104006781,
                            40.409414779665696
                        ],
                        [
                            -3.692669467715035,
                            40.410023395304194
                        ],
                        [
                            -3.69341672668736,
                            40.41230337986363
                        ],
                        [
                            -3.6936595836752133,
                            40.412896041021156
                        ],
                        [
                            -3.6942432000588497,
                            40.41431389574868
                        ],
                        [
                            -3.694430013125981,
                            40.414754822972185
                        ],
                        [
                            -3.6947164598286406,
                            40.41481645773075
                        ],
                        [
                            -3.6948285476691467,
                            40.41493498595264
                        ],
                        [
                            -3.6949966794293516,
                            40.415328498153514
                        ],
                        [
                            -3.6949157298795114,
                            40.41557029447014
                        ],
                        [
                            -3.6948285504489604,
                            40.41563666955622
                        ],
                        [
                            -3.694523422439346,
                            40.41677451800081
                        ],
                        [
                            -3.693908713567737,
                            40.41895400287552
                        ],
                        [
                            -3.694020801408243,
                            40.41902985629767
                        ],
                        [
                            -3.6940892995329193,
                            40.41939489969249
                        ],
                        [
                            -3.69380907993218,
                            40.4194612710055
                        ],
                        [
                            -3.6939858336754128,
                            40.41958383643015
                        ],
                        [
                            -3.6936308888480482,
                            40.41970235625445
                        ],
                        [
                            -3.6934689841898205,
                            40.41980665352702
                        ],
                        [
                            -3.692898490261541,
                            40.42105662074442
                        ],
                        [
                            -3.6925435454341766,
                            40.42178194291654
                        ],
                        [
                            -3.6919010700519266,
                            40.42316867406129
                        ],
                        [
                            -3.6910846521561496,
                            40.42503375756834
                        ],
                        [
                            -3.691863039935555,
                            40.42555520098469
                        ],
                        [
                            -3.6925355705446066,
                            40.42586806404546
                        ],
                        [
                            -3.6934820900842453,
                            40.42635631876297
                        ],
                        [
                            -3.6953439936516475,
                            40.42729963579936
                        ],
                        [
                            -3.6958195483970258,
                            40.42745478160714
                        ],
                        [
                            -3.6963052623707995,
                            40.42754958638889
                        ],
                        [
                            -3.6965730277665614,
                            40.42765861172293
                        ],
                        [
                            -3.6980114883829174,
                            40.42797620625433
                        ],
                        [
                            -3.7002719251153735,
                            40.42844548477498
                        ],
                        [
                            -3.7019283343097413,
                            40.428805736928894
                        ],
                        [
                            -3.7025259545682445,
                            40.4289243699059
                        ],
                        [
                            -3.703203123813381,
                            40.42905410183448
                        ],
                        [
                            -3.703955072644419,
                            40.429173663569344
                        ],
                        [
                            -3.7052912320939697,
                            40.42939680150445
                        ],
                        [
                            -3.7057025328776945,
                            40.429204799139455
                        ],
                        [
                            -3.706077479246801,
                            40.42925668884462
                        ],
                        [
                            -3.706356981989984,
                            40.42953517836719
                        ],
                        [
                            -3.707616657369101,
                            40.42965458910396
                        ],
                        [
                            -3.7081506666737027,
                            40.429706481342066
                        ],
                        [
                            -3.7091414213702762,
                            40.42980161708158
                        ],
                        [
                            -3.7104412227410535,
                            40.42992788784832
                        ],
                        [
                            -3.712035650137551,
                            40.43010094188381
                        ],
                        [
                            -3.7137579666394913,
                            40.43026695409171
                        ],
                        [
                            -3.7138830749967724,
                            40.43020211383467
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "es--01--002",
            "properties": {
                "land": "es",
                "city": "Madrid",
                "zone": "ZBEDEP Platze Eliptica",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. DGT-B Sticker",
                "img_sign": "sign-es--white",
                "color": 1,
                "urlZone": "https://www.race.es/distrito-centro-zona-cero-emisiones-calles-area",
                "urlPark": "https://www.esmadrid.com/aparcar-madrid?utm_referrer=https%3A%2F%2Fwww.esmadrid.com%2Fde%2Fautofahren-madrid%3Futm_referrer%3Dhttps%253A%252F%252Fwww.google.com%252F",
                "urlReg": "https://www.race.es/madrid-central-zona-cero-emisiones-calles-area",
                "urlBadge": "https://www.dgt.es/nuestros-servicios/tu-vehiculo/tus-vehiculos/distintivo-ambiental/"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -3.7118799337442283,
                            40.38969542851095
                        ],
                        [
                            -3.7121717509374434,
                            40.39001414592107
                        ],
                        [
                            -3.71267830153667,
                            40.38981704454301
                        ],
                        [
                            -3.7131352982732153,
                            40.38951510088725
                        ],
                        [
                            -3.7136827278798137,
                            40.38913217612239
                        ],
                        [
                            -3.714079158783761,
                            40.38875474250284
                        ],
                        [
                            -3.7144150238550537,
                            40.38846537529565
                        ],
                        [
                            -3.714877526576174,
                            40.387999868918826
                        ],
                        [
                            -3.715291349022891,
                            40.38759251721976
                        ],
                        [
                            -3.7161447766634126,
                            40.38680827373685
                        ],
                        [
                            -3.7163650160539987,
                            40.386753753797166
                        ],
                        [
                            -3.7168880846077172,
                            40.38687956897596
                        ],
                        [
                            -3.716992698318478,
                            40.38659019371221
                        ],
                        [
                            -3.717972763608799,
                            40.38677891685148
                        ],
                        [
                            -3.7183747004972645,
                            40.386703427659086
                        ],
                        [
                            -3.71948140560977,
                            40.38630920409295
                        ],
                        [
                            -3.719652091138556,
                            40.385982081367445
                        ],
                        [
                            -3.7196906330312913,
                            40.38568431442906
                        ],
                        [
                            -3.719687894601293,
                            40.3852830592912
                        ],
                        [
                            -3.719566762936921,
                            40.38526628354995
                        ],
                        [
                            -3.719594292860535,
                            40.38509852590755
                        ],
                        [
                            -3.7200567955816553,
                            40.385085944067356
                        ],
                        [
                            -3.721273618217026,
                            40.385090138014135
                        ],
                        [
                            -3.7217636508617886,
                            40.385060780379945
                        ],
                        [
                            -3.7228208033914143,
                            40.38517401723527
                        ],
                        [
                            -3.7234980395185744,
                            40.38527047782276
                        ],
                        [
                            -3.723752438554783,
                            40.38414227040528
                        ],
                        [
                            -3.723554223102383,
                            40.38390321164877
                        ],
                        [
                            -3.7235211871942226,
                            40.38373545061165
                        ],
                        [
                            -3.7246664320270497,
                            40.38306020821082
                        ],
                        [
                            -3.724941731265716,
                            40.38318183622346
                        ],
                        [
                            -3.7267752237257525,
                            40.38168873263024
                        ],
                        [
                            -3.725095898370057,
                            40.380501775293055
                        ],
                        [
                            -3.723522628254244,
                            40.3794058659349
                        ],
                        [
                            -3.7193160933566958,
                            40.37909968268997
                        ],
                        [
                            -3.7191178779051484,
                            40.38050057093031
                        ],
                        [
                            -3.719250029021481,
                            40.380796556052246
                        ],
                        [
                            -3.7192224990978957,
                            40.38127888895093
                        ],
                        [
                            -3.7190132716764026,
                            40.38176541255007
                        ],
                        [
                            -3.718627871040127,
                            40.38328368066439
                        ],
                        [
                            -3.7171577731051286,
                            40.38309075366095
                        ],
                        [
                            -3.717020123485355,
                            40.38341369637672
                        ],
                        [
                            -3.7161942257693,
                            40.38326271036422
                        ],
                        [
                            -3.7155531224724427,
                            40.383105433915404
                        ],
                        [
                            -3.715085113766804,
                            40.38279507199155
                        ],
                        [
                            -3.7149860059997195,
                            40.382715384246666
                        ],
                        [
                            -3.713482872156561,
                            40.38271957834169
                        ],
                        [
                            -3.7134718601865586,
                            40.38307188135073
                        ],
                        [
                            -3.7136260277608812,
                            40.3833277192858
                        ],
                        [
                            -3.713604003820933,
                            40.383684213148626
                        ],
                        [
                            -3.7134718937744537,
                            40.384011366827565
                        ],
                        [
                            -3.7131965945357877,
                            40.38439302101861
                        ],
                        [
                            -3.712888259123673,
                            40.384694987713516
                        ],
                        [
                            -3.7124089717766253,
                            40.384930273033035
                        ],
                        [
                            -3.7121391785225057,
                            40.38502673396954
                        ],
                        [
                            -3.712216262309653,
                            40.38522384936667
                        ],
                        [
                            -3.7121612024624255,
                            40.38537902491211
                        ],
                        [
                            -3.7123318879903593,
                            40.38559291494238
                        ],
                        [
                            -3.7123924538229858,
                            40.3857229261975
                        ],
                        [
                            -3.712568645335466,
                            40.38597875406384
                        ],
                        [
                            -3.712634717152639,
                            40.38609618292347
                        ],
                        [
                            -3.7126873037036887,
                            40.386280079964735
                        ],
                        [
                            -3.712720339611849,
                            40.38646461011135
                        ],
                        [
                            -3.712604713931995,
                            40.38742919128737
                        ],
                        [
                            -3.7125441480993686,
                            40.38784437632364
                        ],
                        [
                            -3.7124615583277034,
                            40.38805825852566
                        ],
                        [
                            -3.7123624506014608,
                            40.38834343373742
                        ],
                        [
                            -3.7118559267828743,
                            40.38942122077242
                        ],
                        [
                            -3.7118799337442283,
                            40.38969542851095
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "es",
                "city": "Barcelona",
                "zone": "ZBE",
                "area_code": 2,
                "time": "Mo-Fr: 07.00-20.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. DGT-B Sticker or Online Reg.",
                "img_sign": "sign-es--white",
                "color": 1,
                "urlZone": "https://www.zbe.barcelona/",
                "urlPark": "https://www.amb.cat/en/web/mobilitat/mobilitat-sostenible/zones-d-estacionament/aparcaments-d-intercanvi-p-r",
                "urlReg": "https://zberegistre.ambmobilitat.cat/en",
                "urlBadge": "https://ajuntament.barcelona.cat/qualitataire/en/afectacions-la-mobilitat/dgt-environmental-label"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                2.223562002182007,
                                41.430959096123125
                            ],
                            [
                                2.2220921516418457,
                                41.42948703040529
                            ],
                            [
                                2.2201180458068843,
                                41.42765293461421
                            ],
                            [
                                2.2185516357421875,
                                41.42544873340554
                            ],
                            [
                                2.218422889709472,
                                41.4240972153671
                            ],
                            [
                                2.220160961151123,
                                41.42250431870768
                            ],
                            [
                                2.2229450941085815,
                                41.4211406715857
                            ],
                            [
                                2.2249245643615723,
                                41.41949942999941
                            ],
                            [
                                2.22571849822998,
                                41.41797078533425
                            ],
                            [
                                2.2258472442626953,
                                41.41662313453868
                            ],
                            [
                                2.22547709941864,
                                41.415243272065446
                            ],
                            [
                                2.2248226404190063,
                                41.41423349990281
                            ],
                            [
                                2.2235298156738277,
                                41.41282542486483
                            ],
                            [
                                2.2225964069366455,
                                41.41148973690656
                            ],
                            [
                                2.22198486328125,
                                41.4099448105579
                            ],
                            [
                                2.220783233642578,
                                41.4078647345004
                            ],
                            [
                                2.2169798612594604,
                                41.404806861557525
                            ],
                            [
                                2.2114652395248413,
                                41.40078714913504
                            ],
                            [
                                2.2096842527389526,
                                41.399161489116906
                            ],
                            [
                                2.2084182500839233,
                                41.39698851370937
                            ],
                            [
                                2.206926941871643,
                                41.39564042763589
                            ],
                            [
                                2.204384207725525,
                                41.39361220964395
                            ],
                            [
                                2.1995347738265987,
                                41.39007072492662
                            ],
                            [
                                2.1966809034347534,
                                41.387941716833666
                            ],
                            [
                                2.193290591239929,
                                41.385410159012
                            ],
                            [
                                2.1908873319625854,
                                41.38409403198282
                            ],
                            [
                                2.1894228458404537,
                                41.38349432000649
                            ],
                            [
                                2.1873629093170166,
                                41.38250820439706
                            ],
                            [
                                2.1844124794006348,
                                41.381192018631744
                            ],
                            [
                                2.183307409286499,
                                41.3808820872219
                            ],
                            [
                                2.18252956867218,
                                41.38054397854473
                            ],
                            [
                                2.182062864303589,
                                41.38020586810977
                            ],
                            [
                                2.18102753162384,
                                41.37892586268237
                            ],
                            [
                                2.1797722578048706,
                                41.37735601031151
                            ],
                            [
                                2.1788066625595093,
                                41.376281243376475
                            ],
                            [
                                2.1782219409942623,
                                41.37508166979521
                            ],
                            [
                                2.1775031089782715,
                                41.37415580928717
                            ],
                            [
                                2.1790695190429688,
                                41.37410347764757
                            ],
                            [
                                2.18353271484375,
                                41.371849151666204
                            ],
                            [
                                2.1849918365478516,
                                41.36392618639254
                            ],
                            [
                                2.1837902069091797,
                                41.357548464778496
                            ],
                            [
                                2.185678482055664,
                                41.35703306604139
                            ],
                            [
                                2.192115783691406,
                                41.368757377569366
                            ],
                            [
                                2.2002696990966797,
                                41.382862402745104
                            ],
                            [
                                2.2058486938476562,
                                41.389044463188355
                            ],
                            [
                                2.218294143676758,
                                41.40147120183829
                            ],
                            [
                                2.2271347045898438,
                                41.405205197895654
                            ],
                            [
                                2.234258651733398,
                                41.414153039294284
                            ],
                            [
                                2.237589955329895,
                                41.42609632582989
                            ],
                            [
                                2.2340816259384155,
                                41.428984268073556
                            ],
                            [
                                2.2317802906036377,
                                41.42637788573895
                            ],
                            [
                                2.225906252861023,
                                41.429362345710075
                            ],
                            [
                                2.2246295213699336,
                                41.42926983755567
                            ],
                            [
                                2.2244739532470703,
                                41.42945887581762
                            ],
                            [
                                2.224554419517517,
                                41.42952322914292
                            ],
                            [
                                2.223562002182007,
                                41.430959096123125
                            ]
                        ],
                        [
                            [
                                2.2230255603790283,
                                41.43164283114216
                            ],
                            [
                                2.2225427627563477,
                                41.43238286563566
                            ],
                            [
                                2.220831513404846,
                                41.431670984782684
                            ],
                            [
                                2.2196352481842037,
                                41.43290973287839
                            ],
                            [
                                2.2174251079559326,
                                41.432201879718185
                            ],
                            [
                                2.216362953186035,
                                41.433504967061424
                            ],
                            [
                                2.2153061628341675,
                                41.433802582106146
                            ],
                            [
                                2.214689254760742,
                                41.43482411525807
                            ],
                            [
                                2.211894392967224,
                                41.43407606499825
                            ],
                            [
                                2.210553288459778,
                                41.433673883875876
                            ],
                            [
                                2.21200168132782,
                                41.43151010667233
                            ],
                            [
                                2.2147750854492183,
                                41.42791035469301
                            ],
                            [
                                2.2171568870544434,
                                41.42718635811996
                            ],
                            [
                                2.220134139060974,
                                41.428557922565574
                            ],
                            [
                                2.221636176109314,
                                41.43003000935048
                            ],
                            [
                                2.2230255603790283,
                                41.43164283114216
                            ]
                        ],
                        [
                            [
                                2.224409580230713,
                                41.418594315792525
                            ],
                            [
                                2.2237443923950195,
                                41.41959195207437
                            ],
                            [
                                2.2199463844299316,
                                41.42163544951514
                            ],
                            [
                                2.2135305404663086,
                                41.424161574006796
                            ],
                            [
                                2.214324474334717,
                                41.42694502413486
                            ],
                            [
                                2.2107839584350586,
                                41.43214155096689
                            ],
                            [
                                2.2095608711242676,
                                41.4341363919517
                            ],
                            [
                                2.2085094451904297,
                                41.43675854934449
                            ],
                            [
                                2.2078657150268555,
                                41.43971840341532
                            ],
                            [
                                2.2067284584045406,
                                41.44209905764931
                            ],
                            [
                                2.205183506011963,
                                41.44394883003659
                            ],
                            [
                                2.2030162811279297,
                                41.446554506897215
                            ],
                            [
                                2.2003555297851562,
                                41.44856498851091
                            ],
                            [
                                2.1968579292297363,
                                41.447487378113046
                            ],
                            [
                                2.1940040588378906,
                                41.446908356178696
                            ],
                            [
                                2.1914076805114746,
                                41.44665101143812
                            ],
                            [
                                2.1872663497924805,
                                41.446618843273775
                            ],
                            [
                                2.182481288909912,
                                41.44632932907699
                            ],
                            [
                                2.1792197227478027,
                                41.445750296807915
                            ],
                            [
                                2.17628002166748,
                                41.44502649920502
                            ],
                            [
                                2.169477939605713,
                                41.44238859072108
                            ],
                            [
                                2.1626329421997066,
                                41.43979883234857
                            ],
                            [
                                2.1569252014160156,
                                41.43897845255237
                            ],
                            [
                                2.1524834632873535,
                                41.43780416538707
                            ],
                            [
                                2.1506166458129883,
                                41.437015933317866
                            ],
                            [
                                2.1476125717163086,
                                41.43481204999971
                            ],
                            [
                                2.1464967727661133,
                                41.43281722977883
                            ],
                            [
                                2.1428275108337402,
                                41.42472470938333
                            ],
                            [
                                2.1417975425720215,
                                41.42329272698853
                            ],
                            [
                                2.1399521827697754,
                                41.41959597477031
                            ],
                            [
                                2.138788104057312,
                                41.41698519272335
                            ],
                            [
                                2.1380209922790523,
                                41.41579441598615
                            ],
                            [
                                2.1355533599853516,
                                41.41466798546655
                            ],
                            [
                                2.13134765625,
                                41.41378291921146
                            ],
                            [
                                2.1288585662841797,
                                41.411900101792305
                            ],
                            [
                                2.125275135040283,
                                41.409647086153896
                            ],
                            [
                                2.1213912963867188,
                                41.40625132210526
                            ],
                            [
                                2.117764949798584,
                                41.40406248912791
                            ],
                            [
                                2.1144604682922363,
                                41.4009400556583
                            ],
                            [
                                2.1137094497680664,
                                41.39862227607079
                            ],
                            [
                                2.112572193145752,
                                41.39678730860786
                            ],
                            [
                                2.1107053756713867,
                                41.394871804852
                            ],
                            [
                                2.109203338623047,
                                41.391716734434084
                            ],
                            [
                                2.1078085899353023,
                                41.38854541241048
                            ],
                            [
                                2.1040749549865723,
                                41.384150380474864
                            ],
                            [
                                2.1033883094787598,
                                41.38181590205543
                            ],
                            [
                                2.1022510528564453,
                                41.38035077279714
                            ],
                            [
                                2.100706100463867,
                                41.37970674949303
                            ],
                            [
                                2.095813751220703,
                                41.375810272494114
                            ],
                            [
                                2.094161510467529,
                                41.371478790640644
                            ],
                            [
                                2.0914793014526363,
                                41.368386998942434
                            ],
                            [
                                2.0897626876831055,
                                41.36292769548817
                            ],
                            [
                                2.088947296142578,
                                41.35788669298176
                            ],
                            [
                                2.092123031616211,
                                41.35220100440021
                            ],
                            [
                                2.092874050140381,
                                41.3486894812448
                            ],
                            [
                                2.0952773094177246,
                                41.34403400489993
                            ],
                            [
                                2.100341320037842,
                                41.34160142638828
                            ],
                            [
                                2.1036458015441895,
                                41.3429707698419
                            ],
                            [
                                2.1085166931152344,
                                41.34140810499301
                            ],
                            [
                                2.117335796356201,
                                41.339865540801746
                            ],
                            [
                                2.123982310295105,
                                41.34127116865747
                            ],
                            [
                                2.1399521827697754,
                                41.346853104724644
                            ],
                            [
                                2.1463680267333984,
                                41.35003048663898
                            ],
                            [
                                2.148250937461853,
                                41.35060231837644
                            ],
                            [
                                2.153770923614502,
                                41.35204798325568
                            ],
                            [
                                2.1584701538085938,
                                41.35414595234311
                            ],
                            [
                                2.1600311994552612,
                                41.35555529859857
                            ],
                            [
                                2.1633625030517574,
                                41.35841416440983
                            ],
                            [
                                2.168855667114258,
                                41.36227142094475
                            ],
                            [
                                2.170851230621338,
                                41.365343372983396
                            ],
                            [
                                2.172369360923767,
                                41.36758987257164
                            ],
                            [
                                2.1738606691360474,
                                41.37021471647864
                            ],
                            [
                                2.175073027610779,
                                41.37164786876171
                            ],
                            [
                                2.178313136100769,
                                41.376269167242626
                            ],
                            [
                                2.1819716691970825,
                                41.380644606310966
                            ],
                            [
                                2.1859681606292725,
                                41.38225060438672
                            ],
                            [
                                2.192115783691406,
                                41.385253191132406
                            ],
                            [
                                2.195613384246826,
                                41.38780487839031
                            ],
                            [
                                2.200033664703369,
                                41.39115331288804
                            ],
                            [
                                2.2073721885681152,
                                41.39696436913006
                            ],
                            [
                                2.2086596488952637,
                                41.39953974037497
                            ],
                            [
                                2.2199034690856934,
                                41.4081825840887
                            ],
                            [
                                2.2220492362976074,
                                41.412608176283975
                            ],
                            [
                                2.2243666648864746,
                                41.415102468124054
                            ],
                            [
                                2.224409580230713,
                                41.418594315792525
                            ]
                        ],
                        [
                            [
                                2.0889365673065186,
                                41.36376514050876
                            ],
                            [
                                2.089703679084778,
                                41.365862731931706
                            ],
                            [
                                2.090368866920471,
                                41.367964281628765
                            ],
                            [
                                2.0909535884857178,
                                41.3690150310228
                            ],
                            [
                                2.089751958847046,
                                41.369486051104595
                            ],
                            [
                                2.08715558052063,
                                41.3687493258825
                            ],
                            [
                                2.086876630783081,
                                41.367799219836854
                            ],
                            [
                                2.0867639780044556,
                                41.366776632573746
                            ],
                            [
                                2.085299491882324,
                                41.365890914081646
                            ],
                            [
                                2.0889365673065186,
                                41.36376514050876
                            ]
                        ],
                        [
                            [
                                2.1022939682006836,
                                41.38369959117129
                            ],
                            [
                                2.104353904724121,
                                41.38585691167221
                            ],
                            [
                                2.1067142486572266,
                                41.38955960836037
                            ],
                            [
                                2.1091818809509277,
                                41.39432450769634
                            ],
                            [
                                2.112421989440918,
                                41.39841302801219
                            ],
                            [
                                2.113323211669922,
                                41.40140682071436
                            ],
                            [
                                2.117185592651367,
                                41.40507644298273
                            ],
                            [
                                2.1208763122558594,
                                41.407056021574675
                            ],
                            [
                                2.1242880821228027,
                                41.410129881797026
                            ],
                            [
                                2.12935209274292,
                                41.413203596587216
                            ],
                            [
                                2.1311974525451656,
                                41.414410512890704
                            ],
                            [
                                2.1364760398864746,
                                41.4158909662646
                            ],
                            [
                                2.1382999420166016,
                                41.41754839033237
                            ],
                            [
                                2.13958740234375,
                                41.420525210851494
                            ],
                            [
                                2.141690254211426,
                                41.42503040939966
                            ],
                            [
                                2.14583158493042,
                                41.433766385754694
                            ],
                            [
                                2.1469902992248535,
                                41.435552048363654
                            ],
                            [
                                2.148921489715576,
                                41.43706419269923
                            ],
                            [
                                2.150745391845703,
                                41.43788459669261
                            ],
                            [
                                2.155616283416748,
                                41.43941277255982
                            ],
                            [
                                2.162289619445801,
                                41.440458345841655
                            ],
                            [
                                2.168469429016113,
                                41.442838972931746
                            ],
                            [
                                2.17653751373291,
                                41.44586288682049
                            ],
                            [
                                2.180185317993164,
                                41.44669926365472
                            ],
                            [
                                2.1866440773010254,
                                41.4474712942402
                            ],
                            [
                                2.1926307678222656,
                                41.44756779741746
                            ],
                            [
                                2.192974090576172,
                                41.449208329469606
                            ],
                            [
                                2.1936821937561035,
                                41.45113831406844
                            ],
                            [
                                2.1941542625427246,
                                41.453566796441145
                            ],
                            [
                                2.1915364265441895,
                                41.45503027502682
                            ],
                            [
                                2.189455032348633,
                                41.45504635702566
                            ],
                            [
                                2.1874594688415527,
                                41.45593086082166
                            ],
                            [
                                2.1850401163101196,
                                41.45785260476875
                            ],
                            [
                                2.1818214654922485,
                                41.460188371686286
                            ],
                            [
                                2.180491089820862,
                                41.46164366068906
                            ],
                            [
                                2.179268002510071,
                                41.46330393671205
                            ],
                            [
                                2.1766233444213867,
                                41.464437563230874
                            ],
                            [
                                2.173619270324707,
                                41.46234717266838
                            ],
                            [
                                2.1701645851135254,
                                41.460916020269906
                            ],
                            [
                                2.1673965454101562,
                                41.45951699807194
                            ],
                            [
                                2.1646714210510254,
                                41.456590210349816
                            ],
                            [
                                2.1632981300354004,
                                41.4513956410102
                            ],
                            [
                                2.156195640563965,
                                41.44988383061024
                            ],
                            [
                                2.1524405479431152,
                                41.447439126482564
                            ],
                            [
                                2.134995460510254,
                                41.44816289717214
                            ],
                            [
                                2.1294593811035156,
                                41.438672818212225
                            ],
                            [
                                2.1242451667785645,
                                41.429326146882744
                            ],
                            [
                                2.123086452484131,
                                41.42525566112234
                            ],
                            [
                                2.1218472719192505,
                                41.420505097657184
                            ],
                            [
                                2.1273350715637207,
                                41.41807940074786
                            ],
                            [
                                2.1190953254699707,
                                41.41402430211336
                            ],
                            [
                                2.1143531799316406,
                                41.412688638806046
                            ],
                            [
                                2.109096050262451,
                                41.4128334710946
                            ],
                            [
                                2.106478214263916,
                                41.41183573100123
                            ],
                            [
                                2.106156349182129,
                                41.409759738791536
                            ],
                            [
                                2.1036887168884277,
                                41.4080055541301
                            ],
                            [
                                2.103753089904785,
                                41.40283928564363
                            ],
                            [
                                2.0981955528259277,
                                41.394227925367005
                            ],
                            [
                                2.1011781692504883,
                                41.389495215437094
                            ],
                            [
                                2.1022939682006836,
                                41.38369959117129
                            ]
                        ],
                        [
                            [
                                2.1789407730102535,
                                41.467573022721666
                            ],
                            [
                                2.17928409576416,
                                41.466093747905916
                            ],
                            [
                                2.1816015243530273,
                                41.46299037694192
                            ],
                            [
                                2.1838331222534175,
                                41.4607069728939
                            ],
                            [
                                2.1884679794311523,
                                41.45758726275844
                            ],
                            [
                                2.1900343894958496,
                                41.45609167839779
                            ],
                            [
                                2.1931028366088867,
                                41.455544897012146
                            ],
                            [
                                2.194991111755371,
                                41.45432266313024
                            ],
                            [
                                2.1961283683776855,
                                41.451636884091045
                            ],
                            [
                                2.1981883049011226,
                                41.450655823301474
                            ],
                            [
                                2.199561595916748,
                                41.45154038696636
                            ],
                            [
                                2.1897125244140625,
                                41.45848781305009
                            ],
                            [
                                2.187716960906982,
                                41.461736122699115
                            ],
                            [
                                2.187201976776123,
                                41.46747654930595
                            ],
                            [
                                2.1831679344177246,
                                41.46770165371936
                            ],
                            [
                                2.1789407730102535,
                                41.467573022721666
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "es",
                "city": "Barcelona - Sant Joan Despi",
                "zone": "ZBE - Sant Joan Despi",
                "area_code": 2,
                "time": "Mo-Fr: 07.00-20.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. DGT-B Sticker or Online Reg.",
                "img_sign": "sign-es--white",
                "color": 1,
                "urlZone": "https://sjdespi.cat/ZBE",
                "urlPark": "https://www.amb.cat/en/web/mobilitat/mobilitat-sostenible/zones-d-estacionament/aparcaments-d-intercanvi-p-r",
                "urlReg": "https://zberegistre.ambmobilitat.cat/en",
                "urlBadge": "https://ajuntament.barcelona.cat/qualitataire/en/afectacions-la-mobilitat/dgt-environmental-label"
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                2.0430915174622157,
                                41.37383102095134
                            ],
                            [
                                2.0434700328461872,
                                41.37336081851876
                            ],
                            [
                                2.045051894408971,
                                41.37318625091498
                            ],
                            [
                                2.049285396231909,
                                41.37299993226054
                            ],
                            [
                                2.052076916634803,
                                41.37317450036508
                            ],
                            [
                                2.0543101329573688,
                                41.373674926334786
                            ],
                            [
                                2.0573497874057978,
                                41.37443137654134
                            ],
                            [
                                2.0597070713025687,
                                41.375013255595775
                            ],
                            [
                                2.062529608599334,
                                41.37540893038002
                            ],
                            [
                                2.065600271332073,
                                41.37566495310415
                            ],
                            [
                                2.068097131248976,
                                41.37596752512442
                            ],
                            [
                                2.069663484363815,
                                41.37623518381477
                            ],
                            [
                                2.0695083998967903,
                                41.37653775318168
                            ],
                            [
                                2.067259680271917,
                                41.37715451807861
                            ],
                            [
                                2.0664532410441154,
                                41.37810875704514
                            ],
                            [
                                2.0654607004566117,
                                41.37886515603495
                            ],
                            [
                                2.06553824268903,
                                41.37919098673507
                            ],
                            [
                                2.06502646394938,
                                41.379458632161175
                            ],
                            [
                                2.0618007070382305,
                                41.379377174973996
                            ],
                            [
                                2.061537063444945,
                                41.379004797963916
                            ],
                            [
                                2.0596760575052713,
                                41.37920262085942
                            ],
                            [
                                2.058931652064075,
                                41.377782918266746
                            ],
                            [
                                2.0542791180592133,
                                41.37837640426764
                            ],
                            [
                                2.053534740079442,
                                41.378213477070176
                            ],
                            [
                                2.0521234714305763,
                                41.379062969345114
                            ],
                            [
                                2.0514255913300588,
                                41.37895823802057
                            ],
                            [
                                2.051177456182671,
                                41.3785974955
                            ],
                            [
                                2.0485100033529307,
                                41.37687521330864
                            ],
                            [
                                2.0470461713665884,
                                41.375739582402446
                            ],
                            [
                                2.0450455817445174,
                                41.37484349649958
                            ],
                            [
                                2.0436963468822,
                                41.37423834059581
                            ],
                            [
                                2.0430915174622157,
                                41.37383102095134
                            ]
                        ],
                        [
                            [
                                2.043997319774718,
                                41.372621507421854
                            ],
                            [
                                2.0493787507749346,
                                41.36752388256775
                            ],
                            [
                                2.05353642912479,
                                41.363309774797955
                            ],
                            [
                                2.0552578667066257,
                                41.36135428880695
                            ],
                            [
                                2.0557386285545647,
                                41.36062096641129
                            ],
                            [
                                2.059817351377717,
                                41.357431498512426
                            ],
                            [
                                2.060003452737476,
                                41.357943686155494
                            ],
                            [
                                2.058716251662844,
                                41.359282339372044
                            ],
                            [
                                2.0581579475824583,
                                41.36060932494934
                            ],
                            [
                                2.060096503417924,
                                41.36191300373005
                            ],
                            [
                                2.058685226801316,
                                41.36325157407154
                            ],
                            [
                                2.061104544483669,
                                41.36492765965053
                            ],
                            [
                                2.0647955547947845,
                                41.362832545930615
                            ],
                            [
                                2.065508939721667,
                                41.362972199508164
                            ],
                            [
                                2.0676801222576273,
                                41.364194354752414
                            ],
                            [
                                2.0699282934141365,
                                41.36506743243831
                            ],
                            [
                                2.07203744216244,
                                41.365917096395236
                            ],
                            [
                                2.0734797277051484,
                                41.36668527617644
                            ],
                            [
                                2.0735417614917537,
                                41.366883139196375
                            ],
                            [
                                2.0743947260592392,
                                41.36720902991797
                            ],
                            [
                                2.0747514203328876,
                                41.3670111678891
                            ],
                            [
                                2.075868226447625,
                                41.36782598836126
                            ],
                            [
                                2.0778843245165604,
                                41.36904805244103
                            ],
                            [
                                2.0789078819979636,
                                41.36967653360091
                            ],
                            [
                                2.0787993228705943,
                                41.37020026326388
                            ],
                            [
                                2.078303052576814,
                                41.370828733293195
                            ],
                            [
                                2.0777447484964853,
                                41.37141064457458
                            ],
                            [
                                2.0766901741212678,
                                41.37205074097142
                            ],
                            [
                                2.0762409790416996,
                                41.37213352891362
                            ],
                            [
                                2.0757602171937606,
                                41.37295982480717
                            ],
                            [
                                2.0745040330129143,
                                41.37398395162984
                            ],
                            [
                                2.0733253910645146,
                                41.374903324475724
                            ],
                            [
                                2.0724879349440073,
                                41.37539209968517
                            ],
                            [
                                2.070409803087358,
                                41.375380462222665
                            ],
                            [
                                2.0662898344180007,
                                41.37522862750885
                            ],
                            [
                                2.0611410301183355,
                                41.374646750381004
                            ],
                            [
                                2.0559784358945024,
                                41.37339117231869
                            ],
                            [
                                2.052783695877139,
                                41.37261143375656
                            ],
                            [
                                2.049852599453061,
                                41.37237867416505
                            ],
                            [
                                2.045683110994702,
                                41.372550345374464
                            ],
                            [
                                2.043997319774718,
                                41.372621507421854
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "es--02--002",
            "properties": {
                "land": "es",
                "city": "Barcelona - Sant Cugat del Valles",
                "zone": "ZBE - Sant Cugat del Valles",
                "area_code": 2,
                "time": "Mo-Fr: 07.00-20.00",
                "commercial": 2,
                "diesel": "min. EURO-4 (diesel)",
                "minEUd": 4,
                "petrol": "min. EURO-3 (petrol)",
                "minEUb": 3,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. DGT-B Sticker or Online Reg.",
                "img_sign": "sign-es--white",
                "color": 1,
                "urlZone": "https://www.santcugat.cat/web/zona-baixes-emissions",
                "urlPark": "https://www.amb.cat/en/web/mobilitat/mobilitat-sostenible/zones-d-estacionament/aparcaments-d-intercanvi-p-r",
                "urlReg": "https://zberegistre.ambmobilitat.cat/en",
                "urlBadge": "https://ajuntament.barcelona.cat/qualitataire/en/afectacions-la-mobilitat/dgt-environmental-label"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            2.0870200443388853,
                            41.46408860760894
                        ],
                        [
                            2.0883467379088074,
                            41.46463035041094
                        ],
                        [
                            2.0904781846496974,
                            41.465513454934865
                        ],
                        [
                            2.088899604640403,
                            41.46571514995952
                        ],
                        [
                            2.0905656842159033,
                            41.46554896478284
                        ],
                        [
                            2.0910603544496382,
                            41.46592509803631
                        ],
                        [
                            2.090804069614819,
                            41.467936099896974
                        ],
                        [
                            2.0906003818716385,
                            41.4695332295789
                        ],
                        [
                            2.0915024296011495,
                            41.46868833525224
                        ],
                        [
                            2.090598503429561,
                            41.469640043966194
                        ],
                        [
                            2.090933133293248,
                            41.46992893987911
                        ],
                        [
                            2.0920825141296575,
                            41.4700216043334
                        ],
                        [
                            2.093108227407413,
                            41.4699888992466
                        ],
                        [
                            2.093995724002639,
                            41.46987988217373
                        ],
                        [
                            2.094955966220624,
                            41.469585535158984
                        ],
                        [
                            2.0950214372814173,
                            41.46937840127376
                        ],
                        [
                            2.095232399586422,
                            41.469220324969115
                        ],
                        [
                            2.0956906970087914,
                            41.4692094231406
                        ],
                        [
                            2.0958580119406633,
                            41.46944381204608
                        ],
                        [
                            2.095858010889998,
                            41.46979266760144
                        ],
                        [
                            2.096832802231688,
                            41.4712861875698
                        ],
                        [
                            2.0977566668269105,
                            41.47279030985044
                        ],
                        [
                            2.097567528208458,
                            41.47398943373932
                        ],
                        [
                            2.097953076502307,
                            41.47541745006092
                        ],
                        [
                            2.097589348389164,
                            41.475624564650246
                        ],
                        [
                            2.0975675247025833,
                            41.475924334594424
                        ],
                        [
                            2.0970073834091068,
                            41.47640941393044
                        ],
                        [
                            2.0965345330244247,
                            41.47671463633219
                        ],
                        [
                            2.096294472469907,
                            41.47663833231712
                        ],
                        [
                            2.0959452934815204,
                            41.47684544300526
                        ],
                        [
                            2.095785253111501,
                            41.47730326428302
                        ],
                        [
                            2.095450623247814,
                            41.477706580348325
                        ],
                        [
                            2.0941557511661415,
                            41.47876391055098
                        ],
                        [
                            2.093239153440976,
                            41.479652276398184
                        ],
                        [
                            2.092904523577232,
                            41.48013187783687
                        ],
                        [
                            2.09155872955958,
                            41.481210968098026
                        ],
                        [
                            2.090023791633655,
                            41.481755960573395
                        ],
                        [
                            2.0886561739293654,
                            41.482197397698
                        ],
                        [
                            2.086844807872012,
                            41.48257888414582
                        ],
                        [
                            2.0858715169594007,
                            41.48262823922491
                        ],
                        [
                            2.084765783497204,
                            41.482355749339405
                        ],
                        [
                            2.0835291079134493,
                            41.48178896670768
                        ],
                        [
                            2.0809015815366934,
                            41.48047046050553
                        ],
                        [
                            2.0809670525974866,
                            41.48012166163258
                        ],
                        [
                            2.0803632639298257,
                            41.47980011100927
                        ],
                        [
                            2.07996316300634,
                            41.479843711187186
                        ],
                        [
                            2.07956306208186,
                            41.48006716163914
                        ],
                        [
                            2.0698452027432666,
                            41.47989196963999
                        ],
                        [
                            2.0694160035703533,
                            41.47955951778911
                        ],
                        [
                            2.0693067476313445,
                            41.4785842654143
                        ],
                        [
                            2.0695322590611056,
                            41.47824090654552
                        ],
                        [
                            2.0693867678158995,
                            41.47770133893377
                        ],
                        [
                            2.0694595134385168,
                            41.47706366232859
                        ],
                        [
                            2.0693722232147422,
                            41.47633332271545
                        ],
                        [
                            2.0692049082828703,
                            41.47575558668453
                        ],
                        [
                            2.068608394178085,
                            41.47500888248754
                        ],
                        [
                            2.0683827855860955,
                            41.47455672423203
                        ],
                        [
                            2.068528276831387,
                            41.47430055186976
                        ],
                        [
                            2.0685064531438115,
                            41.47409888355395
                        ],
                        [
                            2.068302765400631,
                            41.47398987339196
                        ],
                        [
                            2.068033606597652,
                            41.473929917725
                        ],
                        [
                            2.0680117829110714,
                            41.47364649018357
                        ],
                        [
                            2.0682736671521695,
                            41.47324859942884
                        ],
                        [
                            2.0686446371868215,
                            41.47252369458417
                        ],
                        [
                            2.06903018897205,
                            41.47184236139674
                        ],
                        [
                            2.069284798651296,
                            41.47138450154674
                        ],
                        [
                            2.0693866442365447,
                            41.47098114724491
                        ],
                        [
                            2.0697067249764984,
                            41.47081217328929
                        ],
                        [
                            2.0698085688476056,
                            41.47065410047986
                        ],
                        [
                            2.069684901288923,
                            41.47039791269543
                        ],
                        [
                            2.0699831583422963,
                            41.46980377117018
                        ],
                        [
                            2.070128616808205,
                            41.46921504849206
                        ],
                        [
                            2.0702086369926462,
                            41.46859909226657
                        ],
                        [
                            2.0702448741535306,
                            41.46794486786706
                        ],
                        [
                            2.0702594232782587,
                            41.46727983990036
                        ],
                        [
                            2.0703467179561983,
                            41.466876458885736
                        ],
                        [
                            2.070586783581149,
                            41.46661480745024
                        ],
                        [
                            2.0706595292037377,
                            41.46651123584442
                        ],
                        [
                            2.070623156392429,
                            41.46628773865851
                        ],
                        [
                            2.0704267432111294,
                            41.46610239894457
                        ],
                        [
                            2.070361272151331,
                            41.4658080347796
                        ],
                        [
                            2.070375821275121,
                            41.46535013231198
                        ],
                        [
                            2.0704412923358575,
                            41.4646305647579
                        ],
                        [
                            2.070423909009861,
                            41.46421852419539
                        ],
                        [
                            2.070285692326479,
                            41.46387508922885
                        ],
                        [
                            2.0699801605960886,
                            41.46357526345628
                        ],
                        [
                            2.0694272938654876,
                            41.46337356177537
                        ],
                        [
                            2.0686052683299465,
                            41.46316640804406
                        ],
                        [
                            2.067739595421216,
                            41.46295380221096
                        ],
                        [
                            2.067281297999841,
                            41.462752098596184
                        ],
                        [
                            2.066910295324817,
                            41.46246862218604
                        ],
                        [
                            2.066634272117426,
                            41.46205025037389
                        ],
                        [
                            2.0665178791206245,
                            41.46158141825825
                        ],
                        [
                            2.0690639759537817,
                            41.46054561433925
                        ],
                        [
                            2.068169203102883,
                            41.459864154889516
                        ],
                        [
                            2.068663873336618,
                            41.46021306276961
                        ],
                        [
                            2.0701988059727228,
                            41.46134154884086
                        ],
                        [
                            2.0719414337772832,
                            41.46274699556221
                        ],
                        [
                            2.073803721715251,
                            41.46417526181466
                        ],
                        [
                            2.0751877467541817,
                            41.465459100652
                        ],
                        [
                            2.0761188907226824,
                            41.46584613682262
                        ],
                        [
                            2.0770791329406677,
                            41.466647457888286
                        ],
                        [
                            2.078563146439734,
                            41.46671287317679
                        ],
                        [
                            2.0802872176943197,
                            41.46664745964998
                        ],
                        [
                            2.0807818879270314,
                            41.46638035372774
                        ],
                        [
                            2.080199921382814,
                            41.46521379631241
                        ],
                        [
                            2.080447256500179,
                            41.46510477120938
                        ],
                        [
                            2.08042543281357,
                            41.464826756367245
                        ],
                        [
                            2.0802217450703893,
                            41.464701376734695
                        ],
                        [
                            2.0809710224340563,
                            41.46420530799992
                        ],
                        [
                            2.0818803427159196,
                            41.46364381802772
                        ],
                        [
                            2.082200423454964,
                            41.46339305394548
                        ],
                        [
                            2.082505955070218,
                            41.46336579692161
                        ],
                        [
                            2.0824113857609916,
                            41.463082323194214
                        ],
                        [
                            2.0823604638249265,
                            41.46260804707117
                        ],
                        [
                            2.082222247142539,
                            41.46210105841362
                        ],
                        [
                            2.0825277773441258,
                            41.46063458303914
                        ],
                        [
                            2.0829933493283193,
                            41.45985499447298
                        ],
                        [
                            2.0834443721577713,
                            41.45986589786057
                        ],
                        [
                            2.0842445740057087,
                            41.460312935732475
                        ],
                        [
                            2.084961343219561,
                            41.46179355068267
                        ],
                        [
                            2.084182965058204,
                            41.46186987217149
                        ],
                        [
                            2.0839429045037434,
                            41.4622296722645
                        ],
                        [
                            2.0870200443388853,
                            41.463848747977835
                        ],
                        [
                            2.0870200443388853,
                            41.46408860760894
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "se--01",
            "properties": {
                "land": "se",
                "city": "Göteborg",
                "zone": "Milieuzone Klass 1",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck)",
                "minEUd": 6,
                "petrol": "min. EURO-6 (truck)",
                "minEUb": 6,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-se--lez2--white",
                "color": 0,
                "urlZone": "https://goteborg.se/wps/portal/start/gator-vagar-och-torg/gator-och-vagar/trafikregler/miljozon?uri=gbglnk%3Agbg.page.6a9dfa8b-cff4-45fa-a3a4-baaef31d310d",
                "urlPark": null,
                "urlReg": "https://www.transportstyrelsen.se/sv/vagtrafik/",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                11.954262256622314,
                                57.720709857089744
                            ],
                            [
                                11.950185298919678,
                                57.72104217819072
                            ],
                            [
                                11.945550441741943,
                                57.72107655606147
                            ],
                            [
                                11.944091320037842,
                                57.72100780028729
                            ],
                            [
                                11.938490867614746,
                                57.72115677096627
                            ],
                            [
                                11.934928894042969,
                                57.721214067217986
                            ],
                            [
                                11.932954788208008,
                                57.720698397687
                            ],
                            [
                                11.930336952209473,
                                57.72020563993696
                            ],
                            [
                                11.92342758178711,
                                57.71920864443559
                            ],
                            [
                                11.91943645477295,
                                57.718876306498764
                            ],
                            [
                                11.917483806610107,
                                57.718543965510364
                            ],
                            [
                                11.911733150482176,
                                57.717684448802906
                            ],
                            [
                                11.90941572189331,
                                57.71713434739867
                            ],
                            [
                                11.906776428222656,
                                57.715552759291874
                            ],
                            [
                                11.904308795928955,
                                57.714486867459854
                            ],
                            [
                                11.897399425506592,
                                57.7134438676529
                            ],
                            [
                                11.897270679473877,
                                57.711976740139804
                            ],
                            [
                                11.89615488052368,
                                57.71113999235664
                            ],
                            [
                                11.896090507507324,
                                57.71065856705995
                            ],
                            [
                                11.89866542816162,
                                57.71142655199398
                            ],
                            [
                                11.901412010192871,
                                57.7120111266213
                            ],
                            [
                                11.90744161605835,
                                57.712733235188395
                            ],
                            [
                                11.911969184875488,
                                57.71314586218865
                            ],
                            [
                                11.91493034362793,
                                57.713088553164305
                            ],
                            [
                                11.917140483856201,
                                57.71259569180986
                            ],
                            [
                                11.922612190246582,
                                57.7110139053977
                            ],
                            [
                                11.924715042114258,
                                57.71108268015701
                            ],
                            [
                                11.927547454833984,
                                57.71130046603299
                            ],
                            [
                                11.930487155914307,
                                57.712114285869866
                            ],
                            [
                                11.933705806732178,
                                57.71230914142623
                            ],
                            [
                                11.941173076629639,
                                57.712813468584535
                            ],
                            [
                                11.943233013153076,
                                57.713398020818076
                            ],
                            [
                                11.946709156036377,
                                57.71531207678277
                            ],
                            [
                                11.952545642852783,
                                57.71845228470067
                            ],
                            [
                                11.95314645767212,
                                57.7195638988557
                            ],
                            [
                                11.954262256622314,
                                57.720709857089744
                            ]
                        ],
                        [
                            [
                                11.975191003099809,
                                57.71467181021643
                            ],
                            [
                                11.973976416889798,
                                57.71546135789296
                            ],
                            [
                                11.971809012281966,
                                57.715963008525506
                            ],
                            [
                                11.966910675024991,
                                57.71517579486641
                            ],
                            [
                                11.965263447523455,
                                57.716372036642525
                            ],
                            [
                                11.96367401747787,
                                57.71945892955415
                            ],
                            [
                                11.9628070556345,
                                57.72025376185661
                            ],
                            [
                                11.961384139565979,
                                57.720604215248244
                            ],
                            [
                                11.959679114607269,
                                57.720843430887214
                            ],
                            [
                                11.958696557851908,
                                57.720673665112486
                            ],
                            [
                                11.957887393464574,
                                57.71990199246514
                            ],
                            [
                                11.955893381225906,
                                57.71943126407078
                            ],
                            [
                                11.952926811571928,
                                57.71771568379444
                            ],
                            [
                                11.948042590329123,
                                57.71492191594109
                            ],
                            [
                                11.945802938901323,
                                57.713524954422326
                            ],
                            [
                                11.943201628020176,
                                57.712598756685225
                            ],
                            [
                                11.941496603062632,
                                57.71216652561927
                            ],
                            [
                                11.931699926232994,
                                57.711549071647056
                            ],
                            [
                                11.931208647498352,
                                57.711240326654774
                            ],
                            [
                                11.92995155261832,
                                57.71115542130073
                            ],
                            [
                                11.926555951927753,
                                57.70996672602368
                            ],
                            [
                                11.923825001923916,
                                57.70924884594521
                            ],
                            [
                                11.922062179509226,
                                57.70952673175873
                            ],
                            [
                                11.921657597316084,
                                57.71017512369602
                            ],
                            [
                                11.916036794453248,
                                57.71196586014068
                            ],
                            [
                                11.91300243211245,
                                57.7122823277013
                            ],
                            [
                                11.908537578620866,
                                57.71208936635489
                            ],
                            [
                                11.904306242837464,
                                57.71159007586044
                            ],
                            [
                                11.89849759848829,
                                57.7105094584131
                            ],
                            [
                                11.893951197123812,
                                57.70847115071163
                            ],
                            [
                                11.89224617216621,
                                57.70728236734681
                            ],
                            [
                                11.891966918352551,
                                57.70529486800427
                            ],
                            [
                                11.894249917872344,
                                57.70430670438981
                            ],
                            [
                                11.895624292225591,
                                57.70101595307037
                            ],
                            [
                                11.898774253588783,
                                57.69974971398716
                            ],
                            [
                                11.904095445189597,
                                57.69382259563059
                            ],
                            [
                                11.902230958764818,
                                57.6923783830068
                            ],
                            [
                                11.904904091114872,
                                57.687350571969574
                            ],
                            [
                                11.905771052957022,
                                57.687273334408616
                            ],
                            [
                                11.906941451445505,
                                57.687590007362104
                            ],
                            [
                                11.907663919648883,
                                57.68815383284459
                            ],
                            [
                                11.908603128312194,
                                57.688794884037236
                            ],
                            [
                                11.90986022298469,
                                57.68955949653315
                            ],
                            [
                                11.911146216385134,
                                57.69036270844455
                            ],
                            [
                                11.911695292219378,
                                57.690764307723754
                            ],
                            [
                                11.912822325020898,
                                57.69162926978146
                            ],
                            [
                                11.913024616118008,
                                57.691915013582815
                            ],
                            [
                                11.913920476688219,
                                57.692347486401616
                            ],
                            [
                                11.915654400374876,
                                57.69332053136969
                            ],
                            [
                                11.916579159674228,
                                57.69396149115869
                            ],
                            [
                                11.917770690947862,
                                57.69469660295587
                            ],
                            [
                                11.917626197307897,
                                57.695005488923044
                            ],
                            [
                                11.917828488403842,
                                57.69539159267822
                            ],
                            [
                                11.919114481804314,
                                57.695715916652716
                            ],
                            [
                                11.920270430928753,
                                57.69632594673794
                            ],
                            [
                                11.921397481325243,
                                57.6965807663899
                            ],
                            [
                                11.922799069637676,
                                57.696920523137635
                            ],
                            [
                                11.924171756253713,
                                57.69715989448065
                            ],
                            [
                                11.925977926760424,
                                57.69763863405322
                            ],
                            [
                                11.927856344087132,
                                57.69834128874166
                            ],
                            [
                                11.92886780141626,
                                57.69892039367019
                            ],
                            [
                                11.929171238061372,
                                57.69924468605254
                            ],
                            [
                                11.930673971922943,
                                57.699646186884365
                            ],
                            [
                                11.932061110872553,
                                57.69993958852373
                            ],
                            [
                                11.93289917401276,
                                57.7000090780397
                            ],
                            [
                                11.934878737142895,
                                57.70010173255375
                            ],
                            [
                                11.936540414009613,
                                57.70022526882232
                            ],
                            [
                                11.938577774341411,
                                57.700449177234674
                            ],
                            [
                                11.94104861559427,
                                57.700541828586694
                            ],
                            [
                                11.944126330137408,
                                57.70081206034334
                            ],
                            [
                                11.949251848314901,
                                57.70150805935285
                            ],
                            [
                                11.950234405070233,
                                57.70171651846738
                            ],
                            [
                                11.95108691754956,
                                57.701824607906104
                            ],
                            [
                                11.951404803558717,
                                57.70176284255194
                            ],
                            [
                                11.953586657530536,
                                57.701932697022244
                            ],
                            [
                                11.955306131853177,
                                57.702272403573204
                            ],
                            [
                                11.956982258082775,
                                57.70274335511195
                            ],
                            [
                                11.95883660452509,
                                57.703469593733615
                            ],
                            [
                                11.960209294109546,
                                57.704226176408184
                            ],
                            [
                                11.96107625595289,
                                57.705029063184185
                            ],
                            [
                                11.961885420340224,
                                57.70592456974248
                            ],
                            [
                                11.962454249391925,
                                57.70752167850833
                            ],
                            [
                                11.963102527274373,
                                57.70949778202774
                            ],
                            [
                                11.964208413075852,
                                57.710679318177995
                            ],
                            [
                                11.966534586657701,
                                57.7114534071635
                            ],
                            [
                                11.969661574094062,
                                57.7125126600084
                            ],
                            [
                                11.975191003099809,
                                57.71467181021643
                            ]
                        ],
                        [
                            [
                                11.90584762107315,
                                57.685156661360395
                            ],
                            [
                                11.905675107474337,
                                57.68419200087422
                            ],
                            [
                                11.905450933903381,
                                57.68261970296405
                            ],
                            [
                                11.907242666862345,
                                57.68261970296405
                            ],
                            [
                                11.907664251089471,
                                57.68204685097675
                            ],
                            [
                                11.907031874750174,
                                57.68169937896681
                            ],
                            [
                                11.905257707799223,
                                57.68096685949186
                            ],
                            [
                                11.905591461978162,
                                57.68049728794003
                            ],
                            [
                                11.906803516627292,
                                57.68044093894483
                            ],
                            [
                                11.909473550081799,
                                57.68037519834681
                            ],
                            [
                                11.911951927382347,
                                57.67989729498322
                            ],
                            [
                                11.91318154804219,
                                57.678939334298605
                            ],
                            [
                                11.914059848512295,
                                57.67762444507986
                            ],
                            [
                                11.916150203632895,
                                57.67664763935733
                            ],
                            [
                                11.917502786356806,
                                57.67675095659186
                            ],
                            [
                                11.917906804573164,
                                57.67614983400932
                            ],
                            [
                                11.921339705309094,
                                57.67516359698456
                            ],
                            [
                                11.923658418551213,
                                57.675191775594726
                            ],
                            [
                                11.927470242593387,
                                57.675896233732885
                            ],
                            [
                                11.932125238277052,
                                57.6771266596063
                            ],
                            [
                                11.934883101754252,
                                57.67772776599219
                            ],
                            [
                                11.934707441660777,
                                57.67625315912434
                            ],
                            [
                                11.935251987951887,
                                57.675257530759524
                            ],
                            [
                                11.936191263896575,
                                57.67402530198285
                            ],
                            [
                                11.93777220474334,
                                57.67308597295212
                            ],
                            [
                                11.939739597798479,
                                57.67235327941489
                            ],
                            [
                                11.96972502898953,
                                57.67749995418194
                            ],
                            [
                                11.9715694599779,
                                57.67740603137588
                            ],
                            [
                                11.998656227365274,
                                57.678579438370406
                            ],
                            [
                                12.000500658353701,
                                57.67718000503402
                            ],
                            [
                                12.003381483897698,
                                57.67764961955879
                            ],
                            [
                                11.999062769924421,
                                57.68410009312478
                            ],
                            [
                                12.00038022062958,
                                57.684381808782234
                            ],
                            [
                                11.997788562087408,
                                57.68768182284765
                            ],
                            [
                                11.996031961145775,
                                57.689963431196674
                            ],
                            [
                                11.994954082351228,
                                57.69183343428665
                            ],
                            [
                                11.994567630144218,
                                57.69285677228902
                            ],
                            [
                                11.994514932116147,
                                57.69335434821326
                            ],
                            [
                                11.994848686293722,
                                57.69434009165755
                            ],
                            [
                                11.994918950332504,
                                57.69515683019358
                            ],
                            [
                                11.99407578975243,
                                57.69678024792529
                            ],
                            [
                                11.993074527215441,
                                57.69713696253382
                            ],
                            [
                                11.992512414914955,
                                57.697643866724775
                            ],
                            [
                                11.994883826185117,
                                57.69787854218907
                            ],
                            [
                                11.994602770034163,
                                57.69877029508723
                            ],
                            [
                                11.994514939877945,
                                57.700037485133095
                            ],
                            [
                                11.99430812880047,
                                57.702300211962864
                            ],
                            [
                                11.99430812880047,
                                57.703501579852116
                            ],
                            [
                                11.994936483466489,
                                57.70519393644864
                            ],
                            [
                                11.994690559335623,
                                57.70607612438695
                            ],
                            [
                                11.994901351447794,
                                57.70686444436146
                            ],
                            [
                                11.995410765720237,
                                57.70726798246926
                            ],
                            [
                                11.996392924023723,
                                57.711009640469285
                            ],
                            [
                                11.99595377378867,
                                57.71167586888444
                            ],
                            [
                                11.994109342800328,
                                57.712698646396404
                            ],
                            [
                                11.993670192565247,
                                57.71343990745129
                            ],
                            [
                                11.992300043830653,
                                57.71337422670473
                            ],
                            [
                                11.99170279951008,
                                57.71399349757897
                            ],
                            [
                                11.989436784296004,
                                57.712407767659215
                            ],
                            [
                                11.985115554056136,
                                57.71017448556171
                            ],
                            [
                                11.983745405322964,
                                57.71054045197394
                            ],
                            [
                                11.981865842315926,
                                57.710268323456035
                            ],
                            [
                                11.979389034988287,
                                57.71155387811888
                            ],
                            [
                                11.978296988581747,
                                57.71265035922576
                            ],
                            [
                                11.976645783696199,
                                57.71294123601419
                            ],
                            [
                                11.976030973367699,
                                57.7134854508252
                            ],
                            [
                                11.973975750265765,
                                57.712687891845775
                            ],
                            [
                                11.971569206976909,
                                57.711918465355296
                            ],
                            [
                                11.969513983874975,
                                57.711205323762755
                            ],
                            [
                                11.967985752548941,
                                57.71070798866754
                            ],
                            [
                                11.96691422597462,
                                57.71059538453267
                            ],
                            [
                                11.965596775268097,
                                57.710154348298545
                            ],
                            [
                                11.96494683291948,
                                57.709741458444796
                            ],
                            [
                                11.964384720618966,
                                57.70875613408697
                            ],
                            [
                                11.963699646251655,
                                57.707451711074725
                            ],
                            [
                                11.963241584825312,
                                57.706484367935104
                            ],
                            [
                                11.962661906514057,
                                57.70539571964889
                            ],
                            [
                                11.961941700128051,
                                57.70465429389202
                            ],
                            [
                                11.961151229704711,
                                57.70393162379966
                            ],
                            [
                                11.960044584398872,
                                57.7033497238306
                            ],
                            [
                                11.958727133693657,
                                57.7027396566215
                            ],
                            [
                                11.95679487265852,
                                57.702120193248135
                            ],
                            [
                                11.95537202589594,
                                57.70170721177959
                            ],
                            [
                                11.95356272692618,
                                57.701425630805545
                            ],
                            [
                                11.9519993569848,
                                57.701068962953144
                            ],
                            [
                                11.95122645257078,
                                57.70087185302779
                            ],
                            [
                                11.950383284119312,
                                57.70088123923904
                            ],
                            [
                                11.947274100453825,
                                57.70070290080969
                            ],
                            [
                                11.944463538947247,
                                57.700458857324946
                            ],
                            [
                                11.942478579884096,
                                57.700167880252025
                            ],
                            [
                                11.939388934226542,
                                57.700102392786704
                            ],
                            [
                                11.936613503673215,
                                57.69980202623782
                            ],
                            [
                                11.933574583534664,
                                57.699604909355486
                            ],
                            [
                                11.931940944658464,
                                57.699191899206426
                            ],
                            [
                                11.930658641350448,
                                57.69856298560157
                            ],
                            [
                                11.928638550267237,
                                57.69793406381896
                            ],
                            [
                                11.92624957250652,
                                57.69722064667599
                            ],
                            [
                                11.924264613442006,
                                57.696713736562714
                            ],
                            [
                                11.922032393797224,
                                57.69621548213661
                            ],
                            [
                                11.92059198102524,
                                57.695605294755325
                            ],
                            [
                                11.918835380083607,
                                57.69458203438583
                            ],
                            [
                                11.916973382816423,
                                57.6934460875363
                            ],
                            [
                                11.915768946656158,
                                57.69240476403297
                            ],
                            [
                                11.914996042242109,
                                57.691681849514595
                            ],
                            [
                                11.912958385150887,
                                57.69065847832738
                            ],
                            [
                                11.91095586007711,
                                57.68955057601903
                            ],
                            [
                                11.909150030842795,
                                57.68819898588424
                            ],
                            [
                                11.907621788024102,
                                57.68699711041887
                            ],
                            [
                                11.90647999741239,
                                57.68593604660592
                            ],
                            [
                                11.90584762107315,
                                57.685156661360395
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "se--02",
            "properties": {
                "land": "se",
                "city": "Helsingborg",
                "zone": "Milieuzone Klass 1",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck)",
                "minEUd": 6,
                "petrol": "min. EURO-6 (truck)",
                "minEUb": 6,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-se--lez2--white",
                "color": 0,
                "urlZone": "https://foretagare.helsingborg.se/regler-tillstand-och-anmalan/transporter-tungtrafik-med-mera/miljozon-i-helsingborg/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            12.686161994934082,
                            56.049776519071365
                        ],
                        [
                            12.688071727752686,
                            56.04706814874455
                        ],
                        [
                            12.68972396850586,
                            56.044641239472824
                        ],
                        [
                            12.692867517471313,
                            56.04552812772268
                        ],
                        [
                            12.695356607437134,
                            56.043412745719195
                        ],
                        [
                            12.696332931518555,
                            56.04240595094294
                        ],
                        [
                            12.697770595550537,
                            56.04069793543307
                        ],
                        [
                            12.701482772827148,
                            56.03662236494934
                        ],
                        [
                            12.702770233154297,
                            56.03670028430317
                        ],
                        [
                            12.70370364189148,
                            56.03590310194714
                        ],
                        [
                            12.706589698791502,
                            56.03643655662461
                        ],
                        [
                            12.707372903823853,
                            56.035441567793754
                        ],
                        [
                            12.708252668380737,
                            56.03546554372983
                        ],
                        [
                            12.708553075790405,
                            56.03573527198451
                        ],
                        [
                            12.710334062576294,
                            56.03635264289438
                        ],
                        [
                            12.710773944854736,
                            56.03749145652581
                        ],
                        [
                            12.712855339050293,
                            56.0377791514945
                        ],
                        [
                            12.713563442230225,
                            56.03814476075587
                        ],
                        [
                            12.71391749382019,
                            56.03855831459795
                        ],
                        [
                            12.712855339050293,
                            56.039145673307196
                        ],
                        [
                            12.711868286132812,
                            56.04033235036066
                        ],
                        [
                            12.710216045379639,
                            56.04258573765118
                        ],
                        [
                            12.70841360092163,
                            56.04531839247718
                        ],
                        [
                            12.706825733184814,
                            56.04818267814226
                        ],
                        [
                            12.706117630004883,
                            56.04926122432058
                        ],
                        [
                            12.705752849578857,
                            56.050159989767366
                        ],
                        [
                            12.705345153808594,
                            56.05107071739192
                        ],
                        [
                            12.70470142364502,
                            56.052173148378564
                        ],
                        [
                            12.704315185546875,
                            56.05375484214919
                        ],
                        [
                            12.704293727874756,
                            56.05437791582551
                        ],
                        [
                            12.704637050628662,
                            56.05504890698909
                        ],
                        [
                            12.703585624694822,
                            56.05530052566573
                        ],
                        [
                            12.702748775482178,
                            56.05554016097438
                        ],
                        [
                            12.701869010925293,
                            56.05607933497471
                        ],
                        [
                            12.700860500335693,
                            56.05669038972908
                        ],
                        [
                            12.697534561157227,
                            56.054941069910825
                        ],
                        [
                            12.695002555847168,
                            56.053539160454456
                        ],
                        [
                            12.694079875946045,
                            56.05304788098058
                        ],
                        [
                            12.69193410873413,
                            56.052580560551746
                        ],
                        [
                            12.69144058227539,
                            56.05228099319617
                        ],
                        [
                            12.691054344177246,
                            56.05113063292917
                        ],
                        [
                            12.689530849456785,
                            56.050507506792755
                        ],
                        [
                            12.686161994934082,
                            56.049776519071365
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "se--03",
            "properties": {
                "land": "se",
                "city": "Lund",
                "zone": "Milieuzone Klass 1",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck)",
                "minEUd": 6,
                "petrol": "min. EURO-6 (truck)",
                "minEUb": 6,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-se--lez2--white",
                "color": 0,
                "urlZone": "https://lund.se/foretag-naringsliv-och-forening/for-dig-som-har-verksamhet-eller-foretag/anvanda-offentlig-plats-och-gator/varutransporter-miljozon",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            13.188410997390747,
                            55.695395971723684
                        ],
                        [
                            13.189398050308228,
                            55.69526899101123
                        ],
                        [
                            13.192273378372192,
                            55.69503316859385
                        ],
                        [
                            13.195159435272217,
                            55.69517224352494
                        ],
                        [
                            13.198453187942503,
                            55.69566807186137
                        ],
                        [
                            13.202004432678223,
                            55.696260638940885
                        ],
                        [
                            13.20227265357971,
                            55.6963755233555
                        ],
                        [
                            13.203184604644775,
                            55.696405756040065
                        ],
                        [
                            13.206167221069336,
                            55.696804825284964
                        ],
                        [
                            13.20930004119873,
                            55.69681087175737
                        ],
                        [
                            13.21004033088684,
                            55.69685924350272
                        ],
                        [
                            13.210136890411377,
                            55.69740342151334
                        ],
                        [
                            13.2105553150177,
                            55.69938658392573
                        ],
                        [
                            13.211284875869751,
                            55.701200363884396
                        ],
                        [
                            13.211317062377928,
                            55.70302615069463
                        ],
                        [
                            13.210705518722534,
                            55.704229188760834
                        ],
                        [
                            13.209289312362671,
                            55.7059218429237
                        ],
                        [
                            13.207036256790161,
                            55.70825516738589
                        ],
                        [
                            13.206177949905394,
                            55.70935528757862
                        ],
                        [
                            13.20558786392212,
                            55.710727372101125
                        ],
                        [
                            13.205212354660034,
                            55.711912040677916
                        ],
                        [
                            13.204632997512817,
                            55.71344117710625
                        ],
                        [
                            13.203849792480469,
                            55.714420276508996
                        ],
                        [
                            13.202433586120605,
                            55.71535704617517
                        ],
                        [
                            13.199536800384521,
                            55.714523020085345
                        ],
                        [
                            13.199021816253662,
                            55.71433566394909
                        ],
                        [
                            13.198206424713133,
                            55.71391259840049
                        ],
                        [
                            13.196725845336912,
                            55.712818650526955
                        ],
                        [
                            13.195052146911621,
                            55.71203896732338
                        ],
                        [
                            13.193871974945068,
                            55.71189995240448
                        ],
                        [
                            13.191694021224976,
                            55.711452683657136
                        ],
                        [
                            13.191168308258057,
                            55.71129553396272
                        ],
                        [
                            13.187906742095947,
                            55.71137410888895
                        ],
                        [
                            13.185675144195557,
                            55.71135597622768
                        ],
                        [
                            13.1820809841156,
                            55.71115651639838
                        ],
                        [
                            13.180299997329712,
                            55.71103563115776
                        ],
                        [
                            13.178948163986206,
                            55.70923439676467
                        ],
                        [
                            13.178250789642334,
                            55.70824307798094
                        ],
                        [
                            13.178025484085081,
                            55.70738472066833
                        ],
                        [
                            13.177767992019653,
                            55.707106657213586
                        ],
                        [
                            13.177478313446045,
                            55.70688904095566
                        ],
                        [
                            13.17733883857727,
                            55.70626640996779
                        ],
                        [
                            13.177199363708494,
                            55.704398457476465
                        ],
                        [
                            13.177231550216675,
                            55.70315310620831
                        ],
                        [
                            13.177628517150879,
                            55.70300196864526
                        ],
                        [
                            13.177735805511473,
                            55.70270573732614
                        ],
                        [
                            13.177274465560913,
                            55.702397412547974
                        ],
                        [
                            13.177489042282103,
                            55.70097666890659
                        ],
                        [
                            13.178143501281737,
                            55.70026325474894
                        ],
                        [
                            13.179892301559448,
                            55.69906009459404
                        ],
                        [
                            13.181898593902588,
                            55.69798991599777
                        ],
                        [
                            13.184258937835693,
                            55.69688947581315
                        ],
                        [
                            13.186930418014526,
                            55.695692258448624
                        ],
                        [
                            13.188410997390747,
                            55.695395971723684
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "se--04",
            "properties": {
                "land": "se",
                "city": "Malmö",
                "zone": "Milieuzone Klass 1",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck)",
                "minEUd": 6,
                "petrol": "min. EURO-6 (truck)",
                "minEUb": 6,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-se--lez2--white",
                "color": 0,
                "urlZone": "https://malmo.se/Bo-och-leva/Stadsmiljo-och-trafik/Trafik/Miljozon.html",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            12.930715084075928,
                            55.5856702475053
                        ],
                        [
                            12.930650711059569,
                            55.583487272016505
                        ],
                        [
                            12.930328845977783,
                            55.582213813584715
                        ],
                        [
                            12.929213047027586,
                            55.579108827503426
                        ],
                        [
                            12.92841911315918,
                            55.57807782078843
                        ],
                        [
                            12.926809787750244,
                            55.576767796732376
                        ],
                        [
                            12.926487922668457,
                            55.576076377525716
                        ],
                        [
                            12.926831245422362,
                            55.5750331605927
                        ],
                        [
                            12.927968502044678,
                            55.57426892594889
                        ],
                        [
                            12.93060779571533,
                            55.57383221376029
                        ],
                        [
                            12.935779094696045,
                            55.573444021070976
                        ],
                        [
                            12.937195301055908,
                            55.57303156213396
                        ],
                        [
                            12.940049171447754,
                            55.57155152675772
                        ],
                        [
                            12.941679954528809,
                            55.571139047943966
                        ],
                        [
                            12.946572303771973,
                            55.57052032160046
                        ],
                        [
                            12.949490547180176,
                            55.57002290707988
                        ],
                        [
                            12.950778007507324,
                            55.569671073493204
                        ],
                        [
                            12.953481674194336,
                            55.56887033702983
                        ],
                        [
                            12.955412864685059,
                            55.56830010565701
                        ],
                        [
                            12.956807613372803,
                            55.56785119428424
                        ],
                        [
                            12.960669994354248,
                            55.566637894631775
                        ],
                        [
                            12.96210765838623,
                            55.56633456386279
                        ],
                        [
                            12.9643177986145,
                            55.566322430583305
                        ],
                        [
                            12.96760082244873,
                            55.56626176412966
                        ],
                        [
                            12.96837329864502,
                            55.5666985605045
                        ],
                        [
                            12.969553470611572,
                            55.56673495998317
                        ],
                        [
                            12.970111370086668,
                            55.56635883041049
                        ],
                        [
                            12.971570491790771,
                            55.566650027813814
                        ],
                        [
                            12.9738450050354,
                            55.56740227778085
                        ],
                        [
                            12.976012229919434,
                            55.56809384971744
                        ],
                        [
                            12.979359626770018,
                            55.568469962676325
                        ],
                        [
                            12.983436584472656,
                            55.56861555382253
                        ],
                        [
                            12.98560380935669,
                            55.56856702350042
                        ],
                        [
                            12.986955642700195,
                            55.56861555382253
                        ],
                        [
                            12.9878568649292,
                            55.568251574945116
                        ],
                        [
                            12.991204261779785,
                            55.56749934124333
                        ],
                        [
                            12.99583911895752,
                            55.56640736346096
                        ],
                        [
                            12.998929023742674,
                            55.56594629706045
                        ],
                        [
                            13.00055980682373,
                            55.56655296225258
                        ],
                        [
                            13.002490997314453,
                            55.56667429416666
                        ],
                        [
                            13.003864288330078,
                            55.566067630848444
                        ],
                        [
                            13.006181716918945,
                            55.5650241480238
                        ],
                        [
                            13.016223907470703,
                            55.56468440344815
                        ],
                        [
                            13.024077415466309,
                            55.565145484660164
                        ],
                        [
                            13.029913902282715,
                            55.56582496289767
                        ],
                        [
                            13.035192489624023,
                            55.56633456386279
                        ],
                        [
                            13.04171562194824,
                            55.56861555382253
                        ],
                        [
                            13.04403305053711,
                            55.570653773204896
                        ],
                        [
                            13.045878410339355,
                            55.5722066312637
                        ],
                        [
                            13.049225807189941,
                            55.57688909709225
                        ],
                        [
                            13.050985336303711,
                            55.57849022672323
                        ],
                        [
                            13.054075241088867,
                            55.58164377595006
                        ],
                        [
                            13.052315711975098,
                            55.58292938102626
                        ],
                        [
                            13.053174018859863,
                            55.583899621130854
                        ],
                        [
                            13.055191040039062,
                            55.584045155078215
                        ],
                        [
                            13.055577278137207,
                            55.58564599288903
                        ],
                        [
                            13.054590225219727,
                            55.58908999836338
                        ],
                        [
                            13.0537748336792,
                            55.59132116453702
                        ],
                        [
                            13.05274486541748,
                            55.59391594825428
                        ],
                        [
                            13.052659034729004,
                            55.596340824664324
                        ],
                        [
                            13.048624992370604,
                            55.597237990955946
                        ],
                        [
                            13.04600715637207,
                            55.598401851792
                        ],
                        [
                            13.043475151062012,
                            55.5995171860296
                        ],
                        [
                            13.038668632507324,
                            55.60060824318993
                        ],
                        [
                            13.038668632507324,
                            55.601602291074286
                        ],
                        [
                            13.03703784942627,
                            55.602038694384014
                        ],
                        [
                            13.03678035736084,
                            55.60405093572955
                        ],
                        [
                            13.032016754150389,
                            55.605772168700184
                        ],
                        [
                            13.030257225036621,
                            55.60552974609114
                        ],
                        [
                            13.028883934020996,
                            55.60586913732427
                        ],
                        [
                            13.02579402923584,
                            55.60797814558757
                        ],
                        [
                            13.02304744720459,
                            55.60802662720267
                        ],
                        [
                            13.020730018615723,
                            55.607905423052564
                        ],
                        [
                            13.018584251403809,
                            55.608535680542914
                        ],
                        [
                            13.016395568847654,
                            55.60902048710502
                        ],
                        [
                            13.016180992126465,
                            55.60969920622369
                        ],
                        [
                            13.010687828063965,
                            55.60972344597496
                        ],
                        [
                            13.002920150756836,
                            55.60887504576564
                        ],
                        [
                            12.998886108398436,
                            55.60822055306379
                        ],
                        [
                            12.997598648071289,
                            55.608681123140826
                        ],
                        [
                            12.9933500289917,
                            55.60802662720267
                        ],
                        [
                            12.984552383422852,
                            55.60696001782629
                        ],
                        [
                            12.984423637390137,
                            55.60584489519071
                        ],
                        [
                            12.979788780212402,
                            55.60424488124856
                        ],
                        [
                            12.977771759033203,
                            55.60349333702078
                        ],
                        [
                            12.974510192871092,
                            55.60279026648159
                        ],
                        [
                            12.96837329864502,
                            55.60085069621599
                        ],
                        [
                            12.961678504943848,
                            55.599056508340226
                        ],
                        [
                            12.957558631896973,
                            55.59733498067762
                        ],
                        [
                            12.955756187438965,
                            55.59597710275598
                        ],
                        [
                            12.948331832885742,
                            55.59287320532492
                        ],
                        [
                            12.944040298461914,
                            55.59078763633931
                        ],
                        [
                            12.94116497039795,
                            55.58925976546549
                        ],
                        [
                            12.937731742858887,
                            55.58753780795138
                        ],
                        [
                            12.933783531188965,
                            55.586228099542964
                        ],
                        [
                            12.930715084075928,
                            55.5856702475053
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "se",
                "city": "Mölndal",
                "zone": "Milieuzone Klass 1",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck)",
                "minEUd": 6,
                "petrol": "min. EURO-6 (truck)",
                "minEUb": 6,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-se--lez2--white",
                "color": 0,
                "urlZone": "https://www.molndal.se/startsida/trafik-och-resor/trafik-och-gator/miljozon.html",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                11.983036994934082,
                                57.64530880140177
                            ],
                            [
                                11.984624862670898,
                                57.6452054518684
                            ],
                            [
                                11.984968185424805,
                                57.64597482466922
                            ],
                            [
                                11.98556900024414,
                                57.64667528423048
                            ],
                            [
                                11.986920833587646,
                                57.64636524642002
                            ],
                            [
                                11.987886428833008,
                                57.64530880140177
                            ],
                            [
                                11.98833703994751,
                                57.64491836828727
                            ],
                            [
                                11.996297836303711,
                                57.644022652920626
                            ],
                            [
                                12.000439167022703,
                                57.643620722174475
                            ],
                            [
                                12.003443241119383,
                                57.643643689765575
                            ],
                            [
                                12.006726264953613,
                                57.64391929972525
                            ],
                            [
                                12.010030746459961,
                                57.64456238149329
                            ],
                            [
                                12.014257907867432,
                                57.645630331401506
                            ],
                            [
                                12.016618251800537,
                                57.64651452421491
                            ],
                            [
                                12.017412185668945,
                                57.64759389923161
                            ],
                            [
                                12.017669677734375,
                                57.64850100872961
                            ],
                            [
                                12.017862796783447,
                                57.64941957752224
                            ],
                            [
                                12.019364833831787,
                                57.64938513161208
                            ],
                            [
                                12.020587921142578,
                                57.64900622444265
                            ],
                            [
                                12.022755146026611,
                                57.64889140330725
                            ],
                            [
                                12.025072574615479,
                                57.645882960117504
                            ],
                            [
                                12.027175426483154,
                                57.64306949562848
                            ],
                            [
                                12.031102180480957,
                                57.638245906287516
                            ],
                            [
                                12.031638622283934,
                                57.63801619557075
                            ],
                            [
                                12.040221691131592,
                                57.63886611796341
                            ],
                            [
                                12.046916484832764,
                                57.63960116993409
                            ],
                            [
                                12.04700231552124,
                                57.641266467002566
                            ],
                            [
                                12.0465087890625,
                                57.64250677656221
                            ],
                            [
                                12.048590183258057,
                                57.64506765203283
                            ],
                            [
                                12.052602767944334,
                                57.64982144405826
                            ],
                            [
                                12.054190635681152,
                                57.64964921608774
                            ],
                            [
                                12.05745220184326,
                                57.65324287032746
                            ],
                            [
                                12.045328617095945,
                                57.656078505999524
                            ],
                            [
                                12.044706344604492,
                                57.66298872223108
                            ],
                            [
                                12.044341564178465,
                                57.665089075261946
                            ],
                            [
                                12.043311595916746,
                                57.66648924304744
                            ],
                            [
                                12.040629386901855,
                                57.6678664045313
                            ],
                            [
                                12.036638259887695,
                                57.66800411780349
                            ],
                            [
                                12.032475471496582,
                                57.6688648139067
                            ],
                            [
                                12.030801773071289,
                                57.668153306591556
                            ],
                            [
                                12.029793262481688,
                                57.6659383638112
                            ],
                            [
                                12.027883529663086,
                                57.66505464422561
                            ],
                            [
                                12.026145458221436,
                                57.66622528112418
                            ],
                            [
                                12.023742198944092,
                                57.6666843441038
                            ],
                            [
                                12.02080249786377,
                                57.666661391092816
                            ],
                            [
                                12.018849849700928,
                                57.66956483167386
                            ],
                            [
                                12.013142108917235,
                                57.67500385355786
                            ],
                            [
                                12.008635997772217,
                                57.67961605554552
                            ],
                            [
                                12.007155418395996,
                                57.679398079110534
                            ],
                            [
                                12.00756311416626,
                                57.678778559980955
                            ],
                            [
                                12.005138397216797,
                                57.678365541345876
                            ],
                            [
                                12.005267143249512,
                                57.67765422044354
                            ],
                            [
                                12.00007438659668,
                                57.677000251815706
                            ],
                            [
                                12.00005292892456,
                                57.67782631549014
                            ],
                            [
                                11.998293399810791,
                                57.6782967044492
                            ],
                            [
                                11.98728561401367,
                                57.67789515328003
                            ],
                            [
                                11.985290050506592,
                                57.67798693679656
                            ],
                            [
                                11.982285976409912,
                                57.67757390914224
                            ],
                            [
                                11.977779865264893,
                                57.677401812897415
                            ],
                            [
                                11.97160005569458,
                                57.6771264572064
                            ],
                            [
                                11.9696044921875,
                                57.67749359766297
                            ],
                            [
                                11.967737674713135,
                                57.6753365942352
                            ],
                            [
                                11.96780204772949,
                                57.67486616686709
                            ],
                            [
                                11.968724727630615,
                                57.674372785264715
                            ],
                            [
                                11.966085433959961,
                                57.672800803758946
                            ],
                            [
                                11.96932554244995,
                                57.6708156494486
                            ],
                            [
                                11.959133148193358,
                                57.667659833642524
                            ],
                            [
                                11.960291862487793,
                                57.6668794441106
                            ],
                            [
                                11.949090957641602,
                                57.66367737597038
                            ],
                            [
                                11.954305171966553,
                                57.65596370724017
                            ],
                            [
                                11.955957412719727,
                                57.654987903120386
                            ],
                            [
                                11.95585012435913,
                                57.65327731257599
                            ],
                            [
                                11.953468322753906,
                                57.652783637223
                            ],
                            [
                                11.954476833343506,
                                57.65059071901453
                            ],
                            [
                                11.955313682556152,
                                57.65007404360323
                            ],
                            [
                                11.957030296325684,
                                57.6500510800809
                            ],
                            [
                                11.957416534423828,
                                57.649626252296585
                            ],
                            [
                                11.960806846618652,
                                57.64877658180856
                            ],
                            [
                                11.960935592651367,
                                57.64628486581463
                            ],
                            [
                                11.9716215133667,
                                57.645986307721806
                            ],
                            [
                                11.978745460510254,
                                57.6456418145631
                            ],
                            [
                                11.983036994934082,
                                57.64530880140177
                            ]
                        ],
                        [
                            [
                                11.983036994934082,
                                57.64455089799021
                            ],
                            [
                                11.981556415557861,
                                57.64251826071199
                            ],
                            [
                                11.982715129852295,
                                57.64097935226812
                            ],
                            [
                                11.985890865325926,
                                57.640106509531286
                            ],
                            [
                                11.986448764801025,
                                57.63906136759462
                            ],
                            [
                                11.989023685455322,
                                57.639118793756886
                            ],
                            [
                                11.99028968811035,
                                57.64024432820067
                            ],
                            [
                                11.997971534729004,
                                57.639463348823156
                            ],
                            [
                                12.004988193511963,
                                57.638533042639715
                            ],
                            [
                                12.007284164428711,
                                57.636465610218394
                            ],
                            [
                                12.012026309967041,
                                57.636143999039064
                            ],
                            [
                                12.015953063964844,
                                57.6349609048304
                            ],
                            [
                                12.019901275634766,
                                57.63483455268525
                            ],
                            [
                                12.02129602432251,
                                57.6374189409055
                            ],
                            [
                                12.024343013763428,
                                57.63744191242044
                            ],
                            [
                                12.025566101074219,
                                57.63609805435232
                            ],
                            [
                                12.030887603759766,
                                57.63457036041558
                            ],
                            [
                                12.03226089477539,
                                57.63490347209164
                            ],
                            [
                                12.028677463531492,
                                57.638992456079976
                            ],
                            [
                                12.025930881500244,
                                57.642035923293534
                            ],
                            [
                                12.02303409576416,
                                57.64470018324706
                            ],
                            [
                                12.021617889404297,
                                57.64573367972512
                            ],
                            [
                                12.019214630126953,
                                57.64576812910094
                            ],
                            [
                                12.018120288848877,
                                57.645400667406605
                            ],
                            [
                                12.017819881439209,
                                57.644275292826045
                            ],
                            [
                                12.019472122192381,
                                57.64149615715522
                            ],
                            [
                                12.015566825866697,
                                57.64095638299127
                            ],
                            [
                                12.014687061309814,
                                57.64232302967178
                            ],
                            [
                                12.010889053344727,
                                57.64176029903414
                            ],
                            [
                                12.007455825805664,
                                57.641565063917604
                            ],
                            [
                                12.006511688232422,
                                57.64319581911793
                            ],
                            [
                                12.001919746398924,
                                57.642874267552884
                            ],
                            [
                                11.996426582336426,
                                57.64329917437324
                            ],
                            [
                                11.989753246307371,
                                57.64399968556931
                            ],
                            [
                                11.983036994934082,
                                57.64455089799021
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "se--06",
            "properties": {
                "land": "se",
                "city": "Stockholm",
                "zone": "Milieuzone Klass 1",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck)",
                "minEUd": 6,
                "petrol": "min. EURO-6 (truck)",
                "minEUb": 6,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-se--lez2--white",
                "color": 0,
                "urlZone": "https://tillstand.stockholm/tillstand-regler-och-tillsyn/transporter/miljozon--krav-pa-tunga-fordon/",
                "urlPark": null,
                "urlReg": "https://www.transportstyrelsen.se/sv/vagtrafik/",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                18.043370246887207,
                                59.33640691989551
                            ],
                            [
                                18.04875612258911,
                                59.33552049807076
                            ],
                            [
                                18.05128812789917,
                                59.333900803390506
                            ],
                            [
                                18.054335117340088,
                                59.33345209052921
                            ],
                            [
                                18.056931495666504,
                                59.33124127234344
                            ],
                            [
                                18.05431365966797,
                                59.330672127547096
                            ],
                            [
                                18.055107593536377,
                                59.32988406516725
                            ],
                            [
                                18.05699586868286,
                                59.329150712923926
                            ],
                            [
                                18.05924892425537,
                                59.329555700447926
                            ],
                            [
                                18.064119815826416,
                                59.32644702389525
                            ],
                            [
                                18.065879344940186,
                                59.325286670328985
                            ],
                            [
                                18.066909313201904,
                                59.32390733059065
                            ],
                            [
                                18.068861961364746,
                                59.321203224382586
                            ],
                            [
                                18.069570064544678,
                                59.32002079455482
                            ],
                            [
                                18.071608543395996,
                                59.31577246658226
                            ],
                            [
                                18.07304620742798,
                                59.31587101628694
                            ],
                            [
                                18.07697296142578,
                                59.30809566190411
                            ],
                            [
                                18.078346252441406,
                                59.306178928188345
                            ],
                            [
                                18.080255985260006,
                                59.303703443668994
                            ],
                            [
                                18.086049556732178,
                                59.304897394536
                            ],
                            [
                                18.095276355743408,
                                59.306923728978724
                            ],
                            [
                                18.09943914413452,
                                59.308632327987006
                            ],
                            [
                                18.102142810821533,
                                59.31203831731022
                            ],
                            [
                                18.104288578033447,
                                59.31348384309691
                            ],
                            [
                                18.10323715209961,
                                59.31375760999865
                            ],
                            [
                                18.09971809387207,
                                59.3138123631145
                            ],
                            [
                                18.096821308135986,
                                59.31444749281466
                            ],
                            [
                                18.09473991394043,
                                59.31513736163289
                            ],
                            [
                                18.094160556793213,
                                59.316341860933264
                            ],
                            [
                                18.091542720794678,
                                59.31691124574818
                            ],
                            [
                                18.086607456207275,
                                59.317327328620856
                            ],
                            [
                                18.08201551437378,
                                59.31794049409861
                            ],
                            [
                                18.076908588409424,
                                59.319046353875095
                            ],
                            [
                                18.076350688934326,
                                59.31941861540953
                            ],
                            [
                                18.073132038116455,
                                59.32027261176145
                            ],
                            [
                                18.073668479919434,
                                59.32077624057839
                            ],
                            [
                                18.081865310668945,
                                59.31845510658855
                            ],
                            [
                                18.089075088500977,
                                59.3175791657804
                            ],
                            [
                                18.09222936630249,
                                59.31720688410247
                            ],
                            [
                                18.094933032989502,
                                59.31663750423891
                            ],
                            [
                                18.095362186431885,
                                59.3158819662365
                            ],
                            [
                                18.09647798538208,
                                59.31511546125063
                            ],
                            [
                                18.099782466888428,
                                59.31430513718931
                            ],
                            [
                                18.103559017181396,
                                59.31416278096795
                            ],
                            [
                                18.10497522354126,
                                59.31395472080301
                            ],
                            [
                                18.11190605163574,
                                59.31830181857641
                            ],
                            [
                                18.142805099487305,
                                59.320754343828476
                            ],
                            [
                                18.156538009643555,
                                59.3199222568792
                            ],
                            [
                                18.160228729248047,
                                59.331044261762024
                            ],
                            [
                                18.151581287384033,
                                59.33840949145388
                            ],
                            [
                                18.136067390441895,
                                59.33602390095727
                            ],
                            [
                                18.131582736968994,
                                59.33822346519029
                            ],
                            [
                                18.128621578216553,
                                59.33839854876069
                            ],
                            [
                                18.125810623168945,
                                59.33896756413194
                            ],
                            [
                                18.1139874458313,
                                59.339263010661305
                            ],
                            [
                                18.11295747756958,
                                59.341932855343906
                            ],
                            [
                                18.113064765930176,
                                59.34256745968353
                            ],
                            [
                                18.113858699798584,
                                59.34452590520541
                            ],
                            [
                                18.109631538391113,
                                59.34674679893629
                            ],
                            [
                                18.097164630889893,
                                59.34962389847564
                            ],
                            [
                                18.087809085845947,
                                59.34709687730618
                            ],
                            [
                                18.081908226013184,
                                59.34541209204062
                            ],
                            [
                                18.08066368103027,
                                59.344908828280246
                            ],
                            [
                                18.0790114402771,
                                59.343322404574295
                            ],
                            [
                                18.076221942901608,
                                59.34397886475119
                            ],
                            [
                                18.0697900056839,
                                59.34561175440083
                            ],
                            [
                                18.066217303276062,
                                59.34683705386133
                            ],
                            [
                                18.058454990386963,
                                59.35022554246273
                            ],
                            [
                                18.05604636669159,
                                59.35099945973193
                            ],
                            [
                                18.054158091545105,
                                59.35082170644273
                            ],
                            [
                                18.05252730846405,
                                59.35047987057859
                            ],
                            [
                                18.050494194030758,
                                59.349812597055475
                            ],
                            [
                                18.049641251564026,
                                59.3496840633541
                            ],
                            [
                                18.048616647720337,
                                59.35013529654433
                            ],
                            [
                                18.045178055763245,
                                59.349886435344715
                            ],
                            [
                                18.043885231018066,
                                59.34941605534787
                            ],
                            [
                                18.040430545806885,
                                59.34851903357019
                            ],
                            [
                                18.02884340286255,
                                59.34386945560291
                            ],
                            [
                                18.026890754699707,
                                59.34311452287288
                            ],
                            [
                                18.025753498077393,
                                59.34255651832986
                            ],
                            [
                                18.026933670043945,
                                59.340444771080776
                            ],
                            [
                                18.028693199157715,
                                59.339284895487175
                            ],
                            [
                                18.032705783843994,
                                59.33823440793989
                            ],
                            [
                                18.037726879119873,
                                59.33716200172493
                            ],
                            [
                                18.043370246887207,
                                59.33640691989551
                            ]
                        ],
                        [
                            [
                                18.02581787109375,
                                59.34049948121547
                            ],
                            [
                                18.016955852508545,
                                59.33991954930379
                            ],
                            [
                                18.014745712280273,
                                59.340444771080776
                            ],
                            [
                                18.0161190032959,
                                59.339328665096616
                            ],
                            [
                                18.01408052444458,
                                59.33858457406541
                            ],
                            [
                                18.01734209060669,
                                59.33710728621436
                            ],
                            [
                                18.013651371002197,
                                59.335290681229424
                            ],
                            [
                                18.019487857818604,
                                59.33233575477231
                            ],
                            [
                                18.02058219909668,
                                59.33211686110703
                            ],
                            [
                                18.020753860473633,
                                59.33136166713642
                            ],
                            [
                                18.02204132080078,
                                59.33085819516403
                            ],
                            [
                                18.02086114883423,
                                59.329708937711835
                            ],
                            [
                                18.023006916046143,
                                59.32826410176475
                            ],
                            [
                                18.03079605102539,
                                59.32196959211664
                            ],
                            [
                                18.032619953155518,
                                59.31852080123933
                            ],
                            [
                                18.034422397613525,
                                59.31565201655523
                            ],
                            [
                                18.03483009338379,
                                59.314589847844005
                            ],
                            [
                                18.032727241516113,
                                59.31338528647266
                            ],
                            [
                                18.033907413482666,
                                59.308358519819194
                            ],
                            [
                                18.04103136062622,
                                59.30625559961104
                            ],
                            [
                                18.065128326416012,
                                59.303385779957786
                            ],
                            [
                                18.07727336883545,
                                59.30290380176525
                            ],
                            [
                                18.074140548706055,
                                59.30634322388244
                            ],
                            [
                                18.074140548706055,
                                59.3077232763799
                            ],
                            [
                                18.073668479919434,
                                59.30916898560114
                            ],
                            [
                                18.071136474609375,
                                59.31376856062886
                            ],
                            [
                                18.07051420211792,
                                59.315246863332646
                            ],
                            [
                                18.068110942840576,
                                59.32022881759866
                            ],
                            [
                                18.066523075103756,
                                59.32315195415469
                            ],
                            [
                                18.065063953399658,
                                59.325122466152756
                            ],
                            [
                                18.06300401687622,
                                59.32641418424474
                            ],
                            [
                                18.05851936340332,
                                59.32868004568299
                            ],
                            [
                                18.056352138519287,
                                59.328581533109855
                            ],
                            [
                                18.053686022758484,
                                59.32951739102398
                            ],
                            [
                                18.053154945373535,
                                59.33009202784826
                            ],
                            [
                                18.050537109375,
                                59.33277353787217
                            ],
                            [
                                18.0470609664917,
                                59.335214075270144
                            ],
                            [
                                18.03873538970947,
                                59.33652729638417
                            ],
                            [
                                18.026933670043945,
                                59.33888002392601
                            ],
                            [
                                18.02581787109375,
                                59.34049948121547
                            ]
                        ],
                        [
                            [
                                18.031139373779297,
                                59.31396567136976
                            ],
                            [
                                18.033435344696045,
                                59.31478695382371
                            ],
                            [
                                18.029916286468506,
                                59.321476929128295
                            ],
                            [
                                18.02603244781494,
                                59.324837843701204
                            ],
                            [
                                18.022148609161377,
                                59.32760733784361
                            ],
                            [
                                18.019165992736816,
                                59.328012343761905
                            ],
                            [
                                18.01856517791748,
                                59.32720232709833
                            ],
                            [
                                18.017449378967285,
                                59.32677541865493
                            ],
                            [
                                18.014917373657227,
                                59.32621714567531
                            ],
                            [
                                18.015389442443844,
                                59.32784815275516
                            ],
                            [
                                18.018457889556885,
                                59.32920544124976
                            ],
                            [
                                18.020474910736084,
                                59.33050794939188
                            ],
                            [
                                18.018844127655026,
                                59.33112087712388
                            ],
                            [
                                18.01131248474121,
                                59.33072685342256
                            ],
                            [
                                18.009424209594727,
                                59.32813274999598
                            ],
                            [
                                18.007535934448242,
                                59.32642513079844
                            ],
                            [
                                18.001720905303955,
                                59.32455321886604
                            ],
                            [
                                17.999467849731445,
                                59.323031530272786
                            ],
                            [
                                18.014488220214844,
                                59.317557266971036
                            ],
                            [
                                18.02281379699707,
                                59.31602431525982
                            ],
                            [
                                18.031139373779297,
                                59.31396567136976
                            ]
                        ],
                        [
                            [
                                18.012900352478027,
                                59.334929537337636
                            ],
                            [
                                18.010647296905518,
                                59.33456838960667
                            ],
                            [
                                18.01154851913452,
                                59.33173379379941
                            ],
                            [
                                18.018715381622314,
                                59.332040247991
                            ],
                            [
                                18.012900352478027,
                                59.334929537337636
                            ]
                        ],
                        [
                            [
                                18.01187038421631,
                                59.33961316618321
                            ],
                            [
                                18.01135540008545,
                                59.3363959765572
                            ],
                            [
                                18.01305055618286,
                                59.33565182128207
                            ],
                            [
                                18.01607608795166,
                                59.33708539998546
                            ],
                            [
                                18.013200759887695,
                                59.338420434143536
                            ],
                            [
                                18.014016151428223,
                                59.33907698907208
                            ],
                            [
                                18.01187038421631,
                                59.33961316618321
                            ]
                        ],
                        [
                            [
                                18.004467487335205,
                                59.33983201155134
                            ],
                            [
                                18.005647659301758,
                                59.339339607490174
                            ],
                            [
                                18.00985336303711,
                                59.34031346639793
                            ],
                            [
                                18.009896278381348,
                                59.340783972495345
                            ],
                            [
                                18.006699085235596,
                                59.340554191262044
                            ],
                            [
                                18.004467487335205,
                                59.33983201155134
                            ]
                        ],
                        [
                            [
                                18.0025577545166,
                                59.33895662161854
                            ],
                            [
                                17.99614191055298,
                                59.33726048942182
                            ],
                            [
                                17.99463987350464,
                                59.334119685562555
                            ],
                            [
                                17.99933910369873,
                                59.33362719869237
                            ],
                            [
                                18.009059429168698,
                                59.332018358497585
                            ],
                            [
                                18.008737564086914,
                                59.334995200148974
                            ],
                            [
                                18.009788990020752,
                                59.336242769450756
                            ],
                            [
                                18.0076003074646,
                                59.337293318590675
                            ],
                            [
                                18.00633430480957,
                                59.33677899130028
                            ],
                            [
                                18.0025577545166,
                                59.33895662161854
                            ]
                        ],
                        [
                            [
                                17.994017601013184,
                                59.3333535917908
                            ],
                            [
                                17.998180389404297,
                                59.32403869869185
                            ],
                            [
                                18.00607681274414,
                                59.32665500761151
                            ],
                            [
                                18.008265495300293,
                                59.328625316510944
                            ],
                            [
                                18.008995056152344,
                                59.33097859131438
                            ],
                            [
                                18.003673553466797,
                                59.33221536343095
                            ],
                            [
                                17.994017601013184,
                                59.3333535917908
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "se--07",
            "properties": {
                "land": "se",
                "city": "Stockholm",
                "zone": "Milieuzone Klass 2",
                "area_code": 1,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-5 (petrol)",
                "minEUb": 5,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "Hornsgatan Street",
                "img_sign": "sign-se--lez2--white",
                "color": 2,
                "urlZone": "https://trafik.stockholm/trafiksakerhet-trafikregler/miljozoner/miljozon-hornsgatan/",
                "urlPark": null,
                "urlReg": "https://www.transportstyrelsen.se/en/road/Congestion-taxes-in-Stockholm-and-Goteborg/",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            18.03452432155609,
                            59.315140099179686
                        ],
                        [
                            18.035119771957394,
                            59.31529340144825
                        ],
                        [
                            18.036476969718933,
                            59.31547681575416
                        ],
                        [
                            18.039175271987915,
                            59.31582995394473
                        ],
                        [
                            18.04229199886322,
                            59.31622415038299
                        ],
                        [
                            18.045494556427002,
                            59.31664297909071
                        ],
                        [
                            18.046653270721436,
                            59.316804486821766
                        ],
                        [
                            18.049866557121277,
                            59.31719319719836
                        ],
                        [
                            18.053364157676697,
                            59.31764486212392
                        ],
                        [
                            18.05598735809326,
                            59.31795965534158
                        ],
                        [
                            18.058873414993286,
                            59.31832371690617
                        ],
                        [
                            18.061335682868958,
                            59.31863302930735
                        ],
                        [
                            18.06328296661377,
                            59.318873907812105
                        ],
                        [
                            18.067864179611206,
                            59.319445987420295
                        ],
                        [
                            18.06776225566864,
                            59.31965948834773
                        ],
                        [
                            18.06668400764465,
                            59.319511680156175
                        ],
                        [
                            18.063213229179382,
                            59.31906551449476
                        ],
                        [
                            18.061228394508362,
                            59.31880547659228
                        ],
                        [
                            18.058685660362244,
                            59.31849616576016
                        ],
                        [
                            18.056459426879883,
                            59.318233386204945
                        ],
                        [
                            18.054839372634888,
                            59.31802535094981
                        ],
                        [
                            18.05172264575958,
                            59.317631175396166
                        ],
                        [
                            18.04942667484283,
                            59.317321853879285
                        ],
                        [
                            18.04651916027069,
                            59.31697420598353
                        ],
                        [
                            18.04579496383667,
                            59.316853760214066
                        ],
                        [
                            18.040688037872314,
                            59.3161954070472
                        ],
                        [
                            18.038566410541534,
                            59.31592576599947
                        ],
                        [
                            18.037118017673492,
                            59.31574509161358
                        ],
                        [
                            18.03714483976364,
                            59.31566844158404
                        ],
                        [
                            18.036364316940308,
                            59.31556852253608
                        ],
                        [
                            18.035232424736023,
                            59.315417274651374
                        ],
                        [
                            18.034454584121704,
                            59.31529613898248
                        ],
                        [
                            18.03452432155609,
                            59.315140099179686
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "se",
                "city": "Umea",
                "zone": "Milieuzone Klass 1",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck)",
                "minEUd": 6,
                "petrol": "min. EURO-6 (truck)",
                "minEUb": 6,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-se--lez2--white",
                "color": 0,
                "urlZone": "https://www.umea.se/byggaboochmiljo/boendemiljobullerochluftkvalitet/luftenutomhus/miljozonochgenomfartsforbudfortungtrafik.4.7d7d901172bb372c5d34b5.html",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                20.215144157409668,
                                63.82528224662954
                            ],
                            [
                                20.219478607177734,
                                63.82680610120229
                            ],
                            [
                                20.224006175994873,
                                63.82767683822149
                            ],
                            [
                                20.22411346435547,
                                63.828235231487604
                            ],
                            [
                                20.2288556098938,
                                63.82884093369645
                            ],
                            [
                                20.230700969696045,
                                63.82862326096497
                            ],
                            [
                                20.24024963378906,
                                63.82690074783472
                            ],
                            [
                                20.248897075653076,
                                63.82618142544989
                            ],
                            [
                                20.25406837463379,
                                63.82518759455724
                            ],
                            [
                                20.256450176239014,
                                63.827402369675724
                            ],
                            [
                                20.258359909057617,
                                63.82967375295933
                            ],
                            [
                                20.25956153869629,
                                63.83094186225166
                            ],
                            [
                                20.25881052017212,
                                63.83151911696688
                            ],
                            [
                                20.258917808532715,
                                63.83186924930616
                            ],
                            [
                                20.255398750305176,
                                63.83314672226491
                            ],
                            [
                                20.25207281112671,
                                63.83421596987855
                            ],
                            [
                                20.249004364013672,
                                63.835020244416796
                            ],
                            [
                                20.247631072998047,
                                63.83500132057399
                            ],
                            [
                                20.246858596801758,
                                63.83532302417151
                            ],
                            [
                                20.243511199951172,
                                63.835455489290084
                            ],
                            [
                                20.241494178771973,
                                63.83540818039074
                            ],
                            [
                                20.236451625823975,
                                63.835010782496994
                            ],
                            [
                                20.23430585861206,
                                63.83490670116917
                            ],
                            [
                                20.234413146972653,
                                63.8343011294468
                            ],
                            [
                                20.233469009399414,
                                63.833875329029354
                            ],
                            [
                                20.232868194580078,
                                63.83305209662625
                            ],
                            [
                                20.23078680038452,
                                63.8325127244124
                            ],
                            [
                                20.229949951171875,
                                63.831897638223914
                            ],
                            [
                                20.229005813598633,
                                63.83158535855424
                            ],
                            [
                                20.226988792419434,
                                63.83026049719424
                            ],
                            [
                                20.225958824157715,
                                63.83010908049773
                            ],
                            [
                                20.22531509399414,
                                63.82967375295933
                            ],
                            [
                                20.223255157470703,
                                63.82923841869014
                            ],
                            [
                                20.221924781799316,
                                63.82925734640702
                            ],
                            [
                                20.220165252685547,
                                63.82891664555641
                            ],
                            [
                                20.219264030456543,
                                63.82842451482751
                            ],
                            [
                                20.217676162719727,
                                63.8288977176105
                            ],
                            [
                                20.216431617736816,
                                63.828273088257355
                            ],
                            [
                                20.214757919311523,
                                63.82868950936574
                            ],
                            [
                                20.21256923675537,
                                63.82825415987884
                            ],
                            [
                                20.212783813476562,
                                63.827402369675724
                            ],
                            [
                                20.212011337280273,
                                63.82685342455827
                            ],
                            [
                                20.213770866394043,
                                63.8265316241693
                            ],
                            [
                                20.215144157409668,
                                63.82528224662954
                            ]
                        ],
                        [
                            [
                                20.255441665649414,
                                63.824903636431586
                            ],
                            [
                                20.26381015777588,
                                63.823001043132884
                            ],
                            [
                                20.26928186416626,
                                63.821552714315665
                            ],
                            [
                                20.27777910232544,
                                63.81952682227316
                            ],
                            [
                                20.285868644714355,
                                63.817226216788896
                            ],
                            [
                                20.289226770401,
                                63.81598589473224
                            ],
                            [
                                20.291855335235596,
                                63.81405429211049
                            ],
                            [
                                20.294108390808105,
                                63.814849673934006
                            ],
                            [
                                20.29348611831665,
                                63.81831500411974
                            ],
                            [
                                20.29322862625122,
                                63.81952682227316
                            ],
                            [
                                20.291919708251953,
                                63.8212497864528
                            ],
                            [
                                20.288507938385006,
                                63.82406121047873
                            ],
                            [
                                20.286319255828857,
                                63.82557566603197
                            ],
                            [
                                20.284409523010254,
                                63.82641804668343
                            ],
                            [
                                20.2851390838623,
                                63.82694807103165
                            ],
                            [
                                20.28428077697754,
                                63.82758219419755
                            ],
                            [
                                20.281641483306885,
                                63.83220991442978
                            ],
                            [
                                20.280890464782715,
                                63.83384694210586
                            ],
                            [
                                20.280611515045162,
                                63.83485939134746
                            ],
                            [
                                20.279409885406494,
                                63.83516217283231
                            ],
                            [
                                20.277564525604248,
                                63.83527571504952
                            ],
                            [
                                20.274066925048828,
                                63.83578664935937
                            ],
                            [
                                20.2707839012146,
                                63.8363827276663
                            ],
                            [
                                20.26829481124878,
                                63.835077015868876
                            ],
                            [
                                20.265119075775146,
                                63.83339274743694
                            ],
                            [
                                20.264732837677002,
                                63.83288176967512
                            ],
                            [
                                20.265226364135742,
                                63.83219098869772
                            ],
                            [
                                20.264153480529785,
                                63.83167998912296
                            ],
                            [
                                20.261449813842773,
                                63.83071474239802
                            ],
                            [
                                20.260205268859863,
                                63.830601181784125
                            ],
                            [
                                20.25902509689331,
                                63.829096460407975
                            ],
                            [
                                20.256128311157227,
                                63.82585015238269
                            ],
                            [
                                20.255441665649414,
                                63.824903636431586
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "se--09",
            "properties": {
                "land": "se",
                "city": "Uppsala",
                "zone": "Milieuzone Klass 1",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck)",
                "minEUd": 6,
                "petrol": "min. EURO-6 (truck)",
                "minEUb": 6,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-se--lez2--white",
                "color": 0,
                "urlZone": "https://www.uppsala.se/gator-och-trafik/utslapp-miljo-och-buller/miljozon/",
                "urlPark": null,
                "urlReg": null,
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            17.62730598449707,
                            59.8648041378598
                        ],
                        [
                            17.623475790023804,
                            59.86293233645931
                        ],
                        [
                            17.620879411697388,
                            59.86176072371097
                        ],
                        [
                            17.61903941631317,
                            59.86089612784158
                        ],
                        [
                            17.621206641197205,
                            59.859681346145955
                        ],
                        [
                            17.62307345867157,
                            59.85873858033415
                        ],
                        [
                            17.6247900724411,
                            59.85782811256286
                        ],
                        [
                            17.624983191490173,
                            59.85759914485329
                        ],
                        [
                            17.625374794006348,
                            59.85742405083568
                        ],
                        [
                            17.629623413085938,
                            59.85524742058434
                        ],
                        [
                            17.63256311416626,
                            59.85533362647066
                        ],
                        [
                            17.63402223587036,
                            59.855904734826325
                        ],
                        [
                            17.637884616851807,
                            59.85395430548737
                        ],
                        [
                            17.642068862915036,
                            59.85489181894237
                        ],
                        [
                            17.644407749176025,
                            59.853156859460434
                        ],
                        [
                            17.645652294158936,
                            59.85200376181554
                        ],
                        [
                            17.648656368255615,
                            59.853221517958346
                        ],
                        [
                            17.65279769897461,
                            59.85507500814162
                        ],
                        [
                            17.654407024383545,
                            59.856260325642964
                        ],
                        [
                            17.65631675720215,
                            59.85837224126814
                        ],
                        [
                            17.6540207862854,
                            59.85900794472088
                        ],
                        [
                            17.649836540222168,
                            59.86127051946572
                        ],
                        [
                            17.64481544494629,
                            59.8640392705904
                        ],
                        [
                            17.640373706817627,
                            59.86649540162671
                        ],
                        [
                            17.639107704162594,
                            59.866032197809176
                        ],
                        [
                            17.636876106262207,
                            59.86563362191864
                        ],
                        [
                            17.632927894592285,
                            59.86539662858417
                        ],
                        [
                            17.62932300567627,
                            59.86521349621454
                        ],
                        [
                            17.62730598449707,
                            59.8648041378598
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--01",
            "properties": {
                "land": "gb",
                "city": "Aberdeen",
                "zone": "LEZ",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 1.6.2024",
                "img_sign": "sign-scoot--white",
                "color": 1,
                "urlZone": "https://www.aberdeencity.gov.uk/services/roads-transport-and-parking/low-emission-zone",
                "urlPark": "https://www.aberdeencity.gov.uk/services/roads-transport-and-parking/public-transport/park-and-ride",
                "urlReg": "https://www.lowemissionzones.scot/",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -2.0945741477236766,
                            57.14419486681223
                        ],
                        [
                            -2.0941662223181083,
                            57.14435926846156
                        ],
                        [
                            -2.095232655878192,
                            57.14536779336353
                        ],
                        [
                            -2.0939680901513498,
                            57.14589892059689
                        ],
                        [
                            -2.093839885023556,
                            57.146252999242364
                        ],
                        [
                            -2.0912058524073984,
                            57.146467973910006
                        ],
                        [
                            -2.0920566682522406,
                            57.14711605174577
                        ],
                        [
                            -2.0911067828052694,
                            57.14741321925453
                        ],
                        [
                            -2.0898480415548875,
                            57.1476756064084
                        ],
                        [
                            -2.0894459436559885,
                            57.14782102499339
                        ],
                        [
                            -2.0884377851535874,
                            57.148314179417525
                        ],
                        [
                            -2.088845710559184,
                            57.14839320996407
                        ],
                        [
                            -2.0888806755937708,
                            57.148567076572135
                        ],
                        [
                            -2.089809492137846,
                            57.149021440602155
                        ],
                        [
                            -2.09057289539615,
                            57.14926484949146
                        ],
                        [
                            -2.0909633382844106,
                            57.14925852720293
                        ],
                        [
                            -2.0912721960910403,
                            57.14943871200359
                        ],
                        [
                            -2.0927407275499377,
                            57.14964102371633
                        ],
                        [
                            -2.0939470212484537,
                            57.14977379017654
                        ],
                        [
                            -2.0948910819210482,
                            57.14998874341228
                        ],
                        [
                            -2.096004135526357,
                            57.15035858817609
                        ],
                        [
                            -2.0967442287615654,
                            57.149931843889675
                        ],
                        [
                            -2.0976125271245962,
                            57.14956199485991
                        ],
                        [
                            -2.0975425970544848,
                            57.14940393859135
                        ],
                        [
                            -2.0977640422745765,
                            57.149359682715016
                        ],
                        [
                            -2.0976416646534517,
                            57.14913840253985
                        ],
                        [
                            -2.0979971425061876,
                            57.149018278461824
                        ],
                        [
                            -2.0989411936351985,
                            57.148496684710466
                        ],
                        [
                            -2.0995064616964783,
                            57.14825010953666
                        ],
                        [
                            -2.100386415071057,
                            57.147946630143366
                        ],
                        [
                            -2.1003397950239844,
                            57.14782650219587
                        ],
                        [
                            -2.100677790360379,
                            57.14780121205206
                        ],
                        [
                            -2.1031759139319774,
                            57.147719272400025
                        ],
                        [
                            -2.1041141423642387,
                            57.14770346601637
                        ],
                        [
                            -2.106450966589648,
                            57.14762127334484
                        ],
                        [
                            -2.107429987562284,
                            57.14771927303292
                        ],
                        [
                            -2.109022861915548,
                            57.14736366805619
                        ],
                        [
                            -2.110089295474694,
                            57.147132891965214
                        ],
                        [
                            -2.110876008756094,
                            57.14696534129885
                        ],
                        [
                            -2.1118725122459807,
                            57.14666185136721
                        ],
                        [
                            -2.112845831361625,
                            57.14626892852317
                        ],
                        [
                            -2.111965877987984,
                            57.14510235353703
                        ],
                        [
                            -2.1111196127843073,
                            57.143989878635125
                        ],
                        [
                            -2.113456442605411,
                            57.14381915212664
                        ],
                        [
                            -2.1135686501206123,
                            57.142775776117276
                        ],
                        [
                            -2.1120651536270714,
                            57.142759967621714
                        ],
                        [
                            -2.1133472042909887,
                            57.141169598970436
                        ],
                        [
                            -2.1130616565074263,
                            57.141109524043884
                        ],
                        [
                            -2.113411307589928,
                            57.14024949321106
                        ],
                        [
                            -2.1105092097075726,
                            57.140066101728394
                        ],
                        [
                            -2.1105325197306684,
                            57.139914328779355
                        ],
                        [
                            -2.108645149662294,
                            57.140132374088296
                        ],
                        [
                            -2.1063549398864723,
                            57.14040746047911
                        ],
                        [
                            -2.103621829823311,
                            57.14066989935131
                        ],
                        [
                            -2.0991055089905615,
                            57.14112204767352
                        ],
                        [
                            -2.0994726418548453,
                            57.14189985207463
                        ],
                        [
                            -2.1000728749516497,
                            57.142810431577146
                        ],
                        [
                            -2.100457489482295,
                            57.14336056136429
                        ],
                        [
                            -2.1006148321389446,
                            57.14399288565812
                        ],
                        [
                            -2.101296650316499,
                            57.14450189886486
                        ],
                        [
                            -2.0994787128261123,
                            57.145019115063064
                        ],
                        [
                            -2.1005393188794983,
                            57.14559450482241
                        ],
                        [
                            -2.099717640563483,
                            57.14592329495355
                        ],
                        [
                            -2.0987794121312504,
                            57.14617620845098
                        ],
                        [
                            -2.098645379498606,
                            57.14592329495355
                        ],
                        [
                            -2.0986861720389527,
                            57.14528151919504
                        ],
                        [
                            -2.098254936610317,
                            57.14510763714679
                        ],
                        [
                            -2.0980276638844657,
                            57.14488949305817
                        ],
                        [
                            -2.0980276638844657,
                            57.144525916718976
                        ],
                        [
                            -2.0969670571654433,
                            57.14479465102741
                        ],
                        [
                            -2.0958190385246382,
                            57.14523410052544
                        ],
                        [
                            -2.0950381543587184,
                            57.144623927331565
                        ],
                        [
                            -2.094828364150146,
                            57.1444468806379
                        ],
                        [
                            -2.0945741477236766,
                            57.14419486681223
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--02",
            "properties": {
                "land": "gb",
                "city": "Bath",
                "zone": "CAZ C",
                "area_code": 2,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel bus/truck)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol bus/truck)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-gb-caz-c--white",
                "color": 0,
                "urlZone": "https://beta.bathnes.gov.uk/bath-clean-air-zone",
                "urlPark": "https://beta.bathnes.gov.uk/find-car-parks-bath",
                "urlReg": "https://beta.bathnes.gov.uk/check-your-vehicle-and-pay-charge",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -2.378363797733499,
                            51.38900088069292
                        ],
                        [
                            -2.378500932004215,
                            51.38834373013151
                        ],
                        [
                            -2.378561602369274,
                            51.386661604692364
                        ],
                        [
                            -2.3783173836401943,
                            51.38570877854701
                        ],
                        [
                            -2.3756218859818716,
                            51.38533014869486
                        ],
                        [
                            -2.376063912929993,
                            51.38429160502076
                        ],
                        [
                            -2.3744951506268137,
                            51.38401032871994
                        ],
                        [
                            -2.373411751246749,
                            51.38355054642847
                        ],
                        [
                            -2.3719210117193654,
                            51.38305830718963
                        ],
                        [
                            -2.371140964165903,
                            51.38258769664611
                        ],
                        [
                            -2.373359766096087,
                            51.38233886612676
                        ],
                        [
                            -2.373342431706078,
                            51.381868248185356
                        ],
                        [
                            -2.372995743904397,
                            51.381500405505506
                        ],
                        [
                            -2.3743738279160596,
                            51.38156531913464
                        ],
                        [
                            -2.3747811860827994,
                            51.381424672821964
                        ],
                        [
                            -2.3747544351388967,
                            51.38096088483309
                        ],
                        [
                            -2.3733850183222103,
                            51.3805605773685
                        ],
                        [
                            -2.37503178537915,
                            51.37871587205433
                        ],
                        [
                            -2.3693894514833858,
                            51.378131612131
                        ],
                        [
                            -2.3652291978646645,
                            51.377752919590165
                        ],
                        [
                            -2.365324537009741,
                            51.37736881395506
                        ],
                        [
                            -2.3664166035848098,
                            51.37721192481109
                        ],
                        [
                            -2.3686874086850196,
                            51.37706585512473
                        ],
                        [
                            -2.368761233432622,
                            51.37623736890987
                        ],
                        [
                            -2.3690992540392983,
                            51.37498220967822
                        ],
                        [
                            -2.370468670855985,
                            51.37478744050648
                        ],
                        [
                            -2.372020098767763,
                            51.3753987965147
                        ],
                        [
                            -2.3725487976646207,
                            51.37409492118334
                        ],
                        [
                            -2.371266052799399,
                            51.37377570953123
                        ],
                        [
                            -2.370676683536118,
                            51.3741760763335
                        ],
                        [
                            -2.3700439782991793,
                            51.374170665994654
                        ],
                        [
                            -2.3693332683058372,
                            51.37399753481381
                        ],
                        [
                            -2.3680245218542098,
                            51.37387850674705
                        ],
                        [
                            -2.3675824949074524,
                            51.374051638378035
                        ],
                        [
                            -2.3668329797177137,
                            51.37408858915143
                        ],
                        [
                            -2.3666596358175696,
                            51.374499773819764
                        ],
                        [
                            -2.36633894960093,
                            51.37508408252151
                        ],
                        [
                            -2.3659575930192034,
                            51.375527719332894
                        ],
                        [
                            -2.366867648497788,
                            51.375803637620066
                        ],
                        [
                            -2.3670323252043204,
                            51.37605250366843
                        ],
                        [
                            -2.367188334714399,
                            51.37636087928644
                        ],
                        [
                            -2.3643628291309824,
                            51.37668007291359
                        ],
                        [
                            -2.364414832301037,
                            51.37701008432353
                        ],
                        [
                            -2.363660065317845,
                            51.37711808333145
                        ],
                        [
                            -2.3625593315477715,
                            51.376847582989996
                        ],
                        [
                            -2.361406594607672,
                            51.3766041313155
                        ],
                        [
                            -2.360756554979389,
                            51.376571670994565
                        ],
                        [
                            -2.36027119205761,
                            51.37684217296689
                        ],
                        [
                            -2.359101120727445,
                            51.3768367629431
                        ],
                        [
                            -2.357445673402111,
                            51.37657707573885
                        ],
                        [
                            -2.356544285118588,
                            51.37641477380774
                        ],
                        [
                            -2.355963583050368,
                            51.376479694649504
                        ],
                        [
                            -2.3547761773301943,
                            51.37627952175882
                        ],
                        [
                            -2.3535107668549813,
                            51.37614426931026
                        ],
                        [
                            -2.3525487082050347,
                            51.376095578330705
                        ],
                        [
                            -2.352245356378404,
                            51.37642018388132
                        ],
                        [
                            -2.352722052105179,
                            51.37654461539873
                        ],
                        [
                            -2.352505372230013,
                            51.37695036799738
                        ],
                        [
                            -2.351846665406697,
                            51.37694495798641
                        ],
                        [
                            -2.3511792913883767,
                            51.3772695575137
                        ],
                        [
                            -2.3499578951237936,
                            51.37846188165017
                        ],
                        [
                            -2.3489698348888055,
                            51.37901368119151
                        ],
                        [
                            -2.349030505255172,
                            51.37999283851198
                        ],
                        [
                            -2.349125844802586,
                            51.381445165525406
                        ],
                        [
                            -2.349879890772371,
                            51.38191037839309
                        ],
                        [
                            -2.3503565864991742,
                            51.38265146354411
                        ],
                        [
                            -2.350096570647537,
                            51.38306257129932
                        ],
                        [
                            -2.349429196629245,
                            51.38308420844723
                        ],
                        [
                            -2.3488224828945192,
                            51.383159945187174
                        ],
                        [
                            -2.3485017966778514,
                            51.383906419293595
                        ],
                        [
                            -2.347834422659531,
                            51.38472319947422
                        ],
                        [
                            -2.3477564179044634,
                            51.385280332558864
                        ],
                        [
                            -2.3479644305859892,
                            51.38564273587576
                        ],
                        [
                            -2.347253720592647,
                            51.38591859319308
                        ],
                        [
                            -2.3464563386478403,
                            51.38552914708521
                        ],
                        [
                            -2.345182260977623,
                            51.38550751109321
                        ],
                        [
                            -2.344705565250848,
                            51.385388512953995
                        ],
                        [
                            -2.344904910736034,
                            51.38478269944733
                        ],
                        [
                            -2.343838845745978,
                            51.38468533581471
                        ],
                        [
                            -2.3435528283107203,
                            51.385280332558864
                        ],
                        [
                            -2.3429547894292853,
                            51.38578337024035
                        ],
                        [
                            -2.3425040952861877,
                            51.38601595521695
                        ],
                        [
                            -2.3419927307793387,
                            51.386475712739326
                        ],
                        [
                            -2.3411520128608743,
                            51.38695169213116
                        ],
                        [
                            -2.340952667374353,
                            51.38672992962134
                        ],
                        [
                            -2.339886602384297,
                            51.387665651936345
                        ],
                        [
                            -2.3415507038325813,
                            51.38828224462131
                        ],
                        [
                            -2.3433274788159792,
                            51.38777923542369
                        ],
                        [
                            -2.3443865142988614,
                            51.387465532642665
                        ],
                        [
                            -2.345495915263996,
                            51.38746012387452
                        ],
                        [
                            -2.345851270260681,
                            51.38793068431437
                        ],
                        [
                            -2.34507988990228,
                            51.38824979726152
                        ],
                        [
                            -2.3457212623355304,
                            51.38912058567635
                        ],
                        [
                            -2.3453659085668903,
                            51.390520629444154
                        ],
                        [
                            -2.345963945023783,
                            51.39172127998586
                        ],
                        [
                            -2.3496388357206968,
                            51.39068828974561
                        ],
                        [
                            -2.3525677572018537,
                            51.39054808947736
                        ],
                        [
                            -2.3543965353553062,
                            51.390174908114716
                        ],
                        [
                            -2.355557930412658,
                            51.390878011360286
                        ],
                        [
                            -2.3572306990559184,
                            51.391673034033346
                        ],
                        [
                            -2.3576033884426693,
                            51.39150537733997
                        ],
                        [
                            -2.358270762460961,
                            51.39181364885056
                        ],
                        [
                            -2.3587561253827403,
                            51.39173252497011
                        ],
                        [
                            -2.3592241539145107,
                            51.3918839560973
                        ],
                        [
                            -2.3594928369611807,
                            51.3923057973077
                        ],
                        [
                            -2.3603335548796167,
                            51.39165680921897
                        ],
                        [
                            -2.3607062442663107,
                            51.39173793323326
                        ],
                        [
                            -2.36040289243968,
                            51.392403144727126
                        ],
                        [
                            -2.3607235786563194,
                            51.39250590011176
                        ],
                        [
                            -2.3614512946607533,
                            51.39193084194821
                        ],
                        [
                            -2.362512876861956,
                            51.391687007073784
                        ],
                        [
                            -2.3630404115754686,
                            51.39236567758326
                        ],
                        [
                            -2.3635418951920997,
                            51.392808636737385
                        ],
                        [
                            -2.3640108149366768,
                            51.39256480654089
                        ],
                        [
                            -2.3639326599374044,
                            51.39230471827321
                        ],
                        [
                            -2.3643299391666233,
                            51.39094330138724
                        ],
                        [
                            -2.3651510276692136,
                            51.39128239151543
                        ],
                        [
                            -2.3660662084928106,
                            51.39106383277988
                        ],
                        [
                            -2.367410717569186,
                            51.39114844010237
                        ],
                        [
                            -2.368009539590446,
                            51.391374048685066
                        ],
                        [
                            -2.368292002807692,
                            51.39238927353864
                        ],
                        [
                            -2.369918990940022,
                            51.39236107315256
                        ],
                        [
                            -2.370755082062999,
                            51.39236812325069
                        ],
                        [
                            -2.3736926995244687,
                            51.39198036623793
                        ],
                        [
                            -2.3755217857214177,
                            51.390949813145596
                        ],
                        [
                            -2.377365388516637,
                            51.38899124610043
                        ],
                        [
                            -2.378193965237614,
                            51.389138976296465
                        ],
                        [
                            -2.378363797733499,
                            51.38900088069292
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--03",
            "properties": {
                "land": "gb",
                "city": "Birmingham",
                "zone": "CAZ D",
                "area_code": 3,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-gb-caz-d--white",
                "color": 1,
                "urlZone": "https://www.birmingham.gov.uk/info/20076/pollution/1763/a_clean_air_zone_for_birmingham",
                "urlPark": "https://www.tfwm.org.uk/plan-your-journey/ways-to-travel/park-and-ride/",
                "urlReg": "https://www.gov.uk/clean-air-zones",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -1.9242357307794578,
                            52.48098714292345
                        ],
                        [
                            -1.9249257527980888,
                            52.4803693340491
                        ],
                        [
                            -1.9261413435525583,
                            52.47910989532252
                        ],
                        [
                            -1.9261273712450873,
                            52.47830996281618
                        ],
                        [
                            -1.9256802574037977,
                            52.477816380184265
                        ],
                        [
                            -1.9254147968084112,
                            52.47712071214394
                        ],
                        [
                            -1.9237381199051526,
                            52.476218618765245
                        ],
                        [
                            -1.9216562460840976,
                            52.4748909760045
                        ],
                        [
                            -1.9187779507338973,
                            52.473750532933394
                        ],
                        [
                            -1.9178976953598692,
                            52.47366542405638
                        ],
                        [
                            -1.917268941521428,
                            52.47279730410921
                        ],
                        [
                            -1.9147539164140142,
                            52.47227812342109
                        ],
                        [
                            -1.9138037995026593,
                            52.472201522876276
                        ],
                        [
                            -1.9132868241239862,
                            52.47190363060153
                        ],
                        [
                            -1.9112189226104022,
                            52.471163146782885
                        ],
                        [
                            -1.9088436303313756,
                            52.47007367667575
                        ],
                        [
                            -1.9048475368645086,
                            52.46831173858496
                        ],
                        [
                            -1.9036319461100675,
                            52.468141499629695
                        ],
                        [
                            -1.9018854076694538,
                            52.467843579873744
                        ],
                        [
                            -1.9015360999815698,
                            52.46793721201436
                        ],
                        [
                            -1.9005999553776292,
                            52.46777548364631
                        ],
                        [
                            -1.900516121532803,
                            52.467486073504745
                        ],
                        [
                            -1.898518081556631,
                            52.466932784114164
                        ],
                        [
                            -1.8979033000256607,
                            52.46710302774434
                        ],
                        [
                            -1.897274546187191,
                            52.46699236945969
                        ],
                        [
                            -1.8969112661918075,
                            52.466524196715056
                        ],
                        [
                            -1.8921886360888323,
                            52.465026012099145
                        ],
                        [
                            -1.8885278915180095,
                            52.46397044117211
                        ],
                        [
                            -1.8872843561485695,
                            52.4638086982292
                        ],
                        [
                            -1.8863482115446573,
                            52.4641747471978
                        ],
                        [
                            -1.885109666483288,
                            52.46520470605128
                        ],
                        [
                            -1.8835447680409345,
                            52.466268759709436
                        ],
                        [
                            -1.8824968449761457,
                            52.467154032750585
                        ],
                        [
                            -1.8811834480693506,
                            52.46785202394156
                        ],
                        [
                            -1.880037723977921,
                            52.46837125024874
                        ],
                        [
                            -1.8796884162899516,
                            52.46893303336972
                        ],
                        [
                            -1.8784867978429816,
                            52.46919689872445
                        ],
                        [
                            -1.8776764040063085,
                            52.47005657810064
                        ],
                        [
                            -1.8762877387913761,
                            52.47155093370864
                        ],
                        [
                            -1.8757009018753195,
                            52.472427589378725
                        ],
                        [
                            -1.8757847357212825,
                            52.4726829226978
                        ],
                        [
                            -1.8757567911052035,
                            52.47321060686278
                        ],
                        [
                            -1.875494167824229,
                            52.47370286501172
                        ],
                        [
                            -1.8753544447483819,
                            52.4751071392391
                        ],
                        [
                            -1.8755081401317,
                            52.47576245187594
                        ],
                        [
                            -1.8760670324316777,
                            52.476953904395145
                        ],
                        [
                            -1.8765656810457756,
                            52.4787330747549
                        ],
                        [
                            -1.8769429333486016,
                            52.47926919618436
                        ],
                        [
                            -1.8774179918048617,
                            52.48003507260836
                        ],
                        [
                            -1.8777952441077161,
                            52.480613725953845
                        ],
                        [
                            -1.8784379702536,
                            52.48154976672231
                        ],
                        [
                            -1.8797832742164928,
                            52.482760495022404
                        ],
                        [
                            -1.8805936680531943,
                            52.48299875020845
                        ],
                        [
                            -1.8808311972802016,
                            52.483687981159306
                        ],
                        [
                            -1.8824519849535761,
                            52.48533868792808
                        ],
                        [
                            -1.8832763510977202,
                            52.486019373711684
                        ],
                        [
                            -1.8842963257568783,
                            52.487312644179866
                        ],
                        [
                            -1.8868113418627956,
                            52.490324465737984
                        ],
                        [
                            -1.887789403389121,
                            52.49138791209219
                        ],
                        [
                            -1.8883902126126202,
                            52.49141343448861
                        ],
                        [
                            -1.8892564956792057,
                            52.49164313539086
                        ],
                        [
                            -1.8893962187539444,
                            52.491949401393924
                        ],
                        [
                            -1.8905698925859724,
                            52.492111040924726
                        ],
                        [
                            -1.8925958771811509,
                            52.49204298224049
                        ],
                        [
                            -1.8949991276067806,
                            52.492051491490884
                        ],
                        [
                            -1.898813567560893,
                            52.49245984189619
                        ],
                        [
                            -1.9028073886688333,
                            52.49306386301754
                        ],
                        [
                            -1.9082286439877976,
                            52.49377845728756
                        ],
                        [
                            -1.9099891547632524,
                            52.49360831683359
                        ],
                        [
                            -1.912006126840538,
                            52.49308135619137
                        ],
                        [
                            -1.913612942206413,
                            52.49258793928115
                        ],
                        [
                            -1.9147726437309984,
                            52.49212854614686
                        ],
                        [
                            -1.915261674493621,
                            52.491635118546355
                        ],
                        [
                            -1.9160022067928821,
                            52.49051212474831
                        ],
                        [
                            -1.9164531611077678,
                            52.48923842577423
                        ],
                        [
                            -1.917193693407114,
                            52.48725604417953
                        ],
                        [
                            -1.918311478008178,
                            52.48565646085174
                        ],
                        [
                            -1.9187446138885775,
                            52.48483325973734
                        ],
                        [
                            -1.9188424200408463,
                            52.484526944178356
                        ],
                        [
                            -1.9193873400344614,
                            52.4843908032455
                        ],
                        [
                            -1.920519096977614,
                            52.48401641344691
                        ],
                        [
                            -1.9218604385004312,
                            52.483131479580635
                        ],
                        [
                            -1.9242357307794578,
                            52.48098714292345
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--04",
            "properties": {
                "land": "gb",
                "city": "Bradford",
                "zone": "CAZ C+",
                "area_code": 2,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel bus/truck)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol bus/truck)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-gb-caz-c--white",
                "color": 0,
                "urlZone": "https://www.bradford.gov.uk/breathe-better-bradford/where-is-the-clean-air-zone/what-is-the-clean-air-zone-and-how-does-it-work/",
                "urlPark": "https://www.tfwm.org.uk/plan-your-journey/ways-to-travel/park-and-ride/",
                "urlReg": "https://www.bradford.gov.uk/transport-and-travel/parking/parking/",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -1.81570937866627,
                            53.84048943835447
                        ],
                        [
                            -1.8157857088624212,
                            53.84030584232582
                        ],
                        [
                            -1.8156983657512171,
                            53.84007627339605
                        ],
                        [
                            -1.8143931045427166,
                            53.83956851746345
                        ],
                        [
                            -1.8116537069933827,
                            53.839146851853286
                        ],
                        [
                            -1.8116298861451696,
                            53.83816763422337
                        ],
                        [
                            -1.8164744174280827,
                            53.8354970506698
                        ],
                        [
                            -1.8182609810469046,
                            53.83534242540648
                        ],
                        [
                            -1.8172154749677532,
                            53.83407569868953
                        ],
                        [
                            -1.8163738049966014,
                            53.83386015297171
                        ],
                        [
                            -1.8164690883893115,
                            53.83337282812903
                        ],
                        [
                            -1.8153256876722423,
                            53.83320882330085
                        ],
                        [
                            -1.8128086180403784,
                            53.833007330775814
                        ],
                        [
                            -1.8116890381726876,
                            53.83313853532107
                        ],
                        [
                            -1.8118240229790104,
                            53.83275429227851
                        ],
                        [
                            -1.814542672805402,
                            53.830853780076694
                        ],
                        [
                            -1.8156962721851357,
                            53.829545559231434
                        ],
                        [
                            -1.8176337011757084,
                            53.82848644854255
                        ],
                        [
                            -1.817760744949453,
                            53.827346179366714
                        ],
                        [
                            -1.8185468329415073,
                            53.82645573161898
                        ],
                        [
                            -1.8167285081917441,
                            53.82603393397409
                        ],
                        [
                            -1.8144735129640424,
                            53.82624480609434
                        ],
                        [
                            -1.8112497581660705,
                            53.826666601615784
                        ],
                        [
                            -1.8109877288362384,
                            53.82649319730427
                        ],
                        [
                            -1.8096616876921416,
                            53.826418217361095
                        ],
                        [
                            -1.8082641979279401,
                            53.82561211077669
                        ],
                        [
                            -1.807811601811494,
                            53.824712252547556
                        ],
                        [
                            -1.8066602608116966,
                            53.82551368972426
                        ],
                        [
                            -1.8053625236808557,
                            53.82613925737917
                        ],
                        [
                            -1.7990975915889749,
                            53.82753139323688
                        ],
                        [
                            -1.7977556838038424,
                            53.82782664148024
                        ],
                        [
                            -1.7955879777232724,
                            53.82749858439317
                        ],
                        [
                            -1.7912287624922385,
                            53.82729706439824
                        ],
                        [
                            -1.7882432037872888,
                            53.826828406631506
                        ],
                        [
                            -1.7857499550023306,
                            53.82567549326532
                        ],
                        [
                            -1.784026913166656,
                            53.82530992689513
                        ],
                        [
                            -1.7822403495477772,
                            53.82553957677462
                        ],
                        [
                            -1.7815971866447455,
                            53.82535679431953
                        ],
                        [
                            -1.7835663767674532,
                            53.82448036480238
                        ],
                        [
                            -1.7839316299615575,
                            53.824180406141124
                        ],
                        [
                            -1.7835743172377647,
                            53.823496117322975
                        ],
                        [
                            -1.783701370477047,
                            53.82265536069056
                        ],
                        [
                            -1.783439341145936,
                            53.82187730448808
                        ],
                        [
                            -1.7818592248791276,
                            53.82168044460451
                        ],
                        [
                            -1.7817957026160798,
                            53.821338281177646
                        ],
                        [
                            -1.7827559505206523,
                            53.82029260805095
                        ],
                        [
                            -1.782938577023259,
                            53.81949575839627
                        ],
                        [
                            -1.781732080404396,
                            53.819041332445465
                        ],
                        [
                            -1.779889934806448,
                            53.81754601039424
                        ],
                        [
                            -1.7793384154305727,
                            53.816708273182684
                        ],
                        [
                            -1.779211370905756,
                            53.815841038363345
                        ],
                        [
                            -1.7794892808023235,
                            53.815194116379075
                        ],
                        [
                            -1.7798624740915443,
                            53.81495034608477
                        ],
                        [
                            -1.7793066542996883,
                            53.814340914145475
                        ],
                        [
                            -1.778216721724391,
                            53.81342519515377
                        ],
                        [
                            -1.7770415598764373,
                            53.81285324616201
                        ],
                        [
                            -1.7749373786592741,
                            53.81178902224531
                        ],
                        [
                            -1.773293740129418,
                            53.81112328439221
                        ],
                        [
                            -1.7726982189239493,
                            53.81035907931522
                        ],
                        [
                            -1.7721430013779127,
                            53.8090563877042
                        ],
                        [
                            -1.771857151198617,
                            53.80785609835587
                        ],
                        [
                            -1.7709271663133848,
                            53.80654209243295
                        ],
                        [
                            -1.7704983910438727,
                            53.80658429201074
                        ],
                        [
                            -1.7701966603003996,
                            53.80560900202332
                        ],
                        [
                            -1.7716973806554108,
                            53.8057027821784
                        ],
                        [
                            -1.7724358269511242,
                            53.80565589276978
                        ],
                        [
                            -1.7730234078751153,
                            53.80543082287949
                        ],
                        [
                            -1.7758977902307436,
                            53.803864678104134
                        ],
                        [
                            -1.7773949292830196,
                            53.80330166505823
                        ],
                        [
                            -1.7796261487368383,
                            53.80246228864732
                        ],
                        [
                            -1.7820796932926726,
                            53.80156193435673
                        ],
                        [
                            -1.7832627954233544,
                            53.80111174941359
                        ],
                        [
                            -1.784247390483472,
                            53.80068031764063
                        ],
                        [
                            -1.7846126434911866,
                            53.8004458419895
                        ],
                        [
                            -1.7848667325383474,
                            53.80023012323241
                        ],
                        [
                            -1.7850260382042222,
                            53.799295569781094
                        ],
                        [
                            -1.7849625159424818,
                            53.79796368667556
                        ],
                        [
                            -1.7847719490525265,
                            53.79650504849366
                        ],
                        [
                            -1.7845337405707937,
                            53.79545448276485
                        ],
                        [
                            -1.7840921348375787,
                            53.79418377553117
                        ],
                        [
                            -1.7832187037343488,
                            53.79251402169348
                        ],
                        [
                            -1.7822420128262593,
                            53.79111158320211
                        ],
                        [
                            -1.781892640385223,
                            53.79065659544955
                        ],
                        [
                            -1.7804554492073805,
                            53.789427634791906
                        ],
                        [
                            -1.779232644731053,
                            53.78842377909797
                        ],
                        [
                            -1.7775096033739715,
                            53.78773421546876
                        ],
                        [
                            -1.7750322340815785,
                            53.78662713733627
                        ],
                        [
                            -1.7779780789817607,
                            53.78507905522025
                        ],
                        [
                            -1.7793993907541221,
                            53.78425807914823
                        ],
                        [
                            -1.781646490773369,
                            53.78315560064172
                        ],
                        [
                            -1.7829725179931017,
                            53.78251755729397
                        ],
                        [
                            -1.7833060130440685,
                            53.78221260652984
                        ],
                        [
                            -1.7829169391894482,
                            53.78199679398645
                        ],
                        [
                            -1.7807810031282543,
                            53.78073473754094
                        ],
                        [
                            -1.779971090717197,
                            53.78012949763573
                        ],
                        [
                            -1.7792644055523397,
                            53.77853425604059
                        ],
                        [
                            -1.7789369645818454,
                            53.77770040066147
                        ],
                        [
                            -1.7785637712926246,
                            53.77709511933824
                        ],
                        [
                            -1.7779285486722927,
                            53.77686989615205
                        ],
                        [
                            -1.7754750013009755,
                            53.77638660065287
                        ],
                        [
                            -1.773767830378091,
                            53.77597837523385
                        ],
                        [
                            -1.7730849660614467,
                            53.77562645411115
                        ],
                        [
                            -1.7724894448547275,
                            53.77571091544982
                        ],
                        [
                            -1.7711554773522948,
                            53.775476300200495
                        ],
                        [
                            -1.7699406140920075,
                            53.7749366801506
                        ],
                        [
                            -1.7680827766940581,
                            53.77410142840483
                        ],
                        [
                            -1.7670425996535926,
                            53.77373072149538
                        ],
                        [
                            -1.7662485713788385,
                            53.7734397846659
                        ],
                        [
                            -1.7656530469253369,
                            53.77285790299979
                        ],
                        [
                            -1.7646605115812406,
                            53.7725106483625
                        ],
                        [
                            -1.763445648319646,
                            53.77220093234365
                        ],
                        [
                            -1.7624689949420258,
                            53.77195691386274
                        ],
                        [
                            -1.762476935224754,
                            53.77131400745813
                        ],
                        [
                            -1.76178613062541,
                            53.771224844478496
                        ],
                        [
                            -1.761397056769482,
                            53.77138439915012
                        ],
                        [
                            -1.7603012977500327,
                            53.771069982011056
                        ],
                        [
                            -1.7587529426140804,
                            53.77071801973361
                        ],
                        [
                            -1.7567361070609309,
                            53.77016425838954
                        ],
                        [
                            -1.754068172056094,
                            53.769399306773266
                        ],
                        [
                            -1.753520292545744,
                            53.7696433419317
                        ],
                        [
                            -1.7528453638179542,
                            53.76947908706461
                        ],
                        [
                            -1.7518687090393144,
                            53.769572946730534
                        ],
                        [
                            -1.7507570694544086,
                            53.770056320686734
                        ],
                        [
                            -1.7500583245710288,
                            53.770286273333426
                        ],
                        [
                            -1.7492642976769446,
                            53.76996715542123
                        ],
                        [
                            -1.7489069849532086,
                            53.76914119091515
                        ],
                        [
                            -1.7485258513812312,
                            53.76891123199607
                        ],
                        [
                            -1.7476524202780297,
                            53.768643727178585
                        ],
                        [
                            -1.747811225933674,
                            53.768319903277074
                        ],
                        [
                            -1.7472633464233525,
                            53.768230733938026
                        ],
                        [
                            -1.7459362971120242,
                            53.76817910807611
                        ],
                        [
                            -1.743347764934441,
                            53.76839029856865
                        ],
                        [
                            -1.7422440656322067,
                            53.76858271564811
                        ],
                        [
                            -1.7403939809750852,
                            53.768915929766166
                        ],
                        [
                            -1.7387900438588417,
                            53.769000404608136
                        ],
                        [
                            -1.7373925540946402,
                            53.769145888659494
                        ],
                        [
                            -1.735328059289003,
                            53.76944155717021
                        ],
                        [
                            -1.7347007769514278,
                            53.76963396943145
                        ],
                        [
                            -1.7336129582146782,
                            53.770103263930764
                        ],
                        [
                            -1.7326523462263026,
                            53.770689753749
                        ],
                        [
                            -1.7315883483377092,
                            53.77073668225495
                        ],
                        [
                            -1.7311119313718564,
                            53.771022945004205
                        ],
                        [
                            -1.7314057218338519,
                            53.77155792261388
                        ],
                        [
                            -1.730758671512973,
                            53.77202162601782
                        ],
                        [
                            -1.7302504934174578,
                            53.77252843502379
                        ],
                        [
                            -1.7299328821073061,
                            53.77303523791076
                        ],
                        [
                            -1.7295679152711898,
                            53.77398446153404
                        ],
                        [
                            -1.7288691703890606,
                            53.774852562259156
                        ],
                        [
                            -1.728194246355173,
                            53.775420337346304
                        ],
                        [
                            -1.7273605166655273,
                            53.77523733629752
                        ],
                        [
                            -1.7259630269013257,
                            53.77369352646332
                        ],
                        [
                            -1.7251848791919713,
                            53.7739422291487
                        ],
                        [
                            -1.726090071424892,
                            53.774791561173316
                        ],
                        [
                            -1.72651090646292,
                            53.77534526080106
                        ],
                        [
                            -1.726232996566381,
                            53.775866106504196
                        ],
                        [
                            -1.725581893380621,
                            53.776283716858586
                        ],
                        [
                            -1.7250022527393583,
                            53.7766544012203
                        ],
                        [
                            -1.7239303145680083,
                            53.77747552580553
                        ],
                        [
                            -1.722667808488609,
                            53.77836701451071
                        ],
                        [
                            -1.7222310929363687,
                            53.77868606895228
                        ],
                        [
                            -1.721849959364448,
                            53.77915995416083
                        ],
                        [
                            -1.721532348054268,
                            53.779929419734316
                        ],
                        [
                            -1.7212447057866598,
                            53.781064369611755
                        ],
                        [
                            -1.7209032736283518,
                            53.78204023048039
                        ],
                        [
                            -1.7208000499529135,
                            53.782739270709385
                        ],
                        [
                            -1.7204347961064457,
                            53.78399657617132
                        ],
                        [
                            -1.7200616028171964,
                            53.7847002750961
                        ],
                        [
                            -1.7191724038769394,
                            53.786299969603476
                        ],
                        [
                            -1.718187810601961,
                            53.78801687861849
                        ],
                        [
                            -1.7176002296793058,
                            53.789006650216095
                        ],
                        [
                            -1.7174693684326883,
                            53.78993829023341
                        ],
                        [
                            -1.7177631588946838,
                            53.79142521614267
                        ],
                        [
                            -1.7187120381530576,
                            53.7925814996066
                        ],
                        [
                            -1.7183467851465082,
                            53.793134963813884
                        ],
                        [
                            -1.7185135310837154,
                            53.79343983504589
                        ],
                        [
                            -1.7188708438075082,
                            53.79339293192385
                        ],
                        [
                            -1.7191857709446197,
                            53.794632574133885
                        ],
                        [
                            -1.7194319197102743,
                            53.796517982597834
                        ],
                        [
                            -1.7197696871218966,
                            53.79799829933924
                        ],
                        [
                            -1.7206639656337188,
                            53.79966901266047
                        ],
                        [
                            -1.7216009189987744,
                            53.80116028308103
                        ],
                        [
                            -1.7228073604702274,
                            53.80255721696446
                        ],
                        [
                            -1.7248877054182117,
                            53.8045454347631
                        ],
                        [
                            -1.726253434051472,
                            53.80575519676529
                        ],
                        [
                            -1.7278414790875445,
                            53.807391615010005
                        ],
                        [
                            -1.728587865665986,
                            53.80737754875966
                        ],
                        [
                            -1.730207683347686,
                            53.80814180819209
                        ],
                        [
                            -1.7318195607466578,
                            53.8092436300895
                        ],
                        [
                            -1.7323436166678334,
                            53.80951088196741
                        ],
                        [
                            -1.7333361520119865,
                            53.80982969959217
                        ],
                        [
                            -1.7359802661686388,
                            53.81090334699624
                        ],
                        [
                            -1.7372189502783897,
                            53.811184647446595
                        ],
                        [
                            -1.7388149553931953,
                            53.81118464779709
                        ],
                        [
                            -1.7411017568248326,
                            53.81101117940912
                        ],
                        [
                            -1.7439829580559092,
                            53.811048685670585
                        ],
                        [
                            -1.744634061241669,
                            53.81130185528315
                        ],
                        [
                            -1.7471431918595215,
                            53.81040168665655
                        ],
                        [
                            -1.7482071897481717,
                            53.81043919394105
                        ],
                        [
                            -1.7499461116706243,
                            53.81049545480525
                        ],
                        [
                            -1.7528363801745286,
                            53.810232903617674
                        ],
                        [
                            -1.7559648515797903,
                            53.80967966504343
                        ],
                        [
                            -1.7569256263505508,
                            53.81007349716862
                        ],
                        [
                            -1.7594235805121912,
                            53.81246203133222
                        ],
                        [
                            -1.7612339628373093,
                            53.81425288213444
                        ],
                        [
                            -1.7618136034785437,
                            53.81475918112423
                        ],
                        [
                            -1.7629490594655977,
                            53.81509202593506
                        ],
                        [
                            -1.764013057354191,
                            53.81573894949477
                        ],
                        [
                            -1.7652755623121266,
                            53.81682182093235
                        ],
                        [
                            -1.7669430214234865,
                            53.81872029430528
                        ],
                        [
                            -1.7673241549966576,
                            53.81922653932713
                        ],
                        [
                            -1.7679276164848545,
                            53.82069836511451
                        ],
                        [
                            -1.7685407261757575,
                            53.82241455960485
                        ],
                        [
                            -1.7694065671697388,
                            53.823385079663126
                        ],
                        [
                            -1.7701370731827524,
                            53.82444900616753
                        ],
                        [
                            -1.7712566530634035,
                            53.825559769699595
                        ],
                        [
                            -1.7725509191522235,
                            53.82622527824668
                        ],
                        [
                            -1.7730114555514263,
                            53.82692358218662
                        ],
                        [
                            -1.7738531196406484,
                            53.82843261785598
                        ],
                        [
                            -1.773781657096123,
                            53.82880284148405
                        ],
                        [
                            -1.7744009992205463,
                            53.82933708247637
                        ],
                        [
                            -1.7747186105307549,
                            53.8302321551746
                        ],
                        [
                            -1.7740039850831977,
                            53.83110377826904
                        ],
                        [
                            -1.7730749720008987,
                            53.831225616611476
                        ],
                        [
                            -1.7725509133386765,
                            53.83172702452177
                        ],
                        [
                            -1.772211692913089,
                            53.83262275965066
                        ],
                        [
                            -1.7714017840727934,
                            53.83401914261057
                        ],
                        [
                            -1.7715923508594358,
                            53.83447365990679
                        ],
                        [
                            -1.7700757568531458,
                            53.834047257225905
                        ],
                        [
                            -1.76989313034926,
                            53.83429560217226
                        ],
                        [
                            -1.7715208883136597,
                            53.83470325955216
                        ],
                        [
                            -1.7714573660518909,
                            53.83491880093092
                        ],
                        [
                            -1.7710444713490574,
                            53.83501251422794
                        ],
                        [
                            -1.7704410098596384,
                            53.835288967232316
                        ],
                        [
                            -1.770441009400912,
                            53.83561743579972
                        ],
                        [
                            -1.7718623200132697,
                            53.836812244696745
                        ],
                        [
                            -1.7729421929086584,
                            53.83675133013551
                        ],
                        [
                            -1.7738473851415506,
                            53.83677944291671
                        ],
                        [
                            -1.7737600748245939,
                            53.83719697523679
                        ],
                        [
                            -1.7768505823906366,
                            53.83833128876006
                        ],
                        [
                            -1.7798523672505837,
                            53.839177641142555
                        ],
                        [
                            -1.783187800919734,
                            53.83939576036414
                        ],
                        [
                            -1.7863818183082003,
                            53.83981456540556
                        ],
                        [
                            -1.7880823368248286,
                            53.8400239663558
                        ],
                        [
                            -1.7899603007524263,
                            53.8400239663558
                        ],
                        [
                            -1.7923262406542904,
                            53.8399018204764
                        ],
                        [
                            -1.7946330309913208,
                            53.84040786992537
                        ],
                        [
                            -1.79599344603605,
                            53.84048639423361
                        ],
                        [
                            -1.7994536315403877,
                            53.840931362861
                        ],
                        [
                            -1.8026079923004943,
                            53.841536415915044
                        ],
                        [
                            -1.8074433797367817,
                            53.84148406766681
                        ],
                        [
                            -1.8110070752885008,
                            53.84110017835482
                        ],
                        [
                            -1.81570937866627,
                            53.84048943835447
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--05",
            "properties": {
                "land": "gb",
                "city": "Bristol",
                "zone": "CAZ D",
                "area_code": 3,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-gb-caz-d--white",
                "color": 1,
                "urlZone": "https://www.cleanairforbristol.org/",
                "urlPark": "https://www.bristol.gov.uk/residents/parking/where-to-park-in-bristol/car-parking-and-park-and-ride",
                "urlReg": "https://www.bristol.gov.uk/residents/streets-travel/bristols-caz/pay-the-bristol-clean-air-zone-daily-charge",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -2.6265048980712886,
                            51.462349770689805
                        ],
                        [
                            -2.626826763153076,
                            51.46268398814269
                        ],
                        [
                            -2.627577781677246,
                            51.462470089254815
                        ],
                        [
                            -2.6280713081359863,
                            51.461921968778526
                        ],
                        [
                            -2.6293373107910156,
                            51.46145405584625
                        ],
                        [
                            -2.6294660568237305,
                            51.46094603065936
                        ],
                        [
                            -2.62923002243042,
                            51.46010376591622
                        ],
                        [
                            -2.6294231414794917,
                            51.45939518195076
                        ],
                        [
                            -2.630281448364258,
                            51.459288224924926
                        ],
                        [
                            -2.630410194396972,
                            51.4581785309918
                        ],
                        [
                            -2.6297450065612793,
                            51.45688162557894
                        ],
                        [
                            -2.628757953643799,
                            51.45638691999108
                        ],
                        [
                            -2.627749443054199,
                            51.455491088119594
                        ],
                        [
                            -2.6273202896118164,
                            51.45471557767554
                        ],
                        [
                            -2.6272344589233394,
                            51.45313777437931
                        ],
                        [
                            -2.6261186599731445,
                            51.4513727094593
                        ],
                        [
                            -2.6250672340393066,
                            51.450008748917135
                        ],
                        [
                            -2.6240587234497066,
                            51.44943373373346
                        ],
                        [
                            -2.62448787689209,
                            51.44740106387672
                        ],
                        [
                            -2.6247024536132812,
                            51.44619746670353
                        ],
                        [
                            -2.6243162155151363,
                            51.445515414222825
                        ],
                        [
                            -2.623629570007324,
                            51.44468623866308
                        ],
                        [
                            -2.6241660118103027,
                            51.4438035513615
                        ],
                        [
                            -2.625002861022949,
                            51.44249286298363
                        ],
                        [
                            -2.625432014465332,
                            51.441663632541506
                        ],
                        [
                            -2.626268863677978,
                            51.44143626027399
                        ],
                        [
                            -2.6266658306121826,
                            51.44108182477582
                        ],
                        [
                            -2.6258718967437744,
                            51.44099488734673
                        ],
                        [
                            -2.6253676414489746,
                            51.44131588626229
                        ],
                        [
                            -2.6244449615478516,
                            51.4413827607524
                        ],
                        [
                            -2.624005079269409,
                            51.44118882446132
                        ],
                        [
                            -2.623136043548584,
                            51.442171872340516
                        ],
                        [
                            -2.622159719467163,
                            51.443616312467746
                        ],
                        [
                            -2.621666193008423,
                            51.44401085066982
                        ],
                        [
                            -2.62048602104187,
                            51.44410446940382
                        ],
                        [
                            -2.6197779178619385,
                            51.443964041230835
                        ],
                        [
                            -2.6173532009124756,
                            51.44276035346281
                        ],
                        [
                            -2.617009878158569,
                            51.44290078533719
                        ],
                        [
                            -2.6169240474700928,
                            51.443201709328186
                        ],
                        [
                            -2.616505622863769,
                            51.44397741536116
                        ],
                        [
                            -2.6160120964050293,
                            51.44456587321754
                        ],
                        [
                            -2.6156365871429443,
                            51.44485341236673
                        ],
                        [
                            -2.6145315170288086,
                            51.445334869213816
                        ],
                        [
                            -2.6143813133239746,
                            51.44499383780482
                        ],
                        [
                            -2.6131904125213623,
                            51.444779856012524
                        ],
                        [
                            -2.612353563308716,
                            51.4445458122791
                        ],
                        [
                            -2.611699104309082,
                            51.44519444482445
                        ],
                        [
                            -2.611473798751831,
                            51.44550204054292
                        ],
                        [
                            -2.610551118850708,
                            51.445495353701524
                        ],
                        [
                            -2.610046863555908,
                            51.44526131363515
                        ],
                        [
                            -2.608609199523926,
                            51.44543517208472
                        ],
                        [
                            -2.6083409786224365,
                            51.44564915080628
                        ],
                        [
                            -2.608308792114258,
                            51.44596343023569
                        ],
                        [
                            -2.606291770935058,
                            51.44612391251434
                        ],
                        [
                            -2.6053905487060547,
                            51.446150659505925
                        ],
                        [
                            -2.6039421558380127,
                            51.44605035820662
                        ],
                        [
                            -2.602236270904541,
                            51.445836381365126
                        ],
                        [
                            -2.602311372756958,
                            51.4455354747353
                        ],
                        [
                            -2.6021718978881836,
                            51.44539505096281
                        ],
                        [
                            -2.60101318359375,
                            51.44524125300223
                        ],
                        [
                            -2.600036859512329,
                            51.44514094970538
                        ],
                        [
                            -2.5998973846435547,
                            51.44544185893497
                        ],
                        [
                            -2.598620653152466,
                            51.44526131363515
                        ],
                        [
                            -2.5970220565795894,
                            51.44528806113202
                        ],
                        [
                            -2.5961530208587646,
                            51.44535492980565
                        ],
                        [
                            -2.596142292022705,
                            51.44506739381436
                        ],
                        [
                            -2.595895528793335,
                            51.44504064618824
                        ],
                        [
                            -2.595648765563965,
                            51.44538167724766
                        ],
                        [
                            -2.5952517986297607,
                            51.445448545784195
                        ],
                        [
                            -2.594146728515625,
                            51.4455354747353
                        ],
                        [
                            -2.593545913696289,
                            51.44556222207159
                        ],
                        [
                            -2.5927841663360596,
                            51.44550872738334
                        ],
                        [
                            -2.592161893844604,
                            51.44544185893497
                        ],
                        [
                            -2.5920653343200684,
                            51.445281374259274
                        ],
                        [
                            -2.5922906398773193,
                            51.445100828324954
                        ],
                        [
                            -2.592076063156128,
                            51.44481329073367
                        ],
                        [
                            -2.590970993041992,
                            51.44511420212237
                        ],
                        [
                            -2.5895440578460693,
                            51.44483335155461
                        ],
                        [
                            -2.5896835327148438,
                            51.4443184543609
                        ],
                        [
                            -2.5893402099609375,
                            51.44427833225782
                        ],
                        [
                            -2.589125633239746,
                            51.44477316906536
                        ],
                        [
                            -2.588353157043457,
                            51.44465949081371
                        ],
                        [
                            -2.5875377655029292,
                            51.44467955170223
                        ],
                        [
                            -2.5870227813720703,
                            51.44475310821799
                        ],
                        [
                            -2.586904764175415,
                            51.44464611688315
                        ],
                        [
                            -2.5866150856018066,
                            51.44465280384894
                        ],
                        [
                            -2.5866472721099854,
                            51.44479991684814
                        ],
                        [
                            -2.5859498977661133,
                            51.44495371629514
                        ],
                        [
                            -2.584962844848633,
                            51.44530143487457
                        ],
                        [
                            -2.5846731662750244,
                            51.44508745452365
                        ],
                        [
                            -2.5845015048980713,
                            51.445134262811095
                        ],
                        [
                            -2.5847160816192627,
                            51.44541511152818
                        ],
                        [
                            -2.5837934017181396,
                            51.44581632098481
                        ],
                        [
                            -2.5830745697021484,
                            51.446244273853154
                        ],
                        [
                            -2.5823235511779785,
                            51.44665216269766
                        ],
                        [
                            -2.5817549228668213,
                            51.44679258260433
                        ],
                        [
                            -2.5812292098999023,
                            51.446705656046376
                        ],
                        [
                            -2.580435276031494,
                            51.446378008302
                        ],
                        [
                            -2.5798559188842773,
                            51.445829694572666
                        ],
                        [
                            -2.5792980194091797,
                            51.44532149548106
                        ],
                        [
                            -2.5790512561798096,
                            51.44488684703401
                        ],
                        [
                            -2.578815221786499,
                            51.44417133981062
                        ],
                        [
                            -2.577892541885376,
                            51.44422483606556
                        ],
                        [
                            -2.5784826278686523,
                            51.445020585458344
                        ],
                        [
                            -2.5791263580322266,
                            51.4459433699112
                        ],
                        [
                            -2.580488920211792,
                            51.44695306196893
                        ],
                        [
                            -2.581787109375,
                            51.44780894238796
                        ],
                        [
                            -2.5809824466705322,
                            51.44807640172887
                        ],
                        [
                            -2.5811862945556636,
                            51.448464214990445
                        ],
                        [
                            -2.5807249546051025,
                            51.44973461467471
                        ],
                        [
                            -2.5798022747039795,
                            51.45054364026084
                        ],
                        [
                            -2.579265832901001,
                            51.450877944168404
                        ],
                        [
                            -2.58378267288208,
                            51.45262965662454
                        ],
                        [
                            -2.5836324691772456,
                            51.45303748842829
                        ],
                        [
                            -2.5828278064727783,
                            51.452897087726754
                        ],
                        [
                            -2.582763433456421,
                            51.45315114582281
                        ],
                        [
                            -2.5835680961608887,
                            51.45335840269608
                        ],
                        [
                            -2.5833535194396973,
                            51.4540804515504
                        ],
                        [
                            -2.58303165435791,
                            51.45416067849592
                        ],
                        [
                            -2.5829672813415527,
                            51.45444147169489
                        ],
                        [
                            -2.583214044570923,
                            51.4545952386672
                        ],
                        [
                            -2.582988739013672,
                            51.45537743655085
                        ],
                        [
                            -2.5826025009155273,
                            51.45559805404343
                        ],
                        [
                            -2.5807249546051025,
                            51.45601922993213
                        ],
                        [
                            -2.5805211067199707,
                            51.455791929141576
                        ],
                        [
                            -2.5800812244415283,
                            51.45591226499521
                        ],
                        [
                            -2.580317258834839,
                            51.456152935750794
                        ],
                        [
                            -2.580113410949707,
                            51.45627327065274
                        ],
                        [
                            -2.580413818359375,
                            51.45656742129978
                        ],
                        [
                            -2.5830960273742676,
                            51.456012544630916
                        ],
                        [
                            -2.5834178924560547,
                            51.456179676867514
                        ],
                        [
                            -2.5841689109802246,
                            51.45720251282318
                        ],
                        [
                            -2.5840508937835693,
                            51.457690524516295
                        ],
                        [
                            -2.5832676887512207,
                            51.457496657482054
                        ],
                        [
                            -2.5825273990631104,
                            51.458131736076474
                        ],
                        [
                            -2.5824093818664546,
                            51.45845261453236
                        ],
                        [
                            -2.581465244293213,
                            51.45945534516765
                        ],
                        [
                            -2.582259178161621,
                            51.460056972974726
                        ],
                        [
                            -2.5826668739318843,
                            51.460190666966
                        ],
                        [
                            -2.582387924194336,
                            51.460391207218585
                        ],
                        [
                            -2.582505941390991,
                            51.460611800478674
                        ],
                        [
                            -2.5839757919311523,
                            51.45998344111262
                        ],
                        [
                            -2.584125995635986,
                            51.46013050471839
                        ],
                        [
                            -2.5844907760620117,
                            51.46002354941573
                        ],
                        [
                            -2.584351301193237,
                            51.459849746514344
                        ],
                        [
                            -2.5847911834716797,
                            51.45965588865119
                        ],
                        [
                            -2.585402727127075,
                            51.459495453934835
                        ],
                        [
                            -2.5866150856018066,
                            51.45951550830523
                        ],
                        [
                            -2.5874626636505127,
                            51.45961578002496
                        ],
                        [
                            -2.5888359546661377,
                            51.459548932236274
                        ],
                        [
                            -2.588728666305542,
                            51.45972273628322
                        ],
                        [
                            -2.589104175567627,
                            51.459782899068315
                        ],
                        [
                            -2.5892651081085205,
                            51.45955561701957
                        ],
                        [
                            -2.5897264480590816,
                            51.45968931247944
                        ],
                        [
                            -2.5901341438293457,
                            51.459976756391995
                        ],
                        [
                            -2.5904130935668945,
                            51.46039789187849
                        ],
                        [
                            -2.5901341438293457,
                            51.46075886205973
                        ],
                        [
                            -2.5904881954193115,
                            51.46079896968141
                        ],
                        [
                            -2.590552568435669,
                            51.46120672850113
                        ],
                        [
                            -2.5911319255828857,
                            51.46128694292208
                        ],
                        [
                            -2.5913894176483154,
                            51.46075217745269
                        ],
                        [
                            -2.5925159454345703,
                            51.459970071670405
                        ],
                        [
                            -2.593610286712646,
                            51.45998344111262
                        ],
                        [
                            -2.594146728515625,
                            51.460056972974726
                        ],
                        [
                            -2.5950050354003906,
                            51.45967594295107
                        ],
                        [
                            -2.5957131385803223,
                            51.45914115860514
                        ],
                        [
                            -2.5966036319732666,
                            51.458372395129906
                        ],
                        [
                            -2.597440481185913,
                            51.45741643639945
                        ],
                        [
                            -2.5981056690216064,
                            51.45698190308507
                        ],
                        [
                            -2.598663568496704,
                            51.45653399518534
                        ],
                        [
                            -2.5994467735290527,
                            51.45659416217368
                        ],
                        [
                            -2.599232196807861,
                            51.45671449591246
                        ],
                        [
                            -2.598867416381836,
                            51.45668106990573
                        ],
                        [
                            -2.5987493991851807,
                            51.4568949959258
                        ],
                        [
                            -2.5996720790863037,
                            51.45726267893033
                        ],
                        [
                            -2.6000475883483887,
                            51.45692173660783
                        ],
                        [
                            -2.6005196571350098,
                            51.456513939504944
                        ],
                        [
                            -2.5992214679718013,
                            51.4561061387588
                        ],
                        [
                            -2.5997793674468994,
                            51.45585878243275
                        ],
                        [
                            -2.600959539413452,
                            51.45565153691138
                        ],
                        [
                            -2.6009058952331543,
                            51.45534066686507
                        ],
                        [
                            -2.5996077060699463,
                            51.4554944308085
                        ],
                        [
                            -2.5988084077835083,
                            51.454942883825744
                        ],
                        [
                            -2.599318027496338,
                            51.45455846835137
                        ],
                        [
                            -2.600492835044861,
                            51.45421750583034
                        ],
                        [
                            -2.600964903831482,
                            51.45402362404537
                        ],
                        [
                            -2.601335048675537,
                            51.45427767587206
                        ],
                        [
                            -2.6007825136184692,
                            51.45460860968379
                        ],
                        [
                            -2.600991725921631,
                            51.45473897689033
                        ],
                        [
                            -2.601662278175354,
                            51.45438464463926
                        ],
                        [
                            -2.601613998413086,
                            51.45424424808089
                        ],
                        [
                            -2.601335048675537,
                            51.45398016767024
                        ],
                        [
                            -2.6015764474868774,
                            51.45387654076265
                        ],
                        [
                            -2.6024723052978516,
                            51.454585210402186
                        ],
                        [
                            -2.6032018661499023,
                            51.45415065013543
                        ],
                        [
                            -2.602311372756958,
                            51.4533851454499
                        ],
                        [
                            -2.602590322494507,
                            51.452917144996256
                        ],
                        [
                            -2.603164315223694,
                            51.452756686593425
                        ],
                        [
                            -2.603722214698791,
                            51.45274331503441
                        ],
                        [
                            -2.6047414541244507,
                            51.45239231020916
                        ],
                        [
                            -2.6059001684188843,
                            51.45169029246246
                        ],
                        [
                            -2.6072198152542114,
                            51.45123898963912
                        ],
                        [
                            -2.6076221466064453,
                            51.451392767398524
                        ],
                        [
                            -2.6082390546798706,
                            51.45128579162072
                        ],
                        [
                            -2.608399987220764,
                            51.45089465929952
                        ],
                        [
                            -2.608582377433777,
                            51.45062387342189
                        ],
                        [
                            -2.6093602180480957,
                            51.450429976374615
                        ],
                        [
                            -2.6124125719070435,
                            51.449737957785125
                        ],
                        [
                            -2.6136034727096558,
                            51.44967109553
                        ],
                        [
                            -2.6145368814468384,
                            51.449758016442594
                        ],
                        [
                            -2.616505622863769,
                            51.450122413852
                        ],
                        [
                            -2.6188820600509644,
                            51.45051689584247
                        ],
                        [
                            -2.6199764013290405,
                            51.45071747859826
                        ],
                        [
                            -2.6196974515914917,
                            51.45119553061326
                        ],
                        [
                            -2.6202875375747676,
                            51.45133927954097
                        ],
                        [
                            -2.620716691017151,
                            51.450914717448796
                        ],
                        [
                            -2.620861530303955,
                            51.450971548823844
                        ],
                        [
                            -2.6205557584762573,
                            51.451523143788805
                        ],
                        [
                            -2.620764970779419,
                            51.45158666035686
                        ],
                        [
                            -2.621113657951355,
                            51.451048438218706
                        ],
                        [
                            -2.621682286262512,
                            51.451118641466124
                        ],
                        [
                            -2.621617913246155,
                            51.451399453376304
                        ],
                        [
                            -2.6219022274017334,
                            51.45142285429086
                        ],
                        [
                            -2.6220256090164185,
                            51.45116544357111
                        ],
                        [
                            -2.622497677803039,
                            51.45133927954097
                        ],
                        [
                            -2.623039484024048,
                            51.451476342050526
                        ],
                        [
                            -2.62373685836792,
                            51.4515799744065
                        ],
                        [
                            -2.62498140335083,
                            51.45124567563945
                        ],
                        [
                            -2.6255714893341064,
                            51.45184406870173
                        ],
                        [
                            -2.6262420415878296,
                            51.45282020144533
                        ],
                        [
                            -2.6266229152679443,
                            51.45344531658871
                        ],
                        [
                            -2.626579999923706,
                            51.45437795909864
                        ],
                        [
                            -2.6267892122268677,
                            51.45516684613143
                        ],
                        [
                            -2.627121806144714,
                            51.45565822226547
                        ],
                        [
                            -2.6281464099884033,
                            51.45660753260477
                        ],
                        [
                            -2.6288974285125732,
                            51.45707549522541
                        ],
                        [
                            -2.6294124126434326,
                            51.45815847603397
                        ],
                        [
                            -2.629251480102539,
                            51.458773490732476
                        ],
                        [
                            -2.628500461578369,
                            51.4596090952505
                        ],
                        [
                            -2.628575563430786,
                            51.460190666966
                        ],
                        [
                            -2.628854513168335,
                            51.46089923858142
                        ],
                        [
                            -2.62747049331665,
                            51.461581061259366
                        ],
                        [
                            -2.6271378993988037,
                            51.46210913260816
                        ],
                        [
                            -2.6265048980712886,
                            51.462349770689805
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--06",
            "properties": {
                "land": "gb",
                "city": "Dundee",
                "zone": "LEZ",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 30.5.2024",
                "img_sign": "sign-scoot--white",
                "color": 1,
                "urlZone": "https://www.dundeecity.gov.uk/service-area/city-development/sustainable-transport-and-roads/dundee-low-emission-zone-scheme",
                "urlPark": "https://www.dundeecity.gov.uk/parking-information",
                "urlReg": "https://www.lowemissionzones.scot/",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -2.972704603025875,
                            56.45809318534921
                        ],
                        [
                            -2.9715384630245865,
                            56.45795511076915
                        ],
                        [
                            -2.9693727744501928,
                            56.45892162229052
                        ],
                        [
                            -2.966207537302779,
                            56.460698098367175
                        ],
                        [
                            -2.963558755508217,
                            56.462272017945224
                        ],
                        [
                            -2.963308868364578,
                            56.46260335843215
                        ],
                        [
                            -2.9650580783671785,
                            56.464140372324636
                        ],
                        [
                            -2.9663241732266954,
                            56.46458213726342
                        ],
                        [
                            -2.9678901326569473,
                            56.46486744105505
                        ],
                        [
                            -2.9684232252284914,
                            56.46459134064497
                        ],
                        [
                            -2.9677110009403975,
                            56.46401874367953
                        ],
                        [
                            -2.9677802699493725,
                            56.46380263918749
                        ],
                        [
                            -2.9689537684589595,
                            56.46335917090963
                        ],
                        [
                            -2.9701109743057827,
                            56.463192589194534
                        ],
                        [
                            -2.9703839756955404,
                            56.463604543938374
                        ],
                        [
                            -2.9707466193322603,
                            56.46355952177936
                        ],
                        [
                            -2.9709014559419415,
                            56.463683332587635
                        ],
                        [
                            -2.9715778474443653,
                            56.463631557207975
                        ],
                        [
                            -2.971956789671424,
                            56.46399848555137
                        ],
                        [
                            -2.9723805530220204,
                            56.4639759747119
                        ],
                        [
                            -2.9729102605773505,
                            56.46415605764875
                        ],
                        [
                            -2.9733421732231022,
                            56.46418757267037
                        ],
                        [
                            -2.9740633858489502,
                            56.46409978075903
                        ],
                        [
                            -2.97479811840833,
                            56.46377688715165
                        ],
                        [
                            -2.9758982732612935,
                            56.46351575907232
                        ],
                        [
                            -2.97582900425229,
                            56.462896698262
                        ],
                        [
                            -2.9768191436203324,
                            56.462640066458505
                        ],
                        [
                            -2.9776992675021745,
                            56.4635292657411
                        ],
                        [
                            -2.978405668695302,
                            56.46360410767065
                        ],
                        [
                            -2.979167627797068,
                            56.463415014242656
                        ],
                        [
                            -2.9789720447127763,
                            56.46303907570217
                        ],
                        [
                            -2.9781769142060455,
                            56.461767400144
                        ],
                        [
                            -2.9778509423976516,
                            56.4611303008117
                        ],
                        [
                            -2.9775045973512135,
                            56.461087526975234
                        ],
                        [
                            -2.9774394029898303,
                            56.460797113865596
                        ],
                        [
                            -2.97762683677891,
                            56.460623765447195
                        ],
                        [
                            -2.977300865468493,
                            56.460024918595394
                        ],
                        [
                            -2.9768159824040765,
                            56.45973449736047
                        ],
                        [
                            -2.976143663835387,
                            56.45948910040147
                        ],
                        [
                            -2.9756180342938308,
                            56.45984030946417
                        ],
                        [
                            -2.975235017419152,
                            56.45984481212332
                        ],
                        [
                            -2.97478273153547,
                            56.45968721873365
                        ],
                        [
                            -2.97540207797158,
                            56.45920768053941
                        ],
                        [
                            -2.9734340226895313,
                            56.45842419560276
                        ],
                        [
                            -2.973091752290742,
                            56.458266596318225
                        ],
                        [
                            -2.972704603025875,
                            56.45809318534921
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "gb",
                "city": "Edinburgh",
                "zone": "LEZ",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 1.6.2024",
                "img_sign": "sign-scoot--white",
                "color": 1,
                "urlZone": "https://www.edinburgh.gov.uk/lowemissionzone",
                "urlPark": "https://www.edinburgh.gov.uk/parking/find-park-ride",
                "urlReg": "https://www.lowemissionzones.scot/",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -3.210985629695358,
                                55.9522218899985
                            ],
                            [
                                -3.2107782591211844,
                                55.95197079038908
                            ],
                            [
                                -3.210778249523031,
                                55.951598797686245
                            ],
                            [
                                -3.211102344356931,
                                55.951235879100864
                            ],
                            [
                                -3.2123663142079124,
                                55.95149445894131
                            ],
                            [
                                -3.217697674160405,
                                55.9490582957302
                            ],
                            [
                                -3.2151811099255667,
                                55.947318507035135
                            ],
                            [
                                -3.2145248178870247,
                                55.94710526920741
                            ],
                            [
                                -3.2109457733226066,
                                55.94707631676201
                            ],
                            [
                                -3.2109052614672464,
                                55.94616890794194
                            ],
                            [
                                -3.20896069246524,
                                55.94586038409946
                            ],
                            [
                                -3.2072191038971596,
                                55.94591029348706
                            ],
                            [
                                -3.2052664325230467,
                                55.9459511276134
                            ],
                            [
                                -3.2049261329475485,
                                55.945034617967906
                            ],
                            [
                                -3.2036027085684964,
                                55.944088717124515
                            ],
                            [
                                -3.2015852182278763,
                                55.94314947615365
                            ],
                            [
                                -3.1996804281632194,
                                55.941739788666126
                            ],
                            [
                                -3.1982301037822083,
                                55.9410682156506
                            ],
                            [
                                -3.1939935792231893,
                                55.94044004286519
                            ],
                            [
                                -3.1886541168356075,
                                55.93979113654251
                            ],
                            [
                                -3.1855281348934454,
                                55.9399681118077
                            ],
                            [
                                -3.183259126327897,
                                55.94055345354096
                            ],
                            [
                                -3.182481298727083,
                                55.94066235951155
                            ],
                            [
                                -3.182149101522384,
                                55.939586899632985
                            ],
                            [
                                -3.1812821478413014,
                                55.93974572470091
                            ],
                            [
                                -3.1809985648615395,
                                55.93954605879574
                            ],
                            [
                                -3.1818412114301395,
                                55.93936000554862
                            ],
                            [
                                -3.1816872663833635,
                                55.93915579973657
                            ],
                            [
                                -3.1800586898444294,
                                55.93919664098499
                            ],
                            [
                                -3.177360600352756,
                                55.93951429367061
                            ],
                            [
                                -3.1760804257588973,
                                55.939836481590305
                            ],
                            [
                                -3.1767691272807497,
                                55.940494462678856
                            ],
                            [
                                -3.178875741187511,
                                55.94169695291333
                            ],
                            [
                                -3.1798074992749434,
                                55.94311266635475
                            ],
                            [
                                -3.1810714691271755,
                                55.94415626701962
                            ],
                            [
                                -3.1817034540532916,
                                55.9448413982515
                            ],
                            [
                                -3.181720829122355,
                                55.94565679304634
                            ],
                            [
                                -3.181890978910758,
                                55.94616495132516
                            ],
                            [
                                -3.1823285069360168,
                                55.946364583110324
                            ],
                            [
                                -3.182490554352313,
                                55.9466095843494
                            ],
                            [
                                -3.182174561889269,
                                55.946818287885804
                            ],
                            [
                                -3.182247483227883,
                                55.94715402599755
                            ],
                            [
                                -3.1823041998236192,
                                55.94797067411699
                            ],
                            [
                                -3.1824795796489127,
                                55.94860808122644
                            ],
                            [
                                -3.1831601787999375,
                                55.94903907591808
                            ],
                            [
                                -3.183597706826532,
                                55.94907083323179
                            ],
                            [
                                -3.1836868329054653,
                                55.94932035407669
                            ],
                            [
                                -3.1823013274909897,
                                55.94944738225311
                            ],
                            [
                                -3.180308144262824,
                                55.949651533806076
                            ],
                            [
                                -3.1787930009152774,
                                55.950150566404034
                            ],
                            [
                                -3.1773071634154633,
                                55.95066681079993
                            ],
                            [
                                -3.176140422012992,
                                55.95112046516823
                            ],
                            [
                                -3.174333593314799,
                                55.94996363602459
                            ],
                            [
                                -3.1725186622458352,
                                55.950480810972465
                            ],
                            [
                                -3.172316102974179,
                                55.95076207865816
                            ],
                            [
                                -3.172887308832202,
                                55.95172494249792
                            ],
                            [
                                -3.173794774367309,
                                55.951702260259594
                            ],
                            [
                                -3.1744105545518266,
                                55.95250066704028
                            ],
                            [
                                -3.1747913659814344,
                                55.95245983982008
                            ],
                            [
                                -3.1749453110269314,
                                55.95276377475844
                            ],
                            [
                                -3.174572601968123,
                                55.953525869783334
                            ],
                            [
                                -3.1740540502347017,
                                55.95399763537614
                            ],
                            [
                                -3.1736548512316745,
                                55.95448737270175
                            ],
                            [
                                -3.172836511776808,
                                55.95517232115472
                            ],
                            [
                                -3.172172117367438,
                                55.9558118978415
                            ],
                            [
                                -3.172317960043358,
                                55.95610673459959
                            ],
                            [
                                -3.1725529287970176,
                                55.955820969775175
                            ],
                            [
                                -3.174950562290263,
                                55.95475814958226
                            ],
                            [
                                -3.1777377778608695,
                                55.95362864647788
                            ],
                            [
                                -3.179798433105532,
                                55.953136227875035
                            ],
                            [
                                -3.181467521499826,
                                55.95304096585855
                            ],
                            [
                                -3.1822615538422383,
                                55.953258707265945
                            ],
                            [
                                -3.1829178458808656,
                                55.95360799821597
                            ],
                            [
                                -3.1826990818675256,
                                55.95383934503215
                            ],
                            [
                                -3.183657223517315,
                                55.95412321244814
                            ],
                            [
                                -3.1849211933682966,
                                55.95417311023414
                            ],
                            [
                                -3.185002217077738,
                                55.95446795947376
                            ],
                            [
                                -3.1859826039501797,
                                55.954762806467755
                            ],
                            [
                                -3.185634202003854,
                                55.95495332179266
                            ],
                            [
                                -3.1848158581596238,
                                55.955808486787305
                            ],
                            [
                                -3.1830090294614877,
                                55.957400578743545
                            ],
                            [
                                -3.184362125392738,
                                55.95779065396488
                            ],
                            [
                                -3.185099441139414,
                                55.95717832482475
                            ],
                            [
                                -3.1862518447511263,
                                55.95639039417986
                            ],
                            [
                                -3.186932443902151,
                                55.9563949300792
                            ],
                            [
                                -3.1871755150278602,
                                55.95668976239665
                            ],
                            [
                                -3.193211798915911,
                                55.955596600785526
                            ],
                            [
                                -3.2055003246331353,
                                55.95354649264945
                            ],
                            [
                                -3.209097777287809,
                                55.95289326960881
                            ],
                            [
                                -3.2097054551014423,
                                55.95289326960881
                            ],
                            [
                                -3.2105562040396194,
                                55.9527072804772
                            ],
                            [
                                -3.210985629695358,
                                55.9522218899985
                            ]
                        ],
                        [
                            [
                                -3.2109839925159918,
                                55.94584787593857
                            ],
                            [
                                -3.21220454551937,
                                55.945042451412064
                            ],
                            [
                                -3.211975333687718,
                                55.94477932104411
                            ],
                            [
                                -3.2107891624588945,
                                55.945616839540634
                            ],
                            [
                                -3.2109839925159918,
                                55.94584787593857
                            ]
                        ],
                        [
                            [
                                -3.212422289941969,
                                55.94493335002181
                            ],
                            [
                                -3.213041161887759,
                                55.94461566711709
                            ],
                            [
                                -3.21276610768993,
                                55.9443974591683
                            ],
                            [
                                -3.212233190180683,
                                55.94462850284097
                            ],
                            [
                                -3.212422289941969,
                                55.94493335002181
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "gb",
                "city": "Glasgow",
                "zone": "LEZ",
                "area_code": 0,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "enforced from 1.6.2023 (residents 2024)",
                "img_sign": "sign-scoot--white",
                "color": 1,
                "urlZone": "https://glasgow.gov.uk/LEZ",
                "urlPark": "https://glasgow.gov.uk/index.aspx?articleid=27156",
                "urlReg": "https://www.lowemissionzones.scot/",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -4.252638307335872,
                                55.85191383370321
                            ],
                            [
                                -4.251015430098704,
                                55.85363013047714
                            ],
                            [
                                -4.247468222381599,
                                55.852751207266124
                            ],
                            [
                                -4.249224003076677,
                                55.8508291006045
                            ],
                            [
                                -4.248733373121411,
                                55.85066744373009
                            ],
                            [
                                -4.2481689345055145,
                                55.85141614849002
                            ],
                            [
                                -4.246510391613981,
                                55.853308054221856
                            ],
                            [
                                -4.245390253411216,
                                55.85482800236534
                            ],
                            [
                                -4.243944494564971,
                                55.8562660954222
                            ],
                            [
                                -4.242669923267442,
                                55.855691392782035
                            ],
                            [
                                -4.242360613078404,
                                55.855853028758986
                            ],
                            [
                                -4.243560523295258,
                                55.856436708631946
                            ],
                            [
                                -4.242793731721974,
                                55.85736030680266
                            ],
                            [
                                -4.241993791576874,
                                55.85716874559495
                            ],
                            [
                                -4.241796473008549,
                                55.857450100793244
                            ],
                            [
                                -4.24032458314187,
                                55.85722262227978
                            ],
                            [
                                -4.240319250207563,
                                55.85739622442384
                            ],
                            [
                                -4.241529826292975,
                                55.85782723328899
                            ],
                            [
                                -4.240740550600151,
                                55.85866828611864
                            ],
                            [
                                -4.239257994864914,
                                55.858656313973455
                            ],
                            [
                                -4.239343321814204,
                                55.858808958543165
                            ],
                            [
                                -4.240991198512091,
                                55.85906935083608
                            ],
                            [
                                -4.239671598458813,
                                55.860188480947215
                            ],
                            [
                                -4.237282443892866,
                                55.85988020910679
                            ],
                            [
                                -4.237154453470168,
                                55.86009869328481
                            ],
                            [
                                -4.238269036738558,
                                55.860218410121774
                            ],
                            [
                                -4.238146379249287,
                                55.86068530226106
                            ],
                            [
                                -4.236088963024514,
                                55.861256685963156
                            ],
                            [
                                -4.23591830912676,
                                55.86204678671834
                            ],
                            [
                                -4.236750246877534,
                                55.86209766386807
                            ],
                            [
                                -4.236925246737371,
                                55.86286361449908
                            ],
                            [
                                -4.240221001288887,
                                55.86298032920945
                            ],
                            [
                                -4.240231667157474,
                                55.86316587638592
                            ],
                            [
                                -4.239074420414852,
                                55.86383024771638
                            ],
                            [
                                -4.237588935408667,
                                55.86444669002765
                            ],
                            [
                                -4.237001258379252,
                                55.86541090568477
                            ],
                            [
                                -4.23695326197074,
                                55.8669340666078
                            ],
                            [
                                -4.237491890867631,
                                55.86785272049693
                            ],
                            [
                                -4.239170932816762,
                                55.8683662795695
                            ],
                            [
                                -4.2402801831508725,
                                55.868785197712896
                            ],
                            [
                                -4.243751908936929,
                                55.869272297974135
                            ],
                            [
                                -4.2479489352840005,
                                55.86962238858541
                            ],
                            [
                                -4.250642068002492,
                                55.86961939258947
                            ],
                            [
                                -4.2517139877966486,
                                55.86946679049544
                            ],
                            [
                                -4.252588589021656,
                                55.869831837643716
                            ],
                            [
                                -4.253985383637854,
                                55.869872617150776
                            ],
                            [
                                -4.256406535809447,
                                55.86983970323908
                            ],
                            [
                                -4.25876451657345,
                                55.86965711110048
                            ],
                            [
                                -4.260087084278666,
                                55.86953143895329
                            ],
                            [
                                -4.26051371902264,
                                55.86964215015195
                            ],
                            [
                                -4.261898148165301,
                                55.869686672415554
                            ],
                            [
                                -4.263322041623468,
                                55.86960887547906
                            ],
                            [
                                -4.26377000000133,
                                55.86849276976653
                            ],
                            [
                                -4.265252555736595,
                                55.86866931381701
                            ],
                            [
                                -4.26533788268506,
                                55.86845985468494
                            ],
                            [
                                -4.266533604075448,
                                55.86850616608771
                            ],
                            [
                                -4.2674135382347345,
                                55.86887122226534
                            ],
                            [
                                -4.268624114320119,
                                55.86838348250353
                            ],
                            [
                                -4.2695624369698635,
                                55.867824757192665
                            ],
                            [
                                -4.270461842213223,
                                55.867049460689
                            ],
                            [
                                -4.270711141643176,
                                55.86607024859731
                            ],
                            [
                                -4.2702451046696694,
                                55.86440658913099
                            ],
                            [
                                -4.269386406633714,
                                55.8620516152707
                            ],
                            [
                                -4.268987649514372,
                                55.86061631699732
                            ],
                            [
                                -4.269181549071277,
                                55.859170565346176
                            ],
                            [
                                -4.269513951535089,
                                55.855835801758076
                            ],
                            [
                                -4.26022808997547,
                                55.855870829699256
                            ],
                            [
                                -4.26010344026102,
                                55.85509345561084
                            ],
                            [
                                -4.257804345519702,
                                55.85479027550082
                            ],
                            [
                                -4.257430396375412,
                                55.8553966333551
                            ],
                            [
                                -4.251571859775936,
                                55.8537874321309
                            ],
                            [
                                -4.253150756164047,
                                55.852061548062665
                            ],
                            [
                                -4.252638307335872,
                                55.85191383370321
                            ]
                        ],
                        [
                            [
                                -4.238199271927954,
                                55.86881681745007
                            ],
                            [
                                -4.237878762678207,
                                55.86872074144003
                            ],
                            [
                                -4.237299211771102,
                                55.86881435397626
                            ],
                            [
                                -4.23773387495072,
                                55.868922747157285
                            ],
                            [
                                -4.237966573421289,
                                55.86886608712331
                            ],
                            [
                                -4.238199271927954,
                                55.86881681745007
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "gb",
                "city": "London",
                "zone": "LEZ",
                "area_code": 4,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel trucks)",
                "minEUd": 6,
                "petrol": "-",
                "minEUb": 0,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Online Registration",
                "img_sign": "sign-gb-lez--white",
                "color": 1,
                "urlZone": "https://tfl.gov.uk/modes/driving/low-emission-zone",
                "urlPark": "https://tfl.gov.uk/info-for/deliveries-in-london/delivering-legally/parking-and-loading",
                "urlReg": "https://tfl.gov.uk/modes/driving/low-emission-zone/find-out-if-you-need-to-register?intcmp=2272",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -0.06390625896841584,
                                51.68247244588625
                            ],
                            [
                                -0.0665328273206569,
                                51.68318588176794
                            ],
                            [
                                -0.06810084368595426,
                                51.68364403547662
                            ],
                            [
                                -0.07649965541892811,
                                51.68691803995205
                            ],
                            [
                                -0.08167230710668605,
                                51.68868343687265
                            ],
                            [
                                -0.08803448845006301,
                                51.688895726801945
                            ],
                            [
                                -0.09504551966904273,
                                51.68942086680758
                            ],
                            [
                                -0.0989745721704196,
                                51.69008006891053
                            ],
                            [
                                -0.10557106160197804,
                                51.691175006360226
                            ],
                            [
                                -0.11032918022706895,
                                51.69068341081433
                            ],
                            [
                                -0.11260010048033564,
                                51.69020298273574
                            ],
                            [
                                -0.11645706407060175,
                                51.68847116424112
                            ],
                            [
                                -0.11308673004452885,
                                51.68565542201918
                            ],
                            [
                                -0.113987875237342,
                                51.68233663599028
                            ],
                            [
                                -0.11447450100510537,
                                51.68136442257796
                            ],
                            [
                                -0.11874599386237605,
                                51.678849980741205
                            ],
                            [
                                -0.1220802660409106,
                                51.677609461686046
                            ],
                            [
                                -0.12426107041133605,
                                51.676458327968135
                            ],
                            [
                                -0.12485583523957189,
                                51.67540775321282
                            ],
                            [
                                -0.1309837282812225,
                                51.67788886870997
                            ],
                            [
                                -0.1359040554958142,
                                51.680023404705565
                            ],
                            [
                                -0.14051798870823973,
                                51.680973296749755
                            ],
                            [
                                -0.14329355790613363,
                                51.68054864159291
                            ],
                            [
                                -0.14826796929830266,
                                51.68057099769803
                            ],
                            [
                                -0.15349469051506048,
                                51.68098447756637
                            ],
                            [
                                -0.15688306828502618,
                                51.68204611328065
                            ],
                            [
                                -0.1602894486648836,
                                51.68405753593851
                            ],
                            [
                                -0.1635696668074047,
                                51.68557721824064
                            ],
                            [
                                -0.1633173423350911,
                                51.68272777208003
                            ],
                            [
                                -0.16490336570183217,
                                51.680985455737385
                            ],
                            [
                                -0.16216384397236538,
                                51.67521874766675
                            ],
                            [
                                -0.16010920183848043,
                                51.6712732701009
                            ],
                            [
                                -0.16816557216992578,
                                51.67180978272606
                            ],
                            [
                                -0.1759155381122639,
                                51.67229040599781
                            ],
                            [
                                -0.17685274329642198,
                                51.67213392456233
                            ],
                            [
                                -0.18204342515772964,
                                51.66861294349394
                            ],
                            [
                                -0.185575967773417,
                                51.668523518337395
                            ],
                            [
                                -0.1877387489669502,
                                51.667897537297904
                            ],
                            [
                                -0.19096490243958897,
                                51.664197355761615
                            ],
                            [
                                -0.19651604083614416,
                                51.66552766377862
                            ],
                            [
                                -0.19483988541114172,
                                51.66835583589594
                            ],
                            [
                                -0.1959392991840332,
                                51.668523508540545
                            ],
                            [
                                -0.19703871295772046,
                                51.66750628495825
                            ],
                            [
                                -0.19756138507923993,
                                51.66672378973695
                            ],
                            [
                                -0.19837240415142787,
                                51.66577360255562
                            ],
                            [
                                -0.19869682133045785,
                                51.66510286754138
                            ],
                            [
                                -0.19898519215576016,
                                51.664141463370214
                            ],
                            [
                                -0.19875089086028197,
                                51.66330301284623
                            ],
                            [
                                -0.19869682133045785,
                                51.662553983923004
                            ],
                            [
                                -0.19907530803931195,
                                51.661581345798595
                            ],
                            [
                                -0.1996339900782118,
                                51.66009438984071
                            ],
                            [
                                -0.20019270855345894,
                                51.6590322550276
                            ],
                            [
                                -0.2010397978540368,
                                51.657679394638905
                            ],
                            [
                                -0.20152642362253914,
                                51.656639565402344
                            ],
                            [
                                -0.2016706090352045,
                                51.656147594868855
                            ],
                            [
                                -0.2021392116276104,
                                51.65618113848399
                            ],
                            [
                                -0.20298630092821668,
                                51.656650746488936
                            ],
                            [
                                -0.20385141340565838,
                                51.65707562572885
                            ],
                            [
                                -0.20450024776371833,
                                51.657679394638905
                            ],
                            [
                                -0.2049868735314817,
                                51.658350239549264
                            ],
                            [
                                -0.20616253962836595,
                                51.65971857186267
                            ],
                            [
                                -0.2071357911654843,
                                51.661015471369296
                            ],
                            [
                                -0.20828927446817147,
                                51.66273715991801
                            ],
                            [
                                -0.2122723723095703,
                                51.66141796211846
                            ],
                            [
                                -0.2133717860831723,
                                51.66234588505273
                            ],
                            [
                                -0.21886885494916442,
                                51.660121074131496
                            ],
                            [
                                -0.21937350389458743,
                                51.66076952276444
                            ],
                            [
                                -0.2232124440670873,
                                51.6590241649601
                            ],
                            [
                                -0.22613219867767498,
                                51.65735823991716
                            ],
                            [
                                -0.2235008148923896,
                                51.655256179995206
                            ],
                            [
                                -0.22268976675641738,
                                51.65357893413099
                            ],
                            [
                                -0.2216624456902423,
                                51.65275147020412
                            ],
                            [
                                -0.21986012802867094,
                                51.65277383429253
                            ],
                            [
                                -0.21775141636570083,
                                51.65294156460487
                            ],
                            [
                                -0.21760723095232493,
                                51.65248310028096
                            ],
                            [
                                -0.21587864706538085,
                                51.65249681862815
                            ],
                            [
                                -0.21845596132081369,
                                51.65232908667002
                            ],
                            [
                                -0.22031234851144177,
                                51.65156869400849
                            ],
                            [
                                -0.22247512756047172,
                                51.650047851058105
                            ],
                            [
                                -0.22508848816889326,
                                51.64974591677449
                            ],
                            [
                                -0.22748557065790465,
                                51.6491308592355
                            ],
                            [
                                -0.23092799739077918,
                                51.647721786869596
                            ],
                            [
                                -0.23229775881361547,
                                51.64705078463493
                            ],
                            [
                                -0.23438844982669593,
                                51.64596596497191
                            ],
                            [
                                -0.23720006537760696,
                                51.64500415476894
                            ],
                            [
                                -0.24085877022974955,
                                51.64291270634939
                            ],
                            [
                                -0.2431116673061524,
                                51.64151462553346
                            ],
                            [
                                -0.24491401626355014,
                                51.64080995555045
                            ],
                            [
                                -0.2480500489934343,
                                51.640854695499144
                            ],
                            [
                                -0.25059131689590686,
                                51.64101128497296
                            ],
                            [
                                -0.2517808465515543,
                                51.6413244622988
                            ],
                            [
                                -0.2531325847975836,
                                51.64202910337323
                            ],
                            [
                                -0.2541418826876338,
                                51.64257714997163
                            ],
                            [
                                -0.2548628097524386,
                                51.64248767342855
                            ],
                            [
                                -0.2557098820782642,
                                51.64280085481704
                            ],
                            [
                                -0.25664708726165486,
                                51.64144750991659
                            ],
                            [
                                -0.2607383483525325,
                                51.642711378715376
                            ],
                            [
                                -0.2632796162542377,
                                51.64464626004124
                            ],
                            [
                                -0.2679476189964589,
                                51.643975212286364
                            ],
                            [
                                -0.2680197117031753,
                                51.642610717889795
                            ],
                            [
                                -0.2726871804051143,
                                51.64200741745546
                            ],
                            [
                                -0.27378659417800577,
                                51.6387861101866
                            ],
                            [
                                -0.26901045237605103,
                                51.63744383128625
                            ],
                            [
                                -0.2662168632763269,
                                51.63629167719586
                            ],
                            [
                                -0.2652976812691179,
                                51.63566525086554
                            ],
                            [
                                -0.2666313963381981,
                                51.63479271405808
                            ],
                            [
                                -0.26619884009949146,
                                51.63407677388446
                            ],
                            [
                                -0.2650633799736113,
                                51.63336082240929
                            ],
                            [
                                -0.26383780396344036,
                                51.63205194440505
                            ],
                            [
                                -0.2632250159591365,
                                51.630474528419256
                            ],
                            [
                                -0.26288257560318584,
                                51.62877399017165
                            ],
                            [
                                -0.2638197807873155,
                                51.6283488456493
                            ],
                            [
                                -0.26540582032876614,
                                51.62982564634646
                            ],
                            [
                                -0.2671360452836211,
                                51.632007195771195
                            ],
                            [
                                -0.2697974391978164,
                                51.63526624685204
                            ],
                            [
                                -0.27163580321234804,
                                51.637156702314655
                            ],
                            [
                                -0.2768985707828904,
                                51.63773836506519
                            ],
                            [
                                -0.28165668940792443,
                                51.636921797935145
                            ],
                            [
                                -0.289568866545892,
                                51.6352550614277
                            ],
                            [
                                -0.29631721939659883,
                                51.63469735861429
                            ],
                            [
                                -0.2995433680092958,
                                51.63487634226797
                            ],
                            [
                                -0.299363136243727,
                                51.63287392238868
                            ],
                            [
                                -0.2984439542364612,
                                51.63160977940382
                            ],
                            [
                                -0.29574047774448786,
                                51.62976384362386
                            ],
                            [
                                -0.2920096832475281,
                                51.62741436373301
                            ],
                            [
                                -0.2880626075699695,
                                51.624628392334444
                            ],
                            [
                                -0.28707133285607256,
                                51.6243598559314
                            ],
                            [
                                -0.28642249849795576,
                                51.623811589173386
                            ],
                            [
                                -0.28705330967991927,
                                51.62330807304966
                            ],
                            [
                                -0.2881887698065668,
                                51.62325212646894
                            ],
                            [
                                -0.28878353463400686,
                                51.62358780491945
                            ],
                            [
                                -0.2888556273406948,
                                51.624102507053436
                            ],
                            [
                                -0.29053178276569724,
                                51.62540042563967
                            ],
                            [
                                -0.29563234140940153,
                                51.62875693915038
                            ],
                            [
                                -0.2984259397372284,
                                51.63064767478147
                            ],
                            [
                                -0.29972360845334833,
                                51.63162097677238
                            ],
                            [
                                -0.3006067441069149,
                                51.63280681074599
                            ],
                            [
                                -0.30154394929104456,
                                51.63416041352167
                            ],
                            [
                                -0.3029317338900057,
                                51.63514482653167
                            ],
                            [
                                -0.3045538197851556,
                                51.63564821129191
                            ],
                            [
                                -0.30516660690102526,
                                51.63422753828016
                            ],
                            [
                                -0.30493230560483653,
                                51.63322073163738
                            ],
                            [
                                -0.3036887064185123,
                                51.631643356296706
                            ],
                            [
                                -0.30055267368862815,
                                51.6291709058566
                            ],
                            [
                                -0.2998858070011181,
                                51.62841012307686
                            ],
                            [
                                -0.2978311648671763,
                                51.626799015116546
                            ],
                            [
                                -0.2960829167362533,
                                51.62533330470245
                            ],
                            [
                                -0.29444280766421116,
                                51.623833979410534
                            ],
                            [
                                -0.2929468840055449,
                                51.62235698366635
                            ],
                            [
                                -0.2923521191773375,
                                51.622189140198685
                            ],
                            [
                                -0.2919375861154663,
                                51.62168560607063
                            ],
                            [
                                -0.29195560929238695,
                                51.621349913547675
                            ],
                            [
                                -0.29303699988915355,
                                51.62132753395801
                            ],
                            [
                                -0.2932893243614956,
                                51.62194296865627
                            ],
                            [
                                -0.2949474566096342,
                                51.62337522072838
                            ],
                            [
                                -0.29765093310075486,
                                51.62563540156253
                            ],
                            [
                                -0.3010573084711723,
                                51.628577947539156
                            ],
                            [
                                -0.3040491557884195,
                                51.63089380642762
                            ],
                            [
                                -0.3055631026874437,
                                51.63273969767104
                            ],
                            [
                                -0.3060497284560313,
                                51.633813635351885
                            ],
                            [
                                -0.3056712417471772,
                                51.63473093698508
                            ],
                            [
                                -0.3052206623315783,
                                51.63579364129049
                            ],
                            [
                                -0.3082125096488255,
                                51.63661022873302
                            ],
                            [
                                -0.3149892435891388,
                                51.638679609462
                            ],
                            [
                                -0.31798109090718185,
                                51.639977111015355
                            ],
                            [
                                -0.3216037494055968,
                                51.637728830445326
                            ],
                            [
                                -0.32857873054896913,
                                51.635995001378745
                            ],
                            [
                                -0.3320391804579401,
                                51.63403737756258
                            ],
                            [
                                -0.33254382940336313,
                                51.633086501221754
                            ],
                            [
                                -0.33191298868095487,
                                51.630323246775134
                            ],
                            [
                                -0.33157054832582844,
                                51.62956248500751
                            ],
                            [
                                -0.3342560016409095,
                                51.63008830700261
                            ],
                            [
                                -0.33605831930168506,
                                51.63080431013205
                            ],
                            [
                                -0.3379327296692054,
                                51.629249226453965
                            ],
                            [
                                -0.3386716799100782,
                                51.627750030569814
                            ],
                            [
                                -0.3404018867749983,
                                51.62628434104357
                            ],
                            [
                                -0.34290710832371474,
                                51.62428154185889
                            ],
                            [
                                -0.34207804219926174,
                                51.62296121248093
                            ],
                            [
                                -0.3418617527947845,
                                51.62146180616216
                            ],
                            [
                                -0.34245651762304874,
                                51.62045471617614
                            ],
                            [
                                -0.34579080529616135,
                                51.62042114612501
                            ],
                            [
                                -0.35074717886374174,
                                51.62038757604907
                            ],
                            [
                                -0.353054184304483,
                                51.620622570897666
                            ],
                            [
                                -0.3559739389157812,
                                51.62173036455482
                            ],
                            [
                                -0.3577582334004603,
                                51.62099183845464
                            ],
                            [
                                -0.36053380259838264,
                                51.61832856875645
                            ],
                            [
                                -0.3622099581016016,
                                51.617052829589
                            ],
                            [
                                -0.3628227461059055,
                                51.61676186651022
                            ],
                            [
                                -0.3658145968301767,
                                51.61783617914671
                            ],
                            [
                                -0.3668419178971476,
                                51.61844047068794
                            ],
                            [
                                -0.3677070303737935,
                                51.619469985892636
                            ],
                            [
                                -0.36857214285120676,
                                51.62051066774251
                            ],
                            [
                                -0.3699419233398942,
                                51.6200070905131
                            ],
                            [
                                -0.37635817421386264,
                                51.61803758607775
                            ],
                            [
                                -0.3819814053163668,
                                51.61745567068209
                            ],
                            [
                                -0.3838558229887781,
                                51.61631418448752
                            ],
                            [
                                -0.39029009703889983,
                                51.615374127905
                            ],
                            [
                                -0.39611158308463246,
                                51.61449000930486
                            ],
                            [
                                -0.40018485220491584,
                                51.61334845335844
                            ],
                            [
                                -0.40398774246978064,
                                51.613214151977786
                            ],
                            [
                                -0.41033190063703273,
                                51.613773738442035
                            ],
                            [
                                -0.4116115461762888,
                                51.61414306172938
                            ],
                            [
                                -0.41399062329088565,
                                51.61297910414484
                            ],
                            [
                                -0.415234222477153,
                                51.61203897848631
                            ],
                            [
                                -0.41586503365837757,
                                51.610819024497516
                            ],
                            [
                                -0.41606328860086705,
                                51.610292979175
                            ],
                            [
                                -0.4192353676845073,
                                51.611266717842994
                            ],
                            [
                                -0.42094756946247003,
                                51.61127791012021
                            ],
                            [
                                -0.4228580261836896,
                                51.61118837182613
                            ],
                            [
                                -0.4244620889020041,
                                51.61136744823719
                            ],
                            [
                                -0.4259940589135738,
                                51.61174798326445
                            ],
                            [
                                -0.4283551179186986,
                                51.61177036754813
                            ],
                            [
                                -0.4307161540547213,
                                51.61173679107293
                            ],
                            [
                                -0.4325184717163211,
                                51.61146817837721
                            ],
                            [
                                -0.43350974642950746,
                                51.61112121795924
                            ],
                            [
                                -0.4349696237351566,
                                51.612408315857664
                            ],
                            [
                                -0.43675391821983567,
                                51.61330366678476
                            ],
                            [
                                -0.43884460670670933,
                                51.614590702800655
                            ],
                            [
                                -0.4410794806061915,
                                51.615418863200716
                            ],
                            [
                                -0.4424853209036712,
                                51.61382968571985
                            ],
                            [
                                -0.44789227388679365,
                                51.61368419408194
                            ],
                            [
                                -0.45019924049307747,
                                51.61455713691305
                            ],
                            [
                                -0.45198353497696075,
                                51.61367300239803
                            ],
                            [
                                -0.45540793853294304,
                                51.61231878829557
                            ],
                            [
                                -0.4570480698812389,
                                51.611983021626514
                            ],
                            [
                                -0.4591027120151523,
                                51.6123299754569
                            ],
                            [
                                -0.4604184039074255,
                                51.61213971078152
                            ],
                            [
                                -0.4635724598149409,
                                51.61109883697068
                            ],
                            [
                                -0.46564512512489387,
                                51.60943115068284
                            ],
                            [
                                -0.46816836985047416,
                                51.607640278397895
                            ],
                            [
                                -0.46952010809570766,
                                51.606308271324366
                            ],
                            [
                                -0.4727102411802946,
                                51.60546873141192
                            ],
                            [
                                -0.4778108001613077,
                                51.60403591418881
                            ],
                            [
                                -0.47973928005862376,
                                51.60329710014025
                            ],
                            [
                                -0.4812532268941254,
                                51.6038456147501
                            ],
                            [
                                -0.48246077972672197,
                                51.604349346819674
                            ],
                            [
                                -0.4817939221925087,
                                51.60502098088659
                            ],
                            [
                                -0.48085671700835064,
                                51.607013436828794
                            ],
                            [
                                -0.4799195118250452,
                                51.60881552672518
                            ],
                            [
                                -0.47741429947311076,
                                51.612094914644985
                            ],
                            [
                                -0.47687360417469904,
                                51.61399752739035
                            ],
                            [
                                -0.4760625624171553,
                                51.61609030739817
                            ],
                            [
                                -0.47530558899941866,
                                51.61783608372045
                            ],
                            [
                                -0.47292652968729953,
                                51.6192125138578
                            ],
                            [
                                -0.48056836337440245,
                                51.62362129534563
                            ],
                            [
                                -0.48876891565427627,
                                51.627425491679986
                            ],
                            [
                                -0.49158053120513046,
                                51.630692355866046
                            ],
                            [
                                -0.49695143783534945,
                                51.631643282396965
                            ],
                            [
                                -0.4957619081788778,
                                51.62867856329004
                            ],
                            [
                                -0.49282413039142625,
                                51.626899638819765
                            ],
                            [
                                -0.49451830899334936,
                                51.62510945573047
                            ],
                            [
                                -0.4953834214700237,
                                51.62540036528898
                            ],
                            [
                                -0.4939235441651135,
                                51.62676537753825
                            ],
                            [
                                -0.4966089974801662,
                                51.6283876747508
                            ],
                            [
                                -0.4971857391315666,
                                51.629965163303666
                            ],
                            [
                                -0.49859154690733476,
                                51.63092729251014
                            ],
                            [
                                -0.4998351498133502,
                                51.628309360871754
                            ],
                            [
                                -0.4975822527370042,
                                51.62668706085873
                            ],
                            [
                                -0.5000153815789758,
                                51.623710828627395
                            ],
                            [
                                -0.4995467824541322,
                                51.62128270254618
                            ],
                            [
                                -0.4971677231412457,
                                51.6207343986062
                            ],
                            [
                                -0.4954735445401184,
                                51.619246111657645
                            ],
                            [
                                -0.4963026121024541,
                                51.61667226384006
                            ],
                            [
                                -0.49835725423560007,
                                51.612307585198124
                            ],
                            [
                                -0.4989339995617854,
                                51.61020343490296
                            ],
                            [
                                -0.4982851652036686,
                                51.609576648302294
                            ],
                            [
                                -0.4987898141482958,
                                51.60859168044462
                            ],
                            [
                                -0.4983392347335496,
                                51.60759549803444
                            ],
                            [
                                -0.497402024948542,
                                51.60455084553894
                            ],
                            [
                                -0.4962124952920419,
                                51.6021216968347
                            ],
                            [
                                -0.5007543357978079,
                                51.60046487571131
                            ],
                            [
                                -0.4998052942528659,
                                51.597411893919315
                            ],
                            [
                                -0.49894018177545263,
                                51.59286621462422
                            ],
                            [
                                -0.4977060209415072,
                                51.58998373810002
                            ],
                            [
                                -0.4960839350470394,
                                51.58844965124595
                            ],
                            [
                                -0.49505661882477625,
                                51.58675872929021
                            ],
                            [
                                -0.49500254929495213,
                                51.58492216767456
                            ],
                            [
                                -0.4937949964623556,
                                51.58395906300939
                            ],
                            [
                                -0.49240721186342284,
                                51.58488857134384
                            ],
                            [
                                -0.48901885466042927,
                                51.586377984811065
                            ],
                            [
                                -0.48507175751859677,
                                51.5879681572294
                            ],
                            [
                                -0.48103456595754324,
                                51.58977100300061
                            ],
                            [
                                -0.48022352300989724,
                                51.590062139086115
                            ],
                            [
                                -0.4747805236738145,
                                51.58668036644707
                            ],
                            [
                                -0.4712479810582124,
                                51.584407046673874
                            ],
                            [
                                -0.47040088092910537,
                                51.58326915953302
                            ],
                            [
                                -0.47067122857831123,
                                51.580928470590464
                            ],
                            [
                                -0.4704549504589579,
                                51.5800660811135
                            ],
                            [
                                -0.47067122857831123,
                                51.57863246239128
                            ],
                            [
                                -0.4701665796328598,
                                51.57704198870644
                            ],
                            [
                                -0.46910320991457866,
                                51.57509302155225
                            ],
                            [
                                -0.46749914719634944,
                                51.57425292414894
                            ],
                            [
                                -0.46656194201219137,
                                51.57137407261587
                            ],
                            [
                                -0.4654598073771581,
                                51.56820150906333
                            ],
                            [
                                -0.464756903489274,
                                51.56596087516556
                            ],
                            [
                                -0.4641801567434811,
                                51.56422430243029
                            ],
                            [
                                -0.4633330674429317,
                                51.56351845292528
                            ],
                            [
                                -0.46430631898005004,
                                51.56312630957629
                            ],
                            [
                                -0.466108636640854,
                                51.56230839970971
                            ],
                            [
                                -0.46800107018520976,
                                51.56052687387617
                            ],
                            [
                                -0.4686859542381683,
                                51.559395162036054
                            ],
                            [
                                -0.46809118940993244,
                                51.55900298312963
                            ],
                            [
                                -0.4688121164739698,
                                51.5579048641996
                            ],
                            [
                                -0.4704882718989438,
                                51.55826343656139
                            ],
                            [
                                -0.4723807054432996,
                                51.55875646894296
                            ],
                            [
                                -0.476435920180478,
                                51.559227085775916
                            ],
                            [
                                -0.4769045227721733,
                                51.55685154153687
                            ],
                            [
                                -0.4775533571302333,
                                51.55506980192985
                            ],
                            [
                                -0.47886904902244964,
                                51.55486809116118
                            ],
                            [
                                -0.480797537256592,
                                51.55322074577617
                            ],
                            [
                                -0.4828161330375167,
                                51.551506106031866
                            ],
                            [
                                -0.4840597322230451,
                                51.55147248499864
                            ],
                            [
                                -0.4849248447004584,
                                51.55083368064663
                            ],
                            [
                                -0.4835370601014972,
                                51.55025090393991
                            ],
                            [
                                -0.48303241115687,
                                51.5498698535948
                            ],
                            [
                                -0.48297834162698905,
                                51.549264649428125
                            ],
                            [
                                -0.4830143745713258,
                                51.5484464875741
                            ],
                            [
                                -0.4827260037452561,
                                51.54835682540008
                            ],
                            [
                                -0.48222135480057204,
                                51.547942135548595
                            ],
                            [
                                -0.48279809645194405,
                                51.547292073579655
                            ],
                            [
                                -0.48274402692211993,
                                51.54676529241297
                            ],
                            [
                                -0.4821132157408954,
                                51.54575654549734
                            ],
                            [
                                -0.4817887985618938,
                                51.54544270856263
                            ],
                            [
                                -0.4800946199607665,
                                51.54474777621769
                            ],
                            [
                                -0.4796079941921789,
                                51.54450118476967
                            ],
                            [
                                -0.48020275902041476,
                                51.54423217439333
                            ],
                            [
                                -0.48119403373362957,
                                51.5438174469476
                            ],
                            [
                                -0.4818969376215989,
                                51.54395195356
                            ],
                            [
                                -0.4822033316237082,
                                51.54433305347084
                            ],
                            [
                                -0.4829603050414448,
                                51.544613271957274
                            ],
                            [
                                -0.4857178510632423,
                                51.54435547101306
                            ],
                            [
                                -0.4865829635399166,
                                51.54416492155073
                            ],
                            [
                                -0.48761027879979224,
                                51.54327941097958
                            ],
                            [
                                -0.4882771363346876,
                                51.542875883984124
                            ],
                            [
                                -0.4886736462204624,
                                51.541945516442325
                            ],
                            [
                                -0.4895928282269608,
                                51.541104806521076
                            ],
                            [
                                -0.4905841029409146,
                                51.54067884089895
                            ],
                            [
                                -0.49215211930624037,
                                51.54048827604058
                            ],
                            [
                                -0.493738158847691,
                                51.54054432461109
                            ],
                            [
                                -0.4954323374488183,
                                51.538515322374565
                            ],
                            [
                                -0.4936120054656499,
                                51.536374119371885
                            ],
                            [
                                -0.49087248262148364,
                                51.533806788794266
                            ],
                            [
                                -0.49011551517381235,
                                51.53170650442746
                            ],
                            [
                                -0.4891963331665181,
                                51.52940801018235
                            ],
                            [
                                -0.48634867126193626,
                                51.53002469076057
                            ],
                            [
                                -0.48526728066516966,
                                51.53011438905824
                            ],
                            [
                                -0.483428916651377,
                                51.52991256764065
                            ],
                            [
                                -0.4823835724075707,
                                51.52978923188911
                            ],
                            [
                                -0.48232950287771814,
                                51.530091964500144
                            ],
                            [
                                -0.4827620591164532,
                                51.53131408679593
                            ],
                            [
                                -0.4829783372357781,
                                51.532098918676326
                            ],
                            [
                                -0.4829422908827894,
                                51.53246890615574
                            ],
                            [
                                -0.4822213638180699,
                                51.53244648275793
                            ],
                            [
                                -0.48213124793522866,
                                51.531538325856644
                            ],
                            [
                                -0.4817707844032384,
                                51.530719847942436
                            ],
                            [
                                -0.48168066851962976,
                                51.53003590305755
                            ],
                            [
                                -0.4815725294599815,
                                51.529452859960344
                            ],
                            [
                                -0.482924267705954,
                                51.52935194789703
                            ],
                            [
                                -0.4844742608944159,
                                51.52963225863178
                            ],
                            [
                                -0.4857899527866607,
                                51.52965468341583
                            ],
                            [
                                -0.48771843268400517,
                                51.529273460582374
                            ],
                            [
                                -0.4889800550471648,
                                51.52892587227524
                            ],
                            [
                                -0.4896288894052816,
                                51.52758034413327
                            ],
                            [
                                -0.490512030425748,
                                51.52531527821455
                            ],
                            [
                                -0.4906742390153056,
                                51.52251182741085
                            ],
                            [
                                -0.49140811044318866,
                                51.519145238028074
                            ],
                            [
                                -0.4920929911541805,
                                51.51635262303418
                            ],
                            [
                                -0.48984010568915437,
                                51.51444592498419
                            ],
                            [
                                -0.48974998980634155,
                                51.511675455496174
                            ],
                            [
                                -0.4868662714642369,
                                51.51062106259499
                            ],
                            [
                                -0.4847395366243745,
                                51.5090730797601
                            ],
                            [
                                -0.4831534970829523,
                                51.50726703330949
                            ],
                            [
                                -0.484415762512441,
                                51.50552719658657
                            ],
                            [
                                -0.4845779711019418,
                                51.50374344712429
                            ],
                            [
                                -0.48537096401659596,
                                51.502520586738626
                            ],
                            [
                                -0.4827576034082597,
                                51.50091623138678
                            ],
                            [
                                -0.4832081828237733,
                                51.500489889696894
                            ],
                            [
                                -0.4859116593150077,
                                51.50215035590122
                            ],
                            [
                                -0.48838083451073544,
                                51.50035525465037
                            ],
                            [
                                -0.4925982578374146,
                                51.497987856767224
                            ],
                            [
                                -0.48805640793096927,
                                51.495092947233815
                            ],
                            [
                                -0.48378491507443755,
                                51.49484608678242
                            ],
                            [
                                -0.47670180666628426,
                                51.494924633434806
                            ],
                            [
                                -0.46949253153405834,
                                51.4956652089148
                            ],
                            [
                                -0.46332860513317087,
                                51.49603549243585
                            ],
                            [
                                -0.45914722815945197,
                                51.49619258150619
                            ],
                            [
                                -0.45556058681012246,
                                51.49637210921816
                            ],
                            [
                                -0.45388443138512,
                                51.49670872550516
                            ],
                            [
                                -0.45220827596082813,
                                51.496495535478545
                            ],
                            [
                                -0.45071235230224715,
                                51.496170138252694
                            ],
                            [
                                -0.4446024954311554,
                                51.49544079120446
                            ],
                            [
                                -0.43988040833667696,
                                51.494745092625635
                            ],
                            [
                                -0.4315717239190633,
                                51.49410549200218
                            ],
                            [
                                -0.4201991600105828,
                                51.49333122724377
                            ],
                            [
                                -0.4130619820725485,
                                51.493084357251746
                            ],
                            [
                                -0.41070094593652584,
                                51.49325267784579
                            ],
                            [
                                -0.4088265355690339,
                                51.49288237171851
                            ],
                            [
                                -0.4091509527480355,
                                51.49061558380231
                            ],
                            [
                                -0.4112236180580737,
                                51.490828801329485
                            ],
                            [
                                -0.4116201279438201,
                                51.49167043972537
                            ],
                            [
                                -0.41531487914892296,
                                51.49222030175201
                            ],
                            [
                                -0.4206497394254143,
                                51.492590613257306
                            ],
                            [
                                -0.43189621539997347,
                                51.49335367139864
                            ],
                            [
                                -0.4453234877771308,
                                51.4945655600651
                            ],
                            [
                                -0.45138381229412516,
                                51.494898997754945
                            ],
                            [
                                -0.4521082360336095,
                                51.494406025365635
                            ],
                            [
                                -0.4552417898810859,
                                51.49500388480919
                            ],
                            [
                                -0.46059915613889757,
                                51.495381476207086
                            ],
                            [
                                -0.46669782100389057,
                                51.49475215916195
                            ],
                            [
                                -0.4747338703884907,
                                51.49404940740774
                            ],
                            [
                                -0.48076515076277815,
                                51.49381865852402
                            ],
                            [
                                -0.4869143290129898,
                                51.49385012541143
                            ],
                            [
                                -0.49025004762634694,
                                51.4932312726381
                            ],
                            [
                                -0.4937205427483491,
                                51.4910285081568
                            ],
                            [
                                -0.4958095815658794,
                                51.48787102500205
                            ],
                            [
                                -0.49936430950711497,
                                51.48405237659827
                            ],
                            [
                                -0.49134510718505453,
                                51.483832060590345
                            ],
                            [
                                -0.49153042488558185,
                                51.48327602021823
                            ],
                            [
                                -0.4925075545799018,
                                51.48266751543733
                            ],
                            [
                                -0.4939901046107593,
                                51.4797297918079
                            ],
                            [
                                -0.49361946920978994,
                                51.47892188466935
                            ],
                            [
                                -0.49429335175747724,
                                51.47848120201624
                            ],
                            [
                                -0.49525363438786485,
                                51.47649807738631
                            ],
                            [
                                -0.4955400453305572,
                                51.475438271042975
                            ],
                            [
                                -0.49466399801701755,
                                51.475375311830646
                            ],
                            [
                                -0.49388903308712884,
                                51.475091994299845
                            ],
                            [
                                -0.4936700212587368,
                                51.47436795261544
                            ],
                            [
                                -0.4951188687377055,
                                51.47402166774779
                            ],
                            [
                                -0.49399011546933025,
                                51.472877859654005
                            ],
                            [
                                -0.4918505383786851,
                                51.47093646834523
                            ],
                            [
                                -0.49136197633924894,
                                51.469467247743694
                            ],
                            [
                                -0.49153044697720816,
                                51.46689600605873
                            ],
                            [
                                -0.4923391060350184,
                                51.46482841274903
                            ],
                            [
                                -0.49237281883799255,
                                51.463894282644844
                            ],
                            [
                                -0.49195164224653354,
                                51.46336948712022
                            ],
                            [
                                -0.4924065129658288,
                                51.46285518165095
                            ],
                            [
                                -0.49046910063967175,
                                51.46231987796554
                            ],
                            [
                                -0.4898120651559168,
                                51.46179506433543
                            ],
                            [
                                -0.4889360178438267,
                                51.461732086294234
                            ],
                            [
                                -0.48711653496380336,
                                51.46129123757245
                            ],
                            [
                                -0.4836460398403517,
                                51.46115478353346
                            ],
                            [
                                -0.48057985148767557,
                                51.461238760288154
                            ],
                            [
                                -0.4780022507416959,
                                51.46132273189798
                            ],
                            [
                                -0.4765870973905919,
                                51.46103932709511
                            ],
                            [
                                -0.4761153796073643,
                                51.4605564852298
                            ],
                            [
                                -0.4757447442063665,
                                51.46008413498362
                            ],
                            [
                                -0.4743127437913017,
                                51.4591499167681
                            ],
                            [
                                -0.4731502963963976,
                                51.458646060804
                            ],
                            [
                                -0.4707580133510021,
                                51.45823667373756
                            ],
                            [
                                -0.4662766514064458,
                                51.45781678900997
                            ],
                            [
                                -0.46351373295996723,
                                51.4574808758405
                            ],
                            [
                                -0.46080135570392144,
                                51.457207944570456
                            ],
                            [
                                -0.45935250822634544,
                                51.456777549636314
                            ],
                            [
                                -0.45741509590018836,
                                51.45574878432606
                            ],
                            [
                                -0.45578093072066395,
                                51.455339371273425
                            ],
                            [
                                -0.4553429070653294,
                                51.455664802460916
                            ],
                            [
                                -0.454096224350792,
                                51.455591318201925
                            ],
                            [
                                -0.4537929772041025,
                                51.455192401589784
                            ],
                            [
                                -0.45418045966903264,
                                51.45464651004983
                            ],
                            [
                                -0.456791754542877,
                                51.45525538865505
                            ],
                            [
                                -0.4578699666194268,
                                51.45560181596059
                            ],
                            [
                                -0.45886394337776437,
                                51.456063714944804
                            ],
                            [
                                -0.45926827290662686,
                                51.45292480935598
                            ],
                            [
                                -0.4612899237671684,
                                51.45239988347478
                            ],
                            [
                                -0.46152578265807165,
                                51.449019243359345
                            ],
                            [
                                -0.4575835454160426,
                                51.44917673693118
                            ],
                            [
                                -0.4576003924799181,
                                51.44704534251338
                            ],
                            [
                                -0.4516533789941377,
                                51.448221296564896
                            ],
                            [
                                -0.4482502721264723,
                                51.448966751751755
                            ],
                            [
                                -0.44734053068648905,
                                51.44973319298637
                            ],
                            [
                                -0.44621177741805695,
                                51.44973319298637
                            ],
                            [
                                -0.445723212570897,
                                51.449061245202074
                            ],
                            [
                                -0.44644763631040973,
                                51.44845228398023
                            ],
                            [
                                -0.4478627896615137,
                                51.44732883420696
                            ],
                            [
                                -0.4493790255214094,
                                51.445554349483416
                            ],
                            [
                                -0.45121535546533664,
                                51.44285575232564
                            ],
                            [
                                -0.4530516854078712,
                                51.440367021389505
                            ],
                            [
                                -0.45386034322837077,
                                51.43844524674478
                            ],
                            [
                                -0.44636336411045363,
                                51.43993647213364
                            ],
                            [
                                -0.4477953645255184,
                                51.43499006397553
                            ],
                            [
                                -0.4419494334205467,
                                51.43468549055362
                            ],
                            [
                                -0.43986039752175543,
                                51.43456996217239
                            ],
                            [
                                -0.4372996438397365,
                                51.43508458634881
                            ],
                            [
                                -0.43254877187530383,
                                51.436008794803655
                            ],
                            [
                                -0.4315547951169947,
                                51.43650239847773
                            ],
                            [
                                -0.4291793450713328,
                                51.43721654310241
                            ],
                            [
                                -0.42693868559848624,
                                51.43744758618536
                            ],
                            [
                                -0.42540560280119166,
                                51.43759461299382
                            ],
                            [
                                -0.4252708262925182,
                                51.43734256674722
                            ],
                            [
                                -0.425692002883892,
                                51.436554913262626
                            ],
                            [
                                -0.4260963324128113,
                                51.43481152525143
                            ],
                            [
                                -0.4262479559868382,
                                51.43359321461776
                            ],
                            [
                                -0.4268544595828132,
                                51.43263744466955
                            ],
                            [
                                -0.42720824791987866,
                                51.431744677745854
                            ],
                            [
                                -0.4272419420478002,
                                51.43083088647478
                            ],
                            [
                                -0.4237377527978481,
                                51.430914914021685
                            ],
                            [
                                -0.4227943172299433,
                                51.431524109112445
                            ],
                            [
                                -0.42055364804869555,
                                51.4321963358893
                            ],
                            [
                                -0.4189363299331035,
                                51.432280360924466
                            ],
                            [
                                -0.4184140709580788,
                                51.43309959691979
                            ],
                            [
                                -0.4169146822886489,
                                51.433383175187714
                            ],
                            [
                                -0.41512889353654714,
                                51.43323613482423
                            ],
                            [
                                -0.41155731603228674,
                                51.432301367159425
                            ],
                            [
                                -0.4081542091645929,
                                51.431723692186324
                            ],
                            [
                                -0.4057787416847418,
                                51.43091493989024
                            ],
                            [
                                -0.4037739411042196,
                                51.430841415795356
                            ],
                            [
                                -0.40222401124441376,
                                51.430799401973644
                            ],
                            [
                                -0.40123003448616146,
                                51.43061033929848
                            ],
                            [
                                -0.4010952579760101,
                                51.430043146578925
                            ],
                            [
                                -0.40266203490116936,
                                51.42937090905292
                            ],
                            [
                                -0.40416142357057083,
                                51.427396154119606
                            ],
                            [
                                -0.40630099683625076,
                                51.42445487251575
                            ],
                            [
                                -0.4074465971685868,
                                51.42336234790898
                            ],
                            [
                                -0.4055765730967664,
                                51.422553442670505
                            ],
                            [
                                -0.40362231370806967,
                                51.42322578049692
                            ],
                            [
                                -0.3987029711070136,
                                51.42366699681958
                            ],
                            [
                                -0.3945417071716406,
                                51.42328882150764
                            ],
                            [
                                -0.3907342707750274,
                                51.42218576358502
                            ],
                            [
                                -0.3865898931039453,
                                51.420158168626415
                            ],
                            [
                                -0.3876512588163905,
                                51.419317675955654
                            ],
                            [
                                -0.386404576103331,
                                51.414652729701544
                            ],
                            [
                                -0.38210857485955785,
                                51.41427446995115
                            ],
                            [
                                -0.37875600918226837,
                                51.41398026575871
                            ],
                            [
                                -0.3757740789073978,
                                51.41417990452442
                            ],
                            [
                                -0.3736008076902806,
                                51.41411686079789
                            ],
                            [
                                -0.37019768236922346,
                                51.413749101324754
                            ],
                            [
                                -0.3666934931192998,
                                51.4133393116868
                            ],
                            [
                                -0.36502563381333175,
                                51.41346540119741
                            ],
                            [
                                -0.3632229979972408,
                                51.41336032662923
                            ],
                            [
                                -0.36059485605946406,
                                51.4129610410684
                            ],
                            [
                                -0.358488973096712,
                                51.41265632079421
                            ],
                            [
                                -0.3563493844017671,
                                51.4120889052779
                            ],
                            [
                                -0.35154797124693005,
                                51.410481189563086
                            ],
                            [
                                -0.3468307934103052,
                                51.40825341160439
                            ],
                            [
                                -0.3450113105303103,
                                51.40732864170397
                            ],
                            [
                                -0.3424337689068295,
                                51.405867903814624
                            ],
                            [
                                -0.34145663921248115,
                                51.405489571379576
                            ],
                            [
                                -0.3409175331734673,
                                51.40514276389774
                            ],
                            [
                                -0.34213052176016845,
                                51.4035453136604
                            ],
                            [
                                -0.34012572117964623,
                                51.402599428666036
                            ],
                            [
                                -0.33751442632791395,
                                51.39999288877263
                            ],
                            [
                                -0.3330162636916043,
                                51.39651376911658
                            ],
                            [
                                -0.33080929834665085,
                                51.39379125093808
                            ],
                            [
                                -0.3282317193958022,
                                51.39200418862703
                            ],
                            [
                                -0.3256541186497941,
                                51.391415491749626
                            ],
                            [
                                -0.3197070920726617,
                                51.39264544646778
                            ],
                            [
                                -0.3177191385560718,
                                51.39363358534777
                            ],
                            [
                                -0.3168430912439817,
                                51.3930238851562
                            ],
                            [
                                -0.3141644081157722,
                                51.39448504960342
                            ],
                            [
                                -0.3125976311906129,
                                51.39303439729747
                            ],
                            [
                                -0.3102390422729968,
                                51.39198317123052
                            ],
                            [
                                -0.30848694764733864,
                                51.39113166041054
                            ],
                            [
                                -0.30543762256857576,
                                51.389575772706735
                            ],
                            [
                                -0.3044099516824019,
                                51.38917627944849
                            ],
                            [
                                -0.30275893944036625,
                                51.38826163701364
                            ],
                            [
                                -0.3009394565604282,
                                51.38717876083945
                            ],
                            [
                                -0.3003666563949423,
                                51.386831814508895
                            ],
                            [
                                -0.2999286327381583,
                                51.38534937782924
                            ],
                            [
                                -0.29994547227440194,
                                51.38386688717469
                            ],
                            [
                                -0.30029926061155265,
                                51.38273133449596
                            ],
                            [
                                -0.3012426961794006,
                                51.38041808447679
                            ],
                            [
                                -0.3023714466442584,
                                51.3775684172073
                            ],
                            [
                                -0.3032643410203093,
                                51.37540213315495
                            ],
                            [
                                -0.29849662199333693,
                                51.37455031377323
                            ],
                            [
                                -0.29403215011302564,
                                51.37369847854171
                            ],
                            [
                                -0.29098282880852366,
                                51.37380364382591
                            ],
                            [
                                -0.28823675742458477,
                                51.374245337132066
                            ],
                            [
                                -0.2854232977862807,
                                51.37548626218717
                            ],
                            [
                                -0.28336795601535414,
                                51.377021597479
                            ],
                            [
                                -0.2816832575363435,
                                51.3787251221718
                            ],
                            [
                                -0.2812789280074526,
                                51.379692531274884
                            ],
                            [
                                -0.28031864537706497,
                                51.379944895504394
                            ],
                            [
                                -0.2790551155985952,
                                51.381332873895275
                            ],
                            [
                                -0.2760226474409819,
                                51.38436104176361
                            ],
                            [
                                -0.27378198796813535,
                                51.38652690184284
                            ],
                            [
                                -0.2720635874703987,
                                51.387756980614
                            ],
                            [
                                -0.2677675952691061,
                                51.38981754865864
                            ],
                            [
                                -0.2646677355480733,
                                51.39131035312795
                            ],
                            [
                                -0.2625113113949453,
                                51.39230903315118
                            ],
                            [
                                -0.2593440431786007,
                                51.39370715920742
                            ],
                            [
                                -0.2568169836230254,
                                51.39435889765434
                            ],
                            [
                                -0.2548795712968399,
                                51.394758345661586
                            ],
                            [
                                -0.2542225358131418,
                                51.39517881348041
                            ],
                            [
                                -0.25307693548077737,
                                51.395094720225984
                            ],
                            [
                                -0.2528073824620094,
                                51.39443248044424
                            ],
                            [
                                -0.2529927066657365,
                                51.394180191385715
                            ],
                            [
                                -0.25284108309165276,
                                51.39355998646073
                            ],
                            [
                                -0.2523525182445212,
                                51.39179393316101
                            ],
                            [
                                -0.2525546830089809,
                                51.39082677984629
                            ],
                            [
                                -0.2527063065829793,
                                51.38938652452018
                            ],
                            [
                                -0.2526052242007779,
                                51.38852445219868
                            ],
                            [
                                -0.25277369976367936,
                                51.38791467896192
                            ],
                            [
                                -0.25366659413975867,
                                51.38654792744114
                            ],
                            [
                                -0.2521335113424925,
                                51.3853598718012
                            ],
                            [
                                -0.24839346320024447,
                                51.382889037848315
                            ],
                            [
                                -0.24667505655349942,
                                51.381900665786304
                            ],
                            [
                                -0.2440132204892791,
                                51.38054424965347
                            ],
                            [
                                -0.24335618500407463,
                                51.37962943468048
                            ],
                            [
                                -0.2398351415188813,
                                51.37807315556432
                            ],
                            [
                                -0.23811674102111624,
                                51.377021585762634
                            ],
                            [
                                -0.23705537600710613,
                                51.37694797497167
                            ],
                            [
                                -0.23572445797566388,
                                51.376096184354395
                            ],
                            [
                                -0.2329446924638603,
                                51.37395086368417
                            ],
                            [
                                -0.2313105212800508,
                                51.37300436590141
                            ],
                            [
                                -0.22959212078228575,
                                51.37228922188578
                            ],
                            [
                                -0.22834543806769148,
                                51.37148993006099
                            ],
                            [
                                -0.2276715555200326,
                                51.37098510698195
                            ],
                            [
                                -0.22977743848275622,
                                51.36904990028225
                            ],
                            [
                                -0.23186647212753542,
                                51.36711460805728
                            ],
                            [
                                -0.23306261365021896,
                                51.36581034576602
                            ],
                            [
                                -0.22681233546774138,
                                51.36257055982193
                            ],
                            [
                                -0.22445374654873262,
                                51.35965666585423
                            ],
                            [
                                -0.22295439184870247,
                                51.35740536291303
                            ],
                            [
                                -0.2193322731526166,
                                51.3578261739591
                            ],
                            [
                                -0.21739486082788062,
                                51.35780513349829
                            ],
                            [
                                -0.21582808390274977,
                                51.357973456911736
                            ],
                            [
                                -0.21520474254543842,
                                51.35831010188258
                            ],
                            [
                                -0.2140928363424166,
                                51.358751944652425
                            ],
                            [
                                -0.21269453005524497,
                                51.35904650413141
                            ],
                            [
                                -0.21178478861526173,
                                51.35726859849106
                            ],
                            [
                                -0.21072342360116636,
                                51.354911978159095
                            ],
                            [
                                -0.20888723269993648,
                                51.35278670515925
                            ],
                            [
                                -0.20662972616455022,
                                51.34978801226288
                            ],
                            [
                                -0.20408581727687647,
                                51.34488446114776
                            ],
                            [
                                -0.20253588741564954,
                                51.34179053598916
                            ],
                            [
                                -0.2014913694670213,
                                51.340033009295695
                            ],
                            [
                                -0.19935179237637612,
                                51.342537727520494
                            ],
                            [
                                -0.19818934498152885,
                                51.34400050384312
                            ],
                            [
                                -0.19574652074419419,
                                51.343474330686234
                            ],
                            [
                                -0.19396070286322242,
                                51.34295867081997
                            ],
                            [
                                -0.19379223222665587,
                                51.342579818144344
                            ],
                            [
                                -0.18860333660686024,
                                51.34099070752609
                            ],
                            [
                                -0.18536870037570452,
                                51.33948573740446
                            ],
                            [
                                -0.1791113970633944,
                                51.336032175158124
                            ],
                            [
                                -0.175025353356574,
                                51.33380872349085
                            ],
                            [
                                -0.1737449765155361,
                                51.33434553322911
                            ],
                            [
                                -0.172397201825504,
                                51.33497707742623
                            ],
                            [
                                -0.1697522128251876,
                                51.33493497550907
                            ],
                            [
                                -0.16732623565184213,
                                51.335019179305164
                            ],
                            [
                                -0.16702298850518105,
                                51.33484024605346
                            ],
                            [
                                -0.167376776843696,
                                51.3342297626441
                            ],
                            [
                                -0.16845498892027422,
                                51.331861431103135
                            ],
                            [
                                -0.16941528131502537,
                                51.33031404947556
                            ],
                            [
                                -0.16961744607948503,
                                51.329503498341694
                            ],
                            [
                                -0.1629965500445678,
                                51.33024036360075
                            ],
                            [
                                -0.16239005575127408,
                                51.329008739308904
                            ],
                            [
                                -0.1641926915673082,
                                51.328135002872756
                            ],
                            [
                                -0.16383890323027117,
                                51.32650328182032
                            ],
                            [
                                -0.16410847322435984,
                                51.32601900916222
                            ],
                            [
                                -0.16067167223019396,
                                51.32319754405026
                            ],
                            [
                                -0.15858263633001002,
                                51.32389239858327
                            ],
                            [
                                -0.1565272966712996,
                                51.32138665769966
                            ],
                            [
                                -0.16206998062821754,
                                51.319649406722306
                            ],
                            [
                                -0.16084014497903354,
                                51.31705920126964
                            ],
                            [
                                -0.1592902151178066,
                                51.31751197130737
                            ],
                            [
                                -0.1563082848429076,
                                51.31782785473402
                            ],
                            [
                                -0.15270301321081092,
                                51.31852279061599
                            ],
                            [
                                -0.1501422595273425,
                                51.31918612868259
                            ],
                            [
                                -0.148070070692512,
                                51.319649406722306
                            ],
                            [
                                -0.14593046385374464,
                                51.319712590485665
                            ],
                            [
                                -0.14387512208139697,
                                51.319365132873
                            ],
                            [
                                -0.14136490958978243,
                                51.32000740094122
                            ],
                            [
                                -0.14038777989540563,
                                51.32032326718419
                            ],
                            [
                                -0.14013507394059843,
                                51.31994422743165
                            ],
                            [
                                -0.1393601090092318,
                                51.31898608520302
                            ],
                            [
                                -0.13870307352553368,
                                51.31864915131143
                            ],
                            [
                                -0.13838297931491184,
                                51.317806805754856
                            ],
                            [
                                -0.13742269668452423,
                                51.31694338550639
                            ],
                            [
                                -0.13656349643491694,
                                51.31660643661107
                            ],
                            [
                                -0.1355021312235749,
                                51.31555345111951
                            ],
                            [
                                -0.13514834288648103,
                                51.31450044570116
                            ],
                            [
                                -0.1346429309753887,
                                51.31388969148438
                            ],
                            [
                                -0.13268867158527087,
                                51.312910361717655
                            ],
                            [
                                -0.1295045765459406,
                                51.31146765505787
                            ],
                            [
                                -0.12591613856096728,
                                51.309803745172445
                            ],
                            [
                                -0.12372602028000301,
                                51.30896123719893
                            ],
                            [
                                -0.12232771399277453,
                                51.30807658717822
                            ],
                            [
                                -0.1216875255716161,
                                51.30800286557363
                            ],
                            [
                                -0.11966587792713312,
                                51.30736042943229
                            ],
                            [
                                -0.1189751483155419,
                                51.30686543151711
                            ],
                            [
                                -0.11852027643311658,
                                51.305285612056736
                            ],
                            [
                                -0.11836865286051079,
                                51.304042785021124
                            ],
                            [
                                -0.11799801745809191,
                                51.30256820081726
                            ],
                            [
                                -0.11764422912102646,
                                51.30144116523465
                            ],
                            [
                                -0.11683556694137565,
                                51.30038783465557
                            ],
                            [
                                -0.11587528430959537,
                                51.29941875035087
                            ],
                            [
                                -0.1144095897680586,
                                51.298344306879756
                            ],
                            [
                                -0.11324714237181865,
                                51.298017755351424
                            ],
                            [
                                -0.11014728265078588,
                                51.29788081369696
                            ],
                            [
                                -0.10684524399823658,
                                51.2981652332513
                            ],
                            [
                                -0.10366114895890632,
                                51.298470716027566
                            ],
                            [
                                -0.10184166607896827,
                                51.297575329723
                            ],
                            [
                                -0.10049390098214417,
                                51.29645870586239
                            ],
                            [
                                -0.09476588847493872,
                                51.29914488133858
                            ],
                            [
                                -0.09479958260141075,
                                51.29958729456837
                            ],
                            [
                                -0.09725925390262091,
                                51.30047210823574
                            ],
                            [
                                -0.09747826573101293,
                                51.301061974539266
                            ],
                            [
                                -0.09633266539870533,
                                51.30151490244833
                            ],
                            [
                                -0.09557454753272054,
                                51.30139903759476
                            ],
                            [
                                -0.09451318251868202,
                                51.30061957552252
                            ],
                            [
                                -0.0911606168427852,
                                51.301483302971775
                            ],
                            [
                                -0.09087421676008489,
                                51.30289472504242
                            ],
                            [
                                -0.08868409550743195,
                                51.305085504386966
                            ],
                            [
                                -0.08902103678198614,
                                51.30707607268744
                            ],
                            [
                                -0.08676353024515038,
                                51.308740079399115
                            ],
                            [
                                -0.08324248256860756,
                                51.31013021916135
                            ],
                            [
                                -0.08560107148761631,
                                51.313163098206985
                            ],
                            [
                                -0.08708361309305701,
                                51.314974305356
                            ],
                            [
                                -0.08541575378706057,
                                51.31589041197435
                            ],
                            [
                                -0.08403429456242861,
                                51.31661696628507
                            ],
                            [
                                -0.08479241250407199,
                                51.31720662579562
                            ],
                            [
                                -0.08769010746061667,
                                51.31911243546085
                            ],
                            [
                                -0.09046987369387693,
                                51.32101816888084
                            ],
                            [
                                -0.09308116856774973,
                                51.32249216025696
                            ],
                            [
                                -0.09613048709840655,
                                51.32377659983152
                            ],
                            [
                                -0.09961783693754,
                                51.325239984056026
                            ],
                            [
                                -0.10356004984421929,
                                51.32664014809657
                            ],
                            [
                                -0.1059017916992957,
                                51.327471804244624
                            ],
                            [
                                -0.10820983833247055,
                                51.32949297789594
                            ],
                            [
                                -0.10975976819366906,
                                51.33101932885714
                            ],
                            [
                                -0.11200042630397888,
                                51.33376662890174
                            ],
                            [
                                -0.1130786383805571,
                                51.33493497253383
                            ],
                            [
                                -0.11353350910127347,
                                51.33556649723573
                            ],
                            [
                                -0.11478019181438981,
                                51.33642956692586
                            ],
                            [
                                -0.11627958048521236,
                                51.337061071039244
                            ],
                            [
                                -0.11755995732625024,
                                51.33734524505212
                            ],
                            [
                                -0.11799798098161318,
                                51.33815565756123
                            ],
                            [
                                -0.11644805112177892,
                                51.33843982478865
                            ],
                            [
                                -0.11565623912790102,
                                51.337850438947555
                            ],
                            [
                                -0.11499920364278182,
                                51.337239995623406
                            ],
                            [
                                -0.1138872974383105,
                                51.336524293097455
                            ],
                            [
                                -0.11267432267092659,
                                51.33565070051233
                            ],
                            [
                                -0.11206782837754758,
                                51.33492444768871
                            ],
                            [
                                -0.11149502821069746,
                                51.33408240102716
                            ],
                            [
                                -0.1090522039748123,
                                51.33120880035446
                            ],
                            [
                                -0.10743488901476894,
                                51.329608767456875
                            ],
                            [
                                -0.10568279439053185,
                                51.328103423650475
                            ],
                            [
                                -0.10393069976484526,
                                51.32729283343963
                            ],
                            [
                                -0.10091504093750814,
                                51.32625062139877
                            ],
                            [
                                -0.09813527542712563,
                                51.32521891696081
                            ],
                            [
                                -0.09324962695396266,
                                51.32322913571406
                            ],
                            [
                                -0.09043616731565862,
                                51.32171305396372
                            ],
                            [
                                -0.08698251342497088,
                                51.31945989299953
                            ],
                            [
                                -0.08396688902226401,
                                51.31739615538601
                            ],
                            [
                                -0.08341093591934623,
                                51.31671173210523
                            ],
                            [
                                -0.0815240647849862,
                                51.316795969367604
                            ],
                            [
                                -0.07889592284857372,
                                51.31801739229235
                            ],
                            [
                                -0.07884536796896668,
                                51.319702069958225
                            ],
                            [
                                -0.07114625985755652,
                                51.32109187440818
                            ],
                            [
                                -0.07178644827874336,
                                51.32254480676352
                            ],
                            [
                                -0.07208969542401178,
                                51.324229308400504
                            ],
                            [
                                -0.07134842462198776,
                                51.325303145901955
                            ],
                            [
                                -0.0698658833556749,
                                51.325861107875824
                            ],
                            [
                                -0.07111256606881966,
                                51.32696648912096
                            ],
                            [
                                -0.07296574307670767,
                                51.32782972070734
                            ],
                            [
                                -0.07385863745278698,
                                51.328482397216476
                            ],
                            [
                                -0.0744819788100699,
                                51.32946139456246
                            ],
                            [
                                -0.07561073295059373,
                                51.33108249116546
                            ],
                            [
                                -0.07646993319877993,
                                51.332545633062296
                            ],
                            [
                                -0.07724489813008972,
                                51.3340192539645
                            ],
                            [
                                -0.07768291520869752,
                                51.33510339095233
                            ],
                            [
                                -0.0782894095020481,
                                51.33542967860268
                            ],
                            [
                                -0.07850842133044011,
                                51.33605067126294
                            ],
                            [
                                -0.07803670354581982,
                                51.33650325383482
                            ],
                            [
                                -0.07663839725861976,
                                51.33686110665829
                            ],
                            [
                                -0.07434719659539724,
                                51.33841878640516
                            ],
                            [
                                -0.0723423960148466,
                                51.3396185847169
                            ],
                            [
                                -0.06883820676497976,
                                51.34035528741711
                            ],
                            [
                                -0.06617637791353559,
                                51.3415866044968
                            ],
                            [
                                -0.06366616542331371,
                                51.34287050926713
                            ],
                            [
                                -0.06284065930159954,
                                51.343638730168664
                            ],
                            [
                                -0.06137495617883815,
                                51.34239694182048
                            ],
                            [
                                -0.05967340274492017,
                                51.34081834899641
                            ],
                            [
                                -0.05771914335485917,
                                51.339534386739984
                            ],
                            [
                                -0.05561326039210712,
                                51.338229339066714
                            ],
                            [
                                -0.05276610205791599,
                                51.33655587572525
                            ],
                            [
                                -0.051569960535175596,
                                51.33566123247164
                            ],
                            [
                                -0.0508960779874883,
                                51.33444027938893
                            ],
                            [
                                -0.050424360204260665,
                                51.33330350073666
                            ],
                            [
                                -0.049902101229207574,
                                51.33271404885738
                            ],
                            [
                                -0.04651581546119132,
                                51.333461389451486
                            ],
                            [
                                -0.04208503770735206,
                                51.33367190541864
                            ],
                            [
                                -0.04023186069943563,
                                51.33648220098732
                            ],
                            [
                                -0.037789036463578896,
                                51.33861875237665
                            ],
                            [
                                -0.03460490525165483,
                                51.33803990089305
                            ],
                            [
                                -0.03246532816243075,
                                51.337471559718665
                            ],
                            [
                                -0.030005656861220587,
                                51.33767153241669
                            ],
                            [
                                -0.025052620133720893,
                                51.33845036513708
                            ],
                            [
                                -0.021969607475256225,
                                51.33805042566334
                            ],
                            [
                                -0.020554454125544908,
                                51.336997936690295
                            ],
                            [
                                -0.01977948919420669,
                                51.33706108671012
                            ],
                            [
                                -0.022407631131983408,
                                51.338829251946265
                            ],
                            [
                                -0.024985229352068927,
                                51.34045001909658
                            ],
                            [
                                -0.029028524640949627,
                                51.3437439809523
                            ],
                            [
                                -0.032566408018766424,
                                51.346248496385385
                            ],
                            [
                                -0.03524508182718478,
                                51.34761644028703
                            ],
                            [
                                -0.036137976203207245,
                                51.34852137258076
                            ],
                            [
                                -0.036828705816247975,
                                51.34992082570665
                            ],
                            [
                                -0.03789007082892226,
                                51.35149910501471
                            ],
                            [
                                -0.03824385916595929,
                                51.35233031026149
                            ],
                            [
                                -0.037906917892797765,
                                51.353487659539184
                            ],
                            [
                                -0.03736781185386917,
                                51.354350391798704
                            ],
                            [
                                -0.036121129140695984,
                                51.35381381632757
                            ],
                            [
                                -0.0370477176432189,
                                51.353172021726465
                            ],
                            [
                                -0.037384658917773095,
                                51.352193530687686
                            ],
                            [
                                -0.036205364459021894,
                                51.35008917808946
                            ],
                            [
                                -0.0348576057915011,
                                51.348037339359024
                            ],
                            [
                                -0.03292019346534403,
                                51.347100829444884
                            ],
                            [
                                -0.03130287535117304,
                                51.34613273166286
                            ],
                            [
                                -0.028388328360478,
                                51.344070190637126
                            ],
                            [
                                -0.02547378633997255,
                                51.34155503219668
                            ],
                            [
                                -0.02338475364942383,
                                51.340155319772606
                            ],
                            [
                                -0.01993110558990452,
                                51.33776624467808
                            ],
                            [
                                -0.017286105922153183,
                                51.33572437685464
                            ],
                            [
                                -0.014556881602146632,
                                51.333440329217865
                            ],
                            [
                                -0.01324281063321564,
                                51.33165090365068
                            ],
                            [
                                -0.01066520988723596,
                                51.333682422607836
                            ],
                            [
                                -0.0057795462135459275,
                                51.333587689189585
                            ],
                            [
                                -0.00305032189493204,
                                51.334135027185084
                            ],
                            [
                                -0.005644769704872488,
                                51.33521915815493
                            ],
                            [
                                -0.00480241651919755,
                                51.33569279956089
                            ],
                            [
                                -0.0009444389322084135,
                                51.33601908301631
                            ],
                            [
                                -0.0004727211475881177,
                                51.33488234351148
                            ],
                            [
                                -0.004297010550772029,
                                51.33422975635514
                            ],
                            [
                                -0.0019889628235887358,
                                51.334524473498874
                            ],
                            [
                                -0.00043903296241865064,
                                51.33480866323592
                            ],
                            [
                                0.00038647315790285575,
                                51.33409292275195
                            ],
                            [
                                0.0024081208023289946,
                                51.33279824613746
                            ],
                            [
                                0.0036884976433668726,
                                51.3322719443793
                            ],
                            [
                                0.003452638752406756,
                                51.33045089368119
                            ],
                            [
                                0.0024755090581436434,
                                51.329577184724485
                            ],
                            [
                                0.0028629931913144446,
                                51.3286929297191
                            ],
                            [
                                0.004278146542418426,
                                51.324566192490494
                            ],
                            [
                                0.005474288062345067,
                                51.32138666731251
                            ],
                            [
                                0.005979699973465813,
                                51.318743913984065
                            ],
                            [
                                0.006601140629243218,
                                51.31688496363765
                            ],
                            [
                                0.006769611267230857,
                                51.31567404046817
                            ],
                            [
                                0.010374882899384374,
                                51.31612682417702
                            ],
                            [
                                0.00857224776174803,
                                51.31100905481023
                            ],
                            [
                                0.00951110313422987,
                                51.30720791667133
                            ],
                            [
                                0.012403076461794171,
                                51.29943060707575
                            ],
                            [
                                0.01398670044952155,
                                51.296007048522284
                            ],
                            [
                                0.02052336116608444,
                                51.29559620433827
                            ],
                            [
                                0.020371737592000727,
                                51.29683926008937
                            ],
                            [
                                0.021416255542135332,
                                51.2980296124382
                            ],
                            [
                                0.02294933833800883,
                                51.29931473696047
                            ],
                            [
                                0.023606373823156446,
                                51.30072622571382
                            ],
                            [
                                0.024364490667778682,
                                51.30203233979583
                            ],
                            [
                                0.02626820886604264,
                                51.30361226807565
                            ],
                            [
                                0.02734642094259243,
                                51.304549666480966
                            ],
                            [
                                0.028828962548118398,
                                51.305329061813495
                            ],
                            [
                                0.03029465709104784,
                                51.30576088325884
                            ],
                            [
                                0.030867457256505304,
                                51.306519194013504
                            ],
                            [
                                0.03167611631425871,
                                51.30683515312981
                            ],
                            [
                                0.0325184615438161,
                                51.307572377835385
                            ],
                            [
                                0.027481189496626257,
                                51.308952018323595
                            ],
                            [
                                0.02716109528600441,
                                51.3094469937318
                            ],
                            [
                                0.02818876617220667,
                                51.311900729147965
                            ],
                            [
                                0.028761566175262487,
                                51.31295379427169
                            ],
                            [
                                0.029149048640221054,
                                51.31344872651286
                            ],
                            [
                                0.029907166507626926,
                                51.3132802395406
                            ],
                            [
                                0.029233283959939627,
                                51.31281689717707
                            ],
                            [
                                0.02844147196611857,
                                51.31115303830174
                            ],
                            [
                                0.027784420750691652,
                                51.30927849053043
                            ],
                            [
                                0.031052751109712062,
                                51.30834118871297
                            ],
                            [
                                0.03317548113494695,
                                51.30763556684843
                            ],
                            [
                                0.0337482813018255,
                                51.30705631690981
                            ],
                            [
                                0.03512974052503637,
                                51.30648759167502
                            ],
                            [
                                0.038384507312684946,
                                51.304281150967114
                            ],
                            [
                                0.040894719804299484,
                                51.30274337722528
                            ],
                            [
                                0.041518061160161324,
                                51.30277497583435
                            ],
                            [
                                0.04254573204633516,
                                51.30132141730195
                            ],
                            [
                                0.042848987472723365,
                                51.29924993025227
                            ],
                            [
                                0.0441799055055867,
                                51.29851256708119
                            ],
                            [
                                0.04241097761101287,
                                51.29289768041576
                            ],
                            [
                                0.044331542873237595,
                                51.29334015385231
                            ],
                            [
                                0.04544346046463943,
                                51.294267232159655
                            ],
                            [
                                0.048459084867403135,
                                51.293951186521724
                            ],
                            [
                                0.05083452084892315,
                                51.29425669733993
                            ],
                            [
                                0.051912732925444516,
                                51.2936035338073
                            ],
                            [
                                0.05457456899114277,
                                51.29228664359394
                            ],
                            [
                                0.05868525253438861,
                                51.29366674358752
                            ],
                            [
                                0.06195357276737923,
                                51.29365620530925
                            ],
                            [
                                0.06547460908126368,
                                51.293456040660516
                            ],
                            [
                                0.0701412457259778,
                                51.294498994257026
                            ],
                            [
                                0.0762735602589828,
                                51.29526801929856
                            ],
                            [
                                0.0813613734965486,
                                51.29581581464288
                            ],
                            [
                                0.0832313975670047,
                                51.297058864447735
                            ],
                            [
                                0.08619646257486124,
                                51.29884963449288
                            ],
                            [
                                0.08532041526129319,
                                51.30130393655273
                            ],
                            [
                                0.08267544095014046,
                                51.304842964721956
                            ],
                            [
                                0.08092334632587495,
                                51.30764449534496
                            ],
                            [
                                0.08009783691608163,
                                51.30972973105713
                            ],
                            [
                                0.07760447148839944,
                                51.31102505898113
                            ],
                            [
                                0.07642517702964824,
                                51.312288758301236
                            ],
                            [
                                0.07546489439923221,
                                51.313963106291936
                            ],
                            [
                                0.07436982963440641,
                                51.31599542357711
                            ],
                            [
                                0.08053585494857884,
                                51.31765910684953
                            ],
                            [
                                0.08365256173354396,
                                51.3177854600755
                            ],
                            [
                                0.08547204461356728,
                                51.317311633682436
                            ],
                            [
                                0.08545519754957809,
                                51.31617443037001
                            ],
                            [
                                0.08778009592293756,
                                51.318469869144025
                            ],
                            [
                                0.09394612123713841,
                                51.320628317467566
                            ],
                            [
                                0.09911815974942328,
                                51.32362891441832
                            ],
                            [
                                0.10065124254671787,
                                51.32571342682914
                            ],
                            [
                                0.1027234313815768,
                                51.3260924189066
                            ],
                            [
                                0.10380164345812659,
                                51.32681881163393
                            ],
                            [
                                0.10496409320583666,
                                51.32777679047834
                            ],
                            [
                                0.10449237542260903,
                                51.33162955729634
                            ],
                            [
                                0.10504833949161707,
                                51.33413474720945
                            ],
                            [
                                0.10754170491929926,
                                51.33816590078308
                            ],
                            [
                                0.11018669391961566,
                                51.34094434856752
                            ],
                            [
                                0.11016984668395935,
                                51.34264922311095
                            ],
                            [
                                0.11015299962141967,
                                51.34439612617689
                            ],
                            [
                                0.11060787034071495,
                                51.345227460275765
                            ],
                            [
                                0.11616740136298631,
                                51.344859149560904
                            ],
                            [
                                0.12078350512931024,
                                51.344911767140985
                            ],
                            [
                                0.12578708304866382,
                                51.34690060755389
                            ],
                            [
                                0.1306727315218268,
                                51.34741621875639
                            ],
                            [
                                0.134665489354461,
                                51.34692165383086
                            ],
                            [
                                0.1363333486604006,
                                51.34756353598553
                            ],
                            [
                                0.1368050664450493,
                                51.34974165922657
                            ],
                            [
                                0.13806859622212642,
                                51.350299326306896
                            ],
                            [
                                0.13830445511445077,
                                51.35112003155248
                            ],
                            [
                                0.138894102343869,
                                51.351888114170265
                            ],
                            [
                                0.1384055371229067,
                                51.35269827089547
                            ],
                            [
                                0.13751264274679897,
                                51.35383456859415
                            ],
                            [
                                0.1352045950196441,
                                51.35477094088668
                            ],
                            [
                                0.13284600610063535,
                                51.35613864130218
                            ],
                            [
                                0.13276177078236628,
                                51.3572117315025
                            ],
                            [
                                0.13464864191672632,
                                51.35748526027811
                            ],
                            [
                                0.13715885440836928,
                                51.35720121113218
                            ],
                            [
                                0.1389446431605279,
                                51.35669623052425
                            ],
                            [
                                0.1404103377034005,
                                51.35593874917413
                            ],
                            [
                                0.14229720883778896,
                                51.355370629941916
                            ],
                            [
                                0.1430216325772733,
                                51.35494979633853
                            ],
                            [
                                0.1439482210797962,
                                51.35494979633853
                            ],
                            [
                                0.1457176829748903,
                                51.3576723915379
                            ],
                            [
                                0.1452796535989478,
                                51.36008146621816
                            ],
                            [
                                0.1449932535148264,
                                51.36289014263406
                            ],
                            [
                                0.14787977875749903,
                                51.36607724125051
                            ],
                            [
                                0.14604344881354336,
                                51.36752874816818
                            ],
                            [
                                0.14124203565867788,
                                51.37070507381071
                            ],
                            [
                                0.14090510213040375,
                                51.372356263471204
                            ],
                            [
                                0.13767046589930487,
                                51.37377602167453
                            ],
                            [
                                0.13812533661860016,
                                51.37474353537249
                            ],
                            [
                                0.1376199247074794,
                                51.376226315596625
                            ],
                            [
                                0.13618792429386417,
                                51.37740409261235
                            ],
                            [
                                0.13347554703778997,
                                51.37837152963496
                            ],
                            [
                                0.13147073791873254,
                                51.37884473714141
                            ],
                            [
                                0.12966810210264157,
                                51.37943359575246
                            ],
                            [
                                0.1277643839043776,
                                51.38053768522957
                            ],
                            [
                                0.12653454825377253,
                                51.38082158964767
                            ],
                            [
                                0.12722527786678484,
                                51.382062336438906
                            ],
                            [
                                0.12821925462509398,
                                51.38255652282797
                            ],
                            [
                                0.1289942195550111,
                                51.38393390779163
                            ],
                            [
                                0.13079685537107366,
                                51.384848636730794
                            ],
                            [
                                0.13081370704611572,
                                51.386446743640704
                            ],
                            [
                                0.13145389546738784,
                                51.387266801265326
                            ],
                            [
                                0.13293643707280012,
                                51.38690934205658
                            ],
                            [
                                0.13372824906667802,
                                51.38886482005401
                            ],
                            [
                                0.13416627272198411,
                                51.3908412397779
                            ],
                            [
                                0.13687864768647273,
                                51.39261784098542
                            ],
                            [
                                0.13891714239341013,
                                51.39339573980706
                            ],
                            [
                                0.14024806042624505,
                                51.39390031575431
                            ],
                            [
                                0.14267403759959052,
                                51.39404748269047
                            ],
                            [
                                0.14252241402553523,
                                51.39611828152289
                            ],
                            [
                                0.14346583871335383,
                                51.397369119418755
                            ],
                            [
                                0.14523478917465127,
                                51.40003887246206
                            ],
                            [
                                0.14887375493461263,
                                51.401951748988836
                            ],
                            [
                                0.1496487198645582,
                                51.40239317067221
                            ],
                            [
                                0.14515057594456948,
                                51.404820923265135
                            ],
                            [
                                0.14223603392409245,
                                51.40637629266766
                            ],
                            [
                                0.14090511589122912,
                                51.40809974832456
                            ],
                            [
                                0.14056817461670335,
                                51.40901399416285
                            ],
                            [
                                0.13116752374534713,
                                51.40924518237841
                            ],
                            [
                                0.11989683781771987,
                                51.4112732626906
                            ],
                            [
                                0.11432045973293725,
                                51.4122820146236
                            ],
                            [
                                0.1132422476549948,
                                51.412061352040126
                            ],
                            [
                                0.11219772970625286,
                                51.4121454141021
                            ],
                            [
                                0.11125429413840493,
                                51.41293348841799
                            ],
                            [
                                0.1117091648591213,
                                51.41374256391606
                            ],
                            [
                                0.11261890629910454,
                                51.4139737257143
                            ],
                            [
                                0.11592093078459698,
                                51.41374256391606
                            ],
                            [
                                0.11671274277838961,
                                51.412670798489046
                            ],
                            [
                                0.1262481808338407,
                                51.41075837031224
                            ],
                            [
                                0.13069579837301148,
                                51.40998076789958
                            ],
                            [
                                0.13512657612687917,
                                51.40972856930938
                            ],
                            [
                                0.14070295421160495,
                                51.40965501112501
                            ],
                            [
                                0.14080403659383478,
                                51.41040109579171
                            ],
                            [
                                0.1384959888666515,
                                51.41089497612796
                            ],
                            [
                                0.13815904759212572,
                                51.41140986696789
                            ],
                            [
                                0.13760309449065744,
                                51.41223998488769
                            ],
                            [
                                0.13536243501781087,
                                51.41321719299614
                            ],
                            [
                                0.13323970499251914,
                                51.41404727810044
                            ],
                            [
                                0.1315886927490908,
                                51.41443604695368
                            ],
                            [
                                0.12897739787518958,
                                51.41484582676077
                            ],
                            [
                                0.1275622445240856,
                                51.414835319632004
                            ],
                            [
                                0.12695575023073502,
                                51.414467568607705
                            ],
                            [
                                0.1258101498998201,
                                51.41466720524534
                            ],
                            [
                                0.1254900556892551,
                                51.41519256065163
                            ],
                            [
                                0.12653457363933285,
                                51.415570812805925
                            ],
                            [
                                0.12867415072855692,
                                51.415266109926876
                            ],
                            [
                                0.13111697496586316,
                                51.41494039081002
                            ],
                            [
                                0.13320601086462602,
                                51.41447807582105
                            ],
                            [
                                0.13512657612687917,
                                51.41379510194119
                            ],
                            [
                                0.138192753237945,
                                51.41255521478598
                            ],
                            [
                                0.14196649550663665,
                                51.41111564353477
                            ],
                            [
                                0.1477113442294069,
                                51.40925569239701
                            ],
                            [
                                0.14944659178974007,
                                51.41261826066602
                            ],
                            [
                                0.15076066275861422,
                                51.41421539396117
                            ],
                            [
                                0.15102930026804984,
                                51.417520613625555
                            ],
                            [
                                0.15404492467078512,
                                51.41770973046212
                            ],
                            [
                                0.15466826602809647,
                                51.418434671087255
                            ],
                            [
                                0.15142077147061173,
                                51.42038962443101
                            ],
                            [
                                0.15273484243948587,
                                51.42148222010351
                            ],
                            [
                                0.1538130486344187,
                                51.42745876171571
                            ],
                            [
                                0.15592032101505993,
                                51.43083222659905
                            ],
                            [
                                0.16427646461167456,
                                51.428521408575335
                            ],
                            [
                                0.16513566485986075,
                                51.43000244630309
                            ],
                            [
                                0.16444493524826953,
                                51.430643164059745
                            ],
                            [
                                0.16513566485986075,
                                51.43176702432356
                            ],
                            [
                                0.16564107677095308,
                                51.4320085981534
                            ],
                            [
                                0.16530413549781997,
                                51.43337399143968
                            ],
                            [
                                0.16574216920110985,
                                51.43466582836808
                            ],
                            [
                                0.16542207499188066,
                                51.43533798798805
                            ],
                            [
                                0.16382160394022094,
                                51.43640922194015
                            ],
                            [
                                0.16193473280449666,
                                51.436818804753074
                            ],
                            [
                                0.1600478616701082,
                                51.436577256358845
                            ],
                            [
                                0.15875063776519482,
                                51.43592611954219
                            ],
                            [
                                0.15747024130308773,
                                51.435789570107005
                            ],
                            [
                                0.155347511277796,
                                51.43677677685861
                            ],
                            [
                                0.15268567521221144,
                                51.4384045711829
                            ],
                            [
                                0.15137160424328044,
                                51.43722836432397
                            ],
                            [
                                0.14913094477188338,
                                51.4361256428856
                            ],
                            [
                                0.1487940034973576,
                                51.43556902100221
                            ],
                            [
                                0.147934803249143,
                                51.43501239233626
                            ],
                            [
                                0.14732830895582083,
                                51.43591559768876
                            ],
                            [
                                0.14918148596228775,
                                51.436808283105705
                            ],
                            [
                                0.15091674066349015,
                                51.43790048586615
                            ],
                            [
                                0.15243297639682396,
                                51.439076675417624
                            ],
                            [
                                0.15332587077290327,
                                51.43905567229851
                            ],
                            [
                                0.1565436650240315,
                                51.43695530138834
                            ],
                            [
                                0.15805990075742216,
                                51.43648270692066
                            ],
                            [
                                0.15984568950949551,
                                51.437238855721944
                            ],
                            [
                                0.16142931349716605,
                                51.43739638514671
                            ],
                            [
                                0.16388898479701197,
                                51.43704981969512
                            ],
                            [
                                0.16533783227600907,
                                51.43625165895679
                            ],
                            [
                                0.16683722094538211,
                                51.435106904079674
                            ],
                            [
                                0.1705267550788392,
                                51.440798906272306
                            ],
                            [
                                0.16774698956845668,
                                51.442489561937464
                            ],
                            [
                                0.16375423547140144,
                                51.443550127644926
                            ],
                            [
                                0.1606712228142726,
                                51.44442166320542
                            ],
                            [
                                0.15809362206829292,
                                51.44527218187264
                            ],
                            [
                                0.15624044506043333,
                                51.44614368456817
                            ],
                            [
                                0.1542187974160072,
                                51.445104179441074
                            ],
                            [
                                0.15292157351100855,
                                51.44479967345765
                            ],
                            [
                                0.15219714977291687,
                                51.4452301813229
                            ],
                            [
                                0.1524161615998878,
                                51.44592318545787
                            ],
                            [
                                0.15303950295719915,
                                51.44666867816403
                            ],
                            [
                                0.15317427946729367,
                                51.44733016150104
                            ],
                            [
                                0.15364599725049288,
                                51.44796013672257
                            ],
                            [
                                0.15463997400880203,
                                51.44780264373199
                            ],
                            [
                                0.15575188021330177,
                                51.44804413276188
                            ],
                            [
                                0.15637522157052786,
                                51.44862160135068
                            ],
                            [
                                0.1579251514303337,
                                51.44866359878145
                            ],
                            [
                                0.15748712777499918,
                                51.447918138644695
                            ],
                            [
                                0.1569143276080922,
                                51.44671067739111
                            ],
                            [
                                0.1583631750870893,
                                51.44586018551652
                            ],
                            [
                                0.16181682314521595,
                                51.444936176390996
                            ],
                            [
                                0.16710681137465144,
                                51.44346612065914
                            ],
                            [
                                0.1690610707647977,
                                51.44269957422529
                            ],
                            [
                                0.17108271840925227,
                                51.44149197497515
                            ],
                            [
                                0.17243048350459844,
                                51.44290958822651
                            ],
                            [
                                0.173744554473501,
                                51.443046096809184
                            ],
                            [
                                0.17608629632860584,
                                51.44213253159759
                            ],
                            [
                                0.17836064992789602,
                                51.44329811158269
                            ],
                            [
                                0.17935462668614832,
                                51.44324560861969
                            ],
                            [
                                0.1805170607627815,
                                51.4435921261323
                            ],
                            [
                                0.18060129608102216,
                                51.44465266623473
                            ],
                            [
                                0.1798768723415094,
                                51.445366679361086
                            ],
                            [
                                0.18004534297810437,
                                51.44682617145807
                            ],
                            [
                                0.18034859012479387,
                                51.44760314822301
                            ],
                            [
                                0.18146049693729083,
                                51.449776513542446
                            ],
                            [
                                0.18167950876565442,
                                51.45041695396253
                            ],
                            [
                                0.18233654425083046,
                                51.450406455011546
                            ],
                            [
                                0.1828756502883948,
                                51.450206974486576
                            ],
                            [
                                0.1909790879287243,
                                51.448947077352614
                            ],
                            [
                                0.1916529704778327,
                                51.45070042472281
                            ],
                            [
                                0.19278172978056318,
                                51.451351354304904
                            ],
                            [
                                0.19771791944413053,
                                51.45184479217227
                            ],
                            [
                                0.20026182606358134,
                                51.45268467415016
                            ],
                            [
                                0.20327745046637347,
                                51.454301403457464
                            ],
                            [
                                0.20391763888616765,
                                51.45586558705597
                            ],
                            [
                                0.2014074263945531,
                                51.45596006622796
                            ],
                            [
                                0.19852657850191235,
                                51.45584459165801
                            ],
                            [
                                0.19685871919594433,
                                51.45599155924182
                            ],
                            [
                                0.19593213069200033,
                                51.45600205690843
                            ],
                            [
                                0.19529194227075664,
                                51.45648494695578
                            ],
                            [
                                0.19599951894633705,
                                51.45683136493
                            ],
                            [
                                0.19741467229744103,
                                51.456474449400645
                            ],
                            [
                                0.1997901082803537,
                                51.45639046887018
                            ],
                            [
                                0.20081777916513488,
                                51.456589922377276
                            ],
                            [
                                0.20258672085475382,
                                51.45646395184269
                            ],
                            [
                                0.20329429469478555,
                                51.45750319620828
                            ],
                            [
                                0.20290681222837748,
                                51.458258996992896
                            ],
                            [
                                0.20415349494297175,
                                51.45956062456261
                            ],
                            [
                                0.20738813117412747,
                                51.461376542942446
                            ],
                            [
                                0.21065646153166995,
                                51.46247865470181
                            ],
                            [
                                0.21052168617956113,
                                51.464231482489055
                            ],
                            [
                                0.2108249333262222,
                                51.466446036767366
                            ],
                            [
                                0.21144827468208405,
                                51.46870246256796
                            ],
                            [
                                0.20890436954780967,
                                51.47104862386024
                            ],
                            [
                                0.21154935854951873,
                                51.472948035344956
                            ],
                            [
                                0.2118189115682867,
                                51.475330058409156
                            ],
                            [
                                0.21510408899126787,
                                51.47637936831026
                            ],
                            [
                                0.21672142451541276,
                                51.47730274824531
                            ],
                            [
                                0.21694043634377635,
                                51.47998881722245
                            ],
                            [
                                0.21998975487437633,
                                51.47996783292177
                            ],
                            [
                                0.22366241476086657,
                                51.482223589989076
                            ],
                            [
                                0.21776593004261713,
                                51.484888403398685
                            ],
                            [
                                0.21037006907786804,
                                51.48587454868951
                            ],
                            [
                                0.2105048500693556,
                                51.490123126092044
                            ],
                            [
                                0.21245910945947344,
                                51.49271403712086
                            ],
                            [
                                0.21473346893969847,
                                51.496248771927554
                            ],
                            [
                                0.22083210599953418,
                                51.49760175634077
                            ],
                            [
                                0.22546504851641203,
                                51.497549315832686
                            ],
                            [
                                0.22856490823883746,
                                51.499091041584904
                            ],
                            [
                                0.22103427076396542,
                                51.49978322801985
                            ],
                            [
                                0.2141101088132018,
                                51.501387812855825
                            ],
                            [
                                0.20868535430102497,
                                51.50363202361291
                            ],
                            [
                                0.20295734457630488,
                                51.506746477452594
                            ],
                            [
                                0.19695978989872742,
                                51.50951469503332
                            ],
                            [
                                0.19261321057541636,
                                51.51008091345133
                            ],
                            [
                                0.18658196176991737,
                                51.51081487320502
                            ],
                            [
                                0.183263090220521,
                                51.51083584330982
                            ],
                            [
                                0.1826397488632665,
                                51.51171657900102
                            ],
                            [
                                0.18422337285238655,
                                51.51312152688138
                            ],
                            [
                                0.19342187534829236,
                                51.51119233344815
                            ],
                            [
                                0.1988466298603555,
                                51.51018576539943
                            ],
                            [
                                0.2049621165504334,
                                51.50711349699381
                            ],
                            [
                                0.21304870705378676,
                                51.50305527097703
                            ],
                            [
                                0.22056249746469803,
                                51.5010627276919
                            ],
                            [
                                0.2260377931686719,
                                51.500800544458315
                            ],
                            [
                                0.22647581682403484,
                                51.502027549003884
                            ],
                            [
                                0.22477426918052856,
                                51.506557743711085
                            ],
                            [
                                0.22251676507295315,
                                51.51076245660485
                            ],
                            [
                                0.22041088211022952,
                                51.512387612993706
                            ],
                            [
                                0.2205216601621771,
                                51.51403740929928
                            ],
                            [
                                0.22067328373481132,
                                51.515253573394716
                            ],
                            [
                                0.22390791996593862,
                                51.51831480824106
                            ],
                            [
                                0.2252893791891779,
                                51.51905911849539
                            ],
                            [
                                0.22559261987976242,
                                51.52091459670481
                            ],
                            [
                                0.22522198447731512,
                                51.52488737817927
                            ],
                            [
                                0.22815337356183818,
                                51.525275203711175
                            ],
                            [
                                0.23204505849233215,
                                51.52524376744881
                            ],
                            [
                                0.24255762624204635,
                                51.524772086987525
                            ],
                            [
                                0.2441075765232199,
                                51.52604037880914
                            ],
                            [
                                0.2480160953020345,
                                51.52887030945931
                            ],
                            [
                                0.24986927230989409,
                                51.52899608007243
                            ],
                            [
                                0.25138550804186366,
                                51.52983454194725
                            ],
                            [
                                0.26019654261060055,
                                51.53107125016214
                            ],
                            [
                                0.2605840250755591,
                                51.532297439537984
                            ],
                            [
                                0.2675250153196487,
                                51.533596954620265
                            ],
                            [
                                0.2726970660976349,
                                51.5339113483912
                            ],
                            [
                                0.2789473267315259,
                                51.534110462984955
                            ],
                            [
                                0.28192925962684967,
                                51.54419081169709
                            ],
                            [
                                0.28286372642841684,
                                51.54795492159744
                            ],
                            [
                                0.28707547220841434,
                                51.553632819674846
                            ],
                            [
                                0.2892824375533678,
                                51.557592271954576
                            ],
                            [
                                0.2892263689631136,
                                51.562284681527586
                            ],
                            [
                                0.2862949830567061,
                                51.56896609768933
                            ],
                            [
                                0.2847282061315468,
                                51.57102896908245
                            ],
                            [
                                0.27638889083797835,
                                51.5727881037912
                            ],
                            [
                                0.27133477172827725,
                                51.57506020748008
                            ],
                            [
                                0.2651013354648626,
                                51.576934357123605
                            ],
                            [
                                0.25881738070449956,
                                51.57758348197885
                            ],
                            [
                                0.2561723917028189,
                                51.57665167082098
                            ],
                            [
                                0.25410020286796,
                                51.57788710171252
                            ],
                            [
                                0.25036015472571194,
                                51.578211658495036
                            ],
                            [
                                0.2505286253622785,
                                51.57904921367805
                            ],
                            [
                                0.2555827444734007,
                                51.579572677828736
                            ],
                            [
                                0.2566104153595745,
                                51.57859902968579
                            ],
                            [
                                0.26370302917769095,
                                51.57828494518992
                            ],
                            [
                                0.2682854276315254,
                                51.57741596715829
                            ],
                            [
                                0.2727162053854215,
                                51.57556278901677
                            ],
                            [
                                0.27947187793040484,
                                51.573395417028195
                            ],
                            [
                                0.2832119260712318,
                                51.573542005831655
                            ],
                            [
                                0.2786295401664063,
                                51.58045209184755
                            ],
                            [
                                0.27175593486859384,
                                51.586314389020345
                            ],
                            [
                                0.2698016754799255,
                                51.58942318051649
                            ],
                            [
                                0.26326501476330577,
                                51.58910917083193
                            ],
                            [
                                0.2577054613188068,
                                51.590155871548745
                            ],
                            [
                                0.25369586015776235,
                                51.590208205440945
                            ],
                            [
                                0.2553637194637304,
                                51.59477148901138
                            ],
                            [
                                0.25822771294312474,
                                51.598413410300765
                            ],
                            [
                                0.2605694547717974,
                                51.60045401641821
                            ],
                            [
                                0.25809295394950027,
                                51.601134205781335
                            ],
                            [
                                0.25408335278848426,
                                51.60172020027244
                            ],
                            [
                                0.2567283348803926,
                                51.604022246738
                            ],
                            [
                                0.25812664116759265,
                                51.60631371497743
                            ],
                            [
                                0.25094979202975765,
                                51.604074564654354
                            ],
                            [
                                0.2447837664082897,
                                51.60206549208641
                            ],
                            [
                                0.24422781330682142,
                                51.6026619384638
                            ],
                            [
                                0.24501962530064247,
                                51.60293399912166
                            ],
                            [
                                0.24596305888792358,
                                51.60303863731036
                            ],
                            [
                                0.24921454218301164,
                                51.604168715375295
                            ],
                            [
                                0.2569641655739474,
                                51.606732213640754
                            ],
                            [
                                0.2607716019705322,
                                51.60848995770198
                            ],
                            [
                                0.2563745276027305,
                                51.613124637254856
                            ],
                            [
                                0.2518595143252753,
                                51.61698477557789
                            ],
                            [
                                0.24173438429784255,
                                51.62129436330693
                            ],
                            [
                                0.23285598172617483,
                                51.62557214154771
                            ],
                            [
                                0.2296718916720124,
                                51.621963771174734
                            ],
                            [
                                0.2261677024220603,
                                51.619746312697
                            ],
                            [
                                0.22138311274457578,
                                51.617727477011954
                            ],
                            [
                                0.2183001000875322,
                                51.615886403866966
                            ],
                            [
                                0.2152339344943357,
                                51.61406617859083
                            ],
                            [
                                0.2113759569059539,
                                51.61247603700255
                            ],
                            [
                                0.20991023262484987,
                                51.610760275176034
                            ],
                            [
                                0.2088657146761932,
                                51.610143010826135
                            ],
                            [
                                0.2070293847322091,
                                51.60930602880978
                            ],
                            [
                                0.20682721996774944,
                                51.61058242020732
                            ],
                            [
                                0.20637238737128882,
                                51.61240280404169
                            ],
                            [
                                0.2024470215285703,
                                51.615583036642875
                            ],
                            [
                                0.1996672560181878,
                                51.61658727432928
                            ],
                            [
                                0.1943098897603761,
                                51.616336216990874
                            ],
                            [
                                0.19281050109094622,
                                51.61606423664003
                            ],
                            [
                                0.19105840646528804,
                                51.615583036642875
                            ],
                            [
                                0.18947473789802416,
                                51.61558303545078
                            ],
                            [
                                0.18790796097425755,
                                51.616095617879296
                            ],
                            [
                                0.18672866651405684,
                                51.61614792188311
                            ],
                            [
                                0.18445431291476666,
                                51.61581317521626
                            ],
                            [
                                0.18330871258245907,
                                51.615572574524435
                            ],
                            [
                                0.1831402573137666,
                                51.61632575793624
                            ],
                            [
                                0.18276962191271195,
                                51.61721491329766
                            ],
                            [
                                0.18255061008431994,
                                51.61784254188808
                            ],
                            [
                                0.18211258642756434,
                                51.61874212773114
                            ],
                            [
                                0.18199465698140216,
                                51.61939065530035
                            ],
                            [
                                0.1837299045431564,
                                51.62114790923002
                            ],
                            [
                                0.18460595185524653,
                                51.62175456442833
                            ],
                            [
                                0.18642543473526985,
                                51.62275866555473
                            ],
                            [
                                0.1883122818869083,
                                51.62380457070219
                            ],
                            [
                                0.18920517626301603,
                                51.624463484802845
                            ],
                            [
                                0.18834597601338032,
                                51.62538385673511
                            ],
                            [
                                0.18602105504729138,
                                51.62551982684124
                            ],
                            [
                                0.18632430219395246,
                                51.62457853996912
                            ],
                            [
                                0.1859368197289939,
                                51.62432752683847
                            ],
                            [
                                0.18244947754303098,
                                51.62574991621722
                            ],
                            [
                                0.1804952181528563,
                                51.62550936820534
                            ],
                            [
                                0.18034359458027893,
                                51.62444257469559
                            ],
                            [
                                0.17483460474986146,
                                51.62338621523051
                            ],
                            [
                                0.16895497289760897,
                                51.62143031627812
                            ],
                            [
                                0.16309219349534487,
                                51.62273775460497
                            ],
                            [
                                0.15655553277872514,
                                51.623271174537024
                            ],
                            [
                                0.15028841287545447,
                                51.62321888258981
                            ],
                            [
                                0.14095510773913134,
                                51.623448993860734
                            ],
                            [
                                0.13603576513810367,
                                51.62358496211169
                            ],
                            [
                                0.12921271077146912,
                                51.621461719577354
                            ],
                            [
                                0.1289431577526443,
                                51.62072954377274
                            ],
                            [
                                0.12596122747638105,
                                51.61957895792574
                            ],
                            [
                                0.12471456983024609,
                                51.61791577161438
                            ],
                            [
                                0.12299616933250945,
                                51.61693248455629
                            ],
                            [
                                0.11935720357385549,
                                51.615300599444964
                            ],
                            [
                                0.12107560407167739,
                                51.614139414694506
                            ],
                            [
                                0.12634873143275627,
                                51.61113693551329
                            ],
                            [
                                0.1270900022362298,
                                51.61054060046405
                            ],
                            [
                                0.12951597940957527,
                                51.60852137274156
                            ],
                            [
                                0.12181687129810825,
                                51.60507906247585
                            ],
                            [
                                0.11947512944306027,
                                51.6071089061833
                            ],
                            [
                                0.1263655784966886,
                                51.61016396376499
                            ],
                            [
                                0.12575908420336646,
                                51.610749841724186
                            ],
                            [
                                0.12444504557308278,
                                51.611461267850956
                            ],
                            [
                                0.12178320950883403,
                                51.61284221433726
                            ],
                            [
                                0.12019958552113508,
                                51.61387789663138
                            ],
                            [
                                0.11858226740557143,
                                51.615164620502725
                            ],
                            [
                                0.11639216632715943,
                                51.61679654150808
                            ],
                            [
                                0.10958595259180015,
                                51.61395115680466
                            ],
                            [
                                0.10380738455432947,
                                51.612591160415974
                            ],
                            [
                                0.10306611375085595,
                                51.61334439041258
                            ],
                            [
                                0.1002358070486764,
                                51.61276900751446
                            ],
                            [
                                0.09826470059601888,
                                51.615175105654885
                            ],
                            [
                                0.09720333558340144,
                                51.61471481848568
                            ],
                            [
                                0.09599034699670028,
                                51.61457882365585
                            ],
                            [
                                0.09246933593152562,
                                51.61342808776294
                            ],
                            [
                                0.09380025396293945,
                                51.61223547002851
                            ],
                            [
                                0.09482792863062173,
                                51.61079173309727
                            ],
                            [
                                0.09422143433732799,
                                51.61066618857268
                            ],
                            [
                                0.093833951872341,
                                51.609787367178654
                            ],
                            [
                                0.09265465741356138,
                                51.609452573601146
                            ],
                            [
                                0.09194712234457825,
                                51.6082703137354
                            ],
                            [
                                0.09167756932581028,
                                51.607464685590685
                            ],
                            [
                                0.09255361663792883,
                                51.606198669621904
                            ],
                            [
                                0.09031295716650334,
                                51.60560226970168
                            ],
                            [
                                0.08893154453627972,
                                51.60516281515086
                            ],
                            [
                                0.08893154453627972,
                                51.604827987468354
                            ],
                            [
                                0.0870952145923809,
                                51.60441991289258
                            ],
                            [
                                0.0855621317950579,
                                51.605549956580205
                            ],
                            [
                                0.08465239035507466,
                                51.60628237728179
                            ],
                            [
                                0.08364156653289001,
                                51.606418396968024
                            ],
                            [
                                0.08308561730271435,
                                51.60749607331118
                            ],
                            [
                                0.08466927803397084,
                                51.60364558980291
                            ],
                            [
                                0.08455134858780866,
                                51.60254689051706
                            ],
                            [
                                0.08482090160660505,
                                51.60088309527424
                            ],
                            [
                                0.08527577824997934,
                                51.598716930609584
                            ],
                            [
                                0.08490514284758888,
                                51.59678089914556
                            ],
                            [
                                0.08465243689275326,
                                51.59489711364034
                            ],
                            [
                                0.08500622522981871,
                                51.59467733357525
                            ],
                            [
                                0.0859665078616274,
                                51.59410171407936
                            ],
                            [
                                0.08594966079772348,
                                51.59339002898068
                            ],
                            [
                                0.08493883697551041,
                                51.59273066371824
                            ],
                            [
                                0.08418071910946878,
                                51.5931702416232
                            ],
                            [
                                0.08433234268213141,
                                51.59368307713646
                            ],
                            [
                                0.08455135451052342,
                                51.59420637271185
                            ],
                            [
                                0.08372584838878083,
                                51.59484478515
                            ],
                            [
                                0.08273187163047169,
                                51.59568203376287
                            ],
                            [
                                0.08074391811391024,
                                51.59740881028492
                            ],
                            [
                                0.0782337212757227,
                                51.59933436338031
                            ],
                            [
                                0.07764407404633289,
                                51.599732019084314
                            ],
                            [
                                0.07676802673421435,
                                51.60046453363441
                            ],
                            [
                                0.07555503814757003,
                                51.60206556001492
                            ],
                            [
                                0.07427466130653215,
                                51.60328983621818
                            ],
                            [
                                0.07264051525476134,
                                51.60470244108507
                            ],
                            [
                                0.06935533783317283,
                                51.605591824143545
                            ],
                            [
                                0.06689566653196266,
                                51.606177761109905
                            ],
                            [
                                0.06460446586871171,
                                51.60693109752617
                            ],
                            [
                                0.06313877045920435,
                                51.60686832652533
                            ],
                            [
                                0.05946611057132145,
                                51.60662767841842
                            ],
                            [
                                0.056720039188746796,
                                51.60633471378364
                            ],
                            [
                                0.05547335647420937,
                                51.60613591527627
                            ],
                            [
                                0.055304885837642814,
                                51.60666953035445
                            ],
                            [
                                0.05611354489545306,
                                51.60721360201623
                            ],
                            [
                                0.05729283935423268,
                                51.60805062261025
                            ],
                            [
                                0.05842161564314097,
                                51.60920151147076
                            ],
                            [
                                0.05274415517618536,
                                51.61183795863718
                            ],
                            [
                                0.05021709562061005,
                                51.61071853350532
                            ],
                            [
                                0.048835636397399185,
                                51.6090655001222
                            ],
                            [
                                0.048700864026614,
                                51.60768443538001
                            ],
                            [
                                0.049627452529108496,
                                51.60557091162141
                            ],
                            [
                                0.04991385261183723,
                                51.6045768936585
                            ],
                            [
                                0.04891988756142496,
                                51.601992335310655
                            ],
                            [
                                0.0481954638219122,
                                51.59957507378127
                            ],
                            [
                                0.04688139285303805,
                                51.598162328898724
                            ],
                            [
                                0.042922339991690706,
                                51.59584950003173
                            ],
                            [
                                0.04049636281834523,
                                51.59401800539854
                            ],
                            [
                                0.039451844869660135,
                                51.592510891569844
                            ],
                            [
                                0.0394855467566515,
                                51.59086766024794
                            ],
                            [
                                0.040597452961094405,
                                51.588062519349734
                            ],
                            [
                                0.04073100197766166,
                                51.58544485640485
                            ],
                            [
                                0.040848931423852264,
                                51.58204267132177
                            ],
                            [
                                0.042247230547275194,
                                51.58000123986017
                            ],
                            [
                                0.044336266446066475,
                                51.57706979059961
                            ],
                            [
                                0.04559979622453625,
                                51.57639971850517
                            ],
                            [
                                0.0456334903510367,
                                51.57511189596062
                            ],
                            [
                                0.0445384312104693,
                                51.57477684671568
                            ],
                            [
                                0.04255047769390785,
                                51.57539459184008
                            ],
                            [
                                0.0421966893568424,
                                51.57619031894936
                            ],
                            [
                                0.042752642458339096,
                                51.57680804486367
                            ],
                            [
                                0.042061912846776295,
                                51.57773985281554
                            ],
                            [
                                0.03940007762915343,
                                51.58216830139051
                            ],
                            [
                                0.03918106580076142,
                                51.58357107545547
                            ],
                            [
                                0.03914737167431781,
                                51.584858658307354
                            ],
                            [
                                0.03864196566095757,
                                51.58703596031205
                            ],
                            [
                                0.036165447297264564,
                                51.58882586812052
                            ],
                            [
                                0.03598012959531616,
                                51.59037497116634
                            ],
                            [
                                0.03678878865306956,
                                51.59184029029515
                            ],
                            [
                                0.035087235219236845,
                                51.593535814866925
                            ],
                            [
                                0.03271180551885777,
                                51.59449868457176
                            ],
                            [
                                0.029932040007082605,
                                51.595304542780696
                            ],
                            [
                                0.027051192114413425,
                                51.59590107795978
                            ],
                            [
                                0.024844226769459965,
                                51.59605805959944
                            ],
                            [
                                0.021761173902916653,
                                51.59555571262766
                            ],
                            [
                                0.01886347894637197,
                                51.59511615780767
                            ],
                            [
                                0.01599947811624247,
                                51.59488591310935
                            ],
                            [
                                0.013691430389059178,
                                51.59485451601461
                            ],
                            [
                                0.012107806401360222,
                                51.5949801042635
                            ],
                            [
                                0.011248606153174023,
                                51.594331227906395
                            ],
                            [
                                0.009968229310800325,
                                51.5946347357434
                            ],
                            [
                                0.00961444097370645,
                                51.59518941723991
                            ],
                            [
                                0.009462817399622736,
                                51.596037124930746
                            ],
                            [
                                0.010979021531682065,
                                51.59695807919604
                            ],
                            [
                                0.012023539480395584,
                                51.59686389214383
                            ],
                            [
                                0.012579492583370211,
                                51.5958278216782
                            ],
                            [
                                0.014466363717644981,
                                51.59564990821005
                            ],
                            [
                                0.01773469407530115,
                                51.59562897716819
                            ],
                            [
                                0.020295447758712726,
                                51.59601619988456
                            ],
                            [
                                0.023209989779189755,
                                51.59661272571631
                            ],
                            [
                                0.02577074346126551,
                                51.59675923963479
                            ],
                            [
                                0.028078791188448804,
                                51.596591795118144
                            ],
                            [
                                0.03133026380561432,
                                51.596037132963346
                            ],
                            [
                                0.0330992054938406,
                                51.59551385848013
                            ],
                            [
                                0.03544094734891701,
                                51.594697538248
                            ],
                            [
                                0.036755018317791155,
                                51.59446729142715
                            ],
                            [
                                0.03843972468763468,
                                51.59476033263289
                            ],
                            [
                                0.03931577199981007,
                                51.595796427449955
                            ],
                            [
                                0.04057930177688718,
                                51.597031337872664
                            ],
                            [
                                0.04308951426853014,
                                51.598580161105616
                            ],
                            [
                                0.046138832799158536,
                                51.60055797343952
                            ],
                            [
                                0.04778980102142327,
                                51.602169445645444
                            ],
                            [
                                0.048716389525367276,
                                51.604314518318745
                            ],
                            [
                                0.04799196578585452,
                                51.60664782376443
                            ],
                            [
                                0.047419157122533306,
                                51.608614817954646
                            ],
                            [
                                0.04839628681827435,
                                51.61034110273934
                            ],
                            [
                                0.049895675487647395,
                                51.611554693956975
                            ],
                            [
                                0.05174885249411432,
                                51.61228701778802
                            ],
                            [
                                0.048463687545705625,
                                51.613521490606416
                            ],
                            [
                                0.04849738167359874,
                                51.615038368275634
                            ],
                            [
                                0.0499293820872424,
                                51.61627275544865
                            ],
                            [
                                0.051917335605253356,
                                51.61686901519357
                            ],
                            [
                                0.05316401831842654,
                                51.61626229468172
                            ],
                            [
                                0.05414114801271808,
                                51.616607498726296
                            ],
                            [
                                0.0543938539690032,
                                51.61749664856757
                            ],
                            [
                                0.05355150078335669,
                                51.618323019271855
                            ],
                            [
                                0.04677898117583368,
                                51.61739204360785
                            ],
                            [
                                0.04671159292149696,
                                51.61671210549389
                            ],
                            [
                                0.04578500441752453,
                                51.615875244600176
                            ],
                            [
                                0.04221342192997213,
                                51.61501741584635
                            ],
                            [
                                0.041590080572717625,
                                51.615216175462166
                            ],
                            [
                                0.04089935096109798,
                                51.61518479242275
                            ],
                            [
                                0.03805219719490083,
                                51.61686898487119
                            ],
                            [
                                0.037698408857835375,
                                51.61662838977648
                            ],
                            [
                                0.03643487908072984,
                                51.61751753920848
                            ],
                            [
                                0.03756363234771243,
                                51.61818700493234
                            ],
                            [
                                0.036182213053933765,
                                51.61944224703589
                            ],
                            [
                                0.03197044712848651,
                                51.622810253461665
                            ],
                            [
                                0.03067322322351629,
                                51.62301943816175
                            ],
                            [
                                0.028061950680807968,
                                51.62037314933343
                            ],
                            [
                                0.027034279796055216,
                                51.61823929570576
                            ],
                            [
                                0.02545065580835626,
                                51.61866816650098
                            ],
                            [
                                0.02432190253998101,
                                51.619515435915616
                            ],
                            [
                                0.023530090546131532,
                                51.62032085019905
                            ],
                            [
                                0.023193149271605762,
                                51.62093797614588
                            ],
                            [
                                0.022738278552282054,
                                51.62207806810841
                            ],
                            [
                                0.021946495486247386,
                                51.62420128858537
                            ],
                            [
                                0.0213063070664532,
                                51.6251739594376
                            ],
                            [
                                0.020716659835642304,
                                51.625717809319355
                            ],
                            [
                                0.023563813601811034,
                                51.627014655807756
                            ],
                            [
                                0.024204002023026305,
                                51.627683981426685
                            ],
                            [
                                0.021828566040085207,
                                51.62882390389109
                            ],
                            [
                                0.023799654625918265,
                                51.63072718879829
                            ],
                            [
                                0.02497894908472631,
                                51.63474266575605
                            ],
                            [
                                0.021744312855020098,
                                51.63411527091364
                            ],
                            [
                                0.019267794489820744,
                                51.63408390094369
                            ],
                            [
                                0.01738092335548913,
                                51.63389568066847
                            ],
                            [
                                0.016016320865247735,
                                51.63365517835226
                            ],
                            [
                                0.01450008513191392,
                                51.63394796666216
                            ],
                            [
                                0.011855096131569098,
                                51.633968880040385
                            ],
                            [
                                0.011063284136326956,
                                51.633822486189416
                            ],
                            [
                                0.009833448487114538,
                                51.6333310176621
                            ],
                            [
                                0.0074074925163358785,
                                51.632306243630865
                            ],
                            [
                                0.0030104088903613047,
                                51.631009548407064
                            ],
                            [
                                0.0018648085580252882,
                                51.630695826251696
                            ],
                            [
                                0.0008708317997445647,
                                51.630172951171375
                            ],
                            [
                                -0.0006454039335892503,
                                51.63119778065882
                            ],
                            [
                                -0.0014203688635063827,
                                51.631469670310054
                            ],
                            [
                                -0.003913757196670531,
                                51.63171021075587
                            ],
                            [
                                -0.005969098969018205,
                                51.63212849712701
                            ],
                            [
                                -0.008108676059634945,
                                51.632755919451256
                            ],
                            [
                                -0.00967542111479247,
                                51.63387482202609
                            ],
                            [
                                -0.011730762885662216,
                                51.63528645570091
                            ],
                            [
                                -0.01326384568298522,
                                51.635924290579595
                            ],
                            [
                                -0.01225302186077215,
                                51.63876829758706
                            ],
                            [
                                -0.011966627202895097,
                                51.64004385774484
                            ],
                            [
                                -0.0122867214134601,
                                51.64174803736785
                            ],
                            [
                                -0.01176446243843543,
                                51.64312806227639
                            ],
                            [
                                -0.011629685928340905,
                                51.64451849943913
                            ],
                            [
                                -0.01098950399634191,
                                51.646389768841715
                            ],
                            [
                                -0.0121856455190823,
                                51.646483853150926
                            ],
                            [
                                -0.011730774799787014,
                                51.64925402585567
                            ],
                            [
                                -0.011713931705799041,
                                51.65319469392011
                            ],
                            [
                                -0.008900472067495002,
                                51.66010310713804
                            ],
                            [
                                -0.009220559831646824,
                                51.66128401882216
                            ],
                            [
                                -0.01141067811269636,
                                51.66340539798199
                            ],
                            [
                                -0.011326442794398872,
                                51.66741798151227
                            ],
                            [
                                -0.010854725011142818,
                                51.66838972562957
                            ],
                            [
                                -0.010517778346098794,
                                51.66929876007467
                            ],
                            [
                                -0.011410672722149684,
                                51.67077198022099
                            ],
                            [
                                -0.010281917727610335,
                                51.67657036127363
                            ],
                            [
                                -0.011107423847931841,
                                51.68023706153477
                            ],
                            [
                                -0.01472958955383774,
                                51.680351963547224
                            ],
                            [
                                -0.021855897499818866,
                                51.680832476540644
                            ],
                            [
                                -0.02724695788401732,
                                51.68173081324136
                            ],
                            [
                                -0.033194026309786295,
                                51.68197106915463
                            ],
                            [
                                -0.03368259115694627,
                                51.680195279520916
                            ],
                            [
                                -0.03317717924585395,
                                51.67991323593495
                            ],
                            [
                                -0.03366574409301393,
                                51.67943271318717
                            ],
                            [
                                -0.03432277957674046,
                                51.679526728908684
                            ],
                            [
                                -0.03444070902290264,
                                51.679766990421115
                            ],
                            [
                                -0.03523252101678054,
                                51.680007250658804
                            ],
                            [
                                -0.03838292192961035,
                                51.68059222677721
                            ],
                            [
                                -0.04020240480963366,
                                51.68090560373142
                            ],
                            [
                                -0.04155016990495142,
                                51.68096827886211
                            ],
                            [
                                -0.043218029212312103,
                                51.68088471200204
                            ],
                            [
                                -0.04495327677264527,
                                51.68072802372356
                            ],
                            [
                                -0.04628419480548018,
                                51.68049821326787
                            ],
                            [
                                -0.048120524749435845,
                                51.68009081913863
                            ],
                            [
                                -0.04988950196874953,
                                51.67982965704073
                            ],
                            [
                                -0.050681313962599006,
                                51.67957894969339
                            ],
                            [
                                -0.051877455485310975,
                                51.679725195814996
                            ],
                            [
                                -0.05290512637148481,
                                51.67924467107133
                            ],
                            [
                                -0.05489307988804626,
                                51.67891038998161
                            ],
                            [
                                -0.05570173894579966,
                                51.67856566002314
                            ],
                            [
                                -0.05704950404120268,
                                51.67822092744032
                            ],
                            [
                                -0.05927331645008849,
                                51.67823137392094
                            ],
                            [
                                -0.05913853993996554,
                                51.679641626660356
                            ],
                            [
                                -0.061193881712313214,
                                51.679881887563624
                            ],
                            [
                                -0.06068846980119247,
                                51.68148011242883
                            ],
                            [
                                -0.05979557542514158,
                                51.682169525347604
                            ],
                            [
                                -0.06390625896841584,
                                51.68247244588625
                            ]
                        ],
                        [
                            [
                                -0.24772028117490663,
                                51.655084318684715
                            ],
                            [
                                -0.24806511857408964,
                                51.65454947785375
                            ],
                            [
                                -0.24898468497229942,
                                51.654406852565984
                            ],
                            [
                                -0.24935825882249674,
                                51.65442468075139
                            ],
                            [
                                -0.2496743597718023,
                                51.65315886215467
                            ],
                            [
                                -0.2501341429703814,
                                51.65066277824266
                            ],
                            [
                                -0.2505651897201915,
                                51.64914723166294
                            ],
                            [
                                -0.2509387635692235,
                                51.647792111814795
                            ],
                            [
                                -0.25168591126728757,
                                51.64650827669817
                            ],
                            [
                                -0.25272042346597345,
                                51.645170909790664
                            ],
                            [
                                -0.25374019210099164,
                                51.64363348269654
                            ],
                            [
                                -0.25369479107138204,
                                51.64277429766844
                            ],
                            [
                                -0.2517482592101885,
                                51.641800481528776
                            ],
                            [
                                -0.2499646529239783,
                                51.64137476298529
                            ],
                            [
                                -0.24699186318926536,
                                51.64123344224075
                            ],
                            [
                                -0.2447709303619945,
                                51.64135051560348
                            ],
                            [
                                -0.242556771510408,
                                51.64232716718237
                            ],
                            [
                                -0.2400014125043981,
                                51.64390227274444
                            ],
                            [
                                -0.237704056058476,
                                51.64528498741086
                            ],
                            [
                                -0.2355431484425594,
                                51.64610974232883
                            ],
                            [
                                -0.23286922562439827,
                                51.647359745858154
                            ],
                            [
                                -0.23129999491325748,
                                51.6481791424126
                            ],
                            [
                                -0.2283574276381728,
                                51.649305917431036
                            ],
                            [
                                -0.2262308201430301,
                                51.650008228015764
                            ],
                            [
                                -0.22432709312528232,
                                51.65035414197408
                            ],
                            [
                                -0.22286933798764608,
                                51.65053503701208
                            ],
                            [
                                -0.22242343641642037,
                                51.650832980207525
                            ],
                            [
                                -0.21989380250096247,
                                51.65239714985407
                            ],
                            [
                                -0.22196038478480773,
                                51.65224286341103
                            ],
                            [
                                -0.22300653847199214,
                                51.65277488341184
                            ],
                            [
                                -0.2239272894937585,
                                51.65394078904245
                            ],
                            [
                                -0.22427029070271942,
                                51.65514310031364
                            ],
                            [
                                -0.22652014415533017,
                                51.65701600140636
                            ],
                            [
                                -0.22792644911183402,
                                51.65818631390877
                            ],
                            [
                                -0.22898056430167912,
                                51.65935051556474
                            ],
                            [
                                -0.22896341424129218,
                                51.65996224208337
                            ],
                            [
                                -0.23423705782693105,
                                51.65830258225108
                            ],
                            [
                                -0.23495248156351067,
                                51.659042540435905
                            ],
                            [
                                -0.23827959328929182,
                                51.65823397939303
                            ],
                            [
                                -0.24209548173857343,
                                51.656914717245996
                            ],
                            [
                                -0.247551113526697,
                                51.65536209753731
                            ],
                            [
                                -0.24772028117490663,
                                51.655084318684715
                            ]
                        ],
                        [
                            [
                                -0.27416005502956864,
                                51.3706330744796
                            ],
                            [
                                -0.2738629025181183,
                                51.37146784564297
                            ],
                            [
                                -0.27288654426857306,
                                51.37201110124016
                            ],
                            [
                                -0.2740114787737866,
                                51.37250135075868
                            ],
                            [
                                -0.27503028738277635,
                                51.37348183404657
                            ],
                            [
                                -0.2771315801376204,
                                51.375310246789354
                            ],
                            [
                                -0.2785324584742739,
                                51.37664838210185
                            ],
                            [
                                -0.2801031217458103,
                                51.37807922105091
                            ],
                            [
                                -0.2806974267677447,
                                51.37829119338747
                            ],
                            [
                                -0.28144030804452314,
                                51.37760227970384
                            ],
                            [
                                -0.2827774943433212,
                                51.37611843040102
                            ],
                            [
                                -0.2850061381745377,
                                51.37471402872163
                            ],
                            [
                                -0.28778663666841453,
                                51.373654074397024
                            ],
                            [
                                -0.2901213670383811,
                                51.37312409186168
                            ],
                            [
                                -0.2915859048012237,
                                51.372978344492566
                            ],
                            [
                                -0.2934749457625685,
                                51.3730313435737
                            ],
                            [
                                -0.29672239820274626,
                                51.37345533401546
                            ],
                            [
                                -0.29880246577835123,
                                51.37383957196178
                            ],
                            [
                                -0.3007551822783512,
                                51.374197307840575
                            ],
                            [
                                -0.3033658793371785,
                                51.37467428466354
                            ],
                            [
                                -0.30417243615264056,
                                51.37458153955916
                            ],
                            [
                                -0.30500021814731326,
                                51.374952518848715
                            ],
                            [
                                -0.3078019418204292,
                                51.375363242412504
                            ],
                            [
                                -0.3100518942850954,
                                51.37509824592709
                            ],
                            [
                                -0.313638949594349,
                                51.374594774908246
                            ],
                            [
                                -0.3153794143000255,
                                51.37405154996759
                            ],
                            [
                                -0.316886402033731,
                                51.372792833260974
                            ],
                            [
                                -0.3174594818755452,
                                51.371454580703045
                            ],
                            [
                                -0.3174965452243441,
                                51.370661513205846
                            ],
                            [
                                -0.31605323302926536,
                                51.3699327327393
                            ],
                            [
                                -0.31613813374633537,
                                51.369402703295805
                            ],
                            [
                                -0.3177300221971393,
                                51.369521960455614
                            ],
                            [
                                -0.31813330060487033,
                                51.3674017868494
                            ],
                            [
                                -0.31860025455040386,
                                51.36434061302714
                            ],
                            [
                                -0.31915343177703903,
                                51.36109313015436
                            ],
                            [
                                -0.32023591592377443,
                                51.35899907394591
                            ],
                            [
                                -0.3216155525810507,
                                51.35695793968722
                            ],
                            [
                                -0.3235597262303713,
                                51.35465961676812
                            ],
                            [
                                -0.32532141611528687,
                                51.35337385607758
                            ],
                            [
                                -0.3281655901477336,
                                51.352048291817
                            ],
                            [
                                -0.3307338368489354,
                                51.34830999402965
                            ],
                            [
                                -0.32857329900133436,
                                51.34460997051738
                            ],
                            [
                                -0.32318210344780596,
                                51.342873141401014
                            ],
                            [
                                -0.3184593412687491,
                                51.341666082806455
                            ],
                            [
                                -0.3163580485138766,
                                51.34120202112979
                            ],
                            [
                                -0.3128983442801996,
                                51.33969047337868
                            ],
                            [
                                -0.3093325190348821,
                                51.33835123967509
                            ],
                            [
                                -0.3051299335243698,
                                51.33804626333378
                            ],
                            [
                                -0.3054058608564958,
                                51.33957112475002
                            ],
                            [
                                -0.3043233767097888,
                                51.34262069540853
                            ],
                            [
                                -0.30152253968367404,
                                51.34517934543348
                            ],
                            [
                                -0.3000580023084751,
                                51.34763197679632
                            ],
                            [
                                -0.2970373656117431,
                                51.34983431225834
                            ],
                            [
                                -0.2928135549227875,
                                51.35253855333562
                            ],
                            [
                                -0.2912352670612677,
                                51.35537417262941
                            ],
                            [
                                -0.29068341239866413,
                                51.35692496641593
                            ],
                            [
                                -0.2894523518978076,
                                51.35801181634025
                            ],
                            [
                                -0.2887519209794789,
                                51.358807055972534
                            ],
                            [
                                -0.28699023109376753,
                                51.35936371550082
                            ],
                            [
                                -0.2854197394833591,
                                51.36100694159617
                            ],
                            [
                                -0.286926727217093,
                                51.36140453713864
                            ],
                            [
                                -0.28839126459141085,
                                51.36176237017651
                            ],
                            [
                                -0.2895798746353364,
                                51.36214670625128
                            ],
                            [
                                -0.2918297436458772,
                                51.36250453349027
                            ],
                            [
                                -0.29397348675919943,
                                51.362796094359
                            ],
                            [
                                -0.2958837529006644,
                                51.363233432181886
                            ],
                            [
                                -0.2971784888400464,
                                51.36340571563261
                            ],
                            [
                                -0.29983165988551264,
                                51.36328642349281
                            ],
                            [
                                -0.3036097418090833,
                                51.36295510772729
                            ],
                            [
                                -0.30719679711836534,
                                51.3628358334648
                            ],
                            [
                                -0.3079184532159047,
                                51.362557525644576
                            ],
                            [
                                -0.30855520859634566,
                                51.36295510772729
                            ],
                            [
                                -0.3086401093134157,
                                51.3634852118021
                            ],
                            [
                                -0.3071543467598019,
                                51.36359123188035
                            ],
                            [
                                -0.3058171604592701,
                                51.363405696551325
                            ],
                            [
                                -0.30057454116143845,
                                51.363710504145075
                            ],
                            [
                                -0.29798506928096913,
                                51.363988804959064
                            ],
                            [
                                -0.29573520027048517,
                                51.36392254301413
                            ],
                            [
                                -0.2935065555642211,
                                51.36337919172561
                            ],
                            [
                                -0.2912566468397415,
                                51.362915316990495
                            ],
                            [
                                -0.28860349942235075,
                                51.36254424016539
                            ],
                            [
                                -0.28686303471585006,
                                51.361908101469595
                            ],
                            [
                                -0.2877332670690862,
                                51.362345447771844
                            ],
                            [
                                -0.28645975630814746,
                                51.36337915879477
                            ],
                            [
                                -0.28391272939668966,
                                51.365168220206726
                            ],
                            [
                                -0.28162041002761384,
                                51.366678927638134
                            ],
                            [
                                -0.27939176619634054,
                                51.36804382219958
                            ],
                            [
                                -0.2772904734415249,
                                51.36853411419776
                            ],
                            [
                                -0.27597450653962596,
                                51.369925458659026
                            ],
                            [
                                -0.27416005502956864,
                                51.3706330744796
                            ]
                        ],
                        [
                            [
                                0.2808409519459474,
                                51.534269626903665
                            ],
                            [
                                0.2977120041313128,
                                51.5355193227482
                            ],
                            [
                                0.29710687313507833,
                                51.53837994310521
                            ],
                            [
                                0.29463791614008983,
                                51.54206838479942
                            ],
                            [
                                0.2934276541466261,
                                51.54408560333357
                            ],
                            [
                                0.29345185938635154,
                                51.54480816732436
                            ],
                            [
                                0.2937181215355906,
                                51.547645407480985
                            ],
                            [
                                0.29405699489433346,
                                51.54943660285758
                            ],
                            [
                                0.2942610893249764,
                                51.552452814084035
                            ],
                            [
                                0.29501145176084265,
                                51.55591435895576
                            ],
                            [
                                0.29501145176084265,
                                51.55713336203718
                            ],
                            [
                                0.2947209956728045,
                                51.55965880690954
                            ],
                            [
                                0.2946241747136753,
                                51.56033597912287
                            ],
                            [
                                0.2979887030546422,
                                51.56045636423866
                            ],
                            [
                                0.3009901527980787,
                                51.56024569007735
                            ],
                            [
                                0.3015226682096852,
                                51.56093790159281
                            ],
                            [
                                0.3008933319727305,
                                51.56308970898937
                            ],
                            [
                                0.29989362499364347,
                                51.564907449865046
                            ],
                            [
                                0.29067142860540685,
                                51.56423034572518
                            ],
                            [
                                0.29117973864256896,
                                51.560272846524214
                            ],
                            [
                                0.29093768624386485,
                                51.55831654101371
                            ],
                            [
                                0.28952645972839264,
                                51.55460795213142
                            ],
                            [
                                0.2863313680658166,
                                51.55040879305986
                            ],
                            [
                                0.2808409519459474,
                                51.534269626903665
                            ]
                        ],
                        [
                            [
                                0.0827430506293183,
                                51.606443431109426
                            ],
                            [
                                0.08044212359845915,
                                51.60693230131827
                            ],
                            [
                                0.07886780510273184,
                                51.60702004669625
                            ],
                            [
                                0.07688981519871163,
                                51.606455966308715
                            ],
                            [
                                0.0747907238715868,
                                51.60544060394966
                            ],
                            [
                                0.07321640537668372,
                                51.60478875441794
                            ],
                            [
                                0.07456870459668608,
                                51.60367306715773
                            ],
                            [
                                0.07598155452859601,
                                51.60238184430793
                            ],
                            [
                                0.07688981519871163,
                                51.60151682982263
                            ],
                            [
                                0.07763660730506672,
                                51.600375988420296
                            ],
                            [
                                0.07907556917663783,
                                51.59935955130072
                            ],
                            [
                                0.08083153980652469,
                                51.597905217826195
                            ],
                            [
                                0.08220402259692605,
                                51.59680189929557
                            ],
                            [
                                0.08303154898553089,
                                51.596024545145184
                            ],
                            [
                                0.08398017679644454,
                                51.59527225426703
                            ],
                            [
                                0.08426274678325285,
                                51.59701504246439
                            ],
                            [
                                0.08456550033969279,
                                51.59801805571067
                            ],
                            [
                                0.08442421534627442,
                                51.599184030766565
                            ],
                            [
                                0.0841416454854027,
                                51.60058817623593
                            ],
                            [
                                0.08398017692158533,
                                51.601240086072096
                            ],
                            [
                                0.08373797015596551,
                                51.602497412461275
                            ],
                            [
                                0.08400035657243166,
                                51.60358805663711
                            ],
                            [
                                0.08319301375419741,
                                51.60556869976227
                            ],
                            [
                                0.0827430506293183,
                                51.606443431109426
                            ]
                        ],
                        [
                            [
                                0.02865229824297444,
                                51.62513818044346
                            ],
                            [
                                0.02654842919753264,
                                51.62615038898181
                            ],
                            [
                                0.024725076029739057,
                                51.627380246349816
                            ],
                            [
                                0.023865996170258086,
                                51.62654220556763
                            ],
                            [
                                0.021656933676837298,
                                51.625649729531744
                            ],
                            [
                                0.02274393268154995,
                                51.62415860235228
                            ],
                            [
                                0.023550415813843983,
                                51.62184018320775
                            ],
                            [
                                0.024234173256132863,
                                51.62039247170878
                            ],
                            [
                                0.025514026923673327,
                                51.61923862347902
                            ],
                            [
                                0.026653622654237097,
                                51.618868515002305
                            ],
                            [
                                0.027512702512353826,
                                51.62101292513631
                            ],
                            [
                                0.02865229824297444,
                                51.62513818044346
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "gb",
                "city": "London",
                "zone": "ULEZ",
                "area_code": 5,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 2,
                "diesel": "min. EURO-6 (diesel)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "req. Online Registration",
                "img_sign": "sign-gb-ulez--white",
                "color": 1,
                "urlZone": "https://tfl.gov.uk/modes/driving/ultra-low-emission-zone",
                "urlPark": "https://tfl.gov.uk/modes/driving/station-car-parks",
                "urlReg": "https://tfl.gov.uk/modes/driving/auto-pay?intcmp=2154",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -0.06390625896841584,
                                51.68247244588625
                            ],
                            [
                                -0.0665328273206569,
                                51.68318588176794
                            ],
                            [
                                -0.06810084368595426,
                                51.68364403547662
                            ],
                            [
                                -0.07649965541892811,
                                51.68691803995205
                            ],
                            [
                                -0.08167230710668605,
                                51.68868343687265
                            ],
                            [
                                -0.08803448845006301,
                                51.688895726801945
                            ],
                            [
                                -0.09504551966904273,
                                51.68942086680758
                            ],
                            [
                                -0.0989745721704196,
                                51.69008006891053
                            ],
                            [
                                -0.10557106160197804,
                                51.691175006360226
                            ],
                            [
                                -0.11032918022706895,
                                51.69068341081433
                            ],
                            [
                                -0.11260010048033564,
                                51.69020298273574
                            ],
                            [
                                -0.11645706407060175,
                                51.68847116424112
                            ],
                            [
                                -0.11308673004452885,
                                51.68565542201918
                            ],
                            [
                                -0.113987875237342,
                                51.68233663599028
                            ],
                            [
                                -0.11447450100510537,
                                51.68136442257796
                            ],
                            [
                                -0.11874599386237605,
                                51.678849980741205
                            ],
                            [
                                -0.1220802660409106,
                                51.677609461686046
                            ],
                            [
                                -0.12426107041133605,
                                51.676458327968135
                            ],
                            [
                                -0.12485583523957189,
                                51.67540775321282
                            ],
                            [
                                -0.1309837282812225,
                                51.67788886870997
                            ],
                            [
                                -0.1359040554958142,
                                51.680023404705565
                            ],
                            [
                                -0.14051798870823973,
                                51.680973296749755
                            ],
                            [
                                -0.14329355790613363,
                                51.68054864159291
                            ],
                            [
                                -0.14826796929830266,
                                51.68057099769803
                            ],
                            [
                                -0.15349469051506048,
                                51.68098447756637
                            ],
                            [
                                -0.15688306828502618,
                                51.68204611328065
                            ],
                            [
                                -0.1602894486648836,
                                51.68405753593851
                            ],
                            [
                                -0.1635696668074047,
                                51.68557721824064
                            ],
                            [
                                -0.1633173423350911,
                                51.68272777208003
                            ],
                            [
                                -0.16490336570183217,
                                51.680985455737385
                            ],
                            [
                                -0.16216384397236538,
                                51.67521874766675
                            ],
                            [
                                -0.16010920183848043,
                                51.6712732701009
                            ],
                            [
                                -0.16816557216992578,
                                51.67180978272606
                            ],
                            [
                                -0.1759155381122639,
                                51.67229040599781
                            ],
                            [
                                -0.17685274329642198,
                                51.67213392456233
                            ],
                            [
                                -0.18204342515772964,
                                51.66861294349394
                            ],
                            [
                                -0.185575967773417,
                                51.668523518337395
                            ],
                            [
                                -0.1877387489669502,
                                51.667897537297904
                            ],
                            [
                                -0.19096490243958897,
                                51.664197355761615
                            ],
                            [
                                -0.19651604083614416,
                                51.66552766377862
                            ],
                            [
                                -0.19483988541114172,
                                51.66835583589594
                            ],
                            [
                                -0.1959392991840332,
                                51.668523508540545
                            ],
                            [
                                -0.19703871295772046,
                                51.66750628495825
                            ],
                            [
                                -0.19756138507923993,
                                51.66672378973695
                            ],
                            [
                                -0.19837240415142787,
                                51.66577360255562
                            ],
                            [
                                -0.19869682133045785,
                                51.66510286754138
                            ],
                            [
                                -0.19898519215576016,
                                51.664141463370214
                            ],
                            [
                                -0.19875089086028197,
                                51.66330301284623
                            ],
                            [
                                -0.19869682133045785,
                                51.662553983923004
                            ],
                            [
                                -0.19907530803931195,
                                51.661581345798595
                            ],
                            [
                                -0.1996339900782118,
                                51.66009438984071
                            ],
                            [
                                -0.20019270855345894,
                                51.6590322550276
                            ],
                            [
                                -0.2010397978540368,
                                51.657679394638905
                            ],
                            [
                                -0.20152642362253914,
                                51.656639565402344
                            ],
                            [
                                -0.2016706090352045,
                                51.656147594868855
                            ],
                            [
                                -0.2021392116276104,
                                51.65618113848399
                            ],
                            [
                                -0.20298630092821668,
                                51.656650746488936
                            ],
                            [
                                -0.20385141340565838,
                                51.65707562572885
                            ],
                            [
                                -0.20450024776371833,
                                51.657679394638905
                            ],
                            [
                                -0.2049868735314817,
                                51.658350239549264
                            ],
                            [
                                -0.20616253962836595,
                                51.65971857186267
                            ],
                            [
                                -0.2071357911654843,
                                51.661015471369296
                            ],
                            [
                                -0.20828927446817147,
                                51.66273715991801
                            ],
                            [
                                -0.2122723723095703,
                                51.66141796211846
                            ],
                            [
                                -0.2133717860831723,
                                51.66234588505273
                            ],
                            [
                                -0.21886885494916442,
                                51.660121074131496
                            ],
                            [
                                -0.21937350389458743,
                                51.66076952276444
                            ],
                            [
                                -0.2232124440670873,
                                51.6590241649601
                            ],
                            [
                                -0.22613219867767498,
                                51.65735823991716
                            ],
                            [
                                -0.2235008148923896,
                                51.655256179995206
                            ],
                            [
                                -0.22268976675641738,
                                51.65357893413099
                            ],
                            [
                                -0.2216624456902423,
                                51.65275147020412
                            ],
                            [
                                -0.21986012802867094,
                                51.65277383429253
                            ],
                            [
                                -0.21775141636570083,
                                51.65294156460487
                            ],
                            [
                                -0.21760723095232493,
                                51.65248310028096
                            ],
                            [
                                -0.21587864706538085,
                                51.65249681862815
                            ],
                            [
                                -0.21845596132081369,
                                51.65232908667002
                            ],
                            [
                                -0.22031234851144177,
                                51.65156869400849
                            ],
                            [
                                -0.22247512756047172,
                                51.650047851058105
                            ],
                            [
                                -0.22508848816889326,
                                51.64974591677449
                            ],
                            [
                                -0.22748557065790465,
                                51.6491308592355
                            ],
                            [
                                -0.23092799739077918,
                                51.647721786869596
                            ],
                            [
                                -0.23229775881361547,
                                51.64705078463493
                            ],
                            [
                                -0.23438844982669593,
                                51.64596596497191
                            ],
                            [
                                -0.23720006537760696,
                                51.64500415476894
                            ],
                            [
                                -0.24085877022974955,
                                51.64291270634939
                            ],
                            [
                                -0.2431116673061524,
                                51.64151462553346
                            ],
                            [
                                -0.24491401626355014,
                                51.64080995555045
                            ],
                            [
                                -0.2480500489934343,
                                51.640854695499144
                            ],
                            [
                                -0.25059131689590686,
                                51.64101128497296
                            ],
                            [
                                -0.2517808465515543,
                                51.6413244622988
                            ],
                            [
                                -0.2531325847975836,
                                51.64202910337323
                            ],
                            [
                                -0.2541418826876338,
                                51.64257714997163
                            ],
                            [
                                -0.2548628097524386,
                                51.64248767342855
                            ],
                            [
                                -0.2557098820782642,
                                51.64280085481704
                            ],
                            [
                                -0.25664708726165486,
                                51.64144750991659
                            ],
                            [
                                -0.2607383483525325,
                                51.642711378715376
                            ],
                            [
                                -0.2632796162542377,
                                51.64464626004124
                            ],
                            [
                                -0.2679476189964589,
                                51.643975212286364
                            ],
                            [
                                -0.2680197117031753,
                                51.642610717889795
                            ],
                            [
                                -0.2726871804051143,
                                51.64200741745546
                            ],
                            [
                                -0.27378659417800577,
                                51.6387861101866
                            ],
                            [
                                -0.26901045237605103,
                                51.63744383128625
                            ],
                            [
                                -0.2662168632763269,
                                51.63629167719586
                            ],
                            [
                                -0.2652976812691179,
                                51.63566525086554
                            ],
                            [
                                -0.2666313963381981,
                                51.63479271405808
                            ],
                            [
                                -0.26619884009949146,
                                51.63407677388446
                            ],
                            [
                                -0.2650633799736113,
                                51.63336082240929
                            ],
                            [
                                -0.26383780396344036,
                                51.63205194440505
                            ],
                            [
                                -0.2632250159591365,
                                51.630474528419256
                            ],
                            [
                                -0.26288257560318584,
                                51.62877399017165
                            ],
                            [
                                -0.2638197807873155,
                                51.6283488456493
                            ],
                            [
                                -0.26540582032876614,
                                51.62982564634646
                            ],
                            [
                                -0.2671360452836211,
                                51.632007195771195
                            ],
                            [
                                -0.2697974391978164,
                                51.63526624685204
                            ],
                            [
                                -0.27163580321234804,
                                51.637156702314655
                            ],
                            [
                                -0.2768985707828904,
                                51.63773836506519
                            ],
                            [
                                -0.28165668940792443,
                                51.636921797935145
                            ],
                            [
                                -0.289568866545892,
                                51.6352550614277
                            ],
                            [
                                -0.29631721939659883,
                                51.63469735861429
                            ],
                            [
                                -0.2995433680092958,
                                51.63487634226797
                            ],
                            [
                                -0.299363136243727,
                                51.63287392238868
                            ],
                            [
                                -0.2984439542364612,
                                51.63160977940382
                            ],
                            [
                                -0.29574047774448786,
                                51.62976384362386
                            ],
                            [
                                -0.2920096832475281,
                                51.62741436373301
                            ],
                            [
                                -0.2880626075699695,
                                51.624628392334444
                            ],
                            [
                                -0.28707133285607256,
                                51.6243598559314
                            ],
                            [
                                -0.28642249849795576,
                                51.623811589173386
                            ],
                            [
                                -0.28705330967991927,
                                51.62330807304966
                            ],
                            [
                                -0.2881887698065668,
                                51.62325212646894
                            ],
                            [
                                -0.28878353463400686,
                                51.62358780491945
                            ],
                            [
                                -0.2888556273406948,
                                51.624102507053436
                            ],
                            [
                                -0.29053178276569724,
                                51.62540042563967
                            ],
                            [
                                -0.29563234140940153,
                                51.62875693915038
                            ],
                            [
                                -0.2984259397372284,
                                51.63064767478147
                            ],
                            [
                                -0.29972360845334833,
                                51.63162097677238
                            ],
                            [
                                -0.3006067441069149,
                                51.63280681074599
                            ],
                            [
                                -0.30154394929104456,
                                51.63416041352167
                            ],
                            [
                                -0.3029317338900057,
                                51.63514482653167
                            ],
                            [
                                -0.3045538197851556,
                                51.63564821129191
                            ],
                            [
                                -0.30516660690102526,
                                51.63422753828016
                            ],
                            [
                                -0.30493230560483653,
                                51.63322073163738
                            ],
                            [
                                -0.3036887064185123,
                                51.631643356296706
                            ],
                            [
                                -0.30055267368862815,
                                51.6291709058566
                            ],
                            [
                                -0.2998858070011181,
                                51.62841012307686
                            ],
                            [
                                -0.2978311648671763,
                                51.626799015116546
                            ],
                            [
                                -0.2960829167362533,
                                51.62533330470245
                            ],
                            [
                                -0.29444280766421116,
                                51.623833979410534
                            ],
                            [
                                -0.2929468840055449,
                                51.62235698366635
                            ],
                            [
                                -0.2923521191773375,
                                51.622189140198685
                            ],
                            [
                                -0.2919375861154663,
                                51.62168560607063
                            ],
                            [
                                -0.29195560929238695,
                                51.621349913547675
                            ],
                            [
                                -0.29303699988915355,
                                51.62132753395801
                            ],
                            [
                                -0.2932893243614956,
                                51.62194296865627
                            ],
                            [
                                -0.2949474566096342,
                                51.62337522072838
                            ],
                            [
                                -0.29765093310075486,
                                51.62563540156253
                            ],
                            [
                                -0.3010573084711723,
                                51.628577947539156
                            ],
                            [
                                -0.3040491557884195,
                                51.63089380642762
                            ],
                            [
                                -0.3055631026874437,
                                51.63273969767104
                            ],
                            [
                                -0.3060497284560313,
                                51.633813635351885
                            ],
                            [
                                -0.3056712417471772,
                                51.63473093698508
                            ],
                            [
                                -0.3052206623315783,
                                51.63579364129049
                            ],
                            [
                                -0.3082125096488255,
                                51.63661022873302
                            ],
                            [
                                -0.3149892435891388,
                                51.638679609462
                            ],
                            [
                                -0.31798109090718185,
                                51.639977111015355
                            ],
                            [
                                -0.3216037494055968,
                                51.637728830445326
                            ],
                            [
                                -0.32857873054896913,
                                51.635995001378745
                            ],
                            [
                                -0.3320391804579401,
                                51.63403737756258
                            ],
                            [
                                -0.33254382940336313,
                                51.633086501221754
                            ],
                            [
                                -0.33191298868095487,
                                51.630323246775134
                            ],
                            [
                                -0.33157054832582844,
                                51.62956248500751
                            ],
                            [
                                -0.3342560016409095,
                                51.63008830700261
                            ],
                            [
                                -0.33605831930168506,
                                51.63080431013205
                            ],
                            [
                                -0.3379327296692054,
                                51.629249226453965
                            ],
                            [
                                -0.3386716799100782,
                                51.627750030569814
                            ],
                            [
                                -0.3404018867749983,
                                51.62628434104357
                            ],
                            [
                                -0.34290710832371474,
                                51.62428154185889
                            ],
                            [
                                -0.34207804219926174,
                                51.62296121248093
                            ],
                            [
                                -0.3418617527947845,
                                51.62146180616216
                            ],
                            [
                                -0.34245651762304874,
                                51.62045471617614
                            ],
                            [
                                -0.34579080529616135,
                                51.62042114612501
                            ],
                            [
                                -0.35074717886374174,
                                51.62038757604907
                            ],
                            [
                                -0.353054184304483,
                                51.620622570897666
                            ],
                            [
                                -0.3559739389157812,
                                51.62173036455482
                            ],
                            [
                                -0.3577582334004603,
                                51.62099183845464
                            ],
                            [
                                -0.36053380259838264,
                                51.61832856875645
                            ],
                            [
                                -0.3622099581016016,
                                51.617052829589
                            ],
                            [
                                -0.3628227461059055,
                                51.61676186651022
                            ],
                            [
                                -0.3658145968301767,
                                51.61783617914671
                            ],
                            [
                                -0.3668419178971476,
                                51.61844047068794
                            ],
                            [
                                -0.3677070303737935,
                                51.619469985892636
                            ],
                            [
                                -0.36857214285120676,
                                51.62051066774251
                            ],
                            [
                                -0.3699419233398942,
                                51.6200070905131
                            ],
                            [
                                -0.37635817421386264,
                                51.61803758607775
                            ],
                            [
                                -0.3819814053163668,
                                51.61745567068209
                            ],
                            [
                                -0.3838558229887781,
                                51.61631418448752
                            ],
                            [
                                -0.39029009703889983,
                                51.615374127905
                            ],
                            [
                                -0.39611158308463246,
                                51.61449000930486
                            ],
                            [
                                -0.40018485220491584,
                                51.61334845335844
                            ],
                            [
                                -0.40398774246978064,
                                51.613214151977786
                            ],
                            [
                                -0.41033190063703273,
                                51.613773738442035
                            ],
                            [
                                -0.4116115461762888,
                                51.61414306172938
                            ],
                            [
                                -0.41399062329088565,
                                51.61297910414484
                            ],
                            [
                                -0.415234222477153,
                                51.61203897848631
                            ],
                            [
                                -0.41586503365837757,
                                51.610819024497516
                            ],
                            [
                                -0.41606328860086705,
                                51.610292979175
                            ],
                            [
                                -0.4192353676845073,
                                51.611266717842994
                            ],
                            [
                                -0.42094756946247003,
                                51.61127791012021
                            ],
                            [
                                -0.4228580261836896,
                                51.61118837182613
                            ],
                            [
                                -0.4244620889020041,
                                51.61136744823719
                            ],
                            [
                                -0.4259940589135738,
                                51.61174798326445
                            ],
                            [
                                -0.4283551179186986,
                                51.61177036754813
                            ],
                            [
                                -0.4307161540547213,
                                51.61173679107293
                            ],
                            [
                                -0.4325184717163211,
                                51.61146817837721
                            ],
                            [
                                -0.43350974642950746,
                                51.61112121795924
                            ],
                            [
                                -0.4349696237351566,
                                51.612408315857664
                            ],
                            [
                                -0.43675391821983567,
                                51.61330366678476
                            ],
                            [
                                -0.43884460670670933,
                                51.614590702800655
                            ],
                            [
                                -0.4410794806061915,
                                51.615418863200716
                            ],
                            [
                                -0.4424853209036712,
                                51.61382968571985
                            ],
                            [
                                -0.44789227388679365,
                                51.61368419408194
                            ],
                            [
                                -0.45019924049307747,
                                51.61455713691305
                            ],
                            [
                                -0.45198353497696075,
                                51.61367300239803
                            ],
                            [
                                -0.45540793853294304,
                                51.61231878829557
                            ],
                            [
                                -0.4570480698812389,
                                51.611983021626514
                            ],
                            [
                                -0.4591027120151523,
                                51.6123299754569
                            ],
                            [
                                -0.4604184039074255,
                                51.61213971078152
                            ],
                            [
                                -0.4635724598149409,
                                51.61109883697068
                            ],
                            [
                                -0.46564512512489387,
                                51.60943115068284
                            ],
                            [
                                -0.46816836985047416,
                                51.607640278397895
                            ],
                            [
                                -0.46952010809570766,
                                51.606308271324366
                            ],
                            [
                                -0.4727102411802946,
                                51.60546873141192
                            ],
                            [
                                -0.4778108001613077,
                                51.60403591418881
                            ],
                            [
                                -0.47973928005862376,
                                51.60329710014025
                            ],
                            [
                                -0.4812532268941254,
                                51.6038456147501
                            ],
                            [
                                -0.48246077972672197,
                                51.604349346819674
                            ],
                            [
                                -0.4817939221925087,
                                51.60502098088659
                            ],
                            [
                                -0.48085671700835064,
                                51.607013436828794
                            ],
                            [
                                -0.4799195118250452,
                                51.60881552672518
                            ],
                            [
                                -0.47741429947311076,
                                51.612094914644985
                            ],
                            [
                                -0.47687360417469904,
                                51.61399752739035
                            ],
                            [
                                -0.4760625624171553,
                                51.61609030739817
                            ],
                            [
                                -0.47530558899941866,
                                51.61783608372045
                            ],
                            [
                                -0.47292652968729953,
                                51.6192125138578
                            ],
                            [
                                -0.48056836337440245,
                                51.62362129534563
                            ],
                            [
                                -0.48876891565427627,
                                51.627425491679986
                            ],
                            [
                                -0.49158053120513046,
                                51.630692355866046
                            ],
                            [
                                -0.49695143783534945,
                                51.631643282396965
                            ],
                            [
                                -0.4957619081788778,
                                51.62867856329004
                            ],
                            [
                                -0.49282413039142625,
                                51.626899638819765
                            ],
                            [
                                -0.49451830899334936,
                                51.62510945573047
                            ],
                            [
                                -0.4953834214700237,
                                51.62540036528898
                            ],
                            [
                                -0.4939235441651135,
                                51.62676537753825
                            ],
                            [
                                -0.4966089974801662,
                                51.6283876747508
                            ],
                            [
                                -0.4971857391315666,
                                51.629965163303666
                            ],
                            [
                                -0.49859154690733476,
                                51.63092729251014
                            ],
                            [
                                -0.4998351498133502,
                                51.628309360871754
                            ],
                            [
                                -0.4975822527370042,
                                51.62668706085873
                            ],
                            [
                                -0.5000153815789758,
                                51.623710828627395
                            ],
                            [
                                -0.4995467824541322,
                                51.62128270254618
                            ],
                            [
                                -0.4971677231412457,
                                51.6207343986062
                            ],
                            [
                                -0.4954735445401184,
                                51.619246111657645
                            ],
                            [
                                -0.4963026121024541,
                                51.61667226384006
                            ],
                            [
                                -0.49835725423560007,
                                51.612307585198124
                            ],
                            [
                                -0.4989339995617854,
                                51.61020343490296
                            ],
                            [
                                -0.4982851652036686,
                                51.609576648302294
                            ],
                            [
                                -0.4987898141482958,
                                51.60859168044462
                            ],
                            [
                                -0.4983392347335496,
                                51.60759549803444
                            ],
                            [
                                -0.497402024948542,
                                51.60455084553894
                            ],
                            [
                                -0.4962124952920419,
                                51.6021216968347
                            ],
                            [
                                -0.5007543357978079,
                                51.60046487571131
                            ],
                            [
                                -0.4998052942528659,
                                51.597411893919315
                            ],
                            [
                                -0.49894018177545263,
                                51.59286621462422
                            ],
                            [
                                -0.4977060209415072,
                                51.58998373810002
                            ],
                            [
                                -0.4960839350470394,
                                51.58844965124595
                            ],
                            [
                                -0.49505661882477625,
                                51.58675872929021
                            ],
                            [
                                -0.49500254929495213,
                                51.58492216767456
                            ],
                            [
                                -0.4937949964623556,
                                51.58395906300939
                            ],
                            [
                                -0.49240721186342284,
                                51.58488857134384
                            ],
                            [
                                -0.48901885466042927,
                                51.586377984811065
                            ],
                            [
                                -0.48507175751859677,
                                51.5879681572294
                            ],
                            [
                                -0.48103456595754324,
                                51.58977100300061
                            ],
                            [
                                -0.48022352300989724,
                                51.590062139086115
                            ],
                            [
                                -0.4747805236738145,
                                51.58668036644707
                            ],
                            [
                                -0.4712479810582124,
                                51.584407046673874
                            ],
                            [
                                -0.47040088092910537,
                                51.58326915953302
                            ],
                            [
                                -0.47067122857831123,
                                51.580928470590464
                            ],
                            [
                                -0.4704549504589579,
                                51.5800660811135
                            ],
                            [
                                -0.47067122857831123,
                                51.57863246239128
                            ],
                            [
                                -0.4701665796328598,
                                51.57704198870644
                            ],
                            [
                                -0.46910320991457866,
                                51.57509302155225
                            ],
                            [
                                -0.46749914719634944,
                                51.57425292414894
                            ],
                            [
                                -0.46656194201219137,
                                51.57137407261587
                            ],
                            [
                                -0.4654598073771581,
                                51.56820150906333
                            ],
                            [
                                -0.464756903489274,
                                51.56596087516556
                            ],
                            [
                                -0.4641801567434811,
                                51.56422430243029
                            ],
                            [
                                -0.4633330674429317,
                                51.56351845292528
                            ],
                            [
                                -0.46430631898005004,
                                51.56312630957629
                            ],
                            [
                                -0.466108636640854,
                                51.56230839970971
                            ],
                            [
                                -0.46800107018520976,
                                51.56052687387617
                            ],
                            [
                                -0.4686859542381683,
                                51.559395162036054
                            ],
                            [
                                -0.46809118940993244,
                                51.55900298312963
                            ],
                            [
                                -0.4688121164739698,
                                51.5579048641996
                            ],
                            [
                                -0.4704882718989438,
                                51.55826343656139
                            ],
                            [
                                -0.4723807054432996,
                                51.55875646894296
                            ],
                            [
                                -0.476435920180478,
                                51.559227085775916
                            ],
                            [
                                -0.4769045227721733,
                                51.55685154153687
                            ],
                            [
                                -0.4775533571302333,
                                51.55506980192985
                            ],
                            [
                                -0.47886904902244964,
                                51.55486809116118
                            ],
                            [
                                -0.480797537256592,
                                51.55322074577617
                            ],
                            [
                                -0.4828161330375167,
                                51.551506106031866
                            ],
                            [
                                -0.4840597322230451,
                                51.55147248499864
                            ],
                            [
                                -0.4849248447004584,
                                51.55083368064663
                            ],
                            [
                                -0.4835370601014972,
                                51.55025090393991
                            ],
                            [
                                -0.48303241115687,
                                51.5498698535948
                            ],
                            [
                                -0.48297834162698905,
                                51.549264649428125
                            ],
                            [
                                -0.4830143745713258,
                                51.5484464875741
                            ],
                            [
                                -0.4827260037452561,
                                51.54835682540008
                            ],
                            [
                                -0.48222135480057204,
                                51.547942135548595
                            ],
                            [
                                -0.48279809645194405,
                                51.547292073579655
                            ],
                            [
                                -0.48274402692211993,
                                51.54676529241297
                            ],
                            [
                                -0.4821132157408954,
                                51.54575654549734
                            ],
                            [
                                -0.4817887985618938,
                                51.54544270856263
                            ],
                            [
                                -0.4800946199607665,
                                51.54474777621769
                            ],
                            [
                                -0.4796079941921789,
                                51.54450118476967
                            ],
                            [
                                -0.48020275902041476,
                                51.54423217439333
                            ],
                            [
                                -0.48119403373362957,
                                51.5438174469476
                            ],
                            [
                                -0.4818969376215989,
                                51.54395195356
                            ],
                            [
                                -0.4822033316237082,
                                51.54433305347084
                            ],
                            [
                                -0.4829603050414448,
                                51.544613271957274
                            ],
                            [
                                -0.4857178510632423,
                                51.54435547101306
                            ],
                            [
                                -0.4865829635399166,
                                51.54416492155073
                            ],
                            [
                                -0.48761027879979224,
                                51.54327941097958
                            ],
                            [
                                -0.4882771363346876,
                                51.542875883984124
                            ],
                            [
                                -0.4886736462204624,
                                51.541945516442325
                            ],
                            [
                                -0.4895928282269608,
                                51.541104806521076
                            ],
                            [
                                -0.4905841029409146,
                                51.54067884089895
                            ],
                            [
                                -0.49215211930624037,
                                51.54048827604058
                            ],
                            [
                                -0.493738158847691,
                                51.54054432461109
                            ],
                            [
                                -0.4954323374488183,
                                51.538515322374565
                            ],
                            [
                                -0.4936120054656499,
                                51.536374119371885
                            ],
                            [
                                -0.49087248262148364,
                                51.533806788794266
                            ],
                            [
                                -0.49011551517381235,
                                51.53170650442746
                            ],
                            [
                                -0.4891963331665181,
                                51.52940801018235
                            ],
                            [
                                -0.48634867126193626,
                                51.53002469076057
                            ],
                            [
                                -0.48526728066516966,
                                51.53011438905824
                            ],
                            [
                                -0.483428916651377,
                                51.52991256764065
                            ],
                            [
                                -0.4823835724075707,
                                51.52978923188911
                            ],
                            [
                                -0.48232950287771814,
                                51.530091964500144
                            ],
                            [
                                -0.4827620591164532,
                                51.53131408679593
                            ],
                            [
                                -0.4829783372357781,
                                51.532098918676326
                            ],
                            [
                                -0.4829422908827894,
                                51.53246890615574
                            ],
                            [
                                -0.4822213638180699,
                                51.53244648275793
                            ],
                            [
                                -0.48213124793522866,
                                51.531538325856644
                            ],
                            [
                                -0.4817707844032384,
                                51.530719847942436
                            ],
                            [
                                -0.48168066851962976,
                                51.53003590305755
                            ],
                            [
                                -0.4815725294599815,
                                51.529452859960344
                            ],
                            [
                                -0.482924267705954,
                                51.52935194789703
                            ],
                            [
                                -0.4844742608944159,
                                51.52963225863178
                            ],
                            [
                                -0.4857899527866607,
                                51.52965468341583
                            ],
                            [
                                -0.48771843268400517,
                                51.529273460582374
                            ],
                            [
                                -0.4889800550471648,
                                51.52892587227524
                            ],
                            [
                                -0.4896288894052816,
                                51.52758034413327
                            ],
                            [
                                -0.490512030425748,
                                51.52531527821455
                            ],
                            [
                                -0.4906742390153056,
                                51.52251182741085
                            ],
                            [
                                -0.49140811044318866,
                                51.519145238028074
                            ],
                            [
                                -0.4920929911541805,
                                51.51635262303418
                            ],
                            [
                                -0.48984010568915437,
                                51.51444592498419
                            ],
                            [
                                -0.48974998980634155,
                                51.511675455496174
                            ],
                            [
                                -0.4868662714642369,
                                51.51062106259499
                            ],
                            [
                                -0.4847395366243745,
                                51.5090730797601
                            ],
                            [
                                -0.4831534970829523,
                                51.50726703330949
                            ],
                            [
                                -0.484415762512441,
                                51.50552719658657
                            ],
                            [
                                -0.4845779711019418,
                                51.50374344712429
                            ],
                            [
                                -0.48537096401659596,
                                51.502520586738626
                            ],
                            [
                                -0.4827576034082597,
                                51.50091623138678
                            ],
                            [
                                -0.4832081828237733,
                                51.500489889696894
                            ],
                            [
                                -0.4859116593150077,
                                51.50215035590122
                            ],
                            [
                                -0.48838083451073544,
                                51.50035525465037
                            ],
                            [
                                -0.4925982578374146,
                                51.497987856767224
                            ],
                            [
                                -0.48805640793096927,
                                51.495092947233815
                            ],
                            [
                                -0.48378491507443755,
                                51.49484608678242
                            ],
                            [
                                -0.47670180666628426,
                                51.494924633434806
                            ],
                            [
                                -0.46949253153405834,
                                51.4956652089148
                            ],
                            [
                                -0.46332860513317087,
                                51.49603549243585
                            ],
                            [
                                -0.45914722815945197,
                                51.49619258150619
                            ],
                            [
                                -0.45556058681012246,
                                51.49637210921816
                            ],
                            [
                                -0.45388443138512,
                                51.49670872550516
                            ],
                            [
                                -0.45220827596082813,
                                51.496495535478545
                            ],
                            [
                                -0.45071235230224715,
                                51.496170138252694
                            ],
                            [
                                -0.4446024954311554,
                                51.49544079120446
                            ],
                            [
                                -0.43988040833667696,
                                51.494745092625635
                            ],
                            [
                                -0.4315717239190633,
                                51.49410549200218
                            ],
                            [
                                -0.4201991600105828,
                                51.49333122724377
                            ],
                            [
                                -0.4130619820725485,
                                51.493084357251746
                            ],
                            [
                                -0.41070094593652584,
                                51.49325267784579
                            ],
                            [
                                -0.4088265355690339,
                                51.49288237171851
                            ],
                            [
                                -0.4091509527480355,
                                51.49061558380231
                            ],
                            [
                                -0.4112236180580737,
                                51.490828801329485
                            ],
                            [
                                -0.4116201279438201,
                                51.49167043972537
                            ],
                            [
                                -0.41531487914892296,
                                51.49222030175201
                            ],
                            [
                                -0.4206497394254143,
                                51.492590613257306
                            ],
                            [
                                -0.43189621539997347,
                                51.49335367139864
                            ],
                            [
                                -0.4453234877771308,
                                51.4945655600651
                            ],
                            [
                                -0.45138381229412516,
                                51.494898997754945
                            ],
                            [
                                -0.4521082360336095,
                                51.494406025365635
                            ],
                            [
                                -0.4552417898810859,
                                51.49500388480919
                            ],
                            [
                                -0.46059915613889757,
                                51.495381476207086
                            ],
                            [
                                -0.46669782100389057,
                                51.49475215916195
                            ],
                            [
                                -0.4747338703884907,
                                51.49404940740774
                            ],
                            [
                                -0.48076515076277815,
                                51.49381865852402
                            ],
                            [
                                -0.4869143290129898,
                                51.49385012541143
                            ],
                            [
                                -0.49025004762634694,
                                51.4932312726381
                            ],
                            [
                                -0.4937205427483491,
                                51.4910285081568
                            ],
                            [
                                -0.4958095815658794,
                                51.48787102500205
                            ],
                            [
                                -0.49936430950711497,
                                51.48405237659827
                            ],
                            [
                                -0.49134510718505453,
                                51.483832060590345
                            ],
                            [
                                -0.49153042488558185,
                                51.48327602021823
                            ],
                            [
                                -0.4925075545799018,
                                51.48266751543733
                            ],
                            [
                                -0.4939901046107593,
                                51.4797297918079
                            ],
                            [
                                -0.49361946920978994,
                                51.47892188466935
                            ],
                            [
                                -0.49429335175747724,
                                51.47848120201624
                            ],
                            [
                                -0.49525363438786485,
                                51.47649807738631
                            ],
                            [
                                -0.4955400453305572,
                                51.475438271042975
                            ],
                            [
                                -0.49466399801701755,
                                51.475375311830646
                            ],
                            [
                                -0.49388903308712884,
                                51.475091994299845
                            ],
                            [
                                -0.4936700212587368,
                                51.47436795261544
                            ],
                            [
                                -0.4951188687377055,
                                51.47402166774779
                            ],
                            [
                                -0.49399011546933025,
                                51.472877859654005
                            ],
                            [
                                -0.4918505383786851,
                                51.47093646834523
                            ],
                            [
                                -0.49136197633924894,
                                51.469467247743694
                            ],
                            [
                                -0.49153044697720816,
                                51.46689600605873
                            ],
                            [
                                -0.4923391060350184,
                                51.46482841274903
                            ],
                            [
                                -0.49237281883799255,
                                51.463894282644844
                            ],
                            [
                                -0.49195164224653354,
                                51.46336948712022
                            ],
                            [
                                -0.4924065129658288,
                                51.46285518165095
                            ],
                            [
                                -0.49046910063967175,
                                51.46231987796554
                            ],
                            [
                                -0.4898120651559168,
                                51.46179506433543
                            ],
                            [
                                -0.4889360178438267,
                                51.461732086294234
                            ],
                            [
                                -0.48711653496380336,
                                51.46129123757245
                            ],
                            [
                                -0.4836460398403517,
                                51.46115478353346
                            ],
                            [
                                -0.48057985148767557,
                                51.461238760288154
                            ],
                            [
                                -0.4780022507416959,
                                51.46132273189798
                            ],
                            [
                                -0.4765870973905919,
                                51.46103932709511
                            ],
                            [
                                -0.4761153796073643,
                                51.4605564852298
                            ],
                            [
                                -0.4757447442063665,
                                51.46008413498362
                            ],
                            [
                                -0.4743127437913017,
                                51.4591499167681
                            ],
                            [
                                -0.4731502963963976,
                                51.458646060804
                            ],
                            [
                                -0.4707580133510021,
                                51.45823667373756
                            ],
                            [
                                -0.4662766514064458,
                                51.45781678900997
                            ],
                            [
                                -0.46351373295996723,
                                51.4574808758405
                            ],
                            [
                                -0.46080135570392144,
                                51.457207944570456
                            ],
                            [
                                -0.45935250822634544,
                                51.456777549636314
                            ],
                            [
                                -0.45741509590018836,
                                51.45574878432606
                            ],
                            [
                                -0.45578093072066395,
                                51.455339371273425
                            ],
                            [
                                -0.4553429070653294,
                                51.455664802460916
                            ],
                            [
                                -0.454096224350792,
                                51.455591318201925
                            ],
                            [
                                -0.4537929772041025,
                                51.455192401589784
                            ],
                            [
                                -0.45418045966903264,
                                51.45464651004983
                            ],
                            [
                                -0.456791754542877,
                                51.45525538865505
                            ],
                            [
                                -0.4578699666194268,
                                51.45560181596059
                            ],
                            [
                                -0.45886394337776437,
                                51.456063714944804
                            ],
                            [
                                -0.45926827290662686,
                                51.45292480935598
                            ],
                            [
                                -0.4612899237671684,
                                51.45239988347478
                            ],
                            [
                                -0.46152578265807165,
                                51.449019243359345
                            ],
                            [
                                -0.4575835454160426,
                                51.44917673693118
                            ],
                            [
                                -0.4576003924799181,
                                51.44704534251338
                            ],
                            [
                                -0.4516533789941377,
                                51.448221296564896
                            ],
                            [
                                -0.4482502721264723,
                                51.448966751751755
                            ],
                            [
                                -0.44734053068648905,
                                51.44973319298637
                            ],
                            [
                                -0.44621177741805695,
                                51.44973319298637
                            ],
                            [
                                -0.445723212570897,
                                51.449061245202074
                            ],
                            [
                                -0.44644763631040973,
                                51.44845228398023
                            ],
                            [
                                -0.4478627896615137,
                                51.44732883420696
                            ],
                            [
                                -0.4493790255214094,
                                51.445554349483416
                            ],
                            [
                                -0.45121535546533664,
                                51.44285575232564
                            ],
                            [
                                -0.4530516854078712,
                                51.440367021389505
                            ],
                            [
                                -0.45386034322837077,
                                51.43844524674478
                            ],
                            [
                                -0.44636336411045363,
                                51.43993647213364
                            ],
                            [
                                -0.4477953645255184,
                                51.43499006397553
                            ],
                            [
                                -0.4419494334205467,
                                51.43468549055362
                            ],
                            [
                                -0.43986039752175543,
                                51.43456996217239
                            ],
                            [
                                -0.4372996438397365,
                                51.43508458634881
                            ],
                            [
                                -0.43254877187530383,
                                51.436008794803655
                            ],
                            [
                                -0.4315547951169947,
                                51.43650239847773
                            ],
                            [
                                -0.4291793450713328,
                                51.43721654310241
                            ],
                            [
                                -0.42693868559848624,
                                51.43744758618536
                            ],
                            [
                                -0.42540560280119166,
                                51.43759461299382
                            ],
                            [
                                -0.4252708262925182,
                                51.43734256674722
                            ],
                            [
                                -0.425692002883892,
                                51.436554913262626
                            ],
                            [
                                -0.4260963324128113,
                                51.43481152525143
                            ],
                            [
                                -0.4262479559868382,
                                51.43359321461776
                            ],
                            [
                                -0.4268544595828132,
                                51.43263744466955
                            ],
                            [
                                -0.42720824791987866,
                                51.431744677745854
                            ],
                            [
                                -0.4272419420478002,
                                51.43083088647478
                            ],
                            [
                                -0.4237377527978481,
                                51.430914914021685
                            ],
                            [
                                -0.4227943172299433,
                                51.431524109112445
                            ],
                            [
                                -0.42055364804869555,
                                51.4321963358893
                            ],
                            [
                                -0.4189363299331035,
                                51.432280360924466
                            ],
                            [
                                -0.4184140709580788,
                                51.43309959691979
                            ],
                            [
                                -0.4169146822886489,
                                51.433383175187714
                            ],
                            [
                                -0.41512889353654714,
                                51.43323613482423
                            ],
                            [
                                -0.41155731603228674,
                                51.432301367159425
                            ],
                            [
                                -0.4081542091645929,
                                51.431723692186324
                            ],
                            [
                                -0.4057787416847418,
                                51.43091493989024
                            ],
                            [
                                -0.4037739411042196,
                                51.430841415795356
                            ],
                            [
                                -0.40222401124441376,
                                51.430799401973644
                            ],
                            [
                                -0.40123003448616146,
                                51.43061033929848
                            ],
                            [
                                -0.4010952579760101,
                                51.430043146578925
                            ],
                            [
                                -0.40266203490116936,
                                51.42937090905292
                            ],
                            [
                                -0.40416142357057083,
                                51.427396154119606
                            ],
                            [
                                -0.40630099683625076,
                                51.42445487251575
                            ],
                            [
                                -0.4074465971685868,
                                51.42336234790898
                            ],
                            [
                                -0.4055765730967664,
                                51.422553442670505
                            ],
                            [
                                -0.40362231370806967,
                                51.42322578049692
                            ],
                            [
                                -0.3987029711070136,
                                51.42366699681958
                            ],
                            [
                                -0.3945417071716406,
                                51.42328882150764
                            ],
                            [
                                -0.3907342707750274,
                                51.42218576358502
                            ],
                            [
                                -0.3865898931039453,
                                51.420158168626415
                            ],
                            [
                                -0.3876512588163905,
                                51.419317675955654
                            ],
                            [
                                -0.386404576103331,
                                51.414652729701544
                            ],
                            [
                                -0.38210857485955785,
                                51.41427446995115
                            ],
                            [
                                -0.37875600918226837,
                                51.41398026575871
                            ],
                            [
                                -0.3757740789073978,
                                51.41417990452442
                            ],
                            [
                                -0.3736008076902806,
                                51.41411686079789
                            ],
                            [
                                -0.37019768236922346,
                                51.413749101324754
                            ],
                            [
                                -0.3666934931192998,
                                51.4133393116868
                            ],
                            [
                                -0.36502563381333175,
                                51.41346540119741
                            ],
                            [
                                -0.3632229979972408,
                                51.41336032662923
                            ],
                            [
                                -0.36059485605946406,
                                51.4129610410684
                            ],
                            [
                                -0.358488973096712,
                                51.41265632079421
                            ],
                            [
                                -0.3563493844017671,
                                51.4120889052779
                            ],
                            [
                                -0.35154797124693005,
                                51.410481189563086
                            ],
                            [
                                -0.3468307934103052,
                                51.40825341160439
                            ],
                            [
                                -0.3450113105303103,
                                51.40732864170397
                            ],
                            [
                                -0.3424337689068295,
                                51.405867903814624
                            ],
                            [
                                -0.34145663921248115,
                                51.405489571379576
                            ],
                            [
                                -0.3409175331734673,
                                51.40514276389774
                            ],
                            [
                                -0.34213052176016845,
                                51.4035453136604
                            ],
                            [
                                -0.34012572117964623,
                                51.402599428666036
                            ],
                            [
                                -0.33751442632791395,
                                51.39999288877263
                            ],
                            [
                                -0.3330162636916043,
                                51.39651376911658
                            ],
                            [
                                -0.33080929834665085,
                                51.39379125093808
                            ],
                            [
                                -0.3282317193958022,
                                51.39200418862703
                            ],
                            [
                                -0.3256541186497941,
                                51.391415491749626
                            ],
                            [
                                -0.3197070920726617,
                                51.39264544646778
                            ],
                            [
                                -0.3177191385560718,
                                51.39363358534777
                            ],
                            [
                                -0.3168430912439817,
                                51.3930238851562
                            ],
                            [
                                -0.3141644081157722,
                                51.39448504960342
                            ],
                            [
                                -0.3125976311906129,
                                51.39303439729747
                            ],
                            [
                                -0.3102390422729968,
                                51.39198317123052
                            ],
                            [
                                -0.30848694764733864,
                                51.39113166041054
                            ],
                            [
                                -0.30543762256857576,
                                51.389575772706735
                            ],
                            [
                                -0.3044099516824019,
                                51.38917627944849
                            ],
                            [
                                -0.30275893944036625,
                                51.38826163701364
                            ],
                            [
                                -0.3009394565604282,
                                51.38717876083945
                            ],
                            [
                                -0.3003666563949423,
                                51.386831814508895
                            ],
                            [
                                -0.2999286327381583,
                                51.38534937782924
                            ],
                            [
                                -0.29994547227440194,
                                51.38386688717469
                            ],
                            [
                                -0.30029926061155265,
                                51.38273133449596
                            ],
                            [
                                -0.3012426961794006,
                                51.38041808447679
                            ],
                            [
                                -0.3023714466442584,
                                51.3775684172073
                            ],
                            [
                                -0.3032643410203093,
                                51.37540213315495
                            ],
                            [
                                -0.29849662199333693,
                                51.37455031377323
                            ],
                            [
                                -0.29403215011302564,
                                51.37369847854171
                            ],
                            [
                                -0.29098282880852366,
                                51.37380364382591
                            ],
                            [
                                -0.28823675742458477,
                                51.374245337132066
                            ],
                            [
                                -0.2854232977862807,
                                51.37548626218717
                            ],
                            [
                                -0.28336795601535414,
                                51.377021597479
                            ],
                            [
                                -0.2816832575363435,
                                51.3787251221718
                            ],
                            [
                                -0.2812789280074526,
                                51.379692531274884
                            ],
                            [
                                -0.28031864537706497,
                                51.379944895504394
                            ],
                            [
                                -0.2790551155985952,
                                51.381332873895275
                            ],
                            [
                                -0.2760226474409819,
                                51.38436104176361
                            ],
                            [
                                -0.27378198796813535,
                                51.38652690184284
                            ],
                            [
                                -0.2720635874703987,
                                51.387756980614
                            ],
                            [
                                -0.2677675952691061,
                                51.38981754865864
                            ],
                            [
                                -0.2646677355480733,
                                51.39131035312795
                            ],
                            [
                                -0.2625113113949453,
                                51.39230903315118
                            ],
                            [
                                -0.2593440431786007,
                                51.39370715920742
                            ],
                            [
                                -0.2568169836230254,
                                51.39435889765434
                            ],
                            [
                                -0.2548795712968399,
                                51.394758345661586
                            ],
                            [
                                -0.2542225358131418,
                                51.39517881348041
                            ],
                            [
                                -0.25307693548077737,
                                51.395094720225984
                            ],
                            [
                                -0.2528073824620094,
                                51.39443248044424
                            ],
                            [
                                -0.2529927066657365,
                                51.394180191385715
                            ],
                            [
                                -0.25284108309165276,
                                51.39355998646073
                            ],
                            [
                                -0.2523525182445212,
                                51.39179393316101
                            ],
                            [
                                -0.2525546830089809,
                                51.39082677984629
                            ],
                            [
                                -0.2527063065829793,
                                51.38938652452018
                            ],
                            [
                                -0.2526052242007779,
                                51.38852445219868
                            ],
                            [
                                -0.25277369976367936,
                                51.38791467896192
                            ],
                            [
                                -0.25366659413975867,
                                51.38654792744114
                            ],
                            [
                                -0.2521335113424925,
                                51.3853598718012
                            ],
                            [
                                -0.24839346320024447,
                                51.382889037848315
                            ],
                            [
                                -0.24667505655349942,
                                51.381900665786304
                            ],
                            [
                                -0.2440132204892791,
                                51.38054424965347
                            ],
                            [
                                -0.24335618500407463,
                                51.37962943468048
                            ],
                            [
                                -0.2398351415188813,
                                51.37807315556432
                            ],
                            [
                                -0.23811674102111624,
                                51.377021585762634
                            ],
                            [
                                -0.23705537600710613,
                                51.37694797497167
                            ],
                            [
                                -0.23572445797566388,
                                51.376096184354395
                            ],
                            [
                                -0.2329446924638603,
                                51.37395086368417
                            ],
                            [
                                -0.2313105212800508,
                                51.37300436590141
                            ],
                            [
                                -0.22959212078228575,
                                51.37228922188578
                            ],
                            [
                                -0.22834543806769148,
                                51.37148993006099
                            ],
                            [
                                -0.2276715555200326,
                                51.37098510698195
                            ],
                            [
                                -0.22977743848275622,
                                51.36904990028225
                            ],
                            [
                                -0.23186647212753542,
                                51.36711460805728
                            ],
                            [
                                -0.23306261365021896,
                                51.36581034576602
                            ],
                            [
                                -0.22681233546774138,
                                51.36257055982193
                            ],
                            [
                                -0.22445374654873262,
                                51.35965666585423
                            ],
                            [
                                -0.22295439184870247,
                                51.35740536291303
                            ],
                            [
                                -0.2193322731526166,
                                51.3578261739591
                            ],
                            [
                                -0.21739486082788062,
                                51.35780513349829
                            ],
                            [
                                -0.21582808390274977,
                                51.357973456911736
                            ],
                            [
                                -0.21520474254543842,
                                51.35831010188258
                            ],
                            [
                                -0.2140928363424166,
                                51.358751944652425
                            ],
                            [
                                -0.21269453005524497,
                                51.35904650413141
                            ],
                            [
                                -0.21178478861526173,
                                51.35726859849106
                            ],
                            [
                                -0.21072342360116636,
                                51.354911978159095
                            ],
                            [
                                -0.20888723269993648,
                                51.35278670515925
                            ],
                            [
                                -0.20662972616455022,
                                51.34978801226288
                            ],
                            [
                                -0.20408581727687647,
                                51.34488446114776
                            ],
                            [
                                -0.20253588741564954,
                                51.34179053598916
                            ],
                            [
                                -0.2014913694670213,
                                51.340033009295695
                            ],
                            [
                                -0.19935179237637612,
                                51.342537727520494
                            ],
                            [
                                -0.19818934498152885,
                                51.34400050384312
                            ],
                            [
                                -0.19574652074419419,
                                51.343474330686234
                            ],
                            [
                                -0.19396070286322242,
                                51.34295867081997
                            ],
                            [
                                -0.19379223222665587,
                                51.342579818144344
                            ],
                            [
                                -0.18860333660686024,
                                51.34099070752609
                            ],
                            [
                                -0.18536870037570452,
                                51.33948573740446
                            ],
                            [
                                -0.1791113970633944,
                                51.336032175158124
                            ],
                            [
                                -0.175025353356574,
                                51.33380872349085
                            ],
                            [
                                -0.1737449765155361,
                                51.33434553322911
                            ],
                            [
                                -0.172397201825504,
                                51.33497707742623
                            ],
                            [
                                -0.1697522128251876,
                                51.33493497550907
                            ],
                            [
                                -0.16732623565184213,
                                51.335019179305164
                            ],
                            [
                                -0.16702298850518105,
                                51.33484024605346
                            ],
                            [
                                -0.167376776843696,
                                51.3342297626441
                            ],
                            [
                                -0.16845498892027422,
                                51.331861431103135
                            ],
                            [
                                -0.16941528131502537,
                                51.33031404947556
                            ],
                            [
                                -0.16961744607948503,
                                51.329503498341694
                            ],
                            [
                                -0.1629965500445678,
                                51.33024036360075
                            ],
                            [
                                -0.16239005575127408,
                                51.329008739308904
                            ],
                            [
                                -0.1641926915673082,
                                51.328135002872756
                            ],
                            [
                                -0.16383890323027117,
                                51.32650328182032
                            ],
                            [
                                -0.16410847322435984,
                                51.32601900916222
                            ],
                            [
                                -0.16067167223019396,
                                51.32319754405026
                            ],
                            [
                                -0.15858263633001002,
                                51.32389239858327
                            ],
                            [
                                -0.1565272966712996,
                                51.32138665769966
                            ],
                            [
                                -0.16206998062821754,
                                51.319649406722306
                            ],
                            [
                                -0.16084014497903354,
                                51.31705920126964
                            ],
                            [
                                -0.1592902151178066,
                                51.31751197130737
                            ],
                            [
                                -0.1563082848429076,
                                51.31782785473402
                            ],
                            [
                                -0.15270301321081092,
                                51.31852279061599
                            ],
                            [
                                -0.1501422595273425,
                                51.31918612868259
                            ],
                            [
                                -0.148070070692512,
                                51.319649406722306
                            ],
                            [
                                -0.14593046385374464,
                                51.319712590485665
                            ],
                            [
                                -0.14387512208139697,
                                51.319365132873
                            ],
                            [
                                -0.14136490958978243,
                                51.32000740094122
                            ],
                            [
                                -0.14038777989540563,
                                51.32032326718419
                            ],
                            [
                                -0.14013507394059843,
                                51.31994422743165
                            ],
                            [
                                -0.1393601090092318,
                                51.31898608520302
                            ],
                            [
                                -0.13870307352553368,
                                51.31864915131143
                            ],
                            [
                                -0.13838297931491184,
                                51.317806805754856
                            ],
                            [
                                -0.13742269668452423,
                                51.31694338550639
                            ],
                            [
                                -0.13656349643491694,
                                51.31660643661107
                            ],
                            [
                                -0.1355021312235749,
                                51.31555345111951
                            ],
                            [
                                -0.13514834288648103,
                                51.31450044570116
                            ],
                            [
                                -0.1346429309753887,
                                51.31388969148438
                            ],
                            [
                                -0.13268867158527087,
                                51.312910361717655
                            ],
                            [
                                -0.1295045765459406,
                                51.31146765505787
                            ],
                            [
                                -0.12591613856096728,
                                51.309803745172445
                            ],
                            [
                                -0.12372602028000301,
                                51.30896123719893
                            ],
                            [
                                -0.12232771399277453,
                                51.30807658717822
                            ],
                            [
                                -0.1216875255716161,
                                51.30800286557363
                            ],
                            [
                                -0.11966587792713312,
                                51.30736042943229
                            ],
                            [
                                -0.1189751483155419,
                                51.30686543151711
                            ],
                            [
                                -0.11852027643311658,
                                51.305285612056736
                            ],
                            [
                                -0.11836865286051079,
                                51.304042785021124
                            ],
                            [
                                -0.11799801745809191,
                                51.30256820081726
                            ],
                            [
                                -0.11764422912102646,
                                51.30144116523465
                            ],
                            [
                                -0.11683556694137565,
                                51.30038783465557
                            ],
                            [
                                -0.11587528430959537,
                                51.29941875035087
                            ],
                            [
                                -0.1144095897680586,
                                51.298344306879756
                            ],
                            [
                                -0.11324714237181865,
                                51.298017755351424
                            ],
                            [
                                -0.11014728265078588,
                                51.29788081369696
                            ],
                            [
                                -0.10684524399823658,
                                51.2981652332513
                            ],
                            [
                                -0.10366114895890632,
                                51.298470716027566
                            ],
                            [
                                -0.10184166607896827,
                                51.297575329723
                            ],
                            [
                                -0.10049390098214417,
                                51.29645870586239
                            ],
                            [
                                -0.09476588847493872,
                                51.29914488133858
                            ],
                            [
                                -0.09479958260141075,
                                51.29958729456837
                            ],
                            [
                                -0.09725925390262091,
                                51.30047210823574
                            ],
                            [
                                -0.09747826573101293,
                                51.301061974539266
                            ],
                            [
                                -0.09633266539870533,
                                51.30151490244833
                            ],
                            [
                                -0.09557454753272054,
                                51.30139903759476
                            ],
                            [
                                -0.09451318251868202,
                                51.30061957552252
                            ],
                            [
                                -0.0911606168427852,
                                51.301483302971775
                            ],
                            [
                                -0.09087421676008489,
                                51.30289472504242
                            ],
                            [
                                -0.08868409550743195,
                                51.305085504386966
                            ],
                            [
                                -0.08902103678198614,
                                51.30707607268744
                            ],
                            [
                                -0.08676353024515038,
                                51.308740079399115
                            ],
                            [
                                -0.08324248256860756,
                                51.31013021916135
                            ],
                            [
                                -0.08560107148761631,
                                51.313163098206985
                            ],
                            [
                                -0.08708361309305701,
                                51.314974305356
                            ],
                            [
                                -0.08541575378706057,
                                51.31589041197435
                            ],
                            [
                                -0.08403429456242861,
                                51.31661696628507
                            ],
                            [
                                -0.08479241250407199,
                                51.31720662579562
                            ],
                            [
                                -0.08769010746061667,
                                51.31911243546085
                            ],
                            [
                                -0.09046987369387693,
                                51.32101816888084
                            ],
                            [
                                -0.09308116856774973,
                                51.32249216025696
                            ],
                            [
                                -0.09613048709840655,
                                51.32377659983152
                            ],
                            [
                                -0.09961783693754,
                                51.325239984056026
                            ],
                            [
                                -0.10356004984421929,
                                51.32664014809657
                            ],
                            [
                                -0.1059017916992957,
                                51.327471804244624
                            ],
                            [
                                -0.10820983833247055,
                                51.32949297789594
                            ],
                            [
                                -0.10975976819366906,
                                51.33101932885714
                            ],
                            [
                                -0.11200042630397888,
                                51.33376662890174
                            ],
                            [
                                -0.1130786383805571,
                                51.33493497253383
                            ],
                            [
                                -0.11353350910127347,
                                51.33556649723573
                            ],
                            [
                                -0.11478019181438981,
                                51.33642956692586
                            ],
                            [
                                -0.11627958048521236,
                                51.337061071039244
                            ],
                            [
                                -0.11755995732625024,
                                51.33734524505212
                            ],
                            [
                                -0.11799798098161318,
                                51.33815565756123
                            ],
                            [
                                -0.11644805112177892,
                                51.33843982478865
                            ],
                            [
                                -0.11565623912790102,
                                51.337850438947555
                            ],
                            [
                                -0.11499920364278182,
                                51.337239995623406
                            ],
                            [
                                -0.1138872974383105,
                                51.336524293097455
                            ],
                            [
                                -0.11267432267092659,
                                51.33565070051233
                            ],
                            [
                                -0.11206782837754758,
                                51.33492444768871
                            ],
                            [
                                -0.11149502821069746,
                                51.33408240102716
                            ],
                            [
                                -0.1090522039748123,
                                51.33120880035446
                            ],
                            [
                                -0.10743488901476894,
                                51.329608767456875
                            ],
                            [
                                -0.10568279439053185,
                                51.328103423650475
                            ],
                            [
                                -0.10393069976484526,
                                51.32729283343963
                            ],
                            [
                                -0.10091504093750814,
                                51.32625062139877
                            ],
                            [
                                -0.09813527542712563,
                                51.32521891696081
                            ],
                            [
                                -0.09324962695396266,
                                51.32322913571406
                            ],
                            [
                                -0.09043616731565862,
                                51.32171305396372
                            ],
                            [
                                -0.08698251342497088,
                                51.31945989299953
                            ],
                            [
                                -0.08396688902226401,
                                51.31739615538601
                            ],
                            [
                                -0.08341093591934623,
                                51.31671173210523
                            ],
                            [
                                -0.0815240647849862,
                                51.316795969367604
                            ],
                            [
                                -0.07889592284857372,
                                51.31801739229235
                            ],
                            [
                                -0.07884536796896668,
                                51.319702069958225
                            ],
                            [
                                -0.07114625985755652,
                                51.32109187440818
                            ],
                            [
                                -0.07178644827874336,
                                51.32254480676352
                            ],
                            [
                                -0.07208969542401178,
                                51.324229308400504
                            ],
                            [
                                -0.07134842462198776,
                                51.325303145901955
                            ],
                            [
                                -0.0698658833556749,
                                51.325861107875824
                            ],
                            [
                                -0.07111256606881966,
                                51.32696648912096
                            ],
                            [
                                -0.07296574307670767,
                                51.32782972070734
                            ],
                            [
                                -0.07385863745278698,
                                51.328482397216476
                            ],
                            [
                                -0.0744819788100699,
                                51.32946139456246
                            ],
                            [
                                -0.07561073295059373,
                                51.33108249116546
                            ],
                            [
                                -0.07646993319877993,
                                51.332545633062296
                            ],
                            [
                                -0.07724489813008972,
                                51.3340192539645
                            ],
                            [
                                -0.07768291520869752,
                                51.33510339095233
                            ],
                            [
                                -0.0782894095020481,
                                51.33542967860268
                            ],
                            [
                                -0.07850842133044011,
                                51.33605067126294
                            ],
                            [
                                -0.07803670354581982,
                                51.33650325383482
                            ],
                            [
                                -0.07663839725861976,
                                51.33686110665829
                            ],
                            [
                                -0.07434719659539724,
                                51.33841878640516
                            ],
                            [
                                -0.0723423960148466,
                                51.3396185847169
                            ],
                            [
                                -0.06883820676497976,
                                51.34035528741711
                            ],
                            [
                                -0.06617637791353559,
                                51.3415866044968
                            ],
                            [
                                -0.06366616542331371,
                                51.34287050926713
                            ],
                            [
                                -0.06284065930159954,
                                51.343638730168664
                            ],
                            [
                                -0.06137495617883815,
                                51.34239694182048
                            ],
                            [
                                -0.05967340274492017,
                                51.34081834899641
                            ],
                            [
                                -0.05771914335485917,
                                51.339534386739984
                            ],
                            [
                                -0.05561326039210712,
                                51.338229339066714
                            ],
                            [
                                -0.05276610205791599,
                                51.33655587572525
                            ],
                            [
                                -0.051569960535175596,
                                51.33566123247164
                            ],
                            [
                                -0.0508960779874883,
                                51.33444027938893
                            ],
                            [
                                -0.050424360204260665,
                                51.33330350073666
                            ],
                            [
                                -0.049902101229207574,
                                51.33271404885738
                            ],
                            [
                                -0.04651581546119132,
                                51.333461389451486
                            ],
                            [
                                -0.04208503770735206,
                                51.33367190541864
                            ],
                            [
                                -0.04023186069943563,
                                51.33648220098732
                            ],
                            [
                                -0.037789036463578896,
                                51.33861875237665
                            ],
                            [
                                -0.03460490525165483,
                                51.33803990089305
                            ],
                            [
                                -0.03246532816243075,
                                51.337471559718665
                            ],
                            [
                                -0.030005656861220587,
                                51.33767153241669
                            ],
                            [
                                -0.025052620133720893,
                                51.33845036513708
                            ],
                            [
                                -0.021969607475256225,
                                51.33805042566334
                            ],
                            [
                                -0.020554454125544908,
                                51.336997936690295
                            ],
                            [
                                -0.01977948919420669,
                                51.33706108671012
                            ],
                            [
                                -0.022407631131983408,
                                51.338829251946265
                            ],
                            [
                                -0.024985229352068927,
                                51.34045001909658
                            ],
                            [
                                -0.029028524640949627,
                                51.3437439809523
                            ],
                            [
                                -0.032566408018766424,
                                51.346248496385385
                            ],
                            [
                                -0.03524508182718478,
                                51.34761644028703
                            ],
                            [
                                -0.036137976203207245,
                                51.34852137258076
                            ],
                            [
                                -0.036828705816247975,
                                51.34992082570665
                            ],
                            [
                                -0.03789007082892226,
                                51.35149910501471
                            ],
                            [
                                -0.03824385916595929,
                                51.35233031026149
                            ],
                            [
                                -0.037906917892797765,
                                51.353487659539184
                            ],
                            [
                                -0.03736781185386917,
                                51.354350391798704
                            ],
                            [
                                -0.036121129140695984,
                                51.35381381632757
                            ],
                            [
                                -0.0370477176432189,
                                51.353172021726465
                            ],
                            [
                                -0.037384658917773095,
                                51.352193530687686
                            ],
                            [
                                -0.036205364459021894,
                                51.35008917808946
                            ],
                            [
                                -0.0348576057915011,
                                51.348037339359024
                            ],
                            [
                                -0.03292019346534403,
                                51.347100829444884
                            ],
                            [
                                -0.03130287535117304,
                                51.34613273166286
                            ],
                            [
                                -0.028388328360478,
                                51.344070190637126
                            ],
                            [
                                -0.02547378633997255,
                                51.34155503219668
                            ],
                            [
                                -0.02338475364942383,
                                51.340155319772606
                            ],
                            [
                                -0.01993110558990452,
                                51.33776624467808
                            ],
                            [
                                -0.017286105922153183,
                                51.33572437685464
                            ],
                            [
                                -0.014556881602146632,
                                51.333440329217865
                            ],
                            [
                                -0.01324281063321564,
                                51.33165090365068
                            ],
                            [
                                -0.01066520988723596,
                                51.333682422607836
                            ],
                            [
                                -0.0057795462135459275,
                                51.333587689189585
                            ],
                            [
                                -0.00305032189493204,
                                51.334135027185084
                            ],
                            [
                                -0.005644769704872488,
                                51.33521915815493
                            ],
                            [
                                -0.00480241651919755,
                                51.33569279956089
                            ],
                            [
                                -0.0009444389322084135,
                                51.33601908301631
                            ],
                            [
                                -0.0004727211475881177,
                                51.33488234351148
                            ],
                            [
                                -0.004297010550772029,
                                51.33422975635514
                            ],
                            [
                                -0.0019889628235887358,
                                51.334524473498874
                            ],
                            [
                                -0.00043903296241865064,
                                51.33480866323592
                            ],
                            [
                                0.00038647315790285575,
                                51.33409292275195
                            ],
                            [
                                0.0024081208023289946,
                                51.33279824613746
                            ],
                            [
                                0.0036884976433668726,
                                51.3322719443793
                            ],
                            [
                                0.003452638752406756,
                                51.33045089368119
                            ],
                            [
                                0.0024755090581436434,
                                51.329577184724485
                            ],
                            [
                                0.0028629931913144446,
                                51.3286929297191
                            ],
                            [
                                0.004278146542418426,
                                51.324566192490494
                            ],
                            [
                                0.005474288062345067,
                                51.32138666731251
                            ],
                            [
                                0.005979699973465813,
                                51.318743913984065
                            ],
                            [
                                0.006601140629243218,
                                51.31688496363765
                            ],
                            [
                                0.006769611267230857,
                                51.31567404046817
                            ],
                            [
                                0.010374882899384374,
                                51.31612682417702
                            ],
                            [
                                0.00857224776174803,
                                51.31100905481023
                            ],
                            [
                                0.00951110313422987,
                                51.30720791667133
                            ],
                            [
                                0.012403076461794171,
                                51.29943060707575
                            ],
                            [
                                0.01398670044952155,
                                51.296007048522284
                            ],
                            [
                                0.02052336116608444,
                                51.29559620433827
                            ],
                            [
                                0.020371737592000727,
                                51.29683926008937
                            ],
                            [
                                0.021416255542135332,
                                51.2980296124382
                            ],
                            [
                                0.02294933833800883,
                                51.29931473696047
                            ],
                            [
                                0.023606373823156446,
                                51.30072622571382
                            ],
                            [
                                0.024364490667778682,
                                51.30203233979583
                            ],
                            [
                                0.02626820886604264,
                                51.30361226807565
                            ],
                            [
                                0.02734642094259243,
                                51.304549666480966
                            ],
                            [
                                0.028828962548118398,
                                51.305329061813495
                            ],
                            [
                                0.03029465709104784,
                                51.30576088325884
                            ],
                            [
                                0.030867457256505304,
                                51.306519194013504
                            ],
                            [
                                0.03167611631425871,
                                51.30683515312981
                            ],
                            [
                                0.0325184615438161,
                                51.307572377835385
                            ],
                            [
                                0.027481189496626257,
                                51.308952018323595
                            ],
                            [
                                0.02716109528600441,
                                51.3094469937318
                            ],
                            [
                                0.02818876617220667,
                                51.311900729147965
                            ],
                            [
                                0.028761566175262487,
                                51.31295379427169
                            ],
                            [
                                0.029149048640221054,
                                51.31344872651286
                            ],
                            [
                                0.029907166507626926,
                                51.3132802395406
                            ],
                            [
                                0.029233283959939627,
                                51.31281689717707
                            ],
                            [
                                0.02844147196611857,
                                51.31115303830174
                            ],
                            [
                                0.027784420750691652,
                                51.30927849053043
                            ],
                            [
                                0.031052751109712062,
                                51.30834118871297
                            ],
                            [
                                0.03317548113494695,
                                51.30763556684843
                            ],
                            [
                                0.0337482813018255,
                                51.30705631690981
                            ],
                            [
                                0.03512974052503637,
                                51.30648759167502
                            ],
                            [
                                0.038384507312684946,
                                51.304281150967114
                            ],
                            [
                                0.040894719804299484,
                                51.30274337722528
                            ],
                            [
                                0.041518061160161324,
                                51.30277497583435
                            ],
                            [
                                0.04254573204633516,
                                51.30132141730195
                            ],
                            [
                                0.042848987472723365,
                                51.29924993025227
                            ],
                            [
                                0.0441799055055867,
                                51.29851256708119
                            ],
                            [
                                0.04241097761101287,
                                51.29289768041576
                            ],
                            [
                                0.044331542873237595,
                                51.29334015385231
                            ],
                            [
                                0.04544346046463943,
                                51.294267232159655
                            ],
                            [
                                0.048459084867403135,
                                51.293951186521724
                            ],
                            [
                                0.05083452084892315,
                                51.29425669733993
                            ],
                            [
                                0.051912732925444516,
                                51.2936035338073
                            ],
                            [
                                0.05457456899114277,
                                51.29228664359394
                            ],
                            [
                                0.05868525253438861,
                                51.29366674358752
                            ],
                            [
                                0.06195357276737923,
                                51.29365620530925
                            ],
                            [
                                0.06547460908126368,
                                51.293456040660516
                            ],
                            [
                                0.0701412457259778,
                                51.294498994257026
                            ],
                            [
                                0.0762735602589828,
                                51.29526801929856
                            ],
                            [
                                0.0813613734965486,
                                51.29581581464288
                            ],
                            [
                                0.0832313975670047,
                                51.297058864447735
                            ],
                            [
                                0.08619646257486124,
                                51.29884963449288
                            ],
                            [
                                0.08532041526129319,
                                51.30130393655273
                            ],
                            [
                                0.08267544095014046,
                                51.304842964721956
                            ],
                            [
                                0.08092334632587495,
                                51.30764449534496
                            ],
                            [
                                0.08009783691608163,
                                51.30972973105713
                            ],
                            [
                                0.07760447148839944,
                                51.31102505898113
                            ],
                            [
                                0.07642517702964824,
                                51.312288758301236
                            ],
                            [
                                0.07546489439923221,
                                51.313963106291936
                            ],
                            [
                                0.07436982963440641,
                                51.31599542357711
                            ],
                            [
                                0.08053585494857884,
                                51.31765910684953
                            ],
                            [
                                0.08365256173354396,
                                51.3177854600755
                            ],
                            [
                                0.08547204461356728,
                                51.317311633682436
                            ],
                            [
                                0.08545519754957809,
                                51.31617443037001
                            ],
                            [
                                0.08778009592293756,
                                51.318469869144025
                            ],
                            [
                                0.09394612123713841,
                                51.320628317467566
                            ],
                            [
                                0.09911815974942328,
                                51.32362891441832
                            ],
                            [
                                0.10065124254671787,
                                51.32571342682914
                            ],
                            [
                                0.1027234313815768,
                                51.3260924189066
                            ],
                            [
                                0.10380164345812659,
                                51.32681881163393
                            ],
                            [
                                0.10496409320583666,
                                51.32777679047834
                            ],
                            [
                                0.10449237542260903,
                                51.33162955729634
                            ],
                            [
                                0.10504833949161707,
                                51.33413474720945
                            ],
                            [
                                0.10754170491929926,
                                51.33816590078308
                            ],
                            [
                                0.11018669391961566,
                                51.34094434856752
                            ],
                            [
                                0.11016984668395935,
                                51.34264922311095
                            ],
                            [
                                0.11015299962141967,
                                51.34439612617689
                            ],
                            [
                                0.11060787034071495,
                                51.345227460275765
                            ],
                            [
                                0.11616740136298631,
                                51.344859149560904
                            ],
                            [
                                0.12078350512931024,
                                51.344911767140985
                            ],
                            [
                                0.12578708304866382,
                                51.34690060755389
                            ],
                            [
                                0.1306727315218268,
                                51.34741621875639
                            ],
                            [
                                0.134665489354461,
                                51.34692165383086
                            ],
                            [
                                0.1363333486604006,
                                51.34756353598553
                            ],
                            [
                                0.1368050664450493,
                                51.34974165922657
                            ],
                            [
                                0.13806859622212642,
                                51.350299326306896
                            ],
                            [
                                0.13830445511445077,
                                51.35112003155248
                            ],
                            [
                                0.138894102343869,
                                51.351888114170265
                            ],
                            [
                                0.1384055371229067,
                                51.35269827089547
                            ],
                            [
                                0.13751264274679897,
                                51.35383456859415
                            ],
                            [
                                0.1352045950196441,
                                51.35477094088668
                            ],
                            [
                                0.13284600610063535,
                                51.35613864130218
                            ],
                            [
                                0.13276177078236628,
                                51.3572117315025
                            ],
                            [
                                0.13464864191672632,
                                51.35748526027811
                            ],
                            [
                                0.13715885440836928,
                                51.35720121113218
                            ],
                            [
                                0.1389446431605279,
                                51.35669623052425
                            ],
                            [
                                0.1404103377034005,
                                51.35593874917413
                            ],
                            [
                                0.14229720883778896,
                                51.355370629941916
                            ],
                            [
                                0.1430216325772733,
                                51.35494979633853
                            ],
                            [
                                0.1439482210797962,
                                51.35494979633853
                            ],
                            [
                                0.1457176829748903,
                                51.3576723915379
                            ],
                            [
                                0.1452796535989478,
                                51.36008146621816
                            ],
                            [
                                0.1449932535148264,
                                51.36289014263406
                            ],
                            [
                                0.14787977875749903,
                                51.36607724125051
                            ],
                            [
                                0.14604344881354336,
                                51.36752874816818
                            ],
                            [
                                0.14124203565867788,
                                51.37070507381071
                            ],
                            [
                                0.14090510213040375,
                                51.372356263471204
                            ],
                            [
                                0.13767046589930487,
                                51.37377602167453
                            ],
                            [
                                0.13812533661860016,
                                51.37474353537249
                            ],
                            [
                                0.1376199247074794,
                                51.376226315596625
                            ],
                            [
                                0.13618792429386417,
                                51.37740409261235
                            ],
                            [
                                0.13347554703778997,
                                51.37837152963496
                            ],
                            [
                                0.13147073791873254,
                                51.37884473714141
                            ],
                            [
                                0.12966810210264157,
                                51.37943359575246
                            ],
                            [
                                0.1277643839043776,
                                51.38053768522957
                            ],
                            [
                                0.12653454825377253,
                                51.38082158964767
                            ],
                            [
                                0.12722527786678484,
                                51.382062336438906
                            ],
                            [
                                0.12821925462509398,
                                51.38255652282797
                            ],
                            [
                                0.1289942195550111,
                                51.38393390779163
                            ],
                            [
                                0.13079685537107366,
                                51.384848636730794
                            ],
                            [
                                0.13081370704611572,
                                51.386446743640704
                            ],
                            [
                                0.13145389546738784,
                                51.387266801265326
                            ],
                            [
                                0.13293643707280012,
                                51.38690934205658
                            ],
                            [
                                0.13372824906667802,
                                51.38886482005401
                            ],
                            [
                                0.13416627272198411,
                                51.3908412397779
                            ],
                            [
                                0.13687864768647273,
                                51.39261784098542
                            ],
                            [
                                0.13891714239341013,
                                51.39339573980706
                            ],
                            [
                                0.14024806042624505,
                                51.39390031575431
                            ],
                            [
                                0.14267403759959052,
                                51.39404748269047
                            ],
                            [
                                0.14252241402553523,
                                51.39611828152289
                            ],
                            [
                                0.14346583871335383,
                                51.397369119418755
                            ],
                            [
                                0.14523478917465127,
                                51.40003887246206
                            ],
                            [
                                0.14887375493461263,
                                51.401951748988836
                            ],
                            [
                                0.1496487198645582,
                                51.40239317067221
                            ],
                            [
                                0.14515057594456948,
                                51.404820923265135
                            ],
                            [
                                0.14223603392409245,
                                51.40637629266766
                            ],
                            [
                                0.14090511589122912,
                                51.40809974832456
                            ],
                            [
                                0.14056817461670335,
                                51.40901399416285
                            ],
                            [
                                0.13116752374534713,
                                51.40924518237841
                            ],
                            [
                                0.11989683781771987,
                                51.4112732626906
                            ],
                            [
                                0.11432045973293725,
                                51.4122820146236
                            ],
                            [
                                0.1132422476549948,
                                51.412061352040126
                            ],
                            [
                                0.11219772970625286,
                                51.4121454141021
                            ],
                            [
                                0.11125429413840493,
                                51.41293348841799
                            ],
                            [
                                0.1117091648591213,
                                51.41374256391606
                            ],
                            [
                                0.11261890629910454,
                                51.4139737257143
                            ],
                            [
                                0.11592093078459698,
                                51.41374256391606
                            ],
                            [
                                0.11671274277838961,
                                51.412670798489046
                            ],
                            [
                                0.1262481808338407,
                                51.41075837031224
                            ],
                            [
                                0.13069579837301148,
                                51.40998076789958
                            ],
                            [
                                0.13512657612687917,
                                51.40972856930938
                            ],
                            [
                                0.14070295421160495,
                                51.40965501112501
                            ],
                            [
                                0.14080403659383478,
                                51.41040109579171
                            ],
                            [
                                0.1384959888666515,
                                51.41089497612796
                            ],
                            [
                                0.13815904759212572,
                                51.41140986696789
                            ],
                            [
                                0.13760309449065744,
                                51.41223998488769
                            ],
                            [
                                0.13536243501781087,
                                51.41321719299614
                            ],
                            [
                                0.13323970499251914,
                                51.41404727810044
                            ],
                            [
                                0.1315886927490908,
                                51.41443604695368
                            ],
                            [
                                0.12897739787518958,
                                51.41484582676077
                            ],
                            [
                                0.1275622445240856,
                                51.414835319632004
                            ],
                            [
                                0.12695575023073502,
                                51.414467568607705
                            ],
                            [
                                0.1258101498998201,
                                51.41466720524534
                            ],
                            [
                                0.1254900556892551,
                                51.41519256065163
                            ],
                            [
                                0.12653457363933285,
                                51.415570812805925
                            ],
                            [
                                0.12867415072855692,
                                51.415266109926876
                            ],
                            [
                                0.13111697496586316,
                                51.41494039081002
                            ],
                            [
                                0.13320601086462602,
                                51.41447807582105
                            ],
                            [
                                0.13512657612687917,
                                51.41379510194119
                            ],
                            [
                                0.138192753237945,
                                51.41255521478598
                            ],
                            [
                                0.14196649550663665,
                                51.41111564353477
                            ],
                            [
                                0.1477113442294069,
                                51.40925569239701
                            ],
                            [
                                0.14944659178974007,
                                51.41261826066602
                            ],
                            [
                                0.15076066275861422,
                                51.41421539396117
                            ],
                            [
                                0.15102930026804984,
                                51.417520613625555
                            ],
                            [
                                0.15404492467078512,
                                51.41770973046212
                            ],
                            [
                                0.15466826602809647,
                                51.418434671087255
                            ],
                            [
                                0.15142077147061173,
                                51.42038962443101
                            ],
                            [
                                0.15273484243948587,
                                51.42148222010351
                            ],
                            [
                                0.1538130486344187,
                                51.42745876171571
                            ],
                            [
                                0.15592032101505993,
                                51.43083222659905
                            ],
                            [
                                0.16427646461167456,
                                51.428521408575335
                            ],
                            [
                                0.16513566485986075,
                                51.43000244630309
                            ],
                            [
                                0.16444493524826953,
                                51.430643164059745
                            ],
                            [
                                0.16513566485986075,
                                51.43176702432356
                            ],
                            [
                                0.16564107677095308,
                                51.4320085981534
                            ],
                            [
                                0.16530413549781997,
                                51.43337399143968
                            ],
                            [
                                0.16574216920110985,
                                51.43466582836808
                            ],
                            [
                                0.16542207499188066,
                                51.43533798798805
                            ],
                            [
                                0.16382160394022094,
                                51.43640922194015
                            ],
                            [
                                0.16193473280449666,
                                51.436818804753074
                            ],
                            [
                                0.1600478616701082,
                                51.436577256358845
                            ],
                            [
                                0.15875063776519482,
                                51.43592611954219
                            ],
                            [
                                0.15747024130308773,
                                51.435789570107005
                            ],
                            [
                                0.155347511277796,
                                51.43677677685861
                            ],
                            [
                                0.15268567521221144,
                                51.4384045711829
                            ],
                            [
                                0.15137160424328044,
                                51.43722836432397
                            ],
                            [
                                0.14913094477188338,
                                51.4361256428856
                            ],
                            [
                                0.1487940034973576,
                                51.43556902100221
                            ],
                            [
                                0.147934803249143,
                                51.43501239233626
                            ],
                            [
                                0.14732830895582083,
                                51.43591559768876
                            ],
                            [
                                0.14918148596228775,
                                51.436808283105705
                            ],
                            [
                                0.15091674066349015,
                                51.43790048586615
                            ],
                            [
                                0.15243297639682396,
                                51.439076675417624
                            ],
                            [
                                0.15332587077290327,
                                51.43905567229851
                            ],
                            [
                                0.1565436650240315,
                                51.43695530138834
                            ],
                            [
                                0.15805990075742216,
                                51.43648270692066
                            ],
                            [
                                0.15984568950949551,
                                51.437238855721944
                            ],
                            [
                                0.16142931349716605,
                                51.43739638514671
                            ],
                            [
                                0.16388898479701197,
                                51.43704981969512
                            ],
                            [
                                0.16533783227600907,
                                51.43625165895679
                            ],
                            [
                                0.16683722094538211,
                                51.435106904079674
                            ],
                            [
                                0.1705267550788392,
                                51.440798906272306
                            ],
                            [
                                0.16774698956845668,
                                51.442489561937464
                            ],
                            [
                                0.16375423547140144,
                                51.443550127644926
                            ],
                            [
                                0.1606712228142726,
                                51.44442166320542
                            ],
                            [
                                0.15809362206829292,
                                51.44527218187264
                            ],
                            [
                                0.15624044506043333,
                                51.44614368456817
                            ],
                            [
                                0.1542187974160072,
                                51.445104179441074
                            ],
                            [
                                0.15292157351100855,
                                51.44479967345765
                            ],
                            [
                                0.15219714977291687,
                                51.4452301813229
                            ],
                            [
                                0.1524161615998878,
                                51.44592318545787
                            ],
                            [
                                0.15303950295719915,
                                51.44666867816403
                            ],
                            [
                                0.15317427946729367,
                                51.44733016150104
                            ],
                            [
                                0.15364599725049288,
                                51.44796013672257
                            ],
                            [
                                0.15463997400880203,
                                51.44780264373199
                            ],
                            [
                                0.15575188021330177,
                                51.44804413276188
                            ],
                            [
                                0.15637522157052786,
                                51.44862160135068
                            ],
                            [
                                0.1579251514303337,
                                51.44866359878145
                            ],
                            [
                                0.15748712777499918,
                                51.447918138644695
                            ],
                            [
                                0.1569143276080922,
                                51.44671067739111
                            ],
                            [
                                0.1583631750870893,
                                51.44586018551652
                            ],
                            [
                                0.16181682314521595,
                                51.444936176390996
                            ],
                            [
                                0.16710681137465144,
                                51.44346612065914
                            ],
                            [
                                0.1690610707647977,
                                51.44269957422529
                            ],
                            [
                                0.17108271840925227,
                                51.44149197497515
                            ],
                            [
                                0.17243048350459844,
                                51.44290958822651
                            ],
                            [
                                0.173744554473501,
                                51.443046096809184
                            ],
                            [
                                0.17608629632860584,
                                51.44213253159759
                            ],
                            [
                                0.17836064992789602,
                                51.44329811158269
                            ],
                            [
                                0.17935462668614832,
                                51.44324560861969
                            ],
                            [
                                0.1805170607627815,
                                51.4435921261323
                            ],
                            [
                                0.18060129608102216,
                                51.44465266623473
                            ],
                            [
                                0.1798768723415094,
                                51.445366679361086
                            ],
                            [
                                0.18004534297810437,
                                51.44682617145807
                            ],
                            [
                                0.18034859012479387,
                                51.44760314822301
                            ],
                            [
                                0.18146049693729083,
                                51.449776513542446
                            ],
                            [
                                0.18167950876565442,
                                51.45041695396253
                            ],
                            [
                                0.18233654425083046,
                                51.450406455011546
                            ],
                            [
                                0.1828756502883948,
                                51.450206974486576
                            ],
                            [
                                0.1909790879287243,
                                51.448947077352614
                            ],
                            [
                                0.1916529704778327,
                                51.45070042472281
                            ],
                            [
                                0.19278172978056318,
                                51.451351354304904
                            ],
                            [
                                0.19771791944413053,
                                51.45184479217227
                            ],
                            [
                                0.20026182606358134,
                                51.45268467415016
                            ],
                            [
                                0.20327745046637347,
                                51.454301403457464
                            ],
                            [
                                0.20391763888616765,
                                51.45586558705597
                            ],
                            [
                                0.2014074263945531,
                                51.45596006622796
                            ],
                            [
                                0.19852657850191235,
                                51.45584459165801
                            ],
                            [
                                0.19685871919594433,
                                51.45599155924182
                            ],
                            [
                                0.19593213069200033,
                                51.45600205690843
                            ],
                            [
                                0.19529194227075664,
                                51.45648494695578
                            ],
                            [
                                0.19599951894633705,
                                51.45683136493
                            ],
                            [
                                0.19741467229744103,
                                51.456474449400645
                            ],
                            [
                                0.1997901082803537,
                                51.45639046887018
                            ],
                            [
                                0.20081777916513488,
                                51.456589922377276
                            ],
                            [
                                0.20258672085475382,
                                51.45646395184269
                            ],
                            [
                                0.20329429469478555,
                                51.45750319620828
                            ],
                            [
                                0.20290681222837748,
                                51.458258996992896
                            ],
                            [
                                0.20415349494297175,
                                51.45956062456261
                            ],
                            [
                                0.20738813117412747,
                                51.461376542942446
                            ],
                            [
                                0.21065646153166995,
                                51.46247865470181
                            ],
                            [
                                0.21052168617956113,
                                51.464231482489055
                            ],
                            [
                                0.2108249333262222,
                                51.466446036767366
                            ],
                            [
                                0.21144827468208405,
                                51.46870246256796
                            ],
                            [
                                0.20890436954780967,
                                51.47104862386024
                            ],
                            [
                                0.21154935854951873,
                                51.472948035344956
                            ],
                            [
                                0.2118189115682867,
                                51.475330058409156
                            ],
                            [
                                0.21510408899126787,
                                51.47637936831026
                            ],
                            [
                                0.21672142451541276,
                                51.47730274824531
                            ],
                            [
                                0.21694043634377635,
                                51.47998881722245
                            ],
                            [
                                0.21998975487437633,
                                51.47996783292177
                            ],
                            [
                                0.22366241476086657,
                                51.482223589989076
                            ],
                            [
                                0.21776593004261713,
                                51.484888403398685
                            ],
                            [
                                0.21037006907786804,
                                51.48587454868951
                            ],
                            [
                                0.2105048500693556,
                                51.490123126092044
                            ],
                            [
                                0.21245910945947344,
                                51.49271403712086
                            ],
                            [
                                0.21473346893969847,
                                51.496248771927554
                            ],
                            [
                                0.22083210599953418,
                                51.49760175634077
                            ],
                            [
                                0.22546504851641203,
                                51.497549315832686
                            ],
                            [
                                0.22856490823883746,
                                51.499091041584904
                            ],
                            [
                                0.22103427076396542,
                                51.49978322801985
                            ],
                            [
                                0.2141101088132018,
                                51.501387812855825
                            ],
                            [
                                0.20868535430102497,
                                51.50363202361291
                            ],
                            [
                                0.20295734457630488,
                                51.506746477452594
                            ],
                            [
                                0.19695978989872742,
                                51.50951469503332
                            ],
                            [
                                0.19261321057541636,
                                51.51008091345133
                            ],
                            [
                                0.18658196176991737,
                                51.51081487320502
                            ],
                            [
                                0.183263090220521,
                                51.51083584330982
                            ],
                            [
                                0.1826397488632665,
                                51.51171657900102
                            ],
                            [
                                0.18422337285238655,
                                51.51312152688138
                            ],
                            [
                                0.19342187534829236,
                                51.51119233344815
                            ],
                            [
                                0.1988466298603555,
                                51.51018576539943
                            ],
                            [
                                0.2049621165504334,
                                51.50711349699381
                            ],
                            [
                                0.21304870705378676,
                                51.50305527097703
                            ],
                            [
                                0.22056249746469803,
                                51.5010627276919
                            ],
                            [
                                0.2260377931686719,
                                51.500800544458315
                            ],
                            [
                                0.22647581682403484,
                                51.502027549003884
                            ],
                            [
                                0.22477426918052856,
                                51.506557743711085
                            ],
                            [
                                0.22251676507295315,
                                51.51076245660485
                            ],
                            [
                                0.22041088211022952,
                                51.512387612993706
                            ],
                            [
                                0.2205216601621771,
                                51.51403740929928
                            ],
                            [
                                0.22067328373481132,
                                51.515253573394716
                            ],
                            [
                                0.22390791996593862,
                                51.51831480824106
                            ],
                            [
                                0.2252893791891779,
                                51.51905911849539
                            ],
                            [
                                0.22559261987976242,
                                51.52091459670481
                            ],
                            [
                                0.22522198447731512,
                                51.52488737817927
                            ],
                            [
                                0.22815337356183818,
                                51.525275203711175
                            ],
                            [
                                0.23204505849233215,
                                51.52524376744881
                            ],
                            [
                                0.24255762624204635,
                                51.524772086987525
                            ],
                            [
                                0.2441075765232199,
                                51.52604037880914
                            ],
                            [
                                0.2480160953020345,
                                51.52887030945931
                            ],
                            [
                                0.24986927230989409,
                                51.52899608007243
                            ],
                            [
                                0.25138550804186366,
                                51.52983454194725
                            ],
                            [
                                0.26019654261060055,
                                51.53107125016214
                            ],
                            [
                                0.2605840250755591,
                                51.532297439537984
                            ],
                            [
                                0.2675250153196487,
                                51.533596954620265
                            ],
                            [
                                0.2726970660976349,
                                51.5339113483912
                            ],
                            [
                                0.2789473267315259,
                                51.534110462984955
                            ],
                            [
                                0.28192925962684967,
                                51.54419081169709
                            ],
                            [
                                0.28286372642841684,
                                51.54795492159744
                            ],
                            [
                                0.28707547220841434,
                                51.553632819674846
                            ],
                            [
                                0.2892824375533678,
                                51.557592271954576
                            ],
                            [
                                0.2892263689631136,
                                51.562284681527586
                            ],
                            [
                                0.2862949830567061,
                                51.56896609768933
                            ],
                            [
                                0.2847282061315468,
                                51.57102896908245
                            ],
                            [
                                0.27638889083797835,
                                51.5727881037912
                            ],
                            [
                                0.27133477172827725,
                                51.57506020748008
                            ],
                            [
                                0.2651013354648626,
                                51.576934357123605
                            ],
                            [
                                0.25881738070449956,
                                51.57758348197885
                            ],
                            [
                                0.2561723917028189,
                                51.57665167082098
                            ],
                            [
                                0.25410020286796,
                                51.57788710171252
                            ],
                            [
                                0.25036015472571194,
                                51.578211658495036
                            ],
                            [
                                0.2505286253622785,
                                51.57904921367805
                            ],
                            [
                                0.2555827444734007,
                                51.579572677828736
                            ],
                            [
                                0.2566104153595745,
                                51.57859902968579
                            ],
                            [
                                0.26370302917769095,
                                51.57828494518992
                            ],
                            [
                                0.2682854276315254,
                                51.57741596715829
                            ],
                            [
                                0.2727162053854215,
                                51.57556278901677
                            ],
                            [
                                0.27947187793040484,
                                51.573395417028195
                            ],
                            [
                                0.2832119260712318,
                                51.573542005831655
                            ],
                            [
                                0.2786295401664063,
                                51.58045209184755
                            ],
                            [
                                0.27175593486859384,
                                51.586314389020345
                            ],
                            [
                                0.2698016754799255,
                                51.58942318051649
                            ],
                            [
                                0.26326501476330577,
                                51.58910917083193
                            ],
                            [
                                0.2577054613188068,
                                51.590155871548745
                            ],
                            [
                                0.25369586015776235,
                                51.590208205440945
                            ],
                            [
                                0.2553637194637304,
                                51.59477148901138
                            ],
                            [
                                0.25822771294312474,
                                51.598413410300765
                            ],
                            [
                                0.2605694547717974,
                                51.60045401641821
                            ],
                            [
                                0.25809295394950027,
                                51.601134205781335
                            ],
                            [
                                0.25408335278848426,
                                51.60172020027244
                            ],
                            [
                                0.2567283348803926,
                                51.604022246738
                            ],
                            [
                                0.25812664116759265,
                                51.60631371497743
                            ],
                            [
                                0.25094979202975765,
                                51.604074564654354
                            ],
                            [
                                0.2447837664082897,
                                51.60206549208641
                            ],
                            [
                                0.24422781330682142,
                                51.6026619384638
                            ],
                            [
                                0.24501962530064247,
                                51.60293399912166
                            ],
                            [
                                0.24596305888792358,
                                51.60303863731036
                            ],
                            [
                                0.24921454218301164,
                                51.604168715375295
                            ],
                            [
                                0.2569641655739474,
                                51.606732213640754
                            ],
                            [
                                0.2607716019705322,
                                51.60848995770198
                            ],
                            [
                                0.2563745276027305,
                                51.613124637254856
                            ],
                            [
                                0.2518595143252753,
                                51.61698477557789
                            ],
                            [
                                0.24173438429784255,
                                51.62129436330693
                            ],
                            [
                                0.23285598172617483,
                                51.62557214154771
                            ],
                            [
                                0.2296718916720124,
                                51.621963771174734
                            ],
                            [
                                0.2261677024220603,
                                51.619746312697
                            ],
                            [
                                0.22138311274457578,
                                51.617727477011954
                            ],
                            [
                                0.2183001000875322,
                                51.615886403866966
                            ],
                            [
                                0.2152339344943357,
                                51.61406617859083
                            ],
                            [
                                0.2113759569059539,
                                51.61247603700255
                            ],
                            [
                                0.20991023262484987,
                                51.610760275176034
                            ],
                            [
                                0.2088657146761932,
                                51.610143010826135
                            ],
                            [
                                0.2070293847322091,
                                51.60930602880978
                            ],
                            [
                                0.20682721996774944,
                                51.61058242020732
                            ],
                            [
                                0.20637238737128882,
                                51.61240280404169
                            ],
                            [
                                0.2024470215285703,
                                51.615583036642875
                            ],
                            [
                                0.1996672560181878,
                                51.61658727432928
                            ],
                            [
                                0.1943098897603761,
                                51.616336216990874
                            ],
                            [
                                0.19281050109094622,
                                51.61606423664003
                            ],
                            [
                                0.19105840646528804,
                                51.615583036642875
                            ],
                            [
                                0.18947473789802416,
                                51.61558303545078
                            ],
                            [
                                0.18790796097425755,
                                51.616095617879296
                            ],
                            [
                                0.18672866651405684,
                                51.61614792188311
                            ],
                            [
                                0.18445431291476666,
                                51.61581317521626
                            ],
                            [
                                0.18330871258245907,
                                51.615572574524435
                            ],
                            [
                                0.1831402573137666,
                                51.61632575793624
                            ],
                            [
                                0.18276962191271195,
                                51.61721491329766
                            ],
                            [
                                0.18255061008431994,
                                51.61784254188808
                            ],
                            [
                                0.18211258642756434,
                                51.61874212773114
                            ],
                            [
                                0.18199465698140216,
                                51.61939065530035
                            ],
                            [
                                0.1837299045431564,
                                51.62114790923002
                            ],
                            [
                                0.18460595185524653,
                                51.62175456442833
                            ],
                            [
                                0.18642543473526985,
                                51.62275866555473
                            ],
                            [
                                0.1883122818869083,
                                51.62380457070219
                            ],
                            [
                                0.18920517626301603,
                                51.624463484802845
                            ],
                            [
                                0.18834597601338032,
                                51.62538385673511
                            ],
                            [
                                0.18602105504729138,
                                51.62551982684124
                            ],
                            [
                                0.18632430219395246,
                                51.62457853996912
                            ],
                            [
                                0.1859368197289939,
                                51.62432752683847
                            ],
                            [
                                0.18244947754303098,
                                51.62574991621722
                            ],
                            [
                                0.1804952181528563,
                                51.62550936820534
                            ],
                            [
                                0.18034359458027893,
                                51.62444257469559
                            ],
                            [
                                0.17483460474986146,
                                51.62338621523051
                            ],
                            [
                                0.16895497289760897,
                                51.62143031627812
                            ],
                            [
                                0.16309219349534487,
                                51.62273775460497
                            ],
                            [
                                0.15655553277872514,
                                51.623271174537024
                            ],
                            [
                                0.15028841287545447,
                                51.62321888258981
                            ],
                            [
                                0.14095510773913134,
                                51.623448993860734
                            ],
                            [
                                0.13603576513810367,
                                51.62358496211169
                            ],
                            [
                                0.12921271077146912,
                                51.621461719577354
                            ],
                            [
                                0.1289431577526443,
                                51.62072954377274
                            ],
                            [
                                0.12596122747638105,
                                51.61957895792574
                            ],
                            [
                                0.12471456983024609,
                                51.61791577161438
                            ],
                            [
                                0.12299616933250945,
                                51.61693248455629
                            ],
                            [
                                0.11935720357385549,
                                51.615300599444964
                            ],
                            [
                                0.12107560407167739,
                                51.614139414694506
                            ],
                            [
                                0.12634873143275627,
                                51.61113693551329
                            ],
                            [
                                0.1270900022362298,
                                51.61054060046405
                            ],
                            [
                                0.12951597940957527,
                                51.60852137274156
                            ],
                            [
                                0.12181687129810825,
                                51.60507906247585
                            ],
                            [
                                0.11947512944306027,
                                51.6071089061833
                            ],
                            [
                                0.1263655784966886,
                                51.61016396376499
                            ],
                            [
                                0.12575908420336646,
                                51.610749841724186
                            ],
                            [
                                0.12444504557308278,
                                51.611461267850956
                            ],
                            [
                                0.12178320950883403,
                                51.61284221433726
                            ],
                            [
                                0.12019958552113508,
                                51.61387789663138
                            ],
                            [
                                0.11858226740557143,
                                51.615164620502725
                            ],
                            [
                                0.11639216632715943,
                                51.61679654150808
                            ],
                            [
                                0.10958595259180015,
                                51.61395115680466
                            ],
                            [
                                0.10380738455432947,
                                51.612591160415974
                            ],
                            [
                                0.10306611375085595,
                                51.61334439041258
                            ],
                            [
                                0.1002358070486764,
                                51.61276900751446
                            ],
                            [
                                0.09826470059601888,
                                51.615175105654885
                            ],
                            [
                                0.09720333558340144,
                                51.61471481848568
                            ],
                            [
                                0.09599034699670028,
                                51.61457882365585
                            ],
                            [
                                0.09246933593152562,
                                51.61342808776294
                            ],
                            [
                                0.09380025396293945,
                                51.61223547002851
                            ],
                            [
                                0.09482792863062173,
                                51.61079173309727
                            ],
                            [
                                0.09422143433732799,
                                51.61066618857268
                            ],
                            [
                                0.093833951872341,
                                51.609787367178654
                            ],
                            [
                                0.09265465741356138,
                                51.609452573601146
                            ],
                            [
                                0.09194712234457825,
                                51.6082703137354
                            ],
                            [
                                0.09167756932581028,
                                51.607464685590685
                            ],
                            [
                                0.09255361663792883,
                                51.606198669621904
                            ],
                            [
                                0.09031295716650334,
                                51.60560226970168
                            ],
                            [
                                0.08893154453627972,
                                51.60516281515086
                            ],
                            [
                                0.08893154453627972,
                                51.604827987468354
                            ],
                            [
                                0.0870952145923809,
                                51.60441991289258
                            ],
                            [
                                0.0855621317950579,
                                51.605549956580205
                            ],
                            [
                                0.08465239035507466,
                                51.60628237728179
                            ],
                            [
                                0.08364156653289001,
                                51.606418396968024
                            ],
                            [
                                0.08308561730271435,
                                51.60749607331118
                            ],
                            [
                                0.08466927803397084,
                                51.60364558980291
                            ],
                            [
                                0.08455134858780866,
                                51.60254689051706
                            ],
                            [
                                0.08482090160660505,
                                51.60088309527424
                            ],
                            [
                                0.08527577824997934,
                                51.598716930609584
                            ],
                            [
                                0.08490514284758888,
                                51.59678089914556
                            ],
                            [
                                0.08465243689275326,
                                51.59489711364034
                            ],
                            [
                                0.08500622522981871,
                                51.59467733357525
                            ],
                            [
                                0.0859665078616274,
                                51.59410171407936
                            ],
                            [
                                0.08594966079772348,
                                51.59339002898068
                            ],
                            [
                                0.08493883697551041,
                                51.59273066371824
                            ],
                            [
                                0.08418071910946878,
                                51.5931702416232
                            ],
                            [
                                0.08433234268213141,
                                51.59368307713646
                            ],
                            [
                                0.08455135451052342,
                                51.59420637271185
                            ],
                            [
                                0.08372584838878083,
                                51.59484478515
                            ],
                            [
                                0.08273187163047169,
                                51.59568203376287
                            ],
                            [
                                0.08074391811391024,
                                51.59740881028492
                            ],
                            [
                                0.0782337212757227,
                                51.59933436338031
                            ],
                            [
                                0.07764407404633289,
                                51.599732019084314
                            ],
                            [
                                0.07676802673421435,
                                51.60046453363441
                            ],
                            [
                                0.07555503814757003,
                                51.60206556001492
                            ],
                            [
                                0.07427466130653215,
                                51.60328983621818
                            ],
                            [
                                0.07264051525476134,
                                51.60470244108507
                            ],
                            [
                                0.06935533783317283,
                                51.605591824143545
                            ],
                            [
                                0.06689566653196266,
                                51.606177761109905
                            ],
                            [
                                0.06460446586871171,
                                51.60693109752617
                            ],
                            [
                                0.06313877045920435,
                                51.60686832652533
                            ],
                            [
                                0.05946611057132145,
                                51.60662767841842
                            ],
                            [
                                0.056720039188746796,
                                51.60633471378364
                            ],
                            [
                                0.05547335647420937,
                                51.60613591527627
                            ],
                            [
                                0.055304885837642814,
                                51.60666953035445
                            ],
                            [
                                0.05611354489545306,
                                51.60721360201623
                            ],
                            [
                                0.05729283935423268,
                                51.60805062261025
                            ],
                            [
                                0.05842161564314097,
                                51.60920151147076
                            ],
                            [
                                0.05274415517618536,
                                51.61183795863718
                            ],
                            [
                                0.05021709562061005,
                                51.61071853350532
                            ],
                            [
                                0.048835636397399185,
                                51.6090655001222
                            ],
                            [
                                0.048700864026614,
                                51.60768443538001
                            ],
                            [
                                0.049627452529108496,
                                51.60557091162141
                            ],
                            [
                                0.04991385261183723,
                                51.6045768936585
                            ],
                            [
                                0.04891988756142496,
                                51.601992335310655
                            ],
                            [
                                0.0481954638219122,
                                51.59957507378127
                            ],
                            [
                                0.04688139285303805,
                                51.598162328898724
                            ],
                            [
                                0.042922339991690706,
                                51.59584950003173
                            ],
                            [
                                0.04049636281834523,
                                51.59401800539854
                            ],
                            [
                                0.039451844869660135,
                                51.592510891569844
                            ],
                            [
                                0.0394855467566515,
                                51.59086766024794
                            ],
                            [
                                0.040597452961094405,
                                51.588062519349734
                            ],
                            [
                                0.04073100197766166,
                                51.58544485640485
                            ],
                            [
                                0.040848931423852264,
                                51.58204267132177
                            ],
                            [
                                0.042247230547275194,
                                51.58000123986017
                            ],
                            [
                                0.044336266446066475,
                                51.57706979059961
                            ],
                            [
                                0.04559979622453625,
                                51.57639971850517
                            ],
                            [
                                0.0456334903510367,
                                51.57511189596062
                            ],
                            [
                                0.0445384312104693,
                                51.57477684671568
                            ],
                            [
                                0.04255047769390785,
                                51.57539459184008
                            ],
                            [
                                0.0421966893568424,
                                51.57619031894936
                            ],
                            [
                                0.042752642458339096,
                                51.57680804486367
                            ],
                            [
                                0.042061912846776295,
                                51.57773985281554
                            ],
                            [
                                0.03940007762915343,
                                51.58216830139051
                            ],
                            [
                                0.03918106580076142,
                                51.58357107545547
                            ],
                            [
                                0.03914737167431781,
                                51.584858658307354
                            ],
                            [
                                0.03864196566095757,
                                51.58703596031205
                            ],
                            [
                                0.036165447297264564,
                                51.58882586812052
                            ],
                            [
                                0.03598012959531616,
                                51.59037497116634
                            ],
                            [
                                0.03678878865306956,
                                51.59184029029515
                            ],
                            [
                                0.035087235219236845,
                                51.593535814866925
                            ],
                            [
                                0.03271180551885777,
                                51.59449868457176
                            ],
                            [
                                0.029932040007082605,
                                51.595304542780696
                            ],
                            [
                                0.027051192114413425,
                                51.59590107795978
                            ],
                            [
                                0.024844226769459965,
                                51.59605805959944
                            ],
                            [
                                0.021761173902916653,
                                51.59555571262766
                            ],
                            [
                                0.01886347894637197,
                                51.59511615780767
                            ],
                            [
                                0.01599947811624247,
                                51.59488591310935
                            ],
                            [
                                0.013691430389059178,
                                51.59485451601461
                            ],
                            [
                                0.012107806401360222,
                                51.5949801042635
                            ],
                            [
                                0.011248606153174023,
                                51.594331227906395
                            ],
                            [
                                0.009968229310800325,
                                51.5946347357434
                            ],
                            [
                                0.00961444097370645,
                                51.59518941723991
                            ],
                            [
                                0.009462817399622736,
                                51.596037124930746
                            ],
                            [
                                0.010979021531682065,
                                51.59695807919604
                            ],
                            [
                                0.012023539480395584,
                                51.59686389214383
                            ],
                            [
                                0.012579492583370211,
                                51.5958278216782
                            ],
                            [
                                0.014466363717644981,
                                51.59564990821005
                            ],
                            [
                                0.01773469407530115,
                                51.59562897716819
                            ],
                            [
                                0.020295447758712726,
                                51.59601619988456
                            ],
                            [
                                0.023209989779189755,
                                51.59661272571631
                            ],
                            [
                                0.02577074346126551,
                                51.59675923963479
                            ],
                            [
                                0.028078791188448804,
                                51.596591795118144
                            ],
                            [
                                0.03133026380561432,
                                51.596037132963346
                            ],
                            [
                                0.0330992054938406,
                                51.59551385848013
                            ],
                            [
                                0.03544094734891701,
                                51.594697538248
                            ],
                            [
                                0.036755018317791155,
                                51.59446729142715
                            ],
                            [
                                0.03843972468763468,
                                51.59476033263289
                            ],
                            [
                                0.03931577199981007,
                                51.595796427449955
                            ],
                            [
                                0.04057930177688718,
                                51.597031337872664
                            ],
                            [
                                0.04308951426853014,
                                51.598580161105616
                            ],
                            [
                                0.046138832799158536,
                                51.60055797343952
                            ],
                            [
                                0.04778980102142327,
                                51.602169445645444
                            ],
                            [
                                0.048716389525367276,
                                51.604314518318745
                            ],
                            [
                                0.04799196578585452,
                                51.60664782376443
                            ],
                            [
                                0.047419157122533306,
                                51.608614817954646
                            ],
                            [
                                0.04839628681827435,
                                51.61034110273934
                            ],
                            [
                                0.049895675487647395,
                                51.611554693956975
                            ],
                            [
                                0.05174885249411432,
                                51.61228701778802
                            ],
                            [
                                0.048463687545705625,
                                51.613521490606416
                            ],
                            [
                                0.04849738167359874,
                                51.615038368275634
                            ],
                            [
                                0.0499293820872424,
                                51.61627275544865
                            ],
                            [
                                0.051917335605253356,
                                51.61686901519357
                            ],
                            [
                                0.05316401831842654,
                                51.61626229468172
                            ],
                            [
                                0.05414114801271808,
                                51.616607498726296
                            ],
                            [
                                0.0543938539690032,
                                51.61749664856757
                            ],
                            [
                                0.05355150078335669,
                                51.618323019271855
                            ],
                            [
                                0.04677898117583368,
                                51.61739204360785
                            ],
                            [
                                0.04671159292149696,
                                51.61671210549389
                            ],
                            [
                                0.04578500441752453,
                                51.615875244600176
                            ],
                            [
                                0.04221342192997213,
                                51.61501741584635
                            ],
                            [
                                0.041590080572717625,
                                51.615216175462166
                            ],
                            [
                                0.04089935096109798,
                                51.61518479242275
                            ],
                            [
                                0.03805219719490083,
                                51.61686898487119
                            ],
                            [
                                0.037698408857835375,
                                51.61662838977648
                            ],
                            [
                                0.03643487908072984,
                                51.61751753920848
                            ],
                            [
                                0.03756363234771243,
                                51.61818700493234
                            ],
                            [
                                0.036182213053933765,
                                51.61944224703589
                            ],
                            [
                                0.03197044712848651,
                                51.622810253461665
                            ],
                            [
                                0.03067322322351629,
                                51.62301943816175
                            ],
                            [
                                0.028061950680807968,
                                51.62037314933343
                            ],
                            [
                                0.027034279796055216,
                                51.61823929570576
                            ],
                            [
                                0.02545065580835626,
                                51.61866816650098
                            ],
                            [
                                0.02432190253998101,
                                51.619515435915616
                            ],
                            [
                                0.023530090546131532,
                                51.62032085019905
                            ],
                            [
                                0.023193149271605762,
                                51.62093797614588
                            ],
                            [
                                0.022738278552282054,
                                51.62207806810841
                            ],
                            [
                                0.021946495486247386,
                                51.62420128858537
                            ],
                            [
                                0.0213063070664532,
                                51.6251739594376
                            ],
                            [
                                0.020716659835642304,
                                51.625717809319355
                            ],
                            [
                                0.023563813601811034,
                                51.627014655807756
                            ],
                            [
                                0.024204002023026305,
                                51.627683981426685
                            ],
                            [
                                0.021828566040085207,
                                51.62882390389109
                            ],
                            [
                                0.023799654625918265,
                                51.63072718879829
                            ],
                            [
                                0.02497894908472631,
                                51.63474266575605
                            ],
                            [
                                0.021744312855020098,
                                51.63411527091364
                            ],
                            [
                                0.019267794489820744,
                                51.63408390094369
                            ],
                            [
                                0.01738092335548913,
                                51.63389568066847
                            ],
                            [
                                0.016016320865247735,
                                51.63365517835226
                            ],
                            [
                                0.01450008513191392,
                                51.63394796666216
                            ],
                            [
                                0.011855096131569098,
                                51.633968880040385
                            ],
                            [
                                0.011063284136326956,
                                51.633822486189416
                            ],
                            [
                                0.009833448487114538,
                                51.6333310176621
                            ],
                            [
                                0.0074074925163358785,
                                51.632306243630865
                            ],
                            [
                                0.0030104088903613047,
                                51.631009548407064
                            ],
                            [
                                0.0018648085580252882,
                                51.630695826251696
                            ],
                            [
                                0.0008708317997445647,
                                51.630172951171375
                            ],
                            [
                                -0.0006454039335892503,
                                51.63119778065882
                            ],
                            [
                                -0.0014203688635063827,
                                51.631469670310054
                            ],
                            [
                                -0.003913757196670531,
                                51.63171021075587
                            ],
                            [
                                -0.005969098969018205,
                                51.63212849712701
                            ],
                            [
                                -0.008108676059634945,
                                51.632755919451256
                            ],
                            [
                                -0.00967542111479247,
                                51.63387482202609
                            ],
                            [
                                -0.011730762885662216,
                                51.63528645570091
                            ],
                            [
                                -0.01326384568298522,
                                51.635924290579595
                            ],
                            [
                                -0.01225302186077215,
                                51.63876829758706
                            ],
                            [
                                -0.011966627202895097,
                                51.64004385774484
                            ],
                            [
                                -0.0122867214134601,
                                51.64174803736785
                            ],
                            [
                                -0.01176446243843543,
                                51.64312806227639
                            ],
                            [
                                -0.011629685928340905,
                                51.64451849943913
                            ],
                            [
                                -0.01098950399634191,
                                51.646389768841715
                            ],
                            [
                                -0.0121856455190823,
                                51.646483853150926
                            ],
                            [
                                -0.011730774799787014,
                                51.64925402585567
                            ],
                            [
                                -0.011713931705799041,
                                51.65319469392011
                            ],
                            [
                                -0.008900472067495002,
                                51.66010310713804
                            ],
                            [
                                -0.009220559831646824,
                                51.66128401882216
                            ],
                            [
                                -0.01141067811269636,
                                51.66340539798199
                            ],
                            [
                                -0.011326442794398872,
                                51.66741798151227
                            ],
                            [
                                -0.010854725011142818,
                                51.66838972562957
                            ],
                            [
                                -0.010517778346098794,
                                51.66929876007467
                            ],
                            [
                                -0.011410672722149684,
                                51.67077198022099
                            ],
                            [
                                -0.010281917727610335,
                                51.67657036127363
                            ],
                            [
                                -0.011107423847931841,
                                51.68023706153477
                            ],
                            [
                                -0.01472958955383774,
                                51.680351963547224
                            ],
                            [
                                -0.021855897499818866,
                                51.680832476540644
                            ],
                            [
                                -0.02724695788401732,
                                51.68173081324136
                            ],
                            [
                                -0.033194026309786295,
                                51.68197106915463
                            ],
                            [
                                -0.03368259115694627,
                                51.680195279520916
                            ],
                            [
                                -0.03317717924585395,
                                51.67991323593495
                            ],
                            [
                                -0.03366574409301393,
                                51.67943271318717
                            ],
                            [
                                -0.03432277957674046,
                                51.679526728908684
                            ],
                            [
                                -0.03444070902290264,
                                51.679766990421115
                            ],
                            [
                                -0.03523252101678054,
                                51.680007250658804
                            ],
                            [
                                -0.03838292192961035,
                                51.68059222677721
                            ],
                            [
                                -0.04020240480963366,
                                51.68090560373142
                            ],
                            [
                                -0.04155016990495142,
                                51.68096827886211
                            ],
                            [
                                -0.043218029212312103,
                                51.68088471200204
                            ],
                            [
                                -0.04495327677264527,
                                51.68072802372356
                            ],
                            [
                                -0.04628419480548018,
                                51.68049821326787
                            ],
                            [
                                -0.048120524749435845,
                                51.68009081913863
                            ],
                            [
                                -0.04988950196874953,
                                51.67982965704073
                            ],
                            [
                                -0.050681313962599006,
                                51.67957894969339
                            ],
                            [
                                -0.051877455485310975,
                                51.679725195814996
                            ],
                            [
                                -0.05290512637148481,
                                51.67924467107133
                            ],
                            [
                                -0.05489307988804626,
                                51.67891038998161
                            ],
                            [
                                -0.05570173894579966,
                                51.67856566002314
                            ],
                            [
                                -0.05704950404120268,
                                51.67822092744032
                            ],
                            [
                                -0.05927331645008849,
                                51.67823137392094
                            ],
                            [
                                -0.05913853993996554,
                                51.679641626660356
                            ],
                            [
                                -0.061193881712313214,
                                51.679881887563624
                            ],
                            [
                                -0.06068846980119247,
                                51.68148011242883
                            ],
                            [
                                -0.05979557542514158,
                                51.682169525347604
                            ],
                            [
                                -0.06390625896841584,
                                51.68247244588625
                            ]
                        ],
                        [
                            [
                                -0.24772028117490663,
                                51.655084318684715
                            ],
                            [
                                -0.24806511857408964,
                                51.65454947785375
                            ],
                            [
                                -0.24898468497229942,
                                51.654406852565984
                            ],
                            [
                                -0.24935825882249674,
                                51.65442468075139
                            ],
                            [
                                -0.2496743597718023,
                                51.65315886215467
                            ],
                            [
                                -0.2501341429703814,
                                51.65066277824266
                            ],
                            [
                                -0.2505651897201915,
                                51.64914723166294
                            ],
                            [
                                -0.2509387635692235,
                                51.647792111814795
                            ],
                            [
                                -0.25168591126728757,
                                51.64650827669817
                            ],
                            [
                                -0.25272042346597345,
                                51.645170909790664
                            ],
                            [
                                -0.25374019210099164,
                                51.64363348269654
                            ],
                            [
                                -0.25369479107138204,
                                51.64277429766844
                            ],
                            [
                                -0.2517482592101885,
                                51.641800481528776
                            ],
                            [
                                -0.2499646529239783,
                                51.64137476298529
                            ],
                            [
                                -0.24699186318926536,
                                51.64123344224075
                            ],
                            [
                                -0.2447709303619945,
                                51.64135051560348
                            ],
                            [
                                -0.242556771510408,
                                51.64232716718237
                            ],
                            [
                                -0.2400014125043981,
                                51.64390227274444
                            ],
                            [
                                -0.237704056058476,
                                51.64528498741086
                            ],
                            [
                                -0.2355431484425594,
                                51.64610974232883
                            ],
                            [
                                -0.23286922562439827,
                                51.647359745858154
                            ],
                            [
                                -0.23129999491325748,
                                51.6481791424126
                            ],
                            [
                                -0.2283574276381728,
                                51.649305917431036
                            ],
                            [
                                -0.2262308201430301,
                                51.650008228015764
                            ],
                            [
                                -0.22432709312528232,
                                51.65035414197408
                            ],
                            [
                                -0.22286933798764608,
                                51.65053503701208
                            ],
                            [
                                -0.22242343641642037,
                                51.650832980207525
                            ],
                            [
                                -0.21989380250096247,
                                51.65239714985407
                            ],
                            [
                                -0.22196038478480773,
                                51.65224286341103
                            ],
                            [
                                -0.22300653847199214,
                                51.65277488341184
                            ],
                            [
                                -0.2239272894937585,
                                51.65394078904245
                            ],
                            [
                                -0.22427029070271942,
                                51.65514310031364
                            ],
                            [
                                -0.22652014415533017,
                                51.65701600140636
                            ],
                            [
                                -0.22792644911183402,
                                51.65818631390877
                            ],
                            [
                                -0.22898056430167912,
                                51.65935051556474
                            ],
                            [
                                -0.22896341424129218,
                                51.65996224208337
                            ],
                            [
                                -0.23423705782693105,
                                51.65830258225108
                            ],
                            [
                                -0.23495248156351067,
                                51.659042540435905
                            ],
                            [
                                -0.23827959328929182,
                                51.65823397939303
                            ],
                            [
                                -0.24209548173857343,
                                51.656914717245996
                            ],
                            [
                                -0.247551113526697,
                                51.65536209753731
                            ],
                            [
                                -0.24772028117490663,
                                51.655084318684715
                            ]
                        ],
                        [
                            [
                                -0.27416005502956864,
                                51.3706330744796
                            ],
                            [
                                -0.2738629025181183,
                                51.37146784564297
                            ],
                            [
                                -0.27288654426857306,
                                51.37201110124016
                            ],
                            [
                                -0.2740114787737866,
                                51.37250135075868
                            ],
                            [
                                -0.27503028738277635,
                                51.37348183404657
                            ],
                            [
                                -0.2771315801376204,
                                51.375310246789354
                            ],
                            [
                                -0.2785324584742739,
                                51.37664838210185
                            ],
                            [
                                -0.2801031217458103,
                                51.37807922105091
                            ],
                            [
                                -0.2806974267677447,
                                51.37829119338747
                            ],
                            [
                                -0.28144030804452314,
                                51.37760227970384
                            ],
                            [
                                -0.2827774943433212,
                                51.37611843040102
                            ],
                            [
                                -0.2850061381745377,
                                51.37471402872163
                            ],
                            [
                                -0.28778663666841453,
                                51.373654074397024
                            ],
                            [
                                -0.2901213670383811,
                                51.37312409186168
                            ],
                            [
                                -0.2915859048012237,
                                51.372978344492566
                            ],
                            [
                                -0.2934749457625685,
                                51.3730313435737
                            ],
                            [
                                -0.29672239820274626,
                                51.37345533401546
                            ],
                            [
                                -0.29880246577835123,
                                51.37383957196178
                            ],
                            [
                                -0.3007551822783512,
                                51.374197307840575
                            ],
                            [
                                -0.3033658793371785,
                                51.37467428466354
                            ],
                            [
                                -0.30417243615264056,
                                51.37458153955916
                            ],
                            [
                                -0.30500021814731326,
                                51.374952518848715
                            ],
                            [
                                -0.3078019418204292,
                                51.375363242412504
                            ],
                            [
                                -0.3100518942850954,
                                51.37509824592709
                            ],
                            [
                                -0.313638949594349,
                                51.374594774908246
                            ],
                            [
                                -0.3153794143000255,
                                51.37405154996759
                            ],
                            [
                                -0.316886402033731,
                                51.372792833260974
                            ],
                            [
                                -0.3174594818755452,
                                51.371454580703045
                            ],
                            [
                                -0.3174965452243441,
                                51.370661513205846
                            ],
                            [
                                -0.31605323302926536,
                                51.3699327327393
                            ],
                            [
                                -0.31613813374633537,
                                51.369402703295805
                            ],
                            [
                                -0.3177300221971393,
                                51.369521960455614
                            ],
                            [
                                -0.31813330060487033,
                                51.3674017868494
                            ],
                            [
                                -0.31860025455040386,
                                51.36434061302714
                            ],
                            [
                                -0.31915343177703903,
                                51.36109313015436
                            ],
                            [
                                -0.32023591592377443,
                                51.35899907394591
                            ],
                            [
                                -0.3216155525810507,
                                51.35695793968722
                            ],
                            [
                                -0.3235597262303713,
                                51.35465961676812
                            ],
                            [
                                -0.32532141611528687,
                                51.35337385607758
                            ],
                            [
                                -0.3281655901477336,
                                51.352048291817
                            ],
                            [
                                -0.3307338368489354,
                                51.34830999402965
                            ],
                            [
                                -0.32857329900133436,
                                51.34460997051738
                            ],
                            [
                                -0.32318210344780596,
                                51.342873141401014
                            ],
                            [
                                -0.3184593412687491,
                                51.341666082806455
                            ],
                            [
                                -0.3163580485138766,
                                51.34120202112979
                            ],
                            [
                                -0.3128983442801996,
                                51.33969047337868
                            ],
                            [
                                -0.3093325190348821,
                                51.33835123967509
                            ],
                            [
                                -0.3051299335243698,
                                51.33804626333378
                            ],
                            [
                                -0.3054058608564958,
                                51.33957112475002
                            ],
                            [
                                -0.3043233767097888,
                                51.34262069540853
                            ],
                            [
                                -0.30152253968367404,
                                51.34517934543348
                            ],
                            [
                                -0.3000580023084751,
                                51.34763197679632
                            ],
                            [
                                -0.2970373656117431,
                                51.34983431225834
                            ],
                            [
                                -0.2928135549227875,
                                51.35253855333562
                            ],
                            [
                                -0.2912352670612677,
                                51.35537417262941
                            ],
                            [
                                -0.29068341239866413,
                                51.35692496641593
                            ],
                            [
                                -0.2894523518978076,
                                51.35801181634025
                            ],
                            [
                                -0.2887519209794789,
                                51.358807055972534
                            ],
                            [
                                -0.28699023109376753,
                                51.35936371550082
                            ],
                            [
                                -0.2854197394833591,
                                51.36100694159617
                            ],
                            [
                                -0.286926727217093,
                                51.36140453713864
                            ],
                            [
                                -0.28839126459141085,
                                51.36176237017651
                            ],
                            [
                                -0.2895798746353364,
                                51.36214670625128
                            ],
                            [
                                -0.2918297436458772,
                                51.36250453349027
                            ],
                            [
                                -0.29397348675919943,
                                51.362796094359
                            ],
                            [
                                -0.2958837529006644,
                                51.363233432181886
                            ],
                            [
                                -0.2971784888400464,
                                51.36340571563261
                            ],
                            [
                                -0.29983165988551264,
                                51.36328642349281
                            ],
                            [
                                -0.3036097418090833,
                                51.36295510772729
                            ],
                            [
                                -0.30719679711836534,
                                51.3628358334648
                            ],
                            [
                                -0.3079184532159047,
                                51.362557525644576
                            ],
                            [
                                -0.30855520859634566,
                                51.36295510772729
                            ],
                            [
                                -0.3086401093134157,
                                51.3634852118021
                            ],
                            [
                                -0.3071543467598019,
                                51.36359123188035
                            ],
                            [
                                -0.3058171604592701,
                                51.363405696551325
                            ],
                            [
                                -0.30057454116143845,
                                51.363710504145075
                            ],
                            [
                                -0.29798506928096913,
                                51.363988804959064
                            ],
                            [
                                -0.29573520027048517,
                                51.36392254301413
                            ],
                            [
                                -0.2935065555642211,
                                51.36337919172561
                            ],
                            [
                                -0.2912566468397415,
                                51.362915316990495
                            ],
                            [
                                -0.28860349942235075,
                                51.36254424016539
                            ],
                            [
                                -0.28686303471585006,
                                51.361908101469595
                            ],
                            [
                                -0.2877332670690862,
                                51.362345447771844
                            ],
                            [
                                -0.28645975630814746,
                                51.36337915879477
                            ],
                            [
                                -0.28391272939668966,
                                51.365168220206726
                            ],
                            [
                                -0.28162041002761384,
                                51.366678927638134
                            ],
                            [
                                -0.27939176619634054,
                                51.36804382219958
                            ],
                            [
                                -0.2772904734415249,
                                51.36853411419776
                            ],
                            [
                                -0.27597450653962596,
                                51.369925458659026
                            ],
                            [
                                -0.27416005502956864,
                                51.3706330744796
                            ]
                        ],
                        [
                            [
                                0.2808409519459474,
                                51.534269626903665
                            ],
                            [
                                0.2977120041313128,
                                51.5355193227482
                            ],
                            [
                                0.29710687313507833,
                                51.53837994310521
                            ],
                            [
                                0.29463791614008983,
                                51.54206838479942
                            ],
                            [
                                0.2934276541466261,
                                51.54408560333357
                            ],
                            [
                                0.29345185938635154,
                                51.54480816732436
                            ],
                            [
                                0.2937181215355906,
                                51.547645407480985
                            ],
                            [
                                0.29405699489433346,
                                51.54943660285758
                            ],
                            [
                                0.2942610893249764,
                                51.552452814084035
                            ],
                            [
                                0.29501145176084265,
                                51.55591435895576
                            ],
                            [
                                0.29501145176084265,
                                51.55713336203718
                            ],
                            [
                                0.2947209956728045,
                                51.55965880690954
                            ],
                            [
                                0.2946241747136753,
                                51.56033597912287
                            ],
                            [
                                0.2979887030546422,
                                51.56045636423866
                            ],
                            [
                                0.3009901527980787,
                                51.56024569007735
                            ],
                            [
                                0.3015226682096852,
                                51.56093790159281
                            ],
                            [
                                0.3008933319727305,
                                51.56308970898937
                            ],
                            [
                                0.29989362499364347,
                                51.564907449865046
                            ],
                            [
                                0.29067142860540685,
                                51.56423034572518
                            ],
                            [
                                0.29117973864256896,
                                51.560272846524214
                            ],
                            [
                                0.29093768624386485,
                                51.55831654101371
                            ],
                            [
                                0.28952645972839264,
                                51.55460795213142
                            ],
                            [
                                0.2863313680658166,
                                51.55040879305986
                            ],
                            [
                                0.2808409519459474,
                                51.534269626903665
                            ]
                        ],
                        [
                            [
                                0.0827430506293183,
                                51.606443431109426
                            ],
                            [
                                0.08044212359845915,
                                51.60693230131827
                            ],
                            [
                                0.07886780510273184,
                                51.60702004669625
                            ],
                            [
                                0.07688981519871163,
                                51.606455966308715
                            ],
                            [
                                0.0747907238715868,
                                51.60544060394966
                            ],
                            [
                                0.07321640537668372,
                                51.60478875441794
                            ],
                            [
                                0.07456870459668608,
                                51.60367306715773
                            ],
                            [
                                0.07598155452859601,
                                51.60238184430793
                            ],
                            [
                                0.07688981519871163,
                                51.60151682982263
                            ],
                            [
                                0.07763660730506672,
                                51.600375988420296
                            ],
                            [
                                0.07907556917663783,
                                51.59935955130072
                            ],
                            [
                                0.08083153980652469,
                                51.597905217826195
                            ],
                            [
                                0.08220402259692605,
                                51.59680189929557
                            ],
                            [
                                0.08303154898553089,
                                51.596024545145184
                            ],
                            [
                                0.08398017679644454,
                                51.59527225426703
                            ],
                            [
                                0.08426274678325285,
                                51.59701504246439
                            ],
                            [
                                0.08456550033969279,
                                51.59801805571067
                            ],
                            [
                                0.08442421534627442,
                                51.599184030766565
                            ],
                            [
                                0.0841416454854027,
                                51.60058817623593
                            ],
                            [
                                0.08398017692158533,
                                51.601240086072096
                            ],
                            [
                                0.08373797015596551,
                                51.602497412461275
                            ],
                            [
                                0.08400035657243166,
                                51.60358805663711
                            ],
                            [
                                0.08319301375419741,
                                51.60556869976227
                            ],
                            [
                                0.0827430506293183,
                                51.606443431109426
                            ]
                        ],
                        [
                            [
                                0.02865229824297444,
                                51.62513818044346
                            ],
                            [
                                0.02654842919753264,
                                51.62615038898181
                            ],
                            [
                                0.024725076029739057,
                                51.627380246349816
                            ],
                            [
                                0.023865996170258086,
                                51.62654220556763
                            ],
                            [
                                0.021656933676837298,
                                51.625649729531744
                            ],
                            [
                                0.02274393268154995,
                                51.62415860235228
                            ],
                            [
                                0.023550415813843983,
                                51.62184018320775
                            ],
                            [
                                0.024234173256132863,
                                51.62039247170878
                            ],
                            [
                                0.025514026923673327,
                                51.61923862347902
                            ],
                            [
                                0.026653622654237097,
                                51.618868515002305
                            ],
                            [
                                0.027512702512353826,
                                51.62101292513631
                            ],
                            [
                                0.02865229824297444,
                                51.62513818044346
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--11",
            "properties": {
                "land": "gb",
                "city": "London",
                "zone": "Congestion Charge Zone",
                "area_code": 6,
                "time": "Mo-Su: 07.00-18.00 / Sat-Sun from 12:00",
                "commercial": 2,
                "diesel": "all vehicles",
                "minEUd": 11,
                "petrol": "all vehicles",
                "minEUb": 11,
                "minOUTd": 6,
                "minOUTb": 4,
                "warn": "req. Online Registration",
                "img_sign": "sign-gb-cc--white",
                "color": 4,
                "urlZone": "https://tfl.gov.uk/modes/driving/congestion-charge?intcmp=2053",
                "urlPark": "https://tfl.gov.uk/modes/driving/station-car-parks",
                "urlReg": "https://tfl.gov.uk/modes/driving/pay-to-drive-in-london",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -0.14606237411499023,
                            51.52257629597823
                        ],
                        [
                            -0.14923810958862305,
                            51.5230569329333
                        ],
                        [
                            -0.15297174453735352,
                            51.522709806752374
                        ],
                        [
                            -0.1572418212890625,
                            51.52194879009642
                        ],
                        [
                            -0.15670537948608398,
                            51.52077386221533
                        ],
                        [
                            -0.15925884246826172,
                            51.520333256444886
                        ],
                        [
                            -0.15983819961547852,
                            51.52150819569328
                        ],
                        [
                            -0.16490221023559568,
                            51.52058693907577
                        ],
                        [
                            -0.16582489013671875,
                            51.519064822093966
                        ],
                        [
                            -0.1669621467590332,
                            51.518103458841786
                        ],
                        [
                            -0.15983819961547852,
                            51.51342987616821
                        ],
                        [
                            -0.1578211784362793,
                            51.513777073083524
                        ],
                        [
                            -0.15646934509277344,
                            51.51063885098415
                        ],
                        [
                            -0.1506328582763672,
                            51.50543026060529
                        ],
                        [
                            -0.15067577362060547,
                            51.503547008299094
                        ],
                        [
                            -0.1494312286376953,
                            51.50325316049429
                        ],
                        [
                            -0.14960289001464844,
                            51.50225139237363
                        ],
                        [
                            -0.1508045196533203,
                            51.50165032093083
                        ],
                        [
                            -0.14835834503173828,
                            51.500060782690625
                        ],
                        [
                            -0.14681339263916013,
                            51.49856469605488
                        ],
                        [
                            -0.14363765716552734,
                            51.49832424897921
                        ],
                        [
                            -0.14172792434692383,
                            51.497723125740386
                        ],
                        [
                            -0.1410198211669922,
                            51.49644070300001
                        ],
                        [
                            -0.14200687408447266,
                            51.49616016821581
                        ],
                        [
                            -0.14009714126586914,
                            51.49418301696384
                        ],
                        [
                            -0.1253986358642578,
                            51.4871955049312
                        ],
                        [
                            -0.12061357498168945,
                            51.486487341653024
                        ],
                        [
                            -0.11269569396972656,
                            51.48762306988736
                        ],
                        [
                            -0.1111292839050293,
                            51.489293207077296
                        ],
                        [
                            -0.10589361190795897,
                            51.491056805537205
                        ],
                        [
                            -0.10295391082763672,
                            51.491818338327676
                        ],
                        [
                            -0.10147333145141602,
                            51.49328792704907
                        ],
                        [
                            -0.10168790817260742,
                            51.495625811469374
                        ],
                        [
                            -0.10050773620605469,
                            51.49608001510318
                        ],
                        [
                            -0.09907007217407227,
                            51.49535863074706
                        ],
                        [
                            -0.09424209594726562,
                            51.49451700123546
                        ],
                        [
                            -0.08752584457397461,
                            51.494583797796174
                        ],
                        [
                            -0.08613109588623047,
                            51.495198321561965
                        ],
                        [
                            -0.08486509323120116,
                            51.495104807610296
                        ],
                        [
                            -0.08078813552856445,
                            51.497642975376195
                        ],
                        [
                            -0.07793426513671875,
                            51.50229146352127
                        ],
                        [
                            -0.07877111434936523,
                            51.50294595394508
                        ],
                        [
                            -0.07842779159545898,
                            51.503239803730864
                        ],
                        [
                            -0.0773763656616211,
                            51.503106235881305
                        ],
                        [
                            -0.07447957992553711,
                            51.5090896914441
                        ],
                        [
                            -0.07589578628540038,
                            51.511266512699024
                        ],
                        [
                            -0.07338523864746094,
                            51.51145347409147
                        ],
                        [
                            -0.07460832595825195,
                            51.51456493319091
                        ],
                        [
                            -0.07233381271362305,
                            51.51536613288439
                        ],
                        [
                            -0.07488727569580078,
                            51.518450620134814
                        ],
                        [
                            -0.07486581802368164,
                            51.520213090494956
                        ],
                        [
                            -0.07945775985717773,
                            51.52349751235236
                        ],
                        [
                            -0.08063793182373047,
                            51.52348416152351
                        ],
                        [
                            -0.08061647415161131,
                            51.52423180191151
                        ],
                        [
                            -0.08411407470703125,
                            51.525953998241214
                        ],
                        [
                            -0.08779942989349365,
                            51.52515298479501
                        ],
                        [
                            -0.0883948802947998,
                            51.52563359455367
                        ],
                        [
                            -0.0890064239501953,
                            51.527208890975785
                        ],
                        [
                            -0.10155916213989258,
                            51.530386017939655
                        ],
                        [
                            -0.10239601135253906,
                            51.52942489367756
                        ],
                        [
                            -0.1062154769897461,
                            51.53161409163567
                        ],
                        [
                            -0.11383295059204103,
                            51.53110684782056
                        ],
                        [
                            -0.11572122573852539,
                            51.529184609441586
                        ],
                        [
                            -0.11977672576904297,
                            51.52842370099461
                        ],
                        [
                            -0.12207269668579102,
                            51.530412715546184
                        ],
                        [
                            -0.13522624969482422,
                            51.52536658975847
                        ],
                        [
                            -0.13451814651489258,
                            51.524445411196304
                        ],
                        [
                            -0.13735055923461914,
                            51.52343075816895
                        ],
                        [
                            -0.13835906982421875,
                            51.52460561750232
                        ],
                        [
                            -0.14327287673950195,
                            51.52344410901347
                        ],
                        [
                            -0.14606237411499023,
                            51.52257629597823
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--12",
            "properties": {
                "land": "gb",
                "city": "Oxford",
                "zone": "ZEZ",
                "area_code": 7,
                "time": "24/7?",
                "commercial": 2,
                "diesel": "diesel - no entry",
                "minEUd": 10,
                "petrol": "gas - no entry",
                "minEUb": 10,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "only Electro or H2",
                "img_sign": "sign-gb-zez--white",
                "color": 4,
                "urlZone": "https://www.oxfordshire.gov.uk/residents/roads-and-transport/oxford-zero-emission-zone-zez",
                "urlPark": "https://www.oxfordshire.gov.uk/residents/roads-and-transport/public-transport/park-and-ride",
                "urlReg": "https://www.oxfordshire.gov.uk/residents/roads-and-transport/oxford-zero-emission-zone-zez/check-your-vehicle-and-pay",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -1.260606050491333,
                            51.75160995000544
                        ],
                        [
                            -1.260230541229248,
                            51.75151696301942
                        ],
                        [
                            -1.259855031967163,
                            51.751500358180316
                        ],
                        [
                            -1.259259581565857,
                            51.75156013557244
                        ],
                        [
                            -1.2577414512634277,
                            51.75182913285789
                        ],
                        [
                            -1.2578541040420532,
                            51.75202174721381
                        ],
                        [
                            -1.2576878070831299,
                            51.752151263301606
                        ],
                        [
                            -1.257162094116211,
                            51.752211039832225
                        ],
                        [
                            -1.25743567943573,
                            51.75309107404364
                        ],
                        [
                            -1.2569528818130493,
                            51.753303608037164
                        ],
                        [
                            -1.256480813026428,
                            51.75341319548676
                        ],
                        [
                            -1.256786584854126,
                            51.75418362095965
                        ],
                        [
                            -1.258261799812317,
                            51.7541537340096
                        ],
                        [
                            -1.2585943937301636,
                            51.75407735615857
                        ],
                        [
                            -1.25892698764801,
                            51.75389803373974
                        ],
                        [
                            -1.2597262859344482,
                            51.75371538980343
                        ],
                        [
                            -1.2612122297286987,
                            51.75353606594737
                        ],
                        [
                            -1.2602519989013672,
                            51.75207488206399
                        ],
                        [
                            -1.260606050491333,
                            51.75160995000544
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--13",
            "properties": {
                "land": "gb",
                "city": "Portsmouth",
                "zone": "CAZ B",
                "area_code": 1,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel trucks)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol trucks)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-gb-caz-b--white",
                "color": 0,
                "urlZone": "https://cleanerairportsmouth.co.uk/clean-air-zone-caz/",
                "urlPark": "https://www.portsmouth.gov.uk/services/parking-roads-and-travel/parking/",
                "urlReg": "https://www.gov.uk/clean-air-zones",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -1.1004137992858887,
                            50.79276596071177
                        ],
                        [
                            -1.098954677581787,
                            50.79192498657763
                        ],
                        [
                            -1.0963153839111328,
                            50.79109756177581
                        ],
                        [
                            -1.0960793495178223,
                            50.79161300845443
                        ],
                        [
                            -1.095435619354248,
                            50.79178934417123
                        ],
                        [
                            -1.0957789421081543,
                            50.792643884760125
                        ],
                        [
                            -1.0956716537475586,
                            50.792918555203
                        ],
                        [
                            -1.0952585935592651,
                            50.79291516421972
                        ],
                        [
                            -1.0952317714691162,
                            50.7931016679349
                        ],
                        [
                            -1.094765067100525,
                            50.79308132211123
                        ],
                        [
                            -1.0936814546585083,
                            50.79301350263502
                        ],
                        [
                            -1.0935419797897339,
                            50.793634047173214
                        ],
                        [
                            -1.0919326543807983,
                            50.79395618453193
                        ],
                        [
                            -1.091294288635254,
                            50.79401043902649
                        ],
                        [
                            -1.0907095670700073,
                            50.79456654396498
                        ],
                        [
                            -1.088671088218689,
                            50.794563153101265
                        ],
                        [
                            -1.087496280670166,
                            50.79475304109082
                        ],
                        [
                            -1.0865575075149534,
                            50.79484120310946
                        ],
                        [
                            -1.085050106048584,
                            50.7947699953381
                        ],
                        [
                            -1.083676815032959,
                            50.79476321363992
                        ],
                        [
                            -1.0832905769348145,
                            50.79461062517098
                        ],
                        [
                            -1.082577109336853,
                            50.794702178312136
                        ],
                        [
                            -1.0810267925262451,
                            50.794827639732794
                        ],
                        [
                            -1.0807263851165771,
                            50.79548884976357
                        ],
                        [
                            -1.0795247554779053,
                            50.795624481434146
                        ],
                        [
                            -1.0797715187072754,
                            50.79689600920377
                        ],
                        [
                            -1.0772663354873657,
                            50.796350104185926
                        ],
                        [
                            -1.0771268606185913,
                            50.797163873308996
                        ],
                        [
                            -1.0771375894546509,
                            50.798316688644185
                        ],
                        [
                            -1.0773307085037231,
                            50.79953050464375
                        ],
                        [
                            -1.0775238275527954,
                            50.80022894313505
                        ],
                        [
                            -1.0775667428970337,
                            50.801320656175804
                        ],
                        [
                            -1.0775560140609741,
                            50.80257507755645
                        ],
                        [
                            -1.0774219036102295,
                            50.802914104581184
                        ],
                        [
                            -1.0773897171020508,
                            50.803226007271334
                        ],
                        [
                            -1.0769337415695188,
                            50.80485668200225
                        ],
                        [
                            -1.0768908262252808,
                            50.8052295933835
                        ],
                        [
                            -1.0770142078399658,
                            50.805548260750754
                        ],
                        [
                            -1.0774540901184082,
                            50.806511029805655
                        ],
                        [
                            -1.0775130987167358,
                            50.80690426779725
                        ],
                        [
                            -1.077561378479004,
                            50.80763649523746
                        ],
                        [
                            -1.0776472091674805,
                            50.80812125060834
                        ],
                        [
                            -1.0776686668395996,
                            50.80862295017075
                        ],
                        [
                            -1.0779690742492676,
                            50.80932464254771
                        ],
                        [
                            -1.07865571975708,
                            50.809938191012925
                        ],
                        [
                            -1.0789024829864502,
                            50.810304282390966
                        ],
                        [
                            -1.079508662223816,
                            50.81092459734309
                        ],
                        [
                            -1.079980731010437,
                            50.81177878805738
                        ],
                        [
                            -1.0802167654037473,
                            50.81232112326068
                        ],
                        [
                            -1.0802757740020752,
                            50.81306004484046
                        ],
                        [
                            -1.0808926820755005,
                            50.813043097228785
                        ],
                        [
                            -1.0819387435913086,
                            50.813199015024225
                        ],
                        [
                            -1.0828453302383423,
                            50.81335154279877
                        ],
                        [
                            -1.083424687385559,
                            50.813283752738236
                        ],
                        [
                            -1.0840898752212524,
                            50.81315156183724
                        ],
                        [
                            -1.0846316814422607,
                            50.812890568446996
                        ],
                        [
                            -1.085227131843567,
                            50.812456706077576
                        ],
                        [
                            -1.0855758190155027,
                            50.81107713254486
                        ],
                        [
                            -1.0860586166381836,
                            50.80961616381778
                        ],
                        [
                            -1.0863322019577026,
                            50.80913820357237
                        ],
                        [
                            -1.0870295763015747,
                            50.80829074430641
                        ],
                        [
                            -1.087270975112915,
                            50.80776870174654
                        ],
                        [
                            -1.0883116722106934,
                            50.80779582098422
                        ],
                        [
                            -1.088445782661438,
                            50.806972067116455
                        ],
                        [
                            -1.0881239175796509,
                            50.80601608762873
                        ],
                        [
                            -1.0880595445632935,
                            50.80560928191354
                        ],
                        [
                            -1.0895133018493652,
                            50.80522620329342
                        ],
                        [
                            -1.0895240306854248,
                            50.804917704068316
                        ],
                        [
                            -1.090441346168518,
                            50.804324430602314
                        ],
                        [
                            -1.0908490419387817,
                            50.803944731630516
                        ],
                        [
                            -1.0909080505371094,
                            50.80338195783565
                        ],
                        [
                            -1.0916483402252197,
                            50.80308700741661
                        ],
                        [
                            -1.0924315452575684,
                            50.80284290911004
                        ],
                        [
                            -1.0928875207900999,
                            50.802392001940255
                        ],
                        [
                            -1.0932952165603638,
                            50.8019207484434
                        ],
                        [
                            -1.0937780141830444,
                            50.80159527562757
                        ],
                        [
                            -1.0940998792648313,
                            50.80114774680409
                        ],
                        [
                            -1.0946577787399292,
                            50.80074767953042
                        ],
                        [
                            -1.095660924911499,
                            50.80036456104927
                        ],
                        [
                            -1.096181273460388,
                            50.80009671528972
                        ],
                        [
                            -1.0976403951644895,
                            50.79997804895613
                        ],
                        [
                            -1.0977530479431152,
                            50.80015774357252
                        ],
                        [
                            -1.0993516445159912,
                            50.800134010360914
                        ],
                        [
                            -1.0992765426635742,
                            50.80157493350131
                        ],
                        [
                            -1.0996735095977783,
                            50.80176818334324
                        ],
                        [
                            -1.1003923416137695,
                            50.8020699227263
                        ],
                        [
                            -1.1013847589492798,
                            50.80246319809847
                        ],
                        [
                            -1.102854609489441,
                            50.80242590488627
                        ],
                        [
                            -1.1029082536697388,
                            50.80179530606411
                        ],
                        [
                            -1.1037719249725342,
                            50.80171732819916
                        ],
                        [
                            -1.1046838760375977,
                            50.801235896759806
                        ],
                        [
                            -1.1048179864883423,
                            50.80062562442606
                        ],
                        [
                            -1.1049145460128784,
                            50.79991362996311
                        ],
                        [
                            -1.105949878692627,
                            50.79973732489656
                        ],
                        [
                            -1.106545329093933,
                            50.7994796470643
                        ],
                        [
                            -1.1074304580688477,
                            50.798855789586106
                        ],
                        [
                            -1.107049584388733,
                            50.79831329805254
                        ],
                        [
                            -1.1068618297576904,
                            50.79797423764608
                        ],
                        [
                            -1.1076128482818604,
                            50.79737748535583
                        ],
                        [
                            -1.1091309785842896,
                            50.79690279059247
                        ],
                        [
                            -1.1087661981582642,
                            50.79554310247903
                        ],
                        [
                            -1.10786497592926,
                            50.79520402197411
                        ],
                        [
                            -1.107269525527954,
                            50.794600452588675
                        ],
                        [
                            -1.108095645904541,
                            50.79432579202943
                        ],
                        [
                            -1.1082994937896729,
                            50.793762902383115
                        ],
                        [
                            -1.1078703403472898,
                            50.793122013749716
                        ],
                        [
                            -1.1068779230117798,
                            50.79284734450242
                        ],
                        [
                            -1.106175184249878,
                            50.7926303207458
                        ],
                        [
                            -1.1054885387420654,
                            50.792416687001214
                        ],
                        [
                            -1.10512375831604,
                            50.79233869348609
                        ],
                        [
                            -1.1043459177017212,
                            50.79237260372603
                        ],
                        [
                            -1.1042386293411255,
                            50.79252859051302
                        ],
                        [
                            -1.104201078414917,
                            50.79402739354315
                        ],
                        [
                            -1.1037558317184448,
                            50.79412912051385
                        ],
                        [
                            -1.1031442880630493,
                            50.79427153790089
                        ],
                        [
                            -1.1029672622680664,
                            50.794342746431674
                        ],
                        [
                            -1.1023449897766113,
                            50.793864629929494
                        ],
                        [
                            -1.1017763614654539,
                            50.79347806407539
                        ],
                        [
                            -1.1008697748184204,
                            50.79310844987416
                        ],
                        [
                            -1.1004137992858887,
                            50.79276596071177
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "land": "gb",
                "city": "Scheffield",
                "zone": "CAZ C",
                "area_code": 2,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel trucks)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol trucks)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-gb-caz-c--white",
                "color": 0,
                "urlZone": "https://www.sheffield.gov.uk/campaigns/clean-air-zone-sheffield",
                "urlPark": "https://www.sheffield.gov.uk/parking",
                "urlReg": "https://www.sheffield.gov.uk/campaigns/clean-air-zone-sheffield#Charges",
                "urlBadge": null
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -1.4783182827054588,
                                53.38838798441489
                            ],
                            [
                                -1.4787617190679327,
                                53.38788848356572
                            ],
                            [
                                -1.4797080279247155,
                                53.38740233731471
                            ],
                            [
                                -1.4802701916013348,
                                53.386631197391154
                            ],
                            [
                                -1.4816100081582704,
                                53.38572033593624
                            ],
                            [
                                -1.4839055098397864,
                                53.38416680449828
                            ],
                            [
                                -1.4843552444537806,
                                53.38352973150728
                            ],
                            [
                                -1.4845145241625062,
                                53.38268587049322
                            ],
                            [
                                -1.483671278646142,
                                53.38263557361347
                            ],
                            [
                                -1.4831653242532923,
                                53.38215495627773
                            ],
                            [
                                -1.4831746936479249,
                                53.38180846113465
                            ],
                            [
                                -1.4835775776165292,
                                53.38151785013102
                            ],
                            [
                                -1.48446767010509,
                                53.38146755187154
                            ],
                            [
                                -1.4844770394996942,
                                53.379494695484766
                            ],
                            [
                                -1.4844208234181053,
                                53.37776767125783
                            ],
                            [
                                -1.4841210027899194,
                                53.37704107108431
                            ],
                            [
                                -1.4835026227450498,
                                53.37645978201954
                            ],
                            [
                                -1.482284601444519,
                                53.37571080171978
                            ],
                            [
                                -1.4800734112933185,
                                53.37432458435623
                            ],
                            [
                                -1.4794269230640111,
                                53.3746152444522
                            ],
                            [
                                -1.4785461999699976,
                                53.37460406525446
                            ],
                            [
                                -1.478171424185149,
                                53.37432458435623
                            ],
                            [
                                -1.4781901629743288,
                                53.37395566676281
                            ],
                            [
                                -1.4787710654408386,
                                53.37352525886533
                            ],
                            [
                                -1.476934664095495,
                                53.37303335879889
                            ],
                            [
                                -1.4769159253062867,
                                53.37273709806371
                            ],
                            [
                                -1.476400608601864,
                                53.372658840166764
                            ],
                            [
                                -1.476016463422411,
                                53.37283771514842
                            ],
                            [
                                -1.4740488905520976,
                                53.3726644300213
                            ],
                            [
                                -1.473720949223008,
                                53.37310601399
                            ],
                            [
                                -1.4734960837522522,
                                53.37356437481384
                            ],
                            [
                                -1.4728027485507198,
                                53.37346934919461
                            ],
                            [
                                -1.4720438275863614,
                                53.37317868127906
                            ],
                            [
                                -1.4717814845372743,
                                53.37274267568594
                            ],
                            [
                                -1.4703479671597677,
                                53.37300539753954
                            ],
                            [
                                -1.4684740882360927,
                                53.373329605251996
                            ],
                            [
                                -1.4669187687290162,
                                53.373748835704816
                            ],
                            [
                                -1.465513359536061,
                                53.37400037199566
                            ],
                            [
                                -1.4637987586658312,
                                53.37431898203843
                            ],
                            [
                                -1.4630023601231414,
                                53.37452579810625
                            ],
                            [
                                -1.4626744313118536,
                                53.37471025483342
                            ],
                            [
                                -1.4626650619165957,
                                53.37484440467853
                            ],
                            [
                                -1.4643391910292394,
                                53.37660531194442
                            ],
                            [
                                -1.4646483810512905,
                                53.37718100976133
                            ],
                            [
                                -1.464217388898902,
                                53.37791878587831
                            ],
                            [
                                -1.4635990088540893,
                                53.37825413443298
                            ],
                            [
                                -1.4631399085179169,
                                53.378516822290294
                            ],
                            [
                                -1.4624090894560027,
                                53.37925457659449
                            ],
                            [
                                -1.461575213334953,
                                53.380165572101134
                            ],
                            [
                                -1.4609568332900835,
                                53.38104860435925
                            ],
                            [
                                -1.460610165688962,
                                53.381752781516695
                            ],
                            [
                                -1.460318581562234,
                                53.38211738910405
                            ],
                            [
                                -1.4589881275259415,
                                53.38254212161377
                            ],
                            [
                                -1.4585946129518845,
                                53.38284390266489
                            ],
                            [
                                -1.4585290271897406,
                                53.38319038938198
                            ],
                            [
                                -1.4585946129518845,
                                53.383777174971755
                            ],
                            [
                                -1.4562054173237868,
                                53.384693661999194
                            ],
                            [
                                -1.4547344257565555,
                                53.38493954448634
                            ],
                            [
                                -1.452982348962479,
                                53.38490601501459
                            ],
                            [
                                -1.452279644365575,
                                53.3852245439314
                            ],
                            [
                                -1.454228478446737,
                                53.385537482300464
                            ],
                            [
                                -1.4550436157785782,
                                53.386157764055866
                            ],
                            [
                                -1.4561960513169936,
                                53.386509811842615
                            ],
                            [
                                -1.4577326265763872,
                                53.386710976665995
                            ],
                            [
                                -1.4586133496703724,
                                53.38688420464183
                            ],
                            [
                                -1.4583510066212,
                                53.38739270849996
                            ],
                            [
                                -1.4584634393569615,
                                53.38744299976051
                            ],
                            [
                                -1.459100558191011,
                                53.386873028664695
                            ],
                            [
                                -1.4605715531460532,
                                53.38701272816857
                            ],
                            [
                                -1.4614991232136845,
                                53.38733124132358
                            ],
                            [
                                -1.461302365926656,
                                53.38791796984438
                            ],
                            [
                                -1.461367951688743,
                                53.387979436173595
                            ],
                            [
                                -1.4618176826302545,
                                53.3876050489728
                            ],
                            [
                                -1.462679666935827,
                                53.38830352989774
                            ],
                            [
                                -1.4633542573236866,
                                53.38884555609573
                            ],
                            [
                                -1.4648533604624845,
                                53.389320510530894
                            ],
                            [
                                -1.4669239966739838,
                                53.38960548064867
                            ],
                            [
                                -1.4679921076603932,
                                53.38961665590844
                            ],
                            [
                                -1.4693694086695928,
                                53.38978428445398
                            ],
                            [
                                -1.4694256281359515,
                                53.38933727519506
                            ],
                            [
                                -1.468479319279112,
                                53.389169644888796
                            ],
                            [
                                -1.469144546296917,
                                53.38798503857828
                            ],
                            [
                                -1.4695099526872184,
                                53.387565948263386
                            ],
                            [
                                -1.4704750003332094,
                                53.38726420068164
                            ],
                            [
                                -1.4718065534443383,
                                53.38734305477229
                            ],
                            [
                                -1.4745143084898018,
                                53.38761127445349
                            ],
                            [
                                -1.4753762927946354,
                                53.388024776484826
                            ],
                            [
                                -1.4761539525481453,
                                53.38860031992337
                            ],
                            [
                                -1.4770815226150376,
                                53.3882091747445
                            ],
                            [
                                -1.4783182827054588,
                                53.38838798441489
                            ]
                        ],
                        [
                            [
                                -1.4516821628122045,
                                53.385187243734634
                            ],
                            [
                                -1.4523122648147364,
                                53.38481142882094
                            ],
                            [
                                -1.4485526562004623,
                                53.384354182869544
                            ],
                            [
                                -1.4492457684029603,
                                53.38485527406547
                            ],
                            [
                                -1.4507685149088445,
                                53.3851246081463
                            ],
                            [
                                -1.4516821628122045,
                                53.385187243734634
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "id": "gb--15",
            "properties": {
                "land": "gb",
                "city": "Newcastle",
                "zone": "CAZ C",
                "area_code": 2,
                "time": "Mo-Su: 00.00-24.00",
                "commercial": 1,
                "diesel": "min. EURO-6 (diesel truck/bus)",
                "minEUd": 6,
                "petrol": "min. EURO-4 (petrol truck/bus)",
                "minEUb": 4,
                "minOUTd": 0,
                "minOUTb": 0,
                "warn": "-",
                "img_sign": "sign-gb-caz-c--white",
                "color": 0,
                "urlZone": "https://www.newcastle.gov.uk/our-city/transport-improvements/transport-and-air-quality/newcastle-and-gateshead-clean-air-zone",
                "urlPark": "https://www.newcastle.gov.uk/services/parking-and-permits/car-parks-and-street-parking/park-and-ride",
                "urlReg": "https://www.gov.uk/clean-air-zones",
                "urlBadge": null
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -1.6317276446658866,
                            54.97057658410694
                        ],
                        [
                            -1.6311031756034993,
                            54.96994897312385
                        ],
                        [
                            -1.6294504771138634,
                            54.968619443478815
                        ],
                        [
                            -1.6292453978912818,
                            54.968522496985344
                        ],
                        [
                            -1.628262223967539,
                            54.96888604512975
                        ],
                        [
                            -1.6277736528766127,
                            54.968934517967114
                        ],
                        [
                            -1.627327303978518,
                            54.96889296982434
                        ],
                        [
                            -1.6269111005596528,
                            54.96888951704557
                        ],
                        [
                            -1.6266517356595784,
                            54.96881334533251
                        ],
                        [
                            -1.6266818943687724,
                            54.96861945304727
                        ],
                        [
                            -1.624208880204833,
                            54.96841171027452
                        ],
                        [
                            -1.6245345942661231,
                            54.96749070437468
                        ],
                        [
                            -1.625167930617522,
                            54.967501091928256
                        ],
                        [
                            -1.6255961842891224,
                            54.96738336787777
                        ],
                        [
                            -1.6242209471448064,
                            54.9663826995191
                        ],
                        [
                            -1.624396246714241,
                            54.96598005718985
                        ],
                        [
                            -1.6248965895997571,
                            54.96560727135221
                        ],
                        [
                            -1.6246091599392969,
                            54.96527115031549
                        ],
                        [
                            -1.6248327163413876,
                            54.96513670111344
                        ],
                        [
                            -1.624225920393485,
                            54.964513339842625
                        ],
                        [
                            -1.6301235565989316,
                            54.96307712677799
                        ],
                        [
                            -1.6291335211034834,
                            54.96131082083406
                        ],
                        [
                            -1.629176103275995,
                            54.96073629628171
                        ],
                        [
                            -1.6278454104063655,
                            54.960681288180524
                        ],
                        [
                            -1.6253011256391972,
                            54.96077908030793
                        ],
                        [
                            -1.6222352092682684,
                            54.961616415653396
                        ],
                        [
                            -1.6198362902645727,
                            54.96268598048792
                        ],
                        [
                            -1.6171323223536547,
                            54.960296230639074
                        ],
                        [
                            -1.6166639184636438,
                            54.960467368345604
                        ],
                        [
                            -1.6194743418045618,
                            54.96288155474571
                        ],
                        [
                            -1.6185408936256351,
                            54.96335730669739
                        ],
                        [
                            -1.6169121255535117,
                            54.964231257177715
                        ],
                        [
                            -1.6156120670900123,
                            54.965033922230845
                        ],
                        [
                            -1.61534592851541,
                            54.96541282434424
                        ],
                        [
                            -1.6143984751924734,
                            54.965700054210316
                        ],
                        [
                            -1.6133445664403894,
                            54.96620117373911
                        ],
                        [
                            -1.6119393547694756,
                            54.96694673024979
                        ],
                        [
                            -1.6103851054977838,
                            54.96762505242921
                        ],
                        [
                            -1.6114268276748192,
                            54.96636269533985
                        ],
                        [
                            -1.6094680477714007,
                            54.96731602359512
                        ],
                        [
                            -1.6079989628430553,
                            54.966326028416745
                        ],
                        [
                            -1.6076476599259593,
                            54.96644825136397
                        ],
                        [
                            -1.6090209349672762,
                            54.96754213018244
                        ],
                        [
                            -1.6084567211901515,
                            54.967774346433714
                        ],
                        [
                            -1.607040863977204,
                            54.966741584919845
                        ],
                        [
                            -1.6066576244305395,
                            54.96685158445115
                        ],
                        [
                            -1.6081480004447144,
                            54.96796378501253
                        ],
                        [
                            -1.6068385986609144,
                            54.96833043778875
                        ],
                        [
                            -1.6060082406616516,
                            54.967902676319
                        ],
                        [
                            -1.6040813973861816,
                            54.965922690479715
                        ],
                        [
                            -1.6039110686995457,
                            54.9659654689834
                        ],
                        [
                            -1.605273698197891,
                            54.96739546702298
                        ],
                        [
                            -1.6068197909345372,
                            54.96897278337991
                        ],
                        [
                            -1.606053311842004,
                            54.969314984145484
                        ],
                        [
                            -1.6062875137870378,
                            54.96960829676658
                        ],
                        [
                            -1.60564878120951,
                            54.969962713326254
                        ],
                        [
                            -1.6057765277253395,
                            54.97010325695794
                        ],
                        [
                            -1.6067559176770487,
                            54.96988938601859
                        ],
                        [
                            -1.6072030304812301,
                            54.96933942694562
                        ],
                        [
                            -1.6078614769892567,
                            54.97029698334174
                        ],
                        [
                            -1.6079572868754894,
                            54.97092025486259
                        ],
                        [
                            -1.607180481690591,
                            54.97138019097548
                        ],
                        [
                            -1.607585012323085,
                            54.971734591899434
                        ],
                        [
                            -1.606797242143898,
                            54.97226618742121
                        ],
                        [
                            -1.6065098124842905,
                            54.97300552121851
                        ],
                        [
                            -1.6063927071918442,
                            54.97389759404615
                        ],
                        [
                            -1.6062010874185262,
                            54.97424586280397
                        ],
                        [
                            -1.6057007468997426,
                            54.97432529209732
                        ],
                        [
                            -1.6040400421989034,
                            54.974227532944724
                        ],
                        [
                            -1.6041145609992782,
                            54.97460023843962
                        ],
                        [
                            -1.6046361926038912,
                            54.974673557145934
                        ],
                        [
                            -1.6049342678072662,
                            54.97493017156472
                        ],
                        [
                            -1.6054133172401635,
                            54.975101246932695
                        ],
                        [
                            -1.6058391389585154,
                            54.97546172478698
                        ],
                        [
                            -1.6055836459276804,
                            54.9763231926531
                        ],
                        [
                            -1.6048278048886289,
                            54.97797276394496
                        ],
                        [
                            -1.6045403752282255,
                            54.97876697609411
                        ],
                        [
                            -1.6048703870603163,
                            54.97948175359667
                        ],
                        [
                            -1.605711384954077,
                            54.98044698849026
                        ],
                        [
                            -1.6042210089398736,
                            54.98072189291668
                        ],
                        [
                            -1.604359400998618,
                            54.98110675595149
                        ],
                        [
                            -1.6061372066715762,
                            54.98081352730736
                        ],
                        [
                            -1.606754648163303,
                            54.98125947835584
                        ],
                        [
                            -1.6074359629124615,
                            54.98100290438529
                        ],
                        [
                            -1.6078192024591544,
                            54.98119228056942
                        ],
                        [
                            -1.6093084051319693,
                            54.981889317144294
                        ],
                        [
                            -1.611235248406615,
                            54.98250630006166
                        ],
                        [
                            -1.6117994621837397,
                            54.982518517449876
                        ],
                        [
                            -1.6120123730429157,
                            54.98227416898783
                        ],
                        [
                            -1.6117568800120523,
                            54.9813150868994
                        ],
                        [
                            -1.611703652296626,
                            54.9806308869486
                        ],
                        [
                            -1.6121294541459008,
                            54.97956179719063
                        ],
                        [
                            -1.6124488204341958,
                            54.979109718073886
                        ],
                        [
                            -1.6131194896404963,
                            54.97929910318817
                        ],
                        [
                            -1.6133536915855018,
                            54.97959845203141
                        ],
                        [
                            -1.6141229864560103,
                            54.98033649144878
                        ],
                        [
                            -1.615059794236032,
                            54.980929062499314
                        ],
                        [
                            -1.6162840316764857,
                            54.9813994477243
                        ],
                        [
                            -1.6165501702502638,
                            54.9813994477243
                        ],
                        [
                            -1.6166246890506386,
                            54.98045867176256
                        ],
                        [
                            -1.61705051102021,
                            54.97934681712567
                        ],
                        [
                            -1.6175402059960504,
                            54.978644255788055
                        ],
                        [
                            -1.6183515388443368,
                            54.97748695860179
                        ],
                        [
                            -1.61836218438728,
                            54.9773158933977
                        ],
                        [
                            -1.6189583347931205,
                            54.977242579515405
                        ],
                        [
                            -1.6198951425731138,
                            54.97667439239078
                        ],
                        [
                            -1.620831950353164,
                            54.977444192369205
                        ],
                        [
                            -1.6214387463019477,
                            54.97769467965591
                        ],
                        [
                            -1.6223542558335566,
                            54.977010419293265
                        ],
                        [
                            -1.6240362516202822,
                            54.97641168078431
                        ],
                        [
                            -1.625335007861139,
                            54.97564797074975
                        ],
                        [
                            -1.6263676255282462,
                            54.975880140136496
                        ],
                        [
                            -1.6270170034813987,
                            54.97545857113636
                        ],
                        [
                            -1.6276344449731255,
                            54.975366924523684
                        ],
                        [
                            -1.6279005835468467,
                            54.972837395465206
                        ],
                        [
                            -1.6284488174349576,
                            54.97224471868776
                        ],
                        [
                            -1.6289065757820254,
                            54.9718108881554
                        ],
                        [
                            -1.6294601440162637,
                            54.971548143441936
                        ],
                        [
                            -1.6317276446658866,
                            54.97057658410694
                        ]
                    ]
                ]
            }
        }
    ]
}
