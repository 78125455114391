import React from "react"
import Modaly from "../Modals/modal" // render the content as Modal
import { Link } from 'react-router-dom'

import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import MenuBar from "./components/MenuBar"
import Email from "../Modals/Email"
import SendPhone from "../Modals/SendPhone"

import {
  Button,
  Icon,
  Modal,
  Message,
} from "semantic-ui-react"



class Export extends React.Component {

  static propTypes = {
    isRouteCalculated: PropTypes.bool.isRequired,
    profile: PropTypes.object,
    auth: PropTypes.object,
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }),
  }

  handleCloseModal = () => {
    this.props.history.push(this.props.location.pathname);
  }


  render() {

    let params = new URLSearchParams(this.props.location.search);
    const timeNow = this.props.firebase.firestore.Timestamp.now().seconds * 1000;

    if (params.get('export') && (this.props.profile.paidDue > timeNow)) {
      return (

        (<Modaly>

          <Modal className="lp-modal-sidebox" dimmer='inverted' open='true' onClose={this.handleCloseModal} >


            <div className="sidebox--rollsection">

              <div className="sidebox__header sidebox__header--about">
                <div className="intro-header__logo intro-header__logo--left"></div>
                <h1 className="header">Export your trip</h1>
              </div>

              <div className="sidebox__body">

                <div className="sidebox--section">
                  <h3>Open in Navigation</h3>
                  <div className="sidebox-expbanner">
                    <div className="text-wrapper">
                      <ul className="features">
                        <li>Specific waypoints for your trip</li>
                        <li>Open in default navigation app</li>
                        <li>Easy use QR-code (desktop) or link (mobile)</li>
                      </ul>
                    </div>
                  </div>
                  <SendPhone />
                </div>

                <div className="sidebox--section">
                  <h3>Full Roadbook</h3>
                  <div className="sidebox-expbanner">
                    <div className="text-wrapper">
                      <ul className="features">
                        <li>Detailed description of your trip</li>
                        <li>Official zone links and registration forms</li>
                        <li>Park+Ride alternatives arround LEZs</li>
                      </ul>
                    </div>
                  </div>
                  <Email />
                </div>

              </div>

            </div>

            <MenuBar />


            {this.props.authError ?
              <Message attached='bottom' warning>
                <Icon name='help' />
                <p>Authorisation was not succesffull</p>
              </Message>
              : null}

          </Modal>

        </Modaly>

        )

      )
    } else if (params.get('export')) {
      return (
        <Modal dimmer="inverted" open className="modal__welcome-screen">
          <div className="modal__contentHolder--additionalInfo">
          <div className="welcome-screen">
            <button className="welcome-screen__close" onClick={this.handleCloseModal}><Icon name="close"></Icon></button>
            <div className="scroll-modal">
              <div className="sidebox__header sidebox__header--login">
                <div className="intro-header__logo intro-header__logo--left"></div>
                <h2 className="header">Upgrade your account...</h2>
                <p className="perex">You are currently using a demo of DRIVE2.CITY, which doesn't include all of the features. Upgrade now to access the full version and activate the export function. Upgrade to export your routes today!</p>
              </div>

              <div className="scroll-modal__button-area">
                <div className="button-place-holder">
                  <a href="https://drive2.city/purchase" target="_blank" className="button--outline button--outlineBig">Upgrade Now</a>
                </div>                
              </div>

              <div className="sidebox--section">
                <h3>From €2.49 you can unlock:</h3>

                <div className="sidebox-banner sidebox-banner--access">
                  <div className="text-wrapper">
                    <h2>Unlimited Planning</h2>
                    <p className="perex">
                      Start planning your next trip right now!                  </p>
                  </div>
                </div>

                <div className="sidebox-banner sidebox-banner--roadbook">
                  <div className="text-wrapper">
                    <h2>Exports</h2>
                    <p className="perex">
                      Detailed roadbook and waypoints to export
                    </p>
                  </div>
                </div>

                <div className="sidebox-banner sidebox-banner--vehicles">
                  <div className="text-wrapper">
                    <h2>Vehicle Profiles</h2>
                    <p className="perex">
                      Save up to 3 vehicle configurations
                    </p>
                  </div>
                </div>



                <p className="perex2">The idea of DRIVE2.CITY came in 2019, after getting lost in Berlin's zone.😅
                  Since then, our routing engine has delivered more than 1.000.000 km of low-emission compliant routes.
                  Your symbolic contribution will help us to maintain the project and to explore new approaches in car mobility.</p>

                <h3>Thank you for your support!</h3>
                <h2>❤</h2>
                <p className="perex2">Tomas, the founder of DRIVE2.CITY</p>

              </div>


            </div>

          </div>
          <p className="modal__additionalInfo">
          Already purchased? Go to <Link to={{ pathname: "/", search: "?login=true" }} >login →</Link>
          </p>
          </div>
        </Modal>
      )
    } else {
      return (<div></div>)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isRouteCalculated: state.resultHandler.isRouteCalculated,
    auth: state.firebase.auth,
    authError: state.firebase.authError,
    profile: state.firebase.profile
  }
}


export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(Export)