import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { getDistanceFromLatLon } from "../../helpers/getDistanceFromLatLon"
import { onlyCityAdress } from "../../helpers/onlyCityAdress"

import {
  Icon,
  Button
} from "semantic-ui-react"

import {
  changeMarkerSwitchPoint,
  selectCityTransport,
  deleteCalculatedRoute,
  resultHandler,
  zoomSelectedZone,
} from "../../actions/actions"



const cityTransportOptions = {
  transit: 'train',
  bicycling: 'bicycle',
  walking: 'male',
};

// Check if the website is being opened on a mobile device
function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}


class ResultMultimodal extends React.Component {
  static propTypes = {
    isRouteCalculated: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isMultimodeOpen: false,
      transportMode: 'public',
      transportIco: 'bus',
      isMobile: false

    }
    // binding this to the handleSearchChange method
    this.handleZoomZone = this.handleZoomZone.bind(this);
    this.updateButtonTitle = this.updateButtonTitle.bind(this);
  }


  componentDidMount() {
    
    // Update the button title based on the device type
    this.updateButtonTitle();

    // Call the function whenever the window is resized
    window.addEventListener("resize", this.updateButtonTitle);
  }

  componentWillUnmount() {
    // Clean up the event listener on component unmount
    window.removeEventListener("resize", this.updateButtonTitle);
  }

  updateButtonTitle() {
    this.setState({ isMobile: isMobileDevice() });
  }


  handleZoomZone(focusPoint) {
    const { dispatch, selectedTarget } = this.props;
    if (focusPoint == 0 && selectedTarget) {
      const targetPoint = [selectedTarget.lat, selectedTarget.lng];
      dispatch(
        zoomSelectedZone({
          passFocusPoint: targetPoint
        })
      );
    } else {
      dispatch(
        zoomSelectedZone({
          passFocusPoint: focusPoint
        })
      );
    }
  }



  render() {
    const distance = (this.props.computedDirectionInfos.distance / 1000).toFixed(0) + ' km';
    const targetCity = onlyCityAdress(this.props.userInputTarget)
    const { isMobile } = this.state;


    if (this.props.isRouteCalculated && this.props.pointInZone && this.props.routeZones.length > 0) {
      // CASE no.1: Zona detekovana, cil uvnitr zony
      return (

        <div>
          <div className="cp-iterinary_header">
            <h2><Icon name="flag checked"></Icon> {distance}</h2>
            <Button as={Link} size='small' to={{ pathname: "/", search: "?export=true" }}>
              {isMobile ? "Start driving" : "Send to phone"}
            </Button>
          </div>

          <div className="cp-iterinary__result">
            {this.props.routeZones.map((itemZone, i) => {
              return (
                <div key={i} className="cp-iterinary">
                  <button onClick={this.handleZoomZone.bind(this, itemZone.waypointsDirection[1])} className="cp-iterinary__item">
                    <div className="iterinary-item__ico-wrapper">
                      <span className="iterinary-ico">
                        <Icon name='warning sign' className="iterinary-ico__symbol" />
                      </span>
                    </div>
                    <div className="iterinary-item__text-wrapper">
                      <h3 className="iterinary-item__info-main">{itemZone.selectedCityZone} {itemZone.selectedCityName}</h3>
                      {itemZone.selectedCityZoneInfo.map((item, i) => {
                        return (
                          <div key={i} className="iterinary-item__info-sub">{item}</div>
                        )
                      })}
                    </div>
                  </button>
                </div>
              )
            })
            }
            <div className="cp-iterinary cp-iterinary--red">
              <button onClick={this.handleZoomZone.bind(this, 0)} className="cp-iterinary__item">
                <div className="iterinary-item__ico-wrapper">
                  <span className="iterinary-ico">
                    <Icon name='pin' className="iterinary-ico__symbol" />
                  </span>
                </div>
                <div className="iterinary-item__text-wrapper">
                  <h3 className="iterinary-item__info-main">Destination inside the zone!</h3>
                  <span className="iterinary-item__info-sub">Your car is not allowed to enter this zone area!</span>
                </div>
              </button>
            </div>
          </div>


        </div>

      )
    } else if (this.props.isRouteCalculated && this.props.routeZones.length > 0) {
      // CASE no.2: Zona detekovana, cil mimo zonu
      return (

        <div>
          <div className="cp-iterinary_header">
            <h2><Icon name="flag checked"></Icon> {distance}</h2>
            <Button as={Link} size='small' to={{ pathname: "/", search: "?export=true" }}>
              {isMobile ? "Start driving" : "Send to phone"}
            </Button>
          </div>

          <div className="cp-iterinary__result">
            {this.props.routeZones.map((itemZone, i) => {
              return (
                <div key={i} className="cp-iterinary">
                  <button onClick={this.handleZoomZone.bind(this, itemZone.waypointsDirection[1])} className="cp-iterinary__item">
                    <div className="iterinary-item__ico-wrapper">
                      <span className="iterinary-ico">
                        <Icon name='warning sign' className="iterinary-ico__symbol" />
                      </span>
                    </div>
                    <div className="iterinary-item__text-wrapper">
                      <h3 className="iterinary-item__info-main">{itemZone.selectedCityZone} {itemZone.selectedCityName}</h3>

                      {itemZone.selectedCityZoneInfo.map((item, i) => {
                        return (
                          <div key={i} className="iterinary-item__info-sub">{item}</div>
                        )
                      })}
                    </div>
                  </button>
                </div>
              )
            })
            }
          </div>

        </div>

      );
    } else if (this.props.isRouteCalculated && this.props.routeZones.length == 0) {
      //CASE no. 3: zony nejsou detekovany
      return (

        <div>
          <div className="cp-iterinary_header">
            <h2><Icon name="flag checked"></Icon> {distance}</h2>
            <Button as={Link} size='small' to={{ pathname: "/", search: "?export=true" }}>
              {isMobile ? "Start driving" : "Send to phone"}
            </Button>
          </div>

          <div className="cp-iterinary cp-iterinary--green">
            <div className="cp-iterinary__item">
              <div className="iterinary-item__ico-wrapper">
                <span className="iterinary-ico">
                  <Icon name='check' className="iterinary-ico__symbol" />
                </span>
              </div>
              <div className="iterinary-item__text-wrapper">
                <h3 className="iterinary-item__info-main">No restrictions on the route!</h3>
                <div className="iterinary-item__info-sub">Vignettes or online registrations can be still needed, check before you go!</div>
              </div>
            </div>
          </div>

        </div>

      );
    } else {
      return (<div></div>);
    }
  }
}



const mapStateToProps = state => {
  const isRouteCalculated = state.routeControls.isRouteCalculated
  const pointInZone = state.routeControls.pointInZone
  const computedDirectionInfos = state.routeControls.computedDirectionInfos
  const switchPointAdress = state.routeControls.switchPointAdress
  const selectedCityTransport = state.routeControls.selectedCityTransport
  const selectedCityTransportIco = state.routeControls.selectedCityTransportIco
  const selectedTarget = state.routeControls.selectedTarget
  const selectedCity = state.routeControls.selectedCity
  const switchPointZone = state.routeControls.switchPointZone
  const decodedDirection = state.routeControls.decodedDirection
  const routeZones = state.routeControls.routeZones
  const userInputTarget = state.routeControls.userInputTarget

  return {
    isRouteCalculated,
    computedDirectionInfos,
    switchPointAdress,
    pointInZone,
    selectedCityTransport,
    selectedCityTransportIco,
    selectedCity,
    selectedTarget,
    switchPointZone,
    decodedDirection,
    routeZones,
    userInputTarget
  }
}

export default connect(mapStateToProps)(ResultMultimodal)