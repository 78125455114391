import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from 'react-promise-loader';

class Spinner extends Component {
    state = {  }


    render() { 
        const {spinner} = this.props;

        if(!spinner) return null;

        return ( 
            <div class="loader-container">                                   
              <Loader loading={true} color={'#204fa4'} background={'rgba(255,255,255,.5)'} />
            </div>
         );
    }
}


const mapStateToProps = (state) => {
    return { 
      spinner: state.application.spinner,
    }
  }

export default connect(mapStateToProps)(Spinner);