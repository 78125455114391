import React from "react"
import PropTypes from "prop-types"
import { withRouter } from 'react-router'
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'


// we are importing some of the beautiful semantic UI react components
import {
    Dropdown,
    Button,
    Icon
} from "semantic-ui-react"


// dont forget to import actions here and dobounce
import {
    deactivateMyCar,
    selectVehicle,
    fetchD2cDirections,
    updateVehicleSelect,
    resultHandler
} from "../../../actions/actions"

// to wait for the users input we will add debounce, this is especially useful for "postponing" the geocode requests
import { debounce } from "throttle-debounce"


// some inline styles (we should move these to our index.css at one stage)

class VehicleSelect extends React.Component {
    static propTypes = {
        userInputStart: PropTypes.string.isRequired,
        userInputTarget: PropTypes.string.isRequired,
        isFetchingStart: PropTypes.bool.isRequired,
        isFetchingTarget: PropTypes.bool.isRequired,
        resultsStart: PropTypes.array.isRequired,
        resultsTarget: PropTypes.array.isRequired,
        dispatch: PropTypes.func.isRequired,
        isRouteLoading: PropTypes.bool.isRequired,
        isRouteCalculated: PropTypes.bool.isRequired,
        timestamp: PropTypes.bool.isRequired,
        profile: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        })
    }

    constructor(props) {
        super(props)
        // binding this to the handleSearchChange method
        this.handleSelectVehicle = this.handleSelectVehicle.bind(this)
    }



    handleSelectVehicle = (e, { value }) => {
        const { dispatch, mode, selectedStart, selectedTarget, selectedVehicle, isRouteCalculated } = this.props;
        //dispatch(
        //  deleteCalculatedRoute()
        //)
        dispatch(
            selectVehicle({
                passVehicle: value
            })
        );
        dispatch(
            deactivateMyCar()
        );
    };


    handleSelectMyVehicle = (e) => {
        const { dispatch, mode, selectedStart, selectedTarget, selectedVehicle, isRouteCalculated } = this.props;
        if (this.props.auth.uid && this.props.profile.vehicle.length) {
            let activeVehicle = this.props.profile.vehicleActive;
            let activeVehicleNorm = this.props.profile.vehicle[activeVehicle].norm;
            let numberNorm = Math.round(activeVehicleNorm) - 1
            dispatch(
                updateVehicleSelect({
                    passValue: numberNorm
                })
            );            
        } else if (this.props.auth.uid && !this.props.profile.vehicle.length) {
            
            this.props.history.push("/?my-car=true");
        } else if (!this.props.auth.uid) {
            dispatch(
                resultHandler({
                    handlerCode: 'SELECT_VEHICLE',
                })
            );
        }
    };

    handleClickSelectVehicle = (e) => {
        window.alert("Zmacknuto select vehicle")
    }


    handleManageMyVehicle = (e) => {
        const { dispatch } = this.props;
        if (this.props.auth.uid) {
            this.props.history.push("/?my-car=true");
        } else if (!this.props.auth.uid) {
            dispatch(
                resultHandler({
                    handlerCode: 'SELECT_VEHICLE',
                })
            );
        }
    }

    updateRoute(prevProps) {
        const { dispatch, mode, selectedStart, selectedTarget, selectedVehicle, isRouteCalculated } = this.props;
        if (prevProps.selectedVehicle !== selectedVehicle && selectedStart.length != 0 && selectedTarget.length != 0 && isRouteCalculated) {
            dispatch(
                fetchD2cDirections({
                    coordStart: (selectedStart.lat + "," + selectedStart.lng),
                    coordZiel: (selectedTarget.lat + "," + selectedTarget.lng),
                    selectedVehicle: selectedVehicle,
                    APImode: mode
                })
            )
        }
    };

    componentDidUpdate(prevProps) {
        this.updateRoute(prevProps)
    }


    render() {

        return (            

                <Dropdown
                    className="dd-vehicle-selector"
                    fluid
                    selection
                    options={this.props.vehicleOptions}
                    value={this.props.selectedVehicle}
                    //placeholder="Select vehicle..."
                    onChange={this.handleSelectVehicle}                    
                    //onClick={this.handleClickSelectVehicle}
                >

                    {/* <Dropdown.Menu>
                          <Input />
                          <Dropdown.Divider />
                          <Dropdown.Header content="Vyberte skupinu" />
                          <Dropdown.Menu scrolling>
                              {this.props.vehicleOptions.map((option) => (
                                  <Dropdown.Item key={option.value} {...option} />
                              ))}
                          </Dropdown.Menu>
                      </Dropdown.Menu>
                                */}
                </Dropdown>

                /*
                <div className="cp-header-under">
                    <Button attached='left' onClick={this.handleSelectMyVehicle}>My Vehicle</Button>
                    <Button attached='right' icon='cog' onClick={this.handleManageMyVehicle} />
                </div>
                */
            
        )

    }

}


const mapStateToProps = state => {
    const selectedStart = state.routeControls.selectedStart
    const selectedTarget = state.routeControls.selectedTarget
    const vehicleOptions = state.routeControls.vehicleOptions
    const selectedVehicle = state.routeControls.selectedVehicle
    const selectedVehicleOption = state.routeControls.selectedVehicleOption
    const isRouteCalculated = state.routeControls.isRouteCalculated
    const mode = state.routeControls.mode
    const profile = state.firebase.profile
    const auth = state.firebase.auth

    return {
        selectedStart,
        selectedTarget,
        vehicleOptions,
        selectedVehicle,
        selectedVehicleOption,
        isRouteCalculated,
        profile,
        auth,
        mode
    }
}


export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
    withRouter
)(VehicleSelect)