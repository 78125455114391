import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"


import {
    deleteCalculatedRoute,
    unsetDefualtVehicle,
    resultHandler,
} from "../../../actions/actions"



class ClearBackButton extends React.Component {
    static propTypes = {
        isRouteCalculated: PropTypes.bool.isRequired,
    }    

    handleDeleteCalculatedRoute = () => {
        const { dispatch } = this.props;
        dispatch(
            deleteCalculatedRoute()
        )
    }

    handleSetVehicleBack = () => {
        const { dispatch } = this.props;
        dispatch(
            unsetDefualtVehicle()
        )
    }

    render () {
        if (this.props.isRouteCalculated) {
            return (
                <button className="buttons button--back" onClick={this.handleDeleteCalculatedRoute} >clear</button>
            )
        } else {
            return (
                <button className="buttons button--back" onClick={this.handleSetVehicleBack} >back</button>
            )
        }
        
    }


}

const mapStateToProps = state => {    
    const isRouteCalculated = state.routeControls.isRouteCalculated
    
      return {        
        isRouteCalculated
      }
  }

  export default connect(mapStateToProps)(ClearBackButton)
