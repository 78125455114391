import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from "redux-firestore"

import applicationReducer from "./application";
import routingReducer from "./routing";
import resultHandlerReducer from "./resulthandler";


// creates a root reducer and combines different reducers if needed
const rootReducer = combineReducers({
    application: applicationReducer,
    resultHandler: resultHandlerReducer,
    routeControls: routingReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,    
  })
  
  export default rootReducer