import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { resultHandler } from "../../../actions/actions"


class VehicleCard extends React.Component {

    state = {
        vehiclesArray: '',
        vehicleEdit: '',
    }

    static propTypes = {
        noButtons: PropTypes.bool,
        carDetail: PropTypes.object,
        countId: PropTypes.object,
        profile: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        }),
    }


    handleDeleteCar = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        let carList = this.props.profile.vehicle;
        let itemToDelete = this.props.countId;
        carList.splice(itemToDelete, 1)
        let activeItem = this.props.profile.vehicleActive;
        if (itemToDelete < activeItem) {
            var newActiveItem = activeItem - 1;
        } else if (activeItem == 0) {
            var newActiveItem = activeItem;
        } else if (activeItem == itemToDelete) {
            var newActiveItem = 0;
        }
        this.props.firebase.updateProfile({
            vehicle: carList,
            vehicleActive: newActiveItem,
        }).then(() => {
            dispatch(
                resultHandler({
                    handlerCode: 'PROFILE_UPDATE_SUCCESS',
                })
            );
        }).catch((error) => {
            dispatch(
                resultHandler({
                    handlerCode: 'PROFILE_UPDATE_ERROR',
                    handlerMessage: '...' + error,
                })
            );
        });
        // upravit i ActivateCar pokud itemToDelete < activeItem
    }


    handleActivateCar = (e) => {
        e.preventDefault();
        let itemToActivate = this.props.countId;
        const { dispatch } = this.props;
        this.props.firebase.updateProfile({
            vehicleActive: itemToActivate
        }).then(() => {
            dispatch(
                resultHandler({
                    handlerCode: 'VEHICLE_ACTIVATED',
                })
            );
        }).catch((error) => {
            dispatch(
                resultHandler({
                    handlerCode: 'PROFILE_UPDATE_ERROR',
                    handlerMessage: '...' + error,
                })
            );
        });        
    }


    render() {

        if (this.props.carDetail) {
        return (
            <div className={this.props.noButtons ? "card-my-vehicle" : "card-my-vehicle card-my-vehicle--active"}>
                {(this.props.profile.vehicleActive == this.props.countId) && <div className="card-my-vehicle__active-badge">DEFAULT</div>}
                <a className="card-my-vehicle__text-wrapper" onClick={this.props.noButtons ? null : this.handleActivateCar}>
                    <h2>{this.props.carDetail.make + " " + this.props.carDetail.model + " (" + this.props.carDetail.year + ")"}</h2>
                    <div className="my-vehicle__highlights">
                        <div className="highlight highlight--fuel">{this.props.carDetail.fuel}</div>
                        <div className="highlight highlight--norm">{"EURO-" + this.props.carDetail.norm}</div>
                        
                    </div>
                </a>
                <div className="card-my-vehicle__button-area">                
                    <button hidden={this.props.noButtons} onClick={this.handleDeleteCar} className="buttons button--delete">clear</button>
                </div>
            </div>
        )} else {
            return (<div></div>)
        };
    };
}


const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}


export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(VehicleCard)