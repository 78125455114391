module.exports = {

    
    onlyCityAdress: function (fullAdress) {
    
                
        const adress = fullAdress;    
        const splittedAdress = adress.toString().split(", "); 
        if (splittedAdress[1]) {
            var city = splittedAdress[1].replace(/\d+/g, '');
        }  

        return city;
    }
    
}