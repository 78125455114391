import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'

import {
  Button,
  Modal,
  Icon,
  Segment
} from "semantic-ui-react";

// dont forget to import actions here and dobounce
import {
  updateInputTarget,
  inputSelectedTarget,
  showWarningLez,
  requestMarkerTarget,
  resultHandler
} from "../../actions/actions"

// some inline styles (we should move these to our index.css at one stage)
const segmentLEZstyle = {
  zIndex: 999,
  position: "absolute",
  width: "360px",
  top: "20px",
  left: "360px",
  maxHeight: "calc(100vh - 5vw)",
  overflow: "auto",
  padding: "15px"
};


class ZoneModal extends React.Component {

  state = {
    modalOpen: true
  }


  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  }

  handleChangeTarget = () => {
    const { dispatch } = this.props;
    dispatch(
      updateInputTarget({
        inputValue: '' //doladit i dalsi veci, vymazani ze souradnic
      })
    );
    dispatch(
      inputSelectedTarget({
        passValue: [], //vymazani ze souradnic
      })
    );
    dispatch(
      showWarningLez({
        passValue: false, //vymazani warning okna v zone
      })
    );
    dispatch(
      requestMarkerTarget() //zde je to zjednoduseno
    );
    dispatch(
      updateInputTarget({
        inputValue: 'Pick Your TARGET!' //to musi ale prijit do MAP, event handleru!!
      })
    );
    dispatch(
      resultHandler({
        handlerCode: 'ALERT_CHANGE_TARGET',
      })
    );
    this.setState({ modalOpen: true });
  }



  render() {

    if (this.props.pointInZone) {
      if (this.state.modalOpen) {
        return (

          <Modal dimmer="inverted" open={this.state.modalOpen} onClose={this.handleCloseModal} className="modal__welcome-screen">

            <div className="welcome-screen">

              <button className="welcome-screen__close" onClick={this.handleCloseModal}><Icon name="close"></Icon></button>

              <div className="welcome-screen__header">
                <Icon name="warning sign" className="welcome-screen__logo"></Icon>
                <h1 className="welcome-screen__headline">{this.props.selectedCityZone} {this.props.selectedCityName}</h1>
              </div>

              <p className="welcome-screen__msg">
                Pay attention to the following zone regulations:
              </p>

              <div className="welcome-screen__email-wrapper">
                {this.props.selectedCityZoneInfo.map((item, i) => {
                  return (
                    <p>{item}</p>
                  )
                })}
              </div>

              <div className="welcome-screen__button-two">
                <Button className="button-send-email" onClick={this.handleCloseModal}>Continue</Button>
                <Button className="button-send-email button-send-email--outline" onClick={this.handleChangeTarget}>Change destination</Button>
              </div>

            </div>

          </Modal>

        )
      } else {
        return (
          <div className="alertBtn">
            <Button basic color='green' icon="warning circle" onClick={this.handleOpenModal}></Button>
          </div>
        )
      }
    } else {
      return (<div></div>);
    }
  }
}



const mapStateToProps = state => {
  const pointInZone = state.routeControls.pointInZone
  const selectedCityZone = state.routeControls.selectedCityZone
  const selectedCityName = state.routeControls.selectedCityName
  const selectedCityZoneInfo = state.routeControls.selectedCityZoneInfo

  return {
    pointInZone,
    selectedCityZone,
    selectedCityName,
    selectedCityZoneInfo
  }
}


export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(ZoneModal)