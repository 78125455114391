import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {  
    Icon,
    Button,  
  } from "semantic-ui-react"





class MenuBar extends Component {
    
            
      handleBack = () => {
        this.props.history.goBack();
      }

      handleClose = () => {
        this.props.history.push(this.props.location.pathname);
      }

    render() { 
        return ( 
            <div className="menubar-sidebox">
                <button onClick={this.handleBack} className="buttons button--back" >back</button>
                <button onClick={this.handleClose} className="buttons button--close" >close</button>
            </div>
         );
    }
}
 

  
export default withRouter(MenuBar)
