import React from "react"
import { Link } from 'react-router-dom'

import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import {
    Icon
} from "semantic-ui-react"


// dont forget to import actions here and dobounce
import {
    resultHandler,
    userLogged
} from "../../actions/actions"

const popUpStyle = {
    width: "380px",
};


class LoginForm extends React.Component {
    state = {
        email: '',
        password: '',
    }

    static propTypes = {
        auth: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        }),
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        const { dispatch } = this.props;
        e.preventDefault();

        this.props.firebase.login(this.state).then((user) => {
            console.log(user);

            if (user.user.user.emailVerified === true) {
                dispatch(
                    resultHandler({
                        handlerCode: 'LOGIN_SUCCESS_EMAIL',
                    })
                );
                dispatch(
                    userLogged()
                );
            } else {
                this.props.firebase.logout();
                dispatch(
                    resultHandler({
                        handlerCode: 'INVALID_CREDENTIALS',
                        handlerMessage: 'not verified email',
                    })
                )
            }
        }).catch((error) => {
            dispatch(
                resultHandler({
                    handlerCode: 'INVALID_CREDENTIALS',
                    handlerMessage: '...' + error,
                })
            )
            console.log("chyba, neco selhalo");
        });
    }

    render() {


        return (

            <div className="intro-login-box">

                <div className="intro-login-box__inner">

                    <button className="button button-google"
                        onClick={
                            () => this.props.firebase.login({ provider: 'google', type: 'popup' }).then((resp) => {
                                console.log("prihalseni probehlo ok: "+JSON.stringify(resp));


                                const { dispatch } = this.props;
                                dispatch(
                                    resultHandler({
                                        handlerCode: 'LOGIN_SUCCESS_GMAIL',
                                    })
                                );
                                dispatch(
                                    userLogged()
                                );
                            }).catch((error) => {
                                const { dispatch } = this.props;
                                dispatch(
                                    resultHandler({
                                        handlerCode: 'INVALID_CREDENTIALS',
                                        handlerMessage: '...' + error,
                                    })
                                )
                            })}
                    >
                        <Icon name="google" />
                        &nbsp;&nbsp;&nbsp;Login with Google
                    </button>


                    <div className="intro-login-box__divider">
                        <span>or login with email</span>
                    </div>

                    <form className="intro-login-box__form">

                        <input type="text" name="email" className="textinput" onChange={this.handleChange} placeholder="E-mail" ></input>
                        <input type="password" name="password" className="textinput" onChange={this.handleChange} placeholder='Password' ></input>

                        <a className="intro-login-box__password" href="https://drive2.city/app/password-forgotten">Forgot your password?</a>

                        <button className="button button-login" type='submit' onClick={this.handleSubmit}>Login</button>

                        <p className="intro-login-box__consent">By continuing you indicate that you've read and agree to <a href="https://drive2.city/terms-of-service/" target="_blank">Terms of Service</a> and <a href="https://drive2.city/privacy-policy/" target="_blank">Privacy Policy</a>.</p>

                    </form>
                </div>

                <a href="https://drive2.city/app/register" target="_blank" className="intro-login-box__button-bottom">Don't have an account?</a>

            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.firebase.authError,
        profile: state.firebase.profile
    }
}


export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(LoginForm)