import React from "react"

import {
  Button,
  Modal,
  Icon,
  Flag
} from "semantic-ui-react";

const dataLEZ = [
  {
    "country": "de",
    "city": "Balingen",
    "state": "lifted up",
    "last_udpate": "01.11.2020"
  },
  {
    "country": "de",
    "city": "Freiburg",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Heidelberg",
    "state": "lifted up",
    "last_udpate": "01.03.2023"
  },
  {
    "country": "de",
    "city": "Heidenheim",
    "state": "lifted up",
    "last_udpate": "01.01.2024"
  },
  {
    "country": "de",
    "city": "Heilbronn",
    "state": "lifted up",
    "last_udpate": "01.01.2024"
  },
  {
    "country": "de",
    "city": "Herrenberg",
    "state": "lifted up",
    "last_udpate": "01.01.2024"
  },
  {
    "country": "de",
    "city": "Ilsfeld",
    "state": "lifted up",
    "last_udpate": "01.05.2023"
  },
  {
    "country": "de",
    "city": "Karlsruhe",
    "state": "lifted up",
    "last_udpate": "01.03.2023"
  },
  {
    "country": "de",
    "city": "Leonberg / Hemmingen und Umgebung(Ditzingen, ...",
    "state": "lifted up",
    "last_udpate": " 01.01.2024"
  },
  {
    "country": "de",
    "city": "Ludwigsburg und Umgebung(Asperg, ...",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Mannheim",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "M\u00fchlacker",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Pfinztal",
    "state": "lifted up",
    "last_udpate": "01.03.2023"
  },
  {
    "country": "de",
    "city": "Pforzheim",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Reutlingen",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Schramberg",
    "state": "lifted up",
    "last_udpate": "01.03.2023"
  },
  {
    "country": "de",
    "city": "Schw\u00e4bisch Gm\u00fcnd",
    "state": "lifted up",
    "last_udpate": "01.05.2023"
  },
  {
    "country": "de",
    "city": "Stuttgart",
    "state": "ok",
    "last_udpate": "01.01.2019"
  },
  {
    "country": "de",
    "city": "Stuttgart - Abschnitt \"B14 (Am Neckartor)\"",
    "state": "ok",
    "last_udpate": "01.01.2020"
  },
  {
    "country": "de",
    "city": "Stuttgart - Abschnitt \"B14 (Hauptst\u00e4tter Stra\u00dfe)\"",
    "state": "ok",
    "last_udpate": "01.01.2020"
  },
  {
    "country": "de",
    "city": "Stuttgart - Abschnitt \"B27 (Charlottenstra\u00dfe, Hohenheimer Stra\u00dfe, Neue Weinsteige)\"",
    "state": "ok",
    "last_udpate": "01.01.2020"
  },
  {
    "country": "de",
    "city": "Stuttgart - Abschnitt \"B27 (Heilbronner Stra\u00dfe)\"",
    "state": "ok",
    "last_udpate": "01.01.2020"
  },
  {
    "country": "de",
    "city": "T\u00fcbingen",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Ulm",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Urbach",
    "state": "lifted up",
    "last_udpate": "01.05.2023"
  },
  {
    "country": "de",
    "city": "Wendlingen",
    "state": "lifted up",
    "last_udpate": "01.05.2023"
  },
  {
    "country": "de",
    "city": "Augsburg",
    "state": "ok",
    "last_udpate": "01.06.2016"
  },
  {
    "country": "de",
    "city": "M\u00fcnchen",
    "state": "ok",
    "last_udpate": "01.02.2023"
  },
  {
    "country": "de",
    "city": "Neu-Ulm",
    "state": "ok",
    "last_udpate": "01.11.2009"
  },
  {
    "country": "de",
    "city": "Regensburg",
    "state": "ok",
    "last_udpate": "15.01.2018"
  },
  {
    "country": "de",
    "city": "Berlin",
    "state": "ok",
    "last_udpate": "01.01.2010"
  },
  {
    "country": "de",
    "city": "Berlin - Abschnitt \"Alt-Moabit\"",
    "state": "lifted up",
    "last_udpate": "01.09.2022"
  },
  {
    "country": "de",
    "city": "Berlin - Abschnitt \"Br\u00fcckenstra\u00dfe, Jannowitzbr\u00fccke\"",
    "state": "lifted up",
    "last_udpate": "01.09.2021"
  },
  {
    "country": "de",
    "city": "Berlin - Abschnitt \"Friedrichstra\u00dfe\"",
    "state": "lifted up",
    "last_udpate": "01.09.2021"
  },
  {
    "country": "de",
    "city": "Berlin - Abschnitt \"Hermannstra\u00dfe\"",
    "state": "lifted up",
    "last_udpate": "01.09.2022"
  },
  {
    "country": "de",
    "city": "Berlin - Abschnitt \"Leipziger Stra\u00dfe\"",
    "state": "lifted up",
    "last_udpate": "01.09.2022"
  },
  {
    "country": "de",
    "city": "Berlin - Abschnitt \"Reinhardtstra\u00dfe\"",
    "state": "lifted up",
    "last_udpate": "01.09.2021"
  },
  {
    "country": "de",
    "city": "Berlin - Abschnitt \"Silbersteinstra\u00dfe\"",
    "state": "lifted up",
    "last_udpate": "01.09.2022"
  },
  {
    "country": "de",
    "city": "Berlin - Abschnitt \"Stromstra\u00dfe\"",
    "state": "lifted up",
    "last_udpate": "01.09.2021"
  },
  {
    "country": "de",
    "city": "Bremen",
    "state": "ok",
    "last_udpate": "01.07.2011"
  },
  {
    "country": "de",
    "city": "Hamburg - Abschnitt \"Max-Brauer-Allee\"",
    "state": "ok",
    "last_udpate": "31.05.2018"
  },
  {
    "country": "de",
    "city": "Hamburg - Abschnitt \"Stresemannstra\u00dfe\"",
    "state": "ok",
    "last_udpate": "31.05.2018"
  },
  {
    "country": "de",
    "city": "Darmstadt",
    "state": "ok",
    "last_udpate": "01.11.2015"
  },
  {
    "country": "de",
    "city": "Darmstadt - Abschnitt \"Heinrichstra\u00dfe\"",
    "state": "ok",
    "last_udpate": "01.06.2019"
  },
  {
    "country": "de",
    "city": "Darmstadt - Abschnitt \"H\u00fcgelstra\u00dfe\"",
    "state": "ok",
    "last_udpate": "01.06.2019"
  },
  {
    "country": "de",
    "city": "Frankfurt a.M.",
    "state": "ok",
    "last_udpate": "01.01.2012"
  },
  {
    "country": "de",
    "city": "Limburg an der Lahn",
    "state": "ok",
    "last_udpate": "31.01.2018"
  },
  {
    "country": "de",
    "city": "Marburg",
    "state": "ok",
    "last_udpate": "01.04.2016"
  },
  {
    "country": "de",
    "city": "Offenbach",
    "state": "ok",
    "last_udpate": "01.01.2015"
  },
  {
    "country": "de",
    "city": "Wiesbaden",
    "state": "ok",
    "last_udpate": "01.02.2013"
  },
  {
    "country": "de",
    "city": "Hannover",
    "state": "ok",
    "last_udpate": "01.01.2010"
  },
  {
    "country": "de",
    "city": "Osnabr\u00fcck",
    "state": "ok",
    "last_udpate": "03.01.2012"
  },
  {
    "country": "de",
    "city": "Aachen",
    "state": "ok",
    "last_udpate": "01.02.2016"
  },
  {
    "country": "de",
    "city": "Bonn",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "Dinslaken",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "D\u00fcsseldorf",
    "state": "ok",
    "last_udpate": "01.10.2012"
  },
  {
    "country": "de",
    "city": "Eschweiler",
    "state": "ok",
    "last_udpate": "01.06.2016"
  },
  {
    "country": "de",
    "city": "Hagen",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "K\u00f6ln",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "Krefeld",
    "state": "ok",
    "last_udpate": "01.07.2012"
  },
  {
    "country": "de",
    "city": "Langenfeld",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "M\u00f6nchengladbach",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "M\u00fcnster",
    "state": "ok",
    "last_udpate": "01.01.2015"
  },
  {
    "country": "de",
    "city": "Neuss",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "Overath",
    "state": "ok",
    "last_udpate": "01.10.2017"
  },
  {
    "country": "de",
    "city": "Remscheid",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "Ruhrgebiet(Bochum, Bottrop, Castrop-Rauxel, ...",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "Siegen",
    "state": "ok",
    "last_udpate": "01.01.2015"
  },
  {
    "country": "de",
    "city": "Wuppertal",
    "state": "ok",
    "last_udpate": "01.07.2014"
  },
  {
    "country": "de",
    "city": "Mainz",
    "state": "ok",
    "last_udpate": "01.02.2013"
  },
  {
    "country": "de",
    "city": "Leipzig",
    "state": "ok",
    "last_udpate": "01.03.2011"
  },
  {
    "country": "de",
    "city": "Halle (Saale)",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Magdeburg",
    "state": "ok",
    "last_udpate": "01.01.2013"
  },
  {
    "country": "de",
    "city": "Erfurt",
    "state": "lifted up",
    "last_udpate": "21.06.2021"
  },
  {
    "country": "dk",
    "city": "Copenhagen",
    "state": "ok",
    "last_udpate": "01.10.2023"
  },
  {
    "country": "dk",
    "city": "Aarhus",
    "state": "ok",
    "last_udpate": "01.10.2023"
  },
  {
    "country": "dk",
    "city": "Odense",
    "state": "ok",
    "last_udpate": "01.10.2023"
  },
  {
    "country": "dk",
    "city": "Aalborg",
    "state": "ok",
    "last_udpate": "01.10.2023"
  },
  {
    "country": "fr",
    "city": "Eurom\u00e9tropole de Strasbourg",
    "state": "ok",
    "last_udpate": "01.01.2024"
  },
  {
    "country": "fr",
    "city": "Grenoble-Alpes M\u00e9tropole",
    "state": "ok",
    "last_udpate": "01.07.2024"
  },
  {
    "country": "fr",
    "city": "M\u00e9tropole d'Aix-Marseille-Provence",
    "state": "ok",
    "last_udpate": "01.09.2023"
  },
  {
    "country": "fr",
    "city": "M\u00e9tropole du Grand Nancy ",
    "state": "alarm",
    "last_udpate": "01.06.2021"
  },
  {
    "country": "fr",
    "city": "M\u00e9tropole du Grand-Paris",
    "state": "ok",
    "last_udpate": "01.06.2021"
  },
  {
    "country": "fr",
    "city": "M\u00e9tropole Nice C\u00f4te d'Azur",
    "state": "ok",
    "last_udpate": "01.01.2024"
  },
  {
    "country": "fr",
    "city": "M\u00e9tropole Rouen Normandie",
    "state": "ok",
    "last_udpate": "31.08.2023"
  },
  {
    "country": "fr",
    "city": "Montpellier M\u00e9diterran\u00e9e M\u00e9tropole",
    "state": "ok",
    "last_udpate": "01.06.2023"
  },
  {
    "country": "fr",
    "city": "Toulouse M\u00e9tropole",
    "state": "ok",
    "last_udpate": "01.01.2024"
  },
  {
    "country": "fr",
    "city": "Lyon",
    "state": "ok",
    "last_udpate": "01.01.2024"
  },
  {
    "country": "fr",
    "city": "Lille",
    "state": "alarm",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "fr",
    "city": "Reims",
    "state": "ok",
    "last_udpate": "01.01.2023"
  },
  {
    "country": "fr",
    "city": "Saint-Etienne",
    "state": "trucks",
    "last_udpate": "31.01.2022"
  },
  {
    "country": "it",
    "city": "Milan",
    "state": "ok",
    "last_udpate": "01.10.2022"
  },
  {
    "country": "nl",
    "city": "Amsterdam",
    "state": "ok",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Arnhem",
    "state": "ok",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Utrecht",
    "state": "ok",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Den Haag",
    "state": "ok",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Breda",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Delft",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Eindhoven",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Leiden",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Maastricht",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Rijswijk",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Rotterdam",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "\u2019s-Hertogenbosch",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Tilburg",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "nl",
    "city": "Haarlem",
    "state": "trucks",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "be",
    "city": "Brussels",
    "state": "ok",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "be",
    "city": "Antwerp",
    "state": "ok",
    "last_udpate": "01.01.2020"
  },
  {
    "country": "be",
    "city": "Gent",
    "state": "ok",
    "last_udpate": "01.01.2020"
  },
  {
    "country": "es",
    "city": "Madrid",
    "state": "ok",
    "last_udpate": "01.01.2024"
  },
  {
    "country": "es",
    "city": "Madrid - Distrito Centro",
    "state": "ok",
    "last_udpate": "22.12.2021"
  },
  {
    "country": "es",
    "city": "Madrid - Plaza El\u00edptica",
    "state": "ok",
    "last_udpate": "22.12.2021"
  },
  {
    "country": "es",
    "city": "Barcelona",
    "state": "ok",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "es",
    "city": "Barcelona \u2013 Sant Joan Despi",
    "state": "ok",
    "last_udpate": "01.01.2022"
  },
  {
    "country": "es",
    "city": "Barcelona \u2013 Sant Cugat del Valles",
    "state": "ok",
    "last_udpate": "01.05.2021"
  },
  {
    "country": "ch",
    "city": "Genf",
    "state": "ok",
    "last_udpate": "15.01.2020"
  },
  {
    "country": "pt",
    "city": "Lisbon - zona 1",
    "state": "ok",
    "last_udpate": "15.01.2015"
  },
  {
    "country": "pt",
    "city": "Lisbon - zona 2",
    "state": "ok",
    "last_udpate": "15.01.2015"
  },
  {
    "country": "se",
    "city": "Goeteborg",
    "state": "trucks",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "se",
    "city": "Helsingborg",
    "state": "trucks",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "se",
    "city": "Lund",
    "state": "trucks",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "se",
    "city": "Molmoe",
    "state": "trucks",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "se",
    "city": "Moelndal",
    "state": "trucks",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "se",
    "city": "Stockholm",
    "state": "trucks",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "se",
    "city": "Stockholm - Hornsgatan",
    "state": "ok",
    "last_udpate": "01.06.2022"
  },
  {
    "country": "se",
    "city": "Umea",
    "state": "trucks",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "se",
    "city": "Uppsala",
    "state": "trucks",
    "last_udpate": "01.01.2021"
  },
  {
    "country": "gb",
    "city": "Aberdeen",
    "state": "pilot",
    "last_udpate": "30.05.2022"
  },
  {
    "country": "gb",
    "city": "Bath",
    "state": "trucks",
    "last_udpate": "15.03.2021"
  },
  {
    "country": "gb",
    "city": "Birmingham",
    "state": "ok",
    "last_udpate": "01.06.2021"
  },
  {
    "country": "gb",
    "city": "Bradford",
    "state": "trucks",
    "last_udpate": "26.09.2022"
  },
  {
    "country": "gb",
    "city": "Bristol",
    "state": "ok",
    "last_udpate": "28.11.2022"
  },
  {
    "country": "gb",
    "city": "Dundee",
    "state": "pilot",
    "last_udpate": "30.05.2022"
  },
  {
    "country": "gb",
    "city": "Edinburgh",
    "state": "pilot",
    "last_udpate": "31.05.2022"
  },
  {
    "country": "gb",
    "city": "Glasgow",
    "state": "ok",
    "last_udpate": "01.06.2023"
  },
  {
    "country": "gb",
    "city": "London LEZ",
    "state": "trucks",
    "last_udpate": "01.01.2020"
  },
  {
    "country": "gb",
    "city": "London ULEZ",
    "state": "ok",
    "last_udpate": "29.08.2023"
  },
  {
    "country": "gb",
    "city": "London CC",
    "state": "ok",
    "last_udpate": "21.02.2022"
  },
  {
    "country": "gb",
    "city": "Tyneside \u2013 Newcastle and Gateshead",
    "state": "trucks",
    "last_udpate": "01.12.2022"
  },
  {
    "country": "gb",
    "city": "Oxford ZEZ",
    "state": "ok",
    "last_udpate": "28.02.2022"
  },
  {
    "country": "gb",
    "city": "Portsmouth",
    "state": "trucks",
    "last_udpate": "29.11.2021"
  },
  {
    "country": "gb",
    "city": "Scheffield",
    "state": "trucks",
    "last_udpate": "27.02.2023"
  }
];

const StateIcon = ({  state }) => {
  if (state === "lifted up") {
    return <span className="status_zone status_zone-canceled">lifted</span>
  } else if (state === "ok") {
    return <span className="status_zone status_zone-ok">active</span>
  } else if (state === "pilot") {
    return <span className="status_zone status_zone-plan">pilot</span>
  } else if (state === "alarm") {
    return <span className="status_zone">temp.</span>
  } else {
    return <span className="status_zone status_zone-gray">HGV only</span>
  }
};


const Table = ({ data, limit }) => {
  return (
    <table className="zones_table">
      <thead>
        <tr>
          <th className="left"></th>
          <th className="city">Where is the zone?</th>
          <th className="lez">status</th>
          <th className="right">ver.</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td className="country"><Flag name={row.country} /></td>
            <td className="city">
              {row.city.length > limit
                ? `${row.city.slice(0, limit)}...`
                : row.city}
            </td>
            <td className="lez"><StateIcon state={row.state} /></td>
            <td className="date">{row.last_udpate}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};


class ZonesStatus extends React.Component {

  state = {
    modalOpen: true
  }

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  }

  handleCloseModal = () => {
    this.props.history.push(this.props.location.pathname);
  }



  render() {

    let params = new URLSearchParams(this.props.location.search);

    return (

      params.get('zones-status') &&
      <Modal dimmer="inverted" open={this.state.modalOpen} onClose={this.handleCloseModal} className="modal__welcome-screen general__modal">

        <div className="welcome-screen">

          <button className="welcome-screen__close" onClick={this.handleCloseModal}><Icon name="close"></Icon></button>

          <div className="welcome-screen__header">
            <h1 className="welcome-screen__headline">LEZ Monitor (version: 2.5.1)</h1>
          </div>

          <div className="welcome-screen__innerContent">


            <Table data={dataLEZ} limit={35} />
            <br></br>
            <br></br>
            <p>Please note that the current version v.2.5.x of the Route Planner is primarily designed for private vehicles up to 3.5 tonnes (M1 category). Commercial vehicles, including trucks and other large vehicles, often have different low emission zone requirements and may not be fully supported. These zones are shown in grey on the map. In current version v.2.0, they are not taken into account by the routing algorithm.</p>
            <p>Last update of LEZ datasets: <b>25.02.2024</b></p>
            <p>Last version of OSM (road network): <b>v2024-02-17</b></p>
            <p><a className="textlink" href="https://drive2.city/attribution" target="_blank"><Icon name="external"></Icon>&nbsp;Source of LEZ Datasets</a></p>
            <br></br>

          </div>

          <div className="welcome-screen__button-row">
            <Button className="button-send-email" onClick={this.handleCloseModal}>Close</Button>
          </div>

        </div>

      </Modal>
    )
  }
}


export default ZonesStatus