import React from "react"
import PropTypes from "prop-types"
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import VehicleCard from "./VehicleCard"
import VehicleAdd from "./VehicleAdd"


import {
    Icon,
    Message,
    Button,
} from "semantic-ui-react"




class VehicleList extends React.Component {

    state = {
        vehiclesArray: '',
        payment: '',

    }

    static propTypes = {
        fullView: PropTypes.bool,
        auth: PropTypes.object,
        profile: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        }),
    }



    componentDidMount() {
        if (typeof this.props.profile.vehicle !== "undefined" && this.props.profile.vehicle.length > 0) {
            let vehicleSting = JSON.stringify(this.props.profile.vehicle)
            let vehicleObj = JSON.parse(vehicleSting);
            console.log("vehicle settings: " + vehicleObj)
            this.setState({
                vehiclesArray: vehicleObj,
            })
        }
        if (typeof this.props.profile.payment !== "undefined" && this.props.profile.payment) {
            let paymentSting = JSON.stringify(this.props.profile.payment)
            let paymentObj = JSON.parse(paymentSting);
            console.log("payment settings: " + paymentObj)
            this.setState({
                payment: paymentObj,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profile !== this.props.profile && this.props.profile.vehicle) {
            let vehicleSting = JSON.stringify(this.props.profile.vehicle)
            let vehicleObj = JSON.parse(vehicleSting);
            console.log("vehicle settings: " + vehicleObj)
            this.setState({
                vehiclesArray: vehicleObj,
            })
        }
        if (prevProps.profile !== this.props.profile && this.props.profile.payment) {
            let paymentSting = JSON.stringify(this.props.profile.payment)
            let paymentObj = JSON.parse(paymentSting);
            console.log("payment settings: " + paymentObj)
            this.setState({
                payment: paymentObj,
            })
        }
    }


    render() {

        if (this.props.fullView == true) {
            return (
                (isLoaded(this.props.auth)) && this.props.profile && (
                    <div>
                        {this.state.vehiclesArray ?
                            this.state.vehiclesArray.map((vehicle, index) => {
                                return (
                                    <VehicleCard carDetail={vehicle} countId={index} />
                                )
                            })
                            :
                            <p className="note--alone">There are no saved vehicles yet...</p>
                        }
                        {(this.state.vehiclesArray.length <= 2 && this.state.payment.paid_due != 0) || (
                            this.state.vehiclesArray.length == 0 && this.state.payment.paid_due == 0) ?
                            <VehicleAdd />
                            :
                            null
                        }
                    </div>
                )
            )
        }
        else if (this.props.fullView == false) {
            return (
                (isLoaded(this.props.auth)) && this.props.profile && (

                    <div>
                        {this.state.vehiclesArray ?
                            <div>
                                <VehicleCard carDetail={this.state.vehiclesArray[this.props.profile.vehicleActive]} noButtons={true} />
                                <div className="button-place-holder">
                                    <Link to={{ pathname: "/", search: "?my-car=true" }} className="button--outline">Manage Cars</Link>
                                </div>
                            </div>
                            :
                            <div>
                                <p className="note--alone">There are no saved vehicles yet...</p>
                                <div className="button-place-holder">
                                    <Link to={{ pathname: "/", search: "?my-car=true" }} className="button--outline">Add Vehicle</Link>
                                </div>
                            </div>
                        }
                    </div>
                )
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}



const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.firebase.authError,
        profile: state.firebase.profile
    }
}


export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(VehicleList)