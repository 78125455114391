import React from "react"
import { Link } from 'react-router-dom'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import ResultMultimodal from "./ResultMultimodal"
import MenuPanel from "../MenuPanel/MenuPanel"
import { geolocated } from "react-geolocated";

// we are importing some of the beautiful semantic UI react components
import {
  Search,
  Dropdown,
  Button,
  Icon
} from "semantic-ui-react"


// dont forget to import actions here and dobounce
import {
  updateInputStart,
  updateInputTarget,
  fetchHereGeocodeStart,
  fetchHereGeocodeTarget,
  inputSelectedStart,
  inputSelectedTarget,
  fetchHereReverseGeocodeStart,
  selectCity,
  selectVehicle,
  fetchD2cDirections,
  requestMarkerStart,
  requestMarkerTarget,
  showWarningLez,
  deleteCalculatedRoute,
  updateVehicleSelect,
  resultHandler
} from "../../actions/actions"

// to wait for the users input we will add debounce, this is especially useful for "postponing" the geocode requests
import { debounce } from "throttle-debounce"


// some inline styles (we should move these to our index.css at one stage)

  class Controlpanel extends React.Component {
    static propTypes = {
      userInputStart: PropTypes.string.isRequired,
      userInputTarget: PropTypes.string.isRequired,  
      isFetchingStart: PropTypes.bool.isRequired,
      isFetchingTarget: PropTypes.bool.isRequired,
      resultsStart: PropTypes.array.isRequired,
      resultsTarget: PropTypes.array.isRequired,
      dispatch: PropTypes.func.isRequired, 
      isRouteLoading: PropTypes.bool.isRequired,
      isRouteCalculated: PropTypes.bool.isRequired,
      timestamp: PropTypes.bool.isRequired,
      profile: PropTypes.object,
      firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
      })
    }

    constructor(props) {
      super(props)
      // binding this to the handleSearchChange method
      this.handleSearchChangeStart = this.handleSearchChangeStart.bind(this)
      this.handleSearchChangeTarget = this.handleSearchChangeTarget.bind(this)
      this.handleAimStart = this.handleAimStart.bind(this)
      this.handleAimTarget = this.handleAimTarget.bind(this)
      //this.handleGeolocateStart = this.handleGeolocateStart(this)
      // we are wrapping fetchGeocodeResults in a 1 second debounce
      this.fetchGeocodeResultsStart = debounce(1000, this.fetchGeocodeResultsStart)
      this.fetchGeocodeResultsTarget = debounce(1000, this.fetchGeocodeResultsTarget)
            
    }

    handleSearchChangeStart = (event) => {
      const { dispatch } = this.props;
      dispatch(
        updateInputStart({
          inputValue: event.target.value
        })
      ); 
      this.fetchGeocodeResultsStart()
    }
    

    handleResultSelectStart = (e, { result }) => {
      const { dispatch } = this.props;    
      dispatch(
          updateInputStart({
            inputValue: result.title
          })        
        );
      dispatch(
          inputSelectedStart({
            passValue: result.displayposition
          })
        );
    }
    
    
    handleStartDelete = () => {
      const { dispatch } = this.props;
      dispatch(
        updateInputStart({
          inputValue: '' //doladit i dalsi veci, vymazani ze souradnic
        })
      )
      dispatch(
        inputSelectedStart({
          passValue: [], //vymazani ze souradnic
        })
      )
    }
    

    handleSearchChangeTarget = (event) => {
      const { dispatch } = this.props;
      dispatch(
        updateInputTarget({
          inputValue: event.target.value
        })
      )
      this.fetchGeocodeResultsTarget()
    }


    handleResultSelectTarget = (e, { result }) => {
      const { dispatch } = this.props;      
      dispatch(
          updateInputTarget({
            inputValue: result.title
          })
        )
      dispatch(
          inputSelectedTarget({
            passValue: result.displayposition
          })
        )
    }  


    handleTargetDelete = () => {
      const { dispatch } = this.props;
      dispatch(
        updateInputTarget({
          inputValue: '' //doladit i dalsi veci, vymazani ze souradnic
        })
      )
      dispatch(
        inputSelectedTarget({
          passValue: [], //vymazani ze souradnic
        })
      )
      dispatch(
        showWarningLez({
          passValue: false, //vymazani warning okna v zone
        })
      )
    }

    // our method to fire a geocode request
    fetchGeocodeResultsStart() {
      const { dispatch, userInputStart } = this.props
      // If the text input has more then 0 characters..
      if (userInputStart.length > 0) {

        dispatch(
          fetchHereGeocodeStart({
            inputValue: userInputStart
          })
        )
      }
    }

    fetchGeocodeResultsTarget() {
      const { dispatch, userInputTarget } = this.props
      // If the text input has more then 0 characters..
      if (userInputTarget.length > 0) {

        dispatch(
          fetchHereGeocodeTarget({
            inputValue: userInputTarget
          })
        )
      }
    }

       
  
    handleAimStart(e) {
      const { dispatch } = this.props;
      dispatch(
        requestMarkerStart({
          triggerValue: true, //mohlo by se i zjednodusit, a true volat rovnou v reduceru
        })
      );
      dispatch(
        updateInputStart({
          inputValue: 'Pick Your START!' //to musi ale prijit do MAP, event handleru!!
        })
      ); 
    };


    handleAimTarget(e) {
      const { dispatch } = this.props;
      dispatch(
        requestMarkerTarget() //zde je to zjednoduseno
      );
      dispatch(
        updateInputTarget({
          inputValue: 'Pick Your TARGET!' //to musi ale prijit do MAP, event handleru!!
        })
      ); 
    };

    handleGeolocateStart = () => {
      const { dispatch } = this.props;      
        if (!this.props.isGeolocationEnabled) {
          console.log("Geolokace neni zapnuta") 
        } else if (this.props.coords) {
          dispatch(
            fetchHereReverseGeocodeStart({
              inputValue: {"lat":this.props.coords.latitude,"lng":this.props.coords.longitude}
            })
          ) 
        } else {
          console.log("nefunguje")
        }
    };
        

    render() {
      
        return (

            <div className="cp-wrapper">

              
              <div className="cp-main-panel">              
                
                {/*<div className="cp-header"> 
                  <a className="cp-header__logo-link" href="https://drive2.city/" target="_blank"> 
                  <img className="cp-header__logo" src="./assets/logo--cp-main-panel.svg" alt="Logo Drive2City"></img>                               
                  </a>                 
                </div>*/}

               
                {this.props.isRouteCalculated ?

                <div className="cp-routing cp-routing--isRouteCalculated">

                <div className="cp-routing__box-icons">
                <span className="cp-routing__ico-box cp-routing__ico-box--isRouteCalculated"><Icon name='target' /></span>
                <i class="cp-routing__ico-dots cp-routing__ico-dots--isRouteCalculated d-none"></i>
                <span className="cp-routing__ico-box cp-routing__ico-box--isRouteCalculated"><Icon name='map marker alternate' /></span>
                </div>

                <div className="cp-routing__box-description">
                  <div className="cp-routing__box d-none-flex">                  
                  <input className="cp-routing__search-input cp-routing__search-input--first cp-routing__search-input--disabled" value={this.props.userInputStart} disabled />
                  </div>                  
                  <div className="cp-routing__box">                  
                  <input className="cp-routing__search-input cp-routing__search-input--second cp-routing__search-input--disabled" value={this.props.userInputTarget} disabled />
                  </div>
                </div>
                
                </div>
                :
                <div className="cp-routing">

                <div className="cp-routing__box">
                <span class="cp-routing__ico-box"><Icon name='target' /></span>
                  <Search                    
                    type="text"
                    name="startaddress"
                    className="cp-routing__search-input cp-routing__search-input--first"
                    onSearchChange={this.handleSearchChangeStart}
                    onResultSelect={this.handleResultSelectStart}
                    fluid
                    icon ={this.props.userInputStart ? <Icon name='delete' link onClick={this.handleStartDelete}/> : <Icon name='crosshairs' title="Pick a Point" link onClick={this.handleAimStart}/>}
                    input={{ fluid: true }}  
                    loading={this.props.isFetchingStart}                 
                    results={this.props.resultsStart}
                    value={this.props.userInputStart}                  
                    placeholder="Where from?"                
                   /> 
                   {!this.props.userInputStart && <Button className="geoButton" size='mini' onClick={this.handleGeolocateStart}>My Position</Button>}    
                                  
                </div>

                <i class="cp-routing__ico-dots"></i>

                <div className="cp-routing__box">
                <span class="cp-routing__ico-box"><Icon name='map marker alternate' /></span>                           
                  <Search                    
                    type="text"
                    name="targetaddress"                    
                    className="cp-routing__search-input cp-routing__search-input--second"
                    onSearchChange={this.handleSearchChangeTarget}
                    onResultSelect={this.handleResultSelectTarget}
                    fluid
                    icon ={this.props.userInputTarget? <Icon name='delete' link onClick={this.handleTargetDelete}/> : <Icon name='crosshairs' title="Pick a Point" link onClick={this.handleAimTarget}/>}
                    input={{ fluid: true }}  
                    loading={this.props.isFetchingTarget}                 
                    results={this.props.resultsTarget}
                    value={this.props.userInputTarget}                  
                    placeholder="Where to go?"
                  />  
                </div>   

                </div> 
                }    

                <ResultMultimodal timestamp={this.props.timestamp} />
                
                <MenuPanel timestamp={this.props.timestamp} />
                  
                
              </div>
            </div>
          )
    }

  }  
  

  const mapStateToProps = state => {
    const userInputStart = state.routeControls.userInputStart
    const userInputTarget = state.routeControls.userInputTarget
    const isFetchingStart = state.routeControls.isFetchingStart
    const isFetchingTarget = state.routeControls.isFetchingTarget
    const resultsStart = state.routeControls.resultsStart
    const resultsTarget = state.routeControls.resultsTarget
    const selectedStart = state.routeControls.selectedStart
    const selectedTarget = state.routeControls.selectedTarget    
    const graphicInputStart = state.routeControls.graphicInputStart
    const graphicInputTarget = state.routeControls.graphicInputTarget
    const isRouteLoading = state.routeControls.isRouteLoading
    const isRouteCalculated = state.routeControls.isRouteCalculated
    const mode = state.routeControls.mode
    const profile = state.firebase.profile
    const auth = state.firebase.auth
      
      return {
        userInputStart,
        userInputTarget,
        isFetchingStart,
        isFetchingTarget,
        resultsStart,
        resultsTarget,
        selectedStart,
        selectedTarget,      
        graphicInputStart,
        graphicInputTarget,
        isRouteLoading,
        isRouteCalculated,
        profile,
        auth,
        mode
      }
  }


export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
    geolocated({
      positionOptions: {
          enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
  })
)(Controlpanel)