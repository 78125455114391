import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import {
  Modal,
  Icon,
} from "semantic-ui-react";

import LoginForm from "../Forms/LoginForm"


class LoginModal extends React.Component {

  constructor(props) {
    super(props)
  }

  handleClose = () => {
    window.history.back();
  }

  render() {

    let params = new URLSearchParams(this.props.location.search);

    return (

      params.get('login') && (isEmpty(this.props.auth)) && (

        <Modal dimmer="inverted" open className="modal__welcome-screen">

          <div className="welcome-screen">
            <button className="welcome-screen__close" onClick={this.handleClose}><Icon name="close"></Icon></button>

            <div className="scroll-modal">
              <div className="sidebox__header sidebox__header--login">
                <div className="intro-header__logo intro-header__logo--left"></div>
                <h2 className="header">Login</h2>
              </div>

              <LoginForm />

            </div>

          </div>

        </Modal>
      )
    )
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.firebase.authError,
  }
}

export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(LoginModal)