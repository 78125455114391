import React from "react"
import PropTypes from "prop-types"
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Button, Modal, Icon } from "semantic-ui-react";

import {
  showPaymentModal
} from "../../actions/actions"


class PayModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    freeRequestsOverload: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.freeRequestsOverload !== prevProps.freeRequestsOverload) {
      if (this.props.freeRequestsOverload >= 0 && this.props.freeRequestsOverload <= 4) {
        this.setState({ showModal: true });
      } else {
        this.setState({ showModal: false });
      }
    }
  }

  handleModalClose() {
    this.setState({ showModal: false });
    const { dispatch } = this.props;
    dispatch(
      showPaymentModal({
        passValue: 10       // resetovani triggeru, aby fungoval prevProps
      })
    );
  }

  render() {
    return (
      <Modal dimmer="inverted" open={this.state.showModal} onClose={this.handleModalClose} className="modal__welcome-screen">

        {(this.props.freeRequestsOverload === 4) ?
          <div className="welcome-screen">

            <button className="welcome-screen__close" onClick={this.handleModalClose}><Icon name="close"></Icon></button>

            <div className="scroll-modal">
              <div className="sidebox__header sidebox__header--login">
                <div className="intro-header__logo intro-header__logo--left"></div>
                <h2 className="header">Too many requests right now...</h2>
                <p className="perex">Please slow-down for a while...</p>
              </div>

            </div>

          </div>
          :
          <div className="modal__contentHolder--additionalInfo">
          <div className="welcome-screen">

            <button className="welcome-screen__close" onClick={this.handleModalClose}><Icon name="close"></Icon></button>

            <div className="scroll-modal">
              <div className="sidebox__header sidebox__header--login">
                <div className="intro-header__logo intro-header__logo--left"></div>

                <h2 className="header">{(this.props.freeRequestsOverload === 2) ? "Your package has expired..." : "Upgrade to full access..."}</h2>
                <p className="perex">{(this.props.freeRequestsOverload === 2) ? "Your package has expired and has been downgraded to the public DEMO with 3 free requests per day. To continue using the full functionality of DRIVE2.CITY, please renew your package." : "You've reached the daily limit of 3 free requests. Upgrade to the full version will unlock all features."}</p>
              </div>

              <div className="scroll-modal__button-area">
                <div className="button-place-holder-row">
                  <a href="https://drive2.city/purchase" target="_blank" className="button--outline button--outlineBig">{(this.props.freeRequestsOverload === 2) ? "Buy Now" : "Buy Now"}</a>
                  <Button className="button--outline button--outlineBig" onClick={this.handleModalClose}>Back to map</Button>
                </div>               
              </div>

              <div className="sidebox--section">
                <h3>From €2.49 you can unlock:</h3>

                <div className="sidebox-banner sidebox-banner--access">
                  <div className="text-wrapper">
                    <h2>Unlimited Planning</h2>
                    <p className="perex">
                      Start planning your next trip right now!                  </p>
                  </div>
                </div>

                <div className="sidebox-banner sidebox-banner--roadbook">
                  <div className="text-wrapper">
                    <h2>Exports</h2>
                    <p className="perex">
                      Detailed roadbook and waypoints to export
                    </p>
                  </div>
                </div>

                <div className="sidebox-banner sidebox-banner--vehicles">
                  <div className="text-wrapper">
                    <h2>Vehicle Profiles</h2>
                    <p className="perex">
                      Save up to 3 vehicle configurations
                    </p>
                  </div>
                </div>



                <p className="perex2">The idea of DRIVE2.CITY came in 2019, after getting lost in Berlin's zone.😅
                  Since then, our routing engine has delivered more than 1.000.000 km of low-emission compliant routes.
                  Your symbolic contribution will help us to maintain the project and to explore new approaches in car mobility.</p>

                <div className="button-place-holder">
                  <a href="https://drive2.city/purchase" target="_blank" className="button--outline button--outlineBig">Choose Your Package</a>
                </div>

                <h3>Thank you for your support!</h3>
                <h2>❤</h2>
                <p className="perex2">Tomas, the founder of DRIVE2.CITY</p>

              </div>

            </div>

          </div>
          <p className="modal__additionalInfo">
          Already purchased access? Go to <Link to={{ pathname: "/", search: "?login=true" }} >login →</Link>
          </p>
          </div>
        }

      </Modal >
    )
  }
}



const mapStateToProps = state => {
  const freeRequestsOverload = state.routeControls.freeRequestsOverload


  return {
    freeRequestsOverload,
  }
}



export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(PayModal)