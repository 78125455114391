import React from "react"
import Modaly from "../Modals/modal" // render the content as Modal
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import VehicleList from "./components/VehicleList"
import MenuBar from "./components/MenuBar"

import {
  Icon,
  Modal,
  Message,
} from "semantic-ui-react"

import {
  resultHandler,
} from "../../actions/actions"



class MyCar extends React.Component {

  state = ''

  static propTypes = {
    auth: PropTypes.object,
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }),
  }


  handleCloseModal = () => {
    this.props.history.push(this.props.location.pathname);
  }

  render() {

    let params = new URLSearchParams(this.props.location.search);

    return (

      params.get('my-car') && (isLoaded(this.props.auth)) && (<Modaly>

        <Modal className="lp-modal-sidebox" dimmer='inverted' open='true' onClose={this.handleCloseModal} >


          <div className="sidebox--rollsection">

            <div className="sidebox__header sidebox__header--vehicles">
              <div className="intro-header__logo intro-header__logo--left"></div>
              <h1 className="header">Vehicle Profiles</h1>
              <p className="perex">Choose a car for today</p>
            </div>

            <div className="sidebox__body">

              <div className="sidebox--section">
                <h2>Your vehicles</h2>
                <VehicleList fullView={true} />
              </div>
              
            </div>

          </div>

          <MenuBar />

          {this.props.authError ?
            <Message attached='bottom' warning>
              <Icon name='help' />
              <p>Authorisation was not succesffull</p>
            </Message>
            : null}

        </Modal>

      </Modaly>

      )

    )

  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.firebase.authError,
    profile: state.firebase.profile
  }
}


export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(MyCar)