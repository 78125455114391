import { 
    SHOW_SPINNER,
    HIDE_SPINNER
} from "../actions/actions"

const initialApplicationReducerState = {
    spinner: false
  };


const applicationReducer = (state = initialApplicationReducerState, action) => {
    switch (action.type) {   

      case SHOW_SPINNER:
          return { ...state, spinner: true };
  
      case HIDE_SPINNER:
        return { ...state, spinner: false };
  
      default:
        return state;
    }
  };

export default applicationReducer;