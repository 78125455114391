import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { resultHandler } from "../../../actions/actions"

import {
    Button,
    Icon,
    Modal,
    Form,
    Message,
    Grid,
    Input,
    Select,
} from "semantic-ui-react"



class VehicleAdd extends Component {

    state = {
        showForm: false,
    }

    static propTypes = {
        auth: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        }),
    }

    handleShowForm = (e) => {
        e.preventDefault();
        this.setState({
            showForm: true,
        })
    }

    handleCloseForm = (e) => {
        e.preventDefault();
        this.setState({
            showForm: false,
        })
    }

    updateForm = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        })
    }


    handleSubmitVehicle = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        let vehiclesString = JSON.stringify(this.props.profile.vehicle)
        let vehiclesObj = JSON.parse(vehiclesString);
        vehiclesObj.push({ "make": this.state.make, "model": this.state.model, "year": this.state.year, "fuel": this.state.fuel, "norm": this.state.norm });
        let activeItem = this.props.profile.vehicleActive;
        if (activeItem != 0) {
            var newActiveItem = activeItem;
        } else {
            var newActiveItem = 0;
        }
        this.props.firebase.updateProfile({
            vehicle: vehiclesObj,
            vehicleActive: newActiveItem,
        }).then(() => {
            dispatch(
                resultHandler({
                    handlerCode: 'PROFILE_UPDATE_SUCCESS',
                })
            );
            this.setState({
                showForm: false,
            })
        }).catch((error) => {
            dispatch(
                resultHandler({
                    handlerCode: 'PROFILE_UPDATE_ERROR',
                    handlerMessage: '...' + error,
                })
            );
        });
    }


    render() {
        return (
            this.state.showForm ?
                <div>
                    <br></br>
                    <h3>New Vehicle</h3>




                    <div className="card-my-vehicle vehicle--new">
                        <div className="cp-iterinary__clear-btn">
                            <button className="button--route-control clear-btn" onClick={this.handleCloseForm}><Icon name='times circle' className="clear-btn__icon" /></button>
                        </div>

                        <Form onSubmit={this.handleSubmitVehicle}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={6} textAlign="right" verticalAlign="middle"><b>Make</b></Grid.Column>
                                    <Grid.Column width={10} textAlign="left">
                                        <Form.Input type="text">
                                            {/*<input name="displayName" value={this.state.displayName} onChange={this.handleChange} />*/}
                                            <input required type="text" name="make" onChange={this.updateForm} placeholder="Audi, BMW, etc..." />

                                        </Form.Input>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={6} textAlign="right" verticalAlign="middle"><b>Model</b></Grid.Column>
                                    <Grid.Column width={10} textAlign="left">
                                        <Form.Input type="text">
                                            <input required type="text" name="model" onChange={this.updateForm} placeholder="Your car model" />

                                        </Form.Input>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={6} textAlign="right" verticalAlign="middle"><b>First Registration</b></Grid.Column>
                                    <Grid.Column width={10} textAlign="left">
                                        <Form.Input type="text">
                                            <select required name="year" onChange={this.updateForm} placeholder="for example 2002">
                                                <option value="" disabled selected hidden>Select the year</option>
                                                <option value="2023">2023</option>
                                                <option value="2022">2022</option>
                                                <option value="2021">2021</option>
                                                <option value="2020">2020</option>
                                                <option value="2019">2019</option>
                                                <option value="2018">2018</option>
                                                <option value="2017">2017</option>
                                                <option value="2016">2016</option>
                                                <option value="2015">2015</option>
                                                <option value="2014">2014</option>
                                                <option value="2013">2013</option>
                                                <option value="2012">2012</option>
                                                <option value="2011">2011</option>
                                                <option value="2010">2010</option>
                                                <option value="2009">2009</option>
                                                <option value="2008">2008</option>
                                                <option value="2007">2007</option>
                                                <option value="2006">2006</option>
                                                <option value="2005">2005</option>
                                                <option value="2004">2004</option>
                                                <option value="2003">2003</option>
                                                <option value="2002">2002</option>
                                                <option value="2001">2001</option>
                                                <option value="2000">2000</option>
                                                <option value="1999">1999</option>
                                                <option value="1998">1998</option>
                                                <option value="1997">1997</option>
                                                <option value="1996">1996</option>
                                                <option value="1995">1995</option>
                                                <option value="1994">1994</option>
                                                <option value="1993">1993</option>
                                                <option value="1992">1992</option>
                                                <option value="1991">1991</option>
                                                <option value="1990">1990</option>
                                                <option value="1989">1989 or older</option>
                                            </select>
                                        </Form.Input>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={6} textAlign="right" verticalAlign="middle"><b>Fuel Type</b></Grid.Column>
                                    <Grid.Column width={10} textAlign="left">
                                        <Form.Input type="text">
                                            <select required name="fuel" onChange={this.updateForm} placeholder="for example DIESEL">
                                                <option value="" disabled selected hidden>Select the fuel type</option>
                                                <option value="0">Benzin</option>
                                                <option value="1">Diesel</option>
                                                <option value="2">CNG/LPG</option>
                                                <option value="3">Hybrid</option>
                                                <option value="4">EV</option>
                                                <option value="5">H2</option>
                                            </select>
                                        </Form.Input>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={6} textAlign="right" verticalAlign="middle"><b>Emission Standard</b></Grid.Column>
                                    <Grid.Column width={10} textAlign="left">
                                        <Form.Input type="text" required>
                                            <select required name="norm" onChange={this.updateForm} placeholder="for example EURO-3">
                                                <option value="" disabled selected hidden>Select the Euro Class</option>
                                                <option value="6">EURO-6</option>
                                                <option value="5">EURO-5</option>
                                                <option value="4">EURO-4</option>
                                                <option value="3">EURO-3</option>
                                                <option value="2">EURO-2</option>
                                                <option value="1">EURO-1</option>
                                                <option value="0">no EURO-norm</option>
                                            </select>
                                        </Form.Input>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row textAlign="center">
                                    <Button className="lp-actions__button" type='submit'>Save</Button>
                                </Grid.Row>
                            </Grid>
                        </Form>

                    </div>
                </div>
                :
                <div className="button-place-holder">
                    <button onClick={this.handleShowForm} className="button--outline">Add Vehicle</button>
                </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.firebase.authError,
        profile: state.firebase.profile
    }
}


export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(VehicleAdd)
