import React from "react"
import Modaly from "../Modals/modal" // render the content as Modal
import { Link } from 'react-router-dom'

import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import dateFormat from "dateformat"

import VehicleList from "./components/VehicleList"
import ActivePlan from "./components/ActivePlan"
import MenuBar from "./components/MenuBar"

import {
  Button,
  Checkbox,
  Icon,
  Modal,
  Form,
  Message,
  Tab,
  Grid,
} from "semantic-ui-react"

import {
  resultHandler,
} from "../../actions/actions"



class UserProfile extends React.Component {

  static propTypes = {
    profile: PropTypes.object,
    auth: PropTypes.object,
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }),
  }



  handleCloseModal = () => {
    this.props.history.push(this.props.location.pathname);
  }



  render() {

    let params = new URLSearchParams(this.props.location.search);


    return (

      params.get('user-profile') && (isLoaded(this.props.auth)) && (<Modaly>

        <Modal className="lp-modal-sidebox" dimmer='inverted' open='true' onClose={this.handleCloseModal} >

          
            
            <div className="sidebox--rollsection">
            
            <div className="sidebox__header sidebox__header--profile">
              <div className="intro-header__logo intro-header__logo--left"></div>
              <h1 className="header">Account Overview</h1>              
            </div>

            <div className="sidebox__body">

            

              <div className="sidebox--section">
                <h2>Profile</h2>
                <table className="profileTab">
                  <tr>
                    <td className="gray">Username</td>
                    <td>{this.props.auth.displayName}</td>
                  </tr>
                  <tr>
                    <td className="gray">Email</td>
                    <td>{this.props.auth.email}</td>
                  </tr>
                  <tr>
                    <td className="gray">Registered on</td>
                    <td>{dateFormat(this.props.profile.joined, "dd.mm.yyyy")}</td>
                  </tr>
                </table>
              </div>


              <div className="sidebox--section">
                <h2>Your Package</h2>
                <ActivePlan />
              </div>



              <div className="sidebox--section">
                <h2>Default Vehicle</h2>
                <VehicleList fullView={false} />
              </div>

              </div>

            </div>

            <MenuBar />

          

          {this.props.authError ?
            <Message attached='bottom' warning>
              <Icon name='help' />
              <p className="note--alone">Authorisation was not succesffull</p>
            </Message>
            : null}

        </Modal>

      </Modaly>

      )

    )

  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.firebase.authError,
    profile: state.firebase.profile
  }
}


export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(UserProfile)