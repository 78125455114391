import React from "react"
import { Link } from 'react-router-dom'
import { Button, Modal, Icon } from "semantic-ui-react";
import Cookies from "js-cookie";

const openInNewTab = url => {
  const width = 350;
  const height = 750;
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  window.open(url, 'title', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no, 
  width=${width},height=${height},left=${left},top=${top}`);
};

class LastUpdates extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      hideUpdatesModal: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleHideModalUpdateChange = this.handleHideModalUpdateChange.bind(this);
  }


  componentDidMount() {
    const hideModal = Cookies.get("hideUpdatesModal");
    if (!hideModal) {
      this.setState({ showModal: true });
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      let params = new URLSearchParams(this.props.location.search);
      if (params.get('lastupdates') === 'true') {
        this.setState({ showModal: true });
      }
    }
  };


  handleCloseModal = () => {
    this.props.history.push(this.props.location.pathname);
    if (this.state.hideUpdatesModal) {
      Cookies.set("hideUpdatesModal", true, { expires: 7 }); // Set cookie expiration time
    }
    this.setState({ showModal: false });
  };


  handleHideModalUpdateChange(event) {
    this.setState({ hideUpdatesModal: event.target.checked });
  };


  render() {

    return (

      <Modal dimmer="inverted" open={this.state.showModal} onClose={this.handleCloseModal} className="modal__welcome-screen general__modal">

        <div className="welcome-screen">

          <button className="welcome-screen__close" onClick={this.handleCloseModal}><Icon name="close"></Icon></button>

          <div className="welcome-screen__header">
            <h1 className="welcome-screen__headline">DRIVE2.CITY: Release Notes</h1>
          </div>

          <div className="welcome-screen__innerContent">

            <h4>(2.5.1) Bugfixes and improvements</h4>
            <p>In this release, we have focused on addressing some UI/UX issues, redesigning certain error messages. Additionally, we have added new information boxes to enhance the user experience, explaining the various situations that can occur when driving in LEZ areas.</p>

            <h4>(2.5.0) BIG UPDATE for 2024</h4>
            <p>We've implemented low emission zones in Denmark (Copenhagen, Aarhus, Odense, Aalborg) where only EURO 5 & 6 diesel vehicles are allowed. It's always important for foreigners to register their vehicles. We've also updated the regulations for certain zones in France. Although we try to ensure accuracy, please check with official sources before you drive. Please note that road signs always prevail and we are not responsible for any fines that may arise.</p>


            <h4>(2.4.1) Bugfix "Send to Phone" (route export)</h4>
            <p>Improved the algorithm for creating waypoints when you upload the calculated route to Google Maps or other third-party navigation software.</p>


            <h4>(2.4.0) PayPal option is here!</h4>
            <p>We're thrilled to introduce an improved payment experience in Version 2.4.0. Based on your feedback, we've added the much-requested PayPal option. From now on we are also saying goodbye to trials and worries about your card details. DRIVE2.CITY gives you a free of choice. No subscriptions - renew as you drive.</p>


            <h4>(2.3.3) London ULEZ expansion (29/08/2023)!</h4>
            <p>The London ULEZ has expanded. It now covers the whole of London. The perimeter of the ULEZ zone is now the same as the previous LEZ for commercial vehicles. The M25 is not included.</p>


            <h4>(2.3.2) Send to phone integration</h4>
            <p>One notable addition is the new button called <b>Send to phone</b> (on desktop) or <b>Start driving</b> (on mobile). With this feature, you can now export your route directly to your default navigation app on your phone. DRIVE2.CITY generates unique waypoints ensuring that you are guided accurately and efficiently to your final destination. Try it now by signing up for a trial!</p>


            <h4>(2.3.1) Bugfixes and improvements / 23.06.2023</h4>
            <p>In this release, we have resolved sporadic errors that occurred during routing for diesel EURO2 & EURO3 vehicle profiles. You can now plan routes without any interruptions or distance limitations.</p>

            <h4>(2.3.0) Update of LEZ areas as of July 1, 2023</h4>
            <ul>
              <li>Germany: Heidelberg, Karlsruhe, Pfinztal, Schramberg, Ilsfeld, Urbach, Wendlingen, and Schwäbisch Gmünd have lifted the restrictions.</li>
              <li>Scotland: The grace period for non-residents in Glasgow has ended.</li>
              <li>France: Grenoble-Alpes Métropole has extended the ZFE-m to apply to private vehicles, starting from July 1, 2023.</li>
            </ul>

            <h4>(2.2.0) Major updates of the Map Infrastructure</h4>
            <p>We are happy to report that we have recently made some major updates to our map infrastructure, bringing a better experience for our users. Here's what's new:</p>
            <ul>
              <li>New vector tiles with higher resolution for better map detail</li>
              <li>Improved visibility of LEZ areas with highlighted outlines</li>
              <li>Clustering of LEZ areas for better overview when zoomed out</li>
              <li>Direct rendering of local LEZ signs for more accurate information</li>
              <li>Fixed some UI/UX issues when rendering the route</li>
            </ul>


            <h4>(2.1.0) New Paywall Integration, Flexible Packages, and Free Trial!</h4>
            <p>We are excited to announce a major upgrade of our payment systems. We've made it easier to upgrade your account and enjoy all the features of DRIVE2.CITY. Your symbolic contribution will help us to maintain the project and to explore new approaches in car mobility.<br></br>
              We now offer two packages based on your usage: a 1-month package and a 1-year package, both of which are available as one-time payments with no binding. Plus, for new customers, we are now offering a 5-day trial period. Let's start HERE: <a href="https://drive2.city/purchase" target="_blank">Start Your Free Trial</a></p>

            <ul>
              <li><b>1-Month access</b> for €2.49</li>
              <li><b>1-Year access</b> for €9.99</li>
              <li><b>5-Days Free Trial</b></li>
              <li>Standard free DEMO: 3 route requests / day</li>
            </ul>



            <h4>(2.0.7) UX/UI Improvements</h4>
            <p>We continue to strive for providing the best user experience and feedback is very important for use. Therefor in this update we are implementing new feedback tools. From now on you can reach us through a simple feedback form - directly from the main menu:</p>
            <ul>
              <li><b>"Report a Bug" Button:</b> The easiest way how to reach us with your enquiries - <a href="" onClick={() => openInNewTab('https://d2c.sleekplan.app/')}>LINK</a></li>
              <li><b>LEZ Monitor:</b> Transparent information about currently implemented zones - <Link to={{ pathname: "/", search: "?zones-status=true" }}>LINK</Link></li>
              <li><b>Release Log:</b> Learn all the news through this box.</li>
            </ul>

            <h4>(2.0.6) New Update: Munich EURO-5/VI</h4>
            <p>February 2023 - the city centre of Munich has now tightened its low emission standards to a minimum of Euro 5/VI diesel. The "Mittlerer Ring" ring road is now also part of the zone.</p>

            <h4>(2.0.5) Revision of Low-Emission Zones for 2023</h4>
            <p>The end of 2022 and the beginning of 2023 brought significant changes to the Low Emission Zones (LEZ) in many cities. A large number of zones have been tightened or newly created. DRIVE2.CITY is working to keep up with the vast majority of these changes through the implementation of open access data sets provided by local authorities. A full list of supported zones is available in the LEZ Monitor.</p>
            <ul>
              <li><b>Germany:</b> Local driving bans in Berlin have been lifted</li>
              <li><b>France:</b> 11 metropolitan cities have created or updated their ZPE-m zones</li>
              <li><b>Italy:</b> New AREA-B requirements in Milan</li>
              <li><b>Spain:</b> New ZBE schemes in Madrid, now including the M30 ring road</li>
              <li><b>Great Britain:</b> New LEZs in Scotland (2 year transitional period) and new CAZ schemes in England - note: for private car holders only Class-D CAZs are relevant</li>
            </ul>

            <h4>(2.0.4) New Update: London ULEZ</h4>
            <p>In October 2021, London's Ultra Low Emission Zone expanded to cover a larger area. The zone, previously limited to central London, now extends up to but not including the South Circular Road to the North Circular Road. Drivers must pay a daily charge of £12.50 if their vehicle does not comply with the ULEZ.</p>

            <h4>(2.0.1) New Routing Engine</h4>
            <p>Introducing the latest release of DRIVE.CITY Routeplanner with a brand new routing engine. Effortlessly plan routes across multiple zones with the updated DRIVE.CITY Routeplanner. Choosing your vehicle's specifications (EURO Exhaust-Norm) will ensure that all relevant zones on your planned route are taken into account and displayed on the list, for both desktop and mobile devices.</p>
          </div>

          <div className="modal-rr__buttonarea">
            <Button className="button-send-email button-send-email--outline" as={Link} to={{ pathname: "/", search: "?zones-status=true" }}>Check LEZ Coverage</Button>
            <Button className="button-send-email" onClick={this.handleCloseModal}>Close</Button>
          </div>

          <div className="modal-rr__checkboxarea">
            <label>
              <input
                type="checkbox"
                checked={this.state.hideUpdatesModal}
                onChange={this.handleHideModalUpdateChange}
              />
              Don't show this message again
            </label>
          </div>

        </div>

      </Modal>
    )
  }
}


export default LastUpdates