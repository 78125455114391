var helper = require("./waypoints");
var helper2 = require("./pInPoly");


module.exports = {

    gMapDeepLink: function (startIn, targetIn, waypointArray) {

    if (waypointArray.length != 0) {   
        let start = startIn.lat+','+startIn.lng;   
        if (targetIn[0] && targetIn[1]) {
            var target = targetIn[0]+','+targetIn[1];
        } else {
            var target = targetIn.lat+','+targetIn.lng; 
        }        
        let waypoints = (helper.wayPointsGmaps(waypointArray));

        let url = new URL('https://www.google.com/maps/dir/'),
        params = {
            'api': 1,
            'origin': start,
            'destination': target, 
            'waypoints': waypoints, 
            'travelmode': 'driving',
        }   
        url.search = new URLSearchParams(params);
        return url.href;        
    } 
    else if (waypointArray.length == 0) { 
        let start = startIn.lat+','+startIn.lng;          
        let target = targetIn.lat+','+targetIn.lng; 
                 
        let url = new URL('https://www.google.com/maps/dir/'),
          params = {
              'api': 1,
              'origin': start,
              'destination': target,  
              'travelmode': 'driving',
          }   
      url.search = new URLSearchParams(params);
      return url.href;
    } 
    
    }
}