
import { decode } from '@mapbox/polyline';
import { pointsInPoly } from "../helpers/intersectionZone"
import { latlngTrans } from "../helpers/latlng_trans"
import { getDistanceFromLatLon } from "../helpers/getDistanceFromLatLon"
import { getExportGeoData } from "../helpers/getExportGeoData"

export const UPDATE_INPUT_START = 'UPDATE_INPUT_START'
export const UPDATE_INPUT_TARGET = 'UPDATE_INPUT_TARGET'

export const REQUEST_GEOCODE_RESULTS_START = 'REQUEST_GEOCODE_RESULTS_START'
export const REQUEST_GEOCODE_RESULTS_TARGET = 'REQUEST_GEOCODE_RESULTS_TARGET'
export const RECIEVE_GEOCODE_RESULTS_START = 'RECIEVE_GEOCODE_RESULTS_START'
export const RECIEVE_GEOCODE_RESULTS_TARGET = 'RECIEVE_GEOCODE_RESULTS_TARGET'

export const INPUT_SELECTED_START = 'INPUT_SELECTED_START'
export const INPUT_SELECTED_TARGET = 'INPUT_SELECTED_TARGET'

export const SELECT_CITY = 'SELECT_CITY'
export const SELECT_VEHICLE = 'SELECT_VEHICLE'
export const SELECT_CITY_TRANSPORT = 'SELECTED_CITY_TRANSPORT'
export const SELECT_ZONE = 'SELECT_ZONE'

export const REQUEST_DIRECTIONS = 'REQUEST_DIRECTIONS'
export const RECIEVE_DIRECTIONS = 'RECIEVE_DIRECTIONS'
export const RECIEVE_DIRECTIONS_NEW = 'RECIEVE_DIRECTIONS_NEW'

export const REQUEST_MARKER_START = 'REQUEST_MARKER_START'
export const RECIEVE_MARKER_START = 'RECIEVE_MARKER_START'
export const REQUEST_MARKER_TARGET = 'REQUEST_MARKER_TARGET'
export const RECIEVE_MARKER_TARGET = 'RECIEVE_MARKER_TARGET'
export const REQUEST_MARKER_SWITCH_POINT = 'REQUEST_MARKER_SWITCH_POINT'
export const RECIEVE_MARKER_SWITCH_POINT = 'RECIEVE_MARKER_SWITCH_POINT'
export const CHANGE_MARKER_SWITCH_POINT = 'CHANGE_MARKER_SWITCH_POINT'

export const SHOW_WARNING_LEZ = 'SHOW_WARNING_LEZ'

export const RESULT_HANDLER = 'RESULT_HANDLER'

export const DELETE_CALCULATED_ROUTE = 'DELETE_CALCULATED_ROUTE'

export const SHOW_SPINNER = 'SHOW_SPINNER'
export const HIDE_SPINNER = 'HIDE_SPINNER'

export const REMAINING_DAYS = 'REMAINING_DAYS'

export const ACCOUNT_EXPIRED = 'ACCOUNT_EXPIRED'

export const SHOW_PAYMENT_MODAL = 'SHOW_PAYMENT_MODAL'
export const SHOW_ROUTEREADY_MODAL = 'SHOW_ROUTEREADY_MODAL'

export const USER_LOGGED = 'USER_LOGGED'

export const UPDATE_VEHICLE_SELECT = 'UPDATE_VEHICLE_SELECT'

export const MODE = 'MODE'
export const ACTIVATE_MY_CAR = 'ACTIVATE_MY_CAR'
export const DEACTIVATE_MY_CAR = 'DEACTIVATE_MY_CAR'
export const UNSET_DEFAULT_VEHICLE = 'UNSET_DEFAULT_VEHICLE'

export const UPDATE_VIEW_ALL = 'UPDATE_VIEW_ALL'

export const ZOOM_SELECTED_ZONE = 'ZOOM_SELECTED_ZONE'



// test-api.drive2.city

export const fetchD2cDirections = payload => dispatch => {

  dispatch(requestDirections())

  const Start = payload.coordStart;
  const Ziel = payload.coordZiel;
  const Profile = payload.selectedVehicle[0].value;
  var nrEuNorm = payload.selectedVehicle[0].euNorm;
  var apiMode = payload.APImode;



  switch (apiMode) {
    case 0:
      var apiKey = `${process.env.REACT_APP_D2CNAVI_ANONYMOUS_API_KEY}`;
      break;
    case 1:
      var apiKey = `${process.env.REACT_APP_D2CNAVI_ANONYMOUS_API_KEY}`;
      break;
    case 2:
      var apiKey = `${process.env.REACT_APP_D2CNAVI_ANONYMOUS_API_KEY}`;
    case 3:
      var apiKey = `${process.env.REACT_APP_D2CNAVI_ANONYMOUS_API_KEY}`;
      break;
    case 4:
      var apiKey = `${process.env.REACT_APP_D2CNAVI_PAYING_API_KEY}`;
      break;
  }

  // zde je potreba vlozit api key podle toho jestli je logged/paid/anonymous


  let url = new URL('https://test-api.drive2.city/navi'),

    params = new URLSearchParams();
  params.append("point", Start);
  params.append("point", Ziel);
  params.append("profile", Profile);
  params.append("locale", "en");
  params.append("apikeyNavi", apiKey);


  url.search = params

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'X-Visitor-ID': "123456",
    },
  };

  dispatch(showSpinner())

  return fetch(url, requestOptions)
    .then(response => {
      if (response.status === 200) {
        console.log("SUCCESS");
        dispatch(
          showRouteReadyModal()
        );
        return response.json();
      } else if (response.status === 429) {
        console.log("RATE LIMITER HERE = 429 --- zde bude dispatch na MODAL");
        dispatch(
          showPaymentModal({
            passValue: apiMode,
          })
        );        
        dispatch(
          hideSpinner()
        );
        dispatch(
          deleteCalculatedRoute()
        );        
      } else {
        console.log("chyba API");
        dispatch(
          resultHandler({
            handlerCode: 'GENERAL_ERROR',
            handlerMessage: 'Click here to report an issue to support...',
          })
        );
        dispatch(
          hideSpinner()
        );
        dispatch(
          deleteCalculatedRoute()
        );
      }
    })
    .then((data) => {
      dispatch(
        processDirections(data, nrEuNorm)
      );
      dispatch(
        hideSpinner()
      );
    })
    .catch(error => {
      console.log("chyba API: " + error);

      dispatch(
        hideSpinner()
      );
      dispatch(
        deleteCalculatedRoute()
      );
    })
   // Error Handling by l proveden jiz v prvnim bode, jinak to zbytecne zasekne aplikaci, neni zatim potvrzeno

}


// fetch ReverseGeoCoding
export const fetchHereReverseGeocodeStart = payload => dispatch => {

  const detail_search = 20;
  const marker_position = payload.inputValue.lat + ',' + payload.inputValue.lng + ',' + detail_search;
  let url = new URL('https://reverse.geocoder.api.here.com/6.2/reversegeocode.json'),
    params = {
      'app_id': `${process.env.REACT_APP_HERE_APP_ID}`,
      'app_code': `${process.env.REACT_APP_HERE_API_CODE}`,
      'mode': 'retrieveAddresses',
      'prox': marker_position,
      'maxresults': 1,
    }

  url.search = new URLSearchParams(params)

  dispatch(showSpinner())
  // we use the fetch API to call HERE Maps with our parameters
  return fetch(url)
    .then(response => response.json())
    .then(data => dispatch(processReverseGeocodeResponseStart(data)))
    .then(dispatch(hideSpinner()))
    .catch(error => console.error(error))

}

// fetch ReverseGeoCoding
export const fetchHereReverseGeocodeTarget = payload => dispatch => {

  const detail_search = 20;
  const marker_position = payload.inputValue.lat + ',' + payload.inputValue.lng + ',' + detail_search;
  let url = new URL('https://reverse.geocoder.api.here.com/6.2/reversegeocode.json'),
    params = {
      'app_id': `${process.env.REACT_APP_HERE_APP_ID}`,
      'app_code': `${process.env.REACT_APP_HERE_API_CODE}`,
      'mode': 'retrieveAddresses',
      'prox': marker_position,
      'maxresults': 1,
    }

  url.search = new URLSearchParams(params)

  // we use the fetch API to call HERE Maps with our parameters
  return fetch(url)
    .then(response => response.json())
    .then(data => dispatch(processReverseGeocodeResponseTarget(data)))
    .catch(error => console.error(error))

}

// fetch ReverseGeoCoding
export const fetchHereReverseGeocodeSwitchPoint = payload => dispatch => {

  const detail_search = 20;
  const marker_position = payload.inputValue.lat + ',' + payload.inputValue.lng + ',' + detail_search;
  let url = new URL('https://reverse.geocoder.api.here.com/6.2/reversegeocode.json'),
    params = {
      'app_id': `${process.env.REACT_APP_HERE_APP_ID}`,
      'app_code': `${process.env.REACT_APP_HERE_API_CODE}`,
      'mode': 'retrieveAddresses',
      'prox': marker_position,
      'maxresults': 1,
    }

  url.search = new URLSearchParams(params)

  // we use the fetch API to call HERE Maps with our parameters
  return fetch(url)
    .then(response => response.json())
    .then(data => dispatch(processReverseGeocodeResponseSwitchPoint(data)))
    .catch(error => console.error(error))

}

// fetch Geocoding - START, load results based on User Input
export const fetchHereGeocodeStart = payload => dispatch => {

  // loading spinner listens to this!
  dispatch(requestGeocodeResultsStart())

  // we define our url and parameters to be sent along
  let url = new URL('https://geocoder.api.here.com/6.2/geocode.json'),
    params = {
      'app_id': `${process.env.REACT_APP_HERE_APP_ID}`,
      'app_code': `${process.env.REACT_APP_HERE_API_CODE}`,
      'searchtext': payload.inputValue,
      'maxresults': 4,
    }
  url.search = new URLSearchParams(params)

  // we use the fetch API to call HERE Maps with our parameters
  return fetch(url)
    .then(response => response.json())
    .then(data => dispatch(processGeocodeResponseStart(data)))
    .catch(error => console.error(error))
}

// fetch Geocoding - TARGET, load results based on User Input
export const fetchHereGeocodeTarget = payload => dispatch => {

  // loading spinner listens to this!
  dispatch(requestGeocodeResultsTarget())

  // we define our url and parameters to be sent along
  let url = new URL('https://geocoder.api.here.com/6.2/geocode.json'),
    params = {
      'app_id': `${process.env.REACT_APP_HERE_APP_ID}`,
      'app_code': `${process.env.REACT_APP_HERE_API_CODE}`,
      'searchtext': payload.inputValue,
      'maxresults': 4,
    }
  url.search = new URLSearchParams(params)

  // we use the fetch API to call HERE Maps with our parameters
  return fetch(url)
    .then(response => response.json())
    .then(data => dispatch(processGeocodeResponseTarget(data)))
    .catch(error => console.error(error))
}


// parsovani souradnic z GeoCoding JSON Filu, pouzite pro START i STOP
const parseGeocodeResponse = (json, latLng) => {
  // parsing the response, just a simple example, this could be much more complex as the response from HERE is fairly ritch
  if (json.Response && json.Response.View.length > 0) {
    let processedResults = []

    for (const address of json.Response.View[0].Result) {
      if (address.Location && address.Location.LocationType === 'point') {
        processedResults.push({
          title: address.Location.Address.Label,
          description: address.Location.Address.PostalCode,
          displayposition: {
            lat: address.Location.DisplayPosition.Latitude,
            lng: address.Location.DisplayPosition.Longitude
          }
        })
      }
    }
    return processedResults
  }
}

// parsovani nazvu ze souradnic ReverseGeoCoding JSON Filu, pouzite pro START i STOP
const parseReverseGeocodeResponse = (json, latLng) => {
  // parsing the response, just a simple example, this could be much more complex as the response from HERE is fairly ritch
  let processedResults = [];
  if (json.Response && json.Response.View.length > 0) {
    const location = json.Response.View[0].Result[0].Location;
    processedResults.push({
      title: location.Address.Label,
      displayposition: {
        lat: location.DisplayPosition.Latitude,
        lng: location.DisplayPosition.Longitude,
      }
    });
  }
  return processedResults
}

// parsovani nactene trasy
const parseDirections = (json) => {

  console.log(JSON.stringify(json));
  let processedResults = [];
  const directionResults = json.paths[0];

  let encoded = directionResults.points;
  let decoded = decode(encoded);  

  processedResults.push({
    routePolyline: directionResults.points,
    routeDecoded: latlngTrans(decoded),
    routeInfo: {
      distance: directionResults.distance,
      duration: directionResults.time,
      bbox: directionResults.bbox
    }
  });

  return processedResults;
}


//VYSLEDKY

// procesovani vysledku - START, oklika kvuli vypnuti spinneru!
const processGeocodeResponseStart = (json) => dispatch => {
  // parse the json file and dispatch the results to receiveGeocodeResults which will be reduced
  const results = parseGeocodeResponse(json)
  // let's let the loading spinner now that it doesn't have to spin anymore
  dispatch(receiveGeocodeResultsStart(results))
}

// procesovani vysledku - TARGET, oklika kvuli vypnuti spinneru!
const processGeocodeResponseTarget = (json) => dispatch => {
  // parse the json file and dispatch the results to receiveGeocodeResults which will be reduced
  const results = parseGeocodeResponse(json)
  // let's let the loading spinner now that it doesn't have to spin anymore
  dispatch(receiveGeocodeResultsTarget(results))
}

// procesovani vysledku - TARGET, oklika kvuli vypnuti spinneru!
const processReverseGeocodeResponseStart = (json) => dispatch => {
  // parse the json file and dispatch the results to receiveGeocodeResults which will be reduced
  const results = parseReverseGeocodeResponse(json)
  // let's let the loading spinner now that it doesn't have to spin anymore
  dispatch(recieveMarkerStart(results))
}

// procesovani vysledku - TARGET, oklika kvuli vypnuti spinneru!
const processReverseGeocodeResponseTarget = (json) => dispatch => {
  // parse the json file and dispatch the results to receiveGeocodeResults which will be reduced
  const results = parseReverseGeocodeResponse(json)
  // let's let the loading spinner now that it doesn't have to spin anymore
  dispatch(recieveMarkerTarget(results))
}

// procesovani vysledku - TARGET, oklika kvuli vypnuti spinneru!
const processReverseGeocodeResponseSwitchPoint = (json) => dispatch => {
  // parse the json file and dispatch the results to receiveGeocodeResults which will be reduced
  const results = parseReverseGeocodeResponse(json)
  // let's let the loading spinner now that it doesn't have to spin anymore
  dispatch(recieveMarkerSwitchPoint(results))
}

// D2C parsing
const processDirections = (json, selectedEuNorm) => dispatch => {
  const results = parseDirections(json) // 1.stupen parsovani -- zparsovani zakladni trasy z fetche
  const resultsDetailedRoute = getExportGeoData(results, selectedEuNorm); // 2. stupen parsovani -- rozdeleni tras, switchpointy, prujezdne body
  //console.log(resultsDetailedRoute);
  //dispatch(recieveDirections(results)) // timto prikazem se odesilaji vysledna data do states (základni setup = results, extended setup = resultDetailedRoute)

  dispatch(recieveDirections(resultsDetailedRoute))
}




export const receiveGeocodeResultsStart = payload => ({
  type: RECIEVE_GEOCODE_RESULTS_START,
  results: payload
})
export const receiveGeocodeResultsTarget = payload => ({
  type: RECIEVE_GEOCODE_RESULTS_TARGET,
  results: payload
})
export const requestDirections = payload => ({
  type: REQUEST_DIRECTIONS,
  ...payload
})
export const recieveDirections = payload => ({
  type: RECIEVE_DIRECTIONS,
  results: payload
})
export const recieveDirectionsNew = payload => ({
  type: RECIEVE_DIRECTIONS_NEW,
  results: payload
})
export const updateVehicleSelect = payload => ({
  type: UPDATE_VEHICLE_SELECT,
  payload
})

export const requestGeocodeResultsStart = payload => ({
  type: REQUEST_GEOCODE_RESULTS_START,
  ...payload
})
export const requestGeocodeResultsTarget = payload => ({
  type: REQUEST_GEOCODE_RESULTS_TARGET,
  ...payload
})

export const updateInputStart = payload => ({
  type: UPDATE_INPUT_START,
  payload
})
export const updateInputTarget = payload => ({
  type: UPDATE_INPUT_TARGET,
  payload
})

export const inputSelectedStart = payload => ({
  type: INPUT_SELECTED_START,
  payload
})
export const inputSelectedTarget = payload => ({
  type: INPUT_SELECTED_TARGET,
  payload
})
export const selectCity = payload => ({
  type: SELECT_CITY,
  payload
})
export const selectVehicle = payload => ({
  type: SELECT_VEHICLE,
  payload
})
export const selectCityTransport = payload => ({
  type: SELECT_CITY_TRANSPORT,
  payload
})
export const requestMarkerStart = payload => ({
  type: REQUEST_MARKER_START,
  payload
})
export const recieveMarkerStart = payload => ({
  type: RECIEVE_MARKER_START,
  results: payload
})
export const requestMarkerTarget = payload => ({
  type: REQUEST_MARKER_TARGET,
  results: payload
})
export const recieveMarkerTarget = payload => ({
  type: RECIEVE_MARKER_TARGET,
  results: payload
})
export const requestMarkerSwitchPoint = payload => ({
  type: REQUEST_MARKER_SWITCH_POINT,
  results: payload
})
export const recieveMarkerSwitchPoint = payload => ({
  type: RECIEVE_MARKER_SWITCH_POINT,
  results: payload
})
export const changeMarkerSwitchPoint = payload => ({
  type: CHANGE_MARKER_SWITCH_POINT,
  payload
})
export const showWarningLez = payload => ({
  type: SHOW_WARNING_LEZ,
  payload
})
export const resultHandler = payload => ({
  type: RESULT_HANDLER,
  ...payload
})
export const deleteCalculatedRoute = payload => ({
  type: DELETE_CALCULATED_ROUTE,
  payload
})
export const showSpinner = payload => ({
  type: SHOW_SPINNER,
  payload
})
export const hideSpinner = payload => ({
  type: HIDE_SPINNER,
  payload
})
export const showPaymentModal = payload => ({
  type: SHOW_PAYMENT_MODAL,
  payload
})
export const showRouteReadyModal = payload => ({
  type: SHOW_ROUTEREADY_MODAL,
  payload
})
export const userLogged = payload => ({
  type: USER_LOGGED,
  payload
})
export const dispatchMode = payload => ({
  type: MODE,
  payload
})
export const activateMyCar = payload => ({
  type: ACTIVATE_MY_CAR,
  payload
})
export const deactivateMyCar = payload => ({
  type: DEACTIVATE_MY_CAR,
  payload
})
export const updateViewAll = payload => ({
  type: UPDATE_VIEW_ALL,
  payload
})
export const zoomSelectedZone = payload => ({
  type: ZOOM_SELECTED_ZONE,
  payload
})
export const unsetDefualtVehicle = payload => ({
  type: UNSET_DEFAULT_VEHICLE,
  payload
})