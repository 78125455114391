import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { withRouter } from 'react-router'
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'


import {
    updateVehicleSelect,
    activateMyCar,
    resultHandler,
} from "../../../actions/actions"

import {
    Popup
} from "semantic-ui-react"




class MyCarButton extends React.Component {
    static propTypes = {
        selectedVehicle: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        profile: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        })
    }

    handleSelectMyVehicle = (e) => {
        const { dispatch } = this.props;
        if (isLoaded(this.props.auth) && this.props.profile.vehicle.length) {
            let activeVehicle = this.props.profile.vehicleActive;
            let activeVehicleNorm = this.props.profile.vehicle[activeVehicle].norm;
            if (activeVehicleNorm == 1) {
                var numberNorm = Math.round(activeVehicleNorm) - 1
            } else {
                var numberNorm = Math.round(activeVehicleNorm) - 2
            }
            dispatch(
                updateVehicleSelect({
                    passValue: numberNorm
                })
            );
            dispatch(
                activateMyCar()
            );
            dispatch(
                resultHandler({
                    handlerCode: 'VEHICLE_SELECTED', // zde result handler, ze je zvoleno moje auto
                })
            );
        } else if (isLoaded(this.props.auth) && !this.props.profile.vehicle.length) {
            this.props.history.push("/?my-car=true");
        }
    };


    render() {        
        if (this.props.auth.uid) {
            return (
                <button className={!this.props.activateMyCar ? "buttons button--car" : "buttons button--car--active"} onClick={this.handleSelectMyVehicle} >myCar</button>
            )
        } else if (!this.props.auth.uid) {
            return (
                <Popup
                    on='click' 
                    pinned                   
                    position='top left'
                    trigger={<button className="buttons button--car" >myCar</button>}
                >Please <Link to={{ pathname: "/", search: "?login=true" }}>signin</Link> to load your vehicle profile.
                </Popup>
            )
        }

    }


}

const mapStateToProps = state => {
    const selectedVehicle = state.routeControls.selectedVehicle
    const profile = state.firebase.profile
    const auth = state.firebase.auth
    const activateMyCar = state.routeControls.activateMyCar

    return {
        activateMyCar,
        selectedVehicle,
        profile,
        auth
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
    withRouter
)(MyCarButton)
