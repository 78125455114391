import React, { Component } from 'react';
import LogInMenu from "../Toppanel/Components/LogInMenu"
import VehicleSelect from '../Toppanel/Components/VehicleSelect'


class TopPanel extends Component {

    render() {

        return (
            <div className="dd-wrapper">
                <LogInMenu />
                <VehicleSelect />
            </div>
        );

    }
}


export default (TopPanel)