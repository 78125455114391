module.exports = {

    
    wayPointsGmaps: function (arrayWaypoints) {
    
        console.log("arrayWaypoints: "+JSON.stringify(arrayWaypoints));      
        const arrayLength = arrayWaypoints.length    
        //const polyObjects = latlng_polyline.toString().replace(/LatLng/g, '').replace(/, /g, ',').split("),("); 
        
        var waypoints = "";

        for (let i=0; i<arrayLength; i++) {            
            waypoints += arrayWaypoints[i].toString().replace(/, /g, ',');
            if (i != arrayLength){
                waypoints += "|"
            } else {
                break;
            }  
        }

        console.log("vsechny waypoints here: "+waypoints);
        // input format lat1,lng1|lat2,lng2|lat3,lng3|lat4,lng4
        
        //let waypoints = polyObjects[indexStep]+'|'+polyObjects[indexStep*2]+'|'+polyObjects[indexStep*3]+'|'+polyObjects[indexStep*4]+'|'+polyObjects[indexStep*5];

        return waypoints;
    }
    
}