import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'

import SendPhone from "../Modals/SendPhone"
import Email from "../Modals/Email"


class SharePanel extends React.Component {

  render() {

    if (this.props.isRouteCalculated) {
      return (
        <div className="cp-actions">
          <Email />
          <SendPhone />
        </div>


      )
    } else {
      return (<div></div>);
    }
  }
}



const mapStateToProps = (state) => {
  return {
    isRouteCalculated: state.routeControls.isRouteCalculated,
  }
}


export default compose(connect(mapStateToProps))(SharePanel)