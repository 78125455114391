import React, {Component} from 'react';
import Loader from 'react-promise-loader';
import lezImg from "../../img/sign-lez--gray.svg"

class SpinnerMap extends Component {
    
    render() { 
        return(
        <div class="loader-container loader-container--big loader-container--map">
              <div className="intro-header__inner">
                <div className="intro-header__logo">                        
                </div>  
                <div className="intro-header__sublogo">
                  <img src={lezImg}></img>
                  ROUTE PLANNER v2.5.1
                </div>             
              </div> 
              <div className="loader-big">                                
              <Loader loading={true} color={'#5f8af2'} background={'none'} />
              <p className="loader-wording">Loading map...</p>
              </div>  
        </div>
        ) 
    }
}

export default (SpinnerMap);