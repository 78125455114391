import { RESULT_HANDLER } from '../actions/actions'

const handlerMessage = {
  LOGIN_SUCCESS_EMAIL:
    'You are now sucessfuly loged with your email adress',
  LOGIN_SUCCESS_GMAIL:
    'You are now sucessfuly loged with your gmail account',
  SIGNUP_SUCCESS:
  'You are now sucessfuly loged in!',
  EMAIL_ROUTE_SUCCESS: 'Check your mail',
}

const handlerTopic = {
  LOGIN_SUCCESS_EMAIL: 'Login has been successfull',
  LOGIN_SUCCESS_GMAIL: 'Login has been successfull',
  LOGOUT_SUCCESS: 'You have successfully been logged out',
  SIGNUP_SUCCESS: 'You are account has been created',
  SIGNUP_ERROR: 'Something went during registration process wrong.',
  RESEND_VERIFICATION: 'Verification Email has been sent to your Address.',
  RESEND_VERIFICATION_ERROR: 'Verification Email canot been delivered.',
  EMAIL_RESET_ERROR: 'Password cannot be reseted',
  EMAIL_ROUTE_SUCCESS: 'Route has been send',
  EMAIL_ROUTE_ERROR: 'Something went wrong',
  PROFILE_UPDATE_SUCCESS: 'Your Profile has been updated',
  PROFILE_UPDATE_ERROR: 'Sry, was not possible to update your profile',
  PASSWORD_UPDATE_SUCCESS: 'Your password has been changed',
  PASSWORD_UPDATE_ERROR: 'Your password canot been changed',
  NEWSLETTER_SUCCESS: 'Newsletter options has been saved',
  NEWSLETTER_ERROR: 'Newsletter cant been saved',
  OUT_OF_RANGE: 'Your point selection is out of range',
  CHANGE_PARKING: 'Tap on the actual parking spot and move it!',
  SELECT_ZONE: 'Please select a low emission zone before setting your route...',
  SELECT_VEHICLE: 'Please select a vehicle type...',
  VEHICLE_SELECTED: 'Your default vehicle has been selected...',
  VEHICLE_ACTIVATED: 'Selected vehicle set as default...',
  SELECT_ROUTE: 'Please select your route details first...',
  REMAINING_DAYS: 'Your account expires at:',
  ACCOUNT_EXPIRED: 'Your account has expired',
  GENERAL_ERROR: "general_error",
  ALERT_CHANGE_TARGET: "Pick a new destination on the map or type the address.",
  
  INVALID_CREDENTIALS:
    'Sorry, your credentials seem to be invalid, please check again.'
}

const initialState = {
  handlerCode: null,
  handlerMessage: null,
  handlerTopic: null,
}

const resultHandlerReducer = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case RESULT_HANDLER:
      return {
        ...state,
        handlerCode: action.handlerCode,
        handlerTopic: handlerTopic[action.handlerCode],
        handlerMessage: action.handlerMessage
          ? action.handlerMessage
          : handlerMessage[action.handlerCode],
      }
    default:
      return state
  }
}

export default resultHandlerReducer