import React, { Component } from 'react';
import PropTypes from "prop-types"
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'

class AlertBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBanner: true,
            plan: '',
            active: false,
        };
    }

    static propTypes = {
        timestamp: PropTypes.bool.isRequired,
        profile: PropTypes.object,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
        })
    }

    componentDidMount() {
        if (typeof this.props.profile.plan !== "undefined" && this.props.profile.plan) {
            let planString = JSON.stringify(this.props.profile.plan)
            let planObj = JSON.parse(planString);
            console.log("plan settings: " + planObj)
            this.setState({
                plan: planObj
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profile !== this.props.profile && this.props.profile.plan) {
            let planString = JSON.stringify(this.props.profile.plan)
            let planObj = JSON.parse(planString);
            console.log("payment settings: " + planObj)
            this.setState({
                plan: planObj
            })
        };
        if (prevProps.profile !== this.props.profile) {
            var paidDue = this.props.profile.paidDue;
            var timeNow = this.props.timestamp;
            if (paidDue > timeNow) {
                this.setState({
                    active: true,
                    showBanner: true
                });
            } else {
                this.setState({
                    active: false,
                    showBanner: false
                })
            }
        }
    }

    hideBanner = () => {
        this.setState({ showBanner: false });
    };



    render() {
        const { showBanner } = this.state;

        if ((isLoaded(this.props.auth) && this.state.plan.stateSub == "BUYED" && this.state.active) || !showBanner) {
            return null; // Don't render anything if the banner is hidden
        }

        return (
            <div className="alert-banner">
                <div className="text">
                    <span className="alert-msg">
                        You’re using a public DEMO with <b>3 free routes</b> available
                    </span>
                    <span className="alert-msg">
                        |
                    </span>
                    <span className="alert-msg">
                        Upgrade to <span className="green">unlock all features.</span>
                    </span>
                </div>
                <Link className="button-free-trial" to={{ pathname: "/", search: "?premium=true" }}>
                UPGRADE NOW
                </Link>

                <a className="button-closeAlertBanner" onClick={this.hideBanner}></a>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const profile = state.firebase.profile
    const auth = state.firebase.auth

    return {
        profile,
        auth
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect(), // withFirebase can also be used
)(AlertBanner)


