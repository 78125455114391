import React from "react"
import { Link } from 'react-router-dom'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import {
  Dropdown,
  Button
} from "semantic-ui-react"

import {
  resultHandler,
  deleteCalculatedRoute,
  unsetDefualtVehicle,
} from "../../../actions/actions"

const dateFormat = require('dateformat');

const openInNewTab = url => {
  const width = 350;
  const height = 750;
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  window.open(url, 'title', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no, 
  width=${width},height=${height},left=${left},top=${top}`);
};


class LogInMenu extends React.Component {

  static propTypes = {
    auth: PropTypes.object,
    profile: PropTypes.object,
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }),
  }

  handleLogout = (e) => {
    e.preventDefault();
    this.props.firebase.logout().then((resp) => {
      const { dispatch } = this.props;
      dispatch(
        resultHandler({
          handlerCode: 'LOGOUT_SUCCESS',
        })
      );
      dispatch(
        deleteCalculatedRoute()
      );
      dispatch(
        unsetDefualtVehicle()
      )
    }).catch((error) => {
      const { dispatch } = this.props;
      dispatch(
        resultHandler({
          handlerCode: 'INVALID_CREDENTIALS',
          handlerMessage: '...' + error,
        })
      )
    });


  }

  render() {
    if (isEmpty(this.props.auth)) {
      return (
        <div className="loginBtn">

          <Dropdown
            floating
            button
            left
            className='button-mainMenu'
          >
            <Dropdown.Menu className="dropdown--login">
              <Dropdown.Header>
                <h3>Route Planner</h3>
                <p>v2.5.1 (28.04.2024)</p>
                <Button icon="info" as={Link} to={{ pathname: "/", search: "?about=true" }}>About</Button>
              </Dropdown.Header>
              <Dropdown.Item text='Login' icon='sign-in'
                as={Link} to={{ pathname: "/", search: "?login=true" }} />              
              <Dropdown.Item text='Report a Bug' icon='warning sign'
                onClick={() => openInNewTab('https://d2c.sleekplan.app/')} />
              <Dropdown.Item text='LEZ Monitor' icon='list'
                as={Link} to={{ pathname: "/", search: "?zones-status=true" }} />
              <Dropdown.Item text='Release Notes' icon='info circle'
                as={Link} to={{ pathname: "/", search: "?lastupdates=true" }} />
            </Dropdown.Menu>
          </Dropdown>

        </div>
      )
    }
    else if (isLoaded(this.props.auth)) {
      return (
        <div className="loginBtn">

          <Dropdown
            floating
            button
            left
            className='button-mainMenu'
          >
            <Dropdown.Menu className="dropdown--login">
              <Dropdown.Header>
                <h3>Route Planner</h3>
                <p>v2.5.1 (28.04.2024)</p>
                <Button as={Link} to={{ pathname: "/", search: "?about=true" }}>About</Button>
              </Dropdown.Header>
              <Dropdown.Item text='My Plan' icon='star'
                as={Link} to={{ pathname: "/", search: "?premium=true" }} />
              <Dropdown.Item text='My Profile' icon='user'
                as={Link} to={{ pathname: "/", search: "?user-profile=true" }} />
              <Dropdown.Item text='My Vehicles' icon='car'
                as={Link} to={{ pathname: "/", search: "?my-car=true" }} />
              <Dropdown.Item text='Report a Bug' icon='comments'
                onClick={() => openInNewTab('https://d2c.sleekplan.app/')} />
              <Dropdown.Item text='LEZ Monitor' icon='list'
                as={Link} to={{ pathname: "/", search: "?zones-status=true" }} />
              <Dropdown.Item text='Release Notes' icon='info circle'
                as={Link} to={{ pathname: "/", search: "?lastupdates=true" }} />
              <Dropdown.Item text='Log out' icon='log out' className="logout"
                onClick={this.handleLogout} />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )
    }

  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.firebase.auth,
    authError: state.firebase.authError,
    profile: state.firebase.profile
  }
}


export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(LogInMenu)