import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import Map from '../Map/Map'
import Controlpanel from '../Controlpanel/Controlpanel'

import SpinnerBig from "../Spinner/SpinnerBig"
import ZoneModal from "../Modals/ZoneModal"
import MapControls from "../Map/MapControls"
import PayModal from "../Modals/PayModal"
import RouteReadyModal from "../Modals/routeReadyModal"
import TopPanel from '../Toppanel/TopPanel'
import AlertBanner from '../AlertBanner/AlertBanner'


import { SemanticToastContainer, toast } from 'react-semantic-toasts'

const openInNewTab = url => {
  const width = 350;
  const height = 750;
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  window.open(url, 'title', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no, 
  width=${width},height=${height},left=${left},top=${top}`);
};


class App extends React.Component {

  static propTypes = {
    resultHandler: PropTypes.object,
    auth: PropTypes.object,
    profile: PropTypes.object,
  }

  
  componentDidUpdate(prevProps, prevState) {

    if (this.props.resultHandler != prevProps.resultHandler) {
      if (this.props.resultHandler.handlerTopic === 'general_error') {
        toast({
          type: 'info',
          icon: 'terminal',
          title: "DRIVE2.CITY is overloaded :((",
          description: 'Click here to report an issue to support...',
          time: 10000,
          animation: 'slide down',
          onClick: () => openInNewTab('https://d2c.sleekplan.app/feedback/151309'),
        })
      }
      else {
        toast({
          type: 'info',
          icon: 'info',
          title: this.props.resultHandler.handlerTopic,
          description: this.props.resultHandler.handlerMessage,
          time: 4500,
          animation: 'slide down',
        })
      }
    }
  }



  render() {

    const timeNow = this.props.firebase.firestore.Timestamp.now().seconds * 1000;


    if (isLoaded(this.props.auth)) {
      return (
        <div>
            
          <div>
          <AlertBanner />  
          <div className="mapDisplay">  
            <TopPanel />            
            <Controlpanel timestamp={timeNow} />
            <Map location={this.props.location} />
          </div>
          </div>  
          <MapControls />
          <ZoneModal />
          <PayModal /> 
          <RouteReadyModal />     
              
          
          <SemanticToastContainer position="top-center" />
        </div>
      )

    }
    else if (!isLoaded(this.props.auth)) {
      return (
        <div>
          <SpinnerBig />
        </div>
      )
    }

  }

}


const mapStateToProps = (state) => {
  return {
    resultHandler: state.resultHandler,
    auth: state.firebase.auth,
    authError: state.firebase.authError,
    profile: state.firebase.profile,
  }
}

export default compose(
  connect(mapStateToProps),
  firebaseConnect(), // withFirebase can also be used
)(App)
