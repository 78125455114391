import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { geolocated } from "react-geolocated";
import 'maplibre-gl/dist/maplibre-gl.css'
import {
  fetchHereReverseGeocodeStart,
  fetchHereReverseGeocodeTarget,
  showWarningLez,
  zoomSelectedZone
} from "../../actions/actions"
//file
import euLEZclusters from "../../mapData/euLEZclusters__v240217.json"


// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl';      // ! is important here
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';
import SpinnerMap from '../Spinner/SpinnerMap';

maplibregl.workerClass = maplibreglWorker;






// defining the container styles the map sits in
const style = {
  width: '100%',
  height: '100vh',
}


// we define a react component
class Map extends React.Component {
  state = {
    displayPosition: null,
    startMarker: null,
    targetMarker: null,
    routeLayer: null,
    routeLayerAlter: null,
    firstLoaded: false
  }

  static propTypes = {
    mapEvents: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.map = null;
    this.vectorTileLayer = null;
    // binding this to the methods
    // this.addMarker = this.addMarker.bind(this);
    // this.updateZoneTooltips = this.updateZoneTooltips.bind(this)
    // this.deleteZoneFocus = this.deleteZoneFocus.bind(this)
  }


  // and once the component has mounted we add everything to it
  componentDidMount() {

    // URL params to load the selected city from link
    const queryURL = new URLSearchParams(this.props.location.search);
    const loc = queryURL.get('loc');

    if (loc != null && loc.match(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/)) {
      const a = loc.split(',')[0];
      const b = loc.split(',')[1];
      // set language in state to the q parameter
      this.setState({
        displayPosition: {
          lat: a,
          lon: b
        }
      })
    } else {
      this.setState({
        displayPosition: null
      })
    }


    // a MapLibre-GL consumes parameters, I'd say they are quite self-explanatory
    // eu bounds      -74.2324546,16.3031381,68.5788605,83.742582
    // eu center      -2.8,50.0,1
    const mapParams = {
      container: 'map',
      style: `https://test-api.drive2.city/maptiles/styles/basic-style/style.json?key=${process.env.REACT_APP_MAPTILESSERVER_API}`,
      center: [7, 52],
      zoom: 4,
      maxBounds: [[-74.2324546, 16.3031381], [68.5788605, 83.742582]],
      attributionControl: false
    }


    // our map!
    this.map = new maplibregl.Map(mapParams);

    // Attributin + zoom and rotation controls to the map.    
    this.map.addControl(new maplibregl.AttributionControl({
      compact: true,
      customAttribution: '© <strong><a href="https://drive2.city/" target="_blank">DRIVE2.CITY 2024</a></strong> | <a href="https://drive2.city/privacy-policy/" target="_blank">Privacy Policy</a> - <a href="https://drive2.city/terms-of-service/" target="_blank">Terms</a> - <a href="https://drive2.city/attribution/" target="_blank">Attribution</a><br>'
    }));
    this.map.addControl(new maplibregl.NavigationControl(), 'bottom-right');

    // add LEZs polygons!
    this.map.on('load', () => {

      // Create a layer with detailed LEZ polygons
      this.map.addSource('LEZdata', {
        type: 'vector',
        tiles: [`https://test-api.drive2.city/maptiles/data/lez/{z}/{x}/{y}.pbf?key=${process.env.REACT_APP_MAPTILESSERVER_API}`]    // pozor musi byt tile
      });

      // Create a layer with with points where LEZs are (for clusters)
      this.map.addSource('LEZpoints', {
        type: 'geojson',
        data: euLEZclusters,
        cluster: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 35 // Radius of each cluster when clustering points (defaults to 50)
      })

      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'LEZpoints',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#2AE0A8',
            10,
            '#2AE0A8',
            35,
            '#2AE0A8'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            10,
            30,
            35,
            40
          ],
          'circle-opacity': 0.5
        }
      });

      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'LEZpoints',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Open Sans Bold'],
          'text-size': 14
        }
      });

      this.map.addLayer({
        id: 'euLEZs',
        type: 'fill',
        source: 'LEZdata',
        'source-layer': "euLEZs",   // zde musi byt nazev vrstvy, odpovida nazvu puvodniho json souboru nebo se podivat do inspect
        paint: {
          'fill-color': [
            'match',
            ['get', 'color'],
            0, 'gray',
            1, '#2ae0a8',
            2, '#2e6bd8',
            3, '#204fa4',
            '#2e6bd8',
          ],
          'fill-opacity': 0.35,
          'fill-outline-color': 'green'
        },
        minzoom: 7
      });

      this.map.addLayer({
        id: 'euLEZs-outline',
        type: 'line',
        source: 'LEZdata',
        'source-layer': "euLEZs",   // zde musi byt nazev vrstvy, odpovida nazvu puvodniho json souboru nebo se podivat do inspect
        paint: {
          'line-color': [
            'match',
            ['get', 'color'],
            0, 'gray',
            1, '#2ae0a8',
            2, '#2e6bd8',
            3, '#204fa4',
            '#2e6bd8',
          ],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7, 1,
            10, 3
          ],
          'line-opacity': 0.95,
          'line-dasharray': [2, 2]
        },
        minzoom: 7
      });

      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'LEZpoints',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'match',
            ['get', 'color'],
            0, 'gray',
            1, '#2ae0a8',
            2, '#2e6bd8',
            3, '#204fa4',
            '#2e6bd8',
          ],
          'circle-radius': 4,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        }
      });

      this.map.addLayer({
        id: 'unclustered-point2',
        type: 'symbol',
        source: 'LEZpoints',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': ['get', 'img_sign'],
          'icon-size': {
            'stops': [
              [10, 0.7],
              [14, 1],
              [20, 2]
            ],
            'base': 1
          }
        },
        minzoom: 6
      });

      // event listener to click on clusters
      this.map.on('click', 'clusters', (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('LEZpoints').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;
            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
      });

      // event listener to display popus
      this.map.on('click', 'unclustered-point2', (e) => {
        const feature = e.features[0];
        const commercial = feature.properties.commercial;

        //create new popup
        const popup = new maplibregl.Popup({
          anchor: 'bottom',
          offset: [0, -35]
        })
          .setLngLat(feature.geometry.coordinates)
          .setHTML(`
          <div class="popup-zone">              
              <div class="popup-zone__info ${feature.properties.commercial === 1 ? 'popup-zone__info--gray' : ''}">
                  <h3>${feature.properties.zone} ${feature.properties.city}</h3>
                  <div class="popup-zone__list ${feature.properties.commercial === 1 ? 'popup-zone__list--gray' : ''}">
                      <div class="listitem ${feature.properties.commercial === 1 ? 'listitem--gray' : 'listitem--time'}">${feature.properties.time}</div>
                      <div class="listitem ${feature.properties.commercial === 1 ? 'listitem--gray' : 'listitem--fuel'}">${feature.properties.diesel}</div>
                      <div class="listitem ${feature.properties.commercial === 1 ? 'listitem--gray' : 'listitem--fuel'}">${feature.properties.petrol}</div>
                      <div class="listitem ${feature.properties.commercial === 1 ? 'listitem--red' : 'listitem--warning'}">${(feature.properties.commercial === 1) ? "APPLIES TO COMMERCIAL VEHICLES ONLY (routing not active)" : feature.properties.warn}</div>
                  </div>
              </div>
          </div>
          `)
          .addTo(this.map)

        this.map.flyTo({
          center: feature.geometry.coordinates,
          essential: true
        })



      });

    });
    
    
    this.map.on('sourcedata', (e) => {
      if (e.isSourceLoaded) {
        // Do something when the source has finished loading
        if (!this.state.firstLoaded) {
          this.setState({ firstLoaded: true});
          console.log("NALADOVANO");
        }        
      }
    });
    
    
    // now creating the Start and Target Points
    this.map.on('click', (e) => {
      const { dispatch } = this.props;
      if (this.props.graphicInputStart === true &&
        this.props.graphicInputTarget === false) {
        let newmarkStart = e.lngLat;
        dispatch(
          fetchHereReverseGeocodeStart({
            inputValue: newmarkStart
          })
        )
      }
      else if (this.props.graphicInputStart === false &&
        this.props.graphicInputTarget === true) {
        //routeLayer.clearLayers();
        let newmarkTarget = e.lngLat;
        dispatch(
          fetchHereReverseGeocodeTarget({
            inputValue: newmarkTarget
          })
        )
      }
      else {
        return
      }
    })

  }

  componentDidUpdate(prevProps, prevState) {
    this.updateMarker('start', prevProps);
    this.updateMarker('target', prevProps);
    this.updateRoute(prevProps);
    this.updateRouteAlter(prevProps);
    this.updateZoomZone(prevProps);
    this.updateMapPosition(prevProps, prevState);
    this.updateViewAll(prevProps);
  }


  updateMarker(markerType, prevProps) {
    const { startMarker, targetMarker } = this.state;
    let propsValue = null;
    let marker = null;
    if (markerType === 'start') {
      marker = startMarker;
      propsValue = "selectedStart";
    } else if (markerType === 'target') {
      marker = targetMarker;
      propsValue = "selectedTarget";
    }
    if (this.props[propsValue] !== prevProps[propsValue]) {
      if (this.props[propsValue].length !== 0) {
        const newMarker = new maplibregl.Marker({ "color": "#204FA4" })
          .setLngLat(this.props[propsValue])
          .addTo(this.map);
        this.setState({ [markerType + 'Marker']: newMarker });
        this.map.flyTo({
          center: this.props[propsValue],
          zoom: 12, // set the desired zoom level here
          essential: true // this option makes the animation smoother
        });
      } else if (marker) {
        marker.remove();
        this.setState({ [markerType + 'Marker']: null });
      }
    }
  }

  updateRoute(prevPros) {
    const { computedDirection, decodedDirection } = this.props;
    const { routeLayer } = this.state;

    if (computedDirection !== prevPros.computedDirection) {
      if (decodedDirection.length === 0) {
        if (routeLayer) {
          this.map.removeLayer("route");
          this.map.removeSource("route")
          this.setState({ routeLayer: null });
        }
      } else {
        if (routeLayer) {
          this.map.getSource("route").setData({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: decodedDirection[0]
            }
          });
        } else {
          this.map.addLayer({
            id: "route",
            type: "line",
            source: {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: decodedDirection[0]
                }
              }
            },
            paint: {
              "line-color": "#2e6bd8",
              "line-width": 6,
              "line-opacity": 0.75
            }
          });
          this.setState({ routeLayer: "yes" });
        }
        const isMobile = window.innerWidth < 768; // Define your own breakpoint here
        const padding = isMobile
          ? { top: 110, bottom: 120, left: 40, right: 40 }
          : { top: 180, bottom: 150, left: 320, right: 100 };

        const routeDetails = this.props.routeInfos;
        this.map.fitBounds(routeDetails.bbox, { padding });
      }
    }
  }

  updateRouteAlter(prevPros) {
    const { routeAlterDecoded } = this.props;
    const { routeLayerAlter } = this.state;
    console.log(JSON.stringify(routeAlterDecoded));

    if (routeAlterDecoded !== prevPros.routeAlterDecoded) {
      if (routeAlterDecoded.length === 0) {
        if (routeLayerAlter) {
          this.map.removeLayer("route2");
          this.map.removeSource("route2")
          this.setState({ routeLayerAlter: null });
        }
      } else {
        if (routeLayerAlter) {
          this.map.getSource("route2").setData({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: routeAlterDecoded[0]
            }
          });
        } else {
          console.log("Jdeme vytvorit alternativni route");
          this.map.addLayer({
            id: "route2",
            type: "line",
            source: {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: routeAlterDecoded[0]
                }
              }
            },
            layout: {
              "line-cap": "round"
            },
            paint: {
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, 1,
                11, 3,
                12, 6
              ],
              "line-dasharray": [0, 2],
              "line-color": "red"
            }
          });
          this.setState({ routeLayerAlter: "yes" });
        }
      }
    }
  }

  updateZoomZone(prevProps) {
    if ((this.props.zoomZonePoint !== prevProps.zoomZonePoint) && this.props.zoomZonePoint !== null) {
      const zonePoint = { "lng": this.props.zoomZonePoint[1], "lat": this.props.zoomZonePoint[0] };

      const isMobile = window.innerWidth < 768;
      const offsetX = isMobile ? 50 : 320;
      this.map.flyTo({
        center: zonePoint,
        zoom: 10, // set the desired zoom level here
        essential: true, // this option makes the animation smoother
        offset: [offsetX, 0]
      });

    }
  }

  updateMapPosition(prevProps, prevState) {
    if (this.state.displayPosition != null && this.state.displayPosition !== prevState.displayPosition) {
      const linkedCity = { "lat": this.state.displayPosition.lat, "lng": this.state.displayPosition.lon }
      this.map.flyTo({
        center: linkedCity,
        zoom: 12, // set the desired zoom level here
        essential: true // this option makes the animation smoother
      });
    } else if (this.state.displayPosition == null && this.props.isGeolocationEnabled && this.props.coords !== prevProps.coords) {
      console.log("update ")
      const focusPosition = { "lat": this.props.coords.latitude, "lng": this.props.coords.longitude }
      this.map.flyTo({
        center: focusPosition,
        zoom: 10, // set the desired zoom level here
        essential: true // this option makes the animation smoother
      });
    }
  }

  updateViewAll(prevProps) {
    if (this.props.isRouteCalculated && (this.props.updateViewTrigger !== prevProps.updateViewTrigger)) {
      const isMobile = window.innerWidth < 768; // Define your own breakpoint here
      const padding = isMobile
        ? { top: 110, bottom: 120, left: 40, right: 40 }
        : { top: 180, bottom: 150, left: 320, right: 100 };

      const routeDetails = this.props.routeInfos;
      this.map.fitBounds(routeDetails.bbox, { padding });
    }
  }



  // don't forget to render it :-)
  render() {

    return (
      <div id="map" className="mapbox">
        {!this.state.firstLoaded ? <SpinnerMap /> : null}
      </div>
    )
  }

}



// and we already map the redux store to properties which we will start soon
const mapStateToProps = (state) => {
  const routeControls = state.routeControls
  const selectedStart = state.routeControls.selectedStart
  const selectedTarget = state.routeControls.selectedTarget
  const selectedCity = state.routeControls.selectedCity
  const selectedCityCenter = state.routeControls.selectedCityCenter
  const computedDirection = state.routeControls.computedDirection
  const routeInfos = state.routeControls.computedDirectionInfos
  const decodedDirection = state.routeControls.decodedDirection
  const graphicInputStart = state.routeControls.graphicInputStart
  const graphicInputTarget = state.routeControls.graphicInputTarget
  const userInputStart = state.routeControls.userInputStart
  const userInputTarget = state.routeControls.userInputTarget
  const switchPointZone = state.routeControls.switchPointZone
  const switchPointAdress = state.routeControls.switchPointAdress
  const isDraggingSwitchPoint = state.routeControls.isDraggingSwitchPoint
  const isRouteCalculated = state.routeControls.isRouteCalculated
  const waypointsDirection = state.routeControls.waypointsDirection
  const routeAlterDecoded = state.routeControls.routeAlterDecoded
  const updateViewTrigger = state.routeControls.updateViewTrigger
  const zoomZonePoint = state.routeControls.zoomZonePoint

  return {
    routeControls,
    selectedStart,
    selectedTarget,
    selectedCity,
    selectedCityCenter,
    computedDirection,
    routeInfos,
    decodedDirection,
    graphicInputStart,
    graphicInputTarget,
    userInputStart,
    userInputTarget,
    switchPointZone,
    switchPointAdress,
    isDraggingSwitchPoint,
    isRouteCalculated,
    waypointsDirection,
    routeAlterDecoded,
    updateViewTrigger,
    zoomZonePoint
  }
}

export default compose(
  connect(mapStateToProps),
  geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  }))(Map)